import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {
    DossierBienOverviewProprietairesService
} from '@features/dossiers/dossier/bien/overview/proprietaires/dossier-bien-overview.proprietaires.service';
import {
    DossierBienOverviewLocalisationService
} from '@features/dossiers/dossier/bien/overview/localisation/dossier-bien-overview.localisation.service';
import {
    DossierBienOverviewDescriptifsService
} from '@features/dossiers/dossier/bien/overview/descriptifs/dossier-bien-overview.descriptifs.service';
import {
    DossierBienOverviewArchiveService
} from '@features/dossiers/dossier/bien/overview/archive/dossier-bien-overview.archive.service';
import {
    DossierBienOverviewCompromisService
} from '@features/dossiers/dossier/bien/overview/compromis/dossier-bien-overview.compromis.service';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewInfosConfidentiellesService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienOverviewArchiveService = inject(DossierBienOverviewArchiveService);
    private _dossierBienOverviewCompromisService = inject(DossierBienOverviewCompromisService);
    private _dossierBienOverviewDescriptifsService = inject(DossierBienOverviewDescriptifsService);
    private _dossierBienOverviewLocalisationService = inject(DossierBienOverviewLocalisationService);
    private _dossierBienOverviewProprietairesService = inject(DossierBienOverviewProprietairesService);
    private _isCommentsConfidentielVisible!: boolean;
    private _isCommentsConfreresVisible!: boolean;

    get isCommentsConfidentielVisible(): boolean {
        return this._isCommentsConfidentielVisible;
    }

    get isCommentsConfreresVisible(): boolean {
        return this._isCommentsConfreresVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._dossierBienOverviewArchiveService.init(dossierBien);
        this._dossierBienOverviewCompromisService.init(dossierBien);
        this._dossierBienOverviewDescriptifsService.init(dossierBien);
        this._dossierBienOverviewLocalisationService.init(dossierBien);
        this._dossierBienOverviewProprietairesService.init(dossierBien);
        this._isCommentsConfidentielVisible = dossierBien.interne && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsConfidentiel);
        this._isCommentsConfreresVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsConfreres);
    }
}
