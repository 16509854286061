import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDCEstimationReferenceData} from '@features/estimations/estimations.interfaces';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';

@Component({selector: 'estimation-reference-dropdown', templateUrl: 'estimation-reference.dropdown.component.html'})
export class EstimationReferenceDropdownComponent implements IDynamicComponent {
    static readonly actions = {EDIT: 'estimation_reference_edit', REMOVE: 'estimation_reference_remove'};
    private _dropdownService = inject(DropdownService);
    private _estimationReference!: EstimationReference;

    @Input()
    set data(value: IDCEstimationReferenceData) {
        this._estimationReference = value.estimationReference!;
    }

    edit(): void {
        this._dropdownService.clicked(EstimationReferenceDropdownComponent.actions.EDIT, {estimationReference: this._estimationReference});
    }

    remove(): void {
        this._dropdownService.clicked(EstimationReferenceDropdownComponent.actions.REMOVE, {estimationReference: this._estimationReference});
    }
}
