import {inject, Injectable} from '@angular/core';
import {
    ICityscanOnsalePropertyApi
} from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.interfaces';
import CityscanOnsaleProperty
    from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {TextsService} from '@shared/texts/texts.service';

@Injectable({providedIn: 'root'})
export class CityscanOnsalePropertyFactory {
    private _textsService = inject(TextsService);

    create(cityscanOnsalePropertyApi: ICityscanOnsalePropertyApi): CityscanOnsaleProperty {
        const cityscanOnsaleProperty = new CityscanOnsaleProperty(this._textsService.uuid());

        cityscanOnsaleProperty.adresse = cityscanOnsalePropertyApi.adresse;
        cityscanOnsaleProperty.communeId = cityscanOnsalePropertyApi.communeId;
        cityscanOnsaleProperty.description = cityscanOnsalePropertyApi.description;
        cityscanOnsaleProperty.imageUrl = cityscanOnsalePropertyApi.imageUrl;
        cityscanOnsaleProperty.natureId =  cityscanOnsalePropertyApi.natureId;
        cityscanOnsaleProperty.nombrePieces = cityscanOnsalePropertyApi.nombrePieces;
        cityscanOnsaleProperty.prixVente = cityscanOnsalePropertyApi.prixVente;
        cityscanOnsaleProperty.publishedAt = cityscanOnsalePropertyApi.publishedAt;
        cityscanOnsaleProperty.superficie = cityscanOnsalePropertyApi.superficie;
        cityscanOnsaleProperty.surfaceCadastrale = cityscanOnsalePropertyApi.surfaceCadastrale;
        cityscanOnsaleProperty.url = cityscanOnsalePropertyApi.url;

        if (cityscanOnsalePropertyApi._links?.commune) {
            cityscanOnsaleProperty.linkCommune = cityscanOnsalePropertyApi._links.commune.href;
        }

        return cityscanOnsaleProperty;
    }
}
