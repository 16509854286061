@if (pending$ | appLoadObs | async; as pending) {
  <div [ngClass]="pending.value ? 'tw-waiting' : undefined!" class="tw-hidden sm:tw-flex tw-gap-4">
    <button type="button" class="tw-btn-primary-info" (click)="print()">Imprimer</button>

    <button type="button" class="tw-btn-secondary-info tw-w-10" #smButtonElement
            (click)="clickDropdown(smButtonElement)"><fa-icon [icon]="'ellipsis-v'"/></button>
  </div>

  <button type="button" class="sm:tw-hidden tw-btn-secondary-info tw-w-10" #xsButtonElement
          (click)="xsClickDropdown(xsButtonElement)" [ngClass]="pending.value ? 'tw-waiting' : undefined!">
    <fa-icon [icon]="'ellipsis-v'"/>
  </button>
}
