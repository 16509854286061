import {NgModule} from '@angular/core';
import {ErrorModule} from '@core/error/error.module';
import {ApiModule} from '@core/api/api.module';
import {AuthModule} from '@core/auth/auth.module';
import {RoutingModule} from '@core/routing/routing.module';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import Transfer from '@models/transfers/transfer/transfer.model';

@NgModule({exports: [RoutingModule], imports: [ApiModule, RoutingModule, AuthModule, ErrorModule]})
export class CoreModule {
    constructor(errorConfigurationService: ErrorConfigurationService) {
        errorConfigurationService.addIgnoredError(Transfer.ABORT);
    }
}
