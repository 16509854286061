@if (cBonvisites$ | async; as cBonvisites) {
  <div>
    @if (cBonvisites.total > 0) {
      <div class="tw-p-4 md:tw-px-0">
        <span class="tw-font-bold">{{cBonvisites.total}}</span> {{cBonvisites.total > 1 ? 'bons' : 'bon'}} de visite
      </div>

      <div class="tw-overflow-hidden tw-mb-8">
        <table class="tw-table-head-separate-body">
          <thead>
            <tr>
              <th class="tw-w-[7.75rem] lg:tw-w-32 xl:tw-w-36">
                <div>
                  Date <span class="tw-hidden lg:tw-inline-block">de</span>
                  <span class="tw-hidden xl:tw-inline-block"> la</span> visite
                </div>
              </th>

              <th><div>Demandeur</div></th>

              <th class="tw-w-40 sm:tw-w-72 lg:tw-w-1/4 2xl:tw-w-1/3"><div>Biens</div></th>

              <th class="tw-w-24 lg:tw-w-32"><div>Statut</div></th>

              <th class="tw-w-16 lg:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
            </tr>
          </thead>

          <tbody>
            @for (bonvisite of cBonvisites.results; track bonvisite) {
              <tr>
                <td>
                  @if (bonvisite.dateVisite) {
                    <div>{{bonvisite.dateVisite | date:'mediumDate'}}</div>
                  }
                </td>

                <td>
                  <div>
                    @if (bonvisite.linkDemandeur) {
                      <app-demandeur-view [link]="bonvisite.linkDemandeur" class="tw-block tw-w-full tw-truncate"/>
                    }
                  </div>
                </td>

                <td>
                  <div class="tw-flex print:tw-block tw-items-center tw-h-px print:tw-h-auto">
                    <app-bonvisite-dossierbiens-number [bonvisite]="bonvisite" class="tw-block tw-w-full"/>
                  </div>
                </td>

                <td class="tw-text-center"><app-bonvisite-statut [bonvisite]="bonvisite" class="tw-text-xs"/></td>

                <td class="tw-table-head-separate-body-actions">
                  <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                          #buttonElement (click)="click(buttonElement, bonvisite)">
                    <fa-icon [icon]="'ellipsis-v'"/>
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    } @else {
      <div class="tw-my-4 sm:tw-my-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
        <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
          Il n'y a aucun bon de visite associé à votre dossier.
        </p>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}

<app-load-more [collection$]="cBonvisites$" (loadMore)="getMore()" [loading]="pendingGetMore"/>
