<div class="tw-mb-5">
  <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-700"><fa-icon [icon]="'feather-alt'"/> Signature</h3>
</div>

<div class="tw-w-[80vw] sm:tw-w-96">
  <div class="tw-w-full"
       [style.aspect-ratio]="procedureSignataire.signaturePlaceholderWidth + '/' + procedureSignataire.signaturePlaceholderHeight">
    <app-signature [addedText]="procedureSignataire.convivialName" (signature)="getSignature($event)"
                   class="tw-border tw-text-gray-700"/>
  </div>

  <div class="tw-mt-4 tw-flex tw-justify-between">
    <div class="tw-flex tw-items-center">
      <input id="signatureValidate" [ngModelOptions]="{standalone: true}" [(ngModel)]="isSignatureValidate"
             class="tw-input-checkbox" type="checkbox" [disabled]="!canValidate"/>

      <label for="signatureValidate" [ngClass]="canValidate ? 'tw-cursor-pointer' : 'tw-cursor-default tw-opacity-75'"
             class="tw-ml-3 tw-text-sm">Lu et approuvé</label>
    </div>

    <button type="button" class="tw-btn-primary-info" [disabled]="!isSignatureValidate" (click)="sign()">Signer</button>
  </div>
</div>
