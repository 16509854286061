import {Component, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({selector: 'app-dossier-bien-overview-dossier', templateUrl: 'dossier-bien-overview.dossier.component.html'})
export class AppDossierBienOverviewDossierComponent {
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get estimation(): Estimation {
        return this._dossierBien as Estimation;
    }

    get vente(): Vente {
        return this._dossierBien as Vente;
    }
}
