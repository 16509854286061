export default `
<div ng-if="$ctrl.dossier.bien.option.commune || $ctrl.dossier.bien.option.quartier" class="text-large">
  <span ng-if="$ctrl.dossier.bien.option.commune" class="text-bold">
    {{$ctrl.dossier.bien.option.commune.fullname}}
  </span>

  <span ng-if="$ctrl.dossier.bien.option.quartier">- {{$ctrl.dossier.bien.option.quartier.name}}</span>
</div>

<hr ng-if="($ctrl.dossier.bien.option.commune || $ctrl.dossier.bien.option.quartier) && ($ctrl.dossier.bien.option.numeroVoie || $ctrl.dossier.bien.option.nomVoie)">

<div ng-if="$ctrl.dossier.bien.option.numeroVoie || $ctrl.dossier.bien.option.nomVoie">
  <span ng-if="$ctrl.dossier.bien.option.numeroVoie">{{$ctrl.dossier.bien.option.numeroVoie}}</span>

  <span ng-if="$ctrl.dossier.bien.option.nomVoie">{{$ctrl.dossier.bien.option.nomVoie}}</span>

  <div ng-if="$ctrl.dossier.bien.option.complementAdresse1" class="text-muted margin-top-10">
    {{$ctrl.dossier.bien.option.complementAdresse1}}
  </div>
</div>
`;
