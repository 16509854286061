import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {DossierFileService} from '@models/dossiers/dossier/files/file/dossier-file.service';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import Contact from '@models/contacts/contact/contact.model';

@Injectable({providedIn: 'root'})
export class DossierFilesService {
    private _dossierFileService = inject(DossierFileService);

    share$(dossier: ADossier, dossierFilesToShare: DossierFile[] = [], contacts: Contact[] = []): Observable<void> {
        if (dossierFilesToShare.length <= 0) {
            return of(undefined);
        }

        return combineLatest(dossierFilesToShare.map(dossierFileToShare => this._dossierFileService.share$(dossier, dossierFileToShare, contacts)))
            .pipe(map(_ => undefined));
    }
}
