import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {takeUntil} from 'rxjs/operators';
import {BonvisiteDropdownComponent} from '@features/bonvisites/item/dropdown/bonvisite.dropdown.component';
import {IBonvisiteActionsMainData, IBonvisiteActionsMainDataOptions} from '@features/bonvisites/bonvisites.interfaces';

@Component({selector: 'bonvisite-sign-actions-main', templateUrl: 'bonvisite-sign.actions-main.component.html'})
export class BonvisiteSignActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = {...{LAUNCH: 'launch'}, ...BonvisiteDropdownComponent.actions};
    static readonly initBonvisiteActionsMainDataOptions: IBonvisiteActionsMainDataOptions = {fromDossier: false};
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private _bonvisite!: Bonvisite;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IBonvisiteActionsMainDataOptions = {...BonvisiteSignActionsMainComponent.initBonvisiteActionsMainDataOptions};

    get actions(): { CANCEL_SIGN: string; SIGN: string } {
        return BonvisiteSignActionsMainComponent.actions;
    }

    set data(value: IBonvisiteActionsMainData) {
        this._bonvisite = value.bonvisite;
        this._options = {...BonvisiteSignActionsMainComponent.initBonvisiteActionsMainDataOptions, ...value.options};
    }

    get options(): IBonvisiteActionsMainDataOptions {
        return this._options;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {
            component: BonvisiteDropdownComponent,
            data: {
                bonvisite: this._bonvisite,
                options: {
                    enabledActions: {
                        archive: this._options.archive,
                        cancelSign: this._options.sign && this._options.cancelSign,
                        print: this._options.print,
                        save: this._options.save,
                    }
                },
            },
        });
    }

    action(action: string): void {
        this._callToActionService.clicked(action, this._bonvisite);
    }
}
