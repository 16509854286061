import {inject, Injectable} from '@angular/core';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {
    ICVentesAddedSearchCriteria, ICVentesApi, ICVentesQueryParameters
} from '@models/ventes/collection/ventes.collection.interfaces';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {VentesApiService} from '@models/ventes/ventes.api.service';
import {ConditionConst} from '@shared/constants';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Vente from '@models/ventes/vente/vente.model';
import SearchCriteria from '@models/search/criteria/search-criteria.model';
import {NgVentesCollection} from '@legacy/app/managers/collections';

@Injectable({providedIn: 'root'})
export class CVentesFactory {
    private _etudeService = inject(EtudeService);
    private _venteFactory = inject(VenteFactory);
    private _ventesApiService = inject(VentesApiService);

    create(cVentesApi: ICVentesApi): CVentes {
        const cVentes = this.createVirgin();

        cVentes.links = new Links(cVentesApi._links);
        cVentes.page = cVentesApi.page;
        cVentes.pages = cVentesApi.pages;
        cVentes.perPage = cVentesApi.limit;
        cVentes.total = cVentesApi.total;
        if (cVentesApi._embedded?.items) {
            cVentesApi._embedded.items.filter(venteApi => venteApi).map(venteApi => cVentes.results.push(this._venteFactory.create(venteApi)));
        }

        return cVentes;
    }

    createVirgin(): CVentes {
        const cVentes = new CVentes();

        cVentes.links = new Links();

        return cVentes;
    }

    get$(queryParameters?: ICVentesQueryParameters): Observable<CVentes> {
        return this._ventesApiService.getCollection$(queryParameters).pipe(map(cVentesApi => this.create(cVentesApi)));
    }

    getByLink$(link: string): Observable<CVentes> {
        return this._ventesApiService.getCollectionByLink$(link).pipe(map(cVentesApi => this.create(cVentesApi)));
    }

    getEtude$(queryParameters?: ICVentesQueryParameters): Observable<CVentes> {
        return this._ventesApiService.getEtudeCollection$(queryParameters).pipe(map(cVentesApi => this.create(cVentesApi)));
    }

    getNext$(cVentes: CVentes): Observable<CVentes> {
        return this._ventesApiService.getCollectionByLink$(cVentes.links.next).pipe(map(cVentesApi => this.create(cVentesApi)));
    }

    getQueryParametersFromSearchCriteria$(searchCriteria: SearchCriteria, addedSearchCriteria: ICVentesAddedSearchCriteria = {}): Observable<ICVentesQueryParameters> {
        const queryParameters = {tris: searchCriteria.tris} as ICVentesQueryParameters;

        if (searchCriteria.annexe) {
            queryParameters.annexe = true;
        }

        if (searchCriteria.ascenseur) {
            queryParameters.ascenseur = true;
        }

        if (searchCriteria.balcon) {
            queryParameters.balcon = true;
        }

        if (searchCriteria.cave) {
            queryParameters.cave = true;
        }

        if (searchCriteria.cheminee) {
            queryParameters.cheminee = true;
        }

        if (searchCriteria.garage) {
            queryParameters.garage = true;
        }

        if (searchCriteria.grenier) {
            queryParameters.grenier = true;
        }

        if (searchCriteria.parking) {
            queryParameters.parking = true;
        }

        if (searchCriteria.piscine) {
            queryParameters.piscine = true;
        }

        if (searchCriteria.pointEau) {
            queryParameters.pointEau = true;
        }

        if (searchCriteria.residenceService) {
            queryParameters.residenceService = true;
        }

        if (searchCriteria.sousSol) {
            queryParameters.sousSol = true;
        }

        if (searchCriteria.stationnement) {
            queryParameters.stationnement = true;
        }

        if (searchCriteria.terrasse) {
            queryParameters.terrasse = true;
        }

        if (searchCriteria.terrasseOuBalcon) {
            queryParameters.terrasseOuBalcon = true;
        }

        if (searchCriteria.anneeConstructionMax > 0 || searchCriteria.anneeConstructionMin > 0) {
            queryParameters.anneeConstruction = {};
            if (searchCriteria.anneeConstructionMax > 0) {
                queryParameters.anneeConstruction.max = searchCriteria.anneeConstructionMax;
            }

            if (searchCriteria.anneeConstructionMin > 0) {
                queryParameters.anneeConstruction.min = searchCriteria.anneeConstructionMin;
            }
        }

        if (searchCriteria.nombreChambresMax > 0 || searchCriteria.nombreChambresMin > 0) {
            queryParameters.nombreChambres = {};
            if (searchCriteria.nombreChambresMax > 0) {
                queryParameters.nombreChambres.max = searchCriteria.nombreChambresMax;
            }

            if (searchCriteria.nombreChambresMin > 0) {
                queryParameters.nombreChambres.min = searchCriteria.nombreChambresMin;
            }
        }

        if (searchCriteria.nombreLogementsMax > 0 || searchCriteria.nombreLogementsMin > 0) {
            queryParameters.nombreLogements = {};
            if (searchCriteria.nombreLogementsMax > 0) {
                queryParameters.nombreLogements.max = searchCriteria.nombreLogementsMax;
            }

            if (searchCriteria.nombreLogementsMin > 0) {
                queryParameters.nombreLogements.min = searchCriteria.nombreLogementsMin;
            }
        }

        if (searchCriteria.nombrePiecesMax > 0 || searchCriteria.nombrePiecesMin > 0) {
            queryParameters.nombrePieces = {};
            if (searchCriteria.nombrePiecesMax > 0) {
                queryParameters.nombrePieces.max = searchCriteria.nombrePiecesMax;
            }

            if (searchCriteria.nombrePiecesMin > 0) {
                queryParameters.nombrePieces.min = searchCriteria.nombrePiecesMin;
            }
        }

        if (searchCriteria.superficieMax > 0 || searchCriteria.superficieMin > 0) {
            queryParameters.superficie = {};
            if (searchCriteria.superficieMax > 0) {
                queryParameters.superficie.max = searchCriteria.superficieMax;
            }

            if (searchCriteria.superficieMin > 0) {
                queryParameters.superficie.min = searchCriteria.superficieMin;
            }
        }

        if (searchCriteria.surfaceBatieMax > 0 || searchCriteria.surfaceBatieMin > 0 ||
            searchCriteria.surfaceCadastraleMax > 0 || searchCriteria.surfaceCadastraleMin > 0 ||
            searchCriteria.surfaceNonBatieMax > 0 || searchCriteria.surfaceNonBatieMin > 0 ||
            searchCriteria.surfaceSejourMax > 0 || searchCriteria.surfaceSejourMin > 0) {
            queryParameters.surfaces = {};
            if (searchCriteria.surfaceBatieMax > 0 || searchCriteria.surfaceBatieMin > 0) {
                queryParameters.surfaces.batie = {};
                if (searchCriteria.surfaceBatieMax > 0) {
                    queryParameters.surfaces.batie.max = searchCriteria.surfaceBatieMax;
                }

                if (searchCriteria.surfaceBatieMin > 0) {
                    queryParameters.surfaces.batie.min = searchCriteria.surfaceBatieMin;
                }
            }

            if (searchCriteria.surfaceCadastraleMax > 0 || searchCriteria.surfaceCadastraleMin > 0) {
                queryParameters.surfaces.cadastrale = {};
                if (searchCriteria.surfaceCadastraleMax > 0) {
                    queryParameters.surfaces.cadastrale.max = searchCriteria.surfaceCadastraleMax;
                }

                if (searchCriteria.surfaceCadastraleMin > 0) {
                    queryParameters.surfaces.cadastrale.min = searchCriteria.surfaceCadastraleMin;
                }
            }

            if (searchCriteria.surfaceNonBatieMax > 0 || searchCriteria.surfaceNonBatieMin > 0) {
                queryParameters.surfaces.nonBatie = {};
                if (searchCriteria.surfaceNonBatieMax > 0) {
                    queryParameters.surfaces.nonBatie.max = searchCriteria.surfaceNonBatieMax;
                }

                if (searchCriteria.surfaceNonBatieMin > 0) {
                    queryParameters.surfaces.nonBatie.min = searchCriteria.surfaceNonBatieMin;
                }
            }

            if (searchCriteria.surfaceSejourMax > 0 || searchCriteria.surfaceSejourMin > 0) {
                queryParameters.surfaces.sejour = {};
                if (searchCriteria.surfaceSejourMax > 0) {
                    queryParameters.surfaces.sejour.max = searchCriteria.surfaceSejourMax;
                }

                if (searchCriteria.surfaceSejourMin > 0) {
                    queryParameters.surfaces.sejour.min = searchCriteria.surfaceSejourMin;
                }
            }
        }

        if (searchCriteria.communes.length > 0) {
            queryParameters.commune = {
                condition: ConditionConst.IN,
                values: searchCriteria.communes.map(commune => commune.id),
            };
        }

        if (searchCriteria.departements.length > 0 || searchCriteria.regions.length > 0) {
            queryParameters.areas = {
                condition: ConditionConst.IN,
                values: searchCriteria.departements.map(departement => departement.id)
                    .concat(searchCriteria.regions.map(region => region.id)),
            };
        }

        if (searchCriteria.etats.length > 0) {
            queryParameters.etat = {
                condition: ConditionConst.IN,
                values: searchCriteria.etats.map(etat => etat.id),
            };
        }

        if (searchCriteria.mitoyennetes.length > 0) {
            queryParameters.mitoyennete = {
                condition: ConditionConst.IN,
                values: searchCriteria.mitoyennetes.map(mitoyennete => mitoyennete.id),
            };
        }

        if (searchCriteria.natures.length > 0) {
            queryParameters.nature = {
                condition: ConditionConst.IN,
                values: searchCriteria.natures.map(nature => nature.code),
            };
        }

        if (searchCriteria.occupations.length > 0) {
            queryParameters.occupation = {
                condition: ConditionConst.IN,
                values: searchCriteria.occupations.map(mitoyennete => mitoyennete.id),
            };
        }

        if (searchCriteria.plainPieds.length > 0) {
            queryParameters.plainPied = {
                condition: ConditionConst.IN,
                values: searchCriteria.plainPieds.map(plainPied => plainPied.id),
            };
        }

        if (searchCriteria.quartiers.length > 0) {
            queryParameters.quartier = {
                condition: ConditionConst.IN,
                values: searchCriteria.quartiers.map(quartier => quartier.id),
            };
        }

        if (searchCriteria.statuts.length > 0) {
            queryParameters.statut = {
                condition: ConditionConst.IN,
                values: searchCriteria.statuts.map(statut => statut.code),
            };
        }

        if (searchCriteria.vues.length > 0) {
            queryParameters.vue = {
                condition: ConditionConst.IN,
                values: searchCriteria.vues.map(vue => vue.id),
            };
        }

        if (searchCriteria.archiveDateVenteMax || searchCriteria.archiveDateVenteMin || searchCriteria.archivePrixCessionMax > 0 ||
            searchCriteria.archivePrixCessionMin > 0 || searchCriteria.archiveTypes.length > 0) {
            queryParameters.archive = {};
            if (searchCriteria.archiveDateVenteMax || searchCriteria.archiveDateVenteMin) {
                queryParameters.archive.date = {};
                if (searchCriteria.archiveDateVenteMax) {
                    queryParameters.archive.date.max = searchCriteria.archiveDateVenteMax;
                }

                if (searchCriteria.archiveDateVenteMin) {
                    queryParameters.archive.date.min = searchCriteria.archiveDateVenteMin;
                }
            }

            if (searchCriteria.archivePrixCessionMax > 0 || searchCriteria.archivePrixCessionMin > 0) {
                queryParameters.archive.prixCession = {};
                if (searchCriteria.archivePrixCessionMax > 0) {
                    queryParameters.archive.prixCession.max = searchCriteria.archivePrixCessionMax;
                }

                if (searchCriteria.archivePrixCessionMin > 0) {
                    queryParameters.archive.prixCession.min = searchCriteria.archivePrixCessionMin;
                }
            }

            if (searchCriteria.archiveTypes.length > 0) {
                queryParameters.archive.type = {
                    condition: addedSearchCriteria.venteArchiveTypeCondition ?? ConditionConst.IN,
                    values: searchCriteria.archiveTypes.map(archiveType => archiveType.code),
                };
            }
        }

        if (searchCriteria.budgetHni || searchCriteria.budgetMax > 0 || searchCriteria.budgetMin > 0 || searchCriteria.excludeVenteInteractive ||
            searchCriteria.excludeVenteTraditionnelle || searchCriteria.excludeVenteViager || searchCriteria.renteMax > 0 || searchCriteria.renteMin > 0) {
            queryParameters.vente = {};
            if (searchCriteria.budgetHni) {
                queryParameters.vente.budgetHni = true;
            }

            if (searchCriteria.budgetMax > 0 || searchCriteria.budgetMin > 0) {
                queryParameters.vente.budget = {};
                if (searchCriteria.budgetMax > 0) {
                    queryParameters.vente.budget.max = searchCriteria.budgetMax;
                }

                if (searchCriteria.budgetMin > 0) {
                    queryParameters.vente.budget.min = searchCriteria.budgetMin;
                }
            }

            if (searchCriteria.renteMax > 0 || searchCriteria.renteMin > 0) {
                queryParameters.vente.rente = {};
                if (searchCriteria.renteMax > 0) {
                    queryParameters.vente.rente.max = searchCriteria.renteMax;
                }

                if (searchCriteria.renteMin > 0) {
                    queryParameters.vente.rente.min = searchCriteria.renteMin;
                }
            }

            if (searchCriteria.excludeVenteInteractive || searchCriteria.excludeVenteTraditionnelle || searchCriteria.excludeVenteViager) {
                queryParameters.vente.type = {condition: ConditionConst.NOT_IN, values: []};
                if (searchCriteria.excludeVenteInteractive) {
                    queryParameters.vente.type.values.push(Vente.types.INTERACTIVE);
                }

                if (searchCriteria.excludeVenteTraditionnelle) {
                    queryParameters.vente.type.values.push(Vente.types.SIMPLE);
                }

                if (searchCriteria.excludeVenteViager) {
                    queryParameters.vente.type.values.push(Vente.types.VIAGER);
                }
            }
        }

        if (searchCriteria.voie) {
            queryParameters.nomVoie = {condition: ConditionConst.CONTAINS, values: [searchCriteria.voie.nom]};
            queryParameters.voie = {condition: ConditionConst.IN, values: [searchCriteria.voie.id]};
        } else if (searchCriteria.nomVoie) {
            queryParameters.nomVoie = {condition: ConditionConst.CONTAINS, values: [searchCriteria.nomVoie]};
        }

        return this._etudeService.last$.pipe(
            map(currentEtude => {
                if (searchCriteria.uniquementBiensNotaire || searchCriteria.isSourceEtude()) {
                    queryParameters.etude = {condition: ConditionConst.IN, values: [currentEtude.id]};
                } else if (searchCriteria.isSourceNetwork()) {
                    queryParameters.etude = {condition: ConditionConst.NOT_IN, values: [currentEtude.id]};
                }

                return queryParameters;
            }),
        );
    }

    ngCreate(ngVentesCollection: NgVentesCollection): CVentes {
        const cVentes = this.createVirgin();

        cVentes.links = new Links({
            first: {href: ngVentesCollection.firstPageLink},
            last: {href: ngVentesCollection.lastPageLink},
            next: {href: ngVentesCollection.nextPageLink},
            previous: {href: ngVentesCollection.previousPageLink},
            self: {href: ngVentesCollection.currentPageLink},
        });
        cVentes.page = ngVentesCollection.current;
        cVentes.pages = ngVentesCollection.pages;
        cVentes.perPage = ngVentesCollection.pageSize;
        cVentes.total = ngVentesCollection.totalItems;
        if (ngVentesCollection.collection) {
            ngVentesCollection.collection.filter(ngVente => ngVente).map(ngVente => cVentes.results.push(this._venteFactory.ngCreate(ngVente)));
        }

        return cVentes;
    }
}
