import angularJS from '@shared/angularJS/global.ng';
import {IAttributes, IController, IDirectiveFactory, IModule, Injectable, IScope, IWindowService} from 'angular';
import {NgElement, NgIDocumentService} from '@legacy/app/soqrate/soqrate';
import {FacebookService} from '@shared/facebook/facebook.service';
import environment from '@env/environment.local';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export default function getSoqFacebook(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-facebook></soq-facebook>
         */
        module.directive('soqFacebook', directive as Injectable<IDirectiveFactory>);

        /**
         * Get selected files
         *
         * @param $window
         * @param $document
         * @param Ng2FacebookService
         */
        directive.$inject = ['$window', '$document', 'Ng2FacebookService'];
        function directive($window: IWindowService, $document: NgIDocumentService, ng2FacebookService: FacebookService) {
            return {
                restrict: 'E',
                scope: {fbScopes: '@', onChangeStatus: '='},
                templateUrl: 'src/app/legacy/templates/soqrate/directives/facebook.html',
                link: link,
                controller: ['$scope', controller],
                bindToController: true
            };

            /**
             * Link of directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             * @param $ctrl
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes, $ctrl: IController) {
                // @ts-ignore
                $scope.$ctrl = $ctrl;

                if (!ng2FacebookService.initInProgress) {
                    ng2FacebookService.initInProgress = true;

                    const elementRoot = $element[0];
                    const elementScripSDK = ($document[0] as any).createElement('script');

                    // Doit être déclaré avant le chargement du SDK
                    $window.fbAsyncInit = () => ng2FacebookService.initFBService($window.FB);

                    // Chargement du SDK
                    elementScripSDK.id = 'facebook-jssdk';
                    // @todo Voir pour faire mieux afin de déterminer l'environnement de déploiement
                    if (['dev', 'local', 'test'].includes(environment.sentry.environment)) {
                        elementScripSDK.src = 'https://connect.facebook.net/en_US/sdk/debug.js';
                    } else {
                        elementScripSDK.src = 'https://connect.facebook.net/fr_FR/sdk.js';
                    }
                    (elementRoot as any).appendChild(elementScripSDK, elementRoot);
                }
            }

            /**
             * Controller of directive
             *
             * @param $scope
             */
            function controller(this: any, $scope: IScope) {
                const $ctrl = this;
                const _onDestroy$ = new Subject<void>();

                $ctrl.login = login;
                $ctrl.onChangeStatus = onChangeStatus;
                $ctrl.loading = true;
                ng2FacebookService.sdkLoaded$.pipe(takeUntil(_onDestroy$)).subscribe(loaded => $ctrl.loading = !loaded);
                $scope.$on("$destroy", () => _onDestroy$.next());

                function login() {
                    ng2FacebookService.login($ctrl.onChangeStatus, {scope: $ctrl.fbScopes});
                }

                /**
                 * On change status
                 */
                function onChangeStatus(response: unknown) {
                    // @ts-ignore
                    if (angular.isFunction($scope.onChangeStatus)) $scope.onChangeStatus(response);
                }
            }
        }
    })(angularJS);
}
