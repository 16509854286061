import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgAreaManager, NgCommuneManager, NgQuartierManager} from '@legacy/app/managers/managers';
import {NgResource} from '@legacy/app/managers/ressources';
import {NgAreasCollection, NgCommunesCollection, NgQuartiersCollection} from '@legacy/app/managers/collections';
import {ConditionConst} from '@shared/constants';

export default function getEskSelectLocalisation(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-localisation></esk-select-localisation>
         * @param options : {disabled(object), name(string), placeholder(string)}
         * @param options.disabled : {quartier(boolean), region(boolean)}
         */
        module.component('eskSelectLocalisation', {
            bindings: {
                ngModel: '=',
                onSelect: '=',
                options: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/localisation.html'
        });

        /**
         * Select communes
         *
         * @param AreaManager
         * @param CommuneManager
         * @param QuartierManager
         * @param $q
         * @constructor
         */
        Controller.$inject = ['AreaManager', 'CommuneManager', 'QuartierManager', '$q'];
        function Controller(this: any,
                            areaManager: NgAreaManager,
                            communeManager: NgCommuneManager,
                            quartierManager: NgQuartierManager,
                            $q: IQService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.refresh = refresh;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.localisations = [];

                if (!angular.isObject($ctrl.options)) {
                    $ctrl.options = {};
                }

                if (!angular.isString($ctrl.options.name) || $ctrl.options.name === '') {
                    $ctrl.options.name = 'localisation';
                }

                if (!angular.isString($ctrl.options.placeholder) || $ctrl.options.placeholder === '') {
                    $ctrl.options.placeholder = "Rechercher une région, un département, une commune ou un quartier";
                }

                if (!angular.isObject($ctrl.options.disabled)) {
                    $ctrl.options.disabled = {};
                }
            }

            /**
             * Refresh communes
             *
             * @param keywords
             */
            function refresh(keywords: string) {
                const localisations: { type: string; data: NgResource; name: string }[] = [];
                const promises = [] as IPromise<unknown>[];
                const areaManagerTypeValues = ['departement'];
                let localisationCPSelectCommunes: { type: string; data: NgResource; name: string };

                $ctrl.localisations = [];
                if (!$ctrl.options.disabled.quartier) {
                    promises.push(quartierManager.getAll(
                        {keywords, limit: 20}
                    ).then((quartiers: NgQuartiersCollection) =>
                        quartiers.collection.map(quartier => localisations.push({
                            type: 'quartier',
                            data: quartier,
                            name: quartier.name + ' (' + quartier.commune!.fullname + ')'
                        }))
                    ));
                }

                promises.push(communeManager.getAll({keywords, limit: 30}).then((communes: NgCommunesCollection) => {
                    if (communes.totalItems > 0 && /\d{5}/.test(keywords)) {
                        localisationCPSelectCommunes = {
                            type: 'communes',
                            data: communes.collection as unknown as NgResource,
                            name: keywords + " - Toutes les communes associées"
                        };
                    }

                    communes.collection.map(commune => {
                        localisations.push({
                            type: 'commune',
                            data: commune,
                            name: commune.fullname + ' - ' + (commune.codesPostaux.length === 1 ? commune.codesPostaux[0] : "Tous codes postaux")
                        });
                    });
                }));

                if (!$ctrl.options.disabled.region) {
                    areaManagerTypeValues.push('region');
                }

                promises.push(areaManager.getAll(
                    {keywords, limit: 20, type: {condition: ConditionConst.IN, values: areaManagerTypeValues}}
                ).then((areas: NgAreasCollection) =>
                    areas.collection.map(area => localisations.push({type: 'area', data: area, name: area.fullname}))
                ));

                return $q.all(promises).finally(() => {
                    if (angular.isObject(localisationCPSelectCommunes)) localisations.unshift(localisationCPSelectCommunes);

                    $ctrl.localisations = localisations;
                });
            }
        }
    })(angularJS);
}
