import {inject, Injectable} from '@angular/core';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {SearchCriteriaFactory} from '@models/search/criteria/search-criteria.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

@Injectable({providedIn: 'root'})
export class SearchCriteriaService {
    private _searchCriteriaFactory = inject(SearchCriteriaFactory);
    private _savedSearchCriteria = new Map<string, SearchCriteria>();

    get(key: string): SearchCriteria {
        const searchCriteria = this._savedSearchCriteria.get(key);

        this._savedSearchCriteria.delete(key);

        return searchCriteria!;
    }

    getOneOrFromEstimation(key: string, estimation: Estimation): SearchCriteria {
        return this.get(key) ?? this._searchCriteriaFactory.createFromEstimation(estimation);
    }

    save(key: string, searchCriteria: SearchCriteria): void {
        this._savedSearchCriteria.set(key, searchCriteria);
    }

    ngSave(key: string, ngSearch: NgSearch): void {
        this._savedSearchCriteria.set(key, this._searchCriteriaFactory.ngCreate(ngSearch));
    }
}
