import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {IModel} from '@models/model.interfaces';
import {
    CollectionSelectionOptionsFilterEnabled, CollectionSelectionOptionsTitleDisabled, ICollectionSelectionOptions
} from '@shared/collection/selection/collection-selection.interfaces';

@Injectable({providedIn: 'root'})
export class CollectionSelectionService {
    private _mapConfiguration = new Map<string, {
        filterEnabled: CollectionSelectionOptionsFilterEnabled | undefined;
        list: IModel[];
        listSelectedSource: ReplaySubject<Set<IModel>>;
        listSelected$: Observable<Set<IModel>>;
        selection: Set<IModel>;
        titleDisabled: CollectionSelectionOptionsTitleDisabled | undefined;
    }>();

    clear(listName: string): void {
        this._mapConfiguration.get(listName)?.selection.clear();
    }

    delete(listName: string): void {
        this._mapConfiguration.delete(listName);
    }

    getListSelected$(listName: string): Observable<Set<IModel>> {
        const configuration = this._mapConfiguration.get(listName);

        if (!configuration) {
            return of(undefined as unknown as Set<IModel>);
        }

        return configuration.listSelected$;
    }

    getNumberSelected(listName: string): number {
        return this._mapConfiguration.get(listName)?.selection.size ?? 0;
    }

    // @todo passer cette fonction en observable
    hasValues(listName: string): boolean {
        return this.getNumberSelected(listName) !== 0;
    }

    indeterminate(listName: string): boolean {
        return this.hasValues(listName) && !this.isAllSelected(listName);
    }

    init(listName: string, list: IModel[], options: ICollectionSelectionOptions): void {
        if (this._mapConfiguration.get(listName)) {
            this._mapConfiguration.delete(listName);
        }

        const listSelectedSource = new ReplaySubject<Set<IModel>>(1);

        this._mapConfiguration.set(listName, {
            filterEnabled: options.filterEnabled,
            list,
            listSelectedSource,
            listSelected$: listSelectedSource.asObservable(),
            selection: new Set<IModel>(),
            titleDisabled: options.titleDisabled,
        });
        if (options.initSelectAll) {
            this.toggleAll(listName);
        }
    }

    isAllSelected(listName: string): boolean {
        return this.getNumberSelected(listName) === this._mapConfiguration.get(listName)?.list.filter(item => this.itemIsEnabled(listName, item)).length;
    }

    isInit$(listName: string): Observable<boolean> {
        const isInitSource = new BehaviorSubject<boolean>(false);
        const endSource = new Subject<void>();

        interval(10).pipe(
            map(_ => this._mapConfiguration.has(listName)),
            filter(isInit => !!isInit),
            takeUntil(endSource),
        ).subscribe(_ => {
            isInitSource.next(true);
            endSource.next();
            endSource.complete();
        });

        return isInitSource.asObservable();
    }

    isSelected(listName: string, value: IModel): boolean {
        return this._mapConfiguration.get(listName)?.selection.has(value) ?? false;
    }

    itemIsEnabled(listName: string, value: IModel): boolean {
        const configuration = this._mapConfiguration.get(listName);

        if (!configuration?.filterEnabled) {
            return true;
        }

        return configuration.filterEnabled(value);
    }

    itemTitleDisabled(listName: string, value: IModel): string {
        const configuration = this._mapConfiguration.get(listName);

        if (!configuration?.titleDisabled) {
            return '';
        }

        return configuration.titleDisabled(value);
    }

    operateListSelected$<T, U extends IModel>(listName: string, action$: (list: Set<U>) => Observable<T>): Observable<T> {
        const configuration = this._mapConfiguration.get(listName);

        if (!configuration) {
            return of(undefined as unknown as T);
        }

        return configuration.listSelected$.pipe(
            // @todo Est-ce possible, pour éviter cette ligne, de faire "const configuration = this._mapConfiguration.get<U>(listName);" ?
            map(listSelected => listSelected as Set<U>),
            switchMap(listSelected => action$(listSelected)),
            take(1),
            tap(value => {
                if (value) {
                    this.clear(listName);
                }
            }),
        );
    }

    toggle(listName: string, value: IModel): void {
        const configuration = this._mapConfiguration.get(listName);

        if (!configuration) {
            return;
        }

        if (this.isSelected(listName, value)) {
            configuration.selection.delete(value);
        } else if (this.itemIsEnabled(listName, value)) {
            configuration.selection.add(value);
        }

        configuration.listSelectedSource.next(configuration.selection);
    }

    toggleAll(listName: string): void {
        const configuration = this._mapConfiguration.get(listName);

        if (!configuration) {
            return;
        }

        if (this.isAllSelected(listName)) {
            this.clear(listName);
        } else {
            configuration.list
                .filter(item => this.itemIsEnabled(listName, item))
                .forEach(item => configuration.selection.add(item));
        }

        configuration.listSelectedSource.next(configuration.selection);
    }
}
