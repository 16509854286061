<div class="tw-py-1">
  @if (options.enabledActions?.save) {
    <button type="button" class="tw-dropdown-link" (click)="save()">
      <fa-icon [icon]="'check'"/><span>Enregistrer</span>
    </button>
  }

  @if (options.enabledActions?.edit) {
    <button type="button" class="tw-dropdown-link" (click)="edit()">
      <fa-icon [icon]="'pencil'"/><span>Éditer</span>
    </button>
  }

  @if (options.enabledActions?.write) {
    <button type="button" class="tw-dropdown-link" (click)="write()">
      <fa-icon [icon]="'pen-to-square'"/><span>Rédiger</span>
    </button>
  }

  @if (options.enabledActions?.send) {
    <button type="button" class="tw-dropdown-link" (click)="send()">
      <fa-icon [icon]="'envelope'"/><span>Envoyer</span>
    </button>
  }

  @if (options.enabledActions?.sign) {
    <button type="button" class="tw-dropdown-link" (click)="sign()">
      <fa-icon [icon]="'feather-alt'"/><span>Signer</span>
    </button>
  }

  @if (options.enabledActions?.cancelSign) {
    <button type="button" class="tw-dropdown-link" (click)="cancelSign()">
      <fa-stack><fa-icon icon="feather-alt" stackItemSize="1x"/><fa-icon icon="slash" stackItemSize="1x"/></fa-stack>
      <span>Annuler la signature</span>
    </button>
  }

  @if (options.enabledActions?.print) {
    <button type="button" class="tw-dropdown-link" (click)="print()">
      <fa-icon icon="print"/><span>Imprimer</span>
    </button>
  }

  @if (options.enabledActions?.archive) {
    <button type="button" class="tw-dropdown-link" (click)="archive()">
      <fa-icon [icon]="'archive'"/><span>Archiver</span>
    </button>
  }

  @if (options.enabledActions?.resume) {
    <button type="button" class="tw-dropdown-link" (click)="resume()">
      <fa-icon [icon]="'eye'"/><span>Voir le résumé</span>
    </button>
  }
</div>
