export default `
<span class="contrats-new">
  <button type="button" class="btn btn-primary" ng-click="$ctrl.generateContrats()">
    <fa-icon [icon]="'edit'"></fa-icon>

    <span class="new-generate">Générer des documents</span>

    <span class="new-create">Créer des documents</span>
  </button>
</span>
`;
