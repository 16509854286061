import {Component, inject, Input, OnInit, output} from '@angular/core';
import {Observable} from 'rxjs';
import CEtudePasserelles from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.model';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {IEtudePasserellesListAction} from '@features/etudes/etudes.interfaces';

@Component({selector: 'app-etude-passerelles-list', templateUrl: 'etude-passerelles-list.component.html'})
export class AppEtudePasserellesListComponent implements OnInit {
    static readonly actions = {
        SEE_LOCATIONS: 'etude_passerelle_see_locations',
        SEE_VENTES: 'etude_passerelle_see_ventes',
    };
    readonly clicked = output<IEtudePasserellesListAction>();
    private _userService = inject(UserService);
    private _cEtudePasserelles$!: Observable<CEtudePasserelles>;
    private _hasRoleLocation$!: Observable<boolean>;
    private _hasRoleVente$!: Observable<boolean>;

    get cEtudePasserelles$(): Observable<CEtudePasserelles> {
        return this._cEtudePasserelles$;
    }

    @Input({required: true})
    set cEtudePasserelles$(value: Observable<CEtudePasserelles>) {
        this._cEtudePasserelles$ = value;
    }

    get hasRoleLocation$(): Observable<boolean> {
        return this._hasRoleLocation$;
    }

    get hasRoleVente$(): Observable<boolean> {
        return this._hasRoleVente$;
    }

    ngOnInit(): void {
        this._hasRoleLocation$ = this._userService.currentHasRole$(User.roles.GRP_LOCATION);
        this._hasRoleVente$ = this._userService.currentHasRole$(User.roles.GRP_VENTE);
    }

    redirectToLocations(etudePasserelle: EtudePasserelle): void {
        this.clicked.emit({action: AppEtudePasserellesListComponent.actions.SEE_LOCATIONS, etudePasserelle});
    }

    redirectToVentes(etudePasserelle: EtudePasserelle): void {
        this.clicked.emit({action: AppEtudePasserellesListComponent.actions.SEE_VENTES, etudePasserelle});
    }
}
