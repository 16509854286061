import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewBatimentService
} from '@features/dossiers/dossier/bien/overview/batiment/dossier-bien-overview.batiment.service';

@Component({
    selector: 'app-dossier-bien-overview-batiment',
    templateUrl: 'dossier-bien-overview.batiment.component.html',
})
export class AppDossierBienOverviewBatimentComponent {
    private _dossierBienOverviewBatimentService = inject(DossierBienOverviewBatimentService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isCharpentesVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isCharpentesVisible;
    }

    get isChauffageVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isChauffageVisible;
    }

    get isCommentsBatimentVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isCommentsBatimentVisible;
    }

    get isDateConstructionEtatVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isDateConstructionEtatVisible;
    }

    get isExpositionVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isExpositionVisible;
    }

    get isHuisseriesVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isHuisseriesVisible;
    }

    get isIsolationsVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isIsolationsVisible;
    }

    get isMateriauxConstructionVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isMateriauxConstructionVisible;
    }

    get isMateriauxCouvertureVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isMateriauxCouvertureVisible;
    }

    get isMitoyenneteVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isMitoyenneteVisible;
    }

    get isNombreEtagesBatimentVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isNombreEtagesBatimentVisible;
    }

    get isVoletsVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isVoletsVisible;
    }
}
