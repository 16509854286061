import angularJS from '@shared/angularJS/global.ng';
import {IDirectiveFactory, IModule, Injectable, IScope} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getSoqPreventTouchMove(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-prevent-touch-move
         */
        module.directive('soqPreventTouchMove', directive as Injectable<IDirectiveFactory>);

        /**
         * prevent touch move directive
         *
         * @returns {{restrict: string, scope: boolean, link: Function}}
         */
        directive.$inject = [] as string[];
        function directive() {
            return {restrict: 'A', link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             */
            function link($scope: IScope, $element: NgElement) {
                $element.on("touchmove", event => event.preventDefault());
            }
        }
    })(angularJS);
}
