import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewRisquesService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isCommentsRisquesVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isZoneRisquesVisible!: boolean;

    get isCommentsRisquesVisible(): boolean {
        return this._isCommentsRisquesVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isZoneRisquesVisible(): boolean {
        return this._isZoneRisquesVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isCommentsRisquesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsRisques);
        this._isZoneRisquesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.zoneRisques);

        this._isPartVisible = this.isCommentsRisquesVisible || this.isZoneRisquesVisible;
    }
}
