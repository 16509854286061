import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import DateFormat from '@shared/date/date.format';

export default function getEskFooter(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-footer></esk-footer>
         */
        module.component('eskFooter', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/footer.html',
        });

        /**
         * Soqrate footer
         *
         * @constructor
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.COMMERCE = EmailRecipient.noteo.COMMERCE;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.currentYear = DateFormat.getYear(DateFormat.dateFromNow());
            }
        }
    })(angularJS);
}
