import AModel from '@models/model.abstract';

export default class EtudeNetwork extends AModel {
    private readonly _id: number;
    private _name!: string;
    private _nbBiensActifsEnvoyes!: number;
    private _numberDaysBeforeAutoDiffusion!: number;
    private _urlLogo!: string;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get nbBiensActifsEnvoyes(): number {
        return this._nbBiensActifsEnvoyes;
    }

    set nbBiensActifsEnvoyes(value: number) {
        this._nbBiensActifsEnvoyes = value;
    }

    get numberDaysBeforeAutoDiffusion(): number {
        return this._numberDaysBeforeAutoDiffusion;
    }

    set numberDaysBeforeAutoDiffusion(value: number) {
        this._numberDaysBeforeAutoDiffusion = value;
    }

    get urlLogo(): string {
        return this._urlLogo;
    }

    set urlLogo(value: string) {
        this._urlLogo = value;
    }
}
