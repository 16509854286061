import {NgModule} from '@angular/core';
import {ConfigurationActionsMainComponent} from '@features/configuration/actions/configuration-actions-main.component';
import {CommonModule} from '@angular/common';
import {
    ConfigurationActionsMainDropdownComponent
} from '@features/configuration/actions/dropdown/configuration-actions-main.dropdown.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [ConfigurationActionsMainComponent, ConfigurationActionsMainDropdownComponent],
    imports: [CommonModule, SharedModule],
})
export class ConfigurationModule {
}
