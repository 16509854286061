import {inject, Injectable} from '@angular/core';
import {ArrondissementFactory} from '@models/arrondissements/arrondissement/arrondissement.factory';
import {ArrondissementsApiService} from '@models/arrondissements/arrondissements.api.service';
import CArrondissements from '@models/arrondissements/collection/arrondissements.collection.model';
import {Observable} from 'rxjs';
import {
    ICArrondissementsQueryParameters, ICArrondissementsApi
} from '@models/arrondissements/collection/arrondissements.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CArrondissementsFactory {
    private _arrondissementFactory = inject(ArrondissementFactory);
    private _arrondissementsApiService = inject(ArrondissementsApiService);

    create(cArrondissementsApi: ICArrondissementsApi): CArrondissements {
        const cArrondissements = this.createVirgin();

        cArrondissements.links = new Links(cArrondissementsApi._links);
        cArrondissements.page = cArrondissementsApi.page;
        cArrondissements.pages = cArrondissementsApi.pages;
        cArrondissements.perPage = cArrondissementsApi.limit;
        cArrondissements.total = cArrondissementsApi.total;
        if (cArrondissementsApi._embedded?.items) {
            cArrondissementsApi._embedded.items.filter(arrondissementApi => arrondissementApi)
                .map(arrondissementApi => cArrondissements.results.push(this._arrondissementFactory.create(arrondissementApi)));
        }

        return cArrondissements;
    }

    createVirgin(): CArrondissements {
        const cArrondissements = new CArrondissements();

        cArrondissements.links = new Links();

        return cArrondissements;
    }

    get$(queryParameters?: ICArrondissementsQueryParameters): Observable<CArrondissements> {
        return this._arrondissementsApiService.getCollection$(queryParameters).pipe(map(cArrondissementsApi => this.create(cArrondissementsApi)));
    }
}
