import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getAuthAuthenticationService from "@legacy/app/auth/services/authentication";
import getAuthTemplates from "@legacy/app/auth/templates";
import getAuthLoginCtrl from "@legacy/app/auth/controllers_ponents/login";
import getAuthResetCtrl from "@legacy/app/auth/controllers_ponents/reset";
import getAuthStartCtrl from "@legacy/app/auth/controllers_ponents/start";
import getAuthDataInit from "@legacy/app/auth/services/data-init";
import getAuthLogoutCtrl from '@legacy/app/auth/controllers_ponents/logout';

export default function getAuth(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.auth', ['esk.client', 'esk.managers', 'pascalprecht.translate', 'soqrate', 'esk.biens']);

        // Config / Run
        getAuthTemplates(module);

        // Services
        getAuthAuthenticationService(module);
        getAuthDataInit(module);

        // Controllers
        getAuthLoginCtrl(module);
        getAuthLogoutCtrl(module);
        getAuthResetCtrl(module);
        getAuthStartCtrl(module);

        return module;
    })(angularJS);
}
