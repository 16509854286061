@if (estimationOnsaleReference$ | async; as estimationOnsaleReference) {
  <div class="tw-flex tw-h-full tw-flex-col tw-divide-y tw-divide-gray-200 tw-bg-white">
    <div class="tw-bg-gray-50 tw-px-4 tw-py-6 sm:tw-px-6">
      <h2 class="tw-mr-8 tw-text-base tw-font-semibold tw-text-gray-900">
        En vente depuis le {{ estimationOnsaleReference.publishedAt | date:'longDate' }}
      </h2>
    </div>

    <div class="tw-min-h-0 tw-flex-1 tw-overflow-y-auto">
      <app-estimation-onsale-reference-photo [estimationOnsaleReference]="estimationOnsaleReference"
                                             class="tw-block tw-aspect-photo"/>

      <div class="tw-p-8 tw-divide-y tw-divide-gray-200 tw-space-y-8 tw-text-sm">
        <div class="tw-space-y-4 tw-text-base tw-text-gray-700">
          <div>
            <span class="tw-font-medium tw-text-gray-900">{{ nature.label }}</span> à
            <span class="tw-font-medium tw-text-gray-900">
              <app-commune-convivial-name [linkCommune]="estimationOnsaleReference.linkCommune"/>
            </span>
          </div>

          <div>{{ estimationOnsaleReference.adresse }}</div>

          <div class="tw-text-sm">
            Annonce présente sur
            <a href="{{estimationOnsaleReference.url}}" target="_blank">
              {{ hostname }}<fa-icon [icon]="'external-link-alt'"/>
            </a>
          </div>
        </div>

        @if (estimationOnsaleReference.nombrePieces) {
          <div class="tw-flex tw-pt-8 tw-text-gray-700 tw-space-x-4 sm:tw-space-x-8">
            @if (estimationOnsaleReference.nombrePieces) {
              <div [ngPlural]="estimationOnsaleReference.nombrePieces">
                {{ estimationOnsaleReference.nombrePieces }}
                <ng-template ngPluralCase="=1">Pièce</ng-template>
                <ng-template ngPluralCase="other">Pièces</ng-template>
              </div>
            }
          </div>
        }

        <div class="tw-space-y-4 tw-pt-8">
          @if (estimationOnsaleReference.surfaceCadastrale) {
            <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <div>Surface cadastrale</div>

              <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0 tw-text-base tw-text-gray-700">
                {{ estimationOnsaleReference.surfaceCadastrale | textCurrency:'m²' }}
              </div>
            </div>
          }

          @if (estimationOnsaleReference.superficie) {
            <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <div>{{ superficieLabel }}</div>

              <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0 tw-text-base tw-text-gray-700">
                {{ estimationOnsaleReference.superficie | textCurrency:'m²' }}
              </div>
            </div>
          }

          @if (estimationOnsaleReference.prixVente) {
            <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
              <div>Prix de vente</div>

              <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0">
                <div class="tw-text-base tw-text-gray-700">{{ estimationOnsaleReference.prixVente | textCurrency }}
                </div>

                <div>soit {{ estimationOnsaleReference.prixM2 | textCurrency }} du m²</div>
              </div>
            </div>
          }
        </div>

        <div [innerHTML]="estimationOnsaleReference.description | textNl2br" class="tw-pt-8"></div>
      </div>
    </div>
  </div>
} @else {
  <div class="tw-px-4 tw-py-6 sm:tw-px-6">
    <div class="tw-mb-10 tw-pr-12"><app-loader [classHeight]="'tw-h-6'"/></div>

    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
  </div>
}
