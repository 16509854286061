import {Injectable, Type} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
    IDynamicComponent, IDynamicComponentData, IDynamicComponentLoading
} from '@shared/shared.interfaces';

@Injectable({providedIn: 'root'})
export class SlideOverService {
    private _dynamicComponentLoadingSource = new Subject<IDynamicComponentLoading>();
    private _dynamicComponentLoading$ = this._dynamicComponentLoadingSource.asObservable();
    private _isOpenSource = new BehaviorSubject<boolean>(false);
    private _isOpen$ = this._isOpenSource.asObservable();
    private _responseSource = new Subject<unknown>();

    get isOpen$(): Observable<boolean> {
        return this._isOpen$;
    }

    get dynamicComponentLoading$(): Observable<IDynamicComponentLoading> {
        return this._dynamicComponentLoading$;
    }

    open$<T>(component: Type<IDynamicComponent>, data?: unknown): Observable<T> {
        // IMPORTANT de conserver cet ordre d'exécution afin que tout soit initialisé avant l'ouverture
        this._dynamicComponentLoadingSource.next({component, data: data as IDynamicComponentData});
        this._isOpenSource.next(true);

        return this._responseSource.asObservable() as Observable<T>;
    }

    setResponse(response?: unknown): void {
        // IMPORTANT de conserver cet ordre d'exécution afin que l'utilisateur ne puisse pas faire une autre action
        this._isOpenSource.next(false);
        this._responseSource.next(response);
    }
}
