import {Injectable} from '@angular/core';
import TemplateCategory from '@models/templates/template/category/template-category.model';
import {ITemplateCategoryApi} from '@models/templates/template/category/template-category.interfaces';

@Injectable({providedIn: 'root'})
export class TemplateCategoryFactory {
    create(templateCategoryApi: ITemplateCategoryApi): TemplateCategory {
        const templateCategory = new TemplateCategory(templateCategoryApi.id.toString(), templateCategoryApi.id);

        templateCategory.code = templateCategoryApi.code;

        return templateCategory;
    }
}
