import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {NgContactManager} from '@legacy/app/managers/managers';
import {NgContact} from '@legacy/app/managers/ressources';

export default function getContactsSearch(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contacts-search></esk-contacts-search>
         * @param options : {dataValidation(Function)}
         */
        module.component('eskContactsSearch', {
            bindings: {
                contactsSelected: '=?',
                membersSelected: '=?',
                onSelect: '=',
                options: '=?'
            },
            controller: Controller,
            templateUrl: "src/app/legacy/templates/contacts/components/search.html",
        });

        /**
         * Search Contact Component Controller
         *
         * @param ContactManager
         * @constructor
         */
        Controller.$inject = ['ContactManager'];
        function Controller(this: any, contactManager: NgContactManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.refresh = refresh;
            $ctrl.disabledMember = disabledMember;

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};

                $ctrl.contacts = [];
            }

            /**
             * Refresh Contacts
             *
             * @param keywords
             * @return {Promise}
             */
            function refresh(keywords: string) {
                return contactManager.getAllForEtude({keywords}).then(function (contactsPaginator) {
                    $ctrl.contacts = angular.isFunction($ctrl.options.dataValidation) ? $ctrl.options.dataValidation(contactsPaginator.collection) : contactsPaginator.collection;
                });
            }

            /**
             * Check if member is already in contactGroup
             *
             * @return boolean
             */
            function disabledMember(contact: NgContact) {
                if (angular.isArray($ctrl.contactsSelected)) {
                    return !!find($ctrl.contactsSelected, {id: contact.id});
                } else if (angular.isArray($ctrl.membersSelected)) {
                    return !!find($ctrl.membersSelected, {contact: {id: contact.id}});
                }

                return false;
            }
        }
    })(angularJS);
}
