import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, inject, Injectable} from '@angular/core';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {ErrorSentryService} from '@core/error/error.sentry.service';
import {Extras} from '@sentry/types';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    private _errorSentryService = inject(ErrorSentryService);
    private _errorConfigurationService = inject(ErrorConfigurationService);

    handleError(error: unknown): void {
        this.catchError(error);
    }

    catchError(error: unknown): void {
        if (!this._errorConfigurationService.isErrorIgnored(error)) {
            this._errorSentryService.captureException(this._extractError(error), error as Extras);
        }

        console.error(error);
    }

    private _extractError(error: unknown): Error | ErrorEvent {
        if (typeof error === 'string') {
            return new Error(error);
        }

        if (error instanceof Error) {
            return error;
        }

        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof Error || error.error instanceof ErrorEvent) {
                return error.error;
            }

            if (typeof error.error === 'string') {
                return new Error(`Server returned code ${error.status.toString()} with body "${error.error}"`);
            }

            return new Error(error.message);
        }

        return new Error('Handled unknown error');
    }
}
