import Person from '@core/models/contacts/person/person.model';
import User from '@models/users/user/user.model';

export default class Participant {
    static types = {PERSON: 'person', USER: 'user'};
    private _convivialName!: string;
    private _emailAddress!: string;
    private _nom!: string;
    private _person!: Person;
    private _prenom!: string;
    private _telephone!: string;
    private _type!: string;
    private _user!: User;

    get convivialName(): string {
        return this._convivialName;
    }

    set data(value: Person | User | undefined) {
        this._person = undefined!;
        this._type = undefined!;
        this._user = undefined!;
        if (value instanceof Person) {
            this._person = value;
            this._type = Participant.types.PERSON;
        } else if (value instanceof User) {
            this._user = value;
            this._type = Participant.types.USER;
        }
    }

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
        this.setConvivialName();
    }

    get person(): Person {
        return this._person ;
    }

    get prenom(): string {
        return this._prenom;
    }

    set prenom(value: string) {
        this._prenom = value;
        this.setConvivialName();
    }

    get telephone(): string {
        return this._telephone;
    }

    set telephone(value: string) {
        this._telephone = value;
    }

    get user(): User {
        return this._user ;
    }

    isPerson(): boolean {
        return this._type === Participant.types.PERSON;
    }

    isUser(): boolean {
        return this._type === Participant.types.USER;
    }

    setConvivialName(): void {
        const convivialName: string[] = [];

        if (this.prenom) {
            convivialName.push(this.prenom);
        }

        if (this.nom) {
            convivialName.push(this.nom);
        }

        this._convivialName = convivialName.join(' ');
    }
}
