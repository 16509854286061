import angularJS from '@shared/angularJS/global.ng';
import {filter} from "lodash";
import {IModule, IParseService} from 'angular';
import {NgFieldConstraints} from '@legacy/app/biens/biens';
import {NgBienDossier} from '@legacy/app/managers/ressources';
import ParserService from '@shared/parser/parser.service';

export default function getBienFieldsConstraints(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienFieldsConstraints', Factory);

        /**
         * Rules for field visibility about bien
         *
         * @param $parse
         * @param Ng2ParserService
         */
        Factory.$inject = ['$parse', 'Ng2ParserService'];
        function Factory($parse: IParseService, ng2ParserService: ParserService) {
            let fieldsConstraints: Record<string, NgFieldConstraints>;
            const factory = {
                set: set,
                get: get,
                getConstraintsForNature: getConstraintsForNature,
                hasConstraintsForNature: hasConstraintsForNature,
                addCallback: addCallback,
                executeCallbacks: executeCallbacks,
                razCallbacks: razCallbacks,
                setTrueIsValid: setTrueIsValid,
                setIsValid: setIsValid,
                getFieldsInvalid: getFieldsInvalid,
            };

            return factory;

            /**
             * Set fieldsConstraints
             *
             * @param _fieldsConstraints_
             */
            function set(_fieldsConstraints_: { expression: string; message: string; name: string; natures: string[], path: string }[]) {
                fieldsConstraints = {};
                _fieldsConstraints_.map(constraint => {
                    // @todo Voir si l'API a fait le ménage
                    if (['statutDpe', 'valeurCE', 'valeurGES'].includes(constraint.name)) {
                        return;
                    }

                    const sanitizedFieldConstraints = {
                        constraints: [],
                        path: constraint.path
                    } as unknown as NgFieldConstraints;

                    if (angular.isString(constraint.expression) && constraint.expression !== '') {
                        sanitizedFieldConstraints.constraints.push({
                            expression: constraint.expression.replace(/.count\(\)/gi, '|count'),
                            message: constraint.message,
                            natures: constraint.natures
                        });
                    }

                    if (sanitizedFieldConstraints.constraints.length > 0) {
                        fieldsConstraints[constraint.name] = sanitizedFieldConstraints;
                    }
                });
            }

            /**
             * Get fieldsConstraints
             *
             * @return fieldsConstraints
             */
            function get() {
                return fieldsConstraints;
            }

            /**
             * Get constraints for nature
             *
             * @param field
             * @param natureCode
             * @returns Array
             */
            function getConstraintsForNature(field: string, natureCode: string): { expression: string; message: string }[] {
                const constraints = [] as { expression: string; message: string }[];

                if (!angular.isObject(fieldsConstraints) || !angular.isObject(fieldsConstraints[field])) {
                    return constraints;
                }

                angular.forEach(fieldsConstraints[field].constraints, (constraint) => {
                    if (constraint.natures.includes(natureCode)) {
                        constraints.push(constraint);
                    }
                });

                return constraints;
            }

            /**
             * Has constraints for field
             *
             * @param field
             * @param natureCode
             * @returns {boolean}
             */
            function hasConstraintsForNature(field: string, natureCode: string) {
                return factory.getConstraintsForNature(field, natureCode).length > 0;
            }

            /**
             * Add callback to fieldConstraints
             *
             * @param field
             * @param callback
             */
            function addCallback(field: string, callback: unknown) {
                if (!angular.isObject(fieldsConstraints) || !angular.isObject(fieldsConstraints[field])) return;

                fieldsConstraints[field].callback = callback;
            }

            /**
             * Execute callbacks
             */
            function executeCallbacks() {
                angular.forEach(fieldsConstraints, function (fieldConstraints) {
                    if (angular.isFunction(fieldConstraints.callback)) {
                        fieldConstraints.callback();
                    }
                });
            }

            /**
             * Raz callbacks
             */
            function razCallbacks() {
                angular.forEach(fieldsConstraints, function (fieldConstraints) {
                    delete fieldConstraints.callback;
                });
            }

            /**
             * Set isValid to true
             */
            function setTrueIsValid() {
                angular.forEach(fieldsConstraints, fieldConstraints => {
                    fieldConstraints.isValid = true;
                    fieldConstraints.message = undefined!;
                });
            }

            /**
             * Set isValid on fieldConstraints
             *
             * @param field
             * @param dossier
             */
            function setIsValid(field: string, dossier: NgBienDossier) {
                let value: unknown;

                if (!angular.isObject(fieldsConstraints) || !angular.isObject(fieldsConstraints[field])) return;

                value = $parse(fieldsConstraints[field].path)(dossier);
                fieldsConstraints[field].isValid = true;
                fieldsConstraints[field].message = undefined!;
                angular.forEach(factory.getConstraintsForNature(field, dossier.bien.nature.code), constraint => {
                    if (!ng2ParserService.evalSync(constraint.expression, {this: dossier, value})) {
                        fieldsConstraints[field].message = constraint.message;
                        fieldsConstraints[field].isValid = false;
                    }
                });
            }

            /**
             * Get fields invalid
             *
             * @return [Object]
             */
            function getFieldsInvalid() {
                return filter(fieldsConstraints, {isValid: false});
            }
        }
    })(angularJS);
}
