export default `
<!-- Groupe de contacts -->
<fieldset>
  <legend>Demandeurs</legend>

  <esk-dossier-contacts-group-edit edit-form="$ctrl.editForm" changed="$ctrl.onGroupCreated">
  </esk-dossier-contacts-group-edit>
</fieldset>

<!-- Dossier -->
<fieldset>
  <legend>Dossier</legend>

  <esk-demandeur-information-edit options="{envoiResults: {hide: $ctrl.demandeur.contactsGroup.getRecipients().length <= 0}}"
                                edit-form="$ctrl.editForm" demandeur="$ctrl.demandeur"></esk-demandeur-information-edit>
</fieldset>

<!-- Étude de financement -->
<fieldset ng-if="$ctrl.demandeur.recherches[0].type === $ctrl.DossierBienTypesConst.VENTE">
  <legend>Étude de financement</legend>

  <div class="row">
    <!-- Provenance des fonds -->
    <div class="col-xs-12 col-sm-4 col-md-3 margin-bottom-10">
      <label>Provenance des fonds</label>

      <esk-select-dictionary ng-model="$ctrl.demandeur.financementProvenanceFonds"
                             dictionary="{{$ctrl.FINANCEMENT_FONDS_PROVENANCES}}"></esk-select-dictionary>
    </div>

    <!-- Statut -->
    <div ng-if="$ctrl.demandeur.financementProvenanceFonds.code === 'financement'"
         class="col-xs-12 col-sm-4 col-md-3 margin-bottom-10">
      <label>Statut</label>

      <esk-select-dictionary ng-model="$ctrl.demandeur.financementStatut" dictionary="{{$ctrl.FINANCEMENT_STATUTS}}">
      </esk-select-dictionary>
    </div>

    <!-- Partenaire -->
    <div ng-if="$ctrl.demandeur.financementProvenanceFonds.code === 'financement'"
         class="col-xs-12 col-sm-4 col-md-3 margin-bottom-10">
      <div class="form-group">
        <label for="demandeur-financement-partenaire">Partenaire</label>

        <input type="text" id="demandeur-financement-partenaire" class="form-control"
               ng-model="$ctrl.demandeur.financementPartenaire">
      </div>
    </div>

    <!-- Commentaires -->
    <div class="col-xs-12">
      <div class="form-group">
        <label>Commentaires</label>

        <app-ng2-textarea [placeholder]="'Saisir les informations complémentaires'"
                          [text]="$ctrl.demandeur.financementCommentaires"
                          (changed)="$ctrl.demandeur.financementCommentaires = $event"></app-ng2-textarea>
      </div>
    </div>
  </div>
</fieldset>
`;
