export default `
<ng-switch on="!!$ctrl.demandeur.metadata.confidentielles.comments" class="text-justify">
  <p ng-switch-when="false">
    Aucun renseignement confidentiel
  </p>

  <p ng-switch-default>
    {{$ctrl.demandeur.metadata.confidentielles.comments}}
  </p>
</ng-switch>
`;
