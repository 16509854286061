import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import addContactsHelpTemplate from '@legacy/templates/eskimmo/components/actions/dossiers/add-contacts-help';
import actionsAttachmentsTemplate from '@legacy/templates/eskimmo/components/actions/attachments';
import actionsDossiersTemplate from '@legacy/templates/eskimmo/components/actions/dossiers';
import boxActivityTemplate from '@legacy/templates/eskimmo/components/box/activity';
import boxMeteoTemplate from '@legacy/templates/eskimmo/components/box/meteo';
import boxNegoRevenusTemplate from '@legacy/templates/eskimmo/components/box/negociation-revenus';
import boxShortcutsTemplate from '@legacy/templates/eskimmo/components/box/shortcuts';
import buttonPrintTemplate from '@legacy/templates/eskimmo/components/button/print';
import buttonSaveTemplate from '@legacy/templates/eskimmo/components/button/save';
import buttonSortTemplate from '@legacy/templates/eskimmo/components/button/sort';
import configFacebookHelpTemplate from '@legacy/templates/eskimmo/components/configuration/facebook/help';
import configFacebookTemplate from '@legacy/templates/eskimmo/components/configuration/facebook';
import dossierContactsGroupEditTemplate from '@legacy/templates/eskimmo/components/dossier/contacts-group.edit';
import dossierSuiviTemplate from '@legacy/templates/eskimmo/components/dossier/suivi';
import listSelectTemplate from '@legacy/templates/eskimmo/components/list/select';
import searchCriteriaGeneralTemplate from '@legacy/templates/eskimmo/components/search/criteria/general';
import searchCriteriaSecondaryTemplate from '@legacy/templates/eskimmo/components/search/criteria/secondary';
import searchBarreTemplate from '@legacy/templates/eskimmo/components/search/barre';
import searchCriteriaTemplate from '@legacy/templates/eskimmo/components/search/criteria';
import searchKeywordsTemplate from '@legacy/templates/eskimmo/components/search/keywords';
import selectCommuneTemplate from '@legacy/templates/eskimmo/components/select/commune';
import selectDateTemplate from '@legacy/templates/eskimmo/components/select/date';
import selectDictionaryMultiTemplate from '@legacy/templates/eskimmo/components/select/dictionary-multiple';
import selectDictionarySimpleTemplate from '@legacy/templates/eskimmo/components/select/dictionary-simple';
import selectDossierTemplate from '@legacy/templates/eskimmo/components/select/dossier';
import selectEtudeTemplate from '@legacy/templates/eskimmo/components/select/etude';
import selectLocalisationTemplate from '@legacy/templates/eskimmo/components/select/localisation';
import selectParticipantTemplate from '@legacy/templates/eskimmo/components/select/participant';
import selectQuartierTemplate from '@legacy/templates/eskimmo/components/select/quartier';
import selectRecipientTemplate from '@legacy/templates/eskimmo/components/select/recipient';
import selectSortTemplate from '@legacy/templates/eskimmo/components/select/sort';
import selectTemplateTemplate from '@legacy/templates/eskimmo/components/select/template';
import selectTemplateThumbnailTemplate from '@legacy/templates/eskimmo/components/select/template-thumbnails';
import selectUserTemplate from '@legacy/templates/eskimmo/components/select/user';
import selectVoieTemplate from '@legacy/templates/eskimmo/components/select/voie';
import uploadErrorTemplate from '@legacy/templates/eskimmo/components/upload-files-info/errors';
import chartNegoRevenusTemplate from '@legacy/templates/eskimmo/components/chart.negociation-revenus';
import taskRunnerTemplate from '@legacy/templates/eskimmo/components/task-runner';
import contactTemplate from '@legacy/templates/eskimmo/components/contact';
import footerTemplate from '@legacy/templates/eskimmo/components/footer';
import meteoTemplate from '@legacy/templates/eskimmo/components/meteo-widget';
import paginationTemplate from '@legacy/templates/eskimmo/components/pagination';
import passerelleContratTemplate from '@legacy/templates/eskimmo/components/passerelle.contrat';
import selectMultipleTemplate from '@legacy/templates/eskimmo/components/select-multiple';
import selectSimpleTemplate from '@legacy/templates/eskimmo/components/select-simple';
import uploadFilesInfoTemplate from '@legacy/templates/eskimmo/components/upload-files-info';
import configDashboardTemplate from '@legacy/templates/eskimmo/controllers/configuration/dashboard';
import configPasserellesTemplate from '@legacy/templates/eskimmo/controllers/configuration/passerelles';
import docAdvertisementTemplate from '@legacy/templates/eskimmo/controllers/documents/advertisement';
import docBlankTemplate from '@legacy/templates/eskimmo/controllers/documents/blank';
import dossierEmailTemplate from '@legacy/templates/eskimmo/controllers/dossier/suivi/email';
import dossierEmailPopoverTemplate from '@legacy/templates/eskimmo/controllers/dossier/suivi/email-popover';
import dossierReportTemplate from '@legacy/templates/eskimmo/controllers/dossier/suivi/report';
import dossierReportPopoverTemplate from '@legacy/templates/eskimmo/controllers/dossier/suivi/report-popover';
import layoutNavbarTaskTemplate from '@legacy/templates/eskimmo/controllers/layout/navbar/task-runner';
import modalCalculatorTemplate from '@legacy/templates/eskimmo/controllers/modals/calculator';
import modalChangePWDTemplate from '@legacy/templates/eskimmo/controllers/modals/change.password';
import modalConfigFBTemplate from '@legacy/templates/eskimmo/controllers/modals/configuration.facebook';
import modalEditPhotoTemplate from '@legacy/templates/eskimmo/controllers/modals/edit.photo';
import modalEmailTemplate from '@legacy/templates/eskimmo/controllers/modals/email';
import modalImpersonationTemplate from '@legacy/templates/eskimmo/controllers/modals/impersonation';
import modalProcedureTemplate from '@legacy/templates/eskimmo/controllers/modals/procedure';
import modalReportTemplate from '@legacy/templates/eskimmo/controllers/modals/report';
import myAccountHelpTemplate from '@legacy/templates/eskimmo/controllers/my-account/photo.help';
import negociationRevenusTemplate from '@legacy/templates/eskimmo/controllers/negociation/revenus';
import browserTemplate from '@legacy/templates/eskimmo/controllers/browser';
import dashboardTemplate from '@legacy/templates/eskimmo/controllers/dashboard';
import myAccountTemplate from '@legacy/templates/eskimmo/controllers/my-account';
import carteQuartiersTemplate from '@legacy/templates/eskimmo/directives/carte-quartiers';
import cropperTemplate from '@legacy/templates/eskimmo/directives/cropper';

export default function getEskTemplates(module: IModule): void {
    (angular => {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/eskimmo/components/actions/dossiers/add-contacts-help.html', addContactsHelpTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/actions/attachments.html', actionsAttachmentsTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/actions/dossiers.html', actionsDossiersTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/activity.html', boxActivityTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/meteo.html', boxMeteoTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/negociation-revenus.html', boxNegoRevenusTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/shortcuts.html', boxShortcutsTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/button/print.html', buttonPrintTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/button/save.html', buttonSaveTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/button/sort.html', buttonSortTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/configuration/facebook/help.html', configFacebookHelpTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/configuration/facebook.html', configFacebookTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/dossier/contacts-group.edit.html', dossierContactsGroupEditTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/dossier/suivi.html', dossierSuiviTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/list/select.html', listSelectTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/search/criteria/general.html', searchCriteriaGeneralTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/search/criteria/secondary.html', searchCriteriaSecondaryTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/search/barre.html', searchBarreTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/search/criteria.html', searchCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/search/keywords.html', searchKeywordsTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/commune.html', selectCommuneTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/date.html', selectDateTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/dictionary-multiple.html', selectDictionaryMultiTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/dictionary-simple.html', selectDictionarySimpleTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/dossier.html', selectDossierTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/etude.html', selectEtudeTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/localisation.html', selectLocalisationTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/participant.html', selectParticipantTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/quartier.html', selectQuartierTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/recipient.html', selectRecipientTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/sort.html', selectSortTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/template.html', selectTemplateTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/template-thumbnails.html', selectTemplateThumbnailTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/user.html', selectUserTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select/voie.html', selectVoieTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/upload-files-info/errors.html', uploadErrorTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/chart.negociation-revenus.html', chartNegoRevenusTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/contact.html', contactTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/footer.html', footerTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/meteo-widget.html', meteoTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/pagination.html', paginationTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/passerelle.contrat.html', passerelleContratTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select-multiple.html', selectMultipleTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/select-simple.html', selectSimpleTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/task-runner.html', taskRunnerTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/components/upload-files-info.html', uploadFilesInfoTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/configuration/dashboard.html', configDashboardTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/configuration/passerelles.html', configPasserellesTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/documents/advertisement.html', docAdvertisementTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/documents/blank.html', docBlankTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/dossier/suivi/email.html', dossierEmailTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/dossier/suivi/email-popover.html', dossierEmailPopoverTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/dossier/suivi/report.html', dossierReportTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/dossier/suivi/report-popover.html', dossierReportPopoverTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/layout/navbar/task-runner.html', layoutNavbarTaskTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/calculator.html', modalCalculatorTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/change.password.html', modalChangePWDTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/configuration.facebook.html', modalConfigFBTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/edit.photo.html', modalEditPhotoTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/email.html', modalEmailTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/impersonation.html', modalImpersonationTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/procedure.html', modalProcedureTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/modals/report.html', modalReportTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/my-account/photo.help.html', myAccountHelpTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/negociation/revenus.html', negociationRevenusTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/browser.html', browserTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/dashboard.html', dashboardTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/controllers/my-account.html', myAccountTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/directives/carte-quartiers.html', carteQuartiersTemplate);
            $templateCache.put('src/app/legacy/templates/eskimmo/directives/cropper.html', cropperTemplate);
        }
    })(angularJS);
}
