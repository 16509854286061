import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';
import {FeaturesModule} from '@features/features.module';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import User from '@models/users/user/user.model';
import {AppLayoutDiffusionsComponent} from '@core/routing/layout/diffusions/layout-diffusions.component';
import {
    AppLayoutDiffusionsPasserelleLocationsComponent
} from '@core/routing/layout/diffusions/passerelles/passerelle/locations/layout-diffusions-passerelle-locations.component';
import {
    AppLayoutDiffusionsPasserelleComponent
} from '@core/routing/layout/diffusions/passerelles/passerelle/layout-diffusions-passerelle.component';
import {
    AppLayoutDiffusionsPasserelleVentesComponent
} from '@core/routing/layout/diffusions/passerelles/passerelle/ventes/layout-diffusions-passerelle-ventes.component';
import {
    AppLayoutDiffusionsPasserellesComponent
} from '@core/routing/layout/diffusions/passerelles/layout-diffusions-passerelles.component';
import {
    VentesPasserellesActionsSelectionComponent
} from '@features/ventes/passerelles/actions/ventes-passerelles.actions-selection.component';
import {
    LocationPasserellesActionsSelectionComponent
} from '@features/locations/location/passerelles/actions/location-passerelles.actions-selection.component';

const routes: Routes = [
    {
        component: AppLayoutDiffusionsComponent,
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {roles: [User.roles.GRP_LOCATION, User.roles.GRP_VENTE]},
        path: '',
        children: [
            {
                component: AppLayoutDiffusionsPasserellesComponent,
                path: 'passerelles',
            },
            {
                component: AppLayoutDiffusionsPasserelleComponent,
                path: 'passerelles/:passerelleId',
                children: [
                    {
                        component: AppLayoutDiffusionsPasserelleLocationsComponent,
                        canActivate: [
                            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                        ],
                        data: {
                            actions: {selection: {component: LocationPasserellesActionsSelectionComponent}},
                            roles: [User.roles.GRP_LOCATION]
                        },
                        path: 'locations',
                    },
                    {
                        component: AppLayoutDiffusionsPasserelleVentesComponent,
                        canActivate: [
                            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                        ],
                        data: {
                            actions: {selection: {component: VentesPasserellesActionsSelectionComponent}},
                            roles: [User.roles.GRP_VENTE],
                        },
                        path: 'ventes',
                    },
                    // Wildcard pour /app/diffusions/:passerelleId/*
                    {path: '**', pathMatch: 'full', redirectTo: 'ventes'},
                ],
            },

            // Wildcard pour /app/diffusions/*
            {path: '**', pathMatch: 'full', redirectTo: 'passerelles'},
        ],
    },
];

@NgModule({
    declarations: [

        AppLayoutDiffusionsPasserelleComponent,
        AppLayoutDiffusionsPasserelleLocationsComponent,
        AppLayoutDiffusionsPasserellesComponent,
        AppLayoutDiffusionsPasserelleVentesComponent,
        AppLayoutDiffusionsComponent,
    ],
    imports: [CommonModule, EskimmoModule, FeaturesModule, RouterModule.forChild(routes), SharedModule],
})
export class DiffusionsRoutingModule {
}
