import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICInotUsersApi} from '@models/inot-users/collection/inot-users.collection.interfaces';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InotUsersApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(): Observable<ICInotUsersApi> {
        return this._modelApiService.getCollection$<ICInotUsersApi>('/inot/utilisateurs').pipe(tap(cInotUsersApi => {
            if (cInotUsersApi._embedded?.items) {
                cInotUsersApi.total = cInotUsersApi._embedded.items.length;
            }
        }));
    }
}
