import {inject, Injectable} from '@angular/core';
import {NgBienDossier, NgEstimation, NgLocation, NgVente} from '@legacy/app/managers/ressources';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable, of} from 'rxjs';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import {IDossierBienDiffusionEtatApi} from '@models/dossiers/biens/bien/dossier-bien.interfaces';
import {IVenteDiffusionEtatApi} from '@models/ventes/vente/vente.interfaces';
import {VirtualVisitService} from '@shared/virtual-visit/virtual-visit.service';
import Etude from '@models/etudes/etude/etude.model';
import {DossierBiensApiService} from '@models/dossiers/biens/dossier-biens.api.service';

@Injectable({providedIn: 'root'})
export class DossierBienFactory {
    private _dossierBiensApiService = inject(DossierBiensApiService);
    private _estimationFactory = inject(EstimationFactory);
    private _locationFactory = inject(LocationFactory);
    private _venteFactory = inject(VenteFactory);
    private _virtualVisitService = inject(VirtualVisitService);

    createFromDiffusionEtat(dossierBienType: DossierBienType, dossierBienDiffusionEtatApi: IDossierBienDiffusionEtatApi): ADossierBien {
        if (dossierBienType === DossierBienTypesConst.LOCATION) {
            return this._locationFactory.createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi);
        } else if (dossierBienType === DossierBienTypesConst.VENTE) {
            return this._venteFactory.createFromVenteDiffusionEtat(dossierBienDiffusionEtatApi as IVenteDiffusionEtatApi);
        }

        return undefined!;
    }

    deleteVirtualVisit$(dossierBien: ADossierBien): Observable<void> {
        return this._dossierBiensApiService.deleteVirtualVisit$(dossierBien.id.toString());
    }

    getByLink$(link: string): Observable<ADossierBien> {
        if (link.search(DossierBienTypesConst.ESTIMATION) > 0) {
            return this._estimationFactory.getByLink$(link);
        } else if (link.search(DossierBienTypesConst.LOCATION) > 0) {
            return this._locationFactory.getByLink$(link);
        } else if (link.search(DossierBienTypesConst.VENTE) > 0) {
            return this._venteFactory.getByLink$(link);
        }

        return of(undefined as unknown as ADossierBien);
    }

    openVirtualVisit$(etude: Etude, dossierBien: ADossierBien): Observable<Window> {
        return this._virtualVisitService.open$(etude.crpcen, dossierBien.reference);
    };

    ngCreate(ngBienDossier: NgBienDossier): ADossierBien {
        if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
            return this._estimationFactory.ngCreate(ngBienDossier as NgEstimation);
        } else if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
            return this._locationFactory.ngCreate(ngBienDossier as NgLocation);
        } else if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
            return this._venteFactory.ngCreate(ngBienDossier as NgVente);
        }

        return undefined!;
    }
}
