import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {
    AppTransferProgressComponent
} from '@features/transfers/transfer/progress/transfer.progress.component';

@NgModule({
    declarations: [AppTransferProgressComponent],
    exports: [AppTransferProgressComponent],
    imports: [SharedModule],
})
export class TransfersModule {
}
