import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
    CLocationPasserellesFactory
} from '@models/locations/location/passerelles/collection/location-passerelles.collection.factory';
import CLocationPasserelles from '@models/locations/location/passerelles/collection/location-passerelles.collection.model';

@Injectable({providedIn: 'root'})
export class CLocationPasserellesService {
    private _cLocationPasserellesFactory = inject(CLocationPasserellesFactory);

    addNext$(cLocationPasserelles: CLocationPasserelles): Observable<CLocationPasserelles> {
        return this._cLocationPasserellesFactory.getNext$(cLocationPasserelles).pipe(
            tap(newCLocationPasserelles => cLocationPasserelles.updateNext(newCLocationPasserelles)),
            map(_ => cLocationPasserelles),
        );
    }
}
