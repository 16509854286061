import angularJS from '@shared/angularJS/global.ng';
import {IDeferred, IIntervalService, IModule, IPromise, IQService, ISCEService, IScope, IWindowService} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {firstValueFrom} from 'rxjs';
import {LocationFactory} from '@models/locations/location/location.factory';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';

export default function getBienEditVirtualVisitCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskBienEditVirtualVisitCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/item/edit/virtual-visit.html',
        });

        /**
         * Controller for template photos.html
         *
         * @param BienDossierCurrent
         * @param $interval
         * @param $q
         * @param $sce
         * @param SoqSweetAlert
         * @param $translate
         * @param $window
         * @param $scope
         * @param Ng2EtudeService
         * @param Ng2LocationFactory
         * @param Ng2DossierBienService
         * @param Ng2DossierBienFactory
         */
        Controller.$inject = ['BienDossierCurrent', '$interval', '$q', '$sce', 'SoqSweetAlert', '$translate', '$window', '$scope', 'Ng2EtudeService', 'Ng2LocationFactory', 'Ng2DossierBienService', 'Ng2DossierBienFactory'];
        function Controller(this: any,
                            bienDossierCurrent: NgBienDossierCurrent,
                            $interval: IIntervalService,
                            $q: IQService,
                            $sce: ISCEService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            $window: IWindowService,
                            $scope: IScope,
                            ng2EtudeService: EtudeService,
                            ng2LocationFactory: LocationFactory,
                            ng2DossierBienService: DossierBienService,
                            ng2DossierBienFactory: DossierBienFactory) {
            const $ctrl = this;
            let deferredOpenVirtualVisit: IDeferred<unknown>;
            let virtualVisitWindow: Window;

            $ctrl.openVirtualVisit = openVirtualVisit;
            $ctrl.removeVirtualVisit = removeVirtualVisit;
            $ctrl.loader = {};
            $ctrl.dossier = bienDossierCurrent.get();
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.etude = currentEtude);
            updateUrlVisiteVirtuelleTrusted();

            /**
             * Remove event listener on destroy
             */
            $scope.$on('$destroy', removeWatchers);

            /**
             * Open virtual visit in new tab
             */
            function openVirtualVisit() {
                if (angular.isDefined($ctrl.watcherWindow)) {
                    virtualVisitWindow.focus();

                    return;
                }

                $ctrl.loader.visitVirtualEditing = true;
                deferredOpenVirtualVisit = $q.defer();
                firstValueFrom<Window>(ng2DossierBienService.openVirtualVisit$(ng2DossierBienFactory.ngCreate($ctrl.dossier))).then(_virtualVisitWindow_ => {
                    virtualVisitWindow = _virtualVisitWindow_;
                    $window.addEventListener('message', receiveMessage);
                    $ctrl.watcherWindow = $interval(function () {
                        if (angular.isDefined($ctrl.watcherWindow) && virtualVisitWindow.closed) {
                            deferredOpenVirtualVisit.resolve($ctrl.dossier.bien.option.urlVisiteVirtuelle);
                        }
                    }, 500);

                    return deferredOpenVirtualVisit.promise.then(urlVisiteVirtuelle => urlVisiteVirtuelle as string).then(urlVisiteVirtuelle => {
                        let promise: IPromise<unknown>;

                        $ctrl.loader.dossierUpdating = true;
                        $ctrl.dossier.bien.option.urlVisiteVirtuelle = urlVisiteVirtuelle;
                        updateUrlVisiteVirtuelleTrusted();
                        virtualVisitWindow.close();
                        removeWatchers();
                        if ($ctrl.dossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
                            promise = firstValueFrom(ng2LocationFactory.save$(ng2LocationFactory.ngCreate($ctrl.dossier)))
                        } else {
                            promise = $ctrl.dossier.save();
                        }

                        return promise.finally(() => $ctrl.loader.dossierUpdating = false);
                    });
                }).finally(() => $ctrl.loader.visitVirtualEditing = false);
            }

            /**
             * Remove virtual visit
             */
            function removeVirtualVisit() {
                soqSweetAlert.warningConfirmation($translate.instant('bien.virtual_visit.remove.TITLE'), $translate.instant('bien.virtual_visit.remove.MESSAGE'), {
                    confirmButtonText: $translate.instant('global.label.REMOVE')
                }).then(() => {
                    $ctrl.loader.visitVirtualRemoving = true;

                    return firstValueFrom(ng2DossierBienFactory.deleteVirtualVisit$(ng2DossierBienFactory.ngCreate($ctrl.dossier))).then(() => {
                        $ctrl.dossier.bien.option.urlVisiteVirtuelle = null;
                        updateUrlVisiteVirtuelleTrusted();
                    }).finally(function () {
                        $ctrl.loader.visitVirtualRemoving = false;
                    });
                });
            }

            /**
             * Update trusted URL of virtual visit
             */
            function updateUrlVisiteVirtuelleTrusted() {
                $ctrl.urlVisiteVirtuelleTrusted = $sce.trustAsResourceUrl($ctrl.dossier.bien.option.urlVisiteVirtuelle);
            }

            /**
             * Receive message
             *
             * @param event
             */
            function receiveMessage(event: { data: string, origin: string }) {
                if (event.origin !== $window.origin) return;

                deferredOpenVirtualVisit.resolve(JSON.parse(event.data).bien_previsite_url);
            }

            /**
             * Remove watchers
             */
            function removeWatchers() {
                $window.removeEventListener("message", receiveMessage);
                $interval.cancel($ctrl.watcherWindow);
                $ctrl.watcherWindow = undefined;
                virtualVisitWindow = undefined!;
            }
        }
    })(angularJS);
}
