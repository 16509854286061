import {inject, Injectable} from '@angular/core';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BonvisitesApiService} from '@models/bonvisites/bonvisites.api.service';
import {IBonvisiteApi} from '@models/bonvisites/bonvisite/bonvisite.interfaces';
import DateFormat from '@shared/date/date.format';
import Template from '@models/templates/template/template.model';

@Injectable({providedIn: 'root'})
export class BonvisiteFactory {
    private _bonvisitesApiService = inject(BonvisitesApiService);

    archive$(bonvisite: Bonvisite): Observable<void> {
        return this._bonvisitesApiService.archive$(bonvisite.uuid);
    }

    create(bonvisiteApi: IBonvisiteApi): Bonvisite {
        const bonvisite = this.createVirgin(bonvisiteApi.uuid, bonvisiteApi.id);

        bonvisite.dateVisite = bonvisiteApi.dateVisite;
        bonvisite.demandeurId = bonvisiteApi.demandeurId;
        bonvisite.documentId = bonvisiteApi.documentId;
        bonvisite.statut = bonvisiteApi.statut;
        if (bonvisiteApi._links) {
            if (bonvisiteApi._links.locations) {
                bonvisite.linkBVLocations = bonvisiteApi._links.locations.href;
            }

            if (bonvisiteApi._links.ventes) {
                bonvisite.linkBVVentes = bonvisiteApi._links.ventes.href;
            }

            if (bonvisiteApi._links.demandeur) {
                bonvisite.linkDemandeur = bonvisiteApi._links.demandeur.href;
            }

            if (bonvisiteApi._links.document) {
                bonvisite.linkDocument = bonvisiteApi._links.document.href;
            }

            if (bonvisiteApi._links.procedures) {
                bonvisite.linkProcedures = bonvisiteApi._links.procedures.href;
            }
        }

        return bonvisite;
    }

    createVirgin(uuid?: string, id?: number): Bonvisite {
        const bonvisite = new Bonvisite(uuid ?? Bonvisite.statuts.NEW, id);

        bonvisite.dateVisite = DateFormat.datetimeFromDate();
        bonvisite.statut = Bonvisite.statuts.NEW;

        return bonvisite;
    }

    forApi(bonvisite: Bonvisite): IBonvisiteApi {
        const bonvisiteApi = {} as IBonvisiteApi;

        bonvisiteApi.dateVisite = DateFormat.toAPI(bonvisite.dateVisite);
        bonvisiteApi.demandeurId = bonvisite.demandeurId;
        bonvisiteApi.documentId = bonvisite.documentId;

        return bonvisiteApi;
    }

    get$(uuid: string): Observable<Bonvisite> {
        return this._bonvisitesApiService.get$(uuid).pipe(map(bonvisiteApi => this.create(bonvisiteApi)));
    }

    save$(bonvisite: Bonvisite): Observable<Bonvisite> {
        return this._bonvisitesApiService.save$(bonvisite.uuid, this.forApi(bonvisite)).pipe(map(bonvisiteApi => this.create(bonvisiteApi)));
    }

    sign$(bonvisite: Bonvisite, provider: string): Observable<void> {
        return this._bonvisitesApiService.sign$(bonvisite.uuid, {provider});
    }

    write$(bonvisite: Bonvisite, template: Template): Observable<void> {
        return this._bonvisitesApiService.write$(bonvisite.uuid, {templateId: template.id});
    }
}
