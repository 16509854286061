<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Surface{{ bienSurfaces.length > 1 ? 's' : '' }}</h4>

  <div class="tw-w-full md:tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-3 md:tw-gap-4 tw-space-y-2 sm:tw-space-y-4 md:tw-space-y-0 tw-bg-gray-100 tw-rounded-xl tw-p-2 sm:tw-p-4">
    @for (bienSurface of bienSurfaces; track bienSurface) {
      <div class="tw-space-y-1">
        <div>
          <span class="tw-text-sm tw-text-gray-700 tw-font-medium">
            {{ bienSurface.otherLabel || (bienSurface.piece ? bienSurface.piece.label : 'Type de pièce indéterminé') }}
          </span>

          @if (bienSurface.surface > 0) {
            <span>
              de
              <span class="tw-text-gray-700 tw-font-medium">
                {{ bienSurface.surface | textCurrency:'m²' }}
              </span>

              @if (bienSurface.ponderation > 0) {
                <span>
                  au sol (avec une pondération de
                  <span class="tw-text-gray-700 tw-font-medium">{{ bienSurface.ponderation }}</span>)
                </span>
              }
            </span>
          }

          @if (bienSurface.etage) {
            <span>
              au <span class="tw-text-gray-700 tw-font-medium">{{ bienSurface.etage.label }}</span>
            </span>
          }

          @if (bienSurface.habitable) {
            <span class="tw-text-gray-700 tw-font-medium"> habitable</span>
          }
        </div>

        @if (bienSurface.comments) {
          <div class="tw-pl-2 sm:tw-pl-4">{{ bienSurface.comments }}</div>
        }
      </div>
    }
  </div>
</div>
