import {formatNumber} from '@angular/common';
import {LOCALE_FR_FR} from '@shared/constants';

export default class TextsSizeFormat {
    static convertToConvivial(bytes: number): string {
        if (bytes <= 1) {
            return bytes.toString() + ' Octet';
        }

        const k = 1000;
        const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const cleanValue = bytes / Math.pow(k, i);
        const fractionDigits = (cleanValue % 1 === 0 ? 0 : 2).toString();

        return formatNumber(cleanValue, LOCALE_FR_FR, '0.' + fractionDigits + '-' + fractionDigits) + ' ' + sizes[i];
    }
}
