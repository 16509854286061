export default class EtudeSettingsGenapi {
    private _inotEnabled!: boolean;

    get inotEnabled(): boolean {
        return this._inotEnabled;
    }

    set inotEnabled(value: boolean) {
        this._inotEnabled = value;
    }
}
