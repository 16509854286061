export default `
<div class="wallet">
  <div class="container-fluid container-fluid-title"><h1>{{$ctrl.title}}</h1></div>

  <div class="container-fluid container-fullw tw-bg-white">
    <!-- Actions sur la liste -->
    <div class="row hidden-print">
      <esk-search-barre type="{{$ctrl.options.typeFilter}}" on-change="$ctrl.onChangeFilter"
                        class="col-xs-12 col-md-8 col-lg-9 margin-bottom-15"></esk-search-barre>

      <div class="col-xs-12 col-md-4 col-lg-3 tw-text-center margin-bottom-15">
        <div class="row">
          <app-dossiers-view-choice [route]="$ctrl.filterRoute" [code]="$ctrl.filterCode"
                                    (changed)="$ctrl.changeDisplayMode($event)" class="col-xs-6 tw-text-center">
          </app-dossiers-view-choice>

          <div class="col-xs-6 tw-text-center">
            <a ng-if="$ctrl.typeDossier !== $ctrl.DossierBienTypesConst.VENTE" class="btn btn-azure margin-left-10"
               ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/item/edit/general')">
              <fa-icon [icon]="'plus'"></fa-icon> Nouveau
            </a>

            <div ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.VENTE" class="btn-group" uib-dropdown>
              <button type="button" class="btn btn-azure margin-left-10"
                      ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/item/edit/general')">
                <fa-icon [icon]="'plus'"></fa-icon> Nouveau
              </button>

              <button type="button" class="btn btn-azure" uib-dropdown-toggle><span class="caret"></span></button>

              <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                <li role="menuitem">
                  <button type="button" class="tw-dropdown-link" ng-click="$ctrl.createFromNotestim()">
                    <fa-icon [icon]="'plus'"></fa-icon><span>Depuis Notestim</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- No results -->
      <h4 ng-if="$ctrl.dossiers.totalItems <= 0" class="col-xs-12 margin-top-30 tw-text-center">
        {{'bien.filters.NO_RESULT' | translate: '{typeDossier: "' + $ctrl.typeDossier + '"}'}}
      </h4>
    </div>

    <!-- Liste des dossiers -->
    <div class="row">
      <div class="col-sm-12">
        <esk-ventes-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.VENTE" filter="$ctrl.filter" ventes="$ctrl.dossiers"
                         unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-ventes-list>

        <esk-locations-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.LOCATION" filter="$ctrl.filter" locations="$ctrl.dossiers"
                            unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-locations-list>

        <esk-estimations-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION" filter="$ctrl.filter"
                              estimations="$ctrl.dossiers" unselect-all="$ctrl.unselectAll"
                              options="$ctrl.options"></esk-estimations-list>
      </div>
    </div>

    <!-- Pagination -->
    <div class="row">
      <div class="col-sm-12 tw-text-center">
        <esk-pagination paginator="$ctrl.dossiers" loader="$ctrl.options.loader" on-change="$ctrl.onChangePagination"
                        ng-hide="$ctrl.options.loader.updating"></esk-pagination>
      </div>
    </div>

    <!-- Barre de faire -->
    <div ng-if="$ctrl.getSelectedDossiers().length > 0" class="barre-faire">
      <div class="dossiers-select-label">
        {{'bien.grouped_action.selection.MESSAGE' | translate:'{nbDossiers: ' + $ctrl.getSelectedDossiers().length + '}'}}
      </div>

      <div ng-switch="!!$ctrl.barreFaireLoader" class="dossiers-select-actions">
        <p ng-switch-when="true" class="loading text-large">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.barreFaireLoader | translate}}
        </p>

        <div on="$ctrl.typeDossier" ng-switch-default class="actions">
          <div ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION || $ctrl.typeDossier === $ctrl.DossierBienTypesConst.VENTE"
               class="btn-group dropup" uib-dropdown>
            <button type="button" class="btn btn-dropdown" ng-click="$ctrl.send()">
              <fa-icon [icon]="['far', 'envelope']"></fa-icon>

              <span class="btn-label">{{'mail.action.TITLE' | translate}}</span>
            </button>

            <button type="button" class="btn" uib-dropdown-toggle><span class="caret"></span></button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
              <li role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.send(true)">
                  <fa-icon [icon]="['far', 'envelope']"></fa-icon>

                  <span>{{'mail.action.TITLE_RELANCE' | translate}}</span>
                </button>
              </li>
            </ul>
          </div>

          <button ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.LOCATION" type="button"
                  class="btn btn-dropdown" ng-click="$ctrl.send()">
            <fa-icon [icon]="['far', 'envelope']"></fa-icon> <span>{{'mail.action.TITLE' | translate}}</span>
          </button>

          <esk-button-print ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.LOCATION || $ctrl.typeDossier === $ctrl.DossierBienTypesConst.VENTE"
                            print="$ctrl.print" categories-code="$ctrl.options.categoriesCode"
                            options="{dropDirection: 'dropup'}"></esk-button-print>
        </div>
      </div>
    </div>
  </div>
</div>
`;
