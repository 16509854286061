import {inject, Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {ModalService} from '@shared/modal/modal.service';
import Media from '@models/medias/media/media.model';
import Email from '@models/emails/email/email.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {catchError, map} from 'rxjs/operators';
import {EmailRecipientService} from '@models/emails/email/recipients/recipient/email-recipient.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import Procedure from '@models/procedures/procedure/procedure.model';
import Document from '@models/documents/document/document.model';
import {DossiersService} from '@models/dossiers/dossiers.service';
import Etude from '@models/etudes/etude/etude.model';
import Site from '@models/sites/site/site.model';
import User from '@models/users/user/user.model';
import {EmailService} from '@models/emails/email/email.service';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {EmailRecipientsService} from '@models/emails/email/recipients/email-recipients.service';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class EmailEditService {
    static getEmailFormulePolitesseFromContactsGroup(contactsGroup?: ContactsGroup): string {
        return Email.goodReceptionPolitesse(EmailEditService.getFormulePolitesseFromContactsGroup(contactsGroup));
    }

    static getFormuleIntroductionFromContactsGroup(contactsGroup?: ContactsGroup): string {
        return contactsGroup?.formuleIntroduction ?? ContactsGroup.default.FORMULE_INTRODUCTION;
    }

    static getFormulePolitesseFromContactsGroup(contactsGroup?: ContactsGroup): string {
        return contactsGroup?.formulePolitesse ?? ContactsGroup.default.FORMULE_POLITESSE;
    }

    static getMessageBonvisiteDocument(contactsGroup: ContactsGroup, referenceBiens: string[], isSigned = false): string {
        return EmailEditService.getFormuleIntroductionFromContactsGroup(contactsGroup) +
            ',<br><br>Veuillez trouver ci-joint le bon de visite ' + (isSigned ? 'signé ' : '') +
            (referenceBiens.length > 1 ? 'des biens' : 'du bien') + ' ' + referenceBiens.join(', ') + '.<br>' +
            EmailEditService.getEmailFormulePolitesseFromContactsGroup(contactsGroup);
    }

    static getMessageConfrereFromVente(etude: Etude, user: User, vente: Vente): string {
        return 'A l\'attention de ' + etude.raisonSociale + '<br><br>Référence du bien : ' + vente.reference +
            '<br><br>' + (user.isFemme() ? 'Ma chère consoeur' : 'Mon cher confrère') + ',<br><br>Pour votre ' +
            'information, j\'ai transmis ce jour, la fiche descriptive de votre bien en vente ayant la référence ' +
            vente.reference + '.<br><br>Bien entendu, je me permettrai de vous contacter ultérieurement si notre ' +
            'client montre un intérêt particulier pour votre bien, ce, afin d\'organiser la visite.<br><br>Je vous ' +
            'prie d\'agréer, ' + (user.isFemme() ? 'Madame' : 'Monsieur') + ', l\'expression de mes salutations les ' +
            'plus distinguées.';
    }

    static getMessageEstimationRapport(estimation: Estimation): string {
        return EmailEditService.getFormuleIntroductionFromContactsGroup(estimation.contactsGroup) +
            ',<br><br>Nous vous prions de trouver ci-joint, ' +
            (estimation.isEvaluation() ? 'le rapport d\'évaluation' : 'l\'avis de valeur') + ' concernant votre bien.' +
            '<br><br>' + EmailEditService.getEmailFormulePolitesseFromContactsGroup(estimation.contactsGroup);
    }

    static getMessageFicheVente(currentDossier?: ADossier): string {
        let addDemandeurMessage = '';

        if (currentDossier?.isDemandeur()) {
            addDemandeurMessage = '<br>Si toutefois, vous n\'étiez plus en recherche d\'un bien ou si vos critères ' +
                'avaient évolué, merci de nous en informer.<br><br>';
        }

        return EmailEditService.getFormuleIntroductionFromContactsGroup(currentDossier?.contactsGroup) + ',<br><br>' +
            'Veuillez trouver ci-jointe la fiche d\'un bien qui pourrait vous intéresser.<br>' + addDemandeurMessage +
            EmailEditService.getEmailFormulePolitesseFromContactsGroup(currentDossier?.contactsGroup);
    }

    static getMessageOffreachatDocument(contactsGroup: ContactsGroup, isSigned = false): string {
        return EmailEditService.getFormuleIntroductionFromContactsGroup(contactsGroup) +
            ',<br><br>Veuillez trouver ci-jointe l\'offre d\'achat ' + (isSigned ? 'signée ' : '') + 'du bien.<br>' +
            EmailEditService.getEmailFormulePolitesseFromContactsGroup(contactsGroup);
    }

    static getMessageProcedureSignataireLinkInterface(procedureSignataire: ProcedureSignataire, media: Media): string {
        return ContactsGroup.default.FORMULE_INTRODUCTION + ',<br><br>Je vous transmets le lien vers la signature ' +
            'électronique de votre ' + media.title + '.<br>Je vous invite à cliquer sur le lien pour accéder au ' +
            'document et le signer.<br>' + procedureSignataire.linkInterfaceSignature + '<br>' +
            Email.goodReceptionPolitesse(ContactsGroup.default.FORMULE_POLITESSE);
    }

    private _dossiersService = inject(DossiersService);
    private _emailRecipientService = inject(EmailRecipientService);
    private _emailRecipientsService = inject(EmailRecipientsService);
    private _emailService = inject(EmailService);
    private _modalService = inject(ModalService);

    forConfrereFromVente$(etude: Etude, user: User, vente: Vente, dossiers: ADossier[] = [], currentDossier?: ADossier): Observable<boolean> {
        return this.open$([this._emailRecipientService.createFromUser(user)],
            'Envoi de votre bien en vente ' + vente.reference,
            EmailEditService.getMessageConfrereFromVente(etude, user, vente),
            true,
            [],
            dossiers,
            currentDossier);
    }

    forProcedureSignataireLinkInterface$(procedureSignataire: ProcedureSignataire, media: Media, dossiers: ADossier[] = [], currentDossier?: ADossier): Observable<boolean> {
        return this.open$([this._emailRecipientService.createFromProcedureSignataire(procedureSignataire)],
            media.title + ' : Signature électronique',
            EmailEditService.getMessageProcedureSignataireLinkInterface(procedureSignataire, media),
            true,
            [],
            dossiers,
            currentDossier);
    }

    forVenteFiche$(vente: Vente, attachments: Media[] = [], dossiers: ADossier[] = [], currentDossier?: ADossier): Observable<boolean> {
        return this.open$(this._emailRecipientsService.createFromContactsGroup(currentDossier?.contactsGroup),
            'Fiche du bien en vente ' + vente.reference,
            EmailEditService.getMessageFicheVente(currentDossier),
            true,
            attachments,
            dossiers,
            currentDossier);
    }

    fromBonvisiteDocument$(document: Document, attachments: Media[] = [], dossiers: ADossier[] = [], procedure?: Procedure): Observable<boolean> {
        const interneDossiers = this._dossiersService.getInternes(dossiers);
        const isSigned = procedure?.isFinished();
        const demandeur = interneDossiers.find(dossier => dossier.isDemandeur())!;
        const referenceBiens = interneDossiers.filter(dossier => !dossier.isDemandeur()).map(dossier => dossier.reference);
        const message = EmailEditService.getMessageBonvisiteDocument(demandeur?.contactsGroup, referenceBiens, isSigned);
        const recipients = this._emailRecipientsService.createFromContactsGroup(demandeur?.contactsGroup);

        return this.open$(recipients, document.titre, message, true, attachments, interneDossiers, demandeur);
    }

    fromEstimationRapport$(estimation: Estimation, document: Document, attachments: Media[] = []): Observable<boolean> {
        const message = EmailEditService.getMessageEstimationRapport(estimation);
        const recipients = this._emailRecipientsService.createFromContactsGroup(estimation?.contactsGroup);

        return this.open$(recipients, document.titre, message, true, attachments, [estimation], estimation);
    }

    fromEtudeSite$(etude: Etude, site: Site): Observable<boolean> {
        return this._emailRecipientService.createFromSite$(etude, site).pipe(
            switchMap(recipient => this.open$([recipient])),
        );
    }

    fromOffreachatDocument$(document: Document, attachments: Media[] = [], dossiers: ADossier[] = [], procedure?: Procedure): Observable<boolean> {
        const interneDossiers = this._dossiersService.getInternes(dossiers);
        const isSigned = procedure?.isFinished();
        const demandeur = interneDossiers.find(dossier => dossier.isDemandeur())!;
        const message = EmailEditService.getMessageOffreachatDocument(demandeur?.contactsGroup, isSigned);
        const recipients = this._emailRecipientsService.createFromContactsGroups(interneDossiers.map(dossier => dossier.contactsGroup));

        return this.open$(recipients, document.titre, message, true, attachments, interneDossiers, demandeur);
    }

    fromUser$(user: User): Observable<boolean> {
        return this.open$([this._emailRecipientService.createFromUser(user)]);
    }

    open$(recipients: EmailRecipient[] = [],
          subject?: string,
          message?: string,
          withSignature = true,
          attachments: Media[] = [],
          dossiers: ADossier[] = [],
          currentDossier?: ADossier): Observable<boolean> {
        return this._emailService.ngCreate$(this._emailRecipientsService.ngGet(recipients), subject, message, withSignature, attachments, dossiers).pipe(
            switchMap(email => this._modalService.ngOpen$('EskModalEmail', {
                resolve: {email, currentDossier: currentDossier!},
            })),
            catchError(_ => of(false)),
            map(data => data as boolean),
        );
    }

    openAssistance$(): Observable<void> {
        return this.open$([this._emailRecipientService.createNoteoSupport()]).pipe(map(() => {}));
    }
}
