import angularJS from '@shared/angularJS/global.ng';
import {map} from 'lodash';
import {IModule, IQService} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgLocation} from '@legacy/app/managers/ressources';
import {LocationFactory} from '@models/locations/location/location.factory';
import {firstValueFrom} from 'rxjs';

export default function getLocationModalArchiveCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('LocationModalArchiveCtrl', Controller);

        /**
         * Controller for archive modal
         *
         * @param location
         * @param $uibModalInstance
         * @param $translate
         * @param SoqSweetAlert
         * @param $q
         * @param Ng2LocationFactory
         */
        Controller.$inject = ['location', '$uibModalInstance', '$translate', 'SoqSweetAlert', '$q', 'Ng2LocationFactory'];
        function Controller(this: any,
                            location: NgLocation,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $q: IQService,
                            ng2LocationFactory: LocationFactory) {
            const $ctrl = this;

            $ctrl.saveLocation = saveLocation;
            $ctrl.archiveLocation = archiveLocation;
            $ctrl.sending = false;
            $ctrl.location = location;
            $ctrl.archive = {comments: $ctrl.location.archiveComments};

            /**
             * Save location information
             */
            function saveLocation() {
                $ctrl.sending = true;
                saveArchiveInfosLocation()
                    .then(() => $uibModalInstance.dismiss())
                    .finally(() => $ctrl.sending = false);
            }

            /**
             * Archive location
             */
            function archiveLocation() {
                $ctrl.sending = true;
                $ctrl.editForm.$submitted = true;
                saveArchiveInfosLocation().then(() => {
                    soqSweetAlert.dangerConfirmation(
                        $translate.instant('location.archive.confirm.TITLE'),
                        $translate.instant('location.archive.confirm.MESSAGE', {reference: location.bien.reference}),
                        {confirmButtonText: $translate.instant('location.archive.confirm.CONFIRMATION')},
                    ).then(() => $ctrl.location.archive().then(() => $uibModalInstance.close()))
                        .catch(() => $uibModalInstance.dismiss());
                }, (rejection: { data: { errors: string[] }; status: number }) => {
                    if (rejection.status === 422 && angular.isObject(rejection.data) && angular.isArray(rejection.data.errors) && rejection.data.errors.length > 0) {
                        soqSweetAlert.warningMessage($translate.instant('location.save.TITLE', {nbMissingData: rejection.data.errors.length}), $translate.instant('location.save.MESSAGE', {
                            nbMissingData: rejection.data.errors.length,
                            missingData: map(rejection.data.errors, 'message').join('<br>- '),
                        }));
                    }
                }).finally(() => $ctrl.sending = false);
            }

            /**
             * Save location
             *
             * @returns {Promise}
             */
            function saveArchiveInfosLocation() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));

                    return $q.reject();
                }

                $ctrl.location.archiveComments = $ctrl.archive.comments;

                return firstValueFrom(ng2LocationFactory.save$(ng2LocationFactory.ngCreate($ctrl.location)));
            }
        }
    })(angularJS);
}
