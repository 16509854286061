import {inject, Injectable} from '@angular/core';
import {MediaFactory} from '@models/medias/media/media.factory';
import {Observable, of, switchMap, throwError} from 'rxjs';
import Media from '@models/medias/media/media.model';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import Document from '@models/documents/document/document.model';
import {ModalService} from '@shared/modal/modal.service';
import {DCMediaRenameComponent} from '@features/medias/media/rename/media.rename.component';
import Report from '@models/reports/report/report.model';
import LocationMandat from '@models/locations/location/mandats/mandat/location-mandat.model';
import Location from '@models/locations/location/location.model';

@Injectable({providedIn: 'root'})
export class MediaService {
    private _mediaFactory = inject(MediaFactory);
    private _modalService = inject(ModalService);

    getOneByLink$(path: string): Observable<Media> {
        return this._mediaFactory.getByLink$(path, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status !== 404) {
                    return throwError(() => httpErrorResponse);
                }

                return of(this._mediaFactory.createVirgin());
            }),
        );
    }

    getFromDocuments$(documents: Document[], titre = Media.NO_TITLE): Observable<Media> {
        return this._mediaFactory.getFromDocuments$(documents, titre);
    }

    getFromLocationMandats$(location: Location, locationMandat: LocationMandat): Observable<Media> {
        return this._mediaFactory.getFromLocationMandats$(location, locationMandat);
    }

    getFromReports$(reports: Report[], titre = Media.NO_TITLE): Observable<Media> {
        return this._mediaFactory.getFromReports$(reports, titre);
    }

    readFromDocuments$(documents: Document[], titre = Media.NO_TITLE): Observable<Window> {
        return this.getFromDocuments$(documents, titre).pipe(switchMap(media => this._mediaFactory.read$(media)));
    }

    readFromLocationMandats$(location: Location, locationMandat: LocationMandat): Observable<Window> {
        return this.getFromLocationMandats$(location, locationMandat).pipe(switchMap(media => this._mediaFactory.read$(media)));
    }

    readFromReports$(reports: Report[], titre = Media.NO_TITLE): Observable<Window> {
        return this.getFromReports$(reports, titre).pipe(switchMap(media => this._mediaFactory.read$(media)));
    }

    rename$(media: Media): Observable<boolean> {
        return this._modalService.open$<{ media: Media }>(DCMediaRenameComponent, {
            media: this._mediaFactory.copy(media),
        }).pipe(
            switchMap(response => {
                if (!response) {
                    return of(false);
                }

                media.title = response.media.title;

                return this._mediaFactory.save$(response.media).pipe(map(_ => true));
            }),
        );
    }
}
