import {inject, Injectable} from '@angular/core';
import {
    DossierExportMediaFactory
} from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.factory';
import {
    IDossierExportMediaApi
} from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.interfaces';
import DossierExportMedia
    from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.model';

@Injectable({providedIn: 'root'})
export class DossierExportMediasFactory {
    private _dossierExportMediaFactory = inject(DossierExportMediaFactory);

    create(dossierExportMediaApis: IDossierExportMediaApi[] = []): DossierExportMedia[] {
        return dossierExportMediaApis.map(dossierExportMediaApi => this._dossierExportMediaFactory.create(dossierExportMediaApi));
    }
}
