import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IEmailApi} from '@models/emails/email/email.interfaces';

@Injectable({providedIn: 'root'})
export class EmailsApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IEmailApi> {
        return this._modelApiService.get$('/emails/' + uuid);
    }
}
