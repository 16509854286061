import {inject, Injectable} from '@angular/core';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import {Observable, of, switchMap, throwError} from 'rxjs';
import {LocationPasserelleFactory} from '@models/locations/location/passerelles/passerelle/location-passerelle.factory';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import Location from '@models/locations/location/location.model';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LocationPasserelleService {
    private _locationPasserelleFactory = inject(LocationPasserelleFactory);

    getOne$(location: Location, etudePasserelle: EtudePasserelle): Observable<LocationPasserelle> {
        return this._locationPasserelleFactory.get$(location, etudePasserelle.passerelle, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return of(this._locationPasserelleFactory.createVirgin(etudePasserelle.passerelle.id, location.id));
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }

    getOneSaved$(location: Location, etudePasserelle: EtudePasserelle): Observable<LocationPasserelle> {
        return this.getOne$(location, etudePasserelle).pipe(switchMap(locationPasserelle => this._locationPasserelleFactory.save$(locationPasserelle)));
    }
}
