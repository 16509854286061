export default `
<soq-panel box="$ctrl.box">
  <content-slot>
    <div ng-switch="$ctrl.loading">
      <p ng-switch-when="true" class="tw-text-center text-extra-large">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
      </p>

      <div ng-switch-default>
        <table class="table">
          <thead>
          <tr class="row">
            <th class="col-xs-4"></th>

            <th class="col-xs-4 tw-text-center">Mensuelle</th>

            <th class="col-xs-4 tw-text-center">{{$ctrl.currentYear}}</th>
          </tr>
          </thead>

          <tr class="row" ng-repeat="statActivity in $ctrl.statsActivity.data">
            <td class="col-xs-4"><h5>{{statActivity.label}}</h5></td>

            <td class="col-xs-4 tw-text-center"><h1>{{statActivity.month}}</h1></td>

            <td class="col-xs-4 tw-text-center"><h1>{{statActivity.year}}</h1></td>
          </tr>
        </table>

        <p class="text-right text-muted text-small">
          Mise à jour des données :<app-ng2-date [date]="$ctrl.statsActivity.date" format="shortDate"></app-ng2-date>
          à <app-ng2-date [date]="$ctrl.statsActivity.date" format="shortTime"></app-ng2-date>
        </p>
      </div>
    </div>
  </content-slot>
</soq-panel>
`;
