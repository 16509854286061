import AModel from '@models/model.abstract';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';

export default class BienCadastre extends AModel {
    private _communeId!: number;
    private _convivialName!: string;
    private readonly _id: number;
    private _lot!: number;
    private _parcelle!: number;
    private _prefixe = '000';
    private _section!: string;
    private _surface!: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get communeId(): number {
        return this._communeId;
    }

    set communeId(value: number) {
        this._communeId = value;
    }

    get convivialName(): string {
        return this._convivialName;
    }

    get id(): number {
        return this._id;
    }

    get lot(): number {
        return this._lot;
    }

    set lot(value: number) {
        this._lot = value;
        this.setConvivialName();
    }

    get parcelle(): number {
        return this._parcelle;
    }

    set parcelle(value: number) {
        this._parcelle = value;
        this.setConvivialName();
    }

    get prefixe(): string {
        return this._prefixe;
    }

    set prefixe(value: string) {
        if (/^\d{3}$/.test(value)) {
            this._prefixe = value;
        } else {
            this.prefixe = '000';
        }
    }

    get section(): string {
        return this._section;
    }

    set section(value: string) {
        this._section = value?.toUpperCase();
        this.setConvivialName();
    }

    get surface(): number {
        return this._surface;
    }

    set surface(value: number) {
        this._surface = value;
        this.setConvivialName();
    }

    setConvivialName(): void {
        const data: string[] = [];

        if (this.section) {
            data.push(this.section);
        }

        if (this.parcelle) {
            data.push(this.parcelle.toString());
        }

        if (this.lot) {
            data.push(`lot ${this.lot.toString()}`);
        }

        if (this.surface) {
            data.push(`(${TextCurrencyPipe.transformation(this.surface, 'm²')})`);
        }

        this._convivialName = data.join(' ');
    }
}
