import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgClientService} from '@legacy/app/client/client';
import {NgAreaManager, NgManager, NgQuartierManager} from '@legacy/app/managers/managers';
import {NgCommune, NgCommuneVoie} from '@legacy/app/managers/ressources';
import Commune from '@models/communes/commune/commune.model';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';

export default function getManagersCommune(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('CommuneManager', manager);

        /**
         * Manager commune
         *
         * @param BaseManager
         * @param QuartierManager
         * @param ClientService
         * @param AreaManager
         * @returns {CommuneManager}
         */
        manager.$inject = ['BaseManager', 'QuartierManager', 'ClientService', 'AreaManager'];
        function manager(baseManager: NgManager,
                         quartierManager: NgQuartierManager,
                         clientService: NgClientService,
                         areaManager: NgAreaManager) {
            let classResource = baseManager.getClass<NgCommune>();

            /**
             * Routing
             */
            baseManager.addRoute('communes.cget', {path: '/communes', method: 'GET'});
            baseManager.addRoute('communes.get', {path: '/communes/{id}', method: 'GET'});
            baseManager.addRoute('communes.get-voies', {path: '/communes/{id}/voies', method: 'GET'}, true);

            /**
             * CommuneManager object
             *
             * @constructor
             */
            angular.extend(CommuneManager.prototype, baseManager.__proto__);
            CommuneManager.prototype.eskManager = {prefixRoute: 'communes', collectionName: 'items'};
            function CommuneManager() {
            }

            /**
             * Create a Commune object
             *
             * @param data
             * @returns manager.Commune object || Array of manager.Commune objects
             */
            CommuneManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var communes = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        communes.push(new Commune(data[i]));
                    }

                    return communes;
                }

                // @ts-ignore
                return new Commune(data);
            };

            /**
             * Create a Commune object
             *
             * @param ng2Commune
             * @returns manager.Commune
             */
            CommuneManager.prototype.createFromNg2 = function (ng2Commune: Commune) {
                if (!ng2Commune) {
                    return undefined!;
                }

                return this.create({
                    codesPostaux: ng2Commune.codesPostaux,
                    coordonneesGps: ng2Commune.coordonneesGps,
                    departement: areaManager.createFromNg2Departement(ng2Commune.departement),
                    fullname: ng2Commune.fullname,
                    hasQuartiers: ng2Commune.hasQuartiers,
                    hasVoies: ng2Commune.hasVoies,
                    id: ng2Commune.id,
                    uuid: ng2Commune.uuid,
                });
            };

            /**
             * Create a Commune object
             *
             * @param ng2CommuneVoie
             * @returns manager.Commune
             */
            CommuneManager.prototype.createVoieFromNg2 = function (ng2CommuneVoie: CommuneVoie): NgCommuneVoie {
                return {
                    code: ng2CommuneVoie.code,
                    id: ng2CommuneVoie.id,
                    nom: ng2CommuneVoie.nom,
                    uuid: ng2CommuneVoie.uuid,
                } as NgCommuneVoie;
            };

            /**
             * Commune object
             *
             * @param data
             * @constructor
             */
            angular.extend(Commune.prototype, classResource.prototype);
            Commune.prototype.eskManager = {prefixRoute: 'communes'};
            function Commune(this: NgCommune, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Get all Quartiers for Commune
             *
             * @param keywords
             * @returns {Promise}
             */
            Commune.prototype.getAllQuartiers = function (keywords: string) {
                return quartierManager.getAllFromCommune(this.id, {keywords});
            };

            /**
             * Get Voies
             *
             * @param keywords
             * @returns {Promise}
             */
            Commune.prototype.getVoies = function (keywords: string) {
                return clientService.execRoute(this.getPrefixRoute() + '.get-voies', {id: this.id, keywords: keywords});
            };

            // @ts-ignore
            return new CommuneManager();
        }
    })(angularJS);
}
