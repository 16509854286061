import AModel from '@models/model.abstract';

export default class BonvisiteVente extends AModel {
    private _linkVente!: string;

    get linkVente(): string {
        return this._linkVente;
    }

    set linkVente(value: string) {
        this._linkVente = value;
    }
}
