import {Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {IVenteSlideOverData} from '@features/ventes/ventes.interfaces';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Vente from '@models/ventes/vente/vente.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';

@Component({selector: 'dc-vente-slide-over', templateUrl: 'vente.slide-over.component.html'})
export class DCVenteSlideOverComponent implements IDynamicComponent {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _venteFactory = inject(VenteFactory);
    private _vente$!: Observable<Vente>;
    private _superficieLabel!: string;

    set data(dynamicComponentData: IVenteSlideOverData) {
        this._vente$ = this._venteFactory.getByLink$(dynamicComponentData.link).pipe(
            tap(vente => this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(vente.bien.nature)),
            take(1)
        );
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    get vente$(): Observable<Vente> {
        return this._vente$;
    }

    redirectToDetails(vente: Vente): void {
        if (vente.interne && vente.isArchived()) {
            this._venteFactory.openOverviewInNewTab$(vente).pipe(take(1)).subscribe();
        } else {
            this._venteFactory.openDetailsInNewTab$(vente).pipe(take(1)).subscribe();
        }
    }
}
