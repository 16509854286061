import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {map} from 'rxjs/operators';
import {IEtudeSettingsNetworkApi} from '@models/etudes/etude/settings/network/etude-settings-network.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeSettingsNetworkApiService {
    private _modelApiService = inject(ModelApiService);

    get$(etudeUuid: string): Observable<IEtudeSettingsNetworkApi> {
        return this._modelApiService.get$('/etudes/' + etudeUuid + '/settings/network');
    }

    save$(etudeUuid: string, etudeSettingsNetworkApi: IEtudeSettingsNetworkApi): Observable<IEtudeSettingsNetworkApi> {
        return this._modelApiService.put$('/etudes/' + etudeUuid + '/settings/network', etudeSettingsNetworkApi)
            .pipe(map(_ => etudeSettingsNetworkApi));
    }
}
