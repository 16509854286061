export default `
<ng-switch on="$ctrl.cadastreEditable">
  <div ng-switch-when="true" class="text-justify">
    <p class="text-bold">Comment saisir un cadastre ?</p>

    <p>
      Les champs
      <span class="text-bold">Commune</span>,
      <span class="text-bold">Section</span>
      et
      <span class="text-bold">Parcelle</span>
      sont obligatoires.
    </p>

    <p ng-if="$ctrl.bien._esk.type === $ctrl.DossierBienTypesConst.VENTE && $ctrl.currentEtude.hasNetwork()">
      Dès qu'une date de mandat sera saisie sur votre bien en vente, vous ne pourrez plus modifier les références cadastrales.
    </p>
  </div>

  <div ng-switch-default class="text-justify">
    Vous ne pouvez plus modifier les références cadastrales car une date de mandat est saisie pour ce bien en vente.

    <div class="margin-top-5">
      <esk-contact></esk-contact> <span>pour toutes modifications et/ou pour plus d'informations.</span>
    </div>
  </div>
</ng-switch>
`;
