export default `
<form name="$ctrl.editForm" class="ventes-modal-box-search" ng-submit="$ctrl.save()" novalidate>
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans modifier la configuration"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'cogs'"></fa-icon> {{$ctrl.box.name}}</h3>
  </div>

  <div class="modal-body">
    <div class="checkbox clip-check check-primary">
      <input type="checkbox" id="ventes-search-crpcen" ng-model="$ctrl.box.config.byReference.withCRPCEN">

      <label for="ventes-search-crpcen">CRPCEN affiché dans la recherche par référence</label>
    </div>
  </div>

  <div class="modal-footer">
    <ng-switch on="$ctrl.loader">
      <p class="text-muted" ng-switch-when="true">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
      </p>

      <button type="submit" ng-switch-default class="btn btn-primary">
        <fa-icon [icon]="'check'"></fa-icon> Enregistrer
      </button>
    </ng-switch>
  </div>
</form>
`;
