import {inject, Injectable} from '@angular/core';
import Media from '@models/medias/media/media.model';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {Observable, switchMap} from 'rxjs';
import {DocumentService} from '@models/documents/document/document.service';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {MediaFactory} from '@models/medias/media/media.factory';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';

@Injectable({providedIn: 'root'})
export class MediaResumeService {
    private _bonvisiteService = inject(BonvisiteService);
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _mediaFactory = inject(MediaFactory);
    private _offreachatService = inject(OffreachatService);
    private _procedureService = inject(ProcedureService);

    getFromBonvisite$(bonvisite: Bonvisite): Observable<Media> {
        return this._bonvisiteService.getProcedure$(bonvisite).pipe(switchMap(procedure => {
            if (!procedure || procedure.isNewOrDraft()) {
                return this._documentFactory.getByLink$(bonvisite.linkDocument).pipe(
                    switchMap(document => this._documentService.getMedia$(document)),
                );
            }

            return this._procedureService.getMedia$(procedure);
        }));
    }

    getFromOffreachat$(offreachat: Offreachat): Observable<Media> {
        if (offreachat.isWrite() || offreachat.isSign()) {
            return this._offreachatService.getProcedure$(offreachat).pipe(switchMap(procedure => {
                if (!procedure || procedure.isNewOrDraft()) {
                    return this._documentFactory.getByLink$(offreachat.linkDocument).pipe(
                        switchMap(document => this._documentService.getMedia$(document)),
                    );
                }

                return this._procedureService.getMedia$(procedure);
            }));
        }

        if (offreachat.linkMedia) {
            return this._mediaFactory.getByLink$(offreachat.linkMedia);
        }

        return this._documentFactory.getByLink$(offreachat.linkDocument).pipe(
            switchMap(document => this._documentService.getMedia$(document)),
        );
    }

    readFromBonvisite$(bonvisite: Bonvisite): Observable<Window> {
        return this.getFromBonvisite$(bonvisite).pipe(switchMap(media => this._mediaFactory.read$(media)));
    }

    readFromOffreachat$(offreachat: Offreachat): Observable<Window> {
        return this.getFromOffreachat$(offreachat).pipe(switchMap(media => this._mediaFactory.read$(media)));
    }
}
