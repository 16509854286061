import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DossierBiensApiService {
    private _modelApiService = inject(ModelApiService);

    deleteVirtualVisit$(id: string): Observable<void> {
        return this._modelApiService.delete$(`/previsite/${id}`);
    }
}
