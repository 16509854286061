import {Component, Input} from '@angular/core';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import {EmissionsV1ColorsConst, EmissionsV2ColorsConst} from '@shared/diagnostic/diagnostic.constants';

@Component({selector: 'app-diagnostic-letter-emissions', templateUrl: 'diagnostic-letter-emissions.component.html'})
export class AppDiagnosticLetterEmissionsComponent {
    private _backgroundColor!: string;
    private _letter!: string;

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get letter(): string {
        return this._letter;
    }

    @Input()
    set performanceEnergetique(value: BienPerformanceEnergetique) {
        const emissionsClasse = value.emissionsClasse!;

        this._letter = emissionsClasse.code;
        this._backgroundColor = value.isV1ByDate() ? EmissionsV1ColorsConst[this._letter] : EmissionsV2ColorsConst[this._letter];
    }
}
