import {inject, Injectable} from '@angular/core';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {CommunesApiService} from '@models/communes/communes.api.service';
import CCommunes from '@models/communes/collection/communes.collection.model';
import {Observable} from 'rxjs';
import {ICCommunesApi} from '@models/communes/collection/communes.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class CCommunesFactory {
    private _communeFactory = inject(CommuneFactory);
    private _communesApiService = inject(CommunesApiService);

    create(cCommunesApi: ICCommunesApi): CCommunes {
        const cCommunes = new CCommunes();

        cCommunes.links = new Links(cCommunesApi._links);
        cCommunes.page = cCommunesApi.page;
        cCommunes.pages = cCommunesApi.pages;
        cCommunes.perPage = cCommunesApi.limit;
        cCommunes.total = cCommunesApi.total;
        if (cCommunesApi._embedded?.items) {
            cCommunesApi._embedded.items.filter(communeApi => communeApi).map(communeApi => cCommunes.results.push(this._communeFactory.create(communeApi)));
        }

        return cCommunes;
    }

    get$(queryParameters?: IQueryParameters): Observable<CCommunes> {
        return this._communesApiService.getCollection$(queryParameters).pipe(map(cCommunesApi => this.create(cCommunesApi)));
    }
}
