import {IUserSettingsDiffusions, IUserSettingsDossiers} from '@models/users/user/settings/user-settings.interfaces';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default class UserSettings {
    static readonly phoneSources = {
        AUTO: 'auto',
        AUTRE: 'autre',
        SITE_UTILISATEUR_FIXE: 'site_utilisateur_fixe',
        SITE_PRINCIPAL_FIXE: 'site_principal_fixe',
        UTILISATEUR_FIXE: 'utilisateur_fixe',
        UTILISATEUR_MOBILE: 'utilisateur_mobile',
    };
    static readonly DOSSIER_TYPES = [
        DossierTypesConst.DEMANDEUR + 's',
        DossierTypesConst.ESTIMATION + 's',
        DossierTypesConst.LOCATION + 's',
        DossierTypesConst.VENTE + 's',
    ];
    private _demandeurs = {} as IUserSettingsDossiers;
    private _diffusions = {} as IUserSettingsDiffusions;
    private _estimations = {} as IUserSettingsDossiers;
    private _locations = {} as IUserSettingsDossiers;
    private _ventes = {} as IUserSettingsDossiers;

    get demandeurs(): IUserSettingsDossiers {
        return this._demandeurs;
    }

    set demandeurs(value: IUserSettingsDossiers) {
        this._demandeurs = value;
    }

    get diffusions(): IUserSettingsDiffusions {
        return this._diffusions;
    }

    set diffusions(value: IUserSettingsDiffusions) {
        this._diffusions = value;
    }

    get estimations(): IUserSettingsDossiers {
        return this._estimations;
    }

    set estimations(value: IUserSettingsDossiers) {
        this._estimations = value;
    }

    get locations(): IUserSettingsDossiers {
        return this._locations;
    }

    set locations(value: IUserSettingsDossiers) {
        this._locations = value;
    }

    get ventes(): IUserSettingsDossiers {
        return this._ventes;
    }

    set ventes(value: IUserSettingsDossiers) {
        this._ventes = value;
    }

    getUserSettingsDossiers(type: string): IUserSettingsDossiers {
        return this[type] as IUserSettingsDossiers || null;
    }
}
