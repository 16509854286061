import {Component, Inject, Input} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';

@Component({
    selector: 'bonvisites-actions-main-dropdown',
    templateUrl: 'bonvisites.actions-main-dropdown.component.html',
})
export class BonvisitesActionsMainDropdownComponent implements IDynamicComponent {
    private _window: Window;
    private _value!: IDynamicComponentData;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    print(): void {
        this._window.print();
    }
}
