@if (cActualites$ | async; as cActualites) {
  <div class="tw-overflow-hidden">
    <table id="actualiteReferences" class="tw-table-head-separate-body">
      <thead>
        <tr>
          <th class="tw-w-40 lg:tw-w-48">
            <div>
              <span class="lg:tw-hidden">Date publication</span>

              <span class="tw-hidden lg:tw-inline">Date de publication</span>
            </div>
          </th>

          <th><div>Titre</div></th>

          <th class="tw-hidden sm:tw-table-cell tw-w-28"><div>Lecture</div></th>

          <th class="tw-w-16 md:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
        </tr>
      </thead>

      <tbody>
        @for (actualite of cActualites.results; track actualite) {
          <tr>
            <td>{{actualite.date | date}}</td>

            <td>{{actualite.titre}}</td>

            <td class="tw-hidden sm:tw-table-cell">{{actualite.read ? 'Lu' : 'À découvrir'}}</td>

            <td class="tw-table-head-separate-body-actions">
              <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                      #buttonElement (click)="clickOnActualite(buttonElement, actualite)">
                <fa-icon [icon]="'ellipsis-v'"/>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <app-load-more [collection$]="cActualites$" (loadMore)="getMore(cActualites)" [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
