import {Component, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

// Cette directive n'est pas testée car impossible de mocker Injector et pas réussi à lancer AngularJS avant les tests pour initialiser Injector.
// Penser à supprimer l'entrée dans "codeCoverageExclude" de "angular.json" si une solution est mise en place.
@Component({selector: 'app-diagnostic-graphe-ges-legacy', template: ''})
export class AppDiagnosticGrapheGESLegacyComponent extends UpgradeComponent {
    private _value!: number;
    private _nature!: DictionaryItem;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('soqGesUpgrade', elementRef, injector);
    }

    @Input()
    set value(value: number) {
        this._value = value;
    }

    get value(): number {
        return this._value;
    }

    @Input()
    set nature(value: DictionaryItem) {
        this._nature = value;
    }

    get nature(): DictionaryItem {
        return this._nature;
    }
}
