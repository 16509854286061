import {Injectable} from '@angular/core';
import Jexl from 'jexl';
import {NgBienCharge} from '@legacy/app/managers/ressources';
import {Context} from 'jexl/Expression';

@Injectable({providedIn: 'root'})
export default class ParserService {
    static readonly COUNT = (array: unknown[]): number => array?.length;
    static readonly HAS_CHARGES = (charges: NgBienCharge[] = [], codeTypeCharges: string): boolean => charges.some(charge => charge?.charge?.code === codeTypeCharges);
    static readonly PHONE_NUMBER = (model: {getPhoneNumber: (typeParam: string) => string}, type: string): string => model.getPhoneNumber(type);

    constructor() {
        Jexl.addTransform('count', ParserService.COUNT);
        Jexl.addTransform('hasCharges', ParserService.HAS_CHARGES);
        Jexl.addTransform('phoneNumber', ParserService.PHONE_NUMBER);
    }

    evalSync<T = unknown>(expression: string, context: Context): T {
        return Jexl.evalSync(expression, context) as T;
    }
}
