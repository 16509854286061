import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getContactResume(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact-resume></esk-contact-resume>
         */
        module.component('eskContactResume', {
            templateUrl: 'src/app/legacy/templates/contacts/components/item/resume.html',
            bindings: {contact: '=', onClick: '='}
        });
    })(angularJS);
}
