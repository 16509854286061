import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewAgencementInterieurService
} from '@features/dossiers/dossier/bien/overview/agencement-interieur/dossier-bien-overview.agencement-interieur.service';

@Component({
    selector: 'app-dossier-bien-overview-agencement-interieur',
    templateUrl: 'dossier-bien-overview.agencement-interieur.component.html',
})
export class AppDossierBienOverviewAgencementInterieurComponent {
    private _dossierBienOverviewAgencementInterieurService = inject(DossierBienOverviewAgencementInterieurService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isAlarmeLocationVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isAlarmeLocationVisible;
    }

    get isAlarmePleineProprieteVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isAlarmePleineProprieteVisible;
    }

    get isAscenseurVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isAscenseurVisible;
    }

    get isCaveVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isCaveVisible;
    }

    get isChemineeVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isChemineeVisible;
    }

    get isComblesVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isComblesVisible;
    }

    get isCommentsAgencementInterieurVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isCommentsAgencementInterieurVisible;
    }

    get isConnexionsInternetVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isConnexionsInternetVisible;
    }

    get isCuisineEquipeeVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isCuisineEquipeeVisible;
    }

    get isDernierEtageVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isDernierEtageVisible;
    }

    get isDetecteurFumeeVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isDetecteurFumeeVisible;
    }

    get isGrenierVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isGrenierVisible;
    }

    get isHauteurSousPlafondVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isHauteurSousPlafondVisible;
    }

    get isNombreBainsVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreBainsVisible;
    }

    get isNombreChambresVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreChambresVisible;
    }

    get isNombreDouchesVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreDouchesVisible;
    }

    get isNombreEtagesVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreEtagesVisible;
    }

    get isNombreLogementsVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreLogementsVisible;
    }

    get isNombrePiecesVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombrePiecesVisible;
    }

    get isNombreSallesBainsVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreSallesBainsVisible;
    }

    get isNombreSallesEauVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreSallesEauVisible;
    }

    get isNombreWcVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNombreWcVisible;
    }

    get isNumeroEtageVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isNumeroEtageVisible;
    }

    get isSousSolVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isSousSolVisible;
    }

    get isSurfaceSejourVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isSurfaceSejourVisible;
    }

    get isWcBroyeurVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isWcBroyeurVisible;
    }
}
