import {Component, Input} from '@angular/core';
import environment from '@env/environment';
import Etude from '@models/etudes/etude/etude.model';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';

@Component({selector: 'app-etude-passerelle', templateUrl: 'etude-passerelle.component.html'})
export class AppEtudePasserelleComponent {
    static readonly linkMarianne = environment.api.baseUrl + Etude.linkMarianne.PLURAL;
    private _etudePasserelle!: EtudePasserelle;

    get etudePasserelle(): EtudePasserelle {
        return this._etudePasserelle;
    }

    @Input({required: true})
    set etudePasserelle(value: EtudePasserelle) {
        this._etudePasserelle = value;
    }

    get linkMarianne(): string {
        return AppEtudePasserelleComponent.linkMarianne;
    }
}
