import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService, ITemplateCacheService} from 'angular';
import {NgEskBox, NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgFilterManager, NgLocationManager, NgVenteManager} from '@legacy/app/managers/managers';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgBienDossier, NgFilter} from '@legacy/app/managers/ressources';
import {NgBienDossiersCollection} from '@legacy/app/managers/collections';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getDossiersBoxLast(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-biens-box-last></esk-biens-box-last>
         */
        module.run(template).component('eskBiensBoxLast', {
            bindings: {typeDossier: '@'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/box/last.html'
        });

        /**
         * Display lasts dossierBiens
         *
         * @param FilterManager
         * @param $q
         * @param VenteManager
         * @param Ng2RouterStateForNgService
         * @param EskUtils
         * @param EskBox
         * @param LocationManager
         * @constructor
         */
        Controller.$inject = ['FilterManager', '$q', 'VenteManager', 'Ng2RouterStateForNgService', 'EskUtils', 'EskBox', 'LocationManager'];
        function Controller(this: any,
                            filterManager: NgFilterManager,
                            $q: IQService,
                            venteManager: NgVenteManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            eskUtils: NgEskUtils,
                            eskBox: NgEskBox,
                            locationManager: NgLocationManager) {
            const $ctrl = this;
            let filter: NgFilter;
            let dossierBiensManager: NgLocationManager | NgVenteManager;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.$onInit = $onInit;
            $ctrl.goToDossierBiens = goToDossierBiens;
            $ctrl.onClickDossierBien = onClickDossierBien;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.box = eskBox.getOneByCode($ctrl.typeDossier + 's-last');
                $ctrl.options = {
                    displayMode: 'box',
                    loader: {updating: false},
                    visibleFields: {
                        archiveDateVente: false,
                        bullet: false,
                        statut: false,
                        mandatDateFin: false,
                        nomContactsGroup: false,
                        nombreChambres: false,
                        nombrePieces: false,
                        preciseLocalisation: $ctrl.box.config.preciseLocalisation,
                        priceCession: false,
                        select: false,
                    },
                };
                if ($ctrl.typeDossier === DossierBienTypesConst.LOCATION) {
                    dossierBiensManager = locationManager;
                } else if ($ctrl.typeDossier === DossierBienTypesConst.VENTE) {
                    dossierBiensManager = venteManager;
                } else {
                    throw new Error('Impossible de déterminer le "typeDossier" pour ' + $ctrl.typeDossier);
                }

                filterManager.getActiveByRouteCode('get_' + $ctrl.typeDossier + 's', 'box_new_' + $ctrl.typeDossier + 's').then(
                    filter => filter as NgFilter
                ).then(_filter_ => {
                    filter = _filter_;
                    $ctrl.box.refresh = refresh;
                    $ctrl.box.refresh();
                });
            }

            /**
             * Action on click on dossierBien
             *
             * @param dossierBien
             */
            function onClickDossierBien(dossierBien: NgBienDossier) {
                eskUtils.getSearchFromFilter($ctrl.typeDossier, filter?.queryParameters as NgFilter).then(function (search) {
                    const params = {
                        [$ctrl.typeDossier + 'Id']: dossierBien.id,
                        routeBack: {name: '/app/' + $ctrl.typeDossier + 's/search'},
                        search: search,
                    };

                    params.search.rapidSearchType = 'criteria';
                    eskUtils.setDataForRoute('/app/' + $ctrl.typeDossier + 's/search', {
                        dossiers: $ctrl.dossierBiens,
                        routeBack: {name: '/app/' + $ctrl.typeDossier + 's/search', params: params as unknown as string},
                        search,
                    });
                    ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/search/details/' + dossierBien.id.toString(), params);
                });
            }

            /**
             * Go to last dossierBiens
             */
            function goToDossierBiens() {
                eskUtils.getSearchFromFilter($ctrl.typeDossier, filter?.queryParameters as NgFilter).then(function (search) {
                    search.rapidSearchType = 'criteria';
                    ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/search', {
                        search,
                        options: {preciseLocalisation: $ctrl.box.config.preciseLocalisation},
                    });
                });
            }

            /**
             * Refresh
             *
             * @returns {*}
             */
            function refresh() {
                const params = angular.extend({limit: 9}, filter.queryParameters);

                $ctrl.options.loader.updating = true;

                return (dossierBiensManager.getAll(params) as IPromise<NgBienDossiersCollection>).then(function (dossierBiens) {
                    angular.forEach(dossierBiens.collection, function (dossierBien) {
                        dossierBien._esk.routing = '/app/' + $ctrl.typeDossier + 's/search/details/' + dossierBien.id.toString();
                    });
                    $ctrl.dossierBiens = dossierBiens;
                }).finally(() => $ctrl.options.loader.updating = false);
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/locations/components/box/last/container.html', '<esk-biens-box-last type-dossier="' + DossierBienTypesConst.LOCATION + '"></esk-biens-box-last>');
            $templateCache.put('src/app/legacy/templates/ventes/components/box/last/container.html', '<esk-biens-box-last type-dossier="' + DossierBienTypesConst.VENTE + '"></esk-biens-box-last>');
        }
    })(angularJS);
}
