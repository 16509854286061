import {inject, Injectable} from '@angular/core';
import CCommunes from '@models/communes/collection/communes.collection.model';
import {Observable} from 'rxjs';
import {CCommunesFactory} from '@models/communes/collection/communes.collection.factory';

@Injectable({providedIn: 'root'})
export class CCommunesService {
    private _cCommunesFactory = inject(CCommunesFactory);

    search$(keyword?: string): Observable<CCommunes> {
        return this._cCommunesFactory.get$({keywords: keyword});
    }
}
