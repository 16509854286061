import {Injectable} from '@angular/core';
import LocationMandat from '@models/locations/location/mandats/mandat/location-mandat.model';
import {ILocationMandatApi} from '@models/locations/location/mandats/mandat/location-mandat.interfaces';
import {NgLocationMandat} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class LocationMandatFactory {
    create(locationMandatApi: ILocationMandatApi): LocationMandat {
        return this.createVirgin(locationMandatApi.id.toString(), locationMandatApi.id);
    }

    createVirgin(uuid = LocationMandat.statuts.NEW, id?: number): LocationMandat {
        return new LocationMandat(uuid, id);
    }

    ngCreate(ngLocationMandat: NgLocationMandat): LocationMandat {
        return this.createVirgin(ngLocationMandat.id.toString(), ngLocationMandat.id);
    }
}
