import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {IContactsGroupMemberApi} from '@models/contacts-group/members/member/contacts-group-member.interfaces';

@Injectable({providedIn: 'root'})
export class ContactsGroupMembersApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(contactsGroupUuid: string, contactsGroupMemberUuid: string): Observable<void> {
        return this._modelApiService.delete$('/contacts-groups/' + contactsGroupUuid + '/members/' + contactsGroupMemberUuid);
    }

    save$(contactsGroupUuid: string, contactsGroupMemberApi: IContactsGroupMemberApi): Observable<IContactsGroupMemberApi> {
        return this._modelApiService.post$('/contacts-groups/' + contactsGroupUuid + '/members', contactsGroupMemberApi);
    }

    updateRank$(contactsGroupUuid: string, contactsGroupMemberUuid: string, rank: number): Observable<void> {
        return this._modelApiService.patch$('/contacts-groups/' + contactsGroupUuid + '/members/' + contactsGroupMemberUuid + '/rank', {rank});
    }
}
