import {Component, Directive, ElementRef, inject, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {NgBienDossier, NgDemandeur} from '@legacy/app/managers/ressources';
import {NgDemandeurManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';

@Component({
    selector: 'soq-popover-template',
    template: '<span appNgSoqPopoverTemplate [cadastreEditable]="cadastreEditable" [demandeur]="ngDemandeur"' +
        ' [display]="display" [dossier]="dossier" [placement]="placement" [template]="template" [trigger]="trigger">' +
        '</span>',
})
export class NgSoqPopoverTemplateComponent {
    @Input() cadastreEditable!: boolean;
    @Input() display!: string;
    @Input() dossier!: NgBienDossier;
    @Input() placement!: string;
    @Input() template!: string;
    @Input() trigger!: string;

    private _ngInjectorService = inject(NgInjectorService);

    // Supprimer les injections des anciens manager
    private get ngDemandeurManager(): NgDemandeurManager {
        return this._ngInjectorService.getService<NgDemandeurManager>('DemandeurManager');
    }

    get ngDemandeur(): NgDemandeur {
        return this.ngDemandeurManager.current;
    }
}

@Directive({selector: '[appNgSoqPopoverTemplate]'})
export class NgSoqPopoverTemplateDirective extends UpgradeComponent {
    @Input() cadastreEditable!: boolean;
    @Input() demandeur!: NgDemandeur;
    @Input() dossier!: NgBienDossier;
    @Input() display!: string;
    @Input() placement!: string;
    @Input() template!: string;
    @Input() trigger!: string;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('soqPopoverTemplate', elementRef, injector);
    }
}

@Component({
    selector: 'soq-popover-text',
    template: '<span appNgSoqPopoverText [display]="display" [placement]="placement" [text]="text" [trigger]="trigger">' +
        '</span>',
})
export class NgSoqPopoverTextComponent {
    @Input() display!: string;
    @Input() placement!: string;
    @Input() text!: string;
    @Input() trigger!: string;
}

@Directive({selector: '[appNgSoqPopoverText]'})
export class NgSoqPopoverTextDirective extends UpgradeComponent {
    @Input() display!: string;
    @Input() placement!: string;
    @Input() text!: string;
    @Input() trigger!: string;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('soqPopoverText', elementRef, injector);
    }
}
