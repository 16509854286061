import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {delay, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {ConfigurationActionsMainComponent} from '@features/configuration/actions/configuration-actions-main.component';
import {Router} from '@angular/router';
import {IImagePercentageCoordinates} from '@shared/image/image.interfaces';
import Transfer from '@models/transfers/transfer/transfer.model';
import {ModalService} from '@shared/modal/modal.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import ImageService from '@shared/image/image.service';
import {EtudeSettingsBannerFactory} from '@models/etudes/etude/settings/banner/etude-settings-banner.factory';
import {EtudeSettingsLogoFactory} from '@models/etudes/etude/settings/logo/etude-settings-logo.factory';

@Component({selector: 'layout-configuration-etude', templateUrl: 'layout-configuration-etude.component.html'})
export class AppLayoutConfigurationEtudeComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {
        banner: {
            REMOVE_UPDATE_COMMENTS: 'La bannière actuelle sera supprimée et une nouvelle vous sera demandée.',
            REMOVE_UPDATE_QUESTION: 'Êtes-vous certain de vouloir mettre à jour votre bannière ?',
            TITLE: 'Mise à jour de la bannière',
        },
        logo: {
            REMOVE_UPDATE_COMMENTS: 'Le logo actuel sera supprimé et un nouveau vous sera demandé.',
            REMOVE_UPDATE_QUESTION: 'Êtes-vous certain de vouloir mettre à jour votre logo ?',
            TITLE: 'Mise à jour du logo',
        },
    };
    private _callToActionService = inject(CallToActionService);
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _bannerTransfer!: Transfer<void>;
    private _canUpdateBanner = true;
    private _canUpdateLogo = true;
    private _logoTransfer!: Transfer<void>;
    private readonly _onDestroy$ = new Subject<void>();
    private _openBannerFilePickerSource = new Subject<void>();
    private _openBannerFilePicker$ = this._openBannerFilePickerSource.asObservable();
    private _openLogoFilePickerSource = new Subject<void>();
    private _openLogoFilePicker$ = this._openLogoFilePickerSource.asObservable();

    get BANNER_IMG_LIMIT(): Record<string, number> {
        return EtudeSettingsBannerFactory.imgLimit;
    }

    get bannerTransfer(): Transfer<void> {
        return this._bannerTransfer;
    }

    get canUpdateBanner(): boolean {
        return this._canUpdateBanner;
    }

    get canUpdateLogo(): boolean {
        return this._canUpdateLogo;
    }

    get etude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get LOGO_IMG_LIMIT(): Record<string, number> {
        return EtudeSettingsLogoFactory.imgLimit;
    }

    get logoTransfer(): Transfer<void> {
        return this._logoTransfer;
    }

    get openBannerFilePicker$(): Observable<void> {
        return this._openBannerFilePicker$;
    }

    get openLogoFilePicker$(): Observable<void> {
        return this._openLogoFilePicker$;
    }

    ngOnInit(): void {
        this._callToActionService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(clicked => {
            if (clicked.action === ConfigurationActionsMainComponent.actions.ETUDE_PROFIL) {
                this.etude$.subscribe(currentEtude => this._router.navigateByUrl('/app/annuaires/etudes/' + currentEtude.id.toString()));
            } else if (clicked.action === ConfigurationActionsMainComponent.actions.SAVE) {
                this._callToActionService.actionExec$(this.save$()).subscribe();
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    importBannerFile(file: File): void {
        this._etudeService.uploadBannerCurrent$(file).pipe(
            tap(transfer => this._bannerTransfer = transfer),
            take(1),
        ).subscribe(_ => this._bannerTransfer.race$.pipe(catchError((error: unknown) => {
            if (error === Transfer.ABORT) {
                return of(null);
            }

            return throwError(() => error);
        })).subscribe({
            error: (error: string) => this._toasterService.error(AppLayoutConfigurationEtudeComponent.messages.banner.TITLE, error),
            complete: () => this._bannerTransfer = null!,
        }));
    }

    importLogoFile(file: File): void {
        this._etudeService.uploadLogoCurrent$(file).pipe(
            tap(transfer => this._logoTransfer = transfer),
            take(1),
        ).subscribe(_ => this._logoTransfer.race$.pipe(catchError((error: unknown) => {
            if (error === Transfer.ABORT) {
                return of(null);
            }

            return throwError(() => error);
        })).subscribe({
            error: (error: string) => this._toasterService.error(AppLayoutConfigurationEtudeComponent.messages.logo.TITLE, error),
            complete: () => this._logoTransfer = null!,
        }));
    }

    save$(): Observable<void> {
        // Pour conserver une cohérence avec les autres configurations
        return of(undefined).pipe(delay(400));
    }

    updateBanner(): void {
        this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Mettre à jour',
            comments: AppLayoutConfigurationEtudeComponent.messages.banner.REMOVE_UPDATE_COMMENTS,
            question: AppLayoutConfigurationEtudeComponent.messages.banner.REMOVE_UPDATE_QUESTION,
            title: AppLayoutConfigurationEtudeComponent.messages.banner.TITLE,
            status: ModalService.status.WARNING,
        }).pipe(
            tap(_ => this._canUpdateBanner = false),
            switchMap(isAccepted => {
                if (isAccepted) {
                    return this._etudeService.deleteBannerCurrent$().pipe(
                        delay(1),
                        tap(_ => this._openBannerFilePickerSource.next()),
                    );
                }

                return of(undefined);
            }),
            take(1),
        ).subscribe(_ => this._canUpdateBanner = true);
    }

    updateBannerCrop(cropPercentageCoordinates: IImagePercentageCoordinates): void {
        this._etudeService.updateBannerPositionCurrent$({[ImageService.ORIGIN_Y_KEY]: cropPercentageCoordinates.y}).pipe(take(1)).subscribe();
    }

    updateLogo(): void {
        this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Mettre à jour',
            comments: AppLayoutConfigurationEtudeComponent.messages.logo.REMOVE_UPDATE_COMMENTS,
            question: AppLayoutConfigurationEtudeComponent.messages.logo.REMOVE_UPDATE_QUESTION,
            title: AppLayoutConfigurationEtudeComponent.messages.logo.TITLE,
            status: ModalService.status.WARNING,
        }).pipe(
            tap(_ => this._canUpdateLogo = false),
            switchMap(isAccepted => {
                if (isAccepted) {
                    return this._etudeService.deleteLogoCurrent$().pipe(
                        delay(1),
                        tap(_ => this._openLogoFilePickerSource.next()),
                    );
                }

                return of(undefined);
            }),
            take(1),
        ).subscribe(_ => this._canUpdateLogo = true);
    }
}
