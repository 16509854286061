import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {
    ICVentePrixHistoriesApi
} from '@models/ventes/vente/prix-histories/collection/vente-prix-histories.collection.interfaces';
import {VentePrixHistoryFactory} from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.factory';
import {VentePrixHistoriesApiService} from '@models/ventes/vente/prix-histories/vente-prix-histories.api.service';
import CVentePrixHistories from '@models/ventes/vente/prix-histories/collection/vente-prix-histories.collection.model';
import {IQueryParameters} from '@shared/texts/texts.interfaces';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class CVentePrixHistoriesFactory {
    private _ventePrixHistoryFactory = inject(VentePrixHistoryFactory);
    private _ventePrixHistoryApiFactory = inject(VentePrixHistoriesApiService);

    create(cVentePrixHistoriesApi: ICVentePrixHistoriesApi): CVentePrixHistories {
        const cVentePrixHistories = new CVentePrixHistories();

        cVentePrixHistories.links = new Links(cVentePrixHistoriesApi._links);
        cVentePrixHistories.page = cVentePrixHistoriesApi.page;
        cVentePrixHistories.pages = cVentePrixHistoriesApi.pages;
        cVentePrixHistories.perPage = cVentePrixHistoriesApi.limit;
        cVentePrixHistories.total = cVentePrixHistoriesApi.total;

        if (cVentePrixHistoriesApi._embedded?.items) {
            cVentePrixHistoriesApi._embedded.items
                .filter(ventePrixHistoryApi => ventePrixHistoryApi)
                .map(ventePrixHistoryApi => cVentePrixHistories.results.push(this._ventePrixHistoryFactory.create(ventePrixHistoryApi)));
        }

        cVentePrixHistories.setMoving();

        return cVentePrixHistories;
    }

    get$(vente: Vente, queryParameters?: IQueryParameters): Observable<CVentePrixHistories> {
        return this._ventePrixHistoryApiFactory.getCollection$(vente.id.toString(), queryParameters).pipe(map(cVentePrixHistoriesApi => this.create(cVentePrixHistoriesApi)));
    }
}
