import {inject, Injectable} from '@angular/core';
import {IActualiteApi} from '@models/actualites/actualite/actualite.interfaces';
import Actualite from '@models/actualites/actualite/actualite.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActualitesApiService} from '@models/actualites/actualites.api.service';

@Injectable({providedIn: 'root'})
export class ActualiteFactory {
    private _actualitesApiService = inject(ActualitesApiService);

    create(actualiteApi: IActualiteApi): Actualite {
        const actualite = new Actualite(actualiteApi.uuid);

        actualite.date = actualiteApi.date;
        actualite.read = actualiteApi.read;
        actualite.titre = actualiteApi.titre;
        if (actualiteApi._links) {
            if (actualiteApi._links.contenu) {
                actualite.linkContenu = actualiteApi._links.contenu.href;
            }

            if (actualiteApi._links.self) {
                actualite.linkSelf = actualiteApi._links.self.href;
            }

            if (actualiteApi._links.thumbnail) {
                actualite.linkThumbnail = actualiteApi._links.thumbnail.href;
            }
        }

        return actualite;
    }

    getByLink$(link: string): Observable<Actualite> {
        return this._actualitesApiService.getByLink$(link).pipe(map(actualiteApi => this.create(actualiteApi)));
    }

    read$(actualite: Actualite): Observable<void> {
        return this._actualitesApiService.read$(actualite.uuid);
    }
}
