import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IVentePriceApi, IVentePriceQueryParameters} from '@models/ventes/vente/price/vente-price.interfaces';

@Injectable({providedIn: 'root'})
export class VentePriceApiService {
    private _modelApiService = inject(ModelApiService);

    getForInteractive$(queryParameters: IVentePriceQueryParameters): Observable<IVentePriceApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/calculations/vente-interactive-prices', queryParameters));
    }

    getForSimple$(queryParameters: IVentePriceQueryParameters): Observable<IVentePriceApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/calculations/vente-prices', queryParameters));
    }

    getForViager$(queryParameters: IVentePriceQueryParameters): Observable<IVentePriceApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/calculations/vente-viager-prices', queryParameters));
    }
}
