@if (dossierBien$ | async; as dossierBien) {
  {{ dossierBien.reference }}

  @if (!dossierBien.interne) {
    <div class="tw-inline-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-font-medium tw-whitespace-nowrap tw-bg-marianne tw-text-white"
         title="Confrère">C</div>
  }
} @else {
  <app-loader [classHeight]="'tw-h-5'"/>
}
