import {inject, Injectable} from '@angular/core';
import {
    EtudeDossierQualifiedContactsFactory
} from '@models/etude-dossiers/etude-dossier/qualified-contacts/etude-dossier.qualified-contacts.factory';
import EtudeDossier from '@models/etude-dossiers/etude-dossier/etude-dossier.model';
import {IEtudeDossierApi} from '@models/etude-dossiers/etude-dossier/etude-dossier.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeDossierFactory {
    private _etudeDossierQualifiedContactsFactory = inject(EtudeDossierQualifiedContactsFactory);

    create(etudeDossierApi: IEtudeDossierApi): EtudeDossier {
        const etudeDossier = this.createVirgin();

        etudeDossier.externalId = etudeDossierApi.externalId;
        etudeDossier.externalSource = etudeDossierApi.externalSource;
        etudeDossier.nom = etudeDossierApi.nom;
        etudeDossier.qualifiedContacts = this._etudeDossierQualifiedContactsFactory.create(etudeDossierApi.contacts);
        etudeDossier.type = etudeDossierApi.type;

        return etudeDossier;
    }

    createVirgin(): EtudeDossier {
        return new EtudeDossier();
    }
}
