import angularJS from '@shared/angularJS/global.ng';
import {filter, find} from 'lodash';
import {IModule} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgItemDictionary} from '@legacy/app/managers/ressources';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';

export default function getEskSelectDictionary(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-dictionary></esk-select-dictionary>
         * @param options : {placeholder(string), useDictionaryItem(boolean)}
         */
        module.component('eskSelectDictionary', {
            bindings: {
                ngModel: '=',
                dictionary: '@',
                name: '@',
                onSelect: '=',
                isRequired: '=?',
                onClose: '&?',
                onNoResult: '&?',
                options: '=?',
            },
            controller: Controller,
            templateUrl: template
        });

        /**
         * Display of dictionary
         *
         * @param DictionariesManager
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['DictionariesManager', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            dictionariesManager: NgDictionariesManager,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.isSelected = isSelected;
            $ctrl.isOpenClose = isOpenClose;
            $ctrl.clickWhenNoResult = clickWhenNoResult;

            /**
             * Initialization
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};

                const dictionary = ng2DictionaryFactory.getByName($ctrl.dictionary);

                $ctrl.ngRequired = $ctrl.isRequired === true ? true : null;
                if ($ctrl.options.useDictionaryItem) {
                    $ctrl.itemsDictionary = dictionary.items;
                } else {
                    $ctrl.itemsDictionary = dictionariesManager.createDictionaryFromNg2(dictionary);
                }
            }

            /**
             * Returns true if the clicked item is already selected
             *
             * @param item
             * @returns {boolean}
             */
            function isSelected(item: NgItemDictionary) {
                if (angular.isArray($ctrl.ngModel)) {
                    return find($ctrl.ngModel, {id: item.id});
                }

                return false;
            }

            /**
             * Call onClose on list closing if the method is defined
             */
            function isOpenClose(isOpen: boolean) {
                if (angular.isDefined($ctrl.onClose) && !isOpen) {
                    $ctrl.onClose();
                }
            }

            /**
             * Click when no result
             *
             * @param $select
             */
            function clickWhenNoResult($select: { search: unknown, close: () => void }) {
                $ctrl.onNoResult({search: $select.search});
                $select.close();
            }
        }

        /**
         * Display select dictionary
         *
         * @param $element
         * @param $attrs
         * @returns {string}
         */
        template.$inject = ['$element', '$attrs'];
        function template($element: unknown, $attrs: { isMultiple: unknown }) {
            return 'src/app/legacy/templates/eskimmo/components/select/dictionary-' + (angular.isDefined($attrs.isMultiple) ? 'multiple' : 'simple') + '.html';
        }
    })(angularJS);
}
