import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import loginTemplate from '@legacy/templates/auth/controllers/login';
import resetTemplate from '@legacy/templates/auth/controllers/reset';
import startTemplate from '@legacy/templates/auth/controllers/start';

export default function getAuthTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/auth/controllers/login.html', loginTemplate);
            $templateCache.put('src/app/legacy/templates/auth/controllers/reset.html', resetTemplate);
            $templateCache.put('src/app/legacy/templates/auth/controllers/start.html', startTemplate);
        }
    })(angularJS);
}
