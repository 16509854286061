import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICDemandeurRechercheResultatsApi, ICDemandeurRechercheResultatsQueryParameters
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.interfaces';

@Injectable({providedIn: 'root'})
export class DemandeurRechercheResultatsApiService {
    private _modelApiService = inject(ModelApiService);

    getEtudeCollection$(queryParameters?: ICDemandeurRechercheResultatsQueryParameters): Observable<ICDemandeurRechercheResultatsApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/etude/resultats', queryParameters));
    }

    getRechercheCollection$(rechercheUuid: string, queryParameters?: ICDemandeurRechercheResultatsQueryParameters): Observable<ICDemandeurRechercheResultatsApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/recherches/' + rechercheUuid + '/resultats', queryParameters));
    }
}
