export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.saveEstimation()" novalidate>
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'archive'"></fa-icon> Archiver le bien en estimation</h3>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>Commentaires sur l'archivage</label>

      <app-ng2-textarea [text]="$ctrl.archive.comments" (changed)="$ctrl.archive.comments = $event"></app-ng2-textarea>
    </div>
  </div>

  <div class="modal-footer" ng-switch="$ctrl.sending">
    <p ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
    </p>

    <div ng-switch-default>
      <button class="btn btn-primary btn-o" type="submit"><fa-icon [icon]="'check'"></fa-icon> Enregistrer</button>

      <button class="btn btn-primary" type="button" ng-click="$ctrl.archiveEstimation()">
        <fa-icon [icon]="'archive'"></fa-icon> Confirmer l'archivage
      </button>
    </div>
  </div>
</form>
`;
