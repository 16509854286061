import {Component, inject} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {IModalInformationData} from '@shared/modal/modal.interfaces';

@Component({selector: 'app-modal-information', templateUrl: 'modal.information.component.html'})
export class ModalInformationComponent implements IDynamicComponent {
    readonly ModalStatus = ModalService.status;
    private _modalService = inject(ModalService);
    private _comments!: string;
    private _moduleContact!: string;
    private _status!: string;
    private _title!: string;

    get comments(): string {
        return this._comments;
    }

    set data(modalInformationData: IModalInformationData) {
        this._comments = modalInformationData.comments!;
        this._moduleContact = modalInformationData.moduleContact!;
        this._status = modalInformationData.status!;
        this._title = modalInformationData.title;
    }

    get status(): string {
        return this._status;
    }

    get title(): string {
        return this._title;
    }

    get moduleContact(): string {
        return this._moduleContact;
    }

    response(): void {
        this._modalService.setResponse();
    }
}
