import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getVentesBoxSearch from '@legacy/app/ventes/components/box/search';
import getVentesTemplates from '@legacy/app/ventes/templates';
import getVentesBoxMandatsNatures from '@legacy/app/ventes/components/box/mandats.natures';
import getVentesBoxNegociees from '@legacy/app/ventes/components/box/negociees';
import getVentesBoxMandats from '@legacy/app/ventes/components/box/mandats';
import getVentesBoxNegocieesNatures from '@legacy/app/ventes/components/box/negociees/natures';
import getVentesTableNegocieesNatures from '@legacy/app/ventes/components/table.negociees.natures';
import getVentesList from '@legacy/app/ventes/components/list';
import getVentesChartNegociees from '@legacy/app/ventes/components/chart.negociees';
import getVentesModalBoxSearchCtrl from '@legacy/app/ventes/controllers/modals/box/search';
import getVentesModalBoxMandatsCtrl from '@legacy/app/ventes/controllers/modals/box/mandats';
import getVentesNegocieesEtudeCtrl from '@legacy/app/ventes/controllers_ponents/negociees/etude';
import getVentesNegocieesNaturesCtrl from '@legacy/app/ventes/controllers_ponents/negociees/natures';
import getVenteMandatsCtrl from '@legacy/app/ventes/controllers_ponents/item/mandats';
import getVenteModalContratsCtrl from '@legacy/app/ventes/controllers/modals/item/contrats';
import getVenteDiffusionCtrl from '@legacy/app/ventes/controllers_ponents/item/diffusion';
import getVenteLifetimeCtrl from '@legacy/app/ventes/controllers_ponents/item/lifetime';
import getVenteModalArchiveCtrl from '@legacy/app/ventes/controllers/modals/item/archive';
import getVenteModalCompromisCtrl from '@legacy/app/ventes/controllers/modals/item/compromis';
import getVenteCorrespondantsCtrl from '@legacy/app/ventes/controllers_ponents/item/correspondants';
import getVentesBoxNetworkUpdated from '@legacy/app/ventes/components/box/network-updated';
import getVentesNetworkUpdatedCriteriaCtrl from '@legacy/app/ventes/controllers_ponents/network-updated.criteria';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';

export default function getVentes(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.ventes', [
                'esk.biens',
                'esk.managers',
                'pascalprecht.translate',
                'soqrate',
                'ui.bootstrap',
            ])
            .config(['SoqModalesManagerProvider', modales]);

        // Config / Run
        getVentesTemplates(module);

        // Components
        getVenteModalArchiveCtrl(module);
        getVenteModalCompromisCtrl(module);
        getVentesBoxMandats(module);
        getVentesBoxMandatsNatures(module);
        getVentesBoxNegociees(module);
        getVentesBoxNegocieesNatures(module);
        getVentesBoxNetworkUpdated(module);
        getVentesBoxSearch(module);
        getVentesChartNegociees(module);
        getVentesList(module);
        getVentesModalBoxMandatsCtrl(module);
        getVentesModalBoxMandatsCtrl(module);
        getVentesModalBoxSearchCtrl(module);
        getVentesTableNegocieesNatures(module);

        // Controller
        getVenteCorrespondantsCtrl(module);
        getVenteDiffusionCtrl(module);
        getVenteLifetimeCtrl(module);
        getVenteMandatsCtrl(module);
        getVenteModalContratsCtrl(module);
        getVentesNetworkUpdatedCriteriaCtrl(module);
        getVentesNegocieesEtudeCtrl(module);
        getVentesNegocieesNaturesCtrl(module);

        return module;

        /**
         * Declare modales
         *
         * @param SoqModalesManagerProvider
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('VenteModalArchive', {
                templateUrl: 'src/app/legacy/templates/ventes/controllers/modals/item/archive.html',
                controller: 'VenteModalArchiveCtrl',
                backdrop: 'static'
            });
            soqModalesManagerProvider.addModale('VenteModalCompromis', {
                templateUrl: 'src/app/legacy/templates/ventes/controllers/modals/item/compromis.html',
                controller: 'VenteModalCompromisCtrl'
            });
            soqModalesManagerProvider.addModale('VenteModalContrats', {
                templateUrl: 'src/app/legacy/templates/ventes/controllers/modals/item/contrats.html',
                controller: 'VenteModalContratsCtrl'
            });
            soqModalesManagerProvider.addModale('VentesModalBoxSearch', {
                templateUrl: 'src/app/legacy/templates/ventes/controllers/modals/box/search.html',
                controller: 'VentesModalBoxSearchCtrl'
            });
            soqModalesManagerProvider.addModale('VentesModalBoxMandats', {
                templateUrl: 'src/app/legacy/templates/ventes/controllers/modals/box/mandats.html',
                controller: 'VentesModalBoxMandatsCtrl'
            });
        }
    })(angularJS);
}
