@if (procedure$ | async; as procedure) {
  <div class="tw-h-full tw-max-w-[80rem] tw-mx-auto tw-space-y-8">
    @if (procedure.isNewOrDraft()) {
      <div class="tw-max-w-2xl tw-mx-auto tw-space-y-8 tw-px-4 sm:tw-p-0">
        <ng-container *ngTemplateOutlet="title"/>

        <div class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-gap-8 sm:tw-gap-0">
          <p class="tw-mt-1 tw-mx-auto sm:tw-mx-0 tw-text-lg tw-text-gray-600">
            Les acquéreurs ont tous signé l'offre d'achat.<br>Vous pouvez lancer la signature des vendeurs.
          </p>

          <div class="tw-flex tw-items-center tw-justify-center">
            <button type="button" class="tw-btn-primary-info" (click)="onLaunch()">Lancer la signature</button>
          </div>
        </div>
      </div>
    } @else {
      <div class="tw-h-full tw-space-y-8">
        <div class="tw-relative tw-w-full">
          <ng-container *ngTemplateOutlet="title"/>

          @if (procedure.isDistanciel()) {
            <a [routerLink]="'.'"  fragment="signataires" class="tw-absolute tw-right-2 lg:tw-bottom-0">
              Éditer les signataires
            </a>
          }
        </div>

        @if (procedure.isDistanciel()) {
          <div class="tw-h-[27cm] tw-w-full"><app-pdf [pdfPath$]="documentPath$"/></div>

          <ng-container *ngTemplateOutlet="signataires"/>
        } @else {
          <!-- Ajout de "tw-h-[calc(100%_-_2rem)] xl:-tw-mx-28 tw-block" pour contrer les autres styles au-dessus -->
          <app-procedure-presentiel-sign class="tw-h-[calc(100%_-_2rem)] xl:-tw-mx-28 tw-block"
                                         [cSignataires$]="cProcedureSignataires$" [procedure]="procedure"
                                         (signed)="signed(procedure)"/>
        }
      </div>

      <ng-template #signataires>
        <form [appRoutesAnchorScroll]="'signataires'" class="tw-py-8 tw-space-y-4">
          <app-procedure-signataires-edit [cSignataires$]="cProcedureSignataires$" class="tw-block tw-pt-4"
                                          [options]="procedureSignatairesEditOptions"
                                          [currentDossier]="currentDossier" [procedure]="procedure"
                                          [linkDossiers]="[offreachat.linkDemandeur, offreachat.linkVente]"
                                          (editedSignataires)="editSignataires(procedure, $event)"/>
        </form>
      </ng-template>
    }
  </div>

  <ng-template #title>
    <h3 class="tw-pl-4 sm:tw-pl-0 tw-text-lg tw-text-center tw-font-medium tw-leading-6 tw-text-gray-900">
      Signature en {{procedure.isPresentiel() ? 'présentiel' : 'distanciel'}}
      <app-procedure-statut [procedure]="procedure" class="tw-inline-block"/> pour les
      {{offreachat.isSignatureAcquereur() ? 'acquéreurs' : 'vendeurs'}}
    </h3>
  </ng-template>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
