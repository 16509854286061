import {inject, Injectable} from '@angular/core';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {combineLatest, Observable, of, switchMap, throwError} from 'rxjs';
import Document from '@models/documents/document/document.model';
import {map} from 'rxjs/operators';
import {TemplateService} from '@models/templates/template/template.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {VenteFactory} from '@core/models/ventes/vente/vente.factory';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import Media from '@models/medias/media/media.model';
import {MediaService} from '@models/medias/media/media.service';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Template from '@models/templates/template/template.model';
import {IDocumentVariables} from '@models/documents/document/document.interfaces';

@Injectable({providedIn: 'root'})
export class DocumentService {
    static readonly error = {EMPTY_CONTENT: 'EMPTY'};
    private _bonvisiteService = inject(BonvisiteService);
    private _documentFactory = inject(DocumentFactory);
    private _mediaService = inject(MediaService);
    private _templateService = inject(TemplateService);
    private _venteFactory = inject(VenteFactory);

    getDossiers$(document: Document): Observable<ADossier[]> {
        const dossiers$: Observable<ADossier[]>[] = [];

        if (document.variables) {
            if (document.variables.bonVisiteUuid) {
                dossiers$.push(this._bonvisiteService.getDossiersFromUuid$(document.variables.bonVisiteUuid));
            }

            if (document.variables.venteUuid) {
                dossiers$.push(this._venteFactory.get$(document.variables.venteUuid).pipe(map(vente => [vente])));
            }
        }

        if (dossiers$.length <= 0) {
            return of([]);
        }

        return combineLatest(dossiers$).pipe(
            map(dossierss =>
                dossierss.filter(dossiers => dossiers?.length > 0)
                    .reduce((previousDossiers, dossiers) => previousDossiers.concat(dossiers), [])
            ),
        );
    }

    getMedia$(document: Document, courrier?: Document): Observable<Media> {
        const documents = [document];

        if (courrier) {
            documents.unshift(courrier);
        }

        return this._mediaService.getFromDocuments$(documents, document.titre);
    }

    getMediaDossierBien$(dossierBien: ADossierBien, template: Template, courrierTemplate?: Template, contactId?: number): Observable<Media> {
        return this.saveFromDossierBien$(dossierBien, template, courrierTemplate, contactId).pipe(
            switchMap(([document, courrier]: [Document, Document]) => this.getMedia$(document, courrier)),
        );
    }

    getOne$(id = 0): Observable<Document> {
        if (id > 0) {
            return this._documentFactory.get$(id);
        }

        return of(this._documentFactory.createVirgin());
    }

    getStylesheetLinks$(document: Document): Observable<string[]> {
        return this._templateService.getStylesheetLinksByLink$(document.linkTemplate);
    }

    read$(document: Document, courrier?: Document): Observable<Window> {
        const documents = [document];

        if (courrier) {
            documents.unshift(courrier);
        }

        return this._mediaService.readFromDocuments$(documents, document.titre);
    }

    readFromDossierBien$(dossierBien: ADossierBien, template: Template, courrierTemplate?: Template, contactId?: number): Observable<Window> {
        return this.saveFromDossierBien$(dossierBien, template, courrierTemplate, contactId).pipe(
            switchMap(([document, courrier]: [Document, Document]) => this.read$(document, courrier)),
        );
    }

    readFromFraisAcquisition$(template: Template, variables: IDocumentVariables): Observable<Window> {
        return this.saveFromFraisAcquisition$(template, variables).pipe(switchMap(document => this.read$(document)));
    }

    save$(document: Document): Observable<Document> {
        if (document.uuid !== Document.statuts.NEW && !document.contenu) {
            return throwError(() => DocumentService.error.EMPTY_CONTENT);
        }

        return this._documentFactory.save$(document, {headers: {handledTypeErrors: [EtudeSettingsCityscan.REQUEST_ERRORS.cityscan_not_localizable]}});
    }

    saveFromDossierBien$(dossierBien: ADossierBien, template: Template, courrierTemplate?: Template, contactId?: number): Observable<[Document, Document]> {
        let courrier$ = of(undefined as unknown as Document);

        if (courrierTemplate) {
            courrier$ = this._documentFactory.save$(this._documentFactory.createFromDossierBien(dossierBien, courrierTemplate, {contactId}));
        }

        return combineLatest([this._documentFactory.save$(this._documentFactory.createFromDossierBien(dossierBien, template)), courrier$]);
    }

    saveFromFraisAcquisition$(template: Template, variables: IDocumentVariables): Observable<Document> {
        return this._documentFactory.save$(this._documentFactory.createFromFraisAcquisition(template, variables));
    }
}
