export default `
<ng-switch on="$ctrl.loading">
  <p ng-switch-when="true">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}}
  </p>

  <div ng-switch-default>
    <!-- Envoi -->
    <button type="button" class="btn btn-azure" ng-click="$ctrl.send()">
      <fa-icon [icon]="['far', 'envelope']"></fa-icon> Envoyer
    </button>

    <!-- Impression -->
    <button type="button" class="btn btn-azure" ng-click="$ctrl.generatePDF()">
      <fa-icon icon="print"></fa-icon> Imprimer
    </button>
  </div>
</ng-switch>
`;
