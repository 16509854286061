import angularJS from '@shared/angularJS/global.ng';
import {sortBy} from 'lodash';
import {IModule} from 'angular';
import {switchMap, take, tap} from 'rxjs/operators';
import {combineLatest, from} from 'rxjs';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';
import {NgTemplateManager} from '@legacy/app/managers/managers';

export default function getEskSelectTemplateThumbnails(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-template-thumbnails></esk-select-template-thumbnails>
         * @param options : {loading(boolean)}
         */
        module.component('eskSelectTemplateThumbnails', {
            bindings: {
                codes: '=',
                ngModel: '=',
                colClass: '@?',
                groups: '=?',
                options: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/template-thumbnails.html'
        });

        /**
         * Controller for templates list
         *
         * @param Ng2CTemplatesService
         * @param TemplateManager
         */
        Controller.$inject = ['Ng2CTemplatesService', 'TemplateManager'];
        function Controller(this: any, cTemplatesService: CTemplatesService, templateManager: NgTemplateManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};

                $ctrl.options.loading = true;
                $ctrl.dictionaryGroups = $ctrl.groups ? $ctrl.groups : {items: [false]};
                cTemplatesService.getWithDefaultFirst$($ctrl.codes).pipe(
                    switchMap(templatesCollection => combineLatest(templatesCollection.results.map(template => from(templateManager.createFromNg2(template))))),
                    tap(ngTemplates => $ctrl.ngModel = ngTemplates[0]),
                    take(1),
                ).subscribe({
                    next: ngTemplates => $ctrl.templates = sortBy(ngTemplates, ['name', 'size', 'orientation']),
                    complete: () => $ctrl.options.loading = false,
                });
            }
        }
    })(angularJS);
}
