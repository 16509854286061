import {inject, Injectable} from '@angular/core';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {combineLatest, Observable, of} from 'rxjs';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import {map} from 'rxjs/operators';
import Procedure from '@models/procedures/procedure/procedure.model';
import {
    ProcedureSignataireService
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.service';
import Contact from '@models/contacts/contact/contact.model';
import User from '@models/users/user/user.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {UserFactory} from '@models/users/user/user.factory';

@Injectable({providedIn: 'root'})
export class ProcedureSignatairesService {
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);
    private _procedureSignataireService = inject(ProcedureSignataireService);
    private _userFactory = inject(UserFactory);

    delete$(procedure: Procedure, procedureSignataires: ProcedureSignataire[] = []): Observable<void> {
        if (procedureSignataires.length <= 0) {
            return of(undefined);
        }

        return combineLatest(procedureSignataires.map(procedureSignataire => this._procedureSignataireService.delete$(procedure.uuid, procedureSignataire)))
            .pipe(map(_ => undefined));
    }

    getPotentialsFromDossiers$(dossiers: ADossier[], options = {withResponsableDossier: true}): Observable<(Contact | User)[]> {
        const potentialSignatairess$ = dossiers.filter(dossier => dossier.interne).map(dossier => {
            let users$ = of([] as User[]);

            if (options.withResponsableDossier) {
                users$ = this._userFactory.get$(dossier.responsableId.toString()).pipe(map(user => [user]));
            }

            return users$.pipe(map(users => (dossier.contactsGroup?.members ?? [])
                .map(member => member.contact)
                .filter(contact => !contact.isEntreprise())
                .map(contact => contact as Contact | User)
                .concat(users as (Contact | User)[])));
        });

        return (potentialSignatairess$.length > 0 ? combineLatest(potentialSignatairess$) : of([[]])).pipe(
            map(potentialSignatairess => potentialSignatairess.reduce((previous, current) => previous.concat(current))),
            map(potentialSignataires => {
                const sanitizedPotentialSignataires = [] as (User | Contact)[];

                potentialSignataires.forEach(potentialSignataire => {
                    if (!sanitizedPotentialSignataires.some(sanitizedPotentialSignataire =>
                        sanitizedPotentialSignataire.id === potentialSignataire.id &&
                        sanitizedPotentialSignataire instanceof Contact === potentialSignataire instanceof Contact &&
                        sanitizedPotentialSignataire instanceof User === potentialSignataire instanceof User
                    )) {
                        sanitizedPotentialSignataires.push(potentialSignataire);
                    }
                });

                return sanitizedPotentialSignataires;
            }),
        );
    }

    save$(procedure: Procedure, procedureSignataires: ProcedureSignataire[] = []): Observable<ProcedureSignataire[]> {
        if (procedureSignataires.length <= 0) {
            return of([]);
        }

        return combineLatest(procedureSignataires.map(procedureSignataire => this._procedureSignataireFactory.save$(procedure, procedureSignataire)));
    }

    update$(procedure: Procedure, deletedProcedureSignataires: ProcedureSignataire[] = [], editedProcedureSignataires: ProcedureSignataire[] = []): Observable<void> {
        return combineLatest([this.delete$(procedure, deletedProcedureSignataires), this.save$(procedure, editedProcedureSignataires)])
            .pipe(map(_ => undefined));
    }
}
