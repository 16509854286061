import {inject, Injectable} from '@angular/core';
import CProcedures from '@models/procedures/collection/procedures.collection.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CProceduresFactory} from '@models/procedures/collection/procedures.collection.factory';
import {map, startWith, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ICProceduresQueryParameters} from '@models/procedures/collection/procedures.collection.interfaces';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import Procedure from '@models/procedures/procedure/procedure.model';

@Injectable({providedIn: 'root'})
export class CProceduresService {
    private _cProceduresFactory = inject(CProceduresFactory);
    private _procedureService = inject(ProcedureService);
    private _currentSubject = new BehaviorSubject<CProcedures>(undefined!);
    private readonly _endCurrentSubject = new Subject<void>();

    get current$(): Observable<CProcedures> {
        return this._currentSubject.asObservable();
    }

    addNextToCurrent$(): Observable<CProcedures> {
        return this.current$.pipe(
            take(1),
            switchMap(cProcedures => this._cProceduresFactory.getNext$(cProcedures).pipe(
                tap(newCProcedures => cProcedures.updateNext(newCProcedures)),
                map(_ => cProcedures),
            )),
            tap(nextCProcedures => this._currentSubject.next(nextCProcedures)),
        );
    }

    getFirst$(link: string): Observable<Procedure> {
        return this._cProceduresFactory.getByLink$(link).pipe(map(cProcedures => cProcedures.results[0]));
    }

    initCurrent(queryParameters?: ICProceduresQueryParameters): void {
        this._endCurrentSubject.next();
        this._procedureService.lastEdited$.pipe(
            startWith(1),
            tap(_ => this._currentSubject.next(undefined!)),
            switchMap(_ => this._cProceduresFactory.get$(queryParameters)),
            takeUntil(this._endCurrentSubject),
        ).subscribe(cProcedures => this._currentSubject.next(cProcedures));
    }
}
