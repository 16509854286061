import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {
    ICDossierFileContactsApi, ICDossierFileContactsQueryParameters
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.interfaces';

@Injectable({providedIn: 'root'})
export class DossierFileContactsApiService {
    private _modelApiService = inject(ModelApiService);

    getCollectionByLink$(link: string, queryParameters?: ICDossierFileContactsQueryParameters): Observable<ICDossierFileContactsApi> {
        return this._modelApiService.getCollection$(this._modelApiService.pathWithQueryParameters(link, queryParameters));
    }
}
