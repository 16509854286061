import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IServiceProvider} from 'angular';
import {NgSoqModalesManager, NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';

export default function getSoqModalesManager(module: IModule): void {
    (function (angular) {
        'use strict';

        module.provider('SoqModalesManager', [Provider as unknown as IServiceProvider]);

        /**
         * Manager modales provider
         */
        function Provider(this: NgSoqModalesManagerProvider) {
            const defaultOptions = {animation: true, controllerAs: '$ctrl'};
            const listModales: Record<string, ng.ui.bootstrap.IModalSettings> = {Default: defaultOptions};

            this.addModale = addModale;
            this.$get = Service;

            /**
             * Add modale
             *
             * @param name
             * @param options
             */
            function addModale(name: string, options: ng.ui.bootstrap.IModalSettings) {
                listModales[name] = options;
            }

            /**
             * Manager modales
             *
             * @param $uibModal
             * @returns object
             * @constructor
             */
            Service.$inject = ['$uibModal'];
            function Service($uibModal: ng.ui.bootstrap.IModalService): NgSoqModalesManager {
                let instances: ng.ui.bootstrap.IModalInstanceService[] = [];

                return {open: open, dismissAll: dismissAll};

                /**
                 * Open modal defined by its name
                 *
                 * @param name
                 * @param options
                 * @returns {*}
                 */
                function open(name: string, options: ng.ui.bootstrap.IModalSettings): IPromise<any> {
                    let instance: ng.ui.bootstrap.IModalInstanceService;

                    if (angular.isUndefined(name) || name === '') {
                        name = 'Default';
                    }

                    if (!angular.isObject(listModales[name])) {
                        throw new Error("La modale '" + name + "' n'existe pas.");
                    }

                    instance = $uibModal.open(angular.extend({}, defaultOptions, listModales[name], options));
                    instances.push(instance);

                    return instance.result;
                }

                /**
                 * Dismiss all modales
                 */
                function dismissAll(): void {
                    for (let i = instances.length - 1; i >= 0; i--) {
                        instances[i].dismiss();
                    }

                    instances = [];
                }
            }
        }
    })(angularJS);
};
