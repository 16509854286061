import Collection from '@models/collection/collection.model';
import TemplateBalise from '@models/templates/balises/balise/template-balise.model';

export default class CTemplateBalises extends Collection<TemplateBalise> {
    static readonly models = {
        currentUser: {CODE: 'user_current', LABEL: 'Utilisateur courant'},
        etude: {CODE: 'etude', LABEL: 'Étude'},
        page: {CODE: 'page', LABEL: 'Page'},
        sitePrincipal: {CODE: 'site_principal', LABEL: 'Site principal'},
    };

    findByCode(code: string): TemplateBalise {
        return this.results.find(templateBalise => templateBalise.code === code)!;
    }
}
