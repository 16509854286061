import {
    Component, ComponentRef, HostListener, Inject, inject, OnDestroy, OnInit, Renderer2, ViewContainerRef
} from '@angular/core';
import {delay, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDynamicComponentLoading} from '@shared/shared.interfaces';
import {DropdownContentComponent} from '@shared/dropdown/content/dropdown-content.component';

@Component({selector: 'app-dropdown', template: ''})
export class DropdownComponent implements OnDestroy, OnInit {
    private _dropdownService = inject(DropdownService);
    private _renderer2 = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);
    private _window: Window;
    private _componentRef!: ComponentRef<DropdownContentComponent>;
    private _currentParentElement!: HTMLElement;
    private readonly _onDestroy$ = new Subject<void>();

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    // @todo Utiliser ClickAwayDirective ?
    @HostListener('document:click', ['$event.target'])
    onClick(target: HTMLElement): void {
        if ((this._componentRef?.location?.nativeElement as HTMLElement)?.parentElement?.contains(target)) {
            return;
        }

        this.close();
    }

    ngOnInit(): void {
        this.close();
        this._dropdownService.openElementDynamicComponentLoading$.pipe(
            tap(_ => this.close()),
            filter(elementDynamicComponentLoading => !!elementDynamicComponentLoading),
            tap(_ => this._componentRef = this._viewContainerRef.createComponent(DropdownContentComponent)),
            tap(_ => this._renderer2.setStyle(this._componentRef.location.nativeElement, 'position', 'absolute')),
            tap(([, dynamicComponentLoading]: [HTMLElement, IDynamicComponentLoading]) => this._componentRef.instance.dynamicComponentLoading = dynamicComponentLoading),
            map(([sourceElement,]: [HTMLElement, IDynamicComponentLoading]) => sourceElement),
            tap(sourceElement => this._currentParentElement = sourceElement.parentElement!),
            delay(1),
            tap(_ => this._componentRef.instance.show = true),
            tap((sourceElement: HTMLElement) => {
                this._renderer2.setStyle(sourceElement.parentElement, 'position', 'relative');
                this._renderer2.setStyle(sourceElement.parentElement, 'z-index', '99');
                this._renderer2.appendChild(sourceElement.parentElement, this._componentRef.location.nativeElement);
                this.setComponentRefStyle(sourceElement);
            }),
            switchMap(_ => this._componentRef.instance.closed),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this.close());
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    close(): void {
        this._viewContainerRef.clear();
        if (this._currentParentElement) {
            this._renderer2.setStyle(this._currentParentElement, 'z-index', 'auto');
        }
    }

    setComponentRefStyle(sourceElement: HTMLElement): void {
        const element = this._componentRef.location.nativeElement as HTMLElement;
        const scrollWidth = 15;

        const elementBoundingClientRect = element.getBoundingClientRect();
        const elementComputedStyle = this.getComputedStyle(element);
        const elementHeight = +elementComputedStyle.height.replace('px', '');
        const elementWidth = +elementComputedStyle.width.replace('px', '');
        const parentElement = element.parentElement!;
        const parentElementBoundingClientRect = parentElement.getBoundingClientRect();
        const parentElementWhoOverflowHidden = this.getParentElementWhoOverflowHidden(element);
        const parentElementWhoOverflowHiddenBoundingClientRect = parentElementWhoOverflowHidden.getBoundingClientRect();
        const parentElementWhoOverflowHiddenComputedStyle = this.getComputedStyle(parentElementWhoOverflowHidden);
        const parentElementWhoOverflowHiddenHeight = +parentElementWhoOverflowHiddenComputedStyle.height.replace('px', '');
        const parentElementWhoOverflowHiddenWidth = +parentElementWhoOverflowHiddenComputedStyle.width.replace('px', '');
        const sourceElementBoundingClientRect = sourceElement.getBoundingClientRect();
        const sourceElementComputedStyle = this.getComputedStyle(sourceElement);
        const sourceElementBorderLeftWidth = +sourceElementComputedStyle.borderLeftWidth.replace('px', '');
        const sourceElementBorderRightWidth = +sourceElementComputedStyle.borderRightWidth.replace('px', '');
        const sourceElementBorderWidth = sourceElementBorderLeftWidth + sourceElementBorderRightWidth;
        const sourceElementWidth = +sourceElementComputedStyle.width.replace('px', '');

        // @todo Offre d'achat et bon de visite, sur la rédaction, un overflow horizontal se crée
        const futurElementX = sourceElementBoundingClientRect.left - parentElementWhoOverflowHiddenBoundingClientRect.left + sourceElementWidth + elementWidth + sourceElementBorderWidth;
        if (futurElementX > (parentElementWhoOverflowHiddenWidth - scrollWidth)) {
            // Placement à gauche de la source
            this._renderer2.setStyle(element, 'left', (sourceElementBoundingClientRect.left - parentElementBoundingClientRect.left - (elementWidth + sourceElementBorderWidth)).toString() + 'px');
        } else {
            // Placement à droite de la source
            this._renderer2.setStyle(element, 'left', (sourceElementBoundingClientRect.left - parentElementBoundingClientRect.left + sourceElementWidth + sourceElementBorderWidth).toString() + 'px');
        }

        const futurElementY = elementBoundingClientRect.top - parentElementWhoOverflowHiddenBoundingClientRect.top + elementHeight;
        if (futurElementY > parentElementWhoOverflowHiddenHeight) {
            // Placement jusqu'au bas de la source
            this._renderer2.setStyle(element, 'bottom', (parentElementBoundingClientRect.bottom - sourceElementBoundingClientRect.bottom).toString() + 'px');
        } else {
            // Placement à partir du haut de la source
            this._renderer2.setStyle(element, 'top', (sourceElementBoundingClientRect.top - parentElementBoundingClientRect.top).toString() + 'px');
        }
    }

    getComputedStyle(htmlElement: HTMLElement): CSSStyleDeclaration {
        try {
            // @todo Rechercher cette utilisation et factoriser
            return this._window.getComputedStyle(htmlElement);
        } catch (e) {
            return {
                borderLeftWidth: '',
                borderRightWidth: '',
                height: '',
                overflow: '',
                paddingLeft: '',
                width: '',
            } as CSSStyleDeclaration;
        }
    }

    getParentElementWhoOverflowHidden(element: HTMLElement): HTMLElement {
        if (!element.parentElement) {
            return element;
        }

        // @todo Gestion du overflow-x et/ou overflow-y ?
        if (this.getComputedStyle(element.parentElement).overflow !== 'hidden') {
            return this.getParentElementWhoOverflowHidden(element.parentElement);
        }

        return element.parentElement;
    }
}
