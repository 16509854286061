import {IModule, IPromise, IQService} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgAuthenticationDataInit, NgAuthenticationService} from '@legacy/app/auth/auth';
import {NgClientService} from '@legacy/app/client/client';

export default function getAuthStartCtrl(module: IModule): void {
    (_ => {
        'use strict';

        module.component('eskAuthStartCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/auth/controllers/start.html',
        });

        /**
         * Authenticate user
         *
         * @param AuthenticationService
         * @param Ng2RouterStateForNgService
         * @param $q
         * @param ClientService
         * @param AuthenticationDataInit
         */
        Controller.$inject = ['AuthenticationService', 'Ng2RouterStateForNgService', '$q', 'ClientService', 'AuthenticationDataInit'];
        function Controller(this: any,
                            authenticationService: NgAuthenticationService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $q: IQService,
                            clientService: NgClientService,
                            authenticationDataInit: NgAuthenticationDataInit) {
            const $ctrl = this;
            let authPromise: IPromise<unknown> = $q.reject();

            $ctrl.loaded = {authentication: true};
            clientService.razCache();
            if (authenticationService.isAuthenticated()) {
                authPromise = $q.resolve();
            } else if (authenticationService.hasRefreshToken()) {
                authPromise = authenticationService.authenticateWithRefreshToken();
            }

            authPromise
                .then(_ => authenticationDataInit.loadDictionaries(true))
                .then(_ => initProfileAndDictionaries())
                // Relance pour remettre à jour correctement l'utilisateur car les dictionnaires viennent d'être mis à jour
                // Pour éviter ça, il faudrait qu'au moment de leur utilisation récupérer les dictionnaires en asynchrone
                .then(update => update ? initProfileAndDictionaries() : undefined)
                .then(_ => authenticationService.goToSavedState())
                .catch(_ => ng2RouterStateForNgService.navigateByUrl('/auth/login'));

            function initProfileAndDictionaries(): IPromise<boolean> {
                $ctrl.loaded.dictionaries = false;

                return authenticationDataInit.getCurrentProfile()
                    .then(_ => $ctrl.loaded.currentProfile = true)
                    .then(_ => authenticationDataInit.loadDictionaries())
                    .then(update => {
                        $ctrl.loaded.dictionaries = true;

                        return update;
                    });
            }
        }
    })();
}
