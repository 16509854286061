export default `
<div class="row">
  <div class="col-lg-6">
    <!-- Agencement intérieur -->
    <fieldset ng-if="$ctrl.search.option.source !== 'reference'
                        && ($ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'nombreLogements')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'cave')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'sousSol')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'grenier')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'ascenseur')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'dernierEtage')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'bienRdc')
                        || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'cheminee'))" class="text-justify">
      <legend>Agencement intérieur</legend>

      <!-- Nombre de logements -->
      <div class="form-group" ng-if="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'nombreLogements')">
        <label>Nombre de Logements entre</label>

        <app-form-range-slider [range]="{max: $ctrl.search.option.nombreLogementsMax, min: $ctrl.search.option.nombreLogementsMin}"
                               [options]="{ceil: 10, floor: 0, showTicks: true}"
                               (range-updated)="$ctrl.onSelectLogementsRange($event)"></app-form-range-slider>
      </div>

      <div class="row">
        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'cave')">
          <soq-input-radio-ynn title="Cave" radio-id="'radio-true-cave'" ng-model="$ctrl.search.option.cave"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'sousSol')">
          <soq-input-radio-ynn title="Sous-sol" radio-id="'radio-true-sousSol'" ng-model="$ctrl.search.option.sousSol"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'grenier')">
          <soq-input-radio-ynn title="Grenier" radio-id="'radio-true-grenier'" ng-model="$ctrl.search.option.grenier"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'ascenseur')">
          <soq-input-radio-ynn title="Ascenseur" radio-id="'radio-true-ascenseur'"
                               ng-model="$ctrl.search.option.ascenseur"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group"
             ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'dernierEtage')">
          <soq-input-radio-ynn title="Bien au dernier étage" radio-id="'radio-true-dernierEtage'"
                               ng-model="$ctrl.search.option.dernierEtage"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'bienRdc')">
          <soq-input-radio-ynn title="Bien au RDC" radio-id="'radio-true-rdc'" ng-model="$ctrl.search.option.rdc"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'cheminee')">
          <soq-input-radio-ynn title="Cheminée" radio-id="'radio-true-cheminee'" ng-model="$ctrl.search.option.cheminee"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>
      </div>
    </fieldset>

    <!-- Agencement extérieur -->
    <fieldset ng-if="$ctrl.search.option.source !== 'reference'" class="text-justify">
      <legend>{{"Agencement extérieur" | translate}}</legend>

      <div class="row">
        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'annexe')">
          <soq-input-radio-ynn title="Annexes" radio-id="'radio-true-annexe'" ng-model="$ctrl.search.option.annexe"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'piscine')">
          <soq-input-radio-ynn title="Piscine" radio-id="'radio-true-piscine'" ng-model="$ctrl.search.option.piscine"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'pointEau')">
          <soq-input-radio-ynn title="Point d'eau" radio-id="'radio-true-pointEau'"
                               ng-model="$ctrl.search.option.pointEau"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 form-group"
             ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'terrasseOuBalcon')">
          <div class="checkbox clip-check check-purple">
            <input type="checkbox" id="radio-true-terrasse-ou-balcon" ng-change="$ctrl.changeTerrasseOuBalcon()"
                   ng-model="$ctrl.search.option.terrasseOuBalcon">

            <label for="radio-true-terrasse-ou-balcon" class="margin-right-0">Terrasse ou Balcon</label>

            <a popover-trigger="'outsideClick'" popover-placement="top" type="button" class="btn btn-o btn-null"
               uib-popover="La notion de 'Terrasse ou Balcon' regroupe celle de la 'Terrasse' et du 'Balcon'. Si vous voulez spécifiez précisément ces 2 notions, désélectionnez le champ.">
              <span class="badge badge-info">?</span>
            </a>
          </div>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'terrasse')">
          <soq-input-radio-ynn title="Terrasse" radio-id="'radio-true-terrasse'" ng-model="$ctrl.search.option.terrasse"
                               is-disabled="$ctrl.isDisabled('terrasse', 'terrasseOuBalcon')"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'balcon')">
          <soq-input-radio-ynn title="Balcon" radio-id="'radio-true-balcon'" ng-model="$ctrl.search.option.balcon"
                               is-disabled="$ctrl.isDisabled('balcon', 'terrasseOuBalcon')"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 form-group"
             ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'stationnement')">
          <div class="checkbox clip-check check-purple">
            <input type="checkbox" id="radio-true-stationnement" ng-change="$ctrl.changeStationnement()"
                   ng-model="$ctrl.search.option.stationnement">

            <label for="radio-true-stationnement" class="margin-right-0">Stationnement</label>

            <a popover-trigger="'outsideClick'" popover-placement="top" type="button" class="btn btn-o btn-null"
              uib-popover="La notion de 'Stationnement' regroupe celle du 'Garage' et du 'Parking'. Si vous voulez spécifiez précisément ces 2 notions, désélectionnez le champ.">
              <span class="badge badge-info">?</span>
            </a>
          </div>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'garage')">
          <soq-input-radio-ynn title="Garage" radio-id="'radio-true-garage'"
                               is-disabled="$ctrl.isDisabled('garage', 'stationnement')"
                               ng-model="$ctrl.search.option.garage"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'parking')">
          <soq-input-radio-ynn title="Parking" radio-id="'radio-true-parking'"
                               is-disabled="$ctrl.isDisabled('parking', 'stationnement')"
                               ng-model="$ctrl.search.option.parking"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}">
          </soq-input-radio-ynn>
        </div>
      </div>
    </fieldset>

    <!-- Accessibilité / Environnement -->
    <fieldset ng-if="$ctrl.search.option.source !== 'reference'" class="text-justify">
      <legend>Accessibilité / Environnement</legend>

      <div ng-if="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'plainPieds')" class="margin-bottom-20">
        <label class="margin-bottom-10">Plain-pieds</label>

        <app-dictionary-checkbox [model]="$ctrl.plainPieds" [name]="$ctrl.PLAIN_PIEDS"
                                 (selected)="$ctrl.onSelectPlainPieds($event)"></app-dictionary-checkbox>
      </div>

      <div class="row">
        <div class="col-sm-4 form-group"
             ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'pieceEauRdc')">
          <soq-input-radio-ynn title="Pièce(s) d'eau au RDC" radio-id="'radio-true-pieceEauRdc'"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}"
                               ng-model="$ctrl.search.option.pieceEauRdc">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'chambreRdc')">
          <soq-input-radio-ynn title="Chambre(s) au RDC" radio-id="'radio-true-chambreRdc'"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}"
                               ng-model="$ctrl.search.option.chambreRdc">
          </soq-input-radio-ynn>
        </div>

        <div class="col-sm-4 form-group" ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'accesPmr')">
          <soq-input-radio-ynn title="Accès PMR" radio-id="'radio-true-accesPmr'"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}"
                               ng-model="$ctrl.search.option.accesPmr">
          </soq-input-radio-ynn>
        </div>
      </div>
    </fieldset>

    <!-- Époques -->
    <div ng-if="$ctrl.search.option.source === 'reference'">
      <fieldset class="fieldset-epoques">
        <legend>{{"Époques" | translate}}</legend>

        <esk-select-dictionary ng-model="$ctrl.search.epoques" name="epoques"
                               dictionary="{{$ctrl.CONSTRUCTION_EPOQUES}}" is-multiple></esk-select-dictionary>
      </fieldset>
    </div>
  </div>

  <div class="col-lg-6">
    <!-- Surfaces -->
    <fieldset class="text-justify">
      <legend>{{"Surfaces" | translate}}</legend>

      <div class="row">
        <!-- Superficie -->
        <div class="col-sm-6 form-group">
          <label for="search-option-superficie-min">{{$ctrl.getLabelSuperficie() | translate}}</label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input id="search-option-superficie-min" type="text"
                       class="form-control" ng-model="$ctrl.search.superficieMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input type="text" class="form-control"
                       ng-model="$ctrl.search.superficieMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Surface cadastrale -->
        <div class="col-sm-6 form-group"
             ng-if="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'surfaceCadastrale')">
          <label for="search-option-surface-cadastrale-min">{{"Surface cadastrale" | translate}}</label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input id="search-option-surface-cadastrale-min" type="text"
                       class="form-control" ng-model="$ctrl.search.option.surfaceCadastraleMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input type="text" class="form-control"
                       ng-model="$ctrl.search.option.surfaceCadastraleMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Surface bâtie au sol -->
        <div class="col-sm-6 form-group"
             ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'surfaceBatie')">
          <label for="search-option-surface-batie-min">{{"Surface bâtie au sol" |
            translate}}</label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input id="search-option-surface-batie-min" type="text"
                       class="form-control"
                       ng-model="$ctrl.search.option.surfaceBatieMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input type="text" class="form-control"
                       ng-model="$ctrl.search.option.surfaceBatieMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Surface non batie -->
        <div class="col-sm-6 form-group"
             ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'surfaceNonBatie')">
          <label for="search-option-surface-non-batie-min">
            {{"Surface non bâtie" | translate}}
          </label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input id="search-option-surface-non-batie-min" type="text"
                       class="form-control" ng-model="$ctrl.search.option.surfaceNonBatieMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input type="text" class="form-control"
                       ng-model="$ctrl.search.option.surfaceNonBatieMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Surface du séjour -->
        <div class="col-sm-6 form-group"
             ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'surfaceSejour')">
          <label for="search-option-surface-sejour-min">
            {{"Surface du séjour"|translate}}
          </label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input id="search-option-surface-sejour-min" type="text"
                       class="form-control"
                       ng-model="$ctrl.search.option.surfaceSejourMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group input-group-number input-group-area">
                <input type="text" class="form-control"
                       ng-model="$ctrl.search.option.surfaceSejourMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Vente -->
    <fieldset ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.typeSearch === $ctrl.DossierTypesConst.VENTE">
      <legend>Vente</legend>

      <div class="row">
        <!-- Type -->
        <div class="col-xs-12 form-group">
          <label>Type de biens en vente à exclure</label>

          <div class="row">
            <div class="col-sm-4">
              <div class="checkbox clip-check check-purple">
                <input type="checkbox" id="search-type-vente-traditionnelle"
                       ng-model="$ctrl.search.vente.excludeVenteTraditionnelle">

                <label for="search-type-vente-traditionnelle">Ventes traditionnelles</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="checkbox clip-check check-purple">
                <input type="checkbox" id="search-type-vente-viager" ng-model="$ctrl.search.vente.excludeVenteViager">

                <label for="search-type-vente-viager">Ventes viagères</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="checkbox clip-check check-purple"
                   title="{{$ctrl.search.vente.budgetHni ? '' : 'Il est obligatoire d\\'inclure les honoraires dans le budget afin de trouver des biens en vente interactive.'}}">
                <input type="checkbox" id="search-type-vente-interactive"
                       ng-model="$ctrl.search.vente.excludeVenteInteractive"
                       ng-disabled="!$ctrl.search.vente.budgetHni">

                <label for="search-type-vente-interactive">Ventes interactives</label>
              </div>
            </div>
          </div>
        </div>

        <!-- Fourchette de rente -->
        <div ng-if="!$ctrl.search.vente.excludeVenteViager" class="col-xs-12 col-sm-6 form-group">
          <label for="search-rente-min">Rente</label>

          <div>
            <div class="input-group input-group-number input-group-currency pull-left">
              <input id="search-rente-min" type="text" class="form-control"
                     ng-model="$ctrl.search.vente.renteMin" placeholder="Minimum"
                     soq-only-digits>

              <span class="input-group-addon">€</span>
            </div>

            <div class="input-group input-group-number input-group-currency pull-left">
              <input type="text" class="form-control" ng-model="$ctrl.search.vente.renteMax"
                     placeholder="{{'Maximum'|translate}}" soq-only-digits>

              <span class="input-group-addon">€</span>
            </div>
          </div>
        </div>

        <!-- Inclure les honoraires dans le budget -->
        <div ng-if="!$ctrl.search.vente.excludeVenteTraditionnelle || !$ctrl.search.vente.excludeVenteViager"
             class="col-xs-12 col-sm-6 padding-top-20">
          <div class="checkbox clip-check check-purple"
               title="Recherche effectuée sur les prix en Honoraires de Négociation Inclus">
            <input type="checkbox" id="search-vente-prix-hni" ng-model="$ctrl.search.vente.budgetHni"
                   ng-change="$ctrl.onChangeRechercheBudgetHni()">

            <label for="search-vente-prix-hni">Inclure les honoraires dans le budget</label>
          </div>
        </div>

        <div class="col-xs-12 form-group">
          <!-- Mitoyennetés -->
          <div ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'mitoyennetes')">
            <label class="margin-bottom-10">Occupation du bien</label>

            <app-dictionary-checkbox [model]="$ctrl.occupations" [name]="$ctrl.OCCUPATIONS"
                                     (selected)="$ctrl.onSelectOccupations($event)"></app-dictionary-checkbox>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Location -->
    <fieldset ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.typeSearch === $ctrl.DossierTypesConst.LOCATION">
      <legend>Location</legend>

      <div class="row">
        <!-- Charges incluses -->
        <div class="col-xs-12 col-sm-3 col-lg-4">
          <div class="checkbox clip-check check-purple">
            <input type="checkbox" id="search-location-charges-incluses"
                   ng-model="$ctrl.search.location.chargesIncluses">

            <label for="search-location-charges-incluses">Charges incluses</label>
          </div>
        </div>

        <!-- Meublé -->
        <div class="col-xs-7">
          <soq-input-radio-ynn radio-id="search-location-meuble" ng-model="$ctrl.search.location.meuble"
                               title="Meublé" options="{labels:{choiceNull:'Non renseigné'}}"></soq-input-radio-ynn>
        </div>
      </div>
    </fieldset>

    <!-- Autres -->
    <fieldset class="text-justify">
      <legend>{{"Autres" | translate}}</legend>

      <div ng-if="$ctrl.search.option.source === 'reference'" class="row">
        <!-- Loyer -->
        <div class="col-sm-6 form-group">
          <label>{{"Loyer"|translate}}</label>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group input-group input-group-number input-group-currency">
                <input type="text" class="form-control" ng-model="$ctrl.search.location.loyerMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>

                <span class="input-group-addon">€</span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group input-group input-group-number input-group-currency">
                <input type="text" class="form-control" ng-model="$ctrl.search.location.loyerMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>

                <span class="input-group-addon">€</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Nombre de niveaux -->
        <div class="col-sm-6 form-group">
          <label>Nombre de niveaux</label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group">
                <input type="text" class="form-control" ng-model="$ctrl.search.option.nombreEtagesMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group">
                <input type="text" class="form-control" ng-model="$ctrl.search.option.nombreEtagesMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ng-if="$ctrl.search.option.source !== 'reference'" class="row">
        <!-- Année de construction -->
        <div class="col-xs-12 col-sm-6 form-group"
             ng-if="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'anneeConstruction')">
          <label>{{"Année de construction"|translate}}</label>

          <div class="row">
            <div class="col-sm-6">
              <div class="input-group">
                <input type="text" class="form-control" ng-model="$ctrl.search.option.anneeConstructionMin"
                       placeholder="{{'Minimum'|translate}}" soq-only-digits>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group">
                <input type="text" class="form-control" ng-model="$ctrl.search.option.anneeConstructionMax"
                       placeholder="{{'Maximum'|translate}}" soq-only-digits>
              </div>
            </div>
          </div>
        </div>

        <!-- Résidence service -->
        <div class="col-xs-12 col-sm-6 form-group"
             ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'residenceService')">
          <soq-input-radio-ynn title="Résidence service" radio-id="'radio-true-residenceService'"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}"
                               ng-model="$ctrl.search.option.residenceService">
          </soq-input-radio-ynn>
        </div>
      </div>


      <div ng-if="$ctrl.search.option.source !== 'reference'" class="row">
        <!-- Vues -->
        <div class="col-xs-12 col-sm-6 form-group">
          <label class="margin-bottom-10">Vues</label>

          <esk-select-dictionary ng-model="$ctrl.search.vues" name="vues" dictionary="{{$ctrl.VUES}}" is-multiple>
          </esk-select-dictionary>
        </div>

        <!-- Bien d'exception -->
        <div class="col-xs-12 col-sm-6 form-group"
             ng-show="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'exceptionnel')">
          <soq-input-radio-ynn title="Bien d'exception" radio-id="'radio-true-exceptionnel'"
                               options="{labels:{choiceNull:'Indifférent'}, typeDossier: $ctrl.DossierTypesConst.DEMANDEUR}"
                               ng-model="$ctrl.search.option.exceptionnel">
          </soq-input-radio-ynn>
        </div>
      </div>

      <!-- États -->
      <div
        ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'etats')"
        class="margin-bottom-20">
        <label class="margin-bottom-10">États</label>

        <app-dictionary-checkbox [model]="$ctrl.etats" [name]="$ctrl.ETATS"
                                 (selected)="$ctrl.onSelectEtats($event)"></app-dictionary-checkbox>
      </div>

      <!-- Mitoyennetés -->
      <div
        ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'mitoyennetes')"
        class="margin-bottom-20">
        <label class="margin-bottom-10">Mitoyennetés</label>

        <app-dictionary-checkbox [model]="$ctrl.mitoyennetes" [name]="$ctrl.MITOYENNETES"
                                 (selected)="$ctrl.onSelectMitoyennetes($event)"></app-dictionary-checkbox>
      </div>

      <!-- Uniquement mes biens -->
      <div ng-if="$ctrl.search.option.source !== 'reference' && !$ctrl.options.disabledFields.uniquementBiensNotaire"
           class="checkbox no-marge-padding clip-check check-purple">
        <input type="checkbox" id="search-option-biens-etude"
               ng-model="$ctrl.search.option.uniquementBiensNotaire">

        <label for="search-option-biens-etude">
          {{"Uniquement les biens de l'étude"|translate}}
        </label>
      </div>
    </fieldset>
  </div>
</div>
`;
