import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EtudeDossierFactory} from '@models/etude-dossiers/etude-dossier/etude-dossier.factory';
import {EtudeDossiersApiService} from '@models/etude-dossiers/etude-dossiers.api.service';
import {
    ICEtudeDossiersApi, ICEtudeDossiersQueryParameters
} from '@models/etude-dossiers/collection/etude-dossiers.collection.interfaces';
import CEtudeDossiers from '@models/etude-dossiers/collection/etude-dossiers.collection.model';

@Injectable({providedIn: 'root'})
export class CEtudeDossiersFactory {
    private _etudeDossierFactory = inject(EtudeDossierFactory);
    private _etudeDossiersApiService = inject(EtudeDossiersApiService);

    create(cEtudeDossiersApi: ICEtudeDossiersApi): CEtudeDossiers {
        const cEtudeDossiers = this.createVirgin();

        cEtudeDossiers.links = new Links(cEtudeDossiersApi._links);
        cEtudeDossiers.page = cEtudeDossiersApi.page;
        cEtudeDossiers.pages = cEtudeDossiersApi.pages;
        cEtudeDossiers.perPage = cEtudeDossiersApi.limit;
        cEtudeDossiers.total = cEtudeDossiersApi.total;
        if (cEtudeDossiersApi._embedded?.items) {
            cEtudeDossiersApi._embedded.items
                .filter(etudeDossierApi => etudeDossierApi)
                .map(etudeDossierApi => cEtudeDossiers.results.push(this._etudeDossierFactory.create(etudeDossierApi)));
        }

        return cEtudeDossiers;
    }

    createVirgin(): CEtudeDossiers {
        const cEtudeDossiers = new CEtudeDossiers();

        cEtudeDossiers.links = new Links();

        return cEtudeDossiers;
    }

    get$(queryParameters?: ICEtudeDossiersQueryParameters): Observable<CEtudeDossiers> {
        return this._etudeDossiersApiService.getCollection$(queryParameters).pipe(map(cEtudeDossiersApi => this.create(cEtudeDossiersApi)));
    }

    getNext$(cEtudeDossiers: CEtudeDossiers): Observable<CEtudeDossiers> {
        return this._etudeDossiersApiService.getCollectionByLink$(cEtudeDossiers.links.next).pipe(map(cEtudeDossiersApi => this.create(cEtudeDossiersApi)));
    }
}
