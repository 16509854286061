import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgVenteManager} from '@legacy/app/managers/managers';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';
import Vente from '@models/ventes/vente/vente.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';

export default function getBienPopover(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-popover></esk-vente-popover>
         */
        module.component('eskBienPopover', {
            bindings: {paramDossier: '=dossier'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/popover.html'
        });

        /**
         * Controller for Bien Popover
         *
         * VenteManager
         * Ng2VenteFactory
         * @constructor
         */
        Controller.$inject = ['VenteManager', 'Ng2VenteFactory'];
        function Controller(this: any, venteManager: NgVenteManager, ng2VenteFactory: VenteFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.showBottomInformations = showBottomInformations;
            $ctrl.hasNoValue = hasNoValue;
            $ctrl.DIAG_DISPO = DiagnosticStatutsConst.DISPONIBLE;
            $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS = Vente.chargesType.HONO_NEGO_INCLUS;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.VIAGER = Vente.types.VIAGER;

            /**
             * Initialization method
             */
            function $onInit() {
                switch ($ctrl.paramDossier._esk.typeDossier) {
                    case DossierBienTypesConst.VENTE:
                        venteManager.getOneById($ctrl.paramDossier.id).then(vente => {
                            $ctrl.dossier = vente;
                            $ctrl.ng2Dossier = ng2VenteFactory.ngCreate(vente);
                        });
                        break;
                    default:
                        throw new Error("Le type : " + $ctrl.paramDossier._esk.typeDossier + " du bien id:" + $ctrl.dossier.id + " n'est pas défini");
                }
            }

            /**
             * Check if there is Bottom Information
             *
             * @return boolean
             */
            function showBottomInformations() {
                return !(!$ctrl.dossier.bien.superficie &&
                    !$ctrl.dossier.bien.option.nombreChambres &&
                    !$ctrl.dossier.bien.option.nombrePieces &&
                    !$ctrl.dossier.bien.option.exposition &&
                    !$ctrl.dossier.bien.option.anneeConstruction &&
                    !$ctrl.dossier.bien.option.chambreRdc);
            }

            /**
             * Check if there is nos value to display
             *
             * @return boolean
             */
            function hasNoValue() {
                return !$ctrl.dossier.bien.titre &&
                    !$ctrl.dossier.bien.descriptif &&
                    !$ctrl.dossier.bien.responsableDossier &&
                    !$ctrl.dossier._esk.infosPricing &&
                    !$ctrl.dossier.bien.superficie &&
                    !$ctrl.dossier.bien.option.nombreChambres &&
                    !$ctrl.dossier.bien.option.nombrePieces &&
                    !$ctrl.dossier.bien.option.exposition &&
                    !$ctrl.dossier.bien.option.anneeConstruction &&
                    !$ctrl.dossier.bien.option.chambreRdc;
            }
        }
    })(angularJS);
}
