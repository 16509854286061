import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEventProgress} from '@core/api/api.interfaces';
import {HttpResponse} from '@angular/common/http';
import Transfer from '@models/transfers/transfer/transfer.model';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';

@Injectable({providedIn: 'root'})
export class TransferFactory {
    constructor(errorConfigurationService: ErrorConfigurationService) {
        errorConfigurationService.addIgnoredError(Transfer.ABORT);
    }

    create<T, U = T>(request$: Observable<HttpEventProgress | HttpResponse<T>>, creation: (responseBrute: T) => U = () => undefined!): Transfer<T, U> {
        return new Transfer<T, U>(request$, creation);
    }
}
