export default `
<ui-select ng-model="$ctrl.ngModel" on-select="$ctrl.onSelect($item, $model)" name="$ctrl.name"
           ng-if="$ctrl.quartiers.length > 0">
  <ui-select-match placeholder="Rechercher un quartier" allow-clear="true">{{$select.selected.name}}</ui-select-match>

  <ui-select-choices repeat="quartier in $ctrl.quartiers | filter: $select.search">
    <span ng-bind-html="quartier.name|highlight: $select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>

<p class="text-muted" ng-if="$ctrl.loading"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon></p>

<p class="text-muted" ng-if="!$ctrl.loading && $ctrl.quartiers.length === 0">Non disponible pour cette commune.</p>
`;
