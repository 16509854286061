import {Component, Input} from '@angular/core';
import Location from '@models/locations/location/location.model';
import {ILocationPricingOptions} from '@features/locations/locations.interfaces';

@Component({selector: 'app-location-pricing', templateUrl: 'location-pricing.component.html'})
export class AppLocationPricingComponent {
    static readonly initLocationPricingOptions: ILocationPricingOptions = {details: 'simple'};
    private _options: ILocationPricingOptions = {...AppLocationPricingComponent.initLocationPricingOptions};
    private _isPrive = false;
    private _isPublic = false;
    private _location!: Location;

    get isPrive(): boolean {
        return this._isPrive;
    }

    get isPublic(): boolean {
        return this._isPublic;
    }

    get options(): ILocationPricingOptions {
        return this._options;
    }

    @Input()
    set options(value: ILocationPricingOptions) {
        this._options = {...AppLocationPricingComponent.initLocationPricingOptions, ...value};
        this._isPrive = this._options.details === 'prive';
        this._isPublic = this._options.details === 'public' || this._options.details === 'prive';
    }

    @Input({required: true})
    set location(value: Location) {
        this._location = value;
    }

    get location(): Location {
        return this._location;
    }
}
