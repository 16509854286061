import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';

export default function getBiensModalChoicePasserellesCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BiensModalChoicePasserellesCtrl', Controller);

        /**
         * Controller for format affichette modal
         *
         * @param title
         * @param passerelles
         * @param $uibModalInstance
         * @param Ng2RouterStateForNgService
         */
        Controller.$inject = ['title', 'passerelles', '$uibModalInstance', 'Ng2RouterStateForNgService'];
        function Controller(this: any,
                            title: string,
                            passerelles: unknown[],
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;
            const passerellesSelected: EtudePasserelle[] = [];

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.onSelect = onSelect;
            $ctrl.validateSelection = validateSelection;
            $ctrl.title = title;
            $ctrl.passerelles = passerelles;

            /**
             * Select passerelle
             */
            function onSelect(etudePasserelleSelected: EtudePasserelle) {
                const idx = passerellesSelected.findIndex(etudePasserelle => etudePasserelle.passerelle.id === etudePasserelleSelected.passerelle.id);

                if (idx < 0) {
                    passerellesSelected.push(etudePasserelleSelected);
                } else {
                    passerellesSelected.splice(idx, 1);
                }
            }

            /**
             * Valid selection of passerelle
             */
            function validateSelection() {
                $uibModalInstance.close(passerellesSelected);
            }
        }
    })(angularJS);
}
