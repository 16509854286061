import {inject, Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
    CBonvisiteLocationsFactory
} from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.factory';
import Location from '@models/locations/location/location.model';
import {LocationsService} from '@models/locations/locations.service';

@Injectable({providedIn: 'root'})
export class CBonvisiteLocationsService {
    private _cBonvisiteLocationsFactory = inject(CBonvisiteLocationsFactory);
    private _locationsService = inject(LocationsService);

    getLocationsByLink$(link: string): Observable<Location[]> {
        return this._cBonvisiteLocationsFactory.getByLink$(link).pipe(
            map(cBonvisiteLocations => cBonvisiteLocations.results.map(bonvisiteLocation => bonvisiteLocation.linkLocation)),
            switchMap(locationLinks => this._locationsService.getByLinks$(locationLinks)),
        );
    }
}
