import {inject, Injectable} from '@angular/core';
import BienCadastre from '@models/bien/cadastres/cadastre/bien-cadastre.model';
import {BienCadastresApiService} from '@models/bien/cadastres/bien-cadastres.api.service';
import {Observable} from 'rxjs';
import BienCadastreDoubleMandat from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.model';
import {
    IBienCadastreDoubleMandatApi, IBienCadastreDoubleMandatQueryParameters
} from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.interfaces';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BienCadastreDoubleMandatFactory {
    private _bienCadastresApiService = inject(BienCadastresApiService);
    private _dictionaryItemService = inject(DictionaryItemService);

    create(bienCadastreDoubleMandatApi: IBienCadastreDoubleMandatApi): BienCadastreDoubleMandat {
        const bienCadastreDoubleMandat = new BienCadastreDoubleMandat();

        bienCadastreDoubleMandat.type = bienCadastreDoubleMandatApi.type;
        if (bienCadastreDoubleMandatApi.biens?.length > 0) {
            const dataResponsable = [
                bienCadastreDoubleMandatApi.biens[0].responsableDossierPrenom,
                bienCadastreDoubleMandatApi.biens[0].responsableDossierNom,
            ];

            if (bienCadastreDoubleMandatApi.biens[0].responsableDossierTitre) {
                dataResponsable.unshift(this._dictionaryItemService.getByCode(Dictionary.names.TITRES, bienCadastreDoubleMandatApi.biens[0].responsableDossierTitre, true).abreviation);
            }

            bienCadastreDoubleMandat.bienReference = bienCadastreDoubleMandatApi.biens[0].bienReference;
            bienCadastreDoubleMandat.etudeRaisonSociale = bienCadastreDoubleMandatApi.biens[0].etudeRaisonSociale;
            bienCadastreDoubleMandat.responsable = dataResponsable.join(' ');
        }

        return bienCadastreDoubleMandat;
    }

    checkDoubleMandat$(bienCadastre: BienCadastre, excludeBienId?: number): Observable<BienCadastreDoubleMandat> {
        const queryParameters: IBienCadastreDoubleMandatQueryParameters = {};

        if (bienCadastre.communeId) {
            queryParameters.commune = bienCadastre.communeId;
        }

        /* En attente de https://gitlab.soqrate.com/soqrate/noty/noty/issues/461
        if (bienCadastre.prefixe) {
            queryParameters.prefixe = bienCadastre.prefixe;
        }
        */

        if (excludeBienId) {
            queryParameters.excludeBien = excludeBienId;
        }

        if (bienCadastre.lot) {
            queryParameters.lot = bienCadastre.lot;
        }

        if (bienCadastre.parcelle) {
            queryParameters.parcelle = bienCadastre.parcelle;
        }

        if (bienCadastre.section) {
            queryParameters.section = bienCadastre.section;
        }

        return this._bienCadastresApiService.checkDoubleMandat$(queryParameters).pipe(
            map(bienCadastreDoubleMandatApi => bienCadastreDoubleMandatApi && this.create(bienCadastreDoubleMandatApi)),
        );
    }
}
