import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Bien from '@models/bien/bien.model';

@Injectable({providedIn: 'root'})
export class BienEditVisibilityPanelService {
    // @todo Utilité du typage ?
    private static EXCLUDE_PANELS: Record<string, string[]> = {
        agencementInterieur: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        batiment: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        diagnostics: [],
        habitation: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        loiAlur: [
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            Bien.natures.AUTRE,
        ],
        risques: [],
        situationLocative: [DossierBienTypesConst.LOCATION],
        urbanisme: [],
        viabilisation: [],
    };

    isVisible(dossierBien: ADossierBien, panel: string): boolean {
        if (!BienEditVisibilityPanelService.EXCLUDE_PANELS[panel]) {
            console.error('CAS NON GÉRÉ', panel);
        }

        if (!dossierBien?.bien?.nature || !panel || !BienEditVisibilityPanelService.EXCLUDE_PANELS[panel]) {
            return true;
        }

        return !BienEditVisibilityPanelService.EXCLUDE_PANELS[panel].includes(dossierBien.bien.nature.code)
            && !BienEditVisibilityPanelService.EXCLUDE_PANELS[panel].includes(dossierBien.dossierType);
    }
}
