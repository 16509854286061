import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-eskimmo-browser', template: '<div appNgEskimmoBrowser></div>'})
export class NgEskimmoBrowserComponent {
}

@Directive({selector: '[appNgEskimmoBrowser]'})
export class NgEskimmoBrowserDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskBrowserCtrl', elementRef, injector);
    }
}
