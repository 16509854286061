import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    LocationLocataireConstatsApiService
} from '@models/locations/location/locataires/locataire/constats/location-locataire-constats.api.service';
import LocationLocataireConstat
    from '@models/locations/location/locataires/locataire/constats/constat/location-locataire-constat.model';
import {
    ILocationLocataireConstatApi
} from '@models/locations/location/locataires/locataire/constats/constat/location-locataire-constat.interfaces';
import {NgLocationLocataireEtatLieux} from '@legacy/app/managers/ressources';
import Location from '@models/locations/location/location.model';
import LocationLocataire from '@models/locations/location/locataires/locataire/location-locataire.model';

@Injectable({providedIn: 'root'})
export class LocationLocataireConstatFactory {
    private _locationLocataireConstatsApiService = inject(LocationLocataireConstatsApiService);

    create(locationLocataireConstatApi: ILocationLocataireConstatApi): LocationLocataireConstat {
        const locationLocataireConstat: LocationLocataireConstat = this.createVirgin(locationLocataireConstatApi.id.toString(), locationLocataireConstatApi.id);

        locationLocataireConstat.comments = locationLocataireConstatApi.comments;

        return locationLocataireConstat;
    }

    createVirgin(uuid: string, id: number): LocationLocataireConstat {
        return new LocationLocataireConstat(uuid, id);
    }

    delete$(location: Location, locationLocataire: LocationLocataire, locationLocataireConstat: LocationLocataireConstat): Observable<void> {
        return this._locationLocataireConstatsApiService.delete$(location.id.toString(), locationLocataire.id.toString(), locationLocataireConstat.id.toString());
    }

    ngCreate(ngLocationLocataireEtatLieux: NgLocationLocataireEtatLieux): LocationLocataireConstat {
        const locationLocataireConstat: LocationLocataireConstat = this.createVirgin(ngLocationLocataireEtatLieux.id.toString(), ngLocationLocataireEtatLieux.id);

        locationLocataireConstat.comments = ngLocationLocataireEtatLieux.comments;

        return locationLocataireConstat;
    }
}
