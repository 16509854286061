import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class BienCharge extends AModel {
    private _charge!: DictionaryItem;
    private _comments!: string;
    private _cout!: number;
    private readonly _id: number;
    private _periodicite!: DictionaryItem;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get charge(): DictionaryItem {
        return this._charge;
    }

    set charge(value: DictionaryItem) {
        this._charge = value;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get cout(): number {
        return this._cout;
    }

    set cout(value: number) {
        this._cout = value;
    }

    get id(): number {
        return this._id;
    }

    get periodicite(): DictionaryItem {
        return this._periodicite;
    }

    set periodicite(value: DictionaryItem) {
        this._periodicite = value;
    }
}
