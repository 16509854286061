import {Component, inject, Input, output, ViewChild} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {CDemandeursService} from '@models/demandeurs/collection/demandeurs.collection.service';
import {IDemandeursSelectOptions} from '@features/demandeurs/demandeurs.interfaces';
import Contact from '@models/contacts/contact/contact.model';
import {IFormSelectInputOptionsGroupValueReturn} from '@shared/form/form.interfaces';
import {forkJoin, Observable, of} from 'rxjs';
import {DemandeurService} from '@models/demandeurs/demandeur/demandeur.service';
import {CContactsFactory} from '@models/contacts/collection/contacts.collection.factory';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

@Component({
    exportAs: 'demandeursSelect',
    selector: 'app-demandeurs-select',
    templateUrl: 'demandeurs.select.component.html',
})
export class AppDemandeursSelectComponent {
    static readonly initDemandeursSelectOptions: IDemandeursSelectOptions = {
        bindLabel: 'convivialName',
        loadingText: 'Chargement des demandeurs',
        name: 'demandeursSelect',
        notFoundText: 'Aucun demandeur trouvé',
        placeholder: 'Rechercher un demandeur par son nom, ses téléphones, son adresse email ou ses références',
        withContacts: false,
    };
    // @todo Pour "'contact'", créer une constante ou un type dans Model/Contact
    static readonly keys = {AUTRE: 'autre', DEMANDEUR: DossierTypesConst.DEMANDEUR, CONTACT: 'contact'};
    readonly selected = output<Demandeur>();
    private _cContactsFactory = inject(CContactsFactory);
    private _cDemandeursService = inject(CDemandeursService);
    private _demandeurService = inject(DemandeurService);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IDemandeursSelectOptions = {...AppDemandeursSelectComponent.initDemandeursSelectOptions};
    private _demandeur!: Demandeur;
    private _list: (Demandeur | Contact)[] = [];

    get demandeur(): Demandeur {
        return this._demandeur;
    }

    @Input()
    set demandeur(value: Demandeur) {
        this._demandeur = value;
    }

    get list(): (Demandeur | Contact)[] {
        return this._list;
    }

    @ViewChild('demandeursSelectInput')
    set demandeursSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IDemandeursSelectOptions {
        return this._options;
    }

    @Input()
    set options(value: IDemandeursSelectOptions) {
        this._options = {...AppDemandeursSelectComponent.initDemandeursSelectOptions, ...value};
        if (this.options.withContacts && !this.options.groupBy) {
            this.options.groupBy = (item: Demandeur | Contact): string => {
                if (item instanceof Demandeur) {
                    return AppDemandeursSelectComponent.keys.DEMANDEUR;
                } else if (item) {
                    return AppDemandeursSelectComponent.keys.CONTACT;
                }

                return AppDemandeursSelectComponent.keys.AUTRE;
            };
            this.options.groupValue = (key: string, children: (Demandeur | Contact)[]): IFormSelectInputOptionsGroupValueReturn => {
                let labelPlural: string;
                let labelSingular: string;

                if (key === AppDemandeursSelectComponent.keys.DEMANDEUR) {
                    labelPlural = 'Demandeurs';
                    labelSingular = 'Demandeur';
                } else if (key === AppDemandeursSelectComponent.keys.CONTACT) {
                    labelPlural = 'Contacts';
                    labelSingular = 'Contact';
                } else {
                    labelPlural = 'Autres';
                    labelSingular = 'Autre';
                }

                return {labelPlural, labelSingular, total: children.length};
            };
        }
    }

    onSelect(data: unknown): void {
        let demandeur$ = of(data as Demandeur);

        if (data instanceof Contact) {
            demandeur$ = this._demandeurService.createFromContact$(data);
        }

        demandeur$.pipe(take(1)).subscribe(demandeur => this.selected.emit(demandeur));
    }

    search(search: string): void {
        const search$s: Observable<(Demandeur | Contact)[]>[] = [];

        this._list = [];
        this._loading = true;
        search$s.push(this._cDemandeursService.searchActive$(search).pipe(map(cDemandeurs => cDemandeurs.results), take(1)));
        if (this.options.withContacts) {
            search$s.push(this._cContactsFactory.get$({keywords: search}).pipe(map(cContacts => cContacts.results), take(1)));
        }

        forkJoin(search$s).pipe(
            map(data => data.reduce((previousList, listPart) => previousList.concat(listPart), [])),
            take(1),
        ).subscribe({
            next: list => this._list = list,
            complete: () => this._loading = false,
        });
    }
}
