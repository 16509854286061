import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgAuthenticationService} from '@legacy/app/auth/auth';
import {UserFactory} from '@models/users/user/user.factory';
import {firstValueFrom} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';

export default function getAuthLoginCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskAuthLoginCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/auth/controllers/login.html',
        });

        /**
         * Controller for login
         *
         * @param Ng2RouterStateForNgService
         * @param AuthenticationService
         * @param SoqSweetAlert
         * @param $translate
         * @param Ng2ToasterService
         * @param Ng2UserFactory
         * @param Ng2EtudeService
         * @param Ng2UserService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'AuthenticationService', 'SoqSweetAlert', '$translate', 'Ng2ToasterService', 'Ng2UserFactory', 'Ng2EtudeService', 'Ng2UserService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            authenticationService: NgAuthenticationService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            ng2ToasterService: ToasterService,
                            ng2UserFactory: UserFactory,
                            ng2EtudeService: EtudeService,
                            ng2UserService: UserService) {
            const $ctrl = this;

            $ctrl.signin = signin;
            $ctrl.requestPassword = requestPassword;
            authenticationService.eraseCredentials();
            ng2EtudeService.logout();
            ng2UserService.logout();

            /**
             * Sign in
             */
            function signin() {
                $ctrl.message = null;
                /**
                 * On fait la vérification du formulaire manuellement car sinon l'auto-saisie dans les navigateurs
                 * n'est pas prise en compte par la vue HTML.
                 * @link https://github.com/angular/angular.js/issues/1072
                 */
                if ($ctrl.editForm.$invalid) {
                    return;
                }

                $ctrl.pending = true;
                authenticationService.authenticate($ctrl.username, $ctrl.password, $ctrl.remember).then(function () {
                    ng2RouterStateForNgService.navigateByUrl('start');
                }, function (response) {
                    $ctrl.message = response.status === 400 ? response.data.error_description : "Erreur inconnue survenue";
                }).finally(() => $ctrl.pending = false);
            }

            /**
             * Request password
             */
            function requestPassword() {
                soqSweetAlert.inputConfirmation($translate.instant('authentication.forgot_password.TITLE'), $translate.instant('authentication.forgot_password.confirm.MESSAGE'), $translate.instant('authentication.forgot_password.confirm.INPUT')).then(
                    username => username as string
                ).then(username => {
                    firstValueFrom(ng2UserFactory.requestPassword$(username)).then(() => {
                        ng2ToasterService.success($translate.instant('authentication.forgot_password.TITLE'), $translate.instant('authentication.forgot_password.sended.MESSAGE'));
                    }, () => {
                        ng2ToasterService.error($translate.instant('authentication.forgot_password.TITLE'), $translate.instant('mail.error.MESSAGE'));
                    });
                });
            }
        }
    })(angularJS);
}
