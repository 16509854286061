import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {IEtudeSettingsNetworkApi} from '@models/etudes/etude/settings/network/etude-settings-network.interfaces';
import EtudeSettingsNetwork from '@models/etudes/etude/settings/network/etude-settings-network.model';
import {EtudeSettingsNetworkApiService} from '@models/etudes/etude/settings/network/etude-settings-network.api.service';

@Injectable({providedIn: 'root'})
export class EtudeSettingsNetworkFactory {
    private _etudeSettingsNetworkApiService = inject(EtudeSettingsNetworkApiService);

    create(etudeSettingsNetworkApi: IEtudeSettingsNetworkApi): EtudeSettingsNetwork {
        const etudeSettingsNetwork = this.createVirgin();

        etudeSettingsNetwork.compromisActif = etudeSettingsNetworkApi.compromisActif;
        etudeSettingsNetwork.matchingDemandeurs = etudeSettingsNetworkApi.matchingDemandeurs;

        return etudeSettingsNetwork;
    }

    createVirgin(): EtudeSettingsNetwork {
        return new EtudeSettingsNetwork();
    }

    forApi(etudeSettingsNetwork: EtudeSettingsNetwork): IEtudeSettingsNetworkApi {
        return {
            compromisActif: etudeSettingsNetwork.compromisActif,
            matchingDemandeurs: etudeSettingsNetwork.matchingDemandeurs
        };
    }

    get$(etude: Etude): Observable<EtudeSettingsNetwork> {
        return this._etudeSettingsNetworkApiService.get$(etude.id.toString()).pipe(map(etudeSettingsNetworkApi => this.create(etudeSettingsNetworkApi)));
    }

    save$(etude: Etude, etudeSettingsNetwork: EtudeSettingsNetwork): Observable<EtudeSettingsNetwork> {
        return this._etudeSettingsNetworkApiService.save$(etude.id.toString(), this.forApi(etudeSettingsNetwork)).pipe(
            map(etudeSettingsNetworkApi => this.create(etudeSettingsNetworkApi)),
        );
    }
}
