@if (pending$ | appLoadObs | async; as pending) {
  <div [ngClass]="pending.value ? 'tw-waiting' : undefined!" class="tw-flex tw-gap-4">
    @if (!estimation.isArchived()) {
      <button type="button" class="tw-btn-primary-info" (click)="save()" [disabled]="pending.value">
        Enregistrer
      </button>
    }
    @if (estimation.isArchived()) {
      <button type="button" class="tw-btn-primary-info" (click)="print()" [disabled]="pending.value">
        Imprimer
      </button>
    }

    <button type="button" class="tw-btn-secondary-info tw-w-10" #buttonElement (click)="clickDropdown(buttonElement)">
      <fa-icon [icon]="'ellipsis-v'"/>
    </button>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-8'" class="tw-block tw-w-40"/>
}
