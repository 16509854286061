export default `
<ng-switch on="$ctrl.references.totalItems > 0">
  <div ng-switch-default class="margin-top-30 text-large tw-text-center">
    Aucun résultat correspondant à votre recherche. Élargissez vos critères.
  </div>

  <div ng-switch-when="true" class="edit-collections references">
    <div class="row legend">
      <div class="col-xs-5 col-sm-3 col-lg-4 head-title">
        <span class="hidden-xs">Adresse</span>

        <span class="visible-xs">Commune</span>
      </div>

      <div class="hidden-xs col-xs-2 col-md-1 head-title">Date de vente</div>

      <div class="hidden-xs hidden-sm col-xs-2 head-title">Époque</div>

      <div class="hidden-xs col-xs-2 col-md-1 head-title">
        {{ 'bien.field_label.SUPERFICIE' | translate: '{nature: "' + $ctrl.estimation.bien.nature.code + '"}' }}
      </div>

      <div class="hidden-xs hidden-sm col-xs-1 head-title">Surface cadastrale</div>

      <div class="hidden-xs col-xs-2 col-lg-1 head-title">Prix</div>

      <div class="col-xs-4 col-sm-1 head-title">Prix au m²</div>
    </div>

    <ul class="list-group" ng-model="$ctrl.references.collection" ui-sortable="$ctrl.uiSortableOptions">
      <li class="list-group-item" ng-repeat="reference in $ctrl.references.collection">
        <div ng-if="$ctrl.options.visibleFields.sort" class="item-action">
          <div class="text-muted drag-handler handler-precise" title="Modifier l'ordre des références">:::</div>
        </div>

        <div class="row item">
          <div class="col-xs-5 col-sm-3 col-lg-4 ellipsis reference-adresse">
            <span class="hidden-xs">{{reference.adresse}}</span> {{reference.commune.fullname}}
          </div>

          <div class="hidden-xs col-xs-2 col-md-1">
            <app-ng2-date [date]="reference.dateVente" format="shortDate"></app-ng2-date>
          </div>

          <div class="hidden-xs hidden-sm col-xs-2" ng-switch="!reference.epoque">
            <span ng-switch-when="true" class="text-muted">
              <fa-icon [icon]="'slash'" size="xs" flip="vertical"></fa-icon>
            </span>

            <span ng-switch-default>{{reference.epoque.label}}</span>
          </div>

          <div class="hidden-xs col-xs-2 col-md-1" ng-switch="reference.superficie > 0">
            <span ng-switch-when="false" class="text-muted">
              <fa-icon [icon]="'slash'" size="xs" flip="vertical"></fa-icon>
            </span>

            <span ng-switch-default>
              {{reference.superficie | currency:'m²':2 * (reference.superficie % 1 !== 0)}}
            </span>
          </div>

          <div class="hidden-xs hidden-sm col-xs-1" ng-switch="reference.surfaceCadastrale > 0">
            <span ng-switch-when="false" class="text-muted">
              <fa-icon [icon]="'slash'" size="xs" flip="vertical"></fa-icon>
            </span>

            <span ng-switch-default>
              {{reference.surfaceCadastrale | currency:'m²':2 * (reference.surfaceCadastrale % 1 !== 0)}}
            </span>
          </div>

          <div class="hidden-xs col-xs-2 col-lg-1">
            {{reference.prixVente | currency:'€':2 * (reference.prixVente % 1 !== 0)}}
          </div>

          <div class="col-xs-4 col-sm-1" ng-switch="reference._esk.prixM2 > 0">
            <span ng-switch-when="false" class="text-muted">
              <fa-icon [icon]="'slash'" size="xs" flip="vertical"></fa-icon>
            </span>

            <span ng-switch-default>
              {{reference._esk.prixM2 | currency:'€':2 * (reference._esk.prixM2 % 1 !== 0)}}
            </span>
          </div>

          <ng-switch on="reference._esk.loading" class="col-xs-3 col-sm-2 col-md-1 other tw-text-center"
                     ng-if="$ctrl.options.visibleFields.edit || $ctrl.options.visibleFields.remove">
            <span ng-switch-when="true"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon></span>

            <span ng-switch-default>
              <button ng-if="$ctrl.options.visibleFields.edit" type="button" class="btn btn-o btn-null"
                      ng-click="$ctrl.options.edit(reference)"><fa-icon [icon]="'pencil-alt'"></fa-icon></button>

              <button ng-if="$ctrl.options.visibleFields.remove" type="button" class="btn btn-o btn-null"
                      ng-click="$ctrl.options.remove(reference)"><fa-icon [icon]="'trash-alt'"></fa-icon></button>
            </span>
          </ng-switch>
        </div>
      </li>
    </ul>
  </div>
</ng-switch>
`;
