import {Injectable} from '@angular/core';
import {delay, Observable, ReplaySubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationService {
    private _mustBeOpenSource = new ReplaySubject<boolean>(1);
    // Il y a un delay(1) pour éviter à appClickAway dans AppNavigationLateralComponent de se lancer après l'émission de la valeur.
    private _mustBeOpen$ = this._mustBeOpenSource.asObservable().pipe(delay(1));
    private _mustBeOpen = false;

    constructor() {
        this._mustBeOpenSource.next(this._mustBeOpen);
    }

    get mustBeOpen$(): Observable<boolean> {
        return this._mustBeOpen$;
    }

    close(): void {
        this._mustBeOpen = false;
        this._mustBeOpenSource.next(this._mustBeOpen);
    }

    toggle(): void {
        this._mustBeOpen = !this._mustBeOpen;
        this._mustBeOpenSource.next(this._mustBeOpen);
    }
}
