import {IEnvApi, IEnvFacebook, IEnvMeteo, IEnvMyNoty, IEnvPrevisite, IEnvSentry} from '@env/environment.interfaces';

export default class Environment {
    private _api: IEnvApi;
    private _facebook: IEnvFacebook;
    private _meteo: IEnvMeteo;
    private _myNoty: IEnvMyNoty;
    private _previsite: IEnvPrevisite | null;
    private _production: boolean;
    private _sentry: IEnvSentry;

    constructor(api: IEnvApi,
                facebook: IEnvFacebook,
                meteo: IEnvMeteo,
                myNoty: IEnvMyNoty,
                previsite: IEnvPrevisite | null,
                sentry: IEnvSentry) {
        this._api = api;
        this._facebook = facebook;
        this._meteo = meteo;
        this._myNoty = myNoty;
        this._previsite = previsite;
        this._production = true;
        this._sentry = sentry;
    }

    get api(): IEnvApi {
        return this._api;
    }

    get facebook(): IEnvFacebook {
        return this._facebook;
    }

    get meteo(): IEnvMeteo {
        return this._meteo;
    }

    get myNoty(): IEnvMyNoty {
        return this._myNoty;
    }

    get previsite(): IEnvPrevisite | null {
        return this._previsite;
    }

    get production(): boolean {
        return this._production;
    }

    set production(value: boolean) {
        this._production = value;
    }

    get sentry(): IEnvSentry {
        return this._sentry;
    }
}
