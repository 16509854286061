import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {HttpEventProgress} from '@core/api/api.interfaces';
import {HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class EtudeSettingsLogoApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(etudeUuid: string): Observable<void> {
        return this._modelApiService.delete$('/etudes/' + etudeUuid + '/settings/logo');
    }

    upload$(etudeUuid: string, formData: FormData): Observable<HttpEventProgress | HttpResponse<void>> {
        return this._modelApiService.upload$('/etudes/' + etudeUuid + '/settings/logo', formData);
    }
}
