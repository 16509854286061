import angularJS from '@shared/angularJS/global.ng';
import {sumBy} from 'lodash';
import {IModule} from 'angular';
import {NgDictionariesManager, NgEtudeStatisticsManager} from '@legacy/app/managers/managers';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import DateFormat from '@shared/date/date.format';

/* eslint-disable */
export default function getVentesTableNegocieesNatures(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-ventes-table-negociees-natures></esk-ventes-table-negociees-natures>
         * @param filter : {yearReference(number), responsablesDossier(number[])}
         */
        module.component('eskVentesTableNegocieesNatures', {
            bindings: {filter: '=?', refresh: '=?'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/components/table.negociees.natures.html'
        });

        /**
         * Display table of negociees by natures
         *
         * @param EtudeStatisticsManager
         * @param DictionariesManager
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['EtudeStatisticsManager', 'DictionariesManager', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            etudeStatisticsManager: NgEtudeStatisticsManager,
                            dictionariesManager: NgDictionariesManager,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.years = [DateFormat.getYear(DateFormat.dateFromNow()) - 1, DateFormat.getYear(DateFormat.dateFromNow())];

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.refresh = refresh;
                $ctrl.refresh();
            }

            /**
             * Refresh
             */
            function refresh() {
                var params = {year: $ctrl.years};

                if (angular.isObject($ctrl.filter)) {
                    if (angular.isNumber($ctrl.filter.yearReference)) {
                        $ctrl.years = [$ctrl.filter.yearReference - 1, $ctrl.filter.yearReference];
                        params.year = $ctrl.years;
                    }

                    if (angular.isArray($ctrl.filter.responsablesDossier) && $ctrl.filter.responsablesDossier.length > 0) {
                        // @ts-ignore
                        params.responsableDossier = $ctrl.filter.responsablesDossier;
                    }
                }

                if ($ctrl.years[0] < 2000) {
                    return;
                }

                $ctrl.loading = true;
                etudeStatisticsManager.getVentesNegocieesNatures(params).then(function (statistics) {
                    const natures = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.NATURES));
                    var duree = 0;
                    var nbDuree = 0;

                    $ctrl.tableNatures = {date: DateFormat.toDate(statistics.date), data: [], total: {}};
                    $ctrl.tableNatures.total[$ctrl.years[0]] = 0;
                    $ctrl.tableNatures.total[$ctrl.years[1]] = 0;
                    $ctrl.tableNatures.total.evolution = 0;
                    angular.forEach(natures, function (nature) {
                        const data: Record<string, number | string> = {
                            label: nature.label,
                            duree: undefined! as number,
                            evolution: null! as number
                        };
                        const countYear0 = statistics.data[nature.code][$ctrl.years[0]].count as number;
                        const countYear1 = statistics.data[nature.code][$ctrl.years[1]].count as number;

                        data[$ctrl.years[0]] = countYear0;
                        data[$ctrl.years[1]] = countYear1;
                        data.duree = statistics.data[nature.code][$ctrl.years[1]].duree;
                        if (countYear0 > 0 && countYear1 > 0) {
                            data.evolution = (countYear1 / countYear0 - 1) * 100;
                        }

                        $ctrl.tableNatures.data.push(data);
                        if (angular.isNumber(data.duree)) {
                            duree += data.duree;
                            nbDuree++;
                        }
                    });
                    $ctrl.tableNatures.total[$ctrl.years[0]] = sumBy($ctrl.tableNatures.data, $ctrl.years[0]);
                    $ctrl.tableNatures.total[$ctrl.years[1]] = sumBy($ctrl.tableNatures.data, $ctrl.years[1]);
                    if (duree > 0) {
                        $ctrl.tableNatures.total.duree = duree / nbDuree;
                    }
                    if ($ctrl.tableNatures.total[$ctrl.years[0]] > 0 && $ctrl.tableNatures.total[$ctrl.years[1]] > 0) {
                        $ctrl.tableNatures.total.evolution = ($ctrl.tableNatures.total[$ctrl.years[1]] / $ctrl.tableNatures.total[$ctrl.years[0]] - 1) * 100;
                    }
                }).finally(() => $ctrl.loading = false);
            }
        }
    })(angularJS);
}
/* eslint-enable */
