import {inject, Injectable} from '@angular/core';
import {CArrondissementsFactory} from '@models/arrondissements/collection/arrondissements.collection.factory';
import Commune from '@models/communes/commune/commune.model';
import {Observable, of} from 'rxjs';
import CArrondissements from '@models/arrondissements/collection/arrondissements.collection.model';

@Injectable({providedIn: 'root'})
export class CArrondissementsService {
    private _cArrondissementsFactory = inject(CArrondissementsFactory);

    getOne$(commune?: Commune): Observable<CArrondissements> {
        if (commune?.hasQuartiers) {
            return this._cArrondissementsFactory.get$({communeIds: [commune.id]});
        }

        return of(this._cArrondissementsFactory.createVirgin());
    }
}
