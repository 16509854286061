import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DossierExportsApiService} from '@models/dossiers/dossier/exports/dossier-exports.api.service';
import DossierExportSave from '@models/dossiers/dossier/exports/export/save/dossier-export-save.model';
import {
    IDossierExportSaveApi
} from '@models/dossiers/dossier/exports/export/save/dossier-export-save.interfaces';
import {DossierType} from '@models/dossiers/dossiers.interfaces';

@Injectable({providedIn: 'root'})
export class DossierExportSaveFactory {
    private _dossierExportsApiService = inject(DossierExportsApiService);

    createVirgin(dossierType: DossierType, dossierUuid: string): DossierExportSave {
        return new DossierExportSave(dossierType, dossierUuid);
    }

    create$(dossierExportSave: DossierExportSave): Observable<void> {
        return this._dossierExportsApiService.create$(dossierExportSave.dossierType, dossierExportSave.dossierUuid, this.forApi(dossierExportSave));
    }

    forApi(dossierExportSave: DossierExportSave): IDossierExportSaveApi {
        const dossierExportSaveApi = {} as IDossierExportSaveApi;

        dossierExportSaveApi.contacts = dossierExportSave.contacts.map(contactQualified => ({
            contactUuid: contactQualified.contact.uuid,
            qualification: contactQualified.qualification
        }));
        dossierExportSaveApi.destinataire = dossierExportSave.destinataire?.externalId;
        dossierExportSaveApi.mediaIds = dossierExportSave.mediaIds;

        return dossierExportSaveApi;
    }
}
