import angularJS from '@shared/angularJS/global.ng';
import {concat, filter, map, remove} from 'lodash';
import {IModule, IQService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgEmailManager, NgEstimationReferenceManager, NgMediaManager} from '@legacy/app/managers/managers';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {NgEstimation, NgVente} from '@legacy/app/managers/ressources';
import {DocumentsService} from '@models/documents/documents.service';
import Document from '@models/documents/document/document.model';
import {firstValueFrom} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import DateFormat from '@shared/date/date.format';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEstimationsUtils(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('EstimationsUtils', Service);

        /**
         * Utils for estimation
         *
         * @param SoqSweetAlert
         * @param $translate
         * @param SoqModalesManager
         * @param EmailManager
         * @param $q
         * @param Ng2DocumentsService
         * @param MediaManager
         * @param EstimationReferenceManager
         */
        Service.$inject = ['SoqSweetAlert', '$translate', 'SoqModalesManager', 'EmailManager', '$q', 'Ng2DocumentsService', 'MediaManager', 'EstimationReferenceManager'];
        function Service(this: any,
                         soqSweetAlert: NgSoqSweetAlert,
                         $translate: angular.translate.ITranslateService,
                         soqModalesManager: NgSoqModalesManager,
                         emailManager: NgEmailManager,
                         $q: IQService,
                         ng2DocumentsService: DocumentsService,
                         mediaManager: NgMediaManager,
                         estimationReferenceManager: NgEstimationReferenceManager) {
            this.sendRapport = sendRapport;
            this.remettreRapport = remettreRapport;
            this.createReferenceFromVente = createReferenceFromVente;
            this.getFilterReferencesFromSearch = getFilterReferencesFromSearch;

            /**
             * Send rapport
             *
             * @param estimation
             * @param documents
             * @returns {Promise}
             */
            function sendRapport(estimation: NgEstimation, documents: Document[]) {
                let titre = estimation.bien.reference;

                if (estimation.type.code === Estimation.types.EVALUATION) {
                    titre = 'Rapport d\'évaluation ' + titre;
                } else {
                    titre = 'Avis de valeur ' + titre;
                }

                return firstValueFrom(ng2DocumentsService.getMedia$(documents, titre)).then(
                    media => mediaManager.createFromNg2(media)
                ).then(ngMedia => soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: estimation.bien.contactsGroup.getRecipients(),
                            attachments: [{media: ngMedia}],
                            dossiers: [
                                {typeId: estimation.id, type: DossierBienTypesConst.ESTIMATION, dossier: estimation},
                            ],
                            subject: ngMedia.title,
                            message: estimation.bien.contactsGroup.formuleIntroduction + ",<br><br>" +
                                "Nous vous prions de trouver ci-joint, l'avis de valeur concernant votre bien.<br><br>" +
                                "Nous restons à votre entière disposition pour tous renseignements complémentaires.<br>" +
                                "Nous vous prions d'agréer, " + estimation.bien.contactsGroup.formulePolitesse +
                                ", l'expression de nos salutations les plus distinguées.<br><br>" +
                                emailManager.getCurrentUserSignature(),
                        }),
                        currentDossier: estimation,
                    },
                }));
            }

            /**
             * Remettre rapport
             *
             * @param estimation
             * @returns {Promise}
             */
            function remettreRapport(estimation: NgEstimation) {
                if (estimation.statut !== Estimation.statuts.BROUILLON) {
                    return $q.resolve();
                }

                return soqSweetAlert.warningConfirmation($translate.instant('estimation.remettre.confirm.TITLE', {type: estimation.type.code}), $translate.instant('estimation.remettre.confirm.MESSAGE'), {confirmButtonText: $translate.instant('estimation.remettre.confirm.CONFIRMATION')}).then(function () {
                    return estimation.remettre();
                });
            }

            /**
             * Create reference from vente
             *
             * @param vente
             * @param estimationId
             * @return {*}
             */
            function createReferenceFromVente(vente: NgVente, estimationId = 0) {
                const adresse = remove(remove([vente.bien.option.numeroVoie, vente.bien.option.nomVoie], undefined), null!).join(" ");
                const estimationReference = estimationReferenceManager.create({
                    adresse: adresse === '' ? vente.bien.option.adresseReseau : adresse,
                    commune: vente.bien.option.commune,
                    dateVente: vente.archiveDateVente,
                    prixVente: vente.archivePrixCession,
                    nature: vente.bien.nature,
                    loyer: angular.isObject(vente.bien.metadata.occupation) ? vente.bien.metadata.occupation.loyerActuel : 0,
                    superficie: vente.bien.superficie,
                    surfaceCadastrale: vente.bien.option.surfaceCadastrale,
                    nombrePieces: vente.bien.option.nombrePieces,
                    nombreChambres: vente.bien.option.nombreChambres,
                    epoque: vente.bien.option.epoqueConstruction,
                    nombreEtages: vente.bien.option.nombreEtages,
                    sourceType: DossierBienTypesConst.VENTE,
                    sourceUuid: vente.uuid
                }, estimationId);

                estimationReference._esk.vente = vente;

                return estimationReference;
            }

            /**
             * Get filter for references from search
             *
             * @param search
             */
            function getFilterReferencesFromSearch(search: NgSearch) {
                const filterCurrent: { commune: unknown[] } = {commune: []};

                if (angular.isArray(search.natures) && search.natures.length > 0) {
                    // @ts-ignore
                    filterCurrent.nature = map(search.natures, 'id');
                }
                if (angular.isArray(search.areas) && search.areas.length > 0) {
                    // @ts-ignore
                    filterCurrent.departement = map(filter(search.areas, {type: 'departement'}), 'id');
                }
                if (angular.isArray(search.communes) && search.communes.length > 0) {
                    filterCurrent.commune = map(search.communes, 'id');
                }
                if (angular.isArray(search.quartiers) && search.quartiers.length > 0) {
                    filterCurrent.commune = concat(filterCurrent.commune, map(map(search.quartiers, 'commune'), 'id'));
                }
                if (angular.isArray(search.epoques) && search.epoques.length > 0) {
                    // @ts-ignore
                    filterCurrent.epoque = map(search.epoques, 'id');
                }
                if (search.superficieMin! > 0 || search.superficieMax! > 0) {
                    // @ts-ignore
                    filterCurrent.superficie = {};

                    // @ts-ignore
                    if (search.superficieMin > 0) filterCurrent.superficie.min = search.superficieMin;
                    // @ts-ignore
                    if (search.superficieMax > 0) filterCurrent.superficie.max = search.superficieMax;
                }
                if (search.location) {
                    if (search.location.loyerMin! > 0 || search.location.loyerMax! > 0) {
                        // @ts-ignore
                        filterCurrent.loyer = {};

                        // @ts-ignore
                        if (search.location.loyerMin > 0) filterCurrent.loyer.min = search.location.loyerMin;
                        // @ts-ignore
                        if (search.location.loyerMax > 0) filterCurrent.loyer.max = search.location.loyerMax;
                    }
                }
                if (angular.isObject(search.option)) {
                    if (search.option.source === Etude.enablableModules.CITYSCAN.code) {
                        // @ts-ignore
                        filterCurrent.source = search.option.source;
                    }
                    if (search.option.surfaceCadastraleMin! > 0 || search.option.surfaceCadastraleMax! > 0) {
                        // @ts-ignore
                        filterCurrent.surfaceCadastrale = {};

                        // @ts-ignore
                        if (search.option.surfaceCadastraleMin > 0) filterCurrent.surfaceCadastrale.min = search.option.surfaceCadastraleMin;
                        // @ts-ignore
                        if (search.option.surfaceCadastraleMax > 0) filterCurrent.surfaceCadastrale.max = search.option.surfaceCadastraleMax;
                    }
                    if (search.option.nombrePiecesMin! > 0 || search.option.nombrePiecesMax! > 0) {
                        // @ts-ignore
                        filterCurrent.nombrePieces = {};

                        // @ts-ignore
                        if (search.option.nombrePiecesMin > 0) filterCurrent.nombrePieces.min = search.option.nombrePiecesMin;
                        // @ts-ignore
                        if (search.option.nombrePiecesMax > 0) filterCurrent.nombrePieces.max = search.option.nombrePiecesMax;
                    }
                    if (search.option.nombreChambresMin! > 0 || search.option.nombreChambresMax! > 0) {
                        // @ts-ignore
                        filterCurrent.nombreChambres = {};

                        // @ts-ignore
                        if (search.option.nombreChambresMin > 0) filterCurrent.nombreChambres.min = search.option.nombreChambresMin;
                        // @ts-ignore
                        if (search.option.nombreChambresMax > 0) filterCurrent.nombreChambres.max = search.option.nombreChambresMax;
                    }
                    if (search.option.nombreEtagesMin! > 0 || search.option.nombreEtagesMax! > 0) {
                        // @ts-ignore
                        filterCurrent.nombreEtages = {};

                        // @ts-ignore
                        if (search.option.nombreEtagesMin > 0) filterCurrent.nombreEtages.min = search.option.nombreEtagesMin;
                        // @ts-ignore
                        if (search.option.nombreEtagesMax > 0) filterCurrent.nombreEtages.max = search.option.nombreEtagesMax;
                    }
                    if (angular.isObject(search.option.voie)) {
                        // @ts-ignore
                        filterCurrent.adresse = search.option.voie.nom;
                    } else if (angular.isString(search.option.nomVoie) && search.option.nomVoie !== '') {
                        // @ts-ignore
                        filterCurrent.adresse = search.option.nomVoie;
                    }
                    if (search.option.numeroVoie) {
                        // @ts-ignore
                        filterCurrent.adresse = search.option.numeroVoie + ' ' + filterCurrent.adresse;
                    }
                }
                if (angular.isObject(search.archive)) {
                    if (search.archive.dateVenteMin || search.archive.dateVenteMax) {
                        // @ts-ignore
                        filterCurrent.dateVente = {};

                        // @ts-ignore
                        if (search.archive.dateVenteMin) filterCurrent.dateVente.min = DateFormat.toJSON(search.archive.dateVenteMin);
                        // @ts-ignore
                        if (search.archive.dateVenteMax) filterCurrent.dateVente.max = DateFormat.toJSON(search.archive.dateVenteMax);
                    }
                    if (search.archive.prixCessionMin! > 0 || search.archive.prixCessionMax! > 0) {
                        // @ts-ignore
                        filterCurrent.prixVente = {};

                        // @ts-ignore
                        if (search.archive.prixCessionMin > 0) filterCurrent.prixVente.min = search.archive.prixCessionMin;
                        // @ts-ignore
                        if (search.archive.prixCessionMax > 0) filterCurrent.prixVente.max = search.archive.prixCessionMax;
                    }
                }

                return filterCurrent;
            }
        }
    })(angularJS);
}
