import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import {NgEskBox, NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {NgFilterManager, NgVenteManager} from '@legacy/app/managers/managers';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgFilter, NgVente} from '@legacy/app/managers/ressources';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {SearchService} from '@models/search/search.service';

export default function getVentesBoxNetworkUpdated(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-ventes-box-network-updated></esk-ventes-box-network-updated>
         */
        module.run(template).component('eskVentesBoxNetworkUpdated', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/components/box/network-updated.html'
        });

        /**
         * Display statistiques
         *
         * @param EskBox
         * @param SoqModalesManager
         * @param VenteManager
         * @param FilterManager
         * @param Ng2RouterStateForNgService
         * @param EskUtils
         * @param Ng2SearchService
         * @constructor
         */
        Controller.$inject = ['EskBox', 'SoqModalesManager', 'VenteManager', 'FilterManager', 'Ng2RouterStateForNgService', 'EskUtils', 'Ng2SearchService'];
        function Controller(this: any,
                            eskBox: NgEskBox,
                            soqModalesManager: NgSoqModalesManager,
                            venteManager: NgVenteManager,
                            filterManager: NgFilterManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            eskUtils: NgEskUtils,
                            ng2SearchService: SearchService) {
            const $ctrl = this;
            let filter: NgFilter;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.$onInit = $onInit;
            $ctrl.openConfig = openConfig;
            $ctrl.onClickVente = onClickVente;
            $ctrl.box = eskBox.getOneByCode('ventes-network-updated');
            $ctrl.options = {
                displayMode: 'box',
                loader: {updating: false},
                visibleFields: {
                    archiveDateVente: false,
                    bullet: false,
                    mandatDateFin: false,
                    nomContactsGroup: false,
                    nombreChambres: false,
                    nombrePieces: false,
                    preciseLocalisation: false,
                    priceCession: false,
                    select: false,
                },
            };

            /**
             * Initialization method
             */
            function $onInit() {
                filterManager.getActiveByRouteCode(Filter.routes.GET_VENTES, 'box_updated_ventes').then(function (_filter_) {
                    filter = _filter_ as NgFilter;
                    $ctrl.box.refresh = refresh;
                    $ctrl.box.refresh();
                });
            }

            /**
             * Open configuration
             */
            function openConfig() {
                soqModalesManager.open('VentesModalBoxNetworkUpdated', {resolve: {filter: filter}}).then($ctrl.box.refresh);
            }

            /**
             * Refresh
             *
             * @returns {*}
             */
            function refresh() {
                $ctrl.options.loader.updating = true;

                return venteManager.getAll(angular.extend({limit: 9}, filter.getParamsRequest())).then(function (ventes) {
                    ventes.collection.forEach(vente => vente._esk.routing = '/app/ventes/search/' + vente.id.toString());
                    $ctrl.ventes = ventes;
                }).finally(() => $ctrl.options.loader.updating = false);
            }

            /**
             * Action on click on vente
             *
             * @param vente
             */
            function onClickVente(vente: NgVente) {
                eskUtils.getSearchFromFilter(DossierBienTypesConst.VENTE, (filter.queryParameters as NgFilter)).then(search => {
                    search.rapidSearchType = 'criteria';
                    eskUtils.setDataForRoute('/app/ventes/search', {
                        dossiers: $ctrl.ventes,
                        routeBack: {name: '/app/ventes/search', params: {search} as unknown as string},
                        search,
                    });
                    ng2SearchService.setNgCurrent(search, $ctrl.ventes);
                    ng2RouterStateForNgService.navigateByUrl('/app/ventes/search/' + vente.id.toString(), {
                        routeBack: {name: '/app/ventes/search'},
                        search,
                        venteId: vente.id,
                    });
                });
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/ventes/components/box/network-updated/container.html', '<esk-ventes-box-network-updated></esk-ventes-box-network-updated>');
        }
    })(angularJS);
}
