import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-biens-wallet-current', template: '<div appNgBiensWalletCurrent></div>'})
export class NgBiensWalletCurrentComponent {
}

@Directive({selector: '[appNgBiensWalletCurrent]'})
export class NgBiensWalletCurrentDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskBienDossiersWalletCurrentCtrl', elementRef, injector);
    }
}
