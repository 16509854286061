import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable, switchMap, throwError} from 'rxjs';
import {IVentePasserelleApi} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {
    ICVentePasserellesApi
} from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.interfaces';

@Injectable({providedIn: 'root'})
export class VentePasserellesApiService {
    private _modelApiService = inject(ModelApiService);

    diffuser$(venteId: string, passerelleId: string): Observable<void> {
        return this._modelApiService.patch$('/ventes/' + venteId + '/external/passerelles/' + passerelleId + '/diffuser');
    }

    get$(venteId: string, passerelleId: string, httpOptions?: HttpOptions): Observable<IVentePasserelleApi> {
        return this.getByLink$('/ventes/' + venteId + '/external/passerelles/' + passerelleId, httpOptions);
    }

    getByLink$(link: string, httpOptions?: HttpOptions): Observable<IVentePasserelleApi> {
        return this._modelApiService.get$(link, httpOptions);
    }

    getCollection$(venteId: string): Observable<ICVentePasserellesApi> {
        return this.getCollectionByLink$('/ventes/' + venteId + '/external/passerelles');
    }

    getCollectionByLink$(link: string): Observable<ICVentePasserellesApi> {
        return this._modelApiService.getCollection$(link);
    }

    getCollectionFromPasserelle$(passerelleId: string): Observable<ICVentePasserellesApi> {
        return this.getCollectionByLink$('/etude/passerelles/' + passerelleId + '/ventes');
    }

    retirer$(venteId: string, passerelleId: string): Observable<void> {
        return this._modelApiService.patch$('/ventes/' + venteId + '/external/passerelles/' + passerelleId + '/retirer');
    }

    save$(venteId: string, passerelleId: string, ventePasserelleApi: IVentePasserelleApi): Observable<IVentePasserelleApi> {
        const path = '/ventes/' + venteId + '/external/passerelles/' + passerelleId;

        return this._modelApiService.put$(path, ventePasserelleApi, {headers: {handledStatusErrors: [404]}}).pipe(
            switchMap(_ => this.get$(venteId, passerelleId)),
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return this._modelApiService.post$(path, ventePasserelleApi);
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }
}
