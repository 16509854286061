export default `
<div class="modal-network">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'low-vision'"></fa-icon> Visibilité de la photo</h3>
  </div>

  <div class="modal-body">
    <div class="radio-inline" ng-repeat="visibility in $ctrl.visibilities">
      <div class="margin-left-20 radio clip-radio radio-primary">
        <input type="radio" id="{{visibility.code}}" ng-model="$ctrl.visibilite" name="visibility"
               ng-value="visibility">

        <label for="{{visibility.code}}">{{visibility.shortLabel}}</label>
      </div>

      <p>{{visibility.label}}</p>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" ng-click="$ctrl.save()">
      <fa-icon [icon]="'check'"></fa-icon> Ok
    </button>
  </div>
</div>
`;
