import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TemplateFooterFactory} from '@models/templates/footers/footer/template-footer.factory';
import CTemplateFooters from '@models/templates/footers/collection/template-footers.collection.model';
import {CTemplateFootersFactory} from '@models/templates/footers/collection/template-footers.collection.factory';

@Injectable({providedIn: 'root'})
export class CTemplateFootersService {
    private _cTemplateFootersFactory = inject(CTemplateFootersFactory);
    private _templateFooterFactory = inject(TemplateFooterFactory);

    getWithCustom$(): Observable<CTemplateFooters> {
        return this._cTemplateFootersFactory.get$().pipe(tap(cTemplateFooters => cTemplateFooters.results.push(this._templateFooterFactory.getCustom())));
    }
}
