import angularJS from '@shared/angularJS/global.ng';
import {remove} from 'lodash';
import {IModule} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {
    IUserClientMetadataConfigDashboardBox
} from '@models/users/user/client-metadata/user-client-metadata.interfaces';

export default function getVentesModalBoxMandatsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('VentesModalBoxMandatsCtrl', Controller);

        /**
         * Controller for ventes mandats modal
         *
         * @param box
         * @param EskBox
         * @param $translate
         * @param soqSweetAlert
         * @param $uibModalInstance
         * @constructor
         */
        Controller.$inject = ['box', 'EskBox', '$translate', 'SoqSweetAlert', '$uibModalInstance'];
        function Controller(this: any,
                            box: IUserClientMetadataConfigDashboardBox,
                            eskBox: NgEskBox,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
            const $ctrl = this;

            $ctrl.save = save;
            $ctrl.options = {
                nbDisplayed: {name: 'nbDisplayed', placeholder: "Nombre de biens à afficher", required: true},
                mandatsEcheance: {placeholder: "Sélectionner une échéance", name: 'mandatsEcheance', required: true},
                sort: {name: 'sort', required: true},
            };
            $ctrl.listNbDisplayed = eskBox.getListNbDisplayed();
            $ctrl.box = box;
            if ($ctrl.box.code === 'ventes-mandats-expiring') {
                remove($ctrl.listMandatsEcheance = eskBox.getListMandatsEcheance(), {code: 0});
            }

            /**
             * Save configuration
             */
            function save() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));

                    return;
                }

                eskBox.saveConfig().then($uibModalInstance.close);
            }
        }
    })(angularJS);
}
