import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskBox, NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgFilter} from '@legacy/app/managers/ressources';
import {NgFilterManager} from '@legacy/app/managers/managers';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getBiensLastCriteriaCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.component('eskDossierBiensLastCriteriaCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/last.criteria.html',
        });

        /**
         * Controller of criteria for last dossier biens
         *
         * @param FilterManager
         * @param EskUtils
         * @param Ng2RouterStateForNgService
         * @param EskBox
         */
        Controller.$inject = ['FilterManager', 'EskUtils', 'Ng2RouterStateForNgService', 'EskBox'];
        function Controller(this: any,
                            filterManager: NgFilterManager,
                            eskUtils: NgEskUtils,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            eskBox: NgEskBox) {
            const $ctrl = this;
            let filter: NgFilter;

            if (ng2RouterStateForNgService.current.name!.startsWith('/app/locations')) {
                $ctrl.titre = 'Critères de recherche des derniers biens en location';
                $ctrl.typeDossier = DossierBienTypesConst.LOCATION;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/ventes')) {
                $ctrl.titre = 'Critères de recherche des derniers biens en vente';
                $ctrl.typeDossier = DossierBienTypesConst.VENTE;
            } else {
                throw new Error('Impossible de déterminer le "typeDossier" pour ' + ng2RouterStateForNgService.current.name);
            }

            $ctrl.launch = launch;
            $ctrl.box = eskBox.getOneByCode($ctrl.typeDossier + 's-last');
            filterManager.getActiveByRouteCode('get_' + $ctrl.typeDossier + 's', 'box_new_' + $ctrl.typeDossier + 's')
                .then(filterResponse => filterResponse as NgFilter)
                .then(filterResponse => eskUtils.getSearchFromFilter($ctrl.typeDossier, filterResponse.queryParameters as NgFilter).then(search => {
                    filter = filterResponse;
                    $ctrl.search = search;
                }));

            /**
             * Launch request
             */
            function launch() {
                if (!angular.isFunction($ctrl.updateSearchLocalisations)) {
                    return;
                }

                $ctrl.saving = true;
                eskBox.saveConfig().then(() => {
                    $ctrl.updateSearchLocalisations($ctrl.search).then(() => {
                        filter.queryParameters = eskUtils.getFilterFromSearch($ctrl.search);
                        filter.save().then(() => ng2RouterStateForNgService.navigateByUrl('/app/dashboard')).finally(() => $ctrl.saving = false);
                    });
                });
            }
        }
    })(angularJS);
}
