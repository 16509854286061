import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {IBonvisiteVenteApi} from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.interfaces';
import {
    ICBonvisiteVentesApi
} from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.interfaces';

@Injectable({providedIn: 'root'})
export class BonvisiteVentesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(bonvisiteUuid: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$('/etude/bons-visite/' + bonvisiteUuid + '/ventes/' + uuid);
    }

    getCollectionByLink$(link: string): Observable<ICBonvisiteVentesApi> {
        return this._modelApiService.getCollection$(link);
    }

    save$(bonvisiteUuid: string, bonvisiteVenteApi: IBonvisiteVenteApi): Observable<IBonvisiteVenteApi> {
        return this._modelApiService.post$('/etude/bons-visite/' + bonvisiteUuid + '/ventes', bonvisiteVenteApi);
    }
}
