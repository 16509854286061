import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {FacebookService} from '@shared/facebook/facebook.service';

export default function getEskConfigurationPasserellesCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskConfigurationPasserellesCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/configuration/passerelles.html',
        });

        /**
         * General controller
         *
         * @param Ng2EtudeService
         * @param Ng2FacebookService
         * @constructor
         */
        Controller.$inject = ['Ng2EtudeService', 'Ng2FacebookService'];
        function Controller(this: any, ng2EtudeService: EtudeService, ng2FacebookService: FacebookService) {
            const $ctrl = this;

            ng2FacebookService.razFBService();
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.passerelles = currentEtude.configurablesPasserelles);
        }
    })(angularJS);
}
