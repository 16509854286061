import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import AModel from '@models/model.abstract';

export default class Email extends AModel {
    static goodReceptionPolitesse(formulePolitesse: string): string {
        return 'Nous vous en souhaitons bonne réception et restons à votre entière disposition pour tous renseignements ' +
            'complémentaires.<br><br>Nous vous prions d\'agréer, ' + formulePolitesse + ', l\'expression de nos ' +
            'salutations les plus distinguées.';
    }

    static readonly statuts = {
        BOUNCED_HARD: 'hard-bounced',
        BOUNCED_SOFT: 'soft-bounced',
        DRAFT: 'draft',
        DELIVERED_COMPLETE: 'delivered',
        DELIVERED_PARTIALLY: 'partially-delivered',
        NEW: AModel.STATUT_NEW,
        SENT: 'sent',
    };
    private readonly _id: number;
    private _labelStatut!: string;
    private _message!: string;
    private _recipients: EmailRecipient[] = [];
    private _sendingDate!: string;
    private _shortLabelStatut!: string;
    private _statut!: string;
    private _subject!: string;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get id(): number {
        return this._id;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get recipients(): EmailRecipient[] {
        return this._recipients;
    }

    set recipients(value: EmailRecipient[]) {
        this._recipients = value;
    }

    get sendingDate(): string {
        return this._sendingDate;
    }

    set sendingDate(value: string) {
        this._sendingDate = value;
    }

    get shortLabelStatut(): string {
        return this._shortLabelStatut;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
        switch (this._statut) {
            case Email.statuts.BOUNCED_HARD :
                this._labelStatut = 'rejeté';
                this._shortLabelStatut = 'r';
                break;
            case Email.statuts.BOUNCED_SOFT :
                this._labelStatut = 'rejeté';
                this._shortLabelStatut = 'r';
                break;
            case Email.statuts.DELIVERED_COMPLETE :
                this._labelStatut = 'délivré';
                this._shortLabelStatut = 'd';
                break;
            case Email.statuts.DELIVERED_PARTIALLY :
                this._labelStatut = 'délivré partiellement';
                this._shortLabelStatut = '!';
                break;
            case Email.statuts.DRAFT :
            case Email.statuts.NEW :
                this._labelStatut = 'brouillon';
                this._shortLabelStatut = 'b';
                break;
            case Email.statuts.SENT :
                this._labelStatut = 'envoyé';
                this._shortLabelStatut = 'e';
                break;
            default:
                this._labelStatut = undefined!;
                this._shortLabelStatut = undefined!;
        }
    }

    get subject(): string {
        return this._subject;
    }

    set subject(value: string) {
        this._subject = value;
    }

    isDelivered(): boolean {
        return this.isDeliveredComplete() || this.isDeliveredPartially();
    }

    isDeliveredComplete(): boolean {
        return this.statut === Email.statuts.DELIVERED_COMPLETE;
    }

    isDeliveredPartially(): boolean {
        return this.statut === Email.statuts.DELIVERED_PARTIALLY;
    }

    isEditable(): boolean {
        return this.statut === Email.statuts.DRAFT || this.statut === Email.statuts.NEW;
    }

    isHardBounced(): boolean {
        return this.statut === Email.statuts.BOUNCED_HARD;
    }

    isSent(): boolean {
        return this.statut === Email.statuts.SENT;
    }

    isSoftBounced(): boolean {
        return this.statut === Email.statuts.BOUNCED_SOFT;
    }
}
