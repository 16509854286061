import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import gmapsFreeDrawTemplate from '@legacy/templates/soqrate/components/gmaps.free-draw';
import inputPhotosTemplate from '@legacy/templates/soqrate/components/input-photos';
import inputRadioYNNTemplate from '@legacy/templates/soqrate/components/input-radio-ynn';
import phoneTemplate from '@legacy/templates/soqrate/components/phone';
import facebookTemplate from '@legacy/templates/soqrate/directives/facebook';
import inputFileTemplate from '@legacy/templates/soqrate/directives/input-file';
import panelTemplate from '@legacy/templates/soqrate/directives/panel';

export default function getSoqTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/soqrate/components/gmaps.free-draw.html', gmapsFreeDrawTemplate);
            $templateCache.put('src/app/legacy/templates/soqrate/components/input-photos.html', inputPhotosTemplate);
            $templateCache.put('src/app/legacy/templates/soqrate/components/input-radio-ynn.html', inputRadioYNNTemplate);
            $templateCache.put('src/app/legacy/templates/soqrate/components/phone.html', phoneTemplate);
            $templateCache.put('src/app/legacy/templates/soqrate/directives/facebook.html', facebookTemplate);
            $templateCache.put('src/app/legacy/templates/soqrate/directives/input-file.html', inputFileTemplate);
            $templateCache.put('src/app/legacy/templates/soqrate/directives/panel.html', panelTemplate);
        }
    })(angularJS);
}
