import {Component, inject, Input, OnInit} from '@angular/core';
import {Observable, ReplaySubject, Subject, switchMap} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {take} from 'rxjs/operators';
import {IMapMarker, IMapData, IMapBox} from '@shared/map/map.interfaces';
import {CSitesFactory} from '@models/sites/collection/sites.collection.factory';

@Component({selector: 'app-etude-map', templateUrl: 'etude-map.component.html'})
export class AppEtudeMapComponent implements OnInit {
    private _cSitesFactory = inject(CSitesFactory);
    private _etudeFactory = inject(EtudeFactory);
    private _mapSource = new Subject<{mapBox: IMapBox, data: IMapData}>();
    private _map$ = this._mapSource.asObservable();
    private _etudeSource = new ReplaySubject<Etude>(1);
    private _etude$ = this._etudeSource.asObservable();

    @Input()
    set etude(value: Etude) {
        this._etudeSource.next(value);
    }

    get map$(): Observable<{mapBox: IMapBox, data: IMapData}> {
        return this._map$;
    }

    @Input()
    set link(value: string) {
        this._etudeFactory.getByLink$(value).pipe(take(1)).subscribe(etude => this._etudeSource.next(etude));
    }

    ngOnInit(): void {
        this._etude$.pipe(
            switchMap(etude => this._cSitesFactory.getByLink$(etude.linkSites)),
            take(1)
        ).subscribe(cSites => {
            const communeSitePrincipal = cSites.results[0];
            const markers: IMapMarker[] = [];

            cSites.results.forEach(site => markers.push({
                latitude: site.latitude,
                longitude: site.longitude
            }));

            this._mapSource.next({
                mapBox: {
                    center: {
                        latitude: communeSitePrincipal.latitude,
                        longitude: communeSitePrincipal.longitude,
                    },
                    source: 'site',
                },
                data: {
                    markers: markers,
                    useAutoZoom: markers.length > 1,
                }
            });
        });
    }
}
