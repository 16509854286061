import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {NgBienPhoto} from '@legacy/app/managers/ressources';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';

export default function getBienModalVisibilityPhotoCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.controller('BienModalVisibilityPhotoCtrl', Controller);

        /**
         * Controller of modal to edit visibility Photo
         *
         * @param bienPhoto
         * @param $uibModalInstance
         */
        Controller.$inject = ['bienPhoto', '$uibModalInstance'];
        function Controller(this: any, bienPhoto: NgBienPhoto, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
            const $ctrl = this;

            $ctrl.save = save;
            $ctrl.visibilities = [
                {
                    code: 'publique',
                    shortLabel: 'Publique',
                    label: 'La photo sera visible pour tout le monde.'
                },
                {
                    code: 'protegee',
                    shortLabel: 'Protégée',
                    label: 'La photo ne sera visible que par vous et vos confrères dans ' +
                        AppNoteoIconTextNotyComponent.label + ' et sur les impressions de fiche confidentielle.'
                },
                {
                    code: 'privee',
                    shortLabel: 'Privée',
                    label: 'La photo ne sera visible que par vous et sur vos impressions de fiche confidentielle.'
                }
            ];
            $ctrl.visibilite = find($ctrl.visibilities, {code: bienPhoto.visibilite});

            /**
             * Select photo to display
             */
            function save() {
                bienPhoto.visibilite = $ctrl.visibilite.code;
                bienPhoto.save();
                $uibModalInstance.close();
            }
        }
    })(angularJS);
}
