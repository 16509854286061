import {inject, Injectable} from '@angular/core';
import {Observable, of, ReplaySubject, switchMap} from 'rxjs';
import {CSitesFactory} from '@models/sites/collection/sites.collection.factory';
import CSites from '@models/sites/collection/sites.collection.model';
import {map, take, tap} from 'rxjs/operators';
import Site from '@models/sites/site/site.model';
import {ICSitesQueryParameters} from '@models/sites/collection/sites.collection.interfaces';

@Injectable({providedIn: 'root'})
export class CSitesService {
    private _cSitesFactory = inject(CSitesFactory);
    private _currentSource = new ReplaySubject<CSites>(1);

    get current$(): Observable<CSites> {
        return this._currentSource.asObservable();
    }

    addNext$(cSites: CSites): Observable<CSites> {
        return this._cSitesFactory.getNext$(cSites).pipe(
            tap(newCSites => cSites.updateNext(newCSites)),
            map(_ => cSites),
        );
    }

    getCAnnexes$(link: string): Observable<CSites> {
        return this._cSitesFactory.getByLink$(link).pipe(
            tap(cSites => cSites.removeResult(site => site === cSites.results[0])),
        );
    }

    getSitePrincipal$(link: string): Observable<Site> {
        return this._cSitesFactory.getByLink$(link).pipe(map(cSites => cSites.results[0]));
    }

    initCurrent(link: string): void {
        this._currentSource.next(undefined!);
        this._cSitesFactory.getByLink$(link).pipe(take(1))
            .subscribe(currentCSites => this._currentSource.next(currentCSites));
    }

    getAll$(queryParameters: ICSitesQueryParameters): Observable<CSites> {
        return this._cSitesFactory.get$(queryParameters).pipe(switchMap(cSites => this.updateAll$(cSites)));
    }

    updateAll$(cSites: CSites): Observable<CSites> {
        if (cSites.links.next) {
            return this._cSitesFactory.getNext$(cSites).pipe(
                tap(newCSites => cSites.updateNext(newCSites)),
                switchMap(_ => this.updateAll$(cSites)),
            );
        }

        return of(cSites);
    }
}
