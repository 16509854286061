import {inject, Injectable} from '@angular/core';
import Contact from '@models/contacts/contact/contact.model';
import {PersonFactory} from '@models/contacts/person/person.factory';
import {EntrepriseFactory} from '@models/contacts/entreprise/entreprise.factory';
import {NgContact} from '@legacy/app/managers/ressources';
import {IContactApi} from '@models/contacts/contacts.interfaces';
import {ContactsApiService} from '@models/contacts/contacts.api.service';
import {map} from 'rxjs/operators';
import Person from '@models/contacts/person/person.model';
import Entreprise from '@models/contacts/entreprise/entreprise.model';
import {Observable} from 'rxjs';
import {HttpOptions} from '@core/api/api.interfaces';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';

@Injectable({providedIn: 'root'})
export class ContactFactory {
    private _contactsApiService = inject(ContactsApiService);
    private _entrepriseFactory = inject(EntrepriseFactory);
    private _personFactory = inject(PersonFactory);

    create(contactApi: IContactApi): Contact {
        let contact: Contact;

        if (contactApi.type === Contact.type.ENTREPRISE) {
            contact = this._entrepriseFactory.createFromContactApi(contactApi);
        } else {
            contact = this._personFactory.createFromContactApi(contactApi);
        }

        contact.externalId = contactApi.externalId;
        contact.externalSource = contactApi.externalSource;
        contact.formuleAdressePostale = contactApi.formuleAdressePostale;
        contact.formuleIntroduction = contactApi.formuleIntroduction;
        contact.formulePolitesse = contactApi.formulePolitesse;
        contact.invalidEmailAddress = contactApi.invalidEmailAddress;
        contact.unsubscribed = contactApi.unsubscribed;
        if (contactApi._embedded?.representant) {
            contact.representant = this.create(contactApi._embedded.representant);
        }

        return contact;
    }

    createFromVenteArchiveInfos(venteArchiveInfos: VenteArchiveInfos): Contact {
        const contact = this._personFactory.createVirgin();

        contact.nom = venteArchiveInfos.acquereur;

        return contact;
    }

    delete$(contact: Contact): Observable<void> {
        return this._contactsApiService.delete$(contact.id.toString());
    }

    forApi(contact: Contact): IContactApi {
        const contactApi = {} as IContactApi;

        if (contact.isEntreprise()) {
            contactApi.entreprise = this._entrepriseFactory.forApi(contact as Entreprise);
        } else if (contact.isPerson()) {
            contactApi.person = this._personFactory.forApi(contact as Person);
        }

        if (contact.representant) {
            contactApi.representantId = contact.representant.id;
        }

        contactApi.externalId = contact.externalId;
        contactApi.externalSource = contact.externalSource;

        return contactApi;
    }

    getByLink$(link: string): Observable<Contact> {
        return this._contactsApiService.getByLink$(link).pipe(map(contactApi => this.create(contactApi)));
    }

    save$(contact: Contact, httpOptions?: HttpOptions): Observable<Contact> {
        return this._contactsApiService.save$(contact.uuid, this.forApi(contact), httpOptions).pipe(map(contactApi => this.create(contactApi)));
    }

    ngCreate(ngContact: NgContact): Contact {
        let contact: Contact;

        if (ngContact.type === Contact.type.ENTREPRISE) {
            contact = this._entrepriseFactory.ngCreate(ngContact);
        } else {
            contact = this._personFactory.ngCreate(ngContact);
        }

        contact.externalId = ngContact.externalId;
        contact.externalSource = ngContact.externalSource;
        contact.formuleAdressePostale = ngContact.formuleAdressePostale;
        contact.formuleIntroduction = ngContact.formuleIntroduction;
        contact.formulePolitesse = ngContact.formulePolitesse;
        contact.invalidEmailAddress = ngContact.invalidEmailAddress;
        contact.unsubscribed = ngContact.unsubscribed;
        if (ngContact.representant) {
            contact.representant = this.ngCreate(ngContact.representant);
        }

        return contact;
    }
}
