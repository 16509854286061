export default class InotUser {
    private _convivialName!: string;
    private _externalId!: string;
    private _nom!: string;
    private _prenom!: string;

    get convivialName(): string {
        return this._convivialName;
    }

    get externalId(): string {
        return this._externalId;
    }

    set externalId(value: string) {
        this._externalId = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
        this.setConvivialName();
    }

    get prenom(): string {
        return this._prenom;
    }

    set prenom(value: string) {
        this._prenom = value;
        this.setConvivialName();
    }

    setConvivialName(): void {
        this._convivialName = [this._prenom, this._nom].filter(item => !!item).join(' ');
    }
}
