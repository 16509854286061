export default `
<ng-switch on="$ctrl.displayButton">
  <button type="button" ng-switch-when="true" class="btn btn-sort" ng-click="$ctrl.onClick()">
    {{$ctrl.sort.column | translate}}

    <span ng-if="$ctrl.sort.code === $ctrl.currentSort.code && $ctrl.currentSort.direction === $ctrl.sortConst.ASCENDING">
      <fa-icon [icon]="'caret-down'"></fa-icon>
    </span>

    <span ng-if="$ctrl.sort.code === $ctrl.currentSort.code && $ctrl.currentSort.direction !== $ctrl.sortConst.ASCENDING">
      <fa-icon [icon]="'caret-up'"></fa-icon>
    </span>

    <span ng-if="$ctrl.sort.code !== $ctrl.currentSort.code"><fa-icon [icon]="'sort'"></fa-icon></span>
  </button>

  <span ng-switch-default>{{$ctrl.sort.column | translate}}</span>
</ng-switch>
`;
