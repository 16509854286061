import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {NgDemandeurManager, NgEmailManager} from '@legacy/app/managers/managers';
import {NgDemandeurUtils} from '@legacy/app/demandeurs/demandeurs';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgDemandeur, NgEmail, NgFilter, NgItemDictionary} from '@legacy/app/managers/ressources';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {DisplayMode} from '@shared/constants';
import {NgDemandeursCollection} from '@legacy/app/managers/collections';
import {UserService} from '@models/users/user/user.service';
import Filter from '@models/filters/filter/filter.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

export default function getDemandeursWalletCurrentCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDemandeursWalletCurrentCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/controllers/wallet/current.html',
        });

        /**
         * Controller for Demandeurs list
         *
         * @param DemandeurManager
         * @param Ng2RouterStateForNgService
         * @param DemandeursUtils
         * @param EmailManager
         * @param Ng2EtudeService
         * @param SoqModalesManager
         * @param $q
         * @param SoqSweetAlert
         * @param Ng2UserService
         * @constructor
         */
        Controller.$inject = ['DemandeurManager', 'Ng2RouterStateForNgService', 'DemandeursUtils', 'EmailManager', 'Ng2EtudeService', 'SoqModalesManager', '$q', 'SoqSweetAlert', 'Ng2UserService'];
        function Controller(this: any,
                            demandeurManager: NgDemandeurManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            demandeursUtils: NgDemandeurUtils,
                            emailManager: NgEmailManager,
                            ng2EtudeService: EtudeService,
                            soqModalesManager: NgSoqModalesManager,
                            $q: IQService,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2UserService: UserService) {
            const $ctrl = this;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.changeDisplayMode = changeDisplayMode;
            $ctrl.getSelectedDemandeurs = getSelectedDemandeurs;
            $ctrl.groupArchive = groupArchive;
            $ctrl.onChangeFilter = onChangeFilter;
            $ctrl.onChangePagination = onChangePagination;
            $ctrl.send = send;
            $ctrl.filterCode = Filter.codes.WALLET_CURRENT;
            $ctrl.filterRoute = Filter.routes.GET_ETUDE_DEMANDEURS;
            ng2UserService.last$.subscribe(currentUser => {
                $ctrl.currentUser = currentUser;
                $ctrl.options = {
                    nameDossiers: 'DEMANDEURS_LIST',
                    typeFilter: 'DEMANDEURS_WALLET_CURRENT',
                    displayMode: $ctrl.currentUser.clientMetadata.getWallet($ctrl.filterRoute, $ctrl.filterCode).view,
                    loader: {updating: false},
                    onChangeFilter: $ctrl.onChangeFilter,
                };
            });

            /**
             * Archive selected demandeurs
             */
            function groupArchive() {
                $ctrl.barreFaireLoader = 'Archivage groupé ...';
                soqModalesManager.open('DemandeursModalGroupArchive').then(
                    archiveInfos => archiveInfos as { rechercheId: number, type: NgItemDictionary }
                ).then(archiveInfos => {
                    const html = [] as string[];
                    const references: string[] = $ctrl.getSelectedDemandeurs().map((demandeur: NgDemandeur): string => demandeur.reference);
                    let confirmButtonText: string;

                    if (references.length > 1) {
                        html.push("<p class=\"text-justify\">Pour les demandeurs");
                        confirmButtonText = 'Archiver les demandeurs';
                    } else {
                        html.push("<p class=\"text-justify\">Pour le demandeur");
                        confirmButtonText = 'Archiver le demandeur';
                    }

                    html.push(" <span class=\"text-bold\">" + references.join((', ')) + "</span>, vous déclarez ");
                    if (archiveInfos.type.code === Demandeur.archiveTypes.VENTE_NON_TROUVEE) {
                        html.push("ne pas avoir trouvé de bien en vente à ");
                        if (references.length > 1) {
                            html.push("leur recherche.");
                        } else {
                            html.push("sa recherche.");
                        }
                    } else {
                        if (references.length > 1) {
                            html.push("qu'ils ne sont plus");
                        } else {
                            html.push("qu'il n'est plus");
                        }

                        html.push(" en recherche d'un bien en vente.");
                    }

                    if (references.length > 1) {
                        html.push("</p><hr><p class=\"text-justify text-muted\">En archivant ces dossiers,");
                    } else {
                        html.push("</p><hr><p class=\"text-justify text-muted\">En archivant ce dossier,");
                    }

                    html.push(" vous vous engagez à ce que les informations saisies au-dessus soient exactes.<br>La société Noteo recevra une copie de ces informations. Conformément aux conditions de mise à disposition ainsi qu'à la charte, la société Noteo et l'administrateur se réservent le droit de contrôler celles-ci.</p>");

                    return soqSweetAlert.dangerConfirmation('Confirmer l\'archivage groupé', html.join(''), {confirmButtonText}).then(() => {
                        const promises: IPromise<unknown>[] = [];

                        $ctrl.getSelectedDemandeurs().forEach((demandeur: NgDemandeur) => {
                            promises.push(demandeur.archive({...archiveInfos, ...{rechercheId: demandeur.recherches[0]?.id}}));
                        });

                        return $q.all(promises);
                    });
                }).then(() => $ctrl.onChangeFilter($ctrl.filter)).finally(() => $ctrl.barreFaireLoader = undefined!);
            }

            /**
             * Update Demandeurs list
             *
             * @param filter
             * @returns {Promise}
             */
            function onChangeFilter(filter: NgFilter) {
                $ctrl.demandeurs = undefined;
                $ctrl.filter = filter;
                $ctrl.options.loader.updating = true;

                return demandeurManager.getAllEtudeFiltered($ctrl.filter, {
                    limit: 50,
                    keywords: $ctrl.filter.queryParameters.keywords,
                }).then(function (demandeursFiltered) {
                    $ctrl.onChangePagination(demandeursFiltered);
                    $ctrl.demandeurs = demandeursFiltered;
                }).finally(function () {
                    $ctrl.options.loader.updating = false;
                });
            }

            /**
             * Update Demandeurs list
             *
             * @param demandeursFiltered
             * @returns {Array}
             */
            function onChangePagination(demandeursFiltered: NgDemandeursCollection) {
                angular.forEach(demandeursFiltered.collection, function (demandeur) {
                    if (angular.isArray(demandeur.recherches) && demandeur.recherches.length > 0) {
                        demandeur._esk.routing = '/app/demandeurs/' + demandeur.id.toString() + '/resultats';
                    } else {
                        demandeur._esk.routing = '/app/demandeurs/' + demandeur.id.toString() + '/edit/criteres';
                    }
                });

                return demandeursFiltered;
            }

            /**
             * Change view mode
             *
             * @param displayMode
             */
            function changeDisplayMode(displayMode: DisplayMode) {
                $ctrl.options.displayMode = displayMode;
            }

            /**
             * Get selected Demandeurs
             *
             * @returns {Array}
             */
            function getSelectedDemandeurs(): NgDemandeur[] {
                if (!angular.isObject($ctrl.demandeurs)) {
                    return [];
                }

                return filter($ctrl.demandeurs.collection, {_esk: {select: true}});
            }

            /**
             * Send list Demandeurs
             *
             * @returns relance
             */
            function send(relance: boolean) {
                const txt = ContactsGroup.default.FORMULE_INTRODUCTION + '<br><br>' +
                    'Vous nous avez sollicités pour vous accompagner dans votre projet immobilier et nous vous en remercions vivement.<br>' +
                    'A ce jour, nous n’avons pas encore concrétisé votre projet ensemble.<br><br>' +
                    'Pourriez-vous, s’il vous plaît, nous informer si vos critères de recherche ont évolué et si vous êtes toujours à la recherche de biens ?' +
                    '<br><br>N\'hésitez pas à contacter notre service négociation.<br><br>' +
                    'Je vous prie de croire ' + ContactsGroup.default.FORMULE_POLITESSE +
                    ' à l\'assurance de mes salutations distinguées.';

                $ctrl.barreFaireLoader = 'global.en_cours.SEND';
                ng2EtudeService.last$.subscribe(currentEtude => {
                    demandeursUtils.sendGroupedEmail($ctrl.getSelectedDemandeurs(), emailManager.create({
                        subject: (relance ? currentEtude.raisonSociale + " : Suivi de votre dossier" : ""),
                        message: (relance ? txt : "") + "<br><br>" + emailManager.getCurrentUserSignature(),
                    }) as NgEmail).finally(function () {
                        $ctrl.barreFaireLoader = undefined;
                    });
                });
            }
        }
    })(angularJS);
}
