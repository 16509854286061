import {inject, Injectable} from '@angular/core';
import {EtudeSettingsBannerApiService} from '@models/etudes/etude/settings/banner/etude-settings-banner.api.service';
import Transfer from '@models/transfers/transfer/transfer.model';
import {TransferFactory} from '@models/transfers/transfer/transfer.factory';
import Etude from '@models/etudes/etude/etude.model';
import {Observable} from 'rxjs';
import {IEtudeSettingsBannerPositionApi} from '@models/etudes/etude/settings/banner/etude-settings-banner.interfaces';
import {IMG_MAX_SIZE} from '@shared/image/image.constants';

@Injectable({providedIn: 'root'})
export class EtudeSettingsBannerFactory {
    static readonly imgLimit = {maxSize: IMG_MAX_SIZE, minHeight: 548, minWidth: 1280};
    private _etudeSettingsBannerApiService = inject(EtudeSettingsBannerApiService);
    private _transferFactory = inject(TransferFactory);

    delete$(etude: Etude): Observable<void> {
        return this._etudeSettingsBannerApiService.delete$(etude.uuid);
    }

    updatePosition$(etude: Etude, etudeSettingsBannerPositionApi: IEtudeSettingsBannerPositionApi): Observable<void> {
        return this._etudeSettingsBannerApiService.updatePosition$(etude.uuid, etudeSettingsBannerPositionApi);
    }

    upload(etude: Etude, file: File): Transfer<void> {
        const formData = new FormData();

        formData.append('file', file);

        const transfer = this._transferFactory.create(this._etudeSettingsBannerApiService.upload$(etude.uuid, formData));

        transfer.start();

        return transfer;
    }
}
