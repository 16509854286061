import {inject, Injectable} from '@angular/core';
import Transfer from '@models/transfers/transfer/transfer.model';
import {EtudeSettingsBannerFactory} from '@models/etudes/etude/settings/banner/etude-settings-banner.factory';
import Etude from '@models/etudes/etude/etude.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EtudeSettingsBannerService {
    private _etudeSettingsBannerFactory = inject(EtudeSettingsBannerFactory);
    private _currentTransferSource = new BehaviorSubject<Transfer<void>>(undefined!);
    private _currentTransfer$ = this._currentTransferSource.asObservable();

    get currentTransfer$(): Observable<Transfer<void>> {
        return this._currentTransfer$;
    }

    upload$(etude: Etude, file: File): Observable<void> {
        return this.currentTransfer$.pipe(
            take(1),
            map(currentTransfer => {
                if (currentTransfer) {
                    currentTransfer.abort();
                }
            }),
            tap(_ => this._currentTransferSource.next(this._etudeSettingsBannerFactory.upload(etude, file))),
        );
    }
}
