import {Component, inject, Input} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {take} from 'rxjs/operators';

@Component({selector: 'app-demandeur-view', templateUrl: 'demandeur.view.component.html'})
export class AppDemandeurViewComponent {
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurSource = new Subject<Demandeur>();
    private _demandeur$ = this._demandeurSource.asObservable();

    get demandeur$(): Observable<Demandeur> {
        return this._demandeur$;
    }

    @Input()
    set link(value: string) {
        this._demandeurFactory.getByLink$(value).pipe(take(1)).subscribe(demandeur => this._demandeurSource.next(demandeur));
    }
}
