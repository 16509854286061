<div class="tw-relative tw-flex">
  <div class="tw-flex-shrink-0 tw-overflow-hidden" [ngClass]="options.withBorder ? 'tw-rounded-l-md' : ''">
    <app-bien-photo [dossierBien]="dossierBien" [options]="{linkPhotoAttribute: 'linkPremierePhotoThumbnail'}"
                    class="tw-block tw-h-24 tw-w-24"/>
  </div>

  <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-bg-white tw-py-2 tw-px-4 tw-overflow-hidden"
       [ngClass]="options.withBorder ? 'tw-border-t tw-border-r tw-border-b tw-border-gray-200 tw-rounded-r-md' : ''">
    <div class="tw-flex tw-content-center">
      <div class="tw-flex-1 tw-flex tw-flex-wrap tw-justify-between tw-gap-2">
        <span class="tw-text-sm tw-text-gray-700">{{ dossierBien.reference }}</span>

        @if (dossierBien.isLocation()) {
          <esk-location-label-price [location]="location"/>
        }

        @if (dossierBien.isVente()) {
          <app-vente-price [vente]="vente" [options]="{enabledNV: false}" class="tw-inline-block tw-text-xs"/>
        }
      </div>

      @if (options.enabledAction) {
        <button type="button" class="tw-btn-tertiary-default tw-py-0 tw-pr-0"
                (click)="action.emit(dossierBien)" [ngClass]="options.actionOnAll ? 'tw-pointer-events-none' : ''">
          @if (options.enabledAction === 'close') {
            <fa-icon [icon]="'times'" size="xl"/>
          }

          @if (options.enabledAction === 'choose') {
            <fa-icon [icon]="'arrow-right'" size="xl" class="fa-rotate-by" style="--fa-rotate-angle: -45deg;"/>
          }
        </button>
      }
    </div>

    <div class="tw-text-sm tw-truncate">{{ dossierBien.bien.fullInlineAdresse }}</div>
  </div>

  @if (options.enabledAction && options.actionOnAll) {
    <button type="button" (click)="action.emit(dossierBien)"><span class="tw-absolute tw-inset-0"></span></button>
  }
</div>
