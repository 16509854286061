import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewHabitationService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isInfosLocativesVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isResidenceVisible!: boolean;
    private _isUsageVisible!: boolean;

    get isInfosLocativesVisible(): boolean {
        return this._isInfosLocativesVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isResidenceVisible(): boolean {
        return this._isResidenceVisible;
    }

    get isUsageVisible(): boolean {
        return this._isUsageVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isInfosLocativesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.chargesLocatives)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.dateDisponibilite)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.loyerActuel)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.occupation);
        this._isResidenceVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.residence)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.residenceService);
        this._isUsageVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.usage);

        this._isPartVisible = this.isResidenceVisible || this.isUsageVisible;
    }
}
