import {inject, Injectable} from '@angular/core';
import {IArrondissementApi} from '@models/arrondissements/arrondissement/arrondissement.interfaces';
import Arrondissement from '@models/arrondissements/arrondissement/arrondissement.model';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';

@Injectable({providedIn: 'root'})
export class ArrondissementFactory {
    private _quartierFactory = inject(QuartierFactory);

    create(arrondissementApi: IArrondissementApi): Arrondissement {
        const arrondissement = new Arrondissement(arrondissementApi.id.toString(), arrondissementApi.id);

        arrondissement.code = arrondissementApi.code;
        arrondissement.nom = arrondissementApi.nom;
        if (arrondissementApi._embedded?.quartiers?.length > 0) {
            arrondissement.quartiers = arrondissementApi._embedded.quartiers
                .filter(quartierApi => quartierApi)
                .map(quartierApi => this._quartierFactory.create(quartierApi));
        }

        return arrondissement;
    }
}
