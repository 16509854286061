<div [@openClose]="isOpen" [hidden]="!isOpen" class="tw-absolute tw-inset-0 tw-z-[1400] tw-overflow-hidden
tw-overflow-y-auto">
  <div class="tw-relative tw-flex sm:tw-block tw-items-start tw-justify-center tw-min-h-screen">
    <div class="tw-fixed tw-h-full tw-w-full tw-bg-gray-500 tw-bg-opacity-50 tw-transition-opacity tw-backdrop-blur-sm"
         (click)="close()"></div>

    <div class="tw-absolute tw-inset-y-0 tw-right-0 tw-w-full sm:tw-max-w-lg tw-bg-white tw-overflow-y-auto tw-transition-all"
         [@openCloseSlideOver]="isOpen">
      <div class="tw-relative tw-h-full tw-w-full">
        <div class="tw-absolute tw-top-0 tw-right-0 tw-px-4 tw-py-5 sm:tw-px-5">
          <button type="button" class="tw-h-8 tw-w-8 tw-text-gray-400 hover:tw-text-gray-500" (click)="close()">
            <fa-icon [icon]="'times'" size="2xl"/>
          </button>
        </div>

        <ng-template #slideOverContent/>
      </div>
    </div>
  </div>
</div>
