import {inject, Injectable} from '@angular/core';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import Contact from '@models/contacts/contact/contact.model';
import {Observable, of, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {ContactsGroupMemberFactory} from '@models/contacts-group/members/member/contacts-group-member.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class ContactsGroupMemberService {
    private _contactFactory = inject(ContactFactory);
    private _contactsGroupMemberFactory = inject(ContactsGroupMemberFactory);

    addFromContact$(contactsGroup: ContactsGroup, contactToLink: Contact, httpOptions?: HttpOptions): Observable<ContactsGroupMember> {
        let contact$ = of(contactToLink);

        if (contactToLink.isNew()) {
            contact$ = this._contactFactory.save$(contactToLink, httpOptions);
        }

        return contact$.pipe(
            switchMap(contact => this.save$(contactsGroup, this._contactsGroupMemberFactory.createFromContact(contact))),
            tap(contactGroupMember => contactsGroup.members.push(contactGroupMember)),
        );
    }

    delete$(contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember): Observable<void> {
        return this._contactsGroupMemberFactory.delete$(contactsGroup, contactsGroupMember).pipe(
            tap(_ => contactsGroup.members = contactsGroup.members.filter(member => member.uuid !== contactsGroupMember.uuid)),
        );
    }

    deleteFromContact$(contactsGroup: ContactsGroup, contact: Contact): Observable<void> {
        const contactsGroupMember = contactsGroup.members.find(member => member.uuid === contact.uuid)!;

        return this.delete$(contactsGroup, contactsGroupMember);
    }

    save$(contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember): Observable<ContactsGroupMember> {
        let contact$ = of(contactsGroupMember.contact);

        // Avant d'enlever la condition, s'assurer que tous les champs de Contact sont correctement pris en compte à l'enregistrement pour la modification
        if (contactsGroupMember.contact.isNew()) {
            contact$ = this._contactFactory.save$(contactsGroupMember.contact).pipe(tap(contact => contactsGroupMember.contact = contact));
        }

        return contact$.pipe(switchMap(_ => this._contactsGroupMemberFactory.save$(contactsGroup, contactsGroupMember)));
    }
}
