import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppReportPopoverComponent} from '@features/reports/report/popover/report.popover.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [AppReportPopoverComponent],
    exports: [AppReportPopoverComponent],
    imports: [CommonModule, SharedModule],
})
export class ReportsModule {
}
