export default `
<soq-panel box="$ctrl.box">
  <tools-slot>
    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/network-updated/criteres')"
       class="btn btn-transparent"><fa-icon [icon]="'cogs'"></fa-icon></a>

    <button type="button" class="tw-btn-tertiary-info tw-py-1 tw-px-2" ng-click="$ctrl.box.refresh()">
      <fa-icon [icon]="'sync-alt'"></fa-icon>
    </button>
  </tools-slot>

  <content-slot>
    <esk-ventes-list ventes="$ctrl.ventes" on-click="$ctrl.onClickVente" options="$ctrl.options">
    </esk-ventes-list>

    <div ng-if="$ctrl.ventes.totalItems <= 0" class="tw-text-center margin-top-15 margin-bottom-15">
      <p class="text-muted">Aucun bien en vente à afficher.</p>

      <button ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/network-updated/criteres')"
              type="button" class="btn btn-default">Modifier vos critères</button>
    </div>
  </content-slot>
</soq-panel>
`;
