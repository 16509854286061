import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgMediaManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgEmailAttachmentsCollection} from '@legacy/app/managers/collections';
import {NgEmailAttachment} from '@legacy/app/managers/ressources';

export default function getManagersEmailAttachment(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EmailAttachmentManager', manager);

        /**
         * Manager attachment
         *
         * @param BaseManager
         * @param UtilsManager
         * @param MediaManager
         * @returns {EmailAttachmentManager}
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'MediaManager'];
        function manager(baseManager: NgManager, utilsManager: NgUtilsManager, mediaManager: NgMediaManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('email-attachments.cget', {path: '/emails/{emailId}/attachments', method: 'GET'});
            baseManager.addRoute('email-attachments.insert', {path: '/emails/{emailId}/attachments', method: 'POST'});
            baseManager.addRoute('email-attachments.remove', {
                path: '/emails/{emailId}/attachments/{id}',
                method: 'DELETE'
            });

            /**
             * EmailAttachmentManager object
             *
             * @constructor
             */
            angular.extend(EmailAttachmentManager.prototype, baseManager.__proto__);
            EmailAttachmentManager.prototype.eskManager = {
                prefixRoute: 'email-attachments',
                collectionName: 'items'
            };

            function EmailAttachmentManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param emailId
             * @returns {Promise}
             */
            EmailAttachmentManager.prototype.getAll = function (emailId: number) {
                return baseManager.getAll.call(this, {emailId: emailId}).then(emailAttachments => {
                    const emailAttachmentsCollection: NgEmailAttachmentsCollection = emailAttachments as NgEmailAttachmentsCollection;

                    angular.forEach(emailAttachmentsCollection.collection, attachment => attachment._esk.defaultRouteParams!.emailId = emailId);

                    return emailAttachmentsCollection;
                });
            };

            /**
             * Create a EmailAttachment object
             *
             * @param data
             * @param emailId
             * @returns manager.EmailAttachment object
             */
            EmailAttachmentManager.prototype.create = function (data: unknown, emailId: number) {
                if (angular.isArray(data)) {
                    var attachments = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        attachments.push(new EmailAttachment(emailId, data[i]));
                    }

                    return attachments;
                }

                // @ts-ignore
                return new EmailAttachment(emailId, data);
            };

            /**
             * EmailAttachment object
             *
             * @param emailId
             * @param data
             * @constructor
             */
            angular.extend(EmailAttachment.prototype, classResource.prototype);
            EmailAttachment.prototype.eskManager = {prefixRoute: 'email-attachments'};
            function EmailAttachment(this: NgEmailAttachment, emailId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, emailId: emailId};
            }

            /**
             * Extend the existing emailAttachment with new data
             *
             * @param data
             * @returns {Object}
             */
            EmailAttachment.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isObject(this.media)) {
                    this.media = mediaManager.create(this.media);
                }

                return this;
            };

            /**
             * Sanitize object EmailAttachment before send to API
             *
             * @returns {Object}
             */
            EmailAttachment.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                utilsManager.flattenIdForAttribute(sanitizeObject, 'media');

                return sanitizeObject;
            };

            // @ts-ignore
            return new EmailAttachmentManager();
        }
    })(angularJS);
}
