export default `
<div ng-switch on="!!$ctrl.demandeur">
  <div ng-switch-when="true">
    <p class="text-bold" ng-if="$ctrl.demandeur.recherches[0]._esk.resume">
      {{$ctrl.demandeur.recherches[0]._esk.resume}}
    </p>

    <p>{{$ctrl.demandeur.metadata.confidentielles.comments}}</p>
  </div>

  <div ng-switch-default><fa-icon [icon]="'spinner'" animation="spin" size="2x"></fa-icon></div>
</div>
`;
