export default `
<div class="diffusion-VI">
  <p>
    La vente en immo-interactif® est active pour <span class="text-bold">{{diffusion.diffuseur.passerelle.name}}</span>.
  </p>

  <p class="VI-administration">
    Connectez-vous à votre espace Pro <span class="text-bold">{{diffusion.diffuseur.passerelle.name}}</span> afin de :
  </p>

  <ul class="VI-actions">
    <li>- Valider la mise en vente en immo-interactif®</li>

    <li>- Retirer / Suspendre l'annonce</li>

    <li ng-if="diffusion.children.length > 0">
      <span ng-if="diffusion.children.length === 1">
        - Gérer {{diffusion.children[0].passerelle.name}} liée par contrat
      </span>

      <span ng-if="diffusion.children.length > 1">
        - Gérer les passerelles liées par contrat

        <ul class="VI-children">
          <li ng-repeat="child in diffusion.children">- {{child.passerelle.name}}</li>
        </ul>
      </span>
    </li>
  </ul>
</div>
`;
