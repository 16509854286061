import {Injectable} from '@angular/core';
import Phone from '@models/phones/phone/phone.model';
import {NgPhone} from '@legacy/app/managers/ressources';
import {IPhoneApi} from '@models/phones/phone/phone.interfaces';

@Injectable({providedIn: 'root'})
export class PhoneFactory {
    create(phoneApi: IPhoneApi): Phone {
        const phone = this.createVirgin(phoneApi.uuid);

        phone.numero = phoneApi.numero;
        phone.sortableRank = phoneApi.sortableRank;
        phone.type = phoneApi.type;

        return phone;
    }

    createVirgin(uuid?: string): Phone {
        return new Phone(uuid ?? Phone.statuts.NEW);
    }

    forApi(phone: Phone): IPhoneApi {
        const phoneApi = {} as IPhoneApi;

        phoneApi.numero = phone.numero;
        phoneApi.sortableRank = phone.sortableRank;
        phoneApi.type = phone.type;

        return phoneApi;
    }

    ngCreate(ngPhone: NgPhone): Phone {
        const phone = this.createVirgin(ngPhone.uuid);

        phone.numero = ngPhone.numero;
        phone.sortableRank = ngPhone.sortableRank;
        phone.type = ngPhone.type;

        return phone;
    }
}
