<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Terrain</h4>

  <div class="tw-bg-gray-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (isSurfaceCadastraleVisible) {
        <li>
          Surface cadastrale <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.surfaceCadastrale | textCurrency:'m²' }}
        </li>
      }

      @if (isSurfaceCarrezVisible) {
        <li>
          Surface Carrez

          @if (dossierBien.bien.surfaceCarrez > 0) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
            {{ dossierBien.bien.surfaceCarrez | textCurrency:'m²' }}
          } @else {
            non communiquée
          }
        </li>
      }

      @if (isSurfaceBatieVisible) {
        <li>
          Surface bâtie au sol <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.surfaceBatie | textCurrency:'m²' }}
        </li>
      }

      @if (isSurfaceNonBatieVisible) {
        <li>
          Surface non bâtie <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.surfaceNonBatie | textCurrency:'m²' }}
        </li>
      }

      @if (isSurfacePlancherVisible) {
        <li>
          Surface plancher <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.surfacePlancher | textCurrency:'m²' }}
        </li>
      }

      @if (isLongueurFacadeVisible) {
        <li>
          Longueur de façade <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.longueurFacade | textCurrency:'m' }}
        </li>
      }

      @if (isZoneInondableVisible) {
        <li>Zone inondable</li>
      }

      @if (isPointEauVilleVisible) {
        <li>Robinet, puits, ...</li>
      }

      @if (isPointEauCampagneVisible) {
        <li>Mare, étang, lac, ...</li>
      }

      @if (isPanneauxPhotovoltaiquesVisible) {
        <li>Panneaux photovoltaïques</li>
      }

      @if (isDecliviteVisible) {
        <li>
          Déclivité

          @if (dossierBien.bien.degreDeclivite) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
            {{ dossierBien.bien.degreDeclivite | textCurrency:'°' }}
          }
        </li>
      }

      @if (isServitudeVisible) {
        <li>
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Servitude</div> {{ dossierBien.bien.servitude }}
        </li>
      }

      @if (isCloturesVisible) {
        <li [ngClass]="{'xl:tw-col-span-2': dossierBien.bien.clotures.length >= 2}">
          Clôture{{ dossierBien.bien.clotures.length > 1 ? 's' : '' }}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (cloture of dossierBien.bien.clotures; track cloture; let last = $last) {
            {{ cloture.label }}{{ last ? '' : ',' }}
          }
        </li>
      }
    </ul>

    @if (isCommentsTerrainVisible) {
      <div class="tw-text-sm tw-text-gray-700">{{ dossierBien.bien.commentsTerrain }}</div>
    }
  </div>
</div>
