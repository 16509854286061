import AModel from '@models/model.abstract';

export default class LocationMandat extends AModel{
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private readonly _id: number;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get id(): number {
        return this._id;
    }
}
