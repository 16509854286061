import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';

export default function getEskTaskRunnerComp(module: IModule): void {
    ((angular) => {
        'use strict';

        /**
         * @example <esk-task-runner-comp></esk-task-runner-comp>
         */
        module.component('eskTaskRunnerComp', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/task-runner.html',
        });

        /**
         * General controller
         *
         * @param EskTaskRunner
         * @param Ng2RouterStateForNgService
         */
        Controller.$inject = ['EskTaskRunner', 'Ng2RouterStateForNgService'];
        function Controller(this: any,
                            eskTaskRunner: NgEskTaskRunner,
                            ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.taskRunner = eskTaskRunner;
        }
    })(angularJS);
}
