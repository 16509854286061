import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable, switchMap} from 'rxjs';
import {IMediaApi} from '@models/medias/media/media.interfaces';
import {HttpEventProgress, HttpOptions} from '@core/api/api.interfaces';
import Media from '@models/medias/media/media.model';
import {HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class MediasApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IMediaApi> {
        return this.getByLink$('/medias/' + uuid);
    }

    getByLink$(link: string, httpOptions?: HttpOptions): Observable<IMediaApi> {
        return this._modelApiService.get$(link, httpOptions);
    }

    getFromDocuments$(ids: number[], title = Media.NO_TITLE): Observable<IMediaApi> {
        return this._modelApiService.patch$<unknown, IMediaApi>('/documents/print', {ids, titreMedia: title});
    }

    getFromLocationMandats$(locationId: number, id: number): Observable<IMediaApi> {
        return this._modelApiService.patch$<unknown, IMediaApi>(`/locations/${locationId}/mandats/${id}/print`);
    }

    getFromReports$(reportIds: number[], title = Media.NO_TITLE): Observable<IMediaApi> {
        return this._modelApiService.patch$<unknown, IMediaApi>('/reports/print', {reportIds, titreMedia: title});
    }

    read$(link: string, title = Media.NO_TITLE): Observable<Window> {
        return this._modelApiService.openWindow$(link, {title});
    }

    save$(uuid: string, mediaApi: IMediaApi): Observable<IMediaApi> {
        if (uuid === Media.statuts.NEW) {
            throw new Error('Inexistant');
            // Return this._modelApiService.post$('/medias', mediaApi);
        }

        return this._modelApiService.put$('/medias/' + uuid, mediaApi).pipe(switchMap(_ => this.get$(uuid)));
    }

    upload$(formData: FormData): Observable<HttpEventProgress | HttpResponse<IMediaApi>> {
        return this._modelApiService.upload$('/medias/upload', formData);
    }
}
