import ContactsGroup from '@models/contacts-group/contacts-group.model';

export default class LocationLocataire {
    private _locatairesGroup!: ContactsGroup;

    get id(): number {
        return this._locatairesGroup?.id;
    }

    get locatairesGroup(): ContactsGroup {
        return this._locatairesGroup;
    }

    set locatairesGroup(value: ContactsGroup) {
        this._locatairesGroup = value;
    }
}
