export default `
<div ng-if="!$ctrl.options.loading" class="print-button">
  <div class="btn-group" uib-dropdown
       ng-class="$ctrl.options.dropDirection + ($ctrl.listeTemplates.length === 1 ? ' one-menu' : '')">
    <button type="button" class="btn btn-dropdown" ng-click="$ctrl.print($ctrl.listeTemplates[0])">
      <fa-icon icon="print"></fa-icon>

      <span class="btn-label">Imprimer {{$ctrl.listeTemplates[0].name | lowercase}}</span>
    </button>

    <button ng-if="$ctrl.listeTemplates.length > 1" type="button" class="btn" uib-dropdown-toggle>
      <span class="caret"></span>
    </button>

    <ul class="dropdown-menu" uib-dropdown-menu role="menu">
      <li ng-repeat="template in $ctrl.listeTemplates" ng-if="!$first" role="menuitem">
        <button type="button" class="tw-dropdown-link" ng-click="$ctrl.print(template)">
          <fa-icon icon="print"></fa-icon><span>{{template.name}}</span>
        </button>
      </li>

      <li role="menuitem" ng-if="$ctrl.advancedPrint">
        <button type="button" class="tw-dropdown-link" ng-click="$ctrl.advancedPrint()">
          <fa-icon icon="print"></fa-icon><span>Impression avancée</span>
        </button>
      </li>
    </ul>
  </div>
</div>
`;
