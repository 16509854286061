import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {Subject} from 'rxjs';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {ModalService} from '@shared/modal/modal.service';

@Component({selector: 'dc-notestim-publicites', templateUrl: 'notestim-publicites.component.html'})
export class DCNotestimPublicitesComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private readonly _onDestroy$ = new Subject<void>();

    set data(dynamicComponentData: IDynamicComponentData) {
    }

    get moduleNotestim(): string {
        return AppNoteoContactComponent.modules.NOTESTIM;
    }

    close(): void {
        this._modalService.setResponse();
    }
}
