@if (pdfNbPages <= 0) {
  <div class="tw-h-full"><ng-container *ngTemplateOutlet="loaderPdf"/></div>
}

@if (pdfPath$ | async; as pdfPath) {
  <div [ngClass]="{'tw-opacity-0': pdfNbPages <= 0}" [style.--nb-pages]="pdfNbPages"
       class="viewer-height lg:tw-h-full tw-min-h-16">
    <pdf-viewer [src]="pdfPath" class="tw-mx-auto tw-w-full tw-h-full xl:tw-w-[calc(21cm_+_15px)]"
                (after-load-complete)="getPdfDocument($event)"/>
  </div>
} @else {
  <app-loader classHeight="tw-h-5/6" class="tw-block tw-h-full"/>
}

<ng-template #loaderPdf><app-loader classHeight="tw-h-5/6" class="tw-block tw-h-full"/></ng-template>
