@if (dossiersWallet$ | async; as dossiersWallet) {
  <button type="button" title="Affichage en tableau" (click)="updateView(displayMode.Table)"
          [class]="{'tw-btn-primary-info': dossiersWallet.view === displayMode.Table, 'tw-btn-tertiary-default': dossiersWallet.view !== displayMode.Table}">
    <fa-icon [icon]="['fal', 'align-justify']"/>
  </button>

  @if (options.showTablePhoto) {
    <button type="button" title="Affichage en tableau (photo plus grande)"
            [class]="{'tw-btn-primary-info': dossiersWallet.view === displayMode.TablePhoto, 'tw-btn-tertiary-default': dossiersWallet.view !== displayMode.TablePhoto}"
            (click)="updateView(displayMode.TablePhoto)"><fa-icon [icon]="['fal', 'th-list']"/></button>
  }

  <button type="button" title="Affichage en vignette" (click)="updateView(displayMode.Collection)"
          [class]="{'tw-btn-primary-info': dossiersWallet.view === displayMode.Collection, 'tw-btn-tertiary-default': dossiersWallet.view !== displayMode.Collection}">
    <fa-icon [icon]="['fal', 'th-large']"/>
  </button>
} @else {
  <app-loader [classHeight]="'tw-h-10'" class="tw-block"/>
}
