export default `
Pour rendre disponible votre
<a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/locations/' + $ctrl.location.id + '/edit/general')">
  bien en location
</a>, il
est nécessaire de saisir :
<div class="row">
  <div class="col-sm-offset-1 col-sm-11">
    <div ng-hide="$ctrl.location.bien.option.commune">{{" - la commune" | translate}}</div>

    <div ng-hide="$ctrl.location.bien.superficie">{{" - la superficie" | translate}}</div>

    <div ng-hide="$ctrl.location.loyer">{{" - le loyer" | translate}}</div>

    <div ng-hide="$ctrl.location.bien.titre">{{" - le titre de l'annonce" | translate}}</div>

    <div ng-hide="$ctrl.location.bien.descriptif">{{" - le descriptif de l'annonce" | translate}}</div>
  </div>
</div>
`;
