import {IHttpProvider, IModule, IQProvider, IQService} from "angular";
import environment from "@env/environment";
import angularJS from '@shared/angularJS/global.ng';
import getSoqrate from "@legacy/app/soqrate/module";
import getClient from "@legacy/app/client/module";
import getAuth from "@legacy/app/auth/module";
import getEskimmoRun from "@legacy/app/eskimmo/run";
import getEskBrowserTools from "@legacy/app/eskimmo/services/browser-tools";
import getContacts from "@legacy/app/contacts/module";
import getManagers from "@legacy/app/managers/module";
import getEskFooter from "@legacy/app/eskimmo/components/footer";
import getEskTemplates from "@legacy/app/eskimmo/templates";
import getBiens from "@legacy/app/biens/module";
import getVentes from "@legacy/app/ventes/module";
import getLocations from "@legacy/app/locations/module";
import getDemandeurs from "@legacy/app/demandeurs/module";
import getEstimations from "@legacy/app/estimations/module";
import getEskTaskRunner from '@legacy/app/eskimmo/services/task-runner';
import getEskDashboardCtrl from '@legacy/app/eskimmo/controller_ponents/dashboard';
import getEskUtils from '@legacy/app/eskimmo/services/utils';
import getEskBox from '@legacy/app/eskimmo/services/box';
import getEskDashboard from '@legacy/app/eskimmo/services/dashboard';
import getEskModalCalculatorCtrl from '@legacy/app/eskimmo/controllers/modals/calculator';
import getEskSelectDictionary from '@legacy/app/eskimmo/components/select/dictionary';
import getEskDocumentsBlankCtrl from '@legacy/app/eskimmo/controller_ponents/documents/blank';
import getEskSelectLocalisation from '@legacy/app/eskimmo/components/select/localisation';
import getEskSearchKeywords from '@legacy/app/eskimmo/components/search/keywords';
import getEskPagination from '@legacy/app/eskimmo/components/pagination';
import getEskModalEmailCtrl from '@legacy/app/eskimmo/controllers/modals/email';
import getEskSelectRecipient from '@legacy/app/eskimmo/components/select/recipient';
import getEskActionsDossiers from '@legacy/app/eskimmo/components/actions/dossiers';
import getEskActionsAttachments from '@legacy/app/eskimmo/components/actions/attachments';
import getEskSelectParticipant from '@legacy/app/eskimmo/components/select/participant';
import getEskMyAccountCtrl from '@legacy/app/eskimmo/controller_ponents/my-account';
import getEskUploadFilesInfo from '@legacy/app/eskimmo/components/upload-files-info';
import getEskConfigurationPasserellesCtrl from '@legacy/app/eskimmo/controller_ponents/configuration/passerelles';
import getEskConfigurationDashboardCtrl from '@legacy/app/eskimmo/controller_ponents/configuration/dashboard';
import getEskPasserelleContrat from '@legacy/app/eskimmo/components/passerelle.contrat';
import getEskConfigurationFacebook from '@legacy/app/eskimmo/components/configuration/facebook';
import getEskModalConfigurationFacebookCtrl from '@legacy/app/eskimmo/controllers/modals/configuration.facebook';
import getEskModalImpersonationCtrl from '@legacy/app/eskimmo/controllers/modals/impersonation';
import getEskSelectUser from '@legacy/app/eskimmo/components/select/user';
import getEskSelectCommune from '@legacy/app/eskimmo/components/select/commune';
import getEskSelectVoie from '@legacy/app/eskimmo/components/select/voie';
import getEskSelectQuartier from '@legacy/app/eskimmo/components/select/quartier';
import getEskButtonSort from '@legacy/app/eskimmo/components/button/sort';
import getEskBoxActivity from '@legacy/app/eskimmo/components/box/activity';
import getEskBoxMeteo from '@legacy/app/eskimmo/components/box/meteo';
import getEskBoxShortcuts from '@legacy/app/eskimmo/components/box/shortcuts';
import getEskMeteoWidget from '@legacy/app/eskimmo/components/meteo-widget';
import getEskSelect from '@legacy/app/eskimmo/components/select';
import getEskSelectSort from '@legacy/app/eskimmo/components/select/sort';
import getEskSelectDate from '@legacy/app/eskimmo/components/select/date';
import getEskCarteQuartiers from '@legacy/app/eskimmo/directives/carte-quartiers';
import getEskSearchCriteria from '@legacy/app/eskimmo/components/search/criteria';
import getEskSearchBarre from '@legacy/app/eskimmo/components/search/barre';
import getEskButtonPrint from '@legacy/app/eskimmo/components/button/print';
import getEskButtonSave from '@legacy/app/eskimmo/components/button/save';
import getEskModalEditPhotoCtrl from '@legacy/app/eskimmo/controllers/modals/edit.photo';
import getEskCropper from '@legacy/app/eskimmo/directives/cropper';
import getEskContact from '@legacy/app/eskimmo/components/contact';
import getEskSelectTemplate from '@legacy/app/eskimmo/components/select/template';
import getEskSelectTemplateThumbnails from '@legacy/app/eskimmo/components/select/template-thumbnails';
import getEskTemplateByTags from '@legacy/app/eskimmo/filters/template-by-tags';
import getEskDossierSuiviEmailCtrl from '@legacy/app/eskimmo/controller_ponents/dossier/suivi/email';
import getEskDossierSuiviReportCtrl from '@legacy/app/eskimmo/controller_ponents/dossier/suivi/report';
import getEskModalReportCtrl from '@legacy/app/eskimmo/controllers/modals/report';
import getEskSelectDossier from '@legacy/app/eskimmo/components/select/dossier';
import getEskSelectEtude from '@legacy/app/eskimmo/components/select/etude';
import getEskDocumentsAdvertisementCtrl from '@legacy/app/eskimmo/controller_ponents/documents/advertisement';
import getEskModalChangePasswordCtrl from '@legacy/app/eskimmo/controllers/modals/change.password';
import getEskBrowserCtrl from '@legacy/app/eskimmo/controller_ponents/browser';
import getEskNegociationRevenusCtrl from '@legacy/app/eskimmo/controller_ponents/negociation-revenus';
import getEskBoxNegociationRevenus from '@legacy/app/eskimmo/components/box/negociation-revenus';
import getEskChartNegociationRevenus from '@legacy/app/eskimmo/components/chart.negociation-revenus';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {Injector} from '@angular/core';
import getEskModalProcedureCtrl from '@legacy/app/eskimmo/controllers/modals/procedure';
import {NgAuthenticationServiceProvider} from '@legacy/app/auth/auth';
import {downgradeInjectable} from '@angular/upgrade/static';
import {$locationShim} from '@angular/common/upgrade';
import getEskDossierSuivi from '@legacy/app/eskimmo/components/dossier/suivi';
import * as angular from 'angular';
import {HttpInterceptor} from '@core/http/http-interceptor.service';
import {HttpRejectionNg} from '@shared/angularJS/http.rejection.ng';
import {NgClientConfigProvider} from '@legacy/app/client/client';
import IInjectorService = angular.auto.IInjectorService;
import IProvideService = angular.auto.IProvideService;
import {ErrorHandlerService} from '@core/error/error.handler.service';
import getEskDossierContactsGroupEdit from '@legacy/app/eskimmo/components/dossier/contacts-group.edit';
import ABOUT from '@env/about';
import {LOCALE_FR_FR} from '@shared/constants';
import getEskTaskRunnerComp from '@legacy/app/eskimmo/components/task-runner';

export default function getEskimmo(injector: Injector): IModule {
    return (function (angular) {
        'use strict';

        const baseDeps = [
            'ngMessages',
            'ngSanitize',
            'ngCookies',
            'pascalprecht.translate',
            'uiGmapgoogle-maps',
            'ui.bootstrap',
            'ui.select',
            'ui.sortable',
            'SvgPanZoom',
            getAuth().name,
            getBiens().name,
            getClient().name,
            getContacts().name,
            getDemandeurs().name,
            getEstimations().name,
            getLocations().name,
            getManagers().name,
            getSoqrate().name,
            getVentes().name
        ];

        const eskimmoModule = angular.module('eskimmo', baseDeps)
            // Récupération de l'injecteur AngularJS pour le saisir dans le service NG correspondant
            .run(['$injector', ($injector: IInjectorService) => injector.get(NgInjectorService).injector = $injector]);

        // Anciennement config.js
        eskimmoModule
            .factory('EskInterceptorsHTTP', ['$q', 'Ng2HttpInterceptor', EskInterceptorsHTTP])
            .config(['$provide', customExceptionHandler])
            .config(['$qProvider', promise])
            .config(['uiGmapGoogleMapApiProvider', googleMaps])
            .config(['AuthenticationServiceProvider', authentication])
            .config(['ClientConfigProvider', client])
            .config(['$translateProvider', translate])
            .config(['$httpProvider', http])
            .config(['SoqModalesManagerProvider', modales]);

        // Routing
        // Nécessaire pour la compatibilité du router d'Angular dans AngularJS
        eskimmoModule.factory('$location', downgradeInjectable($locationShim));

        // Config / Run
        getEskTemplates(eskimmoModule);
        getEskimmoRun(eskimmoModule);

        // Services
        getEskBox(eskimmoModule);
        getEskBrowserTools(eskimmoModule);
        getEskDashboard(eskimmoModule);
        getEskTaskRunner(eskimmoModule);
        getEskUtils(eskimmoModule);

        // Filters
        getEskTemplateByTags(eskimmoModule);

        // Directives
        getEskCarteQuartiers(eskimmoModule);
        getEskCropper(eskimmoModule);

        // Components
        getEskActionsAttachments(eskimmoModule);
        getEskActionsDossiers(eskimmoModule);
        getEskBoxActivity(eskimmoModule);
        getEskBoxMeteo(eskimmoModule);
        getEskBoxNegociationRevenus(eskimmoModule);
        getEskBoxShortcuts(eskimmoModule);
        getEskButtonPrint(eskimmoModule);
        getEskButtonSave(eskimmoModule);
        getEskButtonSort(eskimmoModule);
        getEskChartNegociationRevenus(eskimmoModule);
        getEskConfigurationFacebook(eskimmoModule);
        getEskContact(eskimmoModule);
        getEskDossierContactsGroupEdit(eskimmoModule);
        getEskDossierSuivi(eskimmoModule);
        getEskFooter(eskimmoModule);
        getEskMeteoWidget(eskimmoModule);
        getEskPagination(eskimmoModule);
        getEskPasserelleContrat(eskimmoModule);
        getEskSearchBarre(eskimmoModule);
        getEskSearchCriteria(eskimmoModule);
        getEskSearchKeywords(eskimmoModule);
        getEskSelect(eskimmoModule);
        getEskSelectCommune(eskimmoModule);
        getEskSelectDate(eskimmoModule);
        getEskSelectDictionary(eskimmoModule);
        getEskSelectDossier(eskimmoModule);
        getEskSelectEtude(eskimmoModule);
        getEskSelectLocalisation(eskimmoModule);
        getEskSelectParticipant(eskimmoModule);
        getEskSelectQuartier(eskimmoModule);
        getEskSelectRecipient(eskimmoModule);
        getEskSelectSort(eskimmoModule);
        getEskSelectTemplate(eskimmoModule);
        getEskSelectTemplateThumbnails(eskimmoModule);
        getEskSelectUser(eskimmoModule);
        getEskSelectVoie(eskimmoModule);
        getEskTaskRunnerComp(eskimmoModule);
        getEskUploadFilesInfo(eskimmoModule);

        // Controllers
        getEskBrowserCtrl(eskimmoModule);
        getEskConfigurationDashboardCtrl(eskimmoModule);
        getEskConfigurationPasserellesCtrl(eskimmoModule);
        getEskDashboardCtrl(eskimmoModule);
        getEskDocumentsAdvertisementCtrl(eskimmoModule);
        getEskDocumentsBlankCtrl(eskimmoModule);
        getEskDossierSuiviEmailCtrl(eskimmoModule);
        getEskDossierSuiviReportCtrl(eskimmoModule);
        getEskModalCalculatorCtrl(eskimmoModule);
        getEskModalChangePasswordCtrl(eskimmoModule);
        getEskModalConfigurationFacebookCtrl(eskimmoModule);
        getEskModalEditPhotoCtrl(eskimmoModule);
        getEskModalEmailCtrl(eskimmoModule);
        getEskModalImpersonationCtrl(eskimmoModule);
        getEskModalProcedureCtrl(eskimmoModule);
        getEskModalReportCtrl(eskimmoModule);
        getEskMyAccountCtrl(eskimmoModule);
        getEskNegociationRevenusCtrl(eskimmoModule);

        return eskimmoModule;

        /**
         * Custom $exceptionHandler
         *
         * @param $provide
         */
        function customExceptionHandler($provide: IProvideService) {
            $provide.decorator('$exceptionHandler', ['Ng2ErrorHandlerService', (ng2ErrorHandlerService: ErrorHandlerService) => {
                return (exception: unknown) => ng2ErrorHandlerService.catchError(exception);
            }]);
        }

        /**
         * Promise configuration
         *
         * @param $qProvider
         */
        function promise($qProvider: IQProvider) {
            $qProvider.errorOnUnhandledRejections(false);
        }

        /**
         * GoogleMaps configuration
         *
         * @param uiGmapGoogleMapApiProvider
         */
        function googleMaps(uiGmapGoogleMapApiProvider: { configure(init: Record<string, string>): void }) {
            uiGmapGoogleMapApiProvider.configure({
                key: 'AIzaSyAG5kFMCXiPFAiPsssq55q-5yPRrIuEpn8',
                language: LOCALE_FR_FR,
            });
        }

        /**
         * Authentication provider
         *
         * @param AuthenticationServiceProvider
         */
        function authentication(AuthenticationServiceProvider: NgAuthenticationServiceProvider) {
            AuthenticationServiceProvider.setBaseUrl(environment.api.baseUrl + environment.api.pathUrl);
            AuthenticationServiceProvider.setClientId(environment.api.client.id);
            AuthenticationServiceProvider.setClientSecret(environment.api.client.secret);
        }

        /**
         * Client configuration
         *
         * @param ClientConfigProvider
         */
        function client(ClientConfigProvider: NgClientConfigProvider) {
            ClientConfigProvider.setBaseUrl(environment.api.baseUrl + environment.api.pathUrl);
            ClientConfigProvider.setVersionAPI(ABOUT.versionAPI);
        }

        /**
         * Translate configuration
         *
         * @link https://angular-translate.github.io/docs/#/guide/02_getting-started
         * @param $translateProvider
         */
        function translate($translateProvider: angular.translate.ITranslateProvider) {
            // Chargement du fichier i18n à chaque initialisation de l'application
            $translateProvider.useStaticFilesLoader({
                prefix: 'app/legacy/i18n/',
                // @todo Utilisation de DateFormat ?
                suffix: '.json?v=' + (new Date()).getTime(),
            });

            // Langage par défaut
            $translateProvider.preferredLanguage('fr_FR');

            // Options supplémentaires
            $translateProvider.useLocalStorage();
            $translateProvider.useSanitizeValueStrategy('sceParameters');
            $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
            $translateProvider.useMessageFormatInterpolation();
        }

        /**
         * HTTP configuration
         *
         * @link https://docs.angularjs.org/api/ng/service/$http
         * @param $httpProvider
         */
        function http($httpProvider: IHttpProvider) {
            $httpProvider.interceptors.push('EskInterceptorsHTTP');
        }

        /**
         * Interceptors for $httpProvider
         */
        function EskInterceptorsHTTP($q: IQService, ng2HttpInterceptor: HttpInterceptor) {
            return {responseError};

            /**
             *
             * N.B. Do NOT log 304 status code
             *
             * @param rejection
             * @returns {Promise}
             */
            function responseError(rejection: HttpRejectionNg) {
                ng2HttpInterceptor.ngResponseError(rejection);

                return $q.reject(rejection);
            }
        }

        /**
         * Declare modales
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('EskModalCalculator', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/calculator.html',
                controller: 'EskModalCalculatorCtrl',
                size: 'lg',
            });
            soqModalesManagerProvider.addModale('EskModalChangePassword', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/change.password.html',
                controller: 'EskModalChangePasswordCtrl',
            });
            soqModalesManagerProvider.addModale('EskModalConfigurationFacebook', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/configuration.facebook.html',
                controller: 'EskModalConfigurationFacebookCtrl',
                size: 'sm',
                backdrop: 'static',
            });
            soqModalesManagerProvider.addModale('EskModalEditPhoto', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/edit.photo.html',
                controller: 'EskModalEditPhotoCtrl',
                size: 'lg',
            });
            soqModalesManagerProvider.addModale('EskModalEmail', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/email.html',
                controller: 'EskModalEmailCtrl',
                size: 'lg',
                backdrop: 'static',
            });
            soqModalesManagerProvider.addModale('EskModalImpersonation', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/impersonation.html',
                controller: 'EskModalImpersonationCtrl',
                size: 'sm',
            });
            soqModalesManagerProvider.addModale('EskModalProcedure', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/procedure.html',
                controller: 'EskModalProcedureCtrl',
                size: 'lg',
                backdrop: 'static',
                windowTopClass: 'modal-procedure',
            });
            soqModalesManagerProvider.addModale('EskModalReport', {
                templateUrl: 'src/app/legacy/templates/eskimmo/controllers/modals/report.html',
                controller: 'EskModalReportCtrl',
                size: 'lg',
            });
        }
    })(angularJS);
}
