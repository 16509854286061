import {Component, inject} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {Router} from '@angular/router';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    BonvisitesActionsMainDropdownComponent
} from '@features/bonvisites/list/actions/dropdown/bonvisites.actions-main-dropdown.component';
import {DossierService} from '@models/dossiers/dossier/dossier.service';

@Component({selector: 'bonvisites-actions-main', templateUrl: 'bonvisites.actions-main.component.html'})
export class BonvisitesActionsMainComponent implements IDynamicComponent {
    private _callToActionService = inject(CallToActionService);
    private _dossierService = inject(DossierService);
    private _dropdownService = inject(DropdownService);
    private _router = inject(Router);
    private _value!: IDynamicComponent;

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this._dropdownService.open(htmlButtonElement, {component: BonvisitesActionsMainDropdownComponent});
    }

    create(): void {
        const dossier = this._dossierService.getCurrentFromNg(this._router.url);

        this._router.navigateByUrl('/app/' + dossier.dossierType + 's/' + dossier.id.toString() + '/bons-visite/' + Bonvisite.statuts.NEW + '/edit');
    }
}
