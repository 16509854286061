import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {NgEmail, NgEmailRecipient} from '@legacy/app/managers/ressources';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgEmailManager, NgMediaManager} from '@legacy/app/managers/managers';
import {ModalService} from '@shared/modal/modal.service';
import Media from '@models/medias/media/media.model';
import {map, tap} from 'rxjs/operators';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import {EmailFactory} from '@models/emails/email/email.factory';
import {IEmailApi} from '@models/emails/email/email.interfaces';
import {EmailRecipientService} from '@models/emails/email/recipients/recipient/email-recipient.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';

@Injectable({providedIn: 'root'})
export class EmailService {
    private _dossierService = inject(DossierService);
    private _emailFactory = inject(EmailFactory);
    private _emailRecipientService = inject(EmailRecipientService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _toasterService = inject(ToasterService);

    // Supprimer les injections des anciens manager
    private get ngEmailManager(): NgEmailManager {
        return this._ngInjectorService.getService('EmailManager');
    }

    // Supprimer les injections des anciens manager
    private get ngMediaManager(): NgMediaManager {
        return this._ngInjectorService.getService('MediaManager');
    }

    informationRequest$(module: string): Observable<boolean> {
        return this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Être rappelé',
            comments: 'Le module "' + module + '" est un module complémentaire de ' +
                AppNoteoIconTextNotyComponent.label + ' et est activable sur demande.',
            question: 'Souhaitez-vous plus d\'informations quant à l\'activation du module "' + module + '" ?',
            title: 'Demande d\'informations',
            status: ModalService.status.INFO,
        }).pipe(
            switchMap(confirmation => {
                if (!confirmation) {
                    return of(false);
                }

                return this.ngEmailManager.send$(this._emailFactory.create({
                    message: 'Bonjour,<br><br>Je souhaite un complément d\'informations concernant le module "' +
                        module + '" dans ' + AppNoteoIconTextNotyComponent.label + '.<br><br>Cordialement<br><br>' +
                        this.ngEmailManager.getCurrentUserSignature(),
                    recipients: [this._emailRecipientService.createNoteoCommerce()],
                    subject: AppNoteoIconTextNotyComponent.label + ' : Module ' + module,
                } as unknown as IEmailApi)).pipe(
                    tap(_ => this._toasterService.success('Demande d\'informations envoyée', 'Vous serez contacté dans les plus brefs délais concernant l\'activation du module "' + module + '".')),
                    map(_ => true),
                );
            }),
        );
    }

    ngCreate$(recipients: NgEmailRecipient[], subject?: string, message?: string, withSignature = true, attachments: Media[] = [], dossiers: ADossier[] = []): Observable<NgEmail> {
        const emailAttachments = attachments.map(attachment => ({
            media: this.ngMediaManager.createFromNg2(attachment),
            mediaId: attachment.id,
        }));
        const interneDossiers = dossiers.filter(dossier => dossier.interne);
        const emailDossiers$ = interneDossiers.length > 0 ? combineLatest(interneDossiers.map(dossier => this._dossierService.getNgDossier$(dossier).pipe(
            map(ngDossier => ({typeId: ngDossier.id, type: ngDossier._esk.typeDossier, dossier: ngDossier}))
        ))) : of([]);

        return emailDossiers$.pipe(map(emailDossiers => this.ngEmailManager.create({
            attachments: emailAttachments,
            dossiers: emailDossiers,
            message: (message ?? '') + (withSignature ? '<br><br>' + this.ngEmailManager.getCurrentUserSignature() : ''),
            recipients,
            subject,
        }) as NgEmail));
    }
}
