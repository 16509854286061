import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Vente from '@models/ventes/vente/vente.model';

export default class VentePrice {
    private _fraisActe!: number;
    private _honoraires!: number;
    private _pourcentageHonoraires!: number;
    private _priceHNI!: number;
    private _priceNV!: number;
    private _priceTFC!: number;
    private _typeCharges!: DictionaryItem;

    get fraisActe(): number {
        return this._fraisActe;
    }

    set fraisActe(value: number) {
        this._fraisActe = value;
    }

    get honoraires(): number {
        return this._honoraires;
    }

    set honoraires(value: number) {
        this._honoraires = value;
    }

    get pourcentageHonoraires(): number {
        return this._pourcentageHonoraires;
    }

    set pourcentageHonoraires(value: number) {
        this._pourcentageHonoraires = value;
    }

    get priceHNI(): number {
        return this._priceHNI;
    }

    set priceHNI(value: number) {
        this._priceHNI = value;
    }

    get priceNV(): number {
        return this._priceNV;
    }

    set priceNV(value: number) {
        this._priceNV = value;
    }

    get priceTFC(): number {
        return this._priceTFC;
    }

    set priceTFC(value: number) {
        this._priceTFC = value;
    }

    get typeCharges(): DictionaryItem {
        return this._typeCharges;
    }

    set typeCharges(value: DictionaryItem) {
        this._typeCharges = value;
    }

    isTypeChargesNV(): boolean {
        return this.typeCharges.code === Vente.chargesType.NET_VENDEUR;
    }
}
