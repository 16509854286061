import angularJS from '@shared/angularJS/global.ng';
import {IAttributes, IDirectiveFactory, IModule, Injectable, IScope, ITimeoutService} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getSoqRadioChecked(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-radio-checked
         */
        module.directive('soqRadioChecked', directive as Injectable<IDirectiveFactory>);

        /**
         * Directive to check radio input
         *
         * @param $timeout
         * @returns {Object}
         */
        directive.$inject = ['$timeout'];
        function directive($timeout: ITimeoutService) {
            return {restrict: 'A', link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes) {
                $scope.$watch($attrs.soqRadioChecked, (ngModel: {code: string}) => {
                    if (!angular.isDefined(ngModel)) return;

                    $timeout(() => {
                        angular.forEach($element.querySelectorAll('input'), (inputRadioQuery: HTMLInputElement) => {
                            const inputRadio = angular.element(inputRadioQuery);

                            inputRadio[0].checked = ngModel.code === inputRadio.attr('code');
                        });
                    });
                });
            }
        }
    })(angularJS);
}
