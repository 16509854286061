import {inject, Injectable} from '@angular/core';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {Observable, switchMap} from 'rxjs';
import {EtudePasserelleFactory} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {map} from 'rxjs/operators';
import {IEtudePasserelleConfigureApi} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.interfaces';

@Injectable({providedIn: 'root'})
export class EtudePasserelleService {
    private _etudePasserelleFactory = inject(EtudePasserelleFactory);
    private _etudeService = inject(EtudeService);

    authenticate$(etudePasserelle: EtudePasserelle, accessToken: string): Observable<IEtudePasserelleConfigureApi> {
        return this._etudeService.last$.pipe(
            switchMap(currentEtude => this._etudePasserelleFactory.configure$(currentEtude, etudePasserelle, 'authenticate', {accessToken})),
        );
    }

    configure$(etudePasserelle: EtudePasserelle, pageId: number): Observable<void> {
        return this._etudeService.last$.pipe(
            switchMap(currentEtude => this._etudePasserelleFactory.configure$(currentEtude, etudePasserelle, 'configure', {pageId})),
            map(_ => undefined),
        );
    }

    diffuse$(etudePasserelle: EtudePasserelle, bienIds: number[]): Observable<void> {
        return this._etudeService.last$.pipe(
            switchMap(currentEtude => this._etudePasserelleFactory.diffuse$(currentEtude, etudePasserelle, bienIds)),
            map(_ => undefined),
        );
    }

    reset$(etudePasserelle: EtudePasserelle): Observable<void> {
        return this._etudeService.last$.pipe(
            switchMap(currentEtude => this._etudePasserelleFactory.configure$(currentEtude, etudePasserelle, 'reset')),
            map(_ => undefined),
        );
    }

    updateBiensEnvoyees$(etudePasserelle: EtudePasserelle): Observable<void> {
        return this._etudePasserelleFactory.get$(etudePasserelle.passerelle.id.toString()).pipe(
            map(etudePasserelleUpdated => {
                etudePasserelle.nbBiensActifsEnvoyes = etudePasserelleUpdated.nbBiensActifsEnvoyes;
                etudePasserelle.nbLocationsActivesEnvoyees = etudePasserelleUpdated.nbLocationsActivesEnvoyees;
                etudePasserelle.nbVentesActivesEnvoyees = etudePasserelleUpdated.nbVentesActivesEnvoyees;
            }),
        );
    }
}
