import {inject, Injectable} from '@angular/core';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import {IVenteCompromisApi} from '@models/ventes/vente/compromis/vente-compromis.interfaces';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';

@Injectable({providedIn: 'root'})
export class VenteCompromisApiService {
    private _modelApiService = inject(ModelApiService);

    get$(venteUuid: string, uuid: string): Observable<IVenteCompromisApi> {
        return this._modelApiService.get$('/ventes/' + venteUuid + '/compromis/' + uuid);
    }

    getByLink$(link: string): Observable<IVenteCompromisApi> {
        return this._modelApiService.get$(link);
    }

    save$(venteUuid: string, uuid: string, venteCompromisApi: IVenteCompromisApi): Observable<IVenteCompromisApi> {
        if (uuid === VenteCompromis.NEW) {
            return this._modelApiService.post$('/ventes/' + venteUuid + '/compromis', venteCompromisApi);
        }

        return this._modelApiService.put$('/ventes/' + venteUuid + '/compromis/' + uuid, venteCompromisApi)
            .pipe(switchMap(_ => this.get$(venteUuid, uuid)));
    }
}
