import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import EtudeNetwork from '@models/etudes/etude/networks/network/etude-network.model';
import {EtudeNetworksApiService} from '@models/etudes/etude/networks/etude-networks.api.service';
import {map} from 'rxjs/operators';
import {IEtudeNetworkApi} from '@models/etudes/etude/networks/network/etude-network.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeNetworkFactory {
    private _etudeNetworksApiService = inject(EtudeNetworksApiService);

    create(etudeNetworkApi: IEtudeNetworkApi): EtudeNetwork {
        const etudeNetwork = new EtudeNetwork(etudeNetworkApi.uuid, etudeNetworkApi.networkId);

        etudeNetwork.name = etudeNetworkApi.name;
        etudeNetwork.nbBiensActifsEnvoyes = etudeNetworkApi.nbBiensActifsEnvoyes;
        etudeNetwork.numberDaysBeforeAutoDiffusion = etudeNetworkApi.numberDaysBeforeAutoDiffusion;
        etudeNetwork.urlLogo = etudeNetworkApi.urlLogo;

        return etudeNetwork;
    }

    get$(etudeNetworkUuid: string): Observable<EtudeNetwork> {
        return this._etudeNetworksApiService.get$(etudeNetworkUuid).pipe(map(etudeNetworkAPi => this.create(etudeNetworkAPi)));
    }
}
