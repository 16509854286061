import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewAgencementInterieurService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _isAlarmeLocationVisible!: boolean;
    private _isAlarmePleineProprieteVisible!: boolean;
    private _isAscenseurVisible!: boolean;
    private _isCaveVisible!: boolean;
    private _isChemineeVisible!: boolean;
    private _isComblesVisible!: boolean;
    private _isCommentsAgencementInterieurVisible!: boolean;
    private _isConnexionsInternetVisible!: boolean;
    private _isCuisineEquipeeVisible!: boolean;
    private _isDernierEtageVisible!: boolean;
    private _isDetecteurFumeeVisible!: boolean;
    private _isGrenierVisible!: boolean;
    private _isHauteurSousPlafondVisible!: boolean;
    private _isNombreBainsVisible!: boolean;
    private _isNombreChambresVisible!: boolean;
    private _isNombreDouchesVisible!: boolean;
    private _isNombreEtagesVisible!: boolean;
    private _isNombreLogementsVisible!: boolean;
    private _isNombrePiecesVisible!: boolean;
    private _isNombreSallesBainsVisible!: boolean;
    private _isNombreSallesEauVisible!: boolean;
    private _isNombreWcVisible!: boolean;
    private _isNumeroEtageVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isSousSolVisible!: boolean;
    private _isSurfaceSejourVisible!: boolean;
    private _isWcBroyeurVisible!: boolean;

    get isAlarmeLocationVisible(): boolean {
        return this._isAlarmeLocationVisible;
    }

    get isAlarmePleineProprieteVisible(): boolean {
        return this._isAlarmePleineProprieteVisible;
    }

    get isAscenseurVisible(): boolean {
        return this._isAscenseurVisible;
    }

    get isCaveVisible(): boolean {
        return this._isCaveVisible;
    }

    get isChemineeVisible(): boolean {
        return this._isChemineeVisible;
    }

    get isComblesVisible(): boolean {
        return this._isComblesVisible;
    }

    get isCommentsAgencementInterieurVisible(): boolean {
        return this._isCommentsAgencementInterieurVisible;
    }

    get isConnexionsInternetVisible(): boolean {
        return this._isConnexionsInternetVisible;
    }

    get isCuisineEquipeeVisible(): boolean {
        return this._isCuisineEquipeeVisible;
    }

    get isDernierEtageVisible(): boolean {
        return this._isDernierEtageVisible;
    }

    get isDetecteurFumeeVisible(): boolean {
        return this._isDetecteurFumeeVisible;
    }

    get isGrenierVisible(): boolean {
        return this._isGrenierVisible;
    }

    get isHauteurSousPlafondVisible(): boolean {
        return this._isHauteurSousPlafondVisible;
    }

    get isNombreBainsVisible(): boolean {
        return this._isNombreBainsVisible;
    }

    get isNombreChambresVisible(): boolean {
        return this._isNombreChambresVisible;
    }

    get isNombreDouchesVisible(): boolean {
        return this._isNombreDouchesVisible;
    }

    get isNombreEtagesVisible(): boolean {
        return this._isNombreEtagesVisible;
    }

    get isNombreLogementsVisible(): boolean {
        return this._isNombreLogementsVisible;
    }

    get isNombrePiecesVisible(): boolean {
        return this._isNombrePiecesVisible;
    }

    get isNombreSallesBainsVisible(): boolean {
        return this._isNombreSallesBainsVisible;
    }

    get isNombreSallesEauVisible(): boolean {
        return this._isNombreSallesEauVisible;
    }

    get isNombreWcVisible(): boolean {
        return this._isNombreWcVisible;
    }

    get isNumeroEtageVisible(): boolean {
        return this._isNumeroEtageVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isSousSolVisible(): boolean {
        return this._isSousSolVisible;
    }

    get isSurfaceSejourVisible(): boolean {
        return this._isSurfaceSejourVisible;
    }

    get isWcBroyeurVisible(): boolean {
        return this._isWcBroyeurVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isAlarmeLocationVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.alarmeLocation);
        this._isAlarmePleineProprieteVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.alarmePleinePropriete);
        this._isAscenseurVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.ascenseur)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.ascenseur);
        this._isCaveVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cave);
        this._isChemineeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cheminee);
        this._isComblesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.combles);
        this._isCommentsAgencementInterieurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsAgencementInterieur);
        this._isConnexionsInternetVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.connexionsInternet);
        this._isCuisineEquipeeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cuisineEquipee);
        this._isDernierEtageVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.dernierEtage);
        this._isDetecteurFumeeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.detecteurFumee);
        this._isGrenierVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.grenier);
        this._isHauteurSousPlafondVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.hauteurSousPlafond);
        this._isNombreBainsVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreBains);
        this._isNombreChambresVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.nombreChambres)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreChambres);
        this._isNombreDouchesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreDouches);
        this._isNombreEtagesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreEtages);
        this._isNombreLogementsVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.nombreLogements)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreLogements);
        this._isNombrePiecesVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.nombrePieces)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombrePieces);
        this._isNombreSallesBainsVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreSallesBains);
        this._isNombreSallesEauVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreSallesEau);
        this._isNombreWcVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreWc);
        this._isNumeroEtageVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.numeroEtage);
        this._isSousSolVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.sousSol);
        this._isSurfaceSejourVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfaceSejour);
        this._isWcBroyeurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.wcBroyeur);

        this._isPartVisible =  this.isAlarmeLocationVisible ||  this.isAlarmePleineProprieteVisible || this.isAscenseurVisible
            || this.isCaveVisible || this.isChemineeVisible
            || this.isComblesVisible || this.isCommentsAgencementInterieurVisible || this.isConnexionsInternetVisible
            || this.isCuisineEquipeeVisible || this.isDernierEtageVisible || this.isDetecteurFumeeVisible || this.isGrenierVisible
            || this.isHauteurSousPlafondVisible || this.isNombreBainsVisible || this.isNombreChambresVisible
            || this.isNombreDouchesVisible || this.isNombreEtagesVisible || this.isNombreLogementsVisible
            || this.isNombrePiecesVisible || this.isNombreSallesBainsVisible || this.isNombreSallesEauVisible
            || this.isNombreWcVisible || this.isNumeroEtageVisible || this.isSousSolVisible
            || this.isSurfaceSejourVisible || this.isWcBroyeurVisible;
    }
}
