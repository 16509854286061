@if (user$ | async; as user) {
  <div class="tw-bg-gray-50 tw-px-4 tw-py-6 sm:tw-px-6">
    <h2 class="tw-mr-8 tw-text-base tw-font-semibold tw-text-gray-900">{{ user.convivialName }}</h2>
  </div>

  <div class="tw-p-8 tw-divide-y tw-divide-gray-200">
    <div class="tw-py-6 tw-space-y-6">
      <div class="tw-px-4 sm:tw-px-6 tw-space-y-4 tw-text-center">
        <img class="tw-mx-auto tw-rounded-full" [ngSrc]="user.linkPhotoThumbnail" height="160" width="160"
             alt="{{user.convivialName}}">

        <div class="tw-px-2 tw-py-1 tw-text-xs tw-font-medium">{{ user.convivialProfils }}</div>

        <div class="tw-space-y-2">
          <app-text-phones [phones]="[user.getPhoneFixe(), user.getPhoneMobile()]" class="tw-block"/>

          @if (user.emailAddress) {
            <a href="mailto:{{user.emailAddress}}" class="tw-block tw-whitespace-nowrap">{{ user.emailAddress }}</a>
          }
        </div>
      </div>

      <div class="tw-space-x-4 tw-text-center">
        @if (user.getPhoneMobileFirst()) {
          <a href="tel:{{user.getPhoneMobileFirst()}}" class="tw-btn-secondary-default">
            <fa-icon [icon]="['fal', 'phone']"/> <span class="tw-hidden sm:tw-inline-block">Appeler</span>
          </a>
        }

        @if (user.emailAddress) {
          <button type="button" class="tw-btn-secondary-default" (click)="sendMail(user)">
            <fa-icon [icon]="['fal', 'envelope']"/> <span class="tw-hidden sm:tw-inline-block">Écrire</span>
          </button>
        }
      </div>
    </div>

    @if (user.linkVentes) {
      @if (cVentes$ | async; as cVentes) {
        @if (cVentes.total > 0) {
          <div class="tw-py-6 tw-space-y-6">
            <app-dossiers-biens-stacked-images [cDossierBiens]="cVentes" class="tw-h-12 tw-block"
                                               [options]="{labelSingular: 'bien en vente', labelPlural: 'biens en vente'}"/>

            <div class="tw-text-center">
              <a class="tw-btn-secondary-default" [routerLink]="'/app/ventes/search'"
                 [state]="{extraParams: {userId: user.id}}">
                <fa-icon [icon]="['fal', 'house']"/>
                <span class="tw-hidden sm:tw-inline-block">Voir ses biens à vendre</span>
              </a>
            </div>
          </div>
        }
      } @else {
        <app-loader [classHeight]="'tw-h-28'" class="tw-block tw-py-6"/>
      }
    }
    @if (etude$ | async; as etude) {
      <div class="tw-py-6 tw-space-y-6">
        <div class="tw-space-y-2">
          <div class="tw-text-sm tw-font-medium tw-text-gray-900">{{ etude.raisonSociale }}</div>

          @if (sitePrincipal$ | async; as sitePrincipal) {
            <div>
              {{ sitePrincipal.convivialAddress }}
              <app-commune-convivial-name [linkCommune]="sitePrincipal.linkCommune"/>
            </div>
          } @else {
            <app-loader class="tw-block tw-w-40"/>
          }
        </div>

        <div class="tw-text-center">
          <a [routerLink]="'/app/annuaires/etudes/' + etude.id.toString()" class="tw-btn-secondary-default">
            <fa-icon [icon]="['fal', 'building-columns']"/>
            <span class="tw-hidden sm:tw-inline-block">Voir son étude</span>
          </a>
        </div>
      </div>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-py-6"/>
    }
  </div>
} @else {
  <div class="tw-px-4 tw-py-6 sm:tw-px-6">
    <div class="tw-mb-10 tw-pr-12"><app-loader [classHeight]="'tw-h-6'"/></div>

    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
  </div>
}
