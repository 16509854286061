import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallToActionModule} from '@shared/call-to-action/call-to-action.module';
import {CollectionSelectionComponent} from '@shared/collection/selection/collection-selection.component';
import {CollectionSelectionAllComponent} from '@shared/collection/selection/all/collection-selection-all.component';
import {CollectionSelectionBarComponent} from '@shared/collection/selection/bar/collection-selection-bar.component';
import {FaModule} from '@shared/fontawesome/fa.module';
import {FormsModule} from '@angular/forms';
import {AppCollectionSelectionDirective} from '@shared/collection/selection/collection-selection.directive';

@NgModule({
    declarations: [
        AppCollectionSelectionDirective,
        CollectionSelectionAllComponent,
        CollectionSelectionBarComponent,
        CollectionSelectionComponent,
    ],
    exports: [AppCollectionSelectionDirective, CollectionSelectionBarComponent, CollectionSelectionAllComponent],
    imports: [CallToActionModule, CommonModule, FaModule, FormsModule],
})
export class CollectionSelectionModule {
}
