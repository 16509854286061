import {inject, Injectable} from '@angular/core';
import TemplateHeader from '@models/templates/headers/header/template-header.model';
import {ITemplateHeaderApi} from '@models/templates/headers/header/template-header.interfaces';
import {Observable, of} from 'rxjs';
import EtudeSettingsDocumentsHeader
    from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.model';
import {TemplateHeadersApiService} from '@models/templates/headers/template-headers.api.service';
import {map} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class TemplateHeaderFactory {
    private _templateHeadersApiService = inject(TemplateHeadersApiService);

    create(templateHeaderApi: ITemplateHeaderApi): TemplateHeader {
        const templateHeader = this.createVirgin();

        templateHeader.code = templateHeaderApi.code;
        templateHeader.height = templateHeaderApi.height;
        templateHeader.label = templateHeaderApi.title;
        templateHeader.template = templateHeaderApi.template;
        templateHeader.thumbnailUrl = templateHeaderApi.thumbnailUrl;

        return templateHeader;
    }

    createFromEtudeSettingsDocumentsHeader(etudeSettingsDocumentsHeader: EtudeSettingsDocumentsHeader, templateHeaderLikeESDH?: TemplateHeader): TemplateHeader {
        const templateHeader = this.createVirgin();

        if (etudeSettingsDocumentsHeader.mustUseCustomData() || !templateHeaderLikeESDH) {
            templateHeader.height = etudeSettingsDocumentsHeader.customHeight;
            templateHeader.template = etudeSettingsDocumentsHeader.customTemplate;
        } else {
            templateHeader.height = templateHeaderLikeESDH.height;
            templateHeader.template = templateHeaderLikeESDH.template;
        }

        return templateHeader;
    }

    createVirgin(): TemplateHeader {
        return new TemplateHeader();
    }

    getCustom(): TemplateHeader {
        const templateHeader = this.createVirgin();

        templateHeader.code = TemplateHeader.codes.CUSTOM;
        templateHeader.height = 10;
        templateHeader.label = 'Personnalisé';
        templateHeader.thumbnailUrl = TemplateHeadersApiService.THUMBNAIL_DEFAULT;

        return templateHeader;
    }

    getPreviewLink$(height: number, template: string, httpOptions?: HttpOptions): Observable<string> {
        if (!template) {
            return of('');
        }

        return this._templateHeadersApiService.getPreview$({height, template} as ITemplateHeaderApi, httpOptions).pipe(
            map(templateHeaderApi => templateHeaderApi._links.preview.href),
        );
    }
}
