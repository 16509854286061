import {DossierBiensSourcesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {SearchCriteriaSourceDefinition} from '@models/search/search.interfaces';

class SearchCriteriaSource {
    static readonly ALL: SearchCriteriaSourceDefinition = 'all';
    static readonly CITYSCAN: SearchCriteriaSourceDefinition = 'cityscan';
    static readonly ETUDE: SearchCriteriaSourceDefinition = 'etude';
    static readonly NETWORK: SearchCriteriaSourceDefinition = DossierBiensSourcesConst.NETWORK as SearchCriteriaSourceDefinition;
    static readonly REFERENCE: SearchCriteriaSourceDefinition = 'reference';
}

export const SearchCriteriaSourceConst = SearchCriteriaSource;
