import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    IEtudeSettingsDocumentsFooterApi
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.interfaces';
import {map} from 'rxjs/operators';
import environment from '@env/environment';

@Injectable({providedIn: 'root'})
export class EtudeSettingsDocumentsFooterApiService {
    static readonly CSS_FILE = environment.api.baseUrl + '/build/documents/css/footer.css';
    private _modelApiService = inject(ModelApiService);

    get$(etudeUuid: string): Observable<IEtudeSettingsDocumentsFooterApi> {
        return this._modelApiService.get$('/etudes/' + etudeUuid + '/settings/documents/footer');
    }

    save$(etudeUuid: string, etudeSettingsDocumentsFooterApi: IEtudeSettingsDocumentsFooterApi): Observable<IEtudeSettingsDocumentsFooterApi> {
        return this._modelApiService.put$('/etudes/' + etudeUuid + '/settings/documents/footer', etudeSettingsDocumentsFooterApi)
            .pipe(map(_ => etudeSettingsDocumentsFooterApi));
    }
}
