<div class="tw-space-y-1">
  @if (!site.isPrincipal()) {
    <div class="tw-flex tw-items-center tw-space-x-2">
      <app-site-convivial-name [site]="site" class="tw-text-sm tw-text-gray-900"/>
    </div>
  }

  <div>{{ site.convivialAddress }} <app-commune-convivial-name [linkCommune]="site.linkCommune"/></div>

  <div>
    @if (site.getPhoneNumber()) {
      <app-text-phone [phone]="site.getPhoneNumber()"/>
    }

    @if (site.getPhoneNumber() && site.emailAddress) {
      <span> - </span>
    }

    @if (site.emailAddress) {
      <a href="mailto:{{site.emailAddress}}">{{ site.emailAddress }}</a>
    }
  </div>
</div>

<div class="tw-@container tw-flex tw-gap-4 tw-mt-4 tw-mx-auto tw-group"
     [ngClass]="'tw-' + (+!!site.getPhoneNumber() + +!!site.emailAddress + +!!site.siteInternet + +(site.isPrincipal() && !!etude.linkVentes)).toString() + '-children'">
  @if (site.getPhoneNumber()) {
    <div>
      <a href="tel:{{site.getPhoneNumber()}}" class="tw-btn-secondary-default">
        <fa-icon [icon]="['fal', 'phone']"/>

        <span class="tw-hidden group-[:not(.tw-4-children)]:@[22rem]:tw-inline-block group-[.tw-4-children]:@[34rem]:tw-inline-block">
        Appeler
      </span>
      </a>
    </div>
  }

  @if (site.emailAddress) {
    <div>
      <button type="button" class="tw-btn-secondary-default" (click)="sendMail()">
        <fa-icon [icon]="['fal', 'envelope']"/>

        <span class="tw-hidden group-[:not(.tw-4-children)]:@[22rem]:tw-inline-block group-[.tw-4-children]:@[34rem]:tw-inline-block">
        Écrire
      </span>
      </button>
    </div>
  }

  @if (site.siteInternet) {
    <div>
      <a href="{{site.siteInternet}}" target="_blank" class="tw-btn-secondary-default">
        <fa-icon [icon]="['fal', 'laptop']"/>

        <span class="tw-hidden group-[:not(.tw-4-children)]:@[22rem]:tw-inline-block group-[.tw-4-children]:@[34rem]:tw-inline-block">
        Site Internet
      </span>
      </a>
    </div>
  }

  @if (site.isPrincipal() && etude.linkVentes) {
    <div>
      <a class="tw-btn-secondary-default" [routerLink]="'/app/ventes/search'"
         [state]="{extraParams: {etudeId: etude.id}}">
        <fa-icon [icon]="['fal', 'house']"/>

        <span class="tw-hidden group-[:not(.tw-4-children)]:@[22rem]:tw-inline-block group-[.tw-4-children]:@[34rem]:tw-inline-block">
          Biens à vendre
        </span>
      </a>
    </div>
  }
</div>
