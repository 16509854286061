import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getLocationsTemplates from '@legacy/app/locations/templates';
import getLocationsList from '@legacy/app/locations/components/list';
import getLocationLabelRent from '@legacy/app/locations/components/item/label/rent';
import getLocationLabelStatut from '@legacy/app/locations/components/item/label/statut';
import getLocationLifetimeCtrl from '@legacy/app/locations/controllers_ponents/item/lifetime';
import getLocationModalArchiveCtrl from '@legacy/app/locations/controllers/modals/item/archive';
import getLocationDiffusionCtrl from '@legacy/app/locations/controllers_ponents/item/diffusion';
import getLocationMandatsCtrl from '@legacy/app/locations/controllers_ponents/item/mandats';
import getLocationGestionCtrl from '@legacy/app/locations/controllers_ponents/item/gestion';
import getLocationModalEditEtatsLieuxCtrl from '@legacy/app/locations/controllers/modals/item/edit.etats-lieux';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';

export default function getLocations(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.locations', [
                'esk.biens',
                'esk.managers',
                'pascalprecht.translate',
                'soqrate',
                'ui.bootstrap',
            ])
            .config(['SoqModalesManagerProvider', modales]);

        // Config / Run
        getLocationsTemplates(module);

        // Components
        getLocationLabelRent(module);
        getLocationLabelStatut(module);
        getLocationsList(module);

        // Controllers
        getLocationDiffusionCtrl(module);
        getLocationGestionCtrl(module);
        getLocationLifetimeCtrl(module);
        getLocationMandatsCtrl(module);
        getLocationModalArchiveCtrl(module);
        getLocationModalEditEtatsLieuxCtrl(module);

        return module;

        /**
         * Declare modales
         *
         * @param SoqModalesManagerProvider
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('LocationModalArchive', {
                templateUrl: 'src/app/legacy/templates/locations/controllers/modals/item/archive.html',
                controller: 'LocationModalArchiveCtrl',
                backdrop: 'static'
            });
            soqModalesManagerProvider.addModale('LocationModalEditEtatsLieux', {
                templateUrl: 'src/app/legacy/templates/locations/controllers/modals/item/edit.etats-lieux.html',
                controller: 'LocationModalEditEtatsLieuxCtrl',
                size: 'lg'
            });
        }
    })(angularJS);
}
