import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import Vente from '@models/ventes/vente/vente.model';
import {VenteService} from '@models/ventes/vente/vente.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {SearchService} from '@models/search/search.service';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';

@Component({selector: 'layout-search-vente', templateUrl: 'layout-search-vente.component.html'})
export class AppLayoutSearchVenteComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _cVentesFactory = inject(CVentesFactory);
    private _router = inject(Router);
    private _searchService = inject(SearchService);
    private _venteService = inject(VenteService);
    private _cVentes!: CVentes;
    private readonly _onDestroy$ = new Subject<void>();

    get ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get cVentes(): CVentes {
        return this._cVentes;
    }

    get vente$(): Observable<Vente> {
        return this._venteService.current$;
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(
            map(params => +params?.venteId),
            switchMap(venteId => {
                if (venteId > 0) {
                    this._venteService.initCurrent(venteId);

                    return this._searchService.current$.pipe(
                        take(1),
                        map(search => search?.cVentes),
                        tap(cVentes => this._cVentes = cVentes!),
                        map(_ => undefined),
                    );
                }

                return of('/app/ventes/search');
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl('/app/ventes/search');
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    goBack(): void {
        this._router.navigateByUrl('/app/ventes/search');
    }

    wantedIdx(fullIdx: number): void {
        const fullIdxLow = (this.cVentes.page - 1) * this.cVentes.perPage;
        const fullIdxHigh = fullIdxLow + this.cVentes.perPage - 1;
        const realIdx = fullIdx - fullIdxLow;

        if (0 <= realIdx && fullIdx <= fullIdxHigh) {
            this._router.navigateByUrl('/app/ventes/search/' + this.cVentes.results[realIdx].id.toString());
        } else {
            let link: string;
            let takeFirst = true;

            if (fullIdx === 0) {
                link = this.cVentes.links.first;
            } else if ((fullIdx + 1) === this.cVentes.total) {
                link = this.cVentes.links.last;
                takeFirst = false;
            } else if (fullIdx <= fullIdxHigh) {
                link = this.cVentes.links.previous;
                takeFirst = false;
            } else {
                link = this.cVentes.links.next;
            }

            this._cVentesFactory.getByLink$(link).pipe(
                switchMap(cVentes => this._searchService.current$.pipe(
                    take(1),
                    tap(search => this._searchService.setCurrent(search.criteria, cVentes)),
                    map(_ => cVentes),
                )),
                map(cVentes => cVentes.results[takeFirst ? 0 : (cVentes.results.length - 1)].id),
                take(1),
            ).subscribe(id => this._router.navigateByUrl('/app/ventes/search/' + id.toString()));
        }
    }
}
