import angularJS from '@shared/angularJS/global.ng';
import {SortConst} from '@shared/constants';
import {IModule} from 'angular';
import {NgSortManager} from '@legacy/app/managers/managers';

export default function getEskButtonSort(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-button-sort></esk-button-sort>
         */
        module.component('eskButtonSort', {
            bindings: {
                sortType: '@',
                sortCode: '@',
                currentSort: '=',
                onChangeSort: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/button/sort.html'
        });

        /**
         * Search by keywords
         *
         * @param SortManager
         * @constructor
         */
        Controller.$inject = ['SortManager'];
        function Controller(this: any, sortManager: NgSortManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onClick = onClick;
            $ctrl.sortConst = SortConst;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.sort = sortManager.getOneById($ctrl.sortCode + SortConst.ASCENDING);
                $ctrl.displayButton = sortManager.isIdInArray($ctrl.sortType, $ctrl.sort.id) && angular.isFunction($ctrl.onChangeSort);
            }

            /**
             * On click on button
             */
            function onClick() {
                if (angular.isObject($ctrl.currentSort) && $ctrl.sort.code === $ctrl.currentSort.code) {
                    $ctrl.currentSort = sortManager.getOneById($ctrl.currentSort.code + ($ctrl.currentSort.direction === SortConst.DESCENDING ? SortConst.ASCENDING : SortConst.DESCENDING));
                } else {
                    $ctrl.currentSort = sortManager.getOneById($ctrl.sort.id);
                }

                $ctrl.onChangeSort($ctrl.currentSort);
            }
        }
    })(angularJS);
}
