import {Directive, inject, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {NgModel} from '@angular/forms';
import {AppFormErrorComponent} from '@shared/form/error/form-error.component';
import {ReplaySubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import FormService from '@shared/form/form.service';

@Directive({selector: '[appFormError]'})
export class AppFormErrorDirective implements OnDestroy, OnInit {
    static readonly HAS_ERROR_CLASS = 'has-error';
    protected _renderer2 = inject(Renderer2);
    protected _viewContainerRef = inject(ViewContainerRef);
    protected readonly _onDestroy$ = new Subject<void>();
    private _ngModelFormSource = new ReplaySubject<NgModel>();
    private _ngModelForm$ = this._ngModelFormSource.asObservable();

    @Input()
    set appFormError(value: NgModel) {
        this._ngModelFormSource.next(value);
    }

    ngOnInit(): void {
        const componentRef = this._viewContainerRef.createComponent(AppFormErrorComponent);
        const nativeElement = this._viewContainerRef.element.nativeElement as AppFormErrorDirective;

        this._renderer2.appendChild(nativeElement, componentRef.location.nativeElement);
        FormService.getError$(this._ngModelForm$, () => {
            componentRef.instance.error = undefined!;
            this._renderer2.removeClass(nativeElement, AppFormErrorDirective.HAS_ERROR_CLASS);
        }).pipe(
            filter(error => !!error),
            takeUntil(this._onDestroy$),
        ).subscribe(error => {
            componentRef.instance.error = error;
            this._renderer2.addClass(nativeElement, AppFormErrorDirective.HAS_ERROR_CLASS);
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
