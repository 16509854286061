import {Injectable} from '@angular/core';
import {IDictionaryStorage} from '@models/dictionaries/dictionary/dictionary.interfaces';
import {IDictionaryItemApi} from '@models/dictionaries/dictionary/items/item/dictionary-item.interfaces';
import Etude from '@models/etudes/etude/etude.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {Observable, of} from 'rxjs';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import {DiagnosticLettersConst, DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';
import UserSettings from '@models/users/user/settings/user-settings.model';
import Person from '@models/contacts/person/person.model';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';

@Injectable({providedIn: 'root'})
export class DictionariesLocalService {
    static readonly INIT_DICTIONARIES_LOCAL = {
        [Dictionary.names.AFFICHETTE_GROUPS]: [
            {id: 1, code: '1', label: 'Personnalisées', tags: ['perso']},
            {id: 2, code: '2', label: AppNoteoIconTextNotyComponent.label, tags: ['noty']},
            {id: 3, code: '3', label: 'Conseil Supérieur du Notariat', tags: ['csn']},
        ],
        [Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS]: [
            {id: 1, code: Vente.archiveNegocieeRaisons.PORTEFEUILLE, label: 'Vente du portefeuille (vente solo)'},
            {id: 2, code: Vente.archiveNegocieeRaisons.RESEAU, label: 'Vente du réseau (vente duo)'},
            {id: 3, code: Vente.archiveNegocieeRaisons.AUTRES, label: 'Autre'},
        ],
        [Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_TYPES]: [
            {id: 1, code: Demandeur.archiveTypes.VENTE_TROUVEE, label: 'Vente trouvée par l\'étude'},
            {id: 2, code: Demandeur.archiveTypes.VENTE_NON_TROUVEE, label: 'Vente non trouvée par l\'étude'},
            {id: 3, code: Demandeur.archiveTypes.NE_RECHERCHE_PLUS, label: 'Ne recherche plus de biens'},
        ],
        [Dictionary.names.DEMANDEUR_RECHERCHE_STATUTS]: [
            {id: 1, code: DemandeurRecherche.activeStatuts.UP_TO_DATE, label: 'À jour'},
            {id: 2, code: DemandeurRecherche.activeStatuts.PENDING, label: 'Nécessitant une action'},
            {id: 3, code: DemandeurRecherche.activeStatuts.NO_RESULT, label: 'Sans résultat'},
        ],
        [Dictionary.names.DEMANDEUR_RECHERCHE_TRANSACTION_TYPES]: [
            {id: 1, code: DossierBienTypesConst.VENTE, label: 'Vente'},
            {id: 2, code: DossierBienTypesConst.LOCATION, label: 'Location'},
        ],
        [Dictionary.names.DEMANDEUR_STATUTS]: [
            {id: 1, code: Demandeur.statuts.BROUILLON, label: 'Sans recherche active'},
            {id: 2, code: Demandeur.statuts.EN_RECHERCHE, label: 'Avec une recherche active'},
            {id: 3, code: Demandeur.statuts.EN_SUSPENS, label: 'Suspendu'},
            {id: 4, code: Demandeur.statuts.ARCHIVE, label: 'Archivé'},
        ],
        [Dictionary.names.DIAGNOSTIC_STATUTS]: [
            {id: 1, code: DiagnosticStatutsConst.DISPONIBLE, label: 'Disponible'},
            {id: 2, code: DiagnosticStatutsConst.NON_SOUMIS, label: 'Non soumis'},
            {id: 3, code: DiagnosticStatutsConst.BLANC, label: 'Vierge'},
            {id: 4, code: DiagnosticStatutsConst.NON_COMMUNIQUE, label: 'Non communiqué'},
        ],
        [Dictionary.names.DIFFUSION_PHONE_SOURCES]: [
            {id: 1, code: UserSettings.phoneSources.AUTO, label: 'Automatique (défaut)'},
            {id: 2, code: UserSettings.phoneSources.UTILISATEUR_FIXE, label: 'Mon fixe'},
            {id: 3, code: UserSettings.phoneSources.UTILISATEUR_MOBILE, label: 'Mon mobile'},
            {id: 4, code: UserSettings.phoneSources.SITE_UTILISATEUR_FIXE, label: 'Fixe de mon site'},
            {id: 5, code: UserSettings.phoneSources.SITE_PRINCIPAL_FIXE, label: 'Fixe du site principal de l\'étude'},
            {id: 6, code: UserSettings.phoneSources.AUTRE, label: 'Autre'},
        ],
        [Dictionary.names.ESTIMATION_MOTIFS]: [
            {id: 1, code: Estimation.motifs.SUCCESSION, label: 'Succession'},
            {id: 2, code: Estimation.motifs.VENTE, label: 'Vente'},
            {id: 3, code: Estimation.motifs.DIVORCE, label: 'Divorce'},
            {id: 4, code: Estimation.motifs.DONATION, label: 'Donation'},
            {id: 5, code: Estimation.motifs.ETUDE_PATRIMOINE, label: 'Étude patrimoine'},
            {id: 6, code: Estimation.motifs.AUTRE, label: 'Autre'},
        ],
        [Dictionary.names.ESTIMATION_STATUTS]: [
            {id: 1, code: Estimation.statuts.BROUILLON, label: 'Ébauche'},
            {id: 2, code: Estimation.statuts.REMIS, label: 'Remise au client'},
            {id: 3, code: Estimation.statuts.ARCHIVE, label: 'Archivée'},
        ],
        [Dictionary.names.ESTIMATION_TYPES]: [
            {id: 1, code: Estimation.types.AVIS_VALEUR, label: 'Avis de valeur'},
            {id: 2, code: Estimation.types.EVALUATION, label: 'Évaluation'},
        ],
        [Dictionary.names.ESTIMATION_VALEUR_VENALE_TYPES]: [
            {id: 1, code: Estimation.valeurVenaleTypes.SIMPLE, label: 'Simple'},
            {id: 2, code: Estimation.valeurVenaleTypes.FOURCHETTE, label: 'Fourchette'},
        ],
        [Dictionary.names.FINANCEMENT_FONDS_PROVENANCES]: [
            {id: 1, code: Demandeur.fondsProvenances.NON_RENSEIGNE, label: 'Non renseigné'},
            {id: 2, code: Demandeur.fondsProvenances.PAIEMENT_COMPTANT, label: 'Paiement comptant'},
            {id: 3, code: Demandeur.fondsProvenances.FINANCEMENT, label: 'Financement'},
        ],
        [Dictionary.names.FINANCEMENT_STATUTS]: [
            {id: 1, code: Demandeur.fondsStatuts.NON_RENSEIGNE, label: 'Non renseigné'},
            {id: 2, code: Demandeur.fondsStatuts.PAS_DE_RECHERCHE, label: 'Pas de démarche'},
            {id: 3, code: Demandeur.fondsStatuts.ATTENTE_REPONSE, label: 'En attente de réponse'},
            {id: 4, code: Demandeur.fondsStatuts.ACCEPTE, label: 'Accepté'},
            {id: 5, code: Demandeur.fondsStatuts.REFUSE, label: 'Refusé'},
        ],
        [Dictionary.names.LOCATION_LOYER_TYPES]: [
            {id: 1, code: Location.loyerTypes.HORS_TAXES, label: 'Hors taxes', abreviation: 'HT'},
            {id: 2, code: Location.loyerTypes.TTC, label: 'Toutes taxes comprises', abreviation: 'TTC'},
        ],
        [Dictionary.names.LOCATION_MANDAT_TYPES]: [
            {id: 1, code: Location.mandatTypes.GESTION, label: 'Gestion', texte: 'gestion'},
            {id: 2, code: Location.mandatTypes.LOCATION, label: 'Location', texte: 'location'},
        ],
        [Dictionary.names.LOCATION_STATUTS]: [
            {id: 1, code: Location.statuts.BROUILLON, label: 'Pas de disponibilité'},
            {id: 2, code: Location.statuts.DISPONIBLE, label: 'Disponible'},
            {id: 3, code: Location.statuts.DIFFUSE, label: 'Sur le réseau'},
            {id: 4, code: Location.statuts.SUSPENDU, label: 'Indisponible'},
            {id: 5, code: Location.statuts.ARCHIVE, label: 'Archivée'},
        ],
        [Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES]: [
            {id: 1, code: DiagnosticLettersConst.A, label: 'A'},
            {id: 2, code: DiagnosticLettersConst.B, label: 'B'},
            {id: 3, code: DiagnosticLettersConst.C, label: 'C'},
            {id: 4, code: DiagnosticLettersConst.D, label: 'D'},
            {id: 5, code: DiagnosticLettersConst.E, label: 'E'},
            {id: 6, code: DiagnosticLettersConst.F, label: 'F'},
            {id: 7, code: DiagnosticLettersConst.G, label: 'G'},
        ],
        [Dictionary.names.REFERENCES_ARCHIVEES_TYPES]: [
            {id: 1, code: Vente.archiveTypes.NEGOCIEE, label: 'Négociée par l\'étude'},
            {id: 2, code: Vente.archiveTypes.NON_NEGOCIEE, label: 'Non négociée par l\'étude'},
        ],
        [Dictionary.names.SEXES]: [
            {id: 1, code: Person.sexes.FEMME, label: 'Femme', shortLabel: 'Femme'},
            {id: 2, code: Person.sexes.HOMME, label: 'Homme', shortLabel: 'Homme'},
        ],
        [Dictionary.names.TITRES]: [
            {id: 1, code: Person.titres.MONSIEUR, label: 'Monsieur', abreviation: 'M.', shortLabel: 'M.'},
            {id: 2, code: Person.titres.MADAME, label: 'Madame', abreviation: 'Mme', shortLabel: 'Mme'},
            {id: 3, code: Person.titres.MAITRE, label: 'Maître', abreviation: 'Me', shortLabel: 'Me', deprecated: true},
        ],
        [Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS]: [
            {
                id: 1,
                code: Vente.archiveNegocieeRaisons.PORTEFEUILLE,
                label: 'Acquéreur du portefeuille (vente solo)',
                shortLabel: 'Solo',
            },
            {
                id: 2,
                code: Vente.archiveNegocieeRaisons.RESEAU,
                label: 'Acquéreur du réseau (vente duo)',
                shortLabel: 'Duo',
            },
            {
                id: 3,
                code: Vente.archiveNegocieeRaisons.AUTRES,
                label: 'Autre provenance',
                shortLabel: 'Autre',
                deprecated: true,
            },
        ],
        [Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS]: [
            {id: 1, code: Vente.archiveNonNegocieeRaisons.AGENCE, label: 'Négocié par une agence', shortLabel: 'Agence'},
            {id: 2, code: Vente.archiveNonNegocieeRaisons.PAP, label: 'De particulier à particulier', shortLabel: 'PAP'},
            {
                id: 3,
                code: Vente.archiveNonNegocieeRaisons.AUTRES,
                label: 'Non précisée',
                deprecated: true,
            },
        ],
        [Dictionary.names.VENTE_ARCHIVE_TYPES]: [
            {id: 1, code: Vente.archiveTypes.NEGOCIEE, label: 'Négociée par l\'étude'},
            {id: 2, code: Vente.archiveTypes.NON_NEGOCIEE, label: 'Non négociée par l\'étude'},
            {id: 3, code: Vente.archiveTypes.RETIREE, label: 'Retirée de la vente'},
        ],
        [Dictionary.names.VENTE_COMPROMIS_TYPES]: [
            {
                id: 1,
                code: Vente.compromisTypes.SOLO,
                label: 'Dans l\'étude, demandeur de l\'étude (future vente en "solo")',
                tinyLabel: 'avec un demandeur de l\'étude',
            },
            {
                id: 2,
                code: Vente.compromisTypes.DUO,
                label: 'Dans l\'étude, acquéreur apporté par un confrère (future vente en "duo")',
                tinyLabel: 'avec un acquéreur du réseau',
            },
            {id: 3, code: Vente.compromisTypes.AGENCE, label: 'Dans une agence', tinyLabel: 'en agence'},
            {id: 4, code: Vente.compromisTypes.PAP, label: 'De particulier à particulier', tinyLabel: 'de particulier à particulier'},
        ],
        [Dictionary.names.VENTE_INTERACTIVE_ENCHERES_SYSTEMES]: [
            {id: 1, code: Vente.interactiveSystemeEncheres.ASCENDANTES, label: 'Ascendantes'},
            {id: 2, code: Vente.interactiveSystemeEncheres.DESCENDANTES, label: 'Descendantes'},
        ],
        [Dictionary.names.VENTE_MANDAT_TYPES]: [
            {id: 1, code: Vente.mandatTypes.EXCLUSIF, label: 'Exclusif'},
            {id: 2, code: Vente.mandatTypes.NON_EXCLUSIF, label: 'Non exclusif'},
        ],
        [Dictionary.names.VENTE_PRIX_CHARGES_TYPES]: [
            {
                id: 1,
                code: Vente.chargesType.NET_VENDEUR,
                label: 'Charge acquéreur',
                shortLabel: 'Net vendeur',
                abreviation: 'NV',
            },
            {
                id: 2,
                code: Vente.chargesType.HONO_NEGO_INCLUS,
                label: 'Charge vendeur',
                shortLabel: 'Honoraires de négociation inclus',
                abreviation: 'HNI',
            },
        ],
        [Dictionary.names.VENTE_STATUTS]: [
            {id: 1, code: Vente.statuts.BROUILLON, label: 'Sans date de mandat'},
            {id: 2, code: Vente.statuts.MANDAT, label: 'Avec une date de mandat'},
            {id: 3, code: Vente.statuts.DIFFUSE, label: 'Sur le réseau'},
            {id: 4, code: Vente.statuts.COMPROMIS, label: 'En compromis'},
            {id: 5, code: Vente.statuts.SUSPENDU, label: 'Suspendue', deprecated: true},
            {id: 6, code: Vente.statuts.ARCHIVE, label: 'Archivée'},
        ],
        [Dictionary.names.VENTE_TYPES]: [
            {id: 1, code: Vente.types.SIMPLE, label: 'Traditionnelle'},
            {id: 2, code: Vente.types.VIAGER, label: 'Viager'},
            {id: 3, code: Vente.types.INTERACTIVE, label: 'Interactive'},
        ],
    };
    private _dictionaries = {...DictionariesLocalService.INIT_DICTIONARIES_LOCAL} as unknown as Record<string, IDictionaryItemApi[]>;

    get(name: string): IDictionaryStorage {
        return {elements: this._dictionaries[name], etag: undefined!};
    }

    init$(etude?: Etude): Observable<void> {
        this._dictionaries = JSON.parse(JSON.stringify(DictionariesLocalService.INIT_DICTIONARIES_LOCAL)) as Record<string, IDictionaryItemApi[]>;
        if (!etude?.hasNetwork()) {
            this._dictionaries[Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS] =
                this._dictionaries[Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS].filter(item => item.code !== Vente.archiveNegocieeRaisons.RESEAU);
            this._dictionaries[Dictionary.names.LOCATION_STATUTS] =
                this._dictionaries[Dictionary.names.LOCATION_STATUTS].filter(item => item.code !== Location.statuts.DIFFUSE);
            this._dictionaries[Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS] =
                this._dictionaries[Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS].filter(item => item.code !== Vente.archiveNegocieeRaisons.RESEAU);
            this._dictionaries[Dictionary.names.VENTE_COMPROMIS_TYPES] =
                this._dictionaries[Dictionary.names.VENTE_COMPROMIS_TYPES].filter(item => item.code !== Vente.compromisTypes.DUO);
            this._dictionaries[Dictionary.names.VENTE_STATUTS] =
                this._dictionaries[Dictionary.names.VENTE_STATUTS].filter(item => item.code !== Vente.statuts.DIFFUSE);
        }

        return of(undefined);
    }
}
