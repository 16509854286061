import {Injectable} from '@angular/core';
import Region from '@models/regions/region/region.model';
import {NgArea} from '@legacy/app/managers/ressources';
import {IRegionApi} from '@models/regions/region/region.interfaces';

@Injectable({providedIn: 'root'})
export class RegionFactory {
    create(regionApi: IRegionApi): Region {
        const region = new Region(regionApi.uuid, regionApi.id);

        region.code = regionApi.code;
        region.fullname = regionApi.fullname;

        return region;
    }

    ngCreate(ngRegion: NgArea): Region {
        const region = new Region(ngRegion.uuid, ngRegion.id);

        region.code = ngRegion.code;
        region.fullname = ngRegion.fullname;

        return region;
    }
}
