import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {delay, Subject} from 'rxjs';
import {NgLocationManager} from '@legacy/app/managers/managers';

export default function getLocationLabelRent(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-location-label-rent></esk-location-label-rent>
         */
        module.component('eskLocationLabelRent', {
            bindings: {location: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/locations/components/item/label/rent.html'
        });

        /**
         * Controller
         */
        Controller.$inject = ['LocationManager'];
        function Controller(this: any, locationManager: NgLocationManager) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();

            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                // Écoute du current$ pour mettre à jour en fonction de la saisie du prix dans le current
                locationManager.current$.pipe(startWith(undefined), tap(_ => $ctrl.loader = true), delay(1), takeUntil(_onDestroy$)).subscribe(() => {
                    $ctrl.loyer = +($ctrl.location.loyer?.toString().replace(',', '.'));
                    $ctrl.loader = false;
                });
            }
        }
    })(angularJS);
}
