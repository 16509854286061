import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {map} from 'rxjs/operators';
import {IDossierExportSaveApi} from '@models/dossiers/dossier/exports/export/save/dossier-export-save.interfaces';
import {ICDossierExportsApi} from '@models/dossiers/dossier/exports/collection/dossier-exports.collection.interfaces';
import {DossierType} from '@models/dossiers/dossiers.interfaces';

@Injectable({providedIn: 'root'})
export class DossierExportsApiService {
    private _modelApiService = inject(ModelApiService);

    create$(dossierType: DossierType, dossierUuid: string, dossierExportSaveApi: IDossierExportSaveApi): Observable<void> {
        return this._modelApiService.post$('/' + dossierType + 's/' + dossierUuid + '/exports', dossierExportSaveApi)
            .pipe(map(_ => undefined!));
    }

    getCollection$(dossierType: string, dossierUuid: string): Observable<ICDossierExportsApi> {
        return this.getCollectionByLink$('/' + dossierType + 's/' + dossierUuid + '/exports');
    }

    getCollectionByLink$(link: string): Observable<ICDossierExportsApi> {
        return this._modelApiService.getCollection$(link);
    }
}
