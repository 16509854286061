import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {NgBienPhoto} from '@legacy/app/managers/ressources';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {BienPhotoFactory} from '@models/bien/photos/photo/bien-photo.factory';
import {of, switchMap} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';

export default function getBienEditPhotos(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-edit-photos></esk-bien-edit-photos>
         */
        module.component('eskBienEditPhotos', {
            bindings: {bien: '=', dossierBien: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/edit/photos.html'
        });

        /**
         * Controller for display photo directive
         *
         * @param $translate
         * @param SoqModalesManager
         * @param BiensUtils
         * @param Ng2BienPhotoFactory
         * @param Ng2ModalService
         */
        Controller.$inject = ['$translate', 'SoqModalesManager', 'BiensUtils', 'Ng2BienPhotoFactory', 'Ng2ModalService'];
        function Controller(this: any,
                            $translate: angular.translate.ITranslateService,
                            soqModalesManager: NgSoqModalesManager,
                            biensUtils: NgBienUtils,
                            ng2BienPhotoFactory: BienPhotoFactory,
                            ng2ModalService: ModalService) {
            const $ctrl = this;

            $ctrl.removePhoto = removePhoto;
            $ctrl.editBienPhoto = editBienPhoto;
            $ctrl.manageVisibilityBienPhoto = manageVisibilityBienPhoto;
            $ctrl.uiSortableOptions = biensUtils.getUISortableOptions();
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;

            /**
             * Open modale to edit photo
             *
             * @param bienPhoto
             */
            function editBienPhoto(bienPhoto: NgBienPhoto) {
                soqModalesManager.open('EskModalEditPhoto', {resolve: {photo: bienPhoto}});
            }

            /**
             * Open modale to manage visibility
             *
             * @param bienPhoto
             */
            function manageVisibilityBienPhoto(bienPhoto: NgBienPhoto) {
                soqModalesManager.open('BienModalVisibilityPhoto', {resolve: {bienPhoto: bienPhoto}});
            }

            /**
             * Remove the given photo
             *
             * @param photo
             */
            function removePhoto(photo: NgBienPhoto) {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Supprimer',
                    question: $translate.instant('remove.confirm.MESSAGE', {ceData: 'cette photo'}),
                    title: $translate.instant('remove.confirm.TITLE'),
                    status: ModalService.status.DANGER,
                }).pipe(
                    switchMap(response => {
                        if (!response) {
                            return of(null);
                        }

                        photo._esk.removing = true;

                        return ng2BienPhotoFactory.delete$($ctrl.dossierBien, ng2BienPhotoFactory.ngCreate(photo)).pipe(
                            tap(() => $ctrl.bien.photos = ($ctrl.bien.photos as NgBienPhoto[]).filter(ngBP => ngBP._esk.uuid !== photo._esk.uuid)),
                        );
                    }),
                    take(1),
                ).subscribe({complete: () => photo._esk.removing = false});
            }
        }
    })(angularJS);
}
