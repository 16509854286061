import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from 'lodash';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEmailManager} from '@legacy/app/managers/managers';
import {EmailFactory} from '@models/emails/email/email.factory';
import {NgEmailAttachment, NgEmailRecipient} from '@legacy/app/managers/ressources';
import {MediaFactory} from '@models/medias/media/media.factory';
import {take} from 'rxjs/operators';

export default function getEskDossierSuiviEmailCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDossierSuiviEmailCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/dossier/suivi/email.html',
        });

        /**
         * Dossier suivi email
         *
         * @param Ng2RouterStateForNgService
         * @param EmailManager
         * @param Ng2EmailFactory
         * @param Ng2DocumentService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'EmailManager', 'Ng2EmailFactory', 'Ng2MediaFactory'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            emailManager: NgEmailManager,
                            ng2EmailFactory: EmailFactory,
                            ng2MediaFactory: MediaFactory) {
            const $ctrl = this;

            $ctrl.getNg2Recipient = getNg2Recipient;
            $ctrl.viewAttachment = viewAttachment;
            $ctrl.loader = true;
            $ctrl.recipientsExpand = false;
            emailManager.getOneById(+ng2RouterStateForNgService.params.emailId!).then(function (email) {
                const idx = findIndex(ng2RouterStateForNgService.params.emails as unknown[], {id: email.id});

                $ctrl.email = email;
                $ctrl.ng2Email = ng2EmailFactory.ngCreate($ctrl.email);
                if (0 <= idx && idx < (ng2RouterStateForNgService.params.emails as unknown[]).length) (ng2RouterStateForNgService.params.emails as unknown[])[idx] = $ctrl.ng2Email;
            }).finally(() => $ctrl.loader = false);

            function getNg2Recipient(ngEmailRecipient: NgEmailRecipient) {
                return $ctrl.ng2Email.recipients.find(recipient => recipient.id === ngEmailRecipient.id);
            }

            /**
             * View attachment
             *
             * @param attachment
             */
            function viewAttachment(attachment: NgEmailAttachment) {
                ng2MediaFactory.read$(ng2MediaFactory.ngCreate(attachment.media)).pipe(take(1)).subscribe();
            }
        }
    })(angularJS);
}
