export default `
<div class="text-justify">
  <p class="text-bold">
    Correspondance des couleurs
  </p>

  <p>
    <span class="bullet-green"><fa-icon [icon]="'circle'" size="lg"></fa-icon></span>
    - Nouveau(x) résultat(s) à traiter.
  </p>

  <p>
    <span class="bullet-orange"><fa-icon [icon]="'circle'" size="lg"></fa-icon></span> - Résultat(s) modifié(s) (prix).
  </p>

  <p>
    <span class="bullet-blue"><fa-icon [icon]="'circle'" size="lg"></fa-icon></span> - Résultat(s) intéressant(s).
  </p>

  <p class="bullet-compromis">
    <span class="compromis bullet-red"><fa-icon [icon]="'bookmark'" size="lg"></fa-icon></span> - Bien(s) en compromis.
  </p>

  <p>
    <span class="bullet-red"><fa-icon [icon]="'circle'" size="lg"></fa-icon></span> - Bien(s) supprimé(s) du réseau.
  </p>
</div>
`;
