import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getDemandeursUtils from '@legacy/app/demandeurs/services/utils';
import getDemandeursList from '@legacy/app/demandeurs/components/list';
import getDemandeursTemplates from '@legacy/app/demandeurs/templates';
import getDemandeursModalFiltersWalletCurrentCtrl from '@legacy/app/demandeurs/controllers/modals/filters.wallet.current';
import getDemandeursWalletCurrentCtrl from '@legacy/app/demandeurs/controllers_ponents/wallet/current';
import getDemandeurLifetimeCtrl from '@legacy/app/demandeurs/controllers_ponents/item/lifetime';
import getDemandeurModalArchiveCtrl from '@legacy/app/demandeurs/controllers/modals/item.archive';
import getDemandeurPopover from '@legacy/app/demandeurs/components/item/popover';
import getDemandeurEditGeneralCtrl from '@legacy/app/demandeurs/controllers_ponents/item/edit/general';
import getDemandeurEditCriteriaCtrl from '@legacy/app/demandeurs/controllers_ponents/item/edit/criteria';
import getDemandeurResultsHistoricalCtrl from '@legacy/app/demandeurs/controllers_ponents/item/results/historical';
import getDemandeurResultsDetailsCtrl from '@legacy/app/demandeurs/controllers_ponents/item/results/details';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';
import getDemandeurResults from '@legacy/app/demandeurs/components/item/results';
import getDemandeursModalGroupArchiveCtrl from '@legacy/app/demandeurs/controllers/modals/group.archive';
import getDemandeurInformationEdit from '@legacy/app/demandeurs/components/item/information.edit';

export default function getDemandeurs(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.demandeurs', [
                'esk.managers',
                'pascalprecht.translate',
                'soqrate',
            ])
            .config(['SoqModalesManagerProvider', modales]);

        // Config / Run
        getDemandeursTemplates(module);

        // Services
        getDemandeursUtils(module);

        // Components
        getDemandeurInformationEdit(module);
        getDemandeurPopover(module);
        getDemandeurResults(module);
        getDemandeursList(module);

        // Controllers
        getDemandeurEditGeneralCtrl(module);
        getDemandeurEditCriteriaCtrl(module);
        getDemandeurLifetimeCtrl(module);
        getDemandeurModalArchiveCtrl(module);
        getDemandeurResultsDetailsCtrl(module);
        getDemandeurResultsHistoricalCtrl(module);
        getDemandeursModalFiltersWalletCurrentCtrl(module);
        getDemandeursModalGroupArchiveCtrl(module);
        getDemandeursWalletCurrentCtrl(module);

        return module;

        /**
         * Declare modales
         *
         * @param SoqModalesManagerProvider
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('DemandeursModalFiltersWalletCurrent', {
                templateUrl: 'src/app/legacy/templates/demandeurs/controllers/modals/filters.wallet.current.html',
                controller: 'DemandeursModalFiltersWalletCurrentCtrl',
                size: 'lg'
            });
            soqModalesManagerProvider.addModale('DemandeurModalArchive', {
                templateUrl: 'src/app/legacy/templates/demandeurs/controllers/modals/item.archive.html',
                controller: 'DemandeurModalArchiveCtrl',
                backdrop: 'static'
            });
            soqModalesManagerProvider.addModale('DemandeursModalGroupArchive', {
                templateUrl: 'src/app/legacy/templates/demandeurs/controllers/modals/group.archive.html',
                controller: 'DemandeursModalGroupArchiveCtrl',
                backdrop: 'static'
            });
        }
    })(angularJS);
}
