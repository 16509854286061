import {NgModule, Type} from '@angular/core';
import {
    NgDemandeurResultsComponent, NgDemandeurResultsDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results.component';
import {
    NgDemandeurEditCriteriaComponent, NgDemandeurEditCriteriaDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-edit-criteria.component';
import {
    NgDemandeurEditGeneralComponent, NgDemandeurEditGeneralDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-edit-general.component';
import {
    NgDemandeurLifetimeComponent, NgDemandeurLifetimeDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-lifetime.component';
import {
    NgDemandeurResultsDetailsComponent, NgDemandeurResultsDetailsDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results-details.component';
import {
    NgDemandeurResultsHistoricalComponent, NgDemandeurResultsHistoricalDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results-historical.component';
import {
    NgDemandeursWalletCurrentComponent, NgDemandeursWalletCurrentDirective
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-wallet-current.component';

const components: Type<unknown>[] = [
    NgDemandeurEditCriteriaComponent,
    NgDemandeurEditGeneralComponent,
    NgDemandeurLifetimeComponent,
    NgDemandeurResultsComponent,
    NgDemandeurResultsDetailsComponent,
    NgDemandeurResultsHistoricalComponent,
    NgDemandeursWalletCurrentComponent,
];
const directives: Type<unknown>[] = [
    NgDemandeurEditCriteriaDirective,
    NgDemandeurEditGeneralDirective,
    NgDemandeurLifetimeDirective,
    NgDemandeurResultsDetailsDirective,
    NgDemandeurResultsDirective,
    NgDemandeurResultsHistoricalDirective,
    NgDemandeursWalletCurrentDirective,
];

@NgModule({declarations: [...components, ...directives], exports: components})
export class DemandeursComponentsModule {
}
