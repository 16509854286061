import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';

export default function getEskBoxMeteo(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-box-meteo></esk-box-meteo>
         */
        module.run(template).component('eskBoxMeteo', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/box/meteo.html',
        });

        /**
         * Display shortcuts of the application
         *
         * @param EskBox
         *
         * @constructor
         */
        Controller.$inject = ['EskBox'];
        function Controller(this: any, eskBox: NgEskBox) {
            const $ctrl = this;

            $ctrl.box = eskBox.getOneByCode('meteo');
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/meteo/container.html', '<esk-box-meteo></esk-box-meteo>');
        }
    })(angularJS);
}
