export default `
<form name="$ctrl.editForm" class="form-login" ng-submit="$ctrl.resetPassword()" novalidate>
  <fieldset>
    <legend>{{"Réinitialisation de mot de passe" | translate}}</legend>

    <p>
      Votre mot de passe doit être formé avec au minimum :<br>

      <span class="padding-left-20">- 8 caractères</span><br>
      <span class="padding-left-20">- Un chiffre</span><br>
      <span class="padding-left-20">- Une lettre</span><br>
      <span class="padding-left-20">- Une majuscule</span><br>
      <span class="padding-left-20">- Un caractère spécial (#?!@$%^&*:;.,=_-)</span>
    </p>

    <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.newPassword.$invalid}">
      <label for="newPassword">{{'Nouveau mot de passe' | translate}}</label>

      <input type="password" class="form-control" id="newPassword" name="newPassword" ng-model="$ctrl.newPassword"
             required>

      <div class="error text-small tw-block" ng-messages="$ctrl.editForm.newPassword.$error"
           ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.newPassword.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>

    <div class="form-group"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.newPassword.$valid && $ctrl.editForm.confirmationNewPassword.$invalid}">
      <label for="confirmationNewPassword">Confirmation du nouveau mot de passe</label>

      <input type="password" class="form-control" id="confirmationNewPassword" name="confirmationNewPassword"
             ng-model="$ctrl.confirmationNewPassword" ng-pattern="$ctrl.getRegex($ctrl.newPassword)" required>

      <div class="error text-small tw-block" ng-messages="$ctrl.editForm.confirmationNewPassword.$error"
           ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.confirmationNewPassword.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
        <br>Le mot de passe et la confirmation doivent être identiques.
      </div>
    </div>

    <p class="text-danger" ng-if="$ctrl.message">
      <fa-icon [icon]="'times-circle'"></fa-icon> {{$ctrl.message | translate}}
    </p>

    <p ng-show="$ctrl.sending">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}} ...
    </p>

    <div class="form-actions">
      <button type="submit" class="btn btn-primary pull-right" ng-disabled="$ctrl.sending">
        Réinitialiser le mot de passe <fa-icon [icon]="'arrow-circle-right'"></fa-icon>
      </button>
    </div>
  </fieldset>
</form>
`;
