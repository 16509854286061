import {inject, Injectable} from '@angular/core';
import {LocalStorageService} from '@core/storage/local-storage.service';
import {IDictionaryApi, IDictionaryStorage} from '@models/dictionaries/dictionary/dictionary.interfaces';
import {DictionariesLocalService} from '@models/dictionaries/dictionaries.local.service';
import {ModelApiService} from '@models/model.api.service';
import {HttpOptions} from '@core/api/api.interfaces';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class DictionariesApiService {
    static readonly LOCAL_STORAGE_KEY = 'dictionaries';
    private _dictionariesLocalService = inject(DictionariesLocalService);
    private _localStorageService = inject(LocalStorageService);
    private _modelApiService = inject(ModelApiService);

    get$(name: string, httpOptions: HttpOptions = {}): Observable<IDictionaryApi> {
        httpOptions.observe = 'response';

        return this._modelApiService.get$<HttpResponse<IDictionaryApi>>('/dictionaries/' + name, httpOptions).pipe(
            map(httpResponse => {
                const dictionaryApi = httpResponse.body!;

                dictionaryApi.etag = httpResponse.headers.get('etag')!;

                return dictionaryApi;
            }),
        );
    }

    getAllFromStorage(): Record<string, IDictionaryStorage> {
        let dictionaryStorage: Record<string, IDictionaryStorage>;

        try {
            dictionaryStorage = JSON.parse(this._localStorageService.get<string>(DictionariesApiService.LOCAL_STORAGE_KEY)) as Record<string, IDictionaryStorage>;
        } catch (e) {
            dictionaryStorage = {};
        }

        return dictionaryStorage;
    }

    getByName(dictionaryName: string): IDictionaryStorage {
        const dictionaryStorage = this.getFromLocal(dictionaryName);

        if (dictionaryStorage.elements) {
            return dictionaryStorage;
        }

        const dictionariesStorage = this.getAllFromStorage();

        if (dictionariesStorage[dictionaryName]) {
            return dictionariesStorage[dictionaryName];
        }

        return {elements: undefined!, etag: undefined!};
    }

    getFromLocal(dictionaryName: string): IDictionaryStorage {
        return this._dictionariesLocalService.get(dictionaryName);
    }

    saveInStorage(dictionaryName: string, dictionaryStorage: IDictionaryStorage): void {
        const dictionariesStorage = this.getAllFromStorage();

        dictionariesStorage[dictionaryName] = dictionaryStorage;
        this._localStorageService.set(DictionariesApiService.LOCAL_STORAGE_KEY, JSON.stringify(dictionariesStorage));
    }
}
