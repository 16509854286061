import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import Commune from '@models/communes/commune/commune.model';

@Injectable({providedIn: 'root'})
export class CommunesService {
    private _communeFactory = inject(CommuneFactory);

    get$(ids: number[] = []): Observable<Commune[]> {
        return ids.length > 0 ? combineLatest(ids.map(id => this._communeFactory.get$(id))) : of([] as Commune[]);
    }
}
