export default class UserPhoto {
    private _bottomRightCoordinates: [number, number] = [undefined!, undefined!];
    private _photoId!: number;
    private _topLeftCoordinates: [number, number] = [undefined!, undefined!];

    get bottomRightCoordinates(): [number, number] {
        return this._bottomRightCoordinates;
    }

    set bottomRightCoordinates(value: [number, number]) {
        this._bottomRightCoordinates = value;
    }

    get photoId(): number {
        return this._photoId;
    }

    set photoId(value: number) {
        this._photoId = value;
    }

    get topLeftCoordinates(): [number, number] {
        return this._topLeftCoordinates;
    }

    set topLeftCoordinates(value: [number, number]) {
        this._topLeftCoordinates = value;
    }
}
