import {startWith, tap} from 'rxjs/operators';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {NgForm, NgModel, ValidationErrors} from '@angular/forms';

export default class FormService {
    static readonly PARSE = 'parse';
    // @todo Utilité du typage ?
    static readonly TEXT_ERROR: Record<string, string> = {
        DEFAULT: 'Erreur de saisie',
        date: 'Format invalide',
        descriptifVenteWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir un descriptif pour cette vente.',
        email: 'L\'adresse email est invalide.',
        emailValidation: 'L\'adresse email est invalide.',
        interactivePrixReserveWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir un prix de réserve positif.',
        /* eslint-disable */
        // @todo Revoir le nom de cet attribut
        'interval_0-1': 'Valeur entre 0 et 1',
        /* eslint-enable */
        max: 'Valeur trop grande',
        maxdate: 'Date invalide, trop récente',
        min: 'Valeur trop petite',
        mindate: 'Date invalide, trop ancienne',
        minlength: 'Nombre de caractères invalides',
        pattern: 'Syntaxe incorrecte',
        [FormService.PARSE]: 'Format invalide',
        phone: 'Numéro de téléphone invalide',
        prixVenteWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir un prix de vente positif.',
        required: 'Champ requis',
        superficieWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir une superficie positive.',
        titreVenteWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir un titre pour cette vente.',
        viagerBouquetWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir un bouquet positif.',
        viagerRenteWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir une rente positive.',
        viagerValeurBienWhenDateMandat: 'Une date de mandat a été saisie pour ce bien.\nVous devez saisir une valeur de bien positive.',
    };

    static getFirstError(errors: ValidationErrors | null): string {
        if (!errors) {
            return undefined!;
        }

        const errorEntries = Object.entries(errors).filter(([, errorValue]) => errorValue);

        if (errorEntries.length <= 0) {
            return undefined!;
        }

        const [key, value] = errorEntries[0] as [string, string];

        return key === 'custom' ? value : (FormService.TEXT_ERROR[key] || FormService.TEXT_ERROR.DEFAULT);
    }

    static getError$(ngModel$: Observable<NgModel>, raz: () => void = undefined!): Observable<string> {
        return ngModel$.pipe(
            switchMap(ngModel => {
                if (!ngModel) {
                    return of(undefined as unknown as string);
                }

                const ngForm = ngModel.formDirective as NgForm;

                if (!ngForm) {
                    return of(undefined as unknown as string);
                }

                return combineLatest([
                    ngForm.ngSubmit.pipe(startWith(undefined!)),
                    ngModel.valueChanges!.pipe(startWith(undefined!)),
                ]).pipe(
                    tap(_ => raz?.()),
                    switchMap(_ => {
                        if (!ngForm.submitted) {
                            return of(undefined as unknown as string);
                        }

                        return of(FormService.getFirstError(ngModel.errors));
                    }),
                );
            }),
        );
    }

    static hasErrorOnConstraints(ngForm: NgForm, constraints: string[]): boolean {
        return ngForm.submitted && constraints
            .map(constraint => ngForm.controls[constraint]).filter(control => !!control)
            .some(control => FormService.getFirstError(control.errors));
    }

    static parseNumber(value: unknown): number {
        if (!value) {
            return null!;
        }

        if (typeof value !== 'number') {
            const valueNumber = +value;

            return isNaN(valueNumber) ? null! : valueNumber;
        }

        return value;
    }
}
