import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import DateFormat from '@shared/date/date.format';

export default function getVentesNegocieesNaturesCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskVentesNegocieesNaturesCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/controllers/negociees/natures.html',
        });

        /**
         * Controller of ventes negociees by natures
         *
         * @param Ng2EtudeService
         */
        Controller.$inject = ['Ng2EtudeService'];
        function Controller(this: any, ng2EtudeService: EtudeService) {
            const $ctrl = this;

            $ctrl.onChange = onChange;
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtudeId = currentEtude.id);
            $ctrl.options = {user: {placeholder: "Toute l'étude est prise en compte.", onSelect: $ctrl.onChange}};
            $ctrl.filter = {yearReference: DateFormat.getYear(DateFormat.dateFromNow())};
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];

            /**
             * Refresh table on change filter
             */
            function onChange() {
                $ctrl.filter.responsablesDossier = angular.isObject($ctrl.filter.responsableDossier) ? [$ctrl.filter.responsableDossier.id] : undefined;
                $ctrl.refreshTable();
            }
        }
    })(angularJS);
}
