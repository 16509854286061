import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import boxNaturesTemplate from '@legacy/templates/ventes/components/box/negociees/natures';
import boxMandatsNaturesTemplate from '@legacy/templates/ventes/components/box/mandats.natures';
import boxMandatsTemplate from '@legacy/templates/ventes/components/box/mandats';
import boxNegocieesTemplate from '@legacy/templates/ventes/components/box/negociees';
import boxNetworkUpdatedTemplate from '@legacy/templates/ventes/components/box/network-updated';
import boxSearchTemplate from '@legacy/templates/ventes/components/box/search';
import listDetailsTemplate from '@legacy/templates/ventes/components/list/details';
import chartNegocieesTemplate from '@legacy/templates/ventes/components/chart.negociees';
import listTemplate from '@legacy/templates/ventes/components/list';
import tableNegocieesTemplate from '@legacy/templates/ventes/components/table.negociees.natures';
import interactiveDateFinTemplate from '@legacy/templates/ventes/controllers/item/edit/general/interactive-date-fin.help';
import btnNewTemplate from '@legacy/templates/ventes/controllers/item/mandats/btn-new';
import correspondantsTemplate from '@legacy/templates/ventes/controllers/item/correspondants';
import lifetimeTemplate from '@legacy/templates/ventes/controllers/item/lifetime';
import mandatsTemplate from '@legacy/templates/ventes/controllers/item/mandats';
import modalBoxMandatsTemplate from '@legacy/templates/ventes/controllers/modals/box/mandats';
import modalBoxSearchTemplate from '@legacy/templates/ventes/controllers/modals/box/search';
import modalArchiveTemplate from '@legacy/templates/ventes/controllers/modals/item/archive';
import modalCompromisTemplate from '@legacy/templates/ventes/controllers/modals/item/compromis';
import modalContratsTemplate from '@legacy/templates/ventes/controllers/modals/item/contrats';
import negocieesEtudeTemplate from '@legacy/templates/ventes/controllers/negociees/etude';
import negocieesNaturesTemplate from '@legacy/templates/ventes/controllers/negociees/natures';
import networkUpdatedCriteriaTemplate from '@legacy/templates/ventes/controllers/network-updated.criteria';
import prixInteractiveCompletTemplate from '@legacy/templates/ventes/prix/interactive/complet';
import prixInteractiveHonorairesTemplate from '@legacy/templates/ventes/prix/interactive/honoraires_negociation_inclus';
import prixTradDeprecatedCompletTemplate from '@legacy/templates/ventes/prix/traditionnelle/deprecated_complet';
import prixTradDeprecatedHonorairesTemplate from '@legacy/templates/ventes/prix/traditionnelle/deprecated_honoraires_negociation_inclus';
import prixTradHonorairesTemplate from '@legacy/templates/ventes/prix/traditionnelle/honoraires_negociation_inclus';
import prixViagerCompletTemplate from '@legacy/templates/ventes/prix/viager/complet';
import prixViagerHonorairesTemplate from '@legacy/templates/ventes/prix/viager/honoraires_negociation_inclus';
import daysBeforeTemplate from '@legacy/templates/ventes/days-before-auto-send-to-network';
import deprecatedPrixTemplate from '@legacy/templates/ventes/deprecated_prix';
import keywordsHelpTemplate from '@legacy/templates/ventes/keywords.help';
import mandatMissingInfosTemplate from '@legacy/templates/ventes/mandat.missing-information';

export default function getVentesTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/ventes/components/box/negociees/natures.html', boxNaturesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/box/mandats.natures.html', boxMandatsNaturesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/box/mandats.html', boxMandatsTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/box/negociees.html', boxNegocieesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/box/network-updated.html', boxNetworkUpdatedTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/box/search.html', boxSearchTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/list/details.html', listDetailsTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/chart.negociees.html', chartNegocieesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/list.html', listTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/components/table.negociees.natures.html', tableNegocieesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/item/edit/general/interactive-date-fin.help.html', interactiveDateFinTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/item/mandats/btn-new.html', btnNewTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/item/correspondants.html', correspondantsTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/item/lifetime.html', lifetimeTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/item/mandats.html', mandatsTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/modals/box/mandats.html', modalBoxMandatsTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/modals/box/search.html', modalBoxSearchTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/modals/item/archive.html', modalArchiveTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/modals/item/compromis.html', modalCompromisTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/modals/item/contrats.html', modalContratsTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/negociees/etude.html', negocieesEtudeTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/negociees/natures.html', negocieesNaturesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/controllers/network-updated.criteria.html', networkUpdatedCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/interactive/complet.html', prixInteractiveCompletTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/interactive/honoraires_negociation_inclus.html', prixInteractiveHonorairesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_complet.html', prixTradDeprecatedCompletTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_honoraires_negociation_inclus.html', prixTradDeprecatedHonorairesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/traditionnelle/honoraires_negociation_inclus.html', prixTradHonorairesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/viager/complet.html', prixViagerCompletTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/prix/viager/honoraires_negociation_inclus.html', prixViagerHonorairesTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/days-before-auto-send-to-network.html', daysBeforeTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/deprecated_prix.html', deprecatedPrixTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/keywords.help.html', keywordsHelpTemplate);
            $templateCache.put('src/app/legacy/templates/ventes/mandat.missing-information.html', mandatMissingInfosTemplate);
        }
    })(angularJS);
}
