import {NgModule} from '@angular/core';
import {NgAuthLoginComponent, NgAuthLoginDirective} from '@shared/angularJS/up-ng/auth/auth.login.component';
import {NgAuthLogoutComponent, NgAuthLogoutDirective} from '@shared/angularJS/up-ng/auth/auth.logout.component';
import {NgAuthResetComponent, NgAuthResetDirective} from '@shared/angularJS/up-ng/auth/auth.reset.component';
import {NgAuthStartComponent, NgAuthStartDirective} from '@shared/angularJS/up-ng/auth/auth.start.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        NgAuthLoginComponent,
        NgAuthLoginDirective,
        NgAuthLogoutComponent,
        NgAuthLogoutDirective,
        NgAuthResetComponent,
        NgAuthResetDirective,
        NgAuthStartComponent,
        NgAuthStartDirective,
    ],
    imports: [RouterModule],
})
export class AuthComponentsModule {
}
