import {Injectable} from '@angular/core';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {IEmailRecipientApi} from '@models/emails/email/recipients/recipient/email-recipient.interfaces';
import {NgEmailRecipient} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class EmailRecipientFactory {
    create(emailRecipientApi: IEmailRecipientApi): EmailRecipient {
        const emailRecipient: EmailRecipient = this.createVirgin(emailRecipientApi.id?.toString(), emailRecipientApi.id);

        emailRecipient.emailAddress = emailRecipientApi.emailAddress;
        emailRecipient.name = emailRecipientApi.name;
        emailRecipient.statut = emailRecipientApi.status;
        emailRecipient.type = emailRecipientApi.type;

        return emailRecipient;
    }

    createVirgin(uuid?: string, id?: number): EmailRecipient {
        return new EmailRecipient(uuid ?? EmailRecipient.statuts.NEW, id);
    }

    ngCreate(ngEmailRecipient: NgEmailRecipient): EmailRecipient {
        const emailRecipient: EmailRecipient = this.createVirgin(ngEmailRecipient.id.toString(), ngEmailRecipient.id);

        emailRecipient.emailAddress = ngEmailRecipient.emailAddress;
        emailRecipient.name = ngEmailRecipient.name;
        emailRecipient.statut = ngEmailRecipient.status;
        emailRecipient.type = ngEmailRecipient.type;

        return emailRecipient;
    }
}
