import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class BienSurface extends AModel {
    private _comments!: string;
    private _etage!: DictionaryItem;
    private _habitable!: boolean;
    private readonly _id: number;
    private _otherLabel!: string;
    private _piece!: DictionaryItem;
    private _ponderation = 1;
    private _surface!: number;
    private _surfacePonderee = 0;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get etage(): DictionaryItem {
        return this._etage;
    }

    set etage(value: DictionaryItem) {
        this._etage = value;
    }

    get habitable(): boolean {
        return this._habitable;
    }

    set habitable(value: boolean) {
        this._habitable = value;
    }

    get id(): number {
        return this._id;
    }

    get otherLabel(): string {
        return this._otherLabel;
    }

    set otherLabel(value: string) {
        this._otherLabel = value;
    }

    get piece(): DictionaryItem {
        return this._piece;
    }

    set piece(value: DictionaryItem) {
        this._piece = value;
    }

    get ponderation(): number {
        return this._ponderation;
    }

    set ponderation(value: number) {
        this._ponderation = value;
        this.setSurfacePonderee();
    }

    get surface(): number {
        return this._surface;
    }

    set surface(value: number) {
        this._surface = value;
        this.setSurfacePonderee();
    }

    get surfacePonderee(): number {
        return this._surfacePonderee;
    }

    setSurfacePonderee(): void {
        this._surfacePonderee = this.surface && this.ponderation ? this.surface * this.ponderation : 0;
    }
}
