export default `
<div class="diffusions">
  <!-- Impression papier -->
  <div class="container-fluid container-fullw tw-bg-white margin-bottom-30">
    <h4 class="margin-bottom-30"><span class="text-bold">Impression et Envoi</span></h4>

    <div class="row print-send">
      <!-- Affichette -->
      <div ng-class="$ctrl.options.affichette.collapsed ? 'col-lg-4 col-md-7': 'col-md-7'">
        <div class="panel panel-white" ng-class="$ctrl.options.affichette.collapsed ? 'collapsed' : ''">
          <div class="panel-heading border-light">
            <h4 class="panel-title tw-text-center"><span class="text-bold">Affichette</span></h4>

            <span class="collapse-toggler" ng-switch="$ctrl.options.affichette.collapsed"
                  ng-click="$ctrl.options.affichette.collapsed = !$ctrl.options.affichette.collapsed">
              <span ng-switch-when="true">Choix d'impression <fa-icon [icon]="'plus'"></fa-icon></span>

              <span ng-switch-default><fa-icon [icon]="'minus'"></fa-icon></span>
            </span>
          </div>

          <div class="panel-body">
            <esk-select-template-thumbnails codes="$ctrl.options.affichette.codesTemplate"
                                            ng-model="$ctrl.options.affichette.template"
                                            groups="$ctrl.options.affichette.groups"
                                            options="$ctrl.options.affichette">
            </esk-select-template-thumbnails>
          </div>

          <div class="panel-footer text-right">
            <ng-switch on="$ctrl.options.affichette.loading">
              <p ng-switch-when="true">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
              </p>

              <div ng-switch-default class="btn-group" uib-dropdown>
                <button type="button" class="btn btn-azure btn-o" ng-click="$ctrl.print('affichette')">
                  <fa-icon [icon]="'print'"></fa-icon> {{'global.label.PRINT' | translate}}

                  {{ $ctrl.options.affichette.template.size + " " +
                $ctrl.options.affichette.template.orientation + " - " +
                $ctrl.options.affichette.template.name }}
                </button>

                <button type="button" class="btn btn-azure btn-o" uib-dropdown-toggle>
                  <span class="caret"></span>
                </button>

                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                  <li role="menuitem">
                    <button type="button" class="tw-dropdown-link" ng-click="$ctrl.send('affichette')">
                      <fa-icon [icon]="'envelope'"></fa-icon><span>{{'global.label.SEND' | translate}}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </ng-switch>
          </div>
        </div>
      </div>

      <!-- Fiches -->
      <div ng-class="$ctrl.options.fiche.collapsed ? 'col-lg-4 col-md-5': 'col-md-5'">
        <div class="panel panel-white" ng-class="$ctrl.options.fiche.collapsed ? 'collapsed' : ''">
          <div class="panel-heading border-light">
            <h4 class="panel-title tw-text-center"><span class="text-bold">{{"Fiche" | translate}}</span></h4>

            <span class="collapse-toggler"
                  ng-switch="$ctrl.options.fiche.collapsed"
                  ng-click="$ctrl.options.fiche.collapsed = !$ctrl.options.fiche.collapsed">
              <span ng-switch-when="true">
                {{"Choix d'impression" | translate}} <fa-icon [icon]="'plus'"></fa-icon>
              </span>

              <span ng-switch-default><fa-icon [icon]="'minus'"></fa-icon></span>
            </span>
          </div>

          <div class="panel-body">
            <esk-select-template-thumbnails codes="$ctrl.options.fiche.codesTemplate"
                                            ng-model="$ctrl.options.fiche.template"
                                            col-class="col-xs-6 col-sm-4" options="$ctrl.options.fiche">
            </esk-select-template-thumbnails>
          </div>

          <div class="panel-footer text-right">
            <ng-switch on="$ctrl.options.fiche.loading">
              <p ng-switch-when="true">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
              </p>

              <div ng-switch-default class="btn-group" uib-dropdown>
                <button type="button" class="btn btn-azure btn-o" ng-click="$ctrl.print('fiche')">
                  <fa-icon [icon]="'print'"></fa-icon> {{'global.label.PRINT' | translate}}

                  {{ $ctrl.options.fiche.template.size + " " + $ctrl.options.fiche.template.orientation + " - " +
                  $ctrl.options.fiche.template.name }}
                </button>

                <button type="button" class="btn btn-azure btn-o" uib-dropdown-toggle>
                  <span class="caret"></span>
                </button>

                <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                  <li role="menuitem">
                    <button type="button" class="tw-dropdown-link" ng-click="$ctrl.send('fiche')">
                      <fa-icon [icon]="'envelope'"></fa-icon><span>{{'global.label.SEND' | translate}}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </ng-switch>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Diffusions -->
  <ng-switch on="$ctrl.acceptedStatus.indexOf($ctrl.dossier.statut) > -1">
    <ng-switch on="$ctrl.loading" ng-switch-when="true">
      <div ng-switch-default ng-if="($ctrl.diffusions | filter:{type: diffusionType.type}).length > 0"
           ng-repeat="diffusionType in $ctrl.diffusionTypes">
        <div class="container-fluid container-fullw tw-bg-white margin-bottom-30">
          <h4 class="margin-bottom-30">
            <span class="text-bold">{{diffusionType.title}}</span>

            ({{($ctrl.diffusions | filter:{type: diffusionType.type}).length}})

            <button ng-show="$ctrl.statusToDiffuse.indexOf($ctrl.dossier.statut) > -1 && $ctrl.visibilityDiffuseAll(diffusionType.type)"
                    type="button" class="btn btn-green btn-sm pull-right"
                    ng-click="$ctrl.diffuseAll(diffusionType.type)">Diffuser sur tous</button>
          </h4>

          <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-4 md:tw-gap-8">
            <div class="list-diffusion" ng-repeat="diffusion in ($ctrl.diffusions | filter:{type: diffusionType.type})">
              <div ng-include="'src/app/legacy/templates/biens/components/item/diffusion/details.html'"
                   class="tw-h-full"></div>
            </div>
          </div>
        </div>
      </div>

      <div ng-switch-when="true">
        <p class="tw-text-center text-extra-large">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des diffusions
        </p>
      </div>
    </ng-switch>

    <div ng-switch-default class="container-fluid container-fullw tw-bg-white">
      <h4 ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE">
        <fa-icon [icon]="'exclamation-triangle'"></fa-icon> Vous devez saisir une
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.dossier.id + '/mandats')">date de mandat</a>
        afin de pouvoir accéder à la diffusion de votre bien à vendre.
      </h4>

      <h4 ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION">
        <fa-icon [icon]="'exclamation-triangle'"></fa-icon> Vous devez préciser la
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/locations/' + $ctrl.dossier.id + '/gestion')">disponibilité</a>
        de votre bien en location.
      </h4>
    </div>
  </ng-switch>
</div>
`;
