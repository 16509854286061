@if (cReferences$ | async; as cReferences) {
  <div class="tw-overflow-hidden">
    <table [appCollectionSelection]="{collection: cReferences, listName: options.nameSelection!, options: {filterEnabled: options.filterEnabled, initSelectAll: options.initSelectAll, titleDisabled: options.titleDisabled}}"
           id="estimationReferences" class="tw-table-head-separate-body">
      <thead>
      <tr>
        <th [appCollectionColumnSort]="options.tris![CREFERENCES_TRIS.DATE_VENTE]" class="tw-w-32 2xl:tw-w-36"
            (changed)="changedTri.emit([CREFERENCES_TRIS.DATE_VENTE, $event])">
          <div>
            <span class="2xl:tw-hidden">Date vente</span> <span class="tw-hidden 2xl:tw-inline">Date de vente</span>
          </div>
        </th>

        @if (options.enabledColumnAdresse) {
          <th><div>Adresse</div></th>
        }

        @if (options.enabledColumnCommune) {
          <th class="tw-hidden lg:tw-table-cell"><div>Commune</div></th>
        }

        @if (options.enabledColumnLocalisation) {
          <th><div>Adresse</div></th>
        }

        @if (options.enabledColumnDistance) {
          <th [appCollectionColumnSort]="options.tris![CREFERENCES_TRIS.DISTANCE]"
              class="tw-hidden lg:tw-table-cell tw-w-28 2xl:tw-w-32 tw-text-right"
              (changed)="changedTri.emit([CREFERENCES_TRIS.DISTANCE, $event])"><div>Distance</div></th>
        }

        <th class="tw-w-32 2xl:tw-w-36 tw-text-right">
          <div>
            <span class="2xl:tw-hidden">{{ superficieLabel.replace('Surface', 'Surf.') }}</span>
            <span class="tw-hidden 2xl:tw-inline">{{ superficieLabel }}</span>
          </div>
        </th>

        <th class="tw-hidden 2xl:tw-table-cell tw-w-40 tw-text-right">
          <div>
            <span class="2xl:tw-hidden">Surf. cadastrale</span>
            <span class="tw-hidden 2xl:tw-inline">Surface cadastrale</span>
          </div>
        </th>

        <th class="tw-hidden sm:tw-table-cell tw-w-32 2xl:tw-w-36 tw-text-right"><div>Prix</div></th>

        @if (options.enabledColumnPrixM2) {
          <th class="tw-hidden lg:tw-table-cell tw-w-28 2xl:tw-w-32 tw-text-right">
            <div><span class="2xl:tw-hidden">Prix m²</span><span class="tw-hidden 2xl:tw-inline">Prix au m²</span></div>
          </th>
        }

        <th class="tw-w-16 lg:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
      </tr>
      </thead>

      <tbody>
        @for (reference of cReferences.results; track reference) {
          <tr [ngClass]="isEnabled(reference) ? '' : 'tw-opacity-75'">
            <td>{{ reference.dateVente | date }}</td>

            @if (options.enabledColumnAdresse) {
              <td>{{ reference.adresse }}</td>
            }

            @if (options.enabledColumnCommune) {
              <td class="tw-hidden lg:tw-table-cell">
                <app-commune-convivial-name [linkCommune]="reference.linkCommune"/>
              </td>
            }

            @if (options.enabledColumnLocalisation) {
              <td>
                {{ reference.adresse }}
                <app-commune-convivial-name [linkCommune]="reference.linkCommune" class="tw-block tw-text-gray-400"/>
              </td>
            }

            @if (options.enabledColumnDistance) {
              <td class="tw-hidden lg:tw-table-cell tw-text-right">
                {{ reference.distance > 0 ? (reference.distance | textCurrency: 'm') : null }}
              </td>
            }

            <td class="tw-text-right">{{ reference.superficie | textCurrency:'m²' }}</td>

            <td class="tw-hidden 2xl:tw-table-cell tw-text-right">
              {{ reference.surfaceCadastrale | textCurrency:'m²' }}
            </td>

            <td class="tw-hidden sm:tw-table-cell tw-text-right">{{ reference.prixVente | textCurrency }}</td>

            @if (options.enabledColumnPrixM2) {
              <td class="tw-hidden lg:tw-table-cell tw-text-right">
                {{ reference.prixM2 > 0 ? (reference.prixM2 | textCurrency) : null }}
              </td>
            }

            <td class="tw-table-head-separate-body-actions">
              <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                      #buttonElement (click)="clickOnReference(buttonElement, reference)">
                <fa-icon [icon]="'ellipsis-v'"/>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <app-load-more [collection$]="cReferences$" (loadMore)="getMore(cReferences)" [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
