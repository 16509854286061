import {NgModule} from '@angular/core';
import {
    NgEskimmoFooterComponent, NgEskimmoFooterDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo.footer.component';
import {
    NgEskimmoDossierSuiviComponent, NgEskimmoDossierSuiviDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo.dossier-suivi.component';
import {
    NgEskimmoContactComponent, NgEskimmoContactDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo.contact.component';
import {
    NgEskimmoButtonSaveComponent, NgEskimmoButtonSaveDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo.button-save.component';
import {
    NgEskimmoUploadFilesInfoComponent, NgEskimmoUploadFilesInfoDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo.upload-files-info.component';
import {
    NgEskimmoBrowserComponent, NgEskimmoBrowserDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-browser.component';
import {
    NgEskimmoConfigurationPasserellesComponent, NgEskimmoConfigurationPasserellesDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-configuration-passerelles.component';
import {
    NgEskimmoDashboardComponent, NgEskimmoDashboardDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dashboard.component';
import {
    NgEskimmoDocumentsAdvertisementComponent, NgEskimmoDocumentsAdvertisementDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-documents-advertisement.component';
import {
    NgEskimmoDocumentsBlankComponent, NgEskimmoDocumentsBlankDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-documents-blank.component';
import {
    NgEskimmoDossierSuiviEmailComponent, NgEskimmoDossierSuiviEmailDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-email.component';
import {
    NgEskimmoDossierSuiviReportComponent, NgEskimmoDossierSuiviReportDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-report.component';
import {
    NgEskimmoMyAccountComponent, NgEskimmoMyAccountDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-my-account.component';
import {
    NgEskimmoNegociationRevenusComponent, NgEskimmoNegociationRevenusDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-negociation-revenus.component';
import {
    NgEskimmoTaskRunnerCompComponent, NgEskimmoTaskRunnerCompDirective
} from '@shared/angularJS/up-ng/eskimmo/eskimmo.task-runner.components';

const components = [
    NgEskimmoBrowserComponent,
    NgEskimmoButtonSaveComponent,
    NgEskimmoConfigurationPasserellesComponent,
    NgEskimmoContactComponent,
    NgEskimmoDashboardComponent,
    NgEskimmoDocumentsAdvertisementComponent,
    NgEskimmoDocumentsBlankComponent,
    NgEskimmoDossierSuiviComponent,
    NgEskimmoDossierSuiviEmailComponent,
    NgEskimmoDossierSuiviReportComponent,
    NgEskimmoFooterComponent,
    NgEskimmoMyAccountComponent,
    NgEskimmoNegociationRevenusComponent,
    NgEskimmoTaskRunnerCompComponent,
    NgEskimmoUploadFilesInfoComponent,
];
const directives = [
    NgEskimmoBrowserDirective,
    NgEskimmoButtonSaveDirective,
    NgEskimmoConfigurationPasserellesDirective,
    NgEskimmoContactDirective,
    NgEskimmoDashboardDirective,
    NgEskimmoDocumentsAdvertisementDirective,
    NgEskimmoDocumentsBlankDirective,
    NgEskimmoDossierSuiviDirective,
    NgEskimmoDossierSuiviEmailDirective,
    NgEskimmoDossierSuiviReportDirective,
    NgEskimmoFooterDirective,
    NgEskimmoMyAccountDirective,
    NgEskimmoNegociationRevenusDirective,
    NgEskimmoTaskRunnerCompDirective,
    NgEskimmoUploadFilesInfoDirective,
];

@NgModule({declarations: [...components, ...directives], exports: [...components]})
export class EskimmoAppComponentsModule {
}
