@if (procedureAcquereur$ | async; as procedureAcquereur) {
  <form class="tw-form-2col" (submit)="save()" #signatairesForm="ngForm">
    <div class="tw-form-2col-part">
      <div class="tw-form-left-col">
        <h3 class="tw-form-left-col-title">Acquéreurs</h3>

        <p class="tw-form-left-col-explain">
          L'offre d'achat sera mise à disposition des acquéreurs pour qu'ils puissent la signer avant remise de
          celle-ci signée aux vendeurs.
        </p>
      </div>

      <div class="tw-form-right-col">
        <app-procedure-signataires-edit [cSignataires$]="cProcedureSignatairesAcquereurs$" class="tw-block tw-pt-4"
                                        [options]="procedureSignatairesAcquereurEditOptions"
                                        [currentDossier]="currentDossier" [procedure]="procedureAcquereur"
                                        [linkDossiers]="[offreachat.linkDemandeur, offreachat.linkVente]"
                                        (editedSignataires)="editedSignatairesAcquereur = $event"
                                        (deletedSignataires)="deletedSignatairesAcquereur = $event"/>
      </div>
    </div>

    @if (procedureVendeur$ | async; as procedureVendeur) {
      <div class="tw-form-2col-part">
        <div class="tw-form-left-col">
          <h3 class="tw-form-left-col-title">Vendeurs</h3>

          <p class="tw-form-left-col-explain">
            Suite à la signature des acquéreurs, l'offre d'achat sera mise à disposition des vendeurs.
          </p>
        </div>

        <div class="tw-form-right-col">
          <div class="tw-flex tw-items-start">
            <div class="tw-flex tw-items-center tw-h-5 tw-mr-3">
              <input class="tw-input-checkbox" id="checkComments" name="checkComments" type="checkbox"
                     [(ngModel)]="hasSignatairesVendeurs"/>
            </div>

            <label for="checkComments" class="tw-text-sm tw-text-gray-600 tw-cursor-pointer">
              Saisir les signataires vendeurs
            </label>
          </div>

          @if (hasSignatairesVendeurs) {
            <app-procedure-signataires-edit [cSignataires$]="cProcedureSignatairesVendeurs$" class="tw-block tw-pt-4"
                                            [options]="procedureSignatairesVendeurEditOptions"
                                            [currentDossier]="currentDossier" [procedure]="procedureVendeur"
                                            [linkDossiers]="[offreachat.linkDemandeur, offreachat.linkVente]"
                                            (editedSignataires)="editedSignatairesVendeur = $event"
                                            (deletedSignataires)="deletedSignatairesVendeur = $event"/>
          } @else {
            <div class="tw-my-4 sm:tw-my-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
              <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
                Cette offre d'achat ne sera signée par aucun signataire vendeur.
              </p>
            </div>
          }
        </div>
      </div>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
    }

    <button type="submit" class="tw-float-left" #signatairesButtonSubmit></button>
  </form>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
