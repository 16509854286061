import {inject, Injectable} from '@angular/core';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {Observable} from 'rxjs';
import {PasserelleFactory} from '@models/passerelle/passerelle.factory';
import {map, tap} from 'rxjs/operators';
import {EtudePasserellesApiService} from '@models/etudes/etude/passerelles/etude-passerelles.api.service';
import {
    IEtudePasserelleApi, IEtudePasserelleConfigureApi
} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.interfaces';
import Etude from '@models/etudes/etude/etude.model';

@Injectable({providedIn: 'root'})
export class EtudePasserelleFactory {
    private _etudePasserellesApiService = inject(EtudePasserellesApiService);
    private _passerelleFactory = inject(PasserelleFactory);

    configure$(etude: Etude, etudePasserelle: EtudePasserelle, name: string, parameters: unknown = {}): Observable<IEtudePasserelleConfigureApi> {
        return this._etudePasserellesApiService.configure$(etude.id.toString(), etudePasserelle.uuid, {name, parameters}).pipe(
            tap(response => etudePasserelle.statut = response.name),
        );
    }

    create(etudePasserelleApi: IEtudePasserelleApi): EtudePasserelle {
        const etudePasserelle = new EtudePasserelle(etudePasserelleApi._embedded?.passerelle?.id.toString());

        etudePasserelle.biensMaxNumber = etudePasserelleApi.biensMaxNumber;
        etudePasserelle.configurator = etudePasserelleApi.configurator;
        etudePasserelle.nbBiensActifsEnvoyes = etudePasserelleApi.nbBiensActifsEnvoyes;
        etudePasserelle.nbLocationsActivesEnvoyees = etudePasserelleApi.nbLocationsActivesEnvoyees;
        etudePasserelle.nbVentesActivesEnvoyees = etudePasserelleApi.nbVentesActivesEnvoyees;
        etudePasserelle.statut = etudePasserelleApi.statut;

        if (etudePasserelleApi._embedded?.passerelle) {
            etudePasserelle.passerelle = this._passerelleFactory.create(etudePasserelleApi._embedded.passerelle);
        }

        return etudePasserelle;
    }

    diffuse$(etude: Etude, etudePasserelle: EtudePasserelle, bienIds: number[]): Observable<void> {
        return this._etudePasserellesApiService.diffuse$(etudePasserelle.passerelle.id.toString(), {
            bienIds: bienIds.join(','),
            etudeId: etude.id,
        });
    }

    get$(uuid: string): Observable<EtudePasserelle> {
        return this._etudePasserellesApiService.get$(uuid).pipe(map(etudePasserelleApi => this.create(etudePasserelleApi)));
    }

    getByLink$(link: string): Observable<EtudePasserelle> {
        return this._etudePasserellesApiService.getByLink$(link).pipe(map(etudePasserelleApi => this.create(etudePasserelleApi)));
    }
}
