import angularJS from '@shared/angularJS/global.ng';
import {filter as lFilter, min} from 'lodash';
import {IModule, IQService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgDictionariesManager, NgMediaManager, NgPhotoManager, NgUserPhotoManager} from '@legacy/app/managers/managers';
import {UserSettingsFactory} from '@models/users/user/settings/user-settings.factory';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {NgItemDictionary, NgMedia, NgTask, NgUserPhoto} from '@legacy/app/managers/ressources';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import {firstValueFrom, Subject} from 'rxjs';
import {UserPhotoFactory} from '@models/users/user/photo/user-photo.factory';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import Phone from '@models/phones/phone/phone.model';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {PhoneFactory} from '@models/phones/phone/phone.factory';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {DCUserSlideOverComponent} from '@features/dynamic-components/user-slide-over/dc-user.slide-over.component';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {IMG_MAX_SIZE} from '@shared/image/image.constants';
import UserSettings from '@models/users/user/settings/user-settings.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import Site from '@models/sites/site/site.model';

export default function getEskMyAccountCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskMyAccountCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/my-account.html',
        });

        /**
         * Controller of my account
         *
         * @param SoqModalesManager
         * @param MediaManager
         * @param EskTaskRunner
         * @param Ng2RouterStateForNgService
         * @param PhotoManager
         * @param UserPhotoManagers
         * @param $q
         * @param $translate
         * @param SoqSweetAlert
         * @param DictionariesManager
         * @param Ng2UserSettingsFactory
         * @param Ng2PhoneNumberService
         * @param Ng2EtudeService
         * @param Ng2UserPhotoFactory
         * @param Ng2UserService
         * @param Ng2DictionaryItemFactory
         * @param Ng2ErrorConfigurationService
         * @param Ng2PhoneFactory
         * @param Ng2DictionaryFactory
         * @param Ng2SlideOverService
         * @param Ng2CSitesService
         */
        Controller.$inject = ['SoqModalesManager', 'MediaManager', 'EskTaskRunner', 'Ng2RouterStateForNgService', 'PhotoManager', 'UserPhotoManager', '$q', '$translate', 'SoqSweetAlert', 'DictionariesManager', 'Ng2UserSettingsFactory', 'Ng2PhoneNumberService', 'Ng2EtudeService', 'Ng2UserPhotoFactory', 'Ng2UserService', 'Ng2DictionaryItemFactory', 'Ng2ErrorConfigurationService', 'Ng2PhoneFactory', 'Ng2DictionaryFactory', 'Ng2SlideOverService', 'Ng2CSitesService'];
        function Controller(this: any,
                            soqModalesManager: NgSoqModalesManager,
                            mediaManager: NgMediaManager,
                            eskTaskRunner: NgEskTaskRunner,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            photoManager: NgPhotoManager,
                            userPhotoManager: NgUserPhotoManager,
                            $q: IQService,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            dictionariesManager: NgDictionariesManager,
                            ng2UserSettingsFactory: UserSettingsFactory,
                            ng2PhoneNumberService: PhoneNumberService,
                            ng2EtudeService: EtudeService,
                            ng2UserPhotoFactory: UserPhotoFactory,
                            ng2UserService: UserService,
                            ng2DictionaryItemFactory: DictionaryItemFactory,
                            ng2ErrorConfigurationService: ErrorConfigurationService,
                            ng2PhoneFactory: PhoneFactory,
                            ng2DictionaryFactory: DictionaryFactory,
                            ng2SlideOverService: SlideOverService,
                            ng2CSitesService: CSitesService) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();
            let etude: Etude;
            let sitePrincipal: Site;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.ng2PhoneNumberService = ng2PhoneNumberService;
            $ctrl.changePassword = changePassword;
            $ctrl.getInputPhoto = getInputPhoto;
            $ctrl.getSettingsDiffusionsShortLabel = getSettingsDiffusionsShortLabel;
            $ctrl.editPhoto = editPhoto;
            $ctrl.onSelectTitre = onSelectTitre;
            $ctrl.removePhoto = removePhoto;
            $ctrl.removeTask = removeTask;
            $ctrl.saveChange = saveChange;
            $ctrl.saveDiffusions = saveDiffusions;
            $ctrl.seeMyProfile = seeMyProfile;
            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.phoneSources = UserSettings.phoneSources;
            $ctrl.settingsDiffusionsLoader = true;
            $ctrl.diffusionsPhoneSources = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.DIFFUSION_PHONE_SOURCES));
            ng2CSitesService.current$.pipe(filter(cSites => !!cSites), map(cSites => cSites.results[0]), take(1)).subscribe(site => sitePrincipal = site);
            ng2EtudeService.last$.subscribe(currentEtude => etude = currentEtude);
            ng2UserService.current$.pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => $ctrl.infoClientFilter = {type: 'photo_profil', idUser: currentUser.id}),
                tap(currentUser => {
                    if (currentUser.hasRoleGrpLocation() || currentUser.hasRoleGrpVente()) {
                        $ctrl.settingsDiffusionsLoader = false;
                        $ctrl.userSettings = currentUser.settings;
                        $ctrl.userSettingsDiffusionsTelephoneSourceCode = $ctrl.userSettings.diffusions.telephoneSource.code;
                    }
                }),
                tap(currentUser => {
                    let phone = currentUser.cPhones.getByType(Phone.types.FIXE);

                    if (!phone) {
                        phone = ng2PhoneFactory.createVirgin();
                        phone.sortableRank = currentUser.cPhones.total + 1;
                        phone.type = Phone.types.FIXE;
                        currentUser.cPhones.addResult(phone);
                    }

                    $ctrl.phoneFixe = phone;
                }),
                tap(currentUser => {
                    let phone = currentUser.cPhones.getByType(Phone.types.MOBILE);

                    if (!phone) {
                        phone = ng2PhoneFactory.createVirgin();
                        phone.sortableRank = currentUser.cPhones.total + 1;
                        phone.type = Phone.types.MOBILE;
                        currentUser.cPhones.addResult(phone);
                    }

                    $ctrl.phoneMobile = phone;
                }),
                tap(currentUser => $ctrl.titre = dictionariesManager.createFromNg2(currentUser.titre)),
                takeUntil(_onDestroy$),
            ).subscribe(currentUser => $ctrl.currentUser = currentUser);
            $ctrl.TITRES = Dictionary.names.TITRES;
            angular.forEach(lFilter(eskTaskRunner.getTasks(), {infoClient: $ctrl.infoClientFilter}), function (task) {
                $ctrl.task = task;
                $ctrl.task.promise.finally(function () {
                    // @MEC Conserver cet ordre car, si on quitte la vue et qu'on revient, la liste des tâches ne se met pas à jour, pas le même contexte pour eskUploadFilesInfo.
                    // @MEC De plus, il faut laisser cette méthode s'exécuter tel quelle, sinon elle n'est pas correctement initialisée.
                    $ctrl.updateTasks();
                    $ctrl.task = undefined;
                });
            });

            /**
             *  Save user changement
             */
            function saveChange() {
                if (!$ctrl.editForm.$valid
                    || ($ctrl.phoneFixe.numero && !$ctrl.ng2PhoneNumberService.isValid($ctrl.phoneFixe.numero))
                    || ($ctrl.phoneMobile.numero && !$ctrl.ng2PhoneNumberService.isValid($ctrl.phoneMobile.numero))) {
                    soqSweetAlert.warningMessage($translate.instant('formulaire.invalid.TITLE'), $translate.instant('formulaire.invalid.MESSAGE'));

                    return;
                }

                $ctrl.saving = true;
                ng2UserService.saveCurrent$().pipe(
                    tap(currentUser => ng2ErrorConfigurationService.setUser(currentUser, etude)),
                    take(1),
                ).subscribe({complete: () => $ctrl.saving = false});
            }

            /**
             * Change password
             */
            function changePassword() {
                soqModalesManager.open('EskModalChangePassword');
            }

            /**
             * Get photo and upload it
             *
             * @param file
             */
            function getInputPhoto(file: { desktopFile: File; height: number; width: number }) {
                const media = mediaManager.create({file: file.desktopFile, height: file.height, width: file.width});
                const smallerSide = min([media.height, media.width])!;

                $ctrl.task = {};
                if (smallerSide < 300) {
                    setErrorTask('files.process.small_dimensions.MESSAGE');
                } else if (media.file.size >= IMG_MAX_SIZE) {
                    setErrorTask('files.process.over_weight.MESSAGE');
                } else {
                    media.file._esk = {status: 'WAITING'};
                    $ctrl.task = eskTaskRunner.addCallbackTask({
                        method: media.upload,
                        context: media,
                        then: (media: NgMedia) => {
                            const promise = $ctrl.currentUser.isAvatarPhoto ? $q.resolve() : firstValueFrom(removePhoto$());

                            return promise.then(function () {
                                const photo = photoManager.create({media: {id: media.id}});

                                return photo.save().then(function () {
                                    const x = (media.width - smallerSide) / 2;
                                    const y = (media.height - smallerSide) / 2;
                                    const userPhoto = userPhotoManager.create({
                                        photo: {id: media.id},
                                        topLeftCoordinates: [x, y],
                                        bottomRightCoordinates: [x + smallerSide, y + smallerSide]
                                    }, $ctrl.currentUser.id) as NgUserPhoto;

                                    return userPhoto.save().then(() => {
                                        $ctrl.currentUser.photo = ng2UserPhotoFactory.ngCreate(userPhoto);
                                        $ctrl.currentUser.setLinkPhoto(userPhoto._links.file.href);
                                    });
                                });
                            });
                        }
                    }, 1, {
                        type: 'photo_profil',
                        idUser: $ctrl.currentUser.id,
                        uisref: {
                            name: ng2RouterStateForNgService.current.name,
                            params: ng2RouterStateForNgService.current.params,
                        },
                        title: media.file.name,
                        media: media
                    }, {
                        method: media.cancelUpload,
                        context: media
                    });
                    $ctrl.updateTasks();
                }

                $ctrl.task.promise = $ctrl.task.promise.catch($ctrl.updateTasksErrors).finally(function () {
                    $ctrl.updateTasks();
                    $ctrl.task = undefined;
                });

                /**
                 * Set error to current task
                 *
                 * @param message
                 */
                function setErrorTask(message: string) {
                    $ctrl.task = eskTaskRunner.createTasks(undefined!, undefined!, {
                        type: 'photo_profil',
                        idUser: $ctrl.currentUser.id,
                        media: media
                    });
                    $ctrl.task.deferred.reject({
                        status: 'ERROR',
                        data: {title: $translate.instant(message, {type: 'photo'})}
                    });
                }
            }

            /**
             * Remove task
             *
             * @param task
             */
            function removeTask(task: NgTask) {
                eskTaskRunner.removeTask(task);
                task.promise.finally(function () {
                    $ctrl.updateTasks();
                    $ctrl.updateTasksErrors();
                });
                $ctrl.updateTasks();
            }

            /**
             * Open modale to edit photo
             */
            function editPhoto() {
                const userPhoto = userPhotoManager.createFromNg2($ctrl.currentUser.photo, $ctrl.currentUser.linkPhoto);

                soqModalesManager.open('EskModalEditPhoto', {resolve: {photo: userPhoto}}).then(() => {
                    $ctrl.currentUser.photo.bottomRightCoordinates = userPhoto.bottomRightCoordinates;
                    $ctrl.currentUser.photo.topLeftCoordinates = userPhoto.topLeftCoordinates;
                    $ctrl.currentUser.setLinkPhoto($ctrl.currentUser.linkPhoto);
                });
            }

            /**
             * Get settings diffusions short label
             */
            function getSettingsDiffusionsShortLabel(diffusionsPhoneSource: NgItemDictionary) {
                if (diffusionsPhoneSource.code === UserSettings.phoneSources.AUTO) {
                    return $ctrl.currentUser.getPhoneFixe() || $ctrl.currentUser.getPhoneMobile() || $ctrl.currentUser.site.telephone?.numero || sitePrincipal?.telephone?.numero;
                } else if (diffusionsPhoneSource.code === UserSettings.phoneSources.UTILISATEUR_FIXE) {
                    return $ctrl.currentUser.getPhoneFixe();
                } else if (diffusionsPhoneSource.code === UserSettings.phoneSources.UTILISATEUR_MOBILE) {
                    return $ctrl.currentUser.getPhoneMobile();
                } else if (diffusionsPhoneSource.code === UserSettings.phoneSources.SITE_UTILISATEUR_FIXE) {
                    return $ctrl.currentUser.site.telephone?.numero;
                } else if (diffusionsPhoneSource.code === UserSettings.phoneSources.SITE_PRINCIPAL_FIXE) {
                    return sitePrincipal.telephone?.numero;
                }
            }

            /**
             * Remove user photo
             */
            function onSelectTitre() {
                $ctrl.currentUser.titre = $ctrl.titre ? ng2DictionaryItemFactory.ngCreate($ctrl.titre) : undefined;
            }

            /**
             * Remove user photo
             */
            function removePhoto() {
                removePhoto$().subscribe();
            }

            /**
             * Remove user photo
             */
            function removePhoto$() {
                $ctrl.deletingPhoto = true;

                return ng2UserService.removeCurrentPhoto$().pipe(tap(_ => $ctrl.deletingPhoto = false));
            }

            /**
             * Remove user photo
             */
            function saveDiffusions(diffusionsPhoneSource: NgItemDictionary) {
                $ctrl.userSettings.diffusions.telephoneSource = diffusionsPhoneSource;
                if ($ctrl.userSettings.diffusions.telephoneSource.code === UserSettings.phoneSources.AUTRE
                    && !ng2PhoneNumberService.isValid($ctrl.userSettings.diffusions.telephoneAutre)) {
                    return;
                }

                ng2UserSettingsFactory.save$($ctrl.currentUser, $ctrl.userSettings).pipe(take(1)).subscribe();
            }

            function seeMyProfile() {
                ng2SlideOverService.open$(DCUserSlideOverComponent, {user: $ctrl.currentUser});
            }
        }
    })(angularJS);
}
