import {Component, Input} from '@angular/core';
import Email from '@models/emails/email/email.model';
import {IEmailStatutOptions} from '@features/emails/emails.interfaces';

@Component({selector: 'app-email-statut', templateUrl: 'email.statut.component.html'})
export class AppEmailStatutComponent {
    static readonly initEmailStatutOptions: IEmailStatutOptions = {useShortLabel: false};
    private _email!: Email;
    private _options: IEmailStatutOptions = {...AppEmailStatutComponent.initEmailStatutOptions};

    get email(): Email {
        return this._email;
    }

    @Input()
    set email(value: Email) {
        this._email = value;
    }

    get options(): IEmailStatutOptions {
        return this._options;
    }

    @Input()
    set options(value: IEmailStatutOptions) {
        this._options = {...AppEmailStatutComponent.initEmailStatutOptions, ...value};
    }
}
