<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Descriptifs</h4>

  <div class="tw-bg-red-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (dossierBien.bien.descriptifMandat) {
        <li>
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Mandat</div> {{ dossierBien.bien.descriptifMandat }}
        </li>
      }

      @if (dossierBien.bien.descriptifAffichette) {
        <li>
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Affichette</div>
          {{ dossierBien.bien.descriptifAffichette }}
        </li>
      }

      @if (dossierBien.bien.descriptifSiteInternet) {
        <li>
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Sites Internet</div>
          {{ dossierBien.bien.descriptifSiteInternet }}
        </li>
      }

      @if (dossierBien.bien.descriptifJournalPapier) {
        <li>
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Journaux Papier</div>
          {{ dossierBien.bien.descriptifJournalPapier }}
        </li>
      }
    </ul>
  </div>
</div>
