import {Injectable} from '@angular/core';
import IInjectorService = angular.auto.IInjectorService;

@Injectable({providedIn: 'root'})
export class NgInjectorService {
    private _injector!: IInjectorService;

    set injector(value: IInjectorService) {
        this._injector = value;
    }

    getService<T>(serviceName: string): T {
        return this._injector.get<T>(serviceName);
    }
}
