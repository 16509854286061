import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject, switchMap} from 'rxjs';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import {IProcedureSignatairesEditOptions} from '@features/procedures/procedures.interfaces';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import Participant from '@models/participants/participant/participant.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {
    ProcedureSignataireDropdownComponent
} from '@features/procedures/procedure/signataires/signataire/dropdown/procedure-signataire.dropdown.component';
import {ToasterService} from '@shared/toaster/toaster.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {UserService} from '@models/users/user/user.service';
import {ParticipantFactory} from '@models/participants/participant/participant.factory';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {DossiersService} from '@models/dossiers/dossiers.service';

@Component({selector: 'app-procedure-signataires-edit', templateUrl: 'procedure-signataires.edit.component.html'})
export class ProcedureSignatairesEditComponent implements OnDestroy, OnInit {
    static readonly initProcedureSignatairesEditOptions: IProcedureSignatairesEditOptions = {
        enabledActions: {copyLinkInterface: false, remove: true, sendLinkInterface: false},
        enabledEmail: true,
        enabledPhone: true,
        enabledSignatairesAdding: true,
        enabledStatut: true,
        tableId: 'procedure-signataires-edit',
    };
    static messages = {
        copyLink: {
            MESSAGE: 'Le lien pour accéder à l\'interface de signature est accessible avec un "CTRL+V" ou clic droit "Coller".',
            TITLE: 'Lien copié dans le presse-papier',
        },
        NO_SIGNATAIRES: 'Au moins un signataire est requis pour lancer une procédure de signature électronique de votre document.',
    };
    readonly deletedSignataires = output<ProcedureSignataire[]>();
    readonly editedSignataires = output<ProcedureSignataire[]>();
    private _dossiersService = inject(DossiersService);
    private _dropdownService = inject(DropdownService);
    private _emailEditService = inject(EmailEditService);
    private _participantFactory = inject(ParticipantFactory);
    private _procedureService = inject(ProcedureService);
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);
    private _toasterService = inject(ToasterService);
    private _userService = inject(UserService);
    private _amSignataire = false;
    private _cSignataires$!: Observable<CProcedureSignataires>;
    private _currentDossier!: ADossier;
    private _deletedSignatairesSet = new Set<ProcedureSignataire>();
    private _editedSignatairesSet = new Set<ProcedureSignataire>();
    private _linkDossiers!: string[];
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IProcedureSignatairesEditOptions = {...ProcedureSignatairesEditComponent.initProcedureSignatairesEditOptions};
    private _procedure!: Procedure;
    private _showDropdown = false;
    private _showRemove = true;

    get amSignataire(): boolean {
        return this._amSignataire;
    }

    get cSignataires$(): Observable<CProcedureSignataires> {
        return this._cSignataires$;
    }

    @Input()
    set cSignataires$(value: Observable<CProcedureSignataires>) {
        this._cSignataires$ = value.pipe(
            tap(_ => this._deletedSignatairesSet.clear()),
            tap(_ => this._editedSignatairesSet.clear()),
            tap(cSignataires => this.emitSignataires(cSignataires)),
            tap(cSignataires => cSignataires?.results.filter(signataire => signataire.isNew()).forEach(signataire => this.edit(cSignataires, signataire))),
            tap(cSignataires => this.updateAmSignataire(cSignataires)),
        );
    }

    @Input()
    set currentDossier(value: ADossier) {
        this._currentDossier = value;
    }

    @Input()
    set linkDossiers(value: string[]) {
        this._linkDossiers = value;
    }

    get NO_SIGNATAIRES(): string {
        return ProcedureSignatairesEditComponent.messages.NO_SIGNATAIRES;
    }

    get options(): IProcedureSignatairesEditOptions {
        return this._options;
    }

    @Input()
    set options(value: IProcedureSignatairesEditOptions) {
        this._options = {...ProcedureSignatairesEditComponent.initProcedureSignatairesEditOptions, ...value};
        if (this._options.enabledActions) {
            let numberOptions = 0;

            if (this._options.enabledActions.copyLinkInterface) {
                numberOptions++;
            }

            if (this._options.enabledActions.remove) {
                numberOptions++;
            }

            if (this._options.enabledActions.sendLinkInterface) {
                numberOptions++;
            }

            this._showDropdown = numberOptions > 1;
            this._showRemove = !this._showDropdown && !!this._options.enabledActions.remove;
        } else {
            this._showDropdown = false;
            this._showRemove = false;
        }
    }

    get procedure(): Procedure {
        return this._procedure;
    }

    @Input()
    set procedure(value: Procedure) {
        this._procedure = value;
    }

    get showDropdown(): boolean {
        return this._showDropdown;
    }

    get showRemove(): boolean {
        return this._showRemove;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => {
            const value = dropdownClicked.value as { cProcedureSignataires: CProcedureSignataires; procedureSignataire: ProcedureSignataire };

            if (dropdownClicked.action === ProcedureSignataireDropdownComponent.actions.COPY_LINK_INTERFACE) {
                this.copyLinkInterface(value.procedureSignataire);
            } else if (dropdownClicked.action === ProcedureSignataireDropdownComponent.actions.REMOVE) {
                this.remove(value.cProcedureSignataires, value.procedureSignataire);
            } else if (dropdownClicked.action === ProcedureSignataireDropdownComponent.actions.SEND_LINK_INTERFACE) {
                this.sendLinkInterface(value.procedureSignataire);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    add(cSignataires: CProcedureSignataires): void {
        const newSignataire = this._procedureSignataireFactory.createVirgin();

        cSignataires.addResult(newSignataire);
        this.edit(cSignataires, newSignataire);
    }

    addMe(cSignataires: CProcedureSignataires): void {
        this._userService.last$.pipe(
            map(currentUser => this._participantFactory.createFromUser(currentUser)),
        ).subscribe(participant => this.addParticipant(cSignataires, participant));
    }

    addParticipant(cSignataires: CProcedureSignataires, participant: Participant): void {
        if (!participant) {
            return;
        }

        const newSignataire = this._procedureSignataireFactory.createFromParticipant(participant);

        cSignataires.addResult(newSignataire);
        this.edit(cSignataires, newSignataire);
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement, cSignataires: CProcedureSignataires, signataire: ProcedureSignataire): void {
        this._dropdownService.open(htmlButtonElement, {
            component: ProcedureSignataireDropdownComponent,
            data: {
                cProcedureSignataires: cSignataires,
                options: {
                    enabledActions: {
                        copyLinkInterface: this._options.enabledActions?.copyLinkInterface && !!signataire.linkInterfaceSignature,
                        remove: this._options.enabledActions?.remove,
                        sendLinkInterface: this._options.enabledActions?.sendLinkInterface && !!signataire.linkInterfaceSignature,
                    },
                },
                procedureSignataire: signataire,
            },
        });
    }

    copyLinkInterface(signataire: ProcedureSignataire): void {
        navigator.clipboard.writeText(signataire.linkInterfaceSignature);
        this._toasterService.info(ProcedureSignatairesEditComponent.messages.copyLink.TITLE, ProcedureSignatairesEditComponent.messages.copyLink.MESSAGE);
    }

    edit(cSignataires: CProcedureSignataires, signataire: ProcedureSignataire): void {
        signataire.trim();
        this._deletedSignatairesSet.delete(signataire);
        this._editedSignatairesSet.add(signataire);
        this.emitSignataires(cSignataires);
    }

    emitSignataires(cSignataires: CProcedureSignataires): void {
        this.deletedSignataires.emit(Array.from(this._deletedSignatairesSet));
        this.editedSignataires.emit(Array.from(this._editedSignatairesSet));
        this.updateAmSignataire(cSignataires);
    }

    remove(cSignataires: CProcedureSignataires, signataire: ProcedureSignataire): void {
        cSignataires.removeResult(procedureSignataire => procedureSignataire === signataire);
        this._deletedSignatairesSet.add(signataire);
        this._editedSignatairesSet.delete(signataire);
        this.emitSignataires(cSignataires);
    }

    sendLinkInterface(signataire: ProcedureSignataire): void {
        this._procedureService.getMedia$(this._procedure).pipe(
            switchMap(media => this._dossiersService.getByLinks$(this._linkDossiers).pipe(
                map(dossiers => ({dossiers, media})),
            )),
            switchMap(({dossiers, media}) => this._emailEditService.forProcedureSignataireLinkInterface$(signataire, media, dossiers, this._currentDossier)),
            take(1),
        ).subscribe();
    }

    updateAmSignataire(cSignataires: CProcedureSignataires): void {
        this._userService.last$.pipe(
            map(currentUser => cSignataires?.results.some(signataire => signataire.isUser() && signataire.typeId === currentUser.id)),
        ).subscribe(amSignataire => this._amSignataire = amSignataire);
    }
}
