import angularJS from '@shared/angularJS/global.ng';
import {difference, findLastIndex, remove} from 'lodash';
import {IModule} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {UserClientMetadataService} from '@models/users/user/client-metadata/user-client-metadata.service';
import {UserService} from '@models/users/user/user.service';
import {switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {IUserClientMetadataConfigDashboard} from '@models/users/user/client-metadata/user-client-metadata.interfaces';

export default function getEskDashboard(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EskDashboard', Factory);

        /**
         * EskDashboard
         *
         * @param EskBox
         * @param Ng2UserClientMetadataService
         * @param Ng2UserService
         */
        Factory.$inject = ['EskBox', 'Ng2UserClientMetadataService', 'Ng2UserService'];
        function Factory(eskBox: NgEskBox,
                         ng2UserClientMetadataService: UserClientMetadataService,
                         ng2UserService: UserService) {
            let dashboard: IUserClientMetadataConfigDashboard;

            return {init, get, saveConfig};

            /**
             * Init
             *
             * @returns {object}
             */
            function init() {
                ng2UserService.last$.subscribe(currentUser => {
                    const originalDashboard: IUserClientMetadataConfigDashboard = {
                        rows: [
                            [{collection: [eskBox.getOneByCode('shortcuts')], noDrag: true}],
                            [
                                {
                                    class: 'col-sm-6',
                                    collection: [
                                        eskBox.getOneByCode('ventes-search'),
                                        eskBox.getOneByCode('news'),
                                        eskBox.getOneByCode('meteo'),
                                        eskBox.getOneByCode('ventes-mandats-natures'),
                                        eskBox.getOneByCode('ventes-negociees'),
                                        eskBox.getOneByCode('ventes-negociees-natures'),
                                        eskBox.getOneByCode('etude-activity'),
                                        eskBox.getOneByCode('etude-negociation-revenus'),
                                    ],
                                },
                                {
                                    class: 'col-sm-6',
                                    collection: [
                                        eskBox.getOneByCode('ventes-last'),
                                        eskBox.getOneByCode('ventes-mandats-without'),
                                        eskBox.getOneByCode('ventes-mandats-expired'),
                                        eskBox.getOneByCode('ventes-mandats-expiring'),
                                        eskBox.getOneByCode('estimations-remis'),
                                        eskBox.getOneByCode('ventes-network-updated'),
                                        eskBox.getOneByCode('locations-last'),
                                    ],
                                },
                            ],
                        ],
                    };
                    const userBoxKeys: string[] = [];
                    const orginalBoxKeys: string[] = [];

                    dashboard = {rows: []};
                    if (currentUser.clientMetadata?.dashboard && currentUser.clientMetadata.dashboard.rows) {
                        dashboard.rows = (currentUser.clientMetadata.dashboard.rows as unknown as { boxKeys: string[] }[][]).map((row, indexRow) => {
                            return row.map((col, indexCol) => {
                                const collection = col.boxKeys.map(boxKey => eskBox.getOneByCode(boxKey));

                                remove(collection, item => {
                                    if (!angular.isObject(item)) return true;

                                    // @ts-ignore
                                    userBoxKeys.push(item.code);

                                    return;
                                });

                                return {
                                    // @ts-ignore
                                    class: originalDashboard.rows[indexRow][indexCol].class,
                                    collection: collection
                                };
                            });
                        });
                        originalDashboard.rows.map(function (row) {
                            // @ts-ignore
                            row.map(function (col) {
                                col.collection.map(function (box) {
                                    orginalBoxKeys.push(box?.code);
                                });
                            });
                        });
                        difference(orginalBoxKeys, userBoxKeys).map(function (key) {
                            dashboard.rows[findLastIndex(dashboard.rows)][findLastIndex(dashboard.rows[findLastIndex(dashboard.rows)])].collection.push(eskBox.getOneByCode(key));
                        });
                    } else {
                        dashboard = originalDashboard;
                    }

                    dashboard.rows[0][0].collection = dashboard.rows[0][0].collection.filter(box => !!box);
                    dashboard.rows[1][0].collection = dashboard.rows[1][0].collection.filter(box => !!box);
                    dashboard.rows[1][1].collection = dashboard.rows[1][1].collection.filter(box => !!box);
                });
            }

            /**
             * Returns Dashboard
             *
             * @returns {object}
             */
            function get() {
                return dashboard;
            }

            /**
             * Save Dashboard config
             */
            function saveConfig() {
                ng2UserService.last$.pipe(
                    tap(currentUser => (currentUser.clientMetadata.dashboard as unknown as { rows: { boxKeys: string[] }[][] }) = {
                        rows: dashboard.rows.map(row => row.map(col => ({
                            class: col.class,
                            boxKeys: col.collection.map(box => box?.code),
                        })))
                    }),
                    switchMap(currentUser => ng2UserClientMetadataService.saveFromUser$(currentUser)),
                ).subscribe();
            }
        }
    })(angularJS);
}
