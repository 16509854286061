import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import {
    DossierBienOverviewInfosService
} from '@features/dossiers/dossier/bien/overview/infos/dossier-bien-overview.infos.service';
import {
    DossierBienOverviewInfosConfidentiellesService
} from '@features/dossiers/dossier/bien/overview/infos-confidentielles/dossier-bien-overview.infos-confidentielles.service';
import {
    DossierBienOverviewLogementsService
} from '@features/dossiers/dossier/bien/overview/logements/dossier-bien-overview.logements.service';
import {
    DossierBienOverviewSurfacesService
} from '@features/dossiers/dossier/bien/overview/surfaces/dossier-bien-overview.surfaces.service';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewService {
    private _dossierBienOverviewInfosConfidentiellesService = inject(DossierBienOverviewInfosConfidentiellesService);
    private _dossierBienOverviewInfosService = inject(DossierBienOverviewInfosService);
    private _dossierBienOverviewLogementsService = inject(DossierBienOverviewLogementsService);
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _dossierBienOverviewSurfacesService = inject(DossierBienOverviewSurfacesService);

    init(dossierBien: ADossierBien, mainInfosLimit: number): void {
        this._dossierBienOverviewInfosConfidentiellesService.init(dossierBien);
        this._dossierBienOverviewLogementsService.init(dossierBien);
        this._dossierBienOverviewMainInfosService.init(dossierBien, mainInfosLimit);
        this._dossierBienOverviewSurfacesService.init(dossierBien);
        this._dossierBienOverviewInfosService.init(dossierBien);
    }
}
