import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {map} from 'rxjs/operators';
import Template from '@models/templates/template/template.model';

@Injectable({providedIn: 'root'})
export class TemplateService {
    private _templateFactory = inject(TemplateFactory);

    getStylesheetLinksByLink$(link: string): Observable<string[]> {
        return this._templateFactory.getByLink$(link).pipe(map(template => template.stylesheets.map(stylesheet => stylesheet.href)));
    }

    update$(template: Template): Observable<void> {
        return this._templateFactory.getByLink$(template.linkSelf).pipe(
            map(updatedTemplate => {
                template.category = updatedTemplate.category;
                template.name = updatedTemplate.name;
                template.stylesheets = updatedTemplate.stylesheets;
            }),
        );
    }
}
