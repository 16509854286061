export default `
<div class="panel panel-white tw-h-full !tw-m-0">
  <div class="panel-heading border-light">
    <div class="row media">
      <div ng-if="!!((diffusion.diffuseur.passerelle || diffusion.diffuseur) || diffusion.diffuseur).urlLogo"
           class="col-sm-2 media-left media-middle">
        <div class="media-object">
          <div class="tw-flex tw-items-center tw-h-full">
            <img ng-src="{{((diffusion.diffuseur.passerelle || diffusion.diffuseur) || diffusion.diffuseur).urlLogo}}"
                 loading="lazy">
          </div>
        </div>
      </div>

      <div class="media-body tw-text-center">
        <h4 class="media-heading panel-title">
          <div>
            <span class="text-bold">{{(diffusion.diffuseur.passerelle || diffusion.diffuseur).name}}</span>

            <a ng-if="(diffusion.diffuseur.passerelle || diffusion.diffuseur).urlSite" ng-href="{{(diffusion.diffuseur.passerelle || diffusion.diffuseur).urlSite}}"
               target="_blank"><fa-icon [icon]="'external-link-alt'"></fa-icon></a>

            <a ng-if="$ctrl.dossier.type.code === $ctrl.INTERACTIVE && (diffusion.diffuseur.passerelle || diffusion.diffuseur).venteInteractive === 'native'"
               class="btn btn-o btn-null !tw-p-0" popover-trigger="'outsideClick'" popover-placement="left-top"
               uib-popover-template="'src/app/legacy/templates/biens/components/item/diffusion/details/help.html'">
              <span class="badge badge-info">?</span>
            </a>
          </div>

          <esk-passerelle-contrat ng-if="diffusion.type === 'soqrate' || (diffusion.type === 'social_network' && !(diffusion.diffuseur.passerelle || diffusion.diffuseur).instantDiffusion) || diffusion.type === 'website'"
                                  passerelle="diffusion.diffuseur"></esk-passerelle-contrat>
        </h4>
      </div>
    </div>
  </div>

  <div class="panel-body">
    <ng-switch on="diffusion.bien.doubleMandat === 'doubleMandat'">
      <ng-switch on="diffusion.isAvailable()" ng-switch-default>
        <div ng-switch-default>
          <p ng-show="diffusion.isDiffused() || diffusion.isSuspended()">
            <span ng-show="diffusion.isDiffused() && !diffusion.isSuspended()" class="text-success">
              <fa-icon [icon]="'check'"></fa-icon>

              Le bien en {{$ctrl.dossier._esk.typeDossier}} est diffusé sur ce support depuis le
              <app-ng2-date [date]="diffusion.bien.dateDiffusion" format="longDate"></app-ng2-date> à
              <app-ng2-date [date]="diffusion.bien.dateDiffusion" format="shortTime"></app-ng2-date>.

              <ng-include ng-if="$ctrl.dossier.type.code === $ctrl.INTERACTIVE && (diffusion.diffuseur.passerelle || diffusion.diffuseur).venteInteractive === 'native'"
                          src="'src/app/legacy/templates/biens/components/item/diffusion/details/help.html'"></ng-include>
            </span>

            <span ng-show="diffusion.isSuspended()" class="text-danger">
              <fa-icon [icon]="'times'"></fa-icon>

              Le bien en {{$ctrl.dossier._esk.typeDossier}} est retiré sur ce support depuis le
              <app-ng2-date [date]="diffusion.bien.dateSuspension" format="longDate"></app-ng2-date> à
              <app-ng2-date [date]="diffusion.bien.dateSuspension" format="shortTime"></app-ng2-date>.
            </span>
          </p>

          <p ng-show="!diffusion.isDiffused() && !diffusion.isSuspended()">
            Le bien en {{$ctrl.dossier._esk.typeDossier}} n'a jamais été diffusé sur ce support.
          </p>
        </div>

        <p ng-switch-when="compromis">
          Le bien en {{$ctrl.dossier._esk.typeDossier}} est en compromis, il a été retiré de ce support.
        </p>

        <p ng-switch-when="suspendu">
          Le bien en {{$ctrl.dossier._esk.typeDossier}} est indisponible, il a été retiré de ce support.
        </p>
      </ng-switch>

      <p ng-switch-when="true">
        Votre bien possède au moins une référence cadastrale identique au bien à vendre
        <span class="text-bold">{{diffusion.bien.doubleMandat.bienReference}}</span>
        <span ng-if="diffusion.bien.doubleMandat.type === 'doubleMandat'">
          de l'étude <span class="text-bold">{{diffusion.bien.doubleMandat.etudeRaisonSociale}}</span>.
          <br>Conformément à la charte, votre bien à vendre ne sera pas envoyé sur le réseau.
        </span>
        <span ng-if="diffusion.bien.doubleMandat.type === 'doublon'">de votre étude.</span>
      </p>
    </ng-switch>

    <ng-switch on="!!diffusion.message" ng-if="$ctrl.statusToDiffuse.indexOf($ctrl.dossier.statut) > -1">
      <p ng-switch-when="true" class="text-right">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{diffusion.message}}
      </p>

      <div ng-if="diffusion.bien.doubleMandat.type !== 'doubleMandat'" ng-switch-default class="row">
        <div class="col-sm-6">
          <button type="button" class="btn btn-link !tw-p-0" ng-show="diffusion.edit" ng-click="diffusion.edit()">
            Personnaliser
          </button>
        </div>

        <ng-switch on="diffusion.visibleBtn()" class="col-sm-6 text-right">
          <a ng-switch-when="to_configure" class="btn btn-sm btn-primary"
             ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/configuration/passerelles')">
            Configurer la passerelle
          </a>

          <button type="button" ng-switch-when="diffuse" class="btn btn-sm btn-green" ng-click="diffusion.diffuse()">
            Diffuser
          </button>

          <button class="btn btn-sm btn-danger btn-o"
                  ng-disabled="$ctrl.dossier.type.code === $ctrl.INTERACTIVE && (diffusion.diffuseur.passerelle || diffusion.diffuseur).venteInteractive === 'native'"
                  type="button" ng-switch-when="suspend" ng-click="diffusion.suspend()">Suspendre</button>
        </ng-switch>
      </div>
    </ng-switch>
  </div>
</div>
`;
