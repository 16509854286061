import {inject, Injectable} from '@angular/core';
import {DictionariesApiService} from '@models/dictionaries/dictionaries.api.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {IDictionaryApi, IDictionaryStorage} from '@models/dictionaries/dictionary/dictionary.interfaces';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {Observable} from 'rxjs';
import {HttpOptions} from '@core/api/api.interfaces';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DictionaryFactory {
    private _dictionariesApiService = inject(DictionariesApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(name: string, dictionaryApi: IDictionaryApi): Dictionary {
        const dictionary: Dictionary = this.createVirgin(name);

        dictionary.etag = dictionaryApi.etag;
        if (dictionaryApi._embedded?.items) {
            dictionary.items = dictionaryApi._embedded.items.map(dictionaryItemApi => this._dictionaryItemFactory.create(dictionaryItemApi));
        }

        return dictionary;
    }

    createFromStorage(name: string, dictionaryStorage: IDictionaryStorage): Dictionary {
        const dictionary: Dictionary = this.createVirgin(name);

        dictionary.etag = dictionaryStorage.etag;
        if (dictionaryStorage.elements) {
            dictionary.items = dictionaryStorage.elements.map(dictionaryItemApi => this._dictionaryItemFactory.create(dictionaryItemApi));
        }

        return dictionary;
    }

    createVirgin(name: string): Dictionary {
        return new Dictionary(name);
    }

    forStorage(dictionary: Dictionary): IDictionaryStorage {
        const dictionaryStorage = {} as IDictionaryStorage;

        dictionaryStorage.elements = dictionary.items.map(dictionaryItem => this._dictionaryItemFactory.forApi(dictionaryItem));
        dictionaryStorage.etag = dictionary.etag;

        return dictionaryStorage;
    }

    get$(name: string, httpOptions?: HttpOptions): Observable<Dictionary> {
        return this._dictionariesApiService.get$(name, httpOptions).pipe(map(dictionaryApi => this.create(name, dictionaryApi)));
    }

    getByName(name: string, withDeprecated = false): Dictionary {
        const dictionary = this.createFromStorage(name, this._dictionariesApiService.getByName(name));

        if (!withDeprecated) {
            dictionary.items = dictionary.items.filter(item => !item.deprecated);
        }

        return dictionary;
    }

    saveInStorage(dictionary: Dictionary): void {
        this._dictionariesApiService.saveInStorage(dictionary.name, this.forStorage(dictionary));
    }
}
