import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgDemandeurManager, NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {map, switchMap, take} from 'rxjs/operators';
import {NgDemandeur, NgEtude, NgVente} from '@legacy/app/managers/ressources';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';
import {VenteArchiveInfosService} from '@models/ventes/vente/archive-infos/vente-archive-infos.service';
import {combineLatest, from} from 'rxjs';
import EtudeNetwork from '@models/etudes/etude/networks/network/etude-network.model';
import Dictionary from '@core/models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {ConditionConst} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Vente from '@models/ventes/vente/vente.model';

export default function getVenteModalArchiveCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.controller('VenteModalArchiveCtrl', Controller);

        /**
         * Controller for archive modal
         *
         * @param vente
         * @param $uibModalInstance
         * @param $translate
         * @param SoqSweetAlert
         * @param DictionariesManager
         * @param $q
         * @param Ng2VenteArchiveInfosService
         * @param DemandeurManager
         * @param Ng2DictionaryFactory
         * @param Ng2VentePriceFactory
         * @param Ng2VenteFactory
         */
        Controller.$inject = ['vente', '$uibModalInstance', '$translate', 'SoqSweetAlert', 'DictionariesManager', '$q', 'Ng2VenteArchiveInfosService', 'DemandeurManager', 'Ng2DictionaryFactory', 'Ng2VentePriceFactory', 'Ng2VenteFactory'];
        function Controller(this: any,
                            vente: NgVente,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            dictionariesManager: NgDictionariesManager,
                            $q: IQService,
                            ng2VenteArchiveInfosService: VenteArchiveInfosService,
                            demandeurManager: NgDemandeurManager,
                            ng2DictionaryFactory: DictionaryFactory,
                            ng2VentePriceFactory: VentePriceFactory,
                            ng2VenteFactory: VenteFactory) {
            const $ctrl = this;

            $ctrl.getArchiveInfos = getArchiveInfos;
            $ctrl.onSelectDemandeur = onSelectDemandeur;
            $ctrl.getArchivedDemandeurs = getArchivedDemandeurs;
            $ctrl.operatePricing = operatePricing;
            $ctrl.saveVente = saveVente;
            $ctrl.archiveVente = archiveVente;
            $ctrl.sending = false;
            $ctrl.typesArchiveVente = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.VENTE_ARCHIVE_TYPES));
            $ctrl.vente = vente;
            $ctrl.VENTE_ARCHIVE_TYPES_RETIREE = Vente.archiveTypes.RETIREE;
            $ctrl.VENTE_ARCHIVE_NEGOCIEE_RAISONS = Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS;
            $ctrl.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS = Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES = Vente.archiveNegocieeRaisons.AUTRES;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE = Vente.archiveNegocieeRaisons.PORTEFEUILLE;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU = Vente.archiveNegocieeRaisons.RESEAU;
            $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE = Vente.archiveTypes.NEGOCIEE;
            $ctrl.VENTE_ARCHIVE_TYPES_NON_NEGOCIEE = Vente.archiveTypes.NON_NEGOCIEE;
            $ctrl.options = {
                demandeurs: {
                    name: 'archiveDemandeurId',
                    placeholder: 'Trouver le demandeur',
                    conditions: {},
                    searchIsItem: true,
                },
            };
            $ctrl.ngVente = ng2VenteFactory.ngCreate(vente);
            $ctrl.getArchivedDemandeurs();
            $ctrl.getArchiveInfos();

            /**
             * Get archive infos
             */
            function getArchiveInfos(reinit: boolean) {
                if (reinit) {
                    $ctrl.vente.archiveType = undefined!;
                }

                $ctrl.vente.getArchiveInfos().pipe(
                    switchMap((venteArchiveInfos: VenteArchiveInfos) => {
                        $ctrl.archiveDateVente = venteArchiveInfos.dateVente && venteArchiveInfos.dateVente !== 'Invalid date' ? DateFormat.toDate(venteArchiveInfos.dateVente) : undefined;

                        return combineLatest([
                            ng2VenteArchiveInfosService.getDemandeur$(venteArchiveInfos).pipe(
                                switchMap(demandeur => from(demandeurManager.createFromNg2(demandeur))),
                                map(ngDemandeur => $ctrl.archiveDemandeur = ngDemandeur),
                            ),
                            ng2VenteArchiveInfosService.getEtude$(venteArchiveInfos).pipe(map(etude => $ctrl.archiveEtudeAcquereur = etude)),
                        ]).pipe(map(_ => venteArchiveInfos));
                    }),
                    take(1),
                ).subscribe((venteArchiveInfos: VenteArchiveInfos) => {
                    $ctrl.archive = venteArchiveInfos;
                    $ctrl.archive.type = $ctrl.typesArchiveVente.find(item => item.uuid === $ctrl.archive.type.uuid);
                });
            }

            /**
             * On select demandeur
             *
             * @param demandeur
             */
            function onSelectDemandeur(demandeur: NgDemandeur) {
                if (demandeur === null) {
                    $ctrl.archive.acquereur = undefined!;
                }
            }

            /**
             * Get or not archived demandeurs
             */
            function getArchivedDemandeurs() {
                const params = angular.copy($ctrl.options.demandeurs);

                params.conditions.statuts = $ctrl.archivedDemandeurs ? undefined! : {
                    condition: ConditionConst.NOT_IN,
                    values: [Demandeur.statuts.ARCHIVE],
                };
                $ctrl.options.demandeurs = angular.copy(params);
            }

            /**
             * Operate pricing
             */
            function operatePricing() {
                if ($ctrl.vente.type.code !== Vente.types.SIMPLE) {
                    // @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/97 : A supprimer
                    return;
                }

                if (!angular.isNumber($ctrl.archive.prixCession) || $ctrl.archive.prixCession < 0) {
                    return;
                }

                if ($ctrl.archive.prixCession > VentePriceFactory.MAX_PRICE) {
                    $ctrl.archive.prixCession = VentePriceFactory.MAX_PRICE;
                }

                ng2VentePriceFactory.getFromVenteArchiveInfos$($ctrl.ngVente, $ctrl.archive)
                    .pipe(take(1))
                    .subscribe(ventePrice => {
                        // Cas où la réponse est arrivée après une nouvelle modification
                        if ($ctrl.vente.type.code === Vente.types.SIMPLE && $ctrl.archive.prixCession !== ventePrice.priceNV) {
                            return;
                        }

                        $ctrl.archive.honorairesNego = ventePrice.honoraires;
                    });
            }

            /**
             * Save vente information
             */
            function saveVente() {
                $ctrl.sending = true;

                saveArchiveInfoVente().then(() => $uibModalInstance.dismiss()).finally(() => $ctrl.sending = false);
            }

            /**
             * Archive vente
             */
            function archiveVente() {
                $ctrl.archive.dateVente = DateFormat.toJSON($ctrl.archiveDateVente);
                $ctrl.archive.demandeurId = undefined!;
                $ctrl.archive.linkDemandeur = undefined!;
                if ($ctrl.archiveDemandeur) {
                    if ($ctrl.archiveDemandeur.id > 0) {
                        $ctrl.archive.acquereur = undefined!;
                        $ctrl.archive.demandeurId = $ctrl.archiveDemandeur.id;
                        $ctrl.archive.linkDemandeur = $ctrl.archiveDemandeur._links.self.href;
                    } else {
                        $ctrl.archive.acquereur = $ctrl.archiveDemandeur.contactsGroup.nom;
                    }
                }

                $ctrl.archive.etudeAcquereurId = undefined!;
                $ctrl.archive.linkEtude = undefined!;
                if ($ctrl.archiveEtudeAcquereur?.id > 0) {
                    $ctrl.archive.etudeAcquereurId = $ctrl.archiveEtudeAcquereur.id;
                    $ctrl.archive.linkEtude = $ctrl.archiveEtudeAcquereur.linkSelf;
                }

                $ctrl.editForm.$submitted = true;
                if (!$ctrl.editForm.$valid || ($ctrl.archive.type.code !== Vente.archiveTypes.RETIREE && !DateFormat.isNowOlder($ctrl.archive.dateVente?.toString(), {orSame: true}))) {
                    if ($ctrl.archive.dateVente) {
                        $ctrl.editForm.archiveDateVente.$setValidity('maxdate', DateFormat.isNowOlder($ctrl.archive.dateVente.toString(), {orSame: true}));
                    }

                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return;
                }

                $ctrl.sending = true;
                soqSweetAlert.dangerConfirmation(
                    $translate.instant('vente.archive.confirm.TITLE'),
                    getConfirmation($ctrl.archive, $ctrl.vente.bien.reference),
                    {confirmButtonText: $translate.instant('vente.archive.confirm.CONFIRMATION')}
                ).then(() => $ctrl.vente.archive($ctrl.archive).then(() => {
                    $uibModalInstance.close();
                })).catch(() => $uibModalInstance.dismiss()).finally(() => $ctrl.sending = false);
            }

            /**
             * Save vente
             *
             * @returns {Promise}
             */
            function saveArchiveInfoVente() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return $q.reject();
                }

                $ctrl.archive.dateVente = DateFormat.toJSON($ctrl.archiveDateVente);
                $ctrl.archive.demandeurId = undefined!;
                $ctrl.archive.linkDemandeur = undefined!;
                if ($ctrl.archiveDemandeur) {
                    if ($ctrl.archiveDemandeur.id > 0) {
                        $ctrl.archive.acquereur = undefined!;
                        $ctrl.archive.demandeurId = $ctrl.archiveDemandeur.id;
                        $ctrl.archive.linkDemandeur = $ctrl.archiveDemandeur._links.self.href;
                    } else {
                        $ctrl.archive.acquereur = $ctrl.archiveDemandeur.contactsGroup.nom;
                    }
                }

                $ctrl.archive.etudeAcquereurId = undefined!;
                $ctrl.archive.linkEtude = undefined!;
                if ($ctrl.archiveEtudeAcquereur?.id > 0) {
                    $ctrl.archive.etudeAcquereurId = $ctrl.archiveEtudeAcquereur.id;
                    $ctrl.archive.linkEtude = $ctrl.archiveEtudeAcquereur.linkSelf;
                }

                return $ctrl.vente.saveArchiveInfos($ctrl.archive).catch((rejection: unknown) => {
                    if (angular.isObject($ctrl.vente._esk.errorsSaving) && $ctrl.vente._esk.errorsSaving.nbErrors > 0) {
                        soqSweetAlert.warningMessage($translate.instant('vente.save.TITLE', $ctrl.vente._esk.errorsSaving), $translate.instant('vente.save.MESSAGE', $ctrl.vente._esk.errorsSaving));
                    }

                    return $q.reject(rejection);
                });
            }

            /**
             * Get template confirmation
             *
             * @param archive
             * @param reference
             * @returns {string}
             */
            function getConfirmation(archive: VenteArchiveInfos & { demandeur: NgDemandeur, etudeAcquereur: NgEtude, reseau: EtudeNetwork },
                                     reference: string) {
                const html: string[] = [];

                html.push("<p class=\"text-justify\">Pour le bien en vente <span class=\"text-bold\">" + reference + "</span>, vous déclarez ");
                if (archive.type.code === Vente.archiveTypes.NEGOCIEE) {
                    html.push("l'avoir négocié et vendu ");
                    if (archive.raisonVenteNegociee.code === Vente.archiveNegocieeRaisons.PORTEFEUILLE && angular.isObject(archive.demandeur) && angular.isObject(archive.demandeur.contactsGroup)) {
                        html.push("à <span class=\"text-bold\">" + archive.demandeur.contactsGroup.nom + "</span>, demandeur(s) issu(s) de votre propre portefeuille,");
                    } else if (archive.raisonVenteNegociee.code === Vente.archiveNegocieeRaisons.RESEAU && angular.isString(archive.acquereur) && archive.acquereur !== '') {
                        html.push("à <span class=\"text-bold\">" + archive.acquereur + "</span>");

                        if (angular.isObject(archive.etudeAcquereur)) {
                            html.push(", acquéreur(s) apporté(s) par <span class=\"text-bold\">" + archive.etudeAcquereur.raisonSociale + "</span> du réseau <span class=\"text-bold\">" + archive.reseau.name + "</span>");
                        }
                    }
                    html.push(" au prix de <span class=\"text-bold\">" + archive.prixCession + " € net vendeur</span>.<br>");
                    if (archive.raisonVenteNegociee.code === Vente.archiveNegocieeRaisons.RESEAU) {
                        html.push("Les honoraires perçus sont de " + archive.honorairesNegoPercus + " € T.T.C.");
                    } else {
                        html.push("Les honoraires perçus sont de " + archive.honorairesNego + " € T.T.C.");
                    }
                } else if (archive.type.code === Vente.archiveTypes.NON_NEGOCIEE) {
                    html.push("ne pas l'avoir négocié.");
                } else {
                    html.push("qu'il a été retiré de la vente.");
                }
                html.push("</p><hr><p class=\"text-justify text-muted\">En archivant ce dossier, vous vous engagez à ce que les informations saisies au-dessus soient exactes.<br>La société Noteo recevra une copie de ces informations. Conformément aux conditions de mise à disposition ainsi qu'à la charte, la société Noteo et l'administrateur se réservent le droit de contrôler celles-ci.</p>");

                return html.join('');
            }
        }
    })(angularJS);
}
