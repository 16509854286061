import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class BienDiagnostic extends AModel {
    private _date!: string;
    private _comments!: string;
    private readonly _id: number;
    private _type!: DictionaryItem;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get id(): number {
        return this._id;
    }

    get type(): DictionaryItem {
        return this._type;
    }

    set type(value: DictionaryItem) {
        this._type = value;
    }
}
