import {BrowserModule} from '@angular/platform-browser';
import {ApplicationRef, DoBootstrap, inject, Injector, NgModule} from '@angular/core';
import {setAngularJSGlobal, UpgradeModule} from '@angular/upgrade/static';
import {CommonModule, PRECONNECT_CHECK_BLOCKLIST} from '@angular/common';
import {CoreModule} from '@core/core.module';
import angularJS from '@shared/angularJS/global.ng';
import {SharedModule} from '@shared/shared.module';
import {FeaturesModule} from '@features/features.module';
import {EskimmoModule, getEskimmoModuleName} from '@shared/angularJS/eskimmo.module';
import {provideRouter, withRouterConfig} from '@angular/router';
import {LocationUpgradeModule} from '@angular/common/upgrade';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import environment from '@env/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreModule,
        EskimmoModule,
        FeaturesModule,
        LocationUpgradeModule.config(),
        SharedModule,
        UpgradeModule,
    ],
    providers: [
        provideRouter(
            [{path: '**', pathMatch: 'full', redirectTo: 'start'}],
            withRouterConfig({paramsInheritanceStrategy: 'always'}),
        ),
        {provide: PRECONNECT_CHECK_BLOCKLIST, useValue: environment.api.mediaUrl},
        {provide: '$scope', useExisting: '$rootScope'},
    ],
})
export class AppModule implements DoBootstrap {
    private _injector = inject(Injector);
    private _upgradeModule = inject(UpgradeModule);

    ngDoBootstrap(applicationRef: ApplicationRef): void {
        // Bootstrap du module principal d'AngularJS
        setAngularJSGlobal(angularJS);
        this._upgradeModule.bootstrap(document.body, [getEskimmoModuleName(this._injector)], {strictDi: true});

        // Bootstrap manuel du component principal
        applicationRef.bootstrap(AppComponent);
    }
}
