import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IBienCommoditeApi} from '@models/bien/commodites/commodite/bien-commodite.interfaces';
import BienCommodite from '@models/bien/commodites/commodite/bien-commodite.model';

@Injectable({providedIn: 'root'})
export class BienCommoditesApiService {
    private _modelApiService = inject(ModelApiService);

    get$(dossierBienId: string, id: string): Observable<IBienCommoditeApi> {
        return this._modelApiService.get$(`/biens/${dossierBienId}/commodites/${id}`);
    }

    save$(dossierBienId: string, id: string, bienCommoditeApi: IBienCommoditeApi): Observable<IBienCommoditeApi> {
        if (id === BienCommodite.STATUT_NEW) {
            return this._modelApiService.post$(`/biens/${dossierBienId}/commodites`, bienCommoditeApi);
        }

        return this._modelApiService.put$(`/biens/${dossierBienId}/commodites/${id}`, bienCommoditeApi)
            .pipe(switchMap(_ => this.get$(dossierBienId, id)));
    }
}
