import AModel from '@models/model.abstract';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import DateFormat from '@shared/date/date.format';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';

export default class Procedure extends AModel {
    static readonly providers = {
        soqrate: {code: 'soqrate', label: 'en présentiel'},
        yousign: {code: 'yousign', label: 'à distance'},
    };
    static readonly statuts = {
        CANCELED: 'canceled',
        DRAFT: 'draft',
        EXPIRED: 'expired',
        FINISHED: 'finished',
        LAUNCHED: 'launched',
        NEW: AModel.STATUT_NEW,
        REFUSED: 'refused',
        STARTED: 'started',
    };
    private _colorStatut!: NoteoStatutColorType;
    private _cSignataires!: CProcedureSignataires;
    private _documentId!: number;
    private _labelStatut!: string;
    private _launchedAt!: string;
    private _linkDocument!: string;
    private _linkMedia!: string;
    private _provider!: string;
    private _providerLabel!: string;
    private _statut!: string;
    private _updatedAt!: string;

    constructor(uuid: string) {
        super(uuid);
        this.statut = Procedure.statuts.NEW;
    }

    get colorStatut(): NoteoStatutColorType {
        return this._colorStatut;
    }

    get cSignataires(): CProcedureSignataires {
        return this._cSignataires;
    }

    set cSignataires(value: CProcedureSignataires) {
        this._cSignataires = value;
    }

    get documentId(): number {
        return this._documentId;
    }

    set documentId(value: number) {
        this._documentId = value;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get launchedAt(): string {
        return this._launchedAt;
    }

    set launchedAt(value: string) {
        this._launchedAt = value;
    }

    get linkDocument(): string {
        return this._linkDocument;
    }

    set linkDocument(value: string) {
        this._linkDocument = value;
    }

    get linkMedia(): string {
        return this._linkMedia;
    }

    set linkMedia(value: string) {
        this._linkMedia = value;
    }

    get provider(): string {
        return this._provider;
    }

    set provider(value: string) {
        this._provider = value;
        this._providerLabel = Procedure.providers[this._provider]?.label;
    }

    get providerLabel(): string {
        return this._providerLabel;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        if (!Procedure.statuts[value?.toUpperCase()]) {
            return;
        }

        this._statut = value;
        switch (this.statut) {
            case Procedure.statuts.CANCELED :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = 'annulée';
                break;
            case Procedure.statuts.DRAFT :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'brouillon';
                break;
            case Procedure.statuts.EXPIRED :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = 'expirée';
                break;
            case Procedure.statuts.FINISHED :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'terminée';
                break;
            case Procedure.statuts.LAUNCHED :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'lancée';
                break;
            case Procedure.statuts.NEW :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'créée';
                break;
            case Procedure.statuts.REFUSED :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = 'refusée';
                break;
            case Procedure.statuts.STARTED :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'démarrée';
                break;
        }
    }

    get updatedAt(): string {
        return this._updatedAt;
    }

    set updatedAt(value: string) {
        this._updatedAt = value;
    }

    isCanceled(): boolean {
        return this.statut === Procedure.statuts.CANCELED;
    }

    isClosed(): boolean {
        return this.isCanceled() || this.isExpired() || this.isFinished() || this.isRefused();
    }

    isDistanciel(): boolean {
        return this.provider === Procedure.providers.yousign.code;
    }

    isDraft(): boolean {
        return this.statut === Procedure.statuts.DRAFT;
    }

    isEditable(): boolean {
        return this.isDraft() || this.isLaunched() || this.isNew() || this.isStarted();
    }

    isExpired(): boolean {
        return this.statut === Procedure.statuts.EXPIRED;
    }

    isFinished(): boolean {
        return this.statut === Procedure.statuts.FINISHED;
    }

    isInProgress(): boolean {
        return this.isLaunched() || this.isStarted();
    }

    isLaunched(): boolean {
        return this.statut === Procedure.statuts.LAUNCHED;
    }

    isNew(): boolean {
        return this.statut === Procedure.statuts.NEW;
    }

    isNewOrDraft(): boolean {
        return this.isDraft() || this.isNew();
    }

    isPresentiel(): boolean {
        return this.provider === Procedure.providers.soqrate.code;
    }

    isPresentielEditable(): boolean {
        return this.isPresentiel() && this.isEditable();
    }

    isRefused(): boolean {
        return this.statut === Procedure.statuts.REFUSED;
    }

    isStarted(): boolean {
        return this.statut === Procedure.statuts.STARTED;
    }

    isValid(): boolean {
        if (!this.documentId) {
            return false;
        }

        return !!this.cSignataires?.isValid(this.provider);
    }

    setCanceled(): void {
        this.updatedAt = DateFormat.toISOString();
        this.statut = Procedure.statuts.CANCELED;
    }

    setLaunched(): void {
        this.launchedAt = DateFormat.toISOString();
        this.updatedAt = DateFormat.toISOString();
        this.statut = Procedure.statuts.LAUNCHED;
    }
}
