import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';

export default function getEskimmoRun(module: IModule): void {
    (function (angular) {
        'use strict';

        module
            .run(datePicker)
            .run(uiSelect)
            .run(templates);

        /**
         * DatePicker initialization
         *
         * @param uibDatepickerConfig
         * @param uibDatepickerPopupConfig
         */
        datePicker.$inject = ['uibDatepickerConfig', 'uibDatepickerPopupConfig'];
        function datePicker(uibDatepickerConfig: angular.ui.bootstrap.IDatepickerConfig,
                            uibDatepickerPopupConfig: angular.ui.bootstrap.IDatepickerPopupConfig) {
            //uibDatepickerConfig
            uibDatepickerConfig.formatYear = 'yyyy';
            uibDatepickerConfig.startingDay = 1;

            //uibDatepickerPopupConfig
            uibDatepickerPopupConfig.onOpenFocus = false;
            uibDatepickerPopupConfig.currentText = "Aujourd'hui";
            uibDatepickerPopupConfig.clearText = "Effacer";
            uibDatepickerPopupConfig.closeText = "Fermer";
        }

        /**
         * uiSelect initialization
         *
         * @param uiSelectConfig
         */
        uiSelect.$inject = ['uiSelectConfig'];
        function uiSelect(uiSelectConfig: angular.ui.select.ISelectConfig & { disableBackspaceReset: boolean }) {
            uiSelectConfig.placeholder = "Rechercher";
            uiSelectConfig.refreshDelay = 300;
            uiSelectConfig.theme = 'bootstrap';
            uiSelectConfig.disableBackspaceReset = true;
        }

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('messages.html',
                '<ng-message when="required">{{"Champ requis" | translate}}</ng-message>' +
                '<ng-message when="pattern, parse, date">{{"Format invalide" | translate}}</ng-message>' +
                '<ng-message when="email, emailValidation">{{"L\'adresse email est invalide." | translate}}</ng-message>' +
                '<ng-message when="superficieWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir une superficie positive." | translate}}</ng-message>' +
                '<ng-message when="prixVenteWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir un prix de vente positif." | translate}}</ng-message>' +
                '<ng-message when="interactivePrixReserveWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir un prix de réserve positif." | translate}}</ng-message>' +
                '<ng-message when="viagerValeurBienWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir une valeur de bien positive." | translate}}</ng-message>' +
                '<ng-message when="viagerBouquetWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir un bouquet positif." | translate}}</ng-message>' +
                '<ng-message when="viagerRenteWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir une rente positive." | translate}}</ng-message>' +
                '<ng-message when="titreVenteWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir un titre pour cette vente." | translate}}</ng-message>' +
                '<ng-message when="descriptifVenteWhenDateMandat">{{"Une date de mandat a été saisie pour ce bien." | translate}}<br>{{"Vous devez saisir un descriptif pour cette vente." | translate}}</ng-message>' +
                '<ng-message when="minlength">{{"Nombre de caractères invalides" | translate}}</ng-message>' +
                '<ng-message when="mindate">{{"Date invalide, trop ancienne" | translate}}</ng-message>' +
                '<ng-message when="maxdate">{{"Date invalide, trop récente" | translate}}</ng-message>' +
                '<ng-message when="interval_0-1">{{"Valeur entre 0 et 1" | translate}}</ng-message>' +
                '<ng-message when="max">Valeur trop grande</ng-message>' +
                '<ng-message when="min">Valeur trop petite</ng-message>'
            );
        }
    })(angularJS);
}
