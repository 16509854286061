import {inject, Injectable} from '@angular/core';
import {map, Observable, of, switchMap, throwError} from 'rxjs';
import {IUserClientMetadataApi} from '@models/users/user/client-metadata/user-client-metadata.interfaces';
import {ModelApiService} from '@models/model.api.service';
import {HttpOptions} from '@core/api/api.interfaces';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class UserClientMetadataApiService {
    private _modelApiService = inject(ModelApiService);

    get$(userUuid: string, httpOptions?: HttpOptions): Observable<IUserClientMetadataApi> {
        return this.getByLink$('/client/users/' + userUuid + '/metadatas', httpOptions);
    }

    getByLink$(link: string, httpOptions?: HttpOptions): Observable<IUserClientMetadataApi> {
        return this._modelApiService.get$(link, httpOptions);
    }

    save$(userUuid: string, userClientMetadataApi: IUserClientMetadataApi): Observable<IUserClientMetadataApi> {
        return this.get$(userUuid, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => httpErrorResponse.status === 404 ? of(undefined!) : throwError(() => httpErrorResponse)),
            map(userClientMetadataApiGet => !!userClientMetadataApiGet),
            switchMap(hasMetadata => {
                if (hasMetadata) {
                    return this._modelApiService.put$('/client/users/' + userUuid + '/metadatas', userClientMetadataApi).pipe(switchMap(_ => this.get$(userUuid)));
                }

                return this._modelApiService.post$('/client/users/' + userUuid + '/metadatas', userClientMetadataApi);
            }),
        );
    }
}
