import {Component, Input} from '@angular/core';
import Procedure from '@core/models/procedures/procedure/procedure.model';

@Component({selector: 'app-procedure-statut', templateUrl: 'procedure.statut.component.html'})
export class AppProcedureStatutComponent {
    private _procedure!: Procedure;

    get procedure(): Procedure {
        return this._procedure;
    }

    @Input()
    set procedure(value: Procedure) {
        this._procedure = value;
    }
}
