import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AppDossierReferenceComponent} from '@features/dossiers/dossier/reference/dossier.reference.component';
import {
    DCDossierBienRestorationComponent
} from '@features/dossiers/dossier/bien/restoration/dossier-bien.restoration.component';

// @todo - remplir avec les autres composants en faisant attention aux dependances

@NgModule({
    declarations: [AppDossierReferenceComponent, DCDossierBienRestorationComponent],
    exports: [AppDossierReferenceComponent],
    imports: [CommonModule, SharedModule],
})
export class DossierModule {
}
