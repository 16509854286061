export default `
<ng-switch on="!!$ctrl.location.loyer" ng-if="!$ctrl.loader" class="label label-rent">
  <span ng-switch-when="true" title="{{$ctrl.location.chargesIncluses ? 'Loyer chargé' : 'Loyer non chargé'}}">
    {{$ctrl.loyer | currency:'€':2 * ($ctrl.loyer % 1 !== 0)}}

    <span ng-if="$ctrl.location.chargesIncluses">CC</span>
  </span>

  <span ng-switch-default>Pas de loyer saisi</span>
</ng-switch>
`;
