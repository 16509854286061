const COLORS = {
    archive: {
        vente: {
            solo: 'rgba(28, 51, 82, 1)',
            soloLight: 'rgba(28, 51, 82, 0.7)',
            duo: 'rgba(233, 64, 97, 1)',
            duoLight: 'rgba(233, 64, 97, 0.7)',
            network: 'rgba(39, 179, 162, 0.5)',
        }
    },
    bien: {
        nature: {
            maison: 'rgba(27, 52, 82, 1)',
            appartement: 'rgba(254, 128, 28, 1)',
            immeuble: 'rgba(251, 194, 16, 1)',
            bureaux: 'rgba(229, 64, 100, 1)',
            locaux_comm: 'rgba(85, 56, 126, 1)',
            garage: 'rgba(247, 227, 162, 1)',
            terrain_batir: 'rgba(163, 204, 56, 1)',
            autre_terrain: 'rgba(105, 200, 178, 1)',
            autre: 'rgba(84, 160, 249, 1)'
        }
    }
};

export default COLORS;
