import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule} from 'angular';

export default function getEskTemplateByTags(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example eskTemplateByTags
         */
        module.filter('eskTemplateByTags', filterElement);

        /**
         * Filter template by tags
         *
         * @returns {function(*=, *=): *}
         */
        filterElement.$inject = [] as string[];
        function filterElement() {
            return (templates: unknown[], tags: string[]): unknown[] => tags ? filter(templates, {tags}) : templates;
        }
    })(angularJS);
}
