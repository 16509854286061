import AModel from '@models/model.abstract';

export default class DictionaryItem extends AModel {
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private _abreviation!: string;
    private _anneeMax!: number;
    private _anneeMin!: number;
    private _code!: string;
    private _codePasserelle!: string;
    private _codes: string[] = [];
    private _defaultComments!: string;
    private _deprecated = false;
    private _duree!: string;
    private readonly _id: number;
    private _label!: string;
    private _shortLabel!: string;
    private _tags!: string[];

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get abreviation(): string {
        return this._abreviation;
    }

    set abreviation(value: string) {
        this._abreviation = value;
    }

    get anneeMax(): number {
        return this._anneeMax;
    }

    set anneeMax(value: number) {
        this._anneeMax = value;
    }

    get anneeMin(): number {
        return this._anneeMin;
    }

    set anneeMin(value: number) {
        this._anneeMin = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get codePasserelle(): string {
        return this._codePasserelle;
    }

    set codePasserelle(value: string) {
        this._codePasserelle = value;
    }

    get codes(): string[] {
        return this._codes;
    }

    set codes(value: string[]) {
        this._codes = value;
    }

    get defaultComments(): string {
        return this._defaultComments;
    }

    set defaultComments(value: string) {
        this._defaultComments = value;
    }

    get deprecated(): boolean {
        return this._deprecated;
    }

    set deprecated(value: boolean) {
        this._deprecated = !!value;
    }

    get duree(): string {
        return this._duree;
    }

    set duree(value: string) {
        this._duree = value;
    }

    get id(): number {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get shortLabel(): string {
        return this._shortLabel;
    }

    set shortLabel(value: string) {
        this._shortLabel = value;
    }

    get tags(): string[] {
        return this._tags;
    }

    set tags(value: string[]) {
        this._tags = value;
    }

    getByCodes(code: string): boolean {
        return !!this.codes.find(item => item === code);
    }
}
