import Vente from '@models/ventes/vente/vente.model';
import CADossierBiens from '@models/dossiers/biens/collection/dossier-biens.collection.model.abstract';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default class CVentes extends CADossierBiens<Vente> {
    static readonly tris = {
        ARCHIVE_DATE_VENTE: 'archiveDateVente',
        COMMUNE: 'commune',
    };
    readonly dossierType = DossierBienTypesConst.VENTE;
}
