export default `
<div class="tw-text-center">
  <h2>
    {{'upload.dnd.MESSAGE' | translate:'{multiple: ' + (options.multiple === false ? false : true) + ', fileType: "' + (!!fileType ? fileType : 'FILE') + '"}'}}
  </h2>

  <p>
    {{'upload.select.MESSAGE' | translate:'{multiple: ' + (options.multiple === false ? false : true) + ', fileType: "' + (!!fileType ? fileType : 'FILE') + '"}'}}
  </p>

  <div class="js-fileapi-wrapper btn btn-primary btn-file">
    <span>
      {{'upload.click.MESSAGE' | translate:'{multiple: ' + (options.multiple === false ? false : true) + ', fileType: "' + (!!fileType ? fileType : 'FILE') + '"}'}}
    </span>

    <input id="input-file" type="file" accept="{{allowedTypes}}">
  </div>
</div>
`;
