<div class="tw-space-y-4 lg:tw-space-y-8">
  <div class="tw-flex tw-justify-between tw-flex-col md:tw-flex-row tw-gap-4 md:tw-gap-8 tw-text-2xl tw-text-gray-700 tw-font-semibold">
    <div>{{ dossierBien.bien.titre }}</div>

    @if (options.showReference) {
      <div class="tw-w-full md:tw-w-auto tw-text-right tw-whitespace-nowrap">
        <span class="tw-hidden lg:tw-inline-block tw-text-gray-500 tw-text-xs tw-font-normal">Référence</span>
        {{ dossierBien.reference }}
      </div>
    }
  </div>

  <div class="tw-flex tw-flex-wrap tw-gap-4">
    <div class="tw-w-full lg:tw-w-2/3 xl:tw-w-3/5 2xl:tw-w-2/3 tw-flex-1 tw-space-y-2">
      <div class="tw-text-sm tw-text-gray-700 tw-font-medium">{{ dossierBien.bien.descriptif }}</div>
    </div>

    <div class="tw-flex tw-flex-col tw-justify-between tw-gap-2 tw-w-full lg:tw-w-1/3 xl:tw-w-2/5 2xl:tw-w-1/3 lg:tw-text-right">
      @if (dossierBien.isLocation()) {
        <app-location-pricing [options]="{details: isInfosConfidentialVisible ? (dossierBien.interne ? 'prive' : 'public') : 'simple'}"
                              class="tw-block tw-bg-gray-100 tw-rounded-xl tw-p-3 lg:tw-p-4" [location]="location"/>
      }

      @if (dossierBien.isVente()) {
        <app-vente-pricing [options]="{details: isInfosConfidentialVisible ? (dossierBien.interne ? 'prive' : 'public') : 'simple'}"
                           class="tw-block tw-bg-gray-100 tw-rounded-xl tw-p-3 lg:tw-p-4" [vente]="vente"/>
      }

      @if (dossierBien.bien.urlVisiteVirtuelle) {
        <div>
          <a [href]="dossierBien.bien.urlVisiteVirtuelle" target="_blank" class="tw-btn-secondary-info">
            Visite virtuelle <fa-icon [icon]="'external-link-alt'"/>
          </a>
        </div>
      }
    </div>
  </div>

  @if (mainInfos.length > 0) {
    <div class="tw-flex tw-justify-evenly">
      @for (mainInfo of mainInfos; track $index) {
        <div class="tw-p-4 tw-text-center" [ngClass]="{
         'tw-w-full': mainInfos.length === 1,
         'tw-w-1/2': mainInfos.length === 2,
         'tw-w-1/3': mainInfos.length === 3,
         'tw-w-1/4': mainInfos.length === 4,
         'tw-w-1/5': mainInfos.length === 5,
         'tw-w-1/6': mainInfos.length >= 6,
        }">
          @switch (mainInfo.code) {
            @case (FIELDS.ASCENSEUR) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'arrow-down-arrow-up'], info: mainInfo}"/>
            }

            @case (FIELDS.BALCON) {
              <div class="tw-h-full tw-flex tw-flex-col tw-space-y-1">
                <div class="tw-h-[51px] tw-pb-1">
                  <svg version="1.1" id="balcon" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink"
                       x="0px" y="0px" viewBox="0 0 160 160" style="enable-background:new 0 0 160 160;"
                       xml:space="preserve"
                       class="tw-h-full tw-w-full">
                    <style type="text/css">
                      .icon-balcon-0 {
                        fill: none;
                        stroke: currentColor;
                        stroke-width: 8;
                        stroke-miterlimit: 10;
                      }

                      .icon-balcon-1 {
                        fill: none;
                        stroke: currentColor;
                        stroke-width: 8;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                      }
                    </style>
                    <rect x="47.2" y="9.2" class="icon-balcon-0" width="66" height="70.4"/>
                    <line class="icon-balcon-1" x1="5.7" y1="81.2" x2="154.6" y2="81.2"/>
                    <rect x="5.7" y="143.8" class="icon-balcon-1" width="148.9" height="10.2"/>
                    <g>
                      <line class="icon-balcon-1" x1="16.6" y1="81.2" x2="16.6" y2="142.4"/>
                      <line class="icon-balcon-1" x1="42.5" y1="81.2" x2="42.5" y2="142.6"/>
                      <line class="icon-balcon-1" x1="68" y1="81.2" x2="68" y2="143.6"/>
                      <line class="icon-balcon-1" x1="93.5" y1="81.2" x2="93.5" y2="143.6"/>
                      <line class="icon-balcon-1" x1="119.6" y1="81.2" x2="119.6" y2="143.6"/>
                      <line class="icon-balcon-1" x1="143.8" y1="81.2" x2="143.8" y2="143.6"/>
                    </g>
                  </svg>
                </div>

                <div class="tw-text-sm tw-text-gray-700 tw-font-medium">{{ mainInfo.label }}</div>

                <div class="tw-text-sm tw-text-gray-700">{{ mainInfo.subLabel }}</div>
              </div>
            }

            @case (FIELDS.COMMUNE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'map-location-dot'], info: mainInfo}"/>
            }

            @case (FIELDS.EXCEPTIONNEL) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'medal'], info: mainInfo}"/>
            }

            @case (FIELDS.GARAGE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'garage'], info: mainInfo}"/>
            }

            @case (FIELDS.LONGUEUR_FACADE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'arrows-left-right'], info: mainInfo}"/>
            }

            @case (FIELDS.NATURE) {
              <ng-container *ngTemplateOutlet="infoView;context:{info: mainInfo}"/>
            }

            @case (FIELDS.NOMBRE_CHAMBRES) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'bed'], info: mainInfo}"/>
            }

            @case (FIELDS.NOMBRE_LOGEMENTS) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'building'], info: mainInfo}"/>
            }

            @case (FIELDS.NOMBRE_PIECES) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'cubes'], info: mainInfo}"/>
            }

            @case (FIELDS.NOMBRE_PLACES_PARKING) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'square-parking'], info: mainInfo}"/>
            }

            @case (FIELDS.PLAIN_PIED) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'store-alt'], info: mainInfo}"/>
            }

            @case (FIELDS.SUPERFICIE) {
              @if (dossierBien.bien.isTerrainABatir() || dossierBien.bien.isTerrainABatir()) {
                <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'expand-wide'], info: mainInfo}"/>
              } @else {
                <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'house'], info: mainInfo}"/>
              }
            }

            @case (FIELDS.SURFACE_CADASTRALE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'up-down-left-right'], info: mainInfo}"/>
            }

            @case (FIELDS.TERRASSE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'umbrella-beach'], info: mainInfo}"/>
            }

            @case (FIELDS.VIABILISATION_EAU) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'faucet-drip'], info: mainInfo}"/>
            }

            @case (FIELDS.VIABILISATION_ELECTRICITE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'bolt'], info: mainInfo}"/>
            }

            @case (FIELDS.VIABILISATION_VOIRIE) {
              <ng-container *ngTemplateOutlet="infoView;context:{icon: ['fal', 'road'], info: mainInfo}"/>
            }
          }

          <ng-template #infoView let-icon="icon" let-info="info">
            <div class="tw-h-full tw-flex tw-flex-col tw-space-y-1" [ngClass]="{'tw-justify-center': !icon}">
              @if (icon) {
                <fa-icon [icon]="icon" size="3x" class="tw-pb-1"/>
              }

              <div class="tw-text-sm tw-text-gray-700 tw-font-medium">{{ info.label }}</div>

              <div class="tw-text-sm tw-text-gray-700">{{ info.subLabel }}</div>
            </div>
          </ng-template>
        </div>
      }
    </div>
  }
</div>
