import {inject, Injectable} from '@angular/core';
import Entreprise from '@models/contacts/entreprise/entreprise.model';
import {NgContact} from '@legacy/app/managers/ressources';
import {IContactApi, IEntrepriseApi} from '@models/contacts/contacts.interfaces';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';

@Injectable({providedIn: 'root'})
export class EntrepriseFactory {
    private _phoneNumberService = inject(PhoneNumberService);

    createFromContactApi(contactApi: IContactApi): Entreprise {
        const entreprise = this.createVirgin(contactApi.uuid, contactApi.id);

        if (contactApi._embedded?.entreprise) {
            entreprise.adresse = contactApi._embedded.entreprise.adresse;
            entreprise.capital = contactApi._embedded.entreprise.capital;
            entreprise.codePostal = contactApi._embedded.entreprise.codePostal;
            entreprise.colorVariation = contactApi._embedded.entreprise.colorVariation;
            entreprise.comments = contactApi._embedded.entreprise.comments;
            entreprise.communeId = contactApi._embedded.entreprise.communeId;
            entreprise.emailAddress = contactApi._embedded.entreprise.emailAddress;
            entreprise.formeJuridique = contactApi._embedded.entreprise.formeJuridique;
            entreprise.initiales = contactApi._embedded.entreprise.initiales;
            entreprise.mobile = this._phoneNumberService.getUsable(contactApi._embedded.entreprise.mobile);
            entreprise.raisonSociale = contactApi._embedded.entreprise.raisonSociale;
            entreprise.siret = contactApi._embedded.entreprise.siret;
            entreprise.telephone = this._phoneNumberService.getUsable(contactApi._embedded.entreprise.telephone);

            if (contactApi._embedded.entreprise._links?.commune) {
                entreprise.linkCommune = contactApi._embedded.entreprise._links.commune.href;
            }
        }

        return entreprise;
    }

    createVirgin(uuid?: string, id?: number): Entreprise {
        return new Entreprise(uuid ?? Entreprise.statuts.NEW, id);
    }

    forApi(entreprise: Entreprise): IEntrepriseApi {
        const entrepriseApi = {} as IEntrepriseApi;

        entrepriseApi.adresse = entreprise.adresse;
        entrepriseApi.capital = entreprise.capital;
        entrepriseApi.codePostal = entreprise.codePostal;
        entrepriseApi.comments = entreprise.comments;
        entrepriseApi.communeId = entreprise.communeId;
        entrepriseApi.emailAddress = entreprise.emailAddress;
        entrepriseApi.formeJuridique = entreprise.formeJuridique;
        entrepriseApi.mobile = this._phoneNumberService.getE164(entreprise.mobile);
        entrepriseApi.raisonSociale = entreprise.raisonSociale;
        entrepriseApi.siret = entreprise.siret;
        entrepriseApi.telephone = this._phoneNumberService.getE164(entreprise.telephone);

        return entrepriseApi;
    }

    ngCreate(ngContact: NgContact): Entreprise {
        const entreprise = this.createVirgin(ngContact.uuid, ngContact.id);

        entreprise.adresse = ngContact.entreprise!.adresse;
        entreprise.capital = ngContact.entreprise!.capital;
        entreprise.codePostal = ngContact.entreprise!.codePostal;
        entreprise.colorVariation = ngContact.entreprise!.colorVariation;
        entreprise.comments = ngContact.entreprise!.comments;
        entreprise.emailAddress = ngContact.entreprise!.emailAddress;
        entreprise.formeJuridique = ngContact.entreprise!.formeJuridique;
        entreprise.initiales = ngContact.entreprise!.initiales;
        entreprise.mobile = this._phoneNumberService.getUsable(ngContact.entreprise!.mobile);
        entreprise.raisonSociale = ngContact.entreprise!.raisonSociale;
        entreprise.siret = ngContact.entreprise!.siret;
        entreprise.telephone = this._phoneNumberService.getUsable(ngContact.entreprise!.telephone);

        if (ngContact.entreprise!._links?.commune) {
            entreprise.linkCommune = ngContact.entreprise!._links.commune.href;
        }

        if (ngContact.entreprise!.commune) {
            entreprise.communeId = ngContact.entreprise!.commune.id;
        }

        return entreprise;
    }
}
