import {inject, Injectable} from '@angular/core';
import {NgBien, NgBienCadastre} from '@legacy/app/managers/ressources';
import {Observable, of} from 'rxjs';
import {BienCadastreFactory} from '@models/bien/cadastres/cadastre/bien-cadastre.factory';
import {map, switchMap, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {
    BienCadastreDoubleMandatFactory
} from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgCommuneManager} from '@legacy/app/managers/managers';
import BienCadastreDoubleMandat from '@models/bien/cadastres/cadastre/double-mandat/bien-cadastre-double-mandat.model';
import BienCadastre from '@models/bien/cadastres/cadastre/bien-cadastre.model';
import {EtudeService} from '@models/etudes/etude/etude.service';

@Injectable({providedIn: 'root'})
export class BienCadastreService {
    private _bienCadastreDoubleMandatFactory = inject(BienCadastreDoubleMandatFactory);
    private _bienCadastreFactory = inject(BienCadastreFactory);
    private _communeFactory = inject(CommuneFactory);
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);

    // Supprimer les injections des anciens manager
    private get ngCommuneManager(): NgCommuneManager {
        return this._ngInjectorService.getService('CommuneManager');
    }

    cancelUpdate$(dossierBien: ADossierBien, ngBienCadastre: NgBienCadastre): Observable<void> {
        return this._bienCadastreFactory.get$(dossierBien, ngBienCadastre.id).pipe(
            switchMap(bienCadastre => {
                ngBienCadastre.lot = bienCadastre.lot;
                ngBienCadastre.parcelle = bienCadastre.parcelle;
                ngBienCadastre.section = bienCadastre.section;
                ngBienCadastre.surface = bienCadastre.surface;

                return this._communeFactory.get$(bienCadastre.communeId).pipe(
                    map(commune => this.ngCommuneManager.createFromNg2(commune)),
                    tap(ngCommune => ngBienCadastre.commune = ngCommune),
                );
            }),
            map(() => undefined!),
        );
    }

    checkDoubleMandat$(dossierBien: ADossierBien, bienCadastre: BienCadastre): Observable<BienCadastreDoubleMandat> {
        return this._etudeService.last$.pipe(switchMap(currentEtude => {
            if (dossierBien.isVente() && !dossierBien.isNewOrBrouillon() && currentEtude.hasNetwork()) {
                return this._bienCadastreDoubleMandatFactory.checkDoubleMandat$(bienCadastre, dossierBien.id);
            }

            return of(undefined as unknown as BienCadastreDoubleMandat);
        }));
    }

    ngSave$(dossierBien: ADossierBien, ngBien: NgBien, ngBienCadastre: NgBienCadastre): Observable<NgBienCadastre> {
        const bienCadastre = this._bienCadastreFactory.ngCreate(ngBienCadastre);

        return this.checkDoubleMandat$(dossierBien, bienCadastre).pipe(
            switchMap(bienCadastreDoubleMandat => {
                if (bienCadastreDoubleMandat) {
                    return this.cancelUpdate$(dossierBien, ngBienCadastre).pipe(
                        switchMap(() => {
                            let comments = `La référence cadastrale <span class="tw-font-bold">${bienCadastre.convivialName}</span> est identique`;

                            comments += ` à celle saisie pour le bien à vendre <span class="tw-font-bold">${bienCadastreDoubleMandat.bienReference}</span>`;
                            if (bienCadastreDoubleMandat.type === 'doubleMandat') {
                                comments += ` de l'étude <span class="tw-font-bold">${bienCadastreDoubleMandat.etudeRaisonSociale}</span>.`;
                            } else {
                                comments += ' de votre étude.';
                            }

                            comments += `<br><br>Votre bien en vente <span class="tw-font-bold">${dossierBien.reference}</span> ayant déjà une date de mandat`;
                            comments += ', il n\'est pas possible de saisir cette référence cadastrale.';

                            return this._modalService.openInformation$({
                                comments,
                                status: ModalService.status.WARNING,
                                title: `Bien en ${bienCadastreDoubleMandat.title}`,
                            });
                        }),
                        map(() => undefined!),
                    );
                }

                return ngBienCadastre.saveFromBien(ngBien, true);
            }),
        );
    }
}
