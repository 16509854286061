import {Component, inject, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import Contact from '@models/contacts/contact/contact.model';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import Person from '@models/contacts/person/person.model';
import {IContactConvivialNameOptions} from '@features/contacts/contacts.interfaces';
import Etude from '@core/models/etudes/etude/etude.model';

@Component({selector: 'app-contact-convivial-name', templateUrl: 'contact.convivial-name.component.html'})
export class AppContactConvivialNameComponent {
    static readonly initContactConvivialNameOptions: IContactConvivialNameOptions = {withExternalSource: false};
    readonly sources = Contact.sources;
    private _contactFactory = inject(ContactFactory);
    private _contactSource = new BehaviorSubject<Contact>(undefined!);
    private _contact$ = this._contactSource.asObservable().pipe(
        tap(contact => {
            if (contact) {
                this._titleRepresente = 'Représenté' + (contact.isEntreprise() || (contact.isPerson() && (contact as Person).titre?.code === Person.titres.MADAME) ? 'e' : '');
            } else {
                this._titleRepresente = undefined!;
            }
        })
    );
    private _options: IContactConvivialNameOptions = {...AppContactConvivialNameComponent.initContactConvivialNameOptions};
    private _titleRepresente!: string;

    get contact$(): Observable<Contact> {
        return this._contact$;
    }

    @Input()
    set contact(value: Contact) {
        this._contactSource.next(value);
    }

    get INOT_LABEL(): string {
        return Etude.enablableModules.INOT.label;
    }

    @Input()
    set linkContact(value: string) {
        this._contactFactory.getByLink$(value).pipe(take(1)).subscribe(contact => this._contactSource.next(contact));
    }

    get options(): IContactConvivialNameOptions {
        return this._options;
    }

    @Input()
    set options(value: IContactConvivialNameOptions) {
        this._options = {...AppContactConvivialNameComponent.initContactConvivialNameOptions, ...value};
    }

    get titleRepresente(): string {
        return this._titleRepresente;
    }
}
