export default class EtudeSettingsNetwork {
    private _compromisActif!: boolean;
    private _matchingDemandeurs!: boolean;

    get compromisActif(): boolean {
        return this._compromisActif;
    }

    set compromisActif(value: boolean) {
        this._compromisActif = value;
    }

    get matchingDemandeurs(): boolean {
        return this._matchingDemandeurs;
    }

    set matchingDemandeurs(value: boolean) {
        this._matchingDemandeurs = value;
    }
}
