export default `
<div class="task-runner">
  <div ng-if="$ctrl.taskRunner.getTasks().length > 0" class="upload-files">
    <h4>{{'task-runner.upload-progress.TITLE' | translate:'{nbFiles: ' + $ctrl.taskRunner.getTasks().length + '}'}}</h4>

    <div ng-repeat="task in $ctrl.taskRunner.getTasks() | limitTo:3" class="upload-file">
      <ng-switch on="!!task.infoClient.uisref && !!task.infoClient.uisref.name">
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl(task.infoClient.uisref.name, task.infoClient.uisref.params)"
           ng-switch-when="true">{{task.infoClient.title}}</a>

        <div ng-switch-default>{{task.infoClient.title}}</div>
      </ng-switch>

      <div class="row">
        <div ng-switch="task.infoClient.media.file._esk.status" class="col-sm-10">
          <div ng-switch-when="WAITING" class="process-status">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>
            {{'task-runner.upload-progress.file.waiting.MESSAGE' | translate}}...
          </div>

          <div ng-switch-when="IN_PROGRESS" class="process-status"
               ng-show="0 <= task.infoClient.media.file._esk.progress || task.infoClient.media.file._esk.progress <= 100">
            <uib-progressbar class="progress-striped" value="task.infoClient.media.file._esk.progress">
              {{task.infoClient.media.file._esk.progress}} %
            </uib-progressbar>
          </div>

          <div ng-switch-when="UPLOADED" class="process-status">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>
            {{'task-runner.upload-progress.file.uploaded.MESSAGE' | translate}}...
          </div>
        </div>

        <div class="col-sm-2">
          <button type="button" class="btn btn-default btn-o" ng-click="$ctrl.taskRunner.removeTask(task)"
                  title="Annuler le téléchargement"><fa-icon [icon]="'times'"></fa-icon></button>
        </div>
      </div>
    </div>

    <div ng-if="$ctrl.taskRunner.getTasks().length > 3" class="upload-file-more">
      {{'task-runner.upload-progress.waiting.MESSAGE' | translate:'{nbFiles: ' + ($ctrl.taskRunner.getTasks().length - 3) + '}'}}
    </div>
  </div>
</div>
`;
