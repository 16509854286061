<div class="tw-bg-gray-50 tw-px-4 tw-py-6 sm:tw-px-6">
  <h2 class="tw-mr-8 tw-text-base tw-font-semibold tw-text-gray-900">Diffusions</h2>
</div>

<div class="tw-p-8">
  @if (etudePasserelles$ | async; as etudePasserelles) {
    @if (etudePasserelles.length > 0) {
      @if (cVentePasserelles$ | async; as cVentePasserelles) {
        <ul class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-8">
          @for (etudePasserelle of etudePasserelles; track etudePasserelle) {
            <li class="tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-shadow tw-p-4 tw-divide-y tw-divide-solid">
              <app-etude-passerelle [etudePasserelle]="etudePasserelle" class="tw-block tw-flex-1"/>

              <div class="tw-mx-auto tw-pt-4">
                <ng-container *ngTemplateOutlet="actions;context:{ventePasserelle: cVentePasserelles.getByPasserelleId(etudePasserelle.passerelle.id)}"/>

                <ng-template #actions let-ventePasserelle="ventePasserelle">
                  @if ($any(ventePasserelle)?.dateDiffusion) {
                    <button type="button" class="tw-btn-secondary-default"
                            (click)="retire($any(ventePasserelle), etudePasserelle)">Retirer</button>
                  } @else {
                    <button type="button" class="tw-btn-primary-info"
                            (click)="diffuse($any(ventePasserelle), etudePasserelle)">Diffuser</button>
                  }
                </ng-template>
              </div>
            </li>
          }
        </ul>
      } @else {
        <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
      }
    } @else {
      Aucune passerelle n'est paramétrée pour envoyer ce bien.
    }

    @if (vente.interne) {
      <div class="tw-mt-8 text-right">
        <a class="tw-font-semibold" [routerLink]="'/app/ventes/' + vente.id.toString() + '/diffusion'">
          Voir les diffusions du bien
        </a>
      </div>
    }
  } @else {
    <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
  }
</div>
