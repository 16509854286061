import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import {
    CCityscanOnsalePropertiesFactory
} from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.factory';
import CCityscanOnsaleProperties
    from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.model';
import {
    ICCityscanOnsalePropertiesQueryParameters
} from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.interfaces';
import Estimation from '@models/estimations/estimation/estimation.model';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

@Injectable({providedIn: 'root'})
export class CCityscanOnsalePropertiesService {
    private _cCityscanOnsalePropertiesFactory = inject(CCityscanOnsalePropertiesFactory);

    get$(queryParameters?: ICCityscanOnsalePropertiesQueryParameters): Observable<CCityscanOnsaleProperties> {
        return this._cCityscanOnsalePropertiesFactory.get$(queryParameters, {headers: {handledTypeErrors: [EtudeSettingsCityscan.REQUEST_ERRORS.cityscan_not_localizable]}});
    }

    getByEstimationAndSearchCriteria$(estimation: Estimation, searchCriteria: SearchCriteria): Observable<CCityscanOnsaleProperties> {
        return this.get$({
            ...this._cCityscanOnsalePropertiesFactory.getQueryParametersFromSearchCriteria(searchCriteria),
            estimationUuid: estimation.uuid
        });
    }
}
