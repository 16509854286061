import {NgModule} from '@angular/core';
import {AppEmailStatutComponent} from '@features/emails/email/statut/email.statut.component';
import {CommonModule} from '@angular/common';
import {
    AppEmailRecipientStatutComponent
} from '@features/emails/email/recipients/recipient/statut/email-recipient.statut.component';
import {AppEmailPopoverComponent} from '@features/emails/email/popover/email.popover.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [AppEmailPopoverComponent, AppEmailRecipientStatutComponent, AppEmailStatutComponent],
    exports: [AppEmailPopoverComponent, AppEmailRecipientStatutComponent, AppEmailStatutComponent],
    imports: [CommonModule, SharedModule],
})
export class EmailsModule {
}
