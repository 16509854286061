export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()" title="Fermer">
    <fa-icon [icon]="'times'"></fa-icon>
  </button>

  <h3 class="modal-title"><fa-icon [icon]="'user'"></fa-icon> Impersonnalisation</h3>
</div>

<div class="modal-body">
  <esk-select-user roles="$ctrl.usersRoles" ng-model="$ctrl.userImpersonate"></esk-select-user>
</div>

<div class="modal-footer">
  <button type="button" ng-click="$ctrl.impersonate()" class="btn btn-primary">
    <fa-icon [icon]="'user'"></fa-icon> Impersonnaliser
  </button>
</div>
`;
