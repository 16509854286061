import angularJS from '@shared/angularJS/global.ng';
import {find, map} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgDemandeurManager, NgDemandeurRechercheResultatManager, NgFilterManager} from '@legacy/app/managers/managers';
import {NgBienDossier, NgFilter, NgRechercheResult} from '@legacy/app/managers/ressources';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';

export default function getDemandeurResultsDetailsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDemandeurResultsDetailsCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/controllers/item/results/details.html',
        });

        /**
         * Controller for display result details
         *
         * @param Ng2RouterStateForNgService
         * @param DemandeurManager
         * @param $translate
         * @param DemandeurRechercheResultatManager
         * @param $q
         * @param Ng2ToasterService
         * @param FilterManager
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'DemandeurManager', '$translate', 'DemandeurRechercheResultatManager', '$q', 'Ng2ToasterService', 'FilterManager'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            demandeurManager: NgDemandeurManager,
                            $translate: angular.translate.ITranslateService,
                            demandeurRechercheResultatManager: NgDemandeurRechercheResultatManager,
                            $q: IQService,
                            ng2ToasterService: ToasterService,
                            filterManager: NgFilterManager) {
            const $ctrl = this;
            let gettingResults = $q.resolve();
            let promise: IPromise<NgRechercheResult>;
            var currentResult;

            $ctrl.replaceLocation = replaceLocation;
            $ctrl.setInterest = setInterest;
            $ctrl.backToList = backToList;
            $ctrl.loader = {updating: false, resultGetting: true};
            $ctrl.demandeur = demandeurManager.current;
            $ctrl.search = $ctrl.demandeur.recherches[0];
            if (!angular.isObject($ctrl.search.results)) {
                $ctrl.search.results = {};
            }

            if (!angular.isArray($ctrl.search.results.collection)) {
                $ctrl.search.results.collection = [];
            }

            if (!$ctrl.search._esk.dossiersCorrespondants) {
                gettingResults = filterManager.getActiveByRouteCode(Filter.routes.GET_RECHERCHE_RESULTATS, Filter.codes.WALLET_CURRENT).then(filter => filter as NgFilter).then(filter => {
                    return $ctrl.search.getFilteredResults(filter).then(() => {
                        angular.forEach($ctrl.search._esk.dossiersCorrespondants.collection, (dossier) => {
                            const predicate = {[dossier._esk.typeDossier]: {id: dossier.id}};

                            dossier._esk.routing = '/app/demandeurs/' + demandeurManager.current.id.toString() + '/resultats/' + find($ctrl.search.results.collection, predicate).id.toString();
                        });
                        demandeurManager.current.recherches[0] = angular.copy($ctrl.search);
                    });
                });
            }

            gettingResults.then(() => {
                const resultId = +(ng2RouterStateForNgService.params.resultId as string);

                currentResult = find($ctrl.search.results.collection, {id: resultId});
                if (angular.isObject(currentResult)) {
                    promise = $q.resolve(currentResult);
                } else {
                    $ctrl.search = angular.copy($ctrl.search);
                    $ctrl.search.results.totalItems = 0;
                    promise = demandeurRechercheResultatManager.getOneById($ctrl.search.id, resultId).then((result) => {
                        $ctrl.search.results.collection = [result];
                        $ctrl.search.setDossiersCorrespondants($ctrl.search.results);

                        return result;
                    });
                }

                return promise.then(result => $ctrl.currentDossierId = result[result.type as DossierBienType].id);
            }).finally(() => $ctrl.loader.resultGetting = false);

            /**
             * Replace location with current
             *
             * @param dossier
             */
            function replaceLocation(dossier: NgBienDossier) {
                if (!dossier) {
                    return;
                }

                const predicate = {[dossier._esk.typeDossier!]: {id: dossier.id}};
                const params = {
                    resultId: find($ctrl.search.results.collection, predicate).id,
                    [dossier._esk.typeDossier + 'Id']: ng2RouterStateForNgService.params[dossier._esk.typeDossier + 'Id'],
                };

                if (params.resultId) {
                    ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/resultats/' + params.resultId.toString());
                } else {
                    throw new Error('CAS NON GÉRÉ')
                }
            }

            /**
             * Set interest for current result
             *
             * @param result
             * @param interest
             * @return {*}
             */
            function setInterest(result: NgRechercheResult, interest: string) {
                if (!angular.isObject(result)) {
                    throw new Error(JSON.stringify({
                        interest: interest,
                        results: map($ctrl.search.results.collection, 'id'),
                        dossiersCorrespondants: map($ctrl.search._esk.dossiersCorrespondants.collection, 'id')
                    }));
                }

                result.interest = interest;

                return result.save().then(() => $ctrl.demandeur.updateSearchStatusAndEmitCurrent()).then(() => {
                    ng2ToasterService.success($translate.instant('demandeur.results.TITLE', {nbResults: 1}), $translate.instant('demandeur.results.action.MESSAGE', {
                        nbResults: 1,
                        state: demandeurRechercheResultatManager.stringifyInterest(interest),
                    }));
                });
            }

            /**
             * Back to list of results
             */
            function backToList() {
                ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + ng2RouterStateForNgService.params.demandeurId + '/resultats');
            }
        }
    })(angularJS);
}
