import {inject, Injectable} from '@angular/core';
import BienDiagnostic from '@models/bien/diagnostics/diagnostic/bien-diagnostic.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgBienDiagnostic} from '@legacy/app/managers/ressources';
import {IBienDiagnosticApi} from '@models/bien/diagnostics/diagnostic/bien-diagnostic.interfaces';
import DateFormat from '@shared/date/date.format';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BienDiagnosticsApiService} from '@models/bien/diagnostics/bien-diagnostics.api.service';

@Injectable({providedIn: 'root'})
export class BienDiagnosticFactory {
    private _bienDiagnosticsApiService = inject(BienDiagnosticsApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienDiagnosticApi: IBienDiagnosticApi): BienDiagnostic {
        const bienDiagnostic: BienDiagnostic = new BienDiagnostic(bienDiagnosticApi.uuid, bienDiagnosticApi.id);

        bienDiagnostic.date = bienDiagnosticApi.date;
        bienDiagnostic.comments = bienDiagnosticApi.comments;
        if (bienDiagnosticApi._embedded?.typeDiagnostic) {
            bienDiagnostic.type = this._dictionaryItemFactory.create(bienDiagnosticApi._embedded.typeDiagnostic);
        }

        return bienDiagnostic;
    }

    forApi(bienDiagnostic: BienDiagnostic): IBienDiagnosticApi {
        const bienDiagnosticApi = {
            comments: bienDiagnostic.comments,
            date: DateFormat.toAPI(bienDiagnostic.date),
        } as IBienDiagnosticApi;

        if (bienDiagnostic.type) {
            bienDiagnosticApi.typeDiagnosticId = bienDiagnostic.type.id;
        }

        return bienDiagnosticApi;
    }

    save$(dossierBien: ADossierBien, bienDiagnostic: BienDiagnostic): Observable<BienDiagnostic> {
        return this._bienDiagnosticsApiService.save$(dossierBien.id.toString(), bienDiagnostic.id?.toString() ?? BienDiagnostic.STATUT_NEW, this.forApi(bienDiagnostic))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }

    ngCreate(ngBienDiagnostic: NgBienDiagnostic): BienDiagnostic {
        const bienDiagnostic: BienDiagnostic = new BienDiagnostic(ngBienDiagnostic.uuid, ngBienDiagnostic.id);

        bienDiagnostic.comments = ngBienDiagnostic.comments;
        bienDiagnostic.date = DateFormat.toJSON(ngBienDiagnostic.date);
        if (ngBienDiagnostic.typeDiagnostic) {
            bienDiagnostic.type = this._dictionaryItemFactory.ngCreate(ngBienDiagnostic.typeDiagnostic);
        }

        return bienDiagnostic;
    }
}
