import {DossierType} from '@models/dossiers/dossiers.interfaces';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

class DossierTypes {
    static readonly DEMANDEUR: DossierType = 'demandeur';
    static readonly ESTIMATION: DossierType = DossierBienTypesConst.ESTIMATION;
    static readonly LOCATION: DossierType = DossierBienTypesConst.LOCATION;
    static readonly VENTE: DossierType = DossierBienTypesConst.VENTE;
}

export const DossierTypesConst = DossierTypes;
