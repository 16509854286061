import Passerelle from '@models/passerelle/passerelle.model';
import AModel from '@models/model.abstract';

export default class EtudePasserelle extends AModel {
    private _biensMaxNumber!: number;
    private _configurator: string = null!;
    private _nbBiensActifsEnvoyes!: number;
    private _nbLocationsActivesEnvoyees!: number;
    private _nbVentesActivesEnvoyees!: number;
    private _passerelle!: Passerelle;
    private _statut: string = null!;

    get biensMaxNumber(): number {
        return this._biensMaxNumber;
    }

    set biensMaxNumber(value: number) {
        this._biensMaxNumber = value;
    }

    get configurator(): string {
        return this._configurator;
    }

    set configurator(value: string) {
        this._configurator = value;
    }

    get nbBiensActifsEnvoyes(): number {
        return this._nbBiensActifsEnvoyes;
    }

    set nbBiensActifsEnvoyes(value: number) {
        this._nbBiensActifsEnvoyes = value;
    }

    get nbLocationsActivesEnvoyees(): number {
        return this._nbLocationsActivesEnvoyees;
    }

    set nbLocationsActivesEnvoyees(value: number) {
        this._nbLocationsActivesEnvoyees = value;
    }

    get nbVentesActivesEnvoyees(): number {
        return this._nbVentesActivesEnvoyees;
    }

    set nbVentesActivesEnvoyees(value: number) {
        this._nbVentesActivesEnvoyees = value;
    }

    get passerelle(): Passerelle {
        return this._passerelle;
    }

    set passerelle(value: Passerelle) {
        this._passerelle = value;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
    }

    hasConfigurator(): boolean {
        return !!this.configurator;
    }

    isNewspaper(): boolean {
        return this.passerelle.isNewspaper();
    }

    isSocialNetwork(): boolean {
        return this.passerelle.isSocialNetwork();
    }

    isWebsite(): boolean {
        return this.passerelle.isWebsite();
    }
}
