import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AppParticipantsSelectComponent} from '@features/participants/select/participants-select.component';

@NgModule({
    declarations: [AppParticipantsSelectComponent],
    exports: [AppParticipantsSelectComponent],
    imports: [CommonModule, SharedModule],
})
export class ParticipantsModule {
}
