@if (contactsGroup$ | async; as contactsGroup) {
  <span title="{{contactsGroup.nom}}" class="tw-flex tw-items-center">
    <span class="tw-truncate" [highlight]="options.highlight">
      {{ contactsGroup.hasNom() ? contactsGroup.nom : 'Dossier sans nom' }}
    </span>
  </span>
} @else {
  <app-loader class="tw-inline-block tw-min-w-[6rem]"/>
}

