import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {take} from 'rxjs/operators';
import Contact from '@models/contacts/contact/contact.model';

export default function getContactModalEditCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('ContactModalEditCtrl', Controller);

        /**
         * Controller to edit contacts group
         *
         * @param contact
         * @param forceSubmit
         * @param $uibModalInstance
         * @param Ng2ContactFactory
         */
        Controller.$inject = ['contact', 'forceSubmit', '$uibModalInstance', 'Ng2ContactFactory'];
        function Controller(this: any,
                            contact: Contact,
                            forceSubmit: boolean,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            ng2ContactFactory: ContactFactory) {
            const $ctrl = this;

            $ctrl.onSave = onSave;
            $ctrl.contact = contact;
            $ctrl.forceSubmit = forceSubmit;

            /**
             * Close on save
             */
            function onSave(contactId: number) {
                ng2ContactFactory.getByLink$('/contacts/' + contactId.toString())
                    .pipe(take(1))
                    .subscribe(updatedContact => $uibModalInstance.close(updatedContact));
            }
        }
    })(angularJS);
}
