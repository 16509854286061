import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {AppNoteoIconTextInotComponent} from '@shared/noteo/icon-text/inot/noteo-icon-text.inot.component';
import {AppNoteoIconTextComponent} from '@shared/noteo/icon-text/noteo-icon-text.component';
import {
    AppNoteoIconTextCityscanComponent
} from '@shared/noteo/icon-text/cityscan/noteo-icon-text.cityscan.component';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {AppNoteoStatutComponent} from '@shared/noteo/statut/noteo-statut.component';
import {AppNoteoLogoNotestimComponent} from '@shared/noteo/logo/notestim/noteo-logo.notestim.component';

@NgModule({
    declarations: [
        AppNoteoContactComponent,
        AppNoteoIconTextCityscanComponent,
        AppNoteoIconTextComponent,
        AppNoteoIconTextInotComponent,
        AppNoteoIconTextNotyComponent,
        AppNoteoLogoNotestimComponent,
        AppNoteoStatutComponent,
    ],
    exports: [
        AppNoteoContactComponent,
        AppNoteoIconTextCityscanComponent,
        AppNoteoIconTextComponent,
        AppNoteoIconTextInotComponent,
        AppNoteoIconTextNotyComponent,
        AppNoteoLogoNotestimComponent,
        AppNoteoStatutComponent,
    ],
    imports: [CommonModule, NgOptimizedImage],
})
export class NoteoModule {
}
