export default `
<ui-select soq-multiple="true" ng-model="$ctrl.ngModel" on-select="$ctrl.options.onSelect($item, $model)"
           on-remove="$ctrl.options.onRemove($item, $model)" name="{{$ctrl.options.name}}">
  <ui-select-match allow-clear="{{!$ctrl.options.required}}" placeholder="{{$ctrl.options.placeholder}}">
    {{$item.name}}
  </ui-select-match>

  <ui-select-choices repeat="item in $ctrl.list | filter: $select.search"
                     ui-disable-choice="$ctrl.isSelected(itemDictionary)">
    <p>{{item.name}}</p>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
