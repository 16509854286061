import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICNotestimBiensQueryParameters, ICNotestimBiensApi
} from '@models/notestim-biens/collection/notestim-biens.collection.interfaces';
import {tap} from 'rxjs/operators';
import {IVenteApi} from '@models/ventes/vente/vente.interfaces';

@Injectable({providedIn: 'root'})
export class NotestimBiensApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(queryParameters?: ICNotestimBiensQueryParameters): Observable<ICNotestimBiensApi> {
        return this._modelApiService.getCollection$<ICNotestimBiensApi>(this._modelApiService.pathWithQueryParameters('/notestim/biens', queryParameters)).pipe(tap(cNotestimBiensApi => {
            if (cNotestimBiensApi._embedded?.items) {
                cNotestimBiensApi.total = cNotestimBiensApi._embedded.items.length;
            }
        }));
    }

    import$(notestimId: number): Observable<IVenteApi> {
        return this._modelApiService.patch$('/notestim/biens/' + notestimId.toString() + '/import');
    }
}
