import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEstimationLabelStatut(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimation-label-statut></esk-estimation-label-statut>
         */
        module.component('eskEstimationLabelStatut', {
            bindings: {estimation: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/item/label/statut.html'
        });

        Controller.$inject = [];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.STATUTS = Estimation.statuts;
        }
    })(angularJS);
}
