import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewDiagnosticsService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isDiagnosticsRealisesVisible!: boolean;
    private _isPerformanceEnergetiqueCommentsVisible!: boolean;
    private _isPartVisible!: boolean;

    get isDiagnosticsRealisesVisible(): boolean {
        return this._isDiagnosticsRealisesVisible;
    }

    get isPerformanceEnergetiqueCommentsVisible(): boolean {
        return this._isPerformanceEnergetiqueCommentsVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isDiagnosticsRealisesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.diagnosticsRealises);
        this._isPerformanceEnergetiqueCommentsVisible = !!dossierBien.bien.performanceEnergetique.comments;

        this._isPartVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.performanceEnergetique)
            && (this.isDiagnosticsRealisesVisible || this.isPerformanceEnergetiqueCommentsVisible);
    }
}
