import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewCompromisService {
    private _isPartVisible!: boolean;

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        const vente = dossierBien as Vente;

        this._isPartVisible = dossierBien.isVente() && !!vente.linkCompromisActif;
    }
}
