export default `
<div>La recherche s'effectuera sur les champs énumérés ci-dessous.</div>
<div class="text-bold">Propriétaire(s) :</div>
<div>- Nom (groupe des propriétaires)</div>
<div>- Nom(s) et prénom(s)</div>
<div>- Téléphone(s) et mobile(s)</div>
<div>- Adresse(s) email</div>
<div class="text-bold">Location :</div>
<div>- Référence, référence interne et ancienne référence</div>
<div>- Titre et descriptif</div>
<div>- Rue, code postal et commune</div>
`;
