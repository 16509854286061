import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import Person from '@models/contacts/person/person.model';
import {NgContact} from '@legacy/app/managers/ressources';
import {IContactApi, IPersonApi} from '@models/contacts/contacts.interfaces';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Injectable({providedIn: 'root'})
export class PersonFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _phoneNumberService = inject(PhoneNumberService);

    createFromContactApi(contactApi: IContactApi): Person {
        const person = this.createVirgin(contactApi.uuid, contactApi.id);

        if (contactApi._embedded?.person) {
            person.adresse = contactApi._embedded.person.adresse;
            person.autresPrenoms = contactApi._embedded.person.autresPrenoms;
            person.codePostal = contactApi._embedded.person.codePostal;
            person.colorVariation = contactApi._embedded.person.colorVariation;
            person.comments = contactApi._embedded.person.comments;
            person.communeId = contactApi._embedded.person.communeId;
            person.communeNaissance = contactApi._embedded.person.communeNaissance;
            person.dateNaissance = contactApi._embedded.person.dateNaissance;
            person.emailAddress = contactApi._embedded.person.emailAddress;
            person.etranger = contactApi._embedded.person.etranger;
            person.initiales = contactApi._embedded.person.initiales;
            person.mobile = this._phoneNumberService.getUsable(contactApi._embedded.person.mobile);
            person.nationalite = contactApi._embedded.person.nationalite;
            person.nom = contactApi._embedded.person.nom;
            person.nomJeuneFille = contactApi._embedded.person.nomJeuneFille;
            person.prenom = contactApi._embedded.person.prenom;
            person.profession = contactApi._embedded.person.profession;
            person.telephone = this._phoneNumberService.getUsable(contactApi._embedded.person.telephone);

            if (contactApi._embedded.person._links?.commune) {
                person.linkCommune = contactApi._embedded.person._links.commune.href;
            }

            if (contactApi._embedded.person.titre) {
                person.titre = this._dictionaryItemService.getByCode(Dictionary.names.TITRES, contactApi._embedded.person.titre);
            }
        }

        return person;
    }

    createVirgin(uuid?: string, id?: number): Person {
        return new Person(uuid ?? Person.statuts.NEW, id);
    }

    forApi(person: Person): IPersonApi {
        const personApi = {} as IPersonApi;

        personApi.adresse = person.adresse;
        personApi.autresPrenoms = person.autresPrenoms;
        personApi.codePostal = person.codePostal;
        personApi.comments = person.comments;
        personApi.communeId = person.communeId;
        personApi.communeNaissance = person.communeNaissance;
        personApi.dateNaissance = person.dateNaissance;
        personApi.emailAddress = person.emailAddress;
        personApi.etranger = person.etranger;
        personApi.mobile = this._phoneNumberService.getE164(person.mobile);
        personApi.nationalite = person.nationalite;
        personApi.nom = person.nom;
        personApi.nomJeuneFille = person.nomJeuneFille;
        personApi.prenom = person.prenom;
        personApi.profession = person.profession;
        personApi.telephone = this._phoneNumberService.getE164(person.telephone);
        personApi.titre = person.titre.code;

        return personApi;
    }

    ngCreate(ngContact: NgContact): Person {
        const person = this.createVirgin(ngContact.uuid, ngContact.id);

        person.adresse = ngContact.person!.adresse;
        person.autresPrenoms = ngContact.person!.autresPrenoms;
        person.codePostal = ngContact.person!.codePostal;
        person.colorVariation = ngContact.person!.colorVariation;
        person.comments = ngContact.person!.comments;
        person.communeNaissance = ngContact.person!.communeNaissance;
        person.dateNaissance = ngContact.person!.dateNaissance;
        person.emailAddress = ngContact.person!.emailAddress;
        person.etranger = ngContact.person!.etranger;
        person.initiales = ngContact.person!.initiales;
        person.mobile = this._phoneNumberService.getUsable(ngContact.person!.mobile);
        person.nationalite = ngContact.person!.nationalite;
        person.nom = ngContact.person!.nom;
        person.nomJeuneFille = ngContact.person!.nomJeuneFille;
        person.prenom = ngContact.person!.prenom;
        person.profession = ngContact.person!.profession;
        person.telephone = this._phoneNumberService.getUsable(ngContact.person!.telephone);

        if (ngContact.person!._links?.commune) {
            person.linkCommune = ngContact.person!._links.commune.href;
        }

        if (ngContact.person!.commune) {
            person.communeId = ngContact.person!.commune.id;
        }

        if (ngContact.person!.titre) {
            person.titre = this._dictionaryItemFactory.ngCreate(ngContact.person!.titre);
        }

        return person;
    }
}
