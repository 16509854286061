export default `
<div class="container container-footer">
  <div class="row">
    <div class="col-logo col-sm-4">
      <h6><app-ng2-img [src]="'assets/images/noty/logo.svg'"></app-ng2-img></h6><hr>

      <p>
        <app-noteo-icon-text-noty [src-image]="''"></app-noteo-icon-text-noty> est une solution dédiée<br>à la
        négociation notariale<br>proposée par <a href="https://www.soqrate.fr/" target="_blank">Noteo</a>.
      </p>
    </div>

    <div class="col-sm-4">
      <h6 class="text-uppercase font-weight-bold">Services</h6><hr>

      <p><a href="https://noty.fr/logiciel-negociation-notaire" target="_blank">Noty négociation</a></p>

      <p><a href="https://noty.fr/application-negociation-notaire" target="_blank">Noty iOS</a></p>

      <p><a href="https://noty.fr/carrousel-office-notarial" target="_blank">Noty Tv</a></p>

      <p><a href="https://noty.fr/communication-digitale" target="_blank">Noty Communication</a></p>
    </div>

    <div class="col-sm-4">
      <h6 class="text-uppercase font-weight-bold">Nous contacter</h6><hr>

      <div>
        <div class="col-icon"><fa-icon [icon]="'home'"></fa-icon></div>

        <div class="col-info">7 chemin du Clos Doré 49480 Verrières en Anjou</div>

        <div class="col-icon"><fa-icon [icon]="'envelope'"></fa-icon></div>

        <div class="col-info">
          <a href="mailto:{{$ctrl.COMMERCE.emailAddress}}" target="_blank">{{$ctrl.COMMERCE.emailAddress}}</a>
        </div>

        <div class="col-icon"><fa-icon [icon]="'phone'"></fa-icon></div>

        <div class="col-info"><soq-phone></soq-phone></div>
      </div>
    </div>
  </div>
</div>

<div class="footer-copyright tw-text-center tw-py-3">
  © {{$ctrl.currentYear}} Copyright : <a href="https://www.soqrate.fr/" target="_blank"> Noteo</a>
</div>
`;
