import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ICDossierFilesApi} from '@models/dossiers/dossier/files/collection/dossier-files.collection.interfaces';
import {IDossierFileApi} from '@models/dossiers/dossier/files/file/dossier-file.interfaces';

@Injectable({providedIn: 'root'})
export class DossierFilesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(dossierType: string, dossierUuid: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$('/' + dossierType + 's/' + dossierUuid + '/files/' + uuid);
    }

    getCollection$(dossierType: string, dossierUuid: string): Observable<ICDossierFilesApi> {
        return this.getCollectionByLink$('/' + dossierType + 's/' + dossierUuid + '/files');
    }

    getCollectionByLink$(link: string): Observable<ICDossierFilesApi> {
        return this._modelApiService.getCollection$(link);
    }

    save$(dossierType: string, dossierUuid: string, dossierFileApi: IDossierFileApi): Observable<IDossierFileApi> {
        return this._modelApiService.post$('/' + dossierType + 's/' + dossierUuid + '/files', dossierFileApi);
    }

    share$(dossierType: string, dossierUuid: string, uuid: string, contactId: number): Observable<void> {
        return this._modelApiService.patch$('/' + dossierType + 's/' + dossierUuid + '/files/' + uuid + '/share', {contactId});
    }

    unshare$(dossierType: string, dossierUuid: string, uuid: string, contactId: number): Observable<void> {
        return this._modelApiService.patch$('/' + dossierType + 's/' + dossierUuid + '/files/' + uuid + '/unshare', {contactId});
    }
}
