export default class BienCadastreDoubleMandat {
    private _bienReference!: string;
    private _etudeRaisonSociale!: string;
    private _responsable!: string;
    private _title!: string;
    private _type!: 'doubleMandat' | 'doublon';

    get bienReference(): string {
        return this._bienReference;
    }

    set bienReference(value: string) {
        this._bienReference = value;
    }

    get etudeRaisonSociale(): string {
        return this._etudeRaisonSociale;
    }

    set etudeRaisonSociale(value: string) {
        this._etudeRaisonSociale = value;
    }

    get responsable(): string {
        return this._responsable;
    }

    set responsable(value: string) {
        this._responsable = value;
    }

    get title(): string {
        return this._title;
    }

    get type(): 'doubleMandat' | 'doublon' {
        return this._type;
    }

    set type(value: 'doubleMandat' | 'doublon') {
        this._type = value;
        this._title = this.type === 'doubleMandat' ? 'double mandat' : 'doublon';
    }
}
