import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from 'lodash';
import {IModule} from 'angular';
import {NgSortManager} from '@legacy/app/managers/managers';

export default function getEskSelectSort(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-sort></esk-select-sort>
         * @param options : {name(string), required(boolean)}
         */
        module.component('eskSelectSort', {
            bindings: {
                type: '@',
                ngModel: '=',
                onSelect: '=',
                options: '='
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/sort.html'
        });

        /**
         * Select tri
         *
         * @param SortManager
         * @constructor
         */
        Controller.$inject = ['SortManager'];
        function Controller(this: any, sortManager: NgSortManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.sorts = sortManager.getList($ctrl.type);
                if (angular.isObject($ctrl.ngModel)) {
                    let idx = findIndex($ctrl.sorts, {id: $ctrl.ngModel.id});
                    if (idx < 0) {
                        idx = findIndex($ctrl.sorts, {type: $ctrl.ngModel.type});
                        $ctrl.ngModel = idx < 0 ? undefined : sortManager.getOneById($ctrl.sorts[idx].code + $ctrl.ngModel.direction);
                    }
                }
            }
        }
    })(angularJS);
}
