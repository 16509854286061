import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {
    NgBienPhotoPasserelleMManager, NgDictionariesManager, NgManager, NgUtilsManager
} from '@legacy/app/managers/managers';
import {NgBienPhotoPasserelle, NgVentePasserelle} from '@legacy/app/managers/ressources';
import {NgVentePasserellesCollection} from '@legacy/app/managers/collections';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import DateFormat from '@shared/date/date.format';

export default function getManagersVentePasserelle(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('VentePasserelleManager', manager);

        /**
         * Manager ventePasserelle
         *
         * @param BaseManager
         * @param UtilsManager
         * @param DictionariesManager
         * @param BienPhotoPasserelleManager
         * @param $q
         * @param Ng2DictionaryItemService
         * @param Ng2DictionaryFactory
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'DictionariesManager', 'BienPhotoPasserelleManager', '$q', 'Ng2DictionaryItemService', 'Ng2DictionaryFactory'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         dictionariesManager: NgDictionariesManager,
                         bienPhotoPasserelleManager: NgBienPhotoPasserelleMManager,
                         $q: IQService,
                         ng2DictionaryItemService: DictionaryItemService,
                         ng2DictionaryFactory: DictionaryFactory) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('vente-passerelles.cget', {
                path: '/ventes/{venteId}/external/passerelles',
                method: 'GET'
            });
            baseManager.addRoute('vente-passerelles.get', {
                path: '/ventes/{venteId}/external/passerelles/{id}',
                method: 'GET'
            });
            baseManager.addRoute('vente-passerelles.insert', {
                path: '/ventes/{venteId}/external/passerelles/{id}',
                method: 'POST'
            });
            baseManager.addRoute('vente-passerelles.edit', {
                path: '/ventes/{venteId}/external/passerelles/{id}',
                method: 'PUT'
            });
            baseManager.addRoute('vente-passerelles.diffuse', {
                path: '/ventes/{venteId}/external/passerelles/{id}/diffuser',
                method: 'PATCH'
            });
            baseManager.addRoute('vente-passerelles.suspend', {
                path: '/ventes/{venteId}/external/passerelles/{id}/retirer',
                method: 'PATCH'
            });

            /**
             * VentePasserelleManager object
             *
             * @constructor
             */
            angular.extend(VentePasserelleManager.prototype, baseManager.__proto__);
            VentePasserelleManager.prototype.eskManager = {
                prefixRoute: 'vente-passerelles',
                collectionName: 'items'
            };
            function VentePasserelleManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param venteId
             * @returns {Promise}
             */
            VentePasserelleManager.prototype.getAll = function (venteId: number) {
                return baseManager.getAll.call(this, {venteId: venteId}).then(ventePasserelles => {
                    const ventePasserellesCollection: NgVentePasserellesCollection = ventePasserelles as NgVentePasserellesCollection;

                    angular.forEach(ventePasserellesCollection.collection, ventePasserelle => ventePasserelle._esk.defaultRouteParams!.venteId = venteId);

                    return ventePasserellesCollection;
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param venteId
             * @param resourceId
             * @returns {Promise}
             */
            VentePasserelleManager.prototype.getOneById = function (venteId: number, resourceId: number) {
                return this.get('get', {
                    venteId: venteId,
                    id: resourceId
                }).then(function (ventePasserelle: NgVentePasserelle) {
                    ventePasserelle._esk.defaultRouteParams!.venteId = venteId;
                    updatePhotosRouteParams(venteId, ventePasserelle.photos);

                    return ventePasserelle;
                });
            };

            /**
             * Create a VentePasserelle object
             *
             * @param data
             * @param venteId
             * @returns manager.VentePasserelle object || Array of manager.VentePasserelle objects
             */
            VentePasserelleManager.prototype.create = function (data: any, venteId: number) {
                if (angular.isArray(data)) {
                    var ventePasserelles = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        ventePasserelles.push(new VentePasserelle(venteId, data[i]));
                    }

                    return ventePasserelles;
                }

                // @ts-ignore
                return new VentePasserelle(venteId, data);
            };

            /**
             * VentePasserelle object
             *
             * @param venteId
             * @param data
             * @constructor
             */
            angular.extend(VentePasserelle.prototype, classResource.prototype);
            VentePasserelle.prototype.eskManager = {prefixRoute: 'vente-passerelles'};
            function VentePasserelle(this: NgVentePasserelle, venteId: number, data: any) {
                this.extend(data);
                this.id = angular.isNumber(this.passerelle.id) as unknown as number;
                this._esk.defaultRouteParams = {id: this.passerelle.id, venteId: venteId};
                updatePhotosRouteParams(venteId, this.photos);
                if (this.afficherCommune !== false) {
                    this.afficherCommune = true;
                }
                if (this.afficherPrix !== false) {
                    this.afficherPrix = true;
                }
                if (this.afficherDpe !== false) {
                    this.afficherDpe = true;
                }
                this._esk.persist = !!this.id;
                this._esk.flush = this._esk.persist;
                updateStatut(this);
            }

            /**
             * Extend the existing VentePasserelle with new data
             *
             * @param data
             * @returns {VentePasserelle}
             */
            VentePasserelle.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isString(this.dateDiffusion)) {
                    this.dateDiffusion = DateFormat.toDate(this.dateDiffusion);
                }

                if (angular.isString(this.dateRetrait)) {
                    this.dateRetrait = DateFormat.toDate(this.dateRetrait);
                }

                if (!angular.isObject(this._esk.normalizer)) {
                    if (!this.natureExternalType) {
                        this.natureExternalType = this.passerelle.normalizer;
                    }

                    if (!this.typePrixExternalType) {
                        this.typePrixExternalType = this.passerelle.normalizer;
                    }

                    this._esk.normalizer = {
                        natureExternalType: this.natureExternalType,
                        typePrixExternalType: this.typePrixExternalType
                    };
                }

                if (!angular.isObject(this.natureExternalType) && ng2DictionaryFactory.getByName('natures-' + this._esk.normalizer.natureExternalType)?.items?.length > 0) {
                    this.natureExternalType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById('natures-' + this._esk.normalizer.natureExternalType, this.natureExternalTypeId));
                }

                if (!angular.isObject(this.typePrixExternalType) && ng2DictionaryFactory.getByName('types-prix-' + this._esk.normalizer.typePrixExternalType)?.items?.length > 0) {
                    this.typePrixExternalType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById('types-prix-' + this._esk.normalizer.typePrixExternalType, this.typePrixExternalTypeId));
                }

                if (angular.isArray(this.photos)) {
                    this.photos = bienPhotoPasserelleManager.create(this.photos, null!, this.passerelle.id);
                } else {
                    this.photos = [];
                }

                return this;
            };

            /**
             * Sanitize object VentePasserelle before send to API
             *
             * @returns {Object}
             */
            VentePasserelle.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet VentePasserelle pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'natureExternalType');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'typePrixExternalType');
                sanitizeObject.natureExternalType = this._esk.normalizer.natureExternalType;
                sanitizeObject.typePrixExternalType = this._esk.normalizer.typePrixExternalType;

                if (ng2DictionaryFactory.getByName('natures-' + this._esk.normalizer.natureExternalType)?.items?.length <= 0 || !sanitizeObject.natureExternalTypeId) {
                    delete sanitizeObject.natureExternalType;
                    delete sanitizeObject.natureExternalTypeId;
                }

                if (ng2DictionaryFactory.getByName('types-prix-' + this._esk.normalizer.typePrixExternalType)?.items?.length <= 0 || !sanitizeObject.typePrixExternalTypeId) {
                    delete sanitizeObject.typePrixExternalType;
                    delete sanitizeObject.typePrixExternalTypeId;
                }

                return sanitizeObject;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            VentePasserelle.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self.id = angular.isNumber(self.passerelle.id);
                    self._esk.defaultRouteParams.id = self.passerelle.id;

                    return self;
                }, (rejection: any) => {
                    if (!self.id && angular.isObject(rejection) && rejection.status === 409) {
                        self.id = true;

                        return self.save();
                    }

                    return $q.reject(rejection);
                });
            };

            /**
             * Passerelle
             *
             * @returns {Promise}
             */
            VentePasserelle.prototype.diffuse = function () {
                const self = this;

                return self.patch('diffuse').then(function () {
                    self.dateDiffusion = DateFormat.toDate();
                    updateStatut(self);
                });
            };

            /**
             * Suspension
             *
             * @returns {Promise}
             */
            VentePasserelle.prototype.suspend = function () {
                const self = this;

                return self.patch('suspend').then(function () {
                    self.dateRetrait = DateFormat.toDate();
                    self.dateDiffusion = null;
                    updateStatut(self);
                });
            };

            /**
             * Is diffused
             *
             * @returns {Boolean}
             */
            VentePasserelle.prototype.isDiffused = function () {
                if (!angular.isDefined(this.dateDiffusion) || this.dateDiffusion === null) {
                    return false;
                }

                if (!angular.isDefined(this.dateRetrait) || this.dateRetrait === null) {
                    return true;
                }

                return DateFormat.diff(this.dateRetrait.toString(), this.dateDiffusion.toString()) < 0;
            };

            /**
             * Is suspended
             *
             * @returns {Boolean}
             */
            VentePasserelle.prototype.isSuspended = function () {
                if (!angular.isDefined(this.dateRetrait) || this.dateRetrait === null) {
                    return false;
                }

                if (!angular.isDefined(this.dateDiffusion) || this.dateDiffusion === null) {
                    return true;
                }

                return DateFormat.diff(this.dateDiffusion.toString(), this.dateRetrait.toString()) < 0;
            };

            /**
             * Update params for photos route
             *
             * @param venteId
             * @param bienPhotosPasserelle
             */
            function updatePhotosRouteParams(venteId: number, bienPhotosPasserelle: NgBienPhotoPasserelle[]) {
                angular.forEach(bienPhotosPasserelle, function (bienPhotoPasserelle) {
                    bienPhotoPasserelle._esk.defaultRouteParams!.bienId = venteId;
                });
            }

            /**
             * Update statut
             *
             * @param ventePasserelle
             */
            function updateStatut(ventePasserelle: NgVentePasserelle) {
                if (ventePasserelle.isDiffused()) {
                    ventePasserelle._esk.statut = 'diffused';
                } else if (ventePasserelle.isSuspended()) {
                    ventePasserelle._esk.statut = 'suspended';
                } else {
                    ventePasserelle._esk.statut = 'none';
                }
            }

            // @ts-ignore
            return new VentePasserelleManager();
        }
    })(angularJS);
}
