import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {IDCCityscanOnsalePropertyData} from '@features/cityscan/cityscan.interfaces';

@Component({selector: 'reference-dropdown', templateUrl: 'cityscan-onsale-property.dropdown.component.html'})
export class CityscanOnsalePropertyDropdownComponent implements IDynamicComponent {
    static readonly actions = {LINK: 'onsale_property_link', SEE: 'onsale_property_see'};
    private _dropdownService = inject(DropdownService);
    private _cityscanOnsaleProperty!: CityscanOnsaleProperty;

    get cityscanOnsaleProperty(): CityscanOnsaleProperty {
        return this._cityscanOnsaleProperty;
    }

    @Input()
    set data(value: IDCCityscanOnsalePropertyData) {
        this._cityscanOnsaleProperty = value.cityscanOnsaleProperty!;
    }

    link(): void {
        this._dropdownService.clicked(CityscanOnsalePropertyDropdownComponent.actions.LINK, {cityscanOnsaleProperty: this._cityscanOnsaleProperty});
    }

    see(): void {
        this._dropdownService.clicked(CityscanOnsalePropertyDropdownComponent.actions.SEE, {cityscanOnsaleProperty: this._cityscanOnsaleProperty});
    }
}
