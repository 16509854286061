export default `
<div ng-if="!!$ctrl.demandeur" class="container-fluid container-fullw tw-bg-white lifetime">
  <div class="row">
    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center tw-relative">
          <div class="panel-body">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/bons-visite/' + $ctrl.BonvisiteStatutNEW + '/edit')">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'file-alt', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Créer un bon de visite</h4>
            </a>
          </div>

          <div class="tw-absolute tw-right-0 tw-bottom-0 tw-text-xs">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/bons-visite/portefeuille')"
               class="tw-block tw-p-2">Voir la liste</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/offres-achat/' + $ctrl.OffreachatStatutNEW + '/edit')">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'file-invoice-dollar'}">
              </app-fa-shortcut>

              <h4>Créer une offre d'achat</h4>
            </a>
          </div>

          <div class="tw-absolute tw-right-0 tw-bottom-0 tw-text-xs">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/offres-achat/portefeuille')"
               class="tw-block tw-p-2">Voir la liste</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createEmail()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'envelope', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Envoyer un E-mail</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- Affichage des biens correspondants -->
    <div class="col-xs-12 col-sm-6">
      <div class="panel panel-white no-radius results">
        <div class="panel-heading border-bottom">
          <h4 class="panel-title">
            <fa-icon [icon]="['far', 'check-square']"></fa-icon>
            {{$ctrl.demandeur.recherches[0].type === $ctrl.DossierBienTypesConst.VENTE ? "Ventes" : "Locations"}} correspondantes
          </h4>

          <div class="panel-tools">
            <span ng-if="$ctrl.demandeur.recherches[0]._esk.status.green"
                  class="label label-results resultat-green">{{$ctrl.demandeur.recherches[0]._esk.status.green}}</span>

            <span ng-if="$ctrl.demandeur.recherches[0]._esk.status.orange"
                  class="label label-results resultat-orange">
              {{$ctrl.demandeur.recherches[0]._esk.status.orange}}
            </span>

            <span ng-if="$ctrl.demandeur.recherches[0]._esk.status.blue"
                  class="label label-results resultat-blue">{{$ctrl.demandeur.recherches[0]._esk.status.blue}}</span>

            <span ng-if="$ctrl.demandeur.recherches[0]._esk.status.red"
                  class="label label-results resultat-red">{{$ctrl.demandeur.recherches[0]._esk.status.red}}</span>
          </div>
        </div>

        <ng-switch on="$ctrl.resultsOptions.loader.updating">
          <ng-switch on="$ctrl.demandeur.recherches[0].results.totalItems > 0" ng-switch-default>
            <div ng-switch-default>
              <esk-ventes-list ng-if="$ctrl.demandeur.recherches[0].type === $ctrl.DossierBienTypesConst.VENTE"
                               ventes="$ctrl.demandeur.recherches[0]._esk.dossiersCorrespondants"
                               options="$ctrl.resultsOptions"></esk-ventes-list>

              <esk-locations-list ng-if="$ctrl.demandeur.recherches[0].type === $ctrl.DossierBienTypesConst.LOCATION"
                                  locations="$ctrl.demandeur.recherches[0]._esk.dossiersCorrespondants"
                                  options="$ctrl.resultsOptions"></esk-locations-list>
            </div>

            <ng-switch on="$ctrl.demandeur.recherches.length > 0" ng-switch-when="false" class="tw-text-center">
              <div ng-switch-default class="margin-top-10 margin-bottom-10">
                <p class="text-muted">Aucun bien en {{$ctrl.demandeur.recherches[0].type}} à afficher.</p>

                <button type="button" class="btn btn-green"
                        ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/edit/criteres')">
                  Élargir la recherche pour trouver des biens
                </button>
              </div>

              <div ng-switch-when="false" class="margin-top-10 margin-bottom-10">
                <p class="text-muted">Aucune recherche en cours.</p>

                <button ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/edit/criteres')"
                        type="button" class="btn btn-green">Saisir vos critères de recherche</button>
              </div>
            </ng-switch>
          </ng-switch>

          <p class="tw-text-center text-extra-large" ng-switch-when="true">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des résultats
          </p>
        </ng-switch>

        <div ng-if="$ctrl.demandeur.recherches[0].results.totalItems > 0" class="panel-footer">
          <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/resultats')">
            <fa-icon [icon]="'arrow-circle-right'"></fa-icon> Tous les biens correspondants
          </a>
        </div>
      </div>
    </div>

    <!-- Archivage -->
    <div ng-if="$ctrl.demandeur.statut !== $ctrl.STATUT_ARCHIVE" class="col-xs-12 col-sm-6">
      <div class="panel panel-white no-radius">
        <div class="panel-heading border-bottom">
          <h4 class="panel-title"><fa-icon [icon]="'archive'"></fa-icon> Archivage</h4>
        </div>

        <div class="panel-body text-info">
          <span class="text-large">Ce demandeur est actif. Il n'est pas encore archivé.</span>

          <p class="text-muted">
            Vous pouvez procéder à son archivage en cliquant sur le lien ci-dessous.<br>
            Il sera retiré de votre portefeuille de demandeurs en cours.
          </p>
        </div>

        <div class="panel-footer">
          <button type="button" class="text-bold text-danger" ng-click="$ctrl.archive()">
            Archiver le dossier ...
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-text-right">
    Demandeur créé le <app-ng2-date [date]="$ctrl.demandeur.createdAt" format="shortDate"></app-ng2-date> |
    Dernière modification le <app-ng2-date [date]="$ctrl.demandeur.updatedAt" format="shortDate"></app-ng2-date>
  </div>
</div>
`;
