import {Component, inject} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Media from '@models/medias/media/media.model';
import {NgForm} from '@angular/forms';

@Component({selector: 'dc-media-rename', templateUrl: 'media.rename.component.html'})
export class DCMediaRenameComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _media!: Media;

    set data(value: {media: Media}) {
        this._media = value.media;
    }

    get media(): Media {
        return this._media;
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        this._modalService.setResponse({media: this.media});
    }
}
