import {Injectable} from '@angular/core';
import Passerelle from '@models/passerelle/passerelle.model';
import {NgPasserelle} from '@legacy/app/managers/ressources';
import {IPasserelleApi} from '@models/passerelle/passerelle.interfaces';

@Injectable({providedIn: 'root'})
export class PasserelleFactory {
    create(passerelleApi: IPasserelleApi): Passerelle {
        const passerelle = new Passerelle(passerelleApi.id.toString(), passerelleApi.id);

        passerelle.allowNetwork = passerelleApi.allowNetwork;
        passerelle.configurationAfficherCommune = passerelleApi.configurationAfficherCommune;
        passerelle.configurationAfficherDpe = passerelleApi.configurationAfficherDpe;
        passerelle.configurationAfficherPrix = passerelleApi.configurationAfficherPrix;
        passerelle.instantDiffusion = passerelleApi.instantDiffusion;
        passerelle.name = passerelleApi.name;
        passerelle.normalizer = passerelleApi.normalizer;
        passerelle.photosMaxNumber = passerelleApi.photosMaxNumber;
        passerelle.type = passerelleApi.type;
        passerelle.urlLogo = passerelleApi.urlLogo;
        passerelle.urlSite = passerelleApi.urlSite;
        passerelle.venteInteractive = passerelleApi.venteInteractive;
        passerelle.viaId = passerelleApi.viaId;

        return passerelle;
    }

    ngCreate(ngPasserelle: NgPasserelle): Passerelle {
        const passerelle = new Passerelle(ngPasserelle.uuid, ngPasserelle.id);

        passerelle.allowNetwork = ngPasserelle.allowNetwork;
        passerelle.configurationAfficherCommune = ngPasserelle.configurationAfficherCommune;
        passerelle.configurationAfficherDpe = ngPasserelle.configurationAfficherDpe;
        passerelle.configurationAfficherPrix = ngPasserelle.configurationAfficherPrix;
        passerelle.instantDiffusion = ngPasserelle.instantDiffusion;
        passerelle.name = ngPasserelle.name;
        passerelle.normalizer = ngPasserelle.normalizer;
        passerelle.photosMaxNumber = ngPasserelle.photosMaxNumber;
        passerelle.type = ngPasserelle.type;
        passerelle.urlLogo = ngPasserelle.urlLogo;
        passerelle.urlSite = ngPasserelle.urlSite;
        passerelle.venteInteractive = ngPasserelle.venteInteractive;
        passerelle.viaId = ngPasserelle.viaId;

        return passerelle;
    }
}
