// @todo Voir https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl
import {IDateFormatDiffOptions, IDateFormatIsNowOlderOptions} from '@shared/date/date.interfaces';
import {TextsService} from '@shared/texts/texts.service';
import {LOCALE_FR_FR} from '@shared/constants';

// Utile pour les anciens managers qui utilisaient Date, à voir si toujours utile dans les nouveaux model quand les managers auront disparu
Date.prototype.toJSON = function (this: string) {
    return DateFormat.toAPI(this);
};

export default class DateFormat {
    static readonly DAYS = 'days';
    static readonly HOURS = 'hours';
    static readonly MINUTES = 'minutes';
    static readonly MONTHS = 'months';
    static readonly SECONDS = 'seconds';
    static readonly WEEKS = 'weeks';
    static readonly YEARS = 'years';
    static readonly ONE_DAY_IN_HOURS = 24;
    static readonly ONE_MINUTE_IN_SECONDS = 60;
    static readonly ONE_HOUR_IN_MINUTES = 60;
    static readonly ONE_DAY_IN_SECONDS = DateFormat.ONE_DAY_IN_HOURS * DateFormat.ONE_HOUR_IN_MINUTES * DateFormat.ONE_MINUTE_IN_SECONDS;
    static readonly ONE_SECOND_IN_MILLISECONDS = 1000;
    static readonly ONE_MINUTE_IN_MILLISECONDS = DateFormat.ONE_SECOND_IN_MILLISECONDS * DateFormat.ONE_MINUTE_IN_SECONDS;
    static readonly ONE_HOUR_IN_MILLISECONDS = DateFormat.ONE_MINUTE_IN_MILLISECONDS * DateFormat.ONE_HOUR_IN_MINUTES;
    static readonly ONE_DAY_IN_MILLISECONDS = DateFormat.ONE_HOUR_IN_MILLISECONDS * DateFormat.ONE_DAY_IN_HOURS;
    static readonly ONE_WEEK_IN_MILLISECONDS = DateFormat.ONE_DAY_IN_MILLISECONDS * 7;
    static readonly ONE_30DAYS_IN_MILLISECONDS = DateFormat.ONE_DAY_IN_MILLISECONDS * 30;
    static readonly ONE_365DAYS_IN_MILLISECONDS = DateFormat.ONE_DAY_IN_MILLISECONDS * 365;

    static addDaysToDateString(dateBrut: string | Date, addedDays: number): string {
        const date = DateFormat.sanitize(dateBrut);

        if (!date) {
            return undefined!;
        }

        date.setDate(date.getDate() + addedDays);

        return date.toJSON();
    }

    static addHoursToDateString(dateBrut: string | Date, addedHours: number): string {
        const date = DateFormat.sanitize(dateBrut);

        if (!date) {
            return undefined!;
        }

        date.setHours(date.getHours() + addedHours);

        return date.toJSON();
    }

    static addMonthsToDateString(dateBrut: string | Date, addedMonths: number): string {
        const date = DateFormat.sanitize(dateBrut);

        if (!date) {
            return undefined!;
        }

        date.setMonth(date.getMonth() + addedMonths);

        return date.toJSON();
    }

    static addSecondsToDateString(dateBrut: string | Date, addedSeconds: number): string {
        const date = DateFormat.sanitize(dateBrut);

        if (!date) {
            return undefined!;
        }

        date.setSeconds(date.getSeconds() + addedSeconds);

        return date.toJSON();
    }

    static addYearsToDateString(dateBrut: string | Date, addedYears: number): string {
        const date = DateFormat.sanitize(dateBrut);

        if (!date) {
            return undefined!;
        }

        date.setFullYear(date.getFullYear() + addedYears);

        return date.toJSON();
    }

    static dateFromDate(date?: Date, options = {withDay: true}): string {
        if (!date) {
            return undefined!;
        }

        const dayBrut = DateFormat.getDay(date);
        const monthBrut = DateFormat.getMonth(date);
        const yearBrut = DateFormat.getYear(date);
        const dateArray = [yearBrut.toString().padStart(4, '0'), monthBrut.toString().padStart(2, '0')];

        if (options.withDay) {
            dateArray.push(dayBrut.toString().padStart(2, '0'));
        }

        return dateArray.join('-');
    }

    static dateFromDatetime(dateTime?: string): string {
        const regexDateWithoutTime = /^\d{4}-\d{1,2}-\d{1,2}/;

        if (!dateTime) {
            return undefined!;
        }

        try {
            return regexDateWithoutTime.exec(dateTime)![0];
        } catch (e) {
            return undefined!;
        }
    }

    static dateFromNow(): string {
        return DateFormat.dateFromDate(new Date());
    }

    static datetimeFromDate(date = new Date()): string {
        return DateFormat.dateFromDate(date) + 'T' + DateFormat.timeFromDate(date);
    }

    static diff(date1: string, date2 = (new Date()).toString(), options: IDateFormatDiffOptions = {}): number {
        const date1Date = new Date(date1);
        const date2Date = new Date(date2);

        if (options.withoutTime) {
            DateFormat.razTime(date1Date);
            DateFormat.razTime(date2Date);
        }

        const date1Time = (new Date(date1Date)).getTime();
        const date2Time = (new Date(date2Date)).getTime();
        const dateDiffBrute = date1Time - date2Time;
        let dateDiff = dateDiffBrute;

        if (options.returnUnit === DateFormat.HOURS) {
            dateDiff = dateDiffBrute / DateFormat.ONE_HOUR_IN_MILLISECONDS;
        } else if (options.returnUnit === DateFormat.DAYS) {
            dateDiff = dateDiffBrute / DateFormat.ONE_DAY_IN_MILLISECONDS;
        } else if (options.returnUnit === DateFormat.SECONDS) {
            dateDiff = dateDiffBrute / DateFormat.ONE_SECOND_IN_MILLISECONDS;
        }

        return Math.trunc(dateDiff);
    }

    static getDateString(dateBrut?: string | Date): string {
        if (typeof dateBrut === 'string') {
            return DateFormat.dateFromDatetime(dateBrut);
        }

        return DateFormat.dateFromDate(dateBrut);
    }

    static getDay(dateBrut?: string | Date): number {
        return DateFormat.sanitize(dateBrut)?.getDate() || 1;
    }

    static getHours(dateBrut?: string | Date): number {
        return DateFormat.sanitize(dateBrut)?.getHours() || 0;
    }

    static getMinutes(dateBrut?: string | Date): number {
        return DateFormat.sanitize(dateBrut)?.getMinutes() || 0;
    }

    static getMonth(dateBrut?: string | Date): number {
        return (DateFormat.sanitize(dateBrut)?.getMonth() || 0) + 1;
    }

    static getSecondes(dateBrut?: string | Date): number {
        return DateFormat.sanitize(dateBrut)?.getSeconds() || 0;
    }

    static getYear(dateBrut?: string | Date): number {
        return DateFormat.sanitize(dateBrut)?.getFullYear() || 0;
    }

    static isNowOlder(dateString: string, options: IDateFormatIsNowOlderOptions = {}): boolean {
        const diff = DateFormat.diff(dateString, undefined, options);

        return options.orSame ? diff <= 0 : diff < 0;
    }

    // @todo En faire une propriété et pas une méthode ?
    static monthsShort(): string[] {
        const date = new Date();
        const months: string[] = [];

        for (let i = 0; i < 12; i++) {
            date.setMonth(i);
            months.push(date.toLocaleString('default', {month: 'short'}));
        }

        return months.map(month => TextsService.capitalize(month));
    }

    static razTime(date?: Date): void {
        if (date instanceof Date) {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
    }

    static relativeTime(dateBrut: string, unit?: Intl.RelativeTimeFormatUnit): string {
        const relativeTime = () => {
            const rtf = new Intl.RelativeTimeFormat('fr', {numeric: 'auto', style: 'long'});
            const diffFromNow = DateFormat.diff(dateBrut);

            if (unit) {
                return rtf.format(diffFromNow, unit);
            }

            const diffFromNowYears = Math.round(diffFromNow / DateFormat.ONE_365DAYS_IN_MILLISECONDS);

            if (Math.abs(diffFromNowYears) >= 1) {
                return rtf.format(diffFromNowYears, DateFormat.YEARS);
            }

            const diffFromNowMonths = Math.round(diffFromNow / DateFormat.ONE_30DAYS_IN_MILLISECONDS);

            if (Math.abs(diffFromNowMonths) >= 1) {
                return rtf.format(diffFromNowMonths, DateFormat.MONTHS);
            }

            const diffFromNowWeeks = Math.round(diffFromNow / DateFormat.ONE_WEEK_IN_MILLISECONDS);

            if (Math.abs(diffFromNowWeeks) >= 1) {
                return rtf.format(diffFromNowWeeks, DateFormat.WEEKS);
            }

            const diffFromNowDays = Math.round(diffFromNow / DateFormat.ONE_DAY_IN_MILLISECONDS);

            if (Math.abs(diffFromNowDays) >= 1) {
                return rtf.format(diffFromNowDays, DateFormat.DAYS);
            }

            const diffFromNowHours = Math.round(diffFromNow / DateFormat.ONE_HOUR_IN_MILLISECONDS);

            if (Math.abs(diffFromNowHours) >= 1) {
                return rtf.format(diffFromNowHours, DateFormat.HOURS);
            }

            const diffFromNowMinutes = Math.round(diffFromNow / DateFormat.ONE_MINUTE_IN_MILLISECONDS);

            if (Math.abs(diffFromNowMinutes) >= 1) {
                return rtf.format(diffFromNowMinutes, DateFormat.MINUTES);
            }

            return rtf.format(Math.round(diffFromNow / DateFormat.ONE_SECOND_IN_MILLISECONDS), DateFormat.SECONDS);
        };

        return relativeTime().replace('’', '\'');
    }

    // @todo Regarder les utilisations afin de ne plus utiliser cette méthode en externe
    static sanitize(dateBrut?: string | Date): Date {
        if (dateBrut instanceof Date) {
            return DateFormat.toDate(dateBrut.toString());
        }

        if (typeof dateBrut === 'string') {
            return DateFormat.toDate(dateBrut);
        }

        return undefined!;
    }

    static timeFromDate(date?: Date): string {
        if (!date) {
            return undefined!;
        }

        const hourBrut = DateFormat.getHours(date);
        const minuteBrut = DateFormat.getMinutes(date);
        const secondBrut = DateFormat.getSecondes(date);

        return hourBrut.toString().padStart(2, '0') + ':' + minuteBrut.toString().padStart(2, '0') + ':' + secondBrut.toString().padStart(2, '0');
    }

    static toAPI(dateString?: string): string {
        if (!dateString) {
            return undefined!;
        }

        if (typeof dateString === 'string' && dateString.includes('T') && dateString.endsWith('Z')) {
            return dateString;
        }

        const date = DateFormat.toDate(dateString);

        if (!date) {
            return undefined!;
        }

        // Précédemment, c'était "return DateFormat.toISOString(dateString).slice(0, 19) + 'Z';".
        // L'API ne prend pas en compte le Z qui veut dire GMT+0000.
        // Du coup, on triche en renvoyant l'heure en cours sans se soucier du fuseau horaire.
        // En attente de https://gitlab.soqrate.com/soqrate/noty/api/-/issues/1311 pour avoir une meilleure stabilité.
        return DateFormat.getDateString(dateString) + 'T' + date.toLocaleTimeString(LOCALE_FR_FR) + 'Z';
    }

    // @todo Supprimer son utilisation afin que Date ne sorte pas de cette class
    /**
     * @deprecated
     */
    static toDate(dateBrut = (new Date()).toString()): Date {
        const date = new Date(dateBrut);

        return date.getTime() === date.getTime() ? date : undefined!;
    }

    static toISOString(dateString?: string): string {
        return DateFormat.toDate(dateString).toISOString();
    }

    static toJSON(dateBrut?: string | Date, format?: string): string {
        const sanitizedDate = DateFormat.sanitize(dateBrut);

        if (!sanitizedDate) {
            return undefined!;
        }

        if (format === 'MMM YY') {
            return new Intl.DateTimeFormat(LOCALE_FR_FR, {
                year: '2-digit',
                month: 'short',
            }).format(sanitizedDate);
        } else if (format === 'DD/MM/YYYY') {
            return new Intl.DateTimeFormat(LOCALE_FR_FR).format(sanitizedDate);
        }

        return DateFormat.toAPI(DateFormat.datetimeFromDate(sanitizedDate));
    }
}
