import {inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {
    CContactsGroupMembersFactory
} from '@models/contacts-group/members/collection/contacts-group-members.collection.factory';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import CContactsGroupMembers from '@models/contacts-group/members/collection/contacts-group-members.collection.model';


@Injectable({providedIn: 'root'})
export class CContactsGroupMembersService {
    private _cContactsGroupMembersFactory = inject(CContactsGroupMembersFactory);
    private _contactsGroupService = inject(ContactsGroupService);

    getFromContactsGroupLink$(contactsGroupLink?: string): Observable<CContactsGroupMembers> {
        return this._contactsGroupService.getOneByLink$(contactsGroupLink).pipe(
            map(contactsGroup => this._cContactsGroupMembersFactory.createFromMembers(contactsGroup.members))
        );
    }
}
