import {inject, Injectable} from '@angular/core';
import Estimation from '@models/estimations/estimation/estimation.model';
import Vente from '@models/ventes/vente/vente.model';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {catchError} from 'rxjs/operators';
import {IModelValidationError} from '@models/model.interfaces';
import Reference from '@models/references/reference/reference.model';
import {
    EstimationReferenceSourceConst, EstimationReferenceSourceType
} from '@models/estimations/estimation/references/estimation-references.constants';
import {
    EstimationReferenceService
} from '@models/estimations/estimation/references/reference/estimation-reference.service';

@Injectable({providedIn: 'root'})
export class EstimationReferencesService {
    static TITLE_ADDED_ESTIMATION_REFERENCES = 'Ajout de références';
    static MESSAGE_ESTIMATION_REFERENCES = 'aux références de votre bien en estimation';

    static getMessageLinkReferencesAdded(references: Reference[]): string {
        if (references.length <= 1) {
            return EstimationReferenceService.getMessageLinkReferenceAdded();
        }

        return 'Les ' + references.length.toString() + ' références sont ajoutées ' + EstimationReferencesService.MESSAGE_ESTIMATION_REFERENCES + '.';
    }

    static getMessageLinkVentesAdded(ventes: Vente[]): string {
        if (ventes.length <= 1) {
            return EstimationReferenceService.getMessageLinkVenteAdded(ventes[0]);
        }

        return 'Les ' + ventes.length.toString() + ' biens en vente sont ajoutés ' + EstimationReferencesService.MESSAGE_ESTIMATION_REFERENCES + '.';
    }

    static getMessageLinkVentesAddedError(ventes: Vente[], error: IModelValidationError): string {
        return EstimationReferenceService.getMessageLinkVenteAddedError(ventes[error.index!], error);
    }

    private _estimationReferenceService = inject(EstimationReferenceService);

    linkReferences$(estimation: Estimation, references: Reference[] = [], source: EstimationReferenceSourceType = EstimationReferenceSourceConst.REFERENCE): Observable<EstimationReference[]> {
        if (references.length <= 0) {
            return of(undefined! as EstimationReference[]);
        }

        return combineLatest(references.map((reference, index) => this._estimationReferenceService.linkReference$(estimation, reference, source).pipe(
            catchError((error: IModelValidationError) => {
                error.index = index;

                return throwError(() => error);
            }),
        )));
    }

    linkVentes$(estimation: Estimation, ventes: Vente[] = []): Observable<EstimationReference[]> {
        if (ventes.length <= 0) {
            return of(undefined! as EstimationReference[]);
        }

        return combineLatest(ventes.map((vente, index) => this._estimationReferenceService.linkVente$(estimation, vente).pipe(
            catchError((error: IModelValidationError) => {
                error.index = index;

                return throwError(() => error);
            }),
        )));
    }
}
