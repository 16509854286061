import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {NgMedia} from '@legacy/app/managers/ressources';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import CDossierFiles from '@models/dossiers/dossier/files/collection/dossier-files.collection.model';
import {MediaFactory} from '@models/medias/media/media.factory';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default function getEskActionsAttachments(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-actions-attachments></esk-actions-attachments>
         */
        module.component('eskActionsAttachments', {
            bindings: {
                add: '=',
                addDocument: '=',
                attachments: '=',
                currentDossier: '=',
                filesMaxSize: '=',
                remove: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/actions/attachments.html'
        });

        /**
         * Actions on attachments
         *
         * @param Ng2CDossierFilesService
         * @param Ng2MediaFactory
         * @constructor
         */
        Controller.$inject = ['Ng2CDossierFilesService', 'Ng2MediaFactory'];
        function Controller(this: any, ng2CDossierFilesService: CDossierFilesService, ng2MediaFactory: MediaFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.addFromGed = addFromGed;
            $ctrl.downloadMedia = downloadMedia;
            $ctrl.upload = upload;

            /**
             * Initialization
             */
            function $onInit() {
                let gedDocuments$ = of(undefined!) as unknown as Observable<CDossierFiles>;

                if ($ctrl.currentDossier && ($ctrl.currentDossier._esk?.typeDossier === DossierTypesConst.DEMANDEUR || $ctrl.currentDossier.bien?.interne)) {
                    gedDocuments$ = ng2CDossierFilesService.getOneByLinkWithMedia$($ctrl.currentDossier._links?.files?.href);
                }

                gedDocuments$.pipe(take(1)).subscribe(cDossierFiles => $ctrl.cDossierFiles = cDossierFiles);
            }

            /**
             * Add from GED
             *
             * @param document
             */
            function addFromGed(document: unknown) {
                $ctrl.addDocument(document);
            }

            /**
             * Download ngMedia
             *
             * @param ngMedia
             */
            function downloadMedia(ngMedia: NgMedia) {
                ng2MediaFactory.read$(ng2MediaFactory.ngCreate(ngMedia)).pipe(take(1)).subscribe();
            }

            /**
             * Upload
             *
             * @param files
             */
            function upload(files: unknown[]) {
                files.map(file => $ctrl.add(file));
            }
        }
    })(angularJS);
}
