export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.save()" novalidate class="report-edit">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title">
      <fa-icon [icon]="['far', 'calendar']"></fa-icon>

      {{(!!$ctrl.report.id ? "Modifier le" : "Nouveau") + " compte-rendu" | translate}}
    </h3>
  </div>

  <div class="modal-body">
    <!-- List tabs -->
    <ul class="nav nav-pills margin-bottom-20">
      <li role="presentation" ng-class="{active: $ctrl.currentTab === tab.code}" ng-repeat="tab in $ctrl.tabs">
        <a ng-click="$ctrl.currentTab = tab.code">{{tab.label|translate}}</a>
      </li>
    </ul>

    <div class="row">
      <!-- Edition -->
      <div ng-show="$ctrl.currentTab === 'general'" class="col-sm-12">
        <!-- Objet -->
        <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.subject.$invalid}">
          <input type="text" id="subject" name="subject" class="form-control" ng-model="$ctrl.report.subject" required
                 placeholder="Objet">

          <div class="error text-small tw-block" ng-messages="$ctrl.editForm.subject.$error"
               ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.subject.$invalid">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </div>
        </div>

        <!-- Corps -->
        <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && !$ctrl.report.comments}">
          <app-ng2-tinymce (change-content)="$ctrl.report.comments = $event" [content]="$ctrl.report.comments"
                           [options]="$ctrl.tinymceOptions"></app-ng2-tinymce>

          <div class="error text-small tw-block" ng-messages="{required: true}"
               ng-show="$ctrl.editForm.$submitted && !$ctrl.report.comments">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </div>
        </div>

        <!-- Informations complémentaires -->
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group"
                 ng-class="{'has-error': $ctrl.editForm.$submitted && ($ctrl.editForm.date.$error | json) !== '{}'}">
              <label>{{"Date du compte-rendu"|translate}}</label>

              <esk-select-date form="$ctrl.editForm" form-field-name="date" model="$ctrl.report.date">
              </esk-select-date>

              <span class="error text-small tw-block" ng-messages="$ctrl.editForm.date.$error"
                    ng-if="$ctrl.editForm.$submitted">
                <ng-messages-include src="messages.html"></ng-messages-include>
              </span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Thème du compte-rendu</label>

              <esk-select-dictionary ng-model="$ctrl.report.theme" dictionary="{{$ctrl.COMPTE_RENDU_THEMES}}"
                                     on-select="$ctrl.onSelectTheme"></esk-select-dictionary>
            </div>
          </div>
        </div>
      </div>

      <!-- Participants -->
      <div ng-show="$ctrl.currentTab === 'participants'" class="col-sm-12">
        <esk-select-participant on-select="$ctrl.addParticipant" disabled="$ctrl.isParticipantDisabled"
                                ng-model="$ctrl.newParticipant" options="$ctrl.participantsOptions">
        </esk-select-participant>

        <table class="margin-top-15 table table-bordered table-hover">
          <thead>
          <tr>
            <th>Nom du participant</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr ng-if="$ctrl.report.participants.length === 0">
            <td colspan="2" class="tw-text-center text-muted">
              Il n'y a pas de participant rattaché à ce compte-rendu
            </td>
          </tr>
          <tr ng-repeat="reportParticipant in $ctrl.report.participants">
            <td class="col-sm-9">
              {{ reportParticipant.person.convivialName }}
            </td>
            <td class="col-sm-3 tw-text-center">
              <button type="button" class="tw-btn-tertiary-info tw-py-0 tw-px-2"
                      ng-click="$ctrl.report.removeParticipant(reportParticipant)">
                <fa-icon [icon]="['far', 'file']"></fa-icon><span>Retirer du compte-rendu</span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Dossiers -->
      <div ng-show="$ctrl.currentTab === 'dossiers'" class="col-sm-12">
        <esk-actions-dossiers dossiers="$ctrl.report.dossiers" current-dossier="$ctrl.dossier"
                              add="$ctrl.addDossier" remove="$ctrl.report.removeDossier"></esk-actions-dossiers>
      </div>

      <!-- Attachments -->
      <div ng-show="$ctrl.currentTab === 'attachments'" class="col-sm-12">
        <esk-actions-attachments attachments="$ctrl.report.attachments" add="$ctrl.addAttachment"
                                 add-document="$ctrl.addAttachmentDocument" remove="$ctrl.report.removeAttachment"
                                 current-dossier="$ctrl.dossier"></esk-actions-attachments>
      </div>
    </div>
  </div>

  <div class="modal-footer" ng-switch="$ctrl.saving || $ctrl.uploading">
    <div ng-switch-when="true">
      <span ng-if="$ctrl.saving">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
      </span>
      <span ng-if="$ctrl.uploading">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Envoi de fichiers en cours ...
      </span>
    </div>

    <div ng-switch-default>
      <button type="submit" class="btn btn-primary"><fa-icon [icon]="'check'"></fa-icon> Enregistrer</button>
    </div>
  </div>
</form>
`;
