import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {HttpEventProgress} from '@core/api/api.interfaces';
import {HttpResponse} from '@angular/common/http';
import {IEtudeSettingsBannerPositionApi} from '@models/etudes/etude/settings/banner/etude-settings-banner.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeSettingsBannerApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(etudeUuid: string): Observable<void> {
        return this._modelApiService.delete$('/etudes/' + etudeUuid + '/settings/banner');
    }

    updatePosition$(etudeUuid: string, etudeSettingsBannerPositionApi: IEtudeSettingsBannerPositionApi): Observable<void> {
        return this._modelApiService.patch$('/etudes/' + etudeUuid + '/settings/banner/position', etudeSettingsBannerPositionApi);
    }

    upload$(etudeUuid: string, formData: FormData): Observable<HttpEventProgress | HttpResponse<void>> {
        return this._modelApiService.upload$('/etudes/' + etudeUuid + '/settings/banner', formData);
    }
}
