<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Localisation</h4>

  <div class="tw-bg-red-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (isAdresseVisible) {
        <li>
          {{ dossierBien.bien.inlineAdresse }}

          @if (dossierBien.bien.commune) {
            <div class="tw-mt-1 tw-text-gray-500 tw-font-normal">
              {{ dossierBien.bien.commune.fullname }}

              @if (dossierBien.bien.codePostal) {
                ({{ dossierBien.bien.codePostal }})
              }
            </div>
          }
        </li>
      }

      @if (isCadastresVisible) {
        <li class="">
          <div class="tw-text-gray-500 tw-font-normal">
            Référence{{ dossierBien.bien.cadastres.length > 1 ? 's' : '' }}
            cadastrale{{ dossierBien.bien.cadastres.length > 1 ? 's' : '' }}
          </div>

          @for (bienCadastre of dossierBien.bien.cadastres; track bienCadastre; let last = $last) {
            <div>
              <span class="tw-text-gray-500 tw-text-xs tw-font-normal">Section</span> {{ bienCadastre.section }}
              <span class="tw-text-gray-500 tw-text-xs tw-font-normal">numéro</span> {{ bienCadastre.parcelle }}

              @if (bienCadastre.lot > 0) {
                <span class="tw-text-gray-500 tw-text-xs tw-font-normal">lot</span> {{ bienCadastre.lot }}
              }

              @if (bienCadastre.surface > 0) {
                <span class="tw-text-gray-500 tw-text-xs tw-font-normal">pour</span>
                {{ bienCadastre.surface | textCurrency:'m²' }}
              }
            </div>
          }
        </li>
      }
    </ul>
  </div>
</div>
