export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.addCompromis()" class="vente-modal-compromis">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans mettre en compromis"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="['far', 'bookmark']"></fa-icon> Mise en compromis</h3>
  </div>

  <div ng-if="!!$ctrl.options" class="modal-body">
    <p ng-if="$ctrl.options.compromisAdding" class="text-extra-large tw-text-center margin-top-20 margin-bottom-20">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
    </p>

    <div ng-if="!$ctrl.options.compromisAdding">
      <div ng-if="![true, false].includes($ctrl.compromis.negocie)" class="compromis-first">
        <div class="first-dates">
          <h4>Du</h4>

          <esk-select-date model="$ctrl.compromisDate" on-select="$ctrl.updateDateFin()" is-required="true">
          </esk-select-date>

          <h4>{{"au" | translate}}</h4>

          <esk-select-date model="$ctrl.compromisDateFin" on-select="$ctrl.updateDateVente()" is-required="true"
                           options="$ctrl.options.dateFin"></esk-select-date>
        </div>

        <div class="row first-options">
          <h4 class="tw-text-center margin-bottom-20">Avez-vous négocié le bien ?</h4>

          <div class="col-xs-12 col-sm-6">
            <h3 class="tw-text-center">OUI</h3>

            <button type="button" class="btn btn-primary btn-o" title="{{$ctrl.options.solo.label}}"
                    ng-click="$ctrl.selectNegociation(true, $ctrl.options.solo)">
              {{$ctrl.options.solo.shortLabel | uppercase}}
            </button>

            <button ng-if="$ctrl.options.duo" ng-click="$ctrl.selectNegociation(true, $ctrl.options.duo)"
                    class="btn btn-primary btn-o" title="{{$ctrl.options.duo.label}}" type="button">
              {{$ctrl.options.duo.shortLabel | uppercase}}
            </button>
          </div>

          <div class="col-xs-12 col-sm-6">
            <h3 class="tw-text-center">NON</h3>

            <button type="button" class="btn btn-primary btn-o" title="{{$ctrl.options.agence.label}}"
                    ng-click="$ctrl.selectNegociation(false, $ctrl.options.agence)">
              {{$ctrl.options.agence.shortLabel | uppercase}}
            </button>

            <button type="button" class="btn btn-primary btn-o" title="{{$ctrl.options.pap.label}}"
                    ng-click="$ctrl.selectNegociation(false, $ctrl.options.pap)">
              {{$ctrl.options.pap.shortLabel | uppercase}}
            </button>
          </div>
        </div>
      </div>

      <div ng-if="[true, false].includes($ctrl.compromis.negocie)" class="compromis-second row">
        <h4 class="tw-text-center">
          {{'vente.compromis.enabled.second.RESUME' | translate: $ctrl.options.negociationResume}}
        </h4>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <label for="compromisPrixCession">Prix de cession</label>

          <div class="input-group">
            <input type="text" ng-change="$ctrl.operatePricing()" ng-model="$ctrl.compromis.prixCession"
                   id="compromisPrixCession" class="form-control" soq-only-digits="{float: true}">

            <span class="input-group-addon">&euro;</span>
          </div>
        </div>

        <div ng-if="$ctrl.compromis.negocie === true" class="col-xs-12 col-sm-6 col-md-4">
          <label for="compromisHonorairesNego">Honoraires de négociation</label>

          <div class="input-group">
            <input type="text" id="compromisHonorairesNego" class="form-control"
                   ng-model="$ctrl.compromis.honorairesNego" soq-only-digits="{float: true}">

            <span class="input-group-addon">&euro;</span>
          </div>
        </div>

        <div ng-if="$ctrl.compromis.negocie === true && $ctrl.options.negociationResume.codeType === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU"
             class="col-xs-12 col-sm-6 col-md-4">
          <label for="compromisHonorairesNegoPercus">Négociation perçue</label>

          <div class="input-group">
            <input type="text" id="compromisHonorairesNegoPercus" class="form-control"
                   ng-model="$ctrl.compromis.honorairesNegoPercus" soq-only-digits="{float: true}">

            <span class="input-group-addon">&euro;</span>
          </div>
        </div>

        <div ng-if="$ctrl.compromis.negocie === true && $ctrl.options.negociationResume.codeType === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU"
             class="col-xs-12 col-sm-6">
          <label>Étude de l'acquéreur</label>

          <esk-select-etude ng-model="$ctrl.etudeAcquereur"></esk-select-etude>
        </div>

        <div ng-if="$ctrl.compromis.negocie === true && $ctrl.options.negociationResume.codeType === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU"
             class="col-xs-12 col-sm-6">
          <label for="compromisAcquereur">{{"Nom de l'acquéreur" | translate}}</label>

          <input id="compromisAcquereur" type="text" class="form-control" ng-model="$ctrl.compromis.acquereur"
                 placeholder="Nom de l'acquéreur">
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <label>{{"Date de vente" | translate}}</label>

          <esk-select-date model="$ctrl.compromisDateVente" is-required="true" options="$ctrl.options.dateVente">
          </esk-select-date>
        </div>

        <div ng-if="$ctrl.compromis.negocie === true && $ctrl.options.negociationResume.codeType === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE"
             class="col-xs-12 col-sm-6">
          <esk-select-dossier types-dossier-enabled="['demandeurs']" ng-model="$ctrl.demandeur"
                              options="$ctrl.options.demandeurs"></esk-select-dossier>

          <div class="checkbox clip-check check-green"
               title="La recherche sera effectuée également sur les demandeurs archivés.">
            <input type="checkbox" id="demandeurs-archives" ng-model="$ctrl.archivedDemandeurs"
                   ng-change="$ctrl.getArchivedDemandeurs()">

            <label for="demandeurs-archives">Archivés</label>
          </div>
        </div>

        <div class="col-xs-12">
          <label>Commentaires complémentaires</label>

          <app-ng2-textarea [text]="$ctrl.compromis.comments" (changed)="$ctrl.compromis.comments = $event">
          </app-ng2-textarea>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="[true, false].includes($ctrl.compromis.negocie) && $ctrl.options && !$ctrl.options.compromisAdding"
       class="modal-footer">
    <button type="submit" class="btn btn-default pull-left" ng-click="$ctrl.compromis.negocie = undefined">
      <fa-icon [icon]="'arrow-left'" size="xl"></fa-icon>
    </button>

    <button type="submit" class="btn btn-primary">Mettre en compromis</button>
  </div>
</form>
`;
