export default `
<div ng-if="!$ctrl.loader" class="label label-valeur">
  <ng-switch ng-if="$ctrl.estimation.typeValeurVenale.code === $ctrl.ESTIMATION_VALEUR_VENALE_TYPES.FOURCHETTE"
             on="(!!$ctrl.valeurVenaleMin && $ctrl.valeurVenaleMin > 0) || (!!$ctrl.valeurVenaleMax && $ctrl.valeurVenaleMax > 0)">
    <span ng-switch-when="false">Pas de valeur saisie</span>

    <ng-switch ng-switch-default
               on="!!$ctrl.valeurVenaleMin && $ctrl.valeurVenaleMin > 0 && !!$ctrl.valeurVenaleMax && $ctrl.valeurVenaleMax > 0">
      <ng-switch on="!!$ctrl.valeurVenaleMin && $ctrl.valeurVenaleMin > 0" ng-switch-when="false">
        <span ng-switch-when="true">
          A partir
          de {{$ctrl.valeurVenaleMin | currency:'€':2 * ($ctrl.valeurVenaleMin % 1 !== 0)}}
        </span>

        <span ng-switch-default>
          Jusqu'à {{$ctrl.valeurVenaleMax | currency:'€':2 * ($ctrl.valeurVenaleMax % 1 !== 0)}}
        </span>
      </ng-switch>

      <span ng-switch-default>
        Entre {{$ctrl.valeurVenaleMin | currency:'€':2 * ($ctrl.valeurVenaleMin % 1 !== 0)}}
        et {{$ctrl.valeurVenaleMax | currency:'€':2 * ($ctrl.valeurVenaleMax % 1 !== 0)}}
      </span>
    </ng-switch>
  </ng-switch>

  <ng-switch on="!!$ctrl.valeurVenale" ng-if="$ctrl.estimation.typeValeurVenale.code !== $ctrl.ESTIMATION_VALEUR_VENALE_TYPES.FOURCHETTE">
    <span ng-switch-when="false">Pas de valeur saisie</span>

    <span ng-switch-default>
      {{$ctrl.valeurVenale | currency:'€':2 * ($ctrl.valeurVenale % 1 !== 0)}}

      <span ng-if="$ctrl.estimation.type.code === $ctrl.ESTIMATION_TYPES.AVIS_VALEUR && !!$ctrl.pourcentageValeurVenale"
            class="text-muted text-small">
        + ou - {{$ctrl.pourcentageValeurVenale | currency:'%':0}}
      </span>
    </span>
  </ng-switch>
</div>
`;
