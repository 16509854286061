import {inject, Injectable} from '@angular/core';
import Procedure from '@models/procedures/procedure/procedure.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {ProcedureFactory} from '@models/procedures/procedure/procedure.factory';
import Document from '@models/documents/document/document.model';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {
    CProcedureSignatairesService
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.service';
import {CProceduresFactory} from '@models/procedures/collection/procedures.collection.factory';
import {ICProceduresQueryParameters} from '@models/procedures/collection/procedures.collection.interfaces';
import {
    CProcedureSignatairesFactory
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.factory';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {
    ProcedureSignataireService
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.service';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import {MediaFactory} from '@models/medias/media/media.factory';
import {ModalService} from '@shared/modal/modal.service';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {ProcedureSignatairesService} from '@models/procedures/procedure/signataires/procedure-signataires.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import Media from '@models/medias/media/media.model';
import {SortConst} from '@shared/constants';

@Injectable({providedIn: 'root'})
export class ProcedureService {
    static readonly messages = {
        cancel: {
            button: {CANCEL: 'Continuer', CONFIRMATION: 'Annuler la procédure'},
            comments: {
                DISTANCIEL_FACTURE: 'Conformément au contrat, cela vous sera facturé.',
                NO_SIGNATAIRES: 'Aucun signataire n\'a encore validé la procédure.',
                PROCEDURE: 'Vous êtes sur le point d\'annuler la procédure de signature',
            },
            TITLE: 'Annulation de la procédure',
        },
        initSignataires: {
            MESSAGE: 'Les contacts physiques sont pré-saisis comme signataires.',
            MESSAGE_WITH_RESPONSABLE_DOSSIER: 'Les contacts physiques et le responsable du dossier sont pré-saisis comme signataires.',
            TITLE: 'Saisie des signataires',
        },
    };
    private _cProceduresFactory = inject(CProceduresFactory);
    private _cProcedureSignatairesFactory = inject(CProcedureSignatairesFactory);
    private _cProcedureSignatairesService = inject(CProcedureSignatairesService);
    private _documentFactory = inject(DocumentFactory);
    private _mediaFactory = inject(MediaFactory);
    private _modalService = inject(ModalService);
    private _procedureFactory = inject(ProcedureFactory);
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);
    private _procedureSignataireService = inject(ProcedureSignataireService);
    private _procedureSignatairesService = inject(ProcedureSignatairesService);
    private _toasterService = inject(ToasterService);
    private _lastEditedSubject = new Subject<Procedure>();
    private _currentSource = new BehaviorSubject<Procedure>(undefined!);
    private _current$ = this._currentSource.asObservable();

    get current$(): Observable<Procedure> {
        return this._current$;
    }

    get lastEdited$(): Observable<Procedure> {
        return this._lastEditedSubject.asObservable();
    }

    cancel$(procedure: Procedure): Observable<boolean> {
        return combineLatest([
            this.getTitle$(procedure),
            this.getCProcedureSignataires$(procedure).pipe(
                map(cProcedureSignataires => {
                    const procedureSignatairesFinished = cProcedureSignataires.getFinished();

                    if (procedureSignatairesFinished.length > 0) {
                        let message = procedureSignatairesFinished.length.toString() + (procedureSignatairesFinished.length > 1 ? ' signataires ont' : ' signataire a') +
                            ' déjà signé.';

                        if (procedure.isDistanciel()) {
                            message += ' ' + ProcedureService.messages.cancel.comments.DISTANCIEL_FACTURE;
                        }

                        return message;
                    }

                    return ProcedureService.messages.cancel.comments.NO_SIGNATAIRES;
                }),
            )
        ]).pipe(
            map(([procedureTitle, procedureSignataires]) => ' ' + procedure.providerLabel + ' de "' + procedureTitle + '".<br>' + procedureSignataires),
            switchMap(message => this._modalService.openConfirmation$({
                buttonCancelLabel: ProcedureService.messages.cancel.button.CANCEL,
                buttonConfirmationLabel: ProcedureService.messages.cancel.button.CONFIRMATION,
                question: ProcedureService.messages.cancel.comments.PROCEDURE + message,
                title: ProcedureService.messages.cancel.TITLE,
                status: ModalService.status.WARNING,
            })),
            switchMap(isAccepted => {
                if (!isAccepted) {
                    return of(false);
                }

                return this._procedureFactory.cancel$(procedure).pipe(
                    tap(_ => procedure.setCanceled()),
                    tap(_ => this._lastEditedSubject.next(procedure)),
                    map(_ => true),
                );
            }),
        );
    }

    createFromDossierDocument(dossier: ADossier, document: Document, contactId?: number): Procedure {
        const procedure = this._procedureFactory.createVirgin();

        procedure.documentId = document.id;
        dossier.contactsGroup.members
            .filter(member => !contactId || contactId === member.contact.id)
            .forEach(member => procedure.cSignataires.addResult(this._procedureSignataireFactory.createFromContact(member.contact)));

        return procedure;
    }

    getCProcedureSignataires$(procedure: Procedure, forDossiers: ADossier[] = [], options = {withResponsableDossier: true}): Observable<CProcedureSignataires> {
        return this._cProcedureSignatairesFactory.get$(procedure.uuid).pipe(switchMap(cProcedureSignataires => {
            if (cProcedureSignataires.total <= 0 && forDossiers.length > 0) {
                return this._procedureSignatairesService.getPotentialsFromDossiers$(forDossiers, options).pipe(
                    tap(potentialSignataires => this._cProcedureSignatairesService.init(cProcedureSignataires, potentialSignataires)),
                    tap(_ => this._toasterService.info(ProcedureService.messages.initSignataires.TITLE,
                        options.withResponsableDossier ? ProcedureService.messages.initSignataires.MESSAGE_WITH_RESPONSABLE_DOSSIER : ProcedureService.messages.initSignataires.MESSAGE)),
                    map(_ => cProcedureSignataires),
                );
            }

            return of(cProcedureSignataires);
        }));
    }

    getDocument$(procedure: Procedure): Observable<Document> {
        return this._documentFactory.getByLink$(procedure.linkDocument);
    }

    getLastCreated$(cProceduresQueryParameters: ICProceduresQueryParameters): Observable<Procedure> {
        const queryParameters: ICProceduresQueryParameters = {...{tris: {}}, ...cProceduresQueryParameters};

        queryParameters.tris!.createdAt = SortConst.DESCENDING;

        return this._cProceduresFactory.get$(queryParameters).pipe(
            map(cProcedures => cProcedures.results[0]),
            switchMap(lastCreatedProcedure => {
                if (!lastCreatedProcedure) {
                    return of(undefined! as Procedure);
                }

                return this.getWithCProcedureSignataires$(lastCreatedProcedure.uuid);
            }),
        );
    }

    getMedia$(procedure: Procedure): Observable<Media> {
        return this._mediaFactory.getByLink$(procedure.linkMedia);
    }

    getTitle$(procedure: Procedure): Observable<string> {
        if (procedure.linkMedia) {
            return this.getMedia$(procedure).pipe(map(media => media.title));
        }

        return this.getDocument$(procedure).pipe(map(document => document.titre));
    }

    getWithCProcedureSignataires$(procedureUuid: string): Observable<Procedure> {
        return this._procedureFactory.get$(procedureUuid).pipe(
            switchMap(procedure => this.getCProcedureSignataires$(procedure).pipe(
                tap(cProcedureSignataires => procedure.cSignataires = cProcedureSignataires),
                map(_ => procedure),
            )),
        );
    }

    initCurrent(link: string): void {
        this._currentSource.next(undefined!);
        this._procedureFactory.getByLink$(link).pipe(take(1)).subscribe(procedure => this._currentSource.next(procedure));
    }

    launch$(procedure: Procedure): Observable<Procedure> {
        return this._procedureFactory.launch$(procedure).pipe(
            switchMap(_ => this._procedureFactory.getByLink$(procedure.linkSelf)),
            tap(updatetedProcedure => this._lastEditedSubject.next(updatetedProcedure)),
        );
    }

    launchCurrent$(): Observable<void> {
        return this.current$.pipe(
            take(1),
            switchMap(currentProcedure => this.launch$(currentProcedure)),
            map(updatetedProcedure => this._currentSource.next(updatetedProcedure)),
        );
    }

    save$(procedureToSave: Procedure): Observable<Procedure> {
        return this._procedureFactory.save$(procedureToSave).pipe(
            tap(procedure => procedure.cSignataires = procedureToSave.cSignataires),
            switchMap(procedure => this._cProcedureSignatairesService.save$(procedure).pipe(
                tap(cSignataires => procedure.cSignataires = cSignataires),
                map(_ => procedure),
            )),
            tap(procedure => this._lastEditedSubject.next(procedure))
        );
    }

    saveCurrentSignataires$(deletedProcedureSignataires: ProcedureSignataire[], editedProcedureSignataires: ProcedureSignataire[]): Observable<void> {
        return this.current$.pipe(
            take(1),
            switchMap(procedure => this._procedureSignatairesService.update$(procedure, deletedProcedureSignataires, editedProcedureSignataires).pipe(
                map(_ => procedure)
            )),
            switchMap(procedure => this._procedureFactory.getByLink$(procedure.linkSelf)),
            tap(procedure => this._currentSource.next(procedure)),
            map(_ => undefined),
        );
    }

    signataireSign$(procedure: Procedure, procedureSignataire: ProcedureSignataire, signature: string): Observable<void> {
        return this._procedureSignataireService.sign$(procedure.uuid, procedureSignataire, signature).pipe(
            switchMap(_ => this._procedureFactory.get$(procedure.uuid)),
            map(updateProcedure => {
                procedure.statut = updateProcedure.statut;
                procedure.updatedAt = updateProcedure.updatedAt;
            }),
        );
    }
}
