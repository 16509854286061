import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEskButtonSave(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-button-save></esk-button-save>
         */
        module.component('eskButtonSave', {
            bindings: {saving: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/button/save.html'
        });

        /**
         * Controller for saving
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.saving = false;
            }
        }
    })(angularJS);
}
