export default `
<ng-switch on="$ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork">
  <div ng-switch-default>
    {{"Vous avez encore" | translate}} {{$ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork}} {{"jours avant l'envoi automatique de votre bien en vente sur le réseau." | translate}}
  </div>

  <div ng-switch-when="1">
    {{"Demain soir, votre bien en vente sera envoyé automatiquement sur le réseau." | translate}}
  </div>

  <div ng-switch-when="0">
    {{"Ce soir, votre bien en vente sera envoyé automatiquement sur le réseau." | translate}}
  </div>
</ng-switch>
`;
