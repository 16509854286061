export default `
<div class="text-justify">
  Les contacts associés à ce demandeur possédant une adresse email recevront quotidiennement et automatiquement les
  biens correspondants par email.
  <br>
  Un espace sur le site <a ng-href="{{$ctrl.myNotyUrl}}" target="_blank">MyNoty</a> leur sera dédié afin qu'ils puissent
  les consulter à tout moment.
  <br>
  De plus, les biens correspondants transmis passeront automatiquement en "Intéressé" dans la liste des résultats.
</div>
`;
