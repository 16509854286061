import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BienPhotoFactory} from '@models/bien/photos/photo/bien-photo.factory';
import {BienPhotosApiService} from '@models/bien/photos/bien-photos.api.service';
import CBienPhotos from '@models/bien/photos/collection/bien-photos.collection.model';
import {ICBienPhotosApi} from '@models/bien/photos/collection/bien-photos.collection.interfaces';

@Injectable({providedIn: 'root'})
export class CBienPhotosFactory {
    private _bienPhotoFactory = inject(BienPhotoFactory);
    private _bienPhotosApiService = inject(BienPhotosApiService);

    create(cBienPhotosApi: ICBienPhotosApi): CBienPhotos {
        const cBienPhotos = new CBienPhotos();

        cBienPhotos.links = new Links(cBienPhotosApi._links);
        cBienPhotos.page = cBienPhotosApi.page;
        cBienPhotos.pages = cBienPhotosApi.pages;
        cBienPhotos.perPage = cBienPhotosApi.limit;
        cBienPhotos.total = cBienPhotosApi.total;

        if (cBienPhotosApi._embedded?.items) {
            cBienPhotosApi._embedded.items.filter(bienPhotoApi => bienPhotoApi)
                .map(bienPhotoApi => cBienPhotos.results.push(this._bienPhotoFactory.create(bienPhotoApi)));
        }

        return cBienPhotos;
    }

    getByLink$(link: string): Observable<CBienPhotos> {
        return this._bienPhotosApiService.getCollectionByLink$(link).pipe(map(cBienPhotosApi => this.create(cBienPhotosApi)));
    }
}
