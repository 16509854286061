import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    IEtudeSettingsDocumentsHeaderApi
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.interfaces';
import {map} from 'rxjs/operators';
import environment from '@env/environment';

@Injectable({providedIn: 'root'})
export class EtudeSettingsDocumentsHeaderApiService {
    static readonly CSS_FILE = environment.api.baseUrl + '/build/documents/css/header.css';
    private _modelApiService = inject(ModelApiService);

    get$(etudeUuid: string): Observable<IEtudeSettingsDocumentsHeaderApi> {
        return this._modelApiService.get$('/etudes/' + etudeUuid + '/settings/documents/header');
    }

    save$(etudeUuid: string, etudeSettingsDocumentsHeaderApi: IEtudeSettingsDocumentsHeaderApi): Observable<IEtudeSettingsDocumentsHeaderApi> {
        return this._modelApiService.put$('/etudes/' + etudeUuid + '/settings/documents/header', etudeSettingsDocumentsHeaderApi)
            .pipe(map(_ => etudeSettingsDocumentsHeaderApi));
    }
}
