export default `
<div class="modal-body">
  <esk-cropper url="{{$ctrl.photo._links.file.href}}?size=original_rotated" on-crop="$ctrl.onCrop(details)">
  </esk-cropper>
</div>

<div class="modal-footer" ng-switch="$ctrl.saving">
  <div ng-switch-when="false">
    <button class="btn btn-default" type="button" ng-click="$dismiss();">
      <fa-icon [icon]="'times'"></fa-icon> Annuler
    </button>

    <button class="btn btn-primary" type="button" ng-click="$ctrl.save()">
      <fa-icon [icon]="'check'"></fa-icon> Enregistrer
    </button>
  </div>

  <p ng-switch-when="true" class="text-right">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
  </p>
</div>
`;
