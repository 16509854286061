import AModel from '@models/model.abstract';

export default class Report extends AModel {
    private _date!: string;
    private readonly _id: number;
    private _subject!: string;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get id(): number {
        return this._id;
    }

    get subject(): string {
        return this._subject;
    }

    set subject(value: string) {
        this._subject = value;
    }
}
