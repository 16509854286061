import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[appFormEmailValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: FormEmailValidatorDirective, multi: true}],
})
export class FormEmailValidatorDirective implements Validator {
    static readonly REG_EXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    validate(control: AbstractControl<string>): ValidationErrors | null {
        return !control.value || FormEmailValidatorDirective.REG_EXP.test(control.value) ? null : {email: true};
    }
}
