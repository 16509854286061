import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EtudeDiffusionsApiService} from '@models/etudes/etude/diffusions/etude-diffusions.api.service';
import {EtudeDiffusionFactory} from '@models/etudes/etude/diffusions/diffusion/etude-diffusion.factory';
import {
    IEtudeDiffusionsQueryParameters, IEtudeDiffusionsWallet
} from '@models/etudes/etude/diffusions/etude-diffusions.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeDiffusionsService {
    private _etudeDiffusionFactory = inject(EtudeDiffusionFactory);
    private _etudeDiffusionsApiService = inject(EtudeDiffusionsApiService);

    getWalletEtat$(queryParameters?: IEtudeDiffusionsQueryParameters): Observable<IEtudeDiffusionsWallet> {
        return this._etudeDiffusionsApiService.getWalletEtat$(queryParameters).pipe(
            map(etudeDiffusionWalletApi => {
                const etudeDiffusionWallet = {} as IEtudeDiffusionsWallet;

                if (etudeDiffusionWalletApi.locations) {
                    etudeDiffusionWallet.locations = etudeDiffusionWalletApi.locations.map(etudeDiffusionApi => this._etudeDiffusionFactory.create(etudeDiffusionApi));
                }

                if (etudeDiffusionWalletApi.ventes) {
                    etudeDiffusionWallet.ventes = etudeDiffusionWalletApi.ventes.map(etudeDiffusionApi => this._etudeDiffusionFactory.create(etudeDiffusionApi));
                }

                return etudeDiffusionWallet;
            }),
        );
    }
}
