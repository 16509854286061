import angularJS from '@shared/angularJS/global.ng';
import {
    IDeferred, IHttpRequestConfigHeaders, IHttpResponse, IHttpService, IModule, IPromise, IQService, IServiceProvider
} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgAuthenticationService} from '@legacy/app/auth/auth';
import environment from '@env/environment';
import {ApiService} from '@core/api/api.service';

export default function getClientConfig(module: IModule): void {
    ((angular) => {
        'use strict';

        module.provider('ClientConfig', Provider as unknown as IServiceProvider);

        /**
         * Use of API
         *
         * @returns {methods}
         */
        Provider.$inject = [] as string[];
        function Provider(this: any) {
            let baseUrl: string;
            let versionAPI: string;

            this.setBaseUrl = setBaseUrl;
            this.setVersionAPI = setVersionAPI;
            this.$get = Service;

            /**
             * Set base URL
             *
             * @param value
             */
            function setBaseUrl(value: string) {
                baseUrl = value;
            }

            /**
             * Set versionAPI
             *
             * @param value
             */
            function setVersionAPI(value: string) {
                versionAPI = value;
            }

            /**
             * All methods to manage connexion
             *
             * @param $q
             * @param $http
             * @param Ng2RouterStateForNgService
             * @param AuthenticationService
             */
            Service.$inject = ['$q', '$http', 'Ng2RouterStateForNgService', 'AuthenticationService'];
            function Service($q: IQService,
                             $http: IHttpService,
                             ng2RouterStateForNgService: RouterStateForNgService,
                             authenticationService: NgAuthenticationService) {
                let refreshInProgress = false;
                let requestsInError: { requestError: IHttpResponse<unknown>, deferred: IDeferred<unknown> }[] = [];
                let impersonateUsername: string;
                const factory = {
                    setImpersonate: setImpersonate,
                    isImpersonate: isImpersonate,
                    getImpersonate: getImpersonate,
                    razImpersonate: razImpersonate,
                    requestError: requestError,
                    getFullUrl: getFullUrl,
                    serializeURIParams: serializeURIParams,
                    getConfig: getConfig,
                    deleteEmbedded: deleteEmbedded
                };

                return factory;

                /**
                 * Set impersonate
                 *
                 * @param username
                 */
                function setImpersonate(username: string) {
                    ng2RouterStateForNgService.navigateByUrl('').then(_ => {
                        impersonateUsername = username;
                        setTimeout(() => ng2RouterStateForNgService.navigateByUrl('start'), 1);
                    });
                }

                /**
                 * Is impersonate
                 */
                function isImpersonate() {
                    return angular.isString(impersonateUsername);
                }

                /**
                 * Is impersonate
                 */
                function getImpersonate() {
                    return impersonateUsername;
                }

                /**
                 * RAZ impersonate
                 */
                function razImpersonate() {
                    ng2RouterStateForNgService.navigateByUrl('/app/dashboard').then(_ => {
                        setTimeout(() => {
                            impersonateUsername = undefined!;
                            ng2RouterStateForNgService.navigateByUrl('start');
                        }, 2000);
                    });
                }

                /**
                 * Manage request error
                 *
                 * @param requestError
                 * @returns {Promise}
                 */
                function requestError(requestError: IHttpResponse<unknown>): IPromise<unknown> {
                    const deferred = $q.defer();

                    // Ignore invalid rejection
                    if (!angular.isObject(requestError)) {
                        return $q.reject(requestError);
                    }

                    // Erreurs non gérées
                    switch (requestError.status) {
                        case 401 :
                            manage401(requestError, deferred);

                            return deferred.promise;

                        default :
                            return $q.reject(requestError);
                    }

                    /**
                     * Manage 401 error
                     *
                     * @param requestError
                     * @param deferred
                     */
                    function manage401(requestError: IHttpResponse<unknown>, deferred: IDeferred<unknown>) {
                        let promise;

                        requestsInError.push({requestError, deferred});

                        // Un seul lancement de refresh pour ne pas le faire pour chaque requête
                        if (refreshInProgress) {
                            return;
                        }
                        refreshInProgress = true;

                        // Refresh du token
                        if (authenticationService.hasRefreshToken()) {
                            promise = authenticationService.authenticateWithRefreshToken().then(function () {
                                // Succès du refresh ok, on relance toutes les requêtes en attente, copie utile car d'autres requêtes pourraient être ajoutées
                                while (requestsInError.length > 0) {
                                    // Modification des headers pour mettre le nouvel accessToken
                                    requestsInError[0].requestError.config.headers!.Authorization = "Bearer " + authenticationService.getAccessToken();
                                    // Relance de la requête(succès de la promesse liée à la requête, échec de la promesse liée à la requête si la relance a échoué)
                                    $http(requestsInError[0].requestError.config).then(requestsInError[0].deferred.resolve, requestsInError[0].deferred.reject);
                                    // Suppression des requêtes prises en compte
                                    requestsInError.splice(0, 1);
                                }
                            });
                        } else {
                            promise = $q.reject('NO_REFRESH_TOKEN_401');
                        }

                        promise.catch(function (error) {
                            // Échec du refresh, on rejette toutes les requêtes en attente
                            angular.forEach(angular.copy(requestsInError), function (requestInError) {
                                requestInError.deferred.reject(error);
                            });
                            requestsInError = [];
                            // Redirection vers la page de /auth/login
                            authenticationService.redirect('/auth/login', 'api.error.unauthorized', ng2RouterStateForNgService.current.name!, ng2RouterStateForNgService.params);
                        }).finally(function () {
                            refreshInProgress = false;
                        });
                    }
                }

                /**
                 * Return complete URL
                 *
                 * @param path
                 * @param queryParams
                 * @returns {string}
                 */
                function getFullUrl(path: string, queryParams: any) {
                    let stringParams;

                    if (!angular.isObject(queryParams)) {
                        queryParams = {};
                    }

                    if (factory.isImpersonate()) {
                        queryParams[ApiService.SWITCH_USER_KEY] = impersonateUsername;
                    }

                    stringParams = factory.serializeURIParams(queryParams, undefined!);

                    if (stringParams !== "") {
                        stringParams = (path.indexOf("?") <= 0 ? "?" : "&") + stringParams;
                    }

                    const fullUrl = (angular.isDefined(baseUrl) ? baseUrl : '') + path + stringParams;

                    return fullUrl.replace(environment.api.pathUrl.repeat(2), environment.api.pathUrl)
                }

                /**
                 * Serialized in string query params
                 *
                 * @param params
                 * @param prefix
                 * @returns {string}
                 */
                function serializeURIParams(params: unknown[], prefix: string): string {
                    let thisPrefix: string;
                    const serializedParams: string[] = [];

                    angular.forEach(params, (value, key: string | number) => {
                        if (angular.isDefined(value)) {
                            thisPrefix = prefix ? prefix + "[" + (typeof key === "number" ? "" : key) + "]" : key.toString();
                            serializedParams.push(typeof value === "object" ? factory.serializeURIParams(value as unknown[], thisPrefix) : thisPrefix + "=" + encodeURIComponent(value as string));
                        }
                    });

                    return serializedParams.join("&");
                }

                /**
                 * Return complete headers
                 *
                 * @param additionalHeaders
                 * @returns {Object}
                 */
                function getConfig(additionalHeaders: IHttpRequestConfigHeaders): IHttpRequestConfigHeaders {
                    const config: IHttpRequestConfigHeaders = {
                        headers: {
                            Accept: "application/vnd.noty.api+json;version=" + versionAPI,
                            'Content-Type': "application/vnd.noty.api+json;version=" + versionAPI
                        }
                    };

                    if (authenticationService.isAuthenticated()) {
                        config.headers['Authorization'] = "Bearer " + authenticationService.getAccessToken();
                    }
                    angular.extend(config.headers, additionalHeaders);

                    return config;
                }

                /**
                 * Remove _embedded from object
                 *
                 * @param element
                 * @returns {*}
                 */
                function deleteEmbedded(element: unknown): unknown {
                    let newElement;
                    let object;

                    if (angular.isArray(element)) {
                        newElement = [];
                        for (let i = 0, c = element.length; i < c; i++) {
                            newElement.push(factory.deleteEmbedded(element[i]));
                        }
                    } else {
                        if (angular.isObject(element)) {
                            newElement = {};
                            angular.forEach(element, function (value, key) {
                                // @ts-ignore Erreur TS2367
                                if (key === '_embedded') {
                                    object = factory.deleteEmbedded(value);
                                    angular.forEach(object, function (valueObject, keyObject) {
                                        newElement[keyObject] = valueObject;
                                    });
                                } else {
                                    newElement[key] = factory.deleteEmbedded(value);
                                }
                            });
                        } else {
                            newElement = element;
                        }
                    }

                    return newElement;
                }
            }
        }
    })(angularJS);
}
