import {Component, Input} from '@angular/core';
import {IInotLoaderOptions} from '@features/inot/inot.interfaces';

@Component({selector: 'app-inot-loader', templateUrl: 'inot.loader.component.html'})
export class AppInotLoaderComponent {
    static readonly initInotLoaderOptions: IInotLoaderOptions = {type: 'contacts'};
    private _classHeight = 'tw-h-4';
    private _options: IInotLoaderOptions = {...AppInotLoaderComponent.initInotLoaderOptions};

    get classHeight(): string {
        return this._classHeight;
    }

    @Input()
    set classHeight(value: string) {
        this._classHeight = value;
    }

    get options(): IInotLoaderOptions {
        return this._options;
    }

    @Input()
    set options(value: IInotLoaderOptions) {
        this._options = {...AppInotLoaderComponent.initInotLoaderOptions, ...value};
    }
}
