export default `
<soq-panel box="$ctrl.box">
  <tools-slot>
    <button type="button" class="tw-btn-tertiary-info tw-py-1 tw-px-2" ng-click="$ctrl.openConfig()">
      <fa-icon [icon]="'cogs'"></fa-icon>
    </button>

    <button type="button" class="tw-btn-tertiary-info tw-py-1 tw-px-2" ng-click="$ctrl.updateEstimations()">
      <fa-icon [icon]="'sync-alt'"></fa-icon>
    </button>
  </tools-slot>

  <content-slot>
    <esk-estimations-list estimations="$ctrl.estimations" options="$ctrl.options"></esk-estimations-list>

    <div ng-if="$ctrl.estimations.totalItems <= 0" class="tw-text-center margin-top-15 margin-bottom-15">
      <p class="text-muted">Aucun bien en estimation à relancer.</p>
    </div>
  </content-slot>

  <footer-slot ng-if="$ctrl.estimations.totalItems > 0">
    <a ng-click="$ctrl.goToEstimations()">
      <fa-icon [icon]="'arrow-circle-right'"></fa-icon> Tous les biens en estimation à relancer
    </a>
  </footer-slot>
</soq-panel>
`;
