export default `
<div ng-if="$ctrl.dossier._esk.errorsSaving.nbErrors > 0" class="alert alert-warning">
  <fa-icon [icon]="'exclamation-triangle'"></fa-icon>

  <span ng-bind-html="$ctrl.dossier._esk.typeDossier + '.save.LIST' | translate: $ctrl.dossier._esk.errorsSaving">
  </span>
</div>

<div ng-if="!!$ctrl.dossier.id" class="pull-right fiche-link">
  <a class="btn btn-azure btn-o" ng-click="$ctrl.viewDossierBien()">
    <fa-icon [icon]="['far', 'file']"></fa-icon> Voir la fiche de détails
  </a>
</div>
`;
