import {Component, Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {NgBienDossier} from '@legacy/app/managers/ressources';

@Component({selector: 'esk-bien-edit-header', template: '<div appNgEskBienEditHeader [dossier]="dossier"></div>'})
export class NgBienEditHeaderComponent {
    @Input() dossier!: NgBienDossier;
}

@Directive({selector: '[appNgEskBienEditHeader]'})
export class NgBienEditHeaderDirective extends UpgradeComponent {
    @Input() dossier!: NgBienDossier;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskBienEditHeader', elementRef, injector);
    }
}
