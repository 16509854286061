import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICVentePrixHistoriesApi
} from '@models/ventes/vente/prix-histories/collection/vente-prix-histories.collection.interfaces';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class VentePrixHistoriesApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(venteId: string, queryParameters?: IQueryParameters): Observable<ICVentePrixHistoriesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/ventes/' + venteId + '/historiques-prix-vente', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICVentePrixHistoriesApi> {
        return this._modelApiService.getCollection$(link);
    }
}
