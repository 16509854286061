import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';
import InotUser from '@models/inot-users/user/inot-user.model';
import {DossierType} from '@models/dossiers/dossiers.interfaces';

export default class DossierExportSave {
    private _contacts: EtudeDossierQualifiedContact[] = [];
    private _destinataire!: InotUser;
    private _dossierUuid: string;
    private _dossierType: DossierType;
    private _mediaIds: number[] = [];

    constructor(dossierType: DossierType, dossierUuid: string) {
        this._dossierType = dossierType;
        this._dossierUuid = dossierUuid;
    }

    get contacts(): EtudeDossierQualifiedContact[] {
        return this._contacts;
    }

    set contacts(value: EtudeDossierQualifiedContact[]) {
        this._contacts = value;
    }

    get destinataire(): InotUser {
        return this._destinataire;
    }

    set destinataire(value: InotUser) {
        this._destinataire = value;
    }

    get dossierType(): DossierType {
        return this._dossierType;
    }

    get dossierUuid(): string {
        return this._dossierUuid;
    }

    get mediaIds(): number[] {
        return this._mediaIds;
    }

    set mediaIds(value: number[]) {
        this._mediaIds = value;
    }
}
