import {inject, Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {map, tap} from 'rxjs/operators';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import Contact from '@models/contacts/contact/contact.model';
import {UserService} from '@models/users/user/user.service';
import CDemandeurRechercheResultats
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.model';
import {DemandeurRechercheService} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.service';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgDemandeurManager} from '@legacy/app/managers/managers';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';
import {ContactFactory} from '@models/contacts/contact/contact.factory';

@Injectable({providedIn: 'root'})
export class DemandeurService {
    private _contactFactory = inject(ContactFactory);
    private _contactsGroupService = inject(ContactsGroupService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurRechercheService = inject(DemandeurRechercheService);
    private _ngInjectorService = inject(NgInjectorService);
    private _userService = inject(UserService);

    // Supprimer les injections des anciens manager
    private get ngDemandeurManager(): NgDemandeurManager {
        return this._ngInjectorService.getService('DemandeurManager');
    }

    createFromContact$(contact: Contact): Observable<Demandeur> {
        return this._userService.last$.pipe(
            map(currentUser => {
                const demandeur = this._demandeurFactory.createFromContact(contact);

                demandeur.responsableDossier = currentUser;

                return demandeur;
            }),
        );
    }

    createFromVenteArchiveInfos$(venteArchiveInfos: VenteArchiveInfos): Observable<Demandeur> {
        return this._userService.last$.pipe(
            map(currentUser => {
                const contact = this._contactFactory.createFromVenteArchiveInfos(venteArchiveInfos);
                const demandeur = this._demandeurFactory.createFromContact(contact);

                demandeur.responsableDossier = currentUser;

                return demandeur;
            }),
        );
    }

    getCResultsByFilter$(demandeur: Demandeur, code: string, keywords?: string): Observable<CDemandeurRechercheResultats> {
        return this.getOneRecherche$(demandeur).pipe(
            switchMap(demandeurRecherche => this._demandeurRechercheService.getCResultsByFilter$(demandeurRecherche, code, keywords)),
        );
    }

    getCurrentFromNg(): Demandeur {
        if (!this.ngDemandeurManager.current) {
            return undefined!;
        }

        return this._demandeurFactory.ngCreate(this.ngDemandeurManager.current);
    }

    getOneRecherche$(demandeur: Demandeur): Observable<DemandeurRecherche> {
        return this._demandeurRechercheService.getOneFromDemandeur$(demandeur);
    }

    save$(demandeur: Demandeur): Observable<Demandeur> {
        let contactsGroup$ = of(demandeur.contactsGroup);

        // Avant d'enlever la condition, s'assurer que tous les champs de ContactsGroup sont correctement pris en compte à l'enregistrement
        if (demandeur.contactsGroup.uuid === ContactsGroup.statuts.NEW) {
            contactsGroup$ = this._contactsGroupService.save$(demandeur.contactsGroup).pipe(tap(contactsGroup => demandeur.contactsGroup = contactsGroup));
        }

        return contactsGroup$.pipe(switchMap(_ => this._demandeurFactory.save$(demandeur)));
    }
}
