@if (cUsers$ | async; as cUsers) {
  <div>
    @if (cUsers.total > 0) {
      <div class="tw-p-4 md:tw-px-0">
        <span class="tw-font-bold">{{ cUsers.total }}</span> {{ cUsers.total > 1 ? 'utilisateurs' : 'utilisateur' }}
      </div>

      <div class="tw-overflow-hidden tw-mb-8">
        <div class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-6 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5 xl:tw-gap-x-8">
          @for (user of cUsers.results; track user) {
            <app-user-card [user]="user"/>
          }
        </div>
      </div>
    } @else {
      <div class="tw-my-4 sm:tw-my-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
        <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
          Il n'y a aucun utilisateur.
        </p>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}

<app-load-more [collection$]="cUsers$" (loadMore)="getMore()" [loading]="pendingGetMore"/>
