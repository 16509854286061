import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';

export default function getVentesModalBoxSearchCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('VentesModalBoxSearchCtrl', Controller);

        /**
         * Controller for ventes without mandats modal
         *
         * @param EskBox
         * @param $translate
         * @param soqSweetAlert
         * @param $uibModalInstance
         * @constructor
         */
        Controller.$inject = ['EskBox', '$translate', 'SoqSweetAlert', '$uibModalInstance'];
        function Controller(
            this: any,
            eskBox: NgEskBox,
            $translate: angular.translate.ITranslateService,
            soqSweetAlert: NgSoqSweetAlert,
            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
        ) {
            const $ctrl = this;

            $ctrl.save = save;
            $ctrl.box = eskBox.getOneByCode('ventes-search');

            /**
             * Save configuration
             */
            function save() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));

                    return;
                }

                eskBox.saveConfig().then($uibModalInstance.close);
            }
        }
    })(angularJS);
}
