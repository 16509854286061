import {inject, Injectable} from '@angular/core';
import {
    IEtudeSettingsDocumentsHeaderApi
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.interfaces';
import EtudeSettingsDocumentsHeader
    from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.model';
import {Observable} from 'rxjs';
import {
    EtudeSettingsDocumentsHeaderApiService
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.api.service';
import {map} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';

@Injectable({providedIn: 'root'})
export class EtudeSettingsDocumentsHeaderFactory {
    private _etudeSettingsDocumentsHeaderApiService = inject(EtudeSettingsDocumentsHeaderApiService);

    create(etudeSettingsDocumentsHeaderApi: IEtudeSettingsDocumentsHeaderApi): EtudeSettingsDocumentsHeader {
        const etudeSettingsDocumentsHeader = new EtudeSettingsDocumentsHeader();

        etudeSettingsDocumentsHeader.code = etudeSettingsDocumentsHeaderApi.code;
        etudeSettingsDocumentsHeader.customHeight = etudeSettingsDocumentsHeaderApi.customHeight;
        etudeSettingsDocumentsHeader.customTemplate = etudeSettingsDocumentsHeaderApi.customTemplate;
        etudeSettingsDocumentsHeader.enabled = etudeSettingsDocumentsHeaderApi.enabled;

        return etudeSettingsDocumentsHeader;
    }

    forApi(etudeSettingsDocumentsHeader: EtudeSettingsDocumentsHeader): IEtudeSettingsDocumentsHeaderApi {
        const etudeSettingsDocumentsHeaderApi = {} as IEtudeSettingsDocumentsHeaderApi;

        if (etudeSettingsDocumentsHeader.enabled) {
            etudeSettingsDocumentsHeaderApi.code = etudeSettingsDocumentsHeader.code;

            if (etudeSettingsDocumentsHeader.code === EtudeSettingsDocumentsHeader.CODE_CUSTOM) {
                etudeSettingsDocumentsHeaderApi.customHeight = etudeSettingsDocumentsHeader.customHeight;
                etudeSettingsDocumentsHeaderApi.customTemplate = etudeSettingsDocumentsHeader.customTemplate;
            }
        }

        return etudeSettingsDocumentsHeaderApi;
    }

    get$(etude: Etude): Observable<EtudeSettingsDocumentsHeader> {
        return this._etudeSettingsDocumentsHeaderApiService.get$(etude.id.toString()).pipe(map(etudeSettingsDocumentsHeaderApi => this.create(etudeSettingsDocumentsHeaderApi)));
    }

    save$(etude: Etude, etudeSettingsDocumentsHeader: EtudeSettingsDocumentsHeader): Observable<EtudeSettingsDocumentsHeader> {
        return this._etudeSettingsDocumentsHeaderApiService.save$(etude.id.toString(), this.forApi(etudeSettingsDocumentsHeader)).pipe(
            map(etudeSettingsDocumentsHeaderApi => this.create(etudeSettingsDocumentsHeaderApi)),
        );
    }
}
