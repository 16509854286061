import {NgModule} from '@angular/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AppPdfComponent} from '@shared/pdf/pdf.component';
import {LoadModule} from '@shared/load/load.module';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [AppPdfComponent],
    exports: [AppPdfComponent],
    imports: [CommonModule, LoadModule, PdfViewerModule],
})
export class PdfModule {
}
