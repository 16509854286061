export default `
<div class="input-group input-group-number input-group-date datepicker">
  <input type="text" class="form-control text-right" uib-datepicker-popup="dd/MM/yyyy" name="{{$ctrl.formFieldName}}"
         ng-model="$ctrl.model" is-open="$ctrl.open" ng-click="$ctrl.open = true;" ng-required="$ctrl.ngRequired"
         datepicker-options="$ctrl.options" ng-change="$ctrl.change()" placeholder="{{$ctrl.options.placeholder}}">

  <span class="input-group-btn">
    <button type="button" class="btn btn-primary btn-green" ng-click="$ctrl.open = true;">
      <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
    </button>
  </span>
</div>

<div ng-if="$ctrl.optionsHour.showHour" uib-timepicker ng-model="$ctrl.model" show-meridian="false"
     show-spinners="false"></div>
`;
