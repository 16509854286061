import {Component, inject, OnInit} from '@angular/core';
import {Observable, ReplaySubject, Subject, switchMap} from 'rxjs';
import {FilterService} from '@models/filters/filter/filter.service';
import Filter from '@models/filters/filter/filter.model';
import {filter, take, tap} from 'rxjs/operators';
import Commune from '@models/communes/commune/commune.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import {ICSitesQueryParameters} from '@models/sites/collection/sites.collection.interfaces';
import {IMapBox} from '@shared/map/map.interfaces';
import {IEtudesSiteMapData} from '@features/etudes/etudes.interfaces';
import CSites from '@models/sites/collection/sites.collection.model';
import Site from '@models/sites/site/site.model';
import {Router} from '@angular/router';
import {SiteFactory} from '@models/sites/site/site.factory';
import {UserService} from '@models/users/user/user.service';
import {SortConst} from '@shared/constants';

@Component({selector: 'layout-annuaire-etudes', templateUrl: 'layout-annuaire-etudes.component.html'})
export class AppLayoutAnnuaireEtudesComponent implements OnInit {
    private _cSitesService = inject(CSitesService);
    private _filterService = inject(FilterService);
    private _router = inject(Router);
    private _siteFactory = inject(SiteFactory);
    private _userService = inject(UserService);
    private _cSitesSource = new Subject<CSites>();
    private _cSites$ = this._cSitesSource.asObservable();
    private _etudesSiteMapDataSource = new ReplaySubject<IEtudesSiteMapData>(1);
    private _etudesSiteMapData$ = this._etudesSiteMapDataSource.asObservable();
    private _etudesSiteMapBoxSource = new ReplaySubject<IMapBox>(1);
    private _etudesSiteMapBox$ = this._etudesSiteMapBoxSource.asObservable();
    private _filterSource = new Subject<Filter<ICSitesQueryParameters>>();
    private _filter$ = this._filterSource.asObservable();
    private _siteHoveredSource = new Subject<Site>();
    private _siteHovered$ = this._siteHoveredSource.asObservable();
    private _siteUserCurrent!: Site;

    get etudesSiteMapData$(): Observable<IEtudesSiteMapData> {
        return this._etudesSiteMapData$;
    }

    get etudesSiteMapBox$(): Observable<IMapBox> {
        return this._etudesSiteMapBox$;
    }

    get cSites$(): Observable<CSites> {
        return this._cSites$;
    }

    get filter$(): Observable<Filter<ICSitesQueryParameters>> {
        return this._filter$;
    }

    ngOnInit(): void {
        this._userService.last$.pipe(
            switchMap(user => this._siteFactory.getByLink$(user.linkSite)),
            tap(site => this._siteUserCurrent = site),
            switchMap(_ => this._filterService.getOneByRouteCode$<ICSitesQueryParameters>(Filter.routes.GET_ANNUAIRES, Filter.codes.SITES)),
            tap(filterSaved => {
                if(!this.hasCoordonnees(filterSaved)) {
                    filterSaved.queryParameters.mapBox = {
                        center: {
                            latitude: this._siteUserCurrent.latitude,
                            longitude: this._siteUserCurrent.longitude
                        },
                        source: 'site'
                    };
                }else {
                    filterSaved.queryParameters.mapBox!.source = 'filtre';
                }
            }),
            tap(filterSaved => this._filterSource.next(filterSaved)),
            take(1),
        ).subscribe(filterSaved => this.sendMapBox(filterSaved.queryParameters.mapBox!));
    }

    actionWhenNoResult(filterCurrent: Filter<ICSitesQueryParameters>): void {
        this._filterService.clear(filterCurrent);
        this._cSitesSource.next(undefined!);
        this.sendMapBox({
            center: {
                latitude: this._siteUserCurrent.latitude,
                longitude: this._siteUserCurrent.longitude
            },
            source: 'site'
        });
    }

    communeSelected(filterCurrent: Filter<ICSitesQueryParameters>, commune: Commune): void {
        filterCurrent.queryParameters.commune!.values = commune ? [commune.id] : [];

        if (commune) {
            this.sendMapBox({
                center: {
                    latitude: commune.coordonneesGps[0],
                    longitude: commune.coordonneesGps[1]
                },
                source: 'commune'
            });
        }
    }

    hasCoordonnees(filterCurrent: Filter<ICSitesQueryParameters>): boolean {
        return filterCurrent.queryParameters.coordonnees?.ne.lat !== undefined
            && filterCurrent.queryParameters.coordonnees?.ne.lon !== undefined
            && filterCurrent.queryParameters.coordonnees?.sw.lat !== undefined
            && filterCurrent.queryParameters.coordonnees?.sw.lon !== undefined;
    }

    mapChanged(filterCurrent: Filter<ICSitesQueryParameters>, mapBox: IMapBox): void {
        filterCurrent.queryParameters.coordonnees!.ne.lat = mapBox.nordEst!.latitude;
        filterCurrent.queryParameters.coordonnees!.ne.lon = mapBox.nordEst!.longitude;
        filterCurrent.queryParameters.coordonnees!.sw.lat = mapBox.sudOuest!.latitude;
        filterCurrent.queryParameters.coordonnees!.sw.lon = mapBox.sudOuest!.longitude;
        filterCurrent.queryParameters.tris!.distance = SortConst.ASCENDING;
        filterCurrent.queryParameters.mapBox = mapBox;

        this.sendMapBox(mapBox);
        this.updateList(filterCurrent);
    }

    searchedText(filterCurrent: Filter<ICSitesQueryParameters>, keywords: string): void {
        filterCurrent.queryParameters.keywords = keywords;
        this.updateList(filterCurrent);
    }

    sendMapBox(mapBox: IMapBox): void {
        this._etudesSiteMapBoxSource.next(mapBox);
    }

    siteClicked(site: Site): void {
        this._router.navigateByUrl('/app/annuaires/etudes/' + site.etudeId.toString());
    }

    siteHovered(site: Site): void {
        this._siteHoveredSource.next(site);
    }

    updateList(filterCurrent: Filter<ICSitesQueryParameters>): void {
        this._cSitesService.getAll$(filterCurrent.queryParameters).pipe(
            filter(cSites => !!cSites),
            take(1)
        ).subscribe(cSites => {
            this._etudesSiteMapDataSource.next({cSites: cSites, hovered$: this._siteHovered$});
            this._cSitesSource.next(cSites);
        });
        this._filterService.save$(filterCurrent).pipe(take(1)).subscribe();
    }
}
