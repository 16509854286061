import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-vente-diffusion', template: '<div appNgVenteDiffusion></div>'})
export class NgVenteDiffusionComponent {
}

@Directive({selector: '[appNgVenteDiffusion]'})
export class NgVenteDiffusionDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVenteDiffusionCtrl', elementRef, injector);
    }
}
