import {Pipe, PipeTransform} from '@angular/core';
import {CollectionForFilterCallback} from '@shared/collection/collection.interfaces';

@Pipe({name: 'appCollectionForFilter'})
export class AppCollectionForFilterPipe implements PipeTransform {
    transform<T>(items: T[], callback: CollectionForFilterCallback<T>): T[] {
        if (!items || !callback) {
            return items;
        }

        return items.filter(item => callback(item));
    }
}
