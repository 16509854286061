@if (document$ | async; as document) {
  <div class="tw-pb-8">
    <form>
      <editor [(ngModel)]="document.contenu" [init]="editorOptions" [ngModelOptions]="{standalone: true}"
              ngDefaultControl/>
    </form>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
