<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Logement{{ bienLogements.length > 1 ? 's' : '' }}</h4>

  <div class="tw-w-full md:tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-3 md:tw-gap-4 tw-space-y-2 sm:tw-space-y-4 md:tw-space-y-0 tw-bg-gray-100 tw-rounded-xl tw-p-2 sm:tw-p-4">
    @for (bienLogement of bienLogements; track bienLogement) {
      <div class="tw-space-y-1">
        <div>
          <span class="tw-text-sm tw-text-gray-700 tw-font-medium">
            {{ bienLogement.otherLabel || (bienLogement.logement ? bienLogement.logement.label : 'Type de pièce indéterminé') }}
          </span>

          @if (bienLogement.surface > 0) {
            <span>
              de
              <span class="tw-text-gray-700 tw-font-medium">
                {{ bienLogement.surface | textCurrency:'m²' }}
              </span>
            </span>
          }

          @if (bienLogement.etage) {
            <span>au <span class="tw-text-gray-700 tw-font-medium">{{ bienLogement.etage.label }}</span></span>
          }
        </div>

        @if (bienLogement.comments) {
          <div class="tw-pl-2 sm:tw-pl-4">{{ bienLogement.comments }}</div>
        }
      </div>
    }
  </div>
</div>
