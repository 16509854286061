export default `
<label>
  {{$ctrl.title}}

  <a ng-if="!!$ctrl.displayedOptions.helpText && $ctrl.displayedOptions.helpText !== ''"
     uib-popover="{{$ctrl.displayedOptions.helpText}}" popover-placement="top"
     popover-trigger="'outsideClick'" type="button" class="btn margin-left-5 no-padding btn-o btn-null">
    <span class="badge badge-info">?</span>
  </a>
</label>

<div class="radio clip-radio" ng-class="$ctrl.classColor">
  <input ng-disabled="$ctrl.isDisabled === true" type="radio" id="radio-true-{{$ctrl.radioId}}" value="1"
         ng-model="$ctrl.value" ng-change="$ctrl.ngModel = $ctrl.displayedOptions.values.choiceTrue"
         name="{{$ctrl.options.name}}">
  <label for="radio-true-{{$ctrl.radioId}}">{{$ctrl.displayedOptions.labels.choiceTrue}}</label>

  <input ng-disabled="$ctrl.isDisabled === true" type="radio" id="radio-false-{{$ctrl.radioId}}" value="2"
         ng-model="$ctrl.value" ng-change="$ctrl.ngModel = $ctrl.displayedOptions.values.choiceFalse"
         name="{{$ctrl.options.name}}">
  <label for="radio-false-{{$ctrl.radioId}}">{{$ctrl.displayedOptions.labels.choiceFalse}}</label>

  <input ng-disabled="$ctrl.isDisabled === true" type="radio" id="radio-null-{{$ctrl.radioId}}" value="3"
         ng-model="$ctrl.value" ng-change="$ctrl.ngModel = $ctrl.displayedOptions.values.choiceNull"
         name="{{$ctrl.options.name}}">
  <label for="radio-null-{{$ctrl.radioId}}">{{$ctrl.displayedOptions.labels.choiceNull}}</label>
</div>
`;
