import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgClientService} from '@legacy/app/client/client';
import {NgManager} from '@legacy/app/managers/managers';
import {NgMedia} from '@legacy/app/managers/ressources';
import Ng2Media from '@models/medias/media/media.model';

export default function getManagersMedia(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('MediaManager', manager);

        /**
         * Manager media
         *
         * @param BaseManager
         * @param ClientService
         */
        manager.$inject = ['BaseManager', 'ClientService'];
        function manager(baseManager: NgManager, clientService: NgClientService) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('medias.get', {path: '/medias/{id}', method: 'GET'});
            baseManager.addRoute('medias.upload', {path: '/medias/upload', method: 'POST'});
            baseManager.addRoute('medias.edit', {path: '/medias/{id}', method: 'PUT'});
            baseManager.addRoute('medias.remove', {path: '/medias/{id}', method: 'DELETE'});

            /**
             * MediaManager object
             *
             * @constructor
             */
            angular.extend(MediaManager.prototype, baseManager.__proto__);
            MediaManager.prototype.eskManager = {prefixRoute: 'medias', collectionName: 'items'};
            function MediaManager() {
            }

            /**
             * Create a Media object
             *
             * @param data
             * @returns manager.Media object || Array of manager.Media objects
             */
            MediaManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var medias = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        medias.push(new Media(data[i]));
                    }

                    return medias;
                }

                // @ts-ignore
                return new Media(data);
            };

            /**
             * Create a Media object
             *
             * @param ng2Media
             * @returns manager.Media
             */
            MediaManager.prototype.createFromNg2 = function (ng2Media: Ng2Media) {
                if (!ng2Media) {
                    return undefined!;
                }

                return this.create({
                    _links: {download: {href: ng2Media.linkDownload}},
                    comments: ng2Media.comments,
                    createdAt: ng2Media.createdAt,
                    id: ng2Media.id,
                    mediaType: ng2Media.mediaType,
                    title: ng2Media.title,
                    totalBytes: ng2Media.totalBytes,
                });
            };

            /**
             * Media object
             *
             * @param data
             * @constructor
             */
            angular.extend(Media.prototype, classResource.prototype);
            Media.prototype.eskManager = {prefixRoute: 'medias'};
            function Media(this: NgMedia, data: any) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Upload media
             *
             * @returns {Promise}
             */
            Media.prototype.upload = function () {
                const self = this;

                return clientService.uploadFile(self.getPrefixRoute() + '.upload', self.file).then(data => self.extend(data));
            };

            /**
             * Cancel upload
             */
            Media.prototype.cancelUpload = function () {
                if (angular.isObject(this.file._esk.uploadXhr)) this.file._esk.uploadXhr.abort();
            };

            // @ts-ignore
            return new MediaManager();
        }
    })(angularJS);
}
