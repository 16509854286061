import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICContactsApi, ICContactsQueryParameters} from '@models/contacts/collection/contacts.collection.interfaces';
import {IContactApi} from '@models/contacts/contacts.interfaces';
import Contact from '@models/contacts/contact/contact.model';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class ContactsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(uuid: string): Observable<void> {
        return this._modelApiService.delete$('/contacts/' + uuid);
    }

    get$(uuid: string): Observable<IContactApi> {
        return this.getByLink$('/contacts/' + uuid);
    }

    getByLink$(link: string): Observable<IContactApi> {
        return this._modelApiService.get$(link);
    }

    getCollectionByLink$(link: string): Observable<ICContactsApi> {
        return this._modelApiService.getCollection$(link);
    }

    getEtudeCollection$(queryParameters?: ICContactsQueryParameters): Observable<ICContactsApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/contacts', queryParameters));
    }

    save$(uuid: string, contactApi: IContactApi, httpOptions?: HttpOptions): Observable<IContactApi> {
        if (uuid === Contact.statuts.NEW) {
            return this._modelApiService.post$('/user/contacts', contactApi, httpOptions);
        }

        return this._modelApiService.put$('/contacts/' + uuid, contactApi, httpOptions).pipe(switchMap(_ => this.get$(uuid)));
    }
}
