export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
          title="Fermer sans modifier le mot de passe"><fa-icon [icon]="'times'"></fa-icon></button>

  <h3 class="modal-title"><fa-icon [icon]="'lock'"></fa-icon> Modification du mot de passe</h3>
</div>

<form name="$ctrl.editForm" ng-submit="$ctrl.changePassword()" novalidate>
  <div class="modal-body">
    <p>
      Votre mot de passe doit être formé avec au minimum :<br>
      <span class="padding-left-20">- 8 caractères</span><br>
      <span class="padding-left-20">- Un chiffre</span><br>
      <span class="padding-left-20">- Une lettre</span><br>
      <span class="padding-left-20">- Une majuscule</span><br>
      <span class="padding-left-20">- Un caractère spécial (#?!@$%^&*:;.,=_-)</span>
    </p>

    <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.newPassword.$invalid}">
      <label for="newPassword">Nouveau mot de passe</label>

      <input type="password" class="form-control" id="newPassword" name="newPassword" ng-model="$ctrl.newPassword"
             required>

      <div class="error text-small tw-block" ng-messages="$ctrl.editForm.newPassword.$error"
           ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.newPassword.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>

    <div class="form-group"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.newPassword.$valid && $ctrl.editForm.confirmationNewPassword.$invalid}">
      <label for="confirmationNewPassword">Confirmation du nouveau mot de passe</label>

      <input type="password" class="form-control" id="confirmationNewPassword" name="confirmationNewPassword"
             ng-model="$ctrl.confirmationNewPassword" ng-pattern="$ctrl.getRegex($ctrl.newPassword)" required>

      <div class="error text-small tw-block" ng-messages="$ctrl.editForm.confirmationNewPassword.$error"
           ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.confirmationNewPassword.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
        <br>Le mot de passe et la confirmation doivent être identiques.
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div ng-hide="$ctrl.sending">
      <button class="btn btn-primary" type="submit"><fa-icon [icon]="'lock'"></fa-icon> Changer le mot de passe</button>
    </div>

    <p ng-show="$ctrl.sending">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}}
    </p>
  </div>
</form>
`;
