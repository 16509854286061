export default `
<form ng-if="$ctrl.contact" name="$ctrl.editForm" ng-submit="$ctrl.save()" novalidate>
  <!-- Type contact -->
  <div class="row form-group" ng-if="!$ctrl.contact._esk.persist">
    <div class="col-sm-12">
      <div class="radio clip-radio radio-primary radio-inline">
        <input type="radio" id="edit-contact-type-person" name="inline" value="person" checked="checked"
               ng-model="$ctrl.contact.getType" ng-model-options="{getterSetter: true}">

        <label for="edit-contact-type-person">Personne physique</label>
      </div>

      <div class="radio clip-radio radio-primary radio-inline">
        <input type="radio" id="edit-contact-type-company" name="inline" value="entreprise"
               ng-model="$ctrl.contact.getType" ng-model-options="{getterSetter: true}">

        <label for="edit-contact-type-company">Personne morale</label>
      </div>
    </div>
  </div>

  <!-- Contact -->
  <div ng-if="$ctrl.editionPart === 'contact'">
    <!-- Personne physique -->
    <div ng-if="$ctrl.contact.type === 'person'">
      <esk-contact-edit-person contact="$ctrl.contact" edit-form="$ctrl.editForm"
                               edit-representant="$ctrl.editRepresentant"></esk-contact-edit-person>
    </div>

    <!-- Personne morale -->
    <div ng-if="$ctrl.contact.type === 'entreprise'">
      <esk-contact-edit-entreprise contact="$ctrl.contact" edit-form="$ctrl.editForm"></esk-contact-edit-entreprise>
    </div>
  </div>

  <!-- Représentant -->
  <div ng-if="$ctrl.editionPart === 'representant'">
    <!-- Personne physique -->
    <div ng-if="$ctrl.contact.type === 'person'">
      <esk-contact-edit-person contact="$ctrl.contact" edit-form="$ctrl.editForm"></esk-contact-edit-person>
    </div>

    <!-- Personne morale -->
    <div ng-if="$ctrl.contact.type === 'entreprise'">
      <esk-contact-edit-entreprise contact="$ctrl.contact" edit-form="$ctrl.editForm"></esk-contact-edit-entreprise>
    </div>
  </div>

  <hr>

  <div ng-hide="$ctrl.sending" class="text-right">
    <button class="btn btn-default" type="button" ng-click="$ctrl.cancel()">
      <fa-icon [icon]="'times'"></fa-icon> Annuler
    </button>

    <button class="btn btn-primary" type="submit"><fa-icon [icon]="'check'"></fa-icon> Enregistrer</button>
  </div>

  <p ng-show="$ctrl.sending">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
  </p>
</form>
`;
