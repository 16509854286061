import {
    IEtudeDiffusionNetwork, IEtudeDiffusionPasserelle
} from '@models/etudes/etude/diffusions/diffusion/etude-diffusion.interfaces';

export default class EtudeDiffusion {
    private _codeNature!: string;
    private _codeTypeCharges!: string;
    private _doubleMandat!: boolean;
    private _id: number;
    private _network!: IEtudeDiffusionNetwork;
    private _nomProprietairesGroup!: string;
    private _passerelles!: IEtudeDiffusionPasserelle[];
    private _premierePhoto!: string;
    private _reference!: string;
    private _type!: string;

    constructor(id: number) {
        this._id = id;
    }

    get codeNature(): string {
        return this._codeNature;
    }

    set codeNature(value: string) {
        this._codeNature = value;
    }

    get codeTypeCharges(): string {
        return this._codeTypeCharges;
    }

    set codeTypeCharges(value: string) {
        this._codeTypeCharges = value;
    }

    get doubleMandat(): boolean {
        return this._doubleMandat;
    }

    set doubleMandat(value: boolean) {
        this._doubleMandat = value;
    }

    get id(): number {
        return this._id;
    }

    get network(): IEtudeDiffusionNetwork {
        return this._network;
    }

    set network(value: IEtudeDiffusionNetwork) {
        this._network = value;
    }

    get nomProprietairesGroup(): string {
        return this._nomProprietairesGroup;
    }

    set nomProprietairesGroup(value: string) {
        this._nomProprietairesGroup = value;
    }

    get passerelles(): IEtudeDiffusionPasserelle[] {
        return this._passerelles;
    }

    set passerelles(value: IEtudeDiffusionPasserelle[]) {
        this._passerelles = value;
    }

    get premierePhoto(): string {
        return this._premierePhoto;
    }

    set premierePhoto(value: string) {
        this._premierePhoto = value;
    }

    get reference(): string {
        return this._reference;
    }

    set reference(value: string) {
        this._reference = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}
