import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-location-diffusion', template: '<div appNgLocationDiffusion></div>'})
export class NgLocationDiffusionComponent {
}

@Directive({selector: '[appNgLocationDiffusion]'})
export class NgLocationDiffusionDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskLocationDiffusionCtrl', elementRef, injector);
    }
}
