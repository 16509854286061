import {inject, Injectable} from '@angular/core';
import Site from '@models/sites/site/site.model';
import {PhoneFactory} from '@models/phones/phone/phone.factory';
import {ISiteApi} from '@models/sites/site/site.interfaces';
import {IPhoneApi} from '@models/phones/phone/phone.interfaces';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SitesApiService} from '@models/sites/sites.api.service';

@Injectable({providedIn: 'root'})
export class SiteFactory {
    private _phoneFactory = inject(PhoneFactory);
    private _sitesApiService = inject(SitesApiService);

    create(siteApi: ISiteApi): Site {
        const site = new Site(siteApi.uuid, siteApi.id);

        site.adresse = siteApi.adresse;
        site.codePostal = siteApi.codePostal;
        site.emailAddress = siteApi.emailAddress;
        site.etudeId = siteApi.etudeId;
        site.latitude = siteApi.coordonneesGps.latitude;
        site.longitude = siteApi.coordonneesGps.longitude;
        site.siteInternet = siteApi.siteInternet;
        site.sortableRank = siteApi.sortableRank;
        if (siteApi._links) {
            if (siteApi._links.commune) {
                site.linkCommune = siteApi._links.commune.href;
            }

            if (siteApi._links.etude) {
                site.linkEtude = siteApi._links.etude.href;
            }

            if (siteApi._links.users) {
                site.linkUsers = siteApi._links.users.href;
            }
        }

        if (siteApi.telephone) {
            site.telephone = this._phoneFactory.create({numero: siteApi.telephone.e164, type: 'fixe'} as IPhoneApi);
        }

        return site;
    }

    getByLink$(link: string): Observable<Site> {
        return this._sitesApiService.getByLink$(link).pipe(map(siteApi => this.create(siteApi)));
    }
}
