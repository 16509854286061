import {IModule} from 'angular';
import {Injector} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {EmailFactory} from '@models/emails/email/email.factory';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {ErrorHandlerService} from '@core/error/error.handler.service';
import {HttpInterceptor} from '@core/http/http-interceptor.service';
import {PhoneFactory} from '@models/phones/phone/phone.factory';
import {ReportFactory} from '@models/reports/report/report.factory';
import {ToasterService} from '@shared/toaster/toaster.service';
import {UserFactory} from '@models/users/user/user.factory';
import {VenteArchiveInfosFactory} from '@models/ventes/vente/archive-infos/vente-archive-infos.factory';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {VenteCreditRentierFactory} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.factory';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {CProcedureSignatairesService} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.service';
import {ProcedureSignataireFactory} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {CProceduresFactory} from '@models/procedures/collection/procedures.collection.factory';
import {UserSettingsFactory} from '@models/users/user/settings/user-settings.factory';
import {UserService} from '@models/users/user/user.service';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {CDemandeurRechercheResultatsFactory} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.factory';
import {DemandeurRechercheFactory} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.factory';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import {BienFactory} from '@models/bien/bien.factory';
import {BienPerformanceEnergetiqueService} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {DocumentService} from '@models/documents/document/document.service';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {VenteArchiveInfosService} from '@models/ventes/vente/archive-infos/vente-archive-infos.service';
import {EtudePasserelleService} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.service';
import {CVenteMatchingsService} from '@models/ventes/vente/matchings/collection/vente-matching.collection.service';
import {EtudePasserellesService} from '@models/etudes/etude/passerelles/etude-passerelles.service';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {FacebookService} from '@shared/facebook/facebook.service';
import {UserClientMetadataService} from '@models/users/user/client-metadata/user-client-metadata.service';
import {EmailService} from '@models/emails/email/email.service';
import {UserPhotoFactory} from '@models/users/user/photo/user-photo.factory';
import {DossierFileService} from '@models/dossiers/dossier/files/file/dossier-file.service';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {DocumentsService} from '@models/documents/documents.service';
import {MediaFactory} from '@models/medias/media/media.factory';
import {ContactsGroupMemberFactory} from '@models/contacts-group/members/member/contacts-group-member.factory';
import {ModalService} from '@shared/modal/modal.service';
import {ContactsGroupMemberService} from '@models/contacts-group/members/member/contacts-group-member.service';
import {CReferencesFactory} from '@models/references/collection/references.collection.factory';
import {ReferenceFactory} from '@models/references/reference/reference.factory';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {PersonFactory} from '@models/contacts/person/person.factory';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LocalStorageService} from '@core/storage/local-storage.service';
import {SessionStorageService} from '@core/storage/session-storage.service';
import {CEtudesService} from '@core/models/etudes/collection/etudes.collection.service';
import {ModelApiService} from '@models/model.api.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {DictionariesService} from '@models/dictionaries/dictionaries.service';
import {
    EstimationReferenceFactory
} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import {EmailRecipientService} from '@models/emails/email/recipients/recipient/email-recipient.service';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {TextsService} from '@shared/texts/texts.service';
import {SearchCriteriaService} from '@models/search/criteria/search-criteria.service';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {CBienPhotosFactory} from '@models/bien/photos/collection/bien-photos.collection.factory';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {
    CEstimationOnsaleReferencesFactory
} from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.factory';
import {
    EstimationOnsaleReferenceFactory
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.factory';
import ParserService from '@shared/parser/parser.service';
import {
    CEtudePasserellesFactory
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.factory';
import {VentePasserellesService} from '@models/ventes/vente/passerelles/vente-passerelles.service';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import {LocationPasserelleFactory} from '@models/locations/location/passerelles/passerelle/location-passerelle.factory';
import {LocationPasserellesService} from '@models/locations/location/passerelles/location-passerelles.service';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import {SearchService} from '@models/search/search.service';
import {VenteService} from '@models/ventes/vente/vente.service';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';
import {CTemplatesFactory} from '@models/templates/collection/templates.collection.factory';
import {ReportsService} from '@models/reports/reports.service';
import {LocationMandatsService} from '@models/locations/location/mandats/location-mandats.service';
import {LocationFactory} from '@models/locations/location/location.factory';
import {LocationMandatFactory} from '@models/locations/location/mandats/mandat/location-mandat.factory';
import {LocationService} from '@models/locations/location/location.service';
import {EtudeDiffusionsService} from '@models/etudes/etude/diffusions/etude-diffusions.service';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {
    CEstimationReferencesFactory
} from '@models/estimations/estimation/references/collection/estimation-references.collection.factory';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import {MeteoService} from '@core/meteo/meteo.service';
import {BienPhotoFactory} from '@models/bien/photos/photo/bien-photo.factory';
import {FilterFactory} from '@models/filters/filter/filter.factory';
import {LocationLocataireFactory} from '@models/locations/location/locataires/locataire/location-locataire.factory';
import {
    LocationLocataireConstatFactory
} from '@models/locations/location/locataires/locataire/constats/constat/location-locataire-constat.factory';
import {VenteContratFactory} from '@models/ventes/vente/contrats/contrat/vente-contrat.factory';

export default (eskimmo: IModule, injector: Injector): void => {
    // Core
    eskimmo
        .factory('Ng2ErrorConfigurationService', () => injector.get(ErrorConfigurationService))
        .factory('Ng2ErrorHandlerService', () => injector.get(ErrorHandlerService))
        .factory('Ng2HttpInterceptor', () => injector.get(HttpInterceptor))
        .factory('Ng2LocalStorageService', () => injector.get(LocalStorageService))
        .factory('Ng2ModelApiService', () => injector.get(ModelApiService))
        .factory('Ng2SessionStorageService', () => injector.get(SessionStorageService));

    // Models
    eskimmo
        .factory('Ng2BienFactory', () => injector.get(BienFactory))
        .factory('Ng2BienPerformanceEnergetiqueService', () => injector.get(BienPerformanceEnergetiqueService))
        .factory('Ng2BienPhotoFactory', () => injector.get(BienPhotoFactory))
        .factory('Ng2CBienPhotosFactory', () => injector.get(CBienPhotosFactory))
        .factory('Ng2CDemandeurRechercheResultatsFactory', () => injector.get(CDemandeurRechercheResultatsFactory))
        .factory('Ng2CDossierFilesService', () => injector.get(CDossierFilesService))
        .factory('Ng2CEstimationOnsaleReferencesFactory', () => injector.get(CEstimationOnsaleReferencesFactory))
        .factory('Ng2CEstimationReferencesFactory', () => injector.get(CEstimationReferencesFactory))
        .factory('Ng2CEtudesService', () => injector.get(CEtudesService))
        .factory('Ng2CEtudePasserellesFactory', () => injector.get(CEtudePasserellesFactory))
        .factory('Ng2CommuneFactory', () => injector.get(CommuneFactory))
        .factory('Ng2ContactFactory', () => injector.get(ContactFactory))
        .factory('Ng2ContactsGroupMemberFactory', () => injector.get(ContactsGroupMemberFactory))
        .factory('Ng2ContactsGroupMemberService', () => injector.get(ContactsGroupMemberService))
        .factory('Ng2ContactsGroupFactory', () => injector.get(ContactsGroupFactory))
        .factory('Ng2ContactsGroupService', () => injector.get(ContactsGroupService))
        .factory('Ng2CProceduresFactory', () => injector.get(CProceduresFactory))
        .factory('Ng2CProcedureSignatairesService', () => injector.get(CProcedureSignatairesService))
        .factory('Ng2CReferencesFactory', () => injector.get(CReferencesFactory))
        .factory('Ng2CSitesService', () => injector.get(CSitesService))
        .factory('Ng2CTemplatesFactory', () => injector.get(CTemplatesFactory))
        .factory('Ng2CTemplatesService', () => injector.get(CTemplatesService))
        .factory('Ng2CVenteMatchingsService', () => injector.get(CVenteMatchingsService))
        .factory('Ng2DemandeurFactory', () => injector.get(DemandeurFactory))
        .factory('Ng2DemandeurRechercheFactory', () => injector.get(DemandeurRechercheFactory))
        .factory('Ng2DeviceDetectorService', () => injector.get(DeviceDetectorService))
        .factory('Ng2DictionariesService', () => injector.get(DictionariesService))
        .factory('Ng2DictionaryFactory', () => injector.get(DictionaryFactory))
        .factory('Ng2DictionaryItemFactory', () => injector.get(DictionaryItemFactory))
        .factory('Ng2DictionaryItemService', () => injector.get(DictionaryItemService))
        .factory('Ng2DocumentFactory', () => injector.get(DocumentFactory))
        .factory('Ng2DocumentService', () => injector.get(DocumentService))
        .factory('Ng2DocumentsService', () => injector.get(DocumentsService))
        .factory('Ng2DossierBienFactory', () => injector.get(DossierBienFactory))
        .factory('Ng2DossierBienService', () => injector.get(DossierBienService))
        .factory('Ng2DossierFileService', () => injector.get(DossierFileService))
        .factory('Ng2EmailFactory', () => injector.get(EmailFactory))
        .factory('Ng2EmailRecipientService', () => injector.get(EmailRecipientService))
        .factory('Ng2EmailService', () => injector.get(EmailService))
        .factory('Ng2EstimationFactory', () => injector.get(EstimationFactory))
        .factory('Ng2EstimationOnsaleReferenceFactory', () => injector.get(EstimationOnsaleReferenceFactory))
        .factory('Ng2EstimationReferenceFactory', () => injector.get(EstimationReferenceFactory))
        .factory('Ng2EstimationService', () => injector.get(EstimationService))
        .factory('Ng2EtudeDiffusionsService', () => injector.get(EtudeDiffusionsService))
        .factory('Ng2EtudePasserelleService', () => injector.get(EtudePasserelleService))
        .factory('Ng2EtudePasserellesService', () => injector.get(EtudePasserellesService))
        .factory('Ng2EtudeService', () => injector.get(EtudeService))
        .factory('Ng2FacebookService', () => injector.get(FacebookService))
        .factory('Ng2FilterFactory', () => injector.get(FilterFactory))
        .factory('Ng2LocationFactory', () => injector.get(LocationFactory))
        .factory('Ng2LocationLocataireFactory', () => injector.get(LocationLocataireFactory))
        .factory('Ng2LocationLocataireConstatFactory', () => injector.get(LocationLocataireConstatFactory))
        .factory('Ng2LocationMandatFactory', () => injector.get(LocationMandatFactory))
        .factory('Ng2LocationMandatsService', () => injector.get(LocationMandatsService))
        .factory('Ng2LocationPasserelleFactory', () => injector.get(LocationPasserelleFactory))
        .factory('Ng2LocationPasserellesService', () => injector.get(LocationPasserellesService))
        .factory('Ng2LocationService', () => injector.get(LocationService))
        .factory('Ng2MediaFactory', () => injector.get(MediaFactory))
        .factory('Ng2MeteoService', () => injector.get(MeteoService))
        .factory('Ng2PersonFactory', () => injector.get(PersonFactory))
        .factory('Ng2PhoneFactory', () => injector.get(PhoneFactory))
        .factory('Ng2PhoneNumberService', () => injector.get(PhoneNumberService))
        .factory('Ng2ProcedureService', () => injector.get(ProcedureService))
        .factory('Ng2ProcedureSignataireFactory', () => injector.get(ProcedureSignataireFactory))
        .factory('Ng2QuartierFactory', () => injector.get(QuartierFactory))
        .factory('Ng2ReferenceFactory', () => injector.get(ReferenceFactory))
        .factory('Ng2ReportFactory', () => injector.get(ReportFactory))
        .factory('Ng2ReportsService', () => injector.get(ReportsService))
        .factory('Ng2SearchService', () => injector.get(SearchService))
        .factory('Ng2SearchCriteriaService', () => injector.get(SearchCriteriaService))
        .factory('Ng2TemplateFactory', () => injector.get(TemplateFactory))
        .factory('Ng2UserClientMetadataService', () => injector.get(UserClientMetadataService))
        .factory('Ng2UserFactory', () => injector.get(UserFactory))
        .factory('Ng2UserPhotoFactory', () => injector.get(UserPhotoFactory))
        .factory('Ng2UserService', () => injector.get(UserService))
        .factory('Ng2UserSettingsFactory', () => injector.get(UserSettingsFactory))
        .factory('Ng2VenteArchiveInfosFactory', () => injector.get(VenteArchiveInfosFactory))
        .factory('Ng2VenteArchiveInfosService', () => injector.get(VenteArchiveInfosService))
        .factory('Ng2VenteCompromisFactory', () => injector.get(VenteCompromisFactory))
        .factory('Ng2VenteContratFactory', () => injector.get(VenteContratFactory))
        .factory('Ng2VenteCreditRentierFactory', () => injector.get(VenteCreditRentierFactory))
        .factory('Ng2VenteFactory', () => injector.get(VenteFactory))
        .factory('Ng2VentePasserelleFactory', () => injector.get(VentePasserelleFactory))
        .factory('Ng2VentePasserellesService', () => injector.get(VentePasserellesService))
        .factory('Ng2VentePriceFactory', () => injector.get(VentePriceFactory))
        .factory('Ng2VenteService', () => injector.get(VenteService));

    // Shared
    eskimmo
        .factory('Ng2DropdownService', () => injector.get(DropdownService))
        .factory('Ng2ParserService', () => injector.get(ParserService))
        .factory('Ng2ModalService', () => injector.get(ModalService))
        .factory('Ng2RouterStateForNgService', () => injector.get(RouterStateForNgService))
        .factory('Ng2TextsService', () => injector.get(TextsService))
        .factory('Ng2TinymceService', () => injector.get(TinymceService))
        .factory('Ng2ToasterService', () => injector.get(ToasterService))
        .factory('Ng2SlideOverService', () => injector.get(SlideOverService));
};
