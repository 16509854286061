export default `
<div class="upload-files">
  <fieldset ng-if="$ctrl.tasks.length > 0" class="row files-infos-process">
    <legend>{{'files.process.TITLE' | translate:'{type: "' + $ctrl.typeFile + '", nbFiles:' + $ctrl.taskErrors.length + '}'}}</legend>

    <div ng-repeat="task in $ctrl.tasks | limitTo:5" class="file-process"
         ng-class="['evaluation_estimation_map', 'photo_profil'].includes($ctrl.infoClientFilter.type) ? 'col-sm-12' : 'col-sm-2'">
      {{task.infoClient.media.file.name}}

      <div class="row">
        <div ng-switch="task.infoClient.media.file._esk.status" class="col-sm-10">
          <div ng-switch-when="WAITING" class="file-process-status">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> En attente...
          </div>

          <div ng-switch-when="IN_PROGRESS"
               ng-show="0 <= task.infoClient.media.file._esk.progress || task.infoClient.media.file._esk.progress <= 100"
               class="file-process-status">
            <uib-progressbar class="progress-striped" value="task.infoClient.media.file._esk.progress">
              {{task.infoClient.media.file._esk.progress}} %
            </uib-progressbar>
          </div>

          <div ng-switch-when="UPLOADED" class="file-process-status">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}}
          </div>
        </div>

        <div class="col-sm-2">
          <button type="button" class="btn btn-default btn-o" ng-click="$ctrl.removeTask(task)"
                  title="Annuler le téléchargement"><fa-icon [icon]="'times'"></fa-icon></button>
        </div>
      </div>
    </div>

    <div ng-if="$ctrl.tasks.length > 5" class="col-sm-2 file-process-more">
      + {{'files.process.waiting.MESSAGE' | translate:'{type: "' + $ctrl.typeFile + '", nbFiles: ' + ($ctrl.tasks.length - 5) + '}'}}
    </div>
  </fieldset>

  <div ng-if="$ctrl.taskErrors.length > 0" class="alert alert-danger">
    <fa-icon [icon]="'exclamation-triangle'"></fa-icon> <span class="text-bold">Erreur !</span>

    {{'files.process.error.MESSAGE' | translate:'{type: "' + $ctrl.typeFile + '", nbFiles:' + $ctrl.taskErrors.length + '}'}}

    <a uib-popover-template="'src/app/legacy/templates/eskimmo/components/upload-files-info/errors.html'"
       popover-trigger="'outsideClick'" popover-placement="bottom" class="margin-left-10">
      <span class="badge badge-info">?</span>
    </a>

    <button type="button" class="btn btn-default btn-o pull-right" ng-click="$ctrl.removeTasksErrors()"
            title="Supprimer les erreurs de téléchargement"><fa-icon [icon]="'times'"></fa-icon></button>
  </div>
</div>
`;
