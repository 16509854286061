import {inject, Injectable} from '@angular/core';
import Location from '@models/locations/location/location.model';
import {combineLatest, Observable, of} from 'rxjs';
import {LocationFactory} from '@models/locations/location/location.factory';

@Injectable({providedIn: 'root'})
export class LocationsService {
    private _locationFactory = inject(LocationFactory);

    getByLinks$(links: string[] = []): Observable<Location[]> {
        return links.length > 0 ? combineLatest(links.map(link => this._locationFactory.getByLink$(link))) : of([] as Location[]);
    }
}
