import {inject, Injectable} from '@angular/core';
import {IBienCadastreApi} from '@models/bien/cadastres/cadastre/bien-cadastre.interfaces';
import BienCadastre from '@models/bien/cadastres/cadastre/bien-cadastre.model';
import {NgBienCadastre} from '@legacy/app/managers/ressources';
import {BienCadastresApiService} from '@models/bien/cadastres/bien-cadastres.api.service';
import {Observable} from 'rxjs';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BienCadastreFactory {
    private _bienCadastresApiService = inject(BienCadastresApiService);

    create(bienCadastreApi: IBienCadastreApi): BienCadastre {
        const bienCadastre = new BienCadastre(bienCadastreApi.id?.toString(), bienCadastreApi.id);

        bienCadastre.lot = bienCadastreApi.lot;
        bienCadastre.parcelle = bienCadastreApi.parcelle;
        bienCadastre.prefixe = bienCadastreApi.prefixe;
        bienCadastre.section = bienCadastreApi.section;
        bienCadastre.surface = bienCadastreApi.surface;
        if (bienCadastreApi._embedded?.commune) {
            bienCadastre.communeId = bienCadastreApi._embedded.commune.id;
        }

        return bienCadastre;
    }

    forApi(bienCadastre: BienCadastre): IBienCadastreApi {
        const bienCadastreApi = {
            communeId: bienCadastre.communeId,
            parcelle: bienCadastre.parcelle,
            prefixe: bienCadastre.prefixe,
            section: bienCadastre.section,
            surface: bienCadastre.surface,
        } as IBienCadastreApi;

        if (bienCadastre.lot) {
            bienCadastreApi.lot = bienCadastre.lot;
        }

        return bienCadastreApi;
    }

    get$(dossierBien: ADossierBien, bienCadastreId: number): Observable<BienCadastre> {
        if (!dossierBien.id) {
            throw new Error('DossierBien id is required');
        }

        if (!bienCadastreId) {
            throw new Error('BienCadastre id is required');
        }

        return this._bienCadastresApiService.get$(dossierBien.id.toString(), bienCadastreId.toString()).pipe(map(bienCadastreApi => this.create(bienCadastreApi)));
    }

    save$(dossierBien: ADossierBien, bienCadastre: BienCadastre): Observable<BienCadastre> {
        return this._bienCadastresApiService.save$(dossierBien.id.toString(), bienCadastre.id?.toString() ?? BienCadastre.STATUT_NEW, this.forApi(bienCadastre))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }

    ngCreate(ngBienCadastre: NgBienCadastre): BienCadastre {
        const bienCadastre = new BienCadastre(ngBienCadastre.id?.toString(), ngBienCadastre.id);

        bienCadastre.communeId = ngBienCadastre.commune?.id;
        bienCadastre.lot = ngBienCadastre.lot;
        bienCadastre.parcelle = ngBienCadastre.parcelle;
        bienCadastre.prefixe = ngBienCadastre.prefixe;
        bienCadastre.section = ngBienCadastre.section;
        bienCadastre.surface = ngBienCadastre.surface;

        return bienCadastre;
    }
}
