import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';
import {combineLatest, from} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {NgTemplateManager} from '@legacy/app/managers/managers';

export default function getEskButtonPrint(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-button-print></esk-button-print>
         * @param options : {dropDirection{string}, loading(boolean)}
         */
        module.component('eskButtonPrint', {
            bindings: {categoriesCode: '=', print: '=', options: '=?', advancedPrint: '=?'},
            templateUrl: 'src/app/legacy/templates/eskimmo/components/button/print.html',
            controller: Controller
        });

        /**
         * Controller for eskPrintButton
         *
         * @param Ng2CTemplatesService
         * @param TemplateManager
         */
        Controller.$inject = ['Ng2CTemplatesService', 'TemplateManager'];
        function Controller(this: any, cTemplatesService: CTemplatesService, templateManager: NgTemplateManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) {
                    $ctrl.options = {};
                }

                if (!['dropup', 'dropdown'].includes($ctrl.options.dropDirection)) {
                    $ctrl.options.dropDirection = 'dropdown';
                }

                $ctrl.options.loading = true;
                cTemplatesService.getWithDefaultFirst$($ctrl.categoriesCode).pipe(
                    switchMap(templatesCollection => combineLatest(templatesCollection.results.map(template => from(templateManager.createFromNg2(template))))),
                    take(1),
                ).subscribe({
                    next: ngTemplates => $ctrl.listeTemplates = ngTemplates,
                    complete: () => $ctrl.options.loading = false,
                });
            }
        }
    })(angularJS);
}
