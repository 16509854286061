import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';

export default function getBienHeader(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-header></esk-bien-header>
         */
        module.component('eskBienHeader', {
            bindings: {dossier: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/header.html'
        });

        /**
         * Controller for display localisation
         *
         * @param Ng2RouterStateForNgService
         * @param Ng2CDossierFilesService
         * @constructor
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'Ng2CDossierFilesService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2CDossierFilesService: CDossierFilesService) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();

            $ctrl.$onInit = $onInit;
            $ctrl.isActivedRoute = isActivedRoute;
            $ctrl.onClick = onClick;
            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.STATUT_ARCHIVE = ADossierBien.abstractBienStatuts.ARCHIVE;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
                ng2CDossierFilesService.getCurrentTotal$().pipe(takeUntil(_onDestroy$))
                    .subscribe(nbDossierFiles => $ctrl.nbDossierFiles = nbDossierFiles);
                $ctrl.navItems = [];
                if (!$ctrl.dossier.id) {
                    return;
                }

                $ctrl.navItems.push({
                    code: 'DASHBOARD',
                    route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/lifetime',
                    label: 'bien.header.DASHBOARD',
                    activatingRoutes: [
                        '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/lifetime',
                        '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/correspondants',
                    ],
                });
                $ctrl.navItems.push({
                    code: 'FICHE',
                    route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/edit/general',
                    label: 'bien.header.FICHE',
                    activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/edit'],
                });
                if ([DossierBienTypesConst.VENTE, DossierBienTypesConst.LOCATION].includes($ctrl.dossier._esk.typeDossier)) {
                    $ctrl.navItems.push({
                        code: 'MANDATS',
                        route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/mandats',
                        label: 'bien.header.MANDATS',
                        activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/mandats'],
                    });
                }
                if ($ctrl.dossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
                    $ctrl.navItems.push({
                        code: 'GESTION',
                        route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/gestion',
                        label: 'bien.header.GESTION',
                        activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/gestion'],
                    });
                }
                if ($ctrl.dossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
                    $ctrl.navItems.push({
                        code: 'EVALUATION',
                        route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/evaluation',
                        label: 'bien.header.EVALUATION',
                        activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/evaluation'],
                    });
                    $ctrl.navItems.push({
                        code: 'RAPPORTS',
                        route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/rapport',
                        label: 'bien.header.RAPPORTS',
                        activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/rapport'],
                    });
                }
                if ([DossierBienTypesConst.VENTE, DossierBienTypesConst.LOCATION].includes($ctrl.dossier._esk.typeDossier)) {
                    $ctrl.navItems.push({
                        code: 'DIFFUSION',
                        route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/diffusion',
                        label: 'bien.header.DIFFUSION',
                        activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/diffusion'],
                    });
                }
                $ctrl.navItems.push({
                    code: 'DOCUMENTS',
                    route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/documents',
                    label: 'bien.header.DOCUMENTS',
                    activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/documents'],
                });
                $ctrl.navItems.push({
                    code: 'SUIVI',
                    route: '/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/suivi',
                    label: 'bien.header.SUIVI',
                    activatingRoutes: ['/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/suivi'],
                });
            }

            /**
             * Is route activate
             *
             * @param navItem
             * @return Boolean
             */
            function isActivedRoute(navItem: { activatingRoutes: string[] }) {
                let isActive = false;

                angular.forEach(navItem.activatingRoutes, function (activatingRoute) {
                    if (ng2RouterStateForNgService.current.url.startsWith(activatingRoute)) {
                        isActive = true;
                    }
                });

                return isActive;
            }

            /**
             * Go to route section
             *
             * @param navItem
             */
            function onClick(navItem: { route: string }) {
                $ctrl.toggleMore = false;
                ng2RouterStateForNgService.navigateByUrl(navItem.route);
            }
        }
    })(angularJS);
}
