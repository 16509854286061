export default `
<div class="list-demandeurs">
  <div
    ng-if="$ctrl.demandeurs.totalItems > 0 && (!!$ctrl.options.visibleFields.select || !!$ctrl.options.nameDossiers)"
    class="padding-bottom-10 list-infos hidden-print">
    <span ng-if="$ctrl.options.visibleFields.select && $ctrl.options.displayMode!='table'"
          ng-include="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all"></span>

    <span ng-if="!!$ctrl.options.nameDossiers" class="text-justify">
      {{$ctrl.options.nameDossiers | translate:'{NB: ' + $ctrl.demandeurs.totalItems + '}'}}
    </span>

    <span ng-if="$ctrl.options.nameDossiers === 'DEMANDEURS_LIST_CORRESPONDANTS_POTENTIELS'"
          class="text-emphasis text-justify">
      Votre bien en vente n'a pas de date de mandat et n'est pas visible dans les résultats de recherche de vos
      demandeurs.
    </span>

    <span ng-if="!!$ctrl.filter.nom">
      {{"dans" | translate}}

      <span class="text-emphasis text-bold">{{$ctrl.filter.nom | lowercase}}</span>
    </span>
  </div>

  <ng-switch on="$ctrl.options.loader.updating">
    <p class="tw-text-center text-extra-large" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos demandeurs
    </p>

    <ng-switch ng-switch-default on="$ctrl.options.displayMode" ng-if="$ctrl.demandeurs.totalItems > 0">
      <!-- Tableau -->
      <div ng-switch-default class="table-responsive">
        <table class="table table-hover table-demandeurs">
          <thead>
          <tr>
            <th ng-if="$ctrl.options.visibleFields.select">
              <ng-include src="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all">
              </ng-include>
            </th>

            <th class="tw-text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="reference"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th class="tw-text-center">{{'Responsable dossier' | translate}}</th>

            <th class="tw-text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nomDemandeur"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th class="tw-text-center">{{'Date de création' | translate}}</th>

            <th class="tw-text-center">{{'Téléphone référent' | translate}}</th>

            <th class="tw-text-center">{{'Nature' | translate}}</th>

            <th class="tw-text-center">{{'Localisation' | translate}}</th>

            <th ng-if="$ctrl.options.visibleFields.results" class="tw-text-center">
              {{'Résultats' | translate}}

              <a uib-popover-template="'src/app/legacy/templates/demandeurs/components/list/results-color-bullet.html'"
                 popover-trigger="'outsideClick'" popover-placement="left-top" class="btn btn-o btn-null">
                <span class="badge badge-info">?</span>
              </a>
            </th>

            <th class="hidden-print"></th>
          </tr>
          </thead>

          <tr class="line" ng-repeat="demandeur in $ctrl.demandeurs.collection">
            <td ng-if="$ctrl.options.visibleFields.select" class="select hidden-print">
              <div class="checkbox clip-check check-purple">
                <input type="checkbox" id="table-demandeurs-select-{{demandeur.id}}"
                       ng-model="demandeur._esk.select" ng-change="$ctrl.onSelect(demandeur)">

                <label for="table-demandeurs-select-{{demandeur.id}}"></label>
              </div>
            </td>

            <td class="col-reference tw-text-center">
              <span class="cursor-pointer" popover-placement="right" popover-trigger="'mouseenter'"
                    uib-popover-template="'src/app/legacy/templates/demandeurs/components/list/details.html'"
                    popover-class="dossier-popover">
                {{demandeur.reference}}
              </span>
            </td>

            <td class="col-initiales tw-text-center">
              <app-user-cartouche [link]="demandeur._links.responsableDossier.href"
                                  [options]="{showConvivialName: false, showInitiales: true}"
                                  class="tw-block tw-h-8 tw-max-w-full tw-w-fit tw-mx-auto"></app-user-cartouche>
            </td>

            <td ng-switch="!!demandeur._esk.routing" class="col-nom">
              <a ng-switch-when="true" class="ellipsis" href="{{demandeur._esk.routing}}"
                 ng-click="$ctrl.onClick(demandeur)">
                {{demandeur.contactsGroup.nom}}
              </a>

              <div ng-switch-default class="ellipsis" ng-click="$ctrl.onClick(demandeur)">
                {{demandeur.contactsGroup.nom}}
              </div>
            </td>

            <td class="col-creation-date text-capitalize tw-text-center">
              <app-ng2-date [date]="demandeur.dateCreation" format="shortDate"></app-ng2-date>
            </td>

            <td class="col-phone tw-text-center">
              <div
                ng-switch="!!demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].mobile">
                <div ng-switch-when="true">
                  <a ng-if="demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].mobile"
                     ng-href="{{demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].getRfc3966(demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].mobile)}}">
                    {{demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].mobile}}
                  </a>
                </div>

                <div ng-switch-default>
                  <div
                    ng-switch="!!demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].telephone">
                    <div ng-switch-when="true">
                      <a ng-if="demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].telephone"
                         ng-href="{{demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].getRfc3966(demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].telephone)}}">
                        {{demandeur.contactsGroup.members[0].contact[demandeur.contactsGroup.members[0].contact.type].telephone}}
                      </a>
                    </div>

                    <div ng-switch-default>
                      <span class="text-muted">{{"Aucun numéro renseigné"| translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>

            <td class="col-nature">
              <div class="ellipsis">
                <span ng-repeat="nature in demandeur.recherches[0].natures">
                  {{nature.label}} <span ng-if="!$last">/</span>
                </span>
              </div>
            </td>

            <td class="col-localisation">
              <div class="ellipsis">{{demandeur.recherches[0].localizationSummary}}</div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.results" ng-switch="!!demandeur._esk.routing"
                class="col-results tw-text-center">
              <a ng-switch-when="true" href="{{demandeur._esk.routing}}" ng-click="$ctrl.onClick(demandeur)">
                <span
                  ng-if="demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value] > 0"
                  class="label label-results" ng-class="demandeur.recherches[0]._esk.status.main.class">
                  {{demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value]}}
                </span>
              </a>

              <div ng-switch-default ng-click="$ctrl.onClick(demandeur)">
                <span class="label label-results" ng-class="demandeur.recherches[0]._esk.status.main.class"
                  ng-if="demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value] > 0">
                  {{demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value]}}
                </span>
              </div>
            </td>

            <td class="tw-text-center hidden-print angle-right" ng-click="$ctrl.onClick(demandeur)">
              <a href="{{demandeur._esk.routing}}" ng-click="$ctrl.onClick(demandeur)">
                <fa-icon [icon]="'angle-right'"></fa-icon>
              </a>
            </td>
          </tr>
        </table>
      </div>

      <!-- Vignettes -->
      <div ng-switch-when="collection" class="collection-demandeurs clearfix">
        <div class="content" ng-repeat="demandeur in $ctrl.demandeurs.collection">
          <div class="thumbnail">
            <div ng-switch="!!demandeur._esk.routing" class="title-dossier text text-large text-bold">
              <a ng-switch-when="true" class="ellipsis" href="{{demandeur._esk.routing}}"
                 ng-click="$ctrl.onClick(demandeur)">
                {{demandeur.contactsGroup.nom}}
              </a>

              <div ng-switch-default class="ellipsis" ng-click="$ctrl.onClick(demandeur)">
                {{demandeur.contactsGroup.nom}}
              </div>
            </div>

            <div class="descriptif-dossier">
              <div class="transaction"><div class="tw-text-center text-bold">{{demandeur.recherches[0].type}}</div></div>

              <div class="nature">
                <fa-icon [icon]="'home'"></fa-icon>

                <span ng-repeat="nature in demandeur.recherches[0].natures| limitTo: 4">
                  {{nature.label}}
                  <span
                    ng-if="demandeur.recherches[0].natures.length > 1 && demandeur.recherches[0].natures.length - 1 > $index">/</span>
                </span>

                <span ng-if="demandeur.recherches[0].natures.length > 4">
                  + {{demandeur.recherches[0].natures.length - 4}} autres natures
                </span>
              </div>

              <div class="localisation">
                <fa-icon [icon]="'map-marker-alt'"></fa-icon> {{demandeur.recherches[0].localizationSummary}}
              </div>
            </div>

            <div class="footer-dossier">
              <span ng-if="$ctrl.options.visibleFields.select" class="select">
                <div class="checkbox clip-check check-purple">
                  <input type="checkbox" id="collection-demandeurs-select-{{demandeur.id}}"
                         ng-model="demandeur._esk.select" ng-change="$ctrl.onSelect(demandeur)">

                  <label for="collection-demandeurs-select-{{demandeur.id}}"></label>
                </div>
              </span>

              <span ng-if="$ctrl.options.visibleFields.results" ng-switch="!!demandeur._esk.routing">
                <a ng-switch-when="true" href="{{demandeur._esk.routing}}" ng-click="$ctrl.onClick(demandeur)">
                  <span class="label label-results" ng-class="demandeur.recherches[0]._esk.status.main.class"
                        ng-if="demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value] > 0">
                    {{demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value]}}
                  </span>
                </a>

                <div ng-switch-default ng-click="$ctrl.onClick(demandeur)">
                  <span class="label label-results" ng-class="demandeur.recherches[0]._esk.status.main.class"
                        ng-if="demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value] > 0">
                    {{demandeur.recherches[0]._esk.status[demandeur.recherches[0]._esk.status.main.value]}}
                  </span>
                </div>
              </span>

              <span class="pull-right text-bold">{{demandeur.reference}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-switch>
  </ng-switch>
</div>
`;
