export default `
<div class="text-justify">
  <p class="text-bold">
    Addition des champs suivants à la charge du locataire :
  </p>

  <p>
    - Honoraires de rédaction
    <br>
    - États des lieux
    <br>
    - Dépôt de garantie
  </p>
</div>
`;
