import {inject, Injectable} from '@angular/core';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {combineLatest, Observable, of} from 'rxjs';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import Vente from '@models/ventes/vente/vente.model';
import {VentePasserelleService} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.service';

@Injectable({providedIn: 'root'})
export class VentePasserellesService {
    private _ventePasserelleService = inject(VentePasserelleService);

    getFromEtudePasserelles$(vente: Vente, etudePasserelles: EtudePasserelle[] = []): Observable<VentePasserelle[]> {
        if (etudePasserelles.length <= 0) {
            return of([]);
        }

        return combineLatest(etudePasserelles.map(etudePasserelle => this._ventePasserelleService.getOneSaved$(vente, etudePasserelle)));
    }
}
