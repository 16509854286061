@if (etude$ | async; as etude) {
  <div class="tw-relative tw-h-full tw-flex tw-flex-col tw-overflow-hidden tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-divide-y tw-divide-gray-200">
    <a [routerLink]="'/app/annuaires/etudes/' + etude.id.toString()">
      @if (etude.linkBanner) {
        <app-image-preview [link]="etude.linkBanner"/>
      } @else {
        <div class="tw-relative tw-w-full tw-aspect-cinema">
          <img [ngSrc]="etude.linkLogo" class="tw-h-full tw-w-full tw-object-contain tw-object-center" fill>
        </div>
      }
    </a>

    <div class="tw-flex tw-flex-1 tw-flex-col tw-space-y-3 tw-p-4">
      <!-- Supprimer la marge à la suppression de bootstrap-->
      <h3 class="tw-m-0">
        <a [routerLink]="'/app/annuaires/etudes/' + etude.id.toString()"
           class="tw-text-sm tw-font-medium tw-text-gray-900">{{ etude.raisonSociale }}</a>
      </h3>

      <p class="tw-text-sm tw-text-gray-500"><app-commune-convivial-name [linkCommune]="site.linkCommune"/></p>

      <app-users-stacked-images [linkUsers]="site.linkUsers" class="tw-h-8 tw-block"/>
    </div>

    <div class="tw-flex tw-justify-end tw-divide-x tw-divide-gray-200">
      @if (site.emailAddress) {
        <div class="tw-flex-1 tw-flex tw-justify-center tw-items-center">
          @if (site.emailAddress) {
            <button (click)="sendMail()" type="button"
                    class="tw-btn-tertiary-default tw-w-full tw-gap-x-3 tw-py-4 hover:tw-ring-0 tw-rounded-none">
              <fa-icon [icon]="'envelope'" class="tw-h-5 tw-w-5 tw-text-gray-400"/>
              <span class="tw-hidden sm:tw-inline-block tw-text-sm">Écrire</span>
            </button>
          }
        </div>
      }

      @if (site.getPhoneNumber()) {
        <div class="tw-flex-1 tw-text-center">
          <app-text-phone [options]="{icon: 'phone', iconAddedClass: 'tw-h-5 tw-w-5 tw-text-gray-400', label: 'Appeler', linkAddedClass: 'tw-btn-tertiary-default tw-w-full tw-gap-x-3 tw-py-4 tw-text-sm hover:tw-ring-0 tw-rounded-none'}"
                          [phone]="site.getPhoneNumber()" class="tw-block tw-w-full"/>
        </div>
      }
    </div>
  </div>
} @else {
  <app-loader class="tw-block tw-w-full" [classHeight]="'tw-h-80'"/>
}
