export default `
<div>
  <div>
    <span ng-if="passerelle._esk.statut === 'diffused'">
      Diffusé depuis le <app-ng2-date [date]="passerelle.dateDiffusion" format="shortDate"></app-ng2-date>
    </span>

    <span ng-if="passerelle._esk.statut === 'suspended'">
      Suspendu depuis le <app-ng2-date [date]="passerelle.dateRetrait" format="shortDate"></app-ng2-date>
    </span>

    <span ng-if="passerelle._esk.statut !== 'diffused' && passerelle._esk.statut !== 'suspended'">Jamais diffusé</span>
  </div>

  <div>{{ 'biens.diffusions.item.statut.ACTION' | translate: '{statut: "' + passerelle._esk.statut + '"}' }}</div>
</div>
`;
