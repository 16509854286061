import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import CQuartiers from '@models/quartiers/collection/quartiers.collection.model';
import {CQuartiersFactory} from '@models/quartiers/collection/quartiers.collection.factory';

@Injectable({providedIn: 'root'})
export class CQuartiersService {
    private _cQuartiersFactory = inject(CQuartiersFactory);

    searchFromCommune$(communeUuid: string, keyword?: string): Observable<CQuartiers> {
        return this._cQuartiersFactory.getFromCommune$(communeUuid, {keywords: keyword});
    }
}
