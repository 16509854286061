import {inject, Injectable} from '@angular/core';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {NgEmailRecipient} from '@legacy/app/managers/ressources';
import {EmailRecipientService} from '@models/emails/email/recipients/recipient/email-recipient.service';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';

@Injectable({providedIn: 'root'})
export class EmailRecipientsService {
    private _emailRecipientService = inject(EmailRecipientService);

    createFromContactsGroup(contactsGroup?: ContactsGroup): EmailRecipient[] {
        return this.createFromContactsGroupMembers(contactsGroup?.members);
    }

    createFromContactsGroupMembers(contactsGroupMembers: ContactsGroupMember[] = []): EmailRecipient[] {
        return contactsGroupMembers
            .map(contactsGroupMember => this._emailRecipientService.createFromContactsGroupMember(contactsGroupMember))
            .filter(emailRecipient => !!emailRecipient.emailAddress);
    }

    createFromContactsGroups(contactsGroups: ContactsGroup[] = []): EmailRecipient[] {
        const recipientss = contactsGroups.map(contactsGroup => this.createFromContactsGroup(contactsGroup));
        const isBCC = recipientss.filter(rs => rs.length > 0).length > 1;
        const recipients = recipientss.reduce((previous, current) => previous.concat(current), []);

        if (isBCC) {
            recipients.forEach(recipient => recipient.type = EmailRecipient.types.BCC);
        }

        return recipients;
    }

    ngGet(emailRecipients: EmailRecipient[] = []): NgEmailRecipient[] {
        return emailRecipients.map(emailRecipient => this._emailRecipientService.ngGet(emailRecipient));
    }
}
