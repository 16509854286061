import angularJS from '@shared/angularJS/global.ng';
import {map} from 'lodash';
import {IModule} from 'angular';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {NgContact} from '@legacy/app/managers/ressources';
import Vente from '@models/ventes/vente/vente.model';

export default function getVenteModalContratsCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.controller('VenteModalContratsCtrl', Controller);

        /**
         * Controller for contrats modal
         *
         * @param BienDossierCurrent
         * @constructor
         */
        Controller.$inject = ['BienDossierCurrent'];
        function Controller(this: any, bienDossierCurrent: NgBienDossierCurrent) {
            const $ctrl = this;

            $ctrl.setTranslateParams = setTranslateParams;
            $ctrl.vente = bienDossierCurrent.get();
            $ctrl.contacts = {
                collection: angular.copy(map($ctrl.vente.bien.contactsGroup.members, 'contact')),
                options: {
                    onRemove: $ctrl.setTranslateParams,
                    onSelect: $ctrl.setTranslateParams,
                    placeholder: "Sélectionnez les mandants",
                }
            };
            $ctrl.contacts.collection.map((contact: NgContact) => (contact as unknown as {name: string}).name = contact[contact.type]!.convivialName);
            $ctrl.contratsOptions = {
                type: 'mandat',
                codePrefix: 'vente-' + ($ctrl.vente.type.code === Vente.types.INTERACTIVE ? 'interactive-' : ''),
                courrier: {options: {placeholder: "Choisissez un modèle de courrier", loading: true}},
                avenant: {options: {placeholder: "Choisissez un modèle d'avenant", loading: true, required: true}},
                mandat: {options: {placeholder: "Choisissez un modèle de mandat", loading: true, required: true}},
                mandants: $ctrl.contacts.collection
            };
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.setTranslateParams();

            /**
             * Set translateParams
             */
            function setTranslateParams() {
                $ctrl.translateParams = {
                    type: $ctrl.contratsOptions.type,
                    contratMandant: ($ctrl.contratsOptions.contratMandant && angular.isArray($ctrl.contratsOptions.mandants) && $ctrl.contratsOptions.mandants.length > 0)
                };
                $ctrl.translateParams.nbContrats = $ctrl.translateParams.contratMandant ? $ctrl.contratsOptions.mandants.length : 1;
            }
        }
    })(angularJS);
}
