import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@core/auth/core/auth.service';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RoutesService} from '@shared/routes/routes.service';

@Injectable({providedIn: 'root'})
export class AuthGuard {
    private _authService = inject(AuthService);
    private _routesService = inject(RoutesService);

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
        return this._isAuthenticated$(routerStateSnapshot.url);
    }

    // @todo Utilité d'une méthode private ?
    private _isAuthenticated$(currentUrl: string): Observable<boolean> {
        return this._authService.isAuthenticated$().pipe(
            catchError(_ => of(false)),
            switchMap(isAuthenticated => isAuthenticated ? of(true) : this._routesService.redirect$('/auth/login', currentUrl)),
        );
    }
}
