import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NgBrowserTools} from '@legacy/app/eskimmo/eskimmo';

export default function getEskBrowserCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskBrowserCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/browser.html',
        });

        /**
         * General controller
         *
         * @param EskBrowserTools
         * @param Ng2RouterStateForNgService
         * @param Ng2DeviceDetectorService
         * @constructor
         */
        Controller.$inject = ['EskBrowserTools', 'Ng2RouterStateForNgService', 'Ng2DeviceDetectorService'];
        function Controller(this: any,
                            eskBrowserTools: NgBrowserTools,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2DeviceDetectorService: DeviceDetectorService) {
            const $ctrl = this;

            $ctrl.ng2DeviceDetectorService = ng2DeviceDetectorService;
            $ctrl.browserConvivialName = eskBrowserTools.getConvivialName();
            if (!eskBrowserTools.browserIsOudated()) {
                ng2RouterStateForNgService.navigateByUrl('start');
            }
        }
    })(angularJS);
}
