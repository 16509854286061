import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {
    AppLocationPasserellesListComponent
} from '@features/locations/location/passerelles/list/location-passerelles-list.component';
import {
    LocationPasserelleDropdownComponent
} from '@features/locations/location/passerelles/passerelle/dropdown/location-passerelle.dropdown.component';
import {BiensModule} from '@features/biens/biens.module';
import {ContactsGroupModule} from '@features/contacts-group/contactsGroup.module';
import {DossierModule} from '@features/dossiers/dossier/dossier.module';
import {DCLocationSlideOverComponent} from '@features/locations/location/slide-over/location.slide-over.component';
import {UsersModule} from '@features/users/users.module';
import {AppLocationPricingComponent} from '@features/locations/location/pricing/location-pricing.component';
import {
    LocationPasserellesActionsSelectionComponent
} from '@features/locations/location/passerelles/actions/location-passerelles.actions-selection.component';

@NgModule({
    declarations: [
        AppLocationPasserellesListComponent,
        AppLocationPricingComponent,
        DCLocationSlideOverComponent,
        LocationPasserellesActionsSelectionComponent,
        LocationPasserelleDropdownComponent,
    ],
    exports: [AppLocationPasserellesListComponent, AppLocationPricingComponent],
    imports: [BiensModule, CommonModule, ContactsGroupModule, DossierModule, SharedModule, UsersModule],
})
export class LocationsModule {
}
