import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ICEtudeNetworksApi} from '@models/etudes/etude/networks/collection/etude-networks.collection.interfaces';
import {ModelApiService} from '@models/model.api.service';
import {IEtudeNetworkApi} from '@models/etudes/etude/networks/network/etude-network.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeNetworksApiService {
    private _modelApiService = inject(ModelApiService);

    get$(etudeNetworkUuid: string): Observable<IEtudeNetworkApi> {
        return this._modelApiService.get$('/etude/networks/' + etudeNetworkUuid);
    }

    getCollection$(): Observable<ICEtudeNetworksApi> {
        return this.getCollectionByLink$('/etude/networks');
    }

    getCollectionByLink$(link: string): Observable<ICEtudeNetworksApi> {
        return this._modelApiService.getCollection$(link);
    }
}
