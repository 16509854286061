import {NgModule, Type} from '@angular/core';
import {
    NgVenteCorrespondantsComponent, NgVenteCorrespondantsDirective
} from '@shared/angularJS/up-ng/ventes/ventes-item-correspondants.component';
import {
    NgVenteDiffusionComponent, NgVenteDiffusionDirective
} from '@shared/angularJS/up-ng/ventes/ventes-item-diffusion.component';
import {
    NgVenteLifetimeComponent, NgVenteLifetimeDirective
} from '@shared/angularJS/up-ng/ventes/ventes-item-lifetime.component';
import {
    NgVenteMandatsComponent, NgVenteMandatsDirective
} from '@shared/angularJS/up-ng/ventes/ventes-item-mandats.component';
import {
    NgVentesNegocieesEtudesComponent, NgVentesNegocieesEtudesDirective
} from '@shared/angularJS/up-ng/ventes/ventes-negociees-etudes.component';
import {
    NgVentesNegocieesNaturesComponent, NgVentesNegocieesNaturesDirective
} from '@shared/angularJS/up-ng/ventes/ventes-negociees-natures.component';
import {
    NgVentesNetworkUpdatedCriteriaComponent, NgVentesNetworkUpdatedCriteriaDirective
} from '@shared/angularJS/up-ng/ventes/ventes-network-updated-criteria.component';
import {CommonModule} from '@angular/common';

const components: Type<unknown>[] = [
    NgVenteCorrespondantsComponent,
    NgVenteDiffusionComponent,
    NgVenteLifetimeComponent,
    NgVenteMandatsComponent,
    NgVentesNegocieesEtudesComponent,
    NgVentesNegocieesNaturesComponent,
    NgVentesNetworkUpdatedCriteriaComponent,
];
const directives: Type<unknown>[] = [
    NgVenteCorrespondantsDirective,
    NgVenteDiffusionDirective,
    NgVenteLifetimeDirective,
    NgVenteMandatsDirective,
    NgVentesNegocieesEtudesDirective,
    NgVentesNegocieesNaturesDirective,
    NgVentesNetworkUpdatedCriteriaDirective,
];

@NgModule({declarations: [...components, ...directives], exports: components, imports: [CommonModule]})
export class VentesComponentsModule {
}
