@if (bonvisite$ | async; as bonvisite) {
  <div class="tw-mx-auto tw-h-full tw-w-full lg:tw-w-[calc(21cm_+_8rem)]">
    @if (bonvisite.documentId) {
      @if (procedure$ | appLoadObs | async; as procedure) {
        <div class="tw-flex tw-flex-col tw-h-full">
          <div class="tw-mb-4 sm:tw-mb-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
            <p class="tw-mx-auto tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
              Document
              @if (!!procedure.value) {
                signé {{ procedure.value.providerLabel }} /
                <span class="tw-text-sm">
                  Procédure <app-procedure-statut [procedure]="procedure.value" class="tw-inline-block"/>
                </span>
              } @else {
                rédigé
              }
            </p>
          </div>

          <div class="tw-flex-1 tw-mx-auto tw-w-full"><app-pdf [pdfPath$]="documentPath$"/></div>
        </div>
      } @else {
        <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
      }
    }

    @if (!bonvisite.documentId) {
      <div class="tw-pb-4 tw-space-y-4">
        <div class="tw-mb-4 sm:tw-mb-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
          <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
            Aucun document n'a été créé pour ce bon de visite.
          </p>
        </div>

        @if (bonvisite.linkDemandeur) {
          <div class="tw-mt-5 md:tw-mt-0 tw-shadow tw-bg-white md:tw-rounded-md tw-px-4 tw-py-5 md:tw-p-6 tw-space-y-2">
            <label class="tw-block tw-text-sm tw-text-gray-700">Demandeur sélectionné</label>

            <app-demandeur-view [link]="bonvisite.linkDemandeur" class="tw-block tw-text-base tw-text-gray-500"/>
          </div>
        }

        @if (dossierBiens$ | async; as dossierBiens) {
          <div class="tw-mt-5 md:tw-mt-0 tw-shadow tw-bg-white md:tw-rounded-md tw-px-4 tw-py-5 md:tw-p-6 tw-space-y-2">
            <label class="tw-block tw-text-sm tw-text-gray-700">
              {{ dossierBiens.length > 1 ? 'Biens sélectionnés' : 'Bien sélectionné' }}
            </label>

            <ul class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
              @for (dossierBien of dossierBiens; track dossierBien) {
                <li class="tw-shadow-sm tw-rounded-md">
                  <app-dossier-bien-card [options]="{enabledAction: undefined, withBorder: true}"
                                         [dossierBien]="dossierBien"/>
                </li>
              }
            </ul>
          </div>
        } @else {
          <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
        }

        <div class="tw-mt-5 md:tw-mt-0 tw-shadow tw-bg-white md:tw-rounded-md tw-px-4 tw-py-5 md:tw-p-6 tw-space-y-2">
          <label class="tw-block tw-text-sm tw-text-gray-700">Visite</label>

          <p class="tw-text-base tw-text-gray-500">
            {{ bonvisite.dateVisite | date:'longDate' }} à {{ bonvisite.dateVisite | date: 'shortTime' }}
          </p>
        </div>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
