export default `
<div class="row action">
  <div class="col-xs-12 text-right">
    <ng-switch on="!!$ctrl.loader.action">
      <p ng-switch-when="true" class="text-extra-large">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.' + $ctrl.loader.action | translate}}
      </p>

      <div ng-switch-default>
        <div ng-if="$ctrl.dossier._esk.typeDossier !== $ctrl.DossierBienTypesConst.ESTIMATION">
          <div ng-if="$ctrl.dossiers.totalItems > 1" class="inline-nav">
            <span class="text-bold">
              {{($ctrl.dossiers.current - 1) * $ctrl.dossiers.pageSize + $ctrl.currentIndex + 1}}
            </span>

            <span class="text-muted">/ {{$ctrl.dossiers.collection.length}}</span>

            <button type="button" class="btn btn-azure btn-o text-large"
                    ng-click="$ctrl.getDossier($ctrl.currentIndex - 1)">
              <fa-icon [icon]="'arrow-left'"></fa-icon>
            </button>

            <button class="btn btn-azure btn-o text-large" ng-click="$ctrl.getDossier($ctrl.currentIndex + 1)"
                    type="button"><fa-icon [icon]="'arrow-right'"></fa-icon></button>
          </div>

          <div ng-if="$ctrl.backToList" class="inline-nav">
            <button type="button" class="btn btn-azure btn-o text-large" ng-click="$ctrl.backToList()"
                    uib-tooltip="Revenir à la liste" tooltip-placement="bottom">
              <fa-icon [icon]="'reply-all'" flip="vertical"></fa-icon>
            </button>
          </div>

          <esk-button-print categories-code="$ctrl.options.categoriesCode" print="$ctrl.print"
                            advanced-print="$ctrl.advancedPrint"></esk-button-print>

          <div class="btn-group" uib-dropdown>
            <button ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE || !$ctrl.dossier.bien._esk.interne || $ctrl.inReferencesArchivees"
                    type="button" class="btn btn-azure btn-o text-large" ng-click="$ctrl.send()">
              <span class="text-large"><fa-icon [icon]="['far', 'envelope']"></fa-icon></span> Envoyer
            </button>

            <button ng-if="$ctrl.dossier.statut === $ctrl.STATUT_ARCHIVE && $ctrl.dossier.bien._esk.interne && !$ctrl.inReferencesArchivees"
                    type="button" class="btn btn-azure btn-o text-large" ng-click="$ctrl.restoration()">
              <span class="text-large"><fa-icon [icon]="'sync-alt'"></fa-icon></span> Restaurer
            </button>

            <button type="button" class="btn btn-azure btn-o text-large" uib-dropdown-toggle>
              <span class="caret"></span>
            </button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && !$ctrl.dossier.bien._esk.interne" role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.contactConfrere()">
                  <fa-icon [icon]="['far', 'envelope']"></fa-icon><span>Contacter le confrère</span>
                </button>
              </li>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && !$ctrl.dossier.bien._esk.interne" class="divider"></li>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && [$ctrl.DossierBienTypesConst.LOCATION, $ctrl.DossierBienTypesConst.VENTE].includes($ctrl.dossier._esk.typeDossier) && $ctrl.dossier.type.code !== $ctrl.INTERACTIVE"
                  role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.editBonVisite()">
                  <fa-icon [icon]="['far', 'file-alt']"></fa-icon><span>Créer un bon de visite</span>
                </button>
              </li>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && [$ctrl.DossierBienTypesConst.LOCATION, $ctrl.DossierBienTypesConst.VENTE].includes($ctrl.dossier._esk.typeDossier) && $ctrl.dossier.type.code !== $ctrl.INTERACTIVE"
                  class="divider"></li>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && $ctrl.DossierBienTypesConst.VENTE && $ctrl.dossier.type.code !== $ctrl.INTERACTIVE"
                  role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.editOffreAchat()">
                  <fa-icon [icon]="'file-invoice-dollar'"></fa-icon><span>Créer une offre d'achat</span>
                </button>
              </li>

              <div ng-if="!$ctrl.bienDossier.isArchived() && ($ctrl.bienDossier.isLocation() || $ctrl.bienDossier.isVente())">
                <div ng-repeat="bienDossierPasserelle in $ctrl.bienDossierPasserelles">
                  <li class="divider"></li>

                  <li role="menuitem">
                    <button ng-if="!bienDossierPasserelle.diffused"
                            type="button" class="tw-dropdown-link" ng-click="$ctrl.diffuser(bienDossierPasserelle)">
                      <fa-icon [icon]="'rss'"></fa-icon>

                      <span>Diffuser sur {{$ctrl.getEtudePasserelle(bienDossierPasserelle).passerelle.name}}</span>
                    </button>

                    <button ng-if="bienDossierPasserelle.diffused"
                            type="button" class="tw-dropdown-link" ng-click="$ctrl.retirer(bienDossierPasserelle)"
                            ng-disabled="$ctrl.dossier.type.code === $ctrl.INTERACTIVE && $ctrl.getEtudePasserelle(bienDossierPasserelle).passerelle.venteInteractive === 'native'">
                      <fa-icon [icon]="'trash-alt'"></fa-icon>

                      <span>Retirer de {{$ctrl.getEtudePasserelle(bienDossierPasserelle).passerelle.name}}</span>
                    </button>
                  </li>
                </div>
              </div>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && $ctrl.DossierBienTypesConst.VENTE && $ctrl.dossier.type.code !== $ctrl.INTERACTIVE"
                  class="divider"></li>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && $ctrl.dossier.bien._esk.interne" role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.editDossierBien()">
                  <fa-icon [icon]="['far', 'edit']"></fa-icon><span>Modifier</span>
                </button>
              </li>

              <li ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE && $ctrl.dossier.bien._esk.interne" class="divider"></li>

              <li role="menuitem"
                  ng-if="$ctrl.DossierBienTypesConst.VENTE && $ctrl.dossier.statut !== $ctrl.CODE_VENTE_BROUILLON && $ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE">
                <button type="button" class="tw-dropdown-link"
                        ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.dossier.id.toString() + '/correspondants')">
                  <fa-icon [icon]="['far', 'user']"></fa-icon><span>Demandeurs correspondants</span>
                </button>
              </li>

              <li ng-if="$ctrl.DossierBienTypesConst.VENTE && $ctrl.dossier.statut !== $ctrl.CODE_VENTE_BROUILLON && $ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE"
                  class="divider"></li>

              <li role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.unlockData = !$ctrl.unlockData">
                  <fa-icon ng-if="$ctrl.unlockData" [icon]="'lock'"></fa-icon>

                  <fa-icon ng-if="!$ctrl.unlockData" [icon]="'lock-open'"></fa-icon>

                  <span ng-if="$ctrl.unlockData">Bloquer</span>

                  <span ng-if="!$ctrl.unlockData">Débloquer</span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION">
          <esk-button-print categories-code="$ctrl.options.categoriesCode" print="$ctrl.print"
                            advanced-print="$ctrl.advancedPrint"></esk-button-print>

          <a ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE" type="button" class="btn btn-azure btn-o text-large"
             ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + $ctrl.dossier.id.toString() + '/edit/general')">
            <fa-icon [icon]="['far', 'edit']" class="text-large"></fa-icon><span>Modifier</span>
          </a>

          <button ng-if="$ctrl.dossier.statut === $ctrl.STATUT_ARCHIVE" ng-click="$ctrl.restoration()"
                  type="button" class="btn btn-azure btn-o text-large">
            <fa-icon [icon]="'sync-alt'" class="text-large"></fa-icon><span>Restaurer</span>
          </button>
        </div>
      </div>
    </ng-switch>
  </div>
</div>
`;
