export default `
<div class="text-justify">
  <p class="text-bold">
    Correspondance des couleurs.
  </p>

  <p>
    <span class="label label-results resultat-green">X</span> - Nouveau(x) résultat(s) à traiter.
  </p>

  <p>
    <span class="label label-results resultat-orange">X</span> - Résultat(s) modifié(s) (prix).
  </p>

  <p>
    <span class="label label-results resultat-blue">X</span> - Résultat(s) intéressant(s).
  </p>

  <p>
    <span class="label label-results resultat-red">X</span> - Bien(s) supprimé(s) du réseau.
  </p>
</div>
`;
