import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';

export default class Offreachat extends AModel {
    static readonly statuts = {
        ARCHIVE: 'archive',
        BROUILLON: 'brouillon',
        NEW: AModel.STATUT_NEW,
        REDACTION: 'redaction',
        SIGNATURE: 'signature',
        SIGNATURE_ACQUEREUR: 'signature_acquereur',
        SIGNATURE_VENDEUR: 'signature_vendeur',
    };
    private _colorStatut!: NoteoStatutColorType;
    private _dateOffre!: string;
    private _documentId!: number;
    private _demandeurUuid!: string;
    private _honorairesNego!: number;
    private _labelStatut!: string;
    private _linkDemandeur!: string;
    private _linkDocument!: string;
    private _linkMedia!: string;
    private _linkProcedureAcquereur!: string;
    private _linkProcedures!: string;
    private _linkProcedureVendeur!: string;
    private _linkVente!: string;
    private _montant!: number;
    private _statut!: string;
    private _typeCharges!: DictionaryItem;
    private _venteUuid!: string;

    get colorStatut(): NoteoStatutColorType {
        return this._colorStatut;
    }

    get dateOffre(): string {
        return this._dateOffre;
    }

    set dateOffre(value: string) {
        this._dateOffre = value;
    }

    get demandeurUuid(): string {
        return this._demandeurUuid;
    }

    set demandeurUuid(value: string) {
        this._demandeurUuid = value;
    }

    get documentId(): number {
        return this._documentId;
    }

    set documentId(value: number) {
        this._documentId = value;
    }

    get honorairesNego(): number {
        return this._honorairesNego;
    }

    set honorairesNego(value: number) {
        this._honorairesNego = value;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get linkDemandeur(): string {
        return this._linkDemandeur;
    }

    set linkDemandeur(value: string) {
        this._linkDemandeur = value;
    }

    get linkDocument(): string {
        return this._linkDocument;
    }

    set linkDocument(value: string) {
        this._linkDocument = value;
    }

    get linkMedia(): string {
        return this._linkMedia;
    }

    set linkMedia(value: string) {
        this._linkMedia = value;
    }

    get linkProcedureAcquereur(): string {
        return this._linkProcedureAcquereur;
    }

    set linkProcedureAcquereur(value: string) {
        this._linkProcedureAcquereur = value;
    }

    get linkProcedures(): string {
        return this._linkProcedures;
    }

    set linkProcedures(value: string) {
        this._linkProcedures = value;
    }

    get linkProcedureVendeur(): string {
        return this._linkProcedureVendeur;
    }

    set linkProcedureVendeur(value: string) {
        this._linkProcedureVendeur = value;
    }

    get linkVente(): string {
        return this._linkVente;
    }

    set linkVente(value: string) {
        this._linkVente = value;
    }

    get montant(): number {
        return this._montant;
    }

    set montant(value: number) {
        this._montant = value;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
        switch (this._statut) {
            case Offreachat.statuts.ARCHIVE :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = 'archive';
                break;
            case Offreachat.statuts.BROUILLON :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'brouillon';
                break;
            case Offreachat.statuts.NEW :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'nouveau';
                break;
            case Offreachat.statuts.REDACTION :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'rédaction';
                break;
            case Offreachat.statuts.SIGNATURE :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'signature';
                break;
            case Offreachat.statuts.SIGNATURE_ACQUEREUR :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'signature';
                break;
            case Offreachat.statuts.SIGNATURE_VENDEUR :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'signature';
                break;
            default:
                this._labelStatut = undefined!;
        }
    }

    get typeCharges(): DictionaryItem {
        return this._typeCharges;
    }

    set typeCharges(value: DictionaryItem) {
        this._typeCharges = value;
    }

    get venteUuid(): string {
        return this._venteUuid;
    }

    set venteUuid(value: string) {
        this._venteUuid = value;
    }

    isArchive(): boolean {
        return this.statut === Offreachat.statuts.ARCHIVE;
    }

    isBrouillon(): boolean {
        return this.statut === Offreachat.statuts.BROUILLON;
    }

    isEdit(): boolean {
        return this.isBrouillon() || this.isNew();
    }

    isNew(): boolean {
        return this.statut === Offreachat.statuts.NEW;
    }

    isSign(): boolean {
        return this.statut === Offreachat.statuts.SIGNATURE;
    }

    isSignature(): boolean {
        return this.isSignatureAcquereur() || this.isSignatureVendeur();
    }

    isSignatureAcquereur(): boolean {
        return this.statut === Offreachat.statuts.SIGNATURE_ACQUEREUR;
    }

    isSignatureVendeur(): boolean {
        return this.statut === Offreachat.statuts.SIGNATURE_VENDEUR;
    }

    isWrite(): boolean {
        return this.statut === Offreachat.statuts.REDACTION;
    }
}
