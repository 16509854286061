import {inject, Injectable} from '@angular/core';
import {ProcedureSignataireFactory} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import {combineLatest, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {ProcedureSignataireService} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import Contact from '@models/contacts/contact/contact.model';
import User from '@models/users/user/user.model';

@Injectable({providedIn: 'root'})
export class CProcedureSignatairesService {
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);
    private _procedureSignataireService = inject(ProcedureSignataireService);

    delete$(procedureUuid: string, cProcedureSignataires: CProcedureSignataires, procedureSignataire: ProcedureSignataire): Observable<void> {
        return this._procedureSignataireService.delete$(procedureUuid, procedureSignataire).pipe(
            tap(_ => cProcedureSignataires.removeResult(result => result.uuid === procedureSignataire.uuid && result.type === procedureSignataire.type && result.typeId === procedureSignataire.typeId))
        );
    }

    init(cProcedureSignataires: CProcedureSignataires, futurProcedureSignataires: (Contact | User)[] = []): void {
        futurProcedureSignataires
            .map(futurProcedureSignataire => this._procedureSignataireFactory.createFromUnknown(futurProcedureSignataire))
            .forEach(procedureSignataure => cProcedureSignataires.addResult(procedureSignataure));
    }

    save$(procedure: Procedure): Observable<CProcedureSignataires> {
        const procedureSignatairesSaves: Observable<ProcedureSignataire>[] = [];

        if (!procedure.isEditable()) {
            return of(procedure.cSignataires);
        }

        procedure.cSignataires.results
            .filter(procedureSignataire => procedureSignataire.isEditable())
            .forEach(procedureSignataire => procedureSignatairesSaves.push(this._procedureSignataireFactory.save$(procedure, procedureSignataire)));

        return combineLatest(procedureSignatairesSaves).pipe(
            tap(procedureSignataires => procedure.cSignataires.results = procedureSignataires),
            map(_ => procedure.cSignataires));
    }
}
