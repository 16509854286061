import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, from, Observable, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {UserService} from '@models/users/user/user.service';
import {NgLocationManager} from '@legacy/app/managers/managers';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {NgLocation} from '@legacy/app/managers/ressources';
import {LocationFactory} from '@models/locations/location/location.factory';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';

@Component({selector: 'layout-location', templateUrl: 'layout-location.component.html'})
export class AppLayoutLocationComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _locationFactory = inject(LocationFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _userService = inject(UserService);
    private _locationSource = new Subject<NgLocation>();
    private _location$ = this._locationSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService<NgBienDossierCurrent>('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService<NgLocationManager>('LocationManager');
    }

    get location$(): Observable<NgLocation> {
        return this._location$;
    }

    ngOnInit(): void {
        combineLatest([this._activatedRoute.params, this._userService.last$]).pipe(
            tap(_ => this._locationSource.next(undefined!)),
            switchMap(([params, currentUser]): Observable<void> => from(this.ngLocationManager.initCurrent(+params.locationId, currentUser))),
            switchMap(_ => this.ngLocationManager.current$),
            tap(ngLocation => this._cDossierFilesService.initCurrentWithMedia(this._locationFactory.ngCreate(ngLocation))),
            tap(ngLocation => this.ngBienDossierCurrent.set(ngLocation)),
            takeUntil(this._onDestroy$),
        ).subscribe(ngLocation => this._locationSource.next(ngLocation));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
