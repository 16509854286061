import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICEtudeDossiersApi, ICEtudeDossiersQueryParameters
} from '@models/etude-dossiers/collection/etude-dossiers.collection.interfaces';


@Injectable({providedIn: 'root'})
export class EtudeDossiersApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(queryParameters?: ICEtudeDossiersQueryParameters): Observable<ICEtudeDossiersApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/dossiers', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICEtudeDossiersApi> {
        return this._modelApiService.getCollection$(link);
    }
}
