import angularJS from '@shared/angularJS/global.ng';
import {find} from "lodash";
import {SortConst} from '@shared/constants';
import {IModule} from 'angular';
import {NgSort} from '@legacy/app/managers/ressources';

export default function getManagersSort(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('SortManager', manager);

        /**
         * Manager sorts
         *
         * @returns {SortManager}
         */
        manager.$inject = [] as string[];
        function manager() {
            let sorts: Record<string, NgSort> = {};
            let sortsForBoxEstimationsAvisValeurRemis: NgSort[] = [];
            let sortsForBoxVentesMandatsExpired: NgSort[] = [];
            let sortsForBoxVentesMandatsExpiring: NgSort[] = [];
            let sortsForBoxVentesMandatsWithout: NgSort[] = [];
            let sortsForListLocations: NgSort[] = [];
            let sortsForListVentes: NgSort[] = [];
            let sortsForMatchingDemandeurs: NgSort[] = [];
            let sortsForMatchingLocations: NgSort[] = [];
            let sortsForMatchingVentes: NgSort[] = [];
            let sortsForWalletDemandeurs: NgSort[] = [];
            let sortsForWalletEstimations: NgSort[] = [];
            let sortsForWalletLocations: NgSort[] = [];
            let sortsForWalletVentes: NgSort[] = [];

            /**
             * SortManager object
             *
             * @constructor
             */
            function SortManager() {
                const sortsStructure = {
                    commune: {
                        type: undefined,
                        column: "Localisation",
                        ASC: "Localisation A→Z",
                        DESC: "Localisation Z→A"
                    },
                    createdAt: {
                        type: undefined, column: "Date de création", ASC: "Premier créé", DESC: "Dernier créé"
                    },
                    createdAtDemandeur: {
                        type: undefined, column: "Date de création", ASC: "Premier créé", DESC: "Dernier créé"
                    },
                    dateDiffusion: {
                        type: undefined,
                        column: "Date de diffusion",
                        ASC: "Envoyés il y a longtemps",
                        DESC: "Envoyés récemment"
                    },
                    dateRemise: {
                        type: undefined,
                        column: "Date de remise",
                        ASC: "Date de remise la plus ancienne",
                        DESC: "Date de remise la plus récente"
                    },
                    loyer: {type: 'price', column: "Loyer", ASC: "Loyer croissant ↗", DESC: "Loyer décroissant ↘"},
                    mandatDate: {
                        type: undefined,
                        column: "Date de mandat",
                        ASC: "Date de mandat la plus ancienne",
                        DESC: "Date de mandat la plus récente"
                    },
                    mandatDateFin: {
                        type: undefined,
                        column: "Date de fin de mandat",
                        ASC: "Date de fin de mandat la plus ancienne",
                        DESC: "Date de fin de mandat la plus récente"
                    },
                    nature: {
                        type: undefined,
                        column: "Nature",
                        ASC: "Nature A→Z",
                        DESC: "Nature Z→A"
                    },
                    nombreChambres: {
                        type: undefined,
                        column: "Chambres",
                        ASC: "Nombre chambres croissant ↗",
                        DESC: "Nombre chambres décroissant ↘"
                    },
                    nombrePieces: {
                        type: undefined,
                        column: "Pièces",
                        ASC: "Nombre pièces croissant ↗",
                        DESC: "Nombre pièces décroissant ↘"
                    },
                    nomDemandeur: {
                        type: undefined,
                        column: "Nom du dossier",
                        ASC: "Nom du dossier A→Z",
                        DESC: "Nom du dossier Z→A"
                    },
                    nomProprietaire: {
                        type: undefined,
                        column: "Nom du dossier",
                        ASC: "Nom du dossier A→Z",
                        DESC: "Nom du dossier Z→A"
                    },
                    nouveauxResultats: {
                        type: undefined,
                        column: "Résultats",
                        ASC: "Nouveaux biens correspondants",
                        DESC: "Biens validés"
                    },
                    prixHni: {
                        type: 'price',
                        column: "Prix",
                        ASC: "Prix croissant ↗",
                        DESC: "Prix décroissant ↘"
                    },
                    reference: {type: undefined, column: "Référence", ASC: "Référence A→Z", DESC: "Référence Z→A"},
                    status: {
                        type: undefined,
                        column: "Statut",
                        ASC: "Nouveautés en premier",
                        DESC: "Nouveautés en dernier"
                    },
                    updatedAt: {
                        type: undefined,
                        column: "Date de modification",
                        ASC: "Premier modifié",
                        DESC: "Dernier modifié"
                    },
                    updatedAtDemandeur: {
                        type: undefined,
                        column: "Date de modification",
                        ASC: "Premier modifié",
                        DESC: "Dernier modifié"
                    },
                    valeurVenale: {
                        type: 'price',
                        column: "Valeur vénale",
                        ASC: "Valeur vénale croissante ↗",
                        DESC: "Valeur vénale décroissante ↘"
                    },
                };

                // Initialisation de tous les tris possibles
                angular.forEach(sortsStructure, (sortProperty, sortCode) => {
                    const sortASC = {
                        id: sortCode + SortConst.ASCENDING,
                        code: sortCode,
                        type: sortProperty.type,
                        column: sortProperty.column,
                        label: sortProperty[SortConst.ASCENDING],
                        direction: SortConst.ASCENDING
                    };
                    const sortDESC = {
                        id: sortCode + SortConst.DESCENDING,
                        code: sortCode,
                        type: sortProperty.type,
                        column: sortProperty.column,
                        label: sortProperty[SortConst.DESCENDING],
                        direction: SortConst.DESCENDING
                    };

                    sorts[sortASC.id] = sortASC as NgSort;
                    sorts[sortDESC.id] = sortDESC as NgSort;
                });

                sortsForBoxEstimationsAvisValeurRemis = getSortsArray([
                    'dateRemise' + SortConst.ASCENDING,
                    'dateRemise' + SortConst.DESCENDING,
                ]);
                sortsForBoxVentesMandatsExpired = getSortsArray([
                    'mandatDateFin' + SortConst.ASCENDING,
                    'mandatDateFin' + SortConst.DESCENDING,
                ]);
                sortsForBoxVentesMandatsExpiring = getSortsArray([
                    'mandatDateFin' + SortConst.ASCENDING,
                    'mandatDateFin' + SortConst.DESCENDING,
                ]);
                sortsForBoxVentesMandatsWithout = getSortsArray([
                    'createdAt' + SortConst.ASCENDING,
                    'createdAt' + SortConst.DESCENDING,
                ]);
                sortsForListLocations = getSortsArray([
                    'dateDiffusion' + SortConst.DESCENDING,
                    'loyer' + SortConst.ASCENDING,
                    'loyer' + SortConst.DESCENDING,
                    'commune' + SortConst.ASCENDING,
                    'commune' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                    'nature' + SortConst.ASCENDING,
                    'nature' + SortConst.DESCENDING,
                    'nombrePieces' + SortConst.ASCENDING,
                    'nombrePieces' + SortConst.DESCENDING,
                    'nombreChambres' + SortConst.ASCENDING,
                    'nombreChambres' + SortConst.DESCENDING,
                    'dateDiffusion' + SortConst.ASCENDING,
                ]);
                sortsForListVentes = getSortsArray([
                    'dateDiffusion' + SortConst.DESCENDING,
                    'prixHni' + SortConst.ASCENDING,
                    'prixHni' + SortConst.DESCENDING,
                    'commune' + SortConst.ASCENDING,
                    'commune' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                    'nature' + SortConst.ASCENDING,
                    'nature' + SortConst.DESCENDING,
                    'nombrePieces' + SortConst.ASCENDING,
                    'nombrePieces' + SortConst.DESCENDING,
                    'nombreChambres' + SortConst.ASCENDING,
                    'nombreChambres' + SortConst.DESCENDING,
                    'dateDiffusion' + SortConst.ASCENDING,
                ]);
                sortsForMatchingDemandeurs = getSortsArray([
                    'nouveauxResultats' + SortConst.ASCENDING,
                    'createdAtDemandeur' + SortConst.DESCENDING,
                    'updatedAtDemandeur' + SortConst.DESCENDING,
                    'nomDemandeur' + SortConst.ASCENDING,
                    'nomDemandeur' + SortConst.DESCENDING,
                    'createdAtDemandeur' + SortConst.ASCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                ]);
                sortsForMatchingLocations = getSortsArray([
                    'status' + SortConst.ASCENDING,
                    'loyer' + SortConst.ASCENDING,
                    'loyer' + SortConst.DESCENDING,
                    'commune' + SortConst.ASCENDING,
                    'commune' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                    'nature' + SortConst.ASCENDING,
                    'nature' + SortConst.DESCENDING,
                    'nombrePieces' + SortConst.ASCENDING,
                    'nombrePieces' + SortConst.DESCENDING,
                    'nombreChambres' + SortConst.ASCENDING,
                    'nombreChambres' + SortConst.DESCENDING,
                ]);
                sortsForMatchingVentes = getSortsArray([
                    'status' + SortConst.ASCENDING,
                    'prixHni' + SortConst.ASCENDING,
                    'prixHni' + SortConst.DESCENDING,
                    'commune' + SortConst.ASCENDING,
                    'commune' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                    'nature' + SortConst.ASCENDING,
                    'nature' + SortConst.DESCENDING,
                    'nombrePieces' + SortConst.ASCENDING,
                    'nombrePieces' + SortConst.DESCENDING,
                    'nombreChambres' + SortConst.ASCENDING,
                    'nombreChambres' + SortConst.DESCENDING,
                ]);
                sortsForWalletDemandeurs = getSortsArray([
                    'nouveauxResultats' + SortConst.ASCENDING,
                    'createdAt' + SortConst.DESCENDING,
                    'updatedAt' + SortConst.DESCENDING,
                    'nomDemandeur' + SortConst.ASCENDING,
                    'nomDemandeur' + SortConst.DESCENDING,
                    'createdAt' + SortConst.ASCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                ]);
                sortsForWalletLocations = getSortsArray([
                    'createdAt' + SortConst.DESCENDING,
                    'updatedAt' + SortConst.DESCENDING,
                    'loyer' + SortConst.DESCENDING,
                    'loyer' + SortConst.ASCENDING,
                    'createdAt' + SortConst.ASCENDING,
                    'nomProprietaire' + SortConst.ASCENDING,
                    'nomProprietaire' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                ]);
                sortsForWalletEstimations = getSortsArray([
                    'createdAt' + SortConst.DESCENDING,
                    'updatedAt' + SortConst.DESCENDING,
                    'valeurVenale' + SortConst.ASCENDING,
                    'valeurVenale' + SortConst.DESCENDING,
                    'createdAt' + SortConst.ASCENDING,
                    'nomProprietaire' + SortConst.ASCENDING,
                    'nomProprietaire' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                    'dateRemise' + SortConst.ASCENDING,
                    'dateRemise' + SortConst.DESCENDING,
                ]);
                sortsForWalletVentes = getSortsArray([
                    'createdAt' + SortConst.DESCENDING,
                    'updatedAt' + SortConst.DESCENDING,
                    'mandatDate' + SortConst.ASCENDING,
                    'prixHni' + SortConst.DESCENDING,
                    'prixHni' + SortConst.ASCENDING,
                    'createdAt' + SortConst.ASCENDING,
                    'mandatDate' + SortConst.DESCENDING,
                    'mandatDateFin' + SortConst.ASCENDING,
                    'mandatDateFin' + SortConst.DESCENDING,
                    'nomProprietaire' + SortConst.ASCENDING,
                    'nomProprietaire' + SortConst.DESCENDING,
                    'reference' + SortConst.ASCENDING,
                    'reference' + SortConst.DESCENDING,
                    'commune' + SortConst.ASCENDING,
                    'commune' + SortConst.DESCENDING,
                ]);

                /**
                 * Get sorts array by ids
                 *
                 * @param sortsIds
                 * @returns {Array}
                 */
                function getSortsArray(sortsIds: string[]): NgSort[] {
                    var sortsArray: NgSort[] = [];

                    angular.forEach(sortsIds, function (sortsId) {
                        sortsArray.push(sorts[sortsId]);
                    });

                    return angular.copy(sortsArray);
                }
            }

            /**
             * Get sorts for type
             *
             * @param type
             * @returns {Array}
             */
            SortManager.prototype.getList = function (type: string) {
                switch (type.toUpperCase()) {
                    case 'BOX_ESTIMATIONS-REMIS' :
                        return angular.copy(sortsForBoxEstimationsAvisValeurRemis);

                    case 'BOX_VENTES-MANDATS-EXPIRED' :
                        return angular.copy(sortsForBoxVentesMandatsExpired);

                    case 'BOX_VENTES-MANDATS-EXPIRING' :
                        return angular.copy(sortsForBoxVentesMandatsExpiring);

                    case 'BOX_VENTES-MANDATS-WITHOUT' :
                        return angular.copy(sortsForBoxVentesMandatsWithout);

                    case 'DEMANDEURS_WALLET_CURRENT' :
                        return angular.copy(sortsForWalletDemandeurs);

                    case 'DEMANDEURS_LIST_CORRESPONDANTS' :
                        return angular.copy(sortsForMatchingDemandeurs);

                    case 'ESTIMATIONS_WALLET_CURRENT' :
                        return angular.copy(sortsForWalletEstimations);

                    case 'LOCATIONS_LIST' :
                        return angular.copy(sortsForListLocations);

                    case 'LOCATIONS_LIST_CORRESPONDANTS' :
                        return angular.copy(sortsForMatchingLocations);

                    case 'LOCATIONS_WALLET_CURRENT' :
                        return angular.copy(sortsForWalletLocations);

                    case 'VENTES_LIST' :
                        return angular.copy(sortsForListVentes);

                    case 'VENTES_LIST_CORRESPONDANTS' :
                        return angular.copy(sortsForMatchingVentes);

                    case 'VENTES_WALLET_CURRENT' :
                        return angular.copy(sortsForWalletVentes);

                    default :
                        return [];
                }
            };

            /**
             * Get one sort by id
             *
             * @param sortId
             * @returns {Object}
             */
            SortManager.prototype.getOneById = function (sortId: string) {
                return angular.copy(sorts[sortId]);
            };

            /**
             * Get one sort by id
             *
             * @param sortType
             * @param sortId
             * @returns Boolean
             */
            SortManager.prototype.isIdInArray = function (sortType: string, sortId: string) {
                return angular.isObject(find(this.getList(sortType), {id: sortId}));
            };

            // @ts-ignore
            return new SortManager();
        }
    })(angularJS);
}
