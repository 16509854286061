import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import Bien from '@models/bien/bien.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';

export default abstract class ADossierBien extends ADossier {
    static readonly abstractBienStatuts = {
        ARCHIVE: ADossier.abstractStatuts.ARCHIVE,
        BROUILLON: ADossier.abstractStatuts.BROUILLON,
        COMPROMIS: ADossier.abstractStatuts.COMPROMIS,
        DIFFUSE: ADossier.abstractStatuts.DIFFUSE,
        DISPONIBLE: ADossier.abstractStatuts.DISPONIBLE,
        MANDAT: ADossier.abstractStatuts.MANDAT,
        NEW: ADossier.abstractStatuts.NEW,
        REMIS: ADossier.abstractStatuts.REMIS,
        SUSPENDU: ADossier.abstractStatuts.SUSPENDU,
    };
    readonly dossierType: DossierBienType = null!;
    protected _printAffichetteCategoryCodes: string[] = [];
    protected _printCourrierFicheCategoryCodes: string[] = [];
    protected _printFicheCategoryCodes: string[] = [];
    protected _sendFicheCategoryCode!: string;
    private _archiveComments!: string;
    private _bien!: Bien;

    get archiveComments(): string {
        return this._archiveComments;
    }

    set archiveComments(value: string) {
        this._archiveComments = value;
    }

    get bien(): Bien {
        return this._bien;
    }

    set bien(value: Bien) {
        this._bien = value;
    }

    get contactsGroup(): ContactsGroup {
        return this._bien.contactsGroup;
    }

    get linkPremierePhoto(): string {
        return this._bien.linkPremierePhoto;
    }

    get linkPremierePhoto16by9(): string {
        return this._bien.linkPremierePhoto16by9;
    }

    get linkPremierePhotoOptimized(): string {
        return this._bien.linkPremierePhotoOptimized;
    }

    get linkPremierePhotoThumbnail(): string {
        return this._bien.linkPremierePhotoThumbnail;
    }

    get printAffichetteCategoryCodes(): string[] {
        return this._printAffichetteCategoryCodes;
    }

    get printCourrierFicheCategoryCodes(): string[] {
        return this._printCourrierFicheCategoryCodes;
    }

    get printFicheCategoryCodes(): string[] {
        return this._printFicheCategoryCodes;
    }

    get responsableId(): number {
        return this._bien.responsableDossierId;
    }

    get sendFicheCategoryCode(): string {
        return this._sendFicheCategoryCode;
    }
}
