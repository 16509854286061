import angularJS from '@shared/angularJS/global.ng';
import {last} from 'lodash';
import simplify from 'simplify-js';
import {IModule, ITimeoutService} from 'angular';

export default function getSoqGmapsFreeDraw(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-gmaps-free-draw></soq-gmaps-free-draw>
         */
        module.component('soqGmapsFreeDraw', {
            bindings: {
                center: '=',
                zoom: '=',
                getPolygons: '=',
                onGetPolygons: '=',
                options: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/soqrate/components/gmaps.free-draw.html',
        });

        /**
         * Draw on the map
         *
         * @param $timeout
         * @constructor
         */
        Controller.$inject = ['$timeout'];
        function Controller(this: any, $timeout: ITimeoutService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.resetMap = resetMap;
            $ctrl.startDrawing = startDrawing;
            $ctrl.clearDraw = clearDraw;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.map = {};
                $ctrl.drawing = false;
                $ctrl.polygons = [];
                $ctrl.getPolygons = getPolygons;
            }

            /**
             * Method to erase drawed polygons
             *
             * @pgaste: Je n'ai pas trouvé d'autre alternative pour annuler le dessin sur la carte.
             * Pour le moment, on supprime la carte et on la recharge par l'opération de compilation d'Angular.
             * Pas propre du tout, mais pas mieux pour le moment. Utilisation du timeout pour s'assurer de la
             * suppression, puis réactivation (via ng-if).
             */
            function resetMap() {
                $ctrl.drawing = false;
                $ctrl.map = undefined;
                $timeout(function () {
                    $ctrl.map = {};
                });
            }

            /**
             * Start drawing
             */
            function startDrawing() {
                if (!angular.isObject($ctrl.map) || !angular.isFunction($ctrl.map.draw)) {
                    return;
                }

                $ctrl.drawing = true;
                $ctrl.map.draw().then(function () {
                    const coordinatesBrut = [] as unknown[];
                    const newPath = [] as unknown[];
                    var polygon = last($ctrl.polygons);
                    // @ts-ignore
                    var paths = polygon.getPath().getArray();
                    var coordinatesSimplify;
                    var i;

                    // Ajout des options
                    // @ts-ignore
                    polygon.setOptions({editable: true, draggable: true});

                    // Pas de simplification des points
                    if (paths.length < 30) return;

                    // Création d'un tableau de coordonnées (x,y) pour simplify()
                    for (i = 0; i < paths.length; i++) {
                        var latLngObject = paths[i].toJSON();

                        coordinatesBrut.push({
                            x: +latLngObject.lat.toFixed(5),
                            y: +latLngObject.lng.toFixed(5),
                        });
                    }

                    // Simplification du nombre de points entre 30 et 50, 20 tours maximum
                    var tolerance = {min: 0, max: 0.002};
                    var turn = 0;
                    do {
                        // @ts-ignore
                        tolerance.current = (tolerance.max + tolerance.min) / 2;
                        // @ts-ignore
                        coordinatesSimplify = simplify(coordinatesBrut, tolerance.current);
                        turn++;

                        if (coordinatesSimplify.length < 30) {
                            // @ts-ignore
                            tolerance.min = tolerance.current / 2;
                            // @ts-ignore
                            tolerance.max = tolerance.current;
                        } else {
                            // @ts-ignore
                            tolerance.min = tolerance.current;
                            // @ts-ignore
                            tolerance.max = tolerance.current * 2;
                        }
                    } while ((coordinatesSimplify.length < 30 || coordinatesSimplify.length > 50) && turn < 20);

                    if (coordinatesSimplify.length > 100) throw new Error(JSON.stringify(coordinatesSimplify));

                    // Création du nouveau path
                    for (i = 0; i < coordinatesSimplify.length; i++) {
                        newPath.push({lat: coordinatesSimplify[i].x, lng: coordinatesSimplify[i].y});
                    }

                    // Saisie du nouveau path
                    // @ts-ignore
                    polygon.setPath(newPath);
                }).finally(function () {
                    $ctrl.drawing = false;
                });
            }

            /**
             * Clear all draws
             */
            function clearDraw() {
                $ctrl.polygons = [];
            }

            /**
             * Get Polygons to launch Search
             */
            function getPolygons() {
                $ctrl.searching = true;

                return $ctrl.onGetPolygons($ctrl.polygons).finally(function () {
                    $ctrl.polygons = [];
                    $ctrl.searching = false;
                });
            }
        }
    })(angularJS);
}
