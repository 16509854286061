import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewAccessibiliteEnvironnementService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _isAccesHandicapeVisible!: boolean;
    private _isChambreRdcVisible!: boolean;
    private _isCommentsAccessibiliteEtEnvironnementVisible!: boolean;
    private _isCommoditesVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isPieceEauRdcVisible!: boolean;
    private _isPlainPiedVisible!: boolean;
    private _isVuesVisible!: boolean;

    get isAccesHandicapeVisible(): boolean {
        return this._isAccesHandicapeVisible;
    }

    get isChambreRdcVisible(): boolean {
        return this._isChambreRdcVisible;
    }

    get isCommentsAccessibiliteEtEnvironnementVisible(): boolean {
        return this._isCommentsAccessibiliteEtEnvironnementVisible;
    }

    get isCommoditesVisible(): boolean {
        return this._isCommoditesVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isPieceEauRdcVisible(): boolean {
        return this._isPieceEauRdcVisible;
    }

    get isPlainPiedVisible(): boolean {
        return this._isPlainPiedVisible;
    }

    get isVuesVisible(): boolean {
        return this._isVuesVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isAccesHandicapeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.accesHandicape);
        this._isChambreRdcVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.chambreRdc);
        this._isCommentsAccessibiliteEtEnvironnementVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsAccessibiliteEtEnvironnement);
        this._isCommoditesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commodites);
        this._isPieceEauRdcVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.pieceEauRdc);
        this._isPlainPiedVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.plainPied)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.plainPied);
        this._isVuesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsVue)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.vues);

        this._isPartVisible = this.isAccesHandicapeVisible || this.isChambreRdcVisible
            || this.isCommentsAccessibiliteEtEnvironnementVisible || this.isCommoditesVisible
            || this.isPieceEauRdcVisible || this.isPlainPiedVisible;
    }
}
