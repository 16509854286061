import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgCommune} from '@legacy/app/managers/ressources';

export default function getContactEditEntreprise(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact-edit-entreprise></esk-contact-edit-entreprise>
         */
        module.component('eskContactEditEntreprise', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/contacts/components/item/edit/entreprise.html',
            bindings: {contact: '=', editForm: '=',}
        });

        /**
         * Controller to edit contact
         *
         * @param EskUtils
         */
        Controller.$inject = ['EskUtils'];
        function Controller(this: any, eskUtils: NgEskUtils) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectCommune = onSelectCommune;

            /**
             * Initialization method
             */
            function $onInit() {
            }

            /**
             * On commune selection
             *
             * @param commune
             */
            function onSelectCommune(commune: NgCommune) {
                eskUtils.setCodePostalFromWatchingCommune($ctrl.contact.entreprise, commune);
            }
        }
    })(angularJS);
}
