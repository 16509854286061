import {IEstimationIndicator} from '@models/estimations/estimation/indicators/estimation-indicators.interfaces';

export default class EstimationIndicatorsSection {
    private _indicators!: IEstimationIndicator[];
    private _statut!: 'crossed' | 'checked' | 'unchecked';
    private _title!: string;
    private _uuid: string;

    constructor(uuid: string) {
        this._uuid = uuid;
    }

    get indicators(): IEstimationIndicator[] {
        return this._indicators;
    }

    set indicators(value: IEstimationIndicator[]) {
        this._indicators = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get uuid(): string {
        return this._uuid;
    }

    getEnabledIndicators(): IEstimationIndicator[] {
        return this._indicators.filter(indicator => !indicator.disabled);
    }

    isAllIndicatorsDisabled(): boolean {
        return this.getEnabledIndicators().length <= 0;
    }

    isChecked(): boolean {
        return this._statut === 'checked';
    }

    isCrossed(): boolean {
        return this._statut === 'crossed';
    }

    setStatut(selectedIndicators: IEstimationIndicator[]): void {
        if (this.isAllIndicatorsDisabled()) {
            this._statut = 'unchecked';
        } else {
            const enabledIndicators = this.getEnabledIndicators();
            const isSelectedIndicators = enabledIndicators.map(indicator => selectedIndicators.includes(indicator));

            if (isSelectedIndicators.filter(isSelectedIndicator => isSelectedIndicator).length === enabledIndicators.length) {
                this._statut = 'checked';
            } else if (isSelectedIndicators.some(selectedIndicator => selectedIndicator)) {
                this._statut = 'crossed';
            } else {
                this._statut = 'unchecked';
            }
        }
    }

    toggleSelectedIndicators(): IEstimationIndicator[] {
        const selectedIndicators = this.isChecked() ? []: this.getEnabledIndicators();

        this.setStatut(selectedIndicators);

        return selectedIndicators;
    }
}
