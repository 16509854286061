import {inject, Injectable} from '@angular/core';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {
    IEtudeDossierQualifiedContactApi
} from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.interfaces';
import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';

@Injectable({providedIn: 'root'})
export class EtudeDossierQualifiedContactFactory {
    private _contactFactory = inject(ContactFactory);

    create(etudeDossierQualifiedContactApi: IEtudeDossierQualifiedContactApi): EtudeDossierQualifiedContact {
        const etudeDossierQualifiedContact = this.createVirgin();

        etudeDossierQualifiedContact.contact = this._contactFactory.create(etudeDossierQualifiedContactApi.contact);
        etudeDossierQualifiedContact.qualification = etudeDossierQualifiedContactApi.qualification;

        return etudeDossierQualifiedContact;
    }

    createFromContactsGroupMember(contactGroupMember: ContactsGroupMember, qualification: string): EtudeDossierQualifiedContact {
        const qualifiedContact = this.createVirgin();

        qualifiedContact.contact = contactGroupMember.contact;
        qualifiedContact.qualification = qualification;

        return qualifiedContact;
    }

    createVirgin(): EtudeDossierQualifiedContact {
        return new EtudeDossierQualifiedContact();
    }
}
