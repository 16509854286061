import {Injectable} from '@angular/core';
import Photo from '@models/photos/photo/photo.model';
import {NgPhoto} from '@legacy/app/managers/ressources';
import {IPhotoApi} from '@models/photos/photo/photo.interfaces';

@Injectable({providedIn: 'root'})
export class PhotoFactory {
    create(photoApi: IPhotoApi): Photo {
        return new Photo(photoApi.id?.toString(), photoApi.id);
    }

    ngCreate(ngPhoto: NgPhoto): Photo {
        return new Photo(ngPhoto.id?.toString(), ngPhoto.id);
    }
}
