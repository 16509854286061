import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {BiensModule} from '@features/biens/biens.module';
import {VentesModule} from '@features/ventes/ventes.module';
import {
    AppDossierBienOverviewMainInfosComponent
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.component';
import {
    AppDossierBienOverviewComponent
} from '@features/dossiers/dossier/bien/overview/dossier-bien-overview.component';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {
    AppDossierBienOverviewSurfacesComponent
} from '@features/dossiers/dossier/bien/overview/surfaces/dossier-bien-overview.surfaces.component';
import {
    AppDossierBienOverviewLogementsComponent
} from '@features/dossiers/dossier/bien/overview/logements/dossier-bien-overview.logements.component';
import {
    AppDossierBienOverviewInfosComponent
} from '@features/dossiers/dossier/bien/overview/infos/dossier-bien-overview.infos.component';
import {
    AppDossierBienOverviewAgencementInterieurComponent
} from '@features/dossiers/dossier/bien/overview/agencement-interieur/dossier-bien-overview.agencement-interieur.component';
import {
    AppDossierBienOverviewBatimentComponent
} from '@features/dossiers/dossier/bien/overview/batiment/dossier-bien-overview.batiment.component';
import {
    AppDossierBienOverviewPerformanceEnergetiqueComponent
} from '@features/dossiers/dossier/bien/overview/performance-energetique/dossier-bien-overview.performance-energetique.component';
import {
    AppDossierBienOverviewTerrainComponent
} from '@features/dossiers/dossier/bien/overview/terrain/dossier-bien-overview.terrain.component';
import {
    AppDossierBienOverviewAccessibiliteEnvironnementComponent
} from '@features/dossiers/dossier/bien/overview/accessibilite-environnement/dossier-bien-overview.accessibilite-environnement.component';
import {
    AppDossierBienOverviewAgencementExterieurComponent
} from '@features/dossiers/dossier/bien/overview/agencement-exterieur/dossier-bien-overview.agencement-exterieur.component';
import {
    AppDossierBienOverviewViabilisationComponent
} from '@features/dossiers/dossier/bien/overview/viabilisation/dossier-bien-overview.viabilisation.component';
import {
    AppDossierBienOverviewDiagnosticsComponent
} from '@features/dossiers/dossier/bien/overview/diagnostics/dossier-bien-overview.diagnostics.component';
import {
    AppDossierBienOverviewLoiAlurComponent
} from '@features/dossiers/dossier/bien/overview/loi-alur/dossier-bien-overview.loi-alur.component';
import {
    AppDossierBienOverviewHabitationComponent
} from '@features/dossiers/dossier/bien/overview/habitation/dossier-bien-overview.habitation.component';
import {
    AppDossierBienOverviewCoutsComponent
} from '@features/dossiers/dossier/bien/overview/couts/dossier-bien-overview.couts.component';
import {
    AppDossierBienOverviewUrbanismeComponent
} from '@features/dossiers/dossier/bien/overview/urbanisme/dossier-bien-overview.urbanisme.component';
import {
    AppDossierBienOverviewRisquesComponent
} from '@features/dossiers/dossier/bien/overview/risques/dossier-bien-overview.risques.component';
import {
    AppDossierBienOverviewInfosConfidentiellesComponent
} from '@features/dossiers/dossier/bien/overview/infos-confidentielles/dossier-bien-overview.infos-confidentielles.component';
import {RouterModule} from '@angular/router';
import {UsersModule} from '@features/users/users.module';
import {
    AppDossierBienOverviewDossierComponent
} from '@features/dossiers/dossier/bien/overview/dossier/dossier-bien-overview.dossier.component';
import {LocationsModule} from '@features/locations/locations.module';
import {
    AppDossierBienOverviewProprietairesComponent
} from '@features/dossiers/dossier/bien/overview/proprietaires/dossier-bien-overview.proprietaires.component';
import {ContactsModule} from '@features/contacts/contacts.module';
import {
    AppDossierBienOverviewLocalisationComponent
} from '@features/dossiers/dossier/bien/overview/localisation/dossier-bien-overview.localisation.component';
import {
    AppDossierBienOverviewDescriptifsComponent
} from '@features/dossiers/dossier/bien/overview/descriptifs/dossier-bien-overview.descriptifs.component';
import {
    AppDossierBienOverviewArchiveComponent
} from '@features/dossiers/dossier/bien/overview/archive/dossier-bien-overview.archive.component';
import {
    AppDossierBienOverviewCompromisComponent
} from '@features/dossiers/dossier/bien/overview/compromis/dossier-bien-overview.compromis.component';

@NgModule({
    declarations: [
        AppDossierBienOverviewComponent,
        AppDossierBienOverviewAccessibiliteEnvironnementComponent,
        AppDossierBienOverviewAgencementExterieurComponent,
        AppDossierBienOverviewAgencementInterieurComponent,
        AppDossierBienOverviewArchiveComponent,
        AppDossierBienOverviewBatimentComponent,
        AppDossierBienOverviewCompromisComponent,
        AppDossierBienOverviewCoutsComponent,
        AppDossierBienOverviewDescriptifsComponent,
        AppDossierBienOverviewDiagnosticsComponent,
        AppDossierBienOverviewDossierComponent,
        AppDossierBienOverviewHabitationComponent,
        AppDossierBienOverviewInfosComponent,
        AppDossierBienOverviewInfosConfidentiellesComponent,
        AppDossierBienOverviewLocalisationComponent,
        AppDossierBienOverviewLogementsComponent,
        AppDossierBienOverviewLoiAlurComponent,
        AppDossierBienOverviewMainInfosComponent,
        AppDossierBienOverviewPerformanceEnergetiqueComponent,
        AppDossierBienOverviewProprietairesComponent,
        AppDossierBienOverviewRisquesComponent,
        AppDossierBienOverviewSurfacesComponent,
        AppDossierBienOverviewTerrainComponent,
        AppDossierBienOverviewUrbanismeComponent,
        AppDossierBienOverviewViabilisationComponent,
    ],
    exports: [AppDossierBienOverviewComponent],
    imports: [
        BiensModule,
        CommonModule,
        ContactsModule,
        LocationsModule,
        RouterModule,
        SharedModule,
        UsersModule,
        VentesModule,
    ],
    providers: [TextCurrencyPipe],
})
export class DossierBienOverviewModule {
}
