import angularJS from '@shared/angularJS/global.ng';
import {find, findIndex} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {tap} from 'rxjs/operators';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgBienDossier, NgBienPhotoPasserelle} from '@legacy/app/managers/ressources';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getBienModalDiffusionCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BienModalDiffusionCtrl', Controller);

        /**
         * Controller for diffusion modal
         *
         * @param dossier
         * @param diffusion
         * @param config
         * @param Ng2EtudeService
         * @param DictionariesManager
         * @param $q
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['dossier', 'diffusion', 'config', 'Ng2EtudeService', 'DictionariesManager', '$q', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            dossier: NgBienDossier,
                            diffusion: EtudePasserelle,
                            config: unknown,
                            ng2EtudeService: EtudeService,
                            dictionariesManager: NgDictionariesManager,
                            $q: IQService,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;
            let photosNumber = 0;
            let photosLoading = false;

            $ctrl.isDictionaryExisting = isDictionaryExisting;
            $ctrl.photoDiffuse = photoDiffuse;
            $ctrl.dossier = dossier;
            $ctrl.diffusion = diffusion;
            $ctrl.configuration = config;
            $ctrl.configuration.getDescriptifParent = {
                btn: "Reprendre le descriptif général",
                content: $ctrl.dossier.bien.descriptif
            };
            $ctrl.dictionariesManager = dictionariesManager;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            if ($ctrl.configuration.type === 'networks') {
                $ctrl.dossierDiffusion = $ctrl.dossier.passerelles.find(passerelle => passerelle.passerelle.id === $ctrl.diffusion.id);
            } else {
                $ctrl.dossierDiffusion = $ctrl.dossier.passerelles.find(passerelle => passerelle.passerelle.id === $ctrl.diffusion.passerelle.id);
            }
            ng2EtudeService.last$.pipe(tap(currentEtude => $ctrl.currentEtude = currentEtude)).subscribe(currentEtude => {
                ///////////////////////
                // Choix des options //
                ///////////////////////
                $ctrl.tabs = [];
                // Général
                if (['networks', 'passerelle'].includes($ctrl.configuration.type)) {
                    switch ($ctrl.configuration.type) {
                        case 'networks':
                            if (currentEtude.sendingAddressesBiensToConfreres) {
                                $ctrl.tabs.push({code: 'general', label: "Informations générales"});
                            }

                            break;

                        case 'passerelle':
                            if ($ctrl.dossierDiffusion.passerelle.normalizer !== 'immobilier_notaires') {
                                $ctrl.tabs.push({code: 'general', label: "Informations générales"});
                            }

                            break;
                    }
                }

                // Descriptif
                if ($ctrl.configuration.type === 'passerelle') {
                    $ctrl.tabs.push({code: 'descriptif', label: "Descriptif"});
                    switch ($ctrl.diffusion.passerelle.type) {
                        case 'website' :
                            if (angular.isString($ctrl.dossier.bien.descriptifSiteInternet) && $ctrl.dossier.bien.descriptifSiteInternet !== '') {
                                $ctrl.configuration.getDescriptifParent = {
                                    btn: "Reprendre le descriptif des sites Internet",
                                    content: $ctrl.dossier.bien.descriptifSiteInternet
                                };
                            }

                            break;
                        case 'newspaper' :
                            if (angular.isString($ctrl.dossier.bien.descriptifJournalPapier) && $ctrl.dossier.bien.descriptifJournalPapier !== '') {
                                $ctrl.configuration.getDescriptifParent = {
                                    btn: "Reprendre le descriptif des journaux papier",
                                    content: $ctrl.dossier.bien.descriptifJournalPapier
                                };
                            }

                            break;
                    }
                }

                // Photos
                if ($ctrl.dossier.bien.photos.length > 0 && ($ctrl.configuration.type === 'passerelle' && (diffusion.passerelle.photosMaxNumber === null || diffusion.passerelle.photosMaxNumber > 0))) {
                    const visibilitesAllowed = ['publique'];

                    $ctrl.tabs.push({code: 'photos', label: "Photos"});
                    $ctrl.photos = [];
                    angular.forEach($ctrl.dossier.bien.photos, bienPhoto => {
                        const diffusionPhoto = {
                            id: bienPhoto.photo.id,
                            link: bienPhoto._links.file.href,
                            visibilite: bienPhoto.visibilite
                        };

                        if (!visibilitesAllowed.includes(bienPhoto.visibilite)) return;

                        if ($ctrl.configuration.type === 'passerelle') {
                            const dossierDiffusionPhoto = find($ctrl.dossierDiffusion.photos, {photo: {id: bienPhoto.photo.id}});

                            // @ts-ignore
                            diffusionPhoto.diffused = dossierDiffusionPhoto?.diffused;
                            // @ts-ignore
                            diffusionPhoto.diffuse = () => {
                                dossierDiffusionPhoto.diffused = !dossierDiffusionPhoto.diffused;
                                // @ts-ignore
                                diffusionPhoto.diffused = dossierDiffusionPhoto.diffused;

                                return dossierDiffusionPhoto.configure();
                            };
                        }

                        // @ts-ignore
                        if (diffusion.passerelle?.photosMaxNumber > 0 && diffusionPhoto.diffused) {
                            if (photosNumber < diffusion.passerelle.photosMaxNumber) {
                                photosNumber++;
                            } else {
                                // @ts-ignore
                                diffusionPhoto.diffuse();
                            }
                        }

                        $ctrl.photos.push(diffusionPhoto);
                    });
                }

                $ctrl.currentTab = findIndex($ctrl.tabs, {code: $ctrl.configuration.currentTab}) >= 0 ? $ctrl.configuration.currentTab : $ctrl.tabs[0]?.code;
            });

            function isDictionaryExisting(normalizer: string) {
                return ng2DictionaryFactory.getByName(normalizer)?.items?.length > 0;
            }

            /**
             * Diffuse photo
             *
             * @param photoDiffused
             */
            function photoDiffuse(photoDiffused: NgBienPhotoPasserelle) {
                if (photosLoading) {
                    return;
                }

                photosLoading = true;
                photoDiffused.diffuse().then(function () {
                    const promises = [] as IPromise<unknown>[];
                    let photosNumber = photoDiffused.diffused as unknown as number;

                    if (diffusion.passerelle.photosMaxNumber > 0) {
                        angular.forEach($ctrl.photos, function (photo) {
                            if (photoDiffused.id === photo.id || !photo.diffused) {
                                return;
                            }

                            if (photosNumber < diffusion.passerelle.photosMaxNumber) {
                                photosNumber++;
                            } else {
                                promises.push(photo.diffuse());
                            }
                        });
                    }

                    return $q.all(promises);
                }).finally(() => photosLoading = false);
            }
        }
    })(angularJS);
}
