import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {EtudeSettingsNetworkFactory} from '@models/etudes/etude/settings/network/etude-settings-network.factory';
import EtudeSettingsNetwork from '@models/etudes/etude/settings/network/etude-settings-network.model';

@Injectable({providedIn: 'root'})
export class EtudeSettingsNetworkService {
    private _etudeService = inject(EtudeService);
    private _etudeSettingsNetworkFactory = inject(EtudeSettingsNetworkFactory);

    save$(etudeSettingsNetwork: EtudeSettingsNetwork): Observable<EtudeSettingsNetwork> {
        return this._etudeService.last$.pipe(switchMap(currentEtude => this._etudeSettingsNetworkFactory.save$(currentEtude, etudeSettingsNetwork)));
    }
}
