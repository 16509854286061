import {inject, Injectable} from '@angular/core';
import {ToasterService} from '@shared/toaster/toaster.service';
import {ErrorHandlerService} from '@core/error/error.handler.service';
import {HttpRejectionNg, HttpRejectionNgDataErrorsItem} from '@shared/angularJS/http.rejection.ng';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class HttpInterceptor {
    private static IGNORED_STATUS = [304, 401, 500];
    private static IGNORED_ERROR_AUTHENTICATION = ['invalid_grant', 'invalid_request'];
    private static IGNORED_FORBIDDEN_ACTION = ['quota_exceeded', 'inactive_recipients'];
    private static IGNORED_EXCEPTION_FORM = ['person.emailAddress', 'entreprise.emailAddress', 'emailAddress'];
    private static IGNORED_EXCEPTION_MESSAGE = ['A AppBundle\\Model\\Bien\\BienCadastre object already exists with BienId'];
    private _errorHandlerService = inject(ErrorHandlerService);
    private _toasterService = inject(ToasterService);

    ngResponseError(rejection: HttpRejectionNg | undefined): void {
        let captureException = false;
        let toastrMessage: string;

        // Rejection invalide
        if (!rejection) {
            return;
        }

        // Ignorer certains status de réponse
        if (HttpInterceptor.IGNORED_STATUS.includes(rejection.status)) {
            return;
        }

        // Ignorer l'erreur d'authentification
        if (rejection.status === 400 && rejection.data && HttpInterceptor.IGNORED_ERROR_AUTHENTICATION.includes(rejection.data.error)) {
            return;
        }

        // Ignorer l'erreur pour des actions interdites sur l'API
        if (rejection.status === 403 && rejection.data) {
            const explodeDataType = rejection.data.type.split('#');

            if (HttpInterceptor.IGNORED_FORBIDDEN_ACTION.includes(explodeDataType.at(-1)!)) {
                return;
            }
        }

        // Ignorer l'erreur pour certains conflits
        if (rejection.status === 409 && rejection.config && rejection.config.method === 'POST') {
            if ((/(\/client\/users\/)\d+(\/metadatas)/).exec(rejection.config.url)) {
                return;
            }

            if ((/(\/demandeurs\/)\d+(\/recherches)/).exec(rejection.config.url)) {
                return;
            }

            if ((/(\/ventes\/)\d+(\/external\/passerelles\/)\d+/).exec(rejection.config.url)) {
                return;
            }

            if ((/(\/locations\/)\d+(\/external\/passerelles\/)\d+/).exec(rejection.config.url)) {
                return;
            }
        }

        // Lever une exception
        if (rejection.status === 422 && rejection.data && Array.isArray(rejection.data.errors) && rejection.data.errors.length > 0) {
            // Manque d'informations pour une modification d'un bien en vente
            if (rejection.config.method === 'PUT' && rejection.config.url.includes('/ventes/')) {
                return;
            }

            // Média de la photo uploadée pas du bon type
            if (rejection.config.method === 'POST' && rejection.config.url.endsWith('/photos') && rejection.config.data && (rejection.config.data.mediaId as number) > 0) {
                return;
            }

            rejection.data.errors.forEach((error: HttpRejectionNgDataErrorsItem) => {
                if (error.property_path === '') {
                    if (!HttpInterceptor.IGNORED_EXCEPTION_MESSAGE.some(message => error.message.includes(message))) {
                        captureException = true;
                    }
                } else {
                    if (!HttpInterceptor.IGNORED_EXCEPTION_FORM.includes(error.property_path)) {
                        captureException = true;
                    }
                }
            });
        } else {
            captureException = true;
        }

        if (captureException) {
            this._errorHandlerService.catchError(new HttpErrorResponse({
                error: rejection,
                status: rejection.status,
                statusText: rejection.statusText,
            }));
        }

        if (rejection.status === 422) {
            toastrMessage = 'Valeur : "' + rejection.data.errors[0].property_path + '"<br>' + rejection.data.errors[0].message;
        } else {
            toastrMessage = 'Une erreur est survenue pendant le traitement de votre demande. Merci de réessayer. L\'erreur a été remontée à notre service technique.';
        }

        this._toasterService.error('Erreur de l\'application', toastrMessage, {enableHtml: true});

        return;
    }
}
