import {NgModule} from '@angular/core';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {TextNl2brPipe} from '@shared/texts/nl2br/text-nl2br.pipe';
import {TextPhonesComponent} from '@shared/texts/phones/text-phones.component';
import {CommonModule} from '@angular/common';
import {TextPhoneComponent} from '@shared/texts/phones/phone/text-phone.component';
import {FaModule} from '@shared/fontawesome/fa.module';
import {TextSizePipe} from '@shared/texts/size/text-size.pipe';

// @todo Uniformiser le nommage des composants et module, "Text*" ou "Texts*"
@NgModule({
    exports: [TextCurrencyPipe, TextNl2brPipe, TextPhoneComponent, TextPhonesComponent, TextSizePipe],
    imports: [
        CommonModule,
        FaModule,
        TextCurrencyPipe,
        TextNl2brPipe,
        TextPhoneComponent,
        TextPhonesComponent,
        TextSizePipe,
    ],
})
export class TextsModule {
}
