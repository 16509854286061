import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ICQuartiersApi} from '@models/quartiers/collection/quartiers.collection.interfaces';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class QuartiersApiService {
    private _modelApiService = inject(ModelApiService);

    getCollectionByLink$(link: string): Observable<ICQuartiersApi> {
        return this._modelApiService.getCollection$(link);
    }

    getCollectionFromCommune$(communeUuid: string, queryParameters?: IQueryParameters): Observable<ICQuartiersApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/communes/' + communeUuid + '/quartiers', queryParameters));
    }
}
