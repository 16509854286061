@if (procedure$ | async; as procedure) {
  <div class="tw-h-full">
    @if (procedure.isNewOrDraft() || procedure.isDistanciel()) {
      <form class="tw-pb-8" (submit)="save(signatairesButtonSubmit)" #signatairesForm="ngForm">
        <app-procedure-signataires-edit [cSignataires$]="cProcedureSignataires$" [currentDossier]="currentDossier"
                                        (editedSignataires)="editedSignataires = $event"
                                        (deletedSignataires)="deletedSignataires = $event"
                                        [options]="procedureSignatairesEditOptions" [procedure]="procedure"/>

        <button type="submit" class="tw-float-left" #signatairesButtonSubmit></button>
      </form>
    } @else {
      <app-procedure-presentiel-sign [cSignataires$]="cProcedureSignataires$" [procedure]="procedure"
                                     (signed)="signed()"/>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mb-8"/>
}
