export default `
<div class="my-account">
  <div class="container-fluid container-fluid-title">
    <h1>Mon compte</h1>

    <div class="pull-right margin-top-5">
      <button ng-click="$ctrl.seeMyProfile()" type="button" class="btn btn-azure btn-o">
        <fa-icon [icon]="'user'"></fa-icon> Voir mon profil
      </button>
    </div>
  </div>

  <div class="container-fluid container-fullw tw-bg-white">
    <div class="noty-container">
      <fieldset>
        <legend>Mes informations</legend>

        <form name="$ctrl.editForm" ng-submit="$ctrl.saveChange()" novalidate>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group"
                   ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.userTitre.$invalid}">
                <label>Civilité</label>

                <esk-select-dictionary dictionary="{{$ctrl.TITRES}}" ng-model="$ctrl.titre" on-select="$ctrl.onSelectTitre()"
                                       name="userTitre" is-required="true"></esk-select-dictionary>

                <div class="help-block" ng-messages="$ctrl.editForm.userTitre.$error"
                     ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group"
                   ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.userNom.$invalid}">
                <label for="edit-contact-user-nom">Nom *</label>

                <input type="text" name="userNom" id="edit-contact-user-nom" class="form-control" required
                       ng-model="$ctrl.currentUser.nom">

                <div class="help-block" ng-messages="$ctrl.editForm.userNom.$error"
                     ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group"
                   ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.userPrenom.$invalid}">
                <label for="edit-contact-user-prenom">Prénom *</label>

                <input type="text" name="userPrenom" id="edit-contact-user-prenom" class="form-control" required
                       ng-model="$ctrl.currentUser.prenom">

                <div class="help-block" ng-messages="$ctrl.editForm.userPrenom.$error"
                     ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group"
                   ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.phoneFixe.numero && !$ctrl.ng2PhoneNumberService.isValid($ctrl.phoneFixe.numero)}">
                <label for="edit-contact-user-phone">Téléphone</label>

                <input type="text" id="edit-contact-user-phone" class="form-control" ng-model="$ctrl.phoneFixe.numero">

                <div class="error text-small tw-block" ng-messages="{pattern: true}"
                     ng-if="$ctrl.editForm.$submitted && $ctrl.phoneFixe.numero && !$ctrl.ng2PhoneNumberService.isValid($ctrl.phoneFixe.numero)">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group"
                   ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.phoneMobile.numero && !$ctrl.ng2PhoneNumberService.isValid($ctrl.phoneMobile.numero)}">
                <label for="edit-contact-user-mobile">Mobile</label>

                <input type="text" id="edit-contact-user-mobile" class="form-control"
                       ng-model="$ctrl.phoneMobile.numero">

                <div class="error text-small tw-block" ng-messages="{pattern: true}"
                     ng-if="$ctrl.editForm.$submitted && $ctrl.phoneMobile.numero && !$ctrl.ng2PhoneNumberService.isValid($ctrl.phoneMobile.numero)">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </div>
              </div>
            </div>

            <div class="col-sm-4 text-right padding-top-20">
              <button ng-switch="!!$ctrl.saving" class="btn btn-primary" type="submit">
                <span ng-switch-when="true">
                  <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
                </span>

                <span ng-switch-default><fa-icon [icon]="'check'"></fa-icon> Enregistrer</span>
              </button>
            </div>
          </div>
        </form>

        <div class="padding-top-20 text-right">
          <button type="button" class="tw-btn-secondary-info" ng-click="$ctrl.changePassword()">
            <fa-icon [icon]="'lock'"></fa-icon><span>Changer de mot de passe</span>
          </button>
        </div>
      </fieldset>

      <fieldset>
        <legend>Autres informations</legend>

        <div>
          <span class="margin-right-15">
            <fa-icon [icon]="['far', 'building']"></fa-icon>

            {{$ctrl.currentUser.site.adresse}}

            {{$ctrl.currentUser.site.commune.fullname}}

            ({{$ctrl.currentUser.site.codePostal || $ctrl.currentUser.site.commune.codesPostaux[0]}})
          </span>

          -

          <span class="margin-left-15">
            <fa-icon [icon]="['far', 'envelope']"></fa-icon> {{$ctrl.currentUser.emailAddress}}
          </span>
        </div>

        <div class="margin-top-10">
          <esk-contact></esk-contact>
          <span>pour toutes modifications de ces informations.</span>
        </div>
      </fieldset>

      <fieldset>
        <legend>Photo de profil</legend>
        <div class="row">
          <div class="col-sm-2 margin-bottom-10">
            <h5>
              Photo actuelle

              <span ng-show="$ctrl.currentUser.isAvatarPhoto" class="text-extra-small">(Par défaut)</span>
            </h5>

            <div class="img-container">
              <div ng-switch="$ctrl.deletingPhoto" ng-if="!$ctrl.currentUser.isAvatarPhoto" class="img-tools toolbar">
                <span ng-switch-when="true">
                  <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.REMOVE' | translate}}
                </span>

                <span ng-switch-default ng-click="$ctrl.editPhoto()" title="Modifier la photo">
                  <fa-icon [icon]="'pencil-alt'"></fa-icon>
                </span>

                <span ng-switch-default ng-click="$ctrl.removePhoto()" title="Supprimer la photo">
                  <fa-icon [icon]="['far', 'file']"></fa-icon>
                </span>
              </div>

              <img ng-src="{{$ctrl.currentUser.linkPhotoThumbnail}}" class="img-responsive" loading="lazy">
            </div>
          </div>

          <div class="files-infos-process col-sm-10">
            <esk-upload-files-info type-file="photo" info-client-filter="$ctrl.infoClientFilter"
                                   update-tasks="$ctrl.updateTasks" update-tasks-errors="$ctrl.updateTasksErrors">
            </esk-upload-files-info>

            <div ng-if="!$ctrl.task">
              <h5>
                Envoyer une nouvelle photo
                <a uib-popover-template="'src/app/legacy/templates/eskimmo/controllers/my-account/photo.help.html'"
                   popover-trigger="'outsideClick'" popover-placement="bottom">
                  <span class="badge badge-info">?</span>
                </a>
              </h5>

              <soq-input-photos get-input-photo="$ctrl.getInputPhoto" options="{multiple: false}"></soq-input-photos>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset ng-if="$ctrl.currentUser.hasRoleGrpLocation() || $ctrl.currentUser.hasRoleGrpVente()"
                class="account-diffusions">
        <legend>Visibilité de mes coordonnées</legend>

        <app-loader ng-if="$ctrl.settingsDiffusionsLoader" [classHeight]="'tw-h-40'"></app-loader>

        <div ng-if="!$ctrl.settingsDiffusionsLoader">
          <div class="form-group">
            <label>Quel numéro voir en priorité sur les diffusions ?</label>

            <div class="radio clip-radio radio-primary radio-success diffusions-diffusion"
                 ng-repeat="diffusionsPhoneSource in $ctrl.diffusionsPhoneSources">
              <input type="radio" id="phone{{diffusionsPhoneSource.id}}" ng-value="diffusionsPhoneSource.code"
                     ng-model="$ctrl.userSettingsDiffusionsTelephoneSourceCode"
                     ng-change="$ctrl.saveDiffusions(diffusionsPhoneSource)">

              <label for="phone{{diffusionsPhoneSource.id}}">{{diffusionsPhoneSource.label}}</label>

              <span ng-if="diffusionsPhoneSource.code !== $ctrl.phoneSources.AUTRE" class="diffusion-short-label">
                {{$ctrl.getSettingsDiffusionsShortLabel(diffusionsPhoneSource)}}
              </span>

              <div ng-class="{'has-error': !$ctrl.userSettings.diffusions.telephoneAutre}" class="diffusion-autre"
                   ng-if="diffusionsPhoneSource.code === $ctrl.phoneSources.AUTRE && $ctrl.userSettingsDiffusionsTelephoneSourceCode === $ctrl.phoneSources.AUTRE">
                <input type="text" class="form-control" ng-model="$ctrl.userSettings.diffusions.telephoneAutre"
                       ng-change="$ctrl.saveDiffusions(diffusionsPhoneSource)">

                <div class="error tw-block" ng-messages="{required: true}"
                     ng-if="!$ctrl.userSettings.diffusions.telephoneAutre">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="account-dashboard">
        <legend>Mon tableau de bord</legend>

        <esk-configuration-dashboard-ctrl></esk-configuration-dashboard-ctrl>
      </fieldset>
    </div>
  </div>
</div>
`;
