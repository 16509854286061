import {Injectable} from '@angular/core';
import {IVenteMatchingApi} from '@models/ventes/vente/matchings/vente-matching/vente-matching.interfaces';
import VenteMatching from '@models/ventes/vente/matchings/vente-matching/vente-matching.model';

@Injectable({providedIn: 'root'})
export class VenteMatchingFactory {
    create(venteMatchingApi: IVenteMatchingApi): VenteMatching {
        const venteMatching = this.createVirgin();

        venteMatching.numberDemandeurs = venteMatchingApi.numberDemandeurs;
        if (venteMatchingApi._links?.etude) {
            venteMatching.linkEtude = venteMatchingApi._links.etude.href;
        }

        return venteMatching;
    }

    createVirgin(): VenteMatching {
        return new VenteMatching();
    }

    createVirginForEtude(linkEtude: string): VenteMatching {
        const venteMatching = this.createVirgin();

        venteMatching.linkEtude = linkEtude;

        return venteMatching;
    }
}
