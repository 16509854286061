import AModel from '@models/model.abstract';

export default class LocationLocataireConstat extends AModel {
    private _comments!: string;
    private _id: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get id(): number {
        return this._id;
    }
}
