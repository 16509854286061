import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgCommuneManager, NgDictionariesManager} from '@legacy/app/managers/managers';
import {UserService} from '@models/users/user/user.service';
import {firstValueFrom, switchMap} from 'rxjs';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import {IVentePriceQueryParameters} from '@models/ventes/vente/price/vente-price.interfaces';
import {map, take} from "rxjs/operators";
import {DocumentService} from '@models/documents/document/document.service';
import {IDocumentVariables} from '@models/documents/document/document.interfaces';
import Vente from '@models/ventes/vente/vente.model';
import {CTemplatesFactory} from '@models/templates/collection/templates.collection.factory';

export default function getEskModalCalculatorCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EskModalCalculatorCtrl', Controller);

        /**
         * Controller for archive modal
         *
         * @param DictionariesManager
         * @param $q
         * @param Ng2UserService
         * @param CommuneManager
         * @param Ng2DictionaryFactory
         * @param Ng2VentePriceFactory
         * @param Ng2DocumentService
         * @param Ng2CTemplatesFactory
         */
        Controller.$inject = ['DictionariesManager', '$q', 'Ng2UserService', 'CommuneManager', 'Ng2DictionaryFactory', 'Ng2VentePriceFactory', 'Ng2DocumentService', 'Ng2CTemplatesFactory'];
        function Controller(this: any,
                            dictionariesManager: NgDictionariesManager,
                            $q: IQService,
                            ng2UserService: UserService,
                            communeManager: NgCommuneManager,
                            ng2DictionaryFactory: DictionaryFactory,
                            ng2VentePriceFactory: VentePriceFactory,
                            ng2DocumentService: DocumentService,
                            ng2CTemplatesFactory: CTemplatesFactory) {
            const $ctrl = this;
            let operateOptions: string;

            $ctrl.operate = operate;
            $ctrl.print = print;
            $ctrl.loader = {};
            $ctrl.NATURES = Dictionary.names.NATURES;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.VIAGER = Vente.types.VIAGER;
            $ctrl.typesVente = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.VENTE_TYPES));
            ng2UserService.getCurrentCommune$().pipe(take(1)).subscribe(commune => {
                ng2UserService.last$.subscribe(currentUser => $ctrl.input = {
                    bouquet: 0,
                    commune: communeManager.createFromNg2(commune),
                    nature: currentUser.settings.ventes.defaultNature,
                    price: 0,
                    typeVente: $ctrl.typesVente[0],
                });
            });

            /**
             * Operate pricing
             */
            function operate() {
                const promises: IPromise<unknown>[] = [];
                let generalOptions: Record<string, unknown>;

                if ($ctrl.input.price <= 0 || !angular.isObject($ctrl.input.commune) || !angular.isObject($ctrl.input.nature) || !angular.isObject($ctrl.input.typeVente) || ($ctrl.input.typeVente.code === Vente.types.VIAGER && $ctrl.input.bouquet <= 0)) {
                    $ctrl.pricings = undefined;
                    return;
                }

                if ($ctrl.input.price > VentePriceFactory.MAX_PRICE) {
                    $ctrl.input.price = VentePriceFactory.MAX_PRICE;
                }

                if ($ctrl.input.bouquet > VentePriceFactory.MAX_PRICE) {
                    $ctrl.input.bouquet = VentePriceFactory.MAX_PRICE;
                }

                const ventePriceQueryParameters = {
                    codeNature: $ctrl.input.nature.code,
                    communeId: $ctrl.input.commune.id,
                } as IVentePriceQueryParameters;

                $ctrl.loader.operate = true;
                if ($ctrl.input.typeVente.code === Vente.types.VIAGER) {
                    generalOptions = {valeurBien: $ctrl.input.price, bouquet: $ctrl.input.bouquet};
                    ventePriceQueryParameters.bouquet = $ctrl.input.bouquet;
                    ventePriceQueryParameters.valeurBien = $ctrl.input.price;
                } else if ($ctrl.input.typeVente.code === Vente.types.INTERACTIVE) {
                    generalOptions = {prixReserve: $ctrl.input.price};
                    ventePriceQueryParameters.prixReserve = $ctrl.input.price;
                } else {
                    generalOptions = {prix: $ctrl.input.price};
                    ventePriceQueryParameters.prix = $ctrl.input.price;
                }
                generalOptions.codeNature = $ctrl.input.nature.code;
                generalOptions.communeId = $ctrl.input.commune.id;
                operateOptions = JSON.stringify(generalOptions);
                ng2DictionaryFactory.getByName(Dictionary.names.VENTE_PRIX_CHARGES_TYPES).items.forEach(chargeType => {
                    ventePriceQueryParameters.typePrix = chargeType.code;
                    promises.push(firstValueFrom(ng2VentePriceFactory.get$($ctrl.input.typeVente.code, ventePriceQueryParameters).pipe(
                        map(ventePrice => ({
                            fraisActe: ventePrice.fraisActe,
                            honoraires: ventePrice.honoraires,
                            pourcentageHonoraires: ventePrice.pourcentageHonoraires,
                            bouquetNV: ventePrice.priceNV,
                            bouquetHNI: ventePrice.priceHNI,
                            bouquetTFC: ventePrice.priceTFC,
                            prixNV: ventePrice.priceNV,
                            prixHNI: ventePrice.priceHNI,
                            prixTFC: ventePrice.priceTFC,
                            prixReserveNV: ventePrice.priceNV,
                            prixReserveHNI: ventePrice.priceHNI,
                            prixReserveTFC: ventePrice.priceTFC,
                        }))
                    )).then(infosPricing => ({
                        infosPricing,
                        typeCharges: dictionariesManager.createFromNg2(chargeType),
                    })));
                });
                $q.all(promises).then(pricings => {
                    delete generalOptions.typePrix;
                    if (JSON.stringify(generalOptions) === operateOptions) {
                        $ctrl.pricings = pricings;
                    }
                }).finally(() => {
                    if (JSON.stringify(generalOptions) === operateOptions) {
                        $ctrl.loader.operate = false;
                    }
                });
            }

            /**
             * Permet de faire télécharger la calculatrice en format PDF
             */
            function print() {
                const variables: IDocumentVariables = {
                    codeNature: $ctrl.input.nature.code,
                    communeId: $ctrl.input.commune.id
                };
                let code: string;

                if ($ctrl.input.typeVente.code === Vente.types.VIAGER) {
                    code = 'frais-acquisition-vente-viager';
                    variables.valeurBien = $ctrl.input.price;
                    variables.bouquet = $ctrl.input.bouquet;
                } else if ($ctrl.input.typeVente.code === Vente.types.INTERACTIVE) {
                    code = 'frais-acquisition-vente-interactive';
                    variables.prixReserve = $ctrl.input.price;
                } else {
                    code = 'frais-acquisition-vente-traditionelle';
                    variables.prix = $ctrl.input.price;
                }

                $ctrl.loader.print = true;
                ng2CTemplatesFactory.get$({categoryCodes: [code]}).pipe(
                    map(templatesCollection => templatesCollection.results[0]),
                    switchMap(template => ng2DocumentService.readFromFraisAcquisition$(template, variables)),
                    take(1),
                ).subscribe({complete: () => $ctrl.loader.print = false});
            }
        }
    })(angularJS);
}
