import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-eskimmo-dashboard', template: '<div appNgEskimmoDashboard></div>'})
export class NgEskimmoDashboardComponent {
}

@Directive({selector: '[appNgEskimmoDashboard]'})
export class NgEskimmoDashboardDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDashboardCtrl', elementRef, injector);
    }
}
