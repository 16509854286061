export default `
<form name="$ctrl.editForm" ng-submit="$close($ctrl.pageSelected)" novalidate>
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans sélectionner de pages">
      <fa-icon [icon]="'times'"></fa-icon>
    </button>

    <h3 class="modal-title">Pages Facebook</h3>
  </div>

  <div class="modal-body">
    <div class="radio clip-radio radio-primary" ng-repeat="fbPage in $ctrl.fbPages">
      <input type="radio" id="fb-page{{fbPage.id}}" name="fbPage" ng-value="fbPage" ng-model="$ctrl.pageSelected">

      <label for="fb-page{{fbPage.id}}">{{fbPage.name}}</label>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary"><fa-icon [icon]="'check'"></fa-icon> Sélectionner</button>
  </div>
</form>
`;
