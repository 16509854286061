import {inject, Injectable} from '@angular/core';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class DictionaryService {
    private _dictionaryFactory = inject(DictionaryFactory);

    update$(name: string): Observable<boolean> {
        const dictionaryStored = this._dictionaryFactory.getByName(name);
        const httpOptions: HttpOptions = {headers: {handledStatusErrors: [304]}};

        if (dictionaryStored.etag) {
            httpOptions.headers!['If-None-Match'] = dictionaryStored.etag;
        }

        return this._dictionaryFactory.get$(name, httpOptions).pipe(
            tap(dictionary => this._dictionaryFactory.saveInStorage(dictionary)),
            map(_ => true),
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse?.status === 304) {
                    return of(false);
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }
}
