import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgEtudeStatisticsManager} from '@legacy/app/managers/managers';
import DateFormat from '@shared/date/date.format';

export default function getEskBoxActivity(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-box-activity></esk-box-activity>
         */
        module.run(template).component('eskBoxActivity', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/box/activity.html'
        });

        /**
         * Display biens activity
         *
         * @param EtudeStatisticsManager
         * @param EskBox
         * @constructor
         */
        Controller.$inject = ['EtudeStatisticsManager', 'EskBox'];
        function Controller(this: any, etudeStatisticsManager: NgEtudeStatisticsManager, eskBox: NgEskBox) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.box = eskBox.getOneByCode('etude-activity');
            $ctrl.currentYear = DateFormat.getYear(DateFormat.dateFromNow());

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.loading = true;

                etudeStatisticsManager.getEtudeActivity().then(function (statistics) {
                    const currentMonth = DateFormat.dateFromDate(DateFormat.toDate(DateFormat.dateFromNow()), {withDay: false});

                    $ctrl.statsActivity = {
                        data: [
                            {
                                label: "Nouveaux mandats",
                                year: statistics.data[$ctrl.currentYear].ventesMandats,
                                month: statistics.data[currentMonth].ventesMandats
                            },
                            {
                                label: "Nouveaux compromis",
                                year: statistics.data[$ctrl.currentYear].ventesCompromis,
                                month: statistics.data[currentMonth].ventesCompromis
                            },
                            {
                                label: "Estimations remises",
                                year: statistics.data[$ctrl.currentYear].estimationsRemises,
                                month: statistics.data[currentMonth].estimationsRemises
                            },
                            {
                                label: "Estimations passées en mandat",
                                year: statistics.data[$ctrl.currentYear].estimationsMandats,
                                month: statistics.data[currentMonth].estimationsMandats
                            },
                        ],
                        date: DateFormat.toDate(statistics.date),
                    };
                }).finally(() => $ctrl.loading = false);
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/activity/container.html', '<esk-box-activity></esk-box-activity>');
        }
    })(angularJS);
}
