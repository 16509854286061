import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IMeteoApi, IMeteoStorage} from '@core/meteo/meteo.interfaces';
import {UrlService} from '@shared/texts/url/url.service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '@core/api/api.service';
import {AuthService} from '@core/auth/core/auth.service';
import {LocalStorageService} from '@core/storage/local-storage.service';
import environment from '@env/environment';

@Injectable({providedIn: 'root'})
export class MeteoApiService {
    static readonly STORAGE_KEY = 'meteo';
    private _apiService = inject(ApiService);
    private _authService = inject(AuthService);
    private _localStorageService = inject(LocalStorageService);
    private _httpClient = inject(HttpClient);
    private _urlService = inject(UrlService);

    constructor() {
        this._apiService.addExcludedUrl(environment.meteo.url);
        this._authService.addExcludedUrl(environment.meteo.url);
    }

    get$(latitude: number, longitude: number): Observable<IMeteoApi> {
        const queryParameters: Record<string, unknown> = {
            lat: latitude,
            lon: longitude,
            mode: 'json',
            units: 'metric',
            lang: 'fr',
            APPID: environment.meteo.appId,
        };
        const completePath = this._urlService.pathWithQueryParameters(environment.meteo.url + '/data/2.5/weather', queryParameters);

        return this._httpClient.get<IMeteoApi>(completePath);
    }

    getFromStorage(): IMeteoStorage {
        return this._localStorageService.get<IMeteoStorage>(MeteoApiService.STORAGE_KEY);
    }

    saveInStorage(meteoStorage: IMeteoStorage): void {
        this._localStorageService.set(MeteoApiService.STORAGE_KEY, meteoStorage);
    }
}
