import angularJS from '@shared/angularJS/global.ng';
import {IAttributes, IDirectiveFactory, IModule, Injectable, IScope} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';
import Bien from '@models/bien/bien.model';
import {DiagnosticLettersConst} from '@shared/diagnostic/diagnostic.constants';

export default function getSoqDiagnostics(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-pe></soq-pe>
         * @example <soq-ges></soq-ges>
         */
        module.directive('soqPe', directivePe as Injectable<IDirectiveFactory>).component('soqCeUpgrade', {
            bindings: {nature: '=', value: '='},
            controller: [function (this: any) {
                const $ctrl = this;

                $ctrl.$onInit = function () {
                    $ctrl.format = [Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE].includes($ctrl.nature.code) ? 'extend' : null
                };
            }],
            template: '<soq-pe format="{{$ctrl.format}}" level="$ctrl.value"></soq-pe>'
        }).directive('soqGes', directiveGes as Injectable<IDirectiveFactory>).component('soqGesUpgrade', {
            bindings: {nature: '=', value: '='},
            controller: [function (this: any) {
                const $ctrl = this;

                $ctrl.$onInit = function () {
                    $ctrl.format = [Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE].includes($ctrl.nature.code) ? 'extend' : null
                };
            }],
            template: '<soq-ges format="{{$ctrl.format}}" level="$ctrl.value"></soq-ges>'
        });

        /**
         * Display DPE graph
         *
         * @returns {{restrict: string, link: Function}}
         */
        directivePe.$inject = [] as string[];
        function directivePe() {
            return {restrict: 'E', link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes) {
                $scope.$watch($attrs.level, (newValue: any) => {
                    $element.children().remove();
                    if (newValue !== null && newValue !== '' && !isNaN(newValue)) {
                        $element.append(SVGHelper.createSVG(newValue, $attrs, 'dpe')).addClass('diagnostic');
                    } else {
                        $element.append('');
                    }
                });
            }
        }

        /**
         * Display GES graph
         *
         * @returns {{restrict: string, link: Function}}
         */
        directiveGes.$inject = [] as string[];
        function directiveGes() {
            return {restrict: 'E', link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes) {
                $scope.$watch($attrs.level, (newValue: any) => {
                    $element.children().remove();
                    if (newValue !== null && newValue !== '' && !isNaN(newValue)) {
                        $element.append(SVGHelper.createSVG(newValue, $attrs, 'ges')).addClass('diagnostic');
                    } else {
                        $element.append('');
                    }
                });
            }
        }

        const SVGHelper = {
            data: {
                dpe: {
                    default: [
                        {letter: DiagnosticLettersConst.A, min: 0, max: 50, libelle: '≤ 50', color: '#319834'},
                        {letter: DiagnosticLettersConst.B, min: 51, max: 90, libelle: '51 à 90', color: '#33cc31'},
                        {letter: DiagnosticLettersConst.C, min: 91, max: 150, libelle: '91 à 150', color: '#cbfc34'},
                        {letter: DiagnosticLettersConst.D, min: 151, max: 230, libelle: '151 à 230', color: '#fbfe06'},
                        {letter: DiagnosticLettersConst.E, min: 231, max: 330, libelle: '231 à 330', color: '#fbcc05'},
                        {letter: DiagnosticLettersConst.F, min: 331, max: 450, libelle: '331 à 450', color: '#fc9935'},
                        {letter: DiagnosticLettersConst.G, min: 451, max: null, libelle: '> 450', color: '#fc0205'},
                    ],
                    extend: [
                        {letter: DiagnosticLettersConst.A, min: 0, max: 50, libelle: '≤ 50', color: '#319834'},
                        {letter: DiagnosticLettersConst.B, min: 51, max: 110, libelle: '51 à 110', color: '#33cc31'},
                        {letter: DiagnosticLettersConst.C, min: 111, max: 210, libelle: '111 à 210', color: '#cbfc34'},
                        {letter: DiagnosticLettersConst.D, min: 211, max: 350, libelle: '211 à 350', color: '#fbfe06'},
                        {letter: DiagnosticLettersConst.E, min: 351, max: 540, libelle: '351 à 540', color: '#fbcc05'},
                        {letter: DiagnosticLettersConst.F, min: 541, max: 750, libelle: '541 à 750', color: '#fc9935'},
                        {letter: DiagnosticLettersConst.G, min: 751, max: null, libelle: '> 751', color: '#fc0205'},
                    ],
                },
                ges: {
                    default: [
                        {letter: DiagnosticLettersConst.A, min: 0, max: 5, libelle: '≤ 5', color: '#f2eff4'},
                        {letter: DiagnosticLettersConst.B, min: 6, max: 10, libelle: '6 à 10', color: '#dfc1f7'},
                        {letter: DiagnosticLettersConst.C, min: 11, max: 20, libelle: '11 à 20', color: '#d6aaf4'},
                        {letter: DiagnosticLettersConst.D, min: 21, max: 35, libelle: '21 à 35', color: '#cc93f4'},
                        {letter: DiagnosticLettersConst.E, min: 36, max: 55, libelle: '36 à 55', color: '#bb72f3'},
                        {letter: DiagnosticLettersConst.F, min: 56, max: 80, libelle: '56 à 80', color: '#a94cee'},
                        {letter: DiagnosticLettersConst.G, min: 81, max: null, libelle: '> 80', color: '#8b1ae1'},
                    ],
                    extend: [
                        {letter: DiagnosticLettersConst.A, min: 0, max: 5, libelle: '≤ 5', color: '#f2eff4'},
                        {letter: DiagnosticLettersConst.B, min: 6, max: 15, libelle: '6 à 15', color: '#dfc1f7'},
                        {letter: DiagnosticLettersConst.C, min: 16, max: 30, libelle: '16 à 30', color: '#d6aaf4'},
                        {letter: DiagnosticLettersConst.D, min: 31, max: 60, libelle: '31 à 60', color: '#cc93f4'},
                        {letter: DiagnosticLettersConst.E, min: 61, max: 100, libelle: '61 à 100', color: '#bb72f3'},
                        {letter: DiagnosticLettersConst.F, min: 101, max: 145, libelle: '101 à 145', color: '#a94cee'},
                        {letter: DiagnosticLettersConst.G, min: 146, max: null, libelle: '> 146', color: '#8b1ae1'},
                    ],
                },
            },
            setAttributes: function (elem: SVGPathElement, options: any) {
                if (options.fill) {
                    elem.setAttribute('fill', options.fill);
                }
                if (options.fontSize) {
                    elem.setAttribute('font-size', options.fontSize);
                }
                if (options.fontFamily) {
                    elem.setAttribute('font-family', options.fontFamily);
                }
                if (options.textAnchor) {
                    elem.setAttribute('text-anchor', options.textAnchor);
                }
                if (options.fontWeight) {
                    elem.setAttribute('font-weight', options.fontWeight);
                }
                if (options.strokeWidth) {
                    elem.setAttribute('stroke-width', options.strokeWidth);
                }
                if (options.stroke) {
                    elem.setAttribute('stroke', options.stroke);
                }
                if (options.opacity) {
                    elem.setAttribute('opacity', options.opacity);
                }
            },
            getText: function (text: any) {
                const elem: SVGPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'text') as unknown as SVGPathElement;

                elem.setAttribute('x', text.x);
                elem.setAttribute('y', text.y);
                elem.textContent = text.text;
                this.setAttributes(elem, text.options);

                return elem;
            },
            getPath: function (path: string, options: any) {
                const elem = document.createElementNS('http://www.w3.org/2000/svg', 'path');

                this.setAttributes(elem, options);
                elem.setAttribute('d', path);

                return elem;
            },
            getPolygon: function (points: string, color: string, shadow: boolean) {
                const elem = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');

                elem.setAttribute('style', 'fill-opacity: 1;fill: ' + color + ';stroke: #000000;' + (shadow ? 'filter:url(#fs)' : ''));
                elem.setAttribute('points', points);

                return elem;
            },
            createSVG: function (val: string, attrs: any, type: string) {
                const self = {width: 250, height: 200, pad: 5};
                const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                let blocWidth: number;
                let whiteIdx: number;
                let data: any;

                svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
                svg.setAttribute('version', '1.1');
                svg.setAttribute('style', 'overflow: hidden; position: relative;');
                svg.setAttribute('width', '100%');
                svg.setAttribute('height', '100%');
                svg.setAttribute('viewBox', '0 0 250 200');

                if (attrs.shadow === 'true') {
                    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
                    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
                    const feGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', 'feGaussianBlur');
                    const feOffset = document.createElementNS('http://www.w3.org/2000/svg', 'feOffset');
                    const feMerge = document.createElementNS('http://www.w3.org/2000/svg', 'feMerge');
                    const feMergeNode1 = document.createElementNS('http://www.w3.org/2000/svg', 'feMergeNode');
                    const feMergeNode2 = document.createElementNS('http://www.w3.org/2000/svg', 'feMergeNode');

                    filter.setAttribute('id', 'fs');
                    filter.setAttribute('height', '120%');

                    feGaussianBlur.setAttribute('in', 'SourceAlpha');
                    feGaussianBlur.setAttribute('stdDeviation', '3');
                    filter.appendChild(feGaussianBlur);

                    feOffset.setAttribute('result', 'offsetblur');
                    feOffset.setAttribute('dx', '2');
                    feOffset.setAttribute('dy', '2');
                    filter.appendChild(feOffset);

                    feMerge.appendChild(feMergeNode1);
                    feMergeNode2.setAttribute('in', 'SourceGraphic');
                    feMerge.appendChild(feMergeNode2);
                    filter.appendChild(feMerge);
                    defs.appendChild(filter);
                    svg.appendChild(defs);
                }

                const height = 7;
                const blocHeight = (self.height - ((height + 1) * self.pad)) / height;

                blocWidth = (self.width - (2 * self.pad)) - 45;

                const blocPart = ((blocWidth / 3) * 2) / 6;

                blocWidth = (blocWidth / 3);

                if (type === 'dpe') {
                    data = (attrs.format === 'extend') ? SVGHelper.data.dpe.extend : SVGHelper.data.dpe.default;
                    whiteIdx = 5;
                } else {
                    data = (attrs.format === 'extend') ? SVGHelper.data.ges.extend : SVGHelper.data.ges.default;
                    whiteIdx = 3;
                }

                for (let i = 0; i < data.length; i++) {
                    let poly: string = undefined!;
                    let x: number;
                    let x1 = 0;
                    let x2: number;
                    let y: number;
                    let y1: number;
                    let y2: number;

                    x = 5;
                    y = ((i * self.pad) + self.pad) + (i * blocHeight);
                    switch (type) {
                        case 'dpe':
                            x1 = ((blocWidth + (blocPart * i)) - (blocHeight / 2));
                            y1 = y + (blocHeight / 2);
                            x2 = x + (blocWidth + blocPart * i);
                            y2 = y + blocHeight;
                            poly = x + ',' + y + ' ' +
                                x1 + ',' + y + ' ' +
                                x2 + ',' + y1 + ' ' +
                                x1 + ',' + y2 + ' ' +
                                x + ',' + y2 + ' ' +
                                x + ',' + y;
                            break;
                        case 'ges':
                            x1 = x + (blocWidth + blocPart * i);
                            y1 = y + blocHeight;
                            poly = x + ',' + y + ' ' +
                                x1 + ',' + y + ' ' +
                                x1 + ',' + y1 + ' ' +
                                x + ',' + y1 + ' ' +
                                x + ',' + y;
                            break;
                    }
                    if (isNaN(+val)) {
                        if (val === data[i].letter) {
                            // @ts-ignore
                            self.score = i;
                        }
                    } else {
                        if ((data[i].min !== null && data[i].max !== null) && (data[i].min <= val && val <= data[i].max)) {
                            // @ts-ignore
                            self.score = i;
                        } else if ((data[i].min === null && data[i].max !== null) && val < data[i].max) {
                            // @ts-ignore
                            self.score = i;
                        } else if ((data[i].min !== null && data[i].max === null) && data[i].min <= val) {
                            // @ts-ignore
                            self.score = i;
                        }
                    }

                    // @ts-ignore
                    if (self.score === i) {
                        const sx1 = x - 2;
                        const sx2 = (self.width - (2 * self.pad)) + 2;
                        const sy1 = y - 2;
                        const sy2 = y + blocHeight + 3;
                        const scorePath = 'M ' + sx1 + ' ' + sy1 +
                            'L' + sx2 + ' ' + sy1 +
                            'L' + sx2 + ' ' + sy2 +
                            'L' + sx1 + ' ' + sy2 + ' Z';

                        //Dessin du rectangle autour de la bonne flèche
                        svg.appendChild(SVGHelper.getPath(scorePath, {
                            'stroke': '#5b5b5b',
                            'strokeWidth': 1,
                            'fill': '#ffffff',
                            'fillOpacity': 0.8
                        }));
                        //Ecriture du texte
                        svg.appendChild(SVGHelper.getText({
                            x: sx2 - 5,
                            y: y + blocHeight * 0.9,
                            text: (isNaN(+val)) ? data[i].letter : val,
                            options: {
                                'fill': '#000000',
                                'fontSize': (blocHeight * 0.8),
                                'fontWeight': 'bold',
                                'textAnchor': 'end'
                            }
                        }));
                    }

                    svg.appendChild(SVGHelper.getPolygon(poly, data[i].color, (attrs.shadow === 'true')));
                    svg.appendChild(SVGHelper.getText({
                        x: x + self.pad,
                        y: y + blocHeight - ((blocHeight * 0.6) / 2),
                        text: data[i].libelle,
                        options: {
                            fill: i > whiteIdx ? '#ffffff' : '#000000',
                            fontSize: (blocHeight * 0.5),
                            textAnchor: 'start'
                        }
                    }));
                    svg.appendChild(SVGHelper.getText({
                        x: x1 - self.pad,
                        y: y + (blocHeight * 0.8),
                        text: data[i].letter,
                        options: {
                            fill: i > whiteIdx ? '#ffffff' : '#000000',
                            fontSize: (blocHeight * 0.7),
                            fontWeight: 'bold',
                            textAnchor: 'end'
                        }
                    }));
                }

                return svg;
            },
        };
    })(angularJS);
}
