import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ICCommunesApi} from '@models/communes/collection/communes.collection.interfaces';
import {ICommuneApi} from '@models/communes/commune/commune.interfaces';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class CommunesApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<ICommuneApi> {
        return this.getByLink$('/communes/' + uuid);
    }

    getByLink$(link: string): Observable<ICommuneApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: IQueryParameters): Observable<ICCommunesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/communes', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICCommunesApi> {
        return this._modelApiService.getCollection$(link);
    }
}
