import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgPhoto} from '@legacy/app/managers/ressources';

export default function getManagersPhoto(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('PhotoManager', manager);

        /**
         * Manager photo
         *
         * @param BaseManager
         * @param UtilsManager
         * @returns {PhotoManager}
         */
        manager.$inject = ['BaseManager', 'UtilsManager'];
        function manager(baseManager: NgManager, utilsManager: NgUtilsManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('photos.get', {path: '/photos/{id}', method: 'GET'});
            baseManager.addRoute('photos.get-hash', {path: '/photos/{hash}', method: 'GET'});
            baseManager.addRoute('photos.insert', {path: '/photos', method: 'POST'});
            baseManager.addRoute('photos.remove', {path: '/photos/{id}', method: 'DELETE'});

            /**
             * PhotoManager object
             *
             * @constructor
             */
            angular.extend(PhotoManager.prototype, baseManager.__proto__);
            PhotoManager.prototype.eskManager = {prefixRoute: 'photos', collectionName: 'items'};

            function PhotoManager() {
            }

            /**
             * Create a Photo object
             *
             * @param data
             * @returns manager.Photo object || Array of manager.Photo objects
             */
            PhotoManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var photos = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        photos.push(new Photo(data[i]));
                    }

                    return photos;
                }

                // @ts-ignore
                return new Photo(data);
            };

            /**
             * Photo object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(Photo.prototype, classResource.prototype);
            Photo.prototype.eskManager = {prefixRoute: 'photos'};
            function Photo(this: NgPhoto, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Sanitize object Photo before send to API
             *
             * @returns {Object}
             */
            Photo.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                utilsManager.flattenIdForAttribute(sanitizeObject, 'media');

                return sanitizeObject;
            };

            // @ts-ignore
            return new PhotoManager();
        }
    })(angularJS);
}
