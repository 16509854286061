import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'esk-task-runner', template: '<div appNgEskTaskRunnerComp class="tw-h-full tw-w-full"></div>'})
export class NgEskimmoTaskRunnerCompComponent {
}

@Directive({selector: '[appNgEskTaskRunnerComp]'})
export class NgEskimmoTaskRunnerCompDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskTaskRunnerComp', elementRef, injector);
    }
}
