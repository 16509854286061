import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICEtudePasserellesApi
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.interfaces';
import {
    IEtudePasserelleApi, IEtudePasserelleConfigureApi, IEtudePasserelleDiffuseQueryParameters
} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.interfaces';

@Injectable({providedIn: 'root'})
export class EtudePasserellesApiService {
    private _modelApiService = inject(ModelApiService);

    configure$(etudeUuid: string, uuid: string, body: unknown): Observable<IEtudePasserelleConfigureApi> {
        return this._modelApiService.patch$('/etudes/' + etudeUuid + '/passerelles/' + uuid + '/configure', body);
    }

    diffuse$(uuid: string, queryParameters: IEtudePasserelleDiffuseQueryParameters): Observable<void> {
        return this._modelApiService.patch$(this._modelApiService.pathWithQueryParameters('/etude/passerelles/' + uuid + '/diffuser', queryParameters));
    }

    get$(uuid: string): Observable<IEtudePasserelleApi> {
        return this.getByLink$('/etude/passerelles/' + uuid);
    }

    getByLink$(link: string): Observable<IEtudePasserelleApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(): Observable<ICEtudePasserellesApi> {
        return this.getCollectionByLink$('/etude/passerelles');
    }

    getCollectionByLink$(link: string): Observable<ICEtudePasserellesApi> {
        return this._modelApiService.getCollection$(link);
    }
}
