import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICSitesApi, ICSitesQueryParameters} from '@models/sites/collection/sites.collection.interfaces';
import {ISiteApi} from '@models/sites/site/site.interfaces';

@Injectable({providedIn: 'root'})
export class SitesApiService {
    private _modelApiService = inject(ModelApiService);

    getByLink$(link: string): Observable<ISiteApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICSitesQueryParameters): Observable<ICSitesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/sites', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICSitesApi> {
        return this._modelApiService.getCollection$(link);
    }
}
