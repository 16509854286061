import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import Template from '@models/templates/template/template.model';
import {TemplateService} from '@models/templates/template/template.service';

@Injectable({providedIn: 'root'})
export class TemplatesService {
    private _templateService = inject(TemplateService);

    update$(templates: Template[] = []): Observable<void> {
        if (templates.length <= 0) {
            return of(undefined);
        }

        return combineLatest(templates.map(template => this._templateService.update$(template))).pipe(map(_ => undefined));
    }
}
