import {Injectable} from '@angular/core';
import {
    IDossierFileContactApi
} from '@models/dossiers/dossier/files/file/contacts/contact/dossier-file-contact.interfaces';
import DossierFileContact from '@models/dossiers/dossier/files/file/contacts/contact/dossier-file-contact.model';

@Injectable({providedIn: 'root'})
export class DossierFileContactFactory {
    create(dossierFileContactApi: IDossierFileContactApi): DossierFileContact {
        const dossierFileContact = new DossierFileContact(dossierFileContactApi.id.toString(), dossierFileContactApi.id);

        if (dossierFileContactApi._links?.contact) {
            dossierFileContact.linkContact = dossierFileContactApi._links.contact.href;
        }

        return dossierFileContact;
    }
}
