import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-eskimmo-my-account', template: '<div appNgEskimmoMyAccount></div>'})
export class NgEskimmoMyAccountComponent {
}

@Directive({selector: '[appNgEskimmoMyAccount]'})
export class NgEskimmoMyAccountDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskMyAccountCtrl', elementRef, injector);
    }
}
