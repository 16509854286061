import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-auth-reset', template: '<div appNgAuthReset></div>'})
export class NgAuthResetComponent {
}

@Directive({selector: '[appNgAuthReset]'})
export class NgAuthResetDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskAuthResetCtrl', elementRef, injector);
    }
}
