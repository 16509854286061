import angularJS from '@shared/angularJS/global.ng';
import {filter as lFilter} from 'lodash';
import {DisplayMode, SortConst} from '@shared/constants';
import {IModule, IPromise} from 'angular';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEmailManager, NgEstimationManager, NgLocationManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgBienDossiersCollection} from '@legacy/app/managers/collections';
import {NgBienDossier, NgEmail, NgFilter, NgTemplate} from '@legacy/app/managers/ressources';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import {DocumentsService} from '@models/documents/documents.service';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {take} from 'rxjs/operators';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {IDocumentVariableSort} from '@models/documents/document/document.interfaces';
import {VenteService} from '@models/ventes/vente/vente.service';
import {ModalService} from '@shared/modal/modal.service';
import {DCNotestimPublicitesComponent} from '@features/notestim/publicites/notestim-publicites.component';

export default function getBienDossiersWalletCurrentCtrl(module: IModule): void {
    (angular => {
        'use strict';

        module.component('eskBienDossiersWalletCurrentCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/wallet/current.html',
        });

        /**
         * Controller of list bienDossiers
         *
         * @param Ng2RouterStateForNgService
         * @param BienUtils
         * @param EmailManager
         * @param $translate
         * @param Ng2EtudeService
         * @param EstimationManager
         * @param LocationManager
         * @param VenteManager
         * @param Ng2UserService
         * @param Ng2DocumentsService
         * @param Ng2TemplateFactory
         * @param Ng2DossierBienFactory
         * @param Ng2VenteService
         * @param Ng2ModalService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'BiensUtils', 'EmailManager', '$translate', 'Ng2EtudeService', 'EstimationManager', 'LocationManager', 'VenteManager', 'Ng2UserService', 'Ng2DocumentsService', 'Ng2TemplateFactory', 'Ng2DossierBienFactory', 'Ng2VenteService', 'Ng2ModalService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            biensUtils: NgBienUtils,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            ng2EtudeService: EtudeService,
                            estimationManager: NgEstimationManager,
                            locationManager: NgLocationManager,
                            venteManager: NgVenteManager,
                            ng2UserService: UserService,
                            ng2DocumentsService: DocumentsService,
                            ng2TemplateFactory: TemplateFactory,
                            ng2DossierBienFactory: DossierBienFactory,
                            ng2VenteService: VenteService,
                            ng2ModalService: ModalService) {
            const $ctrl = this;
            let manager: NgEstimationManager | NgLocationManager | NgVenteManager;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.createFromNotestim = createFromNotestim;
            $ctrl.onChangeFilter = onChangeFilter;
            $ctrl.onChangePagination = onChangePagination;
            $ctrl.changeDisplayMode = changeDisplayMode;
            $ctrl.getSelectedDossiers = getSelectedDossiers;
            $ctrl.send = send;
            $ctrl.print = print;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.filterCode = Filter.codes.WALLET_CURRENT;
            if (ng2RouterStateForNgService.current.name!.startsWith('/app/estimations')) {
                $ctrl.filterRoute = Filter.routes.GET_ETUDE_ESTIMATIONS;
                manager = estimationManager;
                $ctrl.options = {nameDossiers: 'ESTIMATIONS_LIST', typeFilter: 'ESTIMATIONS_WALLET_CURRENT'};
                $ctrl.title = 'Mon portefeuille de biens en estimation';
                $ctrl.typeDossier = DossierBienTypesConst.ESTIMATION;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/locations')) {
                $ctrl.filterRoute = Filter.routes.GET_ETUDE_LOCATIONS;
                manager = locationManager;
                $ctrl.options = {
                    nameDossiers: 'LOCATIONS_LIST',
                    typeFilter: 'LOCATIONS_WALLET_CURRENT',
                    visibleFields: {bullet: false, nombreChambres: false, nombrePieces: false},
                    categoriesCode: ['liste-location', 'catalogue-location'],
                };
                $ctrl.title = 'Mon portefeuille de biens en location';
                $ctrl.typeDossier = DossierBienTypesConst.LOCATION;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/ventes')) {
                $ctrl.filterRoute = Filter.routes.GET_ETUDE_VENTES;
                manager = venteManager;
                $ctrl.options = {
                    nameDossiers: 'VENTES_LIST',
                    typeFilter: 'VENTES_WALLET_CURRENT',
                    visibleFields: {
                        archiveDateVente: false,
                        bullet: false,
                        mandatDateFin: false,
                        nombreChambres: false,
                        nombrePieces: false,
                        priceCession: false,
                    },
                    categoriesCode: ['liste-vente', 'catalogue-vente'],
                };
                $ctrl.title = 'Mon portefeuille de biens en vente';
                $ctrl.typeDossier = DossierBienTypesConst.VENTE;
            } else {
                throw new Error('Impossible de déterminer le "typeDossier" pour ' + ng2RouterStateForNgService.current.name);
            }

            $ctrl.options.loader = {updating: true};
            $ctrl.options.onChangeFilter = $ctrl.onChangeFilter;
            ng2UserService.last$.subscribe(currentUser => {
                $ctrl.currentUser = currentUser;
                $ctrl.options.displayMode = $ctrl.currentUser.clientMetadata.getWallet($ctrl.filterRoute, $ctrl.filterCode).view;
            });

            function createFromNotestim() {
                if ($ctrl.currentUser.hasRoleNotestim()) {
                    ng2VenteService.importFromNotestim$().pipe(take(1)).subscribe(vente => {
                        if (vente) {
                            ng2RouterStateForNgService.navigateByUrl(`/app/ventes/${vente.id}/edit/general`)
                        }
                    });
                } else {
                    ng2ModalService.open(DCNotestimPublicitesComponent);
                }
            }

            /**
             * Update list dossiers
             *
             * @param ngFilter
             * @returns {Promise}
             */
            function onChangeFilter(ngFilter: NgFilter) {
                $ctrl.options.loader.updating = true;
                $ctrl.filter = ngFilter;

                return (manager.getAllEtudeFiltered($ctrl.filter, {
                    limit: 50,
                    keywords: $ctrl.filter.queryParameters.keywords,
                }) as IPromise<NgBienDossiersCollection>).then(dossiersFiltered => {
                    $ctrl.onChangePagination(dossiersFiltered);
                    $ctrl.dossiers = dossiersFiltered;
                }).finally(() => $ctrl.options.loader.updating = false);
            }

            /**
             * Update list dossiers
             *
             * @param dossiersFiltered
             * @returns {Array}
             */
            function onChangePagination(dossiersFiltered: NgBienDossiersCollection) {
                angular.forEach(dossiersFiltered.collection, dossier => {
                    if (dossier.statut === ADossierBien.abstractBienStatuts.ARCHIVE) {
                        if ($ctrl.typeDossier === DossierBienTypesConst.ESTIMATION) {
                            dossier._esk.routing = '/app/estimations/' + dossier.id.toString() + '/edit/general';
                        } else if ($ctrl.typeDossier === DossierBienTypesConst.LOCATION) {
                            dossier._esk.routing = '/app/locations/details/' + dossier.id.toString();
                        } else {
                            dossier._esk.routing = '/app/ventes/' + dossier.id.toString() + '/overview';
                        }
                    } else {
                        dossier._esk.routing = '/app/' + $ctrl.typeDossier + 's/' + dossier.id.toString() + '/edit/general';
                    }
                });

                return dossiersFiltered;
            }

            /**
             * Change view mode
             *
             * @param displayMode
             */
            function changeDisplayMode(displayMode: DisplayMode) {
                $ctrl.options.displayMode = displayMode;
            }

            /**
             * Get selected dossiers
             *
             * @returns {Array}
             */
            function getSelectedDossiers(): NgBienDossier[] {
                let selectedDossiers = [] as NgBienDossier[];

                if (angular.isObject($ctrl.dossiers)) {
                    selectedDossiers = lFilter($ctrl.dossiers.collection, {_esk: {select: true}});
                }

                return selectedDossiers;
            }

            /**
             * Send email for selected dossiers
             *
             * @param relance
             */
            function send(relance: boolean) {
                $ctrl.barreFaireLoader = 'global.en_cours.SEND';
                ng2EtudeService.last$.subscribe(currentEtude => {
                    biensUtils.sendGroupedEmail($ctrl.getSelectedDossiers(), emailManager.create({
                        subject: (relance ? currentEtude.raisonSociale + " : Suivi de votre dossier" : ""),
                        message: (relance ? $translate.instant('bien.grouped_action.send.relance.' + $ctrl.typeDossier.toUpperCase() + '_CORPS', {typeDossier: $ctrl.typeDossier}) : "") + "<br><br>" + emailManager.getCurrentUserSignature()
                    }) as NgEmail).finally(() => {
                        $ctrl.unselectAll();
                        $ctrl.barreFaireLoader = undefined;
                    });
                });
            }

            /**
             * Print list for selected dossiers
             */
            function print(ngTemplate: NgTemplate) {
                const sort: IDocumentVariableSort = {field: 'reference', direction: SortConst.ASCENDING};
                const selectedDossiers = $ctrl.getSelectedDossiers().map(selectedDossier => ng2DossierBienFactory.ngCreate(selectedDossier));

                if (!ngTemplate || selectedDossiers.length <= 0) {
                    return;
                }

                if ($ctrl.filter.queryParameters.sort?.code) {
                    sort.field = $ctrl.filter.queryParameters.sort.code;
                    sort.direction = $ctrl.filter.queryParameters.sort.direction;
                }

                $ctrl.barreFaireLoader = 'global.en_cours.PRINT';
                ng2DocumentsService.readFromDossierBiens$(selectedDossiers, ng2TemplateFactory.ngCreate(ngTemplate), sort).pipe(take(1)).subscribe({
                    complete: () => {
                        $ctrl.unselectAll();
                        $ctrl.barreFaireLoader = undefined;
                    }
                });
            }
        }
    })(angularJS);
}
