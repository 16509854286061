import {inject, Injectable} from '@angular/core';
import {QuartiersApiService} from '@models/quartiers/quartiers.api.service';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';
import {ICQuartiersApi} from '@models/quartiers/collection/quartiers.collection.interfaces';
import CQuartiers from '@models/quartiers/collection/quartiers.collection.model';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class CQuartiersFactory {
    private _quartierFactory = inject(QuartierFactory);
    private _quartiersApiService = inject(QuartiersApiService);

    create(cQuartiersApi: ICQuartiersApi): CQuartiers {
        const cQuartiers = new CQuartiers();

        cQuartiers.links = new Links(cQuartiersApi._links);
        cQuartiers.page = cQuartiersApi.page;
        cQuartiers.pages = cQuartiersApi.pages;
        cQuartiers.perPage = cQuartiersApi.limit;
        cQuartiers.total = cQuartiersApi.total;
        if (cQuartiersApi._embedded?.items) {
            cQuartiersApi._embedded.items.filter(quartierApi => quartierApi).map(quartierApi => cQuartiers.results.push(this._quartierFactory.create(quartierApi)));
        }

        return cQuartiers;
    }

    getFromCommune$(communeUuid: string, queryParameters?: IQueryParameters): Observable<CQuartiers> {
        return this._quartiersApiService.getCollectionFromCommune$(communeUuid, queryParameters).pipe(map(cQuartiersApi => this.create(cQuartiersApi)));
    }
}
