import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-ventes-negociees-natures', template: '<div appNgVentesNegocieesNatures></div>'})
export class NgVentesNegocieesNaturesComponent {
}

@Directive({selector: '[appNgVentesNegocieesNatures]'})
export class NgVentesNegocieesNaturesDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVentesNegocieesNaturesCtrl', elementRef, injector);
    }
}
