import {
    Directive, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, OutputRefSubscription, Renderer2,
    ViewContainerRef
} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ClassCSSConst, SortDefinition} from '@shared/constants';
import {CollectionColumnSortComponent} from '@shared/collection/column-sort/collection-column.sort.component';

@Directive({selector: '[appCollectionColumnSort]'})
export class AppCollectionColumnSortDirective implements OnDestroy, OnInit {
    @Output() readonly changed = new EventEmitter<SortDefinition>();
    // Impossible de passer avec la ligne ci-dessous, ça crée une erreur ci-dessous
    // Readonly changed = output<SortDefinition>();

    private _componentRefChangedSubscription!: OutputRefSubscription;
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _renderer2 = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);
    private readonly _onDestroy$ = new Subject<void>();
    private _sortSource = new ReplaySubject<SortDefinition>(1);

    @Input()
    set appCollectionColumnSort(sort: SortDefinition) {
        this._sortSource.next(sort);
    }

    ngOnInit(): void {
        const divContent = this._elementRef.nativeElement.firstChild;
        const componentRef = this._viewContainerRef.createComponent(CollectionColumnSortComponent);

        this._sortSource.asObservable().pipe(takeUntil(this._onDestroy$)).subscribe(sort => componentRef.instance.sort = sort);
        this._componentRefChangedSubscription = componentRef.instance.changed.subscribe(sort => this.changed.emit(sort));

        this._renderer2.addClass(divContent, ClassCSSConst.FLEX);
        this._renderer2.appendChild(divContent, componentRef.location.nativeElement);
    }

    ngOnDestroy(): void {
        this._componentRefChangedSubscription.unsubscribe();
        this._onDestroy$.next();
    }
}
