import * as angular from 'angular';
import 'node_modules/angular-cookies/angular-cookies.js';
import 'node_modules/angular-drag-and-drop-lists/angular-drag-and-drop-lists.js';
import 'node_modules/angular-i18n/angular-locale_fr-fr.js';
import 'node_modules/angular-messages/angular-messages.js';
import 'node_modules/angular-sanitize/angular-sanitize.js';
import 'node_modules/angular-svg-pan-zoom/dist/angular-svg-pan-zoom.js';
import 'node_modules/angular-translate/dist/angular-translate.js';
import 'node_modules/angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files.js';
import 'node_modules/angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie.js';
import 'node_modules/angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local.js';
import 'node_modules/angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';
import 'node_modules/angular-ui-sortable/dist/sortable.js';
import 'node_modules/ui-select/dist/select.js';
import '@shared/angularJS/fixed-dependencies/angular-google-map';
import '@shared/angularJS/fixed-dependencies/angular-translate-interpolation-messageformat';

const angularJS = angular;

export default angularJS;
