import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    CDemandeurRecherchesFactory
} from '@models/demandeurs/demandeur/recherches/collection/demandeur-recherches.collection.factory';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import {DemandeurRechercheFactory} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.factory';
import CDemandeurRechercheResultats
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.model';
import {
    CDemandeurRechercheResultatsService
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.service';
import {
    CDemandeurRechercheResultatsFactory
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.factory';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';

@Injectable({providedIn: 'root'})
export class DemandeurRechercheService {
    private _cDemandeurRecherchesFactory = inject(CDemandeurRecherchesFactory);
    private _cDemandeurRechercheResultatsFactory = inject(CDemandeurRechercheResultatsFactory);
    private _cDemandeurRechercheResultatsService = inject(CDemandeurRechercheResultatsService);
    private _demandeurRechercheFactory = inject(DemandeurRechercheFactory);

    getCResultsByFilter$(demandeurRecherche: DemandeurRecherche, code: string, keywords?: string): Observable<CDemandeurRechercheResultats> {
        if (demandeurRecherche.uuid === DemandeurRecherche.statuts.NEW) {
            return of(this._cDemandeurRechercheResultatsFactory.createVirgin());
        }

        return this._cDemandeurRechercheResultatsService.getFromRechercheByFilter$(demandeurRecherche, code, keywords);
    }

    getOneFromDemandeur$(demandeur: Demandeur): Observable<DemandeurRecherche> {
        let demandeurRecherche$ = of(undefined as unknown as DemandeurRecherche);

        if (demandeur?.linkRecherches) {
            demandeurRecherche$ = this._cDemandeurRecherchesFactory.getByLink$(demandeur.linkRecherches).pipe(map(
                cDemandeurRecherches => cDemandeurRecherches.results[0]
            ));
        }

        return demandeurRecherche$.pipe(map(demandeurRecherche => demandeurRecherche || this._demandeurRechercheFactory.createVirgin()));
    }
}
