import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager} from '@legacy/app/managers/managers';
import {NgQuartiersCollection} from '@legacy/app/managers/collections';
import {NgQuartier} from '@legacy/app/managers/ressources';
import Quartier from '@models/quartiers/quartier/quartier.model';

export default function getManagersQuartier(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('QuartierManager', manager);

        /**
         * Manager quartier
         *
         * @param BaseManager
         * @returns {QuartierManager}
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('quartiers.cget', {path: '/quartiers', method: 'GET'});
            baseManager.addRoute('quartiers.cget-commune', {
                path: '/communes/{communeId}/quartiers',
                method: 'GET'
            }, true);

            /**
             * QuartierManager object
             *
             * @constructor
             */
            angular.extend(QuartierManager.prototype, baseManager.__proto__);
            QuartierManager.prototype.eskManager = {prefixRoute: 'quartiers', collectionName: 'items'};
            function QuartierManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param communeId
             * @param params
             * @returns {Promise}
             */
            QuartierManager.prototype.getAllFromCommune = function (communeId: number, params: unknown) {
                return this.get('cget-commune', angular.extend({communeId: communeId}, params)).then((quartiers: NgQuartiersCollection) => {
                    angular.forEach(quartiers.collection, quartier => quartier._esk.defaultRouteParams!.communeId = communeId);

                    return quartiers;
                });
            };

            /**
             * Create a Quartier object
             *
             * @param data
             * @returns manager.Quartier object || Array of manager.Quartier objects
             */
            QuartierManager.prototype.create = function (data: unknown, communeId: number) {
                if (angular.isArray(data)) {
                    var quartiers = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        quartiers.push(new Quartier(communeId, data[i]));
                    }

                    return quartiers;
                }

                // @ts-ignore
                return new Quartier(communeId, data);
            };

            /**
             * Create a Quartier object
             *
             * @param communeId
             * @param ng2Quartier
             * @returns manager.Quartier
             */
            QuartierManager.prototype.createFromNg2 = function (communeId: number, ng2Quartier: Quartier) {
                if (!ng2Quartier) {
                    return undefined!;
                }

                return this.create({code: ng2Quartier.code, id: ng2Quartier.id, name: ng2Quartier.nom}, communeId);
            };

            /**
             * Quartier object
             *
             * @param communeId
             * @param data
             * @constructor
             */
            angular.extend(Quartier.prototype, classResource.prototype);
            Quartier.prototype.eskManager = {prefixRoute: 'quartiers'};
            function Quartier(this: NgQuartier, communeId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, communeId: communeId};
            }

            // @ts-ignore
            return new QuartierManager();
        }
    })(angularJS);
}
