import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LocationLocataireConstatsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(locationId: string, locationLocataireId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/locations/${locationId}/locataires/${locationLocataireId}/etats-lieux/${id}`);
    }
}
