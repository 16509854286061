import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IBonvisiteApi} from '@models/bonvisites/bonvisite/bonvisite.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {
    ICBonvisitesApi, ICBonvisitesQueryParameters
} from '@models/bonvisites/collection/bonvisites.collection.interfaces';

@Injectable({providedIn: 'root'})
export class BonvisitesApiService {
    private _modelApiService = inject(ModelApiService);

    archive$(uuid: string): Observable<void> {
        return this._modelApiService.patch$('/etude/bons-visite/' + uuid + '/archiver');
    }

    get$(uuid: string): Observable<IBonvisiteApi> {
        return this._modelApiService.get$('/bons-visite/' + uuid);
    }

    getCollectionByLink$(link: string): Observable<ICBonvisitesApi> {
        return this._modelApiService.getCollection$(link);
    }

    getEtudeCollection$(queryParameters?: ICBonvisitesQueryParameters): Observable<ICBonvisitesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/bons-visite', queryParameters));
    }

    save$(uuid: string, bonvisiteApi: IBonvisiteApi): Observable<IBonvisiteApi> {
        if (uuid === Bonvisite.statuts.NEW) {
            return this._modelApiService.post$('/user/bons-visite', bonvisiteApi);
        }

        return this._modelApiService.put$('/bons-visite/' + uuid, bonvisiteApi).pipe(switchMap(_ => this.get$(uuid)));
    }

    sign$(uuid: string, data: unknown): Observable<void> {
        return this._modelApiService.patch$('/etude/bons-visite/' + uuid + '/signer', data);
    }

    write$(uuid: string, data: unknown): Observable<void> {
        return this._modelApiService.patch$('/etude/bons-visite/' + uuid + '/rediger', data);
    }
}
