import {inject, Injectable} from '@angular/core';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import Vente from '@models/ventes/vente/vente.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IVenteCompromisApi} from '@models/ventes/vente/compromis/vente-compromis.interfaces';
import {NgVenteCompromis} from '@legacy/app/managers/ressources';
import {VenteCompromisApiService} from '@models/ventes/vente/compromis/vente-compromis.api.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DateFormat from '@shared/date/date.format';

@Injectable({providedIn: 'root'})
export class VenteCompromisFactory {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _venteCompromisApiService = inject(VenteCompromisApiService);

    create(venteCompromisApi: IVenteCompromisApi): VenteCompromis {
        const venteCompromis = this.createVirgin(venteCompromisApi.id?.toString());

        venteCompromis.acquereur = venteCompromisApi.acquereur!;
        venteCompromis.comments = venteCompromisApi.comments;
        venteCompromis.date = venteCompromisApi.date;
        venteCompromis.dateFin = venteCompromisApi.dateFin;
        venteCompromis.dateVente = venteCompromisApi.dateVente;
        venteCompromis.demandeurId = venteCompromisApi.demandeurId!;
        venteCompromis.enabled = venteCompromisApi.enabled;
        venteCompromis.honorairesNego = venteCompromisApi.honorairesNego!;
        venteCompromis.honorairesNegoPercus = venteCompromisApi.honorairesNegoPercus!;
        venteCompromis.negocie = venteCompromisApi.negocie;
        venteCompromis.prixCession = venteCompromisApi.prixCession;

        if (venteCompromisApi._links) {
            if (venteCompromisApi._links.demandeur) {
                venteCompromis.linkDemandeur = venteCompromisApi._links.demandeur.href;
            }

            if (venteCompromisApi._links.etudeAcquereur) {
                venteCompromis.linkEtudeAcquereur = venteCompromisApi._links.etudeAcquereur.href;
            }
        }

        if (venteCompromisApi.raisonNegocie) {
            venteCompromis.raisonNegocie = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, venteCompromisApi.raisonNegocie);
        }

        if (venteCompromisApi.raisonNonNegocie) {
            venteCompromis.raisonNonNegocie = this._dictionaryItemService
                .getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, venteCompromisApi.raisonNonNegocie);
        }

        return venteCompromis;
    }

    createFromVente(vente: Vente): VenteCompromis {
        const venteCompromis = this.createVirgin();

        venteCompromis.date = DateFormat.toJSON(DateFormat.dateFromNow());
        if (!venteCompromis.honorairesNego || venteCompromis.honorairesNego <= 0) {
            venteCompromis.honorairesNego = vente.honorairesNego;
        }

        if (!venteCompromis.honorairesNegoPercus || venteCompromis.honorairesNegoPercus <= 0) {
            venteCompromis.honorairesNegoPercus = vente.honorairesNego;
        }

        if (!venteCompromis.prixCession || venteCompromis.prixCession <= 0) {
            venteCompromis.prixCession = vente.prixVente;
        }

        return venteCompromis;
    }

    createVirgin(uuid?: string): VenteCompromis {
        return new VenteCompromis(uuid ?? VenteCompromis.NEW);
    }

    forApi(venteCompromis: VenteCompromis): IVenteCompromisApi {
        const venteCompromisApi: IVenteCompromisApi = {
            enabled: venteCompromis.enabled,
            date: venteCompromis.date,
            dateFin: venteCompromis.dateFin,
            negocie: venteCompromis.negocie,
            prixCession: venteCompromis.prixCession,
            dateVente: venteCompromis.dateVente,
            comments: venteCompromis.comments,
        } as IVenteCompromisApi;

        if (venteCompromis.negocie) {
            if (venteCompromis.raisonNegocie) {
                venteCompromisApi.raisonNegocie = venteCompromis.raisonNegocie.code;
                venteCompromisApi.honorairesNego = venteCompromis.honorairesNego;
                if (venteCompromisApi.raisonNegocie === Vente.archiveNegocieeRaisons.PORTEFEUILLE) {
                    if (venteCompromis.demandeurId) {
                        venteCompromisApi.demandeurId = venteCompromis.demandeurId;
                    } else {
                        venteCompromisApi.acquereur = venteCompromis.acquereur;
                    }
                } else if (venteCompromisApi.raisonNegocie === Vente.archiveNegocieeRaisons.RESEAU) {
                    venteCompromisApi.honorairesNegoPercus = venteCompromis.honorairesNegoPercus;
                    venteCompromisApi.acquereur = venteCompromis.acquereur;
                    if (venteCompromis.etudeAcquereurId) {
                        venteCompromisApi.etudeAcquereurId = venteCompromis.etudeAcquereurId;
                    }
                }
            }
        } else if (venteCompromis.raisonNonNegocie) {
            venteCompromisApi.raisonNonNegocie = venteCompromis.raisonNonNegocie.code;
        }

        return venteCompromisApi;
    }

    getByLink$(link: string): Observable<VenteCompromis> {
        return this._venteCompromisApiService.getByLink$(link).pipe(map(venteApi => this.create(venteApi)));
    }

    ngCreate(ngVenteCompromis: NgVenteCompromis): VenteCompromis {
        const venteCompromis = this.createVirgin(ngVenteCompromis.id?.toString());

        venteCompromis.acquereur = ngVenteCompromis.acquereur;
        venteCompromis.comments = ngVenteCompromis.comments;
        venteCompromis.demandeurId = ngVenteCompromis.demandeurId;
        venteCompromis.enabled = ngVenteCompromis.enabled;
        venteCompromis.honorairesNego = ngVenteCompromis.honorairesNego;
        venteCompromis.honorairesNegoPercus = ngVenteCompromis.honorairesNegoPercus;
        venteCompromis.negocie = ngVenteCompromis.negocie;
        venteCompromis.prixCession = ngVenteCompromis.prixCession;

        if (ngVenteCompromis._links) {
            if (ngVenteCompromis._links.demandeur) {
                venteCompromis.linkDemandeur = ngVenteCompromis._links.demandeur.href;
            }

            if (ngVenteCompromis._links.etudeAcquereur) {
                venteCompromis.linkEtudeAcquereur = ngVenteCompromis._links.etudeAcquereur.href;
            }
        }

        if (ngVenteCompromis.date) {
            venteCompromis.date = DateFormat.toJSON(ngVenteCompromis.date);
        }

        if (ngVenteCompromis.dateFin) {
            venteCompromis.dateFin = DateFormat.toJSON(ngVenteCompromis.dateFin);
        }

        if (ngVenteCompromis.dateVente) {
            venteCompromis.dateVente = DateFormat.toJSON(ngVenteCompromis.dateVente);
        }

        if (ngVenteCompromis.raisonNegocie) {
            venteCompromis.raisonNegocie = this._dictionaryItemFactory.ngCreate(ngVenteCompromis.raisonNegocie);
        }

        if (ngVenteCompromis.raisonNonNegocie) {
            venteCompromis.raisonNonNegocie = this._dictionaryItemFactory.ngCreate(ngVenteCompromis.raisonNonNegocie);
        }

        return venteCompromis;
    }

    save$(venteUuid: string, venteCompromis: VenteCompromis): Observable<VenteCompromis> {
        return this._venteCompromisApiService.save$(venteUuid, venteCompromis.uuid, this.forApi(venteCompromis))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }
}
