import {Component, inject, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {
    AppDiagGrapheV2ConsommationAComponent, AppDiagGrapheV2ConsommationBComponent, AppDiagGrapheV2ConsommationCComponent,
    AppDiagGrapheV2ConsommationDComponent, AppDiagGrapheV2ConsommationEComponent, AppDiagGrapheV2ConsommationFComponent,
    AppDiagGrapheV2ConsommationGComponent, AppDiagGrapheV2ConsommationStatutComponent
} from '@shared/diagnostic/graphes/v2/consommation/diagnostic-graphes-v2-consommation.component';
import {DiagnosticLettersConst, DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';

// @todo Voir DropdownComponent pour les tests
// Ce component n'est pas testé car impossible de mocker ViewContainerRef.
// Penser à supprimer l'entrée dans "codeCoverageExclude" de "angular.json" si une solution est mise en place.
@Component({selector: 'app-diag-graphe-v2-consommation', template: ''})
export class AppDiagGrapheV2ConsommationComponent implements OnDestroy, OnInit {
    private _renderer2 = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);
    private _codeStatutDpe!: string;
    private _consommation!: number;
    private _consommationClasse!: string;
    private _consommationSource = new ReplaySubject<void>();
    private _grapheConsommationSource = new BehaviorSubject<void>(undefined);
    private _emissions!: number;
    private readonly _onDestroy$ = new Subject<void>();

    @Input()
    set codeStatutDpe(value: string) {
        this._codeStatutDpe = value;
        this._grapheConsommationSource.next();
    }

    @Input()
    set consommation(value: number) {
        this._consommation = value;
        this._consommationSource.next();
    }

    @Input()
    set consommationClasse(value: string) {
        this._consommationClasse = value;
        this._grapheConsommationSource.next();
    }

    @Input()
    set emissions(value: number) {
        this._emissions = value;
        this._consommationSource.next();
    }

    ngOnInit(): void {
        const nativeElement = this._viewContainerRef.element.nativeElement as AppDiagGrapheV2ConsommationComponent;

        this._grapheConsommationSource.asObservable().pipe(
            map(_ => {
                if (this._codeStatutDpe === DiagnosticStatutsConst.DISPONIBLE) {
                    if (this._consommationClasse === DiagnosticLettersConst.A) {
                        return AppDiagGrapheV2ConsommationAComponent;
                    } else if (this._consommationClasse === DiagnosticLettersConst.B) {
                        return AppDiagGrapheV2ConsommationBComponent;
                    } else if (this._consommationClasse === DiagnosticLettersConst.C) {
                        return AppDiagGrapheV2ConsommationCComponent;
                    } else if (this._consommationClasse === DiagnosticLettersConst.D) {
                        return AppDiagGrapheV2ConsommationDComponent;
                    } else if (this._consommationClasse === DiagnosticLettersConst.E) {
                        return AppDiagGrapheV2ConsommationEComponent;
                    } else if (this._consommationClasse === DiagnosticLettersConst.F) {
                        return AppDiagGrapheV2ConsommationFComponent;
                    } else {
                        return AppDiagGrapheV2ConsommationGComponent;
                    }
                } else {
                    return AppDiagGrapheV2ConsommationStatutComponent;
                }
            }),
            tap(_ => this._viewContainerRef.clear()),
            map(componentFactory => this._viewContainerRef.createComponent(componentFactory)),
            tap(componentRef => this._renderer2.appendChild(nativeElement, componentRef.location.nativeElement)),
            switchMap(componentRef => this._consommationSource.asObservable().pipe(map(_ => componentRef))),
            takeUntil(this._onDestroy$),
        ).subscribe(componentRef => {
            componentRef.instance.consommation = this._consommation;
            componentRef.instance.emissions = this._emissions;
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
