import {inject, Injectable} from '@angular/core';
import {IVenteContratApi} from '@models/ventes/vente/contrats/contrat/vente-contrat.interfaces';
import VenteContrat from '@models/ventes/vente/contrats/contrat/vente-contrat.model';
import {NgVenteContrat} from '@legacy/app/managers/ressources';
import {Observable} from 'rxjs';
import {VenteContratsApiService} from '@models/ventes/vente/contrats/vente-contrats.api.service';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class VenteContratFactory {
    private _venteContratsApiService = inject(VenteContratsApiService);

    create(venteContratApi: IVenteContratApi): VenteContrat {
        const venteContrat = this.createVirgin(venteContratApi.contratUuid);

        venteContrat.type = venteContratApi.type;

        return venteContrat;
    }

    createVirgin(uuid: string): VenteContrat {
        return new VenteContrat(uuid);
    }

    delete$(vente: Vente, venteContrat: VenteContrat): Observable<void> {
        return this._venteContratsApiService.delete$(vente.uuid, venteContrat.uuid);
    }

    ngCreate(ngVenteContrat: NgVenteContrat): VenteContrat {
        const venteContrat = this.createVirgin(ngVenteContrat.contratUuid);

        venteContrat.type = ngVenteContrat.type;

        return venteContrat;
    }
}
