import AModel from '@models/model.abstract';

export default class Quartier extends AModel {
    private _code!: string;
    private _communeId!: number;
    private readonly _id: number;
    private _nom!: string;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get communeId(): number {
        return this._communeId;
    }

    set communeId(value: number) {
        this._communeId = value;
    }

    get id(): number {
        return this._id;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
    }
}
