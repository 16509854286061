import angularJS from '@shared/angularJS/global.ng';
import {upperFirst} from 'lodash';
import {IModule, IPromise, IQService, IScope} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgContactManager} from '@legacy/app/managers/managers';
import {NgContact} from '@legacy/app/managers/ressources';
import Person from '@models/contacts/person/person.model';

export default function getContactEdit(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact-edit></esk-contact-edit>
         */
        module.component('eskContactEdit', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/contacts/components/item/edit.html',
            bindings: {contactModel: '=contact', forceSubmit: '=', onSaved: '=', onCancel: '&', titleData: '='}
        });

        /**
         * Controller to edit contact
         *
         * @param SoqSweetAlert
         * @param $translate
         * @param ContactManager
         * @param $q
         * @param $scope
         */
        Controller.$inject = ['SoqSweetAlert', '$translate', 'ContactManager', '$q', '$scope'];
        function Controller(this: any,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            contactManager: NgContactManager,
                            $q: IQService,
                            $scope: IScope) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.editRepresentant = editRepresentant;
            $ctrl.save = save;
            $ctrl.cancel = cancel;

            /**
             * Initialization method
             */
            function $onInit() {
                let ngContact$: IPromise<NgContact> = contactManager.createFromNg2($ctrl.contactModel);

                if ($ctrl.contactModel.id) {
                    ngContact$ = contactManager.getOneById($ctrl.contactModel.id);
                }

                ngContact$.then(ngContact => $ctrl.ngModel = ngContact);
                $ctrl.sending = false;
                $ctrl.titleData = {clickOnRepresentativite};

                /**
                 * Update ngModel
                 */
                $scope.$watch('$ctrl.ngModel', () => {
                    setContact('contact');
                    setTimeout(() => {
                        if ($ctrl.forceSubmit) {
                            setTimeout(() => {
                                if (!$ctrl.editForm) {
                                    $ctrl.editForm = {};
                                }

                                if (!$ctrl.editForm.$submitted) {
                                    $ctrl.editForm.$submitted = {};
                                }

                                $ctrl.editForm.$submitted = true
                            }, 10);
                        }
                    }, 10);
                });
            }

            /**
             * Edit representant of contact
             *
             * @param representant
             */
            function editRepresentant(representant: NgContact) {
                if (angular.isObject($ctrl.ngModel.representant) && angular.isNumber($ctrl.ngModel.representant.id) && $ctrl.ngModel.representant.id > 0) {
                    // Représentant existant
                    if (angular.isObject(representant)) {
                        // Édition du représentant
                        setContact('representant');
                    } else {
                        // Suppression du représentant
                        delete $ctrl.ngModel.representant;
                        setContact('contact');
                    }
                } else {
                    // Association d'un représentant
                    if (angular.isObject(representant)) {
                        // Contact existant
                        $ctrl.ngModel.representant = representant;
                        setContact('contact');
                    } else {
                        // Création d'un contact
                        $ctrl.ngModel.representant = contactManager.create();
                        setContact('representant');
                    }
                }
            }

            /**
             * Click on representant
             */
            function clickOnRepresentativite() {
                if ($ctrl.editionPart === 'contact') {
                    $ctrl.editRepresentant($ctrl.ngModel.representant);
                } else if ($ctrl.editionPart === 'representant') {
                    setContact('contact');
                }
            }

            /**
             * Save contact (insert and update)
             */
            function save() {
                let promise = $q.resolve();

                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid || !$ctrl.contact.isValid()) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return;
                }

                $ctrl.sending = true;
                if ($ctrl.editionPart === 'contact') {
                    promise = $ctrl.ngModel.save().then(savedContact => $ctrl.onSaved(savedContact.id));
                } else if ($ctrl.editionPart === 'representant') {
                    promise = $ctrl.ngModel.representant.save().then(() => setContact('contact'));
                }

                promise.catch(function (errorAPI) {
                    if (angular.isObject(errorAPI) && angular.isObject(errorAPI.data)) {
                        angular.forEach(errorAPI.data.errors, function (error) {
                            let field: string = null!;

                            angular.forEach(error.property_path.split('.'), function (slug, key) {
                                if (key === 0) {
                                    field = slug;
                                } else {
                                    field += upperFirst(slug);
                                }
                            });

                            if (angular.isObject($ctrl.editForm[field])) {
                                $ctrl.editForm[field].$error = {emailValidation: true};
                                $ctrl.editForm[field].$invalid = true;
                                $ctrl.editForm.$invalid = true;
                            }
                        });
                    }
                }).finally(() => $ctrl.sending = false);
            }

            /**
             * Cancel contact edition
             */
            function cancel() {
                if ($ctrl.editionPart === 'contact') {
                    $ctrl.onCancel();
                } else if ($ctrl.editionPart === 'representant') {
                    setContact('contact');
                }
            }

            /**
             * Set contact
             *
             * @param editionPart
             */
            function setContact(editionPart: string) {
                if (!$ctrl.ngModel) {
                    return;
                }

                $ctrl.editionPart = editionPart;
                $ctrl.titleData.representativite = {
                    has: angular.isObject($ctrl.ngModel.representant) && angular.isNumber($ctrl.ngModel.representant.id) && $ctrl.ngModel.representant.id > 0,
                    labelParam: {}
                };

                if ($ctrl.editionPart === 'contact') {
                    $ctrl.contact = $ctrl.ngModel;
                    if ($ctrl.titleData.representativite.has) {
                        $ctrl.titleData.representativite.label = 'contact.edit.representant.PAR';
                        $ctrl.titleData.representativite.labelParam = {convivialName: $ctrl.ngModel.representant[$ctrl.ngModel.representant.type].convivialName};
                    }
                } else if ($ctrl.editionPart === 'representant') {
                    $ctrl.contact = $ctrl.ngModel.representant;
                    $ctrl.titleData.representativite.label = 'contact.edit.representant.POUR';
                    $ctrl.titleData.representativite.labelParam = {convivialName: $ctrl.ngModel[$ctrl.ngModel.type].convivialName};
                }

                $ctrl.titleData.mainConvivialName = $ctrl.contact[$ctrl.contact.type].convivialName;
                $ctrl.titleData.representativite.labelParam.genre = angular.isObject($ctrl.contact[$ctrl.contact.type].titre) && $ctrl.contact[$ctrl.contact.type].titre.code === Person.titres.MONSIEUR ? 'male' : 'female';
            }
        }
    })(angularJS);
}
