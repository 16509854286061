import {Component, Input} from '@angular/core';

@Component({selector: 'app-diag-pgrahes-v2-emissions', template: ''})
export class AAppDiagGrapheV2EmissionsComponent {
    private _emissions!: number;

    get emissions(): number {
        return this._emissions;
    }

    @Input()
    set emissions(value: number) {
        this._emissions = value;
    }
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-A', templateUrl: 'emissions_A.svg'})
export class AppDiagGrapheV2EmissionsAComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-B', templateUrl: 'emissions_B.svg'})
export class AppDiagGrapheV2EmissionsBComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-C', templateUrl: 'emissions_C.svg'})
export class AppDiagGrapheV2EmissionsCComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-D', templateUrl: 'emissions_D.svg'})
export class AppDiagGrapheV2EmissionsDComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-E', templateUrl: 'emissions_E.svg'})
export class AppDiagGrapheV2EmissionsEComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-F', templateUrl: 'emissions_F.svg'})
export class AppDiagGrapheV2EmissionsFComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-G', templateUrl: 'emissions_G.svg'})
export class AppDiagGrapheV2EmissionsGComponent extends AAppDiagGrapheV2EmissionsComponent {
}

@Component({selector: 'app-diag-pgrahes-v2-emissions-default', templateUrl: 'emissions_default.svg'})
export class AppDiagGrapheV2EmissionsStatutComponent extends AAppDiagGrapheV2EmissionsComponent {
}
