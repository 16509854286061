import {Component, inject, Inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import {IBonvisiteAddedInformation} from '@models/bonvisites/bonvisite/bonvisite.interfaces';
import Vente from '@models/ventes/vente/vente.model';
import Location from '@models/locations/location/location.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {IWindowHistoryState} from '@shared/routes/routes.interfaces';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';

@Component({selector: 'layout-bonvisite', templateUrl: 'layout-bonvisite.component.html'})
export class AppLayoutBonvisiteComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _bonvisiteService = inject(BonvisiteService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _dossierService = inject(DossierService);
    private _locationFactory = inject(LocationFactory);
    private _router = inject(Router);
    private _venteFactory = inject(VenteFactory);
    private _window: Window;
    private readonly _onDestroy$ = new Subject<void>();

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(
            map(params => (params as { bonvisiteUuid: string }).bonvisiteUuid),
            switchMap(bonvisiteUuid => {
                const addedInformation: IBonvisiteAddedInformation = {};
                const currentDossier = this._dossierService.getCurrentFromNg(this._router.url);
                const dossiers$: Observable<unknown>[] = [];
                const state = this._window.history.state as IWindowHistoryState;

                if (currentDossier?.isDemandeur()) {
                    addedInformation.demandeur = currentDossier as Demandeur;
                    if (state.extraParams) {
                        const linkLocations = (state.extraParams.linkLocations ?? []) as string[];
                        const linkVentes = (state.extraParams.linkVentes ?? []) as string[];

                        if (linkLocations.length > 0) {
                            addedInformation.locations = [];
                            dossiers$.push(combineLatest(linkLocations
                                .map(linkLocation => this._locationFactory.getByLink$(linkLocation).pipe(tap(location => addedInformation.locations!.push(location)))))
                                .pipe(map(_ => undefined)));
                        }

                        if (linkVentes.length > 0) {
                            addedInformation.ventes = [];
                            dossiers$.push(combineLatest(linkVentes
                                .map(linkVente => this._venteFactory.getByLink$(linkVente).pipe(tap(vente => addedInformation.ventes!.push(vente)))))
                                .pipe(map(_ => undefined)));
                        }
                    }
                } else if (currentDossier?.isLocation()) {
                    addedInformation.locations = [currentDossier as Location];
                } else if (currentDossier?.isVente()) {
                    addedInformation.ventes = [currentDossier as Vente];
                } else if (state.extraParams) {
                    const linkLocations = (state.extraParams.linkLocations ?? []) as string[];
                    const linkVentes = (state.extraParams.linkVentes ?? []) as string[];

                    if (state.extraParams.linkDemandeur) {
                        dossiers$.push(this._demandeurFactory.getByLink$(state.extraParams.linkDemandeur as string).pipe(
                            tap(demandeur => addedInformation.demandeur = demandeur),
                            map(_ => undefined)),
                        );
                    }

                    if (linkLocations.length > 0) {
                        addedInformation.locations = [];
                        dossiers$.push(combineLatest(linkLocations
                            .map(linkLocation => this._locationFactory.getByLink$(linkLocation).pipe(tap(location => addedInformation.locations!.push(location)))))
                            .pipe(map(_ => undefined)));
                    }

                    if (linkVentes.length > 0) {
                        addedInformation.ventes = [];
                        dossiers$.push(combineLatest(linkVentes
                            .map(linkVente => this._venteFactory.getByLink$(linkVente).pipe(tap(vente => addedInformation.ventes!.push(vente)))))
                            .pipe(map(_ => undefined)));
                    }
                }

                return (dossiers$.length > 0 ? combineLatest(dossiers$) : of([] as ADossier[])).pipe(
                    map(_ => ({addedInformation, bonvisiteUuid})),
                );
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(({addedInformation, bonvisiteUuid}) =>
            this._bonvisiteService.initCurrent(bonvisiteUuid, addedInformation));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._bonvisiteService.razCurrent();
    }
}
