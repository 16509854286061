import Collection from '@models/collection/collection.model';
import Reference from '@models/references/reference/reference.model';
import DateFormat from '@shared/date/date.format';

export default class CReferences extends Collection<Reference> {
    static readonly QUERY_PARAMETERS_DEFAULT = {
        dateVente: {
            max: DateFormat.toAPI((DateFormat.getYear(DateFormat.dateFromNow())).toString() + '-12-31'),
            min: DateFormat.toAPI('0001-01-01'),
        },
    };
    static readonly tris = {
        DATE_VENTE: 'dateVente',
        DISTANCE: 'distance',
    };
}
