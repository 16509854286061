import angularJS from '@shared/angularJS/global.ng';
import {IHttpRequestConfigHeaders, IHttpService, IModule, IRequestShortcutConfig} from 'angular';
import {NgClientConfig, NgClientResponseApi} from '@legacy/app/client/client';

export default function getClientHttp(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ClientHttp', Factory);

        /**
         * All methods to use API
         *
         * @param $http
         * @param ClientConfig
         * @returns {ClientHttp}
         */
        Factory.$inject = ['$http', 'ClientConfig'];
        function Factory($http: IHttpService, clientConfig: NgClientConfig) {
            const factory = {get: get, post: post, put: put, save: save, patch: patch, remove: remove};

            return factory;

            /**
             * GET
             *
             * @param path
             * @param queryParams
             * @param additionalHeaders
             * @returns {HttpPromise}
             */
            function get(path: string, queryParams: unknown, additionalHeaders: IHttpRequestConfigHeaders) {
                return $http.get(clientConfig.getFullUrl(path, queryParams), clientConfig.getConfig(additionalHeaders) as IRequestShortcutConfig)
                    .catch(clientConfig.requestError)
                    .then(response => response as NgClientResponseApi)
                    .then(response => {
                        if (angular.isDefined(response.headers().etag) && angular.isObject(response.data)) {
                            response.data.etag = response.headers().etag;
                        }

                        return clientConfig.deleteEmbedded(response.data);
                    });
            }

            /**
             * POST and PUT
             *
             * @param path
             * @param data
             * @param additionalHeaders
             * @returns {HttpPromise}
             */
            function post(path: string, data: unknown, additionalHeaders: IHttpRequestConfigHeaders) {
                return $http.post(clientConfig.getFullUrl(path), data, clientConfig.getConfig(additionalHeaders) as IRequestShortcutConfig)
                    .catch(clientConfig.requestError)
                    .then(response => response as NgClientResponseApi)
                    .then(response => clientConfig.deleteEmbedded(response.data));
            }

            /**
             * PUT
             *
             * @param path
             * @param data
             * @param additionalHeaders
             * @returns {HttpPromise}
             */
            function put(path: string, data: unknown, additionalHeaders: IHttpRequestConfigHeaders) {
                return $http.put(clientConfig.getFullUrl(path), data, clientConfig.getConfig(additionalHeaders) as IRequestShortcutConfig)
                    .catch(clientConfig.requestError)
                    .then(response => response as NgClientResponseApi)
                    .then(response => clientConfig.deleteEmbedded(response.data));
            }

            /**
             * POST and PUT
             *
             * @param path
             * @param data
             * @param additionalHeaders
             * @returns {HttpPromise}
             */
            function save(path: string, data: { id: unknown }, additionalHeaders: IHttpRequestConfigHeaders) {
                if (data.id) {
                    return factory.put(path, data, additionalHeaders);
                }

                return factory.post(path, data, additionalHeaders);
            }

            /**
             * PATCH
             *
             * @param path
             * @param queryParams
             * @param datasToModify
             * @param additionalHeaders
             * @returns {HttpPromise}
             */
            function patch(path: string, queryParams: unknown[], datasToModify: unknown, additionalHeaders: IHttpRequestConfigHeaders) {
                return $http.patch(clientConfig.getFullUrl(path, queryParams), datasToModify, clientConfig.getConfig(additionalHeaders) as IRequestShortcutConfig)
                    .catch(clientConfig.requestError)
                    .then(response => response as NgClientResponseApi)
                    .then(response => clientConfig.deleteEmbedded(response.data));
            }

            /**
             * DELETE
             *
             * @param path
             * @param additionalHeaders
             * @returns {HttpPromise}
             */
            function remove(path: string, additionalHeaders: IHttpRequestConfigHeaders) {
                return $http.delete(clientConfig.getFullUrl(path), clientConfig.getConfig(additionalHeaders) as IRequestShortcutConfig)
                    .catch(clientConfig.requestError).then(() => undefined);
            }
        }
    })(angularJS);
}
