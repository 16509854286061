import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgVenteManager} from '@legacy/app/managers/managers';

export default function getVenteDiffusionCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskVenteDiffusionCtrl', {
            controller: Controller,
            template: '<esk-bien-diffusion dossier="$ctrl.vente"></esk-bien-diffusion>',
        });

        /**
         * Diffusion of vente
         *
         * @param VenteManager
         * @param AuthenticationService
         * @constructor
         */
        Controller.$inject = ['VenteManager'];
        function Controller(this: any, venteManager: NgVenteManager) {
            const $ctrl = this;

            $ctrl.vente = venteManager.current;
        }
    })(angularJS);
}
