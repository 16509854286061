export default `
<div class="row details-item">
  <div class="col-sm-6 text-right">
    <h4 class="text-bold">
      {{$ctrl.dossier._esk.infosPricing.prixNV |
      currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixNV % 1 !== 0)}}
    </h4>

    <p>{{"Prix net vendeur" | translate}}</p>
  </div>

  <div class="col-sm-6">
    <h5>
      <span class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.honoraires |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.honoraires % 1 !== 0)}}
      </span>

      {{"soit" | translate}}

      <span class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.pourcentageHonoraires |
        currency:'%':2 * ($ctrl.dossier._esk.infosPricing.pourcentageHonoraires % 1 !== 0)}}
      </span>
    </h5>

    <p>{{"Honoraires de négociation" | translate}}</p>
  </div>
</div>

<ng-include
  src="'src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_honoraires_negociation_inclus.html'"></ng-include>
`;
