export default `
<ng-switch on="dossier.doubleMandat">
  <div ng-switch-when="true">
    Votre bien en {{$ctrl.typeDossier}} possède au moins une référence cadastrale identique à un bien à vendre sur le
    réseau.

    <div class="text-muted">Pour plus d'informations, consulter sa fiche.</div>
  </div>

  <ng-switch on="!!dossier.network.dateDiffusion" ng-switch-default>
    <div ng-switch-when="false">
      Jamais diffusé <div class="text-muted">Pour le diffuser, cliquez ici.</div>
    </div>

    <div ng-switch-default>
      Diffusé depuis le <app-ng2-date [date]="dossier.network.dateDiffusion" format="shortDate"></app-ng2-date>
    </div>
  </ng-switch>
</ng-switch>
`;
