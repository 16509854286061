import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-demandeurs-wallet-current', template: '<div appNgDemandeursWalletCurrent></div>'})
export class NgDemandeursWalletCurrentComponent {
}

@Directive({selector: '[appNgDemandeursWalletCurrent]'})
export class NgDemandeursWalletCurrentDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeursWalletCurrentCtrl', elementRef, injector);
    }
}
