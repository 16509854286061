import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Passerelle from '@models/passerelle/passerelle.model';

export default class Dictionary {
    static readonly names = {
        AFFICHETTE_GROUPS: 'affichettesGroups',
        ASSAISSEMENTS: 'assainissements',
        CHARGES: 'charges',
        CHARPENTES: 'charpentes',
        CHAUFFAGES: 'chauffages',
        CLOTURES: 'clotures',
        COMMODITES: 'commodites',
        COMPTE_RENDU_THEMES: 'themes-compte-rendu',
        CONSTRUCTION_EPOQUES: 'epoques-construction',
        CONSTRUCTION_MATERIAUX: 'materiaux-construction',
        CONTACTS_GROUP_TYPES: 'types-contacts-group',
        COUVERTURE_MATERIAUX: 'materiaux-couverture',
        DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS: 'raisonsArchiveDemandeurVenteNegociee',
        DEMANDEUR_ARCHIVE_VENTE_TYPES: 'typesArchiveDemandeurVente',
        DEMANDEUR_RECHERCHE_STATUTS: 'demandeursRecherchesStatuts',
        DEMANDEUR_RECHERCHE_TRANSACTION_TYPES: 'transactionTypes',
        DEMANDEUR_STATUTS: 'statutsDemandeurs',
        DEMANDEUR_TYPES: 'types-demandeur',
        DIAGNOSTIC_STATUTS: 'statutsDpe',
        DIAGNOSTIC_TYPES: 'types-diagnostic',
        DIFFUSION_FACEBOOK_PRIX_TYPES: 'types-prix-facebook',
        DIFFUSION_IMMONOT_NATURES: 'natures-immonot',
        DIFFUSION_IMMONOT_PRIX_TYPES: 'types-prix-immonot',
        DIFFUSION_PHONE_SOURCES: 'diffusionsPhoneSources',
        DIFFUSION_PRECOM_NATURES: 'natures-' + Passerelle.normalizers.PRECOM,
        DIFFUSION_REAL_NATURES: 'natures-real',
        DIFFUSION_REAL_PRIX_TYPES: 'types-prix-real',
        DIFFUSION_SELOGER_NATURES: 'natures-seloger',
        DIFFUSION_SPIR_NATURES: 'natures-spir',
        DIFFUSION_SPIR_PRIX_TYPES: 'types-prix-spir',
        ESTIMATION_MOTIFS: 'motifsEstimation',
        ESTIMATION_STATUTS: 'statutsEstimations',
        ESTIMATION_TYPES: 'typesEstimation',
        ESTIMATION_VALEUR_VENALE_TYPES: 'typesValeurVenale',
        ETAGES: 'etages',
        ETATS: 'etats',
        EXPOSITIONS: 'expositions',
        EXTENSIONS: 'extensions',
        FINANCEMENT_FONDS_PROVENANCES: 'financementProvenancesFonds',
        FINANCEMENT_STATUTS: 'financementStatuts',
        HUISSERIES: 'huisseries',
        INTERNET_CONNEXIONS: 'connexions-internet',
        ISOLATIONS: 'isolations',
        LOCATION_LOYER_TYPES: 'typesLoyer',
        LOCATION_MANDAT_TYPES: 'typesMandatLocation',
        LOCATION_STATUTS: 'statutsLocations',
        LOGEMENTS: 'logements',
        MITOYENNETES: 'mitoyennetes',
        NATURES: 'natures',
        OCCUPATIONS: 'occupations',
        PERFORMANCE_ENERGETIQUE_CLASSES: 'performanceEnergetiqueClasses',
        PERIODICITES: 'periodicites',
        PIECE_TYPES: 'pieces',
        PLAIN_PIEDS: 'plain-pieds',
        REFERENCES_ARCHIVEES_TYPES: 'typesReferencesArchivees',
        RESIDENCES: 'residences',
        SEXES: 'sexe',
        TITRES: 'titres',
        VENTE_ARCHIVE_NEGOCIEE_RAISONS: 'raisonsArchiveVenteNegociee',
        VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS: 'raisonsArchiveVenteNonNegociee',
        VENTE_ARCHIVE_TYPES: 'typesArchiveVente',
        VENTE_COMPROMIS_TYPES: 'typesCompromis',
        VENTE_INTERACTIVE_ENCHERES_SYSTEMES: 'systemesEncheres',
        VENTE_MANDAT_TYPES: 'typesMandatVente',
        VENTE_PRIX_CHARGES_TYPES: 'typesCharges',
        VENTE_STATUTS: 'statutsVentes',
        VENTE_TYPES: 'typesVente',
        USAGES: 'usages',
        VOLETS: 'volets',
        VUES: 'vues',
    };
    private _etag!: string;
    private _items: DictionaryItem[] = [];
    private _name: string;

    constructor(name: string) {
        this._name = name;
    }

    get etag(): string {
        return this._etag;
    }

    set etag(value: string) {
        this._etag = value;
    }

    get items(): DictionaryItem[] {
        return this._items;
    }

    set items(value: DictionaryItem[]) {
        this._items = value;
    }

    get name(): string {
        return this._name;
    }

    getItemByCode(code: string): DictionaryItem {
        return this.items.find(item => item.code === code)!;
    }

    getItemByCodes(code: string): DictionaryItem {
        return this.items.find(item => item.getByCodes(code))!;
    }

    getItemById(id: number): DictionaryItem {
        return this.items.find(item => item.id === id)!;
    }
}
