import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({
    selector: 'app-ng-estimation-evaluation-onsale-references-criteria',
    template: '<div appNgEstimationEvaluationOnsaleReferencesCriteria></div>',
})
export class NgEstimationEvaluationOnsaleReferencesCriteriaComponent {
}

@Directive({selector: '[appNgEstimationEvaluationOnsaleReferencesCriteria]'})
export class NgEstimationEvaluationOnsaleReferencesCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationEvaluationOnsaleReferencesCriteriaCtrl', elementRef, injector);
    }
}
