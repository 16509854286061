export default `
<div ng-class="$ctrl.contact.invalidEmailAddress ? 'contact-invalid-email-address' : ''"
     title="{{$ctrl.contact.invalidEmailAddress ? 'mail.invalid_recipient.contact.HELP' : '' | translate: '{type: \\'initiales\\'}'}}">
  <div class="img-circle ellipsis contact-initiales"
       ng-class="'bg-' + $ctrl.contact[$ctrl.contact.type].colorVariation">
    {{$ctrl.contact[$ctrl.contact.type].initiales}}
  </div>

  <span ng-if="$ctrl.contact.invalidEmailAddress > 0" class="badge badge-danger">
    <fa-icon [icon]="'exclamation'"></fa-icon>
  </span>
</div>
`;
