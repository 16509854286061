import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import editEntrepriseTemplate from '@legacy/templates/contacts/components/item/edit/entreprise';
import editPersonTemplate from '@legacy/templates/contacts/components/item/edit/person';
import convivialNameTemplate from '@legacy/templates/contacts/components/item/convivial-name';
import editTemplate from '@legacy/templates/contacts/components/item/edit';
import initialesTemplate from '@legacy/templates/contacts/components/item/initiales';
import resumeTemplate from '@legacy/templates/contacts/components/item/resume';
import listTemplate from '@legacy/templates/contacts/components/list';
import searchTemplate from '@legacy/templates/contacts/components/search';
import modalItemEditTemplate from '@legacy/templates/contacts/controllers/modals/item.edit';
import walletCurrentTemplate from '@legacy/templates/contacts/controllers/wallet.current';
import keywordsHelpTemplate from '@legacy/templates/contacts/keywords.help';

export default function getContactsTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/contacts/components/item/edit/entreprise.html', editEntrepriseTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/item/edit/person.html', editPersonTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/item/convivial-name.html', convivialNameTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/item/edit.html', editTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/item/initiales.html', initialesTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/item/resume.html', resumeTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/list.html', listTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/components/search.html', searchTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/controllers/modals/item.edit.html', modalItemEditTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/controllers/wallet.current.html', walletCurrentTemplate);
            $templateCache.put('src/app/legacy/templates/contacts/keywords.help.html', keywordsHelpTemplate);
        }
    })(angularJS);
}
