import angularJS from '@shared/angularJS/global.ng';
import environment from '@env/environment';
import {IModule} from 'angular';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

export default function getDemandeurInformationEdit(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-demandeur-information-edit></esk-demandeur-information-edit>
         * @param options : {envoiResults(object), typeDemandeur(object)}
         * @param options.envoiResults : {hide(boolean)}
         * @param options.typeDemandeur : {hide(boolean)}
         */
        module.component('eskDemandeurInformationEdit', {
            bindings: {
                demandeur: '=',
                editForm: '=',
                options: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/components/item/information.edit.html'
        });

        /**
         * Controller to manage demandeur information edition
         *
         * @param Ng2EtudeService
         * @constructor
         */
        Controller.$inject = ['Ng2EtudeService'];
        function Controller(this: any, ng2EtudeService: EtudeService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.DEMANDEUR_TYPES = Dictionary.names.DEMANDEUR_TYPES;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.myNotyUrl = environment.myNoty.baseUrl;
                $ctrl.roleNotaire = User.roles.NOTAIRE;
                $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];
                ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
            }
        }
    })(angularJS);
}
