import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import CTemplateBalises from '@models/templates/balises/collection/template-balises.collection.model';
import {CTemplateBalisesFactory} from '@models/templates/balises/collection/template-balises.collection.factory';
import {map, take} from 'rxjs/operators';
import TemplateBalise from '@models/templates/balises/balise/template-balise.model';

@Injectable({providedIn: 'root'})
export class TemplateBalisesEditorService {
    private _cTemplateBalisesFactory = inject(CTemplateBalisesFactory);
    private _templateBalisesSource = new BehaviorSubject<TemplateBalise[]>(undefined!);
    private _templateBalises$ = this._templateBalisesSource.asObservable();
    private _modelsLabelsCTemplateBalisesSource = new BehaviorSubject<[string, string, CTemplateBalises][]>(undefined!);
    private _modelsLabelsCTemplateBalises$ = this._modelsLabelsCTemplateBalisesSource.asObservable();

    get templateBalises$(): Observable<TemplateBalise[]> {
        return this._templateBalises$;
    }

    get modelsLabelsCTemplateBalises$(): Observable<[string, string, CTemplateBalises][]> {
        return this._modelsLabelsCTemplateBalises$;
    }

    initModelsLabelsCTemplateBalises(models: string[]): void {
        const modelsLabelsCTemplateBalises$ = [] as Observable<[string, string, CTemplateBalises]>[];

        this._templateBalisesSource.next(undefined!);
        this._modelsLabelsCTemplateBalisesSource.next(undefined!);
        models.forEach(model => {
            switch (model) {
                case CTemplateBalises.models.etude.CODE:
                    modelsLabelsCTemplateBalises$.push(this._cTemplateBalisesFactory.getEtude$().pipe(map(cTemplateBalises => [model, CTemplateBalises.models.etude.LABEL, cTemplateBalises])));
                    break;
                case CTemplateBalises.models.page.CODE:
                    modelsLabelsCTemplateBalises$.push(this._cTemplateBalisesFactory.getPage$().pipe(map(cTemplateBalises => [model, CTemplateBalises.models.page.LABEL, cTemplateBalises])));
                    break;
                case CTemplateBalises.models.sitePrincipal.CODE:
                    modelsLabelsCTemplateBalises$.push(this._cTemplateBalisesFactory.getSitePrincipal$()
                        .pipe(map(cTemplateBalises => [model, CTemplateBalises.models.sitePrincipal.LABEL, cTemplateBalises])));
                    break;
                case CTemplateBalises.models.currentUser.CODE:
                    modelsLabelsCTemplateBalises$.push(this._cTemplateBalisesFactory.getUser$().pipe(map(cTemplateBalises => [model, CTemplateBalises.models.currentUser.LABEL, cTemplateBalises])));
                    break;
            }
        });
        combineLatest(modelsLabelsCTemplateBalises$).pipe(
            map(modelsLabelsCTemplateBalises => {
                let allTemplateBalises: TemplateBalise[] = [];

                modelsLabelsCTemplateBalises
                    .map(([,, cTemplateBalises]) => cTemplateBalises.results)
                    .forEach(templateBalises => allTemplateBalises = allTemplateBalises.concat(templateBalises));

                return allTemplateBalises;
            }),
            take(1),
        ).subscribe(templateBalises => this._templateBalisesSource.next(templateBalises));
        combineLatest(modelsLabelsCTemplateBalises$).pipe(take(1)).subscribe(modelsLabelsCTemplateBalises => this._modelsLabelsCTemplateBalisesSource.next(modelsLabelsCTemplateBalises));
    }

    getModelTemplateBalise$(code: string): Observable<[string, TemplateBalise]> {
        return this.modelsLabelsCTemplateBalises$.pipe(
            map(modelsLabelsCTemplateBalises => {
                let modelTemplateBalise: [string, TemplateBalise] = [undefined!, undefined!];

                modelsLabelsCTemplateBalises.forEach(([model, , cTemplateBalises]) => {
                    const templateBalise = cTemplateBalises.findByCode(code);

                    if (templateBalise) {
                        modelTemplateBalise = [model, templateBalise];
                    }
                });

                return modelTemplateBalise;
            }),
        );
    }
}
