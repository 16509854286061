import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgMediaManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgReportAttachment} from '@legacy/app/managers/ressources';
import {NgReportAttachmentsCollection} from '@legacy/app/managers/collections';

export default function getManagersReportAttachment(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ReportAttachmentManager', manager);

        /**
         * Manager attachment
         *
         * @param BaseManager
         * @param UtilsManager
         * @param MediaManager
         * @returns {EmailAttachmentManager}
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'MediaManager'];
        function manager(baseManager: NgManager, utilsManager: NgUtilsManager, mediaManager: NgMediaManager) {
            let classResource = baseManager.getClass<NgReportAttachment>();

            /**
             * Routing
             */
            baseManager.addRoute('report-attachments.cget', {path: '/reports/{reportId}/attachments', method: 'GET'});
            baseManager.addRoute('report-attachments.insert', {
                path: '/reports/{reportId}/attachments',
                method: 'POST'
            });
            baseManager.addRoute('report-attachments.remove', {
                path: '/reports/{reportId}/attachments/{id}',
                method: 'DELETE'
            });

            /**
             * EmailAttachmentManager object
             *
             * @constructor
             */
            angular.extend(ReportAttachmentManager.prototype, baseManager.__proto__);
            ReportAttachmentManager.prototype.eskManager = {
                prefixRoute: 'report-attachments',
                collectionName: 'items'
            };

            function ReportAttachmentManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param reportId
             * @returns {Promise}
             */
            ReportAttachmentManager.prototype.getAll = function (reportId: number) {
                return baseManager.getAll.call(this, {reportId})
                    .then(attachments => attachments as NgReportAttachmentsCollection)
                    .then(function (attachments: NgReportAttachmentsCollection) {
                    angular.forEach(attachments.collection, function (attachment) {
                        attachment._esk.defaultRouteParams!.reportId = reportId;
                    });

                    return attachments;
                });
            };

            /**
             * Create a ReportAttachment object
             *
             * @param data
             * @param reportId
             * @returns manager.ReportAttachment object
             */
            ReportAttachmentManager.prototype.create = function (data: unknown, reportId: number) {
                if (angular.isArray(data)) {
                    var attachments = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        attachments.push(new ReportAttachment(reportId, data[i]));
                    }

                    return attachments;
                }

                // @ts-ignore
                return new ReportAttachment(reportId, data);
            };

            /**
             * ReportAttachment object
             *
             * @param emailId
             * @param data
             * @constructor
             */
            angular.extend(ReportAttachment.prototype, classResource.prototype);
            ReportAttachment.prototype.eskManager = {prefixRoute: 'report-attachments'};

            function ReportAttachment(this: NgReportAttachment, reportId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, reportId: reportId};
            }

            /**
             * Extend the existing reportAttachment with new data
             *
             * @param data
             * @returns {Object}
             */
            ReportAttachment.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isObject(this.media)) {
                    this.media = mediaManager.create(this.media);
                }

                return this;
            };

            /**
             * Sanitize object ReportAttachment before send to API
             *
             * @returns {Object}
             */
            ReportAttachment.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                utilsManager.flattenIdForAttribute(sanitizeObject, 'media');

                return sanitizeObject;
            };

            // @ts-ignore
            return new ReportAttachmentManager();
        }
    })(angularJS);
}
