import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEskModalConfigurationFacebookCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EskModalConfigurationFacebookCtrl', Controller);

        /**
         * Controller to choose page
         *
         * @constructor
         * @param fbPages
         */
        Controller.$inject = ['fbPages'];
        function Controller(this: any, fbPages: unknown[]) {
            const $ctrl = this;

            $ctrl.fbPages = fbPages;
            $ctrl.pageSelected = $ctrl.fbPages[0];
        }
    })(angularJS);
}
