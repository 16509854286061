import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {BonvisiteVenteFactory} from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.factory';
import {BonvisiteVentesApiService} from '@models/bonvisites/bonvisite/ventes/bonvisite-ventes.api.service';
import {
    ICBonvisiteVentesApi
} from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.interfaces';
import CBonvisiteVentes from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CBonvisiteVentesFactory {
    private _bonvisiteVenteFactory = inject(BonvisiteVenteFactory);
    private _bonvisiteVentesApiService = inject(BonvisiteVentesApiService);

    create(cBonvisiteVentesApi: ICBonvisiteVentesApi): CBonvisiteVentes {
        const cBonvisiteVentes = new CBonvisiteVentes();

        cBonvisiteVentes.links = new Links(cBonvisiteVentesApi._links);
        cBonvisiteVentes.page = cBonvisiteVentesApi.page;
        cBonvisiteVentes.pages = cBonvisiteVentesApi.pages;
        cBonvisiteVentes.perPage = cBonvisiteVentesApi.limit;
        cBonvisiteVentes.total = cBonvisiteVentesApi.total;

        if (cBonvisiteVentesApi._embedded?.items) {
            cBonvisiteVentesApi._embedded.items.filter(bonvisiteVenteApi => bonvisiteVenteApi)
                .map(bonvisiteVenteApi => cBonvisiteVentes.results.push(this._bonvisiteVenteFactory.create(bonvisiteVenteApi)));
        }

        return cBonvisiteVentes;
    }

    getByLink$(link: string): Observable<CBonvisiteVentes> {
        return this._bonvisiteVentesApiService.getCollectionByLink$(link).pipe(map(cBonvisiteVentesApi => this.create(cBonvisiteVentesApi)));
    }
}
