import angularJS from '@shared/angularJS/global.ng';
import {capitalize} from 'lodash';
import COLORS from '@core/colors';
import {IModule, IQService} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgEtudeStatisticsManager} from '@legacy/app/managers/managers';
import {EtudeService} from '@models/etudes/etude/etude.service';
import DateFormat from '@shared/date/date.format';
import Vente from '@models/ventes/vente/vente.model';

export default function getEskChartNegociationRevenus(module: IModule): void {
    ((angular) => {
        'use strict';

        /**
         * @example <esk-chart-negociation-revenus></esk-chart-negociation-revenus>
         */
        module.component('eskChartNegociationRevenus', {
            bindings: {
                dateDebut: '=',
                dateFin: '=',
                user: '=',
                getStatistics: '=?',
                honorairesHorsTaxe: '=',
                refresh: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/chart.negociation-revenus.html'
        });

        /**
         * Display chart of negociation revenus
         *
         * @param EtudeStatisticsManager
         * @param Ng2EtudeService
         * @param $q
         * @param EskUtils
         * @constructor
         */
        Controller.$inject = ['EtudeStatisticsManager', 'Ng2EtudeService', '$q', 'EskUtils'];
        function Controller(this: any,
                            etudeStatisticsManager: NgEtudeStatisticsManager,
                            ng2EtudeService: EtudeService,
                            $q: IQService,
                            eskUtils: NgEskUtils) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.network = currentEtude.mainNetwork);

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.refresh = refresh;
                $ctrl.refresh();
            }

            /**
             * Refresh chart
             */
            function refresh() {
                const deferredGetStatistics = $q.defer();
                const userId: number = angular.isObject($ctrl.user) ? $ctrl.user.id : undefined!;

                $ctrl.loading = true;
                $ctrl.getStatistics = () => deferredGetStatistics.promise;
                DateFormat.razTime($ctrl.dateDebut)
                DateFormat.razTime($ctrl.dateFin)
                etudeStatisticsManager.getEtudeNegociationRevenus({
                    dateDebut: DateFormat.dateFromDate($ctrl.dateDebut),
                    dateFin: DateFormat.dateFromDate($ctrl.dateFin),
                    honorairesHorsTaxe: $ctrl.honorairesHorsTaxe,
                    userId: userId,
                }).then(statistics => {
                    const barChartColors = {solo: COLORS.archive.vente.solo, duo: COLORS.archive.vente.duo};
                    const months = eskUtils.getPeriodByMonths($ctrl.dateDebut, $ctrl.dateFin);
                    const datasetBase = {
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderWidth: {top: 2, right: 0, bottom: 2, left: 0},
                        data: [] as number[],
                    };
                    const datasetSolo = {...{label: 'Solo', backgroundColor: barChartColors.solo}, ...datasetBase};
                    const datasetDuo = {...{label: 'Duo', backgroundColor: barChartColors.duo}, ...datasetBase};
                    const barChartData = {
                        datasets: [],
                        labels: months.map(month => capitalize(DateFormat.toJSON(month, 'MMM YY'))),
                        noty: {
                            honorairesTotal: statistics.honorairesTotal,
                            nombreVentes: statistics.nombreSolo + statistics.nombreDuo + statistics.nombreDuoNetwork,
                        },
                    };

                    // @ts-ignore
                    barChartData.noty.honorairesMoyen = barChartData.noty.honorairesTotal / barChartData.noty.nombreVentes
                    statistics.date = DateFormat.toDate();
                    [
                        {
                            datasetInitial: datasetSolo,
                            nombreLabel: 'nombreSolo',
                            raisonArchive: Vente.compromisTypes.SOLO,
                        },
                        {datasetInitial: datasetDuo, nombreLabel: 'nombreDuo', raisonArchive: Vente.compromisTypes.DUO},
                        {datasetInitial: datasetDuo, nombreLabel: 'nombreDuoNetwork', raisonArchive: 'duo_network'},
                    ].map(datasetBrowsing => {
                        const nombreLabel = statistics[datasetBrowsing.nombreLabel];
                        let indexDataset = 0;

                        while (true) {
                            const dataset = angular.copy(datasetBrowsing.datasetInitial);

                            months.map(month => {
                                const dataMonth = statistics.data[DateFormat.dateFromDate(month, {withDay: false})];
                                let honoraires = 0;

                                if (angular.isObject(dataMonth) && angular.isObject(dataMonth[datasetBrowsing.raisonArchive])) {
                                    honoraires = dataMonth[datasetBrowsing.raisonArchive].honoraires[indexDataset];
                                }
                                dataset.data.push(honoraires);
                            });
                            if (indexDataset < nombreLabel) {
                                (barChartData.datasets as unknown[]).push(dataset);
                                indexDataset++;
                            } else {
                                break;
                            }
                        }
                    });
                    $ctrl.barNegociationRevenus = {
                        date: statistics.date,
                        data: barChartData,
                        options: {
                            plugins: {legend: {display: false}},
                            responsive: true,
                            scales: {x: {stacked: true}, y: {stacked: true}},
                        },
                        type: 'bar',
                    };
                    deferredGetStatistics.resolve(statistics);
                }).finally(() => $ctrl.loading = false);
            }
        }
    })(angularJS);
}
