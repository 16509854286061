import {
    IUserClientMetadataConfig, IUserClientMetadataConfigBoxes, IUserClientMetadataConfigDashboard,
    IUserClientMetadataConfigWallet, IUserClientMetadataConfigWalletRoute, IUserClientMetadataConfigWalletRouteCode
} from '@models/users/user/client-metadata/user-client-metadata.interfaces';
import {DisplayMode} from '@shared/constants';
import Filter from '@models/filters/filter/filter.model';

export default class UserClientMetadata {
    private _boxes = {} as IUserClientMetadataConfigBoxes;
    private _dashboard = {} as IUserClientMetadataConfigDashboard;
    private _wallet = {} as IUserClientMetadataConfigWallet;

    constructor() {
        this.cleanConfig();
    }

    get boxes(): IUserClientMetadataConfigBoxes {
        return this._boxes;
    }

    set boxes(value: IUserClientMetadataConfigBoxes) {
        this._boxes = value;
    }

    get config(): IUserClientMetadataConfig {
        return {
            ...this._wallet,
            ...{boxes: {...this._boxes}, dashboard: {...this._dashboard}},
        };
    }

    set config(value: IUserClientMetadataConfig) {
        this._boxes = value?.boxes ?? {} as IUserClientMetadataConfigBoxes;
        this._dashboard = value?.dashboard ?? {} as IUserClientMetadataConfigDashboard;
        this._wallet = value || {} as IUserClientMetadataConfigWallet;
        this.cleanConfig();
    }

    get dashboard(): IUserClientMetadataConfigDashboard {
        return this._dashboard;
    }

    set dashboard(value: IUserClientMetadataConfigDashboard) {
        this._dashboard = value;
    }

    cleanConfig(): void {
        [
            [Filter.routes.GET_ETUDE_DEMANDEURS, Filter.codes.WALLET_CURRENT],
            [Filter.routes.GET_ETUDE_ESTIMATIONS, Filter.codes.WALLET_CURRENT],
            [Filter.routes.GET_ETUDE_LOCATIONS, Filter.codes.WALLET_CURRENT],
            [Filter.routes.GET_ETUDE_VENTES, Filter.codes.WALLET_CURRENT],
            [Filter.routes.GET_LOCATIONS, Filter.codes.BOX_SEARCH],
            [Filter.routes.GET_RECHERCHE_RESULTATS, Filter.codes.WALLET_CURRENT],
            [Filter.routes.GET_VENTES, Filter.codes.BOX_SEARCH],
            [Filter.routes.GET_VENTES, Filter.codes.ESTIMATIONS_REFERENCES],
        ].filter(([route, code]) => !((this._wallet[route] as IUserClientMetadataConfigWalletRoute)?.[code] as IUserClientMetadataConfigWalletRouteCode)).forEach(([route, code]) => {
            if (!this._wallet[route]) {
                this._wallet[route] = {} as IUserClientMetadataConfigWalletRoute;
            }

            ((this._wallet[route] as IUserClientMetadataConfigWalletRoute)[code] as IUserClientMetadataConfigWalletRouteCode) = {view: DisplayMode.Table};
        });
    }

    getWallet(route: string, code: string): IUserClientMetadataConfigWalletRouteCode {
        return (((this._wallet[route] as IUserClientMetadataConfigWalletRoute)?.[code] as IUserClientMetadataConfigWalletRouteCode) || {} as IUserClientMetadataConfigWalletRouteCode);
    }

    setWallet(route: string, code: string, value: IUserClientMetadataConfigWalletRouteCode): void {
        if (!this._wallet[route]) {
            this._wallet[route] = {} as IUserClientMetadataConfigWalletRoute;
        }

        ((this._wallet[route] as IUserClientMetadataConfigWalletRoute)[code] as IUserClientMetadataConfigWalletRouteCode) = value;
    }
}
