import {inject, Injectable} from '@angular/core';
import BonvisiteVente from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.model';
import {IBonvisiteVenteApi} from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.interfaces';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BonvisiteVentesApiService} from '@models/bonvisites/bonvisite/ventes/bonvisite-ventes.api.service';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class BonvisiteVenteFactory {
    private _bonvisiteVentesApiService = inject(BonvisiteVentesApiService);

    create(bonvisiteVenteApi: IBonvisiteVenteApi): BonvisiteVente {
        const bonvisiteVente = this.createVirgin(bonvisiteVenteApi.venteUuid);

        if (bonvisiteVenteApi._links?.vente) {
            bonvisiteVente.linkVente = bonvisiteVenteApi._links.vente.href;
        }

        return bonvisiteVente;
    }

    createFromVente(vente: Vente): BonvisiteVente {
        const bonvisiteVente = this.createVirgin(vente.uuid);

        bonvisiteVente.linkVente = vente.linkSelf;

        return bonvisiteVente;
    }

    createVirgin(uuid: string): BonvisiteVente {
        return new BonvisiteVente(uuid);
    }

    delete$(bonvisite: Bonvisite, bonvisiteVente: BonvisiteVente): Observable<void> {
        return this._bonvisiteVentesApiService.delete$(bonvisite.uuid, bonvisiteVente.uuid);
    }

    forApi(bonvisite: BonvisiteVente): IBonvisiteVenteApi {
        return {venteUuid: bonvisite.uuid} as IBonvisiteVenteApi;
    }

    save$(bonvisite: Bonvisite, bonvisiteVente: BonvisiteVente): Observable<BonvisiteVente> {
        return this._bonvisiteVentesApiService.save$(bonvisite.uuid, this.forApi(bonvisiteVente)).pipe(map(bonvisiteVenteApi => this.create(bonvisiteVenteApi)));
    }
}
