import angularJS from '@shared/angularJS/global.ng';
import {remove} from 'lodash';
import {IModule, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgCommuneManager} from '@legacy/app/managers/managers';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {take, tap} from 'rxjs/operators';
import {UserService} from '@models/users/user/user.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

export default function getVentesBoxSearch(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-ventes-box-search></esk-ventes-box-search>
         */
        module.run(template).component('eskVentesBoxSearch', {
            templateUrl: 'src/app/legacy/templates/ventes/components/box/search.html',
            controller: Controller,
        });

        /**
         * Select criteria of search
         *
         * @param Ng2RouterStateForNgService
         * @param Ng2UserService
         * @param SoqModalesManager
         * @param EskBox
         * @param Ng2EtudeService
         * @param CommuneManager
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'Ng2UserService', 'SoqModalesManager', 'EskBox', 'Ng2EtudeService', 'CommuneManager'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2UserService: UserService,
                            soqModalesManager: NgSoqModalesManager,
                            eskBox: NgEskBox,
                            ng2EtudeService: EtudeService,
                            communeManager: NgCommuneManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.openConfig = openConfig;
            $ctrl.onSelectLocalisationCommune = onSelectLocalisationCommune;
            $ctrl.onChangeLocalisationType = onChangeLocalisationType;
            $ctrl.submit = submit;
            $ctrl.box = eskBox.getOneByCode('ventes-search');
            $ctrl.NATURES = Dictionary.names.NATURES;

            /**
             * Initialization method
             */
            function $onInit() {
                ng2UserService.getCurrentCommune$().pipe(take(1)).subscribe(currentUserCommune => {
                    ng2UserService.last$.subscribe(currentUser => {
                        ng2EtudeService.last$.pipe(tap(currentEtude => $ctrl.currentEtude = currentEtude)).subscribe(_ => {
                            $ctrl.searchs = {
                                rapidSearchType: 'criteria',
                                communes: [communeManager.createFromNg2(currentUserCommune)],
                                criteria: {
                                    natures: [currentUser.settings.ventes.defaultNature],
                                    rapidSearchType: 'criteria',
                                },
                                reference: {rapidSearchType: 'reference'},
                                localisation: {rapidSearchType: 'localisation'},
                            };
                            update();
                            $ctrl.onSelectLocalisationCommune();
                        });
                    });
                });
            }

            /**
             * Open configuration
             */
            function openConfig() {
                soqModalesManager.open('VentesModalBoxSearch').then(update);
            }

            /**
             * On select commune on localisation search
             */
            function onSelectLocalisationCommune() {
                $ctrl.searchs.localisation.rapidSearchLocalisationType = 'adresse';
                $ctrl.onChangeLocalisationType();
            }

            /**
             * On change localisation type on localisation search
             */
            function onChangeLocalisationType() {
                $ctrl.searchs.localisation.quartiers = [];
                $ctrl.searchs.localisation.voie = undefined;
                $ctrl.searchs.localisation.nomVoie = undefined;
            }

            /**
             * Submit search
             */
            function submit() {
                const search = angular.copy($ctrl.searchs[$ctrl.searchs.rapidSearchType]);

                if ($ctrl.searchs.rapidSearchType === 'criteria' || ($ctrl.searchs.rapidSearchType === 'localisation' && $ctrl.searchs.localisation.rapidSearchLocalisationType === 'adresse')) {
                    search.communes = $ctrl.searchs.communes;
                }

                if (!angular.isArray(search.natures) || !angular.isObject(search.natures[0])) {
                    search.natures = [];
                }

                remove(search.communes, commune => commune === null);

                ng2RouterStateForNgService.navigateByUrl('/app/ventes/search', {search: search});
            }

            /**
             * Update config
             */
            function update() {
                $ctrl.searchs.reference.reference = $ctrl.box.config.byReference.withCRPCEN ? ($ctrl.currentEtude.crpcen + '-') : '';
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/ventes/components/box/search/container.html', '<esk-ventes-box-search></esk-ventes-box-search>');
        }
    })(angularJS);
}
