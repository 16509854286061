<div class="tw-flex tw-flex-col tw-min-h-80 tw-h-[70vh] tw-w-[calc(100vw_-_2rem)] sm:tw-w-[33rem] md:tw-w-[40rem] lg:tw-w-[50rem] tw-space-y-5">
  @if (actualite$ | async; as actualite) {
    <div>
      <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-700">{{actualite.titre}}</h3>

      {{actualite.date | date}}
    </div>

    <app-iframe [src]="API_BASE_PATH_URL + actualite.linkContenu" class="tw-flex-1 tw-block"/>

    @if (!actualite.read) {
      @if (waiting$ | async; as waiting) {
        <div class="tw-flex tw-justify-end tw-gap-4">
          <button type="button" class="tw-btn-secondary-default" (click)="next(actualite)">Revoir plus tard</button>

          <button type="button" class="tw-btn-primary-info" (click)="read(actualite)">Ok</button>
        </div>
      } @else {
        <app-loader class="tw-block"/>
      }
    }
  } @else {
    <app-loader class="tw-block tw-mr-8"/>

    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>

    <app-loader class="tw-block"/>
  }
</div>
