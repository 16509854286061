export default `
<div class="details-item">
  <div class="row">
    <div class="col-sm-6 text-right">
      <h4 class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.interactivePremiereOffre |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.interactivePremiereOffre % 1 !== 0)}}
      </h4>

      <p>Première offre</p>
    </div>

    <div class="col-sm-6">
      <h5 class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.fraisActe |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
      </h5>

      <p>Frais d’acte</p>
    </div>
  </div>

  <div class="row">
    <div
      ng-if="!!$ctrl.dossier._esk.infosPricing.interactivePasOffre && $ctrl.dossier._esk.infosPricing.interactivePasOffre > 0"
      class="col-sm-6 text-right">
      <h5 class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.interactivePasOffre |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.interactivePasOffre % 1 !== 0)}}
      </h5>

      <p>Pas de l'offre</p>
    </div>

    <div ng-if="!!$ctrl.dossier.interactiveDateFin" class="col-sm-6">
      <h5 class="text-bold">
        <app-ng2-date [date]="$ctrl.dossier.interactiveDateFin" format="shortDate"></app-ng2-date> à
        <app-ng2-date [date]="$ctrl.dossier.interactiveDateFin" format="shortTime"></app-ng2-date>
      </h5>

      <p>Date et heure de fin</p>
    </div>
  </div>

  <div class="row">
    <div ng-if="$ctrl.dossier._esk.infosPricing.interactiveSystemeEncheres" class="col-sm-6 text-right">
      <h5 class="text-bold">{{$ctrl.dossier._esk.infosPricing.interactiveSystemeEncheres.label}}</h5>

      <p>Système d'enchères</p>
    </div>
  </div>
</div>
`;
