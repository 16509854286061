import {inject, Injectable} from '@angular/core';
import DossierExportQualifiedContact
    from '@models/dossiers/dossier/exports/export/contacts/contact/dossier-export.qualified-contact.model';
import {
    IDossierExportQualifiedContactApi
} from '@models/dossiers/dossier/exports/export/contacts/contact/dossier-export.qualified-contact.interfaces';
import {
    DossierExportQualifiedContactFactory
} from '@models/dossiers/dossier/exports/export/contacts/contact/dossier-export.qualified-contact.factory';

@Injectable({providedIn: 'root'})
export class DossierExportQualifiedContactsFactory {
    private _dossierExportQualifiedContactFactory = inject(DossierExportQualifiedContactFactory);

    create(dossierExportQualifiedContactApis: IDossierExportQualifiedContactApi[] = []): DossierExportQualifiedContact[] {
        return dossierExportQualifiedContactApis.map(dossierExportQualifiedContactApi => this._dossierExportQualifiedContactFactory.create(dossierExportQualifiedContactApi));
    }
}
