import {Injectable} from '@angular/core';
import {v4 as uuidV4} from 'uuid';

@Injectable({providedIn: 'root'})
export class TextsService {
    static capitalize(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    uuid(): string {
        return (uuidV4 as () => string)();
    }
}
