<!-- https://angular.io/errors/NG0910 -->

@if (!options.sandbox) {
  <iframe [height]="options.height" [width]="options.width" [src]="safeResourceUrl"></iframe>
}

@if (options.sandbox === 'allow-same-origin') {
  <iframe sandbox="allow-same-origin" [height]="options.height" [width]="options.width" [src]="safeResourceUrl">
  </iframe>
}
