import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEskSearchKeywords(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-search-keywords></esk-search-keywords>
         */
        module.component('eskSearchKeywords', {
            bindings: {
                help: '=',
                onChangeFilter: '=',
                resetKeywords: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/search/keywords.html'
        });

        /**
         * Search by keywords
         *
         * @constructor
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.removeKeywords = removeKeywords;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.resetKeywords = resetKeywords;
            }

            /**
             * Remove keywords and execute change filter
             */
            function removeKeywords() {
                $ctrl.resetKeywords();
                $ctrl.onChangeFilter();
            }

            /**
             * Reset keywords
             */
            function resetKeywords() {
                $ctrl.keywords = undefined;
            }
        }
    })(angularJS);
}
