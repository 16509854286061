import TextsSizeFormat from '@shared/texts/size/texts-size.format';
import {ToasterService} from '@shared/toaster/toaster.service';
import {IImageAcceptedOptions, IImagePercentageCoordinates} from '@shared/image/image.interfaces';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {UrlService} from '@shared/texts/url/url.service';
import {ModalService} from '@shared/modal/modal.service';

@Injectable({providedIn: 'root'})
export default class ImageService {
    static readonly acceptedMimeTypes = {
        JPEG: 'image/jpeg',
        JPG: 'image/jpg',
        PNG: 'image/png',
    };
    static readonly initImagePercentageCoordinates: IImagePercentageCoordinates = {x: 50, y: 50};
    static readonly ORIGIN_Y_KEY = 'originY';
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {TITLE_IMAGE_SELECTION: 'Sélection d\'une image'};

    static getMessageOverWeight(label: string, size: number, maxSize: number): string {
        return 'Le poids de votre ' + label + ' (' + TextsSizeFormat.convertToConvivial(size) + ') est supérieure à ' + TextsSizeFormat.convertToConvivial(maxSize) + '.';
    }

    static getMessageUnderHeight(label: string, height: number, minHeight: number): string {
        return 'La hauteur de votre ' + label + ' (' + height.toString() + ' pixels) est inférieure à ' + minHeight.toString() + ' pixels.';
    }

    static getMessageUnderWidth(label: string, width: number, minWidth: number): string {
        return 'La largeur de votre ' + label + ' (' + width.toString() + ' pixels) est inférieure à ' + minWidth.toString() + ' pixels.';
    }

    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _urlService = inject(UrlService);

    getCoordinatesFromUrl(url = ''): IImagePercentageCoordinates {
        const originYValue = +this._urlService.getQueryparamValue(url, ImageService.ORIGIN_Y_KEY);

        if (!originYValue || isNaN(originYValue)) {
            return undefined!;
        }

        return {y: originYValue} as IImagePercentageCoordinates;
    }

    getDimensions$(file: File): Observable<{ height: number; width: number }> {
        return this.load$(file).pipe(map(image => ({height: image.height, width: image.width})));
    }

    isAccepted$(file: File, options: IImageAcceptedOptions): Observable<boolean> {
        if (file.size > options.maxSize!) {
            if (options.messageWithValidation) {
                return this._modalService.openInformation$({
                    comments: ImageService.getMessageOverWeight(options.labelImageType!, file.size, options.maxSize!),
                    title: ImageService.messages.TITLE_IMAGE_SELECTION,
                    status: ModalService.status.WARNING,
                }).pipe(map(_ => false));
            } else {
                this._toasterService.warning(ImageService.messages.TITLE_IMAGE_SELECTION, ImageService.getMessageOverWeight(options.labelImageType!, file.size, options.maxSize!));

                return of(false);
            }
        }

        return this.getDimensions$(file).pipe(
            switchMap(dimensions => {
                if (dimensions.height < options.minHeight!) {
                    if (options.messageWithValidation) {
                        return this._modalService.openInformation$({
                            comments: ImageService.getMessageUnderHeight(options.labelImageType!, dimensions.height, options.minHeight!),
                            title: ImageService.messages.TITLE_IMAGE_SELECTION,
                            status: ModalService.status.WARNING,
                        }).pipe(map(_ => false));
                    } else {
                        this._toasterService.warning(ImageService.messages.TITLE_IMAGE_SELECTION, ImageService.getMessageUnderHeight(options.labelImageType!, dimensions.height, options.minHeight!));

                        return of(false);
                    }
                }

                if (dimensions.width < options.minWidth!) {
                    if (options.messageWithValidation) {
                        return this._modalService.openInformation$({
                            comments: ImageService.getMessageUnderWidth(options.labelImageType!, dimensions.width, options.minWidth!),
                            title: ImageService.messages.TITLE_IMAGE_SELECTION,
                            status: ModalService.status.WARNING,
                        }).pipe(map(_ => false));
                    } else {
                        this._toasterService.warning(ImageService.messages.TITLE_IMAGE_SELECTION, ImageService.getMessageUnderWidth(options.labelImageType!, dimensions.width, options.minWidth!));

                        return of(false);
                    }
                }

                return of(true);
            }),
        );
    }

    // Impossible à tester sans avoir un réel File
    load$(file: File): Observable<HTMLImageElement> {
        const imageSource = new Subject<HTMLImageElement>();
        // @todo Rechercher "FileReader" et utiliser cette méthode
        const imageReader = new FileReader();

        imageReader.onload = evt => {
            const image = new Image();

            image.onload = () => {
                imageSource.next(image);
                imageSource.complete();
            };
            image.src = evt.target!.result as string;
        };
        imageReader.readAsDataURL(file);

        return imageSource.asObservable();
    }
}
