export default `
<div ng-switch="$ctrl.loading">
  <p ng-switch-when="true" class="tw-text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
  </p>

  <div ng-switch-default class="table-responsive">
    <table class="table table-hover">
      <thead>
      <tr class="row">
        <th class="col-sm-3">Nature</th>

        <th class="col-sm-2 tw-text-center">Ventes {{$ctrl.years[0]}}</th>

        <th class="col-sm-2 tw-text-center">Ventes {{$ctrl.years[1]}}</th>

        <th class="col-sm-2 tw-text-center">Évolution</th>

        <th class="col-sm-3 tw-text-center">
          Durée moyenne

          <a uib-popover="Nombre moyen de jours pour réaliser une vente entre la date de début de mandat et la date de mise en compromis"
             popover-trigger="'outsideClick'" popover-placement="left" class="btn btn-o btn-null hidden-print">
            <span class="badge badge-info">?</span>
          </a>
        </th>
      </tr>
      </thead>

      <tr ng-repeat="nature in $ctrl.tableNatures.data" class="row">
        <td class="col-sm-3">{{nature.label}}</td>

        <td class="col-sm-2 tw-text-center">{{nature[$ctrl.years[0]]}}</td>

        <td class="col-sm-2 tw-text-center">{{nature[$ctrl.years[1]]}}</td>

        <td class="col-sm-2 tw-text-center">{{nature.evolution | currency:'%':0}}</td>

        <td class="col-sm-3 tw-text-center">{{nature.duree}}</td>
      </tr>

      <tr class="row table-total">
        <td class="col-sm-3">{{"Global" | translate}}</td>

        <td class="col-sm-2 tw-text-center">{{$ctrl.tableNatures.total[$ctrl.years[0]]}}</td>

        <td class="col-sm-2 tw-text-center">{{$ctrl.tableNatures.total[$ctrl.years[1]]}}</td>

        <td class="col-sm-2 tw-text-center">{{$ctrl.tableNatures.total.evolution | currency:'%':0}}</td>

        <td class="col-sm-3 tw-text-center">{{$ctrl.tableNatures.total.duree}}</td>
      </tr>
    </table>
  </div>
</div>

<p class="text-right text-muted text-small">
  Mise à jour des données : <app-ng2-date [date]="$ctrl.tableNatures.date" format="shortDate"></app-ng2-date> à
  <app-ng2-date [date]="$ctrl.tableNatures.date" format="shortTime"></app-ng2-date>
</p>
`;
