import {inject, Injectable} from '@angular/core';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {combineLatest, Observable, of} from 'rxjs';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DossiersService {
    private _dossierService = inject(DossierService);

    getByLinks$(links: string[] = []): Observable<ADossier[]> {
        if (links.length <= 0) {
            return of([]);
        }

        return combineLatest(links.map(link => this._dossierService.getByLink$(link))).pipe(
            map(dossiers => dossiers.filter(dossier => dossier)),
        );
    }

    getInternes(dossiers: ADossier[] = []): ADossier[] {
        return dossiers.filter(dossier => dossier.interne);
    }
}
