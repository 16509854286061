import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import CReferences from '@models/references/collection/references.collection.model';
import {CReferencesService} from '@models/references/collection/references.collection.service';
import {IReferencesListOptions} from '@features/references/references.interfaces';
import Reference from '@models/references/reference/reference.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {ReferenceDropdownComponent} from '@features/references/reference/dropdown/reference.dropdown.component';
import {SortConst, SortDefinition} from '@shared/constants';

@Component({selector: 'app-references-list', templateUrl: 'references-list.component.html'})
export class AppReferencesListComponent implements OnDestroy, OnInit {
    static readonly initReferencesListOptions: IReferencesListOptions = {
        enabledColumnAdresse: false,
        enabledColumnCommune: false,
        enabledColumnDistance: false,
        enabledColumnLocalisation: true,
        enabledColumnPrixM2: true,
        nameSelection: 'references-list-selection',
        tris: {[CReferences.tris.DISTANCE]: SortConst.ASCENDING},
    };
    readonly changedTri = output<[string, SortDefinition]>();
    readonly clicked = output<IDropdownClicked>();
    readonly referencesSelected = output<Reference[]>();
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cReferencesService = inject(CReferencesService);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dropdownService = inject(DropdownService);
    private _cReferences$!: Observable<CReferences>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IReferencesListOptions = {...AppReferencesListComponent.initReferencesListOptions};
    private _pendingGetMore = false;
    private _superficieLabel!: string;

    get CREFERENCES_TRIS(): Record<string, string> {
        return CReferences.tris;
    }

    get cReferences$(): Observable<CReferences> {
        return this._cReferences$;
    }

    @Input({required: true})
    set cReferences$(value$: Observable<CReferences>) {
        this._cReferences$ = value$;
    }

    get options(): IReferencesListOptions {
        return this._options;
    }

    @Input()
    set options(value: IReferencesListOptions) {
        this._options = {...AppReferencesListComponent.initReferencesListOptions, ...value};
        this._options.tris = value.tris ?? AppReferencesListComponent.initReferencesListOptions.tris;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._options.mainNature!);
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));
        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<Reference>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.referencesSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }

    clickOnReference(htmlButtonElement: HTMLButtonElement, reference: Reference): void {
        this._dropdownService.open(htmlButtonElement, {component: ReferenceDropdownComponent, data: {reference}});
    }

    getMore(cReferences: CReferences): void {
        this._pendingGetMore = true;
        this._cReferencesService.addNext$(cReferences).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }

    isEnabled(reference: Reference): boolean {
        if (!this.options.filterEnabled) {
            return true;
        }

        return this.options.filterEnabled(reference);
    }
}
