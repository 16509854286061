import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEskPasserelleContrat(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-passerelle-contrat></esk-passerelle-contrat>
         */
        module.component('eskPasserelleContrat', {
            bindings: {passerelle: '='},
            templateUrl: 'src/app/legacy/templates/eskimmo/components/passerelle.contrat.html'
        });
    })(angularJS);
}
