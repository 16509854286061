import {inject, Injectable} from '@angular/core';
import {FilterFactory} from '@models/filters/filter/filter.factory';
import CFilters from '@models/filters/collection/filters.collection.model';
import {ICFiltersApi, ICFiltersQueryParameters} from '@models/filters/collection/filters.collection.interfaces';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {FiltersApiService} from '@models/filters/filters.api.service';
import {map} from 'rxjs/operators';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class CFiltersFactory {
    private _filterFactory = inject(FilterFactory);
    private _filtersApiService = inject(FiltersApiService);

    create<T extends IQueryParameters>(cFiltersApi: ICFiltersApi): CFilters<T> {
        const cFilters = new CFilters<T>();

        cFilters.links = new Links(cFiltersApi._links);
        cFilters.page = cFiltersApi.page;
        cFilters.pages = cFiltersApi.pages;
        cFilters.perPage = cFiltersApi.limit;
        cFilters.total = cFiltersApi.total;
        if (cFiltersApi._embedded?.items) {
            cFiltersApi._embedded.items.filter(filterApi => filterApi).map(filterApi => cFilters.results.push(this._filterFactory.create(filterApi)));
        }

        return cFilters;
    }

    get$<T extends IQueryParameters>(queryParameters?: ICFiltersQueryParameters): Observable<CFilters<T>> {
        return this._filtersApiService.getCollection$(queryParameters).pipe(map(cFiltersApi => this.create(cFiltersApi)));
    }
}
