@if (contactsGroup$ | async; as contactsGroup) {
  <span class="tw-flex tw-items-center">
    <span [ngPlural]="contactsGroup.members.length">
      <ng-template ngPluralCase="=1">1 contact</ng-template>
      <ng-template ngPluralCase="other">{{ contactsGroup.members.length }} contacts</ng-template>
    </span>
  </span>
} @else {
  <app-loader class="tw-inline-block tw-min-w-[6rem]"/>
}

