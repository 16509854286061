import {Directive, ElementRef, HostListener, inject, Input, OnInit} from '@angular/core';

@Directive({selector: 'textarea'})
export class AppFormTextareaAutoResizeDirective implements OnInit {
    private _elementRef = inject(ElementRef<HTMLTextAreaElement>);
    private _minHeight = 0;

    @Input()
    set minHeight(value: number) {
        this._minHeight = value;
    }

    ngOnInit(): void {
        setTimeout(() => this.adjustCustom(), 1);
    }

    @HostListener('input', ['$event.target'])
    @HostListener('cut', ['$event.target'])
    @HostListener('paste', ['$event.target'])
    @HostListener('change', ['$event.target'])
    adjustCustom(): void {
        const nativeElement = this._elementRef.nativeElement;

        nativeElement.style.minHeight = this._minHeight.toString() + 'px';
        // Permet de réduire éventuellement le champ si on enlève du contenu
        nativeElement.style.height = 'auto';
        nativeElement.style.height = (nativeElement.scrollHeight + 2).toString() + 'px';
    }
}
