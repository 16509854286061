import AModel from '@models/model.abstract';

export default class Passerelle extends AModel {
    static readonly normalizers = {PRECOM: 'precom'};
    static readonly types = {NEWSPAPER: 'newspaper', SOCIAL_NETWORK: 'social_network', WEBSITE: 'website'};
    private _allowNetwork!: boolean;
    private _configurationAfficherCommune!: boolean;
    private _configurationAfficherDpe!: boolean;
    private _configurationAfficherPrix!: boolean;
    private readonly _id: number;
    private _instantDiffusion!: boolean;
    private _name!: string;
    private _normalizer!: string;
    private _photosMaxNumber!: number;
    private _type!: string;
    private _urlLogo!: string;
    private _urlSite!: string;
    private _venteInteractive!: string;
    private _viaId!: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get allowNetwork(): boolean {
        return this._allowNetwork;
    }

    set allowNetwork(value: boolean) {
        this._allowNetwork = value;
    }

    get configurationAfficherCommune(): boolean {
        return this._configurationAfficherCommune;
    }

    set configurationAfficherCommune(value: boolean) {
        this._configurationAfficherCommune = value;
    }

    get configurationAfficherDpe(): boolean {
        return this._configurationAfficherDpe;
    }

    set configurationAfficherDpe(value: boolean) {
        this._configurationAfficherDpe = value;
    }

    get configurationAfficherPrix(): boolean {
        return this._configurationAfficherPrix;
    }

    set configurationAfficherPrix(value: boolean) {
        this._configurationAfficherPrix = value;
    }

    get id(): number {
        return this._id;
    }

    get instantDiffusion(): boolean {
        return this._instantDiffusion;
    }

    set instantDiffusion(value: boolean) {
        this._instantDiffusion = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get normalizer(): string {
        return this._normalizer;
    }

    set normalizer(value: string) {
        this._normalizer = value;
    }

    get photosMaxNumber(): number {
        return this._photosMaxNumber;
    }

    set photosMaxNumber(value: number) {
        this._photosMaxNumber = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        if (!Passerelle.types[value?.toUpperCase()]) {
            return;
        }

        this._type = value;
    }

    get urlLogo(): string {
        return this._urlLogo;
    }

    set urlLogo(value: string) {
        this._urlLogo = value;
    }

    get urlSite(): string {
        return this._urlSite;
    }

    set urlSite(value: string) {
        this._urlSite = value;
    }

    get venteInteractive(): string {
        return this._venteInteractive;
    }

    set venteInteractive(value: string) {
        this._venteInteractive = value;
    }

    get viaId(): number {
        return this._viaId;
    }

    set viaId(value: number) {
        this._viaId = value;
    }

    isNewspaper(): boolean {
        return this.type === Passerelle.types.NEWSPAPER;
    }

    isSocialNetwork(): boolean {
        return this.type === Passerelle.types.SOCIAL_NETWORK;
    }

    isWebsite(): boolean {
        return this.type === Passerelle.types.WEBSITE;
    }
}
