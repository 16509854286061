import {Component, inject, Input, output} from '@angular/core';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {IFormFieldInput, IFormRadioOptions} from '@shared/form/form.interfaces';

@Component({selector: 'app-dictionary-radio', templateUrl: 'dictionary-radio.component.html'})
export class AppDictionaryRadioComponent {
    static readonly initDictionaryRadioOptions: IFormRadioOptions = {name: 'dictionaryRadio'};
    readonly selected = output<DictionaryItem>();
    private _dictionaryFactory = inject(DictionaryFactory);
    private _list: DictionaryItem[] = [];
    private _model!: DictionaryItem;
    private _options: IFormRadioOptions = {...AppDictionaryRadioComponent.initDictionaryRadioOptions};

    get list(): DictionaryItem[] {
        return this._list;
    }

    get model(): DictionaryItem {
        return this._model;
    }

    @Input()
    set model(value: DictionaryItem) {
        this._model = value;
    }

    @Input()
    set name(value: string) {
        this._list = this._dictionaryFactory.getByName(value).items;
        this._options.name = AppDictionaryRadioComponent.initDictionaryRadioOptions.name! + value;
    }

    get options(): IFormRadioOptions {
        return this._options;
    }

    onSelect(dictionaryItem: IFormFieldInput): void {
        this._model = dictionaryItem as DictionaryItem;
        this.selected.emit(this._model);
    }
}
