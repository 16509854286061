import {Component, Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'esk-contact', template: '<div appNgEskContact [type]="type"></div>'})
export class NgEskimmoContactComponent {
    @Input() type!: string;
}

@Directive({selector: '[appNgEskContact]'})
export class NgEskimmoContactDirective extends UpgradeComponent {
    @Input() type!: string;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskContact', elementRef, injector);
    }
}
