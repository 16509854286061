import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AppVenteViewComponent} from '@features/ventes/vente/view/vente.view.component';
import {AppVentesSelectComponent} from '@features/ventes/select/ventes.select.component';
import {EtudesModule} from '@features/etudes/etudes.module';
import {AppVentesListComponent} from '@features/ventes/list/ventes-list.component';
import {VenteDropdownComponent} from '@features/ventes/vente/dropdown/vente.dropdown.component';
import {AppVentePricingComponent} from '@features/ventes/vente/pricing/vente-pricing.component';
import {AppVenteStatutComponent} from '@features/ventes/vente/statut/vente-statut.component';
import {AppVentePrixHistoryComponent} from '@features/ventes/vente/price/prix-history/vente-prix-history.component';
import {AppVentePriceComponent} from '@features/ventes/vente/price/vente-price.component';
import {BiensModule} from '@features/biens/biens.module';
import {DCVenteSlideOverComponent} from '@features/ventes/vente/slide-over/vente.slide-over.component';
import {UsersModule} from '@features/users/users.module';
import {
    VenteOverviewActionsMainComponent
} from '@features/ventes/vente/actions/overview/vente-overview.actions-main.component';
import {
    VentePasserelleDropdownComponent
} from '@features/ventes/vente/passerelles/passerelle/dropdown/vente-passerelle.dropdown.component';
import {ContactsGroupModule} from '@features/contacts-group/contactsGroup.module';
import {DossierModule} from '@features/dossiers/dossier/dossier.module';
import {
    VentesPasserellesActionsSelectionComponent
} from '@features/ventes/passerelles/actions/ventes-passerelles.actions-selection.component';
import {
    VenteSearchOverviewActionsMainComponent
} from '@features/ventes/search/vente/actions/overview/vente-search-overview.actions-main.component';
import {AppVentesPasserellesListComponent} from '@features/ventes/passerelles/list/ventes-passerelles-list.component';
import {AppVentePriceM2Component} from '@features/ventes/vente/price/m2/vente-price-m2.component';

@NgModule({
    declarations: [
        AppVentesPasserellesListComponent,
        AppVentePriceComponent,
        AppVentePricingComponent,
        AppVentePrixHistoryComponent,
        AppVentesListComponent,
        AppVentesSelectComponent,
        AppVenteStatutComponent,
        AppVenteViewComponent,
        DCVenteSlideOverComponent,
        VenteDropdownComponent,
        VenteOverviewActionsMainComponent,
        VenteSearchOverviewActionsMainComponent,
        VentePasserelleDropdownComponent,
        VentesPasserellesActionsSelectionComponent
    ],
    exports: [
        AppVentesPasserellesListComponent,
        AppVentePriceComponent,
        AppVentePricingComponent,
        AppVentesListComponent,
        AppVentesSelectComponent,
        AppVenteStatutComponent,
        AppVenteViewComponent,
    ],
    imports: [AppVentePriceM2Component, BiensModule, CommonModule, ContactsGroupModule, DossierModule, EtudesModule, SharedModule, UsersModule],
})
export class VentesModule {
}
