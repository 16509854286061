@if (isDiagV1()) {
  @if (bien.performanceEnergetique.isDisponible()) {
    <div class="row">
      <div class="col-sm-6">
        <app-diagnostic-graphe-ce-legacy [value]="bien.performanceEnergetique.consommation" [nature]="bien.nature"/>
      </div>

      <div class="col-sm-6">
        <app-diagnostic-graphe-ges-legacy [value]="bien.performanceEnergetique.emissions" [nature]="bien.nature"/>
      </div>
    </div>
  } @else if (bien.performanceEnergetique.statutDpe) {
    Statut {{ bien.performanceEnergetique.statutDpe.label | lowercase }}
  } @else {
    Aucun statut
  }
}

@if (isDiagV2()) {
  <div>
    <app-diag-graphes-v2 [codeStatutDpe]="bien.performanceEnergetique.statutDpe.code" [options]="options"
                         [consommation]="bien.performanceEnergetique.consommation"
                         [consommationClasse]="bien.performanceEnergetique.consommationClasse?.code!"
                         [coutMax]="bien.performanceEnergetique.coutMax" [coutMin]="bien.performanceEnergetique.coutMin"
                         [dateReference]="bien.performanceEnergetique.dateReference"
                         [emissionsClasse]="bien.performanceEnergetique.emissionsClasse?.code!"
                         [emissions]="bien.performanceEnergetique.emissions"/>
  </div>
}
