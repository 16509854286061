import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewProprietairesService {
    private _isPartVisible!: boolean;

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isPartVisible = !!dossierBien.contactsGroup && (dossierBien.contactsGroup.members.length > 0 || dossierBien.contactsGroup.hasNom());
    }
}
