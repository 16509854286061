import AModel from '@models/model.abstract';
import DossierExportQualifiedContact
    from '@models/dossiers/dossier/exports/export/contacts/contact/dossier-export.qualified-contact.model';
import DossierExportMedia
    from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.model';
import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';

export default class DossierExport extends AModel {
    static readonly statuts = {DRAFT: 'draft', FAILED: 'failed', SENT: 'sent'};
    private _acquereurContacts: DossierExportQualifiedContact[] = [];
    private _colorStatut!: NoteoStatutColorType;
    private _contacts: DossierExportQualifiedContact[] = [];
    private _createdAt!: string;
    private _medias: DossierExportMedia[] = [];
    private _labelStatut!: string;
    private _linkCreator!: string;
    private _nbMedias = 0;
    private _processedAt!: string;
    private _proprietaireContacts: DossierExportQualifiedContact[] = [];
    private _statut!: string;

    get acquereurContacts(): DossierExportQualifiedContact[] {
        return this._acquereurContacts;
    }

    get colorStatut(): NoteoStatutColorType {
        return this._colorStatut;
    }

    get contacts(): DossierExportQualifiedContact[] {
        return this._contacts;
    }

    set contacts(value: DossierExportQualifiedContact[]) {
        this._contacts = value;
        this._acquereurContacts = this._contacts.filter(qualifiedContact => qualifiedContact.qualification === EtudeDossierQualifiedContact.qualifications.ACQUEREUR);
        this._proprietaireContacts = this._contacts.filter(qualifiedContact => qualifiedContact.qualification === EtudeDossierQualifiedContact.qualifications.PROPRIETAIRE);
    }

    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get medias(): DossierExportMedia[] {
        return this._medias;
    }

    set medias(value: DossierExportMedia[]) {
        this._medias = value;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get linkCreator(): string {
        return this._linkCreator;
    }

    set linkCreator(value: string) {
        this._linkCreator = value;
    }

    get processedAt(): string {
        return this._processedAt;
    }

    set processedAt(value: string) {
        this._processedAt = value;
    }

    get proprietaireContacts(): DossierExportQualifiedContact[] {
        return this._proprietaireContacts;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
        switch (this._statut) {
            case DossierExport.statuts.DRAFT :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'en cours d\'envoi';
                break;
            case DossierExport.statuts.FAILED :
                this._colorStatut = NoteoStatutColorConst.RED;
                this._labelStatut = 'en échec';
                break;
            case DossierExport.statuts.SENT :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'envoyé';
                break;
            default:
                this._labelStatut = undefined!;
        }
    }

    isDraft(): boolean {
        return this.statut === DossierExport.statuts.DRAFT;
    }

    isFailed(): boolean {
        return this.statut === DossierExport.statuts.FAILED;
    }

    isSent(): boolean {
        return this.statut === DossierExport.statuts.SENT;
    }
}
