import {inject, Injectable} from '@angular/core';
import {
    IVentePriceApi, IVentePriceQueryParameters, IVentePriceQueryParametersOptions
} from '@models/ventes/vente/price/vente-price.interfaces';
import VentePrice from '@models/ventes/vente/price/vente-price.model';
import {VentePriceApiService} from '@models/ventes/vente/price/vente-price.api.service';
import Vente from '@models/ventes/vente/vente.model';
import {Observable, of, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';

@Injectable({providedIn: 'root'})
export class VentePriceFactory {
    static readonly MAX_PRICE = 10000000000;
    private _ventePriceApiService = inject(VentePriceApiService);

    create(venteType: string, ventePriceApi: IVentePriceApi): VentePrice {
        const ventePrice = this.createVirgin();

        ventePrice.honoraires = ventePriceApi.honoraires;
        ventePrice.fraisActe = ventePriceApi.fraisActe;
        ventePrice.pourcentageHonoraires = ventePriceApi.pourcentageHonoraires;
        if (venteType === Vente.types.INTERACTIVE) {
            ventePrice.priceHNI = ventePriceApi.prixReserveHNI;
            ventePrice.priceNV = ventePriceApi.prixReserveNV;
            ventePrice.priceTFC = ventePriceApi.prixReserveTFC;
        } else if (venteType === Vente.types.SIMPLE) {
            ventePrice.priceHNI = ventePriceApi.prixHNI;
            ventePrice.priceNV = ventePriceApi.prixNV;
            ventePrice.priceTFC = ventePriceApi.prixTFC;
        } else if (venteType === Vente.types.VIAGER) {
            ventePrice.priceHNI = ventePriceApi.bouquetHNI;
            ventePrice.priceNV = ventePriceApi.bouquetNV;
            ventePrice.priceTFC = ventePriceApi.bouquetTFC;
        }

        return ventePrice;
    }

    createFromVente(vente: Vente): VentePrice {
        const ventePrice = this.createVirgin();
        let priceForHonoraires = 0;

        ventePrice.honoraires = vente.honorairesNego ?? 0;
        ventePrice.fraisActe = vente.fraisActe ?? 0;
        ventePrice.typeCharges = vente.typeCharges;
        if (vente.isInteractive()) {
            priceForHonoraires = vente.interactivePrixReserve ?? 0;
            ventePrice.priceNV = vente.interactivePrixReserve ?? 0;
        } else if (vente.isSimple()) {
            priceForHonoraires = vente.prixVente ?? 0;
            ventePrice.priceNV = vente.prixVente ?? 0;
        } else if (vente.isViager()) {
            priceForHonoraires = vente.viagerValeurBien ?? 0;
            ventePrice.priceNV = vente.viagerBouquet ?? 0;
        } else {
            ventePrice.priceNV = 0;
        }

        ventePrice.priceHNI = ventePrice.priceNV + ventePrice.honoraires;
        ventePrice.priceTFC = ventePrice.priceHNI + ventePrice.fraisActe;
        ventePrice.pourcentageHonoraires = priceForHonoraires === 0 ? 0 : Math.round((ventePrice.honoraires / priceForHonoraires) * 100 * 100) / 100;

        return ventePrice;
    }

    createVirgin(): VentePrice {
        return new VentePrice();
    }

    get$(venteType: string, queryParameters: IVentePriceQueryParameters): Observable<VentePrice> {
        let get$ = of(undefined as unknown as IVentePriceApi);

        if (venteType === Vente.types.INTERACTIVE) {
            if (queryParameters.prixReserve! > VentePriceFactory.MAX_PRICE) {
                return throwError(() => 'Prix de réserve trop élevé');
            }

            get$ = this._ventePriceApiService.getForInteractive$(queryParameters);
        } else if (venteType === Vente.types.SIMPLE) {
            if (queryParameters.prix! > VentePriceFactory.MAX_PRICE) {
                return throwError(() => 'Prix de vente trop élevé');
            }

            get$ = this._ventePriceApiService.getForSimple$(queryParameters);
        } else if (venteType === Vente.types.VIAGER) {
            if (queryParameters.bouquet! > VentePriceFactory.MAX_PRICE) {
                return throwError(() => 'Bouquet trop élevé');
            }

            get$ = this._ventePriceApiService.getForViager$(queryParameters);
        }

        return get$.pipe(map(ventePriceApi => {
            if (!ventePriceApi) {
                return undefined!;
            }

            return this.create(venteType, ventePriceApi);
        }));
    }

    getFromOffreachat$(vente: Vente, offreachat: Offreachat): Observable<VentePrice> {
        return this.get$(vente.type.code, this.getQueryParametersFromOffreachat(vente, offreachat));
    }

    getFromVente$(vente: Vente): Observable<VentePrice> {
        return this.get$(vente.type.code, this.getQueryParametersFromVente(vente));
    }

    getFromVenteArchiveInfos$(vente: Vente, venteArchiveInfos: VenteArchiveInfos): Observable<VentePrice> {
        return this.get$(vente.type.code, this.getQueryParametersFromVenteArchiveInfos(vente, venteArchiveInfos));
    }

    getFromVenteCompromis$(vente: Vente, venteCompromis: VenteCompromis): Observable<VentePrice> {
        return this.get$(vente.type.code, this.getQueryParametersFromVenteCompromis(vente, venteCompromis));
    }

    getQueryParametersFromOffreachat(vente: Vente, offreachat: Offreachat): IVentePriceQueryParameters {
        const queryParameters = this.getQueryParametersFromVente(vente);
        const montant = offreachat.montant > VentePriceFactory.MAX_PRICE ? VentePriceFactory.MAX_PRICE : offreachat.montant;

        queryParameters.prix = montant;
        queryParameters.valeurBien = montant;

        return queryParameters;
    }

    getQueryParametersFromVente(vente: Vente, ventePriceQueryParametersOptions = {} as IVentePriceQueryParametersOptions): IVentePriceQueryParameters {
        const queryParameters: IVentePriceQueryParameters = {
            codeNature: vente.bien.nature.code,
            typePrix: vente.typeCharges.code,
        };

        if (vente.bien.commune) {
            queryParameters.communeId = vente.bien.commune.id;
        }

        if (ventePriceQueryParametersOptions.keepFraisActe) {
            queryParameters.fraisActe = vente.fraisActe;
        }

        if (ventePriceQueryParametersOptions.keepHonoraires) {
            queryParameters.honoraires = vente.honorairesNego;
        }

        if (vente.type.code === Vente.types.INTERACTIVE) {
            throw new Error('CAS À GÉRER');
        } else if (vente.type.code === Vente.types.SIMPLE) {
            queryParameters.prix = vente.prixVente;
        } else if (vente.type.code === Vente.types.VIAGER) {
            queryParameters.bouquet = vente.viagerBouquet + (vente.typeCharges.code === Vente.chargesType.NET_VENDEUR ? 0 : vente.honorairesNego);
            queryParameters.valeurBien = vente.viagerValeurBien;
        }

        return queryParameters;
    }

    getQueryParametersFromVenteArchiveInfos(vente: Vente, venteArchiveInfos: VenteArchiveInfos): IVentePriceQueryParameters {
        const queryParameters: IVentePriceQueryParameters = this.getQueryParametersFromVente(vente);

        queryParameters.prix = venteArchiveInfos.prixCession;
        queryParameters.typePrix = Vente.chargesType.NET_VENDEUR;

        return queryParameters;
    }

    getQueryParametersFromVenteCompromis(vente: Vente, venteCompromis: VenteCompromis): IVentePriceQueryParameters {
        const queryParameters: IVentePriceQueryParameters = this.getQueryParametersFromVente(vente);

        queryParameters.prix = venteCompromis.prixCession;
        queryParameters.typePrix = Vente.chargesType.NET_VENDEUR;

        return queryParameters;
    }
}
