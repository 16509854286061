import Contact from '@models/contacts/contact/contact.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class Person extends Contact {
    static readonly sexes = {FEMME: 'femme', HOMME: 'homme'};
    static readonly titres = {MADAME: 'mme', MAITRE: 'me', MONSIEUR: 'm'};
    private _autresPrenoms!: string;
    private _communeNaissance!: string;
    private _dateNaissance!: string;
    private _etranger!: boolean;
    private _nationalite!: string;
    private _nom!: string;
    private _nomJeuneFille!: string;
    private _prenom!: string;
    private _profession!: string;
    private _titre!: DictionaryItem;

    get autresPrenoms(): string {
        return this._autresPrenoms;
    }

    set autresPrenoms(value: string) {
        this._autresPrenoms = value;
    }

    get communeNaissance(): string {
        return this._communeNaissance;
    }

    set communeNaissance(value: string) {
        this._communeNaissance = value;
    }

    get dateNaissance(): string {
        return this._dateNaissance;
    }

    set dateNaissance(value: string) {
        this._dateNaissance = value;
    }

    get etranger(): boolean {
        return this._etranger;
    }

    set etranger(value: boolean) {
        this._etranger = value;
    }

    get nationalite(): string {
        return this._nationalite;
    }

    set nationalite(value: string) {
        this._nationalite = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
        this.setConvivialName();
    }

    get nomJeuneFille(): string {
        return this._nomJeuneFille;
    }

    set nomJeuneFille(value: string) {
        this._nomJeuneFille = value;
    }

    get prenom(): string {
        return this._prenom;
    }

    set prenom(value: string) {
        this._prenom = value;
        this.setConvivialName();
    }

    get profession(): string {
        return this._profession;
    }

    set profession(value: string) {
        this._profession = value;
    }

    get titre(): DictionaryItem {
        return this._titre;
    }

    set titre(value: DictionaryItem) {
        this._titre = value;
        this.setConvivialName();
    }

    get type(): string {
        return Contact.type.PERSON;
    }

    isNew(): boolean {
        return super.isNew() || this.uuid === Person.statuts.NEW;
    }

    setConvivialName(): void {
        const convivialName: string[] = [];

        if (this.titre) {
            convivialName.push(this.titre.shortLabel);
        }

        if (this.prenom) {
            convivialName.push(this.prenom);
        }

        if (this.nom) {
            convivialName.push(this.nom);
        }

        this.convivialName = convivialName.length <= 0 ? Contact.NO_NAME : convivialName.join(' ');
    }
}
