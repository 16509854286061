import {inject, Injectable} from '@angular/core';
import CUsers from '@models/users/collection/users.collection.model';
import {BehaviorSubject, Observable, Subject, switchMap} from 'rxjs';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import {ICUsersQueryParameters} from '@models/users/collection/users.collection.interfaces';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {map, take, takeUntil, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CUsersService {
    private _cUsersFactory = inject(CUsersFactory);
    private _etudeService = inject(EtudeService);
    private _currentSource = new BehaviorSubject<CUsers>(undefined!);
    private readonly _endCurrentSubject = new Subject<void>();

    get current$(): Observable<CUsers> {
        return this._currentSource.asObservable();
    }

    addNextToCurrent$(): Observable<CUsers> {
        return this.current$.pipe(
            take(1),
            switchMap(cUsers => this._cUsersFactory.getNext$(cUsers).pipe(
                tap(newCUsers => cUsers.updateNext(newCUsers)),
                map(_ => cUsers),
            )),
        );
    }

    getEtude$(queryParameters?: ICUsersQueryParameters): Observable<CUsers> {
        return this._etudeService.last$.pipe(switchMap(etude => this._cUsersFactory.getEtude$(etude, queryParameters)));
    }

    initCurrent(queryParameters: ICUsersQueryParameters): void {
        this._endCurrentSubject.next();
        this._currentSource.next(undefined!);
        this._cUsersFactory.get$(queryParameters).pipe(
            takeUntil(this._endCurrentSubject),
        ).subscribe(currentCUsers => this._currentSource.next(currentCUsers));
    }

    search$(keyword?: string): Observable<CUsers> {
        return this._cUsersFactory.get$({keywords: keyword});
    }
}
