import {NgModule} from '@angular/core';
import {AppEtudeRaisonSocialeComponent} from '@features/etudes/etude/raison-sociale/etude-raison-sociale.component';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AppEtudeMapComponent} from '@features/etudes/etude/map/etude-map.component';
import {CommunesModule} from '@features/communes/communes.module';
import {AppEtudeSiteCardComponent} from '@features/etudes/sites/site/card/etude-site.card.component';
import {AppEtudesSitesMapComponent} from '@features/etudes/sites/map/etudes-sites.map.component';
import {AppEtudesSitesListComponent} from '@features/etudes/sites/list/etudes-sites.list.component';
import {UsersModule} from '@features/users/users.module';
import {
    AppEtudePasserellesListComponent
} from '@features/etudes/etude/passerelles/list/etude-passerelles-list.component';
import {AppEtudePasserelleComponent} from '@features/etudes/etude/passerelles/passerelle/etude-passerelle.component';

@NgModule({
    declarations: [
        AppEtudeMapComponent,
        AppEtudePasserelleComponent,
        AppEtudePasserellesListComponent,
        AppEtudeRaisonSocialeComponent,
        AppEtudeSiteCardComponent,
        AppEtudesSitesListComponent,
        AppEtudesSitesMapComponent,
    ],
    exports: [
        AppEtudeMapComponent,
        AppEtudePasserelleComponent,
        AppEtudePasserellesListComponent,
        AppEtudeRaisonSocialeComponent,
        AppEtudeSiteCardComponent,
        AppEtudesSitesListComponent,
        AppEtudesSitesMapComponent,
    ],
    imports: [CommonModule, CommunesModule, RouterModule, SharedModule, UsersModule],
})
export class EtudesModule {
}
