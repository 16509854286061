import Contact from '@models/contacts/contact/contact.model';

export default class EtudeDossierQualifiedContact {
    static readonly qualifications = {ACQUEREUR: 'acquereur', PROPRIETAIRE: 'proprietaire'};

    private _contact!: Contact;
    private _qualification!: string;

    get contact(): Contact {
        return this._contact;
    }

    set contact(value: Contact) {
        this._contact = value;
    }

    get qualification(): string {
        return this._qualification;
    }

    set qualification(value: string) {
        this._qualification = value;
    }
}
