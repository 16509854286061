import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {EstimationReferenceFactory} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import {ICEstimationReferencesApi} from '@models/estimations/estimation/references/collection/estimation-references.collection.interfaces';
import CEstimationReferences from '@models/estimations/estimation/references/collection/estimation-references.collection.model';
import {NgEstimationReferencesCollection} from '@legacy/app/managers/collections';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    EstimationReferencesApiService
} from '@models/estimations/estimation/references/estimation-references.api.service';

@Injectable({providedIn: 'root'})
export class CEstimationReferencesFactory {
    private _estimationReferenceFactory = inject(EstimationReferenceFactory);
    private _estimationReferencesApiService = inject(EstimationReferencesApiService);

    create(cEstimationReferencesApi: ICEstimationReferencesApi): CEstimationReferences {
        const cEstimationReferences = new CEstimationReferences();

        cEstimationReferences.links = new Links(cEstimationReferencesApi._links);
        cEstimationReferences.page = cEstimationReferencesApi.page;
        cEstimationReferences.pages = cEstimationReferencesApi.pages;
        cEstimationReferences.perPage = cEstimationReferencesApi.limit;
        cEstimationReferences.total = cEstimationReferencesApi.total;
        if (cEstimationReferencesApi._embedded?.items) {
            cEstimationReferences.results = cEstimationReferencesApi._embedded.items.filter(estimationReferenceApi => estimationReferenceApi)
                .map(estimationReferenceApi => this._estimationReferenceFactory.create(estimationReferenceApi));
        }

        return cEstimationReferences;
    }

    getNext$(cEstimationReferences: CEstimationReferences): Observable<CEstimationReferences> {
        return this._estimationReferencesApiService.getCollectionByLink$(cEstimationReferences.links.next)
            .pipe(map(cEstimationReferencesApi => this.create(cEstimationReferencesApi)));
    }

    ngCreate(ngEstimationReferencesCollection: NgEstimationReferencesCollection): CEstimationReferences {
        const cEstimationReferences = new CEstimationReferences();

        cEstimationReferences.links = new Links({
            first: {href: ngEstimationReferencesCollection.firstPageLink},
            last: {href: ngEstimationReferencesCollection.lastPageLink},
            next: {href: ngEstimationReferencesCollection.nextPageLink},
            previous: {href: ngEstimationReferencesCollection.previousPageLink},
            self: {href: ngEstimationReferencesCollection.currentPageLink},
        });
        cEstimationReferences.page = ngEstimationReferencesCollection.current;
        cEstimationReferences.pages = ngEstimationReferencesCollection.pages;
        cEstimationReferences.perPage = ngEstimationReferencesCollection.pageSize;
        cEstimationReferences.total = ngEstimationReferencesCollection.totalItems;
        if (ngEstimationReferencesCollection.collection) {
            cEstimationReferences.results = ngEstimationReferencesCollection.collection.filter(ngEstimationReference => ngEstimationReference)
                .map(ngEstimationReference => this._estimationReferenceFactory.ngCreate(ngEstimationReference));
        }

        return cEstimationReferences;
    }
}
