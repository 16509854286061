import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {IModel} from '@models/model.interfaces';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({selector: 'app-collection-selection', templateUrl: 'collection-selection.component.html'})
export class CollectionSelectionComponent implements OnDestroy, OnInit {
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _disabled = false;
    private _item!: IModel;
    private _listName!: string;
    private _model = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _titleDisabled!: string;

    get checked(): boolean {
        return this._collectionSelectionService.isSelected(this._listName, this._item);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set item(value: IModel) {
        this._item = value;
    }

    @Input()
    set listName(value: string) {
        this._listName = value;
    }

    get model(): boolean {
        return this._model;
    }

    set model(value: boolean) {
        this._model = value;
    }

    get titleDisabled(): string {
        return this._titleDisabled;
    }

    ngOnInit(): void {
        this._disabled = !this._collectionSelectionService.itemIsEnabled(this._listName, this._item);
        if (this._disabled) {
            this._titleDisabled = this._collectionSelectionService.itemTitleDisabled(this._listName, this._item);
        }
        this._collectionSelectionService.getListSelected$(this._listName)
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(_ => this._model = this._collectionSelectionService.isSelected(this._listName, this._item));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    toggle(): void {
        this._collectionSelectionService.toggle(this._listName, this._item);
    }
}
