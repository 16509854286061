export default `
<h3 class="description-title">Valeur vénale</h3>

<div class="description-details">
  <ng-switch on="(!!$ctrl.bienDossier.valeurVenaleMin && $ctrl.bienDossier.valeurVenaleMin > 0) || (!!$ctrl.bienDossier.valeurVenaleMax && $ctrl.bienDossier.valeurVenaleMax > 0)"
             ng-if="$ctrl.bienDossier.typeValeurVenale.code === $ctrl.ESTIMATION_VALEUR_VENALE_TYPES.FOURCHETTE" class="details-item">
    <h4 ng-switch-when="false" class="text-bold">Pas de valeur saisie</h4>

    <ng-switch
      on="!!$ctrl.bienDossier.valeurVenaleMin && $ctrl.bienDossier.valeurVenaleMin > 0 && !!$ctrl.bienDossier.valeurVenaleMax && $ctrl.bienDossier.valeurVenaleMax > 0"
      ng-switch-default>
      <ng-switch on="!!$ctrl.bienDossier.valeurVenaleMin && $ctrl.bienDossier.valeurVenaleMin > 0" ng-switch-when="false">
        <div ng-switch-when="true" class="item-price">
          A partir de

          <h4 class="text-bold">
            {{$ctrl.bienDossier.valeurVenaleMin | currency:'€':2 * ($ctrl.bienDossier.valeurVenaleMin % 1 !== 0)}}
          </h4>
        </div>

        <div ng-switch-default class="item-price">
          Jusqu'à

          <h4 class="text-bold">
            {{$ctrl.bienDossier.valeurVenaleMax | currency:'€':2 * ($ctrl.bienDossier.valeurVenaleMax % 1 !== 0)}}
          </h4>
        </div>
      </ng-switch>

      <div ng-switch-default class="item-price">
        Entre

        <h4 class="text-bold">
          {{$ctrl.bienDossier.valeurVenaleMin | currency:'€':2 * ($ctrl.bienDossier.valeurVenaleMin % 1 !== 0)}}
        </h4>

        et

        <h4 class="text-bold">
          {{$ctrl.bienDossier.valeurVenaleMax | currency:'€':2 * ($ctrl.bienDossier.valeurVenaleMax % 1 !== 0)}}
        </h4>
      </div>
    </ng-switch>
  </ng-switch>

  <div ng-if="$ctrl.bienDossier.typeValeurVenale.code !== $ctrl.ESTIMATION_VALEUR_VENALE_TYPES.FOURCHETTE"
       ng-switch="!!$ctrl.bienDossier.valeurVenale" class="details-item">
    <h4 ng-switch-when="false" class="text-bold">Pas de valeur saisie</h4>

    <div ng-switch-default class="item-price">
      <h4 class="text-bold">
        {{$ctrl.bienDossier.valeurVenale | currency:'€':2 * ($ctrl.bienDossier.valeurVenale % 1 !== 0)}}
      </h4>

      <span ng-if="!!$ctrl.dossier.pourcentageValeurVenale">
        + ou - {{$ctrl.bienDossier.pourcentageValeurVenale | currency:'%':0}}
      </span>
    </div>
  </div>
</div>
`;
