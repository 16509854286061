<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Agencement extérieur</h4>

  <div class="tw-bg-gray-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (isVerandaVisible) {
        <li>Véranda</li>
      }

      @if (isPiscineVisible) {
        <li>Piscine</li>
      }

      @if (isGarageVisible) {
        Garage
        @if (dossierBien.bien.surfaceGarage > 0) {
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{dossierBien.bien.surfaceGarage | textCurrency:'m²'}}
        }
      }

      @if (isTerrasseVisible) {
        <li>
          Terrasse
          @if (dossierBien.bien.surfaceTerrasse > 0) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
            {{dossierBien.bien.surfaceTerrasse | textCurrency:'m²'}}
          }
        </li>
      }

      @if (isBalconVisible) {
        <li>
          Balcon
          @if (dossierBien.bien.surfaceBalcon > 0) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
            {{dossierBien.bien.surfaceBalcon | textCurrency:'m²'}}
          }
        </li>
      }

      @if (isCourVisible) {
        <li>
          Cour
          @if (dossierBien.bien.surfaceCour > 0) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
            {{dossierBien.bien.surfaceCour | textCurrency:'m²'}}
          }
        </li>
      }

      @if (isAnnexeVisible) {
        <li>
          Annexe(s)
          @if (dossierBien.bien.surfaceAnnexe > 0) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
            {{dossierBien.bien.surfaceAnnexe | textCurrency:'m²'}}
          }
        </li>
      }

      @if (isNombrePlacesParkingVisible) {
        <li>
          {{dossierBien.bien.nombrePlacesParking}} Place{{dossierBien.bien.nombrePlacesParking > 1 ? 's' : ''}}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span> parking
        </li>
      }

      @if (isCiterneGazLocationVisible) {
        <li>Citerne de gaz en location</li>
      }

      @if (isCiterneGazPleineProprieteVisible) {
        <li>Citerne de gaz en pleine propriété</li>
      }

      @if (isCuveFuelAerienneVisible) {
        <li>Cuve à fuel aérienne</li>
      }

      @if (isCuveFuelEnterreeVisible) {
        <li>Cuve à fuel enterrée</li>
      }

      @if (isCuveInactiveDegazeeVisible) {
        <li>Cuve inactive dégazée</li>
      }

      @if (isCuveInactiveNeutralizeeVisible) {
        <li>Cuve inactive neutralisée</li>
      }
    </ul>

    @if (isCommentsAgencementExterieurVisible) {
      <div class="tw-text-sm tw-text-gray-700">{{dossierBien.bien.commentsAgencementExterieur}}</div>
    }
  </div>
</div>
