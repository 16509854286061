import AModel from '@models/model.abstract';

export default class VentePrixHistory extends AModel {
    private _createdAt!: string;
    private readonly _id: number;
    private _prixVente!: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get id(): number {
        return this._id;
    }

    get prixVente(): number {
        return this._prixVente;
    }

    set prixVente(value: number) {
        this._prixVente = value;
    }
}
