@if (contact$ | async; as contact) {
  <div class="tw-flex tw-space-x-3 tw-items-center">
    <div [class]="options.enabledClick ? 'tw-cursor-pointer' : ''" (click)="onClick(contact)">
      <app-contact-initiales [contact]="contact" class="tw-block tw-w-12 tw-text-xl"/>
    </div>

    <div class="tw-flex-1 tw-overflow-hidden">
      <div class="tw-text-base tw-font-semibold" [class]="options.enabledClick ? 'tw-cursor-pointer' : ''"
           (click)="onClick(contact)">
        <app-contact-convivial-name [contact]="contact" [options]="contactConvivialNameOptions"
                                    class="tw-block tw-truncate tw-w-full"/>
      </div>

      <app-text-phones [phones]="[contact.telephone, contact.mobile]"/>

      @if (contact.emailAddress) {
        <div>
          <a href="mailto:{{contact.emailAddress}}" target="_blank" [highlight]="options.highlight">
            {{ contact.emailAddress }}
          </a>
        </div>
      }
    </div>
  </div>
} @else {
  <app-loader class="tw-block" [classHeight]="'tw-h-20'"/>
}

