import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';
import DateFormat from '@shared/date/date.format';

export default class BienPerformanceEnergetique {
    static readonly DATE_MIN = '2006-01-01T00:00:00';
    static readonly DATE_V1_TO_V2 = '2021-07-01T00:00:00';
    private _comments!: string;
    private _consommation!: number;
    private _consommationClasse!: DictionaryItem | undefined;
    private _coutMax!: number;
    private _coutMin!: number;
    private _date!: string;
    private _dateReference!: string;
    private _emissions!: number;
    private _emissionsClasse!: DictionaryItem | undefined;
    private _statutDpe!: DictionaryItem;

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get consommation(): number {
        return this._consommation;
    }

    set consommation(value: number) {
        this._consommation = value;
    }

    get consommationClasse(): DictionaryItem | undefined {
        return this._consommationClasse;
    }

    set consommationClasse(value: DictionaryItem | undefined) {
        this._consommationClasse = value;
    }

    get coutMax(): number {
        return this._coutMax;
    }

    set coutMax(value: number) {
        this._coutMax = value;
    }

    get coutMin(): number {
        return this._coutMin;
    }

    set coutMin(value: number) {
        this._coutMin = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get dateReference(): string {
        return this._dateReference;
    }

    set dateReference(value: string) {
        this._dateReference = value;
    }

    get emissions(): number {
        return this._emissions;
    }

    set emissions(value: number) {
        this._emissions = value;
    }

    get emissionsClasse(): DictionaryItem | undefined {
        return this._emissionsClasse;
    }

    set emissionsClasse(value: DictionaryItem | undefined) {
        this._emissionsClasse = value;
    }

    get statutDpe(): DictionaryItem {
        return this._statutDpe;
    }

    set statutDpe(value: DictionaryItem) {
        this._statutDpe = value;
    }

    isDisponible(): boolean {
        return this._statutDpe?.code === DiagnosticStatutsConst.DISPONIBLE;
    }

    isV1ByDate(): boolean {
        return !this.date || DateFormat.diff(BienPerformanceEnergetique.DATE_V1_TO_V2, this.date) > 0;
    }
}
