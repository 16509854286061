import {inject, Injectable} from '@angular/core';
import CContacts from '@models/contacts/collection/contacts.collection.model';
import {Observable} from 'rxjs';
import {CContactsFactory} from '@models/contacts/collection/contacts.collection.factory';
import {map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CContactsService {
    private _cContactsFactory = inject(CContactsFactory);

    addNext$(cContacts: CContacts): Observable<CContacts> {
        return this._cContactsFactory.getNext$(cContacts).pipe(
            tap(newCContacts => cContacts.updateNext(newCContacts)),
            map(_ => cContacts),
        );
    }
}
