import angularJS from '@shared/angularJS/global.ng';
import {find, floor, max} from 'lodash';
import COLORS from '@core/colors';
import {IModule, IQService} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgDictionariesManager, NgEtudeStatisticsManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgItemDictionary} from '@legacy/app/managers/ressources';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {ChartData, ChartDataset, ChartType, TooltipItem} from 'chart.js';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import DateFormat from '@shared/date/date.format';
import Vente from '@models/ventes/vente/vente.model';

type TooltipItemNoty = TooltipItem<ChartType> & {dataset: {noty: {tooltip: {notCountInTotal: boolean}; year: number}}};

export default function getVentesChartNegociees(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-ventes-chart-negociees></esk-ventes-chart-negociees>
         */
        module.component('eskVentesChartNegociees', {
            bindings: {getStatistics: '=?'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/components/chart.negociees.html'
        });

        /**
         * Display chart of negociees
         *
         * @param EtudeStatisticsManager
         * @param DictionariesManager
         * @param Ng2EtudeService
         * @param VenteManager
         * @param $q
         * @param EskUtils
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['EtudeStatisticsManager', 'DictionariesManager', 'Ng2EtudeService', 'VenteManager', '$q', 'EskUtils', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            etudeStatisticsManager: NgEtudeStatisticsManager,
                            dictionariesManager: NgDictionariesManager,
                            ng2EtudeService: EtudeService,
                            venteManager: NgVenteManager,
                            $q: IQService,
                            eskUtils: NgEskUtils,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.years = [DateFormat.getYear(DateFormat.dateFromNow()) - 1, DateFormat.getYear(DateFormat.dateFromNow())];
            $ctrl.network = undefined!;
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.network = currentEtude.mainNetwork);
            $ctrl.venteManager = venteManager; // Défaut de conception concernant les dictionnaires attachés au manager

            /**
             * Initialization method
             */
            function $onInit() {
                const deferredGetStatistics = $q.defer();

                $ctrl.loading = true;
                $ctrl.getStatistics = () => deferredGetStatistics.promise;
                etudeStatisticsManager.getVentesNegociees().then(function (statistics) {
                    const raisonsArchiveVenteNegociee = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, true));
                    const raisonSolo = find(raisonsArchiveVenteNegociee, {code: Vente.archiveNegocieeRaisons.PORTEFEUILLE}) as NgItemDictionary;
                    const raisonDuo = find(raisonsArchiveVenteNegociee, {code: Vente.archiveNegocieeRaisons.RESEAU}) as NgItemDictionary;
                    const raisonAutres = find(raisonsArchiveVenteNegociee, {code: Vente.archiveNegocieeRaisons.AUTRES}) as NgItemDictionary;
                    const previousData = statistics.data[$ctrl.years[0]];
                    const currentData = statistics.data[$ctrl.years[1]];
                    const barChartColors = {
                        duoCurrent: COLORS.archive.vente.duo,
                        duoPrevious: COLORS.archive.vente.duoLight,
                        network: COLORS.archive.vente.network,
                        soloCurrent: COLORS.archive.vente.solo,
                        soloPrevious: COLORS.archive.vente.soloLight,
                    };
                    const barChartData: ChartData = {datasets: [], labels: DateFormat.monthsShort()};

                    if (angular.isObject($ctrl.network) && angular.isArray(currentData.network)) {
                        barChartData.datasets?.push({
                            backgroundColor: barChartColors.network,
                            data: currentData.network,
                            fill: true,
                            label: $ctrl.network.name,
                            noty: {year: $ctrl.years[1], tooltip: {display: $ctrl.years[1], notCountInTotal: true}},
                            order: 1,
                            type: 'line',
                        } as ChartDataset);
                    }

                    if (angular.isArray(previousData[raisonSolo.code]) || angular.isArray(previousData[raisonAutres.code])) {
                        barChartData.datasets?.push({
                            backgroundColor: barChartColors.soloPrevious,
                            data: eskUtils.getArraySum(previousData[raisonSolo.code], previousData[raisonAutres.code]),
                            label: raisonSolo.shortLabel,
                            noty: {year: $ctrl.years[0], tooltip: {}},
                            stack: $ctrl.years[0],
                        } as ChartDataset);
                    }

                    if (angular.isArray(currentData[raisonSolo.code]) || angular.isArray(currentData[raisonAutres.code])) {
                        barChartData.datasets?.push({
                            backgroundColor: barChartColors.soloCurrent,
                            data: eskUtils.getArraySum(currentData[raisonSolo.code], currentData[raisonAutres.code]),
                            label: raisonSolo.shortLabel,
                            noty: {year: $ctrl.years[1], tooltip: {}},
                            stack: $ctrl.years[1],
                        } as ChartDataset);
                    }

                    if (angular.isObject($ctrl.network) && angular.isArray(previousData[raisonDuo.code])) {
                        barChartData.datasets?.push({
                            backgroundColor: barChartColors.duoPrevious,
                            data: previousData[raisonDuo.code],
                            label: raisonDuo.shortLabel,
                            noty: {year: $ctrl.years[0], tooltip: {}},
                            stack: $ctrl.years[0],
                        } as ChartDataset);
                    }

                    if (angular.isObject($ctrl.network) && angular.isArray(currentData[raisonDuo.code])) {
                        barChartData.datasets?.push({
                            backgroundColor: barChartColors.duoCurrent,
                            data: currentData[raisonDuo.code],
                            label: raisonDuo.shortLabel,
                            noty: {year: $ctrl.years[1], tooltip: {}},
                            stack: $ctrl.years[1],
                        } as ChartDataset);
                    }

                    $ctrl.barVentesNegociees = {
                        data: barChartData,
                        date: DateFormat.toDate(statistics.date),
                        options: {
                            plugins: {
                                legend: {display: false},
                                tooltip: {
                                    callbacks: {
                                        footer: (context: TooltipItemNoty[]): string => {
                                            let addEtudeLabel = false;
                                            let total = 0;

                                            if (!$ctrl.network) {
                                                return undefined!;
                                            }

                                            for (let i = 0; i < context.length; i++) {
                                                if (context[i].dataset.noty.tooltip.notCountInTotal) {
                                                    if (context[i].dataset.noty.year === $ctrl.barVentesNegociees.options.plugins.tooltip.currentYear) {
                                                        addEtudeLabel = true;
                                                    }
                                                } else {
                                                    total += +context[i].formattedValue;
                                                }
                                            }

                                            return (total > 0) ? ('Total' + (addEtudeLabel ? ' de l\'étude' : '') + ' : ' + total) : undefined!;
                                        },
                                        label: (context: TooltipItemNoty): string => {
                                            return context.dataset.label + ' : ' + context.formattedValue;
                                        },
                                        title: (context: TooltipItemNoty[]): string => {
                                            $ctrl.barVentesNegociees.options.plugins.tooltip.currentYear = context[0].dataset.noty.year;

                                            return context[0].label + " " + $ctrl.barVentesNegociees.options.plugins.tooltip.currentYear;
                                        },
                                    },
                                    mode: 'x',
                                },
                            },
                            responsive: true,
                            scales: {x: {stacked: true}, y: {ticks: {}, stacked: true}},
                        },
                        type: 'bar',
                    };
                    $ctrl.barVentesNegociees.options.scales.y.ticks.stepSize = getStep();
                    $ctrl.barVentesNegociees.options.scales.y.max = $ctrl.barVentesNegociees.options.scales.y.ticks.stepSize * 5;
                    deferredGetStatistics.resolve(statistics);

                    /**
                     * Get step for graph
                     *
                     * @returns {number}
                     */
                    function getStep() {
                        let sumPrevious = eskUtils.getArraySum(previousData[raisonSolo.code], previousData[raisonAutres.code]);
                        let sumCurrent = eskUtils.getArraySum(currentData[raisonSolo.code], currentData[raisonAutres.code]);
                        let maxNetwork = 0;
                        let maxData: number;

                        if ($ctrl.network) {
                            sumPrevious = eskUtils.getArraySum(sumPrevious, previousData[raisonDuo.code]);
                            sumCurrent = eskUtils.getArraySum(sumCurrent, currentData[raisonDuo.code]);
                            maxNetwork = max(currentData.network) as number;
                        }

                        maxData = max([max(sumPrevious), max(sumCurrent), maxNetwork]) as number;
                        if (maxData < 5) {
                            return 1;
                        } else if (maxData < 20) {
                            // @ts-ignore
                            return floor(maxData / 5) + 1;
                        } else {
                            // @ts-ignore
                            let step = floor(maxData / 5) + 1;

                            step += step % 2;

                            return step;
                        }
                    }
                }).finally(() => $ctrl.loading = false);
            }
        }
    })(angularJS);
}
