import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICReportDossiersApi} from '@models/reports/report/dossiers/collection/report-dossiers.collection.interfaces';

@Injectable({providedIn: 'root'})
export class ReportDossiersApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(reportUuid: string): Observable<ICReportDossiersApi> {
        return this.getCollectionByLink$('/reports/' + reportUuid + '/dossiers');
    }

    getCollectionByLink$(link: string): Observable<ICReportDossiersApi> {
        return this._modelApiService.getCollection$(link);
    }
}
