import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICEtudeConstraintsApi
} from '@models/etudes/etude/constraints/collection/etude-constraints.collection.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeConstraintsApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(etudeUuid: string): Observable<ICEtudeConstraintsApi> {
        return this.getCollectionByLink$('/etude/' + etudeUuid + '/constraints');
    }

    getCollectionByLink$(link: string): Observable<ICEtudeConstraintsApi> {
        return this._modelApiService.getCollection$(link);
    }
}
