<div class="tw-mb-5"><h3><fa-icon [icon]="'edit'"/> {{ngBienPiece.uuid ? 'Édition' : 'Ajout'}} d'une pièce</h3></div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
  class="tw-w-[80vw] sm:tw-w-[30rem] md:tw-w-[40rem] lg:tw-w-[50rem]">
  <div class="tw-py-6">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienPieceTypePiece.formInput">
          <label>Type de pièce *</label>

          <app-dictionary-select #bienPieceTypePiece="dictionarySelect" [ngItem]="ngBienPiece.piece"
                                 [name]="DICTIONARY_NAME_PIECE_TYPES" (selected)="onSelectPiece($event)"
                                 [options]="{acceptNoResult: true, name: 'piece' + ngBienPiece.uuid, required: true}"/>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienPieceEtage.formInput">
          <label>Étage</label>

          <app-dictionary-select #bienPieceEtage="dictionarySelect" [ngItem]="ngBienPiece.etage"
                                 [options]="{name: 'etage' + ngBienPiece.uuid}" (selected)="onSelectEtage($event)"
                                 [name]="DICTIONARY_NAME_ETAGES"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="surface">
          <label for="surface">Surface</label>

          <div class="input-group input-group-number">
            <input type="text" id="surface" name="surface" #surface="ngModel" [(ngModel)]="ngBienPiece.surface"
                   class="form-control" pattern="^(?:\d*.)?\d+$" (change)="ngBienPiece.setSurfacePonderee()">

            <span class="input-group-addon">m<sup>2</sup></span>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>Habitable</label>

          <app-form-radio-ynn [model]="ngBienPiece.habitable" (selected)="onSelectHabitable($event)"/>
        </div>
      </div>

      @if (isEvaluation) {
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" [appFormError]="ponderation">
            <label for="ponderation">Pondération</label>

            <input type="text" id="ponderation" name="ponderation" #ponderation="ngModel" class="form-control"
                   min="0" max="1" pattern="^(?:\d*.)?\d+$" [(ngModel)]="ngBienPiece.ponderation"
                   (change)="ngBienPiece.setSurfacePonderee()">
          </div>
        </div>
      }

      @if (isEvaluation && ngBienPiece._esk.surfacePonderee! > 0) {
        <div class="col-xs-12 col-sm-6">
          <div class="num-group">
            <label>Surface pondérée</label>

            <div class="margin-top-5 text-bold">
              {{ngBienPiece._esk.surfacePonderee! | textCurrency:'m²'}}
            </div>
          </div>
        </div>
      }

      <div class="col-xs-12">
        <div class="form-group">
          <label for="piece-comments">Commentaires</label>

          <textarea id="piece-comments" class="form-control" name="comments" [(ngModel)]="ngBienPiece.comments"
                    [minHeight]="100"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-float-right">
    <button type="submit" class="tw-btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
