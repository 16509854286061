export default `
<div ng-if="!!$ctrl.currentEtudeId" class="ventes-negociees-natures">
  <!-- Table -->
  <div class="natures-table">
    <div class="row table-filter">
      <div class="col-sm-6">
        <div class="form-group filter-user">
          <label>{{"Utilisateur" | translate}}</label>

          <esk-select-user roles="$ctrl.usersRoles" etudes="[$ctrl.currentEtudeId]" options="$ctrl.options.user"
                           ng-model="$ctrl.filter.responsableDossier"></esk-select-user>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group filter-annee">
          <label>{{"Année de référence" | translate}}</label>

          <input type="text" id="filter-annee" class="form-control" maxlength="4" soq-only-digits
                 ng-model="$ctrl.filter.yearReference" ng-change="$ctrl.onChange()">
        </div>
      </div>
    </div>

    <esk-ventes-table-negociees-natures filter="$ctrl.filter" refresh="$ctrl.refreshTable">
    </esk-ventes-table-negociees-natures>
  </div>
</div>
`;
