import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {DemandeursModule} from '@features/demandeurs/demandeurs.module';
import {ContactsModule} from '@features/contacts/contacts.module';
import {VentesModule} from '@features/ventes/ventes.module';
import {OffreachatsActionsMainComponent} from '@features/offreachats/list/actions/offreachats.actions-main.component';
import {
    OffreachatsActionsMainDropdownComponent
} from '@features/offreachats/list/actions/dropdown/offreachats.actions-main-dropdown.component';
import {OffreachatDropdownComponent} from '@features/offreachats/item/dropdown/offreachat.dropdown.component';
import {AppOffreachatsListComponent} from '@features/offreachats/list/offreachats.list.component';
import {AppOffreachatStatutComponent} from '@features/offreachats/item/statut/offreachat.statut.component';
import {AppOffreachatEditComponent} from '@features/offreachats/item/edit/offreachat.edit.component';
import {
    OffreachatEditActionsMainComponent
} from '@features/offreachats/item/actions/edit/offreachat-edit.actions-main.component';
import {
    OffreachatWriteActionsMainComponent
} from '@features/offreachats/item/actions/write/offreachat-write.actions-main.component';
import {
    OffreachatSignActionsMainComponent
} from '@features/offreachats/item/actions/sign/offreachat-sign.actions-main.component';
import {
    OffreachatResumeActionsMainComponent
} from '@features/offreachats/item/actions/resume/offreachat-resume.actions-main.component';
import {DossiersModule} from '@features/dossiers/dossiers.module';
import {
    AppOffreachatSignatureEditComponent
} from '@features/offreachats/item/signature/edit/offreachat.signature-edit.component';
import {ProceduresModule} from '@features/procedures/procedures.module';
import {
    AppOffreachatSignatureInProgressComponent
} from '@features/offreachats/item/signature/in-progress/offreachat.signature-in-progress.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        AppOffreachatEditComponent,
        AppOffreachatSignatureEditComponent,
        AppOffreachatSignatureInProgressComponent,
        AppOffreachatsListComponent,
        AppOffreachatStatutComponent,
        OffreachatDropdownComponent,
        OffreachatEditActionsMainComponent,
        OffreachatResumeActionsMainComponent,
        OffreachatSignActionsMainComponent,
        OffreachatWriteActionsMainComponent,
        OffreachatsActionsMainComponent,
        OffreachatsActionsMainDropdownComponent,
    ],
    exports: [
        AppOffreachatEditComponent,
        AppOffreachatSignatureEditComponent,
        AppOffreachatSignatureInProgressComponent,
        AppOffreachatsListComponent,
        AppOffreachatStatutComponent,
    ],
    imports: [
        CommonModule,
        ContactsModule,
        DemandeursModule,
        DossiersModule,
        ProceduresModule,
        RouterModule,
        SharedModule,
        VentesModule,
    ],
})
export class OffreachatsModule {
}
