import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {take} from 'rxjs/operators';

export default function getEskDocumentsBlankCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDocumentsBlankCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/documents/blank.html',
        });

        /**
         * Blank documents controller
         *
         * @param Ng2DocumentFactory
         * @constructor
         */
        Controller.$inject = ['Ng2DocumentFactory'];
        function Controller(this: any, ng2DocumentFactory: DocumentFactory) {
            const $ctrl = this;

            $ctrl.openBlankDocument = (name: string, titreWindow: string) => ng2DocumentFactory.readByName$(name, titreWindow).pipe(take(1)).subscribe();
            $ctrl.blankDocumentsCollection = [
                {
                    title: "Fiches vierges",
                    collection: [
                        {link: 'fiche-vente.pdf', name: 'Bien en vente (A3) vierge', title: "Bien en vente - A3"},
                        {link: 'fiche-vente-a4.pdf', name: 'Bien en vente (A4) vierge', title: "Bien en vente - A4"},
                        {link: 'fiche-location.pdf', name: 'Location vierge', title: "Location"},
                        {link: 'fiche-locataire.pdf', name: 'Locataire vierge', title: "Locataire"},
                        {link: 'fiche-demandeur.pdf', name: 'Demandeur vierge', title: "Demandeur"},
                    ],
                },
                {
                    title: "Mandats vierges",
                    collection: [
                        {
                            link: 'mandat-csn-exclusif-charge-acquereur.pdf',
                            name: 'CSN exclusif charge acquéreur vierge',
                            title: 'CSN exclusif charge acquéreur',
                        },
                        {
                            link: 'mandat-csn-exclusif-charge-vendeur.pdf',
                            name: 'CSN exclusif charge vendeur vierge',
                            title: 'CSN exclusif charge vendeur',
                        },
                        {
                            link: 'mandat-csn-non-exclusif-charge-acquereur.pdf',
                            name: 'CSN non exclusif charge acquéreur vierge',
                            title: 'CSN non exclusif charge acquéreur',
                        },
                        {
                            link: 'mandat-csn-non-exclusif-charge-vendeur.pdf',
                            name: 'CSN non exclusif charge vendeur vierge',
                            title: 'CSN non exclusif charge vendeur',
                        },
                        {
                            link: 'delegation-mandat-vente.pdf',
                            name: 'Délégation d\'un mandat de vente vierge',
                            title: 'Délégation d\'un mandat de vente',
                        },
                        {
                            link: 'mandat-recherche.pdf',
                            name: 'Mandat de recherche vierge',
                            title: 'Mandat de recherche',
                        },
                    ],
                },
                {
                    title: "Autres documents",
                    collection: [
                        {link: 'avis-de-valeur.pdf', name: 'Avis de valeur vierge', title: 'Avis de valeur'},
                        {link: 'bon-de-visite.pdf', name: 'Bon de visite vierge', title: 'Bon de visite'},
                        {link: 'offre-achat.pdf', name: 'Offre d\'achat vierge', title: 'Offre d\'achat'},
                        {link: 'notice-cnil.pdf', name: 'Notice CNIL vierge', title: 'Notice CNIL'},
                    ],
                },
            ];
        }
    })(angularJS);
}
