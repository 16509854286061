import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable, switchMap} from 'rxjs';
import {IDocumentApi} from '@models/documents/document/document.interfaces';
import Document from '@models/documents/document/document.model';
import {HttpOptions} from '@core/api/api.interfaces';
import Media from '@models/medias/media/media.model';

@Injectable({providedIn: 'root'})
export class DocumentsApiService {
    private _modelApiService = inject(ModelApiService);

    get$(id: string): Observable<IDocumentApi> {
        return this.getByLink$('/documents/' + id);
    }

    getByLink$(link: string): Observable<IDocumentApi> {
        return this._modelApiService.get$(link);
    }

    readByName$(name: string, title = Media.NO_TITLE): Observable<Window> {
        return this._modelApiService.openWindow$('/documents/' + name, {
            needAuthentication: true,
            needFullPath: true,
            title,
        });
    }

    save$(uuid: string, documentApi: IDocumentApi, httpOptions?: HttpOptions): Observable<IDocumentApi> {
        if (uuid === Document.statuts.NEW) {
            return this._modelApiService.post$('/documents', documentApi, httpOptions);
        }

        return this._modelApiService.put$('/documents/' + uuid, documentApi, httpOptions).pipe(switchMap(_ => this.get$(uuid)));
    }
}
