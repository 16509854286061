import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IQService, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {NgFilterManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgFilter} from '@legacy/app/managers/ressources';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import Filter from '@models/filters/filter/filter.model';
import {ConditionConst} from '@shared/constants';
import Vente from '@models/ventes/vente/vente.model';

export default function getVentesBoxMandats(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-ventes-box-mandats></esk-ventes-box-mandats>
         */
        module.run(template).component('eskVentesBoxMandats', {
            bindings: {type: '@'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/components/box/mandats.html'
        });

        /**
         * Display ventes mandats
         *
         * @param EskBox
         * @param $q
         * @param VenteManager
         * @param Ng2RouterStateForNgService
         * @param SoqModalesManager
         * @param FilterManager
         * @constructor
         */
        Controller.$inject = ['EskBox', '$q', 'VenteManager', 'Ng2RouterStateForNgService', 'SoqModalesManager', 'FilterManager'];
        function Controller(this: any,
                            eskBox: NgEskBox,
                            $q: IQService,
                            venteManager: NgVenteManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            soqModalesManager: NgSoqModalesManager,
                            filterManager: NgFilterManager) {
            const $ctrl = this;
            let filterCurrent = filterManager.create({
                route: Filter.routes.GET_ETUDE_VENTES,
                queryParameters: {typeVente: {values: [], condition: ConditionConst.IN}},
            }) as NgFilter;

            $ctrl.$onInit = $onInit;
            $ctrl.updateVentes = updateVentes;
            $ctrl.openConfig = openConfig;
            $ctrl.goToVentes = goToVentes;
            $ctrl.options = {
                displayMode: 'box',
                loader: {updating: false},
                visibleFields: {
                    bullet: false,
                    nomContactsGroup: false,
                    nombreChambres: false,
                    nombrePieces: false,
                    preciseLocalisation: false,
                    priceCession: false,
                    select: false,
                },
            };

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.box = eskBox.getOneByCode('ventes-mandats-' + $ctrl.type);
                $ctrl.options.visibleFields.mandatDateFin = $ctrl.type !== 'without';
                $ctrl.updateVentes();
            }

            /**
             * Refresh list of ventes
             *
             * @returns {Promise}
             */
            function updateVentes() {
                $ctrl.options.loader.updating = true;
                filterCurrent.queryParameters.sort = $ctrl.box.config.sort;
                if ($ctrl.type === 'expired') {
                    filterCurrent.queryParameters.statut = {condition: ConditionConst.IN, values: [Vente.statuts.DIFFUSE, Vente.statuts.MANDAT]};
                    filterCurrent.queryParameters.mandat = {echeance: filter(eskBox.getListMandatsEcheance(), {code: 0})[0]};
                } else if ($ctrl.type === 'expiring') {
                    filterCurrent.queryParameters.statut = {condition: ConditionConst.IN, values: [Vente.statuts.DIFFUSE, Vente.statuts.MANDAT]};
                    filterCurrent.queryParameters.mandat = {echeance: $ctrl.box.config.mandatsEcheance};
                } else if ($ctrl.type === 'without') {
                    filterCurrent.queryParameters.statut = {condition: ConditionConst.IN, values: [Vente.statuts.BROUILLON]};
                }

                return venteManager.getAllEtudeFiltered(filterCurrent, {limit: $ctrl.box.config.nbDisplayed.code}).then(function (ventes) {
                    angular.forEach(ventes.collection, function (vente) {
                        vente._esk.routing = '/app/ventes/' + vente.id.toString() + '/mandats';
                    });
                    $ctrl.ventes = ventes;
                }).finally(function () {
                    $ctrl.box.badge.value = $ctrl.ventes.totalItems;
                    $ctrl.options.loader.updating = false;
                });
            }

            /**
             * Open configuration
             */
            function openConfig() {
                soqModalesManager.open('VentesModalBoxMandats', {resolve: {box: $ctrl.box}}).then($ctrl.updateVentes);
            }

            /**
             * Go to ventes
             */
            function goToVentes() {
                filterManager.getActiveByRouteCode(Filter.routes.GET_ETUDE_VENTES, Filter.codes.WALLET_CURRENT)
                    .then(filter => filter as NgFilter)
                    .then((filterUser: NgFilter) => {
                        filterUser.queryParameters = filterCurrent.queryParameters;
                        filterUser.fromFilterId = filterUser.id;
                        filterUser.save().then(() => ng2RouterStateForNgService.navigateByUrl('/app/ventes/portefeuille'));
                    });
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/ventes/components/box/mandats/expired.html', '<esk-ventes-box-mandats type="expired"></esk-ventes-box-mandats>');
            $templateCache.put('src/app/legacy/templates/ventes/components/box/mandats/expiring.html', '<esk-ventes-box-mandats type="expiring"></esk-ventes-box-mandats>');
            $templateCache.put('src/app/legacy/templates/ventes/components/box/mandats/without.html', '<esk-ventes-box-mandats type="without"></esk-ventes-box-mandats>');
        }
    })(angularJS);
}
