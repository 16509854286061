import {inject, Injectable} from '@angular/core';
import {TemplateFactory} from '@models/templates/template/template.factory';
import CTemplates from '@models/templates/collection/templates.collection.model';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {ICTemplatesApi, ICTemplatesQueryParameters} from '@models/templates/collection/templates.collection.interfaces';
import {TemplatesApiService} from '@models/templates/templates.api.service';

@Injectable({providedIn: 'root'})
export class CTemplatesFactory {
    private _templateFactory = inject(TemplateFactory);
    private _templatesApiService = inject(TemplatesApiService);

    create(cTemplatesApi: ICTemplatesApi): CTemplates {
        const cTemplates = new CTemplates();

        cTemplates.links = new Links(cTemplatesApi._links);
        cTemplates.page = cTemplatesApi.page;
        cTemplates.pages = cTemplatesApi.pages;
        cTemplates.perPage = cTemplatesApi.limit;
        cTemplates.total = cTemplatesApi.total;
        if (cTemplatesApi._embedded?.items) {
            cTemplates.results = cTemplatesApi._embedded.items.filter(templateApi => templateApi).map(templateApi => this._templateFactory.create(templateApi));
        }

        return cTemplates;
    }

    get$(queryParameters?: ICTemplatesQueryParameters): Observable<CTemplates> {
        return this._templatesApiService.getCollection$(queryParameters).pipe(map(cTemplatesApi => this.create(cTemplatesApi)));
    }
}
