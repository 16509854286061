@for (item of list; track item) {
  <div class="tw-flex tw-items-center">
    <input class="tw-input-checkbox" id="{{options.name}}-{{item.uuid}}" type="checkbox" (change)="onChange(item)"
           [value]="item" [disabled]="(options.disabledUuids || []).includes(item.uuid)" [checked]="isChecked(item)"/>

    <label class="tw-flex tw-items-center tw-space-x-2 tw-ml-3 tw-text-gray-700 tw-text-sm"
           [ngClass]="!(options.disabledUuids || []).includes(item.uuid) ? 'tw-cursor-pointer' : 'tw-cursor-default tw-opacity-75'"
           for="{{options.name}}-{{item.uuid}}">
      @if (options.bindLinkImage) {
        <img class="tw-rounded-full" [ngSrc]="item[options.bindLinkImage!]" height="24" width="24">
      }

      <span class="tw-truncate">{{ item[options.bindLabel!] }}</span>
    </label>
  </div>
}
