import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({
    selector: 'app-ng-eskimmo-documents-advertisement',
    template: '<div appNgEskimmoDocumentsAdvertisement></div>',
})
export class NgEskimmoDocumentsAdvertisementComponent {
}

@Directive({selector: '[appNgEskimmoDocumentsAdvertisement]'})
export class NgEskimmoDocumentsAdvertisementDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDocumentsAdvertisementCtrl', elementRef, injector);
    }
}
