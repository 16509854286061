import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default class Estimation extends ADossierBien {
    static readonly motifs = {
        SUCCESSION: 'succession',
        VENTE: 'vente',
        DIVORCE: 'divorce',
        DONATION: 'donation',
        ETUDE_PATRIMOINE: 'etude_patrimoine',
        AUTRE: 'autre',
    };
    static readonly statuts = {
        ARCHIVE: ADossierBien.abstractBienStatuts.ARCHIVE,
        BROUILLON: ADossierBien.abstractBienStatuts.BROUILLON,
        NEW: ADossierBien.abstractBienStatuts.NEW,
        REMIS: ADossierBien.abstractBienStatuts.REMIS,
    };
    static readonly types = {
        AVIS_VALEUR: 'avis_valeur',
        EVALUATION: 'evaluation',
    };
    static readonly valeurVenaleTypes = {
        FOURCHETTE: 'fourchette',
        SIMPLE: 'simple',
    };
    readonly dossierType = DossierBienTypesConst.ESTIMATION;
    private _avantages!: string;
    private _correctifValeurVenale!: number;
    private _courrierIntroductifRapportId!: number;
    private _dateRemise!: string;
    private _dateVisite!: string;
    private _inconvenients!: string;
    private _mapMediaId!: number;
    private _montantTravauxM2!: number;
    private _motif!: string;
    private _pourcentageValeurVenale!: number;
    private _rapportId!: number;
    private _reservesEnvironnementContamination!: string;
    private _type!: DictionaryItem;
    private _typeValeurVenale!: DictionaryItem;
    private _valeurVenale!: number;
    private _valeurVenaleBrute!: number;
    private _valeurVenaleComments!: string;
    private _valeurVenaleComparaison!: number;
    private _valeurVenaleM2!: number;
    private _valeurVenaleMax!: number;
    private _valeurVenaleMin!: number;

    constructor(uuid: string, id?: number, reference?: string) {
        super(uuid, id, reference);
        this._interne = true;
    }

    get avantages(): string {
        return this._avantages;
    }

    set avantages(value: string) {
        this._avantages = value;
    }

    get correctifValeurVenale(): number {
        return this._correctifValeurVenale;
    }

    set correctifValeurVenale(value: number) {
        this._correctifValeurVenale = value;
    }

    get courrierIntroductifRapportId(): number {
        return this._courrierIntroductifRapportId;
    }

    set courrierIntroductifRapportId(value: number) {
        this._courrierIntroductifRapportId = value;
    }

    get dateRemise(): string {
        return this._dateRemise;
    }

    set dateRemise(value: string) {
        this._dateRemise = value;
    }

    get dateVisite(): string {
        return this._dateVisite;
    }

    set dateVisite(value: string) {
        this._dateVisite = value;
    }

    get inconvenients(): string {
        return this._inconvenients;
    }

    set inconvenients(value: string) {
        this._inconvenients = value;
    }

    get mapMediaId(): number {
        return this._mapMediaId;
    }

    set mapMediaId(value: number) {
        this._mapMediaId = value;
    }

    get montantTravauxM2(): number {
        return this._montantTravauxM2;
    }

    set montantTravauxM2(value: number) {
        this._montantTravauxM2 = value;
    }

    get motif(): string {
        return this._motif;
    }

    set motif(value: string) {
        this._motif = value;
    }

    get pourcentageValeurVenale(): number {
        return this._pourcentageValeurVenale;
    }

    set pourcentageValeurVenale(value: number) {
        if (value > 100) {
            this._pourcentageValeurVenale = 100;
        } else {
            this._pourcentageValeurVenale = value;
        }
    }

    get rapportId(): number {
        return this._rapportId;
    }

    set rapportId(value: number) {
        this._rapportId = value;
    }

    get reservesEnvironnementContamination(): string {
        return this._reservesEnvironnementContamination;
    }

    set reservesEnvironnementContamination(value: string) {
        this._reservesEnvironnementContamination = value;
    }

    get type(): DictionaryItem {
        return this._type;
    }

    set type(value: DictionaryItem) {
        this._type = value;
    }

    get typeValeurVenale(): DictionaryItem {
        return this._typeValeurVenale;
    }

    set typeValeurVenale(value: DictionaryItem) {
        this._typeValeurVenale = value;
    }

    get valeurVenale(): number {
        return this._valeurVenale;
    }

    set valeurVenale(value: number) {
        this._valeurVenale = value;
    }

    get valeurVenaleBrute(): number {
        return this._valeurVenaleBrute;
    }

    set valeurVenaleBrute(value: number) {
        this._valeurVenaleBrute = value;
    }

    get valeurVenaleComments(): string {
        return this._valeurVenaleComments;
    }

    set valeurVenaleComments(value: string) {
        this._valeurVenaleComments = value;
    }

    get valeurVenaleComparaison(): number {
        return this._valeurVenaleComparaison;
    }

    set valeurVenaleComparaison(value: number) {
        this._valeurVenaleComparaison = value;
    }

    get valeurVenaleM2(): number {
        return this._valeurVenaleM2;
    }

    set valeurVenaleM2(value: number) {
        this._valeurVenaleM2 = value;
    }

    get valeurVenaleMax(): number {
        return this._valeurVenaleMax;
    }

    set valeurVenaleMax(value: number) {
        this._valeurVenaleMax = value;
    }

    get valeurVenaleMin(): number {
        return this._valeurVenaleMin;
    }

    set valeurVenaleMin(value: number) {
        this._valeurVenaleMin = value;
    }

    canEditRapport(): boolean {
        if (this.isArchived()) {
            return false;
        }

        if (this.dateRemise) {
            return true;
        }

        if (!this.bien.hasSavedCadastres() || !this.bien.descriptif) {
            return false;
        }

        if (!this.hasValeurVenale()) {
            return false;
        }

        if (this.isEvaluation()) {
            return this.bien.hasLocalisation();
        }

        return true;
    }

    hasValeurVenale(): boolean {
        if (this.isValeurVenaleSimple()) {
            return this.valeurVenale > 0;
        } else if (this.isValeurVenaleFourchette()) {
            return this.valeurVenaleMin > 0 && this.valeurVenaleMax > 0;
        }

        return false;
    }

    isEvaluation(): boolean {
        return this.type.code === Estimation.types.EVALUATION;
    }

    isValeurVenaleFourchette(): boolean {
        return this.typeValeurVenale.code === Estimation.valeurVenaleTypes.FOURCHETTE;
    }

    isValeurVenaleSimple(): boolean {
        return this.typeValeurVenale.code === Estimation.valeurVenaleTypes.SIMPLE;
    }
}
