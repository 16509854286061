import {inject, Injectable} from '@angular/core';
import {NgLocationLocataire} from '@legacy/app/managers/ressources';
import {ILocationLocataireApi} from '@models/locations/location/locataires/locataire/location-locataire.interfaces';
import LocationLocataire from '@models/locations/location/locataires/locataire/location-locataire.model';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';

@Injectable({providedIn: 'root'})
export class LocationLocataireFactory {
    private _contactsGroupFactory = inject(ContactsGroupFactory);

    create(locationLocataireApi: ILocationLocataireApi): LocationLocataire {
        const locationLocataire: LocationLocataire = this.createVirgin();

        if (locationLocataireApi._embedded?.locatairesGroup) {
            locationLocataire.locatairesGroup = this._contactsGroupFactory.create(locationLocataireApi._embedded.locatairesGroup);
        }

        return locationLocataire;
    }

    createVirgin(): LocationLocataire {
        return new LocationLocataire();
    }

    ngCreate(ngLocationLocataire: NgLocationLocataire): LocationLocataire {
        const locationLocataire: LocationLocataire = this.createVirgin();

        if (ngLocationLocataire.locatairesGroup) {
            locationLocataire.locatairesGroup = this._contactsGroupFactory.ngCreate(ngLocationLocataire.locatairesGroup);
        }

        return locationLocataire;
    }
}
