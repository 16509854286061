import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewViabilisationService
} from '@features/dossiers/dossier/bien/overview/viabilisation/dossier-bien-overview.viabilisation.service';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

@Component({selector: 'app-dossier-bien-overview-viabilisation', templateUrl: 'dossier-bien-overview.viabilisation.component.html'})
export class AppDossierBienOverviewViabilisationComponent {
    private _dossierBienOverviewViabilisationService = inject(DossierBienOverviewViabilisationService);
    private _dossierBien!: ADossierBien;

    get bienRaccordements(): DictionaryItem[] {
        return this._dossierBienOverviewViabilisationService.raccordements;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isAssainissementVisible(): boolean {
        return this._dossierBienOverviewViabilisationService.isAssainissementVisible;
    }

    get isCommentsViabilisationVisible(): boolean {
        return this._dossierBienOverviewViabilisationService.isCommentsViabilisationVisible;
    }

    get isRaccordementsVisible(): boolean {
        return this._dossierBienOverviewViabilisationService.isRaccordementsVisible;
    }

    get isViabilisableVisible(): boolean {
        return this._dossierBienOverviewViabilisationService.isViabilisableVisible;
    }
}
