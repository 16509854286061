import Collection from '@models/collection/collection.model';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';

export default class CEstimationReferences extends Collection<EstimationReference> {
    getPrixM2(): number {
        const filteredResults = this.results.filter(estimationReference => estimationReference.prixM2);

        if (filteredResults.length > 0) {
            return filteredResults.reduce((acc, estimationReference) => acc + estimationReference.prixM2, 0) / filteredResults.length;
        }

        return 0;
    }
}
