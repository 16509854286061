import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule} from 'angular';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {NgTask} from '@legacy/app/managers/ressources';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export default function getEskUploadFilesInfo(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-upload-files-info></esk-upload-files-info>
         */
        module.component('eskUploadFilesInfo', {
            bindings: {
                typeFile: '@',
                infoClientFilter: '=',
                updateTasks: '=',
                updateTasksErrors: '=',
                updateTasks$: '=',
                updateTasksErrors$: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/upload-files-info.html'
        });

        /**
         * Controller for display upload files information
         *
         * @param EskTaskRunner
         */
        Controller.$inject = ['EskTaskRunner'];
        function Controller(this: any, eskTaskRunner: NgEskTaskRunner) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();

            $ctrl.$onInit = $onInit;
            $ctrl.removeTask = removeTask;
            $ctrl.removeTasksErrors = removeTasksErrors;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.updateTasks = updateTasks;
                $ctrl.updateTasksErrors = updateTasksErrors;
                $ctrl.updateTasks();
                $ctrl.updateTasksErrors();
                $ctrl.updateTasks$?.pipe(takeUntil(_onDestroy$)).subscribe(() => $ctrl.updateTasks());
                $ctrl.updateTasksErrors$?.pipe(takeUntil(_onDestroy$)).subscribe(() => $ctrl.updateTasksErrors());
            }

            /**
             * Remove task
             *
             * @param task
             */
            function removeTask(task: NgTask) {
                eskTaskRunner.removeTask(task);
                task.promise.finally(function () {
                    $ctrl.updateTasks();
                    $ctrl.updateTasksErrors();
                });
                $ctrl.updateTasks();
            }

            /**
             * Remove task errors
             */
            function removeTasksErrors() {
                eskTaskRunner.removeTaskErrors($ctrl.taskErrors);
                $ctrl.updateTasksErrors();
            }

            /**
             * Update tasks
             */
            function updateTasks() {
                $ctrl.tasks = filter(eskTaskRunner.getTasks(), {infoClient: $ctrl.infoClientFilter});
            }

            /**
             * Update task errors
             */
            function updateTasksErrors() {
                $ctrl.taskErrors = filter(eskTaskRunner.getTaskErrors(), {infoClient: $ctrl.infoClientFilter});
            }
        }
    })(angularJS);
}
