import angularJS from '@shared/angularJS/global.ng';
import {remove} from "lodash";
import {IModule, IPromise, IQService} from 'angular';
import {NgMedia, NgTask, NgTaskCallback, NgTaskInfoClient} from '@legacy/app/managers/ressources';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {NgMediaManager} from '@legacy/app/managers/managers';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {DossierFileService} from '@models/dossiers/dossier/files/file/dossier-file.service';
import {firstValueFrom} from 'rxjs';
import {TextsService} from '@shared/texts/texts.service';

export default function getEskTaskRunner(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('EskTaskRunner', Service);

        /**
         * Manage tasks in execution
         *
         * @param $q
         * @param MediaManager
         * @param Ng2RouterStateForNgService
         * @param Ng2DossierFileService
         */
        Service.$inject = ['$q', 'MediaManager', 'Ng2RouterStateForNgService', 'Ng2DossierFileService', 'Ng2TextsService'];
        function Service(this: NgEskTaskRunner,
                         $q: IQService,
                         mediaManager: NgMediaManager,
                         ng2RouterStateForNgService: RouterStateForNgService,
                         ng2DossierFileService: DossierFileService,
                         ng2TextsService: TextsService) {
            const self = this;
            let taskErrors: NgTask[] = [];
            let tasks: NgTask[] = [];
            let running = false;

            self.createTaskDocumentFromFile = createTaskDocumentFromFile;
            self.createTasks = createTasks;
            self.addCallbackTask = addCallbackTask;
            self.getTasks = getTasks;
            self.removeTask = removeTask;
            self.addTaskError = addTaskError;
            self.getTaskErrors = getTaskErrors;
            self.removeTaskErrors = removeTaskErrors;

            /**
             * Create task
             *
             * @param ng2Dossier
             * @param file
             * @returns {Object}
             */
            function createTaskDocumentFromFile(ng2Dossier: ADossier, file: File): NgTask {
                const media = mediaManager.create({file});
                const task = self.addCallbackTask(
                    {
                        method: media.upload,
                        context: media,
                        then: (media: NgMedia) => firstValueFrom(ng2DossierFileService.createFromNgMedia$(ng2Dossier, media)),
                    },
                    1,
                    {
                        type: 'document_' + ng2Dossier.dossierType,
                        idDossier: ng2Dossier.id,
                        uisref: {
                            name: ng2RouterStateForNgService.current.name,
                            params: ng2RouterStateForNgService.current.params,
                        },
                        title: media.file.name + " du bien " + ng2Dossier.reference,
                        media,
                    },
                    {method: media.cancelUpload, context: media},
                );

                media.file._esk = {status: 'WAITING'};

                return task;
            }

            /**
             * Create task
             *
             * @param callback:{method: Function, context: Object, params: Array}
             * @param retry
             * @param infoClient
             * @param cancelCallback:{method: Function, context: Object, params: Array}
             * @returns {Object}
             */
            function createTasks(callback: NgTaskCallback, retry: number, infoClient: NgTaskInfoClient, cancelCallback: NgTaskCallback): NgTask {
                const deferred = $q.defer();
                let task: NgTask = {
                    uuid: ng2TextsService.uuid(),
                    callback,
                    cancelCallback,
                    retry: angular.isNumber(retry) && retry > 0 ? retry : 0,
                    attempts: 0,
                    deferred,
                    promise: deferred.promise,
                    infoClient,
                    error: undefined!,
                };

                if (angular.isObject(callback) && angular.isFunction(callback.then)) {
                    task.promise = task.promise.then(callback.then, function (error) {
                        if (angular.isObject(task.infoClient) && angular.isObject(task.infoClient.media) && angular.isObject(task.infoClient.media._esk) && angular.isDefined(task.infoClient.media._esk.error)) {
                            error = task.infoClient.media._esk.error;
                        }

                        return $q.reject(error);
                    });
                }

                task.promise = task.promise.catch(function (error) {
                    let formatedError: { data: { title: string }, status: string } = {
                        data: {title: undefined!},
                        status: undefined!
                    };

                    if (angular.isString(error)) {
                        formatedError.status = 'ERROR';
                        formatedError.data.title = error;
                    } else {
                        formatedError = error;
                    }

                    task.error = formatedError;
                    self.addTaskError(task);

                    return $q.reject(formatedError);
                });

                return task;
            }

            /**
             * Add task with callback execution
             *
             * @param callback
             * @param retry
             * @param infoClient
             * @param cancelCallback
             * @returns {Object}
             */
            function addCallbackTask(callback: NgTaskCallback, retry: number, infoClient: NgTaskInfoClient, cancelCallback: NgTaskCallback): NgTask {
                const task = self.createTasks(callback, retry, infoClient, cancelCallback);

                tasks.push(task);
                if (!running) {
                    run();
                }

                return task;

                /**
                 * Run tasks and execute callback with its params
                 *
                 * @returns {Promise}
                 */
                function run(): IPromise<void> | void {
                    let currentTask: NgTask;

                    running = tasks.length > 0;
                    if (!running) {
                        return;
                    }

                    currentTask = tasks[0];
                    currentTask.attempts++;

                    return currentTask.callback.method.apply(currentTask.callback.context, currentTask.callback.params).then(function (data) {
                        currentTask.deferred.resolve(data);
                    }, error => {
                        if (currentTask.attempts <= currentTask.retry) {
                            return run();
                        }

                        currentTask.deferred.reject(error);
                    }).finally(() => {
                        tasks.shift();
                        run();
                    });
                }
            }

            /**
             * Get all tasks
             *
             * @returns {Array}
             */
            function getTasks() {
                return tasks;
            }

            /**
             * Remove task
             *
             * @param currentTask
             */
            function removeTask(currentTask: NgTask) {
                if (!angular.isArray(tasks) || tasks.length <= 0) {
                    return;
                }

                if (currentTask.uuid === tasks[0].uuid) {
                    currentTask.retry = 0;
                    currentTask.cancelCallback.method.apply(currentTask.cancelCallback.context, currentTask.cancelCallback.params);
                } else {
                    remove(tasks, function (task) {
                        return task.uuid === currentTask.uuid;
                    });
                }
            }

            /**
             * Add task error
             *
             * @param task
             */
            function addTaskError(task: NgTask) {
                taskErrors.push(task);
            }

            /**
             * Get task errors
             *
             * @returns {Array}
             */
            function getTaskErrors() {
                return taskErrors;
            }

            /**
             * Remove task errors
             *
             * @param currentTaskErrors
             */
            function removeTaskErrors(currentTaskErrors: NgTask[]) {
                if (!angular.isArray(currentTaskErrors) || currentTaskErrors.length <= 0) {
                    taskErrors = [];
                } else {
                    angular.forEach(currentTaskErrors, function (currentTaskError) {
                        remove(taskErrors, function (taskError) {
                            return taskError.uuid === currentTaskError.uuid;
                        });
                    });
                }
            }
        }
    })(angularJS);
}
