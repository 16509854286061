import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {ContactsModule} from '@features/contacts/contacts.module';
import {CommunesModule} from '@features/communes/communes.module';
import {AppEtudeDossiersComponent} from '@features/etude-dossiers/etude-dossiers.component';

@NgModule({
    declarations: [AppEtudeDossiersComponent],
    exports: [AppEtudeDossiersComponent],
    imports: [CommonModule, CommunesModule, ContactsModule, SharedModule],
})
export class EtudeDossiersModule {
}
