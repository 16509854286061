import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ITemplateFooterApi} from '@models/templates/footers/footer/template-footer.interfaces';
import {ICTemplateFootersApi} from '@models/templates/footers/collection/template-footers.collection.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';
import environment from '@env/environment';

@Injectable({providedIn: 'root'})
export class TemplateFootersApiService {
    static readonly THUMBNAIL_DEFAULT = environment.api.baseUrl + '/assets/documents/footer/thumbnail-default.png';

    private _modelApiService = inject(ModelApiService);

    getFooters$(): Observable<ICTemplateFootersApi> {
        return this._modelApiService.getCollection$('/templates/footers');
    }

    getPreview$(templateFooterApi: ITemplateFooterApi, httpOptions?: HttpOptions): Observable<ITemplateFooterApi> {
        return this._modelApiService.post$('/templates/footers/preview', templateFooterApi, httpOptions);
    }
}
