import AModel from '@models/model.abstract';

export default class TemplateCategory extends AModel {
    static readonly codes = {
        BONVISITE: 'bon-visite',
        COURRIER_ESTIMATION_AVIS_VALEUR: 'estimation-avis-valeur-courrier-introductif',
        COURRIER_ESTIMATION_EVALUATION: 'estimation-evaluation-courrier-introductif',
        ESTIMATION_AVIS_VALEUR: 'estimation-avis-valeur',
        ESTIMATION_EVALUATION: 'estimation-evaluation',
        ESTIMATION_EVALUATION_CITYSCAN: 'estimation-evaluation-cityscan',
        OFFREACHAT: 'offre-achat',
    };
    private _code!: string;
    private readonly _id: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get id(): number {
        return this._id;
    }

    isEstimationAvisValeur(): boolean {
        return this.code === TemplateCategory.codes.ESTIMATION_AVIS_VALEUR;
    }

    isEstimationEvaluation(): boolean {
        return this.code === TemplateCategory.codes.ESTIMATION_EVALUATION;
    }

    isEstimationEvaluationCityscan(): boolean {
        return this.code === TemplateCategory.codes.ESTIMATION_EVALUATION_CITYSCAN;
    }
}
