<button type="button" class="tw-btn-tertiary-default tw-w-4 tw-p-0" (click)="toggle()">
  @if (isAscending) {
    <fa-icon [icon]="'sort-up'"/>
  }

  @if (isDescending) {
    <fa-icon [icon]="'sort-down'"/>
  }

  @if (!isAscending && !isDescending) {
    <fa-icon [icon]="['fal', 'sort']" class="tw-opacity-50"/>
  }
</button>
