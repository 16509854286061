import {inject, Injectable} from '@angular/core';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {IVentePasserelleApi} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.interfaces';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {VentePasserellesApiService} from '@models/ventes/vente/passerelles/vente-passerelles.api.service';
import Passerelle from '@models/passerelle/passerelle.model';
import Vente from '@models/ventes/vente/vente.model';
import {HttpOptions} from '@core/api/api.interfaces';
import {TextsService} from '@shared/texts/texts.service';

@Injectable({providedIn: 'root'})
export class VentePasserelleFactory {
    private _textsService = inject(TextsService);
    private _ventePasserellesApiService = inject(VentePasserellesApiService);

    create(ventePasserelleApi: IVentePasserelleApi): VentePasserelle {
        const ventePasserelle = this.createVirgin(ventePasserelleApi.passerelleId, ventePasserelleApi.venteId);

        ventePasserelle.afficherCommune = ventePasserelleApi.afficherCommune;
        ventePasserelle.afficherDpe = ventePasserelleApi.afficherDpe;
        ventePasserelle.afficherPrix = ventePasserelleApi.afficherPrix;
        ventePasserelle.communeId = ventePasserelleApi.communeId;
        ventePasserelle.createdAt = ventePasserelleApi.createdAt;
        ventePasserelle.dateDiffusion = ventePasserelleApi.dateDiffusion;
        ventePasserelle.dateRetrait = ventePasserelleApi.dateRetrait;
        ventePasserelle.descriptif = ventePasserelleApi.descriptif;
        ventePasserelle.diffused = ventePasserelleApi.diffused;
        ventePasserelle.natureExternalType = ventePasserelleApi.natureExternalType;
        ventePasserelle.natureExternalTypeId = ventePasserelleApi.natureExternalTypeId;
        ventePasserelle.typePrixExternalType = ventePasserelleApi.typePrixExternalType;
        ventePasserelle.typePrixExternalTypeId = ventePasserelleApi.typePrixExternalTypeId;
        ventePasserelle.updatedAt = ventePasserelleApi.updatedAt;
        if (ventePasserelleApi._links) {
            if (ventePasserelleApi._links.passerelle) {
                ventePasserelle.linkPasserelle = ventePasserelleApi._links.passerelle.href;
            }

            if (ventePasserelleApi._links.vente) {
                ventePasserelle.linkVente = ventePasserelleApi._links.vente.href;
            }

            if (ventePasserelleApi._links.self) {
                ventePasserelle.linkSelf = ventePasserelleApi._links.self.href;
            }
        }

        return ventePasserelle;
    }

    createVirgin(passerelleId: number, venteId: number): VentePasserelle {
        return new VentePasserelle(this._textsService.uuid(), passerelleId, venteId);
    }

    diffuser$(ventePasserelle: VentePasserelle): Observable<void> {
        return this._ventePasserellesApiService.diffuser$(ventePasserelle.venteId.toString(), ventePasserelle.passerelleId.toString()).pipe(
            tap(_ => ventePasserelle.setDiffuser()),
        );
    }

    forApi(ventePasserelle: VentePasserelle): IVentePasserelleApi {
        const ventePasserelleApi = {} as IVentePasserelleApi;

        ventePasserelleApi.afficherCommune = ventePasserelle.afficherCommune;
        ventePasserelleApi.afficherDpe = ventePasserelle.afficherDpe;
        ventePasserelleApi.afficherPrix = ventePasserelle.afficherPrix;
        ventePasserelleApi.communeId = ventePasserelle.communeId;
        ventePasserelleApi.descriptif = ventePasserelle.descriptif;
        ventePasserelleApi.natureExternalType = ventePasserelle.natureExternalType;
        ventePasserelleApi.natureExternalTypeId = ventePasserelle.natureExternalTypeId;
        ventePasserelleApi.typePrixExternalType = ventePasserelle.typePrixExternalType;
        ventePasserelleApi.typePrixExternalTypeId = ventePasserelle.typePrixExternalTypeId;

        return ventePasserelleApi;
    }

    get$(vente: Vente, passerelle: Passerelle, httpOptions?: HttpOptions): Observable<VentePasserelle> {
        return this._ventePasserellesApiService.get$(vente.id.toString(), passerelle.id.toString(), httpOptions).pipe(map(ventePasserelleApi => this.create(ventePasserelleApi)));
    }

    getByLink$(link: string): Observable<VentePasserelle> {
        return this._ventePasserellesApiService.getByLink$(link).pipe(map(ventePasserelleApi => this.create(ventePasserelleApi)));
    }

    retirer$(ventePasserelle: VentePasserelle): Observable<void> {
        return this._ventePasserellesApiService.retirer$(ventePasserelle.venteId.toString(), ventePasserelle.passerelleId.toString()).pipe(
            map(_ => ventePasserelle.setRetirer()),
        );
    }

    save$(ventePasserelle: VentePasserelle): Observable<VentePasserelle> {
        return this._ventePasserellesApiService.save$(ventePasserelle.venteId.toString(), ventePasserelle.passerelleId.toString(), this.forApi(ventePasserelle))
            .pipe(map(ventePasserelleApi => this.create(ventePasserelleApi)));
    }
}
