import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EtudePasserelleFactory} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.factory';
import {combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import {map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {
    CVentePasserellesFactory
} from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.factory';
import CVentePasserelles from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.model';
import {
    VentePasserelleDropdownComponent
} from '@features/ventes/vente/passerelles/passerelle/dropdown/vente-passerelle.dropdown.component';
import {IDCVentePasserelleData} from '@features/ventes/ventes.interfaces';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {DCVenteSlideOverComponent} from '@features/ventes/vente/slide-over/vente.slide-over.component';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {
    VentesPasserellesActionsSelectionComponent
} from '@features/ventes/passerelles/actions/ventes-passerelles.actions-selection.component';
import {AppVentesPasserellesListComponent} from '@features/ventes/passerelles/list/ventes-passerelles-list.component';

@Component({
    selector: 'layout-diffusions-passerelle-ventes',
    templateUrl: 'layout-diffusions-passerelle-ventes.component.html',
})
export class AppLayoutDiffusionsPasserelleVentesComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _callToActionService = inject(CallToActionService);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cVentePasserellesFactory = inject(CVentePasserellesFactory);
    private _etudePasserelleFactory = inject(EtudePasserelleFactory);
    private _modalService = inject(ModalService);
    private _slideOverService = inject(SlideOverService);
    private _venteFactory = inject(VenteFactory);
    private _ventePasserelleFactory = inject(VentePasserelleFactory);
    private _cVentePasserelles$!: Observable<CVentePasserelles>;
    private _etudePasserelle!: EtudePasserelle;
    private readonly _onDestroy$ = new Subject<void>();
    private _updateCVentePasserellesSource = new Subject<void>();

    get cVentePasserelles$(): Observable<CVentePasserelles> {
        return this._cVentePasserelles$;
    }

    ngOnInit(): void {
        this._cVentePasserelles$ = this._updateCVentePasserellesSource.pipe(
            startWith(undefined!),
            switchMap(_ => this._activatedRoute.params),
            map(params => (params as { passerelleId: string }).passerelleId),
            switchMap(passerelleId => this._etudePasserelleFactory.get$(passerelleId)),
            tap(etudePasserelle => this._etudePasserelle = etudePasserelle),
            switchMap(etudePasserelle => this._cVentePasserellesFactory.getFromPasserelle$(etudePasserelle.passerelle)),
        );

        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === VentesPasserellesActionsSelectionComponent.actions.REMOVE) {
                    const action$ = (ventePasserelles: Set<VentePasserelle>) => this.removeSelected$(Array.from(ventePasserelles));

                    return this._callToActionService.actionExec$(
                        this._collectionSelectionService.operateListSelected$(AppVentesPasserellesListComponent.initVentePasserellesListOptions.nameSelection!, action$)
                    );
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$)).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clicked(dropdownClicked: IDropdownClicked): void {
        const ventePasserelle = (dropdownClicked.value as IDCVentePasserelleData).ventePasserelle!;

        if (dropdownClicked.action === VentePasserelleDropdownComponent.actions.REMOVE) {
            this.remove(ventePasserelle);
        } else if (dropdownClicked.action === VentePasserelleDropdownComponent.actions.SEE_VENTE) {
            this._slideOverService.open$(DCVenteSlideOverComponent, {link: ventePasserelle.linkVente}).pipe(take(1)).subscribe();
        }
    }

    remove(ventePasserelle: VentePasserelle): void {
        this._venteFactory.getByLink$(ventePasserelle.linkVente).pipe(
            switchMap(vente => this._modalService.openConfirmation$({
                    buttonConfirmationLabel: 'Retirer',
                    question: 'Voulez-vous vraiment retirer le bien en vente "' + vente.reference + '" de ' + this._etudePasserelle.passerelle.name + ' ?',
                    title: 'Retirer de ' + this._etudePasserelle.passerelle.name,
                    status: ModalService.status.DANGER,
                }
            )),
            switchMap(isAccepted => {
                if (!isAccepted) {
                    return of(false);
                }

                return this._ventePasserelleFactory.retirer$(ventePasserelle).pipe(
                    tap(_ => this._updateCVentePasserellesSource.next())
                );
            }),
            take(1),
        ).subscribe();
    }

    removeSelected$(ventePasserellesToRemove: VentePasserelle[] = []): Observable<boolean> {
        if (ventePasserellesToRemove.length <= 0) {
            return of(false);
        }

        return this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Retirer',
            question: 'Voulez-vous vraiment retirer ' + (ventePasserellesToRemove.length > 1 ? ('ces ' + ventePasserellesToRemove.length.toString() + ' biens') : 'ce bien') + ' en vente ?',
            title: 'Retirer de ' + this._etudePasserelle.passerelle.name,
            status: ModalService.status.DANGER,
        }).pipe(switchMap(isAccepted => {
            if (!isAccepted) {
                return of(false);
            }

            return combineLatest(ventePasserellesToRemove.map(ventePasserelleToRemove => this._ventePasserelleFactory.retirer$(ventePasserelleToRemove))).pipe(
                take(1),
                tap(_ => this._updateCVentePasserellesSource.next()),
                map(_ => true),
            );
        }));
    }
}
