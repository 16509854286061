export default `
<ng-switch on="!!$ctrl.routing">
  <a ng-switch-when="true" ng-href="{{$ctrl.routing}}">
    <img class="photo tw-inline tw-max-w-none" ng-src="{{$ctrl.photoLink || $ctrl.linkMarianne}}?size=thumbnail" loading="lazy">
  </a>

  <img ng-switch-default class="photo tw-inline tw-max-w-none" ng-src="{{$ctrl.photoLink || $ctrl.linkMarianne}}?size=thumbnail"
       loading="lazy">
</ng-switch>
`;
