import {NgModule} from '@angular/core';
import {
    AppDocumentConvivialNameComponent
} from '@features/documents/document/convivial-name/document.convivial-name.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [AppDocumentConvivialNameComponent],
    exports: [AppDocumentConvivialNameComponent],
    imports: [CommonModule, SharedModule],
})
export class DocumentsModule {
}
