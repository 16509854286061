import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';

export default function getBiensModalFormatAffichettesCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BiensModalFormatAffichettesCtrl', Controller);

        /**
         * Controller for format affichette modal
         *
         * @param codesTemplate
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['codesTemplate', 'Ng2DictionaryFactory'];
        function Controller(this: any, codesTemplate: unknown, ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.affichette = {
                codesTemplate,
                groups: ng2DictionaryFactory.getByName(Dictionary.names.AFFICHETTE_GROUPS),
            };
        }
    })(angularJS);
}
