import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import CDossierExports from '@models/dossiers/dossier/exports/collection/dossier-exports.collection.model';
import {ICDossierExportsApi} from '@models/dossiers/dossier/exports/collection/dossier-exports.collection.interfaces';
import {DossierExportFactory} from '@models/dossiers/dossier/exports/export/dossier-export.factory';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DossierExportsApiService} from '@models/dossiers/dossier/exports/dossier-exports.api.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';

@Injectable({providedIn: 'root'})
export class CDossierExportsFactory {
    private _dossierExportFactory = inject(DossierExportFactory);
    private _dossierExportsApiService = inject(DossierExportsApiService);

    create(cDossierExportsApi: ICDossierExportsApi): CDossierExports {
        const cDossierExports = new CDossierExports();

        cDossierExports.links = new Links(cDossierExportsApi._links);
        cDossierExports.page = cDossierExportsApi.page;
        cDossierExports.pages = cDossierExportsApi.pages;
        cDossierExports.perPage = cDossierExportsApi.limit;
        cDossierExports.total = cDossierExportsApi.total;
        if (cDossierExportsApi._embedded?.items) {
            cDossierExportsApi._embedded.items.filter(dossierDossierExportApi => dossierDossierExportApi)
                .map(dossierDossierExportApi => cDossierExports.results.push(this._dossierExportFactory.create(dossierDossierExportApi)));
        }

        return cDossierExports;
    }

    get$(dossier: ADossier): Observable<CDossierExports> {
        return this._dossierExportsApiService.getCollection$(dossier.dossierType, dossier.uuid).pipe(map(cInotUsersApi => this.create(cInotUsersApi)));
    }
}
