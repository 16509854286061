import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgBienPhotoPasserelle} from '@legacy/app/managers/ressources';
import {NgManager} from '@legacy/app/managers/managers';

export default function getManagersBienPhotoPasserelle(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienPhotoPasserelleManager', manager);

        /**
         * Manager bienPhotoPasserelle
         *
         * @param BaseManager
         * @returns {BienPhotoPasserelleManager}
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('bien-photos-passerelle.configure', {
                path: '/biens/{bienId}/photos/{photoId}/external/passerelles/{id}/configure',
                method: 'PATCH'
            });

            /**
             * BienPhotoPasserelleManager object
             *
             * @constructor
             */
            angular.extend(BienPhotoPasserelleManager.prototype, baseManager.__proto__);
            BienPhotoPasserelleManager.prototype.eskManager = {
                prefixRoute: 'bien-photos-passerelle',
                collectionName: 'items',
            };
            function BienPhotoPasserelleManager() {
            }

            /**
             * Create a BienPhotoPasserelle object
             *
             * @param data
             * @param bienId
             * @param passerelleId
             * @returns manager.BienPhotoPasserelle object || Array of manager.BienPhotoPasserelle objects
             */
            BienPhotoPasserelleManager.prototype.create = function (data: unknown, bienId: number, passerelleId: number) {
                if (angular.isArray(data)) {
                    var bienPhotos = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        bienPhotos.push(new BienPhotoPasserelle(bienId, passerelleId, data[i]));
                    }

                    return bienPhotos;
                }

                // @ts-ignore
                return new BienPhotoPasserelle(bienId, passerelleId, data);
            };

            /**
             * BienPhotoPasserelle object
             *
             * @param bienId
             * @param photoId
             * @param data
             * @constructor
             */
            angular.extend(BienPhotoPasserelle.prototype, classResource.prototype);
            BienPhotoPasserelle.prototype.eskManager = {prefixRoute: 'bien-photos-passerelle'};
            function BienPhotoPasserelle(this: NgBienPhotoPasserelle,
                                         bienId: number,
                                         passerelleId: number,
                                         data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: passerelleId, bienId: bienId, photoId: this.photoId};
            }

            /**
             * Returns the promise of configuration network
             *
             * @returns {Promise}
             */
            BienPhotoPasserelle.prototype.configure = function () {
                return this.patch('configure', {diffuse: this.diffused});
            };

            // @ts-ignore
            return new BienPhotoPasserelleManager();
        }
    })(angularJS);
}
