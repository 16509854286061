import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgDictionariesManager, NgEstimationManager} from '@legacy/app/managers/managers';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {delay, from, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({selector: 'layout-estimation-edit-general', templateUrl: 'layout-estimation-edit-general.component.html'})
export class AppLayoutEstimationEditGeneralComponent implements OnDestroy, OnInit {
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _estimationFactory = inject(EstimationFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _contactsGroupLinkSource = new ReplaySubject<string>(1);
    private _contactsGroupLink$ = this._contactsGroupLinkSource.asObservable();
    private _ngEstimation!: NgEstimation;
    private readonly _onDestroy$ = new Subject<void>();
    private _typeValeurVenale!: DictionaryItem;

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService<NgContactsGroupManager>('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService<NgEstimationManager>('EstimationManager');
    }

    get contactsGroupLink$(): Observable<string> {
        return this._contactsGroupLink$;
    }

    get ESTIMATION_BROUILLON(): string {
        return Estimation.statuts.BROUILLON;
    }

    get ESTIMATION_TYPE_AVIS_VALEUR(): string {
        return Estimation.types.AVIS_VALEUR;
    }

    get ESTIMATION_VALEUR_VENALE_TYPE_FOURCHETTE(): string {
        return Estimation.valeurVenaleTypes.FOURCHETTE;
    }

    get ESTIMATION_VALEUR_VENALE_TYPE_SIMPLE(): string {
        return Estimation.valeurVenaleTypes.SIMPLE;
    }

    get ESTIMATION_VALEUR_VENALE_TYPES(): string {
        return Dictionary.names.ESTIMATION_VALEUR_VENALE_TYPES;
    }

    get ngEstimation(): NgEstimation {
        return this._ngEstimation;
    }

    get typeValeurVenale(): DictionaryItem {
        return this._typeValeurVenale;
    }

    ngOnInit(): void {
        this.ngEstimationManager.current$.pipe(
            tap(_ => this._ngEstimation = undefined!),
            delay(1),
            tap(ngEstimation => {
                const estimation = this._estimationFactory.ngCreate(ngEstimation);

                this._contactsGroupLinkSource.next(estimation.linkContactsGroup);
                this._typeValeurVenale = estimation.typeValeurVenale;
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(ngEstimation => this._ngEstimation = ngEstimation);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    onChangeContactsGroup(contactsGroup: ContactsGroup): void {
        let contactsGroupSaved$ = of(contactsGroup);

        if (!contactsGroup.id) {
            contactsGroupSaved$ = this._contactsGroupFactory.save$(contactsGroup).pipe(tap(contactsGroupSaved => {
                this._ngEstimation._links.contactsGroup = {href: contactsGroupSaved.linkSelf};
                this._contactsGroupLinkSource.next(contactsGroupSaved.linkSelf);
            }));
        }

        contactsGroupSaved$.pipe(
            switchMap(contactsGroupSaved => from(this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))),
            take(1),
        ).subscribe(ngContactsGroup => this._ngEstimation.bien.contactsGroup = ngContactsGroup);
    }

    onSelectTypeValeurVenale(typeValeurVenale: DictionaryItem): void {
        this._typeValeurVenale = typeValeurVenale;
        if (this._typeValeurVenale) {
            this._ngEstimation.typeValeurVenale = this.ngDictionariesManager.createFromNg2(this._typeValeurVenale);
        } else {
            this._ngEstimation.typeValeurVenale = undefined!;
        }
    }
}
