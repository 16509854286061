import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getSoqTransformation(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('SoqTransformation', Factory);

        /**
         * SoqTransformation
         *
         * @returns {SoqTransformation}
         */
        Factory.$inject = [] as string[];
        function Factory() {
            return {password: password};

            /**
             * Transformation email address
             *
             * @param password
             * @returns {string}
             */
            function password(password?: string): string {
                if (!angular.isString(password)) {
                    return '';
                }

                return password.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
            }
        }
    })(angularJS);
}
