import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import CDemandeurRechercheResultats
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.model';
import {FilterService} from '@models/filters/filter/filter.service';
import {tap} from 'rxjs/operators';
import Filter from '@models/filters/filter/filter.model';
import {
    CDemandeurRechercheResultatsFactory
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.factory';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import {
    ICDemandeurRechercheResultatsQueryParameters
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.interfaces';

@Injectable({providedIn: 'root'})
export class CDemandeurRechercheResultatsService {
    private _cDemandeurRechercheResultatsFactory = inject(CDemandeurRechercheResultatsFactory);
    private _filterService = inject(FilterService);

    getFromRechercheByFilter$(demandeurRecherche: DemandeurRecherche, code: string, keywords?: string): Observable<CDemandeurRechercheResultats> {
        return this._filterService.getOneByRouteCode$<ICDemandeurRechercheResultatsQueryParameters>(Filter.routes.GET_RECHERCHE_RESULTATS, code).pipe(
            tap(filter => {
                if (keywords) {
                    filter.queryParameters.keywords = keywords;
                }
            }),
            switchMap(filter => this._cDemandeurRechercheResultatsFactory.getFromRecherche$(demandeurRecherche, filter.queryParameters)),
        );
    }
}
