<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Viabilisation</h4>

  <div class="tw-bg-gray-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (isViabilisableVisible) {
        <li>Terrain viabilisable</li>
      }

      @if (isRaccordementsVisible) {
        <li>
          Raccordement{{ bienRaccordements.length > 1 ? 's' : '' }}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">à</span>

          @for (bienRaccordement of bienRaccordements; track bienRaccordement; let last = $last) {
            {{ bienRaccordement.label }}{{ last ? '' : ',' }}
          }
        </li>
      }

      @if (isAssainissementVisible) {
        <li>
          @if (dossierBien.bien.aucunAssainissement) {
            Aucun assainissement
          } @else {
            Assainissement{{ dossierBien.bien.assainissements.length > 1 ? 's' : '' }}

            @if (dossierBien.bien.assainissementNonConforme) {
              non conforme{{ dossierBien.bien.assainissements.length > 1 ? 's' : '' }}
            }

            @if (dossierBien.bien.viabilisationAssainissement) {
              {{ dossierBien.bien.viabilisationAssainissement }}{{ dossierBien.bien.assainissements.length > 1 ? 's' : '' }}

              @if (dossierBien.bien.isViabilisationAssainissementCollectifRaccorde()) {
                raccordé{{ dossierBien.bien.assainissements.length > 1 ? 's' : '' }}
              }
            }
          }

          @if (!dossierBien.bien.aucunAssainissement) {
            @for (assainissement of dossierBien.bien.assainissements; track assainissement; let last = $last) {
              {{ assainissement.label }}{{ last ? '' : ',' }}
            }
          }
        </li>
      }
    </ul>

    @if (isCommentsViabilisationVisible) {
      <div class="tw-text-sm tw-text-gray-700">{{ dossierBien.bien.viabilisationComments }}</div>
    }
  </div>
</div>
