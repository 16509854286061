import {inject, Injectable} from '@angular/core';
import Commune from '@models/communes/commune/commune.model';
import {NgCommune} from '@legacy/app/managers/ressources';
import {ICommuneApi} from '@models/communes/commune/commune.interfaces';
import {DepartementFactory} from '@models/departements/departement/departement.factory';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CommunesApiService} from '@models/communes/communes.api.service';

@Injectable({providedIn: 'root'})
export class CommuneFactory {
    private _communesApiService = inject(CommunesApiService);
    private _departementFactory = inject(DepartementFactory);

    create(communeApi: ICommuneApi): Commune {
        const commune = new Commune(communeApi.id?.toString(), communeApi.id);

        commune.codeInsee = communeApi.codeInsee;
        commune.codesPostaux = communeApi.codesPostaux;
        commune.coordonneesGps = communeApi.coordonneesGps;
        commune.fullname = communeApi.fullname;
        commune.hasQuartiers = communeApi.hasQuartiers;
        commune.hasVoies = communeApi.hasVoies;
        if (communeApi._embedded) {
            if (communeApi._embedded.communeParent) {
                commune.communeParent = this.create(communeApi._embedded.communeParent);
            }

            if (communeApi._embedded.departement) {
                commune.departement = this._departementFactory.create(communeApi._embedded.departement);
            }
        }

        // En attente d'un '_links/self'
        if (commune.id) {
            commune.linkSelf = '/communes/' + commune.id.toString();
        }

        return commune;
    }

    get$(id: number): Observable<Commune> {
        return this._communesApiService.get$(id.toString()).pipe(map(communeApi => this.create(communeApi)));
    }

    getByLink$(link: string): Observable<Commune> {
        return this._communesApiService.getByLink$(link).pipe(map(communeApi => this.create(communeApi)));
    }

    ngCreate(ngCommune: NgCommune): Commune {
        const commune = new Commune(ngCommune.id?.toString(), ngCommune.id);

        commune.codeInsee = ngCommune.codeInsee;
        commune.codesPostaux = ngCommune.codesPostaux || [];
        commune.coordonneesGps = ngCommune.coordonneesGps;
        commune.fullname = ngCommune.fullname;
        commune.hasQuartiers = ngCommune.hasQuartiers;
        commune.hasVoies = ngCommune.hasVoies;

        if (ngCommune.communeParent) {
            commune.communeParent = this.ngCreate(ngCommune.communeParent);
        }

        if (ngCommune.departement) {
            commune.departement = this._departementFactory.ngCreate(ngCommune.departement);
        }

        // En attente d'un '_links/self'
        if (commune.id) {
            commune.linkSelf = '/communes/' + commune.id.toString();
        }

        return commune;
    }
}
