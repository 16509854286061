import {NgModule} from '@angular/core';
import {DCTemplatesSelectComponent} from '@features/templates/select/dynamic-components/templates-select.component';
import {AppTemplateSelectComponent} from '@features/templates/template/select/template-select.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [AppTemplateSelectComponent, DCTemplatesSelectComponent],
    exports: [AppTemplateSelectComponent],
    imports: [CommonModule, SharedModule],
})
export class TemplatesModule {
}
