import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Commune from '@models/communes/commune/commune.model';
import Quartier from '@models/quartiers/quartier/quartier.model';
import Departement from '@models/departements/departement/departement.model';
import Region from '@models/regions/region/region.model';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';
import {SortDefinition} from '@shared/constants';
import {
    EstimationReferenceSourceConst, EstimationReferenceSourceType
} from '@models/estimations/estimation/references/estimation-references.constants';
import {
    CReferencesQueryParametersSourceConst, CReferencesQueryParametersSourceType
} from '@models/references/references.constants';
import {SearchCriteriaSourceConst} from '@models/search/criteria/search-criteria.constants';
import {ISearchCriteriaDateModification, SearchCriteriaSourceDefinition} from '@models/search/search.interfaces';

export default class SearchCriteria {
    private _accesPmr!: boolean;
    private _anneeConstructionMax!: number;
    private _anneeConstructionMin!: number;
    private _annexe!: boolean;
    private _archiveDateVenteMax!: string;
    private _archiveDateVenteMin!: string;
    private _archivePrixCessionMax!: number;
    private _archivePrixCessionMin!: number;
    private _archiveTypes: DictionaryItem[] = [];
    private _ascenseur!: boolean;
    private _balcon!: boolean;
    private _bienExceptionnel!: boolean;
    private _bienRdc!: boolean;
    private _budgetHni!: boolean;
    private _budgetMax!: number;
    private _budgetMin!: number;
    private _cave!: boolean;
    private _chambreRdc!: boolean;
    private _chargesIncluses!: boolean;
    private _cheminee!: boolean;
    private _communes: Commune[] = [];
    private _dateModification: ISearchCriteriaDateModification = {};
    private _departements: Departement[] = [];
    private _dernierEtage!: boolean;
    private _epoques: DictionaryItem[] = [];
    private _etats: DictionaryItem[] = [];
    private _excludeVenteInteractive!: boolean;
    private _excludeVenteTraditionnelle!: boolean;
    private _excludeVenteViager!: boolean;
    private _garage!: boolean;
    private _grenier!: boolean;
    private _locationMeuble!: boolean;
    private _loyerMax!: number;
    private _loyerMin!: number;
    private _mitoyennetes: DictionaryItem[] = [];
    private _natures: DictionaryItem[] = [];
    private _nombreChambresMax!: number;
    private _nombreChambresMin!: number;
    private _nombreEtagesMax!: number;
    private _nombreEtagesMin!: number;
    private _nombreLogementsMax!: number;
    private _nombreLogementsMin!: number;
    private _nombrePiecesMax!: number;
    private _nombrePiecesMin!: number;
    private _nomVoie!: string;
    private _numeroVoie!: number;
    private _occupations: DictionaryItem[] = [];
    private _parking!: boolean;
    private _pieceEauRdc!: boolean;
    private _piscine!: boolean;
    private _plainPieds: DictionaryItem[] = [];
    private _pointEau!: boolean;
    private _quartiers: Quartier[] = [];
    private _regions: Region[] = [];
    private _renteMax!: number;
    private _renteMin!: number;
    private _residenceService!: boolean;
    private _source: SearchCriteriaSourceDefinition = SearchCriteriaSourceConst.ETUDE;
    private _sousSol!: boolean;
    private _stationnement!: boolean;
    private _statuts: DictionaryItem[] = [];
    private _superficieMax!: number;
    private _superficieMin!: number;
    private _surfaceBatieMax!: number;
    private _surfaceBatieMin!: number;
    private _surfaceCadastraleMax!: number;
    private _surfaceCadastraleMin!: number;
    private _surfaceNonBatieMax!: number;
    private _surfaceNonBatieMin!: number;
    private _surfaceSejourMax!: number;
    private _surfaceSejourMin!: number;
    private _terrasse!: boolean;
    private _terrasseOuBalcon!: boolean;
    private _tris!: Record<string, SortDefinition>;
    private _typeSearch!: DossierBienType;
    private _uniquementBiensNotaire!: boolean;
    private _voie!: CommuneVoie;
    private _vues: DictionaryItem[] = [];

    get accesPmr(): boolean {
        return this._accesPmr;
    }

    set accesPmr(value: boolean) {
        this._accesPmr = value;
    }

    get anneeConstructionMax(): number {
        return this._anneeConstructionMax;
    }

    set anneeConstructionMax(value: number) {
        this._anneeConstructionMax = value;
    }

    get anneeConstructionMin(): number {
        return this._anneeConstructionMin;
    }

    set anneeConstructionMin(value: number) {
        this._anneeConstructionMin = value;
    }

    get annexe(): boolean {
        return this._annexe;
    }

    set annexe(value: boolean) {
        this._annexe = value;
    }

    get archiveDateVenteMax(): string {
        return this._archiveDateVenteMax;
    }

    set archiveDateVenteMax(value: string) {
        this._archiveDateVenteMax = value;
    }

    get archiveDateVenteMin(): string {
        return this._archiveDateVenteMin;
    }

    set archiveDateVenteMin(value: string) {
        this._archiveDateVenteMin = value;
    }

    get archivePrixCessionMin(): number {
        return this._archivePrixCessionMin;
    }

    set archivePrixCessionMin(value: number) {
        this._archivePrixCessionMin = value;
    }

    get archivePrixCessionMax(): number {
        return this._archivePrixCessionMax;
    }

    set archivePrixCessionMax(value: number) {
        this._archivePrixCessionMax = value;
    }

    get archiveTypes(): DictionaryItem[] {
        return this._archiveTypes;
    }

    set archiveTypes(value: DictionaryItem[]) {
        this._archiveTypes = value;
    }

    get ascenseur(): boolean {
        return this._ascenseur;
    }

    set ascenseur(value: boolean) {
        this._ascenseur = value;
    }

    get balcon(): boolean {
        return this._balcon;
    }

    set balcon(value: boolean) {
        this._balcon = value;
    }

    get bienExceptionnel(): boolean {
        return this._bienExceptionnel;
    }

    set bienExceptionnel(value: boolean) {
        this._bienExceptionnel = value;
    }

    get bienRdc(): boolean {
        return this._bienRdc;
    }

    set bienRdc(value: boolean) {
        this._bienRdc = value;
    }

    get budgetHni(): boolean {
        return this._budgetHni;
    }

    set budgetHni(value: boolean) {
        this._budgetHni = value;
    }

    get budgetMax(): number {
        return this._budgetMax;
    }

    set budgetMax(value: number) {
        this._budgetMax = value;
    }

    get budgetMin(): number {
        return this._budgetMin;
    }

    set budgetMin(value: number) {
        this._budgetMin = value;
    }

    get cave(): boolean {
        return this._cave;
    }

    set cave(value: boolean) {
        this._cave = value;
    }

    get chambreRdc(): boolean {
        return this._chambreRdc;
    }

    set chambreRdc(value: boolean) {
        this._chambreRdc = value;
    }

    get chargesIncluses(): boolean {
        return this._chargesIncluses;
    }

    set chargesIncluses(value: boolean) {
        this._chargesIncluses = value;
    }

    get cheminee(): boolean {
        return this._cheminee;
    }

    set cheminee(value: boolean) {
        this._cheminee = value;
    }

    get communes(): Commune[] {
        return this._communes;
    }

    set communes(value: Commune[]) {
        this._communes = value;
    }

    get dateModification(): ISearchCriteriaDateModification {
        return this._dateModification;
    }

    set dateModification(value: ISearchCriteriaDateModification) {
        this._dateModification = value ?? {};
    }

    get departements(): Departement[] {
        return this._departements;
    }

    set departements(value: Departement[]) {
        this._departements = value;
    }

    get dernierEtage(): boolean {
        return this._dernierEtage;
    }

    set dernierEtage(value: boolean) {
        this._dernierEtage = value;
    }

    get epoques(): DictionaryItem[] {
        return this._epoques;
    }

    set epoques(value: DictionaryItem[]) {
        this._epoques = value;
    }

    get etats(): DictionaryItem[] {
        return this._etats;
    }

    set etats(value: DictionaryItem[]) {
        this._etats = value;
    }

    get excludeVenteInteractive(): boolean {
        return this._excludeVenteInteractive;
    }

    set excludeVenteInteractive(value: boolean) {
        this._excludeVenteInteractive = value;
    }

    get excludeVenteTraditionnelle(): boolean {
        return this._excludeVenteTraditionnelle;
    }

    set excludeVenteTraditionnelle(value: boolean) {
        this._excludeVenteTraditionnelle = value;
    }

    get excludeVenteViager(): boolean {
        return this._excludeVenteViager;
    }

    set excludeVenteViager(value: boolean) {
        this._excludeVenteViager = value;
    }

    get garage(): boolean {
        return this._garage;
    }

    set garage(value: boolean) {
        this._garage = value;
    }

    get grenier(): boolean {
        return this._grenier;
    }

    set grenier(value: boolean) {
        this._grenier = value;
    }

    get locationMeuble(): boolean {
        return this._locationMeuble;
    }

    set locationMeuble(value: boolean) {
        this._locationMeuble = value;
    }

    get loyerMax(): number {
        return this._loyerMax;
    }

    set loyerMax(value: number) {
        this._loyerMax = value;
    }

    get loyerMin(): number {
        return this._loyerMin;
    }

    set loyerMin(value: number) {
        this._loyerMin = value;
    }

    get mitoyennetes(): DictionaryItem[] {
        return this._mitoyennetes;
    }

    set mitoyennetes(value: DictionaryItem[]) {
        this._mitoyennetes = value;
    }

    get natures(): DictionaryItem[] {
        return this._natures;
    }

    set natures(value: DictionaryItem[]) {
        this._natures = value;
    }

    get nombreChambresMax(): number {
        return this._nombreChambresMax;
    }

    set nombreChambresMax(value: number) {
        this._nombreChambresMax = value;
    }

    get nombreChambresMin(): number {
        return this._nombreChambresMin;
    }

    set nombreChambresMin(value: number) {
        this._nombreChambresMin = value;
    }

    get nombreEtagesMax(): number {
        return this._nombreEtagesMax;
    }

    set nombreEtagesMax(value: number) {
        this._nombreEtagesMax = value;
    }

    get nombreEtagesMin(): number {
        return this._nombreEtagesMin;
    }

    set nombreEtagesMin(value: number) {
        this._nombreEtagesMin = value;
    }

    get nombreLogementsMax(): number {
        return this._nombreLogementsMax;
    }

    set nombreLogementsMax(value: number) {
        this._nombreLogementsMax = value;
    }

    get nombreLogementsMin(): number {
        return this._nombreLogementsMin;
    }

    set nombreLogementsMin(value: number) {
        this._nombreLogementsMin = value;
    }

    get nombrePiecesMax(): number {
        return this._nombrePiecesMax;
    }

    set nombrePiecesMax(value: number) {
        this._nombrePiecesMax = value;
    }

    get nombrePiecesMin(): number {
        return this._nombrePiecesMin;
    }

    set nombrePiecesMin(value: number) {
        this._nombrePiecesMin = value;
    }

    get nomVoie(): string {
        return this._nomVoie;
    }

    set nomVoie(value: string) {
        this._nomVoie = value;
    }

    get numeroVoie(): number {
        return this._numeroVoie;
    }

    set numeroVoie(value: number) {
        this._numeroVoie = value;
    }

    get occupations(): DictionaryItem[] {
        return this._occupations;
    }

    set occupations(value: DictionaryItem[]) {
        this._occupations = value;
    }

    get parking(): boolean {
        return this._parking;
    }

    set parking(value: boolean) {
        this._parking = value;
    }

    get pieceEauRdc(): boolean {
        return this._pieceEauRdc;
    }

    set pieceEauRdc(value: boolean) {
        this._pieceEauRdc = value;
    }

    get piscine(): boolean {
        return this._piscine;
    }

    set piscine(value: boolean) {
        this._piscine = value;
    }

    get plainPieds(): DictionaryItem[] {
        return this._plainPieds;
    }

    set plainPieds(value: DictionaryItem[]) {
        this._plainPieds = value;
    }

    get pointEau(): boolean {
        return this._pointEau;
    }

    set pointEau(value: boolean) {
        this._pointEau = value;
    }

    get quartiers(): Quartier[] {
        return this._quartiers;
    }

    set quartiers(value: Quartier[]) {
        this._quartiers = value;
    }

    get regions(): Region[] {
        return this._regions;
    }

    set regions(value: Region[]) {
        this._regions = value;
    }

    get renteMax(): number {
        return this._renteMax;
    }

    set renteMax(value: number) {
        this._renteMax = value;
    }

    get renteMin(): number {
        return this._renteMin;
    }

    set renteMin(value: number) {
        this._renteMin = value;
    }

    get residenceService(): boolean {
        return this._residenceService;
    }

    set residenceService(value: boolean) {
        this._residenceService = value;
    }

    get source(): SearchCriteriaSourceDefinition {
        return this._source;
    }

    set source(value: SearchCriteriaSourceDefinition) {
        this._source = value;
    }

    get sousSol(): boolean {
        return this._sousSol;
    }

    set sousSol(value: boolean) {
        this._sousSol = value;
    }

    get stationnement(): boolean {
        return this._stationnement;
    }

    set stationnement(value: boolean) {
        this._stationnement = value;
    }

    get statuts(): DictionaryItem[] {
        return this._statuts;
    }

    set statuts(value: DictionaryItem[]) {
        this._statuts = value;
    }

    get superficieMax(): number {
        return this._superficieMax;
    }

    set superficieMax(value: number) {
        this._superficieMax = value;
    }

    get superficieMin(): number {
        return this._superficieMin;
    }

    set superficieMin(value: number) {
        this._superficieMin = value;
    }

    get surfaceBatieMax(): number {
        return this._surfaceBatieMax;
    }

    set surfaceBatieMax(value: number) {
        this._surfaceBatieMax = value;
    }

    get surfaceBatieMin(): number {
        return this._surfaceBatieMin;
    }

    set surfaceBatieMin(value: number) {
        this._surfaceBatieMin = value;
    }

    get surfaceCadastraleMax(): number {
        return this._surfaceCadastraleMax;
    }

    set surfaceCadastraleMax(value: number) {
        this._surfaceCadastraleMax = value;
    }

    get surfaceCadastraleMin(): number {
        return this._surfaceCadastraleMin;
    }

    set surfaceCadastraleMin(value: number) {
        this._surfaceCadastraleMin = value;
    }

    get surfaceNonBatieMax(): number {
        return this._surfaceNonBatieMax;
    }

    set surfaceNonBatieMax(value: number) {
        this._surfaceNonBatieMax = value;
    }

    get surfaceNonBatieMin(): number {
        return this._surfaceNonBatieMin;
    }

    set surfaceNonBatieMin(value: number) {
        this._surfaceNonBatieMin = value;
    }

    get surfaceSejourMax(): number {
        return this._surfaceSejourMax;
    }

    set surfaceSejourMax(value: number) {
        this._surfaceSejourMax = value;
    }

    get surfaceSejourMin(): number {
        return this._surfaceSejourMin;
    }

    set surfaceSejourMin(value: number) {
        this._surfaceSejourMin = value;
    }

    get terrasse(): boolean {
        return this._terrasse;
    }

    set terrasse(value: boolean) {
        this._terrasse = value;
    }

    get terrasseOuBalcon(): boolean {
        return this._terrasseOuBalcon;
    }

    set terrasseOuBalcon(value: boolean) {
        this._terrasseOuBalcon = value;
    }

    get tris(): Record<string, SortDefinition> {
        return this._tris;
    }

    set tris(value: Record<string, SortDefinition>) {
        this._tris = value;
    }

    get typeSearch(): DossierBienType {
        return this._typeSearch;
    }

    set typeSearch(value: DossierBienType) {
        this._typeSearch = value;
    }

    get uniquementBiensNotaire(): boolean {
        return this._uniquementBiensNotaire;
    }

    set uniquementBiensNotaire(value: boolean) {
        this._uniquementBiensNotaire = value;
    }

    get voie(): CommuneVoie {
        return this._voie;
    }

    set voie(value: CommuneVoie) {
        this._voie = value;
    }

    get vues(): DictionaryItem[] {
        return this._vues;
    }

    set vues(value: DictionaryItem[]) {
        this._vues = value;
    }

    getCReferencesQueryParametersSource(): CReferencesQueryParametersSourceType {
        if (this.isSourceCityscan()) {
            return CReferencesQueryParametersSourceConst.CITYSCAN;
        }

        return CReferencesQueryParametersSourceConst.LOCAL;
    }

    getEstimationReferenceSource(): EstimationReferenceSourceType {
        if (this.isSourceCityscan()) {
            return EstimationReferenceSourceConst.CITYSCAN;
        } else if (this.isSourceCVentes()) {
            return EstimationReferenceSourceConst.VENTE;
        } else if (this.isSourceReference()) {
            return EstimationReferenceSourceConst.REFERENCE;
        }

        return undefined!;
    }

    isSourceCityscan(): boolean {
        return this.source === SearchCriteriaSourceConst.CITYSCAN;
    }

    isSourceCReferences(): boolean {
        return this.isSourceCityscan() || this.isSourceReference();
    }

    isSourceCVentes(): boolean {
        return this.isSourceEtude() || this.isSourceNetwork();
    }

    isSourceEtude(): boolean {
        return this.source === SearchCriteriaSourceConst.ETUDE;
    }

    isSourceNetwork(): boolean {
        return this.source === SearchCriteriaSourceConst.NETWORK;
    }

    isSourceReference(): boolean {
        return this.source === SearchCriteriaSourceConst.REFERENCE;
    }

    setTri(name: string, sort?: SortDefinition, withMultiTris = true): void {
        if (!withMultiTris) {
            this.tris = {};
            this.tris[name] = sort!;

            return;
        }

        if (!this.tris) {
            this.tris = {};
        }

        if (sort) {
            this.tris[name] = sort;
        } else {
            const tris = {};

            for (const [key, value] of Object.entries(this.tris)) {
                if (key !== name) {
                    tris[key] = value;
                }
            }

            this.tris = tris;
        }
    }
}
