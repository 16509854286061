// @todo Renommer le répertoire en "matching"
export default class VenteMatching {
    private _linkEtude!: string;
    private _numberDemandeurs!: number;

    get linkEtude(): string {
        return this._linkEtude;
    }

    set linkEtude(value: string) {
        this._linkEtude = value;
    }

    get numberDemandeurs(): number {
        return this._numberDemandeurs;
    }

    set numberDemandeurs(value: number) {
        this._numberDemandeurs = value;
    }

    hasLinkEtude(linkEtude: string): boolean {
        return this.linkEtude === linkEtude;
    }
}
