import angularJS from '@shared/angularJS/global.ng';
import {IModule, IScope} from 'angular';
import {NgCommune} from '@legacy/app/managers/ressources';
import {NgQuartiersCollection} from '@legacy/app/managers/collections';

export default function getEskSelectQuartier(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-quartier></esk-select-quartier>
         */
        module.component('eskSelectQuartier', {
            bindings: {
                ngModel: '=',
                name: '@',
                commune: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/quartier.html'
        });

        /**
         * Select quartiers
         *
         * @param $scope
         * @constructor
         */
        Controller.$inject = ['$scope'];
        function Controller(this: any, $scope: IScope) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Check if commune change to update quartiers
             */
            $scope.$watch('$ctrl.commune', function (commune: NgCommune, oldCommune: NgCommune) {
                if (angular.isObject(commune) && (!angular.isObject(oldCommune) || commune.id !== oldCommune.id)) {
                    $ctrl.ngModel = undefined;
                    loadQuartiers();
                }
            });

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.quartiers = [];

                if (angular.isObject($ctrl.commune)) {
                    loadQuartiers();
                }
            }

            /**
             * Update quartiers
             */
            function loadQuartiers() {
                if ($ctrl.commune && $ctrl.commune.hasQuartiers && $ctrl.commune.getAllQuartiers) {
                    $ctrl.loading = true;
                    $ctrl.commune.getAllQuartiers().then((quartiers: NgQuartiersCollection) => {
                        $ctrl.quartiers = quartiers.collection;
                        $ctrl.loading = false;
                    });
                }
            }
        }
    })(angularJS);
}
