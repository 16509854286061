import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-demandeur-lifetime', template: '<div appNgDemandeurLifetime></div>'})
export class NgDemandeurLifetimeComponent {
}

@Directive({selector: '[appNgDemandeurLifetime]'})
export class NgDemandeurLifetimeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurLifetimeCtrl', elementRef, injector);
    }
}
