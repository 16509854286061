export default `
<div class="media-contact">
  <div class="media-contact-left" ng-click="$ctrl.onClick($ctrl.contact)">
    <esk-contact-initiales contact="$ctrl.contact"></esk-contact-initiales>

    <div ng-if="$ctrl.contact.unsubscribed" title="{{'dossier.contact.UNSUBSCRIBED' | translate}}"
         class="unsubscribe-mynoty text-warning"><fa-icon [icon]="['far', 'envelope']"></fa-icon> MyNoty</div>
  </div>

  <div class="media-contact-body">
    <h4 class="media-contact-heading ellipsis" ng-click="$ctrl.onClick($ctrl.contact)">
      <esk-contact-convivial-name contact="$ctrl.contact"></esk-contact-convivial-name>
    </h4>

    <ul class="list-unstyled">
      <li>
        <a ng-if="$ctrl.contact[$ctrl.contact.type].telephone"
           ng-href="{{$ctrl.contact[$ctrl.contact.type].getRfc3966($ctrl.contact[$ctrl.contact.type].telephone)}}">
          {{$ctrl.contact[$ctrl.contact.type].telephone}}
        </a>

        <span ng-if="$ctrl.contact[$ctrl.contact.type].telephone && $ctrl.contact[$ctrl.contact.type].mobile"> | </span>

        <a ng-if="$ctrl.contact[$ctrl.contact.type].mobile"
           ng-href="{{$ctrl.contact[$ctrl.contact.type].getRfc3966($ctrl.contact[$ctrl.contact.type].mobile)}}">
          {{$ctrl.contact[$ctrl.contact.type].mobile}}
        </a>
      </li>

      <li ng-if="$ctrl.contact[$ctrl.contact.type].emailAddress">
        <a href="mailto:{{$ctrl.contact[$ctrl.contact.type].emailAddress}}" target="_blank">
          {{$ctrl.contact[$ctrl.contact.type].emailAddress}}
        </a>
      </li>
    </ul>
  </div>
</div>
`;
