import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getSoqPhone(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-phone></soq-phone>
         */
        module.component('soqPhone', {
            bindings: {icon: '=?'},
            templateUrl: 'src/app/legacy/templates/soqrate/components/phone.html'
        });
    })(angularJS);
}
