<div class="tw-flex tw-flex-col lg:tw-flex-row-reverse lg:tw-flex-row tw-h-full tw-overflow-auto lg:tw-overflow-hidden">
  <div class="lg:tw-flex-1 tw-p-4 lg:tw-overflow-auto">
    <div class="xl:tw-w-[22rem] 2xl:tw-w-96">
      @if (cSignataires$ | async; as cSignataires) {
        <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-none tw-gap-4">
          @for (signataire of cSignataires.results; track signataire) {
            <div [ngClass]="signataire.isEditable() ? 'focus-within:tw-ring-2 hover:tw-ring-2 hover:tw-ring-blue-400' : ''"
                 class="tw-relative tw-bg-white tw-rounded-lg tw-shadow tw-group tw-ring-offset-4 tw-ring-offset-gray-100 tw-ring-blue-500">
              <div class="tw-p-4 tw-space-y-2">
                <h3 class="tw-text-gray-800 tw-text-sm tw-font-medium tw-line-clamp-2 xl:tw-line-clamp-1"
                    [ngClass]="signataire.isEditable() ? 'group-hover:tw-text-gray-700 group-focus:tw-text-gray-800 group-active:tw-text-gray-900' : ''">
                  {{ signataire.convivialName }}
                </h3>

                <div class="tw-flex tw-justify-between tw-items-center">
                  <!-- @todo Pourquoi ne pas utiliser AppProcedureSignataireStatutComponent ? -->
                  <span class="tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-capitalize tw-cursor-default"
                        [ngClass]="{'tw-bg-orange-100 tw-text-orange-800': signataire.isEditable(),
                        'tw-bg-green-100 tw-text-green-800': signataire.isFinished(),
                        'tw-bg-red-100 tw-text-red-800': signataire.isRefused()}">{{ signataire.labelStatut }}</span>

                  @if (signataire.isEditable()) {
                    <span class="tw-text-blue-500 group-hover:tw-text-blue-400 group-focus:tw-text-blue-500 group-active:tw-text-blue-600 tw-text-xl">
                      <fa-icon [icon]="'feather-alt'"/>
                    </span>
                  }
                </div>
              </div>

              @if (signataire.isEditable()) {
                <div class="tw-absolute tw-inset-0">
                  <button type="button" class="tw-h-full tw-w-full focus:tw-outline-none" #focusButton
                          (click)="onSelectProcedureSignataire(signataire, focusButton)"></button>
                </div>
              }
            </div>
          }
        </div>
      } @else {
        <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
      }
    </div>
  </div>

  <div class="tw-flex-1 lg:tw-flex-none lg:tw-w-[calc(21cm_+_15px_+_2rem)] xl:tw-w-[calc((100%_-_22rem_+_21cm_+_15px)_/_2)] 2xl:tw-w-[calc((100%_-_24rem_+_21cm_+_15px)_/_2)] lg:tw-h-full tw-pt-4 tw-px-4 tw-border-0 tw-border-t lg:tw-border-0 lg:tw-border-r tw-border-gray-200">
    <div class="tw-flex lg:tw-flex-row-reverse tw-h-full">
      <app-pdf [pdfPath$]="pdfPath$" class="tw-w-full xl:tw-w-auto"/>
    </div>
  </div>
</div>
