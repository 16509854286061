import angularJS from '@shared/angularJS/global.ng';
import {find, findIndex} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgDemandeurManager} from '@legacy/app/managers/managers';
import {NgRechercheResultsCollection} from '@legacy/app/managers/collections';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getDemandeurResultsHistoricalCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDemandeurResultsHistoricalCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/controllers/item/results/historical.html',
        });

        /**
         * Controller for historical Result
         *
         * @param search
         * @param Ng2RouterStateForNgService
         * @param $q
         * @param $translate
         * @param Ng2ToasterService
         * @param DemandeurManager
         * @constructor
         */
        Controller.$inject = ['Ng2RouterStateForNgService', '$q', '$translate', 'Ng2ToasterService', 'DemandeurManager'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $q: IQService,
                            $translate: angular.translate.ITranslateService,
                            ng2ToasterService: ToasterService,
                            demandeurManager: NgDemandeurManager) {
            const $ctrl = this;

            $ctrl.backToResults = backToResults;
            $ctrl.onChange = onChange;
            $ctrl.setInterest = setInterest;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.search = demandeurManager.current.recherches[0];
            $ctrl.options = {
                nameDossiers: $ctrl.search.type.toUpperCase() + 'S_LIST_CORRESPONDANTS_DISINTERESTED',
                displayMode: 'table',
                loader: {updating: true},
                visibleFields: {
                    angleRight: false,
                    archiveDateVente: false,
                    bullet: false,
                    mandatDateFin: false,
                    nomContactsGroup: false,
                    nombreChambres: false,
                    nombrePieces: false,
                    preciseLocalisation: false,
                    priceCession: false,
                    statut: false,
                },
            };
            $ctrl.search.getHistoricalResults().finally(() => $ctrl.options.loader.updating = false);

            /**
             * Back to list of results
             */
            function backToResults() {
                ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + ng2RouterStateForNgService.params.demandeurId + '/resultats');
            }

            /**
             * On change list
             *
             * @param resultsPaginator
             */
            function onChange(resultsPaginator: NgRechercheResultsCollection) {
                $ctrl.search.setDossiersCorrespondants(resultsPaginator, true);
            }

            /**
             * Set interest on each selected dossiers
             */
            function setInterest() {
                const promises: IPromise<unknown>[] = [];

                angular.forEach($ctrl.search._esk.disinterestedDossiersCorrespondants.collection, function (dossier) {
                    if (dossier._esk.select) {
                        const result = find($ctrl.search.disinterestedResults.collection, {[dossier._esk.typeDossier]: {id: dossier.id}});

                        result.interest = undefined!;
                        promises.push(result.save().then(() => dossier.id));
                    }
                });

                $q.all(promises).then(dossiers => {
                    angular.forEach(dossiers, function (dossierId) {
                        $ctrl.search._esk.disinterestedDossiersCorrespondants.collection.splice(findIndex($ctrl.search._esk.disinterestedDossiersCorrespondants.collection, {id: dossierId}), 1);
                        $ctrl.search._esk.disinterestedDossiersCorrespondants.totalItems = $ctrl.search._esk.disinterestedDossiersCorrespondants.collection.length;
                    });

                    if (promises.length > 0) {
                        ng2ToasterService.success($translate.instant('demandeur.results.TITLE', {nbResults: promises.length}), $translate.instant('demandeur.results.remise_corresp.MESSAGE', {nbResults: promises.length}));
                    }
                }).then(() => demandeurManager.current.updateSearchStatusAndEmitCurrent());
            }
        }
    })(angularJS);
}
