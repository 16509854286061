@if (cEstimationOnsaleReferences$ | async; as cEstimationOnsaleReferences) {
  <div class="tw-overflow-hidden">
    @if (cEstimationOnsaleReferences.total > 0) {
      <table id="estimationOnsaleReferences" class="tw-table-head-separate-body">
        <thead>
        <tr>
          <th class="tw-hidden md:tw-table-cell tw-w-[5.5rem]"><div>Photo</div></th>

          <th class="tw-w-40 2xl:tw-w-44"><div>Publication</div></th>

          <th><div>Adresse</div></th>

          <th class="tw-w-32 2xl:tw-w-36 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">{{ superficieLabel.replace('Surface', 'Surf.') }}</span>
              <span class="tw-hidden 2xl:tw-inline">{{ superficieLabel }}</span>
            </div>
          </th>

          <th class="tw-hidden 2xl:tw-table-cell tw-w-40 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">Surf. cadastrale</span>
              <span class="tw-hidden 2xl:tw-inline">Surface cadastrale</span>
            </div>
          </th>

          <th class="tw-hidden sm:tw-table-cell tw-w-32 2xl:tw-w-36 tw-text-right"><div>Prix</div></th>

          <th class="tw-hidden lg:tw-table-cell tw-w-28 2xl:tw-w-32 tw-text-right">
            <div>
              <span class="2xl:tw-hidden">Prix m²</span>
              <span class="tw-hidden 2xl:tw-inline">Prix au m²</span>
            </div>
          </th>

          <th class="tw-w-16 lg:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
        </tr>
        </thead>

        <tbody>
          @for (estimationOnsaleReference of cEstimationOnsaleReferences.results; track estimationOnsaleReference) {
            <tr [ngClass]="isEnabled(estimationOnsaleReference) ? '' : 'tw-opacity-75'"
                [appCollectionSortable]="[AppEstimationOnsaleReferencesListName, cEstimationOnsaleReferences.results, estimationOnsaleReference.uuid]"
                [appCollectionSortableOptions]="{name: AppEstimationOnsaleReferencesListName, tHeadWithDiv: true}">
              <td class="tw-table-head-separate-body-img tw-hidden md:tw-table-cell">
                <div class="tw-h-16 tw-w-16">
                  <app-estimation-onsale-reference-photo [estimationOnsaleReference]="estimationOnsaleReference"/>
                </div>
              </td>

              <td>{{ estimationOnsaleReference.publishedAt | date }}</td>

              <td>
                {{ estimationOnsaleReference.adresse }}
                <app-commune-convivial-name [linkCommune]="estimationOnsaleReference.linkCommune"
                                            class="tw-block tw-text-gray-400"/>
              </td>

              <td class="tw-text-right">{{ estimationOnsaleReference.superficie | textCurrency:'m²' }}</td>

              <td class="tw-hidden 2xl:tw-table-cell tw-text-right">
                {{ estimationOnsaleReference.surfaceCadastrale | textCurrency:'m²' }}
              </td>

              <td class="tw-hidden sm:tw-table-cell tw-text-right">
                {{ estimationOnsaleReference.prixVente | textCurrency }}
              </td>

              <td class="tw-hidden lg:tw-table-cell tw-text-right">
                {{ estimationOnsaleReference.prixM2 > 0 ? (estimationOnsaleReference.prixM2 | textCurrency) : null }}
              </td>

              <td class="tw-table-head-separate-body-actions">
                <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                        (click)="clickOnEstimationOnsaleReference(buttonElement, estimationOnsaleReference)"
                        #buttonElement><fa-icon [icon]="'ellipsis-v'"/></button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="tw-mb-4 sm:tw-mb-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
        <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
          Aucune référence en vente n'est sélectionnée.
        </p>
      </div>
    }

    <app-load-more [collection$]="cEstimationOnsaleReferences$" (loadMore)="getMore(cEstimationOnsaleReferences)"
                   [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
