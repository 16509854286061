import {Injectable} from '@angular/core';
import EtudeConstraint from '@models/etudes/etude/constraints/constraint/etude-constraint.model';
import {IEtudeConstraintApi} from '@models/etudes/etude/constraints/constraint/etude-constraint.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeConstraintFactory {
    create(etudeConstraintApi: IEtudeConstraintApi): EtudeConstraint {
        const etudeConstraint = new EtudeConstraint(etudeConstraintApi.uuid);

        etudeConstraintApi.expressions
            .filter(constraintExpression => constraintExpression.language === 'jexl')
            .forEach(constraintExpression => etudeConstraint.expression = constraintExpression.expression);
        etudeConstraint.message = etudeConstraintApi.message;
        etudeConstraint.name = etudeConstraintApi.name.replace(/Required$/, '');
        etudeConstraint.natures = etudeConstraintApi.natures;
        etudeConstraint.path = etudeConstraintApi.path;

        return etudeConstraint;
    }
}
