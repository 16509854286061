import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Vente from '@models/ventes/vente/vente.model';

export default function getEstimationsReferencesCriteriaCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskEstimationsReferencesCriteriaCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/controllers/references/criteria.html',
        });

        /**
         * Controller des critères de recherche des biens archivés
         *
         * @param Ng2RouterStateForNgService
         * @param DictionariesManager
         * @param Ng2DictionaryItemService
         * @param Ng2EtudeService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'DictionariesManager', 'Ng2DictionaryItemService', 'Ng2EtudeService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            dictionariesManager: NgDictionariesManager,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2EtudeService: EtudeService) {
            const $ctrl = this;

            $ctrl.launch = launch;
            $ctrl.search = angular.extend({
                archive: {
                    types: [
                        dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, Vente.archiveTypes.NEGOCIEE)),
                    ],
                },
                areas: [],
                communes: [],
                natures: [],
                quartiers: [],
                vente: {},
            }, ng2RouterStateForNgService.params.search);
            ng2EtudeService.last$.subscribe(etude => $ctrl.etude = etude);

            /**
             * Launch request
             */
            function launch() {
                if (!angular.isFunction($ctrl.updateSearchLocalisations)) {
                    return;
                }

                $ctrl.updateSearchLocalisations($ctrl.search).then(function () {
                    ng2RouterStateForNgService.navigateByUrl('/app/estimations/references', {search: $ctrl.search});
                });
            }
        }
    })(angularJS);
}
