import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ICCommuneVoiesApi} from '@models/communes/commune/voies/collection/commune-voies.collection.interfaces';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class CommuneVoiesApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(communeUuid: string, queryParameters?: IQueryParameters): Observable<ICCommuneVoiesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/communes/' + communeUuid + '/voies', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICCommuneVoiesApi> {
        return this._modelApiService.getCollection$(link);
    }
}
