import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {IDossierBienOverviewMainInfo} from '@features/dossiers/dossier/bien/overview/dossier-bien-overview.interfaces';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewMainInfosService {
    static readonly fields = {
        ASCENSEUR: DossierBienFieldsConst.ascenseur,
        BALCON: DossierBienFieldsConst.balcon,
        COMMUNE: DossierBienFieldsConst.commune,
        EXCEPTIONNEL: DossierBienFieldsConst.exceptionnel,
        GARAGE: DossierBienFieldsConst.garage,
        LONGUEUR_FACADE: DossierBienFieldsConst.longueurFacade,
        NATURE: DossierBienFieldsConst.nature,
        NOMBRE_CHAMBRES: DossierBienFieldsConst.nombreChambres,
        NOMBRE_LOGEMENTS: DossierBienFieldsConst.nombreLogements,
        NOMBRE_PIECES: DossierBienFieldsConst.nombrePieces,
        NOMBRE_PLACES_PARKING: DossierBienFieldsConst.nombrePlacesParking,
        PLAIN_PIED: DossierBienFieldsConst.plainPied,
        SURFACE_CADASTRALE: DossierBienFieldsConst.surfaceCadastrale,
        SUPERFICIE: DossierBienFieldsConst.superficie,
        TERRASSE: DossierBienFieldsConst.terrasse,
        VIABILISATION_EAU: DossierBienFieldsConst.viabilisationEau,
        VIABILISATION_ELECTRICITE: DossierBienFieldsConst.viabilisationElectricite,
        VIABILISATION_VOIRIE: DossierBienFieldsConst.viabilisationVoirie,
    };
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _textCurrencyPipe = inject(TextCurrencyPipe);
    private _mainInfos: IDossierBienOverviewMainInfo[] = [];

    get mainInfos(): IDossierBienOverviewMainInfo[] {
        return this._mainInfos;
    }

    getByCode(code: string): IDossierBienOverviewMainInfo | undefined {
        return this.mainInfos.find(mainInfo => mainInfo.code === code);
    }

    getForAppartement(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromNombrePieces(dossierBien),
            this.getFromNombreChambres(dossierBien),
            this.getFromAscenseur(dossierBien),
            this.getFromNombrePlacesParking(dossierBien),
            this.getFromTerrasse(dossierBien),
            this.getFromBalcon(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForAutre(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForAutreTerrain(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromLongueurFacade(dossierBien),
            this.getFromViabilisationEau(dossierBien),
            this.getFromViabilisationElectricite(dossierBien),
            this.getFromViabilisationVoirie(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForBureaux(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromNombrePieces(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForGarage(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForImmeuble(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromNombreLogements(dossierBien),
            this.getFromAscenseur(dossierBien),
            this.getFromNombrePlacesParking(dossierBien),
            this.getFromTerrasse(dossierBien),
            this.getFromBalcon(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForLocauxCommerciaux(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromNombrePieces(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForMaison(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromExceptionnel(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromNombrePieces(dossierBien),
            this.getFromNombreChambres(dossierBien),
            this.getFromSurfaceCadastrale(dossierBien),
            this.getFromPlainPied(dossierBien),
            this.getFromGarage(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getForTerrainABatir(dossierBien: ADossierBien): IDossierBienOverviewMainInfo[] {
        return [
            this.getFromNature(dossierBien),
            this.getFromCommune(dossierBien),
            this.getFromSuperficie(dossierBien),
            this.getFromLongueurFacade(dossierBien),
            this.getFromViabilisationEau(dossierBien),
            this.getFromViabilisationElectricite(dossierBien),
            this.getFromViabilisationVoirie(dossierBien),
        ].filter(mainInfo => !!mainInfo);
    }

    getFromAscenseur(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.ascenseur)) {
            return null!;
        }

        return {code: DossierBienOverviewMainInfosService.fields.ASCENSEUR, label: 'Ascenseur'};
    }

    getFromBalcon(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.balcon)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.BALCON,
            label: dossierBien.bien.surfaceBalcon > 0 ? this._textCurrencyPipe.transform(dossierBien.bien.surfaceBalcon, 'm²') : '',
            subLabel: 'Balcon',
        };
    }

    getFromCommune(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commune)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.COMMUNE,
            label: dossierBien.bien.commune.fullname,
            subLabel: dossierBien.bien.quartier?.nom ?? '',
        };
    }

    getFromExceptionnel(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.exceptionnel)) {
            return null!;
        }

        return {code: DossierBienOverviewMainInfosService.fields.EXCEPTIONNEL, label: 'Bien d\'exception'};
    }

    getFromGarage(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.garage)) {
            return null!;
        }

        return {code: DossierBienOverviewMainInfosService.fields.GARAGE,
            label: dossierBien.bien.surfaceGarage > 0 ? this._textCurrencyPipe.transform(dossierBien.bien.surfaceGarage, 'm²') : '',
            subLabel: 'Garage',
        };
    }

    getFromLongueurFacade(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.longueurFacade)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.LONGUEUR_FACADE,
            label: this._textCurrencyPipe.transform(dossierBien.bien.longueurFacade, 'm'),
            subLabel: 'Longueur façade',
        };
    }

    getFromNature(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nature)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.NATURE,
            label: dossierBien.bien.nature.label,
            subLabel: dossierBien.bien.designation ?? '',
        };
    }

    getFromNombreChambres(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreChambres)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.NOMBRE_CHAMBRES,
            label: dossierBien.bien.nombreChambres.toString(),
            subLabel: 'Chambre' + (dossierBien.bien.nombreChambres > 1 ? 's' : ''),
        };
    }

    getFromNombreLogements(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreLogements)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.NOMBRE_LOGEMENTS,
            label: dossierBien.bien.nombreLogements.toString(),
            subLabel: 'Logement' + (dossierBien.bien.nombreLogements > 1 ? 's' : ''),
        };
    }

    getFromNombrePieces(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombrePieces)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.NOMBRE_PIECES,
            label: dossierBien.bien.nombrePieces.toString(),
            subLabel: 'Pièce' + (dossierBien.bien.nombrePieces > 1 ? 's' : ''),
        };
    }

    getFromNombrePlacesParking(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombrePlacesParking)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.NOMBRE_PLACES_PARKING,
            label: dossierBien.bien.nombrePlacesParking.toString(),
            subLabel: 'Place' + (dossierBien.bien.nombrePlacesParking > 1 ? 's' : '') + ' de parking',
        };
    }

    getFromPlainPied(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.plainPied)) {
            return null!;
        }

        return {code: DossierBienOverviewMainInfosService.fields.PLAIN_PIED, label: dossierBien.bien.plainPied.label};
    }

    getFromSuperficie(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.superficie)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.SUPERFICIE,
            label: this._textCurrencyPipe.transform(dossierBien.bien.superficie, 'm²'),
            subLabel: this._dictionaryItemService.getOneSuperficieLabel(dossierBien.bien.nature),
        };
    }

    getFromSurfaceCadastrale(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfaceCadastrale)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.SURFACE_CADASTRALE,
            label: this._textCurrencyPipe.transform(dossierBien.bien.surfaceCadastrale, 'm²'),
            subLabel: 'Surface cadastrale',
        };
    }

    getFromTerrasse(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.terrasse)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.TERRASSE,
            label: dossierBien.bien.surfaceTerrasse > 0 ? this._textCurrencyPipe.transform(dossierBien.bien.surfaceTerrasse, 'm²') : '',
            subLabel: 'Terrasse',
        };
    }

    getFromViabilisationEau(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.viabilisationEau)) {
            return null!;
        }

        return {code: DossierBienOverviewMainInfosService.fields.VIABILISATION_EAU, label: 'Eau', subLabel: 'Raccordement'};
    }

    getFromViabilisationElectricite(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.viabilisationElectricite)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.VIABILISATION_ELECTRICITE,
            label: 'Électricité',
            subLabel: 'Raccordement',
        };
    }

    getFromViabilisationVoirie(dossierBien: ADossierBien): IDossierBienOverviewMainInfo {
        if (!this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.viabilisationVoirie)) {
            return null!;
        }

        return {
            code: DossierBienOverviewMainInfosService.fields.VIABILISATION_VOIRIE,
            label: 'Voirie',
            subLabel: 'Raccordement',
        };
    }

    init(dossierBien: ADossierBien, limit = -1): void {
        let mainInfos: IDossierBienOverviewMainInfo[];

        if (dossierBien.bien.isAppartement()) {
            mainInfos = this.getForAppartement(dossierBien);
        } else if (dossierBien.bien.isAutreTerrain()) {
            mainInfos = this.getForAutreTerrain(dossierBien);
        } else if (dossierBien.bien.isBureaux()) {
            mainInfos = this.getForBureaux(dossierBien);
        } else if (dossierBien.bien.isGarage()) {
            mainInfos = this.getForGarage(dossierBien);
        } else if (dossierBien.bien.isImmeuble()) {
            mainInfos = this.getForImmeuble(dossierBien);
        } else if (dossierBien.bien.isLocauxCommerciaux()) {
            mainInfos = this.getForLocauxCommerciaux(dossierBien);
        } else if (dossierBien.bien.isMaison()) {
            mainInfos = this.getForMaison(dossierBien);
        } else if (dossierBien.bien.isTerrainABatir()) {
            mainInfos = this.getForTerrainABatir(dossierBien);
        } else {
            mainInfos = this.getForAutre(dossierBien);
        }

        this._mainInfos = mainInfos.slice(0, limit > 0 ? limit : mainInfos.length);
    }
}
