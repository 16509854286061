import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IDemandeurRechercheApi} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.interfaces';
import {
    ICDemandeurRecherchesApi
} from '@models/demandeurs/demandeur/recherches/collection/demandeur-recherches.collection.interfaces';

@Injectable({providedIn: 'root'})
export class DemandeurRecherchesApiService {
    private _modelApiService = inject(ModelApiService);

    getByLink$(link: string): Observable<IDemandeurRechercheApi> {
        return this._modelApiService.get$(link);
    }

    getCollectionByLink$(link: string): Observable<ICDemandeurRecherchesApi> {
        return this._modelApiService.get$(link);
    }
}
