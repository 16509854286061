import Contact from '@models/contacts/contact/contact.model';

export default class ContactsGroupMember {
    private _contact!: Contact;
    private _sortableRank!: number;

    get contact(): Contact {
        return this._contact;
    }

    set contact(value: Contact) {
        this._contact = value;
    }

    get id(): number {
        return this.contact.id;
    }

    get sortableRank(): number {
        return this._sortableRank;
    }

    set sortableRank(value: number) {
        this._sortableRank = value;
    }

    // Utile pour la modification de l'ordre
    get uuid(): string {
        return this.contact.uuid;
    }


}
