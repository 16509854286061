import angularJS from '@shared/angularJS/global.ng';
import {filter, find} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {
    NgDemandeurManager, NgDemandeurRechercheManager, NgDemandeurRechercheResultatManager, NgEmailManager,
    NgFilterManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {
    NgContact, NgContactsGroupMember, NgDemandeur, NgDemandeurRecherche, NgEmail, NgFilter, NgRechercheResult
} from '@legacy/app/managers/ressources';
import {NgDemandeurUtils} from '@legacy/app/demandeurs/demandeurs';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {
    CDemandeurRechercheResultatsFactory
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.factory';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {combineLatest, firstValueFrom, from, Observable} from 'rxjs';
import {DemandeurRechercheFactory} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.factory';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';
import {NgDemandeursCollection} from '@legacy/app/managers/collections';
import Person from '@models/contacts/person/person.model';
import Entreprise from '@models/contacts/entreprise/entreprise.model';
import Contact from '@models/contacts/contact/contact.model';
import {NgPaginate} from '@legacy/app/client/client';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienType, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Vente from '@models/ventes/vente/vente.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

export default function getVenteCorrespondantsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskVenteCorrespondantsCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/controllers/item/correspondants.html',
        });

        /**
         * Controller for list of demandeurs correspondants to venteId
         *
         * @param FilterManager
         * @param VenteManager
         * @param DemandeurManager
         * @param Ng2RouterStateForNgService
         * @param $q
         * @param DemandeurRechercheResultatManager
         * @param EmailManager
         * @param $translate
         * @param DemandeursUtils
         * @param BiensUtils
         * @param Ng2ToasterService
         * @param Ng2CDemandeurRechercheResultatsFactory
         * @param Ng2DemandeurRechercheFactory
         * @param Ng2DemandeurFactory
         * @param DemandeurRechercheManager
         * @constructor
         */
        Controller.$inject = ['FilterManager', 'VenteManager', 'DemandeurManager', 'Ng2RouterStateForNgService', '$q', 'DemandeurRechercheResultatManager', 'EmailManager', '$translate', 'DemandeursUtils', 'BiensUtils', 'Ng2ToasterService', 'Ng2CDemandeurRechercheResultatsFactory', 'DemandeurRechercheManager', 'Ng2DemandeurRechercheFactory', 'Ng2DemandeurFactory'];
        function Controller(this: any,
                            filterManager: NgFilterManager,
                            venteManager: NgVenteManager,
                            demandeurManager: NgDemandeurManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $q: IQService,
                            demandeurRechercheResultatManager: NgDemandeurRechercheResultatManager,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            demandeursUtils: NgDemandeurUtils,
                            biensUtils: NgBienUtils,
                            ng2ToasterService: ToasterService,
                            ng2CDemandeurRechercheResultatsFactory: CDemandeurRechercheResultatsFactory,
                            demandeurRechercheManager: NgDemandeurRechercheManager,
                            ng2DemandeurRechercheFactory: DemandeurRechercheFactory,
                            ng2DemandeurFactory: DemandeurFactory) {
            const $ctrl = this;
            const addedParams = {venteUuid: undefined! as string};
            var typeList = 'DEMANDEURS_LIST_CORRESPONDANTS';

            $ctrl.$onInit = $onInit;
            $ctrl.sortDemandeurs = sortDemandeurs;
            $ctrl.onClick = onClick;
            $ctrl.setInterest = setInterest;
            $ctrl.send = send;
            $ctrl.getSelectedDemandeurs = getSelectedDemandeurs;
            $ctrl.DISINTERESTED = DemandeurRechercheResultat.interests.DISINTERESTED;
            $ctrl.DISINTERESTED_EXCEPT_PRICE = DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE;

            function $onInit() {
                $ctrl.options = {
                    nameDossiers: typeList + (venteManager.current.statut === Vente.statuts.BROUILLON ? '_POTENTIELS' : ''),
                    typeFilter: typeList,
                    typeSort: typeList,
                    loader: {filter: true, updating: true},
                    private: true,
                    visibleFields: {
                        results: venteManager.current.statut !== Vente.statuts.BROUILLON,
                        select: venteManager.current.statut !== Vente.statuts.BROUILLON,
                    },
                    onChangeFilter: (filter: NgFilter) => {
                        $ctrl.options.loader.updating = true;
                        $ctrl.filter = filter;

                        return getFilteredDemandeurs().finally(() => $ctrl.options.loader.updating = false);
                    }
                };
                filterManager.getActiveByRouteCode(Filter.routes.GET_ETUDE_DEMANDEURS, 'correspondants').then(
                    filter => filter as NgFilter
                ).then(filter => {
                    filter.nom = undefined!;
                    $ctrl.filter = filter;
                    $ctrl.options.loader.filter = false;
                    if (venteManager.current.statut === Vente.statuts.BROUILLON) {
                        addedParams.venteUuid = venteManager.current.uuid;
                        $ctrl.filter.raz();
                    } else {
                        $ctrl.filter.queryParameters.recherches.resultats.vente.values = [venteManager.current.id];
                    }

                    return getFilteredDemandeurs();
                }).finally(() => $ctrl.options.loader.updating = false);
            }

            /**
             * Sort demandeurs
             */
            function sortDemandeurs() {
                $ctrl.options.loader.updating = true;
                $ctrl.filter.save().then(getFilteredDemandeurs).finally(function () {
                    $ctrl.options.loader.updating = false;
                });
            }

            /**
             * Click on item
             *
             * @param demandeur
             */
            function onClick(demandeur: NgDemandeur) {
                ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + demandeur.id.toString() + '/resultats');
            }

            /**
             * Set interest on each selected ventes
             *
             * @param interest
             * @return Promise
             */
            function setInterest(interest: string) {
                const promises = [] as IPromise<unknown>[];

                $ctrl.barreFaireLoader = 'global.en_cours.DEFAULT';
                angular.forEach($ctrl.getSelectedDemandeurs(), function (demandeur) {
                    promises.push(demandeur.recherches[0].getResults().then((results: NgPaginate) => {
                        return getMatchedResult(results).then(function (result) {
                            result.interest = interest;
                            result._esk.defaultRouteParams!.rechercheId! = demandeur.recherches[0].id;

                            return result.save();
                        });
                    }));
                });

                return $q.all(promises).then(() => {
                    if (promises.length > 0) {
                        ng2ToasterService.success($translate.instant('demandeur.results.TITLE', {nbResults: promises.length}), $translate.instant('demandeur.results.action.MESSAGE', {
                            nbResults: promises.length,
                            state: demandeurRechercheResultatManager.stringifyInterest(interest)
                        }));
                    }
                }).then(_ => $ctrl.$onInit()).finally(() => {
                    $ctrl.unselectAll();
                    $ctrl.barreFaireLoader = undefined;
                });
            }

            /**
             * Send list ventes
             */
            function send() {
                var dossiers = venteManager.current.bien._esk.interne ? [{
                    typeId: venteManager.current.id,
                    type: DossierBienTypesConst.VENTE,
                    dossier: venteManager.current
                }] : [];

                $ctrl.barreFaireLoader = 'global.en_cours.SEND';
                biensUtils.getMediasFiche(venteManager.current._esk.typeDossier! as DossierBienType, [venteManager.current]).then(attachments => {
                    return demandeursUtils.sendGroupedEmail($ctrl.getSelectedDemandeurs(), emailManager.create({
                        dossiers: dossiers,
                        attachments: attachments,
                        subject: $translate.instant('mail.bien_correspondants.TITLE', {
                            typeDossier: DossierBienTypesConst.VENTE,
                            nbDossiers: 1
                        }),
                        message: $translate.instant('mail.bien_correspondants.MESSAGE', {
                            typeDossier: DossierBienTypesConst.VENTE,
                            nbDossiers: 1,
                            references: venteManager.current.bien.reference,
                            formuleIntroduction: ContactsGroup.default.FORMULE_INTRODUCTION,
                            formulePolitesse: ContactsGroup.default.FORMULE_POLITESSE,
                        }) + "<br><br>" + emailManager.getCurrentUserSignature()
                    }) as NgEmail, venteManager.current).then(function () {
                        return $ctrl.setInterest('interested');
                    });
                }).finally(function () {
                    $ctrl.unselectAll();
                    $ctrl.barreFaireLoader = undefined;
                });
            }

            /**
             * Get selected demandeurs
             *
             * @returns {Array}
             */
            function getSelectedDemandeurs() {
                let selectedDemandeurs = [] as unknown[];

                if (angular.isObject($ctrl.demandeurs)) {
                    selectedDemandeurs = filter($ctrl.demandeurs.collection, {_esk: {select: true}});
                }

                return selectedDemandeurs;
            }

            /**
             * Get filtered demandeurs
             *
             * @return Promise
             */
            function getFilteredDemandeurs() {
                let promise: IPromise<NgDemandeursCollection>;

                if (venteManager.current.statut === Vente.statuts.BROUILLON) {
                    promise = demandeurManager.getAllEtudeFiltered($ctrl.filter, addedParams).then(function (demandeurs) {
                        angular.forEach(demandeurs.collection, function (demandeur) {
                            demandeur._esk.routing = '/app/demandeurs/' + demandeur.id.toString() + '/resultats';
                        });

                        return demandeurs;
                    });
                } else {
                    const brutParams = angular.extend($ctrl.filter.getParamsRequest(), addedParams);
                    const params = angular.extend(brutParams, brutParams.recherches?.resultats);

                    delete params['recherches'];
                    delete params['statut'];
                    params.limit = 200;
                    if (params.tris?.createdAt) {
                        params.tris.createdAtDemandeur = params.tris.createdAt;
                        delete params.tris?.createdAt;
                    }

                    if (params.tris?.updatedAt) {
                        params.tris.updatedAtDemandeur = params.tris.updatedAt;
                        delete params.tris?.updatedAt;
                    }

                    promise = firstValueFrom(ng2CDemandeurRechercheResultatsFactory.getEtude$(params).pipe(
                        switchMap(cDemandeurRechercheResultats => {
                            const cDemandeurs: NgDemandeursCollection = {
                                collection: [],
                                limit: cDemandeurRechercheResultats.perPage,
                                page: cDemandeurRechercheResultats.page,
                                pages: cDemandeurRechercheResultats.pages,
                                totalItems: cDemandeurRechercheResultats.total
                            } as unknown as NgDemandeursCollection;
                            const get$s: Observable<NgDemandeur>[] = [];

                            cDemandeurRechercheResultats.results.forEach(demandeurRechercheResultat => {
                                let ngDemandeur: NgDemandeur;
                                let ngDemandeurRecherche: NgDemandeurRecherche;

                                get$s.push(ng2DemandeurFactory.getByLink$(demandeurRechercheResultat.linkDemandeur).pipe(
                                    switchMap(demandeur => {
                                        const members = [] as NgContactsGroupMember[];

                                        demandeur.contactsGroup?.members.forEach(member => members.push({contact: getDataContact(member.contact)} as NgContactsGroupMember));

                                        return from(demandeurManager.createFromNg2(demandeur));
                                    }),
                                    tap(ngDemandeurEdit => ngDemandeur = ngDemandeurEdit),
                                    switchMap(_ => ng2DemandeurRechercheFactory.getByLink$(demandeurRechercheResultat.linkDemandeurRecherche)),
                                    tap(demandeurRecherche => ngDemandeurRecherche = demandeurRechercheManager.create({
                                        id: demandeurRecherche.id,
                                        localizationSummary: demandeurRecherche.localizationSummary,
                                        natures: demandeurRecherche.natures,
                                        uuid: demandeurRecherche.uuid,
                                    }, ngDemandeur.id)),
                                    tap(_ => {
                                        const attributes = {
                                            interest: demandeurRechercheResultat.interest,
                                            reason: demandeurRechercheResultat.reason,
                                            status: demandeurRechercheResultat.status
                                        };

                                        if (demandeurRechercheResultat.interest === DemandeurRechercheResultat.interests.INTEREST_NOT_GIVEN) {
                                            attributes.interest = 'interestNotGiven';
                                        } else if (demandeurRechercheResultat.interest === DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE) {
                                            attributes.interest = 'disinterestedExceptPrice';
                                        }

                                        if (demandeurRechercheResultat.reason === DemandeurRechercheResultat.reasons.FIRST_MATCH) {
                                            attributes.reason = 'firstMatch';
                                        }

                                        if (demandeurRechercheResultat.status === DemandeurRechercheResultat.statuts.MATCH_UPDATED) {
                                            attributes.status = 'matchUpdated';
                                        } else if (demandeurRechercheResultat.status === DemandeurRechercheResultat.statuts.MATCH_REMOVED) {
                                            attributes.status = 'matchRemoved';
                                        }

                                        ngDemandeurRecherche.nbResultsPerStatuses = {};
                                        (ngDemandeurRecherche.nbResultsPerStatuses as Record<string, unknown>)[attributes.status] = {};
                                        ((ngDemandeurRecherche.nbResultsPerStatuses as Record<string, unknown>)[attributes.status] as Record<string, unknown>)[attributes.reason] = {};
                                        (((ngDemandeurRecherche.nbResultsPerStatuses as Record<string, unknown>)[attributes.status] as Record<string, unknown>)[attributes.reason] as Record<string, unknown>)[attributes.interest] = 1;
                                        ngDemandeurRecherche.updateStatus();
                                    }),
                                    tap(_ => ngDemandeur.recherches = [ngDemandeurRecherche]),
                                    map(_ => ngDemandeur),
                                ));
                            });

                            return combineLatest(get$s).pipe(tap(ngDemandeurs => cDemandeurs.collection = ngDemandeurs), map(_ => cDemandeurs));
                        }),
                        map(cDemandeurs => {
                            angular.forEach(cDemandeurs.collection, function (demandeur) {
                                demandeur._esk.routing = '/app/demandeurs/' + demandeur.id.toString() + '/resultats';
                            });

                            return cDemandeurs;
                        }),
                        take(1),
                    ));
                }

                return promise.then(cDemandeurs => $ctrl.demandeurs = cDemandeurs);
            }

            /**
             * Get matched result
             *
             * @param resultPaginator
             * @returns {*}
             */
            function getMatchedResult(resultPaginator: NgPaginate): IPromise<NgRechercheResult> {
                const result = find(resultPaginator.collection, {vente: {id: venteManager.current.id}}) as NgRechercheResult;

                if (!result) {
                    if (resultPaginator.current <= resultPaginator.pages) {
                        return resultPaginator.nextPage().then(getMatchedResult);
                    }
                }

                return $q.resolve(result);
            }

            /**
             * Get data from Contact
             *
             * @param contact
             */
            function getDataContact(contact: Contact): NgContact {
                const dataContact: Record<string, unknown> = {
                    communeId: contact.communeId,
                    emailAddress: contact.emailAddress,
                    mobile: contact.mobile,
                    telephone: contact.telephone,
                };
                let representant: NgContact = null!;

                if (contact.representant) {
                    representant = getDataContact(contact.representant);
                }

                if (contact instanceof Entreprise) {
                    const contactEntreprise = contact as Entreprise;

                    dataContact['raisonSociale'] = contactEntreprise.raisonSociale;
                } else if (contact instanceof Person) {
                    const contactPerson = contact as Person;

                    dataContact['nom'] = contactPerson.nom;
                    dataContact['prenom'] = contactPerson.prenom;
                    dataContact['titre'] = contactPerson.titre?.code!;
                }

                return {
                    entreprise: dataContact,
                    id: contact.id,
                    person: dataContact,
                    representant,
                    type: contact.type,
                    uuid: contact.uuid,
                } as NgContact;
            }
        }
    })(angularJS);
}
