import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICCityscanOnsalePropertiesApi, ICCityscanOnsalePropertiesQueryParameters
} from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class CityscanOnsalePropertiesApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(queryParameters?: ICCityscanOnsalePropertiesQueryParameters, httpOptions?: HttpOptions): Observable<ICCityscanOnsalePropertiesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/cityscan/on-sale-properties', queryParameters), httpOptions);
    }

    getCollectionByLink$(link: string, httpOptions?: HttpOptions): Observable<ICCityscanOnsalePropertiesApi> {
        return this._modelApiService.getCollection$(link, httpOptions);
    }
}
