import {Component, Input, OnDestroy, OnInit, output} from '@angular/core';
import {IFormCheckboxListOptions, IFormFieldCheckbox} from '@shared/form/form.interfaces';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

@Component({selector: 'app-form-checkbox-list', templateUrl: 'form.checkbox-list.component.html'})
export class AppFormCheckboxListComponent implements OnDestroy, OnInit {
    static readonly initFormCheckboxListOptions: IFormCheckboxListOptions = {
        bindLabel: 'convivialName',
        name: 'checkbox',
    };
    readonly selected = output<IFormFieldCheckbox[]>();
    private _list: IFormFieldCheckbox[] = [];
    private _modelSource = new ReplaySubject<IFormFieldCheckbox[]>(1);
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IFormCheckboxListOptions = {...AppFormCheckboxListComponent.initFormCheckboxListOptions};
    private _selectedItems = new Set<IFormFieldCheckbox>();

    get list(): IFormFieldCheckbox[] {
        return this._list;
    }

    @Input()
    set list(value: IFormFieldCheckbox[]) {
        this._list = value || [];
    }

    @Input()
    set model(value: IFormFieldCheckbox[]) {
        this._modelSource.next(value || []);
    }

    get options(): IFormCheckboxListOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormCheckboxListOptions) {
        this._options = {...AppFormCheckboxListComponent.initFormCheckboxListOptions, ...value};
    }

    ngOnInit(): void {
        this._modelSource.asObservable().pipe(
            tap(_ => this._selectedItems.clear()),
            takeUntil(this._onDestroy$),
        ).subscribe(model => model.filter(item => !!item?.uuid)
            .map(item => this._list.find(itemList => itemList.uuid === item.uuid)!)
            .forEach(item => this.toggle(item)));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    isChecked(item: IFormFieldCheckbox): boolean {
        return this._selectedItems.has(item);
    }

    onChange(item: IFormFieldCheckbox): void {
        this.toggle(item);
        this.selected.emit(Array.from(this._selectedItems));
    }

    toggle(item: IFormFieldCheckbox): void {
        if (this.isChecked(item)) {
            this._selectedItems.delete(item);
        } else {
            this._selectedItems.add(item);
        }
    }
}
