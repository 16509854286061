import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {
    NgBienPhotoPasserelleMManager, NgDictionariesManager, NgManager, NgUtilsManager
} from '@legacy/app/managers/managers';
import {NgLocationPasserellesCollection} from '@legacy/app/managers/collections';
import {NgBienPhotoPasserelle, NgLocationPasserelle} from '@legacy/app/managers/ressources';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import DateFormat from '@shared/date/date.format';

export default function getManagersLocationPasserelle(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('LocationPasserelleManager', manager);

        /**
         * Manager locationPasserelle
         *
         * @param BaseManager
         * @param UtilsManager
         * @param DictionariesManager
         * @param BienPhotoPasserelleManager
         * @param $q
         * @param Ng2DictionaryItemService
         * @param Ng2DictionaryFactory
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'DictionariesManager', 'BienPhotoPasserelleManager', '$q', 'Ng2DictionaryItemService', 'Ng2DictionaryFactory'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         dictionariesManager: NgDictionariesManager,
                         bienPhotoPasserelleManager: NgBienPhotoPasserelleMManager,
                         $q: IQService,
                         ng2DictionaryItemService: DictionaryItemService,
                         ng2DictionaryFactory: DictionaryFactory) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('location-passerelles.cget', {
                path: '/locations/{locationId}/external/passerelles',
                method: 'GET'
            });
            baseManager.addRoute('location-passerelles.get', {
                path: '/locations/{locationId}/external/passerelles/{id}',
                method: 'GET'
            });
            baseManager.addRoute('location-passerelles.insert', {
                path: '/locations/{locationId}/external/passerelles/{id}',
                method: 'POST'
            });
            baseManager.addRoute('location-passerelles.edit', {
                path: '/locations/{locationId}/external/passerelles/{id}',
                method: 'PUT'
            });
            baseManager.addRoute('location-passerelles.diffuse', {
                path: '/locations/{locationId}/external/passerelles/{id}/diffuser',
                method: 'PATCH'
            });
            baseManager.addRoute('location-passerelles.suspend', {
                path: '/locations/{locationId}/external/passerelles/{id}/retirer',
                method: 'PATCH'
            });

            /**
             * LocationPasserelleManager object
             *
             * @constructor
             */
            angular.extend(LocationPasserelleManager.prototype, baseManager.__proto__);
            LocationPasserelleManager.prototype.eskManager = {
                prefixRoute: 'location-passerelles',
                collectionName: 'items'
            };
            function LocationPasserelleManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @returns {Promise}
             */
            LocationPasserelleManager.prototype.getAll = function (locationId: number) {
                return baseManager.getAll.call(this, {locationId}).then(locationPasserelles => {
                    const locationPasserellesCollection: NgLocationPasserellesCollection = locationPasserelles as NgLocationPasserellesCollection;

                    angular.forEach(locationPasserellesCollection.collection, function (locationPasserelle) {
                        locationPasserelle._esk.defaultRouteParams!.locationId = locationId;
                    });

                    return locationPasserellesCollection;
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @param resourceId
             * @returns {Promise}
             */
            LocationPasserelleManager.prototype.getOneById = function (locationId: number, resourceId: number) {
                return this.get('get', {
                    locationId: locationId,
                    id: resourceId
                }).then(function (locationPasserelle: NgLocationPasserelle) {
                    locationPasserelle._esk.defaultRouteParams!.locationId = locationId;
                    updatePhotosRouteParams(locationId, locationPasserelle.photos);

                    return locationPasserelle;
                });
            };

            /**
             * Create a LocationPasserelle object
             *
             * @param data
             * @param locationId
             * @returns manager.LocationPasserelle object || Array of manager.LocationPasserelle objects
             */
            LocationPasserelleManager.prototype.create = function (data: unknown, locationId: number) {
                if (angular.isArray(data)) {
                    var locationPasserelles = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        locationPasserelles.push(new LocationPasserelle(locationId, data[i]));
                    }

                    return locationPasserelles;
                }

                // @ts-ignore
                return new LocationPasserelle(locationId, data);
            };

            /**
             * LocationPasserelle object
             *
             * @param locationId
             * @param data
             * @constructor
             */
            angular.extend(LocationPasserelle.prototype, classResource.prototype);
            LocationPasserelle.prototype.eskManager = {prefixRoute: 'location-passerelles'};
            function LocationPasserelle(this: NgLocationPasserelle, locationId: number, data: unknown) {
                this.extend(data);
                this.id = angular.isNumber(this.passerelle.id) as unknown as number;
                this._esk.defaultRouteParams = {id: this.passerelle.id, locationId: locationId};
                updatePhotosRouteParams(locationId, this.photos);
                if (this.afficherCommune !== false) {
                    this.afficherCommune = true;
                }
                if (this.afficherDpe !== false) {
                    this.afficherDpe = true;
                }
                this._esk.persist = this.id as unknown as boolean;
                this._esk.flush = this._esk.persist;
                updateStatut(this);
            }

            /**
             * Extend the existing LocationPasserelle with new data
             *
             * @param data
             * @returns {LocationPasserelle}
             */
            LocationPasserelle.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isString(this.dateDiffusion)) {
                    this.dateDiffusion = DateFormat.toDate(this.dateDiffusion);
                }

                if (angular.isString(this.dateRetrait)) {
                    this.dateRetrait = DateFormat.toDate(this.dateRetrait);
                }

                if (!angular.isObject(this._esk.normalizer)) {
                    if (!this.natureExternalType) {
                        this.natureExternalType = this.passerelle.normalizer;
                    }

                    this._esk.normalizer = {natureExternalType: this.natureExternalType};
                }

                if (!angular.isObject(this.natureExternalType) && ng2DictionaryFactory.getByName('natures-' + this._esk.normalizer.natureExternalType)?.items?.length > 0) {
                    this.natureExternalType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById('natures-' + this._esk.normalizer.natureExternalType, this.natureExternalTypeId));
                }

                if (angular.isArray(this.photos)) {
                    this.photos = bienPhotoPasserelleManager.create(this.photos, null!, this.passerelle.id);
                } else {
                    this.photos = [];
                }

                return this;
            };

            /**
             * Sanitize object LocationPasserelle before send to API
             *
             * @returns {Object}
             */
            LocationPasserelle.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet LocationPasserelle pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'natureExternalType');
                sanitizeObject.natureExternalType = this._esk.normalizer.natureExternalType;

                if (ng2DictionaryFactory.getByName('natures-' + this._esk.normalizer.natureExternalType)?.items?.length <= 0 || !sanitizeObject.natureExternalTypeId) {
                    delete sanitizeObject.natureExternalType;
                    delete sanitizeObject.natureExternalTypeId;
                }

                return sanitizeObject;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            LocationPasserelle.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self.id = angular.isNumber(self.passerelle.id);
                    self._esk.defaultRouteParams.id = self.passerelle.id;

                    return self;
                }, (rejection: any) => {
                    if (!self.id && angular.isObject(rejection) && rejection.status === 409) {
                        self.id = true;

                        return self.save();
                    }

                    return $q.reject(rejection);
                });
            };

            /**
             * Passerelle
             *
             * @returns {Promise}
             */
            LocationPasserelle.prototype.diffuse = function () {
                const self = this;

                return self.patch('diffuse').then(function () {
                    self.dateDiffusion = DateFormat.toDate();
                    updateStatut(self);
                });
            };

            /**
             * Suspension
             *
             * @returns {Promise}
             */
            LocationPasserelle.prototype.suspend = function () {
                const self = this;

                return self.patch('suspend').then(function () {
                    self.dateRetrait = DateFormat.toDate();
                    self.dateDiffusion = null;
                    updateStatut(self);
                });
            };

            /**
             * Is diffused
             *
             * @returns {Boolean}
             */
            LocationPasserelle.prototype.isDiffused = function () {
                if (!angular.isDefined(this.dateDiffusion) || this.dateDiffusion === null) {
                    return false;
                }

                if (!angular.isDefined(this.dateRetrait) || this.dateRetrait === null) {
                    return true;
                }

                return DateFormat.diff(this.dateRetrait.toString(), this.dateDiffusion.toString()) < 0;
            };

            /**
             * Is suspended
             *
             * @returns {Boolean}
             */
            LocationPasserelle.prototype.isSuspended = function () {
                if (!angular.isDefined(this.dateRetrait) || this.dateRetrait === null) {
                    return false;
                }

                if (!angular.isDefined(this.dateDiffusion) || this.dateDiffusion === null) {
                    return true;
                }

                return DateFormat.diff(this.dateDiffusion.toString(), this.dateRetrait.toString()) < 0;
            };

            /**
             * Update params for bienPhotoPasserelles route
             *
             * @param locationId
             * @param bienPhotoPasserelles
             */
            function updatePhotosRouteParams(locationId: number, bienPhotoPasserelles: NgBienPhotoPasserelle[]) {
                angular.forEach(bienPhotoPasserelles, function (bienPhotoPasserelle) {
                    bienPhotoPasserelle._esk.defaultRouteParams!.bienId = locationId;
                });
            }

            /**
             * Update statut
             *
             * @param locationPasserelle
             */
            function updateStatut(locationPasserelle: NgLocationPasserelle) {
                if (locationPasserelle.isDiffused()) {
                    locationPasserelle._esk.statut = 'diffused';
                } else if (locationPasserelle.isSuspended()) {
                    locationPasserelle._esk.statut = 'suspended';
                } else {
                    locationPasserelle._esk.statut = 'none';
                }
            }

            // @ts-ignore
            return new LocationPasserelleManager();
        }
    })(angularJS);
}
