import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';

export default function getDemandeursModalGroupArchiveCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('DemandeursModalGroupArchiveCtrl', Controller);

        /**
         * Controller of modal for archive Demandeur
         *
         * @param $uibModalInstance
         * @param DictionariesManager
         * @param SoqSweetAlert
         * @param $translate
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['$uibModalInstance', 'DictionariesManager', 'SoqSweetAlert', '$translate', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            dictionariesManager: NgDictionariesManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.saveDemandeursArchiveInfos = saveDemandeursArchiveInfos;
            $ctrl.typesArchiveDemandeurVente = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_TYPES));
            $ctrl.typesArchiveDemandeurVente[1].label = 'Ventes non trouvées par l\'étude';
            $ctrl.typesArchiveDemandeurVente[2].label = 'Ne recherchent plus de biens';
            $ctrl.archive = {type: $ctrl.typesArchiveDemandeurVente[2]};

            /**
             * Archive demandeur
             */
            function saveDemandeursArchiveInfos() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                } else {
                    $uibModalInstance.close($ctrl.archive);
                }
            }
        }
    })(angularJS);
}
