import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ApiInterceptor} from '@core/api/api.interceptor';

@NgModule({
    imports: [],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    ]
})
export class ApiModule {
}
