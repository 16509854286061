@if (collection$ | appLoadObs| async; as collection) {
  @if (collection.value && idxRef > -1) {
    <div class="tw-h-full tw-flex tw-items-center tw-gap-3">
      @if (collection.value.total > 0) {
        <div>{{ idxRef + 1 }} / {{ collection.value.total }}</div>
      }

      <button type="button" class="tw-btn-secondary-info" (click)="previous(collection.value)">
        <fa-icon [icon]="['fal', 'arrow-left']"/>
      </button>

      <button type="button" class="tw-btn-secondary-info" (click)="next(collection.value)">
        <fa-icon [icon]="['fal', 'arrow-right']"/>
      </button>

      <button type="button" class="tw-btn-secondary-info" (click)="goBack.emit()">
        <fa-icon [icon]="['fal', 'reply-all']"/>
      </button>
    </div>
  }
} @else {
  <div class="tw-h-full tw-flex tw-items-center">
    <app-loader [classHeight]="'tw-h-8'" class="tw-block tw-w-32"/>
  </div>
}
