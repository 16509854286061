import {Component, inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {IDCVentePasserellesData} from '@features/dynamic-components/dc.interfaces';
import Vente from '@models/ventes/vente/vente.model';
import {
    CVentePasserellesFactory
} from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.factory';
import CVentePasserelles from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.model';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import {EtudePasserellesService} from '@models/etudes/etude/passerelles/etude-passerelles.service';

@Component({selector: 'dc-vente-passerelles', templateUrl: 'dc-vente-passerelles.component.html'})
export class DCVentePasserellesComponent implements OnInit {
    private _cVentePasserellesFactory = inject(CVentePasserellesFactory);
    private _etudePasserellesService = inject(EtudePasserellesService);
    private _ventePasserelleFactory = inject(VentePasserelleFactory);
    private _cVentePasserelles$!: Observable<CVentePasserelles>;
    private _etudePasserelles$!: Observable<EtudePasserelle[]>;
    private _vente!: Vente;

    get etudePasserelles$(): Observable<EtudePasserelle[]> {
        return this._etudePasserelles$;
    }

    get cVentePasserelles$(): Observable<CVentePasserelles> {
        return this._cVentePasserelles$;
    }

    set data(data: IDCVentePasserellesData) {
        this._vente = data.vente;
    }

    get vente(): Vente {
        return this._vente;
    }

    ngOnInit(): void {
        this._cVentePasserelles$ = this._cVentePasserellesFactory.get$(this.vente);
        this._etudePasserelles$ = this._etudePasserellesService.getFromVente$(this.vente);
    }

    diffuse(ventePasserelle: VentePasserelle, etudePasserelle: EtudePasserelle): void {
        this._ventePasserelleFactory.diffuser$(ventePasserelle).pipe(take(1)).subscribe(_ => etudePasserelle.nbVentesActivesEnvoyees++);
    }

    retire(ventePasserelle: VentePasserelle, etudePasserelle: EtudePasserelle): void {
        this._ventePasserelleFactory.retirer$(ventePasserelle).pipe(take(1)).subscribe(_ => etudePasserelle.nbVentesActivesEnvoyees--);
    }
}
