<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">
    @if (dossierBien.contactsGroup.hasNom()) {
      <span class="tw-text-gray-700">{{dossierBien.contactsGroup.nom}}</span>
      <span class="tw-font-normal"> ({{dossierBien.contactsGroup.typeGroup.label}})</span>
    } @else {
      Propriétaire{{dossierBien.contactsGroup.members.length > 1 ? 's' : ''}}
    }
  </h4>

  <div class="tw-bg-red-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    @if (dossierBien.contactsGroup.members.length > 0) {
      <ul class="tw-grid tw-grid-cols-1 tw-gap-4 tw-text-gray-700"
        [ngClass]="dossierBien.contactsGroup.members.length > 1 ? 'sm:tw-grid-cols-2' : ''">
        @for (member of dossierBien.contactsGroup.members; track member) {
          <li class="tw-flex tw-items-center tw-p-2 tw-bg-gray-50 tw-rounded-md">
            <app-contact-card [contact]="member.contact" class="tw-overflow-hidden"/>
          </li>
        }
      </ul>
    }
  </div>
</div>
