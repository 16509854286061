export default class DemandeurRechercheResultat {
    static interests = {
        INTERESTED: 'interested',
        INTEREST_NOT_GIVEN: 'interest_not_given',
        DISINTERESTED: 'disinterested',
        DISINTERESTED_EXCEPT_PRICE: 'disinterested_except_price',
    };
    static reasons = {
        ARCHIVED: 'archived',
        COMPROMIS: 'compromis',
        FIRST_MATCH: 'first_match',
        PRICE: 'price',
        RERUN: 'rerun',
        SUSPENDED: 'suspended',
    };
    static statuts = {
        MATCH: 'match',
        MATCH_REMOVED: 'match_removed',
        MATCH_UPDATED: 'match_updated',
    };
    private readonly _id: number;
    private _interest = DemandeurRechercheResultat.interests.INTEREST_NOT_GIVEN;
    private _linkDemandeur!: string;
    private _linkDemandeurRecherche!: string;
    private _linkLocation!: string;
    private _linkVente!: string;
    private _reason!: string;
    private _status!: string;
    private _uuid: string;

    constructor(uuid: string, id: number) {
        this._id = id;
        this._uuid = uuid;
    }

    get id(): number {
        return this._id;
    }

    get interest(): string {
        return this._interest;
    }

    set interest(value: string) {
        if (!Object.values(DemandeurRechercheResultat.interests).includes(value)) {
            return;
        }

        this._interest = value;
    }

    get linkDemandeur(): string {
        return this._linkDemandeur;
    }

    set linkDemandeur(value: string) {
        this._linkDemandeur = value;
    }

    get linkDemandeurRecherche(): string {
        return this._linkDemandeurRecherche;
    }

    set linkDemandeurRecherche(value: string) {
        this._linkDemandeurRecherche = value;
    }

    get linkLocation(): string {
        return this._linkLocation;
    }

    set linkLocation(value: string) {
        this._linkLocation = value;
    }

    get linkVente(): string {
        return this._linkVente;
    }

    set linkVente(value: string) {
        this._linkVente = value;
    }

    get reason(): string {
        return this._reason;
    }

    set reason(value: string) {
        if (!Object.values(DemandeurRechercheResultat.reasons).includes(value)) {
            return;
        }

        this._reason = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        if (!Object.values(DemandeurRechercheResultat.statuts).includes(value)) {
            return;
        }

        this._status = value;
    }

    get uuid(): string {
        return this._uuid;
    }

    hasLocation(): boolean {
        return !!this.linkLocation && !this.linkVente;
    }

    hasVente(): boolean {
        return !this.linkLocation && !!this.linkVente;
    }
}
