import {inject, Injectable} from '@angular/core';
import CEtudeNetworks from '@models/etudes/etude/networks/collection/etude-networks.collection.model';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CEtudeNetworksFactory} from '@models/etudes/etude/networks/collection/etude-networks.collection.factory';
import {EtudeNetworkFactory} from '@models/etudes/etude/networks/network/etude-network.factory';
import EtudeNetwork from '@models/etudes/etude/networks/network/etude-network.model';

@Injectable({providedIn: 'root'})
export class CEtudeNetworksService {
    private _cEtudeNetworksFactory = inject(CEtudeNetworksFactory);
    private _etudeNetworkFactory = inject(EtudeNetworkFactory);

    get$(): Observable<CEtudeNetworks> {
        return this._cEtudeNetworksFactory.get$().pipe(
            switchMap(cEtudeNetworks => {
                let etudeNetworks$ = of([]) as Observable<EtudeNetwork[]>;

                if (cEtudeNetworks.total > 0) {
                    etudeNetworks$ = combineLatest(cEtudeNetworks.results.map(etudeNetwork => this._etudeNetworkFactory.get$(etudeNetwork.id.toString())))
                        .pipe(tap(etudeNetworks => cEtudeNetworks.results = etudeNetworks));
                }

                return etudeNetworks$.pipe(map(_ => cEtudeNetworks));
            }),
        );
    }
}
