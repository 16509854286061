import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';
import {
    BienPerformanceEnergetiqueService
} from '@models/bien/performance-energetique/bien-performance-energetique.service';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewPerformanceEnergetiqueService {
    private _bienPerformanceEnergetiqueService = inject(BienPerformanceEnergetiqueService);
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isPartVisible!: boolean;
    private _isPerformanceEnergetiqueVisible!: boolean;

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isPerformanceEnergetiqueVisible(): boolean {
        return this._isPerformanceEnergetiqueVisible;
    }

    init(dossierBien: ADossierBien): void {
        const isDiagV1Visible = this._bienPerformanceEnergetiqueService.isDiagV1(dossierBien.bien) &&
            (dossierBien.bien.performanceEnergetique.consommation > 0 || dossierBien.bien.performanceEnergetique.emissions > 0);
        const isDiagV2Visible = this._bienPerformanceEnergetiqueService.isDiagV2(dossierBien.bien);

        this._isPerformanceEnergetiqueVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.performanceEnergetique) &&
            (isDiagV1Visible || isDiagV2Visible);

        this._isPartVisible = this.isPerformanceEnergetiqueVisible;
    }
}
