import AModel from '@models/model.abstract';
import ImageService from '@shared/image/image.service';

export default class Media extends AModel {
    // @todo Passer dans shared ?
    static readonly ACCEPTED_MIME_TYPES = {
        applicationCdfv2Unknown: {convivial: 'MSG', mime_type: 'application/CDFV2-unknown'},
        applicationMsoutlook: {convivial: 'PST', mime_type: 'application/msoutlook'},
        applicationMsword: {convivial: 'DOC', mime_type: 'application/msword'},
        applicationPdf: {convivial: 'PDF', mime_type: 'application/pdf'},
        applicationVndMsExcel: {convivial: 'XLS', mime_type: 'application/vnd.ms-excel'},
        applicationVndMsOutlook: {convivial: 'PST', mime_type: 'application/vnd.ms-outlook'},
        applicationVndMsPowerpoint: {convivial: 'PPT', mime_type: 'application/vnd.ms-powerpoint'},
        applicationVndOasisOpendocumentGraphics: {
            convivial: 'ODG',
            mime_type: 'application/vnd.oasis.opendocument.graphics',
        },
        applicationVndOasisOpendocumentPresentation: {
            convivial: 'ODP',
            mime_type: 'application/vnd.oasis.opendocument.presentation',
        },
        applicationVndOasisOpendocumentSpreadsheet: {
            convivial: 'ODS',
            mime_type: 'application/vnd.oasis.opendocument.spreadsheet',
        },
        applicationVndOasisOpendocumentText: {convivial: 'ODT', mime_type: 'application/vnd.oasis.opendocument.text'},
        applicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation: {
            convivial: 'PPTX',
            mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        },
        applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet: {
            convivial: 'XLSX',
            mime_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        applicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument: {
            convivial: 'DOCX',
            mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
        applicationXMsg: {convivial: 'MSG', mime_type: 'application/x-msg'},
        applicationXZipCompressed: {convivial: 'ZIP', mime_type: 'application/x-zip-compressed'},
        applicationZip: {convivial: 'ZIP', mime_type: 'application/zip'},
        imageBmp: {convivial: 'image BMP', mime_type: 'image/bmp'},
        imageGif: {convivial: 'image GIF', mime_type: 'image/gif'},
        imageJpeg: {convivial: 'image JPEG', mime_type: ImageService.acceptedMimeTypes.JPEG},
        imageJpg: {convivial: 'image JPEG', mime_type: ImageService.acceptedMimeTypes.JPG},
        imagePng: {convivial: 'image PNG', mime_type: ImageService.acceptedMimeTypes.PNG},
        imageTiff: {convivial: 'image TIFF', mime_type: 'image/tiff'},
        imageXBmp: {convivial: 'image BMP', mime_type: 'image/x-bmp'},
        imageXMsBmp: {convivial: 'image ICS', mime_type: 'image/x-ms-bmp'},
        messageRfc822: {convivial: 'EML', mime_type: 'message/rfc822'},
        textCsv: {convivial: 'CSV', mime_type: 'text/csv'},
        textHtml: {convivial: 'HTML', mime_type: 'text/html'},
        textPlain: {convivial: 'TXT', mime_type: 'text/plain'},
        textRtf: {convivial: 'RTF', mime_type: 'text/rtf'},
        videoMp4: {convivial: 'video MP4', mime_type: 'video/mp4'},
        videoMpeg: {convivial: 'video MPEG', mime_type: 'video/mpeg'},
        videoQuicktime: {convivial: 'video MOV', mime_type: 'video/quicktime'},
        videoXFlv: {convivial: 'video FLV', mime_type: 'video/x-flv'},
        videoXMsVideo: {convivial: 'video AVI', mime_type: 'video/x-msvideo'},
        videoXMsWmv: {convivial: 'video WMV', mime_type: 'video/x-ms-wmv'},
        videoWebm: {convivial: 'video WEBM', mime_type: 'video/webm'},
    };
    // @todo Utiliser WindowService.NO_TITLE ?
    static readonly NO_TITLE = 'Sans titre';
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private _comments!: string;
    private _createdAt!: string;
    private readonly _id: number;
    private _linkDownload!: string;
    private _mediaType!: string;
    private _title = Media.NO_TITLE;
    private _totalBytes!: number;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get id(): number {
        return this._id;
    }

    get linkDownload(): string {
        return this._linkDownload;
    }

    set linkDownload(value: string) {
        this._linkDownload = value;
    }

    get mediaType(): string {
        return this._mediaType;
    }

    set mediaType(value: string) {
        this._mediaType = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get totalBytes(): number {
        return this._totalBytes;
    }

    set totalBytes(value: number) {
        this._totalBytes = value;
    }
}
