import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgUtilsManager} from '@legacy/app/managers/managers';
import {NgEntreprise} from '@legacy/app/managers/ressources';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';

export default function getManagersEntreprise(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EntrepriseManager', manager);

        /**
         * Manager entreprise
         *
         * @params UtilsManager
         * @params Ng2PhoneNumberService
         * @returns {EntrepriseManager}
         */
        manager.$inject = ['UtilsManager', 'Ng2PhoneNumberService'];
        function manager(utilsManager: NgUtilsManager, ng2PhoneNumberService: PhoneNumberService) {
            /**
             * Entreprise object
             *
             * @constructor
             */
            EntrepriseManager.prototype.eskManager = {name: 'EntrepriseManager'};
            function EntrepriseManager() {
            }

            /**
             * Create a new Entreprise instance
             *
             * @param data
             * @returns {manager.Entreprise}
             */
            EntrepriseManager.prototype.create = function (data: unknown) {
                // @ts-ignore
                return new Entreprise(data);
            };

            /**
             * Entreprise object
             *
             * @param data
             * @constructor
             */
            Entreprise.prototype.eskManager = {name: 'Entreprise'};
            function Entreprise(this: NgEntreprise, data: unknown) {
                this.extend(data);
                this.updateConvivialName();
            }

            /**
             * Extend the existing Entreprise with new data
             *
             * @param data
             * @returns {Entreprise}
             */
            Entreprise.prototype.extend = function (data: unknown) {
                utilsManager.extendDataToResource(this, data);

                // Numéros de téléphone
                this.mobile = ng2PhoneNumberService.getUsable(this.mobile);
                this.telephone = ng2PhoneNumberService.getUsable(this.telephone);

                return this;
            };

            /**
             * Sanitize object Entreprise before send to API
             *
             * @returns sanitizeObject
             */
            Entreprise.prototype.sanitize = function () {
                var sanitizeObject = angular.merge({}, this);

                // Attribut ajouté par l'application
                delete sanitizeObject.convivialName;
                delete sanitizeObject._esk;
                delete sanitizeObject.colorVariation;

                // Numéros de téléphone
                sanitizeObject.mobile = ng2PhoneNumberService.getE164(this.mobile);
                sanitizeObject.telephone = ng2PhoneNumberService.getE164(this.telephone);

                // objet => objetId
                utilsManager.flattenIdForAttribute(sanitizeObject, 'commune');

                if (!sanitizeObject.communeId) {
                    delete sanitizeObject.adresse;
                    delete sanitizeObject.codePostal;
                }

                return sanitizeObject;
            };

            /**
             * Update convivial name
             */
            Entreprise.prototype.updateConvivialName = function () {
                this.convivialName = this.raisonSociale;
            };

            /**
             * Getter and setter for the RAISON SOCIALE property
             *
             * @param raisonSociale
             * @returns {*}
             */
            Entreprise.prototype.getRaisonSociale = function (raisonSociale: string) {
                if (arguments.length) {
                    this.raisonSociale = raisonSociale;
                    this.updateConvivialName();

                    return this;
                }

                return this.raisonSociale;
            };

            /**
             * Entreprise phone is valid
             *
             * @returns {boolean}
             */
            Entreprise.prototype.isPhoneValid = function (phone: string) {
                return !phone || ng2PhoneNumberService.isValid(phone);
            };

            /**
             * Entreprise is valid
             *
             * @returns {boolean}
             */
            Entreprise.prototype.isValid = function () {
                return this.isPhoneValid(this.mobile) && this.isPhoneValid(this.telephone);
            };

            /**
             * Get Rfc3966
             *
             * @returns {string}
             */
            Entreprise.prototype.getRfc3966 = function (phone: string) {
                return ng2PhoneNumberService.getRfc3966(phone);
            };

            // @ts-ignore
            return new EntrepriseManager();
        }
    })(angularJS);
}
