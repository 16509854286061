import AModel from '@models/model.abstract';
import TemplateCategory from '@models/templates/template/category/template-category.model';
import {ITemplateStylesheet} from '@models/templates/template/template.interfaces';
import Etude from '@models/etudes/etude/etude.model';

export default class Template extends AModel {
    static readonly convertMmToPx = 3.779; // 96 Pixels/Pouces / 25.4 mm
    private _category!: TemplateCategory;
    private readonly _id!: number;
    private _name!: string;
    private _orientation!: number;
    private _signatureElectronique!: boolean;
    private _size!: string;
    private _stylesheets: ITemplateStylesheet[] = [];
    private _tags!: string[];
    private _thumbnailUrl!: string;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get category(): TemplateCategory {
        return this._category;
    }

    set category(value: TemplateCategory) {
        this._category = value;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get orientation(): number {
        return this._orientation;
    }

    set orientation(value: number) {
        this._orientation = value;
    }

    get signatureElectronique(): boolean {
        return this._signatureElectronique;
    }

    set signatureElectronique(value: boolean) {
        this._signatureElectronique = value;
    }

    get size(): string {
        return this._size;
    }

    set size(value: string) {
        this._size = value;
    }

    get stylesheets(): ITemplateStylesheet[] {
        return this._stylesheets;
    }

    set stylesheets(value: ITemplateStylesheet[]) {
        this._stylesheets = value;
    }

    get tags(): string[] {
        return this._tags;
    }

    set tags(value: string[]) {
        this._tags = value;
    }

    get thumbnailUrl(): string {
        return this._thumbnailUrl;
    }

    set thumbnailUrl(value: string) {
        this._thumbnailUrl = value;
    }

    needAd(etude: Etude): boolean {
        if (this.category.isEstimationAvisValeur()) {
            return true;
        }

        if (this.category.isEstimationEvaluation() || this.category.isEstimationEvaluationCityscan()) {
            return !etude.settingsCityscan.enabled;
        }

        return false;
    }
}
