import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgContactManager, NgUserManager} from '@legacy/app/managers/managers';
import {NgContact, NgUser} from '@legacy/app/managers/ressources';
import {ConditionConst} from '@shared/constants';

export default function getEskSelectParticipant(module: IModule): void {
    (angular => {
        'use strict';

        /**
         * Big component to pick a person from the users or the contacts.
         * @example <esk-select-participant></esk-select-participant>
         * - onSelect : called uppon picking a choice from the dropdown list
         * - ngModel : empty object
         * - disabled : function to test if the list choice can be picked or not
         * - filtered : function to test if the list choice can be displayed or not
         * - options : an object which features the 'placeholder' and the 'otherwise' boolean
         *      - fullObject: boolean
         *      - otherwise : allows the user input to be displayed in the list choice, even if the search doesn't fulfil
         *      - placeholder : text displayed as a placeholder
         *      - withUsers: boolean
         */
        module.component('eskSelectParticipant', {
            bindings: {
                onSelect: '=',
                ngModel: '=?',
                disabled: '=',
                filtered: '=?',
                options: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/participant.html'
        });

        /**
         * Select participants
         *
         * @param $q
         * @param ContactManager
         * @param UserManager
         * @constructor
         */
        Controller.$inject = ['$q', 'ContactManager', 'UserManager'];
        function Controller(this: any, $q: IQService, contactManager: NgContactManager, userManager: NgUserManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.getResearchedParticipants = getResearchedParticipants;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.options = {...{fullObject: false, withUsers: true}, ...$ctrl.options};
                $ctrl.participants = [];
                if (!$ctrl.ngModel) {
                    $ctrl.ngModel = null;
                }
            }

            /**
             * Search for participants
             *
             * @param keywords
             * @returns {Promise}
             */
            function getResearchedParticipants(keywords: string) {
                const promises = [] as IPromise<unknown>[];
                let participants: NgContact[] = [];

                $ctrl.participants = [];
                promises.push(contactManager.getAllForEtude({
                    keywords,
                    type: {condition: ConditionConst.IN, values: ['person']}
                }).then(contacts => {
                    participants = participants.concat(contacts.collection);
                }));
                if ($ctrl.options.withUsers) {
                    promises.push(userManager.getAll({keywords}).then(users => {
                        const usersParticipants: NgContact[] = [];

                        users.collection.forEach((user: NgUser) => {
                            usersParticipants.push({
                                ...user,
                                ...{person: {convivialName: user.convivialName, emailAddress: user.emailAddress}}
                            } as unknown as NgContact);
                        });
                        participants = participants.concat(usersParticipants);
                    }));
                }

                return $q.all(promises).then(() => {
                    if (angular.isFunction($ctrl.filtered)) {
                        $ctrl.participants = $ctrl.filtered(participants);
                    } else {
                        $ctrl.participants = participants;
                    }

                    if ($ctrl.participants.length === 0 && $ctrl.options.otherwise) {
                        $ctrl.participants.push({person: {convivialName: keywords, emailAddress: keywords}});
                    }

                    return $ctrl.participants;
                });
            }
        }
    })(angularJS);
}
