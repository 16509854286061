import {Injectable} from '@angular/core';
import DossierExportQualifiedContact
    from '@models/dossiers/dossier/exports/export/contacts/contact/dossier-export.qualified-contact.model';
import {
    IDossierExportQualifiedContactApi
} from '@models/dossiers/dossier/exports/export/contacts/contact/dossier-export.qualified-contact.interfaces';

@Injectable({providedIn: 'root'})
export class DossierExportQualifiedContactFactory {
    create(dossierExportQualifiedContactApi: IDossierExportQualifiedContactApi): DossierExportQualifiedContact {
        const dossierExportQualifiedContact = new DossierExportQualifiedContact();

        dossierExportQualifiedContact.qualification = dossierExportQualifiedContactApi.qualification;
        if (dossierExportQualifiedContactApi._links?.contact) {
            dossierExportQualifiedContact.linkContact = dossierExportQualifiedContactApi._links.contact.href;
        }

        return dossierExportQualifiedContact;
    }
}
