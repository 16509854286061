import {Component, inject, Input} from '@angular/core';
import Estimation from '@models/estimations/estimation/estimation.model';
import {Router} from '@angular/router';

@Component({
    selector: 'app-estimation-rapport-missing-data',
    templateUrl: 'estimation-rapport.missing-data.component.html',
})
export class EstimationRapportMissingDataComponent {
    private _router = inject(Router);
    private _estimation!: Estimation;

    get estimation(): Estimation {
        return this._estimation;
    }

    @Input()
    set estimation(value: Estimation) {
        this._estimation = value;
    }

    redirectToFiche(): void {
        this._router.navigateByUrl('/app/estimations/' + this.estimation.id.toString() + '/edit/general');
    }
}
