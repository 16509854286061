import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgAuthenticationService} from '@legacy/app/auth/auth';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';

export default function getAuthLogoutCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskAuthLogoutCtrl', {controller: Controller});

        /**
         * Controller for logout
         *
         * @param AuthenticationService
         * @param Ng2EtudeService
         * @param Ng2UserService
         */
        Controller.$inject = ['AuthenticationService', 'Ng2EtudeService', 'Ng2UserService'];
        function Controller(authenticationService: NgAuthenticationService,
                            ng2EtudeService: EtudeService,
                            ng2UserService: UserService) {
            authenticationService.logout();
            ng2EtudeService.logout();
            ng2UserService.logout();
        }
    })(angularJS);
}
