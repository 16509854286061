export default `
<div>
  Les critères suivants sont saisis automatiquement depuis les données du bien en estimation :

  <ul>
    <li>Commune</li>

    <li>Nature</li>

    <li>80% de la surface (habitable / utile) pour le minimum</li>

    <li>80% de la surface cadastrale pour le minimum</li>
  </ul>
</div>
`;
