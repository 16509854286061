import {Injectable} from '@angular/core';
import {
    IDossierExportMediaApi
} from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.interfaces';
import DossierExportMedia
    from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.model';

@Injectable({providedIn: 'root'})
export class DossierExportMediaFactory {
    create(dossierExportMediaApi: IDossierExportMediaApi): DossierExportMedia {
        const dossierExportMedia = new DossierExportMedia();

        if (dossierExportMediaApi._links?.dossierMedia) {
            dossierExportMedia.linkDossierMedia = dossierExportMediaApi._links.dossierMedia.href;
        }

        return dossierExportMedia;
    }
}
