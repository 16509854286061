import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgUserManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgLocationLocataireEtatsLieuxCollection} from '@legacy/app/managers/collections';
import {NgLocationLocataireEtatLieux} from '@legacy/app/managers/ressources';
import DateFormat from '@shared/date/date.format';

export default function getManagersLocationLocataireEtatLieux(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('LocationLocataireEtatLieuxManager', manager);

        /**
         * Manager locationLocataireEtatLieux
         *
         * @param BaseManager
         * @param UserManager
         * @param UtilsManager
         * @returns {LocationLocataireEtatLieuxManager}
         */
        manager.$inject = ['BaseManager', 'UserManager', 'UtilsManager'];
        function manager(baseManager: NgManager, userManager: NgUserManager, utilsManager: NgUtilsManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('location-locataire-etats-lieux.cget', {
                path: '/locations/{locationId}/locataires/{locataireId}/etats-lieux',
                method: 'GET',
            });
            baseManager.addRoute('location-locataire-etats-lieux.insert', {
                path: '/locations/{locationId}/locataires/{locataireId}/etats-lieux',
                method: 'POST',
            });
            baseManager.addRoute('location-locataire-etats-lieux.edit', {
                path: '/locations/{locationId}/locataires/{locataireId}/etats-lieux/{id}',
                method: 'PUT',
            });

            /**
             * LocationLocataireEtatLieuxManager object
             *
             * @constructor
             */
            angular.extend(LocationLocataireEtatLieuxManager.prototype, baseManager.__proto__);
            LocationLocataireEtatLieuxManager.prototype.eskManager = {
                prefixRoute: 'location-locataire-etats-lieux',
                collectionName: 'items',
            };

            function LocationLocataireEtatLieuxManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param locationId
             * @param locataireId
             * @returns {Promise}
             */
            LocationLocataireEtatLieuxManager.prototype.getAll = function (locationId: number, locataireId: number) {
                return baseManager.getAll.call(this, {
                    locationId: locationId,
                    locataireId: locataireId,
                }).then(function (etatsLieux) {
                    const etatsLieuxCollection: NgLocationLocataireEtatsLieuxCollection = etatsLieux as NgLocationLocataireEtatsLieuxCollection;

                    angular.forEach(etatsLieuxCollection.collection, function (etatLieux) {
                        etatLieux._esk.defaultRouteParams!.locataireId = locataireId;
                        etatLieux._esk.defaultRouteParams!.locationId = locationId;
                    });

                    return etatsLieuxCollection;
                });
            };

            /**
             * Create a LocationLocataireEtatLieux object
             *
             * @param data
             * @param locationId
             * @param locataireId
             * @returns manager.LocationLocataireEtatLieux object || Array of manager.LocationLocataireEtatLieux objects
             */
            LocationLocataireEtatLieuxManager.prototype.create = function (data: unknown, locationId: number, locataireId: number) {
                if (angular.isArray(data)) {
                    var etatsLieux = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        etatsLieux.push(new LocationLocataireEtatLieux(locationId, locataireId, data[i]));
                    }

                    return etatsLieux;
                }

                // @ts-ignore
                return new LocationLocataireEtatLieux(locationId, locataireId, data);
            };

            /**
             * LocationLocataireEtatLieux object
             *
             * @param locationId
             * @param data
             * @constructor
             */
            angular.extend(LocationLocataireEtatLieux.prototype, classResource.prototype);
            LocationLocataireEtatLieux.prototype.eskManager = {prefixRoute: 'location-locataire-etats-lieux'};

            function LocationLocataireEtatLieux(this: NgLocationLocataireEtatLieux, locationId: number, locataireId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, locationId, locataireId};
            }

            /**
             * Extend the existing LocationLocataireEtatLieux with new data
             *
             * @param data
             * @returns {LocationLocataireEtatLieux}
             */
            LocationLocataireEtatLieux.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isObject(this.maker)) {
                    this.maker = userManager.create(this.maker);
                }

                if (angular.isString(this.date)) {
                    this.date = DateFormat.toDate(this.date);
                }

                return this;
            };

            /**
             * Sanitize object LocationLocataireEtatLieux before send to API
             *
             * @returns sanitizeObject
             */
            LocationLocataireEtatLieux.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Objets simples => id
                utilsManager.flattenIdForAttribute(sanitizeObject, 'maker');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'media');
                sanitizeObject.date = DateFormat.toAPI(this.date);

                if (!sanitizeObject.media) {
                    delete sanitizeObject.media;
                }

                return sanitizeObject;
            };

            // @ts-ignore
            return new LocationLocataireEtatLieuxManager();
        }
    })(angularJS);
}
