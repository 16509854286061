import {inject, NgModule} from '@angular/core';
import {NgVenteCorrespondantsComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-correspondants.component';
import {NgVenteDiffusionComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-diffusion.component';
import {NgVenteLifetimeComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-lifetime.component';
import {NgVenteMandatsComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-mandats.component';
import {NgVentesNegocieesNaturesComponent} from '@shared/angularJS/up-ng/ventes/ventes-negociees-natures.component';
import {NgVentesNegocieesEtudesComponent} from '@shared/angularJS/up-ng/ventes/ventes-negociees-etudes.component';
import {
    NgVentesNetworkUpdatedCriteriaComponent
} from '@shared/angularJS/up-ng/ventes/ventes-network-updated-criteria.component';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';
import {FeaturesModule} from '@features/features.module';
import {AppLayoutVentesComponent} from '@core/routing/layout/ventes/layout-ventes.component';
import {NgBiensSearchComponent} from '@shared/angularJS/up-ng/biens/biens.search.component';
import {AppLayoutVentesWalletComponent} from '@core/routing/layout/ventes/wallet/layout-ventes-wallet.component';
import {
    AppLayoutVentesNegocieesComponent
} from '@core/routing/layout/ventes/negociees/layout-ventes-negociees.component';
import {AppLayoutVenteComponent} from '@core/routing/layout/ventes/vente/layout-vente.component';
import {AppLayoutDossierSuiviComponent} from '@core/routing/layout/suivi/layout-dossier-suivi.component';
import {
    NgEskimmoDossierSuiviEmailComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-email.component';
import {
    NgEskimmoDossierSuiviReportComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-report.component';
import {BonvisitesActionsMainComponent} from '@features/bonvisites/list/actions/bonvisites.actions-main.component';
import {CanDeactivateGuard} from '@core/routing/guards/can-deactivate/can-deactivate-guard.service';
import {AppLayoutVenteEditComponent} from '@core/routing/layout/ventes/vente/edit/layout-vente-edit.component';
import {
    AppLayoutVenteEditGeneralComponent
} from '@core/routing/layout/ventes/vente/edit/general/layout-vente-edit-general.component';
import {
    AppLayoutBienEditConstructionComponent
} from '@core/routing/layout/biens/bien/edit/construction/layout-bien-edit-construction.component';
import {
    AppLayoutBienEditFormalitesComponent
} from '@core/routing/layout/biens/bien/edit/formalites/layout-bien-edit-formalites.component';
import {OffreachatsActionsMainComponent} from '@features/offreachats/list/actions/offreachats.actions-main.component';
import {
    AppLayoutDossierFilesComponent
} from '@core/routing/layout/dossiers/dossier/files/layout-dossier-files.component';
import {
    DossierFilesActionsSelectionComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-selection.component';
import {
    DossierFilesActionsMainComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-main.component';
import {IsEnablableModulesGuard} from '@core/routing/guards/is-enablable-modules/is-enablable-modules-guard.service';
import Etude from '@models/etudes/etude/etude.model';
import {
    AppLayoutDossierInotExportComponent
} from '@core/routing/layout/dossiers/dossier/inot/export/layout-dossier-inot-export.component';
import {
    DossierInotExportActionsMainComponent
} from '@features/dossiers/dossier/inot/exports/export/actions/dossier-inot-export.actions-main.component';
import {
    AppLayoutVentesPublicitesComponent
} from '@core/routing/layout/ventes/publicites/layout-ventes.publicites.component';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import User from '@models/users/user/user.model';
import {AppLayoutBonvisitesComponent} from '@core/routing/layout/bonvisites/layout-bonvisites.component';
import {AppLayoutOffreachatsComponent} from '@core/routing/layout/offreachats/layout-offreachats.component';
import {AppLayoutBonvisiteComponent} from '@core/routing/layout/bonvisites/bonvisite/layout-bonvisite.component';
import {AppLayoutOffreachatComponent} from '@core/routing/layout/offreachats/offreachat/layout-offreachat.component';
import {
    AppLayoutBonvisitesWalletComponent
} from '@core/routing/layout/bonvisites/wallet/layout-bonvisites-wallet.component';
import {
    AppLayoutOffreachatsWalletComponent
} from '@core/routing/layout/offreachats/wallet/layout-offreachats-wallet.component';
import {
    AppLayoutBonvisiteEditComponent
} from '@core/routing/layout/bonvisites/bonvisite/edit/layout.bonvisite-edit.component';
import {
    AppLayoutBonvisiteWriteComponent
} from '@core/routing/layout/bonvisites/bonvisite/write/layout.bonvisite-write.component';
import {
    AppLayoutBonvisiteResumeComponent
} from '@core/routing/layout/bonvisites/bonvisite/resume/layout.bonvisite-resume.component';
import {
    AppLayoutBonvisiteSignComponent
} from '@core/routing/layout/bonvisites/bonvisite/sign/layout.bonvisite-sign.component';
import {
    AppLayoutOffreachatEditComponent
} from '@core/routing/layout/offreachats/offreachat/edit/layout.offreachat-edit.component';
import {
    AppLayoutOffreachatWriteComponent
} from '@core/routing/layout/offreachats/offreachat/write/layout.offreachat-write.component';
import {
    AppLayoutOffreachatResumeComponent
} from '@core/routing/layout/offreachats/offreachat/resume/layout.offreachat-resume.component';
import {
    AppLayoutOffreachatSignComponent
} from '@core/routing/layout/offreachats/offreachat/sign/layout.offreachat-sign.component';
import {GuardsService} from '@core/routing/guards/guards.service';
import {CanDeactivateComponent} from '@core/routing/guards/guards.interfaces';
import {NgBiensLastCriteriaComponent} from '@shared/angularJS/up-ng/biens/biens-last-criteria.component';
import {NgBienDetailsComponent} from '@shared/angularJS/up-ng/biens/biens-item-details.component';
import {
    NgBiensWalletSearchCriteriaComponent
} from '@shared/angularJS/up-ng/biens/biens-wallet-search-criteria.component';
import {NgBiensWalletDiffusionComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-diffusion.component';
import {NgBiensWalletCurrentComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-current.component';
import {NgBienEditPhotosComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-photos.component';
import {NgBienEditVirtualVisitComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-virtual_visit.component';
import {CommonModule} from '@angular/common';
import {
    AppLayoutVenteOverviewComponent
} from '@core/routing/layout/ventes/vente/overview/layout-vente-overview.component';
import {
    VenteOverviewActionsMainComponent
} from '@features/ventes/vente/actions/overview/vente-overview.actions-main.component';
import {AppLayoutSearchVenteComponent} from '@core/routing/layout/ventes/search/vente/layout-search-vente.component';
import {
    AppLayoutSearchVenteOverviewComponent
} from '@core/routing/layout/ventes/search/vente/overview/layout-search-vente-overview.component';
import {
    VenteSearchOverviewActionsMainComponent
} from '@features/ventes/search/vente/actions/overview/vente-search-overview.actions-main.component';
import {ActualitesService} from '@models/actualites/actualites.service';
import {
    AppLayoutOffreachatSignatureComponent
} from '@core/routing/layout/offreachats/offreachat/signature/layout.offreachat-signature.component';

const routes: Routes = [
    {component: AppLayoutVentesPublicitesComponent, path: 'informations'},
    {
        component: NgBiensLastCriteriaComponent,
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {
            [GuardsService.REDIRECTION_ROUTE_DATA]: '/app/ventes/informations',
            roles: [User.roles.DEMANDEUR_VENTE, User.roles.GRP_VENTE],
        },
        path: 'last/criteres',
    },
    {
        component: NgVentesNetworkUpdatedCriteriaComponent,
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {
            [GuardsService.REDIRECTION_ROUTE_DATA]: '/app/ventes/informations',
            roles: [User.roles.DEMANDEUR_VENTE, User.roles.GRP_VENTE],
        },
        path: 'network-updated/criteres',
    },
    {
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {
            [GuardsService.REDIRECTION_ROUTE_DATA]: '/app/ventes/informations',
            roles: [User.roles.DEMANDEUR_VENTE, User.roles.GRP_VENTE],
        },
        path: 'search',
        children: [
            {component: NgBiensWalletSearchCriteriaComponent, path: 'criteres'},
            {component: NgBienDetailsComponent, path: 'details/:venteId'},
            {
                component: AppLayoutSearchVenteComponent,
                path: ':venteId',
                children: [
                    {
                        component: AppLayoutSearchVenteOverviewComponent,
                        data: {actions: {main: {component: VenteSearchOverviewActionsMainComponent}}},
                        path: 'overview',
                    },
                    // Wildcard pour /app/ventes/search/{id}/*
                    {path: '**', pathMatch: 'full', redirectTo: 'overview'},
                ],
            },
            {component: NgBiensSearchComponent, path: ''},
            // Wildcard pour /app/ventes/search/*
            {path: '**', pathMatch: 'full', redirectTo: ''},
        ],
    },
    {
        component: AppLayoutVentesComponent,
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {[GuardsService.REDIRECTION_ROUTE_DATA]: '/app/ventes/informations', roles: [User.roles.GRP_VENTE]},
        path: '',
        children: [
            {component: NgBienDetailsComponent, path: 'details/:venteId'},
            {
                component: AppLayoutVentesNegocieesComponent,
                path: 'negociees',
                children: [
                    {component: NgVentesNegocieesEtudesComponent, path: 'etude'},
                    {component: NgVentesNegocieesNaturesComponent, path: 'natures'},
                    // Wildcard pour /app/ventes/negociees/*
                    {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                ],
            },
            {
                component: AppLayoutVentesWalletComponent,
                path: 'portefeuille',
                children: [
                    // @todo à supprimer et deplacer dans le module diffusions
                    {component: NgBiensWalletDiffusionComponent, path: 'diffusion'},
                    {
                        component: NgBiensWalletCurrentComponent,
                        path: '',
                        resolve: {news: () => inject(ActualitesService).show$()},
                    },
                    // Wildcard pour /app/ventes/portefeuille/*
                    {path: '**', pathMatch: 'full', redirectTo: ''},
                ],
            },
            {
                component: AppLayoutVenteComponent,
                path: ':venteId',
                children: [
                    {
                        component: AppLayoutBonvisitesComponent,
                        path: 'bons-visite',
                        children: [
                            {
                                component: AppLayoutBonvisitesWalletComponent,
                                data: {actions: {main: {component: BonvisitesActionsMainComponent}}},
                                path: 'portefeuille',
                            },
                            {
                                component: AppLayoutBonvisiteComponent,
                                path: ':bonvisiteUuid',
                                children: [
                                    {component: AppLayoutBonvisiteEditComponent, path: 'edit'},
                                    {component: AppLayoutBonvisiteWriteComponent, path: 'redaction'},
                                    {component: AppLayoutBonvisiteResumeComponent, path: 'resume'},
                                    {component: AppLayoutBonvisiteSignComponent, path: 'signature'},
                                ],
                            },
                            // Wildcard pour /app/ventes/:venteId/bons-visite/*
                            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                        ],
                    },
                    {component: NgVenteCorrespondantsComponent, path: 'correspondants'},
                    {component: NgVenteDiffusionComponent, path: 'diffusion'},
                    {
                        component: AppLayoutDossierFilesComponent,
                        data: {
                            actions: {
                                main: {component: DossierFilesActionsMainComponent},
                                selection: {component: DossierFilesActionsSelectionComponent},
                            },
                        },
                        path: 'documents',
                    },
                    {
                        canDeactivate: [
                            (component: CanDeactivateComponent) => inject(CanDeactivateGuard).canDeactivate(component),
                        ],
                        component: AppLayoutVenteEditComponent,
                        path: 'edit',
                        children: [
                            {component: AppLayoutBienEditConstructionComponent, path: 'construction'},
                            {component: AppLayoutBienEditFormalitesComponent, path: 'formalites'},
                            {component: AppLayoutVenteEditGeneralComponent, path: 'general'},
                            {component: NgBienEditPhotosComponent, path: 'photos'},
                            {component: NgBienEditVirtualVisitComponent, path: 'virtual_visit'},
                            // Wildcard pour /app/ventes/:venteId/edit/*
                            {path: '**', pathMatch: 'full', redirectTo: 'general'},
                        ],
                    },
                    {
                        component: AppLayoutDossierFilesComponent,
                        path: 'ged',
                    },
                    {
                        canActivate: [
                            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(IsEnablableModulesGuard).canActivate(activatedRouteSnapshot),
                        ],
                        component: AppLayoutDossierInotExportComponent,
                        data: {
                            actions: {main: {component: DossierInotExportActionsMainComponent}},
                            enablableModules: [Etude.enablableModules.INOT.code],
                        },
                        path: 'inot/export',
                    },
                    {component: NgVenteLifetimeComponent, path: 'lifetime'},
                    {component: NgVenteMandatsComponent, path: 'mandats'},
                    {
                        component: AppLayoutOffreachatsComponent,
                        path: 'offres-achat',
                        children: [
                            {
                                component: AppLayoutOffreachatsWalletComponent,
                                data: {actions: {main: {component: OffreachatsActionsMainComponent}}},
                                path: 'portefeuille',
                            },
                            {
                                component: AppLayoutOffreachatComponent,
                                path: ':offreachatUuid',
                                children: [
                                    {component: AppLayoutOffreachatEditComponent, path: 'edit'},
                                    {component: AppLayoutOffreachatWriteComponent, path: 'redaction'},
                                    {component: AppLayoutOffreachatResumeComponent, path: 'resume'},
                                    {component: AppLayoutOffreachatSignComponent, path: 'sign'},
                                    {component: AppLayoutOffreachatSignatureComponent, path: 'signature'},
                                ],
                            },
                            // Wildcard pour /app/ventes/:venteId/offres-achat/*
                            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                        ],
                    },
                    {
                        component: AppLayoutVenteOverviewComponent,
                        data: {actions: {main: {component: VenteOverviewActionsMainComponent}}},
                        path: 'overview',
                    },
                    {
                        component: AppLayoutDossierSuiviComponent,
                        path: 'suivi',
                        children: [
                            {component: NgEskimmoDossierSuiviEmailComponent, path: 'emails/:emailId'},
                            {component: NgEskimmoDossierSuiviReportComponent, path: 'reports/:reportId'},
                            // Wildcard pour /app/ventes/:venteId/suivi/*
                            {path: '**', pathMatch: 'full', redirectTo: ''},
                        ],
                    },
                    // Wildcard pour /app/ventes/:venteId/!*
                    /* @todo Utiliser quand le thème Camilla sera en place
                    {path: '**', pathMatch: 'full', redirectTo: 'overview'},
                    */
                    {path: '**', pathMatch: 'full', redirectTo: 'edit/general'},
                ],
            },
            // Wildcard pour /app/ventes/!*
            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
        ],
    },
];

@NgModule({
    declarations: [
        AppLayoutSearchVenteComponent,
        AppLayoutSearchVenteOverviewComponent,
        AppLayoutVenteComponent,
        AppLayoutVenteEditComponent,
        AppLayoutVenteEditGeneralComponent,
        AppLayoutVenteOverviewComponent,
        AppLayoutVentesComponent,
        AppLayoutVentesNegocieesComponent,
        AppLayoutVentesPublicitesComponent,
        AppLayoutVentesWalletComponent,
    ],
    imports: [CommonModule, EskimmoModule, FeaturesModule, RouterModule.forChild(routes), SharedModule],
})
export class VentesRoutingModule {
}
