import {inject, Injectable} from '@angular/core';
import Document from '@models/documents/document/document.model';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {MediaService} from '@models/medias/media/media.service';
import Media from '@models/medias/media/media.model';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Template from '@models/templates/template/template.model';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {IDocumentVariableSort} from '@models/documents/document/document.interfaces';
import {DocumentService} from '@models/documents/document/document.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DocumentsService {
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _mediaService = inject(MediaService);

    getMedia$(documents: Document[], titreMedia = Media.NO_TITLE): Observable<Media> {
        return this._mediaService.getFromDocuments$(documents, titreMedia);
    }

    getMediaFromEachDossierBiens$(dossierBiens: ADossierBien[], template: Template): Observable<Media[]> {
        return combineLatest(dossierBiens.map(dossierBien => this._documentService.saveFromDossierBien$(dossierBien, template).pipe(
            map(documents => documents[0]),
            switchMap(document => this.getMedia$([document], document.titre)),
        )));
    }

    read$(documents: Document[], titreMedia = Media.NO_TITLE): Observable<Window> {
        return this._mediaService.readFromDocuments$(documents, titreMedia);
    }

    readFromDossierBiens$(dossierBiens: ADossierBien[], template: Template, sort?: IDocumentVariableSort): Observable<Window> {
        return this.saveFromDossierBiens$(dossierBiens, template, sort).pipe(
            switchMap(document => this.read$([document], document.titre)),
        );
    }

    readFromEachDossierBiens$(dossierBiens: ADossierBien[], template: Template): Observable<Window> {
        return combineLatest(dossierBiens.map(dossierBien => this._documentService.saveFromDossierBien$(dossierBien, template).pipe(
            map(documents => documents[0])
        ))).pipe(switchMap(documents => this.read$(documents, template.name)));
    }

    saveFromDossierBiens$(dossierBiens: ADossierBien[], template: Template, sort?: IDocumentVariableSort): Observable<Document> {
        if (dossierBiens.length <= 0) {
            return of(undefined as unknown as Document);
        }

        return this._documentFactory.save$(this._documentFactory.createFromDossierBiens(dossierBiens, template, {sort}));
    }
}
