export default `
<h3 class="description-title">
  {{"Prix charge " + ($ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS ? "vendeur" : "acquéreur") |
  translate}}
</h3>

<div class="description-details">
  <!-- Vente -->
  <ng-switch on="$ctrl.unlockData" ng-if="$ctrl.dossier.type.code !== $ctrl.INTERACTIVE && $ctrl.dossier.type.code !== $ctrl.VIAGER">
    <!-- Données publiques -->
    <div ng-switch-default>
      <!-- Net vendeur -->
      <div ng-if="$ctrl.dossier.typeCharges.code !== $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS">
        <ng-include src="'src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_complet.html'"></ng-include>
      </div>

      <!-- Honoraires de négociations inclus -->
      <div ng-if="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS">
        <ng-include
          src="'src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_honoraires_negociation_inclus.html'"></ng-include>
      </div>
    </div>

    <!-- Données privées -->
    <ng-switch on="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS && !$ctrl.dossier.bien._esk.interne"
               ng-switch-when="true">
      <!-- Honoraires de négociations inclus et bien externe -->
      <div ng-switch-when="true">
        <ng-include
          src="'src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_honoraires_negociation_inclus.html'"></ng-include>
      </div>

      <!-- Net vendeur ou bien interne -->
      <div ng-switch-default>
        <ng-include src="'src/app/legacy/templates/ventes/prix/traditionnelle/deprecated_complet.html'"></ng-include>
      </div>

      <!-- Estimation -->
      <div ng-if="!!$ctrl.dossier.estimationMin || !!$ctrl.dossier.estimationMax" class="tw-text-center details-iem">
        <h5
          ng-switch="!!$ctrl.dossier.estimationMin && $ctrl.dossier.estimationMin > 0 && !!$ctrl.dossier.estimationMax && $ctrl.dossier.estimationMax > 0"
          class="text-bold">
          <ng-switch on="!!$ctrl.dossier.estimationMin && $ctrl.dossier.estimationMin > 0" ng-switch-when="false">
            <span ng-switch-when="true">
              {{"A partir de" | translate}}

              <span class="text-bold">
                {{$ctrl.dossier.estimationMin | currency:'€':2 * ($ctrl.dossier.estimationMin % 1 !== 0)}}
              </span>
            </span>

            <span ng-switch-default>
              {{"Jusqu'à" | translate}}

              <span class="text-bold">
                {{$ctrl.dossier.estimationMax | currency:'€':2 * ($ctrl.dossier.estimationMax % 1 !== 0)}}
              </span>
            </span>
          </ng-switch>

          <span ng-switch-default>
            {{"Entre" | translate}}

            <span class="text-bold">
              {{$ctrl.dossier.estimationMin | currency:'€':2 * ($ctrl.dossier.estimationMin % 1 !== 0)}}
            </span>

            {{"et" | translate}}

            <span class="text-bold">
              {{$ctrl.dossier.estimationMax | currency:'€':2 * ($ctrl.dossier.estimationMax % 1 !== 0)}}
            </span>
          </span>
        </h5>

        <p>{{'Estimation' | translate}}</p>
      </div>
    </ng-switch>
  </ng-switch>

  <!-- Interactive -->
  <ng-switch on="$ctrl.unlockData" ng-if="$ctrl.dossier.type.code === $ctrl.INTERACTIVE">
    <!-- Données publiques -->
    <div ng-switch-default="">
      <ng-include src="'src/app/legacy/templates/ventes/prix/interactive/honoraires_negociation_inclus.html'">
      </ng-include>
    </div>

    <!-- Données privées -->
    <div ng-switch-when="true">
      <ng-include src="'src/app/legacy/templates/ventes/prix/interactive/complet.html'"></ng-include>
    </div>
  </ng-switch>

  <!-- Viager -->
  <ng-switch on="$ctrl.unlockData" ng-if="$ctrl.dossier.type.code === $ctrl.VIAGER" class="details">
    <!-- Données publiques -->
    <div ng-switch-default>
      <!-- Net vendeur -->
      <div ng-if="$ctrl.dossier.typeCharges.code !== $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS">
        <ng-include src="'src/app/legacy/templates/ventes/prix/viager/complet.html'"></ng-include>
      </div>

      <!-- Honoraires de négociation inclus -->
      <div ng-if="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS">
        <ng-include src="'src/app/legacy/templates/ventes/prix/viager/honoraires_negociation_inclus.html'"></ng-include>
      </div>
    </div>

    <!-- Données privées -->
    <ng-switch on="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS && !$ctrl.dossier.bien._esk.interne"
               ng-switch-when="true">
      <!-- Honoraires de négociation inclus et bien externe -->
      <div ng-switch-when="true">
        <ng-include src="'src/app/legacy/templates/ventes/prix/viager/honoraires_negociation_inclus.html'"></ng-include>
      </div>

      <!-- Net vendeur ou bien interne -->
      <div ng-switch-default>
        <ng-include src="'src/app/legacy/templates/ventes/prix/viager/complet.html'"></ng-include>
      </div>
    </ng-switch>
  </ng-switch>
</div>
`;
