import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {switchMap, take} from 'rxjs/operators';
import {CEtudesService} from '@models/etudes/collection/etudes.collection.service';

export default function getEskSelectEtude(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-etude></esk-select-etude>
         */
        module.component('eskSelectEtude', {
            bindings: {
                ngModel: '=',
                isRequired: '=',
                name: '@'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/etude.html'
        });

        /**
         * Select etude
         *
         * @param Ng2EtudeService
         * @param Ng2CEtudesService
         * @constructor
         */
        Controller.$inject = ['Ng2EtudeService', 'Ng2CEtudesService'];
        function Controller(this: any,
                            ng2EtudeService: EtudeService,
                            ng2CEtudesService: CEtudesService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                ng2EtudeService.last$
                    .pipe(switchMap(currentEtude => ng2CEtudesService.getAll$(currentEtude)), take(1))
                    .subscribe(cEtudes => $ctrl.etudes = cEtudes.results);
                $ctrl.ngRequired = $ctrl.isRequired === true ? true : null;
            }
        }
    })(angularJS);
}
