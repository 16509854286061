export default `
<div class="contacts-search-field">
  <ui-select spinner-enabled="true" ng-model="$ctrl.currentContact"
             on-select="$ctrl.onSelect($ctrl.currentContact)" name="$ctrl.name">

    <ui-select-match placeholder="{{'Rechercher un contact...' |translate }}" allow-clear="true">
      <span class="ellipsis">{{$select.selected[$select.selected.type].convivialName}}</span>
    </ui-select-match>

    <ui-select-choices repeat="contact in $ctrl.contacts" ui-disable-choice="$ctrl.disabledMember(contact)"
                       refresh="$ctrl.refresh($select.search)" refresh-delay="500" minimum-input-length="3">
      <span class="ellipsis"
            ng-bind-html="contact[contact.type].convivialName | highlight: $select.search"></span>
    </ui-select-choices>

    <ui-select-no-choice>
      <span class="content" ng-hide="$select.refreshing || $select.search.length < 3">Pas de contact trouvé pour :
        "{{$select.search}}"</span>
    </ui-select-no-choice>
  </ui-select>
</div>

<div class="contacts-search-help">
  <a uib-popover-template="'src/app/legacy/templates/contacts/keywords.help.html'" popover-trigger="'outsideClick'"
     popover-placement="bottom-right" class="btn btn-o btn-null">
    <span class="badge badge-info">?</span>
  </a>
</div>
`;
