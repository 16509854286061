import AModel from '@models/model.abstract';
import Quartier from '@models/quartiers/quartier/quartier.model';

export default class CommuneVoie extends AModel {
    private _code!: string;
    private readonly _id: number;
    private _nom!: string;
    private _quartier!: Quartier;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get id(): number {
        return this._id;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
    }

    get quartier(): Quartier {
        return this._quartier;
    }

    set quartier(value: Quartier) {
        this._quartier = value;
    }
}
