import {inject, Injectable} from '@angular/core';
import {NgClientConfig} from '@legacy/app/client/client';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';

@Injectable({providedIn: 'root'})
export class ApiImpersonationService {
    private _ngInjectorService = inject(NgInjectorService);

    // Supprimer les injections des anciens manager
    private get ngClientConfig(): NgClientConfig {
        return this._ngInjectorService.getService<NgClientConfig>('ClientConfig');
    }

    getImpersonate(): string {
        return this.ngClientConfig.getImpersonate();
    }

    isImpersonate(): boolean {
        return !!this.getImpersonate();
    }

    razImpersonate(): void {
        this.ngClientConfig.razImpersonate();
    }

    setImpersonate(username: string): void {
        this.ngClientConfig.setImpersonate(username);
    }
}
