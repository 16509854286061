import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {SearchCriteriaService} from '@models/search/criteria/search-criteria.service';
import {EstimationService} from '@models/estimations/estimation/estimation.service';

export default function getEstimationEvaluationReferencesCriteriaCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskEstimationEvaluationReferencesCriteriaCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/controllers/item/evaluation/references/criteria.html',
        });

        /**
         * Controller of criteria for search references
         *
         * @param Ng2RouterStateForNgService
         * @param EstimationManager
         * @param EskUtils
         * @param Ng2SearchCriteriaService
         * @param EskUtils
         * @param Ng2EstimationService
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'EstimationManager', 'Ng2SearchCriteriaService', 'EskUtils', 'Ng2EstimationService'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            estimationManager: NgEstimationManager,
                            ng2SearchCriteriaService: SearchCriteriaService,
                            eskUtils: NgEskUtils,
                            ng2EstimationService: EstimationService) {
            const $ctrl = this;
            const estimation = ng2EstimationService.getCurrentFromNg();
            const criteria = ng2SearchCriteriaService.getOneOrFromEstimation('/app/estimations/' + estimationManager.current.id.toString() + '/evaluation/references/criteres', estimation);

            $ctrl.launch = launch;
            $ctrl.search = eskUtils.createNgSearchFromNg2(criteria);

            /**
             * Launch request
             */
            function launch() {
                if (!angular.isFunction($ctrl.updateSearchLocalisations)) {
                    return;
                }

                $ctrl.updateSearchLocalisations($ctrl.search).then(() => {
                    ng2SearchCriteriaService.ngSave('/app/estimations/' + estimationManager.current.id.toString() + '/evaluation/references', $ctrl.search);
                    ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + estimationManager.current.id.toString() + '/evaluation/references', {
                        estimationId: estimationManager.current.id,
                        search: $ctrl.search,
                    });
                });
            }
        }
    })(angularJS);
}
