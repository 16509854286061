import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {EmailDossierFactory} from '@models/emails/email/dossiers/dossier/email-dossier.factory';
import {EmailDossiersApiService} from '@models/emails/email/dossiers/email-dossiers.api.service';
import {ICEmailDossiersApi} from '@models/emails/email/dossiers/collection/email-dossiers.collection.interfaces';
import CEmailDossiers from '@models/emails/email/dossiers/collection/email-dossiers.collection.model';
import {Observable} from 'rxjs';
import Email from '@models/emails/email/email.model';

@Injectable({providedIn: 'root'})
export class CEmailDossiersFactory {
    private _emailDossierFactory = inject(EmailDossierFactory);
    private _emailDossiersApiService = inject(EmailDossiersApiService);

    create(cEmailDossiersApi: ICEmailDossiersApi): CEmailDossiers {
        const cEmailDossiers = new CEmailDossiers();

        cEmailDossiers.links = new Links(cEmailDossiersApi._links);
        cEmailDossiers.page = cEmailDossiersApi.page;
        cEmailDossiers.pages = cEmailDossiersApi.pages;
        cEmailDossiers.perPage = cEmailDossiersApi.limit;
        cEmailDossiers.total = cEmailDossiersApi.total;
        if (cEmailDossiersApi._embedded?.items) {
            cEmailDossiersApi._embedded.items.filter(emailDossierApi => emailDossierApi)
                .map(emailDossierApi => cEmailDossiers.results.push(this._emailDossierFactory.create(emailDossierApi)));
        }

        return cEmailDossiers;
    }

    get$(email: Email): Observable<CEmailDossiers> {
        return this._emailDossiersApiService.getCollection$(email.id.toString()).pipe(map(cEmailDossiersApi => this.create(cEmailDossiersApi)));
    }
}
