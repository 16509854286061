import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {NgEmailManager} from '@legacy/app/managers/managers';
import {EmailRecipientService} from '@models/emails/email/recipients/recipient/email-recipient.service';

export default function getEskContact(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact></esk-contact>
         */
        module.component('eskContact', {
            bindings: {type: '@', emailContent: '@'},
            templateUrl: 'src/app/legacy/templates/eskimmo/components/contact.html',
            controller: Controller
        });

        /**
         * Controller to contact support
         *
         * @param SoqModalesManager
         * @param EmailManager
         * @param $translate
         * @param Ng2EmailRecipientService
         */
        Controller.$inject = ['SoqModalesManager', 'EmailManager', '$translate', 'Ng2EmailRecipientService'];
        function Controller(this: any,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            ng2EmailRecipientService: EmailRecipientService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.sendMail = sendMail;

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isString($ctrl.type) || $ctrl.type === '') $ctrl.type = 'support';
            }

            /**
             * Send mail to support
             */
            function sendMail() {
                var subject;
                var message;

                if (angular.isString($ctrl.emailContent) && $ctrl.emailContent !== '') {
                    subject = $translate.instant($ctrl.emailContent + '.SUBJECT');
                    message = $translate.instant($ctrl.emailContent + '.MESSAGE');
                }

                if (!angular.isString(subject) || subject.startsWith($ctrl.emailContent)) subject = '';
                if (!angular.isString(message) || message.startsWith($ctrl.emailContent)) message = '';

                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: [
                                ng2EmailRecipientService.ngGet($ctrl.type === 'commerce' ? ng2EmailRecipientService.createNoteoCommerce() : ng2EmailRecipientService.createNoteoSupport()),
                            ],
                            subject: subject,
                            message: message + "<br><br>" + emailManager.getCurrentUserSignature()
                        }),
                        currentDossier: {}
                    }
                });
            }
        }
    })(angularJS);
}
