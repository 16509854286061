import angularJS from '@shared/angularJS/global.ng';
import {IModule, IScope, ITimeoutService} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import DateFormat from '@shared/date/date.format';

export default function getEskNegociationRevenusCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.component('eskNegociationRevenusCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/negociation/revenus.html',
        });

        /**
         * Controller of ventes negociees
         *
         * @param Ng2RouterStateForNgService
         * @param Ng2EtudeService
         * @param $scope
         * @param EskUtils
         * @param $timeout
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'Ng2EtudeService', '$scope', 'EskUtils', '$timeout'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2EtudeService: EtudeService,
                            $scope: IScope,
                            eskUtils: NgEskUtils,
                            $timeout: ITimeoutService) {
            const $ctrl = this;

            $ctrl.getStatisticsKey = getStatisticsKey;
            $ctrl.refresh = refresh;
            $ctrl.loading = true;
            $ctrl.dateDebut = DateFormat.toDate(ng2RouterStateForNgService.params.dateDebutBrute as string);
            $ctrl.dateFin = DateFormat.toDate(ng2RouterStateForNgService.params.dateFinBrute as string);
            $ctrl.options = {
                dateDebut: {maxDate: $ctrl.dateFin},
                dateFin: {minDate: $ctrl.dateDebut, maxDate: DateFormat.toDate()},
            }
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);

            /**
             * Get statistics
             */
            $scope.$watch('$ctrl.getStatistics', () => {
                if (!angular.isFunction($ctrl.getStatistics)) return;

                $ctrl.months = eskUtils.getPeriodByMonths($ctrl.dateDebut, $ctrl.dateFin);
                $ctrl.getStatistics()
                    .then((statistics: { data: { date: string }; date: string }) => $ctrl.statistics = {...{date: statistics.date}, ...statistics.data})
                    .finally(() => $ctrl.loading = false);
            });

            function getStatisticsKey(month: string){
                return DateFormat.dateFromDate(DateFormat.toDate(month.toString()), {withDay: false});
            }

            /**
             * Refresh data
             */
            function refresh() {
                $ctrl.options.dateDebut.maxDate = $ctrl.dateFin;
                $ctrl.options.dateFin.minDate = $ctrl.dateDebut;
                $timeout($ctrl.refreshChart);
            }
        }
    })(angularJS);
}
