import {NgModule} from '@angular/core';
import {AppQuartiersSelectionComponent} from '@features/quartiers/selection/quartiers-selection.component';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [AppQuartiersSelectionComponent],
    exports: [AppQuartiersSelectionComponent],
    imports: [CommonModule, SharedModule],
})
export class QuartiersModule {
}
