import {Component, inject, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {IDossierFileContactsNumberOptions} from '@features/dossiers/dossiers.interfaces';
import {
    CDossierFileContactsFactory
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.factory';
import CDossierFileContacts
    from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.model';

@Component({selector: 'app-dossier-file-contacts-number', templateUrl: 'dossier-file-contacts.number.component.html'})
export class AppDossierFileContactsNumberComponent {
    static readonly initContactsNumberOptions: IDossierFileContactsNumberOptions = {
        labelPrefix: '',
        labelNoOne: 'Aucun contact associé',
    };
    private _cDossierFileContactsFactory = inject(CDossierFileContactsFactory);
    private _cDossierFileContacts$!: Observable<CDossierFileContacts>;
    private _options: IDossierFileContactsNumberOptions = {...AppDossierFileContactsNumberComponent.initContactsNumberOptions};

    get cDossierFileContacts$(): Observable<CDossierFileContacts> {
        return this._cDossierFileContacts$;
    }

    @Input()
    set linkContacts(value: string) {
        this._cDossierFileContacts$ = this._cDossierFileContactsFactory.getByLink$(value, {share: true});
    }

    get options(): IDossierFileContactsNumberOptions {
        return this._options;
    }

    @Input()
    set options(value: IDossierFileContactsNumberOptions) {
        this._options = value;
    }
}
