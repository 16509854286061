import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEmailManager, NgEstimationManager} from '@legacy/app/managers/managers';
import {NgEstimationsUtils} from '@legacy/app/estimations/estimations';
import {NgEstimation, NgVente} from '@legacy/app/managers/ressources';
import {UserService} from '@models/users/user/user.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationService} from '@models/estimations/estimation/estimation.service';

export default function getEstimationLifetimeCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskEstimationLifetimeCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/controllers/item/lifetime.html',
        });

        /**
         * Controller of Lifetime Estimation
         *
         * @param EstimationManager
         * @param SoqModalesManager
         * @param Ng2RouterStateForNgService
         * @param $translate
         * @param EstimationsUtils
         * @param SoqSweetAlert
         * @param Ng2ToasterService
         * @param EmailManager
         * @param Ng2UserService
         * @param Ng2EstimationService
         */
        Controller.$inject = ['EstimationManager', 'SoqModalesManager', 'Ng2RouterStateForNgService', '$translate', 'EstimationsUtils', 'SoqSweetAlert', 'Ng2ToasterService', 'EmailManager', 'Ng2UserService', 'Ng2EstimationService'];
        function Controller(this: any,
                            estimationManager: NgEstimationManager,
                            soqModalesManager: NgSoqModalesManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $translate: angular.translate.ITranslateService,
                            estimationsUtils: NgEstimationsUtils,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2ToasterService: ToasterService,
                            emailManager: NgEmailManager,
                            ng2UserService: UserService,
                            ng2EstimationService: EstimationService) {
            const $ctrl = this;

            $ctrl.archive = archive;
            $ctrl.createEmail = createEmail;
            $ctrl.createVente = createVente;
            $ctrl.duplicate = duplicate;
            $ctrl.remettreRapport = remettreRapport;
            $ctrl.hasRoleVente = false;
            $ctrl.estimation = estimationManager.current;
            $ctrl.loader = {};
            $ctrl.STATUTS = Estimation.statuts;
            $ctrl.ng2Estimation = ng2EstimationService.getCurrentFromNg();
            ng2UserService.last$.subscribe(currentUser => $ctrl.hasRoleVente = currentUser.hasRoleGrpVente());

            /**
             * Open modal archive
             */
            function archive() {
                soqModalesManager.open('EstimationModalArchive', {resolve: {estimation: $ctrl.estimation}}).then(() => {
                    ng2ToasterService.success($translate.instant('estimation.archive.realised.TITLE'), $translate.instant('estimation.archive.realised.MESSAGE', {reference: $ctrl.estimation.bien.reference}));
                    ng2RouterStateForNgService.navigateByUrl('/app/estimations/portefeuille');
                });
            }

            /**
             * Create email
             */
            function createEmail() {
                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: $ctrl.estimation.bien.contactsGroup.getRecipients(),
                            dossiers: [
                                {
                                    typeId: $ctrl.estimation.id,
                                    type: $ctrl.estimation._esk.typeDossier,
                                    dossier: $ctrl.estimation,
                                },
                            ],
                        }),
                        currentDossier: $ctrl.estimation,
                    }
                });
            }

            /**
             * Create vente from estimation
             */
            function createVente() {
                soqSweetAlert.warningConfirmation($translate.instant('estimation.createVente.confirm.TITLE'), $translate.instant('estimation.createVente.confirm.MESSAGE', {
                    type: $ctrl.estimation.type.code,
                    reference: $ctrl.estimation.bien.reference
                }), {
                    confirmButtonText: $translate.instant('estimation.createVente.confirm.CONFIRMATION')
                }).then(function () {
                    $ctrl.loader.createVente = true;
                    $ctrl.estimation.createVente().then(function (vente: NgVente) {
                        ng2ToasterService.success($translate.instant('estimation.createVente.realised.TITLE'), $translate.instant('estimation.createVente.realised.MESSAGE', {
                            referenceEstimation: $ctrl.estimation.bien.reference,
                            referenceVente: vente.bien.reference,
                        }));
                        ng2RouterStateForNgService.navigateByUrl('/app/estimations/portefeuille');
                    }).finally(() => $ctrl.loader.createVente = false);
                });
            }

            /**
             * Duplicate location
             */
            function duplicate() {
                soqSweetAlert.warningConfirmation($translate.instant('estimation.duplicate.confirm.TITLE'), $translate.instant('estimation.duplicate.confirm.MESSAGE'), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('estimation.duplicate.confirm.INPUT'),
                    confirmButtonText: $translate.instant('estimation.duplicate.confirm.CONFIRMATION')
                }).then(function (keepOwners) {
                    ng2ToasterService.info($translate.instant('estimation.duplicate.in_progress.TITLE'), $translate.instant('estimation.duplicate.in_progress.MESSAGE'));
                    $ctrl.estimation.clone(keepOwners).then((duplicatedEstimation: NgEstimation) => ng2ToasterService.success($translate.instant('estimation.duplicate.realised.TITLE'),
                        $translate.instant('estimation.duplicate.realised.MESSAGE', {reference: duplicatedEstimation.bien.reference})));
                });
            }

            /**
             * Remettre rapport
             */
            function remettreRapport() {
                return estimationsUtils.remettreRapport($ctrl.estimation);
            }
        }
    })(angularJS);
}
