import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgBienPhoto} from '@legacy/app/managers/ressources';
import {CBienPhotosFactory} from '@models/bien/photos/collection/bien-photos.collection.factory';
import {map, take} from 'rxjs/operators';
import {NgBienPhotoManager} from '@legacy/app/managers/managers';

export default function getBienDisplayPhotos(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-display-photos></esk-bien-display-photos>
         */
        module.component('eskBienDisplayPhotos', {
            bindings: {bien: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/display.photos.html'
        });

        /**
         * Controller for display photo directive
         *
         * @param SoqModalesManager
         * @param Ng2EtudeService
         * @param Ng2CBienPhotosFactory
         * @param BienPhotoManager
         */
        Controller.$inject = ['SoqModalesManager', 'Ng2EtudeService', 'Ng2CBienPhotosFactory', 'BienPhotoManager'];
        function Controller(this: any,
                            soqModalesManager: NgSoqModalesManager,
                            ng2EtudeService: EtudeService,
                            ng2CBienPhotosFactory: CBienPhotosFactory,
                            bienPhotoManager: NgBienPhotoManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.viewOriginal = viewOriginal;

            /**
             * Initialization method
             */
            function $onInit() {
                // @todo Supprimer cette condition après la 2024.12 si pas plus de remontées
                if (!$ctrl.bien) {
                    throw new Error('Aucun bien n\'a été fourni pour le composant "esk-bien-display-photos"');
                }

                ng2CBienPhotosFactory.getByLink$($ctrl.bien._links.photos.href).pipe(
                    map(cBienPhotos => cBienPhotos.results.map(bienPhoto => bienPhotoManager.createFromNg2($ctrl.bien, bienPhoto))),
                    take(1),
                ).subscribe(bienPhotos => $ctrl.bienPhotos = bienPhotos);
                ng2EtudeService.last$.subscribe(currentEtude => $ctrl.marianne = currentEtude.linkMarianne);
            }

            /**
             * View original photo
             *
             * @param bienPhoto
             */
            function viewOriginal(bienPhoto: NgBienPhoto) {
                soqModalesManager.open('BienModalDisplayPhotos', {
                    resolve: {
                        bienPhotos: () => $ctrl.bienPhotos,
                        currentIndex: () => $ctrl.bienPhotos.findIndex((bp: NgBienPhoto) => bp._esk.uuid === bienPhoto._esk.uuid),
                    }
                });
            }
        }
    })(angularJS);
}
