import {inject, Injectable} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {combineLatest, Observable, of} from 'rxjs';
import {VenteFactory} from '@models/ventes/vente/vente.factory';

@Injectable({providedIn: 'root'})
export class VentesService {
    private _venteFactory = inject(VenteFactory);

    getByLinks$(links: string[] = []): Observable<Vente[]> {
        return links.length > 0 ? combineLatest(links.map(link => this._venteFactory.getByLink$(link))) : of([] as Vente[]);
    }
}
