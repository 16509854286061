import AModel from '@models/model.abstract';

export default class Phone extends AModel {
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    static readonly types = {FIXE: 'fixe', MOBILE: 'mobile'};
    private _numero!: string;
    private _sortableRank!: number;
    private _type!: string;

    get numero(): string {
        return this._numero;
    }

    set numero(value: string) {
        this._numero = value;
    }

    get sortableRank(): number {
        return this._sortableRank;
    }

    set sortableRank(value: number) {
        this._sortableRank = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        if (!Phone.types[value?.toUpperCase()]) {
            return;
        }

        this._type = value;
    }

    hasValidNumero(): boolean {
        return !!this.numero;
    }

    isFixe(): boolean {
        return this.type === Phone.types.FIXE;
    }

    isMobile(): boolean {
        return this.type === Phone.types.MOBILE;
    }
}
