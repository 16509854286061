import {Component, inject, Input, output, ViewChild} from '@angular/core';
import Commune from '@models/communes/commune/commune.model';
import {map, take, tap} from 'rxjs/operators';
import {CCommunesService} from '@models/communes/collection/communes.collection.service';
import {NgModel} from '@angular/forms';
import {IFormSelectInputOptions} from '@shared/form/form.interfaces';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {Observable, ReplaySubject} from 'rxjs';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {CommunesService} from '@models/communes/communes.service';

@Component({exportAs: 'communesSelect', selector: 'app-communes-select', templateUrl: 'communes-select.component.html'})
export class AppCommunesSelectComponent {
    static readonly initCommunesSelectOptions: IFormSelectInputOptions = {
        bindLabel: 'convivialName',
        customTemplate: AppFormSelectInputComponent.customTemplates.COMMUNE,
        loadingText: 'Chargement des communes',
        multiple: true,
        name: 'communesSelect',
        notFoundText: 'Aucune commune trouvée',
        placeholder: 'Rechercher une commune par son nom ou son code postal',
    };
    readonly selected = output<Commune[]>();
    private _cCommunesService = inject(CCommunesService);
    private _communeFactory = inject(CommuneFactory);
    private _communesService = inject(CommunesService);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IFormSelectInputOptions = {...AppCommunesSelectComponent.initCommunesSelectOptions};
    private _communesSource = new ReplaySubject<Commune[]>();
    private _communes$ = this._communesSource.asObservable();
    private _communes: Commune[] = [];

    get communes$(): Observable<Commune[]> {
        return this._communes$;
    }

    @Input()
    set commune(value: Commune) {
        this._communesSource.next([value]);
    }

    get communes(): Commune[] {
        return this._communes;
    }

    @ViewChild('communesSelectInput')
    set communesSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value?.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    @Input()
    set ids(value: number[]) {
        this._communesService.get$(value).pipe(take(1)).subscribe(communes => this._communesSource.next(communes));
    }

    @Input()
    set link(value: string) {
        this._communeFactory.getByLink$(value).pipe(take(1)).subscribe(commune => this._communesSource.next([commune]));
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppCommunesSelectComponent.initCommunesSelectOptions, ...value};
    }

    onSelect(communes: unknown): void {
        this.selected.emit(communes as Commune[]);
    }

    search(search: string): void {
        this._communes = [];
        this._loading = true;
        this._cCommunesService.search$(search).pipe(
            map(cCommunes => cCommunes.results),
            tap(communes => this._communes = communes),
            take(1),
        ).subscribe(_ => this._loading = false);
    }
}
