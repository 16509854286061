import {inject, Injectable} from '@angular/core';
import CTemplates from '@models/templates/collection/templates.collection.model';
import {Observable, switchMap} from 'rxjs';
import {CTemplatesFactory} from '@models/templates/collection/templates.collection.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CTemplatesService {
    private _cTemplatesFactory = inject(CTemplatesFactory);
    private _etudeService = inject(EtudeService);

    getWithDefaultFirst$(categoryCodes: string[] = []): Observable<CTemplates> {
        return this._etudeService.last$.pipe(
            map(currentEtude => currentEtude.defaultTemplates.filter(template => categoryCodes.includes(template.category.code))),
            switchMap(etudeDefaultTemplates => this._cTemplatesFactory.get$({categoryCodes}).pipe(
                map(cTemplates => {
                    if (etudeDefaultTemplates.length > 0) {
                        const defaultTemplate = cTemplates.results.find(template => template.uuid === etudeDefaultTemplates[0].uuid)!;

                        cTemplates.removeResult(template => template.uuid === etudeDefaultTemplates[0].uuid);
                        cTemplates.addResult(defaultTemplate, true);
                    }

                    return cTemplates;
                }),
            )),
        );
    }
}
