import {Component, inject, Input} from '@angular/core';
import {Observable} from 'rxjs';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';

@Component({selector: 'app-cityscan-onsale-property-photo', templateUrl: 'cityscan-onsale-property.photo.component.html'})
export class CityscanOnsalePropertyPhotoComponent {
    private _etudeService = inject(EtudeService);
    private _cityscanOnsaleProperty!: CityscanOnsaleProperty;

    @Input({required: true})
    set cityscanOnsaleProperty(value: CityscanOnsaleProperty) {
        this._cityscanOnsaleProperty = value;
    }

    get cityscanOnsaleProperty(): CityscanOnsaleProperty {
        return this._cityscanOnsaleProperty;
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

}
