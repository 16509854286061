import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {IEtudeSettingsCityscanApi} from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.interfaces';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import {EtudeSettingsCityscanApiService} from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.api.service';

@Injectable({providedIn: 'root'})
export class EtudeSettingsCityscanFactory {
    private _etudeSettingsCityscanApiService = inject(EtudeSettingsCityscanApiService);

    create(etudeSettingsCityscanApi: IEtudeSettingsCityscanApi): EtudeSettingsCityscan {
        const etudeSettingsCityscan = this.createVirgin();

        etudeSettingsCityscan.enabled = etudeSettingsCityscanApi.enabled;

        return etudeSettingsCityscan;
    }

    createVirgin(): EtudeSettingsCityscan {
        return new EtudeSettingsCityscan();
    }

    get$(etude: Etude): Observable<EtudeSettingsCityscan> {
        return this._etudeSettingsCityscanApiService.get$(etude.id.toString()).pipe(map(etudeSettingsCityscanApi => this.create(etudeSettingsCityscanApi)));
    }
}
