import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewAgencementInterieurService
} from '@features/dossiers/dossier/bien/overview/agencement-interieur/dossier-bien-overview.agencement-interieur.service';
import {
    DossierBienOverviewBatimentService
} from '@features/dossiers/dossier/bien/overview/batiment/dossier-bien-overview.batiment.service';
import {
    DossierBienOverviewPerformanceEnergetiqueService
} from '@features/dossiers/dossier/bien/overview/performance-energetique/dossier-bien-overview.performance-energetique.service';
import {
    DossierBienOverviewTerrainService
} from '@features/dossiers/dossier/bien/overview/terrain/dossier-bien-overview.terrain.service';
import {
    DossierBienOverviewAccessibiliteEnvironnementService
} from '@features/dossiers/dossier/bien/overview/accessibilite-environnement/dossier-bien-overview.accessibilite-environnement.service';
import {
    DossierBienOverviewAgencementExterieurService
} from '@features/dossiers/dossier/bien/overview/agencement-exterieur/dossier-bien-overview.agencement-exterieur.service';
import {
    DossierBienOverviewViabilisationService
} from '@features/dossiers/dossier/bien/overview/viabilisation/dossier-bien-overview.viabilisation.service';
import {
    DossierBienOverviewDiagnosticsService
} from '@features/dossiers/dossier/bien/overview/diagnostics/dossier-bien-overview.diagnostics.service';
import {
    DossierBienOverviewLoiAlurService
} from '@features/dossiers/dossier/bien/overview/loi-alur/dossier-bien-overview.loi-alur.service';
import {
    DossierBienOverviewHabitationService
} from '@features/dossiers/dossier/bien/overview/habitation/dossier-bien-overview.habitation.service';
import {
    DossierBienOverviewCoutsService
} from '@features/dossiers/dossier/bien/overview/couts/dossier-bien-overview.couts.service';
import {
    DossierBienOverviewUrbanismeService
} from '@features/dossiers/dossier/bien/overview/urbanisme/dossier-bien-overview.urbanisme.service';
import {
    DossierBienOverviewRisquesService
} from '@features/dossiers/dossier/bien/overview/risques/dossier-bien-overview.risques.service';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewInfosService {
    private _dossierBienOverviewAccessibiliteEnvironnementService = inject(DossierBienOverviewAccessibiliteEnvironnementService);
    private _dossierBienOverviewAgencementExterieurService = inject(DossierBienOverviewAgencementExterieurService);
    private _dossierBienOverviewAgencementInterieurService = inject(DossierBienOverviewAgencementInterieurService);
    private _dossierBienOverviewBatimentService = inject(DossierBienOverviewBatimentService);
    private _dossierBienOverviewCoutsService = inject(DossierBienOverviewCoutsService);
    private _dossierBienOverviewDiagnosticsService = inject(DossierBienOverviewDiagnosticsService);
    private _dossierBienOverviewHabitationService = inject(DossierBienOverviewHabitationService);
    private _dossierBienOverviewLoiAlurService = inject(DossierBienOverviewLoiAlurService);
    private _dossierBienOverviewPerformanceEnergetiqueService = inject(DossierBienOverviewPerformanceEnergetiqueService);
    private _dossierBienOverviewRisquesService = inject(DossierBienOverviewRisquesService);
    private _dossierBienOverviewTerrainService = inject(DossierBienOverviewTerrainService);
    private _dossierBienOverviewUrbanismeService = inject(DossierBienOverviewUrbanismeService);
    private _dossierBienOverviewViabilisationService = inject(DossierBienOverviewViabilisationService);
    private _isPartVisible!: boolean;

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._dossierBienOverviewAccessibiliteEnvironnementService.init(dossierBien);
        this._dossierBienOverviewAgencementExterieurService.init(dossierBien);
        this._dossierBienOverviewAgencementInterieurService.init(dossierBien);
        this._dossierBienOverviewBatimentService.init(dossierBien);
        this._dossierBienOverviewCoutsService.init(dossierBien);
        this._dossierBienOverviewDiagnosticsService.init(dossierBien);
        this._dossierBienOverviewHabitationService.init(dossierBien);
        this._dossierBienOverviewLoiAlurService.init(dossierBien);
        this._dossierBienOverviewPerformanceEnergetiqueService.init(dossierBien);
        this._dossierBienOverviewRisquesService.init(dossierBien);
        this._dossierBienOverviewTerrainService.init(dossierBien);
        this._dossierBienOverviewUrbanismeService.init(dossierBien);
        this._dossierBienOverviewViabilisationService.init(dossierBien);
        this._isPartVisible = this._dossierBienOverviewAccessibiliteEnvironnementService.isPartVisible
            || this._dossierBienOverviewAgencementExterieurService.isPartVisible
            || this._dossierBienOverviewAgencementInterieurService.isPartVisible
            || this._dossierBienOverviewBatimentService.isPartVisible
            || this._dossierBienOverviewCoutsService.isPartVisible
            || this._dossierBienOverviewDiagnosticsService.isPartVisible
            || this._dossierBienOverviewHabitationService.isPartVisible
            || this._dossierBienOverviewLoiAlurService.isPartVisible
            || this._dossierBienOverviewPerformanceEnergetiqueService.isPartVisible
            || this._dossierBienOverviewRisquesService.isPartVisible
            || this._dossierBienOverviewTerrainService.isPartVisible
            || this._dossierBienOverviewUrbanismeService.isPartVisible
            || this._dossierBienOverviewViabilisationService.isPartVisible;
    }
}
