import {inject, Injectable} from '@angular/core';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NgContactsGroup, NgDemandeur} from '@legacy/app/managers/ressources';
import {DemandeursApiService} from '@models/demandeurs/demandeurs.api.service';
import {IDemandeurApi} from '@models/demandeurs/demandeur/demandeur.interfaces';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {UserFactory} from '@models/users/user/user.factory';
import Contact from '@models/contacts/contact/contact.model';

@Injectable({providedIn: 'root'})
export class DemandeurFactory {
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _demandeursApiService = inject(DemandeursApiService);
    private _userFactory = inject(UserFactory);

    create(demandeurApi: IDemandeurApi): Demandeur {
        const demandeur = this.createVirgin(demandeurApi.uuid, demandeurApi.id, demandeurApi.reference);

        demandeur.dateCreation = demandeurApi.dateCreation;
        demandeur.statut = demandeurApi.statut;
        if (demandeurApi._embedded) {
            if (demandeurApi._embedded.contactsGroup) {
                demandeur.contactsGroup = this._contactsGroupFactory.create(demandeurApi._embedded.contactsGroup);
            }

            if (demandeurApi._embedded.responsableDossier) {
                demandeur.responsableDossier = this._userFactory.create(demandeurApi._embedded.responsableDossier);
            }
        }

        if (demandeurApi._links) {
            if (demandeurApi._links.contactsGroup) {
                demandeur.linkContactsGroup = demandeurApi._links.contactsGroup.href;
            }

            if (demandeurApi._links.recherches) {
                demandeur.linkRecherches = demandeurApi._links.recherches.href;
            }

            if (demandeurApi._links.self) {
                demandeur.linkSelf = demandeurApi._links.self.href;
            }
        }

        return demandeur;
    }

    createFromContact(contact: Contact): Demandeur {
        const demandeur = this.createVirgin();

        demandeur.contactsGroup = this._contactsGroupFactory.createFromContact(contact);

        return demandeur;
    }

    createVirgin(uuid?: string, id?: number, reference?: string): Demandeur {
        const demandeur = new Demandeur(uuid ?? Demandeur.statuts.NEW, id, reference);

        demandeur.statut = Demandeur.statuts.BROUILLON;

        return demandeur;
    }

    forApi(demandeur: Demandeur): IDemandeurApi {
        const demandeurApi = {} as IDemandeurApi;

        demandeurApi.contactsGroupId = demandeur.contactsGroup?.id;
        demandeurApi.responsableDossierId = demandeur.responsableId;

        return demandeurApi;
    }

    getByLink$(link: string): Observable<Demandeur> {
        return this._demandeursApiService.getByLink$(link).pipe(map(demandeurApi => this.create(demandeurApi)));
    }

    save$(demandeur: Demandeur): Observable<Demandeur> {
        return this._demandeursApiService.save$(demandeur.uuid, this.forApi(demandeur)).pipe(map(demandeurApi => this.create(demandeurApi)));
    }

    ngCreate(ngDemandeur: NgDemandeur): Demandeur {
        const demandeur = this.createVirgin(ngDemandeur.uuid, ngDemandeur.id, ngDemandeur.reference);

        demandeur.statut = ngDemandeur.statut;

        if (ngDemandeur._links) {
            if (ngDemandeur._links.contactsGroup) {
                demandeur.linkContactsGroup = ngDemandeur._links.contactsGroup.href;
            }

            if (ngDemandeur._links.self) {
                demandeur.linkSelf = ngDemandeur._links.self.href;
            }
        }

        if (ngDemandeur.contactsGroup) {
            demandeur.contactsGroup = this._contactsGroupFactory.ngCreate(ngDemandeur.contactsGroup as unknown as NgContactsGroup);
        }

        return demandeur;
    }
}
