import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TextPhoneComponent} from '@shared/texts/phones/phone/text-phone.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TextPhoneComponent],
    selector: 'app-text-phones',
    standalone: true,
    templateUrl: 'text-phones.component.html',
})
export class TextPhonesComponent {
    private _phones!: string[];

    get phones(): string[] {
        return this._phones;
    }

    @Input()
    set phones(value: string[]) {
        this._phones = value.filter(phone => !!phone);
    }
}
