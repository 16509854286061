@if (demandeur$ | async; as demandeur) {
  <div class="tw-flex tw-flex-col sm:tw-flex-row sm:tw-space-x-3 sm:tw-items-center">
    <div class="tw-flex-1 tw-overflow-hidden tw-space-y-1">
      <div class="tw-text-base tw-font-semibold">
        <app-contacts-group-convivial-name [linkContactsGroup]="demandeur.linkContactsGroup"
                                           [options]="{highlight: options.highlight}"/>
      </div>

      <div class="tw-space-x-2 tw-pb-1">
        <span [highlight]="options.highlight">{{ demandeur.reference }}</span>

        <app-demandeur-statut [demandeur]="demandeur" class="tw-inline-block"/>
      </div>
    </div>

    <app-contacts-group-number-contacts [linkContactsGroup]="demandeur.linkContactsGroup"/>
  </div>
} @else {
  <app-loader class="tw-block" [classHeight]="'tw-h-20'"/>
}
