import {NgModule, Type} from '@angular/core';
import {NgBienHeaderComponent, NgBienHeaderDirective} from '@shared/angularJS/up-ng/biens/bien-header.component';
import {
    NgBienEditHeaderComponent, NgBienEditHeaderDirective
} from '@shared/angularJS/up-ng/biens/bien-edit-header.component';
import {
    NgBiensSearchComponent, NgBiensSearchDirective
} from '@shared/angularJS/up-ng/biens/biens.search.component';
import {
    NgBienEditPhotosComponent, NgBienEditPhotosDirective
} from '@shared/angularJS/up-ng/biens/biens-item-edit-photos.component';
import {
    NgBienEditVirtualVisitComponent, NgBienEditVirtualVisitDirective
} from '@shared/angularJS/up-ng/biens/biens-item-edit-virtual_visit.component';
import {
    NgBiensLastCriteriaComponent, NgBiensLastCriteriaDirective
} from '@shared/angularJS/up-ng/biens/biens-last-criteria.component';
import {
    NgBiensWalletCurrentComponent, NgBiensWalletCurrentDirective
} from '@shared/angularJS/up-ng/biens/biens-wallet-current.component';
import {
    NgBiensWalletDiffusionComponent, NgBiensWalletDiffusionDirective
} from '@shared/angularJS/up-ng/biens/biens-wallet-diffusion.component';
import {
    NgBiensWalletSearchCriteriaComponent, NgBiensWalletSearchCriteriaDirective
} from '@shared/angularJS/up-ng/biens/biens-wallet-search-criteria.component';
import {
    NgBienDetailsComponent, NgBienDetailsDirective
} from '@shared/angularJS/up-ng/biens/biens-item-details.component';

const components: Type<unknown>[] = [
    NgBienEditHeaderComponent,
    NgBienEditPhotosComponent,
    NgBienEditVirtualVisitComponent,
    NgBienDetailsComponent,
    NgBienHeaderComponent,
    NgBiensLastCriteriaComponent,
    NgBiensSearchComponent,
    NgBiensWalletCurrentComponent,
    NgBiensWalletDiffusionComponent,
    NgBiensWalletSearchCriteriaComponent,
];
const directives: Type<unknown>[] = [
    NgBienEditHeaderDirective,
    NgBienEditPhotosDirective,
    NgBienEditVirtualVisitDirective,
    NgBienDetailsDirective,
    NgBienHeaderDirective,
    NgBiensLastCriteriaDirective,
    NgBiensSearchDirective,
    NgBiensWalletCurrentDirective,
    NgBiensWalletDiffusionDirective,
    NgBiensWalletSearchCriteriaDirective,
];

@NgModule({declarations: [...components, ...directives], exports: components})
export class BiensComponentsModule {
}
