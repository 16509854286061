export abstract class AbstractStorageService {
    static readonly PREFIX = 'noty';
    abstract storage: Storage;

    get<T>(key: string): T {
        const data = this.storage.getItem(AbstractStorageService.PREFIX + '.' + key);

        if (!data) {
            return undefined!;
        }

        try {
            return JSON.parse(data) as T;
        } catch (e) {
            return undefined!;
        }
    }

    remove(key: string): void {
        this.storage.removeItem(AbstractStorageService.PREFIX + '.' + key);
    }

    set(key: string, data: unknown): void {
        this.storage.setItem(AbstractStorageService.PREFIX + '.' + key, JSON.stringify(data));
    }
}
