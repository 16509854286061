import {inject, Injectable, Type} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {BehaviorSubject, from, Observable, of, Subject, throwError} from 'rxjs';
import {
    IDynamicComponent, IDynamicComponentData, IDynamicComponentLoading
} from '@shared/shared.interfaces';
import {ModalConfirmationComponent} from '@shared/modal/confirmation/modal.confirmation.component';
import {IModalConfirmationData, IModalInformationData, IModalOptions} from '@shared/modal/modal.interfaces';
import {catchError, map, take} from 'rxjs/operators';
import {ModalInformationComponent} from '@shared/modal/information/modal.information.component';

// @todo Voir ce qui est en place de SlideOverService et améliorer au besoin
@Injectable({providedIn: 'root'})
export class ModalService {
    static readonly NG_DISMISS = [undefined, 'backdrop click', 'escape key press'];
    // @todo Renommer en statut
    static readonly status = {
        DANGER: 'danger',
        INFO: 'info',
        SUCCESS: 'success',
        WARNING: 'warning',
    };
    private _ngInjectorService = inject(NgInjectorService);
    private _dynamicComponentLoadingSource = new Subject<IDynamicComponentLoading>();
    private _dynamicComponentLoading$ = this._dynamicComponentLoadingSource.asObservable();
    private _isOpenSource = new BehaviorSubject<boolean>(false);
    private _isOpen$ = this._isOpenSource.asObservable();
    private _responseSource = new Subject<unknown>();

    // Supprimer les injections des anciens manager
    private get ngSoqModalesManager(): NgSoqModalesManager {
        return this._ngInjectorService.getService('SoqModalesManager');
    }

    get isOpen$(): Observable<boolean> {
        return this._isOpen$;
    }

    get dynamicComponentLoading$(): Observable<IDynamicComponentLoading> {
        return this._dynamicComponentLoading$;
    }

    ngOpen$<T>(name: string, options: ng.ui.bootstrap.IModalSettings = {resolve: {}}): Observable<T> {
        return from(this.ngSoqModalesManager.open<T>(name, options)).pipe(
            catchError((error: string) => {
                if (ModalService.NG_DISMISS.includes(error)) {
                    return of(undefined as unknown as T);
                }

                return throwError(() => error);
            }),
        );
    }

    open(component: Type<IDynamicComponent>, data?: unknown, options?: IModalOptions): void {
        this.open$(component, data, options).pipe(take(1)).subscribe();
    }

    // @todo Faire le tour des appels pour ajouter le type du retour correctement
    open$<T = unknown>(component: Type<IDynamicComponent>, data?: unknown, options?: IModalOptions): Observable<T> {
        // IMPORTANT de conserver cet ordre d'exécution afin que tout soit initialisé avant l'ouverture
        this._dynamicComponentLoadingSource.next({component, data: data as IDynamicComponentData, options});
        this._isOpenSource.next(true);

        // @todo Voir si besoin de rajouter ".pipe(take(1))"
        return this._responseSource.asObservable() as Observable<T>;
    }

    // @todo Rechercher "open$(ModalConfirmationComponent" et remplacer par cette méthode
    openConfirmation$(data?: IModalConfirmationData): Observable<boolean> {
        return this.open$(ModalConfirmationComponent, data).pipe(map(isAccepted => !!isAccepted));
    }

    // @todo Rechercher "open$(ModalInformationComponent" et remplacer par cette méthode
    openInformation$(data?: IModalInformationData): Observable<void> {
        return this.open$(ModalInformationComponent, data).pipe(map(_ => undefined));
    }

    setResponse(response?: unknown): void {
        // IMPORTANT de conserver cet ordre d'exécution afin que l'utilisateur ne puisse pas faire une autre action
        this._isOpenSource.next(false);
        this._responseSource.next(response);
    }
}
