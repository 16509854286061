export default `
<ui-select ng-model="$ctrl.ngModel" uis-open-close="$ctrl.isOpenClose(isOpen)" on-select="$ctrl.onSelect($item, $model)"
           name="{{$ctrl.name}}" backspace-reset="!$ctrl.ngRequired" ng-required="{{$ctrl.ngRequired}}">
  <ui-select-match placeholder="{{$ctrl.options.placeholder}}" allow-clear="{{!$ctrl.ngRequired}}">
    {{$select.selected.label}}
  </ui-select-match>

  <ui-select-choices repeat="itemDictionary in $ctrl.itemsDictionary | filter: $select.search">
    <span ng-bind-html="itemDictionary.label | highlight: $select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <ng-switch on="!!$ctrl.onNoResult" ng-hide="$select.refreshing || $select.search.length === 0" class="content">
      <div ng-switch-default>Aucun résultat pour "{{$select.search}}"</div>

      <button ng-switch-when="true" type="button" class="btn btn-link padding-0"
              ng-click="$ctrl.clickWhenNoResult($select)">
        Cliquez pour saisir "{{$select.search}}"
      </button>
    </ng-switch>
  </ui-select-no-choice>
</ui-select>
`;
