import angularJS from '@shared/angularJS/global.ng';
import {
    IAttributes, ICompileService, IController, IDirectiveFactory, IModule, Injectable, IQService, IScope
} from 'angular';
import {NgCommune} from '@legacy/app/managers/ressources';
import {NgCommuneManager} from '@legacy/app/managers/managers';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getEskCarteQuartiers(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-carte-quartiers></esk-carte-quartiers>
         */
        module.directive('eskCarteQuartiers', directive as Injectable<IDirectiveFactory>);

        /**
         * Display carte quartiers
         *
         * @param CommuneManager
         * @param $compile
         * @param $q
         * @param spz
         * @returns {{restrict: string, scope: {commune: Object, onSelect: Function}, link: Link, template: string, controller: Controller}}
         */
        directive.$inject = ['CommuneManager', '$compile', '$q', 'spz'];
        function directive(communeManager: NgCommuneManager, $compile: ICompileService, $q: IQService, spz: (selector: string) => { zoomIn: () => void; zoomOut: () => void; reset: () => void }) {
            return {
                restrict: 'E',
                scope: {commune: '=', codesSelected: '=', onSelect: '='},
                link: link,
                templateUrl: 'src/app/legacy/templates/eskimmo/directives/carte-quartiers.html',
                controller: [controller],
                controllerAs: '$ctrl'
            };

            /**
             * Link of directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             * @param $ctrl
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes, $ctrl: IController) {
                // @ts-ignore
                updateCarte($scope.commune);

                $scope.$watch('commune', function (commune: NgCommune, oldCommune: NgCommune) {
                    if (commune.id !== oldCommune.id) {
                        updateCarte(commune);
                    }
                });

                $scope.$watch('codesSelected', function (codesSelected: unknown[], oldCodesSelected: unknown[]) {
                    if (codesSelected.join() !== oldCodesSelected.join()) {
                        setSelection($element.find('#carte-quartiers').find('svg#carteQuartiers') as unknown as SVGElement, undefined!);
                    }
                });

                /**
                 * Update carte
                 *
                 * @param commune
                 */
                function updateCarte(commune: NgCommune) {
                    const container = $element.find('#carte-quartiers');
                    const svgOldCarte = container.find('svg');

                    // Initialisation
                    svgOldCarte.remove();
                    container.append('<div id="loader-carte-quartiers" class="padding-top-20 tw-text-center text-large"><fa-icon [icon]="\'spinner\'" animation="spin"></fa-icon> Chargement de la carte des quartiers pour ' + commune.name + '</div>');
                    $compile(container)($scope);

                    // Mise à jour de la carte
                    $ctrl.getCommune(commune).then(function (commune: NgCommune) {
                        const selectorSvgCarteCSS = 'svg#carteQuartiers';

                        // Ajout de la carte
                        container.append(commune.carteQuartiers);

                        const svgCarte = container.find(selectorSvgCarteCSS);

                        // Ajout des attributs à la carte
                        svgCarte.attr('svg-pan-zoom', '');
                        svgCarte.attr('height', '100%');
                        svgCarte.attr('width', '100%');

                        // Ajout des boutons de zoom
                        setZoom(spz(selectorSvgCarteCSS));

                        // Sélection des quartiers et ajout des évènements à la carte
                        setSelection(svgCarte as unknown as SVGElement, {
                            onClick: onClick as unknown as (this: GlobalEventHandlers, ev: MouseEvent | TouchEvent) => void,
                            onMouseOver: onMouseOver as unknown as (this: GlobalEventHandlers, ev: MouseEvent | TouchEvent) => void,
                            onMouseOut: onMouseOut as unknown as (this: GlobalEventHandlers, ev: MouseEvent | TouchEvent) => void,
                            onTouchStart: onClick as unknown as (this: GlobalEventHandlers, ev: MouseEvent | TouchEvent) => void
                        });

                        // Nettoyage et compilation
                        container.find('#loader-carte-quartiers').remove();
                        $compile(container)($scope);

                        /**
                         * Set zoom actions
                         *
                         * @param spzTrigger
                         */
                        function setZoom(spzTrigger: { zoomIn: () => void; zoomOut: () => void; reset: () => void }) {
                            // @ts-ignore
                            $scope.ZoomIn = ZoomIn;
                            // @ts-ignore
                            $scope.ZoomOut = ZoomOut;
                            // @ts-ignore
                            $scope.RAZ = RAZ;

                            /**
                             * Zoom in
                             */
                            function ZoomIn() {
                                spzTrigger.zoomIn();
                            }

                            /**
                             * Zoom out
                             */
                            function ZoomOut() {
                                spzTrigger.zoomOut();
                            }

                            /**
                             * RAZ
                             */
                            function RAZ() {
                                spzTrigger.reset();
                            }
                        }

                        /**
                         * On mouse over carte
                         *
                         * @param evt
                         */
                        function onMouseOver(evt: { target: { parentElement: HTMLElement } }) {
                            const quartierElement = angular.element(evt.target.parentElement);

                            if (!quartierElement.hasClass('quartier-selected')) {
                                quartierElement.addClass('quartier-hover');
                            }
                        }

                        /**
                         * On mouse out carte
                         *
                         * @param evt
                         */
                        function onMouseOut(evt: { target: { parentElement: HTMLElement } }) {
                            angular.element(evt.target.parentElement).removeClass('quartier-hover');
                        }

                        /**
                         * On click on carte
                         *
                         * @param evt
                         */
                        function onClick(evt: { target: { parentElement: HTMLElement }; type: string }) {
                            const quartier = evt.target.parentElement;
                            const quartierIsSelected = !angular.element(quartier).hasClass('quartier-selected');

                            if (!angular.element(quartier).hasClass('quartier')) {
                                return;
                            }

                            if (quartierIsSelected) {
                                // @ts-ignore
                                $scope.codesSelected.push(quartier.id);
                                angular.element(quartier).addClass('quartier-selected');
                                angular.element(quartier).removeClass('quartier-hover');
                            } else {
                                const idx = ($scope as unknown as { codesSelected: unknown[] }).codesSelected.indexOf(quartier.id);

                                if (idx > -1) {
                                    ($scope as unknown as { codesSelected: unknown[] }).codesSelected.splice(idx, 1);
                                }
                                angular.element(quartier).removeClass('quartier-selected');
                                if (evt.type !== "touchstart") {
                                    angular.element(quartier).addClass('quartier-hover');
                                }
                            }

                            $scope.$apply(function () {
                                ($scope as unknown as { onSelect: (quartier: HTMLElement, quartierIsSelected: boolean) => void }).onSelect(quartier, quartierIsSelected);
                            });
                        }
                    });
                }

                /**
                 * Set selection of quartiers
                 *
                 * @param svgCarte
                 * @param methods
                 */
                function setSelection(svgCarte: SVGElement, methods: Record<string, (this: GlobalEventHandlers, ev: MouseEvent | TouchEvent) => void>) {
                    if (!svgCarte || !svgCarte.querySelectorAll) {
                        throw new Error(JSON.stringify(svgCarte));
                    }

                    angular.forEach(svgCarte.querySelectorAll('g[id]'), function (svgNode: SVGElement) {
                        const elementSvgNode = angular.element(svgNode);

                        if (angular.isUndefined(elementSvgNode[0].querySelectorAll('g[id]')[0])) {
                            elementSvgNode.addClass('quartier');
                            // @ts-ignore
                            if ($scope.codesSelected.includes(elementSvgNode[0].id)) {
                                elementSvgNode.addClass('quartier-selected');
                            } else {
                                elementSvgNode.removeClass('quartier-selected');
                            }

                            if (angular.isObject(methods)) {
                                elementSvgNode[0].onclick = methods.onClick;
                                elementSvgNode[0].onmouseover = methods.onMouseOver;
                                elementSvgNode[0].onmouseout = methods.onMouseOut;
                                elementSvgNode[0].ontouchstart = methods.onTouchStart;
                            }
                        }
                    });
                }
            }

            /**
             * Controller of directive
             */
            function controller(this: any) {
                const $ctrl = this;

                $ctrl.getCommune = getCommune;

                /**
                 * Get commune
                 *
                 * @returns {Promise}
                 */
                function getCommune(commune: NgCommune) {
                    if (!commune.hasCarteQuartiers) {
                        return $q.reject('NO_CARD');
                    }

                    $ctrl.loader = true;

                    return communeManager.getOneById(commune.id).finally(() => $ctrl.loader = false);
                }
            }
        }
    })(angularJS);
}
