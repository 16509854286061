import angularJS from '@shared/angularJS/global.ng';
import {IModule, IScope, ITimeoutService} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgContactsGroupManager, NgDemandeurManager} from '@legacy/app/managers/managers';
import {BehaviorSubject, firstValueFrom, Subject, switchMap} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getDemandeurEditGeneralCtrl(module: IModule): void {
    (angular => {
        'use strict';

        module.component('eskDemandeurEditGeneralCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/controllers/item/edit/general.html',
        });

        /**
         * Controller to edit demandeur
         *
         * @param DemandeurManager
         * @param Ng2RouterStateForNgService
         * @param Ng2DemandeurFactory
         * @param ContactsGroupManager
         * @param Ng2ContactsGroupFactory
         * @param $timeout
         * @param $scope
         * @param Ng2ContactsGroupService
         */
        Controller.$inject = ['DemandeurManager', 'Ng2RouterStateForNgService', 'Ng2DemandeurFactory', 'ContactsGroupManager', 'Ng2ContactsGroupFactory', '$timeout', '$scope', 'Ng2ContactsGroupService'];
        function Controller(this: any,
                            demandeurManager: NgDemandeurManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2DemandeurFactory: DemandeurFactory,
                            contactsGroupManager: NgContactsGroupManager,
                            ng2ContactsGroupFactory: ContactsGroupFactory,
                            $timeout: ITimeoutService,
                            $scope: IScope,
                            ng2ContactsGroupService: ContactsGroupService) {
            const $ctrl = this;
            const _contactsGroupLinkSource = new BehaviorSubject<string>(undefined!);
            const _onDestroy$ = new Subject<void>();

            $ctrl.onGroupCreated = onGroupCreated;
            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.$onInit = $onInit;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.FINANCEMENT_FONDS_PROVENANCES = Dictionary.names.FINANCEMENT_FONDS_PROVENANCES;
            $ctrl.FINANCEMENT_STATUTS = Dictionary.names.FINANCEMENT_STATUTS;
            demandeurManager.current$.pipe(takeUntil(_onDestroy$)).subscribe(ngDemandeur => {
                const demandeur = ng2DemandeurFactory.ngCreate(ngDemandeur);

                _contactsGroupLinkSource.next(demandeur.contactsGroup?.linkSelf);
                $ctrl.demandeur = ngDemandeur;
            });

            /**
             * When content view is loaded
             */
            $timeout(function () {
                // @ts-ignore
                if (!angular.isObject($scope) || !angular.isObject($scope.$parent) || !angular.isObject($scope.$parent.$ctrl) || !angular.isObject($scope.$parent.$ctrl.editForm)) {
                    return;
                }

                // @ts-ignore
                $ctrl.editForm = $scope.$parent.$ctrl.editForm;
            });

            /**
             * Initialization method
             */
            function $onInit() {
                _contactsGroupLinkSource.asObservable().pipe(
                    tap(_ => contactsGroupManager.currentSource.next(undefined!)),
                    switchMap(link => ng2ContactsGroupService.getOneByLink$(link)),
                    takeUntil(_onDestroy$),
                ).subscribe(contactsGroupCurrent => contactsGroupManager.currentSource.next(contactsGroupCurrent));
            }

            /**
             * Save vente when the ContactsGroup is created
             *
             * @param contactsGroup
             * @returns {*}
             */
            function onGroupCreated(contactsGroup: ContactsGroup) {
                if (contactsGroup.id && !contactsGroup.hasNom()) {
                    return;
                }

                return firstValueFrom(ng2ContactsGroupFactory.save$(contactsGroup))
                    .then(contactsGroupSaved => contactsGroupManager.createFromNg2(contactsGroupSaved))
                    .then(contactsGroupSaved => {
                        $ctrl.demandeur.contactsGroup = contactsGroupSaved;
                        if (!contactsGroup.id) {
                            _contactsGroupLinkSource.next(contactsGroupSaved._links.self.href);
                        }
                    })
                    .then(() => {
                        if (!$ctrl.demandeur.id) {
                            return;
                        }

                        return $ctrl.demandeur.save().then(() => {
                            ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/edit/general');

                            return $ctrl.demandeur.contactsGroup;
                        });
                    });
            }
        }
    })(angularJS);
}
