import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import Swal, {SweetAlertOptions, SweetAlertResult} from 'sweetalert2/src/sweetalert2.js';

export default function getSoqSweetAlert(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('SoqSweetAlert', Factory);

        /**
         * All methods to use swal
         *
         * @param $q
         * @returns {SoqSweetAlert}
         */
        Factory.$inject = ['$q'];
        function Factory($q: IQService) {
            const configs = {
                confirmation: {
                    confirmButtonText: 'Confirmer',
                    showConfirmButton: true,
                    showCancelButton: true,
                    cancelButtonText: 'Annuler'
                },
                message: {
                    confirmButtonText: 'OK'
                },
                input: {
                    input: "text",
                    confirmButtonColor: '#5cb85c'
                },
                success: {
                    icon: 'success',
                    confirmButtonColor: '#5cb85c'
                },
                warning: {
                    icon: 'warning',
                    confirmButtonColor: '#eea236'
                },
                danger: {
                    icon: 'error',
                    confirmButtonColor: '#d43f3a'
                }
            };

            return {
                successConfirmation: successConfirmation,
                warningConfirmation: warningConfirmation,
                dangerConfirmation: dangerConfirmation,
                inputConfirmation: inputConfirmation,
                successMessage: successMessage,
                warningMessage: warningMessage,
                dangerMessage: dangerMessage,
                inputMessage: inputMessage
            };

            /**
             * Success confirmation
             *
             * @param title
             * @param text
             * @param options
             * @returns {*}
             */
            function successConfirmation<T>(title: string, text: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({}, configs.confirmation, configs.success, options), true);
            }

            /**
             * Warning confirmation
             *
             * @param title
             * @param text
             * @param options
             * @returns {*}
             */
            function warningConfirmation<T>(title: string, text: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({}, configs.confirmation, configs.warning, options), true);
            }

            /**
             * Danger confirmation
             *
             * @param title
             * @param text
             * @param options
             * @returns {*}
             */
            function dangerConfirmation<T>(title: string, text: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({}, configs.confirmation, configs.danger, options), true);
            }

            /**
             * Input confirmation
             *
             * @param title
             * @param text
             * @param placeholder
             * @param options
             * @returns {*}
             */
            function inputConfirmation<T>(title: string, text: string, placeholder: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({inputPlaceholder: placeholder}, configs.confirmation, configs.input, options), true);
            }

            /**
             * Success message
             *
             * @param title
             * @param text
             * @param options
             * @returns {*}
             */
            function successMessage<T>(title: string, text: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({}, configs.message, configs.success, options), false);
            }

            /**
             * Warning message
             *
             * @param title
             * @param text
             * @param options
             * @returns {*}
             */
            function warningMessage<T>(title: string, text: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({}, configs.message, configs.warning, options), false);
            }

            /**
             * Danger message
             *
             * @param title
             * @param text
             * @param options
             * @returns {*}
             */
            function dangerMessage<T>(title: string, text: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({}, configs.message, configs.danger, options), false);
            }

            /**
             * Input message
             *
             * @param title
             * @param text
             * @param placeholder
             * @param options
             * @returns {*}
             */
            function inputMessage<T>(title: string, text: string, placeholder: string, options?: unknown): IPromise<T> {
                return showSwal<T>(title, text, angular.extend({inputPlaceholder: placeholder}, configs.message, configs.input, options), false);
            }

            /**
             * Open SWAL
             *
             * @param title
             * @param text
             * @param params
             * @param throwRejection
             * @returns {*}
             */
            function showSwal<T>(title: string, text: string, params: unknown, throwRejection: boolean): IPromise<T> {
                const deferred = $q.defer<T>();
                const options: SweetAlertOptions = params as SweetAlertOptions;

                options.title = title;
                options.html = text;
                Swal.fire(options).then(function (result: SweetAlertResult) {
                    if (angular.isDefined(result.value)) {
                        deferred.resolve(result.value);
                    } else {
                        if (throwRejection) {
                            deferred.reject(result.dismiss);
                        } else {
                            deferred.resolve();
                        }
                    }
                });

                return deferred.promise;
            }
        }
    })(angularJS);
}
