import AModel from '@models/model.abstract';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';

export default class Bonvisite extends AModel {
    static readonly statuts = {
        ARCHIVE: 'archive',
        BROUILLON: 'brouillon',
        NEW: AModel.STATUT_NEW,
        REDACTION: 'redaction',
        SIGNATURE: 'signature',
    };
    private _colorStatut!: NoteoStatutColorType;
    private _dateVisite!: string;
    private _demandeurId!: number;
    private _documentId!: number;
    private readonly _id: number;
    private _labelStatut!: string;
    private _linkBVLocations!: string;
    private _linkBVVentes!: string;
    private _linkDemandeur!: string;
    private _linkDocument!: string;
    private _linkProcedures!: string;
    private _statut!: string;
    private _tmpLinkLocations: string[] = [];
    private _tmpLinkVentes: string[] = [];

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get colorStatut(): NoteoStatutColorType {
        return this._colorStatut;
    }

    get dateVisite(): string {
        return this._dateVisite;
    }

    set dateVisite(value: string) {
        this._dateVisite = value;
    }

    get demandeurId(): number {
        return this._demandeurId;
    }

    set demandeurId(value: number) {
        this._demandeurId = value;
    }

    get documentId(): number {
        return this._documentId;
    }

    set documentId(value: number) {
        this._documentId = value;
    }

    get id(): number {
        return this._id;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get linkBVLocations(): string {
        return this._linkBVLocations;
    }

    set linkBVLocations(value: string) {
        this._linkBVLocations = value;
    }

    get linkBVVentes(): string {
        return this._linkBVVentes;
    }

    set linkBVVentes(value: string) {
        this._linkBVVentes = value;
    }

    get linkDemandeur(): string {
        return this._linkDemandeur;
    }

    set linkDemandeur(value: string) {
        this._linkDemandeur = value;
    }

    get linkDocument(): string {
        return this._linkDocument;
    }

    set linkDocument(value: string) {
        this._linkDocument = value;
    }

    get linkProcedures(): string {
        return this._linkProcedures;
    }

    set linkProcedures(value: string) {
        this._linkProcedures = value;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
        switch (this._statut) {
            case Bonvisite.statuts.ARCHIVE :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = 'archive';
                break;
            case Bonvisite.statuts.BROUILLON :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'brouillon';
                break;
            case Bonvisite.statuts.NEW :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'nouveau';
                break;
            case Bonvisite.statuts.REDACTION :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'rédaction';
                break;
            case Bonvisite.statuts.SIGNATURE :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'signature';
                break;
            default:
                this._labelStatut = undefined!;
        }
    }

    get tmpLinkLocations(): string[] {
        return this._tmpLinkLocations;
    }

    set tmpLinkLocations(value: string[]) {
        this._tmpLinkLocations = value;
    }

    get tmpLinkVentes(): string[] {
        return this._tmpLinkVentes;
    }

    set tmpLinkVentes(value: string[]) {
        this._tmpLinkVentes = value;
    }

    isArchive(): boolean {
        return this.statut === Bonvisite.statuts.ARCHIVE;
    }

    isBrouillon(): boolean {
        return this.statut === Bonvisite.statuts.BROUILLON;
    }

    isEdit(): boolean {
        return this.isBrouillon() || this.isNew();
    }

    isNew(): boolean {
        return this.statut === Bonvisite.statuts.NEW;
    }

    isSign(): boolean {
        return this.statut === Bonvisite.statuts.SIGNATURE;
    }

    isWrite(): boolean {
        return this.statut === Bonvisite.statuts.REDACTION;
    }
}
