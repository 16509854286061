export default `
<ui-select ng-model="$ctrl.ngModel" on-select="$ctrl.options.onSelect($item, $model)" name="{{$ctrl.options.name}}"
           backspace-reset="!$ctrl.options.required" ng-required="{{$ctrl.options.required}}">
  <ui-select-match allow-clear="{{!$ctrl.options.required}}" placeholder="{{$ctrl.options.placeholder}}">
    {{$select.selected.name}}
  </ui-select-match>

  <ui-select-choices repeat="item in $ctrl.list | filter: $select.search">
    <p>{{item.name}}</p>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
