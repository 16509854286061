import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TemplateHeaderFactory} from '@models/templates/headers/header/template-header.factory';
import CTemplateHeaders from '@models/templates/headers/collection/template-headers.collection.model';
import {CTemplateHeadersFactory} from '@models/templates/headers/collection/template-headers.collection.factory';

@Injectable({providedIn: 'root'})
export class CTemplateHeadersService {
    private _cTemplateHeadersFactory = inject(CTemplateHeadersFactory);
    private _templateHeaderFactory = inject(TemplateHeaderFactory);

    getWithCustom$(): Observable<CTemplateHeaders> {
        return this._cTemplateHeadersFactory.get$().pipe(tap(cTemplateHeaders => cTemplateHeaders.results.push(this._templateHeaderFactory.getCustom())));
    }
}
