export default `
<div class="container-fluid container-fluid-title"><h1>Documents vierges</h1></div>

<div class="container-fluid container-fullw tw-bg-white">
  <div class="row">
    <div class="col-md-9 col-md-offset-1">
      <div ng-repeat="blankDocuments in $ctrl.blankDocumentsCollection" class="row">
        <div class="col-sm-12"><h2>{{blankDocuments.title | translate}}</h2></div>

        <div ng-repeat="blankDocument in blankDocuments.collection" class="col-sm-6 col-md-4 col-lg-3">
          <button type="button" class="tw-w-full"
                  ng-click="$ctrl.openBlankDocument(blankDocument.link, blankDocument.name)">
            <div class="panel panel-white">
              <div class="panel-heading border-light tw-flex tw-justify-center tw-items-center">
                <h4 class="panel-title">{{blankDocument.title | translate}}</h4>
              </div>

              <div class="panel-body tw-text-center"><fa-icon [icon]="['far', 'file']" size="4x"></fa-icon></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
