import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default class Location extends ADossierBien {
    static readonly loyerTypes = {
        HORS_TAXES: 'hors_taxes',
        TTC: 'toutes_taxes_comprises',
    };
    static readonly mandatTypes = {
        GESTION: 'gestion',
        LOCATION: 'location',
    };
    static readonly statuts = {
        ARCHIVE: ADossierBien.abstractBienStatuts.ARCHIVE,
        BROUILLON: ADossierBien.abstractBienStatuts.BROUILLON,
        DIFFUSE: ADossierBien.abstractBienStatuts.DIFFUSE,
        DISPONIBLE: ADossierBien.abstractBienStatuts.DISPONIBLE,
        NEW: ADossierBien.abstractBienStatuts.NEW,
        SUSPENDU: ADossierBien.abstractBienStatuts.SUSPENDU,
    };
    readonly dossierType = DossierBienTypesConst.LOCATION;
    private _charges!: number;
    private _chargesIncluses!: boolean;
    private _depotGarantie!: number;
    private _droitBail!: number;
    private _etatLieuxChargeLocataire!: number;
    private _etatLieuxChargeProprietaire!: number;
    private _fraisCautionnement!: string;
    private _honoNegoChargeLocataire!: number;
    private _honoNegoChargeProprietaire!: number;
    private _honoRedacChargeLocataire!: number;
    private _honoRedacChargeProprietaire!: number;
    private _loyer!: number;
    private _loyerBase!: number;
    private _loyerComplement!: number;
    private _loyerEncadrement!: boolean;
    private _loyerReferenceMajore!: number;
    private _loyerVersement!: string;
    private _meuble!: boolean;
    private _montantTotalDu!: number;
    private _montantVersementLocataire!: number;
    private _periodiciteCharges!: DictionaryItem;
    private _periodiciteLoyer!: DictionaryItem;
    private _typeLoyer!: DictionaryItem;

    get charges(): number {
        return this._charges;
    }

    set charges(value: number) {
        this._charges = value;
    }

    get chargesIncluses(): boolean {
        return this._chargesIncluses;
    }

    set chargesIncluses(value: boolean) {
        this._chargesIncluses = value;
    }

    get depotGarantie(): number {
        return this._depotGarantie;
    }

    set depotGarantie(value: number) {
        this._depotGarantie = value;
    }

    get droitBail(): number {
        return this._droitBail;
    }

    set droitBail(value: number) {
        this._droitBail = value;
    }

    get etatLieuxChargeLocataire(): number {
        return this._etatLieuxChargeLocataire;
    }

    set etatLieuxChargeLocataire(value: number) {
        this._etatLieuxChargeLocataire = value;
    }

    get etatLieuxChargeProprietaire(): number {
        return this._etatLieuxChargeProprietaire;
    }

    set etatLieuxChargeProprietaire(value: number) {
        this._etatLieuxChargeProprietaire = value;
    }

    get fraisCautionnement(): string {
        return this._fraisCautionnement;
    }

    set fraisCautionnement(value: string) {
        this._fraisCautionnement = value;
    }

    get honoNegoChargeLocataire(): number {
        return this._honoNegoChargeLocataire;
    }

    set honoNegoChargeLocataire(value: number) {
        this._honoNegoChargeLocataire = value;
    }

    get honoNegoChargeProprietaire(): number {
        return this._honoNegoChargeProprietaire;
    }

    set honoNegoChargeProprietaire(value: number) {
        this._honoNegoChargeProprietaire = value;
    }

    get honoRedacChargeLocataire(): number {
        return this._honoRedacChargeLocataire;
    }

    set honoRedacChargeLocataire(value: number) {
        this._honoRedacChargeLocataire = value;
    }

    get honoRedacChargeProprietaire(): number {
        return this._honoRedacChargeProprietaire;
    }

    set honoRedacChargeProprietaire(value: number) {
        this._honoRedacChargeProprietaire = value;
    }

    get loyer(): number {
        return this._loyer;
    }

    set loyer(value: number) {
        this._loyer = value;
    }

    get loyerBase(): number {
        return this._loyerBase;
    }

    set loyerBase(value: number) {
        this._loyerBase = value;
    }

    get loyerComplement(): number {
        return this._loyerComplement;
    }

    set loyerComplement(value: number) {
        this._loyerComplement = value;
    }

    get loyerEncadrement(): boolean {
        return this._loyerEncadrement;
    }

    set loyerEncadrement(value: boolean) {
        this._loyerEncadrement = value;
    }

    get loyerReferenceMajore(): number {
        return this._loyerReferenceMajore;
    }

    set loyerReferenceMajore(value: number) {
        this._loyerReferenceMajore = value;
    }

    get loyerVersement(): string {
        return this._loyerVersement;
    }

    set loyerVersement(value: string) {
        this._loyerVersement = value;
    }

    get meuble(): boolean {
        return this._meuble;
    }

    set meuble(value: boolean) {
        this._meuble = value;
    }

    get montantTotalDu(): number {
        return this._montantTotalDu;
    }

    set montantTotalDu(value: number) {
        this._montantTotalDu = value;
    }

    get montantVersementLocataire(): number {
        return this._montantVersementLocataire;
    }

    set montantVersementLocataire(value: number) {
        this._montantVersementLocataire = value;
    }

    get periodiciteCharges(): DictionaryItem {
        return this._periodiciteCharges;
    }

    set periodiciteCharges(value: DictionaryItem) {
        this._periodiciteCharges = value;
    }

    get periodiciteLoyer(): DictionaryItem {
        return this._periodiciteLoyer;
    }

    set periodiciteLoyer(value: DictionaryItem) {
        this._periodiciteLoyer = value;
    }

    get typeLoyer(): DictionaryItem {
        return this._typeLoyer;
    }

    set typeLoyer(value: DictionaryItem) {
        this._typeLoyer = value;
    }
}
