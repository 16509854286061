import {Component, Input} from '@angular/core';
import {IGraphesOptions} from '@shared/diagnostic/diagnostic.interfaces';
import {DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';

const graphesOptionsDefault: IGraphesOptions = {estimationCoutsVisibility: true, grapheEmissionsVisibility: true};

@Component({selector: 'app-diag-graphes-v2', templateUrl: 'diagnostic-graphes-v2.component.html'})
export class AppDiagGraphesV2Component {
    private _codeStatutDpe!: string;
    private _consommation!: number;
    private _consommationClasse!: string;
    private _coutMax!: number;
    private _coutMin!: number;
    private _dateReference!: string;
    private _emissions!: number;
    private _emissionsClasse!: string;
    private _labelStatut!: string;
    private _options = graphesOptionsDefault;

    get codeStatutDpe(): string {
        return this._codeStatutDpe;
    }

    @Input()
    set codeStatutDpe(value: string) {
        this._codeStatutDpe = value;

        if (value === DiagnosticStatutsConst.NON_SOUMIS) {
            this._labelStatut = 'non soumis';
        } else if (value === DiagnosticStatutsConst.BLANC) {
            this._labelStatut = 'vierge';
        } else if (value === DiagnosticStatutsConst.NON_COMMUNIQUE) {
            this._labelStatut = 'non communiqué';
        } else {
            this._labelStatut = undefined!;
        }
    }

    get consommation(): number {
        return this._consommation;
    }

    @Input()
    set consommation(value: number) {
        this._consommation = value;
    }

    get consommationClasse(): string {
        return this._consommationClasse;
    }

    @Input()
    set consommationClasse(value: string) {
        this._consommationClasse = value;
    }

    get coutMax(): number {
        return this._coutMax;
    }

    @Input()
    set coutMax(value: number) {
        this._coutMax = value;
    }

    get coutMin(): number {
        return this._coutMin;
    }

    @Input()
    set coutMin(value: number) {
        this._coutMin = value;
    }

    get dateReference(): string {
        return this._dateReference;
    }

    @Input()
    set dateReference(value: string) {
        this._dateReference = value;
    }

    get emissions(): number {
        return this._emissions;
    }

    @Input()
    set emissions(value: number) {
        this._emissions = value;
    }

    get emissionsClasse(): string {
        return this._emissionsClasse;
    }

    @Input()
    set emissionsClasse(value: string) {
        this._emissionsClasse = value;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get options(): IGraphesOptions {
        return this._options;
    }

    @Input()
    set options(value: IGraphesOptions) {
        this._options = {...graphesOptionsDefault, ...value};
    }

    get STATUT_DISPONIBLE(): string {
        return DiagnosticStatutsConst.DISPONIBLE;
    }
}
