import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getBienFieldsConstraints from "@legacy/app/biens/services/item/fields-constraints";
import getBiensWalletDisplayPhoto from '@legacy/app/biens/components/wallet.display.photo';
import getBiensTemplates from '@legacy/app/biens/templates';
import getBiensUtils from '@legacy/app/biens/services/utils';
import getBienDossiersWalletCurrentCtrl from '@legacy/app/biens/controllers_ponents/wallet/current';
import getBienDetailsCtrl from '@legacy/app/biens/controllers_ponents/item/details';
import getBienDetails from '@legacy/app/biens/components/item/details';
import getBienDisplayPhotos from '@legacy/app/biens/components/item/display.photos';
import getBienDossierCurrent from '@legacy/app/biens/services/item/current';
import getBienHeader from '@legacy/app/biens/components/item/header';
import getBienEditPhotosCtrl from '@legacy/app/biens/controllers_ponents/item/edit/photos';
import getBienEditPhotos from '@legacy/app/biens/components/item/edit/photos';
import getBienEditVirtualVisitCtrl from '@legacy/app/biens/controllers_ponents/item/edit/virtual_visit';
import getBienDiffusion from '@legacy/app/biens/components/item/diffusion';
import getBienModalDiffusionCtrl from '@legacy/app/biens/controllers/modals/item/diffusion';
import getBienModalShareDocumentsCtrl from '@legacy/app/biens/controllers/modals/item/share.documents';
import getBienModalAdvancedPrintCtrl from '@legacy/app/biens/controllers/modals/item/advanced-print';
import getDossiersWalletDiffusionCtrl from '@legacy/app/biens/controllers_ponents/wallet/diffusion';
import getBiensModalChoicePasserellesCtrl from '@legacy/app/biens/controllers/modals/choice.passerelles';
import getBiensModalFormatAffichettesCtrl from '@legacy/app/biens/controllers/modals/format-affichettes';
import getBiensModalFiltersWalletCurrentCtrl from '@legacy/app/biens/controllers/modals/filters.wallet.current';
import getBienPopover from '@legacy/app/biens/components/item/popover';
import getBienModalDisplayPhotosCtrl from '@legacy/app/biens/controllers/modals/item/display.photos';
import getBienModalVisibilityPhotoCtrl from '@legacy/app/biens/controllers/modals/item/visibility-photo';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';
import getDossiersWalletSearchCriteriaCtrl from '@legacy/app/biens/controllers_ponents/wallet/search/criteria';
import getDossiersBoxLast from '@legacy/app/biens/components/box/last';
import getBiensLastCriteriaCtrl from '@legacy/app/biens/controllers_ponents/last.criteria';
import getBienEditHeader from '@legacy/app/biens/components/item/edit/header';
import getDossierBiensWalletSearch from '@legacy/app/biens/components/wallet/search';

export default function getBiens(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.biens', [
                'esk.managers',
                'pascalprecht.translate',
                'soqrate',
            ])
            .config(['SoqModalesManagerProvider', modales]);

        // Config / Run
        getBiensTemplates(module);

        // Services
        getBienDossierCurrent(module);
        getBienFieldsConstraints(module);
        getBiensUtils(module);

        // Components
        getBienDetails(module);
        getBienDiffusion(module);
        getBienDisplayPhotos(module);
        getBienEditHeader(module);
        getBienEditPhotos(module);
        getBienHeader(module);
        getBienPopover(module);
        getBiensWalletDisplayPhoto(module);
        getDossierBiensWalletSearch(module);
        getDossiersBoxLast(module);
        getBienDossiersWalletCurrentCtrl(module);
        getDossiersWalletDiffusionCtrl(module);
        getDossiersWalletSearchCriteriaCtrl(module);

        // Controllers
        getBienDetailsCtrl(module);
        getBienEditPhotosCtrl(module);
        getBienEditVirtualVisitCtrl(module);
        getBiensLastCriteriaCtrl(module);
        getBienModalAdvancedPrintCtrl(module);
        getBienModalDiffusionCtrl(module);
        getBienModalDisplayPhotosCtrl(module);
        getBienModalShareDocumentsCtrl(module);
        getBienModalVisibilityPhotoCtrl(module);
        getBiensModalChoicePasserellesCtrl(module);
        getBiensModalFiltersWalletCurrentCtrl(module);
        getBiensModalFormatAffichettesCtrl(module);

        return module;

        /**
         * Declare modales
         *
         * @param SoqModalesManagerProvider
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('BiensModalFiltersWalletCurrent', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/filters.wallet.current.html',
                controller: 'BiensModalFiltersWalletCurrentCtrl',
                size: 'lg'
            });
            soqModalesManagerProvider.addModale('BiensModalFormatAffichettes', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/format-affichettes.html',
                controller: 'BiensModalFormatAffichettesCtrl',
                size: 'lg'
            });
            soqModalesManagerProvider.addModale('BiensModalItemDiffusion', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/item/diffusion.html',
                controller: 'BienModalDiffusionCtrl',
                size: 'lg'
            });
            soqModalesManagerProvider.addModale('BienModalShareDocuments', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/item/share.documents.html',
                controller: 'BienModalShareDocumentsCtrl',
                size: 'lg'
            });
            soqModalesManagerProvider.addModale('BiensModalChoicePasserelles', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/choice.passerelles.html',
                controller: 'BiensModalChoicePasserellesCtrl',
            });
            soqModalesManagerProvider.addModale('BienModalDisplayPhotos', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/item/display.photos.html',
                controller: 'BienModalDisplayPhotosCtrl',
                size: 'display-photos'
            });
            soqModalesManagerProvider.addModale('BienModalAdvancedPrint', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/item/advanced-print.html',
                controller: 'BienModalAdvancedPrintCtrl',
                size: 'md'
            });
            soqModalesManagerProvider.addModale('BienModalVisibilityPhoto', {
                templateUrl: 'src/app/legacy/templates/biens/controllers/modals/item/visibility-photo.html',
                controller: 'BienModalVisibilityPhotoCtrl',
            });
        }
    })(angularJS);
}
