@if (offreachat$ | async; as offreachat) {
  <div>
    <!-- Utile pour valider le premier formulaire de AppOffreachatEditComponent -->
    <button type="button" form="demandeurForm" class="tw-hidden" (click)="saveDemandeur()"></button>

    <!-- Utile pour valider le troisième formulaire de AppOffreachatEditComponent -->
    <button type="button" form="offreForm" class="tw-hidden" (click)="saveOffre()"></button>

    <app-offreachat-edit [offreachat]="offreachat" class="tw-block tw-mx-[-15px] disabled:tw-waiting"
                         (forms)="forms = $event" (demandeur)="demandeur = $event" (vente)="vente = $event"
                         [options]="offreachatEditOptions"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
