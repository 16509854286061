import {inject, Injectable} from '@angular/core';
import {IProcedureApi} from '@models/procedures/procedure/procedure.interfaces';
import Procedure from '@models/procedures/procedure/procedure.model';
import {
    CProcedureSignatairesFactory
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.factory';
import {Observable} from 'rxjs';
import {ProceduresApiService} from '@models/procedures/procedures.api.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProcedureFactory {
    private _cProcedureSignatairesFactory = inject(CProcedureSignatairesFactory);
    private _proceduresApiService = inject(ProceduresApiService);

    cancel$(procedure: Procedure): Observable<void> {
        return this._proceduresApiService.cancel$(procedure.uuid);
    }

    create(procedureApi: IProcedureApi): Procedure {
        const procedure = this.createVirgin(procedureApi.uuid);

        procedure.documentId = procedureApi.documentId;
        procedure.launchedAt = procedureApi.launchedAt;
        procedure.provider = procedureApi.provider;
        procedure.statut = procedureApi.status;
        procedure.updatedAt = procedureApi.updatedAt;
        if (procedureApi._links) {
            if (procedureApi._links.document) {
                procedure.linkDocument = procedureApi._links.document.href;
            }

            if (procedureApi._links.media) {
                procedure.linkMedia = procedureApi._links.media.href;
            }

            if (procedureApi._links.self) {
                procedure.linkSelf = procedureApi._links.self.href;
            }
        }

        return procedure;
    }

    createVirgin(uuid?: string): Procedure {
        const procedure = new Procedure(uuid ?? Procedure.statuts.NEW);

        procedure.cSignataires = this._cProcedureSignatairesFactory.createVirgin();

        return procedure;
    }

    forApi(procedure: Procedure): IProcedureApi {
        return {documentId: procedure.documentId, provider: procedure.provider} as IProcedureApi;
    }

    get$(procedureUuid: string): Observable<Procedure> {
        return this._proceduresApiService.get$(procedureUuid).pipe(map(procedureApi => this.create(procedureApi)));
    }

    getByLink$(link: string): Observable<Procedure> {
        return this._proceduresApiService.getByLink$(link).pipe(map(procedureApi => this.create(procedureApi)));
    }

    launch$(procedure: Procedure): Observable<void> {
        return this._proceduresApiService.launch$(procedure.uuid);
    }

    save$(procedure: Procedure): Observable<Procedure> {
        return this._proceduresApiService.save$(procedure.uuid, this.forApi(procedure)).pipe(map(procedureApi => this.create(procedureApi)));
    }
}
