import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDemandeurManager} from '@legacy/app/managers/managers';
import {NgDemandeur} from '@legacy/app/managers/ressources';

export default function getDemandeurPopover(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-demandeur-popover></esk-demandeur-popover>
         */
        module.component('eskDemandeurPopover', {
            bindings: {paramDemandeur: '=demandeur'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/components/item/popover.html'
        });

        /**
         * Controller for Demandeur Popover
         *
         * DemandeurManager
         * @constructor
         */
        Controller.$inject = ['DemandeurManager'];
        function Controller(this: any, demandeurManager: NgDemandeurManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                demandeurManager.getOneById($ctrl.paramDemandeur.id).then(demandeur => demandeur as NgDemandeur).then(demandeur => {
                    $ctrl.demandeur = demandeur;
                    angular.forEach(demandeur.recherches, recherche => recherche.getResume());
                });
            }
        }
    })(angularJS);
}
