import angularJS from '@shared/angularJS/global.ng';
import Cropper from 'cropperjs/dist/cropper.esm.js';
import {IAttributes, IController, IDirectiveFactory, IModule, Injectable, IScope} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

// @todo Améliorer la qualité du code et supprimer le code inutile
export default function getEskCropper(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-cropper></esk-cropper>
         *
         * Directive "Prototype" et exemple.
         * Cette directive n'est pas proprement configurable pour le moment.
         * L'objectif est de rendre l'ensemble de la configuration de Cropper.js
         * configurable au travers de cette directive pour réaliser une encapsulation
         * complete de Cropper.js dans Angular.
         * Documentation: https://github.com/fengyuanchen/cropperjs
         */
        module.directive('eskCropper', directive as Injectable<IDirectiveFactory>);

        /**
         * Crop image
         *
         * @returns {{bindToController: {canvas: string, onCrop: string, url: string}, controller: controller, scope: boolean, link: *, restrict: string, controllerAs: string, templateUrl: string}}
         */
        directive.$inject = [] as string[];
        function directive() {
            return {
                scope: true,
                controller: [function () {
                }],
                controllerAs: '$ctrl',
                bindToController: {
                    url: '@',
                    canvas: '=',
                    onCrop: '&?',
                },
                restrict: 'E',
                link: link,
                templateUrl: 'src/app/legacy/templates/eskimmo/directives/cropper.html'
            };

            /**
             * Link method
             *
             * @param $scope
             * @param $element
             * @param $attrs
             * @param $ctrl
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes, $ctrl: IController) {
                const isCanvas = angular.isDefined($ctrl.canvas) ? $ctrl.canvas : false;
                const image = $element.find('img')[0];
                let ratio: number;
                const config = {
                    viewMode: 1,
                    dragMode: 'none',
                    autoCropArea: 1,
                    aspectRatio: 1,
                    checkCrossOrigin: false,
                    movable: false,
                    rotatable: false,
                    zoomable: false,
                    scalable: false,
                    minCropBoxWidth: 300,
                    minCropBoxHeight: 300,
                    crop: function (event: { detail: Record<string, number> }) {
                        /**
                         * Need to calculate the new crop size for canvas system
                         */
                        if ($ctrl.canvas === true) {
                            event.detail.x = event.detail.x * ratio;
                            event.detail.y = event.detail.y * ratio;
                            event.detail.width = event.detail.width * ratio;
                            event.detail.height = event.detail.height * ratio;
                        }

                        $scope.$apply(function () {
                            if (angular.isFunction($ctrl.onCrop)) $ctrl.onCrop({details: event.detail});
                        });
                    },
                    ready: function () {
                        $scope.$apply(() => $ctrl.loading = false);
                    }
                };

                $ctrl.loading = true;
                image.onload = function (this: any) {
                    let canvas: HTMLCanvasElement;

                    if (isCanvas) {
                        const height: number = this.offsetHeight;
                        const width: number = this.offsetWidth;

                        canvas = $element.find('canvas')[0];
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d')!.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight, 0, 0, width, height);
                        ratio = this.naturalWidth / this.offsetWidth;
                        image.remove();
                    }

                    // @ts-ignore
                    new Cropper(isCanvas ? canvas : image, config);
                };
            }
        }
    })(angularJS);
}
