export default `
<div class="spaced">
  <ng-switch on="$ctrl.loader.resultGetting">
    <p ng-switch-when="true" class="padding-top-15 tw-text-center text-extra-large">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement du résultat
    </p>

    <esk-bien-details ng-switch-default back-to-list="$ctrl.backToList" replace-location="$ctrl.replaceLocation"
                      dossiers="$ctrl.search._esk.dossiersCorrespondants" loader="$ctrl.loader"
                      current-dossier-id="$ctrl.currentDossierId" working-dossier="$ctrl.demandeur"
                      set-interest="$ctrl.setInterest"></esk-bien-details>
  </ng-switch>
</div>
`;
