export default `
<ul class="list-unstyled dashboard-boxes">
  <li ng-repeat="box in $ctrl.boxes.collection" class="row">
    <h4 class="col-xs-12 col-sm-8 box-title">{{box.name}}</h4>

    <div class="col-xs-12 col-sm-4 tw-flex tw-justify-end tw-items-center tw-gap-4 tw-pt-4">
      <span>{{box.closed ? 'Invisible' : 'Affichée'}}</span>

      <app-form-switch [model]="!box.closed" (clicked)="$ctrl.boxToggleVisibility(box)"></app-form-switch>
    </div>
  </li>
</ul>
`;
