import {Component, ElementRef, Input, OnDestroy, OnInit, output, ViewChild} from '@angular/core';
import {IFormFilePickerOptions} from '@shared/form/form.interfaces';
import {merge, Observable, Subject, takeUntil} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

@Component({selector: 'app-form-file-picker', templateUrl: 'form-file-picker.component.html'})
export class AppFormFilePickerComponent implements OnDestroy, OnInit {
    static readonly initFormFilePickerOptions: IFormFilePickerOptions = {
        allowedTypes: undefined!,
        label: 'Sélectionner un fichier',
        multiple: true,
        withIcon: false,
    };
    readonly selected = output<File>();
    private _filePickerElementRef!: ElementRef<HTMLInputElement>;
    private readonly _onDestroy$ = new Subject<void>();
    private _openAskedSource = new Subject<void>();
    private _openClickedSource = new Subject<void>();
    private _options: IFormFilePickerOptions = {...AppFormFilePickerComponent.initFormFilePickerOptions};

    @ViewChild('filePicker')
    set filePicker(value: ElementRef<HTMLInputElement>) {
        this._filePickerElementRef = value;
    }

    @Input()
    set openFilePicker$(open$: Observable<void>) {
        open$.pipe(takeUntil(this._onDestroy$)).subscribe(() => this._openAskedSource.next());
    }

    get options(): IFormFilePickerOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormFilePickerOptions) {
        this._options = {...AppFormFilePickerComponent.initFormFilePickerOptions, ...value};
    }

    ngOnInit(): void {
        merge(this._openAskedSource.asObservable(), this._openClickedSource.asObservable().pipe(debounceTime(300))).pipe(
            filter(_ => !!this._filePickerElementRef?.nativeElement),
            takeUntil(this._onDestroy$)
        ).subscribe(_ => this._filePickerElementRef.nativeElement.click());
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    onClick(): void {
        this._openClickedSource.next();
    }

    onSelect(event: Event): void {
        const fileList: FileList = (event.target as HTMLInputElement).files!;

        for (let idx = 0; idx < fileList.length; idx++) {
            this.selected.emit(fileList.item(idx)!);
        }

        this._filePickerElementRef.nativeElement.value = null!;
    }
}
