import {inject, Injectable} from '@angular/core';
import BonvisiteLocation from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.model';
import {IBonvisiteLocationApi} from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.interfaces';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BonvisiteLocationsApiService} from '@models/bonvisites/bonvisite/locations/bonvisite-locations.api.service';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Location from '@models/locations/location/location.model';

@Injectable({providedIn: 'root'})
export class BonvisiteLocationFactory {
    private _bonvisiteLocationsApiService = inject(BonvisiteLocationsApiService);

    create(bonvisiteLocationApi: IBonvisiteLocationApi): BonvisiteLocation {
        const bonvisiteLocation = this.createVirgin(bonvisiteLocationApi.locationUuid);

        if (bonvisiteLocationApi._links?.location) {
            bonvisiteLocation.linkLocation = bonvisiteLocationApi._links.location.href;
        }

        return bonvisiteLocation;
    }

    createFromLocation(location: Location): BonvisiteLocation {
        const bonvisiteLocation = this.createVirgin(location.uuid);

        bonvisiteLocation.linkLocation = location.linkSelf;

        return bonvisiteLocation;
    }

    createVirgin(uuid: string): BonvisiteLocation {
        return new BonvisiteLocation(uuid);
    }

    delete$(bonvisite: Bonvisite, bonvisiteLocation: BonvisiteLocation): Observable<void> {
        return this._bonvisiteLocationsApiService.delete$(bonvisite.uuid, bonvisiteLocation.uuid);
    }

    forApi(bonvisite: BonvisiteLocation): IBonvisiteLocationApi {
        return {locationUuid: bonvisite.uuid} as IBonvisiteLocationApi;
    }

    save$(bonvisite: Bonvisite, bonvisiteLocation: BonvisiteLocation): Observable<BonvisiteLocation> {
        return this._bonvisiteLocationsApiService.save$(bonvisite.uuid, this.forApi(bonvisiteLocation)).pipe(map(bonvisiteLocationApi => this.create(bonvisiteLocationApi)));
    }
}
