import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICArrondissementsApi, ICArrondissementsQueryParameters
} from '@models/arrondissements/collection/arrondissements.collection.interfaces';

@Injectable({providedIn: 'root'})
export class ArrondissementsApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(queryParameters?: ICArrondissementsQueryParameters): Observable<ICArrondissementsApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/arrondissements', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICArrondissementsApi> {
        return this._modelApiService.getCollection$(link);
    }
}
