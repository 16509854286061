import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Location from '@models/locations/location/location.model';
import BonvisiteLocation from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.model';
import {BonvisiteLocationFactory} from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.factory';
import {
    CBonvisiteLocationsFactory
} from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.factory';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BonvisiteLocationsService {
    private _bonvisiteLocationFactory = inject(BonvisiteLocationFactory);
    private _cBonvisiteLocationsFactory = inject(CBonvisiteLocationsFactory);

    delete$(bonvisite: Bonvisite, bonvisiteLocations: BonvisiteLocation[] = []): Observable<void> {
        if (bonvisiteLocations.length <= 0) {
            return of(undefined);
        }

        return combineLatest(bonvisiteLocations.map(bonvisiteLocation => this._bonvisiteLocationFactory.delete$(bonvisite, bonvisiteLocation))).pipe(map(_ => undefined));
    }

    persist$(bonvisite: Bonvisite, locations: Location[] = []): Observable<BonvisiteLocation[]> {
        let locationsToSave$ = of(undefined) as Observable<unknown>;

        if (bonvisite.linkBVLocations) {
            locationsToSave$ = this._cBonvisiteLocationsFactory.getByLink$(bonvisite.linkBVLocations).pipe(
                switchMap(cBonvisiteLocations => this.delete$(bonvisite, cBonvisiteLocations.results)),
            );
        }

        return locationsToSave$.pipe(switchMap(_ => this.save$(bonvisite, locations)));
    }

    save$(bonvisite: Bonvisite, locations: Location[] = []): Observable<BonvisiteLocation[]> {
        return locations.length > 0 ? combineLatest(locations.map(location => this._bonvisiteLocationFactory.save$(bonvisite, this._bonvisiteLocationFactory.createFromLocation(location)))) : of([]);
    }
}
