import {inject, Injectable} from '@angular/core';
import {NgBienPerformanceEnergetique} from '@legacy/app/managers/ressources';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import {
    IBienPerformanceEnergetiqueApi
} from '@models/bien/performance-energetique/bien-performance-energetique.interfaces';
import FormService from '@shared/form/form.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';

@Injectable({providedIn: 'root'})
export class BienPerformanceEnergetiqueFactory {
    private _dictionaryItemService = inject(DictionaryItemService);

    create(bienPerformanceEnergetiqueApi: IBienPerformanceEnergetiqueApi): BienPerformanceEnergetique {
        const bienPerformanceEnergetique = new BienPerformanceEnergetique();

        bienPerformanceEnergetique.comments = bienPerformanceEnergetiqueApi.comments;
        bienPerformanceEnergetique.consommation = bienPerformanceEnergetiqueApi.consommation;
        bienPerformanceEnergetique.coutMax = bienPerformanceEnergetiqueApi.coutMax;
        bienPerformanceEnergetique.coutMin = bienPerformanceEnergetiqueApi.coutMin;
        bienPerformanceEnergetique.date = bienPerformanceEnergetiqueApi.date;
        bienPerformanceEnergetique.emissions = bienPerformanceEnergetiqueApi.emissions;
        bienPerformanceEnergetique.statutDpe = this._dictionaryItemService.getByCode(Dictionary.names.DIAGNOSTIC_STATUTS, bienPerformanceEnergetiqueApi.statutDpe || DiagnosticStatutsConst.DISPONIBLE);

        if (bienPerformanceEnergetiqueApi.consommationClasse) {
            bienPerformanceEnergetique.consommationClasse =
                this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, bienPerformanceEnergetiqueApi.consommationClasse);
        }

        if (bienPerformanceEnergetiqueApi.dateReference) {
            bienPerformanceEnergetique.dateReference = bienPerformanceEnergetiqueApi.dateReference;
        }

        if (bienPerformanceEnergetiqueApi.emissionsClasse) {
            bienPerformanceEnergetique.emissionsClasse =
                this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, bienPerformanceEnergetiqueApi.emissionsClasse);
        }

        return bienPerformanceEnergetique;
    }

    forApi(bienPerformanceEnergetique: BienPerformanceEnergetique): IBienPerformanceEnergetiqueApi {
        const bienPerformanceEnergetiqueApi = {
            statutDpe: bienPerformanceEnergetique.statutDpe?.code,
        } as IBienPerformanceEnergetiqueApi;

        bienPerformanceEnergetiqueApi.comments = bienPerformanceEnergetique.comments;
        bienPerformanceEnergetiqueApi.date = bienPerformanceEnergetique.date;
        if (bienPerformanceEnergetique.isDisponible()) {
            bienPerformanceEnergetiqueApi.consommation = bienPerformanceEnergetique.consommation;
            bienPerformanceEnergetiqueApi.coutMax = bienPerformanceEnergetique.coutMax;
            bienPerformanceEnergetiqueApi.coutMin = bienPerformanceEnergetique.coutMin;
            bienPerformanceEnergetiqueApi.dateReference = bienPerformanceEnergetique.dateReference;
            bienPerformanceEnergetiqueApi.emissions = bienPerformanceEnergetique.emissions;
            if (bienPerformanceEnergetique.consommationClasse) {
                bienPerformanceEnergetiqueApi.consommationClasse = bienPerformanceEnergetique.consommationClasse.code;
            }

            if (bienPerformanceEnergetique.emissionsClasse) {
                bienPerformanceEnergetiqueApi.emissionsClasse = bienPerformanceEnergetique.emissionsClasse.code;
            }
        }

        return bienPerformanceEnergetiqueApi;
    }

    ngCreate(ngBienPerformanceEnergetique: NgBienPerformanceEnergetique): BienPerformanceEnergetique {
        const bienPerformanceEnergetique = new BienPerformanceEnergetique();

        bienPerformanceEnergetique.comments = ngBienPerformanceEnergetique.comments;
        bienPerformanceEnergetique.consommation = FormService.parseNumber(ngBienPerformanceEnergetique.consommation);
        bienPerformanceEnergetique.coutMax = ngBienPerformanceEnergetique.coutMax;
        bienPerformanceEnergetique.coutMin = ngBienPerformanceEnergetique.coutMin;
        bienPerformanceEnergetique.date = ngBienPerformanceEnergetique.date;
        bienPerformanceEnergetique.emissions = FormService.parseNumber(ngBienPerformanceEnergetique.emissions);
        bienPerformanceEnergetique.statutDpe = this._dictionaryItemService.getByCode(Dictionary.names.DIAGNOSTIC_STATUTS, ngBienPerformanceEnergetique.statutDpe || DiagnosticStatutsConst.DISPONIBLE);

        if (ngBienPerformanceEnergetique.consommationClasse) {
            bienPerformanceEnergetique.consommationClasse =
                this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, ngBienPerformanceEnergetique.consommationClasse);
        }

        if (ngBienPerformanceEnergetique.dateReference) {
            bienPerformanceEnergetique.dateReference = ngBienPerformanceEnergetique.dateReference;
        }

        if (ngBienPerformanceEnergetique.emissionsClasse) {
            bienPerformanceEnergetique.emissionsClasse =
                this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, ngBienPerformanceEnergetique.emissionsClasse);
        }

        return bienPerformanceEnergetique;
    }
}
