import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import {NgBienDossier, NgBienLogement} from '@legacy/app/managers/ressources';
import {from} from 'rxjs';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({selector: 'bien-edit-logement', templateUrl: 'bien.edit-logement.component.html'})
export class DCBienEditLogementComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _ngBienDossier!: NgBienDossier;
    private _ngBienLogement!: NgBienLogement;

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._ngBienDossier = dynamicComponentData.ngBienDossier as NgBienDossier;
        this._ngBienLogement = dynamicComponentData.ngBienLogement as NgBienLogement;
    }

    get DICTIONARY_NAME_ETAGES(): string {
        return Dictionary.names.ETAGES;
    }

    get DICTIONARY_NAME_LOGEMENTS(): string {
        return Dictionary.names.LOGEMENTS;
    }

    get ngBienLogement(): NgBienLogement {
        return this._ngBienLogement;
    }

    onSelectEtage(etage: DictionarySelected): void {
        if (etage && !Array.isArray(etage)) {
            this._ngBienLogement.etage = this.ngDictionariesManager.createFromNg2(etage);
        } else {
            this._ngBienLogement.etage = undefined!;
        }
    }

    onSelectLogement(logementType: DictionarySelected): void {
        if (logementType && !Array.isArray(logementType)) {
            this._ngBienLogement.logement = this.ngDictionariesManager.createFromNg2(logementType);
        } else {
            this._ngBienLogement.logement = undefined!;
        }
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        from(this._ngBienDossier.bien.saveItem('logements', this._ngBienLogement, true))
            .pipe(take(1)).subscribe(_ => this._modalService.setResponse(this._ngBienLogement));
    }
}
