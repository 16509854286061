import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewAgencementInterieurService
} from '@features/dossiers/dossier/bien/overview/agencement-interieur/dossier-bien-overview.agencement-interieur.service';
import {
    DossierBienOverviewBatimentService
} from '@features/dossiers/dossier/bien/overview/batiment/dossier-bien-overview.batiment.service';
import {
    DossierBienOverviewPerformanceEnergetiqueService
} from '@features/dossiers/dossier/bien/overview/performance-energetique/dossier-bien-overview.performance-energetique.service';
import {
    DossierBienOverviewTerrainService
} from '@features/dossiers/dossier/bien/overview/terrain/dossier-bien-overview.terrain.service';
import {
    DossierBienOverviewAccessibiliteEnvironnementService
} from '@features/dossiers/dossier/bien/overview/accessibilite-environnement/dossier-bien-overview.accessibilite-environnement.service';
import {
    DossierBienOverviewAgencementExterieurService
} from '@features/dossiers/dossier/bien/overview/agencement-exterieur/dossier-bien-overview.agencement-exterieur.service';
import {
    DossierBienOverviewViabilisationService
} from '@features/dossiers/dossier/bien/overview/viabilisation/dossier-bien-overview.viabilisation.service';
import {
    DossierBienOverviewDiagnosticsService
} from '@features/dossiers/dossier/bien/overview/diagnostics/dossier-bien-overview.diagnostics.service';
import {
    DossierBienOverviewLoiAlurService
} from '@features/dossiers/dossier/bien/overview/loi-alur/dossier-bien-overview.loi-alur.service';
import {
    DossierBienOverviewHabitationService
} from '@features/dossiers/dossier/bien/overview/habitation/dossier-bien-overview.habitation.service';
import {
    DossierBienOverviewCoutsService
} from '@features/dossiers/dossier/bien/overview/couts/dossier-bien-overview.couts.service';
import {
    DossierBienOverviewUrbanismeService
} from '@features/dossiers/dossier/bien/overview/urbanisme/dossier-bien-overview.urbanisme.service';
import {
    DossierBienOverviewRisquesService
} from '@features/dossiers/dossier/bien/overview/risques/dossier-bien-overview.risques.service';

@Component({selector: 'app-dossier-bien-overview-infos', templateUrl: 'dossier-bien-overview.infos.component.html'})
export class AppDossierBienOverviewInfosComponent {
    private _dossierBienOverviewAccessibiliteEnvironnementService = inject(DossierBienOverviewAccessibiliteEnvironnementService);
    private _dossierBienOverviewAgencementExterieurService = inject(DossierBienOverviewAgencementExterieurService);
    private _dossierBienOverviewAgencementInterieurService = inject(DossierBienOverviewAgencementInterieurService);
    private _dossierBienOverviewBatimentService = inject(DossierBienOverviewBatimentService);
    private _dossierBienOverviewCoutsService = inject(DossierBienOverviewCoutsService);
    private _dossierBienOverviewDiagnosticsService = inject(DossierBienOverviewDiagnosticsService);
    private _dossierBienOverviewHabitationService = inject(DossierBienOverviewHabitationService);
    private _dossierBienOverviewLoiAlurService = inject(DossierBienOverviewLoiAlurService);
    private _dossierBienOverviewPerformanceEnergetiqueService = inject(DossierBienOverviewPerformanceEnergetiqueService);
    private _dossierBienOverviewRisquesService = inject(DossierBienOverviewRisquesService);
    private _dossierBienOverviewTerrainService = inject(DossierBienOverviewTerrainService);
    private _dossierBienOverviewUrbanismeService = inject(DossierBienOverviewUrbanismeService);
    private _dossierBienOverviewViabilisationService = inject(DossierBienOverviewViabilisationService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isPartAccessibiliteEnvironnementVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isPartVisible;
    }

    get isPartAgencementExterieurVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isPartVisible;
    }

    get isPartAgencementInterieurVisible(): boolean {
        return this._dossierBienOverviewAgencementInterieurService.isPartVisible;
    }

    get isPartBatimentVisible(): boolean {
        return this._dossierBienOverviewBatimentService.isPartVisible;
    }

    get isPartCoutsVisible(): boolean {
        return this._dossierBienOverviewCoutsService.isPartVisible;
    }

    get isPartDiagnosticsVisible(): boolean {
        return this._dossierBienOverviewDiagnosticsService.isPartVisible;
    }

    get isPartHabitationVisible(): boolean {
        return this._dossierBienOverviewHabitationService.isPartVisible;
    }

    get isPartLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isPartVisible;
    }

    get isPartPerformanceEnergetiqueVisible(): boolean {
        return this._dossierBienOverviewPerformanceEnergetiqueService.isPartVisible;
    }

    get isPartRisquesVisible(): boolean {
        return this._dossierBienOverviewRisquesService.isPartVisible;
    }

    get isPartTerrainVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isPartVisible;
    }

    get isPartUrbanismeVisible(): boolean {
        return this._dossierBienOverviewUrbanismeService.isPartVisible;
    }

    get isPartViabilisationVisible(): boolean {
        return this._dossierBienOverviewViabilisationService.isPartVisible;
    }
}
