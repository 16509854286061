import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienBienFieldsConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Bien from '@models/bien/bien.model';
import {LocationFieldsConst} from '@models/locations/locations.constants';
import {VenteFieldsConst} from '@models/ventes/ventes.constants';
import {EstimationFieldsConst} from '@models/estimations/estimations.constants';

@Injectable({providedIn: 'root'})
export class DossierBienFieldsService {
    static EXCLUDE_FIELDS: Record<string, string[]> = {
        [DossierBienBienFieldsConst.accesHandicape]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.alarmeLocation]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.alarmePleinePropriete]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.anneeConstruction]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.annexe]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.ascenseur]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.aucunChauffage]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.balcon]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.causeVente]: [DossierBienTypesConst.ESTIMATION, DossierBienTypesConst.LOCATION],
        [DossierBienBienFieldsConst.cave]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.chambreRdc]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.chargesLocatives]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            DossierBienTypesConst.LOCATION,
        ],
        [DossierBienBienFieldsConst.charpentes]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.chauffageCollectif]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.chauffages]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.cheminee]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.chemineeFonctionnelle]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.chemineeOrnement]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.citerneGazLocation]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.citerneGazPleinePropriete]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.clotures]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.combles]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.commentsAgencementInterieur]: [
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.commentsBatiment]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.commentsConfidentiel]: [DossierBienTypesConst.ESTIMATION],
        [DossierBienBienFieldsConst.commentsConfreres]: [
            DossierBienTypesConst.ESTIMATION,
            DossierBienTypesConst.LOCATION,
        ],
        [DossierBienBienFieldsConst.connexionsInternet]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.cour]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.cuisineEquipee]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.cuveFuelAerienne]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.cuveFuelEnterree]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.cuveInactiveDegazee]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.cuveInactiveNeutralizee]: [Bien.natures.APPARTEMENT],
        [DossierBienBienFieldsConst.dateDisponibilite]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            DossierBienTypesConst.LOCATION,
        ],
        [DossierBienBienFieldsConst.declivite]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
        ],
        [DossierBienBienFieldsConst.degreDeclivite]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
        ],
        [DossierBienBienFieldsConst.dernierEtage]: [
            Bien.natures.MAISON,
            Bien.natures.IMMEUBLE,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.designation]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.detecteurFumee]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.diagnosticsRealises]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.epoqueConstruction]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.etat]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.exceptionnel]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.garage]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.grenier]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.hauteurSousPlafond]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.huisseries]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.isolations]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.logements]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.longueurFacade]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.loyerActuel]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            DossierBienTypesConst.LOCATION,
        ],
        [DossierBienBienFieldsConst.materiauxConstruction]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.materiauxCouverture]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [LocationFieldsConst.meuble]: [DossierBienTypesConst.ESTIMATION, DossierBienTypesConst.VENTE],
        [DossierBienBienFieldsConst.mitoyennete]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [EstimationFieldsConst.motif]: [DossierBienTypesConst.VENTE, DossierBienTypesConst.LOCATION],
        [DossierBienBienFieldsConst.nombreBains]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreChambres]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreCheminees]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreDouches]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreEtages]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreEtagesBatiment]: [
            Bien.natures.MAISON,
            Bien.natures.IMMEUBLE,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreLogements]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.nombrePieces]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreSallesBains]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreSallesEau]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.nombreWc]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.numeroCle]: [DossierBienTypesConst.ESTIMATION],
        [DossierBienBienFieldsConst.numeroEtage]: [
            Bien.natures.MAISON,
            Bien.natures.IMMEUBLE,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.occupation]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
            DossierBienTypesConst.LOCATION,
        ],
        [DossierBienBienFieldsConst.panneaux]: [DossierBienTypesConst.ESTIMATION],
        [DossierBienBienFieldsConst.performanceEnergetique]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.pieceEauRdc]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.piscine]: [
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.plainPied]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.raccordements]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [VenteFieldsConst.referencePublique]: [DossierBienTypesConst.ESTIMATION, DossierBienTypesConst.LOCATION],
        [DossierBienBienFieldsConst.residence]: [
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.residenceService]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.sousSol]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.surfaceAnnexe]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfaceBalcon]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfaceBatie]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.BUREAUX,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfaceCarrez]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.surfaceCave]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.surfaceCour]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfaceGarage]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.surfaceNonBatie]: [
            Bien.natures.APPARTEMENT,
            Bien.natures.BUREAUX,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfacePlancher]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.surfaces]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfaceSejour]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.surfaceTerrasse]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.terrasse]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.type]: [DossierBienTypesConst.LOCATION],
        [DossierBienBienFieldsConst.usage]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
        [DossierBienBienFieldsConst.veranda]: [
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.viabilisable]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationComments]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationEau]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationElectricite]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationFibre]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationGaz]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationTelecom]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.viabilisationVoirie]: [
            Bien.natures.MAISON,
            Bien.natures.APPARTEMENT,
            Bien.natures.IMMEUBLE,
            Bien.natures.BUREAUX,
            Bien.natures.LOCAUX_COMMERCIAUX,
            Bien.natures.GARAGE,
            Bien.natures.AUTRE,
        ],
        [DossierBienBienFieldsConst.volets]: [
            Bien.natures.GARAGE,
            Bien.natures.TERRAIN_A_BATIR,
            Bien.natures.AUTRE_TERRAIN,
        ],
        [DossierBienBienFieldsConst.wcBroyeur]: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
    };

    isEditionVisible(dossierBien: ADossierBien, field: string): boolean {
        if (!dossierBien?.bien?.nature || !field || !DossierBienFieldsService.EXCLUDE_FIELDS[field]) {
            return true;
        }

        return !DossierBienFieldsService.EXCLUDE_FIELDS[field].includes(dossierBien.bien.nature.code)
            && !DossierBienFieldsService.EXCLUDE_FIELDS[field].includes(dossierBien.dossierType);
    }

    isResumeVisible(dossierBien: ADossierBien, field: string): boolean {
        if (!this.isEditionVisible(dossierBien, field)) {
            return false;
        }

        const data: unknown = dossierBien[field] ?? dossierBien.bien[field];

        if (typeof data === 'object') {
            return Array.isArray(data) ? data.length > 0 : !!data;
        } else if (typeof data === 'number') {
            return data > 0;
        } else if (typeof data === 'string') {
            return data !== '';
        } else if (typeof data === 'boolean') {
            return data;
        }

        return !!data;
    }
}
