import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IEtudeApi} from '@models/etudes/etude/etude.interfaces';
import {ModelApiService} from '@models/model.api.service';
import {ICEtudesApi, ICEtudesQueryParameters} from '@models/etudes/collection/etudes.collection.interfaces';

@Injectable({providedIn: 'root'})
export class EtudesApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IEtudeApi> {
        return this.getByLink$('/etudes/' + uuid);
    }

    getByLink$(link: string): Observable<IEtudeApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICEtudesQueryParameters): Observable<ICEtudesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etudes', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICEtudesApi> {
        return this._modelApiService.getCollection$(link);
    }

    getCurrent$(): Observable<IEtudeApi> {
        return this.getByLink$('/etude');
    }
}
