export default `
<ui-select ng-model="$ctrl.ngModel" on-select="$ctrl.onSelect($select.selected)" name="{{$ctrl.options.name}}"
           ng-required="$ctrl.options.required" backspace-reset="!$ctrl.options.required" spinner-enabled="true"
           class="dossier-selection">
  <ui-select-match placeholder="{{$ctrl.options.placeholder}}" allow-clear="{{!$ctrl.options.required}}">
    <div ng-if="$select.selected._esk.typeDossier === $ctrl.DossierTypesConst.DEMANDEUR" class="dossier-selected">
      <span>{{$select.selected.contactsGroup.nom}}</span>

      <span class="text-muted text-right">{{$select.selected.reference}}</span>
    </div>

    <div
      ng-if="$select.selected._esk.typeDossier === $ctrl.DossierTypesConst.VENTE || $select.selected._esk.typeDossier === $ctrl.DossierTypesConst.LOCATION || $select.selected._esk.typeDossier === $ctrl.DossierTypesConst.ESTIMATION"
      class="dossier-selected">
      <span>{{$select.selected.bien._esk.interne ? $select.selected.bien.contactsGroup.nom : $select.selected.bien.reference}}</span>

      <span
        class="text-muted text-right">{{$select.selected.bien.option.commune.fullname}} {{$select.selected.bien.option.nomVoie}}</span>
    </div>
  </ui-select-match>

  <ui-select-choices repeat="dossier in $ctrl.dossiers" group-by="$ctrl.groupDossiers" minimum-input-length="2"
                     ui-disable-choice="$ctrl.disabled(dossier)" refresh="$ctrl.getRefreshDossiers($select.search)"
                     refresh-delay="500">
    <p ng-if="dossier._esk.typeDossier === $ctrl.DossierTypesConst.DEMANDEUR">
      {{dossier.contactsGroup.nom}}<br>

      <span class="text-muted">{{dossier.reference}}</span>
    </p>

    <p
      ng-if="dossier._esk.typeDossier === $ctrl.DossierTypesConst.VENTE || dossier._esk.typeDossier === $ctrl.DossierTypesConst.LOCATION || dossier._esk.typeDossier === $ctrl.DossierTypesConst.ESTIMATION">
      {{dossier.bien._esk.interne ? dossier.bien.contactsGroup.nom : dossier.bien.reference}}<br>

      <span class="text-muted">{{dossier.bien.option.commune.fullname}} {{dossier.bien.option.nomVoie}}</span>
    </p>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
