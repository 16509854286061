import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {NgItemDictionary, NgVente} from '@legacy/app/managers/ressources';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import Dictionary from '@core/models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {ConditionConst} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import {take} from 'rxjs/operators';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Vente from '@models/ventes/vente/vente.model';

export default function getVenteModalCompromisCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.controller('VenteModalCompromisCtrl', Controller);

        /**
         * Controller to manage compromis
         *
         * @param vente
         * @param $uibModalInstance
         * @param DictionariesManager
         * @param Ng2VenteFactory
         * @param Ng2VenteCompromisFactory
         * @param Ng2DictionaryItemFactory
         * @param Ng2EtudeService
         * @param Ng2DictionaryItemService
         * @param Ng2VentePriceFactory
         */
        Controller.$inject = ['vente', '$uibModalInstance', 'DictionariesManager', 'Ng2VenteFactory', 'Ng2VenteCompromisFactory', 'Ng2DictionaryItemFactory', 'Ng2EtudeService', 'Ng2DictionaryItemService', 'Ng2VentePriceFactory'];
        function Controller(this: any,
                            vente: NgVente,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            dictionariesManager: NgDictionariesManager,
                            ng2VenteFactory: VenteFactory,
                            ng2VenteCompromisFactory: VenteCompromisFactory,
                            ng2DictionaryItemFactory: DictionaryItemFactory,
                            ng2EtudeService: EtudeService,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2VentePriceFactory: VentePriceFactory) {
            const $ctrl = this;

            $ctrl.updateDateFin = updateDateFin;
            $ctrl.updateDateVente = updateDateVente;
            $ctrl.selectNegociation = selectNegociation;
            $ctrl.operatePricing = operatePricing;
            $ctrl.getArchivedDemandeurs = getArchivedDemandeurs;
            $ctrl.addCompromis = addCompromis;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES = Vente.archiveNegocieeRaisons.AUTRES;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE = Vente.archiveNegocieeRaisons.PORTEFEUILLE;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU = Vente.archiveNegocieeRaisons.RESEAU;
            $ctrl.ngVente = ng2VenteFactory.ngCreate(vente);
            $ctrl.compromis = ng2VenteCompromisFactory.createFromVente($ctrl.ngVente);
            $ctrl.compromisDate = DateFormat.toDate($ctrl.compromis.date);
            $ctrl.compromisDateFin = DateFormat.toDate($ctrl.compromis.dateFin);
            $ctrl.compromisDateVente = DateFormat.toDate($ctrl.compromis.dateVente);
            ng2EtudeService.last$.subscribe(currentEtude => {
                $ctrl.options = {
                    dateFin: {},
                    solo: ng2DictionaryItemFactory.ngCreate(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, Vente.archiveNegocieeRaisons.PORTEFEUILLE))),
                    duo: currentEtude.hasNetwork() ? ng2DictionaryItemFactory.ngCreate(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, Vente.archiveNegocieeRaisons.RESEAU))) : undefined,
                    agence: ng2DictionaryItemFactory.ngCreate(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, Vente.archiveNonNegocieeRaisons.AGENCE))),
                    pap: ng2DictionaryItemFactory.ngCreate(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, Vente.archiveNonNegocieeRaisons.PAP))),
                    dateVente: {},
                    demandeurs: {
                        name: 'archiveDemandeurId',
                        placeholder: 'Trouver le demandeur',
                        conditions: {},
                        searchIsItem: true
                    }
                };
                $ctrl.updateDateFin();
            });

            /**
             * Update date fin
             */
            function updateDateFin() {
                $ctrl.compromis.date = DateFormat.toJSON($ctrl.compromisDate);
                $ctrl.compromisDateFin = DateFormat.toDate($ctrl.compromis.dateFin);
                $ctrl.options.dateFin = {minDate: $ctrl.compromisDate};
                $ctrl.updateDateVente();
            }

            /**
             * Update date vente
             */
            function updateDateVente() {
                $ctrl.compromis.dateFin = DateFormat.toJSON($ctrl.compromisDateFin);
                $ctrl.compromisDateVente = DateFormat.toDate($ctrl.compromis.dateVente);
                $ctrl.options.dateVente = {minDate: $ctrl.compromisDateFin};
            }

            /**
             * Select negociation
             *
             * @param negocie
             * @param raison
             */
            function selectNegociation(negocie: boolean, raison: NgItemDictionary) {
                $ctrl.options.negociationResume = {
                    codeType: raison.code,
                    shortLabelType: raison.shortLabel!.toUpperCase(),
                    date: DateFormat.toJSON($ctrl.compromisDate, 'DD/MM/YYYY'),
                    dateFin: DateFormat.toJSON($ctrl.compromisDateFin, 'DD/MM/YYYY'),
                };
                $ctrl.compromis.negocie = negocie;
                if ($ctrl.compromis.negocie) {
                    $ctrl.compromis.raisonNegocie = raison;
                } else {
                    $ctrl.compromis.raisonNonNegocie = raison;
                }
            }

            /**
             * Operate pricing
             */
            function operatePricing() {
                if (vente.type.code !== Vente.types.SIMPLE) {
                    // @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/97 : A supprimer
                    return;
                }

                if (!angular.isNumber($ctrl.compromis.prixCession) || $ctrl.compromis.prixCession < 0) {
                    return;
                }

                if ($ctrl.compromis.prixCession > VentePriceFactory.MAX_PRICE) {
                    $ctrl.compromis.prixCession = VentePriceFactory.MAX_PRICE;
                }

                ng2VentePriceFactory.getFromVenteCompromis$($ctrl.ngVente, ng2VenteCompromisFactory.ngCreate($ctrl.compromis))
                    .pipe(take(1))
                    .subscribe(ventePrice => {
                        // Cas où la réponse est arrivée après une nouvelle modification
                        if (vente.type.code === Vente.types.SIMPLE && $ctrl.compromis.prixCession !== ventePrice.priceNV) {
                            return;
                        }

                        $ctrl.compromis.honorairesNego = ventePrice.honoraires;
                    });
            }

            /**
             * Get or not archived demandeurs
             */
            function getArchivedDemandeurs() {
                const params = angular.copy($ctrl.options.demandeurs);

                params.conditions.statuts = $ctrl.archivedDemandeurs ? undefined : {
                    condition: ConditionConst.NOT_IN,
                    values: [Demandeur.statuts.ARCHIVE],
                };
                $ctrl.options.demandeurs = angular.copy(params);
            }

            /**
             * Add compromis
             */
            function addCompromis() {
                $ctrl.options.compromisAdding = true;
                $ctrl.compromis.dateVente = DateFormat.toJSON($ctrl.compromisDateVente);
                if ($ctrl.demandeur) {
                    if ($ctrl.demandeur.id > 0) {
                        $ctrl.compromis.demandeurId = $ctrl.demandeur.id;
                        $ctrl.compromis.linkDemandeur = $ctrl.demandeur._links.self.href;
                    } else {
                        $ctrl.compromis.acquereur = $ctrl.demandeur.contactsGroup.nom;
                    }
                }

                if ($ctrl.etudeAcquereur) {
                    $ctrl.compromis.etudeAcquereurId = $ctrl.etudeAcquereur.id;
                    $ctrl.compromis.linkEtudeAcquereur = $ctrl.etudeAcquereur.linkSelf;
                }

                vente.addCompromis($ctrl.compromis).then(() => $uibModalInstance.close()).finally(() => $ctrl.options.compromisAdding = false);
            }
        }
    })(angularJS);
}
