import {inject, Injectable} from '@angular/core';
import {MediasApiService} from '@models/medias/medias.api.service';
import Media from '@models/medias/media/media.model';
import {IMediaApi} from '@models/medias/media/media.interfaces';
import Document from '@models/documents/document/document.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';
import {NgMedia} from '@legacy/app/managers/ressources';
import Report from '@models/reports/report/report.model';
import LocationMandat from '@models/locations/location/mandats/mandat/location-mandat.model';
import Location from '@models/locations/location/location.model';
import Transfer from '@models/transfers/transfer/transfer.model';
import {TransferFactory} from '@models/transfers/transfer/transfer.factory';

@Injectable({providedIn: 'root'})
export class MediaFactory {
    private _mediasApiService = inject(MediasApiService);
    private _transferFactory = inject(TransferFactory);

    copy(media: Media): Media {
        const copiedMedia = this.createVirgin(media.uuid, media.id);

        copiedMedia.comments = media.comments;
        copiedMedia.createdAt = media.createdAt;
        copiedMedia.linkDownload = media.linkDownload;
        copiedMedia.mediaType = media.mediaType;
        copiedMedia.title = media.title;
        copiedMedia.totalBytes = media.totalBytes;

        return copiedMedia;
    }

    create(mediaApi: IMediaApi): Media {
        const media = this.createVirgin(mediaApi.id.toString(), mediaApi.id);

        media.comments = mediaApi.comments;
        media.createdAt = mediaApi.createdAt;
        media.mediaType = mediaApi.mediaType;
        media.title = mediaApi.title;
        media.totalBytes = mediaApi.totalBytes;
        if (mediaApi._links?.download) {
            media.linkDownload = mediaApi._links.download.href;
        }

        return media;
    }

    createVirgin(uuid?: string, id?: number): Media {
        return new Media(uuid ?? Media.statuts.NEW, id);
    }

    forApi(media: Media): IMediaApi {
        const mediaApi = {} as IMediaApi;

        mediaApi.comments = media.comments;
        mediaApi.title = media.title;

        return mediaApi;
    }

    get$(uuid: string): Observable<Media> {
        return this._mediasApiService.get$(uuid).pipe(map(mediaApi => this.create(mediaApi)));
    }

    getByLink$(path: string, httpOptions?: HttpOptions): Observable<Media> {
        return this._mediasApiService.getByLink$(path, httpOptions).pipe(map(mediaApi => this.create(mediaApi)));
    }

    getFromDocuments$(documents: Document[], titre = Media.NO_TITLE): Observable<Media> {
        return this._mediasApiService.getFromDocuments$(documents.map(document => document.id), titre).pipe(map(mediaApi => this.create(mediaApi)));
    }

    getFromLocationMandats$(location: Location, locationMandat: LocationMandat): Observable<Media> {
        return this._mediasApiService.getFromLocationMandats$(location.id, locationMandat.id).pipe(map(mediaApi => this.create(mediaApi)));
    }

    getFromReports$(reports: Report[], titre = Media.NO_TITLE): Observable<Media> {
        return this._mediasApiService.getFromReports$(reports.map(report => report.id), titre).pipe(map(mediaApi => this.create(mediaApi)));
    }

    read$(media: Media): Observable<Window> {
        return this._mediasApiService.read$(media.linkDownload, media.title);
    }

    save$(media: Media): Observable<Media> {
        return this._mediasApiService.save$(media.uuid, this.forApi(media)).pipe(map(mediaApi => this.create(mediaApi)));
    }

    upload(file: File): Transfer<IMediaApi, Media> {
        const formData = new FormData();

        formData.append('file', file);

        const transfer = this._transferFactory.create(this._mediasApiService.upload$(formData), mediaApi => this.create(mediaApi));

        transfer.start();

        return transfer;
    }

    ngCreate(ngMedia: NgMedia): Media {
        const media = this.createVirgin(ngMedia.id.toString(), ngMedia.id);

        media.title = ngMedia.title;
        media.totalBytes = ngMedia.totalBytes;
        if (ngMedia._links?.download) {
            media.linkDownload = ngMedia._links.download.href;
        }

        return media;
    }
}
