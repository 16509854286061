import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import CADossierBiens from '@models/dossiers/biens/collection/dossier-biens.collection.model.abstract';
import {CLocationsService} from '@models/locations/collection/locations.collection.service';
import {CVentesService} from '@models/ventes/collection/ventes.collection.service';
import CDemandeurRechercheResultats
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.model';
import {CLocationsFactory} from '@models/locations/collection/locations.collection.factory';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import {map, tap} from 'rxjs/operators';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {ICADossierBiensQueryParameters} from '@models/dossiers/biens/collection/dossier-biens.interfaces';
import {DossierBienSource, DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class CDossierBiensService {
    private _cLocationsFactory = inject(CLocationsFactory);
    private _cLocationsService = inject(CLocationsService);
    private _cVentesFactory = inject(CVentesFactory);
    private _cVentesService = inject(CVentesService);
    private _dossierBienService = inject(DossierBienService);

    getFiltered$(dossierBienType: DossierBienType, sourceDossierBien: DossierBienSource, queryParameters: ICADossierBiensQueryParameters = {}): Observable<CADossierBiens> {
        /* If (dossierBienType === DossierBienTypesConst.ESTIMATION) {
            return this._estimationFactory.getByLink$(link);
        } else */
        if (dossierBienType === DossierBienTypesConst.LOCATION) {
            return this._cLocationsService.getBySource$(sourceDossierBien, queryParameters);
        } else if (dossierBienType === DossierBienTypesConst.VENTE) {
            return this._cVentesService.getBySource$(sourceDossierBien, queryParameters);
        }

        return of(undefined as unknown as CADossierBiens);
    }

    getFromDemandeurRechercheResultats$(cDemandeurRechercheResultats: CDemandeurRechercheResultats): Observable<CADossierBiens> {
        if (cDemandeurRechercheResultats.total <= 0) {
            return of(this._cVentesFactory.createVirgin());
        }

        let cADossierBiens: CADossierBiens;

        if (cDemandeurRechercheResultats.results[0].hasLocation()) {
            cADossierBiens = this._cLocationsFactory.createVirgin();
        } else if (cDemandeurRechercheResultats.results[0].hasVente()) {
            cADossierBiens = this._cVentesFactory.createVirgin();
        } else {
            return of(this._cVentesFactory.createVirgin());
        }

        cADossierBiens.links = cDemandeurRechercheResultats.links;
        cADossierBiens.page = cDemandeurRechercheResultats.page;
        cADossierBiens.pages = cDemandeurRechercheResultats.pages;
        cADossierBiens.perPage = cDemandeurRechercheResultats.perPage;
        cADossierBiens.total = cDemandeurRechercheResultats.total;

        return combineLatest(cDemandeurRechercheResultats.results.map(
            demandeurRechercheResultat => this._dossierBienService.getFromDemandeurRechercheResultat$(demandeurRechercheResultat)
        )).pipe(
            map(aDossierBiens => aDossierBiens.filter(dossierBien => !!dossierBien)),
            tap(aDossierBiens => cADossierBiens.results = aDossierBiens),
            map(_ => cADossierBiens),
        );
    }
}
