import {inject, Injectable} from '@angular/core';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {DepartementFactory} from '@models/departements/departement/departement.factory';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';
import {RegionFactory} from '@models/regions/region/region.factory';
import {CommuneVoieFactory} from '@models/communes/commune/voies/voie/commune-voie.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import {SortDefinition} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import {DossierBienType, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import SearchCriteria from '@models/search/criteria/search-criteria.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {SearchCriteriaSourceDefinition} from '@models/search/search.interfaces';

@Injectable({providedIn: 'root'})
export class SearchCriteriaFactory {
    static readonly COEFF_SURFACE = 0.8;
    private _communeFactory = inject(CommuneFactory);
    private _communeVoieFactory = inject(CommuneVoieFactory);
    private _departementFactory = inject(DepartementFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _quartierFactory = inject(QuartierFactory);
    private _regionFactory = inject(RegionFactory);

    createFromEstimation(estimation: Estimation): SearchCriteria {
        const searchCriteria = this.createVirgin();
        const surfacePonderee = estimation.bien.getSurfacePonderee();

        searchCriteria.superficieMin = Math.round((surfacePonderee > 0 ? surfacePonderee : estimation.bien.superficie) * SearchCriteriaFactory.COEFF_SURFACE * 100) / 100;
        if (estimation.bien.commune) {
            searchCriteria.communes.push(estimation.bien.commune);
        }

        if (estimation.bien.nature) {
            searchCriteria.natures.push(estimation.bien.nature);
        }

        if (estimation.bien.surfaceCadastrale > 0) {
            searchCriteria.surfaceCadastraleMin = Math.round(estimation.bien.surfaceCadastrale * SearchCriteriaFactory.COEFF_SURFACE * 100) / 100;
        }

        return searchCriteria;
    }

    createVirgin(): SearchCriteria {
        return new SearchCriteria();
    }

    ngCreate(ngSearch: NgSearch): SearchCriteria {
        const searchCriteria = this.createVirgin();

        searchCriteria.communes = (ngSearch.communes ?? []).map(commune => this._communeFactory.ngCreate(commune));
        searchCriteria.dateModification = ngSearch.dateModification!;
        searchCriteria.departements = (ngSearch.areas ?? []).filter(area => area.type === 'departement').map(departement => this._departementFactory.ngCreate(departement));
        searchCriteria.epoques = (ngSearch.epoques ?? []).map(epoque => this._dictionaryItemFactory.ngCreate(epoque));
        searchCriteria.etats = (ngSearch.etats ?? []).map(etat => this._dictionaryItemFactory.ngCreate(etat));
        searchCriteria.mitoyennetes = (ngSearch.mitoyennetes ?? []).map(mitoyennete => this._dictionaryItemFactory.ngCreate(mitoyennete));
        searchCriteria.natures = (ngSearch.natures ?? []).map(nature => this._dictionaryItemFactory.ngCreate(nature));
        searchCriteria.occupations = (ngSearch.occupations ?? []).map(occupation => this._dictionaryItemFactory.ngCreate(occupation));
        searchCriteria.plainPieds = (ngSearch.plainPieds ?? []).map(plainPied => this._dictionaryItemFactory.ngCreate(plainPied));
        searchCriteria.quartiers = (ngSearch.quartiers ?? []).map(quartier => this._quartierFactory.ngCreate(quartier));
        searchCriteria.regions = (ngSearch.areas ?? []).filter(area => area.type === 'region').map(region => this._regionFactory.ngCreate(region));
        searchCriteria.superficieMax = ngSearch.superficieMax!;
        searchCriteria.superficieMin = ngSearch.superficieMin!;
        searchCriteria.tris = ngSearch.tris as Record<string, SortDefinition>;
        searchCriteria.typeSearch = ngSearch.type as DossierBienType;
        searchCriteria.vues = (ngSearch.vues ?? []).map(vue => this._dictionaryItemFactory.ngCreate(vue));
        searchCriteria.statuts = (ngSearch.statuts ?? []).map(statut => {
            if (searchCriteria.typeSearch === DossierBienTypesConst.VENTE) {
                return this._dictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, statut, true);
            } else {
                return this._dictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, statut, true);
            }
        });

        if (ngSearch.archive) {
            searchCriteria.archiveDateVenteMax = DateFormat.toAPI(ngSearch.archive.dateVenteMax);
            searchCriteria.archiveDateVenteMin = DateFormat.toAPI(ngSearch.archive.dateVenteMin);
            searchCriteria.archivePrixCessionMax = ngSearch.archive.prixCessionMax!;
            searchCriteria.archivePrixCessionMin = ngSearch.archive.prixCessionMin!;
            searchCriteria.archiveTypes = (ngSearch.archive.types ?? []).map(type => this._dictionaryItemFactory.ngCreate(type));
        }

        if (ngSearch.location) {
            searchCriteria.chargesIncluses = ngSearch.location.chargesIncluses!;
            searchCriteria.locationMeuble = ngSearch.location.meuble!;
            searchCriteria.loyerMax = ngSearch.location.loyerMax!;
            searchCriteria.loyerMin = ngSearch.location.loyerMin!;
        }

        if (ngSearch.option) {
            searchCriteria.accesPmr = ngSearch.option.accesPmr!;
            searchCriteria.anneeConstructionMax = ngSearch.option.anneeConstructionMax!;
            searchCriteria.anneeConstructionMin = ngSearch.option.anneeConstructionMin!;
            searchCriteria.annexe = ngSearch.option.annexe!;
            searchCriteria.ascenseur = ngSearch.option.ascenseur!;
            searchCriteria.balcon = ngSearch.option.balcon!;
            searchCriteria.bienExceptionnel = ngSearch.option.exceptionnel!;
            searchCriteria.bienRdc = ngSearch.option.rdc!;
            searchCriteria.cave = ngSearch.option.cave!;
            searchCriteria.chambreRdc = ngSearch.option.chambreRdc!;
            searchCriteria.cheminee = ngSearch.option.cheminee!;
            searchCriteria.dernierEtage = ngSearch.option.dernierEtage!;
            searchCriteria.garage = ngSearch.option.garage!;
            searchCriteria.grenier = ngSearch.option.grenier!;
            searchCriteria.nombreChambresMax = ngSearch.option.nombreChambresMax!;
            searchCriteria.nombreChambresMin = ngSearch.option.nombreChambresMin!;
            searchCriteria.nombreEtagesMax = ngSearch.option.nombreEtagesMax!;
            searchCriteria.nombreEtagesMin = ngSearch.option.nombreEtagesMin!;
            searchCriteria.nombreLogementsMax = ngSearch.option.nombreLogementsMax!;
            searchCriteria.nombreLogementsMin = ngSearch.option.nombreLogementsMin!;
            searchCriteria.nombrePiecesMax = ngSearch.option.nombrePiecesMax!;
            searchCriteria.nombrePiecesMin = ngSearch.option.nombrePiecesMin!;
            searchCriteria.nomVoie = ngSearch.option.nomVoie!;
            searchCriteria.numeroVoie = ngSearch.option.numeroVoie!;
            searchCriteria.parking = ngSearch.option.parking!;
            searchCriteria.pieceEauRdc = ngSearch.option.pieceEauRdc!;
            searchCriteria.piscine = ngSearch.option.piscine!;
            searchCriteria.pointEau = ngSearch.option.pointEau!;
            searchCriteria.residenceService = ngSearch.option.residenceService!;
            searchCriteria.source = ngSearch.option.source as SearchCriteriaSourceDefinition;
            searchCriteria.sousSol = ngSearch.option.sousSol!;
            searchCriteria.stationnement = ngSearch.option.stationnement!;
            searchCriteria.surfaceBatieMax = ngSearch.option.surfaceBatieMax!;
            searchCriteria.surfaceBatieMin = ngSearch.option.surfaceBatieMin!;
            searchCriteria.surfaceCadastraleMax = ngSearch.option.surfaceCadastraleMax!;
            searchCriteria.surfaceCadastraleMin = ngSearch.option.surfaceCadastraleMin!;
            searchCriteria.surfaceNonBatieMax = ngSearch.option.surfaceNonBatieMax!;
            searchCriteria.surfaceNonBatieMin = ngSearch.option.surfaceNonBatieMin!;
            searchCriteria.surfaceSejourMax = ngSearch.option.surfaceSejourMax!;
            searchCriteria.surfaceSejourMin = ngSearch.option.surfaceSejourMin!;
            searchCriteria.terrasse = ngSearch.option.terrasse!;
            searchCriteria.terrasseOuBalcon = ngSearch.option.terrasseOuBalcon!;
            searchCriteria.uniquementBiensNotaire = ngSearch.option.uniquementBiensNotaire!;

            if (ngSearch.option.voie) {
                searchCriteria.voie = this._communeVoieFactory.ngCreate(ngSearch.option.voie);
            }
        }

        if (ngSearch.vente) {
            searchCriteria.budgetHni = ngSearch.vente.budgetHni!;
            searchCriteria.budgetMax = ngSearch.vente.budgetMax!;
            searchCriteria.budgetMin = ngSearch.vente.budgetMin!;
            searchCriteria.excludeVenteInteractive = ngSearch.vente.excludeVenteInteractive!;
            searchCriteria.excludeVenteTraditionnelle = ngSearch.vente.excludeVenteTraditionnelle!;
            searchCriteria.excludeVenteViager = ngSearch.vente.excludeVenteViager!;
            searchCriteria.renteMax = ngSearch.vente.renteMax!;
            searchCriteria.renteMin = ngSearch.vente.renteMin!;
        }

        return searchCriteria;
    }
}
