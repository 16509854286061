import {ErrorHandler, NgModule} from '@angular/core';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {ErrorHandlerService} from '@core/error/error.handler.service';
import {ErrorSentryService} from '@core/error/error.sentry.service';
import ABOUT from '@env/about';
import environment from '@env/environment';

@NgModule({
    providers: [
        ErrorSentryService,
        ErrorHandlerService,
        ErrorConfigurationService,
        {provide: ErrorHandler, useClass: ErrorHandlerService},
    ]
})
export class ErrorModule {
    constructor(errorSentryService: ErrorSentryService, errorConfigurationService: ErrorConfigurationService) {
        if (environment.sentry.enabled) {
            errorSentryService.init({
                dsn: environment.sentry.dsn,
                environment: environment.sentry.environment,
                // Erreur provenant de l'utilisation de https://platform.twitter.com/widgets.js
                ignoreErrors: ['sandbox not initialized'],
                normalizeDepth: 10,
                release: ABOUT.version,
            });
            errorConfigurationService.setUser();
        }
    }
}
