import {Injectable} from '@angular/core';
import CParticipants from '@models/participants/collection/participants.collection.model';
import Links from '@models/links/links.model';

@Injectable({providedIn: 'root'})
export class CParticipantsFactory {
    createVirgin(): CParticipants {
        const cParticipants = new CParticipants();

        cParticipants.links = new Links();

        return cParticipants;
    }
}
