import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {NgResource} from '@legacy/app/managers/ressources';

export default function getEskSelect(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select></esk-select>
         * @param options : {name(string), onRemove(function), onSelect(function), placeholder(string), required(boolean)}
         */
        module.component('eskSelect', {
            bindings: {list: '=', ngModel: '=', options: '=?'},
            controller: Controller,
            templateUrl: template,
        });

        /**
         * Controller for select list
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.isSelected = isSelected;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.options = angular.isObject($ctrl.options) ? $ctrl.options : {};
                $ctrl.options.required = $ctrl.options.required === true;
            }

            /**
             * Returns true if the clicked item is already selected
             *
             * @param item
             * @returns {boolean}
             */
            function isSelected(item: NgResource) {
                if (angular.isArray($ctrl.ngModel) && angular.isObject(item)) {
                    return find($ctrl.ngModel, {id: item.id});
                }

                return false;
            }
        }


        /**
         * Display select dictionary
         *
         * @param $element
         * @param $attrs
         * @returns {string}
         */
        template.$inject = ['$element', '$attrs'];
        function template($element: unknown, $attrs: { isMultiple: unknown }) {
            return 'src/app/legacy/templates/eskimmo/components/select-' + (angular.isDefined($attrs.isMultiple) ? 'multiple' : 'simple') + '.html';
        }
    })(angularJS);
}
