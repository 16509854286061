import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import {NgDemandeurManager, NgEmailManager, NgFilterManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgEstimation, NgFilter, NgLocation, NgVente} from '@legacy/app/managers/ressources';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {CVenteMatchingsService} from '@models/ventes/vente/matchings/collection/vente-matching.collection.service';
import {UserService} from '@models/users/user/user.service';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Filter from '@models/filters/filter/filter.model';
import DateFormat from '@shared/date/date.format';
import Vente from '@models/ventes/vente/vente.model';

export default function getVenteLifetimeCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskVenteLifetimeCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/controllers/item/lifetime.html',
        });

        /**
         * Controller of Lifetime Vente
         *
         * @param VenteManager
         * @param SoqModalesManager
         * @param Ng2RouterStateForNgService
         * @param FilterManager
         * @param DemandeurManager
         * @param $translate
         * @param SoqSweetAlert
         * @param Ng2VenteCompromisFactory
         * @param Ng2ToasterService
         * @param EmailManager
         * @param Ng2EtudeService
         * @param Ng2CVenteMatchingsService
         * @param Ng2UserService
         * @param Ng2VenteFactory
         */
        Controller.$inject = ['VenteManager', 'SoqModalesManager', 'Ng2RouterStateForNgService', 'FilterManager', 'DemandeurManager', '$translate', 'SoqSweetAlert', 'Ng2VenteCompromisFactory', 'Ng2ToasterService', 'EmailManager', 'Ng2EtudeService', 'Ng2CVenteMatchingsService', 'Ng2UserService', 'Ng2VenteFactory'];
        function Controller(this: any,
                            venteManager: NgVenteManager,
                            soqModalesManager: NgSoqModalesManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            filterManager: NgFilterManager,
                            demandeurManager: NgDemandeurManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2VenteCompromisFactory: VenteCompromisFactory,
                            ng2ToasterService: ToasterService,
                            emailManager: NgEmailManager,
                            ng2EtudeService: EtudeService,
                            ng2CVenteMatchingsService: CVenteMatchingsService,
                            ng2UserService: UserService,
                            ng2VenteFactory: VenteFactory) {
            const $ctrl = this;

            $ctrl.archive = archive;
            $ctrl.compromis = compromis;
            $ctrl.createEmail = createEmail;
            $ctrl.createEstimation = createEstimation;
            $ctrl.createLocation = createLocation;
            $ctrl.duplicate = duplicate;
            $ctrl.BonvisiteStatutNEW = Bonvisite.statuts.NEW;
            $ctrl.hasRoleLocation = false;
            $ctrl.loader = {demandeursCorrespondants: true};
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.OffreachatStatutNEW = Offreachat.statuts.NEW;
            $ctrl.vente = venteManager.current;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.STATUTS = Vente.statuts;
            $ctrl.ng2Vente = ng2VenteFactory.ngCreate($ctrl.vente);
            ng2UserService.last$.subscribe(currentUser => $ctrl.hasRoleLocation = currentUser.hasRoleGrpLocation())
            ng2EtudeService.last$.subscribe(currentEtude => {
                $ctrl.showMatchingDemandeurs = $ctrl.vente.statut !== Vente.statuts.BROUILLON && currentEtude.hasNetwork() && currentEtude.settingsNetwork.matchingDemandeurs;
                if ($ctrl.showMatchingDemandeurs) {
                    ng2CVenteMatchingsService.getForMeAndWhenNoOne$($ctrl.vente.uuid).subscribe({
                        next: cVenteMatchings => $ctrl.cVenteMatchings = cVenteMatchings,
                        complete: () => $ctrl.loader.demandeursCorrespondants = false,
                    });
                } else {
                    filterManager.getActiveByRouteCode(Filter.routes.GET_ETUDE_DEMANDEURS, 'correspondants').then(
                        filter => filter as NgFilter
                    ).then(filter => {
                        const addedParams = {limit: 4, venteUuid: undefined};

                        if ($ctrl.vente.statut === Vente.statuts.BROUILLON) {
                            addedParams.venteUuid = $ctrl.vente.uuid;
                            filter.raz();
                        } else {
                            filter.queryParameters.recherches.resultats.vente.values = [$ctrl.vente.id];
                        }

                        return demandeurManager.getAllEtudeFiltered(filter, addedParams);
                    }).then(demandeurs => $ctrl.demandeursCorrespondants = demandeurs).finally(() => $ctrl.loader.demandeursCorrespondants = false);
                }
            });

            /**
             * Open modal archive
             */
            function archive() {
                soqModalesManager.open('VenteModalArchive', {resolve: {vente: $ctrl.vente}}).then(function () {
                    ng2ToasterService.success($translate.instant('vente.archive.realised.TITLE'), $translate.instant('vente.archive.realised.MESSAGE', {reference: $ctrl.vente.bien.reference}));
                    ng2RouterStateForNgService.navigateByUrl('/app/ventes/portefeuille');
                });
            }

            /**
             * Open modal compromis
             */
            function compromis() {
                if (angular.isObject($ctrl.vente.enabledCompromis)) {
                    const enabledCompromis = $ctrl.vente.enabledCompromis instanceof VenteCompromis ? $ctrl.vente.enabledCompromis : ng2VenteCompromisFactory.ngCreate($ctrl.vente.enabledCompromis);
                    const labelType = enabledCompromis.negocie ? enabledCompromis.raisonNegocie : enabledCompromis.raisonNonNegocie;
                    const paramsTranslateCompromis = {
                        dateCompromis: DateFormat.toJSON(enabledCompromis.date, 'DD/MM/YYYY'),
                        labelType: labelType?.label,
                        shortLabelType: labelType?.shortLabel,
                    };

                    soqSweetAlert.warningConfirmation($translate.instant('vente.compromis.disabled.confirm.TITLE'), $translate.instant('vente.compromis.disabled.confirm.MESSAGE', paramsTranslateCompromis), {
                        confirmButtonText: $translate.instant('vente.compromis.disabled.confirm.CONFIRMATION')
                    }).then(function () {
                        $ctrl.vente.disableCompromis();
                    });
                } else {
                    soqModalesManager.open('VenteModalCompromis', {resolve: {vente: $ctrl.vente}});
                }
            }

            /**
             * Create email
             */
            function createEmail() {
                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: $ctrl.vente.bien.contactsGroup.getRecipients(),
                            dossiers: [
                                {typeId: $ctrl.vente.id, type: $ctrl.vente._esk.typeDossier, dossier: $ctrl.vente},
                            ],
                        }),
                        currentDossier: $ctrl.vente,
                    }
                });
            }

            /**
             * Create estimation from vente
             */
            function createEstimation() {
                soqSweetAlert.warningConfirmation($translate.instant('vente.createEstimation.confirm.TITLE'), $translate.instant('vente.createEstimation.confirm.MESSAGE', {
                    reference: $ctrl.vente.bien.reference
                }), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('vente.createEstimation.confirm.INPUT'),
                    confirmButtonText: $translate.instant('vente.createEstimation.confirm.CONFIRMATION')
                }).then((showArchive) => {
                    $ctrl.vente.createEstimation().then((estimation: NgEstimation) => {
                        ng2ToasterService.success($translate.instant('vente.createEstimation.realised.TITLE'), $translate.instant('vente.createEstimation.realised.MESSAGE', {
                            reference: estimation.bien.reference
                        }));

                        if (showArchive) {
                            $ctrl.archive();
                        }
                    });
                });
            }

            /**
             * Create location from vente
             */
            function createLocation() {
                soqSweetAlert.warningConfirmation($translate.instant('vente.createLocation.confirm.TITLE'), $translate.instant('vente.createLocation.confirm.MESSAGE', {
                    reference: $ctrl.vente.bien.reference
                }), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('vente.createLocation.confirm.INPUT'),
                    confirmButtonText: $translate.instant('vente.createLocation.confirm.CONFIRMATION')
                }).then(function (showArchive) {
                    $ctrl.vente.createLocation().then((location: NgLocation) => {
                        ng2ToasterService.success($translate.instant('vente.createLocation.realised.TITLE'), $translate.instant('vente.createLocation.realised.MESSAGE', {
                            reference: location.bien.reference
                        }));

                        if (showArchive) {
                            $ctrl.archive();
                        }
                    });
                });
            }

            /**
             * Duplicate vente
             */
            function duplicate() {
                soqSweetAlert.warningConfirmation($translate.instant('vente.duplicate.confirm.TITLE'), $translate.instant('vente.duplicate.confirm.MESSAGE'), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant('vente.duplicate.confirm.INPUT'),
                    confirmButtonText: $translate.instant('vente.duplicate.confirm.CONFIRMATION')
                }).then(function (keepOwners) {
                    ng2ToasterService.info($translate.instant('vente.duplicate.in_progress.TITLE'), $translate.instant('vente.duplicate.in_progress.MESSAGE'));
                    $ctrl.vente.clone(keepOwners).then((duplicatedVente: NgVente) => {
                        ng2ToasterService.success($translate.instant('vente.duplicate.realised.TITLE'), $translate.instant('vente.duplicate.realised.MESSAGE', {
                            reference: duplicatedVente.bien.reference
                        }));
                    });
                });
            }
        }
    })(angularJS);
}
