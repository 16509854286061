import {Injectable} from '@angular/core';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {IDictionaryItemApi} from '@models/dictionaries/dictionary/items/item/dictionary-item.interfaces';
import {NgItemDictionary} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class DictionaryItemFactory {
    static readonly OTHER_LABEL_CODE = 'DictionaryItemFactory.OTHER_LABEL_CODE';

    create(dictionaryItemApi: IDictionaryItemApi): DictionaryItem {
        const dictionaryItem = this.createVirgin(dictionaryItemApi.uuid ?? dictionaryItemApi.id.toString(), dictionaryItemApi.id);

        dictionaryItem.abreviation = dictionaryItemApi.abreviation;
        dictionaryItem.anneeMax = dictionaryItemApi.anneeMax;
        dictionaryItem.anneeMin = dictionaryItemApi.anneeMin;
        dictionaryItem.code = dictionaryItemApi.code;
        dictionaryItem.codePasserelle = dictionaryItemApi.codePasserelle;
        dictionaryItem.defaultComments = dictionaryItemApi.defaultComments;
        dictionaryItem.deprecated = dictionaryItemApi.deprecated;
        dictionaryItem.duree = dictionaryItemApi.duree;
        dictionaryItem.label = dictionaryItemApi.label;
        dictionaryItem.shortLabel = dictionaryItemApi.shortLabel;
        dictionaryItem.tags = dictionaryItemApi.tags;
        if (dictionaryItemApi.codes?.length > 0) {
            dictionaryItem.codes = dictionaryItemApi.codes;
        }

        return dictionaryItem;
    }

    createForOtherLabel(label: string): DictionaryItem {
        const dictionaryItem = this.createVirgin();

        dictionaryItem.code = DictionaryItemFactory.OTHER_LABEL_CODE;
        dictionaryItem.deprecated = false;
        dictionaryItem.label = label;

        return dictionaryItem;
    }

    createVirgin(uuid?: string, id?: number): DictionaryItem {
        return new DictionaryItem(uuid ?? DictionaryItem.statuts.NEW, id);
    }

    forApi(dictionaryItem: DictionaryItem): IDictionaryItemApi {
        const dictionaryItemApi = {} as IDictionaryItemApi;

        dictionaryItemApi.abreviation = dictionaryItem.abreviation;
        dictionaryItemApi.anneeMax = dictionaryItem.anneeMax;
        dictionaryItemApi.anneeMin = dictionaryItem.anneeMin;
        dictionaryItemApi.code = dictionaryItem.code;
        dictionaryItemApi.codePasserelle = dictionaryItem.codePasserelle;
        dictionaryItemApi.defaultComments = dictionaryItem.defaultComments;
        dictionaryItemApi.deprecated = dictionaryItem.deprecated;
        dictionaryItemApi.duree = dictionaryItem.duree;
        dictionaryItemApi.id = dictionaryItem.id;
        dictionaryItemApi.label = dictionaryItem.label;
        dictionaryItemApi.shortLabel = dictionaryItem.shortLabel;
        dictionaryItemApi.tags = dictionaryItem.tags;
        dictionaryItemApi.uuid = dictionaryItem.uuid;
        if (dictionaryItem.codes?.length > 0) {
            dictionaryItemApi.codes = dictionaryItem.codes;
        }

        return dictionaryItemApi;
    }

    ngCreate(ngItemDictionary: NgItemDictionary): DictionaryItem {
        const dictionaryItem = this.createVirgin(ngItemDictionary.id?.toString(), ngItemDictionary.id);

        dictionaryItem.abreviation = ngItemDictionary.abreviation!;
        dictionaryItem.anneeMax = ngItemDictionary.anneeMax!;
        dictionaryItem.anneeMin = ngItemDictionary.anneeMin!;
        dictionaryItem.code = ngItemDictionary.code;
        dictionaryItem.codePasserelle = ngItemDictionary.codePasserelle;
        dictionaryItem.defaultComments = ngItemDictionary.defaultComments!;
        dictionaryItem.deprecated = ngItemDictionary.deprecated!;
        dictionaryItem.duree = ngItemDictionary.duree!;
        dictionaryItem.label = ngItemDictionary.label;
        dictionaryItem.shortLabel = ngItemDictionary.shortLabel!;
        dictionaryItem.tags = ngItemDictionary.tags!;
        if (ngItemDictionary.codes?.length > 0) {
            dictionaryItem.codes = ngItemDictionary.codes;
        }

        return dictionaryItem;
    }
}
