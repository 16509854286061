import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {AppSiteCardComponent} from '@features/sites/site/card/site.card.component';
import {AppSiteConvivialNameComponent} from '@features/sites/site/convivial-name/site.convivial-name.component';
import {CommunesModule} from '@features/communes/communes.module';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [AppSiteCardComponent, AppSiteConvivialNameComponent],
    exports: [AppSiteCardComponent, AppSiteConvivialNameComponent],
    imports: [CommonModule, CommunesModule, RouterModule, SharedModule],
})
export class SitesModule {
}
