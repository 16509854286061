<ng-select [bindLabel]="options.bindLabel!" [clearable]="!options.required!" [disabled]="options.disabled!"
           [groupBy]="options.groupBy!" [groupValue]="options.groupValue!" [items]="list" [loading]="loading"
           [loadingText]="options.loadingText!" [multiple]="options.multiple!" [ngClass]="{'app-invalid': isInvalid}"
           [placeholder]="options.placeholder!" [searchable]="options.searchable!" [typeahead]="search$"
           [typeToSearchText]="options.typeToSearchText!" [virtualScroll]="options.virtualScroll!" (change)="onSelect()"
           (clear)="clear()" [(ngModel)]="model" [clearAllText]="'Supprimer la sélection'">
  @if (options.customTemplate === customTemplates.USER; as item) {
    <ng-template ng-label-tmp let-item="item">
      <ng-container *ngTemplateOutlet="customTemplateUser;context:{item: item}"/>
    </ng-template>
  }

  @if (options.customTemplate === customTemplates.COMMUNE) {
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      @for (item of items; track item) {
        <div class="ng-value">
          <span class="ng-value-label">{{ item[options.bindLabel!] }}</span>
          <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </div>
      }
    </ng-template>
  }

  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option ng-notfound">{{ options.notFoundText! }}</div>
  </ng-template>

  <ng-template ng-optgroup-tmp let-item="item">
    {{ item.total > 1 ? item.labelPlural : item.labelSingular }} ({{ item.total }})
  </ng-template>

  @if (options.customTemplate === customTemplates.USER) {
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <ng-container *ngTemplateOutlet="customTemplateUser;context:{item: item, search: search}"/>
    </ng-template>
  }

  <ng-template #customTemplateUser let-item="item" let-search="search">
    <div class="tw-relative tw-flex tw-items-center tw-space-x-2">
      @if (options.bindLinkImage) {
        <img class="tw-rounded-full" [ngSrc]="item[options.bindLinkImage!]" height="24" width="24">
      }

      <div class="tw-truncate" [highlight]="search">{{ item[options.bindLabel!] }}</div>
    </div>
  </ng-template>
</ng-select>

<div appProvideParentForm class="tw-hidden">
  <input [name]="options.name!" [(ngModel)]="model" #formNgSelect="ngModel" [required]="options.required!">
</div>
