import {Injectable} from '@angular/core';
import {parsePhoneNumberWithError} from 'libphonenumber-js/max';
import {IPhoneNumber} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class PhoneNumberService {
    static isValid(textNumber: string): boolean {
        const parsedNumber = PhoneNumberService.parse(textNumber);

        if (!parsedNumber) {
            return false;
        }

        return parsedNumber.isValid();
    }

    static parse(textNumber: string): IPhoneNumber {
        if (!textNumber) {
            return undefined!;
        }

        try {
            return parsePhoneNumberWithError(textNumber, 'FR');
        } catch (e) {
            return undefined!;
        }
    }

    getE164(textNumber: string): string {
        return this.parse(textNumber)?.format('E.164');
    }

    getRfc3966(textNumber: string): string {
        return this.parse(textNumber)?.format('RFC3966');
    }

    getUsable(textNumber: string): string {
        const parsedNumber = this.parse(textNumber);

        if (!parsedNumber) {
            return undefined!;
        }

        if (parsedNumber.country === 'FR') {
            return parsedNumber.formatNational();
        }

        return parsedNumber.format('INTERNATIONAL');
    }

    // @todo Vérifier utilité, préférer l'utilisation de la méthode static
    isValid(textNumber: string): boolean {
        return PhoneNumberService.isValid(textNumber);
    }

    // @todo Vérifier utilité, préférer l'utilisation de la méthode static
    parse(textNumber: string): IPhoneNumber {
        return PhoneNumberService.parse(textNumber);
    }
}
