import {inject, Injectable} from '@angular/core';
import TemplateBalise from '@models/templates/balises/balise/template-balise.model';
import {Observable, of} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import CTemplateBalises from '@models/templates/balises/collection/template-balises.collection.model';
import {filter, map} from 'rxjs/operators';
import ParserService from '@shared/parser/parser.service';
import {CSitesService} from '@models/sites/collection/sites.collection.service';

@Injectable({providedIn: 'root'})
export class TemplateBaliseService {
    private _cSitesService = inject(CSitesService);
    private _etudeService = inject(EtudeService);
    private _parserService = inject(ParserService);
    private _userService = inject(UserService);

    getExampleValue$(model: string, templateBalise: TemplateBalise): Observable<string> {
        let getData$: Observable<unknown> = of(undefined!);

        switch (model) {
            case CTemplateBalises.models.etude.CODE :
                getData$ = this._etudeService.last$;
                break;

            case CTemplateBalises.models.sitePrincipal.CODE :
                getData$ = this._cSitesService.current$.pipe(filter(cSites => !!cSites), map(cSites => cSites.results[0]));
                break;

            case CTemplateBalises.models.currentUser.CODE :
                getData$ = this._userService.last$;
                break;
        }

        return getData$.pipe(map(data => this.getValueFromPath(templateBalise, data)));
    }

    getValueFromPath(templateBalise: TemplateBalise, object: unknown): string {
        if (!templateBalise.path) {
            return templateBalise.description;
        }

        const splitPath = templateBalise.path.split(' ')[0].split('.');

        return (this._parserService.evalSync(templateBalise.path, {[splitPath[0]]: object}))?.toString() ?? templateBalise.description;
    }
}
