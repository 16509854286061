import angularJS from '@shared/angularJS/global.ng';
import {find, remove} from 'lodash';
import {IModule, IQService} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {
    NgMediaManager, NgReportAttachmentManager, NgReportDossierManager, NgReportParticipantManager
} from '@legacy/app/managers/managers';
import {NgReport, NgReportAttachment, NgReportParticipant, NgResource} from '@legacy/app/managers/ressources';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

export default function getEskModalReportCtrl(module: IModule): void {
    (angular => {
        'use strict';

        module.controller('EskModalReportCtrl', Controller);

        /**
         * Controller who edits a report
         *
         * @param $q
         * @param report
         * @param currentTab
         * @param dossier
         * @param $uibModalInstance
         * @param ReportDossierManager
         * @param ReportParticipantManager
         * @param ReportAttachmentManager
         * @param MediaManager
         * @param SoqSweetAlert
         * @param $translate
         * @param Ng2ToasterService
         * @param Ng2TinymceService
         * @constructor
         */
        Controller.$inject = ['$q', 'report', 'currentTab', 'dossier', '$uibModalInstance', 'ReportDossierManager', 'ReportParticipantManager', 'ReportAttachmentManager', 'MediaManager', 'SoqSweetAlert', '$translate', 'Ng2ToasterService', 'Ng2TinymceService'];
        function Controller(this: any,
                            $q: IQService,
                            report: NgReport,
                            currentTab: string,
                            dossier: NgResource,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            reportDossierManager: NgReportDossierManager,
                            reportParticipantManager: NgReportParticipantManager,
                            reportAttachmentManager: NgReportAttachmentManager,
                            mediaManager: NgMediaManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            ng2ToasterService: ToasterService,
                            ng2TinymceService: TinymceService) {
            const $ctrl = this;
            let tabFound;

            $ctrl.addAttachmentDocument = addAttachmentDocument;
            $ctrl.addDossier = addDossier;
            $ctrl.addParticipant = addParticipant;
            $ctrl.addAttachment = addAttachment;
            $ctrl.isParticipantDisabled = isParticipantDisabled;
            $ctrl.onSelectTheme = onSelectTheme;
            $ctrl.save = save;
            $ctrl.tinymceOptions = ng2TinymceService.getOptions('simple', {
                plugins: [TinymceService.plugins.LISTS],
            });
            $ctrl.participantsOptions = {placeholder: 'Ajouter un participant', withUsers: false};
            $ctrl.report = report;
            $ctrl.dossier = dossier;
            $ctrl.newParticipant = null;
            $ctrl.COMPTE_RENDU_THEMES = Dictionary.names.COMPTE_RENDU_THEMES;
            $ctrl.tabs = [
                {code: 'general', label: "Rédaction"},
                {code: 'participants', label: "Participants"},
                {code: 'dossiers', label: "Dossiers concernés"},
                {code: 'attachments', label: "Fichiers joints"}
            ];
            tabFound = find($ctrl.tabs, {code: currentTab});
            if (!angular.isObject(tabFound) || !tabFound.code) {
                tabFound = $ctrl.tabs[0];
            }

            $ctrl.currentTab = tabFound.code;
            if (angular.isNumber(report.id)) {
                report.getDossiers();
            }

            /**
             * Saves the report and closes the modal instance
             */
            function save() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid || !$ctrl.report.comments) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return;
                }

                $ctrl.saving = true;
                $ctrl.report.save().then(() => $uibModalInstance.close($ctrl.report)).finally(() => $ctrl.saving = false);
            }

            /**
             * Add attachment document to the report
             *
             * @param dossierFile
             */
            function addAttachmentDocument(dossierFile: DossierFile) {
                $ctrl.report.attachments.push(reportAttachmentManager.create({media: mediaManager.createFromNg2(dossierFile.media)}));
            }

            /**
             * Add a dossier to the report
             *
             * @param dossier
             */
            function addDossier(dossier: NgResource) {
                $ctrl.report.dossiers.push(reportDossierManager.create({
                    typeId: dossier.id,
                    type: dossier._esk.typeDossier,
                    dossier: dossier
                }));
            }

            /**
             * Add a participant to the report
             *
             * @param participant
             */
            function addParticipant(participant: NgReportParticipant) {
                if (!participant) {
                    return;
                }

                $ctrl.report.participants.push(reportParticipantManager.create({
                    personId: participant.id,
                    present: true,
                    person: participant
                }));
            }

            /**
             * Add attachment to report
             *
             * @param file
             */
            function addAttachment(file: File) {
                const reportAttachment = reportAttachmentManager.create({media: mediaManager.create({file})}) as NgReportAttachment;

                $ctrl.uploading = true;
                $ctrl.report.attachments.push(reportAttachment);
                reportAttachment.media.upload().then(() => reportAttachment.mediaId = reportAttachment.media.id, () => {
                    ng2ToasterService.error($translate.instant("mail.TITLE"), $translate.instant('mail.attachment_incorrect.MESSAGE', {fileName: reportAttachment.media.file.name}));
                    remove($ctrl.report.attachments, reportAttachment);
                }).finally(() => {
                    let uploading = false;

                    angular.forEach($ctrl.report.attachments, reportAttachment => {
                        if (angular.isObject(reportAttachment.media.file) && reportAttachment.media.file._esk.uploadXhr) uploading = true;
                    });
                    $ctrl.uploading = uploading;
                });
            }

            /**
             * Is participant disabled
             *
             * @param newParticipant
             * @returns {boolean}
             */
            function isParticipantDisabled(newParticipant: NgReportParticipant) {
                if ($ctrl.report.participants.length <= 0) {
                    return false;
                }

                return !!$ctrl.report.participants
                    .filter((participant: NgReportParticipant) => newParticipant.person.id === participant.person.id);
            }

            /**
             * On select theme
             *
             * @returns {boolean}
             */
            function onSelectTheme() {
                let validation = $q.resolve();

                if ($ctrl.report.comments) {
                    validation = soqSweetAlert.warningConfirmation('Modification du corps du thème', 'Voulez-vous utiliser le corps par défaut du compte-rendu ?');
                }

                validation.then(() => $ctrl.report.comments = $ctrl.report.theme.defaultComments);
            }
        }
    })(angularJS);
}
