export default `
<div id="carte-quartiers">
  <div ng-if="!$ctrl.loader" id="actions-carte-quartiers">
    <button id="zoom-in" type="button" ng-click="ZoomIn()"><fa-icon [icon]="'plus'"></fa-icon></button>

    <button id="zoom-out" type="button" ng-click="ZoomOut()"><fa-icon [icon]="'minus'"></fa-icon></button>

    <button id="reset" type="button" ng-click="RAZ()"><fa-icon [icon]="'undo'"></fa-icon></button>
  </div>
</div>
`;
