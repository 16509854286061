import {inject, Injectable} from '@angular/core';
import CParticipants from '@models/participants/collection/participants.collection.model';
import {combineLatest, Observable} from 'rxjs';
import {CParticipantsFactory} from '@models/participants/collection/participants.collection.factory';
import {map} from 'rxjs/operators';
import {CUsersService} from '@models/users/collection/users.collection.service';
import Contact from '@models/contacts/contact/contact.model';
import Person from '@models/contacts/person/person.model';
import {ParticipantFactory} from '@models/participants/participant/participant.factory';
import {CContactsFactory} from '@models/contacts/collection/contacts.collection.factory';
import {ConditionConst} from '@shared/constants';

@Injectable({providedIn: 'root'})
export class CParticipantsService {
    private _cContactsFactory = inject(CContactsFactory);
    private _cParticipantsFactory = inject(CParticipantsFactory);
    private _cUsersService = inject(CUsersService);
    private _participantFactory = inject(ParticipantFactory);

    search$(keyword?: string): Observable<CParticipants> {
        return combineLatest([
            this._cContactsFactory.get$({
                keywords: keyword,
                type: {condition: ConditionConst.IN, values: [Contact.type.PERSON]},
            }),
            this._cUsersService.search$(keyword)
        ]).pipe(
            map(([cContacts, cUsers]) => {
                const cParticipants = this._cParticipantsFactory.createVirgin();

                cContacts.results.map(contact => contact as Person)
                    .map(person => this._participantFactory.createFromPerson(person))
                    .forEach(participant => cParticipants.addResult(participant));
                cUsers.results.map(user => this._participantFactory.createFromUser(user))
                    .forEach(participant => cParticipants.addResult(participant));

                return cParticipants;
            }),
        );
    }
}
