import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ISearch} from '@models/search/search.interfaces';
import SearchCriteria from '@models/search/criteria/search-criteria.model';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {NgVentesCollection} from '@legacy/app/managers/collections';
import {SearchCriteriaFactory} from '@models/search/criteria/search-criteria.factory';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';

@Injectable({providedIn: 'root'})
export class SearchService {
    private _cVentesFactory = inject(CVentesFactory);
    private _searchCriteriaFactory = inject(SearchCriteriaFactory);
    private _currentSource = new BehaviorSubject<ISearch>(undefined!);
    private _current$ = this._currentSource.asObservable();

    get current$(): Observable<ISearch> {
        return this._current$;
    }

    razCurrent(): void {
        this._currentSource.next(undefined!);
    }

    setCurrent(searchCriteria: SearchCriteria, cVentes: CVentes): void {
        this._currentSource.next({criteria: searchCriteria, cVentes});
    }

    setNgCurrent(search: NgSearch, ngVentesCollection: NgVentesCollection): void {
        this.setCurrent(this._searchCriteriaFactory.ngCreate(search), this._cVentesFactory.ngCreate(ngVentesCollection));
    }
}
