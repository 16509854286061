import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';

export default function getBienEditHeader(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-bien-edit-header></esk-bien-edit-header>
         */
        module.component('eskBienEditHeader', {
            bindings: {dossier: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/edit/header.html'
        });

        /**
         * Controller for estimations lists
         *
         * @param Ng2RouterStateForNgService
         */
        Controller.$inject = ['Ng2RouterStateForNgService'];
        function Controller(this: any, ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            $ctrl.viewDossierBien = viewDossierBien;

            /**
             * View dossier bien
             */
            function viewDossierBien() {
                ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.dossier._esk.typeDossier + 's/details/' + $ctrl.dossier.id.toString());
            }
        }
    })(angularJS);
}
