import angularJS from '@shared/angularJS/global.ng';
import {IAttributes, IController, IDirectiveFactory, IModule, Injectable, IScope} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getSoqCapitalizeInput(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-capitalize-input
         */
        module.directive('soqCapitalizeInput', directive as Injectable<IDirectiveFactory>);

        /**
         * prevent touch move directive
         *
         * @returns {{restrict: string, link: Function}}
         */
        directive.$inject = [] as string[];
        function directive() {
            return {restrict: 'A', require: 'ngModel', link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             * @param $ctrl
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes, $ctrl: IController) {
                const depth = $attrs.soqCapitalizeInput ? +$attrs.soqCapitalizeInput : 0;

                $ctrl.$parsers.push(capitalize);
                capitalize(($scope as unknown as Record<string, string>)[$attrs.ngModel]);

                /**
                 * Capitalize input value
                 *
                 * @param inputValue
                 * @returns {string}
                 */
                function capitalize(inputValue: string) {
                    if (angular.isDefined(inputValue)) {
                        var newValue = inputValue.toUpperCase();

                        if (angular.isNumber(depth) && depth > 0 && depth < inputValue.length) {
                            newValue = inputValue.charAt(depth - 1).toUpperCase() + inputValue.slice(depth);
                        }

                        if (newValue !== inputValue) {
                            $ctrl.$setViewValue(newValue);
                            $ctrl.$render();
                        }

                        return newValue;
                    }

                    return '';
                }
            }
        }
    })(angularJS);
}
