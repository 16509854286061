export default class TemplateFooter {
    static codes = {CUSTOM: 'custom', SOQRATE: 'soqrate'};
    private _code!: string;
    private _height!: number;
    private _label!: string;
    private _template!: string;
    private _thumbnailUrl!: string;

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get height(): number {
        return this._height;
    }

    set height(value: number) {
        this._height = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get template(): string {
        return this._template;
    }

    set template(value: string) {
        this._template = value;
    }

    get thumbnailUrl(): string {
        return this._thumbnailUrl;
    }

    set thumbnailUrl(value: string) {
        this._thumbnailUrl = value;
    }
}
