<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Dossier</h4>

  <div class="tw-bg-red-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      <li class="tw-overflow-hidden">
        <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Responsable</div>

        <app-user-cartouche [link]="dossierBien.bien.linkResponsableDossier"
                            class="tw-block tw-h-8 tw-max-w-full tw-w-fit"/>
      </li>

      @if (dossierBien.bien.linkNotaire) {
        <li class="tw-overflow-hidden">
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Notaire</div>

          <app-user-cartouche [link]="dossierBien.bien.linkNotaire" class="tw-block tw-h-8 tw-max-w-full tw-w-fit"/>
        </li>
      }

      @if (dossierBien.isVente() && (vente.mandatDate || vente.mandatType)) {
        <li>
          Mandat {{ vente.mandatType.label | lowercase }}

          @if (vente.mandatDate) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">réalisé le</span> {{ vente.mandatDate | date }}
          }
        </li>
      }

      @if (dossierBien.isVente() && dossierBien.bien.causeVente) {
        <li class="tw-overflow-hidden">
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Cause de la vente</div>
          {{ dossierBien.bien.causeVente }}
        </li>
      }

      @if (dossierBien.isEstimation() && estimation.motif) {
        <li class="tw-overflow-hidden">
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Motif</div>
          {{ estimation.motif }}
        </li>
      }

      @if (dossierBien.isVente() && vente.referencePublique) {
        <li class="tw-overflow-hidden">
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Référence publique</div> {{ vente.referencePublique }}
        </li>
      }

      @if (dossierBien.bien.referenceInterne) {
        <li class="tw-overflow-hidden">
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Référence interne</div>
          {{ dossierBien.bien.referenceInterne }}
        </li>
      }

      @if (dossierBien.bien.clesVisite || dossierBien.bien.numeroCle) {
        <li class="tw-overflow-hidden">
          Clés

          @if (dossierBien.bien.numeroCle) {
            numéro {{ dossierBien.bien.numeroCle }}
          }

          @if (dossierBien.bien.clesVisite) {
            <div class="tw-text-gray-700 tw-font-normal">{{ dossierBien.bien.clesVisite }}</div>
          }
        </li>
      }

      @if (dossierBien.bien.panneaux) {
        <li class="tw-overflow-hidden">
          <div class="tw-text-gray-500 tw-text-xs tw-font-normal">Panneaux</div>

          <div class="tw-text-gray-700 tw-font-normal">{{ dossierBien.bien.panneaux }}</div>
        </li>
      }
    </ul>
  </div>
</div>
