import {Component, inject, Input, output} from '@angular/core';
import {tap} from 'rxjs/operators';
import Document from '@models/documents/document/document.model';
import {IDocumentConvivialNameOptions} from '@features/documents/documents.interfaces';
import {DocumentService} from '@models/documents/document/document.service';
import {Observable} from 'rxjs';

@Component({selector: 'app-document-convivial-name', templateUrl: 'document.convivial-name.component.html'})
export class AppDocumentConvivialNameComponent {
    readonly getDocument$ = output<Document>();
    readonly selectDocument = output<Document>();
    private _documentService = inject(DocumentService);
    private _document$!: Observable<Document>;
    private _options: IDocumentConvivialNameOptions = {withSelect: false};

    get document$(): Observable<Document> {
        return this._document$;
    }

    @Input()
    set id(id: number) {
        this._document$ = this._documentService.getOne$(id).pipe(
            tap(document => {
                if (!document.id) {
                    document.titre = 'Document inconnu';
                }
            }),
            tap(document => this.getDocument$.emit(document)),
        );
    }

    get options(): IDocumentConvivialNameOptions {
        return this._options;
    }

    @Input()
    set options(value: IDocumentConvivialNameOptions) {
        this._options = value;
    }
}
