import {inject, Injectable} from '@angular/core';
import Email from '@models/emails/email/email.model';
import {Observable} from 'rxjs';
import {EmailsApiService} from '@models/emails/emails.api.service';
import {map} from 'rxjs/operators';
import {NgEmail} from '@legacy/app/managers/ressources';
import {IEmailApi} from '@models/emails/email/email.interfaces';
import {EmailRecipientFactory} from '@models/emails/email/recipients/recipient/email-recipient.factory';

@Injectable({providedIn: 'root'})
export class EmailFactory {
    private _emailRecipientFactory = inject(EmailRecipientFactory);
    private _emailsApiService = inject(EmailsApiService);

    create(emailApi: IEmailApi): Email {
        const email: Email = this.createVirgin(emailApi.uuid, emailApi.id);

        email.message = emailApi.message;
        email.sendingDate = emailApi.sendingDate;
        email.statut = emailApi.statut;
        email.subject = emailApi.subject;
        if (emailApi.recipients?.length > 0) {
            email.recipients = emailApi.recipients.map(emailRecipientApi => this._emailRecipientFactory.create(emailRecipientApi));
        }

        return email;
    }

    createVirgin(uuid?: string, id?: number): Email {
        return new Email(uuid ?? Email.statuts.NEW, id);
    }

    get$(id: number): Observable<Email> {
        return this._emailsApiService.get$(id.toString()).pipe(map(emailApi => this.create(emailApi)));
    }

    ngCreate(ngEmail: NgEmail): Email {
        const email: Email = this.createVirgin(ngEmail.uuid, ngEmail.id);

        email.message = ngEmail.message;
        email.sendingDate = ngEmail.sendingDate.toString();
        email.statut = ngEmail.statut;
        email.subject = ngEmail.subject;
        if (ngEmail.recipients?.length > 0) {
            email.recipients = ngEmail.recipients.map(emailRecipientApi => this._emailRecipientFactory.ngCreate(emailRecipientApi));
        }

        return email;
    }
}
