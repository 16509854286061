import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IEtudeSettingsGenapiApi} from '@models/etudes/etude/settings/genapi/etude-settings-genapi.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeSettingsGenapiApiService {
    private _modelApiService = inject(ModelApiService);

    get$(etudeUuid: string): Observable<IEtudeSettingsGenapiApi> {
        return this._modelApiService.get$('/etudes/' + etudeUuid + '/settings/genapi');
    }
}
