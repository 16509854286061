import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import UserClientMetadata from '@models/users/user/client-metadata/user-client-metadata.model';
import User from '@models/users/user/user.model';
import {UserClientMetadataFactory} from '@models/users/user/client-metadata/user-client-metadata.factory';
import {take, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserClientMetadataService {
    private _userClientMetadataFactory = inject(UserClientMetadataFactory);

    getOneFromUser$(user: User): Observable<UserClientMetadata> {
        if (user.linkClientMetadata) {
            return this._userClientMetadataFactory.getByLink$(user.linkClientMetadata);
        }

        return of(this._userClientMetadataFactory.createVirgin());
    }

    saveFromUser$(user: User): Observable<UserClientMetadata> {
        const userClientMetadataToSave = this._userClientMetadataFactory.createVirgin();

        userClientMetadataToSave.config = {...user.clientMetadata.config};

        return this._userClientMetadataFactory.save$(user.id.toString(), userClientMetadataToSave).pipe(
            tap(userClientMetadata => user.clientMetadata = userClientMetadata),
            take(1),
        );
    }
}
