import {Injectable} from '@angular/core';
import TemplateBalise from '@models/templates/balises/balise/template-balise.model';
import {ITemplateBaliseApi} from '@models/templates/balises/balise/template-balise.interfaces';

@Injectable({providedIn: 'root'})
export class TemplateBaliseFactory {
    create(templateBaliseApi: ITemplateBaliseApi): TemplateBalise {
        const balise = new TemplateBalise();

        balise.code = templateBaliseApi.code;
        balise.description = templateBaliseApi.description;
        balise.path = templateBaliseApi.path;

        return balise;
    }
}
