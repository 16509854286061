export default `
<soq-panel ng-if="$ctrl.searchs" box="$ctrl.box">
  <tools-slot>
    <button type="button" class="tw-btn-tertiary-info tw-py-1 tw-px-2" ng-click="$ctrl.openConfig()">
      <fa-icon [icon]="'cogs'"></fa-icon>
    </button>
  </tools-slot>

  <content-slot>
    <form ng-submit="$ctrl.submit()">
      <uib-tabset ng-if="!!$ctrl.currentEtude" class="tabbable" type="pills">
        <!-- Critères -->
        <uib-tab heading="Critères" ng-click="$ctrl.searchs.rapidSearchType = 'criteria'">
          <div class="search-criteria">
            <div class="form-group">
              <label><fa-icon [icon]="['far', 'building']"></fa-icon> Nature</label>

              <esk-select-dictionary ng-model="$ctrl.searchs.criteria.natures[0]" dictionary="{{$ctrl.NATURES}}">
              </esk-select-dictionary>
            </div>

            <div class="form-group">
              <label><fa-icon [icon]="['far', 'map']"></fa-icon> Commune</label>

              <esk-select-commune options="{codePostalSelection: true, multiple: true, razOnSelect: true}"
                                  ng-model="$ctrl.searchs.communes"></esk-select-commune>
            </div>

            <div class="form-group price">
              <label><fa-icon [icon]="'euro-sign'"></fa-icon> Budget</label>

              <div class="input-price">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" class="form-control" placeholder="{{'Minimum'|translate}}"
                         ng-model="$ctrl.searchs.criteria.vente.budgetMin" soq-only-digits>

                  <span class="input-group-addon">€</span>
                </div>

                <div class="input-group input-group-number input-group-currency">
                  <input type="text" class="form-control" placeholder="{{'Maximum'|translate}}"
                         ng-model="$ctrl.searchs.criteria.vente.budgetMax" soq-only-digits>

                  <span class="input-group-addon">€</span>
                </div>

                <div class="prix-hni" title="Recherche effectuée sur les prix en Honoraires de Négociation Inclus">
                  <div class="checkbox clip-check check-primary">
                    <input type="checkbox" id="modal-search-vente-prix-hni"
                           ng-model="$ctrl.searchs.criteria.vente.budgetHni">

                    <label for="modal-search-vente-prix-hni">
                      {{"Budget HNI" | translate}}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group ventes-etude">
              <div class="checkbox clip-check check-primary">
                <input type="checkbox" id="search-option-biens-etude"
                       ng-model="$ctrl.searchs.criteria.option.uniquementBiensNotaire">

                <label for="search-option-biens-etude">
                  {{"Uniquement les biens de l'étude" | translate}}
                </label>
              </div>
            </div>
          </div>
        </uib-tab>

        <!-- Référence -->
        <uib-tab heading="Référence" ng-click="$ctrl.searchs.rapidSearchType = 'reference'">
          <div class="search-reference">
            <label>
              {{'Exemples : ' + $ctrl.currentEtude.crpcen + '-5863 ou ' + $ctrl.currentEtude.crpcen | translate}}
            </label>

            <div class="input-group">
              <input type="text" class="form-control" ng-model="$ctrl.searchs.reference.reference">

              <div class="input-group-btn">
                <button ng-if="!!$ctrl.searchs.reference.reference" type="button" class="btn btn-o btn-null"
                        ng-click="$ctrl.searchs.reference.reference = ''"><fa-icon [icon]="'times'"></fa-icon></button>
              </div>
            </div>
          </div>
        </uib-tab>

        <!-- Localisation -->
        <uib-tab heading="Localisation" ng-if="$ctrl.currentEtude.sendingAddressesBiensToConfreres"
                 ng-click="$ctrl.searchs.rapidSearchType = 'localisation'">
          <div class="search-localisation">
            <div class="form-group">
              <label><fa-icon [icon]="['far', 'map']"></fa-icon> Commune</label>

              <esk-select-commune ng-model="$ctrl.searchs.communes[0]"
                                  on-select="$ctrl.onSelectLocalisationCommune"></esk-select-commune>
            </div>

            <div ng-if="!!$ctrl.searchs.communes[0].id" class="localisation-precision">
              <div ng-if="$ctrl.searchs.communes[0].hasQuartiers" class="tw-text-center">
                <div class="radio-inline">
                  <div class="radio clip-radio radio-primary">
                    <input type="radio" id="search-localisation-type-adresse" name="searchLocalisationType"
                           ng-model="$ctrl.searchs.localisation.rapidSearchLocalisationType" ng-value="'adresse'"
                           ng-change="$ctrl.onChangeLocalisationType()">

                    <label for="search-localisation-type-adresse">Adresse</label>
                  </div>
                </div>

                <div class="radio-inline">
                  <div class="radio clip-radio radio-primary">
                    <input type="radio" id="search-localisation-type-quartier" name="searchLocalisationType"
                           ng-model="$ctrl.searchs.localisation.rapidSearchLocalisationType" ng-value="'quartier'"
                           ng-change="$ctrl.onChangeLocalisationType()">

                    <label for="search-localisation-type-quartier">Quartier</label>
                  </div>
                </div>
              </div>

              <ng-switch on="$ctrl.searchs.localisation.rapidSearchLocalisationType" class="precision-fields"
                         ng-class="$ctrl.searchs.communes[0].hasQuartiers ? 'quartier' : ''">
                <div ng-switch-when="quartier">
                  <label>Quartier</label>

                  <esk-select-quartier ng-model="$ctrl.searchs.localisation.quartiers[0]"
                                       commune="$ctrl.searchs.communes[0]"></esk-select-quartier>
                </div>

                <div ng-switch-default>
                  <label for="search-adresse">Adresse</label>

                  <span ng-switch="$ctrl.searchs.communes[0].hasVoies">
                    <esk-select-voie ng-switch-when="true" commune="$ctrl.searchs.communes[0]"
                                     ng-model="$ctrl.searchs.localisation.voie"></esk-select-voie>

                    <input ng-switch-when="false" type="text" id="search-adresse" class="form-control"
                           ng-model="$ctrl.searchs.localisation.nomVoie">
                  </span>
                </div>
              </ng-switch>
            </div>
          </div>
        </uib-tab>
      </uib-tabset>

      <button type="submit" class="btn btn-primary pull-right"><fa-icon [icon]="'search'"></fa-icon> Rechercher</button>
    </form>
  </content-slot>
</soq-panel>
`;
