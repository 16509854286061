import Links from '@models/links/links.model';
import {Observable, Subject} from 'rxjs';

export default class Collection<T = unknown> {
    private _links!: Links;
    private _page = 0;
    private _pages = 0;
    private _perPage = 0;
    private _results: T[] = [];
    private _total = 0;
    private _updatedSource = new Subject<void>();
    private _updated$ = this._updatedSource.asObservable();

    get links(): Links {
        return this._links;
    }

    set links(value: Links) {
        this._links = value;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get pages(): number {
        return this._pages;
    }

    set pages(value: number) {
        this._pages = value;
    }

    get perPage(): number {
        return this._perPage;
    }

    set perPage(value: number) {
        this._perPage = value;
    }

    get results(): T[] {
        return this._results;
    }

    set results(value: T[]) {
        this._results = value;
    }

    get total(): number {
        return this._total;
    }

    set total(value: number) {
        this._total = value;
    }

    get updated$(): Observable<void> {
        return this._updated$;
    }

    addResult(result: T, firstPosition = false): void {
        this.total++;
        if (firstPosition) {
            this.results.unshift(result);
        } else {
            this.results.push(result);
        }
        this._updatedSource.next();
    }

    updateNext(collection: Collection<T>): void {
        this.links.updateNext(collection.links);
        this.page = collection.page;
        this.pages = collection.pages;
        this.perPage = collection.perPage;
        this.results = this.results.concat(collection.results);
        this.total = collection.total;
        this._updatedSource.next();
    }

    removeResult(filterResults: (result: T) => boolean): void {
        const idx = this.results.findIndex(filterResults);

        if (idx < 0 || this.total < idx) {
            return;
        }

        this.results.splice(idx, 1);
        this.total--;
        this._updatedSource.next();
    }
}
