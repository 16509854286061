import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {BienSurfaceFactory} from '@models/bien/surfaces/surface/bien-surface.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BienSurfacesService {
    private _bienSurfaceFactory = inject(BienSurfaceFactory);

    save$(dossierBien: ADossierBien): Observable<void> {
        if (dossierBien.bien.surfaces.length <= 0) {
            return of(undefined);
        }

        return combineLatest(dossierBien.bien.surfaces.map(bienSurface => this._bienSurfaceFactory.save$(dossierBien, bienSurface)))
            .pipe(map(() => undefined));
    }
}
