import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import EtudeNetwork from '@models/etudes/etude/networks/network/etude-network.model';
import EtudeConstraint from '@models/etudes/etude/constraints/constraint/etude-constraint.model';
import environment from '@env/environment';
import Template from '@models/templates/template/template.model';
import Site from '@models/sites/site/site.model';
import User from '@models/users/user/user.model';
import EtudeSettingsNetwork from '@models/etudes/etude/settings/network/etude-settings-network.model';
import AModel from '@models/model.abstract';
import EtudeSettingsGenapi from '@models/etudes/etude/settings/genapi/etude-settings-genapi.model';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';

export default class Etude extends AModel {
    static readonly enablableModules = {
        CITYSCAN: {code: 'cityscan', label: 'Cityscan', srcImage: 'assets/images/cityscan/logo.ico'},
        INOT: {code: 'inot', label: 'iNot Office', srcImage: 'assets/images/inot/logo.png'},
        NETWORK: {code: 'network', label: 'Réseau'},
        NOTESTIM: {code: 'notestim', label: 'Notestim', srcImage: 'assets/images/notestim/logo.png'},
    };
    static readonly linkMarianne = {
        PLURAL: '/assets/img/marianne-notaires.svg',
        SINGULAR: '/assets/img/marianne-notaire.svg',
    };
    private _configurablesPasserelles: EtudePasserelle[] = [];
    private _constraints: EtudeConstraint[] = [];
    private _crpcen!: string;
    private _defaultTemplates: Template[] = [];
    private readonly _id: number;
    private _imprimerAffichetteConfrere!: boolean;
    private _linkBanner!: string;
    private _linkLogo!: string;
    private _linkMarianne = environment.api.baseUrl + Etude.linkMarianne.SINGULAR;
    private _linkSites!: string;
    private _linkUsers!: string;
    private _linkVentes!: string;
    private _mainNetwork!: EtudeNetwork;
    private _minNumberDaysBeforeAutoDiffusion = 0;
    private _nbNotaires!: number;
    private _networks: EtudeNetwork[] = [];
    private _passerelles: EtudePasserelle[] = [];
    private _passerellesNewspaper: EtudePasserelle[] = [];
    private _passerellesSocialNetwork: EtudePasserelle[] = [];
    private _passerellesWebsite: EtudePasserelle[] = [];
    private _previsite!: boolean;
    private _raisonSociale!: string;
    private _sendingAddressesBiensToConfreres!: boolean;
    private _settingsCityscan!: EtudeSettingsCityscan;
    private _settingsGenapi!: EtudeSettingsGenapi;
    private _settingsNetwork!: EtudeSettingsNetwork;
    private _sites: Site[] = [];
    private _users: User[] = [];

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get configurablesPasserelles(): EtudePasserelle[] {
        return this._configurablesPasserelles;
    }

    get constraints(): EtudeConstraint[] {
        return this._constraints;
    }

    set constraints(value: EtudeConstraint[]) {
        this._constraints = value;
    }

    get crpcen(): string {
        return this._crpcen;
    }

    set crpcen(value: string) {
        this._crpcen = value;
    }

    get defaultTemplates(): Template[] {
        return this._defaultTemplates;
    }

    set defaultTemplates(value: Template[]) {
        this._defaultTemplates = value;
    }

    get id(): number {
        return this._id;
    }

    get imprimerAffichetteConfrere(): boolean {
        return this._imprimerAffichetteConfrere;
    }

    set imprimerAffichetteConfrere(value: boolean) {
        this._imprimerAffichetteConfrere = value;
    }

    get linkBanner(): string {
        return this._linkBanner;
    }

    set linkBanner(value: string) {
        this._linkBanner = value;
    }

    get linkLogo(): string {
        return this._linkLogo || this._linkMarianne;
    }

    set linkLogo(value: string) {
        this._linkLogo = value;
    }

    get linkMarianne(): string {
        return this._linkMarianne;
    }

    get linkRawLogo(): string {
        return this._linkLogo;
    }

    get linkSites(): string {
        return this._linkSites;
    }

    set linkSites(value: string) {
        this._linkSites = value;
    }

    get linkUsers(): string {
        return this._linkUsers;
    }

    set linkUsers(value: string) {
        this._linkUsers = value;
    }

    get linkVentes(): string {
        return this._linkVentes;
    }

    set linkVentes(value: string) {
        this._linkVentes = value;
    }

    get mainNetwork(): EtudeNetwork {
        return this._mainNetwork;
    }

    get minNumberDaysBeforeAutoDiffusion(): number {
        return this._minNumberDaysBeforeAutoDiffusion;
    }

    get nbNotaires(): number {
        return this._nbNotaires;
    }

    set nbNotaires(value: number) {
        this._nbNotaires = value;
        this._linkMarianne = environment.api.baseUrl + (this.nbNotaires > 1 ? Etude.linkMarianne.PLURAL : Etude.linkMarianne.SINGULAR);
    }

    get networks(): EtudeNetwork[] {
        return this._networks;
    }

    set networks(value: EtudeNetwork[]) {
        this._networks = value;
        this._mainNetwork = this._networks[0];

        const minNumberDaysBeforeAutoDiffusion = Math.min(...this.networks.map(etudeNetWork => etudeNetWork.numberDaysBeforeAutoDiffusion));

        this._minNumberDaysBeforeAutoDiffusion = minNumberDaysBeforeAutoDiffusion !== Infinity ? minNumberDaysBeforeAutoDiffusion : 0;
    }

    get passerelles(): EtudePasserelle[] {
        return this._passerelles;
    }

    set passerelles(value: EtudePasserelle[]) {
        this._passerelles = value;
        this._configurablesPasserelles = this.passerelles.filter(etudePasserelle => etudePasserelle.hasConfigurator());
        this._passerellesNewspaper = this.passerelles.filter(etudePasserelle => etudePasserelle.isNewspaper());
        this._passerellesSocialNetwork = this.passerelles.filter(etudePasserelle => etudePasserelle.isSocialNetwork());
        this._passerellesWebsite = this.passerelles.filter(etudePasserelle => etudePasserelle.isWebsite());
    }

    get passerellesNewspaper(): EtudePasserelle[] {
        return this._passerellesNewspaper;
    }

    get passerellesSocialNetwork(): EtudePasserelle[] {
        return this._passerellesSocialNetwork;
    }

    get passerellesWebsite(): EtudePasserelle[] {
        return this._passerellesWebsite;
    }

    get previsite(): boolean {
        return this._previsite;
    }

    set previsite(value: boolean) {
        this._previsite = value;
    }

    get raisonSociale(): string {
        return this._raisonSociale;
    }

    set raisonSociale(value: string) {
        this._raisonSociale = value;
    }

    get sendingAddressesBiensToConfreres(): boolean {
        return this._sendingAddressesBiensToConfreres;
    }

    set sendingAddressesBiensToConfreres(value: boolean) {
        this._sendingAddressesBiensToConfreres = value;
    }

    get settingsCityscan(): EtudeSettingsCityscan {
        return this._settingsCityscan;
    }

    set settingsCityscan(value: EtudeSettingsCityscan) {
        this._settingsCityscan = value;
    }

    get settingsGenapi(): EtudeSettingsGenapi {
        return this._settingsGenapi;
    }

    set settingsGenapi(value: EtudeSettingsGenapi) {
        this._settingsGenapi = value;
    }

    get settingsNetwork(): EtudeSettingsNetwork {
        return this._settingsNetwork;
    }

    set settingsNetwork(value: EtudeSettingsNetwork) {
        this._settingsNetwork = value;
    }

    get sites(): Site[] {
        return this._sites;
    }

    set sites(value: Site[]) {
        this._sites = value;
    }

    get users(): User[] {
        return this._users;
    }

    set users(value: User[]) {
        this._users = value;
    }

    hasNetwork(): boolean {
        return this.networks.length > 0;
    }

    hasNewspaper(): boolean {
        return this.passerellesNewspaper.length > 0;
    }

    hasWebsite(): boolean {
        return this.passerellesWebsite.length > 0;
    }

    isEnablabledModules(enablableModules: string[] = []): boolean {
        const enablabledModules = enablableModules
            .map(enablableModule => {
                if (enablableModule === Etude.enablableModules.INOT.code) {
                    return this.settingsGenapi.inotEnabled;
                }

                if (enablableModule === Etude.enablableModules.NETWORK.code) {
                    return this.hasNetwork();
                }

                return true;
            })
            .filter(isEnabled => isEnabled);

        return enablabledModules.length === enablableModules.length;
    }
}
