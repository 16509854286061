export default `
<div class="container-fluid container-fluid-title"><h1>Critères de recherche de références archivées</h1></div>

<div ng-if="$ctrl.etude" ng-switch="!!$ctrl.options.creatingMessage" class="container-fluid container-fullw tw-bg-white">
  <esk-search-criteria search="$ctrl.search" update-search-localisations="$ctrl.updateSearchLocalisations"
                       options="{disabledFields: {fieldsetBudget: true, fieldsetSource: !$ctrl.etude.hasNetwork(), sourceCityscan: true, sourceReferences: true, uniquementBiensNotaire: true}}">
  </esk-search-criteria>

  <button type="button" ng-click="$ctrl.launch()" class="btn btn-primary pull-right btn-save"
          ng-disabled="!!$ctrl.loadingSearch" ng-switch="!!$ctrl.loadingSearch">
    <span ng-switch-when="true"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Lancement de la recherche</span>

    <span ng-switch-default><fa-icon [icon]="'check'"></fa-icon> Lancer la recherche</span>
  </button>
</div>
`;
