import {Component, inject, Input, OnInit, output} from '@angular/core';
import {Observable} from 'rxjs';
import COffreachats from '@models/offreachats/collection/offreachats.collection.model';
import {take} from 'rxjs/operators';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {COffreachatsService} from '@models/offreachats/collection/offreachats.collection.service';
import {IOffreachatsListOptions} from '@features/offreachats/offreachats.interfaces';
import {SortConst} from '@shared/constants';

@Component({selector: 'app-offreachats-list', templateUrl: 'offreachats.list.component.html'})
export class AppOffreachatsListComponent implements OnInit {
    readonly clicked = output<[HTMLButtonElement, Offreachat]>();
    static readonly initOffreachatsListOptions: IOffreachatsListOptions = {showDemandeur: true, showVente: true};
    private _cOffreachatsService = inject(COffreachatsService);
    private _demandeurUuids!: string[];
    private _options: IOffreachatsListOptions = {...AppOffreachatsListComponent.initOffreachatsListOptions};
    private _pendingGetMore = false;
    private _venteUuids!: string[];

    get cOffreachats$(): Observable<COffreachats> {
        return this._cOffreachatsService.current$;
    }

    @Input()
    set demandeurUuids(value: string[]) {
        this._demandeurUuids = value;
    }

    get options(): IOffreachatsListOptions {
        return this._options;
    }

    @Input()
    set options(value: IOffreachatsListOptions) {
        this._options = {...AppOffreachatsListComponent.initOffreachatsListOptions, ...value};
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    @Input()
    set venteUuids(value: string[]) {
        this._venteUuids = value;
    }

    ngOnInit(): void {
        this._cOffreachatsService.initCurrent({
            demandeurUuid: this._demandeurUuids,
            tris: {createdAt: SortConst.DESCENDING},
            venteUuid: this._venteUuids,
        });
    }

    click(htmlButtonElement: HTMLButtonElement, offreachat: Offreachat): void {
        this.clicked.emit([htmlButtonElement, offreachat]);
    }

    getMore(): void {
        this._pendingGetMore = true;
        this._cOffreachatsService.addNextToCurrent$().pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
