@if (mustBeOpen$ | appLoadObs | async; as mustBeOpen) {
  <div class="tw-hidden md:tw-block tw-w-56 tw-bg-gray-100 tw-overflow-y-auto tw-h-full" #navigationLateral
       [@openClose]="skipAnimations || mustBeOpen.value"
       [ngClass]="mustBeOpen.value ? '!tw-block tw-absolute tw-shadow-xl' : ''" (appClickAway)="close()">
    @for (section of sections; track section; let first = $first) {
      <div class="tw-mb-4">
        @if (!first) {
          <div class="tw-mx-2 tw-border-t"></div>
        }

        @if (section.title) {
          <div class="tw-pt-4 tw-px-2 tw-pb-2 tw-text-xs tw-text-center tw-truncate">
            {{ section.title }}
          </div>
        }

        @for (menu of section.menus; track menu) {
          <div class="tw-space-y-1 tw-text-sm" (click)="clickMenu($event)">
            <a [routerLink]="menu.route" #rlaMain="routerLinkActive"
               class="tw-group tw-w-full tw-flex tw-items-center tw-py-3 tw-px-4 tw-border-solid tw-border-l-4 tw-border-transparent tw-text-gray-600 hover:tw-bg-gray-50 hover:tw-text-gray-600 focus:tw-text-gray-600"
               [routerLinkActive]="menu.subMenus ? '' : 'tw-bg-gray-200 tw-border-blue-500 hover:tw-bg-gray-200'">
              <span class="tw-h-6 tw-w-6 tw-mr-2 tw-text-gray-400 group-hover:tw-text-gray-400 tw-shrink-0"
                    routerLinkActive="tw-text-blue-500 group-hover:tw-text-blue-500" [ngClass]="menu.colorIcon">
                @if (menu.code === 'DOCUMENTS') {
                  <span><fa-icon [icon]="['far', 'file']"/></span>
                }

                @if (menu.code === 'DOSSIERS') {
                  <span><fa-icon [icon]="'align-justify'"/></span>
                }

                @if (menu.code === 'ETUDE') {
                  <span><fa-icon [icon]="'building-columns'"/></span>
                }

                @if (menu.code === 'GED') {
                  <span><fa-icon [icon]="'file'"/></span>
                }

                @if (menu.code === 'LOCATION') {
                  <span><fa-icon [icon]="'key'"/></span>
                }

                @if (menu.code === 'NETWORK') {
                  <span><fa-icon [icon]="'network-wired'"/></span>
                }

                @if (menu.code === 'OVERVIEW') {
                  <span><fa-icon [icon]="'eye'"/></span>
                }

                @if (menu.code === 'PASSERELLES') {
                  <span><fa-icon [icon]="'rss'"/></span>
                }

                @if (menu.code === 'SUIVI') {
                  <span><fa-icon [icon]="['far', 'calendar']"/></span>
                }

                @if (menu.code === 'VENTE') {
                  <span><fa-icon [icon]="'house'"/></span>
                }
              </span>

              <span [routerLinkActive]="menu.subMenus ? '' : 'tw-text-gray-900 focus:tw-text-gray-900'"
                    class="tw-flex-1" routerLinkActive="tw-font-medium">{{ menu.label }}</span>
            </a>

            @if (menu.subMenus) {
              <div [ngClass]="rlaMain.isActive ? 'tw-block' : 'tw-hidden'">
                @for (subMenu of menu.subMenus; track subMenu) {
                  <a class="tw-group tw-w-full tw-flex tw-items-center tw-py-3 tw-pr-4 tw-pl-12 tw-border-solid tw-border-l-4 tw-border-transparent tw-text-gray-600 hover:tw-bg-gray-50 hover:tw-text-gray-600 focus:tw-text-gray-600"
                     routerLinkActive="tw-bg-gray-200 tw-border-blue-500 hover:tw-bg-gray-200 tw-font-medium tw-text-gray-900 hover:tw-text-gray-900 focus:tw-text-gray-900"
                     [routerLink]="subMenu.route">{{ subMenu.label }}</a>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}
