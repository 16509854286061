import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgEskBox, NgEskDashboard} from '@legacy/app/eskimmo/eskimmo';
import {NgAreaManager} from '@legacy/app/managers/managers';
import {UserService} from '@models/users/user/user.service';
import {NgAuthenticationDataInit} from '@legacy/app/auth/auth';
import {firstValueFrom, of, switchMap} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {map, tap} from 'rxjs/operators';
import {NgBienFieldsConstraints} from '@legacy/app/biens/biens';
import {DictionariesService} from '@models/dictionaries/dictionaries.service';

export default function getAuthDataInit(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('AuthenticationDataInit', Service);

        /**
         * AuthenticationDataInit
         *
         * @param $q
         * @param AreaManager
         * @param BienFieldsConstraints
         * @param EskBox
         * @param Ng2EtudeService
         * @param Ng2UserService
         * @param EskDashboard
         * @param Ng2DictionariesService
         */
        Service.$inject = ['$q', 'AreaManager', 'BienFieldsConstraints', 'EskBox', 'Ng2EtudeService', 'Ng2UserService', 'EskDashboard', 'Ng2DictionariesService'];
        function Service(this: NgAuthenticationDataInit,
                         $q: IQService,
                         areaManager: NgAreaManager,
                         bienFieldsConstraints: NgBienFieldsConstraints,
                         eskBox: NgEskBox,
                         ng2EtudeService: EtudeService,
                         ng2UserService: UserService,
                         eskDashboard: NgEskDashboard,
                         ng2DictionariesService: DictionariesService) {
            const self = this;

            self.loadDictionaries = loadDictionaries;
            self.getCurrentProfile = getCurrentProfile;

            function loadDictionaries(onlyStatics = false): IPromise<boolean> {
                if (onlyStatics) {
                    return firstValueFrom(ng2DictionariesService.init$());
                }

                return firstValueFrom(ng2EtudeService.last$.pipe(
                    switchMap(etude => ng2DictionariesService.init$(onlyStatics, etude)),
                )).then(update => onlyStatics ? $q.resolve(update) : areaManager.load().then(_ => update));
            }

            function getCurrentProfile() {
                ng2UserService.initCurrent();

                return firstValueFrom(ng2UserService.last$.pipe(
                    switchMap(currentUser => {
                        if (currentUser.linkEtude) {
                            ng2EtudeService.initCurrent();

                            return ng2EtudeService.last$.pipe(tap(currentEtude => bienFieldsConstraints.set(currentEtude.constraints)));
                        }

                        return of(undefined);
                    }),
                    switchMap(_ => eskBox.init$()),
                    map(_ => eskDashboard.init()),
                ));
            }
        }
    })(angularJS);
}
