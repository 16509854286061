import {Injectable} from '@angular/core';
import {IVentePrixHistoryApi} from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.interfaces';
import VentePrixHistory from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.model';

@Injectable({providedIn: 'root'})
export class VentePrixHistoryFactory {
    create(ventePrixHistoryApi: IVentePrixHistoryApi): VentePrixHistory {
        const ventePrixHistory = new VentePrixHistory(ventePrixHistoryApi.uuid, ventePrixHistoryApi.id);

        ventePrixHistory.createdAt = ventePrixHistoryApi.createdAt;
        ventePrixHistory.prixVente = ventePrixHistoryApi.prixVente;

        return ventePrixHistory;
    }
}
