import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

export default class VenteArchiveInfos extends AModel {
    private _acquereur!: string;
    private _comments!: string;
    private _dateVente!: string;
    private _demandeurId!: number;
    private _etudeAcquereurId!: number;
    private _honorairesNego!: number;
    private _honorairesNegoPercus!: number;
    private _linkDemandeur!: string;
    private _linkEtudeAcquereur!: string;
    private _prixCession!: number;
    private _raisonVenteNegociee!: DictionaryItem;
    private _raisonVenteNonNegociee!: DictionaryItem;
    private _type!: DictionaryItem;

    get acquereur(): string {
        return this._acquereur;
    }

    set acquereur(value: string) {
        this._acquereur = value;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get dateVente(): string {
        return this._dateVente;
    }

    set dateVente(value: string) {
        this._dateVente = value;
    }

    get demandeurId(): number {
        return this._demandeurId;
    }

    set demandeurId(value: number) {
        this._demandeurId = value;
    }

    get etudeAcquereurId(): number {
        return this._etudeAcquereurId;
    }

    set etudeAcquereurId(value: number) {
        this._etudeAcquereurId = value;
    }

    get honorairesNego(): number {
        return this._honorairesNego;
    }

    set honorairesNego(value: number) {
        this._honorairesNego = value;
    }

    get honorairesNegoPercus(): number {
        return this._honorairesNegoPercus;
    }

    set honorairesNegoPercus(value: number) {
        this._honorairesNegoPercus = value;
    }

    get linkDemandeur(): string {
        return this._linkDemandeur;
    }

    set linkDemandeur(value: string) {
        this._linkDemandeur = value;
    }

    get linkEtudeAcquereur(): string {
        return this._linkEtudeAcquereur;
    }

    set linkEtudeAcquereur(value: string) {
        this._linkEtudeAcquereur = value;
    }

    get prixCession(): number {
        return this._prixCession;
    }

    set prixCession(value: number) {
        this._prixCession = value;
    }

    get raisonVenteNegociee(): DictionaryItem {
        return this._raisonVenteNegociee;
    }

    set raisonVenteNegociee(value: DictionaryItem) {
        this._raisonVenteNegociee = value;
    }

    get raisonVenteNonNegociee(): DictionaryItem {
        return this._raisonVenteNonNegociee;
    }

    set raisonVenteNonNegociee(value: DictionaryItem) {
        this._raisonVenteNonNegociee = value;
    }

    get type(): DictionaryItem {
        return this._type;
    }

    set type(value: DictionaryItem) {
        this._type = value;
    }
}
