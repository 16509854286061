import angularJS from '@shared/angularJS/global.ng';
import Chart from 'chart.js/auto';
import {IAttributes, IDirectiveFactory, IModule, Injectable, IScope, IWindowService} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getSoqChart(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-chart
         */
        module.directive('soqChart', directive as Injectable<IDirectiveFactory>);

        /**
         * Method to init Chart
         *
         * @param $window
         * @param Chart
         * @returns {restrict: string, link: Function}
         */
        directive.$inject = ['$window'];
        function directive($window: IWindowService) {
            return {restrict: 'A', link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes) {
                const canvas = $element[0] as HTMLCanvasElement;
                const chartData = $scope.$eval($attrs.soqChart);
                let chart: Chart;

                $window.addEventListener('resize', updateChart);
                $element.on('$destroy', () => $window.removeEventListener('resize', updateChart));
                updateChart();

                /**
                 * Update Chart
                 */
                function updateChart() {
                    if (!chartData) {
                        return;
                    }

                    if (chartData.getOptions) {
                        chartData.options = angular.merge(chartData.options, chartData.getOptions($window.innerWidth));
                    }

                    if (chart) {
                        angular.merge(chart.options, chartData.options);
                    } else {
                        chart = new Chart(canvas, chartData);
                    }

                    chart.update();
                }
            }
        }
    })(angularJS);
}
