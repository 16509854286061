import {inject, Injectable} from '@angular/core';
import {IDossierExportApi} from '@models/dossiers/dossier/exports/export/dossier-export.interfaces';
import DossierExport from '@models/dossiers/dossier/exports/export/dossier-export.model';
import {
    DossierExportQualifiedContactsFactory
} from '@models/dossiers/dossier/exports/export/contacts/dossier-export.qualified-contacts.factory';
import {
    DossierExportMediasFactory
} from '@models/dossiers/dossier/exports/export/medias/dossier-export.medias.factory';

@Injectable({providedIn: 'root'})
export class DossierExportFactory {
    private _dossierExportMediasFactory = inject(DossierExportMediasFactory);
    private _dossierExportQualifiedContactsFactory = inject(DossierExportQualifiedContactsFactory);

    create(dossierExportApi: IDossierExportApi): DossierExport {
        const dossierExport = new DossierExport(dossierExportApi.uuid);

        dossierExport.contacts = this._dossierExportQualifiedContactsFactory.create(dossierExportApi.contacts);
        dossierExport.createdAt = dossierExportApi.createdAt;
        dossierExport.medias = this._dossierExportMediasFactory.create(dossierExportApi.medias);
        dossierExport.processedAt = dossierExportApi.processedAt;
        dossierExport.statut = dossierExportApi.status;
        if (dossierExportApi._links?.creator) {
            dossierExport.linkCreator = dossierExportApi._links.creator.href;
        }

        return dossierExport;
    }
}
