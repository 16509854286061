@if (reference$ | async; as reference) {
  <div class="tw-bg-gray-50 tw-px-4 tw-py-6 sm:tw-px-6">
    <h2 class="tw-mr-8 tw-text-base tw-font-semibold tw-text-gray-900">
      Vendu le {{ reference.dateVente | date:'longDate' }}
    </h2>
  </div>

  <div class="tw-p-8 tw-divide-y tw-divide-gray-200 tw-space-y-8 tw-text-sm">
    <div class="tw-space-y-4 tw-text-base tw-text-gray-700">
      <div>
        <span class="tw-font-medium tw-text-gray-900">{{ reference.nature.label }}</span> à
        <span class="tw-font-medium tw-text-gray-900">
          <app-commune-convivial-name [linkCommune]="reference.linkCommune"/>
        </span>
      </div>

      <div>{{ reference.adresse }}</div>
    </div>

    @if (reference.epoque || reference.nombrePieces || reference.nombreChambres || reference.nombreEtages) {
      <div class="tw-flex tw-pt-8 tw-text-gray-700 tw-space-x-4 sm:tw-space-x-8">
        @if (reference.epoque) {
          <div>Construction {{ reference.epoque.label | lowercase }}</div>
        }

        @if (reference.nombrePieces) {
          <div [ngPlural]="reference.nombrePieces">
            {{ reference.nombrePieces }}
            <ng-template ngPluralCase="=1">Pièce</ng-template>
            <ng-template ngPluralCase="other">Pièces</ng-template>
          </div>
        }

        @if (reference.nombreChambres) {
          <div [ngPlural]="reference.nombreChambres">
            {{ reference.nombreChambres }}
            <ng-template ngPluralCase="=1">Chambre</ng-template>
            <ng-template ngPluralCase="other">Chambres</ng-template>
          </div>
        }

        @if (reference.nombreEtages) {
          <div [ngPlural]="reference.nombreEtages">
            {{ reference.nombreEtages }}
            <ng-template ngPluralCase="=1">Étage</ng-template>
            <ng-template ngPluralCase="other">Étages</ng-template>
          </div>
        }
      </div>
    }

    <div class="tw-space-y-4 tw-pt-8">
      @if (reference.loyer) {
        <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
          <div>Loyer</div>

          <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0 tw-text-base tw-text-gray-700">
            {{ reference.loyer | textCurrency }}
          </div>
        </div>
      }

      @if (reference.distance > 0) {
        <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
          <div>Distance</div>

          <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0">
            <div class="tw-text-base tw-text-gray-700"> {{ reference.distance | textCurrency:'m' }}</div>

            <div>par rapport à l'adresse de votre bien</div>
          </div>
        </div>
      }

      @if (reference.surfaceCadastrale) {
        <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
          <div>Surface cadastrale</div>

          <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0 tw-text-base tw-text-gray-700">
            {{ reference.surfaceCadastrale | textCurrency:'m²' }}
          </div>
        </div>
      }

      @if (reference.superficie) {
        <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
          <div>{{ superficieLabel }}</div>

          <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0 tw-text-base tw-text-gray-700">
            {{ reference.superficie | textCurrency:'m²' }}
          </div>
        </div>
      }

      @if (reference.prixVente) {
        <div class="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
          <div>Prix de vente</div>

          <div class="sm:tw-col-span-2 tw-mt-1 sm:tw-mt-0">
            <div class="tw-text-base tw-text-gray-700">{{ reference.prixVente | textCurrency }}</div>

            <div>soit {{ reference.prixM2 | textCurrency }} du m²</div>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="tw-px-4 tw-py-6 sm:tw-px-6">
    <div class="tw-mb-10 tw-pr-12"><app-loader [classHeight]="'tw-h-6'"/></div>

    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
  </div>
}
