export default `
<div class="vente-modal-contrats">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="{{'vente.contrats.modal.CLOSE_TITLE' | translate: $ctrl.translateParams}}">
      <fa-icon [icon]="'times'"></fa-icon>
    </button>

    <h3 class="modal-title">
      <fa-icon [icon]="'sync-alt'"></fa-icon> {{'vente.contrats.modal.TITLE' | translate: $ctrl.translateParams}}
    </h3>
  </div>

  <div class="modal-body">
    <!-- Type de document -->
    <div ng-if="$ctrl.vente.type.code !== $ctrl.INTERACTIVE" class="contrats-type">
      <label>Type de document</label>

      <div class="padding-left-20">
        <div class="radio clip-radio radio-primary radio-inline">
          <input type="radio" id="type-document-mandat" name="typeContrat" value="mandat"
                 ng-model="$ctrl.contratsOptions.type" ng-change="$ctrl.setTranslateParams()">

          <label for="type-document-mandat">Mandat</label>
        </div>

        <div class="radio clip-radio radio-primary radio-inline">
          <input type="radio" id="type-document-avenant" name="typeContrat" value="avenant"
                 ng-model="$ctrl.contratsOptions.type" ng-change="$ctrl.setTranslateParams()">

          <label for="type-document-avenant">Avenant</label>
        </div>
      </div>
    </div>

    <!-- Courrier -->
    <div ng-switch="$ctrl.contratsOptions.type">
      <label>{{"Modèle de courrier" | translate}}</label>

      <esk-select-template ng-switch-when="avenant" ng-model="$ctrl.contratsOptions.avenant.courrier"
                           codes="[$ctrl.contratsOptions.codePrefix + 'mandat-avenant-courrier-introductif']"
                           options="$ctrl.contratsOptions.courrier.options"></esk-select-template>

      <esk-select-template ng-switch-default ng-model="$ctrl.contratsOptions.mandat.courrier"
                           codes="[$ctrl.contratsOptions.codePrefix + 'mandat-courrier-introductif']"
                           options="$ctrl.contratsOptions.courrier.options"></esk-select-template>
    </div>

    <!-- Contrat -->
    <div ng-switch="$ctrl.contratsOptions.type" class="padding-top-20">
      <label>{{'vente.contrats.modal.CONTRAT_LABEL' | translate: $ctrl.translateParams}}</label>

      <esk-select-template ng-switch-when="avenant" ng-model="$ctrl.contratsOptions.avenant.template"
                           codes="[$ctrl.contratsOptions.codePrefix + 'mandat-avenant']"
                           options="$ctrl.contratsOptions.avenant.options"></esk-select-template>

      <esk-select-template ng-switch-default ng-model="$ctrl.contratsOptions.mandat.template"
                           codes="[$ctrl.contratsOptions.codePrefix + 'mandat']"
                           options="$ctrl.contratsOptions.mandat.options"></esk-select-template>
    </div>

    <!-- Contrat par mandant -->
    <div class="row contrats-mandant">
      <div class="col-sm-4">
        <div class="checkbox clip-check check-primary checkbox-inline">
          <input type="checkbox" id="contrat-mandant" ng-model="$ctrl.contratsOptions.contratMandant"
                 ng-change="$ctrl.setTranslateParams()">

          <label for="contrat-mandant">
            {{'vente.contrats.modal.contrat_mandant.LABEL' | translate: $ctrl.translateParams}}
          </label>
        </div>
      </div>

      <div class="col-sm-8 mandant-explanation">
        {{'vente.contrats.modal.contrat_mandant.EXPLANATION' | translate: $ctrl.translateParams}}
      </div>

      <div ng-if="$ctrl.contratsOptions.contratMandant" class="col-xs-12">
        <esk-select ng-model="$ctrl.contratsOptions.mandants" list="$ctrl.contacts.collection" is-multiple
                    options="$ctrl.contacts.options"></esk-select>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-switch
      on="$ctrl.contratsOptions.courrier.options.loading || $ctrl.contratsOptions[$ctrl.contratsOptions.type].options.loading">
      <p class="text-muted" ng-switch-when="true">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
      </p>

      <button type="button" ng-switch-default class="btn btn-primary" ng-click="$close($ctrl.contratsOptions)">
        <fa-icon [icon]="'check'"></fa-icon> Ok
      </button>
    </ng-switch>
  </div>
</div>
`;
