import angularJS from '@shared/angularJS/global.ng';
import {map} from 'lodash';
import {IModule, IQService} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgDemandeur} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {ConditionConst} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import Vente from '@models/ventes/vente/vente.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';

export default function getDemandeurModalArchiveCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('DemandeurModalArchiveCtrl', Controller);

        /**
         * Controller of modal for archive Demandeur
         *
         * @param $uibModalInstance
         * @param $translate
         * @param demandeur
         * @param DictionariesManager
         * @param SoqSweetAlert
         * @param $q
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['$uibModalInstance', '$translate', 'demandeur', 'DictionariesManager', 'SoqSweetAlert', '$q', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $translate: angular.translate.ITranslateService,
                            demandeur: NgDemandeur,
                            dictionariesManager: NgDictionariesManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            $q: IQService,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.getArchivedVentes = getArchivedVentes;
            $ctrl.saveDemandeur = saveDemandeur;
            $ctrl.archiveDemandeur = archiveDemandeur;
            $ctrl.DEMANDEUR_ARCHIVE_VENTE_TYPES = Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_TYPES;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES = Vente.archiveNegocieeRaisons.AUTRES;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE = Vente.archiveNegocieeRaisons.PORTEFEUILLE;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU = Vente.archiveNegocieeRaisons.RESEAU;
            $ctrl.CODE_ARCHIVE_TYPES_VENTE_TROUVEE = Demandeur.archiveTypes.VENTE_TROUVEE;
            $ctrl.sending = false;
            $ctrl.raisonsArchiveDemandeurVenteNegociee = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS));
            $ctrl.typesArchiveDemandeurVente = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_TYPES));
            $ctrl.options = {
                ventes: {
                    interne: {name: 'archiveVenteId', placeholder: 'Trouver le bien', required: true, conditions: {}},
                    all: {name: 'archiveVenteAllId', placeholder: 'Trouver le bien', required: true, conditions: {}},
                },
                date: {maxDate: DateFormat.toDate()},
            };
            demandeur.getArchiveInfos().subscribe(archiveInfos => {
                $ctrl.archive = archiveInfos;
                $ctrl.archive.raisonVenteTrouvee = $ctrl.raisonsArchiveDemandeurVenteNegociee.find(item => item.uuid === $ctrl.archive.raisonVenteTrouvee.uuid)
                $ctrl.archive.type = $ctrl.typesArchiveDemandeurVente.find(item => item.uuid === $ctrl.archive.type.uuid)
            });
            $ctrl.getArchivedVentes();

            /**
             * Get or not archived ventes
             */
            function getArchivedVentes() {
                var params;
                var statutCondition;

                if (!$ctrl.archivedVentes) {
                    statutCondition = {condition: ConditionConst.NOT_IN, values: [Vente.statuts.ARCHIVE]};
                }

                params = angular.copy($ctrl.options.ventes.interne);
                params.conditions.statuts = statutCondition;
                $ctrl.options.ventes.interne = angular.copy(params);

                params = angular.copy($ctrl.options.ventes.all);
                params.conditions.statuts = statutCondition;
                $ctrl.options.ventes.all = angular.copy(params);
            }

            /**
             * Save demandeur information
             */
            function saveDemandeur() {
                $ctrl.sending = true;

                saveArchiveInfosDemandeur().then(() => $uibModalInstance.dismiss()).finally(() => $ctrl.sending = false);
            }

            /**
             * Archive demandeur
             */
            function archiveDemandeur() {
                $ctrl.sending = true;
                $ctrl.editForm.$submitted = true;

                saveArchiveInfosDemandeur().then(() => {
                    soqSweetAlert.dangerConfirmation(
                        $translate.instant('demandeur.archive.confirm.TITLE'),
                        getConfirmation($ctrl.archive, demandeur.reference),
                        {confirmButtonText: $translate.instant('demandeur.archive.confirm.CONFIRMATION')}
                    ).then(() => demandeur.archive($ctrl.archive).then(() => $uibModalInstance.close())).catch(
                        () => $uibModalInstance.dismiss()
                    );
                }, (rejection) => {
                    if (rejection.status === 422 && angular.isObject(rejection.data) && angular.isArray(rejection.data.errors) && rejection.data.errors.length > 0) {
                        soqSweetAlert.warningMessage($translate.instant('demandeur.save.TITLE', {nbMissingData: rejection.data.errors.length}), $translate.instant('demandeur.save.MESSAGE', {
                            nbMissingData: rejection.data.errors.length,
                            missingData: map(rejection.data.errors, 'message').join('<br>- ')
                        }));
                    }
                }).finally(() => $ctrl.sending = false);
            }

            /**
             * Save demandeur
             *
             * @returns {Promise}
             */
            function saveArchiveInfosDemandeur() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return $q.reject();
                }

                return demandeur.saveArchiveInfos($ctrl.archive);
            }

            /**
             * Get template confirmation
             *
             * @param archive
             * @param reference
             * @returns {string}
             */
            function getConfirmation(archive: any, reference: string) {
                const html = [] as string[];

                html.push("<p class=\"text-justify\">Pour le demandeur <span class=\"text-bold\">" + reference + "</span>, vous déclarez ");
                if (archive.type.code === Demandeur.archiveTypes.VENTE_TROUVEE) {
                    html.push("avoir trouvé la vente <span class=\"text-bold\">");
                    if (archive.raisonVenteTrouvee.code === Vente.archiveNegocieeRaisons.PORTEFEUILLE || archive.raisonVenteTrouvee.code === Vente.archiveNegocieeRaisons.RESEAU) {
                        html.push(archive.vente.bien.reference);
                    } else {
                        html.push(archive.referenceAutres);
                    }
                    html.push("</span>");
                    if (archive.raisonVenteTrouvee.code === Vente.archiveNegocieeRaisons.PORTEFEUILLE) {
                        html.push(" par le biais de votre propre portefeuille,");
                    } else if (archive.raisonVenteTrouvee.code === Vente.archiveNegocieeRaisons.RESEAU) {
                        if (archive.reseau !== null) {
                            html.push(" par le biais du réseau <span class=\"text-bold\">" + archive.reseau.name + "</span>");
                        }
                    }
                    html.push(" au prix de <span class=\"text-bold\">" + archive.prixAchat + " € net vendeur</span>.");
                    if (archive.raisonVenteTrouvee.code !== Vente.archiveNegocieeRaisons.PORTEFEUILLE) {
                        html.push("<br>Les honoraires perçus sont de " + archive.honorairesNegoPercus + " € T.T.C.");
                    }
                } else if (archive.type.code === Demandeur.archiveTypes.VENTE_NON_TROUVEE) {
                    html.push("ne pas avoir trouvé de bien en vente à sa recherche.");
                } else {
                    html.push("qu'il n'est plus en recherche d'un bien en vente.");
                }
                html.push("</p><hr><p class=\"text-justify text-muted\">En archivant ce dossier, vous vous engagez à ce que les informations saisies au-dessus soient exactes.<br>La société Noteo recevra une copie de ces informations. Conformément aux conditions de mise à disposition ainsi qu'à la charte, la société Noteo et l'administrateur se réservent le droit de contrôler celles-ci.</p>");

                return html.join('');
            }
        }
    })(angularJS);
}
