import angularJS from '@shared/angularJS/global.ng';
import {filter as lFilter, find} from 'lodash';
import {IModule, IQService} from 'angular';
import {
    NgDictionariesManager, NgLocationPasserelleManager, NgVentePasserelleManager
} from '@legacy/app/managers/managers';
import {NgBienDossier, NgEsk, NgEtudeDiffusion, NgTemplate, NgVentePasserelle} from '@legacy/app/managers/ressources';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {map, switchMap, tap} from 'rxjs/operators';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {firstValueFrom, Observable} from 'rxjs';
import {EtudePasserelleService} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.service';
import {EtudePasserellesService} from '@models/etudes/etude/passerelles/etude-passerelles.service';
import {DocumentsService} from '@models/documents/documents.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {TemplateFactory} from '@models/templates/template/template.factory';
import Vente from '@models/ventes/vente/vente.model';
import {EtudeDiffusionsService} from '@models/etudes/etude/diffusions/etude-diffusions.service';
import {ConditionConst} from '@shared/constants';
import EtudeDiffusion from '@models/etudes/etude/diffusions/diffusion/etude-diffusion.model';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Location from '@models/locations/location/location.model';
import DateFormat from '@shared/date/date.format';

export default function getDossiersWalletDiffusionCtrl(module: IModule): void {
    (angular => {
        'use strict';

        module.component('eskDossierBiensWalletDiffusionCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/wallet/diffusion.html',
        });

        /**
         * Controller of Dossiers list diffusions
         *
         * @param Ng2RouterStateForNgService
         * @param VentePasserelleManager
         * @param $q
         * @param DictionariesManager
         * @param LocationPasserelleManager
         * @param SoqModalesManager
         * @param $translate
         * @param Ng2ToasterService
         * @param Ng2EtudeService
         * @param Ng2EtudePasserelleService
         * @param Ng2EtudePasserellesService
         * @param Ng2DocumentsService
         * @param Ng2DictionaryItemService
         * @param Ng2DossierBienFactory
         * @param Ng2TemplateFactory
         * @param Ng2EtudeDiffusionsService
         * @param Ng2LocationFactory
         * @param Ng2VenteFactory
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'VentePasserelleManager', '$q', 'DictionariesManager', 'LocationPasserelleManager', 'SoqModalesManager', '$translate', 'Ng2ToasterService', 'Ng2EtudeService', 'Ng2EtudePasserelleService', 'Ng2EtudePasserellesService', 'Ng2DocumentsService', 'Ng2DictionaryItemService', 'Ng2DossierBienFactory', 'Ng2TemplateFactory', 'Ng2EtudeDiffusionsService', 'Ng2LocationFactory', 'Ng2VenteFactory'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ventePasserelleManager: NgVentePasserelleManager,
                            $q: IQService,
                            dictionariesManager: NgDictionariesManager,
                            locationPasserelleManager: NgLocationPasserelleManager,
                            soqModalesManager: NgSoqModalesManager,
                            $translate: angular.translate.ITranslateService,
                            ng2ToasterService: ToasterService,
                            ng2EtudeService: EtudeService,
                            ng2EtudePasserelleService: EtudePasserelleService,
                            ng2EtudePasserellesService: EtudePasserellesService,
                            ng2DocumentsService: DocumentsService,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2DossierBienFactory: DossierBienFactory,
                            ng2TemplateFactory: TemplateFactory,
                            ng2EtudeDiffusionsService: EtudeDiffusionsService,
                            ng2LocationFactory: LocationFactory,
                            ng2VenteFactory: VenteFactory) {
            const $ctrl = this;
            let socialNetworks: EtudePasserelle[];

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectAll = onSelectAll;
            $ctrl.onSelect = onSelect;
            $ctrl.getDossiers = getDossiers;
            $ctrl.onChangeFilter = onChangeFilter;
            $ctrl.networkDiffuse = networkDiffuse;
            $ctrl.passerelleAction = passerelleAction;
            $ctrl.onClick = onClick;
            $ctrl.afterSendTo = afterSendTo;
            $ctrl.printAffichettes = printAffichettes;
            $ctrl.sendTo = sendTo;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;

            function $onInit() {
                $ctrl.responsableDossier = false;
                $ctrl.nbPasserellesSelected = null;
                if (ng2RouterStateForNgService.current.name!.startsWith('/app/locations')) {
                    $ctrl.typeDossier = DossierBienTypesConst.LOCATION;
                } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/ventes')) {
                    $ctrl.typeDossier = DossierBienTypesConst.VENTE;
                } else {
                    throw new Error('Impossible de déterminer le "typeDossier" pour ' + ng2RouterStateForNgService.current.name);
                }

                ng2EtudeService.last$.pipe(
                    tap(currentEtude => socialNetworks = lFilter(currentEtude.passerellesSocialNetwork, {
                        passerelle: {instantDiffusion: false},
                    })),
                    tap(currentEtude => $ctrl.currentEtude = currentEtude),
                    tap(currentEtude => $ctrl.network = currentEtude.mainNetwork),
                    tap(currentEtude => $ctrl.passerelles = currentEtude.passerelles),
                    tap(currentEtude => $ctrl.passerellesWebsiteSN = currentEtude.passerellesWebsite),
                ).subscribe(_ => {
                    if (socialNetworks.length > 0) {
                        $ctrl.passerellesWebsiteSN.unshift(socialNetworks[0]);
                    }

                    $ctrl.onChangeFilter();
                });
            }

            /**
             * Select all dossiers
             */
            function onSelectAll() {
                angular.forEach($ctrl.dossiers, function (dossier) {
                    dossier._esk.select = $ctrl.selectAll;
                });
            }

            /**
             * Select dossier
             *
             * @param dossier
             */
            function onSelect(dossier: NgBienDossier) {
                if (dossier._esk.select) {
                    $ctrl.selectAll = $ctrl.getDossiers().length === $ctrl.dossiers.length;
                } else {
                    $ctrl.selectAll = false;
                }
            }

            /**
             * Get current dossier
             *
             * @returns {Array}
             */
            function getDossiers() {
                return lFilter($ctrl.dossiers, {_esk: {select: true}});
            }

            /**
             * Change and initialize dossier passerelle
             */
            function onChangeFilter() {
                let wallet$: Observable<EtudeDiffusion[]>;

                $ctrl.loading = "LOADING";
                if ($ctrl.typeDossier === DossierBienTypesConst.LOCATION) {
                    wallet$ = ng2EtudeDiffusionsService.getWalletEtat$({
                        bien: {type: {condition: ConditionConst.IN, values: [DossierBienTypesConst.LOCATION]}},
                        passerelle: {type: {condition: ConditionConst.IN, values: ['website']}},
                        responsableDossier: !!$ctrl.responsableDossier,
                    }).pipe(map(diffusions => diffusions.locations!));
                } else {
                    wallet$ = ng2EtudeDiffusionsService.getWalletEtat$({
                        bien: {type: {condition: ConditionConst.IN, values: [DossierBienTypesConst.VENTE]}},
                        passerelle: {type: {condition: ConditionConst.IN, values: ['website']}},
                        responsableDossier: !!$ctrl.responsableDossier,
                    }).pipe(map(diffusions => diffusions.ventes!));
                }

                firstValueFrom(wallet$.pipe(map(etudeDiffusions => {
                    const ngEtudeDiffusions = etudeDiffusions.map(etudeDiffusion => {
                        const ngEtudeDiffusion = {
                            _esk: {},
                            codeNature: etudeDiffusion.codeNature,
                            codeTypeCharges: etudeDiffusion.codeTypeCharges,
                            doubleMandat: etudeDiffusion.doubleMandat,
                            id: etudeDiffusion.id,
                            network: etudeDiffusion.network,
                            nomProprietairesGroup: etudeDiffusion.nomProprietairesGroup,
                            passerelles: etudeDiffusion.passerelles,
                            premierePhoto: etudeDiffusion.premierePhoto,
                            reference: etudeDiffusion.reference,
                            type: etudeDiffusion.type,
                        } as NgEtudeDiffusion;

                        if (etudeDiffusion.premierePhoto) {
                            etudeDiffusion.premierePhoto = $ctrl.currentEtude.linkMarianne;
                        }

                        return ngEtudeDiffusion;
                    });

                    return [ngEtudeDiffusions];
                }))).then(function (diffusions) {
                    const dossiers = [] as NgEtudeDiffusion[];

                    // Récupération des données
                    angular.forEach(diffusions, function (diffusion) {
                        if (dossiers.length <= 0 && angular.isArray(diffusion)) {
                            angular.forEach(angular.copy(diffusion), function (dossier) {
                                dossier.passerelles = [];
                                if (!dossier.network.dateDiffusion) {
                                    dossier._esk.etat = dossier.doubleMandat ? 'suspended' : 'none';
                                } else {
                                    dossier._esk.etat = 'diffused';
                                }

                                dossiers.push(dossier);
                            });
                        }

                        if (dossiers.length > 0) {
                            angular.forEach(diffusion, function (dossierDiffusion) {
                                const dossier = find(dossiers, {id: dossierDiffusion.id}) as { passerelles: unknown[] };

                                dossier.passerelles = dossier.passerelles.concat(dossierDiffusion.passerelles);
                            });
                        }
                    });

                    // Ajout des passerelles manquantes
                    angular.forEach(dossiers, function (dossier) {
                        const passerelles = [] as NgVentePasserelle[];

                        angular.forEach($ctrl.passerellesWebsiteSN, function (passerelleWebsiteSN) {
                            let passerelle = find(dossier.passerelles, {id: passerelleWebsiteSN.passerelle.id}) as NgVentePasserelle;
                            var newDossierPasserelle = angular.isObject(passerelle);
                            var natureNormalizer;

                            if (angular.isObject(passerelle)) {
                                passerelle.passerelle = passerelleWebsiteSN.passerelle;
                            } else {
                                natureNormalizer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getNatureNormalizer(passerelleWebsiteSN.passerelle.normalizer, $ctrl.typeDossier, dossier.codeNature));
                                passerelle = {
                                    passerelle: passerelleWebsiteSN.passerelle,
                                    natureExternalType: passerelleWebsiteSN.passerelle.normalizer,
                                    natureExternalTypeId: angular.isObject(natureNormalizer) ? natureNormalizer.id : null,
                                } as NgVentePasserelle;
                                if ($ctrl.typeDossier === DossierBienTypesConst.VENTE) {
                                    const typePrixNormalizer = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode('types-prix-' + passerelleWebsiteSN.passerelle.normalizer, dossier.codeTypeCharges));

                                    passerelle.typePrixExternalType = passerelleWebsiteSN.passerelle.normalizer;
                                    passerelle.typePrixExternalTypeId = typePrixNormalizer ? typePrixNormalizer.id : null!;
                                }
                            }

                            switch ($ctrl.typeDossier) {
                                case DossierBienTypesConst.VENTE :
                                    passerelle = ventePasserelleManager.create(passerelle, dossier.id);

                                    break;
                                case DossierBienTypesConst.LOCATION :
                                    passerelle = locationPasserelleManager.create(passerelle, dossier.id) as NgVentePasserelle;

                                    break;
                            }
                            passerelle.id = newDossierPasserelle as unknown as number;
                            updateStatutClass(passerelle);
                            passerelles.push(passerelle);
                        });

                        dossier.passerelles = passerelles;
                    });

                    $ctrl.dossiers = dossiers;
                }).finally(function () {
                    $ctrl.selectAll = false;
                    if ($ctrl.dossiers?.length > 0) {
                        $ctrl.loading = undefined;
                    } else {
                        $ctrl.loading = "NO_DOSSIER";
                    }
                });
            }

            /**
             * Diffuse dossier
             *
             * @param dossier
             */
            function networkDiffuse(dossier: NgEtudeDiffusion) {
                if (dossier.doubleMandat || dossier.network.dateDiffusion !== null || angular.isObject(dossier._esk.actionPromise)) {
                    return;
                }

                dossier._esk.etat = 'loading';
                switch ($ctrl.typeDossier) {
                    case DossierBienTypesConst.VENTE :
                        dossier._esk.actionPromise = firstValueFrom(ng2VenteFactory.networkDiffuse$({id: dossier.id} as Vente));

                        break;

                    case DossierBienTypesConst.LOCATION :
                        dossier._esk.actionPromise = firstValueFrom(ng2LocationFactory.networkDiffuse$({id: dossier.id} as Location));

                        break;

                    default :
                        dossier._esk.actionPromise = $q.resolve();
                }
                dossier._esk.actionPromise.then(function () {
                    dossier._esk.etat = 'diffused';
                    (dossier.network.dateDiffusion as unknown as Date) = DateFormat.toDate();
                    $ctrl.network.nbBiensActifsEnvoyes++;
                }).finally(() => dossier._esk.actionPromise = undefined!);
            }

            /**
             * Diffuse or suspend dossier on passerelle
             *
             * @param dossierPasserelle
             */
            function passerelleAction(dossierPasserelle: NgVentePasserelle) {
                if (angular.isObject(dossierPasserelle._esk.actionPromise)) {
                    return;
                }

                if (dossierPasserelle.id) {
                    dossierPasserelle._esk.actionPromise = $q.resolve();
                } else {
                    dossierPasserelle._esk.actionPromise = dossierPasserelle.save();
                }

                updateStatutClass(dossierPasserelle);
                dossierPasserelle._esk.actionPromise.then(function () {
                    if (!dossierPasserelle.isDiffused()) {
                        return dossierPasserelle.diffuse().then(function () {
                            const passerelleWebsiteSN = find($ctrl.passerellesWebsiteSN, {passerelle: {id: dossierPasserelle.passerelle.id}});

                            switch ($ctrl.typeDossier) {
                                case DossierBienTypesConst.VENTE :
                                    passerelleWebsiteSN.nbVentesActivesEnvoyees++;
                                    break;
                                case DossierBienTypesConst.LOCATION :
                                    passerelleWebsiteSN.nbLocationsActivesEnvoyees++;
                                    break;
                            }

                            if (dossierPasserelle.passerelle.viaId) {
                                $ctrl.$onInit();
                            }
                        });
                    }

                    return dossierPasserelle.suspend().then(function () {
                        const passerelleWebsiteSN = find($ctrl.passerellesWebsiteSN, {passerelle: {id: dossierPasserelle.passerelle.id}});

                        switch ($ctrl.typeDossier) {
                            case DossierBienTypesConst.VENTE :
                                passerelleWebsiteSN.nbVentesActivesEnvoyees--;
                                break;
                            case DossierBienTypesConst.LOCATION :
                                passerelleWebsiteSN.nbLocationsActivesEnvoyees--;
                                break;
                        }

                        if (!dossierPasserelle.passerelle.viaId) {
                            let reload = false;

                            angular.forEach($ctrl.passerellesWebsiteSN, function (etudePasserelle) {
                                if (etudePasserelle.viaId === dossierPasserelle.passerelle.id) reload = true;
                            });

                            if (reload) {
                                $ctrl.$onInit();
                            }
                        }
                    });
                }).finally(function () {
                    dossierPasserelle._esk.actionPromise = undefined;
                    updateStatutClass(dossierPasserelle);
                });
            }

            /**
             * Click on item
             *
             * @param dossier
             */
            function onClick(dossier: { id: number }) {
                ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/' + dossier.id + '/diffusion');
            }

            /**
             * Execute after grouped sending
             *
             * @param passerellesSelected
             */
            function afterSendTo(passerellesSelected: EtudePasserelle[]) {
                firstValueFrom(ng2EtudePasserellesService.updateBiensEnvoyees$(passerellesSelected)).then(() => $ctrl.onChangeFilter());
            }

            /**
             * Update class etat
             *
             * @param dossierPasserelle
             */
            function updateStatutClass(dossierPasserelle: { _esk: NgEsk }) {
                dossierPasserelle._esk.etat = angular.isObject(dossierPasserelle._esk.actionPromise) ? 'loading' : dossierPasserelle._esk.statut;
            }

            /**
             * Print affichettes
             */
            function printAffichettes() {
                let codesTemplate: string[];

                $ctrl.barreFaireLoader = 'bien.grouped_action.print.affichettes.MESSAGE';
                switch ($ctrl.typeDossier) {
                    case DossierBienTypesConst.VENTE :
                        codesTemplate = [Vente.templates.AFFICHETTE];

                        break;
                    case DossierBienTypesConst.LOCATION :
                        codesTemplate = ['affichette-location'];

                        break;
                }

                return soqModalesManager.open<NgTemplate>('BiensModalFormatAffichettes', {
                    resolve: {codesTemplate: () => codesTemplate},
                }).then(ngTemplate => {
                    const selectedDossiers = $ctrl.getDossiers().map(diffusionEtat => ng2DossierBienFactory.createFromDiffusionEtat($ctrl.typeDossier, diffusionEtat));

                    return firstValueFrom(ng2DocumentsService.readFromEachDossierBiens$(selectedDossiers, ng2TemplateFactory.ngCreate(ngTemplate)));
                }).finally(() => $ctrl.barreFaireLoader = false);
            }

            /**
             * Send to passerelles
             *
             * @params vector
             */
            function sendTo(vector: string) {
                soqModalesManager.open<EtudePasserelle[]>('BiensModalChoicePasserelles', {
                    resolve: {
                        title: () => {
                            switch (vector) {
                                case 'social_network' :
                                    return "Choix des réseaux sociaux";
                                case 'website' :
                                    return "Choix des sites Internet";
                                case 'newspaper' :
                                    return "Choix des journaux papier";
                            }

                            return '';
                        },
                        passerelles: () => angular.copy(lFilter($ctrl.passerelles, {passerelle: {type: vector}}))
                    }
                }).then(passerellesSelected => {
                    const promises = [] as unknown[];

                    if (passerellesSelected.length <= 0) {
                        return;
                    }

                    $ctrl.barreFaireLoader = 'bien.grouped_action.send.' + vector + '.MESSAGE';
                    $ctrl.nbPasserellesSelected = passerellesSelected.length;
                    passerellesSelected.forEach(passerelleSelected => {
                        let idsDossiers: number[];

                        if ($ctrl.typeDossier === DossierBienTypesConst.VENTE && passerelleSelected.passerelle.venteInteractive === 'none') {
                            const selectVentes = $ctrl.getDossiers();
                            const unselectedVentes: string[] = [];

                            idsDossiers = [];
                            selectVentes.forEach((vente: {
                                _esk: NgEsk;
                                id: number;
                                reference: string;
                                type: string
                            }) => {
                                if (vente.type === Vente.types.INTERACTIVE) {
                                    vente._esk.select = false;
                                    unselectedVentes.push(vente.reference);
                                } else {
                                    idsDossiers.push(vente.id);
                                }
                            });

                            if (unselectedVentes.length > 0) {
                                ng2ToasterService.warning(unselectedVentes.join(', ') + ' non transmis', passerelleSelected.passerelle.name + ' n\'est pas compatibles avec les ventes interactives.');
                            }
                        } else {
                            idsDossiers = $ctrl.getDossiers().map(dossier => dossier.id);
                        }

                        promises.push(firstValueFrom(ng2EtudePasserelleService.diffuse$(passerelleSelected, idsDossiers).pipe(
                            map(_ => find($ctrl.passerellesWebsiteSN, {passerelle: {id: passerelleSelected.passerelle.id}})),
                            switchMap(passerelleWebsiteSN => ng2EtudePasserelleService.updateBiensEnvoyees$(passerelleWebsiteSN)),
                        )));
                    });
                    $q.all(promises).then(() => {
                        if (angular.isFunction($ctrl.afterSendTo)) {
                            $ctrl.afterSendTo(passerellesSelected);
                        }
                    }).finally(() => {
                        ng2ToasterService.success($translate.instant('bien.grouped_action.send.' + vector + '.TOAST_TITRE', {nbPasserelles: $ctrl.nbPasserellesSelected}), $translate.instant('bien.grouped_action.send.' + vector + '.MESSAGE', {
                            nbDossiers: $ctrl.getDossiers().length,
                            nbPasserelles: $ctrl.nbPasserellesSelected
                        }));
                        $ctrl.barreFaireLoader = undefined;
                    });
                });
            }
        }
    })(angularJS);
}
