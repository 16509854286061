@if (contact$ | async; as contact) {
  <span title="{{contact.convivialName}}">
    <span class="tw-truncate" [highlight]="options.highlight">{{ contact.convivialName }}</span>

    @if (contact.representant) {
      <span title="{{titleRepresente + ' par ' + contact.representant.convivialName}}">
        <fa-icon [icon]="'balance-scale'"/>
      </span>
    }

    @if (options.withExternalSource && contact.externalSource === sources.INOT) {
      <span title="Ajouté depuis {{INOT_LABEL}}"><app-noteo-icon-text-inot [label]="''"/></span>
    }
  </span>
} @else {
  <app-loader class="tw-inline-block tw-min-w-[6rem]"/>
}
