import angularJS from '@shared/angularJS/global.ng';
import {find} from 'lodash';
import {IModule} from 'angular';
import {NgBienDossier} from '@legacy/app/managers/ressources';
import {UserService} from '@models/users/user/user.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getEskActionsDossiers(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-actions-dossiers></esk-actions-dossiers>
         */
        module.component('eskActionsDossiers', {
            bindings: {
                dossiers: '=',
                currentDossier: '=?',
                remove: '=',
                add: '=',
                addContacts: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/actions/dossiers.html'
        });

        /**
         * Controller to manage dossiers selection
         *
         * @param Ng2UserService
         * @constructor
         */
        Controller.$inject = ['Ng2UserService'];
        function Controller(this: any, ng2UserService: UserService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.isDossierDisabled = isDossierDisabled;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.options = {placeholder: 'Ajouter un dossier'};
                $ctrl.typesDossierEnabled = [];
                ng2UserService.last$.subscribe(currentUser => {
                    if (currentUser.hasRoleDemandeur()) {
                        $ctrl.typesDossierEnabled.push('demandeurs');
                    }

                    if (currentUser.hasRoleGrpEstimation()) {
                        $ctrl.typesDossierEnabled.push('estimations');
                    }

                    if (currentUser.hasRoleGrpLocation()) {
                        $ctrl.typesDossierEnabled.push('locations');
                    }

                    if (currentUser.hasRoleGrpVente()) {
                        $ctrl.typesDossierEnabled.push('ventes');
                    }
                });
            }

            /**
             * Disabled dossier if already selected
             *
             * @param newDossier
             * @returns {boolean}
             */
            function isDossierDisabled(newDossier: NgBienDossier) {
                const dossier = find($ctrl.dossiers, {typeId: newDossier.id});

                return angular.isObject(dossier) ? newDossier._esk.typeDossier === dossier.type : false;
            }
        }
    })(angularJS);
}
