import {Directive, inject, Input} from '@angular/core';
import {NgModel} from '@angular/forms';
import {NgBienDossierCurrent, NgBienFieldsConstraints} from '@legacy/app/biens/biens';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {AppFormErrorDirective} from '@shared/form/error/form-error.directive';

@Directive({selector: '[appBienEditConstraints]'})
export class AppBienEditConstraintsDirective extends AppFormErrorDirective {
    static CLASS_CONSTRAINT = 'field-constraint';
    private _ngInjectorService = inject(NgInjectorService);

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngBienFieldsConstraints(): NgBienFieldsConstraints {
        return this._ngInjectorService.getService('BienFieldsConstraints');
    }

    @Input()
    set appBienEditConstraints(ngModel: NgModel) {
        if (!ngModel) {
            return;
        }

        this.operateFieldConstraint(ngModel.name);
        this.appFormError = ngModel;
    }

    operateFieldConstraint(field: string): void {
        const operateElement = () => {
            const nativeElement = this._viewContainerRef.element.nativeElement as AppBienEditConstraintsDirective;

            if (this.ngBienDossierCurrent.hasConstraints(field)) {
                this._renderer2.addClass(nativeElement, AppBienEditConstraintsDirective.CLASS_CONSTRAINT);
            } else {
                this._renderer2.removeClass(nativeElement, AppBienEditConstraintsDirective.CLASS_CONSTRAINT);
            }
        };

        this.ngBienFieldsConstraints.addCallback(field, operateElement);
        operateElement();
    }
}
