export default `
<ui-gmap-google-map ng-if="$ctrl.map" center="$ctrl.center" zoom="$ctrl.zoom" options="$ctrl.options"
                    soq-prevent-touch-move>
  <ui-gmap-free-draw-polygons polygons="$ctrl.polygons" draw="$ctrl.map.draw"></ui-gmap-free-draw-polygons>

  <ui-gmap-map-control>
    <div ng-switch="$ctrl.searching" class="btns-drawing">
      <div ng-switch-when="true" ng-if="$ctrl.polygons.length > 0"
           class="tw-text-center text-large padding-top-5 padding-bottom-5">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>

        <span class="hidden-xs">Traitement de la sélection ...</span>
      </div>

      <div ng-switch-default>
        <button ng-if="$ctrl.polygons.length > 0" type="button" class="btn btn-primary" ng-click="$ctrl.getPolygons()">
          <span class="visible-lg">Valider la sélection</span>

          <span class="hidden-lg"><fa-icon [icon]="'check'"></fa-icon></span>
        </button>

        <button ng-if="!$ctrl.drawing" type="button" class="btn btn-o btn-primary btn-draw"
                ng-click="$ctrl.startDrawing()">
          <fa-icon [icon]="['far', 'edit']"></fa-icon> <span class="visible-lg-inline-block">Dessiner</span>
        </button>

        <button ng-if="$ctrl.polygons.length > 0" type="button" class="btn btn-default"
                ng-click="$ctrl.clearDraw()">
          <fa-icon [icon]="'eraser'"></fa-icon> <span class="visible-lg-inline-block">Effacer</span>
        </button>

        <button ng-if="$ctrl.drawing && $ctrl.polygons.length <= 0" type="button" class="btn btn-default"
                ng-click="$ctrl.resetMap()">
          <fa-icon [icon]="'times'"></fa-icon> <span class="visible-lg-inline-block">Annuler</span>
        </button>
      </div>
    </div>
  </ui-gmap-map-control>
</ui-gmap-google-map>
`;
