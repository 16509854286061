import {Component, inject, OnInit} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsEmailAddress} from '@legacy/app/contacts/contacts';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';

@Component({selector: 'app-authenticated', template: '<router-outlet/>'})
export class AppAuthenticatedComponent implements OnInit {
    private _callToActionService = inject(CallToActionService);
    private _ngInjectorService = inject(NgInjectorService);

    // Supprimer les injections des anciens manager
    private get ngContactsEmailAddress(): NgContactsEmailAddress {
        return this._ngInjectorService.getService('ContactsEmailAddress');
    }

    ngOnInit(): void {
        this._callToActionService.init();
        this.ngContactsEmailAddress.stopRefreshInvalid();
    }
}
