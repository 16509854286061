export default `
<div class="modal-network">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title">
      <fa-icon [icon]="'rss'"></fa-icon> {{$ctrl.diffusion.name || $ctrl.diffusion.passerelle.name}}
    </h3>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3 no-padding">
          <div class="pg-list-group">
            <a class="pg-list-group-item" ng-repeat="tab in $ctrl.tabs"
               ng-click="$ctrl.currentTab = tab.code" ng-class="{active: tab.code === $ctrl.currentTab}">
              <span class="text-bold">{{tab.label | translate}}</span>

              <span class="tw-inline-block tw-w-6">
                <fa-icon ng-if="tab.code === 'general'" [icon]="['far', 'edit']"></fa-icon>

                <fa-icon ng-if="tab.code === 'descriptif'" [icon]="'align-left'"></fa-icon>

                <fa-icon ng-if="tab.code === 'photos'" [icon]="['far', 'image']"></fa-icon>
              </span>
            </a>
          </div>
        </div>

        <div class="col-sm-9 margin-top-10" ng-switch="$ctrl.currentTab">
          <!-- Informations générales -->
          <div ng-switch-default>
            <ng-switch on="$ctrl.configuration.type" class="row">
              <!-- Adresse envoyée aux confrères -->
              <div ng-switch-when="networks" ng-if="$ctrl.currentEtude.sendingAddressesBiensToConfreres"
                   class="col-sm-6 form-group">
                <label for="bien-adresse-reseau">{{"Adresse envoyée aux confrères"|translate}}</label>

                <input type="text" id="bien-adresse-reseau" class="form-control"
                       ng-model="$ctrl.dossier.bien.option.adresseReseau">
              </div>

              <!-- Nature sur la passerelle -->
              <div ng-switch-when="passerelle" class="col-sm-12">
                <div class="row">
                  <div ng-if="$ctrl.isDictionaryExisting('natures-' + $ctrl.diffusion.passerelle.normalizer)"
                       class="col-sm-6 form-group">
                    <label>Nature</label>

                    <esk-select-dictionary ng-model="$ctrl.dossierDiffusion.natureExternalType" is-required="true"
                                           dictionary="natures-{{$ctrl.diffusion.passerelle.normalizer}}">
                    </esk-select-dictionary>
                  </div>

                  <!-- Affichage de la commune sur la passerelle -->
                  <div ng-if="$ctrl.diffusion.passerelle.configurationAfficherCommune" class="col-sm-6 form-group">
                    <div class="checkbox clip-check check-primary">
                      <input type="checkbox" id="modal-diffusion-affiche-commune"
                             ng-model="$ctrl.dossierDiffusion.afficherCommune">

                      <label for="modal-diffusion-affiche-commune">Affichage de la commune</label>
                    </div>
                  </div>
                </div>

                <div class="row" ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE">
                  <!-- Type d'affichage du prix sur la passerelle -->
                  <div ng-if="$ctrl.isDictionaryExisting('types-prix-' + $ctrl.diffusion.passerelle.normalizer)"
                       class="col-sm-6 form-group">
                    <label>Affichage du prix</label>

                    <esk-select-dictionary ng-model="$ctrl.dossierDiffusion.typePrixExternalType" is-required="true"
                                           dictionary="types-prix-{{$ctrl.diffusion.passerelle.normalizer}}">
                    </esk-select-dictionary>
                  </div>

                  <!-- Affichage du prix sur la passerelle -->
                  <div ng-if="$ctrl.diffusion.passerelle.configurationAfficherPrix" class="col-sm-6 form-group">
                    <div class="checkbox clip-check check-primary">
                      <input type="checkbox" id="modal-diffusion-affiche-prix"
                             ng-model="$ctrl.dossierDiffusion.afficherPrix">

                      <label for="modal-diffusion-affiche-prix">Affichage du prix</label>
                    </div>

                    <div ng-if="$ctrl.diffusion.passerelle.normalizer === 'newspaper' && !$ctrl.dossierDiffusion.afficherPrix"
                         class="text-warning">
                      "Prix : Nous consulter" sera envoyé au journal.
                    </div>
                  </div>

                  <!-- Affichage des diagnostics sur la passerelle -->
                  <div ng-if="$ctrl.diffusion.passerelle.configurationAfficherDpe" class="col-sm-6 form-group">
                    <div class="checkbox clip-check check-primary">
                      <input type="checkbox" id="modal-diffusion-affiche-dpe"
                             ng-model="$ctrl.dossierDiffusion.afficherDpe">

                      <label for="modal-diffusion-affiche-dpe">Affichage des diagnostics</label>
                    </div>
                  </div>
                </div>
              </div>
            </ng-switch>
          </div>

          <!-- Descriptif -->
          <div ng-switch-when="descriptif">
            <app-ng2-textarea [placeholder]="'Saisir le descriptif du bien pour ' + ($ctrl.diffusion.name || $ctrl.diffusion.passerelle.name)"
                              [form-name]="'descriptif'" [text]="$ctrl.dossierDiffusion.descriptif"
                              (changed)="$ctrl.dossierDiffusion.descriptif = $event"></app-ng2-textarea>

            <div ng-if="!!$ctrl.dossierDiffusion.descriptif" class="info-count pull-right">
              {{'diffusion.NB_CHAR'| translate:'{nbChar:' + $ctrl.dossierDiffusion.descriptif.length + '}' }}
            </div>

            <button type="button" class="btn btn-link"
                    ng-click="$ctrl.dossierDiffusion.descriptif = $ctrl.configuration.getDescriptifParent.content">
              {{$ctrl.configuration.getDescriptifParent.btn}}
            </button>
          </div>

          <!-- Photos -->
          <div ng-switch-when="photos">
            <div class="padding-bottom-15">
              Photos protégées sélectionnées :
              {{($ctrl.photos | filter:{diffused: true}).length}} / {{$ctrl.photos.length}}

              <a popover-trigger="'outsideClick'" popover-placement="right" type="button"
                 uib-popover="En cliquant sur une photo, elle se grise et ne sera pas envoyée à {{$ctrl.diffusion.name || $ctrl.diffusion.passerelle.name}}."
                 class="btn btn-o btn-null"><span class="badge badge-info">?</span></a>
            </div>

            <ul class="list-unstyled list-photos">
              <li ng-repeat="photo in $ctrl.photos" class="photo" ng-click="$ctrl.photoDiffuse(photo)">
                <img ng-src="{{photo.link}}?size=thumbnail" ng-class="{'not-diffuse': !photo.diffused}" loading="lazy">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" ng-click="$close()">
      <fa-icon [icon]="'check'"></fa-icon> Enregistrer
    </button>
  </div>
</div>
`;
