<div class="tw-flex tw-items-center tw-gap-4 tw-mb-1">
  @if (dossierBien.interne) {
    <div class="tw-truncate tw-text-neutral-600 tw-text-lg tw-font-bold">
      {{dossierBien.contactsGroup ? dossierBien.contactsGroup.nom : NO_NAME}}
    </div>
  }

  <div class="tw-w-min tw-text-sm tw-text-gray-700 tw-whitespace-nowrap">
    <app-dossier-reference [dossierBien]="dossierBien"/>
  </div>

  <app-dossier-bien-statut [dossierBien]="dossierBien"/>
</div>

<div class="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden">
  @if (dossierBien.isLocation()) {
    <esk-location-label-price [location]="location"/>
  }

  @if (dossierBien.isVente()) {
    <app-vente-price [vente]="vente" [options]="{enabledNV: false}" class="tw-w-min tw-text-xs"/>
  }

  @if (dossierBien.bien.nature) {
    <div class="tw-w-min tw-whitespace-nowrap">{{dossierBien.bien.nature.label}}</div>
  }

  @if (localisation) {
    <div class="tw-hidden sm:tw-block tw-truncate" [title]="dossierBien.bien.fullInlineAdresse || ''">
      <fa-icon [icon]="'map-marker-alt'"/> {{localisation}}
    </div>
  }
</div>
