@if (cDossierFiles$ | async; as cDossierFiles) {
  @if (options.enabledSelection) {
    <table id="dossierFiles" class="tw-table-head-separate-body"
           [appCollectionSelection]="{collection: cDossierFiles, listName: options.nameSelection!, options: {filterEnabled: options.filterEnabled, initSelectAll: options.initSelectAll, titleDisabled: options.titleDisabled}}">
      <ng-container [ngTemplateOutlet]="tableContent"/>
    </table>
  } @else {
    <table class="tw-table-head-separate-body"><ng-container [ngTemplateOutlet]="tableContent"/></table>
  }

  <ng-template #tableContent>
    <thead>
    <tr>
      <th><div>Nom</div></th>

      <th class="tw-hidden lg:tw-table-cell lg:tw-w-32 xl:tw-w-36">
        <div>Date <span class="tw-hidden lg:tw-inline-block">d'</span>ajout</div>
      </th>

      <th class="tw-hidden md:tw-table-cell tw-w-28 lg:tw-w-32 xl:tw-w-36"><div>Taille</div></th>

      @if (options.displayColumnShare) {
        <th class="sm:tw-w-60 lg:tw-w-72 xl:tw-w-80"><div>Partages</div></th>
      }

      @if (options.displayColumnAction) {
        <th class="tw-w-16 lg:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
      }
    </tr>
    </thead>

    <tbody>
      @for (dossierFile of cDossierFiles.results; track dossierFile) {
        <tr [ngClass]="isEnabled(dossierFile) ? '' : 'tw-opacity-75'">
          <td><app-media-convivial-name [media]="dossierFile.media"/></td>

          <td class="tw-hidden lg:tw-table-cell">
            @if (dossierFile.media.createdAt) {
              <div>{{ dossierFile.media.createdAt | date:'mediumDate' }}</div>
            }
          </td>

          <td class="tw-hidden md:tw-table-cell ">
            <div class="tw-flex print:tw-block tw-items-center tw-h-px print:tw-h-auto">
              {{ dossierFile.media.totalBytes | textSize }}
            </div>
          </td>

          @if (options.displayColumnShare) {
            <td>
              @if (!hideDossierFileUuidContacts.includes(dossierFile.uuid)) {
                <div>
                  <app-dossier-file-contacts-number [options]="{labelNoOne: '-', labelPrefix: 'Avec'}"
                                                    [linkContacts]="dossierFile.linkContacts"
                                                    class="tw-block tw-truncate tw-w-full"/>
                </div>
              } @else {
                <app-loader [classHeight]="'tw-h-4'"/>
              }
            </td>
          }

          @if (options.displayColumnAction) {
            <td class="tw-table-head-separate-body-actions">
              <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                      #buttonElement (click)="clickOnDossierFile(buttonElement, dossierFile)">
                <fa-icon [icon]="'ellipsis-v'"/>
              </button>
            </td>
          }
        </tr>
      }
    </tbody>
  </ng-template>

  <app-load-more [collection$]="cDossierFiles$" (loadMore)="getMore(cDossierFiles)" [loading]="pendingGetMore"/>
}
