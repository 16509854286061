import angularJS from '@shared/angularJS/global.ng';
import COLORS from '@core/colors';
import {IModule, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgDictionariesManager, NgEtudeStatisticsManager} from '@legacy/app/managers/managers';
import {ChartData, LegendItem} from 'chart.js';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import DateFormat from '@shared/date/date.format';
import Bien from '@models/bien/bien.model';

export default function getVentesBoxMandatsNatures(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-ventes-box-mandats-natures></esk-ventes-box-mandats-natures>
         */
        module.run(template).component('eskVentesBoxMandatsNatures', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/components/box/mandats.natures.html'
        });

        /**
         * Display ventes mandats natures
         *
         * @param EtudeStatisticsManager
         * @param DictionariesManager
         * @param EskBox
         * @param Ng2DictionaryFactory
         */
        Controller.$inject = ['EtudeStatisticsManager', 'DictionariesManager', 'EskBox', 'Ng2DictionaryFactory'];
        function Controller(this: any,
                            etudeStatisticsManager: NgEtudeStatisticsManager,
                            dictionariesManager: NgDictionariesManager,
                            eskBox: NgEskBox,
                            ng2DictionaryFactory: DictionaryFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.box = eskBox.getOneByCode('ventes-mandats-natures');

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.loading = true;

                etudeStatisticsManager.getVentesNaturesRepartition().then(statistics => {
                    const pieChartColors = {
                        [Bien.natures.APPARTEMENT]: COLORS.bien.nature.appartement,
                        [Bien.natures.AUTRE]: COLORS.bien.nature.autre,
                        [Bien.natures.AUTRE_TERRAIN]: COLORS.bien.nature.autre_terrain,
                        [Bien.natures.BUREAUX]: COLORS.bien.nature.bureaux,
                        [Bien.natures.GARAGE]: COLORS.bien.nature.garage,
                        [Bien.natures.IMMEUBLE]: COLORS.bien.nature.immeuble,
                        [Bien.natures.LOCAUX_COMMERCIAUX]: COLORS.bien.nature.locaux_comm,
                        [Bien.natures.MAISON]: COLORS.bien.nature.maison,
                        [Bien.natures.TERRAIN_A_BATIR]: COLORS.bien.nature.terrain_batir,
                    } as Record<string, string>;
                    const pieChartData = {
                        datasets: [{backgroundColor: [], data: []}],
                        labels: [],
                        total: 0,
                    };

                    ng2DictionaryFactory.getByName(Dictionary.names.NATURES).items.forEach(nature => {
                        if (statistics.data[nature.code] > 0) {
                            pieChartData.labels.push(nature.label as never);
                            pieChartData.datasets[0].backgroundColor.push(pieChartColors[nature.code] as never);
                            pieChartData.datasets[0].data.push(statistics.data[nature.code] as never);
                            pieChartData.total += statistics.data[nature.code];
                        }
                    });
                    $ctrl.pieVentesNatures = {
                        borderColor: 'white',
                        borderWidth: 3,
                        data: pieChartData,
                        date: DateFormat.toDate(statistics.date),
                        options: {
                            cutout: '40%',
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    labels: {
                                        boxWidth: 30,
                                        // Ajout de {index: number} qui n'est pas pris en compte dans le typing de ChartJS
                                        filter: (legendItem: LegendItem & { index: number }, chart: ChartData) => {
                                            if (legendItem.text === 'Locaux Commerciaux') {
                                                legendItem.text = 'Locaux Comm.';
                                            }

                                            return legendItem.text += ' : ' + chart.datasets[0].data[legendItem.index];
                                        },
                                    },
                                    position: 'right',
                                },
                            },
                            responsive: true,
                        },
                        type: 'doughnut',
                        getOptions: (width: number) => {
                            const options = angular.copy($ctrl.pieVentesNatures.options);

                            if (width < 778) {
                                options.plugins.legend.position = 'right';
                            } else if (width < 1010) {
                                options.plugins.legend.position = 'bottom';
                            } else {
                                options.plugins.legend.position = 'right';
                            }

                            return options;
                        },
                    };
                }).finally(() => $ctrl.loading = false);
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/ventes/components/box/mandats.natures/container.html', '<esk-ventes-box-mandats-natures></esk-ventes-box-mandats-natures>');
        }
    })(angularJS);
}
