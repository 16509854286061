import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager} from '@legacy/app/managers/managers';
import {NgRechercheResult} from '@legacy/app/managers/ressources';
import {NgRechercheResultsCollection} from '@legacy/app/managers/collections';
import Ng2DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

export default function getManagersDemandeurRechercheResultat(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('DemandeurRechercheResultatManager', manager);

        /**
         * Manager Demandeur Recherche
         *
         * @param BaseManager
         * @returns {DemandeurRechercheResultatManager}
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('demandeur-recherche-resultats.cget', {
                path: '/recherches/{rechercheId}/resultats',
                method: 'GET'
            });
            baseManager.addRoute('demandeur-recherche-resultats.get', {
                path: '/recherches/{rechercheId}/resultats/{id}',
                method: 'GET'
            });
            baseManager.addRoute('demandeur-recherche-resultats.edit', {
                path: '/recherches/{rechercheId}/resultats/{id}',
                method: 'PUT'
            });

            /**
             * DemandeurRechercheResultatManager object
             *
             * @constructor
             */
            angular.extend(DemandeurRechercheResultatManager.prototype, baseManager.__proto__);
            DemandeurRechercheResultatManager.prototype.eskManager = {
                prefixRoute: 'demandeur-recherche-resultats',
                collectionName: 'items'
            };

            function DemandeurRechercheResultatManager() {
            }

            /**
             * Returns stringify interest
             *
             * @param interest
             * @returns {string}
             */
            DemandeurRechercheResultatManager.prototype.stringifyInterest = function (interest: string) {
                var stringInterest;

                switch (interest) {
                    case 'interested' :
                        stringInterest = 'intéressants';
                        break;
                    case Ng2DemandeurRechercheResultat.interests.DISINTERESTED :
                        stringInterest = 'inintéressants';
                        break;
                    case Ng2DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE :
                        stringInterest = 'intéressants si baisse de prix';
                        break;
                    default :
                        stringInterest = interest;
                }

                return stringInterest;
            };

            /**
             * Returns the promise to fetch object
             *
             * @param demandeurId
             * @param resourceId
             * @returns {Promise}
             */
            DemandeurRechercheResultatManager.prototype.getOneById = function (rechercheId: number, resourceId: number) {
                return this.get('get', {rechercheId: rechercheId, id: resourceId}).then(function (resultat: NgRechercheResult) {
                    resultat._esk.defaultRouteParams!.rechercheId = rechercheId;

                    return resultat;
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param rechercheId
             * @param params
             * @returns {Promise}
             */
            DemandeurRechercheResultatManager.prototype.getAll = function (rechercheId: number, params: unknown) {
                return baseManager.getAll.call(this, angular.extend({rechercheId: rechercheId}, params)).then(resultats => {
                    const resultatsCollection: NgRechercheResultsCollection = resultats as NgRechercheResultsCollection;

                    angular.forEach(resultatsCollection.collection, resultat => resultat._esk.defaultRouteParams!.rechercheId = rechercheId);

                    return resultatsCollection;
                });
            };

            /**
             * Create a Recherche object
             *
             * @param data
             * @param rechercheId
             * @returns manager.Recherche object || Array of manager.Recherche objects
             */
            DemandeurRechercheResultatManager.prototype.create = function (data: unknown, rechercheId: number) {
                if (angular.isArray(data)) {
                    var resultats = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        resultats.push(new DemandeurRechercheResultat(rechercheId, data[i]));
                    }

                    return resultats;
                }

                // @ts-ignore
                return new DemandeurRechercheResultat(rechercheId, data);
            };

            /**
             * Demandeur Recherche Resultat object
             *
             * @param rechercheId
             * @param data
             * @constructor
             */
            angular.extend(DemandeurRechercheResultat.prototype, classResource.prototype);
            DemandeurRechercheResultat.prototype.eskManager = {prefixRoute: 'demandeur-recherche-resultats'};
            function DemandeurRechercheResultat(this: NgRechercheResult, rechercheId: number, data: unknown) {
                this.extend(data);
                this.updateColorStatus();
                this._esk.defaultRouteParams = {id: this.id, rechercheId: rechercheId};
            }

            /**
             * Sanitize object DemandeurRechercheResultat before send to API
             *
             * @returns sanitizeObject
             */
            DemandeurRechercheResultat.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.firstMatchAt;
                delete sanitizeObject.id;
                delete sanitizeObject.interestAt;
                delete sanitizeObject.status;
                delete sanitizeObject.type;
                delete sanitizeObject.vente;
                delete sanitizeObject.location;

                return sanitizeObject;
            };

            /**
             * Update color status due to status
             */
            DemandeurRechercheResultat.prototype.updateColorStatus = function () {
                var colorStatus = {};

                switch (this.status) {
                    case "match" :
                        if (this.interest === 'interested') {
                            // @ts-ignore
                            colorStatus.value = 'blue';
                        } else if (this.interest === Ng2DemandeurRechercheResultat.interests.DISINTERESTED || this.interest === Ng2DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE) {
                            // @ts-ignore
                            colorStatus.value = 'none';
                        } else {
                            // @ts-ignore
                            colorStatus.value = 'green';
                        }

                        break;
                    case "match_updated" :
                        if (this.interest === 'interested') {
                            // @ts-ignore
                            colorStatus.value = 'blue';
                        } else if (this.interest === Ng2DemandeurRechercheResultat.interests.DISINTERESTED || this.interest === Ng2DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE) {
                            // @ts-ignore
                            colorStatus.value = 'none';
                        } else {
                            // @ts-ignore
                            colorStatus.value = 'orange';
                        }

                        break;
                    case "match_removed" :
                        // @ts-ignore
                        colorStatus.value = 'red';

                        break;

                    default:
                        // @ts-ignore
                        colorStatus.value = 'none';
                }
                // @ts-ignore
                colorStatus.class = {
                    // @ts-ignore
                    'resultat-green': colorStatus.value === 'green',
                    // @ts-ignore
                    'resultat-orange': colorStatus.value === 'orange',
                    // @ts-ignore
                    'resultat-blue': colorStatus.value === 'blue',
                    // @ts-ignore
                    'resultat-red': colorStatus.value === 'red'
                };
                // @ts-ignore
                colorStatus.classBullet = {
                    // @ts-ignore
                    'bullet-green': colorStatus.value === 'green',
                    // @ts-ignore
                    'bullet-orange': colorStatus.value === 'orange',
                    // @ts-ignore
                    'bullet-blue': colorStatus.value === 'blue',
                };
                // @ts-ignore
                colorStatus.classBullet[(this.reason === 'compromis' ? 'compromis ' : '') + 'bullet-red'] = colorStatus.value === 'red';

                this._esk.colorStatus = colorStatus;
            };

            /**
             * Returns the promise of save
             */
            DemandeurRechercheResultat.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self.updateColorStatus();

                    return self;
                });
            };

            // @ts-ignore
            return new DemandeurRechercheResultatManager();
        }
    })(angularJS);
}
