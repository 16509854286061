import {inject, Injectable} from '@angular/core';
import {IEstimationIndicator} from '@models/estimations/estimation/indicators/estimation-indicators.interfaces';
import EstimationIndicatorsSection
    from '@models/estimations/estimation/indicators/section/estimation-indicators-section.model';
import {TextsService} from '@shared/texts/texts.service';

@Injectable({providedIn: 'root'})
export class EstimationIndicatorsSectionFactory {
    private _textsService = inject(TextsService);

    create(indicators: IEstimationIndicator[], title: string): EstimationIndicatorsSection {
        const estimationIndicatorsSection = new EstimationIndicatorsSection(this._textsService.uuid());

        estimationIndicatorsSection.indicators = indicators;
        estimationIndicatorsSection.title = title;

        return estimationIndicatorsSection;
    }
}
