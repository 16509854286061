import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-demandeur-edit-general', template: '<div appNgDemandeurEditGeneral></div>'})
export class NgDemandeurEditGeneralComponent {
}

@Directive({selector: '[appNgDemandeurEditGeneral]'})
export class NgDemandeurEditGeneralDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurEditGeneralCtrl', elementRef, injector);
    }
}
