@if (vente$ | async; as vente) {
  <div class="vente tw-h-full">
    <div class="tw-second-nav">
      <div class="tw-second-nav-menus-header">
        <div class="tw-second-nav-menus-title tw-overflow-hidden">
          <app-dossier-bien-header [dossierBien]="vente" class="tw-flex-1 tw-overflow-hidden"/>
        </div>

        <app-collection-navigation [collection]="cVentes" [uuidRef]="vente.uuid" (goBack)="goBack()"
                                   (wantedIdx)="wantedIdx($event)"/>

        <app-call-to-action [type]="ACTION_MAIN" class="tw-mr-2"/>
      </div>

      <div class="tw-second-nav-content">
        <!-- tw-h-full n'est pas utilisable car ça rajoute un ascenseur du fait des 5px -->
        <div class="tw-overflow-hidden tw-h-[calc(100%_-_5px)] tw-flex">
          <div class="tw-overflow-y-auto tw-p-4 tw-flex-1"><router-outlet/></div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
