export default `
<span>
  <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.BROUILLON" class="label label-brouillon">Sans les pré-requis</div>

  <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.DISPONIBLE" class="label label-default">Disponible</div>

  <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.DIFFUSE && $ctrl.hasNetwork" class="label label-success">
    Sur le réseau
  </div>

  <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.SUSPENDU" class="label label-warning">Indisponible</div>

  <div ng-if="$ctrl.location.statut === $ctrl.STATUTS.ARCHIVE" class="label label-inverse">Archivée</div>
</span>
`;
