import {inject, Injectable} from '@angular/core';
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class ToasterService {
    private _toastrService = inject(ToastrService);

    error(title: string, message?: string, options?: { enableHtml?: boolean }): ActiveToast<unknown> {
        return this._toastrService.error(message, title, {...{timeOut: 8000}, ...options});
    }

    info(title: string, message?: string): ActiveToast<unknown> {
        return this._toastrService.info(message, title, {timeOut: 4000});
    }

    success(title: string, message?: string): ActiveToast<unknown> {
        return this._toastrService.success(message, title, {timeOut: 2000});
    }

    warning(title: string, message?: string): ActiveToast<unknown> {
        return this._toastrService.warning(message, title, {timeOut: 4000});
    }
}
