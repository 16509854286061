import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {SortConst, SortDefinition} from '@shared/constants';
import {ICityscanOnsalePropertiesListOptions} from '@features/cityscan/cityscan.interfaces';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import CCityscanOnsaleProperties
    from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    CityscanOnsalePropertyDropdownComponent
} from '@features/cityscan/onsaleProperties/onsaleProperty/dropdown/cityscan-onsale-property.dropdown.component';

@Component({
    selector: 'app-cityscan-onsale-properties-list',
    templateUrl: 'cityscan-onsale-properties-list.component.html',
})
export class AppCityscanOnsalePropertiesListComponent implements OnDestroy, OnInit {
    static readonly initCityscanOnsalePropertiesListOptions: ICityscanOnsalePropertiesListOptions = {
        nameSelection: 'ciyscan-onsale-properties-list-selection',
        tris: {[CCityscanOnsaleProperties.tris.DATE]: SortConst.DESCENDING},
    };
    readonly changedTri = output<[string, SortDefinition]>();
    readonly cityscanOnsalePropertiesSelected = output<CityscanOnsaleProperty[]>();
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dropdownService = inject(DropdownService);
    private _cCityscanOnsaleProperties$!: Observable<CCityscanOnsaleProperties>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: ICityscanOnsalePropertiesListOptions = {...AppCityscanOnsalePropertiesListComponent.initCityscanOnsalePropertiesListOptions};
    private _superficieLabel!: string;

    get CCITYSCAN_ONSALE_PROPERTIES_TRIS(): Record<string, string> {
        return CCityscanOnsaleProperties.tris;
    }

    get cCityscanOnsaleProperties$(): Observable<CCityscanOnsaleProperties> {
        return this._cCityscanOnsaleProperties$;
    }

    @Input({required: true})
    set cCityscanOnsaleProperties$(value$: Observable<CCityscanOnsaleProperties>) {
        this._cCityscanOnsaleProperties$ = value$;
    }

    get options(): ICityscanOnsalePropertiesListOptions {
        return this._options;
    }

    @Input()
    set options(value: ICityscanOnsalePropertiesListOptions) {
        this._options = {...AppCityscanOnsalePropertiesListComponent.initCityscanOnsalePropertiesListOptions, ...value};
        this._options.tris = value.tris ?? AppCityscanOnsalePropertiesListComponent.initCityscanOnsalePropertiesListOptions.tris;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._options.mainNature!);
        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<CityscanOnsaleProperty>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.cityscanOnsalePropertiesSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }

    openDropdown(htmlButtonElement: HTMLButtonElement, cityscanOnsaleProperty: CityscanOnsaleProperty): void {
        this._dropdownService.open(htmlButtonElement, {component: CityscanOnsalePropertyDropdownComponent, data: {cityscanOnsaleProperty}});
    }

    isEnabled(cityscanOnsaleProperty: CityscanOnsaleProperty): boolean {
        if (!this.options.filterEnabled) {
            return true;
        }

        return this.options.filterEnabled(cityscanOnsaleProperty);
    }
}
