import Etude from '@models/etudes/etude/etude.model';
import {EstimationFieldsConst} from '@models/estimations/estimations.constants';
import {LocationFieldsConst} from '@models/locations/locations.constants';
import {VenteFieldsConst} from '@models/ventes/ventes.constants';

export const DossierBienBienFieldsConst = {
    accesHandicape: 'accesHandicape',
    administrationSyndicLoiAlur: 'administrationSyndicLoiAlur',
    adresseReseau: 'adresseReseau',
    alarmeLocation: 'alarmeLocation',
    alarmePleinePropriete: 'alarmePleinePropriete',
    anneeConstruction: 'anneeConstruction',
    annexe: 'annexe',
    ascenseur: 'ascenseur',
    assainissementNonConforme: 'assainissementNonConforme',
    assainissements: 'assainissements',
    aucunAssainissement: 'aucunAssainissement',
    aucunChauffage: 'aucunChauffage',
    balcon: 'balcon',
    cadastres: 'cadastres',
    causeVente: 'causeVente',
    cave: 'cave',
    chambreRdc: 'chambreRdc',
    charges: 'charges',
    chargesLocatives: 'chargesLocatives',
    charpentes: 'charpentes',
    chauffageCollectif: 'chauffageCollectif',
    chauffages: 'chauffages',
    cheminee: 'cheminee',
    chemineeFonctionnelle: 'chemineeFonctionnelle',
    chemineeOrnement: 'chemineeOrnement',
    citerneGazLocation: 'citerneGazLocation',
    citerneGazPleinePropriete: 'citerneGazPleinePropriete',
    clotures: 'clotures',
    codePostal: 'codePostal',
    combles: 'combles',
    commentsAccessibiliteEtEnvironnement: 'commentsAccessibiliteEtEnvironnement',
    commentsAgencementExterieur: 'commentsAgencementExterieur',
    commentsAgencementInterieur: 'commentsAgencementInterieur',
    commentsBatiment: 'commentsBatiment',
    commentsCharges: 'commentsCharges',
    commentsConfidentiel: 'commentsConfidentiel',
    commentsConfreres: 'commentsConfreres',
    commentsConstruction: 'commentsConstruction',
    commentsCouts: 'commentsCouts',
    commentsLoiAlur: 'commentsLoiAlur',
    commentsRisques: 'commentsRisques',
    commentsTerrain: 'commentsTerrain',
    commentsUrbanisme: 'commentsUrbanisme',
    commentsVue: 'commentsVue',
    commodites: 'commodites',
    commune: 'commune',
    connexionsInternet: 'connexionsInternet',
    coordonneesSyndicLoiAlur: 'coordonneesSyndicLoiAlur',
    cour: 'cour',
    cuisineEquipee: 'cuisineEquipee',
    cuveFuelAerienne: 'cuveFuelAerienne',
    cuveFuelEnterree: 'cuveFuelEnterree',
    cuveInactiveDegazee: 'cuveInactiveDegazee',
    cuveInactiveNeutralizee: 'cuveInactiveNeutralizee',
    dateDisponibilite: 'dateDisponibilite',
    declivite: 'declivite',
    degreDeclivite: 'degreDeclivite',
    dernierEtage: 'dernierEtage',
    descriptif: 'descriptif',
    descriptifMandat: 'descriptifMandat',
    designation: 'designation',
    detecteurFumee: 'detecteurFumee',
    diagnosticsRealises: 'diagnosticsRealises',
    epoqueConstruction: 'epoqueConstruction',
    etat: 'etat',
    exceptionnel: 'exceptionnel',
    exposition: 'exposition',
    fullInlineAdresse: 'fullInlineAdresse',
    garage: 'garage',
    grenier: 'grenier',
    hasLoiAlur: 'hasLoiAlur',
    hauteurSousPlafond: 'hauteurSousPlafond',
    huisseries: 'huisseries',
    inlineAdresse: 'inlineAdresse',
    isolations: 'isolations',
    linkPhotos: 'linkPhotos',
    linkPremierePhoto: 'linkPremierePhoto',
    linkPremierePhoto16by9: 'linkPremierePhoto16by9',
    linkPremierePhotoOptimized: 'linkPremierePhotoOptimized',
    linkPremierePhotoThumbnail: 'linkPremierePhotoThumbnail',
    linkPrivatePhotos: 'linkPrivatePhotos',
    linkPrivatePremierePhoto: 'linkPrivatePremierePhoto',
    linkProtectedPhotos: 'linkProtectedPhotos',
    linkProtectedPremierePhoto: 'linkProtectedPremierePhoto',
    linkResponsableDossier: 'linkResponsableDossier',
    logements: 'logements',
    longueurFacade: 'longueurFacade',
    loyerActuel: 'loyerActuel',
    materiauxConstruction: 'materiauxConstruction',
    materiauxCouverture: 'materiauxCouverture',
    mitoyennete: 'mitoyennete',
    montantQuotePartLoiAlur: 'montantQuotePartLoiAlur',
    montantTravauxLoiAlur: 'montantTravauxLoiAlur',
    nature: 'nature',
    nombreBains: 'nombreBains',
    nombreChambres: 'nombreChambres',
    nombreCheminees: 'nombreCheminees',
    nombreCoproprietairesLoiAlur: 'nombreCoproprietairesLoiAlur',
    nombreDouches: 'nombreDouches',
    nombreEtages: 'nombreEtages',
    nombreEtagesBatiment: 'nombreEtagesBatiment',
    nombreLogements: 'nombreLogements',
    nombreLotsLoiAlur: 'nombreLotsLoiAlur',
    nombrePieces: 'nombrePieces',
    nombrePlacesParking: 'nombrePlacesParking',
    nombreSallesBains: 'nombreSallesBains',
    nombreSallesEau: 'nombreSallesEau',
    nombreWc: 'nombreWc',
    nomVoie: 'nomVoie',
    numeroCle: 'numeroCle',
    numeroEtage: 'numeroEtage',
    numeroVoie: 'numeroVoie',
    occupation: 'occupation',
    panneaux: 'panneaux',
    panneauxPhotovoltaiques: 'panneauxPhotovoltaiques',
    performanceEnergetique: 'performanceEnergetique',
    pieceEauRdc: 'pieceEauRdc',
    piscine: 'piscine',
    plainPied: 'plainPied',
    planSauvegardeLoiAlur: 'planSauvegardeLoiAlur',
    pointEauCampagne: 'pointEauCampagne',
    pointEauVille: 'pointEauVille',
    quartier: 'quartier',
    raccordements: 'raccordements',
    residence: 'residence',
    residenceService: 'residenceService',
    responsableDossierId: 'responsableDossierId',
    servitude: 'servitude',
    sousSol: 'sousSol',
    superficie: 'superficie',
    surfaceAnnexe: 'surfaceAnnexe',
    surfaceBalcon: 'surfaceBalcon',
    surfaceBatie: 'surfaceBatie',
    surfaceCadastrale: 'surfaceCadastrale',
    surfaceCarrez: 'surfaceCarrez',
    surfaceCave: 'surfaceCave',
    surfaceCour: 'surfaceCour',
    surfaceGarage: 'surfaceGarage',
    surfaceNonBatie: 'surfaceNonBatie',
    surfacePlancher: 'surfacePlancher',
    surfaces: 'surfaces',
    surfaceSejour: 'surfaceSejour',
    surfaceTerrasse: 'surfaceTerrasse',
    terrasse: 'terrasse',
    titre: 'titre',
    type: 'type',
    usage: 'usage',
    veranda: 'veranda',
    viabilisable: 'viabilisable',
    viabilisationAssainissement: 'viabilisationAssainissement',
    viabilisationAssainissementCollectifRaccorde: 'viabilisationAssainissementCollectifRaccorde',
    viabilisationComments: 'viabilisationComments',
    viabilisationEau: 'viabilisationEau',
    viabilisationElectricite: 'viabilisationElectricite',
    viabilisationFibre: 'viabilisationFibre',
    viabilisationGaz: 'viabilisationGaz',
    viabilisationTelecom: 'viabilisationTelecom',
    viabilisationVoirie: 'viabilisationVoirie',
    voie: 'voie',
    volets: 'volets',
    vues: 'vues',
    wcBroyeur: 'wcBroyeur',
    zoneInondable: 'zoneInondable',
    zonePLU: 'zonePLU',
    zoneRisques: 'zoneRisques',
};
export const DossierBienFieldsConst = {...DossierBienBienFieldsConst, ...EstimationFieldsConst, ...LocationFieldsConst, ...VenteFieldsConst};

class DossierBiensSources {
    static readonly BIENS_CORRESPONDANTS: DossierBienSource = 'biens_correspondants';
    static readonly NETWORK: DossierBienSource = Etude.enablableModules.NETWORK.code as DossierBienSource;
    static readonly WALLET: DossierBienSource = 'wallet';
}

export const DossierBiensSourcesConst = DossierBiensSources;
export type DossierBienSource = 'biens_correspondants' | 'network' | 'wallet';

class DossierBienTypes {
    static readonly ESTIMATION: DossierBienType = 'estimation';
    static readonly LOCATION: DossierBienType = 'location';
    static readonly VENTE: DossierBienType = 'vente';
}

export const DossierBienTypesConst = DossierBienTypes;
export type DossierBienType = 'estimation' | 'location' | 'vente';
