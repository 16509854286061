import {Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {IDynamicComponentLoading} from '@shared/shared.interfaces';

@Component({selector: 'app-dropdown-content', templateUrl: 'dropdown-content.component.html'})
export class DropdownContentComponent {
    @Output() closed = new EventEmitter<void>();
    // Impossible de passer avec la ligne ci-dessous, ça crée une erreur dans DropdownComponent
    // Readonly closed = output<string>();
    @ViewChild('dropdownContent', {read: ViewContainerRef, static: true}) dropdownContent!: ViewContainerRef;

    private _show = false;

    @Input()
    set dynamicComponentLoading(dynamicComponentLoading: IDynamicComponentLoading) {
        const componentRef = this.dropdownContent.createComponent(dynamicComponentLoading.component);

        componentRef.instance.data = dynamicComponentLoading.data;
    }

    get show(): boolean {
        return this._show;
    }

    @Input()
    set show(value: boolean) {
        this._show = value;
    }

    close(): void {
        this.closed.emit();
    }
}
