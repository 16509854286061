import {inject, Injectable} from '@angular/core';
import {TemplateHeaderFactory} from '@models/templates/headers/header/template-header.factory';
import EtudeSettingsDocumentsHeader
    from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import CTemplateHeaders from '@models/templates/headers/collection/template-headers.collection.model';

@Injectable({providedIn: 'root'})
export class TemplateHeaderService {
    private _templateHeaderFactory = inject(TemplateHeaderFactory);

    getHeightTemplate$(etudeSettingsDocumentsHeader: EtudeSettingsDocumentsHeader,
                       cTemplateHeaders$: Observable<CTemplateHeaders> = of(undefined as unknown as CTemplateHeaders)): Observable<[number, string]> {
        return cTemplateHeaders$.pipe(
            map(cTemplateHeaders => cTemplateHeaders?.findByCode(etudeSettingsDocumentsHeader.code)),
            map(templateHeaderLikeESDH => this._templateHeaderFactory.createFromEtudeSettingsDocumentsHeader(etudeSettingsDocumentsHeader, templateHeaderLikeESDH)),
            map(templateHeaderFromESDH => [templateHeaderFromESDH.height, templateHeaderFromESDH.template]),
        );
    }
}
