class NoteoStatutColor {
    static readonly BLUE = 'blue';
    static readonly GREEN = 'green';
    static readonly ORANGE = 'orange';
    static readonly RED = 'red';
    static readonly ROSE = 'rose';
    static readonly STONE = 'stone';
}

export const NoteoStatutColorConst = NoteoStatutColor;
export type NoteoStatutColorType = 'blue' | 'green' | 'orange' | 'red' | 'rose' | 'stone';
