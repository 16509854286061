import {inject, Injectable} from '@angular/core';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {IContactsGroupMemberApi} from '@models/contacts-group/members/member/contacts-group-member.interfaces';
import {NgContactsGroupMember} from '@legacy/app/managers/ressources';
import Contact from '@models/contacts/contact/contact.model';
import {Observable} from 'rxjs';
import {ContactsGroupMembersApiService} from '@models/contacts-group/members/contacts-group-members.api.service';
import {map} from 'rxjs/operators';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

@Injectable({providedIn: 'root'})
export class ContactsGroupMemberFactory {
    private _contactFactory = inject(ContactFactory);
    private _contactsGroupMembersApiService = inject(ContactsGroupMembersApiService);

    create(contactsGroupMemberApi: IContactsGroupMemberApi): ContactsGroupMember {
        const contactsGroupMember = this.createVirgin();

        contactsGroupMember.sortableRank = contactsGroupMemberApi.sortableRank;
        if (contactsGroupMemberApi._embedded?.contact) {
            contactsGroupMember.contact = this._contactFactory.create(contactsGroupMemberApi._embedded.contact);
        }

        return contactsGroupMember;
    }

    createFromContact(contact: Contact): ContactsGroupMember {
        const contactsGroup = this.createVirgin();

        contactsGroup.contact = contact;

        return contactsGroup;
    }

    createVirgin(): ContactsGroupMember {
        return new ContactsGroupMember();
    }

    delete$(contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember): Observable<void> {
        return this._contactsGroupMembersApiService.delete$(contactsGroup.uuid, contactsGroupMember.id.toString());
    }

    forApi(contactsGroupMember: ContactsGroupMember): IContactsGroupMemberApi {
        return {contactId: contactsGroupMember.contact.id} as IContactsGroupMemberApi;
    }

    save$(contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember): Observable<ContactsGroupMember> {
        return this._contactsGroupMembersApiService.save$(contactsGroup.uuid, this.forApi(contactsGroupMember)).pipe(map(contactsGroupMemberApi => this.create(contactsGroupMemberApi)));
    }

    updateRank$(contactsGroup: ContactsGroup, contactsGroupMember: ContactsGroupMember, rank: number): Observable<void> {
        return this._contactsGroupMembersApiService.updateRank$(contactsGroup.uuid, contactsGroupMember.id.toString(), rank);
    }

    ngCreate(ngContactsGroupMember: NgContactsGroupMember): ContactsGroupMember {
        const contactsGroupMember = new ContactsGroupMember();

        contactsGroupMember.sortableRank = ngContactsGroupMember.sortableRank;
        if (ngContactsGroupMember.contact) {
            contactsGroupMember.contact = this._contactFactory.ngCreate(ngContactsGroupMember.contact);
        }

        return contactsGroupMember;
    }
}
