import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgPhotoManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgUserPhoto} from '@legacy/app/managers/ressources';
import UserPhoto from '@models/users/user/photo/user-photo.model';

export default function getManagersUserPhoto(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('UserPhotoManager', manager);

        /**
         * Manager userPhoto
         *
         * @param BaseManager
         * @param PhotoManager
         * @param UtilsManager
         * @returns {UserPhotoManager}
         */
        manager.$inject = ['BaseManager', 'PhotoManager', 'UtilsManager'];
        function manager(baseManager: NgManager, photoManager: NgPhotoManager, utilsManager: NgUtilsManager) {
            let classResource = baseManager.getClass<NgUserPhoto>();

            /**
             * Routing
             */
            baseManager.addRoute('user-photos.insert', {path: '/user/photos', method: 'POST'});
            baseManager.addRoute('user-photos.edit', {path: '/user/photos', method: 'PUT'});
            baseManager.addRoute('user-photos.remove', {path: '/user/photos', method: 'DELETE'});

            /**
             * UserPhotoManager object
             *
             * @constructor
             */
            angular.extend(UserPhotoManager.prototype, baseManager.__proto__);
            UserPhotoManager.prototype.eskManager = {prefixRoute: 'user-photos', collectionName: 'items'};
            function UserPhotoManager() {
            }

            /**
             * Create a UserPhoto object
             *
             * @param ng2UserPhoto
             * @param href
             * @returns manager.UserPhoto
             */
            UserPhotoManager.prototype.createFromNg2 = function (ng2UserPhoto: UserPhoto, href: string): NgUserPhoto {
                if (!ng2UserPhoto) {
                    return undefined!;
                }

                return this.create({
                    _links: {file: {href}},
                    bottomRightCoordinates: ng2UserPhoto.bottomRightCoordinates,
                    photo: {id: ng2UserPhoto.photoId},
                    topLeftCoordinates: ng2UserPhoto.topLeftCoordinates,
                });
            };

            /**
             * Create a UserPhoto object
             *
             * @param data
             * @returns manager.UserPhoto object
             */
            UserPhotoManager.prototype.create = function (data: any) {
                // @ts-ignore
                return new UserPhoto(data);
            };

            /**
             * UserPhoto object
             *
             * @param data
             * @constructor
             */
            angular.extend(UserPhoto.prototype, classResource.prototype);
            UserPhoto.prototype.eskManager = {prefixRoute: 'user-photos'};
            function UserPhoto(this: NgUserPhoto, data: any) {
                this.extend(data);
                this.id = (angular.isObject(this._links) && angular.isObject(this._links.file) && angular.isString(this._links.file.href)) as unknown as number;
                this._esk.defaultRouteParams = {};
                this._esk.persist = !!this.id;
                this._esk.flush = this._esk.persist;
            }

            /**
             * Extend the existing UserPhoto with new data
             *
             * @param data
             * @returns {UserPhoto}
             */
            UserPhoto.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.photo = photoManager.create(this.photo);
                this._esk.href = utilsManager.createPhotoHref(this, true);

                return this;
            };

            /**
             * Sanitize object UserPhoto before send to API
             *
             * @returns {object}
             */
            UserPhoto.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                utilsManager.flattenIdForAttribute(sanitizeObject, 'photo');

                return sanitizeObject;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            UserPhoto.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self._esk.href = utilsManager.createPhotoHref(self, true);
                    self.id = angular.isObject(self._links) && angular.isObject(self._links.file) && angular.isString(self._links.file.href);
                    self._esk.defaultRouteParams.id = self.photo.id;

                    return self;
                });
            };

            // @ts-ignore
            return new UserPhotoManager();
        }
    })(angularJS);
}
