import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getContactInitiales(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact-initiales></esk-contact-initiales>
         */
        module.component('eskContactInitiales', {
            templateUrl: 'src/app/legacy/templates/contacts/components/item/initiales.html',
            bindings: {contact: '='}
        });
    })(angularJS);
}
