import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewUrbanismeService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isCommentsUrbanismeVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isZonePLUVisible!: boolean;

    get isCommentsUrbanismeVisible(): boolean {
        return this._isCommentsUrbanismeVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isZonePLUVisible(): boolean {
        return this._isZonePLUVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isCommentsUrbanismeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsUrbanisme);
        this._isZonePLUVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.zonePLU);

        this._isPartVisible = this.isCommentsUrbanismeVisible || this.isZonePLUVisible;
    }
}
