import Collection from '@models/collection/collection.model';
import VentePrixHistory from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.model';
import DateFormat from '@shared/date/date.format';

export default class CVentePrixHistories extends Collection<VentePrixHistory> {
    private _isDecreasing = false;
    private _isRaising = false;

    get isDecreasing(): boolean {
        return this._isDecreasing;
    }

    set isDecreasing(value: boolean) {
        this._isDecreasing = value;
    }

    get isRaising(): boolean {
        return this._isRaising;
    }

    set isRaising(value: boolean) {
        this._isRaising = value;
    }

    setMoving(): void {
        this.isDecreasing = false;
        this.isRaising = false;

        if (this.total <= 1 || DateFormat.isNowOlder(DateFormat.addMonthsToDateString(this.results[0].createdAt, 3))) {
            return;
        }

        this.isDecreasing = this.results[0].prixVente < this.results[1].prixVente;
        this.isRaising = this.results[0].prixVente > this.results[1].prixVente;
    }
}
