import angularJS from '@shared/angularJS/global.ng';
import {IModule, IWindowService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import Procedure from '@models/procedures/procedure/procedure.model';
import Document from '@models/documents/document/document.model';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {
    CProcedureSignatairesService
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.service';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {from, Observable, of} from 'rxjs';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgEmailManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgBienDossier, NgContact, NgDemandeur, NgUser} from '@legacy/app/managers/ressources';
import {DocumentService} from '@models/documents/document/document.service';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import {UserFactory} from '@models/users/user/user.factory';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';

export default function getEskModalProcedureCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EskModalProcedureCtrl', Controller);

        /**
         * Controller to edit procedure
         *
         * @param document
         * @param procedure
         * @param $uibModalInstance
         * @param SoqSweetAlert
         * @param $translate
         * @param Ng2ProcedureService
         * @param Ng2PhoneNumberService
         * @param Ng2CProcedureSignatairesService
         * @param Ng2ProcedureSignataireFactory
         * @param Ng2ContactFactory
         * @param Ng2ToasterService
         * @param SoqModalesManager
         * @param EmailManager
         * @param VenteManager
         * @param Ng2UserService
         * @param $window
         * @param Ng2UserFactory
         */
        Controller.$inject = ['document', 'procedure', '$uibModalInstance', 'SoqSweetAlert', '$translate', 'Ng2ProcedureService', 'Ng2PhoneNumberService', 'Ng2CProcedureSignatairesService', 'Ng2ProcedureSignataireFactory', 'Ng2ContactFactory', 'Ng2ToasterService', 'SoqModalesManager', 'EmailManager', 'Ng2DocumentService', 'VenteManager', 'Ng2UserService', '$window', 'Ng2UserFactory'];
        function Controller(this: any,
                            document: Document,
                            procedure: Procedure,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            ng2ProcedureService: ProcedureService,
                            ng2PhoneNumberService: PhoneNumberService,
                            ng2CProcedureSignatairesService: CProcedureSignatairesService,
                            ng2ProcedureSignataireFactory: ProcedureSignataireFactory,
                            ng2ContactFactory: ContactFactory,
                            ng2ToasterService: ToasterService,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            ng2DocumentService: DocumentService,
                            venteManager: NgVenteManager,
                            ng2UserService: UserService,
                            $window: IWindowService,
                            ng2UserFactory: UserFactory) {
            const $ctrl = this;

            $ctrl.addMe = addMe;
            $ctrl.addSignataire = addSignataire;
            $ctrl.cancel = cancel;
            $ctrl.copyLinkInterfaceSignature = copyLinkInterfaceSignature;
            $ctrl.dismiss = dismiss;
            $ctrl.launch = launch;
            $ctrl.removeSignataire = removeSignataire;
            $ctrl.saveSignataires = saveSignataires;
            $ctrl.sendLinkInterfaceSignature = sendLinkInterfaceSignature;
            $ctrl.updateAmSignataire = updateAmSignataire;
            $ctrl.amSignataire = false;
            $ctrl.options = {
                loader: false,
                participants: {fullObject: true, placeholder: 'Rechercher un contact ou un utilisateur'},
            };
            $ctrl.ng2PhoneNumberService = ng2PhoneNumberService;
            $ctrl.documentProcedure = document;
            $ctrl.procedure = procedure;
            $ctrl.updateAmSignataire($ctrl.procedure.cSignataires);
            $ctrl.procedureProviders = [];
            ng2UserService.last$.pipe(
                map(currentUser => {
                    if (currentUser.hasRoleSignatureElectroniqueYousign()) {
                        $ctrl.procedureProviders.push(Procedure.providers.yousign);
                    }

                    if (currentUser.hasRoleSignatureElectroniqueSoqrate()) {
                        $ctrl.procedureProviders.push(Procedure.providers.soqrate);
                    }
                }),
            ).subscribe(_ => {
                if (!($ctrl.procedureProviders as {
                    code: string
                }[]).some(provider => provider.code === $ctrl.procedure.provider)) {
                    $ctrl.procedure.provider = $ctrl.procedureProviders[0].code;
                }
            });

            function addMe(): void {
                ng2UserService.last$.subscribe(currentUser => $ctrl.addSignataire(currentUser));
            }

            /**
             * Add signataire
             *
             * @param participant
             */
            function addSignataire(participant: { linkSelf: unknown; reachableRoles: unknown }) {
                let procedureSignataire: ProcedureSignataire;

                if (participant?.reachableRoles) {
                    procedureSignataire = ng2ProcedureSignataireFactory.createFromUser(ng2UserFactory.ngCreate(participant as unknown as NgUser));
                } else if (participant?.linkSelf) {
                    procedureSignataire = ng2ProcedureSignataireFactory.createFromUser(participant as unknown as User);
                } else if (participant) {
                    procedureSignataire = ng2ProcedureSignataireFactory.createFromContact(ng2ContactFactory.ngCreate(participant as unknown as NgContact));
                } else {
                    procedureSignataire = ng2ProcedureSignataireFactory.createVirgin();
                }

                $ctrl.procedure.cSignataires.addResult(procedureSignataire);
                $ctrl.updateAmSignataire($ctrl.procedure.cSignataires);
            }

            /**
             * Cancel procedure
             */
            function cancel() {
                const modale = $window.document.getElementsByClassName('modal-procedure')[0] as HTMLElement;
                const modaleBackdrop = $window.document.getElementsByClassName('modal-backdrop')[0] as HTMLElement;
                const modaleDisplay = modale.style.display;
                const modaleBackdropDisplay = modaleBackdrop.style.display;

                $ctrl.options.loader = true;
                modale.style.display = 'none';
                modaleBackdrop.style.display = 'none';
                ng2ProcedureService.cancel$($ctrl.procedure).pipe(take(1)).subscribe({
                    next: isAccepted => {
                        modale.style.display = modaleDisplay;
                        modaleBackdrop.style.display = modaleBackdropDisplay;
                        $ctrl.options.loader = false;
                        if (isAccepted) {
                            $uibModalInstance.close($ctrl.procedure);
                        }
                    },
                    error: _ => $ctrl.options.loader = false,
                });
            }

            /**
             * Copy link interfaceSignature
             */
            function copyLinkInterfaceSignature(procedureSignataire: ProcedureSignataire) {
                navigator.clipboard.writeText(procedureSignataire.linkInterfaceSignature);
                ng2ToasterService.info('Lien copié dans le presse-papier', 'Le lien pour accéder à l\'interface de signature est accessible avec un "CTRL+V" ou clic droit "Coller".');
            }

            /**
             * Dismiss modale
             */
            function dismiss() {
                if (procedure.isNewOrDraft()) {
                    $uibModalInstance.close(procedure);
                } else {
                    $uibModalInstance.dismiss();
                }
            }

            /**
             * Save, launch and close modale
             */
            function launch() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant('formulaire.invalid.TITLE'), $translate.instant('formulaire.invalid.MESSAGE'));

                    return;
                }

                if (!$ctrl.procedure.isValid()) {
                    soqSweetAlert.warningMessage('Saisie d\'une procédure', 'Les signataires ne sont pas correctement saisis.');

                    return;
                }

                $ctrl.options.loader = true;
                ng2ProcedureService.save$($ctrl.procedure).pipe(
                    tap(procedure => $ctrl.procedure = procedure),
                    switchMap(procedure => {
                        let procedureValidation$ = of(undefined! as unknown);

                        if (!procedure.isPresentiel()) {
                            procedureValidation$ = from(soqSweetAlert.warningConfirmation(
                                'Lancement d\'une procédure de signature ' + $ctrl.procedure.providerLabel,
                                'Vous êtes sur le point de lancer une procédure de signature ' +
                                $ctrl.procedure.providerLabel + ' du ' + document.titre + ' comprenant ' +
                                $ctrl.procedure.cSignataires.total +
                                (procedure.cSignataires.total > 1 ? ' signataires' : ' signataire') +
                                '.<br>Cette procédure vous sera facturée.',
                                {confirmButtonText: 'Lancer la procédure'}
                            ));
                        }

                        return procedureValidation$.pipe(map(_ => procedure));
                    }),
                    switchMap(procedure => ng2ProcedureService.launch$(procedure)),
                    take(1),
                ).subscribe({
                    next: procedure => $uibModalInstance.close(procedure),
                    error: _ => $ctrl.options.loader = false,
                });
            }

            /**
             * Remove signataire
             *
             * @param procedureSignataire
             */
            function removeSignataire(procedureSignataire: ProcedureSignataire) {
                ng2CProcedureSignatairesService.delete$($ctrl.procedure.uuid, $ctrl.procedure.cSignataires, procedureSignataire)
                    .subscribe(_ => $ctrl.updateAmSignataire($ctrl.procedure.cSignataires));
            }

            /**
             * Send link interfaceSignature
             *
             * @param procedureSignataire
             */
            function sendLinkInterfaceSignature(procedureSignataire: ProcedureSignataire) {
                const modalProcedure = $window.document.querySelector('.modal-procedure') as unknown as {
                    style: { display: string }
                };

                modalProcedure.style.display = 'none';
                ng2DocumentService.getOne$($ctrl.procedure.documentId).pipe(
                    switchMap((document: Document): Observable<[Document, NgBienDossier | NgDemandeur]> => {
                        if (!document.id || !document.variables || !document.variables.venteUuid) {
                            return of([document, undefined!]);
                        }

                        return from(venteManager.getOneByUuid(document.variables.venteUuid)).pipe(
                            map(ngVente => [document, ngVente]),
                        );
                    }),
                    map(([document, dossier]: [Document, NgBienDossier | NgDemandeur]) => {
                        let dossiers = [] as unknown[];

                        if (!!dossier) {
                            dossiers.push({typeId: dossier.id, type: dossier._esk.typeDossier, dossier: dossier});
                        }

                        return {
                            resolve: {
                                email: emailManager.create({
                                    recipients: [{
                                        emailAddress: procedureSignataire.email,
                                        type: EmailRecipient.types.TO,
                                        name: procedureSignataire.convivialName,
                                    }],
                                    dossiers,
                                    subject: (!document.id ? undefined! : (document.titre + ' : ')) + 'Signature électronique',
                                    message: 'Bonjour,<br><br>Je vous transmets le lien vers la signature électronique' +
                                        (!document.id ? undefined! : (' de votre ' + document.titre)) +
                                        '.<br>Je vous invite à cliquer sur le lien pour accéder au document et le signer.<br>' +
                                        procedureSignataire.linkInterfaceSignature +
                                        '<br><br>Je vous prie d\'agréer l\'expression de mes salutations les plus distinguées.<br><br>' +
                                        emailManager.getCurrentUserSignature()
                                }),
                                currentDossier: dossier,
                            }
                        };
                    }),
                    take(1),
                ).subscribe(params => soqModalesManager.open('EskModalEmail', params).finally(() => modalProcedure.style.display = 'block'));
            }

            /**
             * Save signataires
             */
            function saveSignataires() {
                ng2CProcedureSignatairesService.save$($ctrl.procedure).subscribe(_ => $uibModalInstance.close(procedure));
            }

            function updateAmSignataire(cSignataires: CProcedureSignataires): void {
                ng2UserService.last$.pipe(
                    map(currentUser => cSignataires?.results.some(signataire => signataire.isUser() && signataire.typeId === currentUser.id)),
                ).subscribe(amSignataire => $ctrl.amSignataire = amSignataire);
            }
        }
    })(angularJS);
}
