import {inject, Injectable} from '@angular/core';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import BienSurface from '@models/bien/surfaces/surface/bien-surface.model';
import {NgBienPiece} from '@legacy/app/managers/ressources';
import {IBienSurfaceApi} from '@models/bien/surfaces/surface/bien-surface.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BienSurfacesApiService} from '@models/bien/surfaces/bien-surfaces.api.service';

@Injectable({providedIn: 'root'})
export class BienSurfaceFactory {
    private _bienSurfacesApiService = inject(BienSurfacesApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienSurfaceApi: IBienSurfaceApi): BienSurface {
        const bienSurface: BienSurface = new BienSurface(bienSurfaceApi.uuid, bienSurfaceApi.id);

        bienSurface.comments = bienSurfaceApi.comments;
        bienSurface.habitable = bienSurfaceApi.habitable;
        bienSurface.otherLabel = bienSurfaceApi.otherLabel;
        bienSurface.ponderation = bienSurfaceApi.ponderation;
        bienSurface.surface = bienSurfaceApi.surface;
        if (bienSurfaceApi._embedded) {
            if (bienSurfaceApi._embedded.etage) {
                bienSurface.etage = this._dictionaryItemFactory.create(bienSurfaceApi._embedded.etage);
            }

            if (bienSurfaceApi._embedded.piece) {
                bienSurface.piece = this._dictionaryItemFactory.create(bienSurfaceApi._embedded.piece);
            }
        }

        return bienSurface;
    }

    forApi(bienSurface: BienSurface): IBienSurfaceApi {
        const bienSurfaceApi = {
            comments: bienSurface.comments,
            habitable: bienSurface.habitable,
            otherLabel: bienSurface.otherLabel,
            ponderation: bienSurface.ponderation,
            surface: bienSurface.surface,
        } as IBienSurfaceApi;

        if (bienSurface.etage) {
            bienSurfaceApi.etageId = bienSurface.etage.id;
        }

        if (bienSurface.piece) {
            bienSurfaceApi.pieceId = bienSurface.piece.id;
        }

        return bienSurfaceApi;
    }

    save$(dossierBien: ADossierBien, bienSurface: BienSurface): Observable<BienSurface> {
        return this._bienSurfacesApiService.save$(dossierBien.id.toString(), bienSurface.id?.toString() ?? BienSurface.STATUT_NEW, this.forApi(bienSurface))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }

    ngCreate(ngBienPiece: NgBienPiece): BienSurface {
        const bienSurface: BienSurface = new BienSurface(ngBienPiece.uuid, ngBienPiece.id);

        bienSurface.comments = ngBienPiece.comments;
        bienSurface.habitable = ngBienPiece.habitable;
        bienSurface.ponderation = ngBienPiece.ponderation;
        bienSurface.surface = ngBienPiece.surface;
        if (ngBienPiece.etage) {
            bienSurface.etage = this._dictionaryItemFactory.ngCreate(ngBienPiece.etage);
        }

        if (ngBienPiece.piece) {
            if (ngBienPiece.piece.id) {
                bienSurface.piece = this._dictionaryItemFactory.ngCreate(ngBienPiece.piece);
            } else {
                bienSurface.otherLabel = ngBienPiece.piece.label;
            }
        }

        return bienSurface;
    }
}
