import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import CVenteMatchings from '@models/ventes/vente/matchings/collection/vente-matching.collection.model';
import {CVenteMatchingsFactory} from '@models/ventes/vente/matchings/collection/vente-matching.collection.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {VenteMatchingFactory} from '@models/ventes/vente/matchings/vente-matching/vente-matching.factory';

@Injectable({providedIn: 'root'})
export class CVenteMatchingsService {
    private _cVenteMatchingsFactory = inject(CVenteMatchingsFactory);
    private _etudeService = inject(EtudeService);
    private _venteMatchingFactory = inject(VenteMatchingFactory);

    getForMeAndWhenNoOne$(venteUuid: string): Observable<CVenteMatchings> {
        return this._cVenteMatchingsFactory.get$(venteUuid).pipe(
            switchMap(cVenteMatchings => this._etudeService.last$.pipe(
                map(currentEtude => currentEtude.linkSelf),
                map(linkSelfEtude => {
                    if (!cVenteMatchings.getByLinkEtude(linkSelfEtude)) {
                        cVenteMatchings.add(this._venteMatchingFactory.createVirginForEtude(linkSelfEtude), true);
                    }

                    if (cVenteMatchings.hasOnlyOneByLinkEtude(linkSelfEtude)) {
                        cVenteMatchings.add(this._venteMatchingFactory.createVirgin());
                    }

                    return cVenteMatchings;
                }),
            ))
        );
    }
}
