import {inject, Injectable} from '@angular/core';
import {TemplateFooterFactory} from '@models/templates/footers/footer/template-footer.factory';
import EtudeSettingsDocumentsFooter
    from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import CTemplateFooters from '@models/templates/footers/collection/template-footers.collection.model';

@Injectable({providedIn: 'root'})
export class TemplateFooterService {
    private _templateFooterFactory = inject(TemplateFooterFactory);

    getHeightTemplate$(etudeSettingsDocumentsFooter: EtudeSettingsDocumentsFooter,
                       cTemplateFooters$: Observable<CTemplateFooters> = of(undefined as unknown as CTemplateFooters)): Observable<[number, string]> {
        return cTemplateFooters$.pipe(
            map(cTemplateFooters => cTemplateFooters?.findByCode(etudeSettingsDocumentsFooter.code)),
            map(templateFooterLikeESDH => this._templateFooterFactory.createFromEtudeSettingsDocumentsFooter(etudeSettingsDocumentsFooter, templateFooterLikeESDH)),
            map(templateFooterFromESDH => [templateFooterFromESDH.height, templateFooterFromESDH.template]),
        );
    }
}
