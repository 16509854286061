import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDictionariesManager, NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgEstimationReference} from '@legacy/app/managers/ressources';
import {NgEstimationReferencesCollection} from '@legacy/app/managers/collections';
import Reference from '@models/references/reference/reference.model';
import DateFormat from '@shared/date/date.format';
import {SearchCriteriaSourceConst} from '@models/search/criteria/search-criteria.constants';

export default function getManagersEstimationReference(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EstimationReferenceManager', manager);

        /**
         * Manager estimationReference
         *
         * @param BaseManager
         * @param UtilsManager
         * @param DictionariesManager
         * @returns {EstimationReferenceManager}
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'DictionariesManager'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         dictionariesManager: NgDictionariesManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('estimation-references.cget', {
                path: '/estimations/{estimationId}/references',
                method: 'GET'
            });
            baseManager.addRoute('estimation-references.get', {
                path: '/estimations/{estimationId}/references/{uuid}',
                method: 'GET'
            });
            baseManager.addRoute('estimation-references.insert', {
                path: '/estimations/{estimationId}/references',
                method: 'POST'
            });
            baseManager.addRoute('estimation-references.edit', {
                path: '/estimations/{estimationId}/references/{uuid}',
                method: 'PUT'
            });
            baseManager.addRoute('estimation-references.update-rank', {
                path: '/estimations/{estimationId}/references/{uuid}/rank',
                method: 'PATCH'
            });
            baseManager.addRoute('estimation-references.remove', {
                path: '/estimations/{estimationId}/references/{uuid}',
                method: 'DELETE'
            });

            /**
             * EstimationReferenceManager object
             *
             * @constructor
             */
            angular.extend(EstimationReferenceManager.prototype, baseManager.__proto__);
            EstimationReferenceManager.prototype.eskManager = {
                prefixRoute: 'estimation-references',
                collectionName: 'items'
            };
            function EstimationReferenceManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param estimationId
             * @returns {Promise}
             */
            EstimationReferenceManager.prototype.getAll = function (estimationId: number) {
                return baseManager.getAll.call(this, {estimationId: estimationId})
                    .then(references => references as NgEstimationReferencesCollection)
                    .then((references: NgEstimationReferencesCollection) => {
                        angular.forEach(references.collection, function (reference) {
                            reference._esk.defaultRouteParams!.estimationId = estimationId;
                        });

                        return references;
                    });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param estimationId
             * @param resourceUuid
             * @returns {Promise}
             */
            EstimationReferenceManager.prototype.getOneByUuid = function (estimationId: number, resourceUuid: string) {
                return this.get('get', {estimationId: estimationId, uuid: resourceUuid});
            };

            /**
             * Create a EstimationReference object
             *
             * @param data
             * @param estimationId
             * @returns manager.EstimationReference object || Array of manager.EstimationReference objects
             */
            EstimationReferenceManager.prototype.create = function (data: unknown, estimationId: number) {
                if (angular.isArray(data)) {
                    var references = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        references.push(new EstimationReference(estimationId, data[i]));
                    }

                    return references;
                }

                // @ts-ignore
                return new EstimationReference(estimationId, data);
            };

            EstimationReferenceManager.prototype.createFromNg2 = function (ng2Reference: Reference, estimationId: number) {
                if (!ng2Reference) {
                    return undefined!;
                }

                return this.create({
                    adresse: ng2Reference.adresse,
                    communeId: ng2Reference.communeId,
                    dateVente: ng2Reference.dateVente,
                    epoque: dictionariesManager.createFromNg2(ng2Reference.epoque),
                    loyer: ng2Reference.loyer,
                    nature: dictionariesManager.createFromNg2(ng2Reference.nature),
                    nombreChambres: ng2Reference.nombreChambres,
                    nombreEtages: ng2Reference.nombreEtages,
                    nombrePieces: ng2Reference.nombrePieces,
                    prixVente: ng2Reference.prixVente,
                    superficie: ng2Reference.superficie,
                    surfaceCadastrale: ng2Reference.surfaceCadastrale,
                    sourceType: SearchCriteriaSourceConst.REFERENCE,
                    sourceUuid: ng2Reference.uuid,
                }, estimationId);
            };

            /**
             * EstimationReference object
             *
             * @param estimationId
             * @param data
             * @constructor
             */
            angular.extend(EstimationReference.prototype, classResource.prototype);
            EstimationReference.prototype.eskManager = {prefixRoute: 'estimation-references'};
            function EstimationReference(this: NgEstimationReference, estimationId: number, data: unknown) {
                this.sourceType = SearchCriteriaSourceConst.REFERENCE;
                this.extend(data);
                this.id = this.uuid as unknown as number;
                this._esk.defaultRouteParams = {uuid: this.uuid, estimationId};
                this._esk.persist = angular.isDefined(this.id);
                this._esk.flush = this._esk.persist;
            }

            /**
             * Extend the existing EstimationReference with new data
             *
             * @param data
             * @returns {EstimationReference}
             */
            EstimationReference.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isString(this.dateVente)) {
                    this.dateVente = DateFormat.toDate(this.dateVente);
                }

                this.setPrixM2();

                return this;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            EstimationReference.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    self.id = self.uuid;
                    self._esk.defaultRouteParams.uuid = self.uuid;
                    self.setPrixM2();

                    return self;
                });
            };

            /**
             * Sanitize object EstimationReference before send to API
             *
             * @returns {Object}
             */
            EstimationReference.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet EstimationReference pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'commune');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'epoque');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'nature');

                // @todo https://gitlab.soqrate.com/soqrate/noty/api/issues/956 : A supprimer
                sanitizeObject.communeId = +sanitizeObject.communeId;
                sanitizeObject.dateVente = DateFormat.toAPI(this.dateVente);

                return sanitizeObject;
            };

            /**
             * Set prix M2
             */
            EstimationReference.prototype.setPrixM2 = function () {
                this._esk.prixM2 = this.superficie > 0 ? this.prixVente / this.superficie : 0;
            };

            // @ts-ignore
            return new EstimationReferenceManager();
        }
    })(angularJS);
}
