import AModel from '@models/model.abstract';

export default class BonvisiteLocation extends AModel {
    private _linkLocation!: string;

    get linkLocation(): string {
        return this._linkLocation;
    }

    set linkLocation(value: string) {
        this._linkLocation = value;
    }
}
