import {Component, inject, Input} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';

@Component({selector: 'app-collection-selection-bar', templateUrl: 'collection-selection-bar.component.html'})
export class CollectionSelectionBarComponent {
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _listName!: string;
    private _subText = '';

    get ACTIONS_SELECTION(): string {
        return CallToActionService.SELECTION;
    }

    @Input()
    set listName(value: string) {
        this._listName = value;
    }

    get numberSelected(): number {
        return this._collectionSelectionService.getNumberSelected(this._listName);
    }

    get subText(): string {
        return this._subText;
    }

    @Input()
    set subText(value: string) {
        this._subText = value;
    }

    reset(): void {
        this._collectionSelectionService.clear(this._listName);
    }
}
