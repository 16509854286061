import {inject, Injectable} from '@angular/core';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {Observable, of, switchMap, throwError} from 'rxjs';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import Vente from '@models/ventes/vente/vente.model';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class VentePasserelleService {
    private _ventePasserelleFactory = inject(VentePasserelleFactory);

    getOne$(vente: Vente, etudePasserelle: EtudePasserelle): Observable<VentePasserelle> {
        return this._ventePasserelleFactory.get$(vente, etudePasserelle.passerelle, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return of(this._ventePasserelleFactory.createVirgin(etudePasserelle.passerelle.id, vente.id));
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }

    getOneSaved$(vente: Vente, etudePasserelle: EtudePasserelle): Observable<VentePasserelle> {
        return this.getOne$(vente, etudePasserelle).pipe(switchMap(ventePasserelle => this._ventePasserelleFactory.save$(ventePasserelle)));
    }
}
