import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-auth-logout', template: '<div appNgAuthLogout></div>'})
export class NgAuthLogoutComponent {
}

@Directive({selector: '[appNgAuthLogout]'})
export class NgAuthLogoutDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskAuthLogoutCtrl', elementRef, injector);
    }
}
