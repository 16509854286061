import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getManagersContact from "@legacy/app/managers/services/contacts";
import getManagersBase from "@legacy/app/managers/services/base";
import getManagersUtils from "@legacy/app/managers/services/utils";
import getManagersPerson from "@legacy/app/managers/services/persons";
import getManagersDictionaries from "@legacy/app/managers/services/dictionaries";
import getManagersCommune from "@legacy/app/managers/services/communes";
import getManagersQuartier from "@legacy/app/managers/services/quartiers";
import getManagersEntreprise from "@legacy/app/managers/services/entreprises";
import getManagersUser from "@legacy/app/managers/services/users";
import getManagersSite from "@legacy/app/managers/services/sites";
import getManagersPhoto from "@legacy/app/managers/services/photos";
import getManagersUserPhoto from "@legacy/app/managers/services/users/photos";
import getManagersFilter from "@legacy/app/managers/services/filters";
import getManagersSort from "@legacy/app/managers/services/sorts";
import getManagersArea from "@legacy/app/managers/services/areas";
import getManagersEmail from '@legacy/app/managers/services/emails';
import getManagersEmailAttachment from '@legacy/app/managers/services/emails/attachments';
import getManagersMedia from '@legacy/app/managers/services/medias';
import getManagersEmailRecipient from '@legacy/app/managers/services/emails/recipients';
import getManagersEmailDossier from '@legacy/app/managers/services/emails/dossiers';
import getManagersVente from '@legacy/app/managers/services/ventes';
import getManagersBien from '@legacy/app/managers/services/biens';
import getManagersBienCadastre from '@legacy/app/managers/services/biens/cadastres';
import getManagersBienCharge from '@legacy/app/managers/services/biens/charges';
import getManagersBienCommodite from '@legacy/app/managers/services/biens/commodites';
import getManagersBienDiagnostic from '@legacy/app/managers/services/biens/diagnostics';
import getManagersBienLogement from '@legacy/app/managers/services/biens/logements';
import getManagersBienPhoto from '@legacy/app/managers/services/biens/photos';
import getManagersBienPiece from '@legacy/app/managers/services/biens/pieces';
import getManagersContactsGroup from '@legacy/app/managers/services/contacts-groups';
import getManagersContactsGroupMember from '@legacy/app/managers/services/contacts-groups/members';
import getManagersVenteCompromis from '@legacy/app/managers/services/ventes/compromis';
import getManagersVentePasserelle from '@legacy/app/managers/services/ventes/passerelles';
import getManagersBienPhotoPasserelle from '@legacy/app/managers/services/biens/photos/passerelles';
import getManagersEtudeStatistics from '@legacy/app/managers/services/etudes/statistics';
import getManagersEstimation from '@legacy/app/managers/services/estimations';
import getManagersDemandeur from '@legacy/app/managers/services/demandeurs';
import getManagersDemandeurRecherche from '@legacy/app/managers/services/demandeurs/recherches';
import getManagersDemandeurRechercheResultat from '@legacy/app/managers/services/demandeurs/recherches/resultats';
import getManagersLocation from '@legacy/app/managers/services/locations';
import getManagersLocationPasserelle from '@legacy/app/managers/services/locations/passerelles';
import getManagersTemplate from '@legacy/app/managers/services/templates';
import getManagersVenteContrat from '@legacy/app/managers/services/ventes/contrats';
import getManagersReport from '@legacy/app/managers/services/reports';
import getManagersReportAttachment from '@legacy/app/managers/services/reports/attachments';
import getManagersReportDossier from '@legacy/app/managers/services/reports/dossiers';
import getManagersReportParticipant from '@legacy/app/managers/services/reports/participants';
import getManagersLocationMandat from '@legacy/app/managers/services/locations/mandats';
import getManagersLocationLocataire from '@legacy/app/managers/services/locations/locataires';
import getManagersLocationLocataireEtatLieux from '@legacy/app/managers/services/locations/locataires/etats-lieux';
import getManagersEstimationReference from '@legacy/app/managers/services/estimations/references';

export default function getManagers(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular.module('esk.managers', ['esk.client', 'soqrate']);

        // Services
        getManagersArea(module);
        getManagersBase(module);
        getManagersBien(module);
        getManagersBienCadastre(module);
        getManagersBienCharge(module);
        getManagersBienCommodite(module);
        getManagersBienDiagnostic(module);
        getManagersBienLogement(module);
        getManagersBienPhoto(module);
        getManagersBienPhotoPasserelle(module);
        getManagersBienPiece(module);
        getManagersContact(module);
        getManagersContactsGroup(module);
        getManagersContactsGroupMember(module);
        getManagersCommune(module);
        getManagersDemandeur(module);
        getManagersDemandeurRecherche(module);
        getManagersDemandeurRechercheResultat(module);
        getManagersDictionaries(module);
        getManagersEmail(module);
        getManagersEmailAttachment(module);
        getManagersEmailDossier(module);
        getManagersEmailRecipient(module);
        getManagersEntreprise(module);
        getManagersEtudeStatistics(module);
        getManagersEstimation(module);
        getManagersEstimationReference(module);
        getManagersFilter(module);
        getManagersLocation(module);
        getManagersLocationLocataire(module);
        getManagersLocationLocataireEtatLieux(module);
        getManagersLocationMandat(module);
        getManagersLocationPasserelle(module);
        getManagersMedia(module);
        getManagersPerson(module);
        getManagersPhoto(module);
        getManagersQuartier(module);
        getManagersReport(module);
        getManagersReportAttachment(module);
        getManagersReportDossier(module);
        getManagersReportParticipant(module);
        getManagersSite(module);
        getManagersSort(module);
        getManagersTemplate(module);
        getManagersUtils(module);
        getManagersUser(module);
        getManagersUserPhoto(module);
        getManagersVente(module);
        getManagersVenteCompromis(module);
        getManagersVenteContrat(module);
        getManagersVentePasserelle(module);

        return module;
    })(angularJS);
}
