import {Injectable} from '@angular/core';
import {IInotUserApi} from '@models/inot-users/user/inot-user.interfaces';
import InotUser from '@models/inot-users/user/inot-user.model';

@Injectable({providedIn: 'root'})
export class InotUserFactory {

    create(inotUserApi: IInotUserApi): InotUser {
        const inotUser = this.createVirgin();

        inotUser.externalId = inotUserApi.externalId;
        inotUser.nom = inotUserApi.nom;
        inotUser.prenom = inotUserApi.prenom;

        return inotUser;
    }

    createVirgin(): InotUser {
        return new InotUser();
    }
}
