import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable, switchMap, throwError} from 'rxjs';
import {ILocationPasserelleApi} from '@models/locations/location/passerelles/passerelle/location-passerelle.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {
    ICLocationPasserellesApi
} from '@models/locations/location/passerelles/collection/location-passerelles.collection.interfaces';

@Injectable({providedIn: 'root'})
export class LocationPasserellesApiService {
    private _modelApiService = inject(ModelApiService);

    diffuser$(locationId: string, passerelleId: string): Observable<void> {
        return this._modelApiService.patch$('/locations/' + locationId + '/external/passerelles/' + passerelleId + '/diffuser');
    }

    get$(locationId: string, passerelleId: string, httpOptions?: HttpOptions): Observable<ILocationPasserelleApi> {
        return this.getByLink$('/locations/' + locationId + '/external/passerelles/' + passerelleId, httpOptions);
    }

    getByLink$(link: string, httpOptions?: HttpOptions): Observable<ILocationPasserelleApi> {
        return this._modelApiService.get$(link, httpOptions);
    }

    getCollectionByLink$(link: string): Observable<ICLocationPasserellesApi> {
        return this._modelApiService.getCollection$(link);
    }

    getCollectionFromPasserelle$(passerelleId: string): Observable<ICLocationPasserellesApi> {
        return this.getCollectionByLink$('/etude/passerelles/' + passerelleId + '/locations');
    }

    retirer$(locationId: string, passerelleId: string): Observable<void> {
        return this._modelApiService.patch$('/locations/' + locationId + '/external/passerelles/' + passerelleId + '/retirer');
    }

    save$(locationId: string, passerelleId: string, locationPasserelleApi: ILocationPasserelleApi): Observable<ILocationPasserelleApi> {
        const path = '/locations/' + locationId + '/external/passerelles/' + passerelleId;

        return this._modelApiService.put$(path, locationPasserelleApi, {headers: {handledStatusErrors: [404]}}).pipe(
            switchMap(_ => this.get$(locationId, passerelleId)),
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return this._modelApiService.post$(path, locationPasserelleApi);
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }
}
