export default `
Pour enregistrer une date de mandat pour votre
<a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/edit/general')">
  bien en
  {{($ctrl.vente.type.code === $ctrl.VIAGER ? 'viager' : ('vente' + ($ctrl.vente.type.code === $ctrl.INTERACTIVE ? ' interactive' : '')))}}
</a>, il est nécessaire de saisir :

<div class="row">
  <div class="col-sm-1"></div>

  <div class="col-sm-11">
    <div ng-hide="$ctrl.vente.bien.option.commune"> - la commune</div>

    <div ng-hide="$ctrl.vente.bien.superficie"> - la superficie</div>

    <div ng-hide="$ctrl.vente.bien.titre"> - le titre de l'annonce</div>

    <div ng-hide="$ctrl.vente.bien.descriptif"> - le descriptif de l'annonce</div>

    <div ng-hide="$ctrl.vente.bien.hasSavedCadastres()"> - au moins une référence cadastrale</div>

    <div>
      <div ng-if="$ctrl.vente.type.code === $ctrl.INTERACTIVE">
        <div ng-hide="$ctrl.vente.interactivePrixReserve"> - le prix de réserve</div>
        <div ng-hide="$ctrl.vente.interactivePremiereOffre"> - la première offre</div>
        <div ng-hide="$ctrl.vente.interactiveSystemeEncheres"> - le système d'enchères</div>
        <div ng-hide="$ctrl.vente.interactivePasOffre"> - le pas de l'offre</div>
        <div ng-hide="$ctrl.vente.isInteractiveDateFinValid()"> - la date de fin</div>
      </div>

      <div ng-if="$ctrl.vente.type.code === $ctrl.VIAGER">
        <div ng-hide="$ctrl.vente.viagerValeurBien || $ctrl.vente.viagerValeurBien === 0">
          - la valeur du bien
        </div>
        <div ng-hide="$ctrl.vente.viagerBouquet || (!$ctrl.vente.viagerBouquet && $ctrl.vente.viagerRente)">
          - le bouquet
        </div>
        <div ng-hide="$ctrl.vente.viagerRente || (!$ctrl.vente.viagerRente && $ctrl.vente.viagerBouquet)">
          - la rente
        </div>
      </div>

      <div ng-if="$ctrl.vente.type.code !== $ctrl.INTERACTIVE && $ctrl.vente.type.code !== $ctrl.VIAGER">
        <div ng-hide="$ctrl.vente.prixVente && $ctrl.vente.prixVente !== '0'">- le prix de vente</div>
      </div>
    </div>

    <div ng-repeat="fieldInvalid in $ctrl.vente._esk.fieldsInvalid">- {{fieldInvalid.message}}</div>
  </div>
</div>
`;
