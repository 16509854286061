import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import popoverTemplate from '@legacy/templates/demandeurs/components/item/popover';
import resultTemplate from '@legacy/templates/demandeurs/components/item/results';
import listDetailsTemplate from '@legacy/templates/demandeurs/components/list/details';
import resultsColorBulletTemplate from '@legacy/templates/demandeurs/components/list/results-color-bullet';
import listTemplate from '@legacy/templates/demandeurs/components/list';
import editCriteriaTemplate from '@legacy/templates/demandeurs/controllers/item/edit/criteria';
import editGeneralTemplate from '@legacy/templates/demandeurs/controllers/item/edit/general';
import resultsDetailsTemplate from '@legacy/templates/demandeurs/controllers/item/results/details';
import resultsHistoricalTemplate from '@legacy/templates/demandeurs/controllers/item/results/historical';
import lifetimeTemplate from '@legacy/templates/demandeurs/controllers/item/lifetime';
import renseignementsConfidentielsTemplate from '@legacy/templates/demandeurs/controllers/item/renseignements-confidentiels';
import filtersWalletTemplate from '@legacy/templates/demandeurs/controllers/modals/filters.wallet.current';
import groupArchiveTemplate from '@legacy/templates/demandeurs/controllers/modals/group.archive';
import itemArchiveTemplate from '@legacy/templates/demandeurs/controllers/modals/item.archive';
import walletCurrentTemplate from '@legacy/templates/demandeurs/controllers/wallet/current';
import keywordsHelpTemplate from '@legacy/templates/demandeurs/keywords.help';
import infoEditTemplate from '@legacy/templates/demandeurs/components/item/information.edit';
import infosEditEnvoiResultsHelpTemplate from '@legacy/templates/demandeurs/components/item/information.edit/envoiresults.help';

export default function getDemandeursTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/demandeurs/components/item/information.edit/envoiresults.help.html', infosEditEnvoiResultsHelpTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/components/item/information.edit.html', infoEditTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/components/item/popover.html', popoverTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/components/item/results.html', resultTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/components/list/details.html', listDetailsTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/components/list/results-color-bullet.html', resultsColorBulletTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/components/list.html', listTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/item/edit/criteria.html', editCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/item/edit/general.html', editGeneralTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/item/results/details.html', resultsDetailsTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/item/results/historical.html', resultsHistoricalTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/item/lifetime.html', lifetimeTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/item/renseignements-confidentiels.html', renseignementsConfidentielsTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/modals/filters.wallet.current.html', filtersWalletTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/modals/group.archive.html', groupArchiveTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/modals/item.archive.html', itemArchiveTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/controllers/wallet/current.html', walletCurrentTemplate);
            $templateCache.put('src/app/legacy/templates/demandeurs/keywords.help.html', keywordsHelpTemplate);
        }
    })(angularJS);
}
