export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
          title="Fermer sans modifier le contact"><fa-icon [icon]="'times'"></fa-icon></button>

  <h3 class="modal-title">
    <fa-icon [icon]="'user'"></fa-icon> {{$ctrl.titleData.mainConvivialName}}

    <h4 ng-if="!!$ctrl.titleData.representativite.has" ng-click="$ctrl.titleData.clickOnRepresentativite()">
      {{$ctrl.titleData.representativite.label | translate: $ctrl.titleData.representativite.labelParam}}

      <fa-icon [icon]="'edit'"></fa-icon>
    </h4>
  </h3>

  <div ng-if="$ctrl.contact.unsubscribed" class="text-warning">
    MyNoty : {{'dossier.contact.UNSUBSCRIBED' | translate}}
  </div>
</div>

<div ng-if="$ctrl.contact" class="modal-body">
  <esk-contact-edit contact="$ctrl.contact" on-saved="$ctrl.onSave" title-data="$ctrl.titleData"
                    on-cancel="$dismiss()" force-submit="$ctrl.forceSubmit"></esk-contact-edit>
</div>
`;
