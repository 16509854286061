import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {IUserApi} from '@models/users/user/user.interfaces';
import {ModelApiService} from '@models/model.api.service';
import {ICUsersApi, ICUsersQueryParameters} from '@models/users/collection/users.collection.interfaces';
import User from '@models/users/user/user.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UsersApiService {
    private _modelApiService = inject(ModelApiService);

    askInfosModule$(data: unknown): Observable<void> {
        return this._modelApiService.post$('/user/demande-informations-module', data).pipe(map(_ => undefined));
    }

    changePassword$(uuid: string, data: unknown): Observable<void> {
        return this._modelApiService.patch$('/users/' + uuid + '/change-password', data);
    }

    get$(uuid: string): Observable<IUserApi> {
        return this.getByLink$('/users/' + uuid);
    }

    getByLink$(link: string): Observable<IUserApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICUsersQueryParameters): Observable<ICUsersApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/users', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICUsersApi> {
        return this._modelApiService.getCollection$(link);
    }

    getCurrent$(): Observable<IUserApi> {
        return this.getByLink$('/user');
    }

    getEtudeCollection$(etudeUuid: string, queryParameters?: ICUsersQueryParameters): Observable<ICUsersApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etudes/' + etudeUuid + '/users', queryParameters));
    }

    requestPassword$(data: unknown): Observable<void> {
        return this._modelApiService.patch$('/users/request-password', data);
    }

    save$(uuid: string, userApi: IUserApi): Observable<IUserApi> {
        if (uuid === User.statuts.NEW) {
            throw new Error('Pas utilisable');
            // Return this._modelApiService.post$('/users', userApi);
        }

        return this._modelApiService.put$('/users/' + uuid, userApi).pipe(switchMap(_ => this.get$(uuid)));
    }
}
