import AModel from '@models/model.abstract';
import DateFormat from '@shared/date/date.format';
import Procedure from '@models/procedures/procedure/procedure.model';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';

export default class ProcedureSignataire extends AModel {
    static readonly statuts = {
        DRAFT: 'draft',
        FINISHED: 'finished',
        NEW: AModel.STATUT_NEW,
        REFUSED: 'refused',
        STARTED: 'started',
    };
    static readonly types = {CONTACT: 'contact', UTILISATEUR: 'utilisateur'};
    private _colorStatut!: NoteoStatutColorType;
    private _convivialName!: string;
    private _email!: string;
    private _finishedAt!: string;
    private _labelStatut!: string;
    private _linkInterfaceSignature!: string;
    private _nom!: string;
    private _positionLlx!: number;
    private _positionLly!: number;
    private _positionUrx!: number;
    private _positionUry!: number;
    private _prenom!: string;
    private _refusedAt!: string;
    private _signaturePlaceholderHeight!: number;
    private _signaturePlaceholderWidth!: number;
    private _statut!: string;
    private _telephone!: string;
    private _type!: string;
    private _typeId!: number;

    constructor(uuid: string) {
        super(uuid);
        this.statut = ProcedureSignataire.statuts.NEW;
    }

    get colorStatut(): NoteoStatutColorType {
        return this._colorStatut;
    }

    get convivialName(): string {
        return this._convivialName;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get finishedAt(): string {
        return this._finishedAt;
    }

    set finishedAt(value: string) {
        this._finishedAt = value;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get linkInterfaceSignature(): string {
        return this._linkInterfaceSignature;
    }

    set linkInterfaceSignature(value: string) {
        this._linkInterfaceSignature = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
        this.setConvivialName();
    }

    set positionLlx(value: number) {
        this._positionLlx = value;
        this.setDimensionsSignaturePlaceholder();
    }

    set positionLly(value: number) {
        this._positionLly = value;
        this.setDimensionsSignaturePlaceholder();
    }

    set positionUrx(value: number) {
        this._positionUrx = value;
        this.setDimensionsSignaturePlaceholder();
    }

    set positionUry(value: number) {
        this._positionUry = value;
        this.setDimensionsSignaturePlaceholder();
    }

    get prenom(): string {
        return this._prenom;
    }

    set prenom(value: string) {
        this._prenom = value;
        this.setConvivialName();
    }

    get refusedAt(): string {
        return this._refusedAt;
    }

    set refusedAt(value: string) {
        this._refusedAt = value;
    }

    get signaturePlaceholderHeight(): number {
        return this._signaturePlaceholderHeight;
    }

    get signaturePlaceholderWidth(): number {
        return this._signaturePlaceholderWidth;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        if (!ProcedureSignataire.statuts[value?.toUpperCase()]) {
            return;
        }

        this._statut = value;
        switch (this.statut) {
            case ProcedureSignataire.statuts.DRAFT :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'brouillon';
                break;
            case ProcedureSignataire.statuts.FINISHED :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'terminée';
                break;
            case ProcedureSignataire.statuts.NEW :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'créée';
                break;
            case ProcedureSignataire.statuts.REFUSED :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = 'refusée';
                break;
            case ProcedureSignataire.statuts.STARTED :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'démarrée';
                break;
        }
    }

    get telephone(): string {
        return this._telephone;
    }

    set telephone(value: string) {
        this._telephone = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        if (!ProcedureSignataire.types[value?.toUpperCase()]) {
            return;
        }

        this._type = value;
    }

    get typeId(): number {
        return this._typeId;
    }

    set typeId(value: number) {
        this._typeId = value;
    }

    isClosed(): boolean {
        return this.isFinished() || this.isRefused();
    }

    isDraft(): boolean {
        return ProcedureSignataire.statuts.DRAFT === this.statut;
    }

    isEditable(): boolean {
        return this.isNewOrDraft() || this.isStarted();
    }

    isFinished(): boolean {
        return ProcedureSignataire.statuts.FINISHED === this.statut;
    }

    isNew(): boolean {
        return ProcedureSignataire.statuts.NEW === this.statut;
    }

    isNewOrDraft(): boolean {
        return this.isNew() || this.isDraft();
    }

    isRefused(): boolean {
        return ProcedureSignataire.statuts.REFUSED === this.statut;
    }

    isStarted(): boolean {
        return ProcedureSignataire.statuts.STARTED === this.statut;
    }

    isUser(): boolean {
        return ProcedureSignataire.types.UTILISATEUR === this.type;
    }

    isValid(procedureProvider: string): boolean {
        if (procedureProvider === Procedure.providers.soqrate.code) {
            return !!this.nom && !!this.prenom;
        }

        return !!this.email && !!this.nom && !!this.prenom && PhoneNumberService.isValid(this.telephone);
    }

    setConvivialName(): void {
        const convivialName: string[] = [];

        if (this.prenom) {
            convivialName.push(this.prenom);
        }

        if (this.nom) {
            convivialName.push(this.nom);
        }

        this._convivialName = convivialName.join(' ');
    }

    setDimensionsSignaturePlaceholder(): void {
        const x1 = this._positionLlx || 0;
        const x2 = this._positionUrx || 0;
        const y1 = this._positionLly || 0;
        const y2 = this._positionUry || 0;
        const height = y2 - y1;
        const width = x2 - x1;

        if (height <= 0 || width <= 0) {
            this._signaturePlaceholderHeight = 1;
            this._signaturePlaceholderWidth = 1;
        } else {
            this._signaturePlaceholderHeight = height;
            this._signaturePlaceholderWidth = width;
        }
    }

    setFinished(): void {
        this.finishedAt = DateFormat.toISOString();
        this.statut = ProcedureSignataire.statuts.FINISHED;
    }

    trim(): void {
        this._nom = this._nom?.trim();
        this._prenom = this._prenom?.trim();
    }
}
