import {inject, Injectable} from '@angular/core';
import EtudeSettingsDocumentsHeader
    from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.model';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {
    EtudeSettingsDocumentsHeaderFactory
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EtudeSettingsDocumentsHeaderService {
    private _etudeService = inject(EtudeService);
    private _etudeSettingsDocumentsHeaderFactory = inject(EtudeSettingsDocumentsHeaderFactory);
    private _currentSource = new BehaviorSubject<EtudeSettingsDocumentsHeader>(undefined!);
    private _current$ = this._currentSource.asObservable();

    get current$(): Observable<EtudeSettingsDocumentsHeader> {
        return this._current$;
    }

    get$(): Observable<EtudeSettingsDocumentsHeader> {
        return this._etudeService.last$.pipe(switchMap(currentEtude => this._etudeSettingsDocumentsHeaderFactory.get$(currentEtude)));
    }

    initCurrent(): void {
        this._currentSource.next(undefined!);
        this.get$().pipe(take(1)).subscribe(etudeSettingsDocumentsHeader => this._currentSource.next(etudeSettingsDocumentsHeader));
    }

    save$(etudeSettingsDocumentsHeader: EtudeSettingsDocumentsHeader): Observable<EtudeSettingsDocumentsHeader> {
        return this._etudeService.last$.pipe(switchMap(currentEtude => this._etudeSettingsDocumentsHeaderFactory.save$(currentEtude, etudeSettingsDocumentsHeader)));
    }
}
