export default `
<!-- Première ligne -->
<div ng-if="!$ctrl.options.disabledFields.fieldsetSource || !$ctrl.options.disabledFields.fieldsetTypeTransaction
                || !$ctrl.options.disabledFields.fieldsetNatures || !$ctrl.options.disabledFields.fieldsetBudget"
     class="row">
  <!-- Sources -->
  <div ng-if="!$ctrl.options.disabledFields.fieldsetSource" class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
    <fieldset class="fieldset-sources">
      <legend>Sources des références</legend>

      <div class="radio clip-radio radio-purple padding-left-10">
        <input type="radio" id="search-sources-etude" value="etude" ng-model="$ctrl.search.option.source">

        <label for="search-sources-etude">Mon portefeuille de biens archivés</label>
      </div>

      <div ng-if="!!$ctrl.mainNetwork" class="radio clip-radio radio-purple padding-left-10">
        <input type="radio" id="search-sources-network" value="{{$ctrl.DossierBiensSourcesConst.NETWORK}}"
               ng-model="$ctrl.search.option.source">

        <label for="search-sources-network">
          Biens archivés du {{$ctrl.mainNetwork.name | translate}}
        </label>
      </div>

      <div ng-if="!$ctrl.options.disabledFields.sourceReferences" class="radio clip-radio radio-purple padding-left-10">
        <input type="radio" id="search-sources-references" value="reference"
               ng-model="$ctrl.search.option.source">

        <label for="search-sources-references">Mes références enregistrées</label>
      </div>

      <div ng-if="!$ctrl.options.disabledFields.sourceCityscan" class="radio clip-radio radio-purple padding-left-10">
        <input type="radio" id="search-sources-cityscan" value="{{$ctrl.CITYSCAN_CODE}}"
               ng-model="$ctrl.search.option.source" ng-change="$ctrl.sourceSelectionCityscan()">

        <label for="search-sources-cityscan">
          Références <app-noteo-icon-text-cityscan></app-noteo-icon-text-cityscan>
        </label>
      </div>

      <div ng-if="!!$ctrl.mainNetwork && !$ctrl.options.disabledFields.sourceToutes"
           class="radio clip-radio radio-purple padding-left-10">
        <input type="radio" id="search-sources-all" value="all" ng-model="$ctrl.search.option.source">

        <label for="search-sources-all">Toutes les sources</label>
      </div>
    </fieldset>
  </div>

  <!-- Type de transaction -->
  <!-- @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/437 : A supprimer ng-if -->
  <!-- <div ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.search.option.source !== $ctrl.CITYSCAN_CODE" class="col-sm-6" -->
  <div ng-if="$ctrl.search.option.source !== 'reference' && $ctrl.search.option.source !== $ctrl.CITYSCAN_CODE && !!$ctrl.search._esk && !$ctrl.options.disabledFields.fieldsetTypeTransaction"
       class="col-xs-12 col-sm-6 col-md-3">
    <fieldset class="tw-text-center fieldset-transaction margin-bottom-0">
      <legend>Type de transaction</legend>

      <div class="radio clip-radio radio-purple radio-inline">
        <input type="radio" id="search-type-vente-viager" name="search-type" value="{{$ctrl.DossierTypesConst.VENTE}}"
               ng-model="$ctrl.typeSearch" ng-change="$ctrl.onChangeType()">

        <label for="search-type-vente-viager">Achat</label>
      </div>

      <div class="radio clip-radio radio-purple radio-inline">
        <input type="radio" id="search-type-location" name="search-type" value="{{$ctrl.DossierTypesConst.LOCATION}}"
               ng-model="$ctrl.typeSearch" ng-change="$ctrl.onChangeType()">

        <label for="search-type-location">Location</label>
      </div>
    </fieldset>
  </div>

  <!-- Natures -->
  <div ng-if="!$ctrl.options.disabledFields.fieldsetNatures" class="col-xs-12 col-sm-6 col-md-3">
    <fieldset class="fieldset-natures">
      <legend>Natures</legend>

      <esk-select-dictionary ng-model="$ctrl.search.natures" name="natures" dictionary="{{$ctrl.NATURES}}" is-multiple>
      </esk-select-dictionary>

      <div ng-if="$ctrl.search.option.source === $ctrl.CITYSCAN_CODE" class="tw-mt-4">
        Seules les natures "{{$ctrl.naturesMaison.label}}" et "{{$ctrl.naturesAppartement.label}}" seront prises en
        compte par
        <span class="tw-whitespace-nowrap"><app-noteo-icon-text-cityscan></app-noteo-icon-text-cityscan></span> dans
        la recherche.
      </div>
    </fieldset>
  </div>

  <!-- Budget -->
  <div ng-if="!$ctrl.options.disabledFields.fieldsetBudget" class="col-xs-12 col-sm-6">
    <fieldset class="fieldset-budget">
      <legend>Budget</legend>

      <!-- Vente -->
      <div ng-if="$ctrl.typeSearch === $ctrl.DossierTypesConst.VENTE">
        <div class="input-group input-group-number input-group-currency pull-left margin-bottom-20">
          <input type="text" class="form-control" ng-model="$ctrl.search.vente.budgetMin" soq-only-digits
                 placeholder="Minimum">

          <span class="input-group-addon">€</span>
        </div>

        <div class="input-group input-group-number input-group-currency pull-left margin-bottom-20">
          <input type="text" class="form-control" ng-model="$ctrl.search.vente.budgetMax" soq-only-digits
                 placeholder="Maximum">

          <span class="input-group-addon">€</span>
        </div>

        <div ng-if="$ctrl.search.option.source === $ctrl.CITYSCAN_CODE"
             class="pull-left tw-max-w-[20rem] margin-left-20">
          La fourchette de budget correspond à la fourchette sur le prix de vente d'un bien en vente
        </div>

        <div ng-if="$ctrl.search.option.source !== $ctrl.CITYSCAN_CODE && (!$ctrl.search.vente.excludeVenteTraditionnelle || !$ctrl.search.vente.excludeVenteViager || !$ctrl.search.vente.excludeVenteInteractive)"
             class="pull-left margin-left-20">
          La fourchette de budget correspond à

          <ul class="margin-left-20 margin-bottom-0">
            <li ng-if="!$ctrl.search.vente.excludeVenteTraditionnelle">
              - la fourchette sur le prix de vente pour un bien en vente traditionnelle
            </li>

            <li ng-if="!$ctrl.search.vente.excludeVenteViager">
              - la fourchette sur le bouquet pour un bien en vente viagère
            </li>

            <li ng-if="!$ctrl.search.vente.excludeVenteInteractive">
              - la fourchette sur la première offre pour un bien en vente interactive
            </li>
          </ul>
        </div>

        <div
          ng-if="$ctrl.search.vente.excludeVenteTraditionnelle && $ctrl.search.vente.excludeVenteViager && $ctrl.search.vente.excludeVenteInteractive"
          class="pull-left margin-left-20">
          Tous les types de vente sont exclus, votre recherche ne retournera aucun résultat.
        </div>
      </div>

      <!-- Location -->
      <div ng-if="$ctrl.typeSearch === $ctrl.DossierTypesConst.LOCATION">
        <div class="input-group input-group-number input-group-currency pull-left margin-bottom-20">
          <input type="text" class="form-control" ng-model="$ctrl.search.location.loyerMin" soq-only-digits
                 placeholder="Minimum">

          <span class="input-group-addon">€</span>
        </div>

        <div class="input-group input-group-number input-group-currency pull-left margin-bottom-20">
          <input type="text" class="form-control" ng-model="$ctrl.search.location.loyerMax" soq-only-digits
                 placeholder="Maximum">

          <span class="input-group-addon">€</span>
        </div>

        <div class="pull-left margin-left-20">
          La fourchette de budget correspond à la fourchette sur le loyer pour un bien en location.
        </div>
      </div>
    </fieldset>
  </div>
</div>

<!-- Cession -->
<fieldset ng-if="!$ctrl.options.disabledFields.fieldsetCession" class="fieldset-archivage">
  <legend>Cession</legend>

  <div class="row">
    <!-- Prix cession -->
    <div class="col-xs-12 col-sm-6 col-lg-4 form-inline">
      <p>Prix de cession</p>

      <div class="form-group input-group input-group-number input-group-currency">
        <input type="text" class="form-control" ng-model="$ctrl.search.archive.prixCessionMin" placeholder="Minimum"
               soq-only-digits>

        <span class="input-group-addon">€</span>
      </div>

      <div class="form-group input-group input-group-number input-group-currency">
        <input type="text" class="form-control" ng-model="$ctrl.search.archive.prixCessionMax" placeholder="Maximum"
               soq-only-digits>

        <span class="input-group-addon">€</span>
      </div>
    </div>

    <!-- Date de cession -->
    <div ng-if="$ctrl.search.option.source !== $ctrl.CITYSCAN_CODE" class="col-xs-12 col-sm-6 col-lg-4">
      <p>Date de vente</p>

      <div class="form-group input-group input-group-date pull-left">
        <esk-select-date model="$ctrl.search.archive.dateVenteMin" options="{placeholder: 'À partir de'}">
        </esk-select-date>
      </div>

      <div class="form-group input-group input-group-date pull-left">
        <esk-select-date model="$ctrl.search.archive.dateVenteMax" options="{placeholder: 'Jusqu\\'au'}">
        </esk-select-date>
      </div>
    </div>

    <!-- Année de cession -->
    <div ng-if="$ctrl.search.option.source === $ctrl.CITYSCAN_CODE" class="col-xs-12 col-sm-6 col-lg-4">
      <p>Année de vente</p>

      <div class="form-group input-group input-group-number pull-left">
        <input type="number" step="1" class="form-control" ng-model="$ctrl.anneeCessionMin" placeholder="Minimum">
      </div>

      <div class="form-group input-group input-group-number pull-left">
        <input type="number" step="1" class="form-control" ng-model="$ctrl.anneeCessionMax" placeholder="Maximum">
      </div>
    </div>

    <!-- Type d'archivage -->
    <div ng-if="$ctrl.search.option.source !== $ctrl.CITYSCAN_CODE && $ctrl.search.option.source !== 'reference'" class="col-xs-12 col-sm-6 col-lg-4">
      <p>Type d'archivage</p>

      <app-dictionary-checkbox [model]="$ctrl.typesReferencesArchivees" [name]="$ctrl.REFERENCES_ARCHIVEES_TYPES"
                               (selected)="$ctrl.onSelectTypesReferencesArchivees($event)"></app-dictionary-checkbox>
    </div>
  </div>
</fieldset>

<!-- Construction -->
<fieldset ng-if="!$ctrl.options.disabledFields.fieldsetConstruction && ($ctrl.search.option.source !== $ctrl.CITYSCAN_CODE || ($ctrl.search.option.source === $ctrl.CITYSCAN_CODE && !$ctrl.options.disabledFields.fieldsetConstructionCityscan))"
          class="fieldset-construction">
  <legend>Construction</legend>

  <div class="row">
    <div class="col-md-6 col-lg-8"
         ng-if="(!$ctrl.options.disabledFields.nombreChambres && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'nombreChambres')) || $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'nombrePieces')">
      <div class="row">
        <!-- Chambres -->
        <div ng-if="!$ctrl.options.disabledFields.nombreChambres && $ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'nombreChambres')"
             class="col-lg-6">
          <label>Nombre de chambres entre</label>

          <app-form-range-slider [range]="{max: $ctrl.search.option.nombreChambresMax, min: $ctrl.search.option.nombreChambresMin}"
                                 [options]="{bornesNull: true, ceil: 10, floor: 0, showTicks: true}"
                                 (range-updated)="$ctrl.onSelectChambresRange($event)"></app-form-range-slider>
        </div>

        <!-- Pièces -->
        <div class="col-lg-6" ng-if="$ctrl.demandeursUtils.showFieldForSearch($ctrl.search, 'nombrePieces')">
          <label>Nombre de pièces entre</label>

          <app-form-range-slider [range]="{max: $ctrl.search.option.nombrePiecesMax, min: $ctrl.search.option.nombrePiecesMin}"
                                 [options]="{bornesNull: true, ceil: 10, floor: 0, showTicks: true}"
                                 (range-updated)="$ctrl.onSelectPiecesRange($event)"></app-form-range-slider>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-4 superficie">
      <div class="row">
        <!-- Superficie -->
        <div class="col-sm-6 col-md-12 col-lg-6">
          <label>{{$ctrl.getLabelSuperficie() + " minimun" | translate}}</label>

          <div class="input-group input-group-number input-group-area">
            <input id="search-option-superficie-min" type="text"
                   class="form-control" ng-model="$ctrl.search.superficieMin"
                   placeholder="{{'Minimum'|translate}}" soq-only-digits>

            <span class="input-group-addon">m<sup>2</sup></span>
          </div>
        </div>

        <!-- Surface cadstrale -->
        <div ng-if="!$ctrl.options.disabledFields.surfaceCadastraleMin" class="col-sm-6 col-md-12 col-lg-6">
          <label>{{"Surface cadastrale minimum" | translate}}</label>
          <div class="input-group input-group-number input-group-area">
            <input id="search-option-surface-cadastrale-min" type="text"
                   class="form-control" ng-model="$ctrl.search.option.surfaceCadastraleMin"
                   placeholder="{{'Minimum'|translate}}" soq-only-digits>

            <span class="input-group-addon">m<sup>2</sup></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>

<!-- Localisations -->
<fieldset ng-if="!$ctrl.options.disabledFields.fieldsetLocalisation && ($ctrl.search.option.source !== $ctrl.CITYSCAN_CODE || ($ctrl.search.option.source === $ctrl.CITYSCAN_CODE && !$ctrl.options.disabledFields.fieldsetLocalisationCityscan))"
          class="fieldset-localisation">
  <legend>
    Localisation

    <span class="badge text" ng-if="$ctrl.localisations.collection.length > 0">
      {{$ctrl.localisations.collection.length}}
    </span>
  </legend>

  <uib-tabset type="pills" active="$ctrl.localisations.mode" class="tabbable"
              ng-class="$ctrl.options.disabledFields.searchByAdresse ? 'no-menu' : ''">
    <!-- Critères -->
    <uib-tab heading="Région / Département / Commune / Quartier" index="'localites'"
             ng-click="$ctrl.localisations.onChangeMode('localites')">
      <!-- Liste -->
      <div ng-show="$ctrl.localisations.collection.length > 0" class="selected-localisations">
        <div class="list-localisations" ng-class="{'all-localisations': $ctrl.allLocalisations}">
          <div ng-repeat="localisation in $ctrl.localisations.collection" class="item-localisation">
            <span title="{{localisation.title}}" class="badge text">
              <span class="badge-name" ng-class="{'is-clickable': !!localisation.commune}"
                    ng-click="$ctrl.localisations.onClick(localisation)">
                <span ng-if="localisation.data.hasQuartiers" title="Afficher les quartiers">
                  <fa-icon [icon]="['far', 'map']"></fa-icon>
                </span>

                {{localisation.convivialName}}
              </span>

              <button type="button" title="Supprimer de la sélection" class="btn"
                      ng-click="$ctrl.localisations.remove(localisation)"><fa-icon [icon]="'times'"></fa-icon></button>
            </span>
          </div>
        </div>

        <div class="action-localisations">
          <button type="button" ng-switch="$ctrl.allLocalisations" class="badge more-localisations"
                  ng-click="$ctrl.allLocalisations = !$ctrl.allLocalisations">
            <span ng-switch-default>...</span>

            <span ng-switch-when="true"><fa-icon [icon]="'chevron-up'"></fa-icon></span>
          </button>

          <button type="button" class="badge" title="Supprimer toutes les localisations sélectionnées"
                  ng-click="$ctrl.localisations.removeAll()"><fa-icon [icon]="'times'"></fa-icon></button>
        </div>
      </div>

      <!-- Recherche par mots-clés -->
      <esk-select-localisation ng-model="$ctrl.localisations.search.keywordsLocalisation"
                               class="tw-block margin-top-15 margin-bottom-20"
                               on-select="$ctrl.localisations.search.add"></esk-select-localisation>

      <!-- Quartiers & Cartes -->
      <div class="tw-gap-4">
        <h4 ng-if="$ctrl.localisations.quartiersSelection.commune.hasQuartiers" class="tw-font-bold tw-text-blue-500">
          Quartiers - {{$ctrl.localisations.quartiersSelection.commune.fullname}}

          <a popover-trigger="'outsideClick'" popover-placement="right" type="button" class="btn btn-o btn-null"
             uib-popover="En sélectionnant un quartier ou tous les quartiers de la commune, vous trouverez seulement des biens dont le quartier est précisé.">
            <span class="badge badge-info">?</span>
          </a>
        </h4>

        <div class="tw-grid tw-grid-cols-6 tw-gap-4">
          <!-- Liste des quartiers -->
          <div ng-if="$ctrl.localisations.quartiersSelection.commune.hasQuartiers" class="tw-col-span-2 tw-h-[28rem]">
            <app-quartiers-selection [commune]="$ctrl.localisations.quartiersSelection.ngCommune"
                                     [model]="$ctrl.localisations.quartiersSelection.selectedNgQuartiers"
                                     class="tw-block tw-h-full tw-overflow-auto tw-border tw-p-4"
                                     (selected)="$ctrl.localisations.quartiersSelection.selectQuartiers($event)">
            </app-quartiers-selection>
          </div>

          <!-- Cartes -->
          <div class="tw-col-span-4 tw-h-[28rem]"
               ng-class="{'tw-col-start-2' : !$ctrl.localisations.quartiersSelection.commune.hasQuartiers}">
            <!-- @todo À la refonte de la carte en Angular, saisir explicitement "height" dynamiquement avant le chargement du SVG afin de retirer la hauteur saisie en dur -->
            <div ng-if="$ctrl.localisations.quartiersSelection.commune.hasCarteQuartiers" class="tw-h-[28rem]">
              <esk-carte-quartiers commune="$ctrl.localisations.carteQuartiers.commune"
                                   codes-selected="$ctrl.localisations.carteQuartiers.codesSelected"
                                   on-select="$ctrl.localisations.carteQuartiers.onSelect"></esk-carte-quartiers>
            </div>

            <soq-gmaps-free-draw ng-if="!$ctrl.localisations.quartiersSelection.commune.hasCarteQuartiers"
                                 center="$ctrl.localisations.carteGmap.center" zoom="$ctrl.localisations.carteGmap.zoom"
                                 get-polygons="$ctrl.localisations.carteGmap.getPolygons"
                                 on-get-polygons="$ctrl.localisations.carteGmap.onGetPolygons"
                                 options="$ctrl.localisations.carteGmap.options"></soq-gmaps-free-draw>
          </div>
        </div>
      </div>
    </uib-tab>

    <!-- Adresse précise -->
    <uib-tab heading="Adresse précise" index="'adresse'" ng-click="$ctrl.localisations.onChangeMode('adresse')">
      <div class="row">
        <div class="col-sm-6">
          <label><fa-icon [icon]="['far', 'map']"></fa-icon> Commune</label>

          <esk-select-commune on-select="$ctrl.localisations.searchByAdresse.onSelectCommune"
                              ng-model="$ctrl.localisations.searchByAdresse.commune"></esk-select-commune>
        </div>

        <div ng-if="!!$ctrl.localisations.searchByAdresse.commune.id" class="col-sm-6">
          <label for="search-adresse">Adresse</label>

          <span ng-switch="$ctrl.localisations.searchByAdresse.commune.hasVoies">
            <esk-select-voie ng-switch-when="true" commune="$ctrl.localisations.searchByAdresse.commune"
                             ng-model="$ctrl.localisations.searchByAdresse.voie"></esk-select-voie>

            <input ng-switch-when="false" type="text" id="search-adresse" class="form-control"
                   ng-model="$ctrl.localisations.searchByAdresse.nomVoie">
          </span>
        </div>
      </div>
    </uib-tab>
  </uib-tabset>
</fieldset>

<div ng-if="$ctrl.search.option.source === $ctrl.CITYSCAN_CODE">
  <p class="tw-m-8 tw-mb-16 md:tw-mx-0 md:tw-mt-10 tw-text-sm">
    <span class="tw-whitespace-nowrap"><app-noteo-icon-text-cityscan></app-noteo-icon-text-cityscan></span>
    utilise la commune, le numéro ainsi que l'adresse de votre bien pour localiser les références de biens.
  </p>
</div>
`;
