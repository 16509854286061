import {inject, Injectable} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import {IVenteArchiveInfosApi} from '@models/ventes/vente/archive-infos/vente-archive-infos.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DateFormat from '@shared/date/date.format';

@Injectable({providedIn: 'root'})
export class VenteArchiveInfosFactory {
    private _dictionaryItemService = inject(DictionaryItemService);

    create(vente: Vente): VenteArchiveInfos {
        if (vente.isArchived() || vente.archiveType) {
            return this.createFromVenteArchive(vente);
        } else if (vente.enabledCompromis) {
            return this.createFromCompromis(vente.enabledCompromis);
        }

        return this.createFromVente(vente);
    }

    createFromCompromis(venteCompromis: VenteCompromis): VenteArchiveInfos {
        const venteArchiveInfos: VenteArchiveInfos = new VenteArchiveInfos();

        venteArchiveInfos.acquereur = venteCompromis.acquereur;
        venteArchiveInfos.comments = venteCompromis.comments;
        venteArchiveInfos.dateVente = venteCompromis.dateVente;
        venteArchiveInfos.demandeurId = venteCompromis.demandeurId;
        venteArchiveInfos.etudeAcquereurId = venteCompromis.etudeAcquereurId;
        venteArchiveInfos.honorairesNego = venteCompromis.honorairesNego;
        venteArchiveInfos.honorairesNegoPercus = venteCompromis.honorairesNegoPercus;
        venteArchiveInfos.linkDemandeur = venteCompromis.linkDemandeur;
        venteArchiveInfos.linkEtudeAcquereur = venteCompromis.linkEtudeAcquereur;
        venteArchiveInfos.prixCession = venteCompromis.prixCession;
        venteArchiveInfos.raisonVenteNegociee = venteCompromis.raisonNegocie;
        venteArchiveInfos.raisonVenteNonNegociee = venteCompromis.raisonNonNegocie;
        venteArchiveInfos.type = this._getTypeArchiveVente(venteCompromis.negocie);

        return venteArchiveInfos;
    }

    createFromVente(vente: Vente): VenteArchiveInfos {
        const venteArchiveInfos: VenteArchiveInfos = new VenteArchiveInfos();

        venteArchiveInfos.dateVente = DateFormat.toJSON(DateFormat.dateFromNow());
        venteArchiveInfos.honorairesNego = vente.honorairesNego;
        venteArchiveInfos.honorairesNegoPercus = vente.honorairesNego;
        venteArchiveInfos.prixCession = vente.prixVente;
        venteArchiveInfos.raisonVenteNegociee = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, Vente.archiveNegocieeRaisons.PORTEFEUILLE);
        venteArchiveInfos.raisonVenteNonNegociee = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, Vente.archiveNonNegocieeRaisons.AGENCE);
        venteArchiveInfos.type = this._getTypeArchiveVente(true);

        return venteArchiveInfos;
    }

    createFromVenteArchive(vente: Vente): VenteArchiveInfos {
        const venteArchiveInfos: VenteArchiveInfos = new VenteArchiveInfos();

        venteArchiveInfos.acquereur = vente.archiveAcquereur;
        venteArchiveInfos.comments = vente.archiveComments;
        venteArchiveInfos.dateVente = vente.archiveDateVente;
        venteArchiveInfos.demandeurId = vente.archiveDemandeurId;
        venteArchiveInfos.etudeAcquereurId = vente.archiveEtudeAcquereurId;
        venteArchiveInfos.honorairesNego = vente.archiveHonorairesNego;
        venteArchiveInfos.honorairesNegoPercus = vente.archiveHonorairesNegoPercus;
        venteArchiveInfos.linkDemandeur = vente.linkArchiveDemandeur;
        venteArchiveInfos.linkEtudeAcquereur = vente.linkArchiveEtudeAcquereur;
        venteArchiveInfos.prixCession = vente.archivePrixCession;
        venteArchiveInfos.raisonVenteNegociee = vente.archiveRaisonVenteNegociee;
        venteArchiveInfos.raisonVenteNonNegociee = vente.archiveRaisonVenteNonNegociee;
        venteArchiveInfos.type = vente.archiveType;

        return venteArchiveInfos;
    }

    forApi(venteArchiveInfos: VenteArchiveInfos): IVenteArchiveInfosApi {
        const venteArchiveInfosApi: IVenteArchiveInfosApi = {
            archiveComments: venteArchiveInfos.comments,
            archiveDateVente: venteArchiveInfos.dateVente,
            archivePrixCession: venteArchiveInfos.prixCession,
        } as IVenteArchiveInfosApi;

        if (venteArchiveInfos.type) {
            venteArchiveInfosApi.archiveType = venteArchiveInfos.type.code;

            if (venteArchiveInfosApi.archiveType === Vente.archiveTypes.NEGOCIEE) {
                if (venteArchiveInfos.raisonVenteNegociee) {
                    venteArchiveInfosApi.archiveRaisonVenteNegociee = venteArchiveInfos.raisonVenteNegociee.code;
                    venteArchiveInfosApi.archiveHonorairesNego = venteArchiveInfos.honorairesNego;

                    if (venteArchiveInfosApi.archiveRaisonVenteNegociee === Vente.archiveNegocieeRaisons.PORTEFEUILLE) {
                        if (venteArchiveInfos.demandeurId > 0) {
                            venteArchiveInfosApi.archiveDemandeurId = venteArchiveInfos.demandeurId;
                        } else {
                            venteArchiveInfosApi.archiveAcquereur = venteArchiveInfos.acquereur;
                        }
                    } else if (venteArchiveInfosApi.archiveRaisonVenteNegociee === Vente.archiveNegocieeRaisons.RESEAU) {
                        venteArchiveInfosApi.archiveHonorairesNegoPercus = venteArchiveInfos.honorairesNegoPercus;
                        venteArchiveInfosApi.archiveAcquereur = venteArchiveInfos.acquereur;
                        venteArchiveInfosApi.archiveEtudeAcquereurId = venteArchiveInfos.etudeAcquereurId;
                    } else if (venteArchiveInfosApi.archiveRaisonVenteNegociee === Vente.archiveNegocieeRaisons.AUTRES) {
                        venteArchiveInfosApi.archiveAcquereur = venteArchiveInfos.acquereur;
                    }
                }
            } else if (venteArchiveInfosApi.archiveType === Vente.archiveTypes.NON_NEGOCIEE) {
                if (venteArchiveInfos.raisonVenteNonNegociee) {
                    venteArchiveInfosApi.archiveRaisonVenteNonNegociee = venteArchiveInfos.raisonVenteNonNegociee.code;
                }
            } else if (venteArchiveInfosApi.archiveType === Vente.archiveTypes.RETIREE) {
                venteArchiveInfosApi.archivePrixCession = undefined!;
                venteArchiveInfosApi.archiveDateVente = undefined!;
            }
        }

        return venteArchiveInfosApi;
    }

    private _getTypeArchiveVente(negociee: boolean): DictionaryItem {
        const dictionaryCode = negociee ? Vente.archiveTypes.NEGOCIEE : Vente.archiveTypes.NON_NEGOCIEE;

        return this._dictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, dictionaryCode);
    }
}
