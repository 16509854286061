import angularJS from '@shared/angularJS/global.ng';
import {IAttributes, IController, IDirectiveFactory, IModule, Injectable, IScope} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getSoqOnlyDigits(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-only-digits
         */
        module.directive('soqOnlyDigits', directive as Injectable<IDirectiveFactory>);

        /**
         * Parse and format number
         *
         * @returns {{restrict: string, require: string, link: Function}}
         */
        directive.$inject = [] as string[];
        function directive() {
            return {restrict: 'A', require: 'ngModel', link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             * @param $ctrl
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes, $ctrl: IController) {
                const params = $scope.$eval($attrs.soqOnlyDigits);
                const elementInput = $element[0] as HTMLInputElement;
                const accepted0 = angular.isObject(params) && params.accepted0 === true;
                const float = angular.isObject(params) && params.float === true;
                const multiple = (angular.isObject(params) && typeof params.multiple === 'number') ? +params.multiple : 0;
                const negative = angular.isObject(params) && params.negative === true;
                const nonAccepted0 = angular.isObject(params) && params.nonAccepted0 === true;
                const returnString = angular.isObject(params) && params.returnString === true;

                if (!$ctrl) {
                    return;
                }

                $ctrl.$parsers.push(function (inputValue: string) {
                    let cleanDigits = inputValue.toString().replace(' ', '');
                    let negativePos: number = null!;
                    let returnDigits;
                    let cursorPos = elementInput.selectionStart;

                    if (float) {
                        const commaPos = [] as number[];
                        const editedDigits = cleanDigits.replace('.', ',').split('');

                        for (let i = 0; i < editedDigits.length; i++) {
                            if (editedDigits[i] === ',') {
                                commaPos.push(i);
                            }
                        }

                        // Remove comma if more than one
                        if (commaPos.length > 1) {
                            for (let i = 1; i < commaPos.length; i++) {
                                delete editedDigits[commaPos[i]];
                            }
                        }

                        cleanDigits = editedDigits.join('');
                    }

                    if (negative) {
                        const editedDigits = cleanDigits.split('');

                        for (let i = 0; i < editedDigits.length; i++) {
                            if (editedDigits[i] === '-' && i !== 0) {
                                delete editedDigits[i];
                            }

                            if (editedDigits[0] === '-') {
                                negativePos = 0;
                            }
                        }

                        cleanDigits = editedDigits.join('');
                    }

                    returnDigits = cleanDigits.split('').filter(function (digit: string) {
                        if (float && negative) {
                            return (!isNaN(digit as unknown as number) || digit === ',' || (digit === '-' && negativePos === 0));
                        } else if (float && !negative) {
                            return (!isNaN(digit as unknown as number) || digit === ',');
                        } else if (!float && negative) {
                            return (!isNaN(digit as unknown as number) || (digit === '-' && negativePos === 0));
                        }

                        return !isNaN(digit as unknown as number);
                    }).join('');

                    if (nonAccepted0 && returnDigits === '0') {
                        returnDigits = undefined!;
                    }

                    if (!accepted0 && /^0,/g.exec(returnDigits) === null && /^0+/g.exec(returnDigits) !== null) {
                        returnDigits = returnDigits.split('');
                        delete returnDigits[0];
                        returnDigits = returnDigits.join('');
                    }

                    if (returnDigits !== inputValue) {
                        $ctrl.$setViewValue(returnDigits);
                        $ctrl.$render();
                    }

                    elementInput.setSelectionRange(cursorPos, cursorPos);

                    return returnDigits;
                });

                $ctrl.$parsers.push(function (inputValue: unknown) {
                    if (typeof inputValue === 'string' && !returnString) {
                        // On n'utilise pas "+()" car ça ne réagit pas pareil que "parseFloat()" pour une chaine vide
                        inputValue = parseFloat(inputValue.replace(',', '.'));
                    }

                    return inputValue;
                });

                $ctrl.$parsers.push(function (inputValue: number) {
                    if (isNaN(inputValue) && returnString !== true) {
                        return null;
                    }

                    return inputValue;
                });

                $ctrl.$formatters.push(function (inputValue: unknown) {
                    if (typeof inputValue === 'number') {
                        return inputValue.toString().replace('.', ',');
                    }

                    return inputValue;
                });

                if (multiple > 0) {
                    $ctrl.$validators.pattern = (inputValue: unknown) => (Number(inputValue) || 0) % multiple === 0;
                }
            }
        }
    })(angularJS);
}
