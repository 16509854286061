import AModel from '@models/model.abstract';

export default class Actualite extends AModel {
    private _date!: string;
    private _linkContenu!: string;
    private _linkThumbnail!: string;
    private _read!: boolean;
    private _titre!: string;

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get linkContenu(): string {
        return this._linkContenu;
    }

    set linkContenu(value: string) {
        this._linkContenu = value;
    }

    get linkThumbnail(): string {
        return this._linkThumbnail;
    }

    set linkThumbnail(value: string) {
        this._linkThumbnail = value;
    }

    get read(): boolean {
        return this._read;
    }

    set read(value: boolean) {
        this._read = value;
    }

    get titre(): string {
        return this._titre;
    }

    set titre(value: string) {
        this._titre = value;
    }
}
