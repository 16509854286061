import {Injectable} from '@angular/core';
import {IFBService, FBServiceLoginCallBack, IFBServiceLoginOptions} from '@shared/facebook/facebook.interfaces';
import environment from '@env/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FacebookService {
    private _FBService!: IFBService;
    private _initInProgress = false;
    private _sdkLoadedSource = new ReplaySubject<boolean>(1);
    private _sdkLoaded$ = this._sdkLoadedSource.asObservable();

    get initInProgress(): boolean {
        return this._initInProgress;
    }

    set initInProgress(value: boolean) {
        this._initInProgress = value;
    }

    get sdkLoaded$(): Observable<boolean> {
        return this._sdkLoaded$;
    }

    initFBService(fbService: IFBService): void {
        this._FBService = fbService;
        this._FBService.init({
            appId: environment.facebook.app.id,
            autoLogAppEvents: true,
            status: true,
            version: 'v12.0',
            xfbml: false,
        });
        this._sdkLoadedSource.next(true);
    }

    login(loginCallback: FBServiceLoginCallBack, options: IFBServiceLoginOptions): void {
        this._sdkLoaded$.pipe(take(1)).subscribe(sdkLoaded => {
            if (sdkLoaded) {
                this._FBService.login(loginCallback, options);
            }
        });
    }

    razFBService(): void {
        this._sdkLoadedSource.next(false);
        this._initInProgress = false;
        this._FBService = undefined!;
    }
}
