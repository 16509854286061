export default `
<ng-switch on="$ctrl.options.loader.updating" class="list">
  <ng-switch on="$ctrl.search._esk.disinterestedDossiersCorrespondants.totalItems > 0" ng-switch-default>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button type="button" class="btn btn-azure btn-o" ng-click="$ctrl.backToResults()">
          <span class="text-large"><fa-icon [icon]="'reply-all'" flip="vertical"></fa-icon></span>

          Revenir aux biens correspondants
        </button>
      </div>
    </div>

    <div ng-switch-when="true">
      <ng-switch on="$ctrl.search.type">
        <esk-ventes-list ng-if="$ctrl.search.type === $ctrl.DossierBienTypesConst.VENTE" options="$ctrl.options"
                         ventes="$ctrl.search._esk.disinterestedDossiersCorrespondants"></esk-ventes-list>

        <esk-locations-list ng-if="$ctrl.search.type === $ctrl.DossierBienTypesConst.LOCATION" options="$ctrl.options"
                            locations="$ctrl.search._esk.disinterestedDossiersCorrespondants"></esk-locations-list>
      </ng-switch>

      <div class="row">
        <div class="col-sm-4">
          <button type="button" class="btn btn-green" ng-click="$ctrl.setInterest()">
            Remettre dans les biens correspondants
          </button>
        </div>

        <div class="col-sm-4 tw-text-center">
          <esk-pagination paginator="$ctrl.search._esk.disinterestedDossiersCorrespondants"
                          loader="$ctrl.options.loader" on-change="$ctrl.onChange"
                          ng-hide="$ctrl.options.loader.updating"></esk-pagination>
        </div>
      </div>
    </div>

    <h4 ng-switch-default class="tw-text-center text-warning">Aucun bien inintéressant à afficher.</h4>
  </ng-switch>

  <p class="tw-text-center text-extra-large" ng-switch-when="true">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des résultats ...
  </p>
</ng-switch>
`;
