import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {NgVente} from '@legacy/app/managers/ressources';
import {IVentePriceM2Options} from '@features/ventes/ventes.interfaces';
import {TextsModule} from '@shared/texts/texts.module';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoadModule} from '@shared/load/load.module';
import {AsyncPipe} from '@angular/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AsyncPipe, LoadModule, TextsModule],
    selector: 'app-vente-price-m2',
    standalone: true,
    templateUrl: 'vente-price-m2.component.html',
})
export class AppVentePriceM2Component implements OnInit {
    static readonly initVentePriceM2Options: IVentePriceM2Options = {useArchivePrixCession: false};
    private _venteFactory = inject(VenteFactory);
    private _options = {...AppVentePriceM2Component.initVentePriceM2Options};
    private _venteSource = new ReplaySubject<Vente>(1);
    private _vente$ = this._venteSource.asObservable();
    private _ventePriceM2$!: Observable<number>;

    @Input()
    set ngVente(value: NgVente) {
        this._venteSource.next(this._venteFactory.ngCreate(value));
    }

    get options(): IVentePriceM2Options {
        return this._options;
    }

    @Input()
    set options(value: IVentePriceM2Options) {
        this._options = {...AppVentePriceM2Component.initVentePriceM2Options, ...value};
    }

    @Input()
    set vente(value: Vente) {
        this._venteSource.next(value);
    }

    get ventePriceM2$(): Observable<number> {
        return this._ventePriceM2$;
    }

    ngOnInit(): void {
        this._ventePriceM2$ = this._vente$.pipe(map(vente => this._venteFactory.getPriceM2(vente, this._options.useArchivePrixCession)));
    }
}
