import {Component, Input} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';

@Component({selector: 'app-noteo-icon-text-cityscan', templateUrl: 'noteo-icon-text.cityscan.component.html'})
export class AppNoteoIconTextCityscanComponent {
    private _label = Etude.enablableModules.CITYSCAN.label;
    private _srcImage = Etude.enablableModules.CITYSCAN.srcImage;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
