import {Component, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

@Component({selector: 'app-dossier-bien-header', templateUrl: 'dossier-bien.header.component.html'})
export class AppDossierBienHeaderComponent {
    private _dossierBien!: ADossierBien;
    private _localisation!: string;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input()
    set dossierBien(value: ADossierBien) {
        const localisation: string[] = [];

        this._dossierBien = value;
        if (this.dossierBien.bien.commune) {
            localisation.push(this.dossierBien.bien.commune.fullname);
        }

        if (this.dossierBien.bien.quartier) {
            localisation.push(this.dossierBien.bien.quartier.nom);
        }

        if (this.dossierBien.bien.voie || this.dossierBien.bien.nomVoie) {
            localisation.push(this.dossierBien.bien.voie?.nom || this.dossierBien.bien.nomVoie);
        }

        this._localisation = localisation.join(' - ');
    }

    get location(): Location {
        return this._dossierBien as Location;
    }

    get localisation(): string {
        return this._localisation;
    }

    get NO_NAME(): string {
        return ContactsGroup.NO_NAME;
    }

    get vente(): Vente {
        return this._dossierBien as Vente;
    }
}
