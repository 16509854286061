import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IScope} from 'angular';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {NgEstimationReference} from '@legacy/app/managers/ressources';

export default function getEstimationReferencesList(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimation-references-list></esk-estimation-references-list>
         * @param options : {edit(function), remove(function), visibleFields(object)}
         * @param options.visibleFields : {edit(bool), remove(bool), sort(bool)}
         */
        module.component('eskEstimationReferencesList', {
            bindings: {estimation: '=', references: '=', options: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/item/references/list.html'
        });

        /**
         * Controller for estimation references
         *
         * @param $scope
         * @param BiensUtils
         */
        Controller.$inject = ['$scope', 'BiensUtils'];
        function Controller(this: any, $scope: IScope, biensUtils: NgBienUtils) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectAll = onSelectAll;
            $ctrl.onSelect = onSelect;

            /**
             * Watch on estimations total items change
             */
            $scope.$watch('$ctrl.references.totalItems', function () {
                if (angular.isObject($ctrl.references) && angular.isArray($ctrl.references.collection)) processSelectAll();
            });

            /**
             * Initialization method
             */
            function $onInit() {
                var listFields = ['edit', 'remove', 'sort'];

                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
                if (!angular.isObject($ctrl.options.visibleFields)) $ctrl.options.visibleFields = {};

                angular.forEach(listFields, function (field) {
                    if ($ctrl.options.visibleFields[field] !== false) {
                        $ctrl.options.visibleFields[field] = true;
                    }
                });

                $ctrl.uiSortableOptions = biensUtils.getUISortableOptions();
            }

            /**
             * Select all references
             */
            function onSelectAll() {
                angular.forEach($ctrl.references.collection, reference => reference._esk.select = $ctrl.selectAll);
            }

            /**
             * Select estimationReference
             *
             * @param estimationReference
             */
            function onSelect(estimationReference: NgEstimationReference) {
                if (estimationReference._esk.select) {
                    processSelectAll();
                } else {
                    $ctrl.selectAll = false;
                }
            }

            /**
             * Define selectAll
             */
            function processSelectAll() {
                $ctrl.selectAll = filter($ctrl.references.collection, {_esk: {select: true}}).length === $ctrl.references.collection.length;
            }
        }
    })(angularJS);
}
