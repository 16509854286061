import AModel from '@models/model.abstract';
import {IDocumentVariables} from '@models/documents/document/document.interfaces';

export default class Document extends AModel {
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private _contenu!: string;
    private readonly _id: number;
    private _linkTemplate!: string;
    private _templateId!: number;
    private _titre!: string;
    private _variables!: IDocumentVariables;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get contenu(): string {
        return this._contenu;
    }

    set contenu(value: string) {
        this._contenu = value;
    }

    get id(): number {
        return this._id;
    }

    get linkTemplate(): string {
        return this._linkTemplate;
    }

    set linkTemplate(value: string) {
        this._linkTemplate = value;
    }

    get templateId(): number {
        return this._templateId;
    }

    set templateId(value: number) {
        this._templateId = value;
    }

    get titre(): string {
        return this._titre;
    }

    set titre(value: string) {
        this._titre = value;
    }

    get variables(): IDocumentVariables {
        return this._variables;
    }

    set variables(value: IDocumentVariables) {
        this._variables = value;
    }
}
