import {inject, Injectable} from '@angular/core';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {OffreachatsApiService} from '@models/offreachats/offreachats.api.service';
import {IOffreachatApi} from '@models/offreachats/offreachat/offreachat.interfaces';
import DateFormat from '@shared/date/date.format';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Template from '@models/templates/template/template.model';
import Vente from '@models/ventes/vente/vente.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Injectable({providedIn: 'root'})
export class OffreachatFactory {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _offreachatsApiService = inject(OffreachatsApiService);

    archive$(offreachat: Offreachat): Observable<void> {
        return this._offreachatsApiService.archive$(offreachat.uuid);
    }

    create(offreachatApi: IOffreachatApi): Offreachat {
        const offreachat = this.createVirgin(offreachatApi.uuid);

        offreachat.dateOffre = offreachatApi.dateOffre;
        offreachat.demandeurUuid = offreachatApi.demandeurUuid;
        offreachat.documentId = offreachatApi.documentId;
        offreachat.honorairesNego = offreachatApi.honorairesNego;
        offreachat.montant = offreachatApi.montant;
        offreachat.statut = offreachatApi.statut;
        offreachat.typeCharges =
            this._dictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, offreachatApi.typeCharges || Vente.CODES_DEFAULT.typeCharges);
        offreachat.venteUuid = offreachatApi.venteUuid;
        if (offreachatApi._links) {
            if (offreachatApi._links.demandeur) {
                offreachat.linkDemandeur = offreachatApi._links.demandeur.href;
            }

            if (offreachatApi._links.document) {
                offreachat.linkDocument = offreachatApi._links.document.href;
            }

            if (offreachatApi._links.media) {
                offreachat.linkMedia = offreachatApi._links.media.href;
            }

            if (offreachatApi._links.procedureAcquereur) {
                offreachat.linkProcedureAcquereur = offreachatApi._links.procedureAcquereur.href;
            }

            if (offreachatApi._links.procedures) {
                offreachat.linkProcedures = offreachatApi._links.procedures.href;
            }

            if (offreachatApi._links.procedureVendeur) {
                offreachat.linkProcedureVendeur = offreachatApi._links.procedureVendeur.href;
            }

            if (offreachatApi._links.self) {
                offreachat.linkSelf = offreachatApi._links.self.href;
            }

            if (offreachatApi._links.vente) {
                offreachat.linkVente = offreachatApi._links.vente.href;
            }
        }

        return offreachat;
    }

    createVirgin(uuid?: string): Offreachat {
        const offreachat = new Offreachat(uuid ?? Offreachat.statuts.NEW);

        offreachat.dateOffre = DateFormat.datetimeFromDate();
        offreachat.statut = Offreachat.statuts.NEW;

        return offreachat;
    }

    forApi(offreachat: Offreachat): IOffreachatApi {
        const offreachatApi = {} as IOffreachatApi;

        offreachatApi.dateOffre = DateFormat.toAPI(offreachat.dateOffre);
        offreachatApi.demandeurUuid = offreachat.demandeurUuid;
        offreachatApi.documentId = offreachat.documentId;
        offreachatApi.montant = offreachat.montant;
        offreachatApi.typeCharges = offreachat.typeCharges.code;
        offreachatApi.venteUuid = offreachat.venteUuid;
        if (offreachat.typeCharges.code !== Vente.chargesType.HONO_NEGO_INCLUS) {
            offreachatApi.honorairesNego = offreachat.honorairesNego;
        }

        return offreachatApi;
    }

    get$(uuid: string): Observable<Offreachat> {
        return this._offreachatsApiService.get$(uuid).pipe(map(offreachatApi => this.create(offreachatApi)));
    }

    getByLink$(link: string): Observable<Offreachat> {
        return this._offreachatsApiService.getByLink$(link).pipe(map(offreachatApi => this.create(offreachatApi)));
    }

    save$(offreachat: Offreachat): Observable<Offreachat> {
        return this._offreachatsApiService.save$(offreachat.uuid, this.forApi(offreachat)).pipe(map(offreachatApi => this.create(offreachatApi)));
    }

    sign$(offreachat: Offreachat, provider: string): Observable<void> {
        return this._offreachatsApiService.sign$(offreachat.uuid, {provider});
    }

    write$(offreachat: Offreachat, template: Template): Observable<void> {
        return this._offreachatsApiService.write$(offreachat.uuid, {templateId: template.id});
    }
}
