export default class NotestimBien {
    private _libelle!: string;
    private _notestimId!: number;

    get libelle(): string {
        return this._libelle;
    }

    set libelle(value: string) {
        this._libelle = value;
    }

    get notestimId(): number {
        return this._notestimId;
    }

    set notestimId(value: number) {
        this._notestimId = value;
    }
}
