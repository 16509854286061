import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IScope} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEstimation} from '@legacy/app/managers/ressources';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEstimationsList(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimations-list></esk-estimations-list>
         * @param options : {displayMode(string), loader: {updating(bool)}, nameDossiers(string), onChangeFilter(function), typeFilter(string), visibleFields(object)}
         * @param options.visibleFields : {angleRight(bool), localisation(bool), nature(bool), nomContactsGroup(bool), photo(bool), preciseLocalisation(bool), select(bool), statut(bool), valeurVenale(bool)}
         */
        module.component('eskEstimationsList', {
            bindings: {
                filter: '=',
                estimations: '=',
                click: '=onClick',
                select: '=onSelect',
                unselectAll: '=?',
                options: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/list.html'
        });

        /**
         * Controller for estimations lists
         *
         * @param $scope
         * @param Ng2RouterStateForNgService
         */
        Controller.$inject = ['$scope', 'Ng2RouterStateForNgService'];
        function Controller(this: any, $scope: IScope, ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectAll = onSelectAll;
            $ctrl.onSelect = onSelect;
            $ctrl.onClick = onClick;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.estimationStatuts = Estimation.statuts;

            /**
             * Watch on estimations total items change
             */
            $scope.$watch('$ctrl.estimations', function () {
                if (angular.isObject($ctrl.estimations) && angular.isArray($ctrl.estimations.collection)) {
                    processSelectAll();
                }
            });

            /**
             * Initialization method
             */
            function $onInit() {
                var listFields = ['angleRight', 'localisation', 'nature', 'nomContactsGroup', 'photo', 'preciseLocalisation', 'select', 'statut', 'valeurVenale'];

                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
                if (!angular.isString($ctrl.options.displayMode)) $ctrl.options.displayMode = 'table';
                if (!angular.isObject($ctrl.options.visibleFields)) $ctrl.options.visibleFields = {};

                angular.forEach(listFields, function (field) {
                    if ($ctrl.options.visibleFields[field] !== false) {
                        $ctrl.options.visibleFields[field] = true;
                    }
                });
                $ctrl.unselectAll = unselectAll;

                if (angular.isFunction($ctrl.options.onChangeFilter)) {
                    $ctrl.onChangeSort = onChangeSort;
                }
            }

            /**
             * Select all estimations
             */
            function onSelectAll() {
                angular.forEach($ctrl.estimations.collection, function (estimation) {
                    estimation._esk.select = $ctrl.selectAll;
                    $ctrl.onSelect(estimation, true);
                });
            }

            /**
             * Select estimation
             *
             * @param estimation
             * @param ignoreSelectAll
             */
            function onSelect(estimation: NgEstimation, ignoreSelectAll: boolean) {
                if (!ignoreSelectAll) {
                    if (estimation._esk.select) {
                        processSelectAll();
                    } else {
                        $ctrl.selectAll = false;
                    }
                }

                if (angular.isFunction($ctrl.select)) {
                    $ctrl.select(estimation);
                }
            }

            /**
             * Click on item
             *
             * @param estimation
             */
            function onClick(estimation: NgEstimation) {
                if (angular.isFunction($ctrl.click)) {
                    $ctrl.click(estimation);
                } else if (angular.isString(estimation._esk.routing)) {
                    $ctrl.ng2RouterStateForNgService.navigateByUrl(estimation._esk.routing);
                } else {
                    estimation._esk.select = !estimation._esk.select;
                    $ctrl.onSelect(estimation);
                }
            }

            /**
             * Define selectAll
             */
            function processSelectAll() {
                $ctrl.selectAll = filter($ctrl.estimations.collection, {_esk: {select: true}}).length === $ctrl.estimations.collection.length;
            }

            /**
             * Unselect all selected estimations
             */
            function unselectAll() {
                angular.forEach($ctrl.estimations.collection, estimation => estimation._esk.select = false);
                processSelectAll();
            }

            /**
             * On change sort on column
             *
             * @param sort
             */
            function onChangeSort(sort: unknown) {
                $ctrl.filter.queryParameters.sort = sort;
                $ctrl.filter.save().then($ctrl.options.onChangeFilter);
            }
        }
    })(angularJS);
}
