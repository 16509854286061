import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {CFiltersFactory} from '@models/filters/collection/filters.collection.factory';
import Filter from '@models/filters/filter/filter.model';
import {map} from 'rxjs/operators';
import {FilterFactory} from '@models/filters/filter/filter.factory';
import {ConditionConst} from '@shared/constants';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class FilterService {
    private _cFiltersFactory = inject(CFiltersFactory);
    private _filterFactory = inject(FilterFactory);

    clear(filter: Filter): void {
        filter.queryParameters = this._filterFactory.getDefaultQueryParameters(filter.route, filter.code);
    }

    getOneByRouteCode$<T extends IQueryParameters>(route: string, code: string): Observable<Filter<T>> {
        if (Filter.useSessionStorage(route, code)) {
            return this._filterFactory.getOneFromStorage$(route, code);
        }

        return this._cFiltersFactory.get$<T>({
            code: {condition: ConditionConst.IN, values: [code]},
            route: {condition: ConditionConst.IN, values: [route]},
        }).pipe(
            map(cFilters => cFilters.results?.[0]),
            map(filter => filter ? filter : this._filterFactory.createDefault(route, code)),
        );
    }

    save$(filter: Filter): Observable<void> {
        if (Filter.useSessionStorage(filter.route, filter.code)) {
            return this._filterFactory.saveToStorage$(filter);
        }

        return throwError(() => 'Non implémenté');
    }
}
