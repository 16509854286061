import {inject, Injectable} from '@angular/core';
import COffreachats from '@models/offreachats/collection/offreachats.collection.model';
import {BehaviorSubject, Observable, Subject, switchMap} from 'rxjs';
import {COffreachatsFactory} from '@models/offreachats/collection/offreachats.collection.factory';
import {map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {ICOffreachatsQueryParameters} from '@models/offreachats/collection/offreachats.collection.interfaces';

@Injectable({providedIn: 'root'})
export class COffreachatsService {
    private _cOffreachatsFactory = inject(COffreachatsFactory);
    private _offreachatService = inject(OffreachatService);
    private _currentSource = new BehaviorSubject<COffreachats>(undefined!);
    private readonly _endCurrentSubject = new Subject<void>();

    get current$(): Observable<COffreachats> {
        return this._currentSource.asObservable();
    }

    addNextToCurrent$(): Observable<COffreachats> {
        return this.current$.pipe(
            take(1),
            switchMap(cOffreachats => this._cOffreachatsFactory.getNext$(cOffreachats).pipe(
                tap(newCOffreachats => cOffreachats.updateNext(newCOffreachats)),
                map(_ => cOffreachats),
            )),
        );
    }

    initCurrent(queryParameters?: ICOffreachatsQueryParameters): void {
        this._endCurrentSubject.next();
        this._offreachatService.lastEdited$.pipe(
            startWith(1),
            tap(_ => this._currentSource.next(undefined!)),
            switchMap(_ => this._cOffreachatsFactory.get$(queryParameters)),
            takeUntil(this._endCurrentSubject),
        ).subscribe(currentCOffreachats => this._currentSource.next(currentCOffreachats));
    }
}
