import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import AModel from '@models/model.abstract';

export default class DemandeurRecherche extends AModel {
    static readonly activeStatuts = {
        NO_RESULT: 'no_result',
        PENDING: 'pending',
        UP_TO_DATE: 'up_to_date',
    };
    static readonly statuts = {
        BROUILLON: ADossier.abstractStatuts.BROUILLON,
        NEW: AModel.STATUT_NEW,
    };
    private readonly _id: number;
    private _linkDemandeur!: string;
    private _localizationSummary!: string;
    private _natures: DictionaryItem[] = [];
    private _type!: string;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get id(): number {
        return this._id;
    }

    get linkDemandeur(): string {
        return this._linkDemandeur;
    }

    set linkDemandeur(value: string) {
        this._linkDemandeur = value;
    }

    get localizationSummary(): string {
        return this._localizationSummary;
    }

    set localizationSummary(value: string) {
        this._localizationSummary = value;
    }

    get natures(): DictionaryItem[] {
        return this._natures;
    }

    set natures(value: DictionaryItem[]) {
        this._natures = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}
