@if (cArrondissements$ | async; as cArrondissements) {
  <div class="tw-h-full tw-space-y-2">
    @for (arrondissement of cArrondissements.results; track arrondissement) {
      <div class="tw-flex tw-items-center">
        <input class="tw-input-checkbox" id="{{options.name}}-{{arrondissement.uuid}}" type="checkbox"
               [value]="arrondissement" [ngModel]="checkedArrondissements.has(arrondissement.uuid)"
               [indeterminate]="indeterminateArrondissements.has(arrondissement.uuid)"
               (change)="onChangeQuartiers(arrondissement, checkedArrondissements.has(arrondissement.uuid) ? [] : arrondissement.quartiers)"/>

        <label class="tw-flex tw-items-center tw-space-x-2 tw-ml-3 tw-text-gray-700 tw-text-sm tw-cursor-pointer"
               for="{{options.name}}-{{arrondissement.uuid}}">
          <span class="tw-truncate">{{ arrondissement.nom }}</span>
        </label>
      </div>

      <app-form-checkbox-list class="tw-block tw-ml-6 tw-pb-2" (selected)="onChangeQuartiers(arrondissement, $event)"
                              [options]="{bindLabel: 'nom', name: options.name + '-' + arrondissement.uuid}"
                              [list]="arrondissement.quartiers" [model]="selectedQuartiers"/>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-full'"/>
}
