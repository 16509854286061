export default `
<ul class="list-unstyled list-bien-photos" ng-model="$ctrl.bien.photos" ui-sortable="$ctrl.uiSortableOptions"
    ng-show="$ctrl.bien.photos.length > 0">
  <li ng-repeat="bienPhoto in $ctrl.bien.photos track by $index">
    <div ng-if="['protegee', 'privee'].includes(bienPhoto.visibilite)" class="ribbon">
      <span ng-if="bienPhoto.visibilite === 'protegee'" class="ribbon-warning">Protégée</span>

      <span ng-if="bienPhoto.visibilite === 'privee'" class="ribbon-danger">Privée</span>
    </div>

    <ul ng-switch="bienPhoto._esk.removing" class="list-unstyled toolbar">
      <li ng-switch-when="true" class="loading">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.REMOVE' | translate}}
      </li>

      <li ng-switch-default class="drag-handler" title="Déplacer la photo">
        <fa-icon [icon]="'arrows-alt'" flip="vertical"></fa-icon>
      </li>

      <li ng-switch-default ng-click="$ctrl.removePhoto(bienPhoto)" title="Supprimer la photo">
        <fa-icon [icon]="'trash-alt'"></fa-icon>
      </li>

      <li ng-switch-default class="btn-group photo-more-actions" role="group">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          <fa-icon [icon]="'ellipsis-v'"></fa-icon>
        </button>

        <div class="dropdown-menu">
          <ul class="list-unstyled">
            <li>
              <button type="button" class="btn" ng-click="$ctrl.editBienPhoto(bienPhoto)">
                <fa-icon [icon]="'pencil-alt'"></fa-icon> Modifier
              </button>
            </li>

            <li ng-if="$ctrl.bien._esk.type !== $ctrl.DossierBienTypesConst.ESTIMATION">
              <button type="button" class="btn" ng-click="$ctrl.manageVisibilityBienPhoto(bienPhoto)">
                <fa-icon [icon]="'low-vision'"></fa-icon> Gérer la visibilité
              </button>
            </li>

            <li>
              <a href="{{bienPhoto._links.file.href}}" download target="_blank">
                <fa-icon [icon]="'download'"></fa-icon> Télécharger l'original
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <img class="drag-handler" ng-src="{{bienPhoto._esk.href.thumbnail}}" loading="lazy">
  </li>
</ul>
`;
