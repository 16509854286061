import ContactsGroup from '@models/contacts-group/contacts-group.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import User from '@models/users/user/user.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default class Demandeur extends ADossier {
    static readonly archiveTypes = {
        NE_RECHERCHE_PLUS: 'ne_recherche_plus',
        VENTE_NON_TROUVEE: 'vente_non_trouvee',
        VENTE_TROUVEE: 'vente_trouvee',
    };
    static readonly fondsProvenances = {
        FINANCEMENT: 'financement',
        NON_RENSEIGNE: null,
        PAIEMENT_COMPTANT: 'paiement_comptant',
    };
    static readonly fondsStatuts = {
        ACCEPTE: 'accepte',
        ATTENTE_REPONSE: 'attente_reponse',
        NON_RENSEIGNE: null,
        PAS_DE_RECHERCHE: 'pas_de_demarche',
        REFUSE: 'refuse',
    };
    static readonly statuts = {
        ARCHIVE: ADossier.abstractStatuts.ARCHIVE,
        BROUILLON: ADossier.abstractStatuts.BROUILLON,
        EN_RECHERCHE: ADossier.abstractStatuts.EN_RECHERCHE,
        EN_SUSPENS: ADossier.abstractStatuts.EN_SUSPENS,
        NEW: ADossier.abstractStatuts.NEW,
    };
    readonly dossierType = DossierTypesConst.DEMANDEUR;
    private _contactsGroup!: ContactsGroup;
    private _linkRecherches!: string;
    private _responsableDossier!: User;

    constructor(uuid: string, id?: number, reference?: string) {
        super(uuid, id, reference);
        this._interne = true;
    }

    get contactsGroup(): ContactsGroup {
        return this._contactsGroup;
    }

    set contactsGroup(value: ContactsGroup) {
        this._contactsGroup = value;
    }

    get linkRecherches(): string {
        return this._linkRecherches;
    }

    set linkRecherches(value: string) {
        this._linkRecherches = value;
    }

    get responsableDossier(): User {
        return this._responsableDossier;
    }

    set responsableDossier(value: User) {
        this._responsableDossier = value;
    }

    get responsableId(): number {
        return this._responsableDossier.id;
    }
}
