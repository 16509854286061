export default `
<div ng-if="!$ctrl.box.closed" class="panel panel-white box-{{$ctrl.box.code}}">
  <div class="panel-heading border-light">
    <span class="drag-handler">:::</span>

    <h4 class="panel-title">
      <span ng-if="$ctrl.box.badge.value > 0" class="badge badge-info">{{$ctrl.box.badge.value}}</span>

      {{$ctrl.box.name}}
    </h4>

    <div class="panel-tools">
      <span ng-transclude="toolsSlot"></span>

      <button type="button" class="btn btn-transparent" ng-click="$ctrl.toggleExpansion()">
        <span ng-if="$ctrl.box.expanded"><fa-icon [icon]="'minus'"></fa-icon></span>

        <span ng-if="!$ctrl.box.expanded"><fa-icon [icon]="['far', 'window-maximize']"></fa-icon></span>
      </button>

      <button type="button" class="btn btn-transparent" ng-click="$ctrl.closeBox()">
        <fa-icon [icon]="'times'"></fa-icon>
      </button>
    </div>
  </div>

  <div class="panel-body" ng-show="$ctrl.box.expanded" ng-transclude="contentSlot"></div>

  <div class="panel-footer" ng-show="$ctrl.box.expanded" ng-transclude="footerSlot"></div>
</div>
`;
