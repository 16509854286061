<div class="tw-w-full" [ngClass]="options.aspectRatio">
  @if (link) {
    <div class="tw-relative tw-h-full tw-w-full">
      <img [ngSrc]="link" class="tw-inline tw-object-cover" [priority]="options.priority"
           [ngStyle]="{objectPosition: coordinates.x.toString() + '% ' + coordinates.y.toString() + '%'}" fill/>

      @if (options.editButtonLabel) {
        <div class="tw-absolute tw-right-0 tw-bottom-0 tw-p-[2%]">
          <button type="button" class="tw-btn-secondary-default" (click)="action.emit()">
            <fa-icon [icon]="['fal', 'camera']"/>

            <span class="tw-hidden sm:tw-inline-block">{{ options.editButtonLabel }}</span>
          </button>
        </div>
      }
    </div>
  } @else {
    <div class="tw-relative tw-flex tw-items-center tw-justify-center tw-h-full tw-bg-gray-200 tw-p-[2%]">
      <div class="tw-h-full tw-aspect-square tw-rounded-full tw-bg-white tw-p-[6%]">
        <div class="tw-flex tw-items-center tw-justify-center tw-h-full tw-w-full" #noLinkContainerIconElementRef>
          <fa-icon [icon]="options.noLinkContainerIcon!"/>
        </div>
      </div>

      @if (options.noLinkButtonLabel) {
        <div class="tw-absolute tw-top-0 tw-right-0 tw-p-[2%]">
          <button type="button" class="tw-btn-secondary-default" (click)="action.emit()">
            <fa-icon [icon]="['fal', 'camera']"/>

            <span class="tw-hidden sm:tw-inline-block">{{ options.noLinkButtonLabel }}</span>
          </button>
        </div>
      }
    </div>
  }
</div>
