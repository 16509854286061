@if (dossierbiens$ | async; as nbDossierbiens) {
  @if ((nbDossierbiens.cBonvisiteLocations.total + nbDossierbiens.cBonvisiteVentes.total) > 0) {
    @if ((nbDossierbiens.cBonvisiteLocations.total + nbDossierbiens.cBonvisiteVentes.total) > 1) {
      @if (nbDossierbiens.cBonvisiteLocations.total > 0) {
        <span class="tw-font-bold">{{nbDossierbiens.cBonvisiteLocations.total}}
        </span> bien{{nbDossierbiens.cBonvisiteLocations.total > 1 ? 's' : ''}}
        en <span class="tw-font-bold">location</span>
      }

      @if (nbDossierbiens.cBonvisiteLocations.total > 0 && nbDossierbiens.cBonvisiteVentes.total > 0) {
        <span>et<br></span>
      }

      @if (nbDossierbiens.cBonvisiteVentes.total > 0) {
        <span class="tw-font-bold">{{nbDossierbiens.cBonvisiteVentes.total}}
        </span> bien{{nbDossierbiens.cBonvisiteVentes.total > 1 ? 's' : ''}}
        en <span class="tw-font-bold">vente</span>
      }

      @if ((nbDossierbiens.cBonvisiteLocations.total + nbDossierbiens.cBonvisiteVentes.total) > 0) {
        <span>
          associé{{(nbDossierbiens.cBonvisiteLocations.total + nbDossierbiens.cBonvisiteVentes.total) > 1 ? 's' : ''}}
        </span>
      }
    } @else {
      <div class="sm:tw-flex print:tw-block">
        Bien en

        @if (nbDossierbiens.cBonvisiteLocations.total === 1) {
          location
          <div class="tw-ml-1 tw-font-bold">
            <app-dossier-reference [link]="nbDossierbiens.cBonvisiteLocations.results[0].linkLocation"
                                   class="tw-block tw-min-w-[3rem]"/>
          </div>
        }

        @if (nbDossierbiens.cBonvisiteVentes.total === 1) {
          vente
          <span class="tw-ml-1 tw-font-bold">
            <app-dossier-reference [link]="nbDossierbiens.cBonvisiteVentes.results[0].linkVente"
                                   class="tw-block tw-min-w-[3rem]"/>
          </span>
        }
      </div>
    }
  } @else {
    Aucun bien associé
  }
} @else {
  <app-loader [classHeight]="'tw-h-5'"/>
}
