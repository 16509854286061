import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICFiltersApi, ICFiltersQueryParameters} from '@models/filters/collection/filters.collection.interfaces';
import {SessionStorageService} from '@core/storage/session-storage.service';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class FiltersApiService {
    private _modelApiService = inject(ModelApiService);
    private _sessionStorageService = inject(SessionStorageService);

    delete$(id: string): Observable<void> {
        return this._modelApiService.delete$(`/user/filters/${id}`);
    }

    getCollection$(queryParameters?: ICFiltersQueryParameters): Observable<ICFiltersApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/user/filters', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICFiltersApi> {
        return this._modelApiService.getCollection$(link);
    }

    getFromSessionStorage$<T extends IQueryParameters>(key: string): Observable<T> {
        return of(this._sessionStorageService.get<T>(key));
    }

    saveToSessionStorage$<T extends IQueryParameters>(key: string, value: T): Observable<void> {
        this._sessionStorageService.set(key, value);

        return of(undefined);
    }
}
