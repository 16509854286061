import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICEstimationOnsaleReferencesApi
} from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.interfaces';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import {
    IEstimationOnsaleReferenceApi
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationOnsaleReferencesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(estimationId: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$('/estimations/' + estimationId + '/on-sale-references/' + uuid);
    }

    getCollection$(estimationId: string): Observable<ICEstimationOnsaleReferencesApi> {
        return this.getCollectionByLink$('/estimations/' + estimationId + '/on-sale-references');
    }

    getCollectionByLink$(link: string): Observable<ICEstimationOnsaleReferencesApi> {
        return this._modelApiService.getCollection$(link);
    }

    save$(estimationId: string, uuid: string, estimationOnsaleReferenceApi: IEstimationOnsaleReferenceApi): Observable<IEstimationOnsaleReferenceApi> {
        if (uuid === EstimationOnsaleReference.statuts.NEW) {
            return this._modelApiService.post$('/estimations/' + estimationId + '/on-sale-references', estimationOnsaleReferenceApi);
        }

        return throwError(() => 'PAS IMPLÉMENTÉ');
    }

    updateRank$(estimationId: string, uuid: string, rank: number): Observable<void> {
        return this._modelApiService.patch$('/estimations/' + estimationId + '/on-sale-references/' + uuid + '/rank', {rank});
    }
}
