import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    IUserSettingsDiffusionsApi, IUserSettingsDossiersApi
} from '@models/users/user/settings/user-settings.interfaces';
import {switchMap} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class UserSettingsApiService {
    private _modelApiService = inject(ModelApiService);

    getDossiers$(userUuid: string, dossierType: string, httpOptions?: HttpOptions): Observable<IUserSettingsDossiersApi> {
        return this._modelApiService.get$('/utilisateurs/' + userUuid + '/settings/' + dossierType, httpOptions);
    }

    saveDossiers$(userUuid: string, dossierType: string, userSettingsDossiersApi: IUserSettingsDossiersApi): Observable<IUserSettingsDossiersApi> {
        return this._modelApiService.put$('/utilisateurs/' + userUuid + '/settings/' + dossierType, userSettingsDossiersApi)
            .pipe(switchMap(_ => this.getDossiers$(userUuid, dossierType)));
    }

    getDiffusions$(userUuid: string, httpOptions?: HttpOptions): Observable<IUserSettingsDiffusionsApi> {
        return this._modelApiService.get$('/users/' + userUuid + '/settings/diffusions', httpOptions);
    }

    saveDiffusions$(userUuid: string, userSettingsDiffusionsApi: IUserSettingsDiffusionsApi): Observable<IUserSettingsDiffusionsApi> {
        return this._modelApiService.put$('/users/' + userUuid + '/settings/diffusions', userSettingsDiffusionsApi)
            .pipe(switchMap(_ => this.getDiffusions$(userUuid)));
    }
}
