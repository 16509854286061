import {Component, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';

@Component({
    selector: 'app-dossier-bien-overview-descriptifs',
    templateUrl: 'dossier-bien-overview.descriptifs.component.html',
})
export class AppDossierBienOverviewDescriptifsComponent {
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }
}
