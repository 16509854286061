import {Component, inject, Input} from '@angular/core';
import Site from '@models/sites/site/site.model';
import Etude from '@models/etudes/etude/etude.model';
import {take} from 'rxjs/operators';
import {EmailEditService} from '@models/emails/email/email.edit.service';

@Component({selector: 'app-site-card', templateUrl: 'site.card.component.html'})
export class AppSiteCardComponent {
    private _emailEditService = inject(EmailEditService);
    private _etude!: Etude;
    private _site!: Site;

    get etude(): Etude {
        return this._etude;
    }

    @Input()
    set etude(value: Etude) {
        this._etude = value;
    }

    get site(): Site {
        return this._site;
    }

    @Input()
    set site(value: Site) {
        this._site = value;
    }

    sendMail(): void {
        this._emailEditService.fromEtudeSite$(this.etude, this.site).pipe(take(1)).subscribe();
    }
}
