import {inject, Injectable} from '@angular/core';
import Template from '@models/templates/template/template.model';
import {TemplateCategoryFactory} from '@models/templates/template/category/template-category.factory';
import {ITemplateApi} from '@models/templates/template/template.interfaces';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TemplatesApiService} from '@models/templates/templates.api.service';
import {NgTemplate} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class TemplateFactory {
    private _templateCategoryFactory = inject(TemplateCategoryFactory);
    private _templatesApiService = inject(TemplatesApiService);

    create(templateApi: ITemplateApi): Template {
        const template = new Template(templateApi.id.toString(), templateApi.id);

        template.name = templateApi.name;
        template.orientation = templateApi.orientation;
        template.signatureElectronique = templateApi.signatureElectronique;
        template.size = templateApi.size;
        template.tags = templateApi.tags;
        template.thumbnailUrl = templateApi.thumbnailUrl;
        if (templateApi._embedded) {
            if (templateApi._embedded.category) {
                template.category = this._templateCategoryFactory.create(templateApi._embedded.category);
            }

            if (templateApi._embedded.stylesheets) {
                template.stylesheets = templateApi._embedded.stylesheets;
            }
        }

        if (templateApi._links?.self) {
            template.linkSelf = templateApi._links.self.href;
        }

        return template;
    }

    getByLink$(link: string): Observable<Template> {
        return this._templatesApiService.getByLink$(link).pipe(map(templateApi => this.create(templateApi)));
    }

    ngCreate(ngTemplate: NgTemplate): Template {
        const template = new Template(ngTemplate.id.toString(), ngTemplate.id);

        template.linkSelf = '/templates/' + template.id.toString();
        template.name = ngTemplate.name;
        template.orientation = ngTemplate.orientation;
        template.signatureElectronique = ngTemplate.signatureElectronique;
        template.size = ngTemplate.size;
        template.tags = ngTemplate.tags;
        template.thumbnailUrl = ngTemplate.thumbnailUrl;
        if (ngTemplate.category) {
            template.category = this._templateCategoryFactory.create(ngTemplate.category);
        }

        if (ngTemplate.stylesheets) {
            template.stylesheets = ngTemplate.stylesheets;
        }

        return template;
    }
}
