import {IModule} from 'angular';
import getEskimmo from '@legacy/app/eskimmo/module';
import {Injector, NgModule} from '@angular/core';
import getEskimmoDownNG2Services from '@shared/angularJS/down-ng2/eskimmo.down-ng2.services';
import getEskimmoDownNG2Components from '@shared/angularJS/down-ng2/eskimmo.down-ng2.components';
import {EskimmoComponentsModule} from '@shared/angularJS/up-ng/eskimmo-components.module';
import {Ng2TinymceComponent} from '@shared/angularJS/down-ng2/for-ng/tinymce/ng2-tinymce.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {FeaturesModule} from '@features/features.module';
import {Ng2TextareaComponent} from '@shared/angularJS/down-ng2/for-ng/textarea/ng2-textarea.component';
import {Ng2ImgComponent} from '@shared/angularJS/down-ng2/for-ng/img/ng2-img.component';
import {Ng2DateComponent} from '@shared/angularJS/down-ng2/for-ng/date/ng2-date.component';

@NgModule({
    declarations: [Ng2DateComponent, Ng2ImgComponent, Ng2TextareaComponent, Ng2TinymceComponent],
    exports: [Ng2DateComponent, Ng2ImgComponent, Ng2TextareaComponent, EskimmoComponentsModule, Ng2TinymceComponent],
    imports: [CommonModule, EskimmoComponentsModule, FeaturesModule, SharedModule],
})
export class EskimmoModule {
}

export const getEskimmoModuleName = (injector: Injector): string => {
    const eskimmo: IModule = getEskimmo(injector);

    // Ajout des services Angular dans AngularJS
    getEskimmoDownNG2Services(eskimmo, injector);

    // Ajout des components Angular dans AngularJS
    getEskimmoDownNG2Components(eskimmo);

    return eskimmo.name;
};
