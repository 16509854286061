import {ILinksApi} from '@models/links/links.interfaces';

export default class Links {
    private _first: string;
    private _last: string;
    private _next: string;
    private _previous: string;
    private _self: string;

    constructor(linksApi?: ILinksApi) {
        this._first = linksApi?.first?.href as unknown as string;
        this._last = linksApi?.last?.href as unknown as string;
        this._next = linksApi?.next?.href as unknown as string;
        this._previous = linksApi?.previous?.href as unknown as string;
        this._self = linksApi?.self?.href as unknown as string;
    }

    get first(): string {
        return this._first;
    }

    get last(): string {
        return this._last;
    }

    get next(): string {
        return this._next;
    }

    get previous(): string {
        return this._previous;
    }

    get self(): string {
        return this._self;
    }

    updateNext(links: Links): void {
        this._last = links.last;
        this._next = links.next;
        this._self = links.self;
    }
}
