import {inject, Injectable} from '@angular/core';
import Report from '@models/reports/report/report.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ReportsApiService} from '@models/reports/reports.api.service';
import {IReportApi} from '@models/reports/report/report.interfaces';
import {NgReport} from '@legacy/app/managers/ressources';
import DateFormat from '@shared/date/date.format';

@Injectable({providedIn: 'root'})
export class ReportFactory {
    private _reportsApiService = inject(ReportsApiService);

    create(reportApi: IReportApi): Report {
        const report = new Report(reportApi.uuid, reportApi.id);

        report.date = reportApi.date;
        report.subject = reportApi.subject;

        return report;
    }

    get$(id: number): Observable<Report> {
        return this._reportsApiService.get$(id.toString()).pipe(map(reportApi => this.create(reportApi)));
    }

    ngCreate(ngReport: NgReport): Report {
        const report = new Report(ngReport.uuid, ngReport.id);

        report.date = DateFormat.toJSON(ngReport.date);
        report.subject = ngReport.subject;

        return report;
    }
}
