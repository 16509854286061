export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.saveVente()" novalidate class="vente-archive">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'archive'"></fa-icon> Archiver le bien en vente</h3>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-5">
        <div class="form-group" soq-radio-checked="$ctrl.archive.type">
          <label>Type d'archivage</label>

          <div ng-repeat="typeArchiveVente in $ctrl.typesArchiveVente">
            <div class="radio clip-radio radio-primary">
              <input type="radio" id="typeArchiveVente{{typeArchiveVente.id}}" ng-value="typeArchiveVente"
                     ng-model="$ctrl.archive.type" name="typeArchiveVente" code="{{typeArchiveVente.code}}">

              <label for="typeArchiveVente{{typeArchiveVente.id}}">{{typeArchiveVente.label}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-7" ng-if="$ctrl.archive.type.code !== $ctrl.VENTE_ARCHIVE_TYPES_RETIREE">
        <div ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE"
                   class="form-group reference-demandeur">
          <label>Provenance de l'acquéreur</label>

          <div ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.raisonArchiveVenteNegociee.$invalid}">
            <esk-select-dictionary dictionary="{{$ctrl.VENTE_ARCHIVE_NEGOCIEE_RAISONS}}" name="raisonArchiveVenteNegociee"
                                   is-required="true" ng-model="$ctrl.archive.raisonVenteNegociee">
            </esk-select-dictionary>

            <div class="error text-small tw-block" ng-messages="$ctrl.editForm.raisonArchiveVenteNegociee.$error"
                 ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.raisonArchiveVenteNegociee.$invalid">
              <ng-messages-include src="messages.html"></ng-messages-include>
            </div>
          </div>

          <div ng-if="$ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE"
               class="margin-top-5">
            <div ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.archiveDemandeurId.$invalid}">
              <esk-select-dossier types-dossier-enabled="['demandeurs']"
                                  on-select="$ctrl.onSelectDemandeur" ng-model="$ctrl.archiveDemandeur"
                                  options="$ctrl.options.demandeurs"></esk-select-dossier>

              <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveDemandeurId.$error"
                   ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archiveDemandeurId.$invalid">
                <ng-messages-include src="messages.html"></ng-messages-include>
              </div>
            </div>

            <div class="demandeurs-archives">
              <div class="checkbox clip-check check-green"
                   title="La recherche sera effectuée également sur les demandeurs archivés.">
                <input type="checkbox" id="demandeurs-archives" ng-model="$ctrl.archivedDemandeurs"
                       ng-change="$ctrl.getArchivedDemandeurs()">

                <label for="demandeurs-archives">Archivés</label>
              </div>
            </div>
          </div>

          <div ng-if="$ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU"
               class="margin-top-5" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.archiveEtudeAcquereur.$invalid}">
            <esk-select-etude name="archiveEtudeAcquereur" ng-model="$ctrl.archiveEtudeAcquereur"
                              is-required="true"></esk-select-etude>

            <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveEtudeAcquereur.$error"
                 ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archiveEtudeAcquereur.$invalid">
              <ng-messages-include src="messages.html"></ng-messages-include>
            </div>
          </div>

          <div ng-if="$ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU || $ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES"
               class="margin-top-5" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.archiveAcquereur.$invalid}">
            <input type="text" name="archiveAcquereur" class="form-control" ng-model="$ctrl.archive.acquereur"
                   placeholder="Nom de l'acquéreur">

            <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveAcquereur.$error"
                 ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archiveAcquereur.$invalid">
              <ng-messages-include src="messages.html"></ng-messages-include>
            </div>
          </div>
        </div>

        <div ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NON_NEGOCIEE" class="form-group"
             ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.raisonArchiveVenteNonNegociee.$invalid}">
          <label>{{"Raison de la non-négociation" | translate}}</label>

          <esk-select-dictionary dictionary="{{$ctrl.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS}}" is-required="true"
                                 name="raisonArchiveVenteNonNegociee"
                                 ng-model="$ctrl.archive.raisonVenteNonNegociee"></esk-select-dictionary>

          <div class="error text-small tw-block" ng-messages="$ctrl.editForm.raisonArchiveVenteNonNegociee.$error"
               ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.raisonArchiveVenteNonNegociee.$invalid">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </div>
        </div>
      </div>
    </div>

    <div class="row" ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE || $ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NON_NEGOCIEE">
      <div class="col-sm-4">
        <div class="form-group"
             ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.archivePrixCession.$invalid}">
          <label for="archivePrixCession">{{"Prix de vente final" | translate}}</label>

          <div class="input-group">
            <input type="text" name="archivePrixCession" id="archivePrixCession" class="form-control"
                   ng-model="$ctrl.archive.prixCession" soq-only-digits="{nonAccepted0: true, float: true}"
                   ng-required="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE"
                   ng-change="$ctrl.operatePricing()">

            <span class="input-group-addon">&euro;</span>
          </div>

          <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archivePrixCession.$error"
               ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archivePrixCession.$invalid">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </div>
        </div>

        <div class="form-group" ng-show="$ctrl.vente.mandatPrixVente">
          <label>{{"Prix de vente au mandat" | translate}}</label>

          <p
            class="form-control-static">{{$ctrl.vente.mandatPrixVente | currency:'€':2 * ($ctrl.vente.mandatPrixVente % 1 !== 0)}}</p>
        </div>
      </div>

      <div class="col-sm-4" ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE">
        <div class="form-group">
          <label for="archiveHonorairesNego">Honoraires de négociation</label>

          <div class="input-group">
            <input type="text" id="archiveHonorairesNego" class="form-control"
                   ng-model="$ctrl.archive.honorairesNego" soq-only-digits="{float: true}">

            <span class="input-group-addon">&euro;</span>
          </div>
        </div>

        <div class="form-group" ng-show="$ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU">
          <label for="archiveHonorairesNegoPercus">Négociation perçue</label>

          <div class="input-group">
            <input type="text" id="archiveHonorairesNegoPercus" class="form-control"
                   ng-model="$ctrl.archive.honorairesNegoPercus" soq-only-digits="{float: true}">

            <span class="input-group-addon">&euro;</span>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group"
             ng-class="{'has-error': $ctrl.editForm.$submitted && ($ctrl.editForm.archiveDateVente.$error | json) !== '{}'}">
          <label>{{"Date de vente"|translate}}</label>

          <esk-select-date form="$ctrl.editForm" form-field-name="archiveDateVente"
                           model="$ctrl.archiveDateVente"></esk-select-date>

          <span class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveDateVente.$error"
                ng-if="$ctrl.editForm.$submitted">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Commentaires complémentaires sur l'archivage</label>

      <app-ng2-textarea [text]="$ctrl.archive.comments" (changed)="$ctrl.archive.comments = $event"></app-ng2-textarea>
    </div>
  </div>

  <div class="modal-footer" ng-switch="!$ctrl.archive || $ctrl.sending">
    <p ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>

      {{$ctrl.archive ? 'global.en_cours.SAVE' : 'global.en_cours.LOAD' | translate}}
    </p>

    <div ng-switch-default>
      <button ng-if="$ctrl.vente.enabledCompromis" type="button" class="btn btn-default pull-left"
              ng-click="$ctrl.getArchiveInfos(true)" title="Reprendre les données depuis le compromis en cours">
        <fa-icon [icon]="'sync-alt'"></fa-icon>
      </button>

      <button class="btn btn-primary btn-o" type="submit"><fa-icon [icon]="'check'"></fa-icon> Enregistrer</button>

      <button class="btn btn-primary" type="button" ng-click="$ctrl.archiveVente()">
        <fa-icon [icon]="'archive'"></fa-icon> Confirmer l'archivage
      </button>
    </div>
  </div>
</form>
`;
