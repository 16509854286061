import {inject, Injectable} from '@angular/core';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import {Observable} from 'rxjs';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {
    EstimationOnsaleReferenceFactory
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.factory';
import {IModelValidationError} from '@models/model.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationOnsaleReferenceService {
    static TITLE_ADDED_ESTIMATION_ONSALE_REFERENCE = 'Ajout d\'une référence en vente';
    static TITLE_ADDED_ESTIMATION_ONSALE_REFERENCES = 'Ajout de références en vente';
    static MESSAGE_ESTIMATION_ONSALE_REFERENCES = 'aux références en vente de votre bien en estimation';

    static getMessageLinkOnsaleReferenceAdded(): string {
        return 'La référence est ajoutée ' + EstimationOnsaleReferenceService.MESSAGE_ESTIMATION_ONSALE_REFERENCES + '.';
    }

    static getMessageLinkOnsaleReferenceAddedError(error: IModelValidationError): string {
        return 'La référence ne peut être ajoutée ' + EstimationOnsaleReferenceService.MESSAGE_ESTIMATION_ONSALE_REFERENCES
            + ' ' + EstimationOnsaleReferenceService.getMessageValidationErrorsRaisons(error) + '.';
    }

    static getMessageLinkOnsaleReferencesAdded(cityscanOnsaleProperties: CityscanOnsaleProperty[]): string {
        if (cityscanOnsaleProperties.length <= 1) {
            return EstimationOnsaleReferenceService.getMessageLinkOnsaleReferenceAdded();
        }

        return 'Les ' + cityscanOnsaleProperties.length.toString() + ' références sont ajoutées ' + EstimationOnsaleReferenceService.MESSAGE_ESTIMATION_ONSALE_REFERENCES + '.';
    }

    static getMessageValidationErrorsRaisons(error: IModelValidationError): string {
        const messageValidationErrors = (error.validationErrors ?? []).map(validationError => '<br>    - ' + validationError.message);

        if (messageValidationErrors.length > 1) {
            return 'pour les raisons suivantes :' + messageValidationErrors.join(' ;');
        } else if (messageValidationErrors.length === 1) {
            return 'pour la raison suivante :' + messageValidationErrors.join(' ;');
        }

        return '';
    }

    private _estimationOnsaleReferenceFactory = inject(EstimationOnsaleReferenceFactory);

    saveFromCityscanOnsaleProperty$(estimation: Estimation, cityscanOnsaleProperty: CityscanOnsaleProperty): Observable<EstimationOnsaleReference> {
        return this._estimationOnsaleReferenceFactory.save$(estimation,  this._estimationOnsaleReferenceFactory.createFromCityscanOnsaleProperty(cityscanOnsaleProperty));
    }
}
