import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {DossiersModule} from '@features/dossiers/dossiers.module';
import {RouterModule} from '@angular/router';
import {DCUserSlideOverComponent} from '@features/dynamic-components/user-slide-over/dc-user.slide-over.component';
import {CommunesModule} from '@features/communes/communes.module';
import {
    DCVentePasserellesComponent
} from '@features/dynamic-components/vente-passerelles/dc-vente-passerelles.component';
import {EtudesModule} from '@features/etudes/etudes.module';

@NgModule({
    declarations: [DCUserSlideOverComponent, DCVentePasserellesComponent],
    imports: [CommonModule, CommunesModule, DossiersModule, EtudesModule, RouterModule, SharedModule],
})
export class DCModule {
}
