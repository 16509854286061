import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {map, tap} from 'rxjs/operators';
import {MediaFactory} from '@models/medias/media/media.factory';
import Media from '@models/medias/media/media.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import Contact from '@models/contacts/contact/contact.model';
import {DossierFileFactory} from '@models/dossiers/dossier/files/file/dossier-file.factory';
import {
    CDossierFileContactsService
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.service';
import {NgMedia} from '@legacy/app/managers/ressources';
import {MediaService} from '@models/medias/media/media.service';

@Injectable({providedIn: 'root'})
export class DossierFileService {
    private _cDossierFileContactsService = inject(CDossierFileContactsService);
    private _dossierFileFactory = inject(DossierFileFactory);
    private _mediaFactory = inject(MediaFactory);
    private _mediaService = inject(MediaService);

    attachMedia$(dossierFile: DossierFile): Observable<DossierFile> {
        return this.getMedia$(dossierFile).pipe(
            tap(media => dossierFile.media = media),
            map(_ => dossierFile),
        );
    }

    createFromNgMedia$(dossier: ADossier, ngMedia: NgMedia): Observable<DossierFile> {
        const dossierFileToSave = this._dossierFileFactory.createFromMedia(this._mediaFactory.ngCreate(ngMedia));

        return this._dossierFileFactory.save$(dossier, dossierFileToSave).pipe(switchMap(dossierFile => this.attachMedia$(dossierFile)));
    }

    getMedia$(dossierFile: DossierFile): Observable<Media> {
        return this._mediaFactory.getByLink$(dossierFile.linkMedia);
    }

    read$(dossierFile: DossierFile): Observable<Window> {
        let media$ = of(dossierFile.media);

        if (!dossierFile.media) {
            media$ = this.getMedia$(dossierFile);
        }

        return media$.pipe(switchMap(media => this._mediaFactory.read$(media)));
    }

    rename$(dossierFile: DossierFile): Observable<boolean> {
        return this._mediaService.rename$(dossierFile.media);
    }

    share$(dossier: ADossier, dossierFileToShare: DossierFile, contactsToShare: Contact[] = []): Observable<void> {
        return this._cDossierFileContactsService.getContactsByLink$(dossierFileToShare.linkContacts, {share: true}).pipe(
            switchMap(sharedContacts => {
                const sharingContacts = contactsToShare
                    .filter(contactToShare => !sharedContacts.some(sharedContact => sharedContact.uuid === contactToShare.uuid));

                if (sharingContacts.length <= 0) {
                    return of(sharedContacts);
                }

                return combineLatest(sharingContacts.map(contact => this._dossierFileFactory.share$(dossier, dossierFileToShare, contact)));
            }),
            map(_ => undefined),
        );
    }

    updateShare$(dossier: ADossier, dossierFileToShare: DossierFile, contactsToShare: Contact[] = []): Observable<void> {
        return this._cDossierFileContactsService.getContactsByLink$(dossierFileToShare.linkContacts, {share: true}).pipe(
            switchMap(sharedContacts => {
                if (sharedContacts.length <= 0) {
                    return of(undefined);
                }

                const contactsToUnshare = sharedContacts
                    .filter(sharedContact => !contactsToShare.some(contactToShare => contactToShare.uuid === sharedContact.uuid));

                if (contactsToUnshare.length <= 0) {
                    return of(sharedContacts);
                }

                return combineLatest(contactsToUnshare.map(contactToUnshare => this._dossierFileFactory.unshare$(dossier, dossierFileToShare, contactToUnshare))).pipe(
                    map(_ => sharedContacts),
                );
            }),
            switchMap(_ => this.share$(dossier, dossierFileToShare, contactsToShare)),
            map(_ => undefined),
        );
    }
}
