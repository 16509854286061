import AModel from '@models/model.abstract';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';

export default class EmailRecipient extends AModel {
    static readonly noteo = {
        COMMERCE: {
            emailAddress: 'commerce@noteo.septeo.com',
            name: 'Service Commercial ' + AppNoteoIconTextNotyComponent.label,
        },
        SUPPORT: {
            emailAddress: 'support@noteo.septeo.com',
            name: 'Service Technique ' + AppNoteoIconTextNotyComponent.label,
        },
    };
    static readonly statuts = {
        BOUNCED_HARD: 'hard-bounced',
        BOUNCED_SOFT: 'soft-bounced',
        DRAFT: 'draft',
        DELIVERED_COMPLETE: 'delivered',
        NEW: AModel.STATUT_NEW,
        SENT: 'sent',
    };
    static readonly types = {BCC: 'bcc', CC: 'cc', TO: 'to'};
    private _emailAddress!: string;
    private readonly _id!: number;
    private _labelStatut!: string;
    private _name!: string;
    private _shortLabelStatut!: string;
    private _statut!: string;
    private _type!: string;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value;
    }

    get id(): number {
        return this._id;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get shortLabelStatut(): string {
        return this._shortLabelStatut;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
        switch (this._statut) {
            case EmailRecipient.statuts.BOUNCED_HARD :
                this._labelStatut = 'rejeté';
                this._shortLabelStatut = 'r';
                break;
            case EmailRecipient.statuts.BOUNCED_SOFT :
                this._labelStatut = 'rejeté';
                this._shortLabelStatut = 'r';
                break;
            case EmailRecipient.statuts.DELIVERED_COMPLETE :
                this._labelStatut = 'délivré';
                this._shortLabelStatut = 'd';
                break;
            case EmailRecipient.statuts.DRAFT :
            case EmailRecipient.statuts.NEW :
                this._labelStatut = 'brouillon';
                this._shortLabelStatut = 'b';
                break;
            case EmailRecipient.statuts.SENT :
                this._labelStatut = 'envoyé';
                this._shortLabelStatut = 'e';
                break;
            default:
                this._labelStatut = undefined!;
                this._shortLabelStatut = undefined!;
        }
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    isDelivered(): boolean {
        return this.statut === EmailRecipient.statuts.DELIVERED_COMPLETE;
    }

    isEditable(): boolean {
        return this.statut === EmailRecipient.statuts.DRAFT || this.statut === EmailRecipient.statuts.NEW;
    }

    isHardBounced(): boolean {
        return this.statut === EmailRecipient.statuts.BOUNCED_HARD;
    }

    isSent(): boolean {
        return this.statut === EmailRecipient.statuts.SENT;
    }

    isSoftBounced(): boolean {
        return this.statut === EmailRecipient.statuts.BOUNCED_SOFT;
    }
}
