import {inject, Injectable} from "@angular/core";
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {IBienTitreDescriptifQueryParameters} from '@models/bien/bien.interfaces';

@Injectable({providedIn: 'root'})
export class BienApiService {
    private _modelApiService = inject(ModelApiService);

    getDescriptif$(queryParameters?: IBienTitreDescriptifQueryParameters): Observable<string> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/templates/biens/descriptif.txt', queryParameters), {responseType: 'text'});
    }

    getTitre$(queryParameters?: IBienTitreDescriptifQueryParameters): Observable<string> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/templates/biens/titre.txt', queryParameters), {responseType: 'text'});
    }
}
