import {HttpClient, HttpResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import environment from '@env/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpEventProgress, HttpOptions} from '@core/api/api.interfaces';
import {UrlService} from '@shared/texts/url/url.service';
import {AuthService} from '@core/auth/core/auth.service';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';

@Injectable({providedIn: 'root'})
export class ApiService {
    static readonly SWITCH_USER_KEY = '_switch_user';
    private _apiImpersonationService = inject(ApiImpersonationService);
    private _authService = inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _excludedUrls: string[] = [];

    get excludedUrls(): string[] {
        return this._excludedUrls;
    }

    addExcludedUrl(url: string): void {
        this._excludedUrls.push(url);
    }

    delete$(path: string): Observable<void> {
        return this._httpClient.delete(this.getFullUrl(path)).pipe(map(_ => undefined));
    }

    get$<T>(path: string, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.get(this.getFullUrl(path), {...{observe: 'body'}, ...httpOptions} as NonNullable<unknown>).pipe(
            map(data => data as unknown as T),
        );
    }

    getAuthenticatedUrlPath(urlPath: string): string {
        return this.updateUrlPath(urlPath, 'access_token', this._authService.getAccessToken());
    }

    getFullUrl(path: string): string {
        let urlPath = environment.api.baseUrl + environment.api.pathUrl + path;

        if (this._apiImpersonationService.isImpersonate()) {
            urlPath = UrlService.addQueryparamToUrl(urlPath, ApiService.SWITCH_USER_KEY, this._apiImpersonationService.getImpersonate());
        }

        return urlPath.replace(environment.api.pathUrl.repeat(2), environment.api.pathUrl);
    }

    getResponse$<T>(path: string, httpOptions: HttpOptions = {}): Observable<T> {
        return this.get$<HttpResponse<T>>(path, {...{observe: 'response'}, ...httpOptions} as NonNullable<unknown>).pipe(
            map((httpResponse: HttpResponse<T>): T => httpResponse.body as T),
        );
    }

    patch$<T>(path: string, body: unknown, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.patch<T>(this.getFullUrl(path), body, httpOptions as NonNullable<unknown>).pipe(
            map(data => data as unknown as T),
        );
    }

    post$<T>(path: string, body: T, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.post<T>(this.getFullUrl(path), body, httpOptions as NonNullable<unknown>).pipe(
            map(data => data as unknown as T),
        );
    }

    put$<T>(path: string, body: T, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.put<T>(this.getFullUrl(path), body, httpOptions as NonNullable<unknown>).pipe(
            map(data => data as unknown as T),
        );
    }

    updateUrlPath(urlPath: string, key: string, value: string): string {
        const splittedPath = urlPath.split('?');

        if (splittedPath.length < 2) {
            return UrlService.addQueryparamToUrl(urlPath, key, value);
        }

        const splittedQueryParams = splittedPath[1].split('&').map(splittedQueryParam => splittedQueryParam.split('='));
        const queryParamSplitted = splittedQueryParams.find(splittedQueryParam => splittedQueryParam[0] === key);

        if (!queryParamSplitted) {
            return UrlService.addQueryparamToUrl(urlPath, key, value);
        }

        queryParamSplitted[1] = value;
        splittedPath[1] = splittedQueryParams.map(splittedQueryParam => splittedQueryParam.join('=')).join('&');

        return splittedPath.join('?');
    }

    upload$<T>(path: string, formData: FormData, httpOptions: HttpOptions = {}): Observable<HttpEventProgress | HttpResponse<T>> {
        return this._httpClient.post<HttpEventProgress | HttpResponse<T>>(this.getFullUrl(path), formData, {
            ...{observe: 'events', reportProgress: true},
            ...httpOptions,
        } as NonNullable<unknown>);
    }
}
