import {inject, Injectable} from '@angular/core';
import Meteo from '@core/meteo/meteo.model';
import {IMeteoApi, IMeteoStorage} from '@core/meteo/meteo.interfaces';
import DateFormat from '@shared/date/date.format';
import Commune from '@models/communes/commune/commune.model';
import {Observable} from 'rxjs';
import {MeteoApiService} from '@core/meteo/meteo.api.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MeteoFactory {
    private _meteoApiService = inject(MeteoApiService);

    create(meteoApi: IMeteoApi): Meteo {
        const meteo = this.createVirgin();

        meteo.cloudCover = meteoApi.clouds?.all;
        meteo.date = DateFormat.datetimeFromDate();
        meteo.temperature = meteoApi.main?.temp;
        // Conversion en km/h
        meteo.windSpeed = (meteoApi.wind?.speed ?? 0) * 3.6;

        if (meteoApi.weather?.[0]) {
            meteo.description = meteoApi.weather[0].description;
            meteo.icon = meteoApi.weather[0].icon.replace('n', 'd');
        }

        return meteo;
    }

    createFromStorage(meteoStorage: IMeteoStorage): Meteo {
        const meteo = this.createVirgin();

        meteo.cloudCover = meteoStorage.cloudCover;
        meteo.date = meteoStorage.date;
        meteo.description = meteoStorage.description;
        meteo.icon = meteoStorage.icon;
        meteo.temperature = meteoStorage.temperature;
        meteo.windSpeed = meteoStorage.windSpeed;

        return meteo;
    }

    createVirgin(): Meteo {
        return new Meteo();
    }

    get$(commune: Commune): Observable<Meteo> {
        return this._meteoApiService.get$(commune.coordonneesGps[0], commune.coordonneesGps[1]).pipe(map(meteoApi => this.create(meteoApi)));
    }

    getFromStorage(): Meteo {
        const meteoStorage = this._meteoApiService.getFromStorage();

        if (!meteoStorage) {
            return this.createVirgin();
        }

        return this.createFromStorage(meteoStorage);
    }

    saveInStorage(meteo: Meteo): void {
        this._meteoApiService.saveInStorage({
            cloudCover: meteo.cloudCover,
            date: meteo.date,
            description: meteo.description,
            icon: meteo.icon,
            temperature: meteo.temperature,
            windSpeed: meteo.windSpeed,
        });
    }
}
