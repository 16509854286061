import {Injectable} from '@angular/core';
import EtudeDiffusion from '@models/etudes/etude/diffusions/diffusion/etude-diffusion.model';
import {IEtudeDiffusionApi} from '@models/etudes/etude/diffusions/diffusion/etude-diffusion.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeDiffusionFactory {
    create(etudeDiffusionApi: IEtudeDiffusionApi): EtudeDiffusion {
        const etudeDiffusion = new EtudeDiffusion(etudeDiffusionApi.id);

        etudeDiffusion.codeNature = etudeDiffusionApi.codeNature;
        etudeDiffusion.codeTypeCharges = etudeDiffusionApi.codeTypeCharges;
        etudeDiffusion.doubleMandat = etudeDiffusionApi.doubleMandat;
        etudeDiffusion.network = etudeDiffusionApi.network;
        etudeDiffusion.nomProprietairesGroup = etudeDiffusionApi.nomProprietairesGroup;
        etudeDiffusion.passerelles = etudeDiffusionApi.passerelles;
        etudeDiffusion.premierePhoto = etudeDiffusionApi.premierePhoto;
        etudeDiffusion.reference = etudeDiffusionApi.reference;
        etudeDiffusion.type = etudeDiffusionApi.type;

        return etudeDiffusion;
    }
}
