@if (collection$ | async; as collection) {
  <div>
    @if (collection.links.next) {
      <div class="tw-flex tw-justify-evenly tw-pb-8">
        <!-- Pourquoi "disabled:tw-opacity-50" et pas ce qu'il y a par défaut dans "tw-btn-tertiary-info" ? -->
        <button [disabled]="disabled || loading" (click)="executeLoadMore()"
                class="tw-btn-tertiary-info disabled:tw-opacity-50" type="button">
          @if (loading) {
            <fa-icon [icon]="'spinner'" animation="spin"/>
          }

          <span>Charger la suite</span>
        </button>
      </div>
    }
  </div>
} @else {
  <app-loader class="tw-flex tw-justify-evenly tw-py-8" [classHeight]="'tw-h-8'"/>
}
