import AModel from '@models/model.abstract';
import Departement from '@models/departements/departement/departement.model';

export default class Commune extends AModel {
    private _codeInsee!: string;
    private _codesPostaux: string[] = [];
    private _communeParent!: Commune;
    private _convivialName!: string;
    private _coordonneesGps: number[] = [];
    private _departement!: Departement;
    private _fullname!: string;
    private _hasQuartiers = false;
    private _hasVoies = false;
    private readonly _id: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get codeInsee(): string {
        return this._codeInsee;
    }

    set codeInsee(value: string) {
        this._codeInsee = value;
    }

    get codesPostaux(): string[] {
        return this._codesPostaux;
    }

    set codesPostaux(value: string[]) {
        this._codesPostaux = value;
        this.setConvivialName();
    }

    get communeParent(): Commune {
        return this._communeParent;
    }

    set communeParent(value: Commune) {
        this._communeParent = value;
    }

    get convivialName(): string {
        return this._convivialName;
    }

    get coordonneesGps(): number[] {
        return this._coordonneesGps;
    }

    set coordonneesGps(value: number[]) {
        this._coordonneesGps = value;
    }

    get departement(): Departement {
        return this._departement;
    }

    set departement(value: Departement) {
        this._departement = value;
    }

    get fullname(): string {
        return this._fullname;
    }

    set fullname(value: string) {
        this._fullname = value;
        this.setConvivialName();
    }

    get hasQuartiers(): boolean {
        return this._hasQuartiers;
    }

    set hasQuartiers(value: boolean) {
        this._hasQuartiers = value;
    }

    get hasVoies(): boolean {
        return this._hasVoies;
    }

    set hasVoies(value: boolean) {
        this._hasVoies = value;
    }

    get id(): number {
        return this._id;
    }

    isDOMTOM(): boolean {
        return !!this.departement?.isDOMTOM();
    }

    setConvivialName(): void {
        this._convivialName = this.fullname + ' (' + (this.codesPostaux?.length === 1 ? this.codesPostaux[0] : 'Tous codes postaux') + ')';
    }
}
