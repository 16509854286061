<div title="{{contact.invalidEmailAddress ? 'L\'adresse email de ce contact est invalide.' : ''}}"
     class="tw-relative tw-aspect-square">
  <div class="tw-flex tw-items-center tw-justify-center tw-h-full tw-w-full tw-px-1 tw-rounded-full contact-initiales"
       [class]="'bg-' + contact.colorVariation">
    @if (contact.initiales) {
      <span class="tw-truncate">{{ contact.initiales }}</span>
    } @else {
      @if (contact.isPerson()) {
        <fa-icon [icon]="'user'" size="xl"/>
      } @else {
        <fa-icon [icon]="'city'" size="xl"/>
      }
    }
  </div>

  @if (contact.invalidEmailAddress) {
    <span class="tw-absolute tw-top-0 tw-right-0 tw-flex tw-items-center tw-justify-center tw-h-1/3 tw-w-1/3 tw-bg-red-700 tw-rounded-full tw-text-white tw-text-base">
      <fa-icon [icon]="'exclamation'"/>
    </span>
  }
</div>
