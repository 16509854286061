export default `
<ui-select ng-model="$ctrl.ngModel" on-select="$ctrl.onSelect($select.selected)" name="{{$ctrl.options.name}}"
           backspace-reset="!$ctrl.options.required" ng-required="{{$ctrl.options.required}}">
  <ui-select-match allow-clear="{{!$ctrl.options.required}}" placeholder="Sélectionner un tri...">
    {{$select.selected.label}}
  </ui-select-match>

  <ui-select-choices repeat="sort in $ctrl.sorts | filter: $select.search">
    <span ng-bind-html="sort.label | highlight:$select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
