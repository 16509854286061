export default class DossierExportQualifiedContact {
    private _linkContact!: string;
    private _qualification!: string;

    get linkContact(): string {
        return this._linkContact;
    }

    set linkContact(value: string) {
        this._linkContact = value;
    }

    get qualification(): string {
        return this._qualification;
    }

    set qualification(value: string) {
        this._qualification = value;
    }
}
