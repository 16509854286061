import {NgModule} from '@angular/core';
import {ModalComponent} from '@shared/modal/modal.component';
import {ModalConfirmationComponent} from '@shared/modal/confirmation/modal.confirmation.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ModalInformationComponent} from '@shared/modal/information/modal.information.component';
import {ModalStatusIconComponent} from '@shared/modal/status-icon/modal.status-icon.component';
import {NoteoModule} from '@shared/noteo/noteo.module';
import {FaModule} from '@shared/fontawesome/fa.module';

@NgModule({
    declarations: [ModalComponent, ModalConfirmationComponent, ModalInformationComponent, ModalStatusIconComponent],
    imports: [CommonModule, FaModule, FormsModule, NoteoModule],
    exports: [ModalComponent, ModalStatusIconComponent],
})
export class ModalModule {
}
