import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {NgDemandeurManager, NgEmailManager, NgMediaManager, NgReportManager} from '@legacy/app/managers/managers';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {ReportsService} from '@models/reports/reports.service';
import {ReportFactory} from '@models/reports/report/report.factory';
import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';

export default function getEskDossierSuiviReportCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDossierSuiviReportCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/dossier/suivi/report.html',
        });

        /**
         * General controller
         *
         * @param BienDossierCurrent
         * @param DemandeurManager
         * @param ReportManager
         * @param SoqModalesManager
         * @param $translate
         * @param SoqSweetAlert
         * @param Ng2RouterStateForNgService
         * @param EmailManager
         * @param Ng2ReportsService
         * @param Ng2ReportFactory
         * @param MediaManager
         */
        Controller.$inject = ['BienDossierCurrent', 'DemandeurManager', 'ReportManager', 'SoqModalesManager', '$translate', 'SoqSweetAlert', 'Ng2RouterStateForNgService', 'EmailManager', 'Ng2ReportsService', 'Ng2ReportFactory', 'MediaManager'];

        function Controller(this: any,
                            bienDossierCurrent: NgBienDossierCurrent,
                            demandeurManager: NgDemandeurManager,
                            reportManager: NgReportManager,
                            soqModalesManager: NgSoqModalesManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            emailManager: NgEmailManager,
                            ng2ReportsService: ReportsService,
                            ng2ReportFactory: ReportFactory,
                            mediaManager: NgMediaManager) {
            const $ctrl = this;

            $ctrl.edit = edit;
            $ctrl.remove = remove;
            $ctrl.send = send;
            $ctrl.print = print;
            $ctrl.loader = true;
            if (ng2RouterStateForNgService.current.name?.startsWith('/app/demandeurs')) {
                $ctrl.dossier = demandeurManager.current;
            } else {
                $ctrl.dossier = bienDossierCurrent.get();
            }
            reportManager.getOneById(+ng2RouterStateForNgService.params.reportId!)
                .then(report => $ctrl.report = report)
                .finally(() => $ctrl.loader = false);

            /**
             * Edit report
             *
             * @param tab
             */
            function edit(tab: string) {
                soqModalesManager.open('EskModalReport', {
                    resolve: {report: $ctrl.report, currentTab: () => tab, dossier: $ctrl.dossier},
                    backdrop: 'static',
                }).then(report => $ctrl.report = report);
            }

            /**
             * Remove report
             */
            function remove() {
                soqSweetAlert.dangerConfirmation($translate.instant('remove.confirm.TITLE'), $translate.instant('remove.confirm.MESSAGE', {ceData: 'ce compte-rendu'}), {confirmButtonText: $translate.instant('remove.confirm.CONFIRMATION')}).then(function () {
                    return $ctrl.report.remove().then(() => {
                        ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/suivi');
                    });
                }, () => {
                });
            }

            /**
             * Send report
             */
            function send() {
                $ctrl.generating = true;
                firstValueFrom(ng2ReportsService.getMedia$([ng2ReportFactory.ngCreate($ctrl.report)])).then(
                    media => mediaManager.createFromNg2(media)
                ).then(media => {
                    const contactsGroup = $ctrl.dossier._esk.typeDossier === DossierTypesConst.DEMANDEUR ? $ctrl.dossier.contactsGroup : $ctrl.dossier.bien.contactsGroup;

                    soqModalesManager.open('EskModalEmail', {
                        resolve: {
                            email: emailManager.create({
                                recipients: contactsGroup.getRecipients(),
                                attachments: [{media: media}],
                                dossiers: [{
                                    typeId: $ctrl.dossier.id,
                                    type: $ctrl.dossier._esk.typeDossier,
                                    dossier: $ctrl.dossier
                                }],
                                subject: $translate.instant('mail.report.TITLE'),
                                message: $translate.instant('mail.report.MESSAGE', contactsGroup) + "<br><br>" + emailManager.getCurrentUserSignature()
                            }),
                            currentDossier: $ctrl.dossier
                        }
                    });
                }).finally(() => $ctrl.generating = false);
            }

            /**
             * Print report
             */
            function print() {
                ng2ReportsService.read$([ng2ReportFactory.ngCreate($ctrl.report)]).pipe(take(1)).subscribe();
            }
        }
    })(angularJS);
}
