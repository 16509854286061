@if (etude$ | async; as etude) {
  <div class="tw-form-2col">
    <div class="tw-form-2col-part">
      <div class="tw-form-left-col">
        <h3 class="tw-form-left-col-title">Bannière</h3>

        <p class="tw-form-left-col-explain">
          Cette photo mettra en valeur votre étude.<br>Elle sera visible par vos confrères lors de leur visite sur le
          profil de votre étude.
          @if (etude.linkBanner) {
            <br><br>Vous pouvez glisser la photo dans le cadre afin de la centrer sur la partie la plus pertinente.
          }
          <br><br>Pour un rendu optimal, la photo doit avoir :
          <br>    - un poids inférieur à {{ BANNER_IMG_LIMIT.maxSize | textSize }}
          <br>    - une hauteur supérieure à {{ BANNER_IMG_LIMIT.minHeight | textCurrency: 'px' }}
          <br>    - une largeur supérieure à {{ BANNER_IMG_LIMIT.minWidth | textCurrency: 'px' }}
        </p>
      </div>

      <div class="tw-form-right-col">
        @if (etude.linkBanner) {
          <div class="tw-space-y-2">
            <label class="tw-text-sm tw-text-gray-700">Bannière de votre étude</label>

            <div class="tw-max-w-[50rem]">
              <app-image-crop [link]="etude.linkBanner" (crop)="updateBannerCrop($event)" class="tw-block"/>

              <div class="tw-float-right tw-mt-4 tw-mr-10">
                <button type="button" class="tw-btn-secondary-info tw-flex disabled:tw-waiting" (click)="updateBanner()"
                        [disabled]="!canUpdateBanner">Changer de bannière</button>
              </div>
            </div>
          </div>
        } @else {
          @if (bannerTransfer) {
            <div class="tw-h-14 tw-max-w-[20rem] tw-space-y-2">
              <label class="tw-text-sm tw-text-gray-700">Téléversement de la bannière</label>

              <app-transfer-progress [options]="{waitingLabel: 'Lancement du téléversement'}" class="tw-block"
                                     [transfer]="bannerTransfer"/>
            </div>
          } @else {
            <div class="tw-space-y-2">
              <label class="tw-text-sm tw-text-gray-700">Sélectionnez la bannière de votre étude</label>

              <button class="tw-h-full tw-w-full tw-border-dashed tw-border-2 tw-rounded-lg tw-p-6 tw-text-gray-500 tw-text-2xl"
                      type="button" appFormFileDropper (droppedFile)="importBannerFile($event)">
                <app-image-picker [options]="{label: 'Déposer votre bannière ici', messageWithValidation: true, maxSize: BANNER_IMG_LIMIT.maxSize, minHeight: BANNER_IMG_LIMIT.minHeight, minWidth: BANNER_IMG_LIMIT.minWidth, multiple: false, withIcon: true}"
                                  (selected)="importBannerFile($event)" [openFilePicker$]="openBannerFilePicker$"/>
              </button>
            </div>
          }
        }
      </div>
    </div>

    <div class="tw-form-2col-part">
      <div class="tw-form-left-col">
        <h3 class="tw-form-left-col-title">Logo</h3>

        <p class="tw-form-left-col-explain">
          Cette image sera par exemple utilisée dans les en-têtes de vos documents ou si un de vos biens n'a pas de
          photos associées.<br>Elle sera visible également par vos confrères lors de leur visite sur le profil de votre
          étude.<br><br>Pour un rendu optimal, l'image doit avoir :
          <br>    - un poids inférieur à {{ LOGO_IMG_LIMIT.maxSize | textSize }}
          <br>    - une hauteur supérieure à {{ LOGO_IMG_LIMIT.minHeight | textCurrency: 'px' }}
          <br>    - une largeur supérieure à {{ LOGO_IMG_LIMIT.minWidth | textCurrency: 'px' }}
          <br><br>Également, il est conseillé que l'image soit de forme carrée.
        </p>
      </div>

      <div class="tw-form-right-col">
        @if (etude.linkRawLogo) {
          <div class="tw-space-y-2">
            <label class="tw-text-sm tw-text-gray-700">Logo de votre étude</label>

            <div class="tw-flex tw-gap-4">
              <img class="tw-object-contain tw-shadow-lg tw-aspect-square" [ngSrc]="etude.linkLogo" height="192"
                   width="192">

              <div class="tw-flex tw-items-end">
                <div>
                  <button type="button" class="tw-btn-secondary-info tw-flex disabled:tw-waiting" (click)="updateLogo()"
                          [disabled]="!canUpdateLogo">Changer de Logo</button>
                </div>
              </div>
            </div>
          </div>
        } @else {
          @if (logoTransfer) {
            <div class="tw-h-14 tw-max-w-[20rem] tw-space-y-2">
              <label class="tw-text-sm tw-text-gray-700">Téléversement du logo</label>

              <app-transfer-progress [options]="{waitingLabel: 'Lancement du téléversement'}" class="tw-block"
                                     [transfer]="logoTransfer"/>
            </div>
          } @else {
            <div class="tw-space-y-2">
              <label class="tw-text-sm tw-text-gray-700">Sélectionnez le logo de votre étude</label>

              <button class="tw-h-full tw-w-full tw-border-dashed tw-border-2 tw-rounded-lg tw-p-6 tw-text-gray-500 tw-text-2xl"
                      type="button" appFormFileDropper (droppedFile)="importLogoFile($event)">
                <app-image-picker [options]="{label: 'Déposer votre logo ici', messageWithValidation: true, maxSize: LOGO_IMG_LIMIT.maxSize, minHeight: LOGO_IMG_LIMIT.minHeight, minWidth: LOGO_IMG_LIMIT.minWidth, multiple: false, withIcon: true}"
                                  (selected)="importLogoFile($event)" [openFilePicker$]="openLogoFilePicker$"/>
              </button>
            </div>
          }
        }
      </div>
    </div>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
