import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import UserClientMetadata from '@models/users/user/client-metadata/user-client-metadata.model';
import {UserClientMetadataApiService} from '@models/users/user/client-metadata/user-client-metadata.api.service';
import {IUserClientMetadataApi} from '@models/users/user/client-metadata/user-client-metadata.interfaces';

@Injectable({providedIn: 'root'})
export class UserClientMetadataFactory {
    private _userClientMetadataApiService = inject(UserClientMetadataApiService);

    create(userClientMetadataApi: IUserClientMetadataApi): UserClientMetadata {
        const userClientMetadata = this.createVirgin();

        if (userClientMetadataApi.config) {
            userClientMetadata.config = userClientMetadataApi.config;
        }

        return userClientMetadata;
    }

    createVirgin(): UserClientMetadata {
        return new UserClientMetadata();
    }

    forApi(userClientMetadata: UserClientMetadata): IUserClientMetadataApi {
        const userClientMetadataApi = {} as IUserClientMetadataApi;

        userClientMetadataApi.config = userClientMetadata.config;

        return userClientMetadataApi;
    }

    getByLink$(link: string): Observable<UserClientMetadata> {
        return this._userClientMetadataApiService.getByLink$(link).pipe(map(userClientMetadataApi => this.create(userClientMetadataApi)));
    }

    save$(userUuid: string, userClientMetadata: UserClientMetadata): Observable<UserClientMetadata> {
        return this._userClientMetadataApiService.save$(userUuid, this.forApi(userClientMetadata)).pipe(map(userClientMetadataApi => this.create(userClientMetadataApi)));
    }
}
