export default `
<ui-select spinner-enabled="true" backspace-reset="true" ng-model="$ctrl.ngModel"
           on-select="$ctrl.onSelect($item, $model)" name="$ctrl.options.name">
  <ui-select-match placeholder="{{$ctrl.options.placeholder}}" allow-clear="true">
    {{$select.selected.name}}
  </ui-select-match>

  <ui-select-choices repeat="localisation in $ctrl.localisations" refresh="$ctrl.refresh($select.search)"
                     refresh-delay="500" minimum-input-length="1">
    <span ng-bind-html="localisation.name | highlight: $select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
