import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewCoutsService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isChargesVisible!: boolean;
    private _isCommentsCoutsVisible!: boolean;
    private _isPartVisible!: boolean;

    get isChargesVisible(): boolean {
        return this._isChargesVisible;
    }

    get isCommentsCoutsVisible(): boolean {
        return this._isCommentsCoutsVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isChargesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.charges);
        this._isCommentsCoutsVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsCouts);

        this._isPartVisible = this.isChargesVisible || this.isCommentsCoutsVisible;
    }
}
