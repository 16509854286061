export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.saveDemandeursArchiveInfos()" novalidate class="demandeurs-group-archive">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()" title="Fermer sans archiver">
      <fa-icon [icon]="'times'"></fa-icon>
    </button>

    <h3 class="modal-title"><fa-icon [icon]="'archive'"></fa-icon> Archivage groupé des demandeurs</h3>
  </div>

  <div class="modal-body">
    <div class="row">
      <!-- Raison -->
      <div class="col-sm-6 form-group">
        <label>Raison de l'archivage</label>

        <div class="radio clip-radio radio-purple"
             ng-repeat="typeArchiveDemandeurVente in $ctrl.typesArchiveDemandeurVente" ng-if="$index > 0">
          <input type="radio" id="type-archive{{typeArchiveDemandeurVente.id}}" ng-model="$ctrl.archive.type"
                 name="typeArchiveDemandeurVente" ng-value="typeArchiveDemandeurVente" required>

          <label for="type-archive{{typeArchiveDemandeurVente.id}}">{{typeArchiveDemandeurVente.label}}</label>
        </div>
      </div>
    </div>

    <!-- Commentaires -->
    <div class="row">
      <div class="col-sm-12">
        <label>Commentaires</label>

        <app-ng2-textarea [placeholder]="'Commentaires complémentaires concernant l\\'archivage groupé'"
                          [text]="$ctrl.archive.comments" (changed)="$ctrl.archive.comments = $event">
        </app-ng2-textarea>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" type="submit">
      <fa-icon [icon]="'archive'"></fa-icon> Confirmer l'archivage groupé
    </button>
  </div>
</form>
`;
