import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getSoqValidation(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('SoqValidation', Factory);

        /**
         * SoqValidation
         *
         * @returns {SoqValidation}
         */
        Factory.$inject = [] as string[];
        function Factory() {
            return {emailAddress: emailAddress, password: password};

            /**
             * Validation email address
             *
             * @param emailAddress
             * @returns {boolean}
             */
            function emailAddress(emailAddress: string): boolean {
                var regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                return regexp.test(emailAddress);
            }

            /**
             * Validation password
             *
             * @param password
             * @returns {boolean}
             */
            function password(password: string): boolean {
                var regexp = /^(?=.*[a-z|áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ])(?=.*[A-Z|ÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ])(?=.*\d)(?=.*[#?!@$%^&*:;.,=_-])[A-Za-zÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ\d#?!@$%^&*:;.,=_-]{8,100}$/;

                return regexp.test(password);
            }
        }
    })(angularJS);
}
