export default `
<ng-switch on="$ctrl.bienPhotos.length > 0" class="display-photos">
  <div ng-switch-when="false" class="tw-text-center">
    <img class="marianne tw-inline" ng-src="{{$ctrl.marianne}}" loading="lazy">
  </div>

  <div ng-switch-default class="row">
    <div class="main tw-text-center" ng-click="$ctrl.viewOriginal($ctrl.bienPhotos[0])"
         ng-class="{'col-sm-12': $ctrl.bienPhotos.length === 1, 'col-sm-10': $ctrl.bienPhotos.length > 1 && $ctrl.bienPhotos.length <= 4, 'col-sm-8': $ctrl.bienPhotos.length > 4}">
      <img class="photo cursor-pointer tw-mx-auto" ng-src="{{$ctrl.bienPhotos[0]._links.file.href}}?size=optimized" loading="lazy">
    </div>

    <div ng-if="$ctrl.bienPhotos.length > 1"
         ng-class="{'col-sm-2': $ctrl.bienPhotos.length > 1 && $ctrl.bienPhotos.length <= 4, 'col-sm-4': $ctrl.bienPhotos.length > 4}">
      <ul class="list-inline">
        <li class="list-thumbnail" ng-repeat="bienPhoto in $ctrl.bienPhotos"
            ng-class="{'one-column': $ctrl.bienPhotos.length > 1 && $ctrl.bienPhotos.length <= 4, 'two-columns': $ctrl.bienPhotos.length > 4}"
            ng-click="$ctrl.viewOriginal(bienPhoto)" ng-if="$index > 0 && $index < 7">
          <img class="photo cursor-pointer" ng-src="{{bienPhoto._esk.href.thumbnail}}" loading="lazy">

          <div ng-if="$index === 6 && $ctrl.bienPhotos.length > 7" class="sail">
            <div class="photos-plus cursor-pointer">
              <p>{{'NOMBRE_PHOTOS' | translate:'{NB: ' + ($ctrl.bienPhotos.length - 7) + '}'}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-switch>
`;
