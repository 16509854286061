import Etude from '@models/etudes/etude/etude.model';
import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';

export default class EtudeDossier {
    static readonly sources = {INOT: Etude.enablableModules.INOT.code};
    private _externalId!: string;
    private _externalSource!: string;
    private _nom!: string;
    private _qualifiedContacts!: EtudeDossierQualifiedContact[];
    private _type!: string;

    get externalId(): string {
        return this._externalId;
    }

    set externalId(value: string) {
        this._externalId = value;
    }

    get externalSource(): string {
        return this._externalSource;
    }

    set externalSource(value: string) {
        this._externalSource = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
    }

    get qualifiedContacts(): EtudeDossierQualifiedContact[] {
        return this._qualifiedContacts;
    }

    set qualifiedContacts(value: EtudeDossierQualifiedContact[]) {
        this._qualifiedContacts = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}
