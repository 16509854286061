import {inject, Injectable} from '@angular/core';
import Actualite from '@models/actualites/actualite/actualite.model';
import {Observable} from 'rxjs';
import {ActualiteFactory} from '@models/actualites/actualite/actualite.factory';

@Injectable({providedIn: 'root'})
export class ActualiteService {
    private actualiteFactory = inject(ActualiteFactory);

    read$(actualite: Actualite): Observable<void> {
        actualite.read = true;

        return this.actualiteFactory.read$(actualite);
    }
}
