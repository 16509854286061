import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgDemandeurManager, NgEstimationManager, NgLocationManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgBienDossiersCollection} from '@legacy/app/managers/collections';
import {NgBien, NgBienDossier, NgContactsGroup, NgDemandeur, NgResource} from '@legacy/app/managers/ressources';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEskSelectDossier(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-dossier></esk-select-dossier>
         * @param options: {conditions: (object), name: (string), placeholder: (string), required: (boolean), searchIsItem: (boolean)}
         * @param options.conditions: {statuts: (object), types: (object)}
         * @param options.conditions.statuts: {condition: (string), values: (array)}
         * @param options.conditions.types: {condition: (string), values: (array)}
         */
        module.component('eskSelectDossier', {
            bindings: {
                typesDossierEnabled: '=',
                onSelect: '=',
                disabled: '=?',
                ngModel: '=?',
                options: '<'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/dossier.html'
        });

        /**
         * Controller to select dossier
         *
         * @param DemandeurManager
         * @param VenteManager
         * @param LocationManager
         * @param EstimationManager
         * @param $q
         * @constructor
         */
        Controller.$inject = ['DemandeurManager', 'VenteManager', 'LocationManager', 'EstimationManager', '$q'];
        function Controller(
            this: any,
            demandeurManager: NgDemandeurManager,
            venteManager: NgVenteManager,
            locationManager: NgLocationManager,
            estimationManager: NgEstimationManager,
            $q: IQService,
        ) {
            const $ctrl = this;
            let lastKeywords: string;

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.getRefreshDossiers = getRefreshDossiers;
            $ctrl.groupDossiers = groupDossiers;
            $ctrl.DossierTypesConst = DossierTypesConst;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.dossiers = [];
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
            }

            /**
             * Refresh the list if options changed
             */
            function $onChanges(changes: { options: { isFirstChange: () => boolean } }) {
                if (!changes.options.isFirstChange()) {
                    return $ctrl.getRefreshDossiers(lastKeywords);
                }
            }

            /**
             * Refresh list dossiers with keywords
             *
             * @param keywords
             */
            function getRefreshDossiers(keywords: string) {
                const promises = [] as IPromise<unknown>[];
                var statutCondition;
                var typeCondition;

                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
                if (!angular.isObject($ctrl.options.conditions)) $ctrl.options.conditions = {};

                lastKeywords = keywords;
                $ctrl.dossiers = [];
                statutCondition = $ctrl.options.conditions.statuts;
                typeCondition = $ctrl.options.conditions.types;

                if (!angular.isArray($ctrl.typesDossierEnabled) || $ctrl.typesDossierEnabled.includes('demandeurs')) {
                    promises.push(demandeurManager.getAllEtude({limit: 50, keywords, statut: statutCondition}));
                }

                if (!angular.isArray($ctrl.typesDossierEnabled) || $ctrl.typesDossierEnabled.includes('ventes')) {
                    promises.push(venteManager.getAllEtude({
                        limit: 50,
                        keywords,
                        statut: statutCondition,
                        type: typeCondition,
                    }));
                }

                if (!angular.isArray($ctrl.typesDossierEnabled) || $ctrl.typesDossierEnabled.includes('ventes_reseau')) {
                    promises.push(venteManager.getAll({
                        limit: 50,
                        keywords,
                        statut: statutCondition,
                        type: typeCondition,
                    }));
                }

                if (!angular.isArray($ctrl.typesDossierEnabled) || $ctrl.typesDossierEnabled.includes('locations')) {
                    promises.push(locationManager.getAllEtude({limit: 50, keywords, statut: statutCondition}));
                }

                if (!angular.isArray($ctrl.typesDossierEnabled) || $ctrl.typesDossierEnabled.includes('estimations')) {
                    promises.push(estimationManager.getAllEtude({
                        limit: 50,
                        keywords,
                        statuts: Estimation.statuts.BROUILLON + ',' + Estimation.statuts.REMIS,
                    }));
                }

                return $q.all(promises).then(
                    searchedDossiers => searchedDossiers as NgBienDossiersCollection[]
                ).then(searchedDossiers => {
                    let dossiers: NgBienDossier[] = [];

                    if (lastKeywords !== keywords) {
                        return;
                    }

                    angular.forEach(searchedDossiers, function (searchedDossier) {
                        dossiers = dossiers.concat(searchedDossier.collection);
                    });
                    $ctrl.dossiers = dossiers;

                    if ($ctrl.options.searchIsItem) {
                        const dossierSearch = {
                            _esk: {typeDossier: $ctrl.typesDossierEnabled[0].slice(0, -1)},
                            eskManager: {prefixRoute: $ctrl.typesDossierEnabled[0]}
                        } as NgBienDossier;
                        const contactsGroup = {nom: keywords} as NgContactsGroup;

                        if (dossierSearch._esk.typeDossier === DossierTypesConst.DEMANDEUR) {
                            (dossierSearch as unknown as NgDemandeur).contactsGroup = contactsGroup;
                        } else if ([DossierTypesConst.ESTIMATION, DossierTypesConst.LOCATION, DossierTypesConst.VENTE].includes(dossierSearch._esk.typeDossier!)) {
                            dossierSearch.bien = {_esk: {interne: true}, contactsGroup} as NgBien;
                        }

                        $ctrl.dossiers.unshift(dossierSearch);
                    }

                    return $ctrl.dossiers;
                });
            }

            /**
             * Group dossiers by prefixRoute
             *
             * @param item
             */
            function groupDossiers(item: NgResource) {
                return item.eskManager!.prefixRoute;
            }
        }
    })(angularJS);
}
