import angularJS from '@shared/angularJS/global.ng';
import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {IModule} from 'angular';
import {NgDictionariesManager, NgManager} from '@legacy/app/managers/managers';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import {firstValueFrom} from 'rxjs';
import {NgVenteCompromis} from '@legacy/app/managers/ressources';
import {NgVenteCompromisCollection} from '@legacy/app/managers/collections';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';

export default function getManagersVenteCompromis(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('VenteCompromisManager', manager);

        /**
         * Manager Vente Compromis
         *
         * @param BaseManager
         * @param DictionariesManager
         * @param Ng2VenteCompromisFactory
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'DictionariesManager', 'Ng2VenteCompromisFactory', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         dictionariesManager: NgDictionariesManager,
                         ng2VenteCompromisFactory: VenteCompromisFactory,
                         ng2DictionaryItemService: DictionaryItemService) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('vente-compromis.cget', {path: '/ventes/{venteId}/compromis', method: 'GET'});
            baseManager.addRoute('vente-compromis.insert', {path: '/ventes/{venteId}/compromis', method: 'POST'});
            baseManager.addRoute('vente-compromis.edit', {path: '/ventes/{venteId}/compromis/{id}', method: 'PUT'});
            baseManager.addRoute('vente-compromis.remove', {
                path: '/ventes/{venteId}/compromis/{id}',
                method: 'DELETE'
            });

            /**
             * VenteCompromisManager object
             *
             * @constructor
             */
            angular.extend(VenteCompromisManager.prototype, baseManager.__proto__);
            VenteCompromisManager.prototype.eskManager = {prefixRoute: 'vente-compromis', collectionName: 'items'};
            function VenteCompromisManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param venteId
             * @returns {Promise}
             */
            VenteCompromisManager.prototype.getAll = function (venteId: number) {
                return baseManager.getAll.call(this, {venteId: venteId}).then(venteCompromisList => {
                    const venteCompromisCollection = venteCompromisList as NgVenteCompromisCollection;

                    angular.forEach(venteCompromisCollection.collection, function (venteContrat) {
                        venteContrat._esk.defaultRouteParams!.venteId = venteId;
                    });

                    return venteCompromisList;
                });
            };

            /**
             * Create a VenteCompromis object
             *
             * @param data
             * @param venteId
             * @returns manager.VenteCompromis object || Array of manager.VenteCompromis objects
             */
            VenteCompromisManager.prototype.create = function (data: unknown, venteId: number) {
                if (angular.isArray(data)) {
                    var venteCompromis = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        venteCompromis.push(new VenteCompromis(venteId, data[i]));
                    }

                    return venteCompromis;
                }

                // @ts-ignore
                return new VenteCompromis(venteId, data);
            };

            /**
             * VenteCompromis object
             *
             * @param venteId
             * @param data
             * @constructor
             */
            angular.extend(VenteCompromis.prototype, classResource.prototype);
            VenteCompromis.prototype.eskManager = {prefixRoute: 'vente-compromis'};
            function VenteCompromis(this: NgVenteCompromis, venteId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, venteId: venteId};
            }

            /**
             * Extend the existing VenteCompromis with new data
             *
             * @param data
             * @returns {VenteCompromis}
             */
            VenteCompromis.prototype.extend = function (data: unknown) {
                let object: unknown = {};

                if (data instanceof AModel) {
                    // @ts-ignore
                    object.negocie = data.negocie;
                    // @ts-ignore
                    object.raisonNegocie = data.raisonNegocie instanceof DictionaryItem ? data.raisonNegocie.code : undefined;
                    // @ts-ignore
                    object.raisonNonNegocie = data.raisonNonNegocie instanceof DictionaryItem ? data.raisonNonNegocie.code : undefined;
                    // @ts-ignore
                    object.date = data.date;
                    // @ts-ignore
                    object.dateFin = data.dateFin;
                    // @ts-ignore
                    object.prixCession = data.prixCession;
                    // @ts-ignore
                    object.dateVente = data.dateVente;
                    // @ts-ignore
                    object.demandeur = data.demandeur;
                    // @ts-ignore
                    object.acquereur = data.acquereur;
                    // @ts-ignore
                    object.comments = data.comments;
                    // @ts-ignore
                    object.etudeAcquereur = data.etudeAcquereur;
                    // @ts-ignore
                    object.honorairesNego = data.honorairesNego;
                    // @ts-ignore
                    object.honorairesNegoPercus = data.honorairesNegoPercus;
                } else {
                    object = data;
                }

                classResource.prototype.extend.call(this, object);

                if (angular.isString(this.date)) {
                    this.date = DateFormat.toDate(this.date);
                }

                if (angular.isString(this.dateFin)) {
                    this.dateFin = DateFormat.toDate(this.dateFin);
                }

                if (angular.isString(this.raisonNegocie) && this.raisonNegocie !== '') {
                    this.raisonNegocie = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, this.raisonNegocie));
                }

                if (angular.isString(this.raisonNonNegocie) && this.raisonNonNegocie !== '') {
                    this.raisonNonNegocie = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, this.raisonNonNegocie));
                }

                if (!this._links) {
                    this._links = {};
                }

                if (this.demandeurId) {
                    if (!this._links.demandeur) {
                        this._links.demandeur = {};
                    }

                    if (!this._links.demandeur.href) {
                        this._links.demandeur.href = '/demandeurs/' + this.demandeurId.toString();
                    }
                }

                if (this.etudeAcquereurId) {
                    if (!this._links.etudeAcquereur) {
                        this._links.etudeAcquereur = {};
                    }

                    if (!this._links.etudeAcquereur.href) {
                        this._links.etudeAcquereur.href = '/etudes/' + this.etudeAcquereurId.toString();
                    }
                }

                return this;
            };

            /**
             * Save VenteCompromis
             *
             * @returns {Object}
             */
            VenteCompromis.prototype.save = function () {
                return firstValueFrom(ng2VenteCompromisFactory.save$(this._esk.defaultRouteParams.venteId, ng2VenteCompromisFactory.ngCreate(this)));
            };

            /**
             * Disable compromis
             *
             * @returns {Promise}
             */
            VenteCompromis.prototype.disable = function () {
                this.enabled = false;

                return this.save();
            };

            // @ts-ignore
            return new VenteCompromisManager();
        }
    })(angularJS);
}
