import {inject, Injectable} from '@angular/core';
import TemplateFooter from '@models/templates/footers/footer/template-footer.model';
import {ITemplateFooterApi} from '@models/templates/footers/footer/template-footer.interfaces';
import {Observable, of} from 'rxjs';
import EtudeSettingsDocumentsFooter
    from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.model';
import {TemplateFootersApiService} from '@models/templates/footers/template-footers.api.service';
import {map} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class TemplateFooterFactory {
    private _templateFootersApiService = inject(TemplateFootersApiService);

    create(templateFooterApi: ITemplateFooterApi): TemplateFooter {
        const templateFooter = this.createVirgin();

        templateFooter.code = templateFooterApi.code;
        templateFooter.height = templateFooterApi.height;
        templateFooter.label = templateFooterApi.title;
        templateFooter.template = templateFooterApi.template;
        templateFooter.thumbnailUrl = templateFooterApi.thumbnailUrl;

        return templateFooter;
    }

    createFromEtudeSettingsDocumentsFooter(etudeSettingsDocumentsFooter: EtudeSettingsDocumentsFooter, templateFooterLikeESDH?: TemplateFooter): TemplateFooter {
        const templateFooter = this.createVirgin();

        if (etudeSettingsDocumentsFooter.mustUseCustomData() || !templateFooterLikeESDH) {
            templateFooter.height = etudeSettingsDocumentsFooter.customHeight;
            templateFooter.template = etudeSettingsDocumentsFooter.customTemplate;
        } else {
            templateFooter.height = templateFooterLikeESDH.height;
            templateFooter.template = templateFooterLikeESDH.template;
        }

        return templateFooter;
    }

    createVirgin(): TemplateFooter {
        return new TemplateFooter();
    }

    getCustom(): TemplateFooter {
        const templateFooter = this.createVirgin();

        templateFooter.code = TemplateFooter.codes.CUSTOM;
        templateFooter.height = 10;
        templateFooter.label = 'Personnalisé';
        templateFooter.thumbnailUrl = TemplateFootersApiService.THUMBNAIL_DEFAULT;

        return templateFooter;
    }

    getPreviewLink$(height: number, template: string, httpOptions?: HttpOptions): Observable<string> {
        if (!template) {
            return of('');
        }

        return this._templateFootersApiService.getPreview$({height, template} as ITemplateFooterApi, httpOptions).pipe(
            map(templateFooterApi => templateFooterApi._links.preview.href),
        );
    }
}
