export default `
<ui-select spinner-enabled="true" soq-multiple="$ctrl.options.multiple" ng-required="{{$ctrl.ngRequired}}"
           backspace-reset="!$ctrl.ngRequired" ng-model="$ctrl.ngModel" on-select="$ctrl.onSelect($item, $model)"
           name="{{$ctrl.name}}">
  <ui-select-match placeholder="{{$ctrl.placeholder}}" allow-clear="{{!$ctrl.ngRequired}}">
    {{$ctrl.displayCommune($item, $select.selected)}}
  </ui-select-match>

  <ui-select-choices repeat="commune in $ctrl.communes" refresh="$ctrl.refresh($select.search)" refresh-delay="500"
                     minimum-input-length="1">
    <span ng-bind-html="commune.fullname | highlight: $select.search"></span>

    <span ng-if="!!commune.codesPostaux">
      (<small
      ng-bind-html="(commune.codesPostaux.length === 1 ? commune.codesPostaux[0] : 'Tous codes postaux') | highlight: $select.search">
    </small>)
    </span>

    <p class="no-margin text-muted" ng-if="commune.actuelle === false">
      Rattachée à {{commune.communeParent.fullname}}
    </p>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
