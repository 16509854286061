import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {IDCVentePasserelleData} from '@features/ventes/ventes.interfaces';

@Component({
    selector: 'vente-passerelle-dropdown',
    templateUrl: 'vente-passerelle.dropdown.component.html',
})
export class VentePasserelleDropdownComponent implements IDynamicComponent {
    static readonly actions = {REMOVE: 'vente-passerelle_remove', SEE_VENTE: 'vente-passerelle_see_vente'};
    private _dropdownService = inject(DropdownService);
    private _ventePasserelle!: VentePasserelle;

    @Input()
    set data(value: IDCVentePasserelleData) {
        this._ventePasserelle = value.ventePasserelle!;
    }

    get ventePasserelle(): VentePasserelle {
        return this._ventePasserelle;
    }

    remove(): void {
        this._dropdownService.clicked(VentePasserelleDropdownComponent.actions.REMOVE, {ventePasserelle: this._ventePasserelle});
    }

    see(): void {
        this._dropdownService.clicked(VentePasserelleDropdownComponent.actions.SEE_VENTE, {ventePasserelle: this._ventePasserelle});
    }

}
