import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgBienDossier, NgDemandeur, NgEmail, NgEmailRecipient} from '@legacy/app/managers/ressources';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {
    NgDemandeurRechercheManager, NgEmailDossierManager, NgEmailRecipientManager
} from '@legacy/app/managers/managers';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default function getDemandeursUtils(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('DemandeursUtils', Factory);

        /**
         * @param DemandeurRechercheManager
         * @param SoqSweetAlert
         * @param $translate
         * @param SoqModalesManager
         * @param $q
         * @param EmailRecipientManager
         * @param EmailDossierManager
         * @returns {methods}
         */
        Factory.$inject = ['DemandeurRechercheManager', 'SoqSweetAlert', '$translate', 'SoqModalesManager', '$q', 'EmailRecipientManager', 'EmailDossierManager'];
        function Factory(
            demandeurRechercheManager: NgDemandeurRechercheManager,
            soqSweetAlert: NgSoqSweetAlert,
            $translate: angular.translate.ITranslateService,
            soqModalesManager: NgSoqModalesManager,
            $q: IQService,
            emailRecipientManager: NgEmailRecipientManager,
            emailDossierManager: NgEmailDossierManager,
        ) {
            return {
                showFieldForSearch: showFieldForSearch,
                sendGroupedEmail: sendGroupedEmail
            };

            /**
             * Returns if a field has to be shown
             *
             * @param search
             * @param field
             * @returns {boolean}
             */
            function showFieldForSearch(search: NgSearch, field: string): boolean {
                return demandeurRechercheManager.useAttributeForNatures(search.natures, field);
            }

            /**
             * Send email to demandeurs
             *
             * @param demandeurs
             * @param email
             * @param currentDossier
             * @returns {Promise}
             */
            function sendGroupedEmail(demandeurs: NgDemandeur[], email: NgEmail, currentDossier: NgBienDossier): IPromise<void> {
                let promise = $q.resolve();
                const noRecipientDemandeurs: NgDemandeur[] = [];

                if (demandeurs.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('demandeur.grouped_action.mailing.TITLE'), $translate.instant('demandeur.grouped_action.mailing.no_selected.MESSAGE'));

                    return $q.reject();
                }

                if (!angular.isArray(email.dossiers)) {
                    email.dossiers = [];
                }

                email.recipients = [];
                angular.forEach(demandeurs, function (demandeur: NgDemandeur) {
                    if (demandeur._esk.select) {
                        const recipientsDemandeur = demandeur.contactsGroup.getRecipients();

                        if (recipientsDemandeur.length > 0) {
                            email.recipients = email.recipients.concat(emailRecipientManager.create(recipientsDemandeur, email.id) as NgEmailRecipient);
                            email.dossiers.push(emailDossierManager.create({
                                typeId: demandeur.id,
                                type: DossierTypesConst.DEMANDEUR,
                                dossier: demandeur,
                            }, email.id));
                        } else {
                            noRecipientDemandeurs.push(demandeur);
                        }
                    }
                });

                if (email.recipients.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('demandeur.grouped_action.mailing.TITLE'), $translate.instant('demandeur.grouped_action.mailing.invalid.MESSAGE'));

                    return $q.reject();
                }

                if (noRecipientDemandeurs.length > 0) {
                    let demandeursNames = "";

                    angular.forEach(noRecipientDemandeurs, function (demandeur) {
                        demandeursNames += " - " + demandeur.contactsGroup.nom + "<br />";
                    });

                    promise = soqSweetAlert.warningMessage($translate.instant('demandeur.grouped_action.mailing.TITLE'),
                        $translate.instant('demandeur.grouped_action.mailing.no_email.MESSAGE', {
                            nbDemandeur: noRecipientDemandeurs.length,
                            demandeursNames: demandeursNames
                        }));
                }

                return promise.then(() => {
                    angular.forEach(email.recipients, recipient => recipient.type = EmailRecipient.types.BCC);

                    return soqModalesManager.open('EskModalEmail', {
                        resolve: {email: email, currentDossier: currentDossier}
                    });
                });
            }
        }
    })(angularJS);
}
