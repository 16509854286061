import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {
    ICTemplateBalisesApi, ICTemplateBalisesQueryParameters
} from '@models/templates/balises/collection/template-balises.collection.interfaces';
import CTemplateBalises from '@models/templates/balises/collection/template-balises.collection.model';

@Injectable({providedIn: 'root'})
export class TemplateBalisesApiService {
    static readonly ETUDE_MODELS = {
        _embedded: {
            items: [
                {code: 'etude_raison_sociale', description: 'Raison sociale', path: 'etude.raisonSociale'},
                {code: 'etude_logo', description: 'Logo (Marianne à défaut)'},
                {code: 'etude_marianne', description: 'Marianne'},
            ],
        },
        total: 3,
    } as ICTemplateBalisesApi;
    static readonly PAGE_MODELS = {
        _embedded: {
            items: [
                {code: 'nombre_pages', description: 'Nombre de pages', path: '5'},
                {code: 'numero_page', description: 'Numéro de page', path: '3'},
            ],
        },
        total: 2,
    } as ICTemplateBalisesApi;
    static readonly SITE_PRINCIPAL_MODELS = {
        _embedded: {
            items: [
                {
                    code: 'etude_adresse',
                    description: 'Adresse postale du site principal',
                    path: 'site.adresse',
                },
                {
                    code: 'etude_code_postal',
                    description: 'Code postal du site principal',
                    path: 'site.codePostal',
                },
                {
                    code: 'etude_commune',
                    description: 'Commune du site principal',
                    path: 'site.commune.fullname',
                },
                {
                    code: 'etude_telephone',
                    description: 'Téléphone du site principal',
                    path: 'site | phoneNumber',
                },
                {
                    code: 'etude_email',
                    description: 'Adresse email du site principal',
                    path: 'site.emailAddress',
                },
                {
                    code: 'etude_site_internet',
                    description: 'Site Internet du site principal',
                    path: 'site.siteInternet',
                },
            ],
        },
        total: 6,
    } as ICTemplateBalisesApi;
    static readonly USER_CURRENT_MODELS = {
        _embedded: {
            items: [
                {code: 'utilisateur_titre', description: 'Titre', path: 'user.titre.label'},
                {code: 'utilisateur_nom', description: 'Nom', path: 'user.nom'},
                {code: 'utilisateur_prenom', description: 'Prénom', path: 'user.prenom'},
                {
                    code: 'utilisateur_adresse',
                    description: 'Adresse postale du site de rattachement',
                    path: 'user.site.adresse',
                },
                {
                    code: 'utilisateur_code_postal',
                    description: 'Code postal du site de rattachement',
                    path: 'user.site.codePostal',
                },
                {
                    code: 'utilisateur_commune',
                    description: 'Commune du site de rattachement',
                    path: 'user.site.commune.fullname',
                },
                {
                    code: 'utilisateur_telephone_fixe',
                    description: '1ᴱᴿ numéro de téléphone fixe',
                    path: 'user | phoneNumber(\'fixe\')',
                },
                {
                    code: 'utilisateur_telephone_mobile',
                    description: '1ᴱᴿ numéro de téléphone mobile',
                    path: 'user | phoneNumber(\'mobile\')',
                },
            ],
        },
        total: 8,
    } as ICTemplateBalisesApi;

    getCollection$(queryParameters: ICTemplateBalisesQueryParameters = {}): Observable<ICTemplateBalisesApi> {
        switch (queryParameters.model) {
            case CTemplateBalises.models.etude.CODE :
                return of(TemplateBalisesApiService.ETUDE_MODELS);

            case CTemplateBalises.models.page.CODE :
                return of(TemplateBalisesApiService.PAGE_MODELS);

            case CTemplateBalises.models.sitePrincipal.CODE :
                return of(TemplateBalisesApiService.SITE_PRINCIPAL_MODELS);

            case CTemplateBalises.models.currentUser.CODE :
                return of(TemplateBalisesApiService.USER_CURRENT_MODELS);
        }

        return throwError(() => 'Ce cas ne doit pas arriver : "' + queryParameters.model! + '"');
    }
}
