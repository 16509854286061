@if (demandeur$ | async; as demandeur) {
  <div class="tw-h-full">
    <div class="container-fluid container-fluid-title print:tw-hidden">
      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-5 labels-line-height-25">
          <h3 class="text-bold ellipsis">{{ demandeur.contactsGroup.nom || "Nouveau dossier" }}</h3>

          <div class="header-labels ellipsis">
            <span class="text-bold">{{ demandeur.reference }}</span>

            <soq-popover-template [display]="'default'" [placement]="'right-top'" [trigger]="'outsideClick'"
                                  [template]="'src/app/legacy/templates/demandeurs/controllers/item/renseignements-confidentiels.html'"/>

            @if (demandeur.isArchived()) {
              <span>Dossier archivé</span>
            }
          </div>
        </div>

        @if (!!demandeur.id) {
          <div class="col-xs-12 col-md-6 col-lg-7 menu-two-part">
            @if (nbDossierFiles$ | appLoadObs | async; as nbDossierFiles) {
              <div class="horizontal-menu" [class]="nbDossierFiles.value > 0 ? 'has-documents' : ''">
                @for (navItem of navItems; track $index) {
                  <div class="menu-item menu-item-nav" [routerLink]="navItem.route" routerLinkActive="menu-item-active">
                    <a>
                      <!-- @todo Passer en "<div>" et supprimer "tw-block" ? -->
                      @if (navItem.code === 'DASHBOARD') {
                        <span class="tw-block"><fa-icon [icon]="'tachometer-alt'"/></span>
                      }

                      @if (navItem.code === 'FICHE') {
                        <span class="tw-block"><fa-icon [icon]="['far', 'copy']"/></span>
                      }

                      @if (navItem.code === 'RESULTS') {
                        <span class="tw-block"><fa-icon [icon]="['far', 'check-square']"/></span>
                      }

                      @if (navItem.code === 'DOCUMENTS') {
                        <span class="tw-block documents"><fa-icon [icon]="'file'"/></span>
                      }

                      @if (navItem.code === 'SUIVI') {
                        <span class="tw-block"><fa-icon [icon]="['far', 'calendar']"/></span>
                      }

                      {{ (navItem.code === 'DOCUMENTS' && nbDossierFiles.value > 1) ? nbDossierFiles.value : '' }}
                      {{ navItem.label }}{{ (navItem.code === 'DOCUMENTS' && nbDossierFiles.value > 1) ? 's' : '' }}

                      @if (navItem.notif.results > 0) {
                        <span class="badge" [ngClass]="navItem.notif.class!">{{ navItem.notif.results }}</span>
                      }
                    </a>
                  </div>
                }

                <div class="menu-item menu-item-more">
                  <div (click)="toggleMore = !toggleMore"><fa-icon [icon]="'ellipsis-h'"/></div>

                  <div class="vertical-menu" [ngClass]="{'active': toggleMore}">
                    @for (navItem of navItems; track $index) {
                      <div class="menu-item menu-item-nav tw-text-center" [routerLink]="navItem.route"
                           routerLinkActive="menu-item-active">
                        <a>
                          @if (navItem.code === 'DASHBOARD') {
                            <span class="tw-block"><fa-icon [icon]="'tachometer-alt'"/></span>
                          }

                          @if (navItem.code === 'FICHE') {
                            <span class="tw-block"><fa-icon [icon]="['far', 'copy']"/></span>
                          }

                          @if (navItem.code === 'RESULTS') {
                            <span class="tw-block"><fa-icon [icon]="['far', 'check-square']"/></span>
                          }

                          @if (navItem.code === 'DOCUMENTS') {
                            <span class="tw-block documents"><fa-icon [icon]="'file'"/></span>
                          }

                          @if (navItem.code === 'SUIVI') {
                            <span class="tw-block"><fa-icon [icon]="['far', 'calendar']"/></span>
                          }

                          {{ (navItem.code === 'DOCUMENTS' && nbDossierFiles.value > 1) ? nbDossierFiles.value : '' }}
                          {{ navItem.label }}{{ (navItem.code === 'DOCUMENTS' && nbDossierFiles.value > 1) ? 's' : '' }}

                          @if (navItem.notif.results > 0) {
                            <span class="badge" [ngClass]="navItem.notif.class!">{{ navItem.notif.results }}</span>
                          }
                        </a>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>

    <div class="tw-h-[calc(100%_-_70px)]"><router-outlet/></div>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
