import {NgModule} from '@angular/core';
import {AppBienPhotoComponent} from '@features/biens/bien/photos/photo/bien.photo.component';
import {CommonModule} from '@angular/common';
import {AppBienOverviewPhotosComponent} from '@features/biens/bien/overview/photos/bien-overview.photos.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    declarations: [AppBienOverviewPhotosComponent, AppBienPhotoComponent],
    exports: [AppBienOverviewPhotosComponent, AppBienPhotoComponent],
    imports: [CommonModule, SharedModule],
})
export class BiensModule {
}
