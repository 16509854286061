export default `
<div class="container-fluid container-fluid-title"><h1>Recherche de biens en {{$ctrl.typeDossier}}</h1></div>

<div ng-switch="!!$ctrl.options.creatingMessage"
     class="container-fluid container-fullw tw-bg-white dossier-biens-search">
  <p ng-switch-when="true" class="tw-text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.options.creatingMessage | translate}}
  </p>

  <div ng-switch-default>
    <!-- Actions sur la liste -->
    <div class="row margin-bottom-10 hidden-print">
      <!-- Tri -->
      <div class="col-sm-5 col-md-4 col-lg-5">
        <div class="row">
          <div class="col-xs-12 col-lg-8">
            <esk-select-sort ng-if="!$ctrl.options.loader.filter" type="{{$ctrl.options.typeSort}}"
                             ng-model="$ctrl.sort" on-select="$ctrl.sortResults">
            </esk-select-sort>
          </div>
        </div>
      </div>

      <!-- Modes de vue -->
      <div class="col-sm-2 col-md-3 col-lg-3 tw-text-center">
        <app-dossiers-view-choice [route]="$ctrl.filterRoute" [code]="$ctrl.filterCode"
                                  (changed)="$ctrl.changeDisplayMode($event)"></app-dossiers-view-choice>
      </div>

      <!-- Actions -->
      <div class="col-sm-5 col-md-5 col-lg-4 text-right actions">
        <a class="btn btn-azure btn-o" ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/dashboard')">
          <span class="text-large"><fa-icon [icon]="'reply-all'" flip="vertical"></fa-icon></span>
          Revenir au tableau de bord
        </a>

        <ng-switch on="$ctrl.options.loader.creating" class="action-search">
          <span ng-switch-when="true" class="preparing margin-left-20">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Préparation des fiches ...
          </span>

          <div ng-switch-default class="btn-group" uib-dropdown>
            <button type="button" class="btn btn-primary"
                    ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/search/criteres', {search: $ctrl.search})">
              <fa-icon [icon]="'sliders-h'"></fa-icon> <span class="hidden-xs">Critères de recherche</span>
            </button>

            <button type="button" class="btn btn-primary" uib-dropdown-toggle><span class="caret"></span></button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
              <li role="menuitem">
                <button type="button" class="tw-dropdown-link" ng-click="$ctrl.createDemandeur()">
                  <fa-icon [icon]="'user'"></fa-icon><span>Nouvelle demande</span>
                </button>
              </li>
            </ul>
          </div>
        </ng-switch>
      </div>

      <!-- No results -->
      <h4 ng-if="$ctrl.dossierBiens.totalItems <= 0" class="col-sm-12 margin-top-30 tw-text-center">
        Aucun bien en {{$ctrl.typeDossier}} à afficher actuellement.
      </h4>
    </div>

    <!-- Liste des locations -->
    <esk-locations-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.LOCATION" filter="$ctrl.filter" locations="$ctrl.dossierBiens"
                        unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-locations-list>

    <!-- Liste des ventes -->
    <esk-ventes-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.VENTE" filter="$ctrl.filter" ventes="$ctrl.dossierBiens"
                     unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-ventes-list>

    <!-- Pagination -->
    <div class="row">
      <esk-pagination paginator="$ctrl.dossierBiens" loader="$ctrl.options.loader"
                      on-change="$ctrl.onChangePagination"
                      ng-hide="$ctrl.options.loader.updating" class="col-sm-12 tw-text-center"></esk-pagination>
    </div>

    <!-- Barre de faire -->
    <div ng-if="$ctrl.getSelectedDossierBiens().length > 0" class="barre-faire">
      <div class="dossiers-select-label">
        {{'bien.grouped_action.selection.MESSAGE' | translate:'{nbDossiers: ' + $ctrl.getSelectedDossierBiens().length + '}'}}
      </div>

      <div ng-switch="!!$ctrl.barreFaireLoader" class="dossiers-select-actions">
        <p ng-switch-when="true" class="loading text-large">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.barreFaireLoader | translate}}
        </p>

        <div ng-switch-default class="actions">
          <button type="button" class="btn" ng-click="$ctrl.send()">
            <fa-icon [icon]="['far', 'envelope']"></fa-icon>

            <span class="btn-label">
              {{'files.send_cards' | translate: '{nbCards: ' + $ctrl.getSelectedDossierBiens().length + '}'}}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
