import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
    AppContactsGroupConvivialNameComponent
} from '@features/contacts-group/convivial-name/contacts-group.convivial-name.component';
import {SharedModule} from '@shared/shared.module';
import {
    AppContactsGroupNumberContactsComponent
} from '@features/contacts-group/number-contacts/contacts-group.number-contacts.component';

@NgModule({
    declarations: [AppContactsGroupConvivialNameComponent, AppContactsGroupNumberContactsComponent],
    exports: [AppContactsGroupConvivialNameComponent, AppContactsGroupNumberContactsComponent],
    imports: [CommonModule, SharedModule],
})
export class ContactsGroupModule {
}
