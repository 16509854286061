import angularJS from '@shared/angularJS/global.ng';
import {filter, find} from "lodash";
import {IModule} from 'angular';
import {NgBoxCollection, NgEskBoxDico} from '@legacy/app/eskimmo/eskimmo';
import {UserService} from '@models/users/user/user.service';
import {map, tap} from 'rxjs/operators';
import {NgSortManager} from '@legacy/app/managers/managers';
import {firstValueFrom, switchMap} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserClientMetadataService} from '@core/models/users/user/client-metadata/user-client-metadata.service';
import {
    IUserClientMetadataConfigDashboardBox
} from '@models/users/user/client-metadata/user-client-metadata.interfaces';
import DateFormat from '@shared/date/date.format';

export default function getEskBox(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('EskBox', Service);

        /**
         * EskBox
         *
         * @param SortManager
         * @param Ng2EtudeService
         * @param Ng2UserService
         * @param Ng2UserClientMetadataService
         */
        Service.$inject = ['SortManager', 'Ng2EtudeService', 'Ng2UserService', 'Ng2UserClientMetadataService'];
        function Service(this: any,
                         sortManager: NgSortManager,
                         ng2EtudeService: EtudeService,
                         ng2UserService: UserService,
                         ng2UserClientMetadataService: UserClientMetadataService) {
            const self = this;
            let boxes: NgBoxCollection = {collection: []};
            let listNbDisplayed: { code: number, name: string }[];
            let listMandatsEcheance: NgEskBoxDico[];

            self.init$ = init$;
            self.getListNbDisplayed = getListNbDisplayed;
            self.getListMandatsEcheance = getListMandatsEcheance;
            self.getAll = getAll;
            self.isAllBoxesClosed = isAllBoxesClosed;
            self.getOneByCode = getOneByCode;
            self.toggleExpansion = toggleExpansion;
            self.toggleVisibility = toggleVisibility;
            self.saveConfig = saveConfig;

            /**
             * Get list nbDisplayed
             *
             * @return {Array}
             */
            function init$() {
                return ng2UserService.last$.pipe(
                    map(currentUser => {
                        const originalBoxes = [] as unknown[];

                        if (currentUser.hasRoleGrpEstimation()) {
                            originalBoxes.push({
                                code: 'estimations-remis',
                                name: "Estimations à suivre",
                                templateUrl: 'src/app/legacy/templates/estimations/components/box.remis/container.html',
                            });
                        }

                        if (currentUser.hasRoleGrpLocation()) {
                            originalBoxes.push({
                                code: 'locations-last',
                                name: "Derniers biens en location",
                                templateUrl: 'src/app/legacy/templates/locations/components/box/last/container.html',
                            });
                        }

                        if (currentUser.hasRoleGrpVente()) {
                            originalBoxes.push({
                                code: 'etude-activity',
                                name: "Activité du portefeuille de l'étude",
                                templateUrl: 'src/app/legacy/templates/eskimmo/components/box/activity/container.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-mandats-expiring',
                                name: "Biens avec mandat arrivant à expiration",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/mandats/expiring.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-mandats-expired',
                                name: "Biens avec mandat expiré",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/mandats/expired.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-mandats-without',
                                name: "Biens sans date de mandat",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/mandats/without.html',
                            });
                            originalBoxes.push({
                                code: 'etude-negociation-revenus',
                                name: "Chiffre d'affaires du service négociation",
                                templateUrl: 'src/app/legacy/templates/eskimmo/components/box/negociation-revenus/container.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-negociees',
                                name: "Comparatif des ventes de l'étude",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/negociees/container.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-mandats-natures',
                                name: "Répartition par nature des biens à vendre avec mandat de l'étude",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/mandats.natures/container.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-negociees-natures',
                                name: "Répartition par nature des ventes de l'étude",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/negociees/natures/container.html',
                            });
                        }

                        if (currentUser.hasRoleDemandeurVente() || currentUser.hasRoleGrpVente()) {
                            originalBoxes.push({
                                code: 'ventes-network-updated',
                                name: "Biens en vente modifiés",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/network-updated/container.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-last',
                                name: "Derniers biens en vente",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/last/container.html',
                            });
                            originalBoxes.push({
                                code: 'ventes-search',
                                name: "Recherche rapide de biens en vente",
                                templateUrl: 'src/app/legacy/templates/ventes/components/box/search/container.html',
                            });
                        }

                        if (currentUser.hasRoleGrpLocation() || currentUser.hasRoleGrpVente()) {
                            originalBoxes.push({
                                code: 'meteo',
                                name: "Météo",
                                templateUrl: 'src/app/legacy/templates/eskimmo/components/box/meteo/container.html',
                            });
                            originalBoxes.push({
                                code: 'shortcuts',
                                name: "Raccourcis",
                                templateUrl: 'src/app/legacy/templates/eskimmo/components/box/shortcuts.html',
                            });
                        }

                        listNbDisplayed = [
                            {code: 5, name: '5'},
                            {code: 10, name: '10'},
                            {code: 15, name: '15'},
                            {code: 20, name: '20'},
                        ];
                        listMandatsEcheance = [
                            {code: 0, name: "Mandats expirés"},
                            {code: 15, name: "Mandats expirants dans 15 jours"},
                            {code: 30, name: "Mandats expirants dans 30 jours"},
                            {code: 45, name: "Mandats expirants dans 45 jours"},
                        ];
                        boxes = {collection: []};

                        // Boxes initialisation
                        angular.forEach(originalBoxes, (originalBox: IUserClientMetadataConfigDashboardBox) => {
                            let savedBox: IUserClientMetadataConfigDashboardBox = undefined!;

                            if (angular.isObject(currentUser.clientMetadata.boxes) && angular.isArray(currentUser.clientMetadata.boxes.collection)) {
                                savedBox = find(currentUser.clientMetadata.boxes.collection, {code: originalBox.code})!;
                            }

                            savedBox = angular.isObject(savedBox) ? savedBox : originalBox;
                            savedBox.name = originalBox.name;
                            savedBox.templateUrl = originalBox.templateUrl;
                            savedBox.badge = {};
                            savedBox.expanded = savedBox.expanded !== false;
                            boxes.collection.push(savedBox);
                        });
                    }),
                    switchMap(_ => ng2EtudeService.last$),
                    tap(_ => {
                        const box = self.getOneByCode('ventes-search');

                        if (!box) {
                            return;
                        }

                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.byReference = angular.isObject(box.config.byReference) ? box.config.byReference : {};
                        box.config.byReference.withCRPCEN = box.config.byReference.withCRPCEN !== false;
                    }),
                    tap(currentEtude => {
                        const box = self.getOneByCode('ventes-last');

                        if (!box) {
                            return;
                        }

                        box.name = "Derniers biens en vente" + (currentEtude.hasNetwork() ? " du réseau" : "");
                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.preciseLocalisation = box.config.preciseLocalisation === true;
                    }),
                    tap(_ => {
                        const box = self.getOneByCode('ventes-mandats-expired');

                        if (!box) {
                            return;
                        }

                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.nbDisplayed = angular.isObject(box.config.nbDisplayed) ? box.config.nbDisplayed : listNbDisplayed[0];
                        box.config.sort = angular.isObject(box.config.sort) ? box.config.sort : sortManager.getList('BOX_' + box.code)[0];
                    }),
                    tap(_ => {
                        const box = self.getOneByCode('ventes-mandats-expiring');

                        if (!box) {
                            return;
                        }

                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.nbDisplayed = angular.isObject(box.config.nbDisplayed) ? box.config.nbDisplayed : listNbDisplayed[0];
                        box.config.sort = angular.isObject(box.config.sort) ? box.config.sort : sortManager.getList('BOX_' + box.code)[0];
                        box.config.mandatsEcheance = angular.isObject(box.config.mandatsEcheance) ? box.config.mandatsEcheance : filter(listMandatsEcheance, {code: 30})[0];
                    }),
                    tap(_ => {
                        const box = self.getOneByCode('ventes-mandats-without');

                        if (!box) {
                            return;
                        }

                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.nbDisplayed = angular.isObject(box.config.nbDisplayed) ? box.config.nbDisplayed : listNbDisplayed[0];
                        box.config.sort = angular.isObject(box.config.sort) ? box.config.sort : sortManager.getList('BOX_' + box.code)[0];
                    }),
                    tap(_ => {
                        const box = self.getOneByCode('ventes-negociees');

                        if (!box) {
                            return;
                        }

                        box.name = "Comparatif des ventes de l'étude pour " + DateFormat.getYear(DateFormat.dateFromNow());
                    }),
                    tap(_ => {
                        const box = self.getOneByCode('ventes-negociees-natures');

                        if (!box) {
                            return;
                        }

                        box.name = "Répartition par nature des ventes de l'étude pour " + DateFormat.getYear(DateFormat.dateFromNow());
                    }),
                    tap(_ => {
                        const box = self.getOneByCode('estimations-remis');

                        if (!box) {
                            return;
                        }

                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.nbDisplayed = angular.isObject(box.config.nbDisplayed) ? box.config.nbDisplayed : listNbDisplayed[0];
                        box.config.sort = angular.isObject(box.config.sort) ? box.config.sort : sortManager.getList('BOX_' + box.code)[0];
                    }),
                    tap(currentEtude => {
                        const box = self.getOneByCode('ventes-network-updated');

                        if (!box) {
                            return;
                        }

                        box.name = "Biens en vente modifiés" + (currentEtude.hasNetwork() ? " du réseau" : "");
                    }),
                    tap(currentEtude => {
                        const box = self.getOneByCode('locations-last');

                        if (!box) {
                            return;
                        }

                        box.name = "Derniers biens en location" + (currentEtude.hasNetwork() ? " du réseau" : "");
                        box.config = angular.isObject(box.config) ? box.config : {};
                        box.config.preciseLocalisation = box.config.preciseLocalisation === true;
                    }),
                    map(_ => {
                    }),
                );
            }

            /**
             * Get list nbDisplayed
             *
             * @return {Array}
             */
            function getListNbDisplayed() {
                return angular.copy(listNbDisplayed);
            }

            /**
             * Get list mandatsEcheance
             *
             * @return {Array}
             */
            function getListMandatsEcheance() {
                return angular.copy(listMandatsEcheance);
            }

            /**
             * Get boxes
             *
             * @return Object
             */
            function getAll() {
                return boxes;
            }

            /**
             * Return boolean to know if all Boxes are closed
             */
            function isAllBoxesClosed() {
                const boxes = self.getAll();

                return filter(boxes.collection, {closed: true}).length === boxes.collection.length;
            }

            /**
             * Get one by code
             *
             * @param code
             * @return box
             */
            function getOneByCode(code: string) {
                return find(boxes.collection, {code: code});
            }

            /**
             * Toggle visibility of box
             *
             * @param box
             * @return Promise
             */
            function toggleExpansion(box: IUserClientMetadataConfigDashboardBox) {
                box.expanded = !box.expanded;

                return self.saveConfig();
            }

            /**
             * Toggle visibility of box
             *
             * @param box
             * @return Promise
             */
            function toggleVisibility(box: IUserClientMetadataConfigDashboardBox) {
                box.closed = !box.closed;
                self.isAllBoxesClosed();

                return self.saveConfig();
            }

            /**
             * Save config of boxes
             *
             * @return Promise
             */
            function saveConfig() {
                return firstValueFrom(ng2UserService.last$.pipe(
                    tap(currentUser => {
                        currentUser.clientMetadata.boxes = angular.copy(self.getAll());
                        angular.forEach(currentUser.clientMetadata.boxes.collection, box => box.refresh = undefined!);
                    }),
                    switchMap(currentUser => ng2UserClientMetadataService.saveFromUser$(currentUser)),
                ));
            }
        }
    })(angularJS);
}
