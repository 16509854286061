import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {BienLogementFactory} from '@models/bien/logements/logement/bien-logement.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BienLogementsService {
    private _bienLogementFactory = inject(BienLogementFactory);

    save$(dossierBien: ADossierBien): Observable<void> {
        if (dossierBien.bien.logements.length <= 0) {
            return of(undefined);
        }

        return combineLatest(dossierBien.bien.logements.map(bienLogement => this._bienLogementFactory.save$(dossierBien, bienLogement)))
            .pipe(map(() => undefined));
    }
}
