import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgCommuneManager} from '@legacy/app/managers/managers';
import {NgCommune} from '@legacy/app/managers/ressources';

export default function getEskSelectCommune(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-commune></esk-select-commune>
         * @param options: {codePostalSelection: (boolean), multiple: (boolean), razOnSelect: (boolean)}
         */
        module.component('eskSelectCommune', {
            bindings: {
                ngModel: '=',
                isRequired: '=',
                placeholder: '@',
                name: '@',
                onSelectCallback: '=onSelect',
                options: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/commune.html'
        });

        /**
         * Select communes
         *
         * @param communeManager
         * @constructor
         */
        Controller.$inject = ['CommuneManager'];
        function Controller(this: any, communeManager: NgCommuneManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.refresh = refresh;
            $ctrl.onSelect = onSelect;
            $ctrl.displayCommune = displayCommune;

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};

                $ctrl.communes = [];
                $ctrl.placeholder = angular.isDefined($ctrl.placeholder) ? $ctrl.placeholder : "Rechercher une commune";
                $ctrl.ngRequired = $ctrl.isRequired === true ? true : null;
            }

            /**
             * Refresh communes
             *
             * @param keywords
             */
            function refresh(keywords: string) {
                $ctrl.communes = [];

                return communeManager.getAll({keywords}).then(function (communes) {
                    $ctrl.communes = communes.collection;
                    if ($ctrl.options.codePostalSelection && communes.totalItems > 0 && /\d{5}/.test(keywords)) {
                        $ctrl.communes.unshift({
                            communesCollection: angular.copy(communes.collection),
                            fullname: keywords + " - Toutes les communes associées",
                        });
                    }
                });
            }

            /**
             * On select
             *
             * @param $item
             * @param $model
             */
            function onSelect($item: { communesCollection: unknown[] }, $model: unknown) {
                let returnCallBack;

                if (angular.isObject($item) && angular.isArray($item.communesCollection)) {
                    $ctrl.ngModel = $ctrl.ngModel.filter((commune: { communesCollection: unknown[] }) => !angular.isArray(commune.communesCollection));
                    $ctrl.ngModel = $ctrl.ngModel.concat($item.communesCollection);
                }

                if (angular.isArray($ctrl.ngModel)) {
                    $ctrl.ngModel = $ctrl.ngModel.filter((commune: NgCommune, indexFilter: number) => {
                        return $ctrl.ngModel.findIndex((communeModel: NgCommune, indexFindIndex: number) => {
                            return communeModel.id === commune.id && indexFilter > indexFindIndex;
                        }) < 0;
                    });
                }

                if (angular.isFunction($ctrl.onSelectCallback)) {
                    returnCallBack = $ctrl.onSelectCallback($item, $model);
                }

                if ($ctrl.options.razOnSelect) $ctrl.communes = [];

                return returnCallBack;
            }

            /**
             * Display commune
             *
             * @param $item
             * @param $selected
             * @returns {string}
             */
            function displayCommune($item: { communesCollection: unknown[] }, $selected: NgCommune) {
                const element = $ctrl.options.multiple ? $item : $selected;

                if (!angular.isObject(element) || angular.isArray((element as { communesCollection: unknown[] }).communesCollection)) return;

                return (element as NgCommune).fullname + " (" + ((element as NgCommune)?.codesPostaux?.length === 1 ? (element as NgCommune).codesPostaux[0] : "Tous codes postaux") + ")";
            }
        }
    })(angularJS);
}
