import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {
    IProcedureSignataireApi
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.interfaces';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {
    ICProcedureSignatairesApi
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';
import {map, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProcedureSignatairesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(procedureUuid: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$('/procedures/' + procedureUuid + '/signataires/' + uuid);
    }

    get$(procedureUuid: string, uuid: string): Observable<IProcedureSignataireApi> {
        return this._modelApiService.get$('/procedures/' + procedureUuid + '/signataires/' + uuid);
    }

    getCollection$(procedureUuid: string): Observable<ICProcedureSignatairesApi> {
        return this.getCollectionByLink$('/procedures/' + procedureUuid + '/signataires');
    }

    getCollectionByLink$(link: string): Observable<ICProcedureSignatairesApi> {
        return this._modelApiService.getCollection$(link);
    }

    save$(procedureUuid: string, uuid: string, procedureSignataireApi: IProcedureSignataireApi, httpOptions?: HttpOptions): Observable<IProcedureSignataireApi> {
        if (uuid === ProcedureSignataire.statuts.NEW) {
            return this._modelApiService.post$('/procedures/' + procedureUuid + '/signataires', procedureSignataireApi, httpOptions);
        }

        return this._modelApiService.put$('/procedures/' + procedureUuid + '/signataires/' + uuid, procedureSignataireApi, httpOptions)
            .pipe(switchMap(_ => this.get$(procedureUuid, uuid)));
    }

    sign$(procedureUuid: string, uuid: string, signature: string): Observable<void> {
        return this._modelApiService.patch$<{image: string}>('/procedures/' + procedureUuid + '/signataires/' + uuid + '/sign', {image: signature})
            .pipe(map(_ => undefined!));
    }
}
