import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    IEstimationRapportActions, IEstimationRapportDropdownData, IEstimationRapportDropdownDataOptions
} from '@features/estimations/estimations.interfaces';

@Component({selector: 'estimation-rapport-dropdown', templateUrl: 'estimation-rapport.dropdown.component.html'})
export class EstimationRapportDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        GENERATE: 'generate',
        PRINT: 'print',
        SAVE: 'save',
        SEND: 'send',
    };
    static readonly initEstimationRapportActions: IEstimationRapportActions = {
        generate: false,
        print: false,
        save: false,
        send: false,
    };
    private _dropdownService = inject(DropdownService);
    private _options: IEstimationRapportDropdownDataOptions = {...{enabledActions: EstimationRapportDropdownComponent.initEstimationRapportActions}};

    @Input()
    set data(value: IEstimationRapportDropdownData) {
        this._options = {...{enabledActions: EstimationRapportDropdownComponent.initEstimationRapportActions}, ...value.options};
    }

    get options(): IEstimationRapportDropdownDataOptions {
        return this._options;
    }

    generate(): void {
        this._dropdownService.clicked(EstimationRapportDropdownComponent.actions.GENERATE);
    }

    print(): void {
        this._dropdownService.clicked(EstimationRapportDropdownComponent.actions.PRINT);
    }

    save(): void {
        this._dropdownService.clicked(EstimationRapportDropdownComponent.actions.SAVE);
    }

    send(): void {
        this._dropdownService.clicked(EstimationRapportDropdownComponent.actions.SEND);
    }
}
