import {inject, Injectable} from '@angular/core';
import User from '@models/users/user/user.model';
import {Observable} from 'rxjs';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {map} from 'rxjs/operators';
import {IUserApi} from '@models/users/user/user.interfaces';
import {NgUser} from '@legacy/app/managers/ressources';
import {UsersApiService} from '@models/users/users.api.service';
import {SiteFactory} from '@models/sites/site/site.factory';
import {CPhonesFactory} from '@models/phones/collection/phones.collection.factory';
import {ICPhonesApi} from '@models/phones/collection/phones.collection.interfaces';
import {UserPhotoFactory} from '@models/users/user/photo/user-photo.factory';
import {PhoneFactory} from '@models/phones/phone/phone.factory';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Injectable({providedIn: 'root'})
export class UserFactory {
    private _cPhonesFactory = inject(CPhonesFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _phoneFactory = inject(PhoneFactory);
    private _siteFactory = inject(SiteFactory);
    private _userPhotoFactory = inject(UserPhotoFactory);
    private _usersApiService = inject(UsersApiService);

    askInfosModule$(module: string): Observable<void> {
        return this._usersApiService.askInfosModule$({module});
    }

    changePassword$(user: User, password: string): Observable<void> {
        return this._usersApiService.changePassword$(user.id.toString(), {password});
    }

    create(userApi: IUserApi): User {
        const user = this.createVirgin(userApi.uuid, userApi.id);
        let linkPhoto: string = undefined!;

        user.emailAddress = userApi.emailAddress;
        user.emailSignature = userApi.emailSignature;
        user.initiales = userApi.initiales;
        user.nom = userApi.nom;
        user.prenom = userApi.prenom;
        user.roles = userApi.reachableRoles;
        user.sexe = userApi.sexe;
        user.username = userApi.username;
        if (userApi._embedded) {
            if (userApi._embedded.etude) {
                user.etudeId = userApi._embedded.etude.id;
            }

            if (userApi._embedded.photo) {
                user.photo = this._userPhotoFactory.create(userApi._embedded.photo);
            }

            if (userApi._embedded.site) {
                user.site = this._siteFactory.create(userApi._embedded.site);
            }

            if (userApi._embedded.telephones) {
                const cPhonesApi = {
                    _embedded: {items: userApi._embedded.telephones},
                    total: userApi._embedded.telephones.length,
                } as unknown as ICPhonesApi;

                user.cPhones = this._cPhonesFactory.create(cPhonesApi);
            }
        }

        if (userApi._links) {
            if (userApi._links.clientMetadata) {
                user.linkClientMetadata = userApi._links.clientMetadata.href;
            }

            if (userApi._links.etude) {
                user.linkEtude = userApi._links.etude.href;
            }

            if (userApi._links.photo) {
                linkPhoto = userApi._links.photo.href;
            }

            if (userApi._links.self) {
                user.linkSelf = userApi._links.self.href;
            }

            if (userApi._links.site) {
                user.linkSite = userApi._links.site.href;
            }

            if (userApi._links.ventes) {
                user.linkVentes = userApi._links.ventes.href;
            }
        }

        if (userApi.titre) {
            user.titre = this._dictionaryItemService.getByCode(Dictionary.names.TITRES, userApi.titre, true);
        }

        // "profil" doit être saisi après "sexe"
        user.profils = userApi.profils;
        user.setLinkPhoto(linkPhoto);

        return user;
    }

    createVirgin(uuid?: string, id?: number): User {
        const user = new User(uuid ?? User.statuts.NEW, id);

        user.setLinkPhoto();

        return user;
    }

    forApi(user: User): IUserApi {
        const userApi = {} as IUserApi;

        userApi.emailAddress = user.emailAddress;
        userApi.nom = user.nom;
        userApi.prenom = user.prenom;
        userApi.sexe = user.sexe;
        userApi.telephones = user.cPhones.results.filter(phone => phone.numero).map(phone => this._phoneFactory.forApi(phone));
        userApi.titre = user.titre?.code;

        return userApi;
    }

    get$(uuid: string): Observable<User> {
        return this._usersApiService.get$(uuid).pipe(map(userApi => this.create(userApi)));
    }

    getByLink$(link: string): Observable<User> {
        return this._usersApiService.getByLink$(link).pipe(map(userApi => this.create(userApi)));
    }

    getCurrent$(): Observable<User> {
        return this._usersApiService.getCurrent$().pipe(map(userApi => this.create(userApi)));
    }

    requestPassword$(username: string): Observable<void> {
        return this._usersApiService.requestPassword$({username});
    }

    save$(user: User): Observable<User> {
        return this._usersApiService.save$(user.id.toString(), this.forApi(user)).pipe(map(userApi => this.create(userApi)));
    }

    ngCreate(ngUser: NgUser): User {
        const user = this.createVirgin(ngUser.uuid, ngUser.id);
        let linkPhoto: string = undefined!;

        user.emailAddress = ngUser.emailAddress;
        user.emailSignature = ngUser.emailSignature;
        user.nom = ngUser.nom;
        user.prenom = ngUser.prenom;
        user.sexe = ngUser.sexe;
        user.username = ngUser.username;
        if (ngUser._links) {
            if (ngUser._links.etude) {
                user.linkEtude = ngUser._links.etude.href;
            }

            if (ngUser._links.photo) {
                linkPhoto = ngUser._links.photo.href;
            }

            if (ngUser._links.self) {
                user.linkSelf = ngUser._links.self.href;
            }
        }

        if (ngUser.etude) {
            user.etudeId = ngUser.etude.id;
        }

        if (ngUser.telephones) {
            const cPhonesApi = {
                _embedded: {items: ngUser.telephones},
                total: ngUser.telephones.length,
            } as unknown as ICPhonesApi;

            user.cPhones = this._cPhonesFactory.create(cPhonesApi);
        }

        if (ngUser.titre) {
            user.titre = this._dictionaryItemFactory.ngCreate(ngUser.titre);
        }

        user.setLinkPhoto(linkPhoto);

        return user;
    }
}
