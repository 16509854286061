import {inject, Injectable} from '@angular/core';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import {ILocationPasserelleApi} from '@models/locations/location/passerelles/passerelle/location-passerelle.interfaces';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LocationPasserellesApiService} from '@models/locations/location/passerelles/location-passerelles.api.service';
import Passerelle from '@models/passerelle/passerelle.model';
import Location from '@models/locations/location/location.model';
import {HttpOptions} from '@core/api/api.interfaces';
import {TextsService} from '@shared/texts/texts.service';

@Injectable({providedIn: 'root'})
export class LocationPasserelleFactory {
    private _locationPasserellesApiService = inject(LocationPasserellesApiService);
    private _textsService = inject(TextsService);

    create(locationPasserelleApi: ILocationPasserelleApi): LocationPasserelle {
        const locationPasserelle = this.createVirgin(locationPasserelleApi.passerelleId, locationPasserelleApi.locationId);

        locationPasserelle.afficherCommune = locationPasserelleApi.afficherCommune;
        locationPasserelle.afficherDpe = locationPasserelleApi.afficherDpe;
        locationPasserelle.communeId = locationPasserelleApi.communeId;
        locationPasserelle.createdAt = locationPasserelleApi.createdAt;
        locationPasserelle.dateDiffusion = locationPasserelleApi.dateDiffusion;
        locationPasserelle.dateRetrait = locationPasserelleApi.dateRetrait;
        locationPasserelle.descriptif = locationPasserelleApi.descriptif;
        locationPasserelle.diffused = locationPasserelleApi.diffused;
        locationPasserelle.natureExternalType = locationPasserelleApi.natureExternalType;
        locationPasserelle.natureExternalTypeId = locationPasserelleApi.natureExternalTypeId;
        locationPasserelle.updatedAt = locationPasserelleApi.updatedAt;
        if (locationPasserelleApi._links) {
            if (locationPasserelleApi._links.passerelle) {
                locationPasserelle.linkPasserelle = locationPasserelleApi._links.passerelle.href;
            }

            if (locationPasserelleApi._links.location) {
                locationPasserelle.linkLocation = locationPasserelleApi._links.location.href;
            }

            if (locationPasserelleApi._links.self) {
                locationPasserelle.linkSelf = locationPasserelleApi._links.self.href;
            }
        }

        return locationPasserelle;
    }

    createVirgin(passerelleId: number, locationId: number): LocationPasserelle {
        return new LocationPasserelle(this._textsService.uuid(), passerelleId, locationId);
    }

    diffuser$(locationPasserelle: LocationPasserelle): Observable<void> {
        return this._locationPasserellesApiService.diffuser$(locationPasserelle.locationId.toString(), locationPasserelle.passerelleId.toString()).pipe(
            tap(_ => locationPasserelle.setDiffuser()),
        );
    }

    forApi(locationPasserelle: LocationPasserelle): ILocationPasserelleApi {
        const locationPasserelleApi = {} as ILocationPasserelleApi;

        locationPasserelleApi.afficherCommune = locationPasserelle.afficherCommune;
        locationPasserelleApi.afficherDpe = locationPasserelle.afficherDpe;
        locationPasserelleApi.communeId = locationPasserelle.communeId;
        locationPasserelleApi.descriptif = locationPasserelle.descriptif;
        locationPasserelleApi.natureExternalType = locationPasserelle.natureExternalType;
        locationPasserelleApi.natureExternalTypeId = locationPasserelle.natureExternalTypeId;

        return locationPasserelleApi;
    }

    get$(location: Location, passerelle: Passerelle, httpOptions?: HttpOptions): Observable<LocationPasserelle> {
        return this._locationPasserellesApiService.get$(location.id.toString(), passerelle.id.toString(), httpOptions).pipe(map(locationPasserelleApi => this.create(locationPasserelleApi)));
    }

    getByLink$(link: string): Observable<LocationPasserelle> {
        return this._locationPasserellesApiService.getByLink$(link).pipe(map(locationPasserelleApi => this.create(locationPasserelleApi)));
    }

    retirer$(locationPasserelle: LocationPasserelle): Observable<void> {
        return this._locationPasserellesApiService.retirer$(locationPasserelle.locationId.toString(), locationPasserelle.passerelleId.toString()).pipe(
            map(_ => locationPasserelle.setRetirer()),
        );
    }

    save$(locationPasserelle: LocationPasserelle): Observable<LocationPasserelle> {
        return this._locationPasserellesApiService.save$(locationPasserelle.locationId.toString(), locationPasserelle.passerelleId.toString(), this.forApi(locationPasserelle))
            .pipe(map(locationPasserelleApi => this.create(locationPasserelleApi)));
    }
}
