import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import CCommuneVoies from '@models/communes/commune/voies/collection/commune-voies.collection.model';
import {CCommuneVoiesFactory} from '@models/communes/commune/voies/collection/commune-voies.collection.factory';

@Injectable({providedIn: 'root'})
export class CCommuneVoiesService {
    private _cCommuneVoiesFactory = inject(CCommuneVoiesFactory);

    search$(communeUuid: string, keyword?: string): Observable<CCommuneVoies> {
        return this._cCommuneVoiesFactory.get$(communeUuid, {keywords: keyword});
    }
}
