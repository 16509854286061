export default `
<ng-switch on="$ctrl.infiniteScroll">
  <ng-switch on="$ctrl.paginator.pages === $ctrl.paginator.current" ng-switch-when="true">
    <button type="button" class="btn btn-default" ng-switch-when="false" ng-click="$ctrl.nextPage(false)">
      {{$ctrl.infiniteScrollBtnText ? $ctrl.infiniteScrollBtnText : "Charger plus" | translate}}
    </button>

    <span ng-switch-default>{{"Tous les résultats sont affichés." | translate}}</span>
  </ng-switch>

  <ul uib-pagination ng-switch-default ng-if="$ctrl.paginator.pages > 1" class="pagination-sm"
      total-items="$ctrl.paginator.totalItems" items-per-page="$ctrl.paginator.pageSize"
      ng-model="$ctrl.paginator.current" max-size="5" boundary-link-numbers="true" direction-links="false"
      ng-change="$ctrl.changeCurrentPage()"></ul>
</ng-switch>
`;
