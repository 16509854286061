import {NgModule, Type} from '@angular/core';
import {
    NgSoqPopoverTemplateComponent, NgSoqPopoverTemplateDirective, NgSoqPopoverTextComponent, NgSoqPopoverTextDirective
} from '@shared/angularJS/up-ng/soqrate/soqrate.popover.components';

const components: Type<unknown>[] = [NgSoqPopoverTemplateComponent, NgSoqPopoverTextComponent];
const directives: Type<unknown>[] = [NgSoqPopoverTemplateDirective, NgSoqPopoverTextDirective];

@NgModule({declarations: [...components, ...directives], exports: components})
export class NoteoComponentsModule {
}
