import {NgModule} from '@angular/core';
import {AppNavigationLateralComponent} from '@shared/navigation/lateral/navigation-lateral.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FaModule} from '@shared/fontawesome/fa.module';
import {ClickAwayModule} from '@shared/click-away/click-away.module';
import {AppNavigationToggleComponent} from '@shared/navigation/toggle/navigation-toggle.component';
import {LoadModule} from '@shared/load/load.module';

@NgModule({
    declarations: [AppNavigationLateralComponent, AppNavigationToggleComponent],
    exports: [AppNavigationLateralComponent, AppNavigationToggleComponent],
    imports: [ClickAwayModule, CommonModule, FaModule, LoadModule, NgOptimizedImage, RouterModule],
})
export class NavigationModule {
}
