class ConsommationV1Colors {
    static readonly A = '#319834';
    static readonly B = '#33cc31';
    static readonly C = '#cbfc34';
    static readonly D = '#fbfe06';
    static readonly E = '#fbcc05';
    static readonly F = '#fc9935';
    static readonly G = '#fc0205';
}

class ConsommationV2Colors {
    static readonly A = '#00a06d';
    static readonly B = '#52b153';
    static readonly C = '#a5cc74';
    static readonly D = '#f4e70f';
    static readonly E = '#f0b40f';
    static readonly F = '#eb8235';
    static readonly G = '#d7221f';
}

class EmissionsV1Colors {
    static readonly A = '#f2eff4';
    static readonly B = '#dfc1f7';
    static readonly C = '#d6aaf4';
    static readonly D = '#cc93f4';
    static readonly E = '#bb72f3';
    static readonly F = '#a94cee';
    static readonly G = '#8b1ae1';
}

class EmissionsV2Colors {
    static readonly A = '#a4dbf8';
    static readonly B = '#8cb4d3';
    static readonly C = '#7792b1';
    static readonly D = '#606f8f';
    static readonly E = '#4d5271';
    static readonly F = '#393551';
    static readonly G = '#281b35';
}

class DiagnosticLetters {
    static readonly A = 'A';
    static readonly B = 'B';
    static readonly C = 'C';
    static readonly D = 'D';
    static readonly E = 'E';
    static readonly F = 'F';
    static readonly G = 'G';
}

class DiagnosticStatuts {
    static readonly DISPONIBLE = 'disponible';
    static readonly NON_SOUMIS = 'non_soumis';
    static readonly BLANC = 'blanc';
    static readonly NON_COMMUNIQUE = 'non_communique';
}

// @todo Voir s'il existe un meilleur moyen pour le typage ?
export const ConsommationV1ColorsConst = ConsommationV1Colors as unknown as Record<string, string>;
export const ConsommationV2ColorsConst = ConsommationV2Colors as unknown as Record<string, string>;
export const EmissionsV1ColorsConst = EmissionsV1Colors as unknown as Record<string, string>;
export const EmissionsV2ColorsConst = EmissionsV2Colors as unknown as Record<string, string>;
export const DiagnosticLettersConst = DiagnosticLetters;
export const DiagnosticStatutsConst = DiagnosticStatuts;
