import {Component, inject, OnDestroy, OnInit, Type} from '@angular/core';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Router} from '@angular/router';
import {EditorOptions} from 'tinymce';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import Document from '@models/documents/document/document.model';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {DocumentService} from '@models/documents/document/document.service';
import {
    BonvisiteWriteActionsMainComponent
} from '@features/bonvisites/item/actions/write/bonvisite-write.actions-main.component';
import {IBonvisiteActionsMainData} from '@features/bonvisites/bonvisites.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCProcedureProvidersChoiceComponent
} from '@features/procedures/procedure/providers-choice/procedure.providers-choice.component';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {ToasterService} from '@shared/toaster/toaster.service';
import {MediaResumeService} from '@models/medias/media/media-resume.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

@Component({selector: 'layout-bonvisite-write', templateUrl: 'layout.bonvisite-write.component.html'})
export class AppLayoutBonvisiteWriteComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {WRITE_DISABLED: 'La rédaction de votre bon de visite n\'est pas disponible.'};
    private _bonvisiteService = inject(BonvisiteService);
    private _callToActionService = inject(CallToActionService);
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _emailEditService = inject(EmailEditService);
    private _mediaResumeService = inject(MediaResumeService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _tinymceService = inject(TinymceService);
    private _toasterService = inject(ToasterService);
    private _bonvisitesRoot!: string;
    private _documentSource = new ReplaySubject<Document>(1);
    private _document$ = this._documentSource.asObservable();
    private _editorOptions!: EditorOptions;
    private readonly _onDestroy$ = new Subject<void>();

    get document$(): Observable<Document> {
        return this._document$;
    }

    get editorOptions(): EditorOptions {
        return this._editorOptions;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        this._bonvisitesRoot = '/app/bons-visite';
        if (!this._router.url.startsWith('/app/bons-visite')) {
            this._bonvisitesRoot = this._router.url.split('/bons-visite')[0] + '/bons-visite';
            fallbackPath = this._bonvisitesRoot + '/portefeuille';
        }

        this._bonvisiteService.current$.pipe(filter(bonvisite => !!bonvisite), take(1)).subscribe(bonvisite => {
            if (!bonvisite.isWrite()) {
                this._toasterService.warning(AppLayoutBonvisiteWriteComponent.errorMessages.WRITE_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this._bonvisiteService.current$.pipe(
            filter(bonvisite => !!bonvisite),
            tap(bonvisite => {
                const data: IBonvisiteActionsMainData = {
                    bonvisite,
                    options: {
                        fromDossier: this._router.url.includes(DossierTypesConst.DEMANDEUR)
                            || this._router.url.includes(DossierTypesConst.LOCATION)
                            || this._router.url.includes(DossierTypesConst.VENTE),
                    },
                };

                this._callToActionService.setDynamicComponentLoading({
                    component: BonvisiteWriteActionsMainComponent,
                    data,
                });
            }),
            switchMap(bonvisite => this._documentFactory.getByLink$(bonvisite.linkDocument)),
            switchMap(document => this._documentService.getStylesheetLinks$(document).pipe(
                tap(content_css => this._editorOptions = this._tinymceService.getOptions('complete', {content_css})),
                map(_ => document),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(document => this._documentSource.next(document));
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === BonvisiteWriteActionsMainComponent.actions.ARCHIVE) {
                    return this._callToActionService.actionExec$(this.archiveCurrent$());
                } else if (callToActionClicked.action === BonvisiteWriteActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === BonvisiteWriteActionsMainComponent.actions.SAVE) {
                    return this._callToActionService.actionExec$(this.save$());
                } else if (callToActionClicked.action === BonvisiteWriteActionsMainComponent.actions.SEND) {
                    return this._callToActionService.actionExec$(this.send$());
                } else if (callToActionClicked.action === BonvisiteWriteActionsMainComponent.actions.SIGN) {
                    return this._callToActionService.actionExec$(this.sign$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    archiveCurrent$(): Observable<string> {
        return this._bonvisiteService.archiveCurrent$().pipe(
            switchMap(isArchived => this._bonvisiteService.current$.pipe(
                take(1),
                map(bonvisite => isArchived ? this._bonvisitesRoot + '/' + bonvisite.uuid + '/resume' : undefined!))
            ),
        );
    }

    print$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(document => this._documentService.read$(document)),
            map(_ => undefined!),
        );
    }

    save$(): Observable<string> {
        return this.saveCurrent$().pipe(map(_ => undefined!));
    }

    saveCurrent$(): Observable<Document> {
        return this.document$.pipe(switchMap(document => this._documentFactory.save$(document)));
    }

    send$(): Observable<string> {
        return this._bonvisiteService.current$.pipe(
            take(1),
            switchMap(bonvisite => combineLatest([
                this.saveCurrent$(),
                this._mediaResumeService.getFromBonvisite$(bonvisite).pipe(map(media => [media])),
                this._bonvisiteService.getDossiersCurrent$(),
            ])),
            switchMap(([document, attachments, dossiers]) => this._emailEditService.fromBonvisiteDocument$(document, attachments, dossiers)),
            map(_ => undefined!),
        );
    }

    sign$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(_ => this._modalService.open$(DCProcedureProvidersChoiceComponent as Type<IDynamicComponent>)),
            map(provider => provider as string),
            switchMap(provider => {
                if (!provider) {
                    return of(undefined as unknown as string);
                }

                return this._bonvisiteService.signCurrent$(provider).pipe(
                    map(bonvisite => this._bonvisitesRoot + '/' + bonvisite.uuid + '/signature'),
                );
            }),
        );
    }
}
