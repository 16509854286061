import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgContactsGroupManager, NgLocationLocataireManager, NgLocationManager} from '@legacy/app/managers/managers';
import {BehaviorSubject, firstValueFrom, Subject, switchMap} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import DateFormat from '@shared/date/date.format';
import Location from '@models/locations/location/location.model';
import {LocationFactory} from '@models/locations/location/location.factory';

export default function getLocationGestionCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskLocationGestionCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/locations/controllers/item/gestion.html',
        });

        /**
         * Controller of Gestion Location
         *
         * @param LocationManager
         * @param SoqSweetAlert
         * @param $q
         * @param LocationLocataireManager
         * @param SoqModalesManager
         * @param $translate
         * @param Ng2RouterStateForNgService
         * @param Ng2ContactsGroupFactory
         * @param ContactsGroupManager
         * @param Ng2ContactsGroupService
         * @param Ng2LocationFactory
         */
        Controller.$inject = ['LocationManager', 'SoqSweetAlert', '$q', 'LocationLocataireManager', 'SoqModalesManager', '$translate', 'Ng2RouterStateForNgService', 'Ng2ContactsGroupFactory', 'ContactsGroupManager', 'Ng2ContactsGroupService', 'Ng2LocationFactory'];
        function Controller(this: any,
                            locationManager: NgLocationManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            $q: IQService,
                            locationLocataireManager: NgLocationLocataireManager,
                            soqModalesManager: NgSoqModalesManager,
                            $translate: angular.translate.ITranslateService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2ContactsGroupFactory: ContactsGroupFactory,
                            contactsGroupManager: NgContactsGroupManager,
                            ng2ContactsGroupService: ContactsGroupService,
                            ng2LocationFactory: LocationFactory) {
            const $ctrl = this;
            const _contactsGroupLinkSource = new BehaviorSubject<string>(undefined!);
            const _onDestroy$ = new Subject<void>();

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.$onInit = $onInit;
            $ctrl.available = available;
            $ctrl.onGroupCreated = onGroupCreated;
            $ctrl.onLocataireActuelDateChanged = onLocataireActuelDateChanged;
            $ctrl.onLocataireActuelChanged = onLocataireActuelChanged;
            $ctrl.locataireActuelDisabled = locataireActuelDisabled;
            $ctrl.openEtatsLieux = openEtatsLieux;
            $ctrl.saveLocation = saveLocation;
            $ctrl.STATUTS = Location.statuts;

            function $onInit() {
                $ctrl.location = locationManager.current;
                $ctrl.today = DateFormat.toDate(DateFormat.dateFromNow());
                $ctrl.options = {bailDate: {}, bailDateFin: {}, dateSortie: {}, dateEntree: {}};
                updateLocataires();
                _contactsGroupLinkSource.asObservable().pipe(
                    tap(_ => contactsGroupManager.currentSource.next(undefined!)),
                    switchMap(link => ng2ContactsGroupService.getOneByLink$(link)),
                    takeUntil(_onDestroy$),
                ).subscribe(contactsGroupCurrent => contactsGroupManager.currentSource.next(contactsGroupCurrent));
            }

            /**
             * Make location available
             */
            function available() {
                let promise = $q.resolve();

                if (!$ctrl.location.bien.metadata.occupation.dateDisponibilite) {
                    promise = soqSweetAlert.warningConfirmation($translate.instant("location.gestion.availability.TITLE"), $translate.instant("location.gestion.availability.MESSAGE"), {confirmButtonText: $translate.instant("location.gestion.availability.CONFIRMATION")});
                }

                promise.then(() => $ctrl.saveLocation()).then(() => $ctrl.location.available());
            }

            /**
             * Save location when the ContactsGroup is created
             *
             * @param contactsGroup
             * @returns {*}
             */
            function onGroupCreated(contactsGroup: ContactsGroup) {
                if (contactsGroup.id && !contactsGroup.hasNom()) {
                    return;
                }

                const isNew = contactsGroup.isNew();

                return firstValueFrom(ng2ContactsGroupFactory.save$(contactsGroup))
                    .then(contactsGroupSaved => contactsGroupManager.createFromNg2(contactsGroupSaved))
                    .then(contactsGroup => {
                        $ctrl.locataireActuel.locatairesGroup = contactsGroup;
                        $ctrl.locataireActuel._esk.defaultRouteParams.id = $ctrl.locataireActuel.locatairesGroup.id;
                        if (!contactsGroup.id) {
                            _contactsGroupLinkSource.next(contactsGroup._links.self.href);
                        }
                    })
                    .then(() => $ctrl.onLocataireActuelChanged())
                    .then(() => $ctrl.locataireActuel.locatairesGroup)
                    .then(contactsGroup => {
                        if (isNew) {
                            _onDestroy$.next()
                            $ctrl.$onInit()
                        }

                        return contactsGroup;
                    })
            }

            /**
             * Save and update date options on locataire actuel date bail change
             */
            function onLocataireActuelDateChanged() {
                setOptionsDateBail();
                $ctrl.onLocataireActuelChanged();
            }

            /**
             * Save locataireActuel
             *
             * @returns {*}
             */
            function onLocataireActuelChanged() {
                var newLocataire = !$ctrl.locataireActuel.id;

                if (!angular.isObject($ctrl.locataireActuel.locatairesGroup) || !angular.isNumber($ctrl.locataireActuel.locatairesGroup.id)) {
                    return $q.resolve();
                }

                return $ctrl.locataireActuel.save().then(function () {
                    if (newLocataire) {
                        $ctrl.locataires.unshift($ctrl.locataireActuel);
                    }
                });
            }

            /**
             * Disable locataireActuel
             *
             * @returns {*}
             */
            function locataireActuelDisabled() {
                $ctrl.locataireActuel.enabled = false;

                return $ctrl.onLocataireActuelChanged().then(updateLocataires);
            }

            /**
             * Open modal for etats lieux
             *
             * @param locataire
             */
            function openEtatsLieux(locataire: Object) {
                soqModalesManager.open('LocationModalEditEtatsLieux', {resolve: {locataire}});
            }

            /**
             * Update locataires
             *
             * @returns {*}
             */
            function updateLocataires() {
                $ctrl.loadingLocataires = true;

                return locationLocataireManager.getAll($ctrl.location.id).then(function (locataires) {
                    $ctrl.locataires = locataires.collection;
                    if (angular.isObject($ctrl.locataires[0]) && $ctrl.locataires[0].enabled) {
                        $ctrl.locataireActuel = $ctrl.locataires[0];
                    } else {
                        $ctrl.locataireActuel = locationLocataireManager.create({}, $ctrl.location.id);
                        $ctrl.locataireActuel.id = false;
                        $ctrl.locataireActuel.enabled = true;
                    }

                    if ($ctrl.locataireActuel.locatairesGroup?.id) {
                        _contactsGroupLinkSource.next('/contacts-groups/' + $ctrl.locataireActuel.locatairesGroup.id);
                    } else {
                        _contactsGroupLinkSource.next(undefined!);
                    }

                    setOptionsDateBail();
                }).finally(function () {
                    $ctrl.loadingLocataires = false;
                });
            }

            /**
             * Set options date bail
             */
            function setOptionsDateBail() {
                if (!angular.isObject($ctrl.locataireActuel)) return;

                $ctrl.options.bailDate = {maxDate: $ctrl.locataireActuel.bailDateFin};
                $ctrl.options.bailDateFin = {minDate: $ctrl.locataireActuel.bailDate};
                $ctrl.options.dateSortie = {maxDate: $ctrl.locataireActuel.dateSortie};
                $ctrl.options.dateEntree = {minDate: $ctrl.locataireActuel.dateEntree};
            }

            function saveLocation() {
                return firstValueFrom(ng2LocationFactory.save$(ng2LocationFactory.ngCreate($ctrl.location)));
            }
        }
    })(angularJS);
}
