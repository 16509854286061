import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {IEtudeSettingsGenapiApi} from '@models/etudes/etude/settings/genapi/etude-settings-genapi.interfaces';
import EtudeSettingsGenapi from '@models/etudes/etude/settings/genapi/etude-settings-genapi.model';
import {EtudeSettingsGenapiApiService} from '@models/etudes/etude/settings/genapi/etude-settings-genapi.api.service';

@Injectable({providedIn: 'root'})
export class EtudeSettingsGenapiFactory {
    private _etudeSettingsGenapiApiService = inject(EtudeSettingsGenapiApiService);

    create(etudeSettingsGenapiApi: IEtudeSettingsGenapiApi): EtudeSettingsGenapi {
        const etudeSettingsGenapi = this.createVirgin();

        etudeSettingsGenapi.inotEnabled = etudeSettingsGenapiApi.inotEnabled;

        return etudeSettingsGenapi;
    }

    createVirgin(): EtudeSettingsGenapi {
        return new EtudeSettingsGenapi();
    }

    get$(etude: Etude): Observable<EtudeSettingsGenapi> {
        return this._etudeSettingsGenapiApiService.get$(etude.id.toString()).pipe(map(etudeSettingsGenapiApi => this.create(etudeSettingsGenapiApi)));
    }
}
