import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
    CEstimationReferencesFactory
} from '@models/estimations/estimation/references/collection/estimation-references.collection.factory';
import CEstimationReferences
    from '@models/estimations/estimation/references/collection/estimation-references.collection.model';

@Injectable({providedIn: 'root'})
export class CEstimationReferencesService {
    private _cEstimationReferencesFactory = inject(CEstimationReferencesFactory);

    addNext$(cEstimationReferences: CEstimationReferences): Observable<CEstimationReferences> {
        return this._cEstimationReferencesFactory.getNext$(cEstimationReferences).pipe(
            tap(newCEstimationReferences => cEstimationReferences.updateNext(newCEstimationReferences)),
            map(_ => cEstimationReferences),
        );
    }
}
