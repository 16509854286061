import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgBienPhoto} from '@legacy/app/managers/ressources';

export default function getBienModalDisplayPhotosCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('BienModalDisplayPhotosCtrl', Controller);

        /**
         * Controller of modal to edit Photo
         *
         * @param bienPhoto
         * @param currentIndex
         */
        Controller.$inject = ['bienPhotos', 'currentIndex'];
        function Controller(this: any, bienPhotos: NgBienPhoto[], currentIndex: number) {
            const $ctrl = this;

            $ctrl.selectPhoto = selectPhoto;
            $ctrl.bienPhotos = bienPhotos;
            $ctrl.currentIndex = currentIndex;

            /**
             * Select photo to display
             *
             * @param newIndex
             */
            function selectPhoto(newIndex: number) {
                if (newIndex < 0) {
                    newIndex = $ctrl.bienPhotos.length - 1;
                } else if (newIndex >= $ctrl.bienPhotos.length) {
                    newIndex = 0;
                }

                $ctrl.currentIndex = newIndex;
            }
        }
    })(angularJS);
}
