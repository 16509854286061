export default `
<div class="container-fluid container-fluid-title">
  <h1>Critères de recherche des biens en {{$ctrl.typeDossier}}</h1>
</div>

<div class="container-fluid container-fullw tw-bg-white">
  <esk-search-criteria search="$ctrl.search" update-search-localisations="$ctrl.updateSearchLocalisations"
                       options="{disabledFields: {fieldsetCession: true, fieldsetSource: true, searchByAdresse: true}}">
  </esk-search-criteria>

  <button type="button" ng-click="$ctrl.launch()" class="btn btn-primary pull-right btn-save">
    <fa-icon [icon]="'check'"></fa-icon> Lancer la recherche
  </button>
</div>
`;
