import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {EtudePasserelleService} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.service';
import {firstValueFrom, take} from 'rxjs';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';

type NgPageFB = {
    id: number
}

export default function getEskConfigurationFacebook(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-configuration-facebook></esk-configuration-facebook>
         */
        module.component('eskConfigurationFacebook', {
            bindings: {etudePasserelle: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/configuration/facebook.html'
        });

        /**
         * Barre search
         *
         * @param $q
         * @param SoqModalesManager
         * @param Ng2EtudePasserelleService
         * @constructor
         */
        Controller.$inject = ['$q', 'SoqModalesManager', 'Ng2EtudePasserelleService'];
        function Controller(this: any,
                            $q: IQService,
                            soqModalesManager: NgSoqModalesManager,
                            ng2EtudePasserelleService: EtudePasserelleService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onChangeStatus = onChangeStatus;
            $ctrl.reset = reset;
            $ctrl.NOTY_LABEL = AppNoteoIconTextNotyComponent.label;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.currentStepHelp = 1;
                if ($ctrl.etudePasserelle.configurator === 'facebook') {
                    $ctrl.fbScopes = 'business_management,pages_manage_posts,pages_read_engagement,pages_show_list';
                } else if ($ctrl.etudePasserelle.configurator === 'instagram') {
                    $ctrl.fbScopes = 'business_management,pages_manage_posts,pages_read_engagement,instagram_basic,instagram_content_publish,pages_show_list';
                }
            }

            /**
             * On change status
             *
             * @param changeStatutResponse
             */
            function onChangeStatus(changeStatutResponse: { authResponse: { accessToken: string }, status: string }) {
                let promiseReset = $q.resolve();

                // Passerelle déjà configurée donc affichage du bouton de déconnexion
                if ($ctrl.etudePasserelle.statut === 'configured') {
                    throw new Error(JSON.stringify($ctrl.etudePasserelle));
                }

                // Authentifié mais page non choisie, il faut faire un reset pour repasser par le statut 'unconfigured'
                if ($ctrl.etudePasserelle.statut === 'authenticated') {
                    promiseReset = firstValueFrom(ng2EtudePasserelleService.reset$($ctrl.etudePasserelle));
                }

                promiseReset.then(() => {
                    // La réponse de changement de statut n'est pas correctement formatée.
                    if (changeStatutResponse.status !== 'connected' || !angular.isObject(changeStatutResponse.authResponse) || !angular.isString(changeStatutResponse.authResponse.accessToken) || changeStatutResponse.authResponse.accessToken === '') {
                        throw new Error(JSON.stringify(changeStatutResponse));
                    }

                    firstValueFrom(ng2EtudePasserelleService.authenticate$($ctrl.etudePasserelle, changeStatutResponse.authResponse.accessToken)).then(authenticateResponse => {
                        // La réponse de configuration n'est pas correctement formatée.
                        if (authenticateResponse.name !== 'authenticated' || !angular.isObject(authenticateResponse.data) || !angular.isArray(authenticateResponse.data.pages) || authenticateResponse.data.pages.length <= 0) {
                            throw new Error(JSON.stringify(authenticateResponse));
                        }

                        soqModalesManager.open<NgPageFB>('EskModalConfigurationFacebook', {
                            resolve: {fbPages: () => authenticateResponse.data.pages},
                        }).then((page: NgPageFB) => firstValueFrom(ng2EtudePasserelleService.configure$($ctrl.etudePasserelle, page.id)));
                    });
                });
            }

            function reset() {
                ng2EtudePasserelleService.reset$($ctrl.etudePasserelle).pipe(take(1)).subscribe();
            }
        }
    })(angularJS);
}
