import {inject, Injectable} from '@angular/core';
import {
    EstimationReferenceFactory
} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import Vente from '@models/ventes/vente/vente.model';
import {Observable} from 'rxjs';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {IModelValidationError} from '@models/model.interfaces';
import Reference from '@models/references/reference/reference.model';
import {EstimationReferenceSourceType} from '@models/estimations/estimation/references/estimation-references.constants';
import {EstimationReferencesService} from '@models/estimations/estimation/references/estimation-references.service';

@Injectable({providedIn: 'root'})
export class EstimationReferenceService {
    static TITLE_ADDED_ESTIMATION_REFERENCE = 'Ajout d\'une référence';

    static getMessageLinkReferenceAdded(): string {
        return 'La référence est ajoutée ' + EstimationReferencesService.MESSAGE_ESTIMATION_REFERENCES + '.';
    }

    static getMessageLinkReferenceAddedError(error: IModelValidationError): string {
        return 'La référence ne peut être ajoutée ' + EstimationReferencesService.MESSAGE_ESTIMATION_REFERENCES
            + ' ' + EstimationReferenceService.getMessageValidationErrorsRaisons(error) + '.';
    }

    static getMessageLinkVenteAdded(vente: Vente): string {
        return 'Le bien en vente "' + vente.reference + '" est ajouté ' + EstimationReferencesService.MESSAGE_ESTIMATION_REFERENCES + '.';
    }

    static getMessageLinkVenteAddedError(vente: Vente, error: IModelValidationError): string {
        return 'Le bien en vente "' + vente.reference + '" ne peut être ajouté ' + EstimationReferencesService.MESSAGE_ESTIMATION_REFERENCES
            + ' ' + EstimationReferenceService.getMessageValidationErrorsRaisons(error) + '.';
    }

    static getMessageValidationErrorsRaisons(error: IModelValidationError): string {
        const messageValidationErrors = (error.validationErrors ?? []).map(validationError => '<br>    - ' + validationError.message);

        if (messageValidationErrors.length > 1) {
            return 'pour les raisons suivantes :' + messageValidationErrors.join(' ;');
        } else if (messageValidationErrors.length === 1) {
            return 'pour la raison suivante :' + messageValidationErrors.join(' ;');
        }

        try {
            return 'pour la raison suivante :<br>' + error.toString();
        } catch (e) {
            return 'pour une raison inconnue.';
        }
    }

    private _estimationReferenceFactory = inject(EstimationReferenceFactory);

    linkReference$(estimation: Estimation, reference: Reference, source: EstimationReferenceSourceType): Observable<EstimationReference> {
        return this._estimationReferenceFactory.validAndSave$(estimation, this._estimationReferenceFactory.createFromReference(reference, source));
    }

    linkVente$(estimation: Estimation, vente: Vente): Observable<EstimationReference> {
        return this._estimationReferenceFactory.validAndSave$(estimation, this._estimationReferenceFactory.createFromVente(vente));
    }
}
