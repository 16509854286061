import AModel from '@models/model.abstract';
import Etude from '@models/etudes/etude/etude.model';

export default abstract class Contact extends AModel {
    static readonly NO_NAME = 'Sans nom';
    static readonly sources = {LOCAL: 'local', INOT: Etude.enablableModules.INOT.code};
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    static readonly type = {ENTREPRISE: 'entreprise', PERSON: 'person'};
    private _adresse!: string;
    private _codePostal!: string;
    private _colorVariation!: string;
    private _comments!: string;
    private _communeId!: number;
    private _convivialName = Contact.NO_NAME;
    private _emailAddress!: string;
    private _externalId!: string;
    private _externalSource!: string;
    private _formuleAdressePostale!: string;
    private _formuleIntroduction!: string;
    private _formulePolitesse!: string;
    private readonly _id: number;
    private _initiales!: string;
    private _invalidEmailAddress!: boolean;
    private _linkCommune!: string;
    private _mobile!: string;
    private _representant!: Contact;
    private _telephone!: string;
    private _unsubscribed!: boolean;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get adresse(): string {
        return this._adresse;
    }

    set adresse(value: string) {
        this._adresse = value;
    }

    get codePostal(): string {
        return this._codePostal;
    }

    set codePostal(value: string) {
        this._codePostal = value;
    }

    get colorVariation(): string {
        return this._colorVariation;
    }

    set colorVariation(value: string) {
        this._colorVariation = value;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get communeId(): number {
        return this._communeId;
    }

    set communeId(value: number) {
        this._communeId = value;
    }

    get convivialName(): string {
        return this._convivialName;
    }

    protected set convivialName(value: string) {
        this._convivialName = value;
    }

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value;
    }

    get externalId(): string {
        return this._externalId;
    }

    set externalId(value: string) {
        this._externalId = value;
    }

    get externalSource(): string {
        return this._externalSource;
    }

    set externalSource(value: string) {
        this._externalSource = value;
    }

    get formuleAdressePostale(): string {
        return this._formuleAdressePostale;
    }

    set formuleAdressePostale(value: string) {
        this._formuleAdressePostale = value;
    }

    get formuleIntroduction(): string {
        return this._formuleIntroduction;
    }

    set formuleIntroduction(value: string) {
        this._formuleIntroduction = value;
    }

    get formulePolitesse(): string {
        return this._formulePolitesse;
    }

    set formulePolitesse(value: string) {
        this._formulePolitesse = value;
    }

    get id(): number {
        return this._id;
    }

    get initiales(): string {
        return this._initiales;
    }

    set initiales(value: string) {
        this._initiales = value;
    }

    get invalidEmailAddress(): boolean {
        return this._invalidEmailAddress;
    }

    set invalidEmailAddress(value: boolean) {
        this._invalidEmailAddress = value;
    }

    get linkCommune(): string {
        return this._linkCommune;
    }

    set linkCommune(value: string) {
        this._linkCommune = value;
    }

    get mobile(): string {
        return this._mobile;
    }

    set mobile(value: string) {
        this._mobile = value;
    }

    get representant(): Contact {
        return this._representant;
    }

    set representant(value: Contact) {
        this._representant = value;
    }

    get telephone(): string {
        return this._telephone;
    }

    set telephone(value: string) {
        this._telephone = value;
    }

    get unsubscribed(): boolean {
        return this._unsubscribed;
    }

    set unsubscribed(value: boolean) {
        this._unsubscribed = value;
    }

    isEntreprise(): boolean {
        return this.type === Contact.type.ENTREPRISE;
    }

    isNew(): boolean {
        return !this.uuid;
    }

    isPerson(): boolean {
        return this.type === Contact.type.PERSON;
    }

    abstract get type(): string;

    abstract setConvivialName(): void;
}
