import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgCommuneManager, NgDictionariesManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgItemDictionary, NgPerson} from '@legacy/app/managers/ressources';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';
import Ng2Person from '@models/contacts/person/person.model';

export default function getManagersPerson(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('PersonManager', manager);

        /**
         * Manager person
         *
         * @params UtilsManager
         * @params DictionariesManager
         * @params CommuneManager
         * @params Ng2PhoneNumberService
         * @params Ng2DictionaryItemService
         */
        manager.$inject = ['UtilsManager', 'DictionariesManager', 'CommuneManager', 'Ng2PhoneNumberService', 'Ng2DictionaryItemService'];
        function manager(utilsManager: NgUtilsManager,
                         dictionariesManager: NgDictionariesManager,
                         communeManager: NgCommuneManager,
                         ng2PhoneNumberService: PhoneNumberService,
                         ng2DictionaryItemService: DictionaryItemService) {
            /**
             * Person object
             *
             * @constructor
             */
            PersonManager.prototype.eskManager = {name: 'PersonManager'};
            function PersonManager() {
            }

            /**
             * Create a new Person instance
             *
             * @param data
             * @returns {Person}
             */
            PersonManager.prototype.create = function (data: unknown) {
                // @ts-ignore
                return new Person(data);
            };

            /**
             * Person object
             *
             * @param data
             * @constructor
             */
            Person.prototype.eskManager = {name: 'Person'};
            function Person(this: NgPerson, data: unknown) {
                this.titre = Ng2Person.titres.MONSIEUR as unknown as NgItemDictionary;
                this.extend(data);
                this.updateConvivialName();
                if (!angular.isString(this.nationalite)) {
                    this.nationalite = 'Française';
                }
            }

            /**
             * Extend the existing Person with new data
             *
             * @param data
             * @returns {Person}
             */
            Person.prototype.extend = function (data: unknown) {
                utilsManager.extendDataToResource(this, data);

                if (angular.isString(this.titre)) {
                    this.titre = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.TITRES, this.titre));
                }

                if (angular.isString(this.dateNaissance)) {
                    this.dateNaissance = DateFormat.toDate(this.dateNaissance);
                }

                if (angular.isObject(this.commune)) {
                    this.commune = communeManager.create(this.commune);
                }

                // Numéros de téléphone
                this.mobile = ng2PhoneNumberService.getUsable(this.mobile);
                this.telephone = ng2PhoneNumberService.getUsable(this.telephone);

                return this;
            };

            /**
             * Sanitize object Person before send to API
             *
             * @returns sanitizeObject
             */
            Person.prototype.sanitize = function () {
                const sanitizeObject = angular.merge({}, this);

                // Attribut ajouté par l'application
                delete sanitizeObject.convivialName;
                delete sanitizeObject._esk;
                delete sanitizeObject.colorVariation;
                delete sanitizeObject.initiales;

                sanitizeObject.dateNaissance = DateFormat.toAPI(this.dateNaissance);
                sanitizeObject.mobile = ng2PhoneNumberService.getE164(this.mobile);
                sanitizeObject.telephone = ng2PhoneNumberService.getE164(this.telephone);

                // objet => objetId
                utilsManager.flattenIdForAttribute(sanitizeObject, 'commune');

                // objet.id => objet
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'titre');

                if (!sanitizeObject.etranger && !sanitizeObject.communeId) {
                    delete sanitizeObject.adresse;
                    delete sanitizeObject.codePostal;
                }

                return sanitizeObject;
            };

            /**
             * Update convivial name
             */
            Person.prototype.updateConvivialName = function () {
                this.convivialName = (angular.isString(this.prenom) ? this.prenom : '') + (angular.isString(this.nom) ? ' ' + this.nom : '');

                if (this.convivialName !== "") {
                    this.convivialName = (angular.isObject(this.titre) ? this.titre.abreviation + " " : '') + this.convivialName;
                }
            };

            /**
             * Getter and setter for the NOM property
             *
             * @param nom
             * @returns {*}
             */
            Person.prototype.getNom = function (nom: string) {
                if (arguments.length) {
                    this.nom = nom;
                    this.updateConvivialName();

                    return this;
                }

                return this.nom;
            };

            /**
             * Getter and setter for the PRENOM property
             *
             * @param prenom
             * @returns {*}
             */
            Person.prototype.getPrenom = function (prenom: string) {
                if (arguments.length) {
                    this.prenom = prenom;
                    this.updateConvivialName();

                    return this;
                }

                return this.prenom;
            };

            /**
             * Person phone is valid
             *
             * @returns {boolean}
             */
            Person.prototype.isPhoneValid = function (phone: string) {
                return !phone || ng2PhoneNumberService.isValid(phone);
            };

            /**
             * Person is valid
             *
             * @returns {boolean}
             */
            Person.prototype.isValid = function () {
                return this.isPhoneValid(this.mobile) && this.isPhoneValid(this.telephone);
            };

            /**
             * Get Rfc3966
             *
             * @returns {string}
             */
            Person.prototype.getRfc3966 = function (phone: string) {
                return ng2PhoneNumberService.getRfc3966(phone);
            };

            // @ts-ignore
            return new PersonManager();
        }
    })(angularJS);
}
