import {NgModule} from '@angular/core';
import {AppImagePreviewComponent} from '@shared/image/preview/image-preview.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AppImageCropComponent} from '@shared/image/crop/image-crop.component';
import {FaModule} from '@shared/fontawesome/fa.module';
import {AppImagePickerComponent} from '@shared/image/picker/image-picker.component';
import {AppFormModule} from '@shared/form/form.module';

@NgModule({
    declarations: [AppImageCropComponent, AppImagePickerComponent, AppImagePreviewComponent],
    exports: [AppImageCropComponent, AppImagePickerComponent, AppImagePreviewComponent],
    imports: [AppFormModule, CommonModule, FaModule, NgOptimizedImage],
})
export class ImageModule {
}
