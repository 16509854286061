import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'esk-footer', template: '<div appNgEskFooter></div>'})
export class NgEskimmoFooterComponent {
}

@Directive({selector: '[appNgEskFooter]'})
export class NgEskimmoFooterDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskFooter', elementRef, injector);
    }
}
