import {inject, Injectable} from '@angular/core';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {EmailRecipientFactory} from '@models/emails/email/recipients/recipient/email-recipient.factory';
import Site from '@models/sites/site/site.model';
import Etude from '@models/etudes/etude/etude.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SiteService} from '@models/sites/site/site.service';
import {NgEmailRecipientManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgEmailRecipient} from '@legacy/app/managers/ressources';
import User from '@models/users/user/user.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import Contact from '@models/contacts/contact/contact.model';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';

@Injectable({providedIn: 'root'})
export class EmailRecipientService {
    private _emailRecipientFactory = inject(EmailRecipientFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _siteService = inject(SiteService);

    // Supprimer les injections des anciens manager
    private get ngEmailRecipientManager(): NgEmailRecipientManager {
        return this._ngInjectorService.getService('EmailRecipientManager');
    }

    createFromContact(contact: Contact): EmailRecipient {
        const emailRecipient: EmailRecipient = this._emailRecipientFactory.createVirgin();

        emailRecipient.emailAddress = contact.emailAddress;
        emailRecipient.name = contact.convivialName;
        emailRecipient.type = EmailRecipient.types.TO;

        return emailRecipient;
    }

    createFromContactsGroupMember(contactsGroupMember: ContactsGroupMember): EmailRecipient {
        return this.createFromContact(contactsGroupMember.contact);
    }

    createFromProcedureSignataire(procedureSignataire: ProcedureSignataire): EmailRecipient {
        const emailRecipient: EmailRecipient = this._emailRecipientFactory.createVirgin();

        emailRecipient.emailAddress = procedureSignataire.email;
        emailRecipient.name = procedureSignataire.convivialName;
        emailRecipient.type = EmailRecipient.types.TO;

        return emailRecipient;
    }

    createFromSite$(etude: Etude, site: Site): Observable<EmailRecipient> {
        return this._siteService.getConvivialName$(etude, site).pipe(
            map(convivialName => {
                const emailRecipient: EmailRecipient = this._emailRecipientFactory.createVirgin();

                emailRecipient.emailAddress = site.emailAddress;
                emailRecipient.name = convivialName;
                emailRecipient.type = EmailRecipient.types.TO;

                return emailRecipient;
            }),
        );
    }

    createFromUser(user: User): EmailRecipient {
        const emailRecipient: EmailRecipient = this._emailRecipientFactory.createVirgin();

        emailRecipient.emailAddress = user.emailAddress;
        emailRecipient.name = user.convivialName;
        emailRecipient.type = EmailRecipient.types.TO;

        return emailRecipient;
    }

    createNoteoCommerce(type = EmailRecipient.types.TO): EmailRecipient {
        const emailRecipient: EmailRecipient = this._emailRecipientFactory.createVirgin();

        emailRecipient.emailAddress = EmailRecipient.noteo.COMMERCE.emailAddress;
        emailRecipient.name = EmailRecipient.noteo.COMMERCE.name;
        emailRecipient.type = type;

        return emailRecipient;
    }

    createNoteoSupport(type = EmailRecipient.types.TO): EmailRecipient {
        const emailRecipient: EmailRecipient = this._emailRecipientFactory.createVirgin();

        emailRecipient.emailAddress = EmailRecipient.noteo.SUPPORT.emailAddress;
        emailRecipient.name = EmailRecipient.noteo.SUPPORT.name;
        emailRecipient.type = type;

        return emailRecipient;
    }

    ngGet(emailRecipient: EmailRecipient): NgEmailRecipient {
        return this.ngEmailRecipientManager.createFromNg2(emailRecipient);
    }
}
