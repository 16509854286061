import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Router} from '@angular/router';
import Procedure from '@models/procedures/procedure/procedure.model';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {
    OffreachatSignActionsMainComponent
} from '@features/offreachats/item/actions/sign/offreachat-sign.actions-main.component';
import {ToasterService} from '@shared/toaster/toaster.service';
import {UserService} from '@models/users/user/user.service';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {MediaResumeService} from '@models/medias/media/media-resume.service';

@Component({selector: 'layout-offreachat-signature', templateUrl: 'layout.offreachat-signature.component.html'})
export class AppLayoutOffreachatSignatureComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {
        SIGN_DISABLED: 'La signature de votre offre d\'achat n\'est pas disponible.',
    };
    private _callToActionService = inject(CallToActionService);
    private _dossierService = inject(DossierService);
    private _mediaResumeService = inject(MediaResumeService);
    private _offreachatService = inject(OffreachatService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _userService = inject(UserService);
    private _currentDossier!: ADossier;
    private _offreachatSource = new ReplaySubject<Offreachat>(1);
    private _offreachat$ = this._offreachatSource.asObservable();
    private _offreachatsRoot!: string;
    private readonly _onDestroy$ = new Subject<void>();
    private _procedureAcquereurSource = new BehaviorSubject<Procedure>(undefined!);
    private _procedureAcquereur$ = this._procedureAcquereurSource.asObservable();
    private _procedureVendeurSource = new BehaviorSubject<Procedure>(undefined!);
    private _procedureVendeur$ = this._procedureVendeurSource.asObservable();
    private _requestSignatairesSavingSource = new Subject<void>();
    private _requestSignatairesSaving$ = this._requestSignatairesSavingSource.asObservable();
    private _signatairesSavedSource = new Subject<void>();
    private _signatairesSaved$ = this._signatairesSavedSource.asObservable();

    get currentDossier(): ADossier {
        return this._currentDossier;
    }

    get offreachat$(): Observable<Offreachat> {
        return this._offreachat$;
    }

    get procedureAcquereur$(): Observable<Procedure> {
        return this._procedureAcquereur$;
    }

    get procedureVendeur$(): Observable<Procedure> {
        return this._procedureVendeur$;
    }

    get requestSignatairesSaving$(): Observable<void> {
        return this._requestSignatairesSaving$;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        this._offreachatsRoot = '/app/offres-achat';
        if (!this._router.url.startsWith('/app/offres-achat')) {
            this._offreachatsRoot = this._router.url.split('/offres-achat')[0] + '/offres-achat';
            fallbackPath = this._offreachatsRoot + '/portefeuille';
        }

        this._currentDossier = this._dossierService.getCurrentFromNg(this._router.url);
        this._offreachatService.current$.pipe(
            filter(offreachat => !!offreachat),
            tap(offreachat => this._offreachatSource.next(offreachat)),
            switchMap(offreachat => combineLatest([this._userService.last$, of(offreachat)])),
            take(1),
        ).subscribe(([currentUser, offreachat]) => {
            if (!currentUser.hasRoleSignatureElectronique() || !offreachat.isSignature()) {
                this._toasterService.warning(AppLayoutOffreachatSignatureComponent.messages.SIGN_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this.offreachat$.pipe(
            filter(offreachat => !!offreachat),
            switchMap(offreachat => combineLatest([
                this._offreachatService.getProcedureAcquereur$(offreachat).pipe(
                    tap(procedure => this._procedureAcquereurSource.next(procedure)),
                    switchMap(_ => this._procedureAcquereur$),
                ),
                this._offreachatService.getProcedureVendeur$(offreachat).pipe(
                    tap(procedure => this._procedureVendeurSource.next(procedure)),
                    switchMap(_ => this._procedureVendeur$),
                ),
            ]).pipe(map(([procedureAcquereur, procedureVendeur]: [Procedure, Procedure]) =>
                ({offreachat, procedure: offreachat.isSignatureAcquereur() ? procedureAcquereur : procedureVendeur})
            ))),
            takeUntil(this._onDestroy$),
        ).subscribe(({offreachat, procedure}) => {
            const fromDossier = this._router.url.includes(DossierTypesConst.DEMANDEUR) || this._router.url.includes(DossierTypesConst.VENTE);

            this._callToActionService.setDynamicComponentLoading({
                component: OffreachatSignActionsMainComponent,
                data: {
                    offreachat,
                    options: {
                        archive: fromDossier,
                        cancelSign: true,
                        print: true,
                        save: fromDossier && offreachat.isSignatureAcquereur() && procedure.isNewOrDraft(),
                        sign: procedure.isNewOrDraft(),
                    },
                },
            });
        });
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.ARCHIVE) {
                    return this._callToActionService.actionExec$(this.archive$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.CANCEL_SIGN) {
                    return this._callToActionService.actionExec$(this.cancelSign$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.SAVE) {
                    return this._callToActionService.actionExec$(this.save$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.SIGN) {
                    return this._callToActionService.actionExec$(this.sign$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    archive$(): Observable<string> {
        return this._offreachatService.archiveCurrent$().pipe(
            switchMap(_ => this._offreachatService.current$),
            take(1),
            map(offreachat => offreachat.isArchive() ? this._offreachatsRoot + '/' + offreachat.uuid + '/resume' : undefined!),
        );
    }

    cancelSign$(): Observable<string> {
        return this._offreachatService.cancelSignCurrent$().pipe(
            map(offreachat => {
                if (offreachat) {
                    if (offreachat.isWrite()) {
                        return this._offreachatsRoot + '/' + offreachat.uuid + '/redaction';
                    } else if (offreachat.isArchive()) {
                        return this._offreachatsRoot + '/' + offreachat.uuid + '/resume';
                    }
                }

                return undefined!;
            })
        );
    }

    formSaved(): void {
        this._signatairesSavedSource.next();
    }

    print$(): Observable<string> {
        return this._offreachatService.current$.pipe(
            switchMap(offreachat => this._mediaResumeService.readFromOffreachat$(offreachat)),
            map(_ => undefined!),
        );
    }

    procedureClosed(): void {
        this._offreachatSource.next(undefined!);
        this._offreachatService.updateCurrent$().pipe(
            tap(offreachat => this._offreachatSource.next(offreachat)),
            map(offreachat => offreachat.isSignature() ? undefined! : this._offreachatsRoot + '/' + offreachat.uuid + '/resume'),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    save$(): Observable<string> {
        this._requestSignatairesSavingSource.next();

        return this._signatairesSaved$.pipe(
            switchMap(_ => this.offreachat$),
            take(1),
            tap(offreachat => this._offreachatSource.next(offreachat)),
            map(_ => undefined!),
        );
    }

    sign$(): Observable<string> {
        return this.offreachat$.pipe(
            take(1),
            switchMap(offreachat => {
                if (offreachat.isSignatureVendeur()) {
                    return of(undefined);
                }

                return this.save$();
            }),
            switchMap(_ => this.offreachat$),
            take(1),
            switchMap(offreachat => this._offreachatService.launch$(offreachat)),
            tap(offreachat => offreachat && this._offreachatSource.next(offreachat)),
            map(_ => undefined!),
        );
    }
}
