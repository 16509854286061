import {Directive, ElementRef, HostListener, inject, output} from '@angular/core';

@Directive({selector: '[appClickAway]'})
export class ClickAwayDirective {
    readonly appClickAway = output<void>();
    private _elementRef = inject(ElementRef<HTMLElement>);

    @HostListener('document:click', ['$event.target'])
    onClick(target: HTMLElement): void {
        if (!this._elementRef.nativeElement.contains(target)) {
            this.appClickAway.emit();
        }
    }
}
