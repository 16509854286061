import {inject, Injectable} from '@angular/core';
import {DictionariesLocalService} from '@models/dictionaries/dictionaries.local.service';
import {Observable, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {DictionaryService} from '@models/dictionaries/dictionary/dictionary.service';
import Etude from '@models/etudes/etude/etude.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Injectable({providedIn: 'root'})
export class DictionariesService {
    static readonly API_DICTIONARIES_NAMES = [
        Dictionary.names.ASSAISSEMENTS,
        Dictionary.names.CHARGES,
        Dictionary.names.CHARPENTES,
        Dictionary.names.CHAUFFAGES,
        Dictionary.names.CLOTURES,
        Dictionary.names.COMMODITES,
        Dictionary.names.INTERNET_CONNEXIONS,
        Dictionary.names.CONSTRUCTION_EPOQUES,
        Dictionary.names.ETAGES,
        Dictionary.names.ETATS,
        Dictionary.names.EXPOSITIONS,
        Dictionary.names.EXTENSIONS,
        Dictionary.names.HUISSERIES,
        Dictionary.names.ISOLATIONS,
        Dictionary.names.LOGEMENTS,
        Dictionary.names.CONSTRUCTION_MATERIAUX,
        Dictionary.names.COUVERTURE_MATERIAUX,
        Dictionary.names.MITOYENNETES,
        Dictionary.names.NATURES,
        Dictionary.names.DIFFUSION_IMMONOT_NATURES,
        Dictionary.names.DIFFUSION_PRECOM_NATURES,
        Dictionary.names.DIFFUSION_REAL_NATURES,
        Dictionary.names.DIFFUSION_SELOGER_NATURES,
        Dictionary.names.DIFFUSION_SPIR_NATURES,
        Dictionary.names.OCCUPATIONS,
        Dictionary.names.PERIODICITES,
        Dictionary.names.PIECE_TYPES,
        Dictionary.names.PLAIN_PIEDS,
        Dictionary.names.RESIDENCES,
        Dictionary.names.COMPTE_RENDU_THEMES,
        Dictionary.names.CONTACTS_GROUP_TYPES,
        Dictionary.names.DEMANDEUR_TYPES,
        Dictionary.names.DIAGNOSTIC_TYPES,
        Dictionary.names.DIFFUSION_FACEBOOK_PRIX_TYPES,
        Dictionary.names.DIFFUSION_IMMONOT_PRIX_TYPES,
        Dictionary.names.DIFFUSION_REAL_PRIX_TYPES,
        Dictionary.names.DIFFUSION_SPIR_PRIX_TYPES,
        Dictionary.names.USAGES,
        Dictionary.names.VOLETS,
        Dictionary.names.VUES,
    ];
    private _dictionariesLocalService = inject(DictionariesLocalService);
    private _dictionaryService = inject(DictionaryService);

    init$(onlyStatics = true, etude?: Etude): Observable<boolean> {
        const init$: Observable<boolean>[] = [this._dictionariesLocalService.init$(etude).pipe(map(_ => false))];

        if (!onlyStatics) {
            init$.push(this.update$());
        }

        return combineLatest(init$).pipe(map(updatingDictionaries => updatingDictionaries.some(updatingDictionary => updatingDictionary)));
    }

    update$(): Observable<boolean> {
        return combineLatest(DictionariesService.API_DICTIONARIES_NAMES.map(dictionaryName => this._dictionaryService.update$(dictionaryName))).pipe(
            map(updatingDictionaries => updatingDictionaries.some(updatingDictionary => updatingDictionary)),
        );
    }
}
