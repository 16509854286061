import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import Phone from '@models/phones/phone/phone.model';
import {
    NgCommuneManager, NgDictionariesManager, NgManager, NgPhotoManager, NgSiteManager, NgUserPhotoManager,
    NgUtilsManager
} from '@legacy/app/managers/managers';
import {PhoneFactory} from '@models/phones/phone/phone.factory';
import {NgClientService} from '@legacy/app/client/client';
import {NgEtude, NgItemDictionary, NgUser, NgUserPhoto} from '@legacy/app/managers/ressources';
import Ng2User from '@models/users/user/user.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {ConditionConst} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import Person from '@models/contacts/person/person.model';

export default function getManagersUser(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('UserManager', manager);

        /**
         * Manager user
         *
         * @param UtilsManager
         * @param BaseManager
         * @param SiteManager
         * @param PhotoManager
         * @param UserPhotoManager
         * @param $q
         * @param ClientService
         * @param DictionariesManager
         * @param communeManager
         * @param Ng2PhoneFactory
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['UtilsManager', 'BaseManager', 'SiteManager', 'PhotoManager', 'UserPhotoManager', '$q', 'ClientService', 'DictionariesManager', 'CommuneManager', 'Ng2PhoneFactory', 'Ng2DictionaryItemService'];
        function manager(utilsManager: NgUtilsManager,
                         baseManager: NgManager,
                         siteManager: NgSiteManager,
                         photoManager: NgPhotoManager,
                         userPhotoManager: NgUserPhotoManager,
                         $q: IQService,
                         clientService: NgClientService,
                         dictionariesManager: NgDictionariesManager,
                         communeManager: NgCommuneManager,
                         ng2PhoneFactory: PhoneFactory,
                         ng2DictionaryItemService: DictionaryItemService) {
            let classResource = baseManager.getClass<NgUser>();

            /**
             * Routing
             */
            baseManager.addRoute('users.cget', {path: '/users', method: 'GET'});
            baseManager.addRoute('users.cget-etude', {path: '/etudes/{etudeId}/users', method: 'GET'});
            baseManager.addRoute('users.get', {path: '/users/{id}', method: 'GET'});
            baseManager.addRoute('users.request-password', {path: '/users/request-password', method: 'PATCH'});
            baseManager.addRoute('users.reset-password', {path: '/users/reset-password', method: 'PATCH'});
            baseManager.addRoute('users.insert', {path: '/users', method: 'POST'});
            baseManager.addRoute('users.edit', {path: '/users/{id}', method: 'PUT'});
            baseManager.addRoute('users.change-password', {path: '/users/{id}/change-password', method: 'PATCH'});

            /**
             * UserManager object
             *
             * @constructor
             */
            angular.extend(UserManager.prototype, baseManager.__proto__);
            UserManager.prototype.eskManager = {prefixRoute: 'users', collectionName: 'items'};
            function UserManager() {
            }

            /**
             * Create user from NG2
             *
             * @returns {Promise}
             */
            UserManager.prototype.createFromNg2 = function (ng2User: Ng2User): NgUser {
                if (!ng2User) {
                    return undefined!;
                }

                const user: NgUser = this.create({_links: {self: {href: ng2User.linkSelf}}, id: ng2User.id});

                user.emailAddress = ng2User.emailAddress;
                user.emailSignature = ng2User.emailSignature;
                user.initiales = ng2User.initiales;
                user.nom = ng2User.nom;
                user.prenom = ng2User.prenom;
                user.reachableRoles = ng2User.roles;
                user.sexe = ng2User.sexe;
                user.titre = dictionariesManager.createFromNg2(ng2User.titre);
                user.username = ng2User.username;
                user.uuid = ng2User.uuid;

                return user;
            };

            /**
             * Fetch all user for etude
             *
             * @returns {Promise}
             */
            UserManager.prototype.getAllForEtude = function (etude: NgEtude, params: unknown) {
                return this.get('cget-etude', angular.extend({etudeId: etude.id}, params));
            };

            /**
             * Get users with defined etudes and roles
             *
             * @param etudes
             * @param roles
             * @param keywords
             * @returns {Promise}
             */
            UserManager.prototype.getAllForEtudesAndRoles = function (etudes: number[], roles: string[], keywords: string) {
                let queryParams: Record<string, unknown> = {keywords};

                roles = angular.isArray(roles) ? roles : [];
                if (roles.length > 0) {
                    queryParams['role'] = {condition: ConditionConst.CONTAINS_SOME, values: roles};
                }

                etudes = angular.isArray(etudes) ? etudes : [];
                if (etudes.length === 1) {
                    return this.getAllForEtude({id: etudes[0]}, queryParams);
                } else if (etudes.length > 0) {
                    queryParams['etude'] = {values: etudes, condition: ConditionConst.IN};
                }

                return this.getAll(queryParams);
            };

            /**
             * Reset password
             *
             * @param token
             * @param password
             * @returns {Promise}
             */
            UserManager.prototype.resetPassword = function (token: string, password: string) {
                return this.patch('reset-password', {token, password});
            };

            /**
             * Create a User object
             *
             * @param data
             * @returns manager.User object || Array of manager.User objects
             */
            UserManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var users = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        users.push(new User(data[i]));
                    }

                    return users;
                }

                // @ts-ignore
                return new User(data);
            };

            /**
             * User object
             *
             * @param data
             * @constructor
             */
            angular.extend(User.prototype, classResource.prototype);
            User.prototype.eskManager = {prefixRoute: 'users'};
            function User(this: NgUser, data: unknown) {
                this.titre = Person.titres.MONSIEUR as unknown as NgItemDictionary;
                this.sexe = Person.sexes.HOMME;
                this.nationalite = 'Française';
                this.reachableRoles = [];
                this.extend(data);
                this.updateConvivialName();
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Extend the existing User with new data
             *
             * @param data
             * @returns {User}
             */
            User.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isString(this.titre)) {
                    this.titre = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.TITRES, this.titre));
                }

                if (angular.isString(this.dateNaissance)) {
                    this.dateNaissance = DateFormat.toDate(this.dateNaissance);
                }

                if (angular.isObject(this.commune)) {
                    this.commune = communeManager.create(this.commune);
                }

                if (angular.isObject(this.site)) {
                    this.site = siteManager.create(this.site);
                }

                if (angular.isObject(this.photo)) {
                    this.photo = userPhotoManager.create(this.photo);
                } else {
                    setDefaultProfilePhoto(this);
                }

                return this;

                /**
                 * Set default photo
                 *
                 * @param user
                 */
                function setDefaultProfilePhoto(user: NgUser) {
                    user.photo = {_esk: {href: {thumbnail: undefined!}}} as unknown as NgUserPhoto;
                    if (!angular.isObject(user._links) || !angular.isObject(user._links.photo)) {
                        user.photo._esk.href!.thumbnail = 'assets/images/icons/avatar-' + user.sexe[0] + '.svg';
                    } else {
                        user.photo._esk.href = utilsManager.createPhotoHref({_links: {file: user._links.photo}}, true);
                    }
                }
            };

            /**
             * Update convivial name
             */
            User.prototype.updateConvivialName = function () {
                this.convivialName = (angular.isString(this.prenom) ? this.prenom : '') + (angular.isString(this.nom) ? ' ' + this.nom : '');

                if (this.convivialName !== "") {
                    this.convivialName = (angular.isObject(this.titre) ? this.titre.abreviation + " " : '') + this.convivialName;
                }
            };

            /**
             * Getter phone fixe
             *
             * @returns {*}
             */
            User.prototype.getPhoneFixe = function (): Phone {
                if (!this._esk.phoneFixe) {
                    const ngPhones = this.telephones?.filter(telephone => telephone.type === 'fixe');
                    const phone = ngPhones.length > 0 ? ngPhones[0] : {type: 'fixe'};

                    this._esk.phoneFixe = ng2PhoneFactory.ngCreate(phone);
                }

                return this._esk.phoneFixe;
            };

            /**
             * Getter phone mobile
             *
             * @returns {*}
             */
            User.prototype.getPhoneMobile = function (): Phone {
                if (!this._esk.phoneMobile) {
                    const ngPhones = this.telephones?.filter(telephone => telephone.type === 'mobile') || [];
                    const phone = ngPhones.length > 0 ? ngPhones[0] : {type: 'mobile'};

                    this._esk.phoneMobile = ng2PhoneFactory.ngCreate(phone);
                }

                return this._esk.phoneMobile;
            };

            // @ts-ignore
            return new UserManager();
        }
    })(angularJS);
}
