export default `
<div class="row">
  <div class="col-sm-1 margin-top-5">
    <span class="text-bold" ng-switch="$ctrl.recipientType">
      <span ng-switch-when="to">A :</span>

      <span ng-switch-when="cc">Cc :</span>

      <span ng-switch-when="bcc">Cci :</span>
    </span>
  </div>

  <div ng-show="($ctrl.email.recipients | filter: {type: $ctrl.recipientType}: true).length > 0"
       class="col-sm-11 margin-bottom-10">
    <span ng-repeat="recipient in $ctrl.email.recipients | filter: {type: $ctrl.recipientType}: true">
      <div class="recipient">
        <span class="label label-primary" title="{{recipient.emailAddress}}">
          {{recipient.name}}

          <span ng-click="$ctrl.email.removeRecipient(recipient)"><fa-icon [icon]="'times-circle'"></fa-icon></span>
        </span>
      </div>
    </span>
  </div>

  <div class="col-sm-11" ng-class="{'col-sm-offset-1': $ctrl.email.recipients.length > 0}">
    <esk-select-participant on-select="$ctrl.addRecipient" ng-model="$ctrl.newRecipient"
                            filtered="$ctrl.filterParticipants" disabled="$ctrl.isDisabled" options="$ctrl.options"
                            class="inline-block width-200"></esk-select-participant>
  </div>
</div>
`;
