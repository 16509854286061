import AModel from '@models/model.abstract';
import Phone from '@models/phones/phone/phone.model';

export default class Site extends AModel {
    private _adresse!: string;
    private _codePostal!: string;
    private _convivialAddress = '';
    private _emailAddress!: string;
    private _etudeId!: number;
    private readonly _id: number;
    private _latitude!: number;
    private _linkCommune!: string;
    private _linkEtude!: string;
    private _linkUsers!: string;
    private _longitude!: number;
    private _siteInternet!: string;
    private _sortableRank!: number;
    private _telephone!: Phone;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get adresse(): string {
        return this._adresse;
    }

    set adresse(value: string) {
        this._adresse = value;
        this.setConvivialAddress();
    }

    get codePostal(): string {
        return this._codePostal;
    }

    set codePostal(value: string) {
        this._codePostal = value;
        this.setConvivialAddress();
    }

    get convivialAddress(): string {
        return this._convivialAddress;
    }

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value;
    }

    get etudeId(): number {
        return this._etudeId;
    }

    set etudeId(value: number) {
        this._etudeId = value;
    }

    get id(): number {
        return this._id;
    }

    get latitude(): number {
        return this._latitude;
    }

    set latitude(value: number) {
        this._latitude = value;
    }

    get linkCommune(): string {
        return this._linkCommune;
    }

    set linkCommune(value: string) {
        this._linkCommune = value;
    }

    get linkEtude(): string {
        return this._linkEtude;
    }

    set linkEtude(value: string) {
        this._linkEtude = value;
    }

    get linkUsers(): string {
        return this._linkUsers;
    }

    set linkUsers(value: string) {
        this._linkUsers = value;
    }

    get longitude(): number {
        return this._longitude;
    }

    set longitude(value: number) {
        this._longitude = value;
    }

    get siteInternet(): string {
        return this._siteInternet;
    }

    set siteInternet(value: string) {
        if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
            value = 'https://' + value;
        }

        this._siteInternet = value;
    }

    get sortableRank(): number {
        return this._sortableRank;
    }

    set sortableRank(value: number) {
        this._sortableRank = value;
    }

    get telephone(): Phone {
        return this._telephone;
    }

    set telephone(value: Phone) {
        this._telephone = value;
    }

    getPhoneNumber(): string {
        return this.telephone?.numero;
    }

    isPrincipal(): boolean {
        return this.sortableRank === 1;
    }

    setConvivialAddress(): void {
        this._convivialAddress = [this.adresse, this.codePostal].filter(value => !!value).join(', ');
    }
}
