import {Injectable} from '@angular/core';
import CContactsGroupMembers from '@models/contacts-group/members/collection/contacts-group-members.collection.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';

@Injectable({providedIn: 'root'})
export class CContactsGroupMembersFactory {
    createFromMembers(members: ContactsGroupMember[]): CContactsGroupMembers {
        const cContactsGroupMembers = this.createVirgin();

        cContactsGroupMembers.page = 1;
        cContactsGroupMembers.pages = 1;
        cContactsGroupMembers.perPage = members.length;
        cContactsGroupMembers.total = members.length;
        cContactsGroupMembers.results = members;

        return cContactsGroupMembers;
    }

    createVirgin(): CContactsGroupMembers {
        return new CContactsGroupMembers();
    }
}
