import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewLocalisationService {
    private _isAdresseVisible!: boolean;
    private _isCadastresVisible!: boolean;
    private _isPartVisible!: boolean;

    get isAdresseVisible(): boolean {
        return this._isAdresseVisible;
    }

    get isCadastresVisible(): boolean {
        return this._isCadastresVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isAdresseVisible = !!dossierBien.bien.inlineAdresse;
        this._isCadastresVisible = dossierBien.bien.cadastres.length > 0;

        this._isPartVisible = this._isAdresseVisible || this._isCadastresVisible;
    }
}
