import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgEskBox, NgEskDashboard} from '@legacy/app/eskimmo/eskimmo';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEmailManager, NgVenteManager} from '@legacy/app/managers/managers';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';

export default function getEskDashboardCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDashboardCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/dashboard.html',
        });

        /**
         * Controller for main dashboard
         *
         * @param EmailManager
         * @param Ng2RouterStateForNgService
         * @param VenteManager
         * @param EskBox
         * @param EskDashboard
         * @param $q
         */
        Controller.$inject = ['EmailManager', 'Ng2RouterStateForNgService', 'VenteManager', 'EskBox', 'EskDashboard', '$q', 'Ng2ModalService'];
        function Controller(this: any,
                            emailManager: NgEmailManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            venteManager: NgVenteManager,
                            eskBox: NgEskBox,
                            eskDashboard: NgEskDashboard,
                            $q: IQService,
                            ng2ModalService: ModalService) {
            const $ctrl = this;

            $ctrl.createEmail = createEmail;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.BonvisiteStatutNEW = Bonvisite.statuts.NEW;
            $ctrl.dashboardConfig = eskDashboard.get();
            $ctrl.sortableOptions = {
                connectWith: '.col-dnd',
                placeholder: 'placeholder',
                handle: '.drag-handler',
                stop: function () {
                    eskBox.getAll().collection.map(function (box) {
                        if (angular.isFunction(box.refresh)) box.refresh();
                    });
                },
                update: function () {
                    $q.resolve().then(eskDashboard.saveConfig);
                },
                scroll: false,
            };
            $ctrl.isAllBoxesClosed = eskBox.isAllBoxesClosed();

            /**
             * Create mail
             */
            function createEmail() {
                ng2ModalService.ngOpen$('EskModalEmail', {
                    resolve: {email: emailManager.create(), currentDossier: {}},
                }).pipe(take(1)).subscribe();
            }
        }
    })(angularJS);
}
