import AModel from '@models/model.abstract';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';

export default class EmailDossier extends AModel {
    private _demandeur!: Demandeur;
    private _estimation!: Estimation;
    private readonly _id: number;
    private _location!: Location;
    private _type!: string;
    private _vente!: Vente;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get demandeur(): Demandeur {
        return this._demandeur;
    }

    set demandeur(value: Demandeur) {
        this._demandeur = value;
    }

    get estimation(): Estimation {
        return this._estimation;
    }

    set estimation(value: Estimation) {
        this._estimation = value;
    }

    get id(): number {
        return this._id;
    }

    get location(): Location {
        return this._location;
    }

    set location(value: Location) {
        this._location = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get vente(): Vente {
        return this._vente;
    }

    set vente(value: Vente) {
        this._vente = value;
    }
}
