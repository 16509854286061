import {Injectable} from '@angular/core';
import Quartier from '@models/quartiers/quartier/quartier.model';
import {NgQuartier} from '@legacy/app/managers/ressources';
import {IQuartierApi} from '@models/quartiers/quartier/quartier.interfaces';

@Injectable({providedIn: 'root'})
export class QuartierFactory {
    create(quartierApi: IQuartierApi): Quartier {
        // En attente de l'API pour avoir un vrai uuid
        const quartier = new Quartier(quartierApi.id.toString(), quartierApi.id);

        quartier.code = quartierApi.code;
        quartier.nom = quartierApi.name;
        if (quartierApi._embedded) {
            quartier.communeId = quartierApi._embedded.commune.id;
        }

        return quartier;
    }

    ngCreate(ngQuartier: NgQuartier): Quartier {
        const quartier = new Quartier(ngQuartier.id.toString(), ngQuartier.id);

        quartier.code = ngQuartier.code;
        quartier.nom = ngQuartier.name;
        if (ngQuartier.commune) {
            quartier.communeId = ngQuartier.commune.id;
        }

        return quartier;
    }
}
