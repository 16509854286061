import angularJS from '@shared/angularJS/global.ng';
import {IDirectiveFactory, IModule, Injectable} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import * as angular from 'angular';

export default function getSoqPanel(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-panel
         */
        module.directive('soqPanel', directive as Injectable<IDirectiveFactory>);

        /**
         * Displaying panel with header content and footer
         *
         * @returns {Object}
         */
        directive.$inject = [] as string[];
        function directive() {
            return {
                scope: true,
                restrict: 'E',
                controllerAs: '$ctrl',
                bindToController: {
                    box: '=',
                },
                transclude: {
                    toolsSlot: '?toolsSlot',
                    contentSlot: 'contentSlot',
                    footerSlot: '?footerSlot'
                },
                controller: ['SoqSweetAlert', '$translate', 'EskBox', controller],
                templateUrl: 'src/app/legacy/templates/soqrate/directives/panel.html'
            };

            /**
             * Controller for soq panel
             *
             * @param SoqSweetAlert
             * @param $translate
             * @param EskBox
             */
            function controller(
                this: any,
                soqSweetAlert: NgSoqSweetAlert,
                $translate: angular.translate.ITranslateService,
                eskBox: NgEskBox
            ) {
                const $ctrl = this;

                $ctrl.toggleExpansion = toggleExpansion;
                $ctrl.closeBox = closeBox;

                /**
                 * Toggle box accordion
                 */
                function toggleExpansion() {
                    eskBox.toggleExpansion($ctrl.box);
                }

                /**
                 * Close box
                 */
                function closeBox() {
                    soqSweetAlert.warningConfirmation($translate.instant('dashboard.box.remove.TITLE', {boxName: $ctrl.box.name}), $translate.instant('dashboard.box.remove.MESSAGE', {boxName: $ctrl.box.name}), {confirmButtonText: $translate.instant('dashboard.box.remove.CONFIRMATION')}).then(function () {
                        eskBox.toggleVisibility($ctrl.box);
                    });
                }
            }
        }
    })(angularJS);
}
