import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgContactsEmailAddress} from '@legacy/app/contacts/contacts';
import IInjectorService = angular.auto.IInjectorService;
import {
    NgCommuneManager, NgContactManager, NgDictionariesManager, NgEntrepriseManager, NgManager, NgPersonManager, NgUtilsManager
} from '@legacy/app/managers/managers';
import {NgCommune, NgContact, NgEntreprise, NgPerson} from '@legacy/app/managers/ressources';
import * as Ng2Contact from '@models/contacts/contact/contact.model';
import Entreprise from '@models/contacts/entreprise/entreprise.model';
import Person from '@models/contacts/person/person.model';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';

export default function getManagersContact(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ContactManager', manager);

        /**
         * Manager contact
         *
         * @param BaseManager
         * @param PersonManager
         * @param EntrepriseManager
         * @param $injector
         * @param UtilsManager
         * @param DictionariesManager
         * @param CommuneManager
         * @param $q
         * @returns {ContactManager}
         */
        manager.$inject = ['BaseManager', 'PersonManager', 'EntrepriseManager', '$injector', 'UtilsManager', 'DictionariesManager', 'CommuneManager', '$q'];
        function manager(baseManager: NgManager,
                         personManager: NgPersonManager,
                         entrepriseManager: NgEntrepriseManager,
                         $injector: IInjectorService,
                         utilsManager: NgUtilsManager,
                         dictionariesManager: NgDictionariesManager,
                         communeManager: NgCommuneManager,
                         $q: IQService) {
            let classResource = baseManager.getClass<NgContact>();
            let contactManager: NgContactManager;

            /**
             * Routing
             */
            baseManager.addRoute('contacts.cget-etude', {path: '/etude/contacts', method: 'GET'});
            baseManager.addRoute('contacts.get', {path: '/contacts/{id}', method: 'GET'});
            baseManager.addRoute('contacts.insert', {path: '/user/contacts', method: 'POST'});
            baseManager.addRoute('contacts.edit', {path: '/contacts/{id}', method: 'PUT'});

            /**
             * ContactManager object
             *
             * @constructor
             */
            angular.extend(ContactManager.prototype, baseManager.__proto__);
            ContactManager.prototype.eskManager = {prefixRoute: 'contacts', collectionName: 'items'};
            function ContactManager() {
            }

            /**
             * Create a Contact object
             *
             * @param ng2Contact
             */
            ContactManager.prototype.createFromNg2 = function (ng2Contact: Ng2Contact.default) {
                if (!ng2Contact) {
                    return $q.resolve(undefined!);
                }

                const contactData = {id: ng2Contact.id, uuid: ng2Contact.uuid} as NgContact;
                let communePromise: IPromise<NgCommune>;

                if (ng2Contact.communeId) {
                    communePromise = communeManager.getOneById(ng2Contact.communeId).then(commune => commune as NgCommune);
                } else if (ng2Contact.linkCommune) {
                    communePromise = communeManager.execLink(ng2Contact.linkCommune).then(commune => commune as NgCommune);
                } else {
                    communePromise = $q.resolve(undefined as unknown as NgCommune)
                }

                if (ng2Contact.isEntreprise()) {
                    const entreprise = {_links: {}} as NgEntreprise;
                    const ng2Entreprise = ng2Contact as Entreprise;

                    entreprise.adresse = ng2Entreprise.adresse;
                    entreprise.capital = ng2Entreprise.capital;
                    entreprise.codePostal = ng2Entreprise.codePostal;
                    entreprise.colorVariation = ng2Entreprise.colorVariation;
                    entreprise.comments = ng2Entreprise.comments;
                    entreprise.initiales = ng2Entreprise.initiales;
                    entreprise.emailAddress = ng2Entreprise.emailAddress;
                    entreprise.formeJuridique = ng2Entreprise.formeJuridique;
                    entreprise.mobile = ng2Entreprise.mobile;
                    entreprise.raisonSociale = ng2Entreprise.raisonSociale;
                    entreprise.siret = ng2Entreprise.siret;
                    entreprise.telephone = ng2Entreprise.telephone;
                    contactData.entreprise = entreprise;
                    contactData.type = 'entreprise';
                    communePromise.then(commune => {
                        if (commune) {
                            entreprise.commune = commune;
                            entreprise._links.commune = {href: '/communes/' + commune.id.toString()};
                        }
                    });
                } else {
                    const person = {_links: {}} as NgPerson;
                    const ng2Person = ng2Contact as Person;

                    person.adresse = ng2Person.adresse;
                    person.autresPrenoms = ng2Person.autresPrenoms;
                    person.codePostal = ng2Person.codePostal;
                    person.colorVariation = ng2Person.colorVariation;
                    person.comments = ng2Person.comments;
                    person.communeNaissance = ng2Person.communeNaissance;
                    person.dateNaissance = ng2Person.dateNaissance;
                    person.emailAddress = ng2Person.emailAddress;
                    person.etranger = ng2Person.etranger;
                    person.initiales = ng2Person.initiales;
                    person.mobile = ng2Person.mobile;
                    person.nationalite = ng2Person.nationalite;
                    person.nom = ng2Person.nom;
                    person.nomJeuneFille = ng2Person.nomJeuneFille;
                    person.prenom = ng2Person.prenom;
                    person.profession = ng2Person.profession;
                    person.telephone = ng2Person.telephone;
                    person.titre = dictionariesManager.createFromNg2(ng2Person.titre);
                    contactData.person = person;
                    contactData.type = 'person';
                    communePromise.then(commune => {
                        if (commune) {
                            person.commune = commune;
                            person._links.commune = {href: '/communes/' + commune.id.toString()};
                        }
                    });
                }

                return communePromise.then(_ => {
                    const contact = this.create(contactData) as NgContact;
                    let representant$ = $q.resolve();

                    contact.externalId = ng2Contact.externalId;
                    contact.externalSource = ng2Contact.externalSource;
                    contact.formuleAdressePostale = ng2Contact.formuleAdressePostale;
                    contact.formuleIntroduction = ng2Contact.formuleIntroduction;
                    contact.formulePolitesse = ng2Contact.formulePolitesse;
                    contact.invalidEmailAddress = ng2Contact.invalidEmailAddress;
                    contact.unsubscribed = ng2Contact.unsubscribed;
                    contact[contact.type]?.updateConvivialName();
                    if (ng2Contact.representant) {
                        representant$ = this.createFromNg2(ng2Contact.representant);
                    }

                    return representant$
                        .then(representant => contact.representant = representant!)
                        .then(() => contact);
                });
            };

            /**
             * Returns the promise to fetch collection of objects
             *
             * @param queryParams
             * @returns {*}
             */
            ContactManager.prototype.getAllForEtude = function (queryParams: unknown) {
                return this.get('cget-etude', queryParams);
            };

            /**
             * Create a Contact object
             *
             * @param data
             * @returns manager.Contact object || Array of manager.Contact objects
             */
            ContactManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    const contacts = [];

                    for (let i = 0; i < data.length; i++) {
                        // @ts-ignore
                        contacts.push(new Contact(data[i]));
                    }

                    return contacts;
                }

                // @ts-ignore
                return new Contact(data);
            };

            /**
             * Contact object
             *
             * @param data
             * @constructor
             */
            angular.extend(Contact.prototype, classResource.prototype);
            Contact.prototype.eskManager = {prefixRoute: 'contacts'};
            function Contact(this: NgContact, data: unknown) {
                this.type = 'person';
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
                this._esk.oldEmailAddress = this[this.type]!.emailAddress;
            }

            /**
             * Extend the existing Contact with new data
             *
             * @param data
             * @returns {Contact}
             */
            Contact.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);
                this.getType(this.type);

                if (angular.isObject(this.representant)) this.representant = contactManager.create(this.representant);

                return this;
            };

            /**
             * Return recipients
             *
             * @returns {Array}
             */
            Contact.prototype.getRecipients = function (type = EmailRecipient.types.TO) {
                const recipients = [] as unknown[];

                if (angular.isString(this[this.type].emailAddress) && this[this.type].emailAddress !== '') {
                    recipients.push({
                        emailAddress: this[this.type].emailAddress,
                        type,
                        name: this[this.type].convivialName,
                    });
                }

                if (angular.isObject(this.representant) && angular.isString(this.representant[this.representant.type].emailAddress) && this.representant[this.representant.type].emailAddress !== '') {
                    recipients.push({
                        emailAddress: this.representant[this.representant.type].emailAddress,
                        type,
                        name: this.representant[this.representant.type].convivialName,
                    });
                }

                return recipients;
            };

            /**
             * Get/Set type
             *
             * @param type
             * @returns {*}
             */
            Contact.prototype.getType = function (type: string) {
                if (arguments.length) {
                    this.type = type;

                    if (this.type === 'person') {
                        this.person = personManager.create(this.person);
                    } else if (this.type === 'entreprise') {
                        this.entreprise = entrepriseManager.create(this.entreprise);
                    }

                    return this;
                }

                return this.type;
            };

            /**
             * Sanitize object Contact before send to API
             *
             * @returns {Object}
             */
            Contact.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                if (sanitizeObject.type === 'entreprise') {
                    delete sanitizeObject.person;
                    sanitizeObject.entreprise = this.entreprise.sanitize();
                } else {
                    delete sanitizeObject.entreprise;
                    sanitizeObject.person = this.person.sanitize();
                }

                delete sanitizeObject.representantId;
                utilsManager.flattenIdForAttribute(sanitizeObject, 'representant');

                return sanitizeObject;
            };

            /**
             * Save Contact
             *
             * @returns {Promise}
             */
            Contact.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(this).then(function () {
                    $injector.get<NgContactsEmailAddress>('ContactsEmailAddress').refreshInvalid();
                    self[self.type].updateConvivialName();
                    if (self._esk.oldEmailAddress !== self[self.type].emailAddress) {
                        self.invalidEmailAddress = false;
                        self._esk.oldEmailAddress = self[self.type].emailAddress;
                    }

                    return self;
                });
            };

            /**
             * Contact is valid
             *
             * @returns {boolean}
             */
            Contact.prototype.isValid = function () {
                return this[this.type].isValid();
            };

            // @ts-ignore
            contactManager = new ContactManager();

            return contactManager;
        }
    })(angularJS);
}
