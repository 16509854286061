import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-location-mandats', template: '<div appNgLocationMandats></div>'})
export class NgLocationMandatsComponent {
}

@Directive({selector: '[appNgLocationMandats]'})
export class NgLocationMandatsDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskLocationMandatsCtrl', elementRef, injector);
    }
}
