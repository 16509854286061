import AModel from '@models/model.abstract';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

export default class Filter<T = IQueryParameters> extends AModel {
    static readonly codes = {
        BOX_SEARCH: 'box_search',
        ESTIMATIONS_REFERENCES: 'estimations_references',
        SITES: 'sites',
        WALLET_CURRENT: 'wallet_current',
        USERS: 'users',
    };
    static readonly routes = {
        GET_ANNUAIRES: 'get_annuaires',
        GET_ETUDE_DEMANDEURS: 'get_etude_demandeurs',
        GET_ETUDE_ESTIMATIONS: 'get_etude_estimations',
        GET_ETUDE_LOCATIONS: 'get_etude_locations',
        GET_ETUDE_REPORTS: 'get_etude_reports',
        GET_ETUDE_VENTES: 'get_etude_ventes',
        GET_LOCATIONS: 'get_locations',
        GET_RECHERCHE_RESULTATS: 'get_recherche_resultats',
        GET_VENTES: 'get_ventes',
    };
    static readonly statuts = {NEW: AModel.STATUT_NEW};

    static useSessionStorage(route: string, code: string): boolean {
        return route === Filter.routes.GET_ANNUAIRES && (code === Filter.codes.USERS || code === Filter.codes.SITES);
    }

    private _code!: string;
    private readonly _id: number;
    private _queryParameters!: T;
    private _route!: string;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get id(): number {
        return this._id;
    }

    get queryParameters(): T {
        return this._queryParameters;
    }

    set queryParameters(value: T) {
        this._queryParameters = value;
    }

    get route(): string {
        return this._route;
    }

    set route(value: string) {
        this._route = value;
    }
}
