import {inject, Injectable} from '@angular/core';
import {EtudeSettingsLogoApiService} from '@models/etudes/etude/settings/logo/etude-settings-logo.api.service';
import Transfer from '@models/transfers/transfer/transfer.model';
import {TransferFactory} from '@models/transfers/transfer/transfer.factory';
import Etude from '@models/etudes/etude/etude.model';
import {Observable} from 'rxjs';
import {IMG_MAX_SIZE} from '@shared/image/image.constants';

@Injectable({providedIn: 'root'})
export class EtudeSettingsLogoFactory {
    static readonly imgLimit = {
        maxSize: IMG_MAX_SIZE,
        minHeight: 300,
        minWidth: 300,
    };
    private _etudeSettingsLogoApiService = inject(EtudeSettingsLogoApiService);
    private _transferFactory = inject(TransferFactory);

    delete$(etude: Etude): Observable<void> {
        return this._etudeSettingsLogoApiService.delete$(etude.uuid);
    }

    upload(etude: Etude, file: File): Transfer<void> {
        const formData = new FormData();

        formData.append('file', file);

        const transfer = this._transferFactory.create(this._etudeSettingsLogoApiService.upload$(etude.uuid, formData));

        transfer.start();

        return transfer;
    }
}
