import {inject, Injectable} from '@angular/core';
import BienCharge from '@models/bien/charges/charge/bien-charge.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgBienCharge} from '@legacy/app/managers/ressources';
import {IBienChargeApi} from '@models/bien/charges/charge/bien-charge.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BienChargesApiService} from '@models/bien/charges/bien-charges.api.service';

@Injectable({providedIn: 'root'})
export class BienChargeFactory {
    private _bienChargesApiService = inject(BienChargesApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(bienChargeApi: IBienChargeApi): BienCharge {
        const bienCharge = new BienCharge(bienChargeApi.uuid, bienChargeApi.id);

        bienCharge.comments = bienChargeApi.comments;
        bienCharge.cout = bienChargeApi.cout;
        if (bienChargeApi._embedded) {
            if (bienChargeApi._embedded.charge) {
                bienCharge.charge = this._dictionaryItemFactory.create(bienChargeApi._embedded.charge);
            }

            if (bienChargeApi._embedded.periodicite) {
                bienCharge.periodicite = this._dictionaryItemFactory.create(bienChargeApi._embedded.periodicite);
            }
        }

        return bienCharge;
    }

    forApi(bienCharge: BienCharge): IBienChargeApi {
        const bienChargeApi = {comments: bienCharge.comments, cout: bienCharge.cout} as IBienChargeApi;

        if (bienCharge.charge) {
            bienChargeApi.chargeId = bienCharge.charge.id;
        }

        if (bienCharge.periodicite) {
            bienChargeApi.periodiciteId = bienCharge.periodicite.id;
        }

        return bienChargeApi;
    }

    save$(dossierBien: ADossierBien, bienCharge: BienCharge): Observable<BienCharge> {
        return this._bienChargesApiService.save$(dossierBien.id.toString(), bienCharge.id?.toString() ?? BienCharge.STATUT_NEW, this.forApi(bienCharge))
            .pipe(map(venteCompromisApi => this.create(venteCompromisApi)));
    }

    ngCreate(ngBienCharge: NgBienCharge): BienCharge {
        const bienCharge = new BienCharge(ngBienCharge.uuid, ngBienCharge.id);

        bienCharge.comments = ngBienCharge.comments;
        bienCharge.cout = ngBienCharge.cout;
        if (ngBienCharge.charge) {
            bienCharge.charge = this._dictionaryItemFactory.ngCreate(ngBienCharge.charge);
        }

        if (ngBienCharge.periodicite) {
            bienCharge.periodicite = this._dictionaryItemFactory.ngCreate(ngBienCharge.periodicite);
        }

        return bienCharge;
    }
}
