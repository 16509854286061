import {inject, Injectable} from '@angular/core';
import {
    IEstimationOnsaleReferenceApi
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.interfaces';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    EstimationOnsaleReferencesApiService
} from '@models/estimations/estimation/onsale-references/estimation-onsale-references.api.service';
import DateFormat from '@shared/date/date.format';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';

@Injectable({providedIn: 'root'})
export class EstimationOnsaleReferenceFactory {
    private _estimationOnsaleReferencesApiService = inject(EstimationOnsaleReferencesApiService);

    create(estimationOnsaleReferenceApi: IEstimationOnsaleReferenceApi): EstimationOnsaleReference {
        const estimationOnsaleReference = this.createVirgin(estimationOnsaleReferenceApi.uuid);

        estimationOnsaleReference.adresse = estimationOnsaleReferenceApi.adresse;
        estimationOnsaleReference.communeId = estimationOnsaleReferenceApi.communeId;
        estimationOnsaleReference.description = estimationOnsaleReferenceApi.description;
        estimationOnsaleReference.imageUrl = estimationOnsaleReferenceApi.imageUrl;
        estimationOnsaleReference.natureId = estimationOnsaleReferenceApi.natureId;
        estimationOnsaleReference.nombrePieces = estimationOnsaleReferenceApi.nombrePieces;
        estimationOnsaleReference.prixVente = estimationOnsaleReferenceApi.prixVente;
        estimationOnsaleReference.publishedAt = estimationOnsaleReferenceApi.publishedAt;
        estimationOnsaleReference.sortableRank = estimationOnsaleReferenceApi.sortableRank;
        estimationOnsaleReference.superficie = estimationOnsaleReferenceApi.superficie;
        estimationOnsaleReference.surfaceCadastrale = estimationOnsaleReferenceApi.surfaceCadastrale;
        estimationOnsaleReference.url = estimationOnsaleReferenceApi.url;

        if (estimationOnsaleReferenceApi._links?.commune) {
            estimationOnsaleReference.linkCommune = estimationOnsaleReferenceApi._links.commune.href;
        }

        return estimationOnsaleReference;
    }

    createFromCityscanOnsaleProperty(cityscanOnsaleProperty: CityscanOnsaleProperty): EstimationOnsaleReference {
        const estimationOnsaleReference = this.createVirgin();

        estimationOnsaleReference.adresse = cityscanOnsaleProperty.adresse;
        estimationOnsaleReference.communeId = cityscanOnsaleProperty.communeId;
        estimationOnsaleReference.description = cityscanOnsaleProperty.description;
        estimationOnsaleReference.imageUrl = cityscanOnsaleProperty.imageUrl;
        estimationOnsaleReference.natureId = cityscanOnsaleProperty.natureId;
        estimationOnsaleReference.nombrePieces = cityscanOnsaleProperty.nombrePieces;
        estimationOnsaleReference.prixVente = cityscanOnsaleProperty.prixVente;
        estimationOnsaleReference.publishedAt = cityscanOnsaleProperty.publishedAt;
        estimationOnsaleReference.superficie = cityscanOnsaleProperty.superficie;
        estimationOnsaleReference.surfaceCadastrale = cityscanOnsaleProperty.surfaceCadastrale;
        estimationOnsaleReference.url = cityscanOnsaleProperty.url;

        return estimationOnsaleReference;
    }

    createVirgin(uuid?: string): EstimationOnsaleReference {
        return new EstimationOnsaleReference(uuid ?? EstimationOnsaleReference.statuts.NEW);
    }

    delete$(estimation: Estimation, estimationOnsaleReference: EstimationOnsaleReference): Observable<void> {
        return this._estimationOnsaleReferencesApiService.delete$(estimation.id.toString(), estimationOnsaleReference.uuid);
    }

    forApi(estimationOnsaleReference: EstimationOnsaleReference): IEstimationOnsaleReferenceApi {
        const estimationOnsaleReferenceApi = {} as IEstimationOnsaleReferenceApi;

        estimationOnsaleReferenceApi.adresse = estimationOnsaleReference.adresse;
        estimationOnsaleReferenceApi.communeId = estimationOnsaleReference.communeId;
        estimationOnsaleReferenceApi.description = estimationOnsaleReference.description;
        estimationOnsaleReferenceApi.imageUrl = estimationOnsaleReference.imageUrl;
        estimationOnsaleReferenceApi.natureId = estimationOnsaleReference.natureId;
        estimationOnsaleReferenceApi.nombrePieces = estimationOnsaleReference.nombrePieces;
        estimationOnsaleReferenceApi.prixVente = estimationOnsaleReference.prixVente;
        estimationOnsaleReferenceApi.publishedAt = DateFormat.toAPI(estimationOnsaleReference.publishedAt);
        estimationOnsaleReferenceApi.superficie = estimationOnsaleReference.superficie;
        estimationOnsaleReferenceApi.surfaceCadastrale = estimationOnsaleReference.surfaceCadastrale;
        estimationOnsaleReferenceApi.url = estimationOnsaleReference.url;

        return estimationOnsaleReferenceApi;
    }

    save$(estimation: Estimation, estimationOnsaleReference: EstimationOnsaleReference): Observable<EstimationOnsaleReference> {
        return this._estimationOnsaleReferencesApiService.save$(estimation.id.toString(), estimationOnsaleReference.uuid, this.forApi(estimationOnsaleReference))
            .pipe(map(estimationOnsaleReferenceApi => this.create(estimationOnsaleReferenceApi)));
    }

    updateRank$(estimation: Estimation, estimationOnsaleReference: EstimationOnsaleReference, rank: number): Observable<void> {
        return this._estimationOnsaleReferencesApiService.updateRank$(estimation.id.toString(), estimationOnsaleReference.uuid, rank);
    }
}
