<div class="tw-max-w-[1200px] tw-mx-auto tw-py-4 tw-space-y-8">
  <app-bien-overview-photos [dossierBien]="dossierBien" (clicked)="clickedBienPhoto($event)" class="tw-block"
                            [options]="{linkPhotosAttribute: isInfosConfidentialVisible ? (dossierBien.interne ? 'linkPrivatePhotos' : 'linkProtectedPhotos') : 'linkPhotos'}"/>

  <app-dossier-bien-overview-main-infos [dossierBien]="dossierBien" class="tw-block" [options]="options"
                                        [isInfosConfidentialVisible]="isInfosConfidentialVisible"/>

  @if (isPartLogementsVisible) {
    <app-dossier-bien-overview-logements [dossierBien]="dossierBien" class="tw-block"/>
  }

  @if (isPartSurfacesVisible) {
    <app-dossier-bien-overview-surfaces [dossierBien]="dossierBien" class="tw-block"/>
  }

  @if (isPartInfosVisible) {
    <app-dossier-bien-overview-infos [dossierBien]="dossierBien" class="tw-block"/>
  }

  <app-dossier-bien-overview-infos-confidentielles [dossierBien]="dossierBien" class="tw-block"
                                                   [infosConfidentialWanted$]="infosConfidentialWanted$"
                                                   (isInfosConfidential)="setIsInfosConfidentialVisible($event)"/>
</div>
