export default `
<div class="criteria">
  <div ng-if="!$ctrl.options.disableSecondaryCriteria" class="tabs-container">
    <ul class="nav nav-pills">
      <li ng-repeat="tab in $ctrl.tabs" ng-class="tab.key === $ctrl.currentTab.key ? 'active' : ''" class="nav-item"
          ng-if="tab.key === 'general' || ($ctrl.search.option.source !== $ctrl.CriteriaSourceConst.CITYSCAN && $ctrl.search.option.source !== $ctrl.CriteriaSourceConst.REFERENCE)">
        <a ng-click="$ctrl.currentTab = tab" class="nav-link">{{tab.value}}</a>
      </li>
    </ul>
  </div>

  <ng-switch on="$ctrl.currentTab.key">
    <div ng-switch-when="general">
      <ng-include src="'src/app/legacy/templates/eskimmo/components/search/criteria/general.html'"></ng-include>
    </div>

    <div ng-switch-when="secondary">
      <ng-include src="'src/app/legacy/templates/eskimmo/components/search/criteria/secondary.html'"></ng-include>
    </div>
  </ng-switch>
</div>
`;
