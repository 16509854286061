import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEskSelectVoie(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-voie></esk-select-voie>
         */
        module.component('eskSelectVoie', {
            bindings: {isRequired: '=', name: '@', ngModel: '=', onSelect: '=', commune: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/voie.html'
        });

        /**
         * Select voie
         *
         * @constructor
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.refresh = refresh;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.voies = [];
                $ctrl.ngRequired = $ctrl.isRequired === true ? true : null;
            }

            /**
             * Refresh voie
             *
             * @param search
             */
            function refresh(search: string) {
                return $ctrl.commune?.getVoies?.(search).then((paginatorVoies: { items: unknown }) => $ctrl.voies = paginatorVoies.items);
            }
        }
    })(angularJS);
}
