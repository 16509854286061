import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AuthInterceptor} from '@core/auth/core/auth-interceptor';
import {AuthService} from '@core/auth/core/auth.service';

@NgModule({
    imports: [],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        AuthService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    ],
})
export class AuthModule {
}
