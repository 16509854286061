export default `
<p class="text-warning">
  Cette référence cadastrale est identique à celle saisie pour le bien à vendre
  <span class="text-bold">{{cadastre._esk.doubleMandat.bienReference}}</span>
  <span ng-if="cadastre._esk.doubleMandat.type === 'doubleMandat'">
    de l'étude <span class="text-bold">{{cadastre._esk.doubleMandat.etudeRaisonSociale}}</span>.
    <br>Conformément à la charte, votre bien à vendre ne sera pas envoyé sur le réseau.
  </span>
  <span ng-if="cadastre._esk.doubleMandat.type === 'doublon'">de votre étude.</span>
  <br>
  Vous pourrez tout de même en faire la publicité.
</p>
`;
