@if (currentEtude$ | async; as currentEtude) {
  <div class="tw-relative tw-h-full tw-w-full">
    <img class="tw-min-h-full tw-object-cover tw-mx-auto"
         [ngSrc]="cityscanOnsaleProperty.imageUrl || currentEtude.linkMarianne" fill>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-full'" class="tw-block tw-h-full"/>
}


