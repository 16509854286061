import {inject, Injectable} from '@angular/core';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {NgContactsGroup} from '@legacy/app/managers/ressources';
import {IContactsGroupApi} from '@models/contacts-group/contacts-group.interfaces';
import Contact from '@models/contacts/contact/contact.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContactsGroupApiService} from '@models/contacts-group/contacts-group.api.service';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {ContactsGroupMembersService} from '@models/contacts-group/members/contacts-group-members.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {IDossierBienDiffusionEtatApi} from '@models/dossiers/biens/bien/dossier-bien.interfaces';

@Injectable({providedIn: 'root'})
export class ContactsGroupFactory {
    private _contactsGroupApiService = inject(ContactsGroupApiService);
    private _contactsGroupMembersService = inject(ContactsGroupMembersService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);

    create(contactsGroupApi: IContactsGroupApi): ContactsGroup {
        const contactsGroup: ContactsGroup = this.createVirgin(contactsGroupApi.id?.toString(), contactsGroupApi.id);

        contactsGroup.formuleAdressePostale = contactsGroupApi.formuleAdressePostale;
        contactsGroup.formuleIntroduction = contactsGroupApi.formuleIntroduction;
        contactsGroup.formulePolitesse = contactsGroupApi.formulePolitesse;
        contactsGroup.nom = contactsGroupApi.nom;
        if (contactsGroupApi._embedded) {
            if (contactsGroupApi._embedded.members) {
                contactsGroup.members = this._contactsGroupMembersService.create(contactsGroupApi._embedded.members);
            }

            if (contactsGroupApi._embedded.typeGroup) {
                contactsGroup.typeGroup = this._dictionaryItemFactory.create(contactsGroupApi._embedded.typeGroup);
            }
        }

        if (contactsGroupApi._links?.self) {
            contactsGroup.linkSelf = contactsGroupApi._links.self.href;
        } else if (contactsGroup.id) {
            // À supprimer quand l'API retournera le lien
            contactsGroup.linkSelf = '/contacts-groups/' + contactsGroup.id.toString();
        }

        return contactsGroup;
    }

    createFromContact(contact: Contact): ContactsGroup {
        const contactsGroup = this.createVirgin();

        contactsGroup.members = this._contactsGroupMembersService.createFromContact(contact);
        contactsGroup.nom = contact.convivialName;

        return contactsGroup;
    }

    createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi: IDossierBienDiffusionEtatApi): ContactsGroup {
        const contactsGroup = this.createVirgin();

        contactsGroup.nom = dossierBienDiffusionEtatApi.nomProprietairesGroup;

        return contactsGroup;
    }

    createVirgin(uuid?: string, id?: number): ContactsGroup {
        const contactsGroup = new ContactsGroup(uuid ?? ContactsGroup.statuts.NEW, id);

        contactsGroup.typeGroup = this._dictionaryItemService.getById(Dictionary.names.CONTACTS_GROUP_TYPES, 1);

        return contactsGroup;
    }

    forApi(contactsGroup: ContactsGroup): IContactsGroupApi {
        const contactsGroupApi = {} as IContactsGroupApi;

        contactsGroupApi.nom = contactsGroup.nom;
        contactsGroupApi.typeGroupId = contactsGroup.typeGroup.id;

        return contactsGroupApi;
    }

    getByLink$(link: string): Observable<ContactsGroup> {
        return this._contactsGroupApiService.getByLink$(link).pipe(map(contactsGroupApi => this.create(contactsGroupApi)));
    }

    save$(contactsGroup: ContactsGroup): Observable<ContactsGroup> {
        return this._contactsGroupApiService.save$(contactsGroup.uuid, this.forApi(contactsGroup)).pipe(map(contactsGroupApi => this.create(contactsGroupApi)));
    }

    ngCreate(ngContactsGroup: NgContactsGroup): ContactsGroup {
        const contactsGroup: ContactsGroup = this.createVirgin(ngContactsGroup.id?.toString(), ngContactsGroup.id);

        contactsGroup.formuleAdressePostale = ngContactsGroup.formuleAdressePostale;
        contactsGroup.formuleIntroduction = ngContactsGroup.formuleIntroduction;
        contactsGroup.formulePolitesse = ngContactsGroup.formulePolitesse;
        contactsGroup.nom = ngContactsGroup.nom;

        if (ngContactsGroup._links?.self) {
            contactsGroup.linkSelf = ngContactsGroup._links.self.href;
        } else if (contactsGroup.id) {
            // À supprimer quand l'API retournera le lien
            contactsGroup.linkSelf = '/contacts-groups/' + contactsGroup.id.toString();
        }

        if (ngContactsGroup.members) {
            contactsGroup.members = this._contactsGroupMembersService.ngCreate(ngContactsGroup.members);
        }

        if (ngContactsGroup.typeGroup) {
            contactsGroup.typeGroup = this._dictionaryItemFactory.ngCreate(ngContactsGroup.typeGroup);
        }

        return contactsGroup;
    }
}
