import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ITemplateHeaderApi} from '@models/templates/headers/header/template-header.interfaces';
import {ICTemplateHeadersApi} from '@models/templates/headers/collection/template-headers.collection.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';
import environment from '@env/environment';

@Injectable({providedIn: 'root'})
export class TemplateHeadersApiService {
    static readonly THUMBNAIL_DEFAULT = environment.api.baseUrl + '/assets/documents/header/thumbnail-default.png';

    private _modelApiService = inject(ModelApiService);

    // @todo Renommer en getCollection$ comme les autres models
    getHeaders$(): Observable<ICTemplateHeadersApi> {
        return this._modelApiService.getCollection$('/templates/headers');
    }

    getPreview$(templateHeaderApi: ITemplateHeaderApi, httpOptions?: HttpOptions): Observable<ITemplateHeaderApi> {
        return this._modelApiService.post$('/templates/headers/preview', templateHeaderApi, httpOptions);
    }
}
