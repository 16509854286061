export default `
<ui-select soq-multiple="true" ng-model="$ctrl.ngModel" uis-open-close="$ctrl.isOpenClose(isOpen)"
           on-select="$ctrl.onSelect($item, $model)" name="{{$ctrl.name}}" remove-selected="false">
  <ui-select-match allow-clear="{{!$ctrl.ngRequired}}">{{$item.label}}</ui-select-match>

  <ui-select-choices repeat="itemDictionary in $ctrl.itemsDictionary | filter: $select.search"
                     ui-disable-choice="$ctrl.isSelected(itemDictionary)">
    <span ng-bind-html="itemDictionary.label | highlight: $select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
