<!-- Obliger de spécifier "print!" pour surcharger bootstrap et son "_print.scss" -->
<div class="tw-flex tw-justify-center">
  <div [ngClass]="{
      'tw-bg-blue-100 tw-text-blue-800 print:!tw-bg-blue-100 print:!tw-text-blue-800': color === STATUT_COLORS.BLUE,
      'tw-bg-green-100 tw-text-green-800 print:!tw-bg-green-100 print:!tw-text-green-800': color === STATUT_COLORS.GREEN,
      'tw-bg-orange-100 tw-text-orange-600 print:!tw-bg-orange-100 print:!tw-text-orange-600': color === STATUT_COLORS.ORANGE,
      'tw-bg-red-50 tw-text-red-500 print:!tw-bg-red-50 print:!tw-text-red-500': color === STATUT_COLORS.RED,
      'tw-bg-rose-50 tw-text-rose-600 print:!tw-bg-rose-50 print:!tw-text-rose-600': color === STATUT_COLORS.ROSE,
      'tw-bg-stone-600 tw-text-stone-100 print:!tw-bg-stone-600 print:!tw-text-stone-100': color === STATUT_COLORS.STONE
      }"
       class="tw-rounded-full tw-px-3 tw-py-1 tw-font-semibold tw-whitespace-nowrap first-letter:tw-uppercase">
    {{label}}
  </div>
</div>
