import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {AppInotUsersSelectComponent} from '@features/inot/users-select/inot.users-select.component';
import {AppInotRequestErrorComponent} from '@features/inot/request-error/inot.request-error.component';
import {AppInotLoaderComponent} from '@features/inot/loader/inot.loader.component';

@NgModule({
    declarations: [AppInotLoaderComponent, AppInotRequestErrorComponent, AppInotUsersSelectComponent],
    exports: [AppInotLoaderComponent, AppInotRequestErrorComponent, AppInotUsersSelectComponent],
    imports: [CommonModule, SharedModule],
})
export class InotModule {
}
