import { error } from '../utils/utils.js';
const isJqueryElement = elem => typeof elem === 'object' && elem.jquery;
const isElement = elem => elem instanceof Element || isJqueryElement(elem);
export const argsToParams = args => {
  const params = {};
  if (typeof args[0] === 'object' && !isElement(args[0])) {
    Object.assign(params, args[0]);
  } else {
    ;
    ['title', 'html', 'icon'].forEach((name, index) => {
      const arg = args[index];
      if (typeof arg === 'string' || isElement(arg)) {
        params[name] = arg;
      } else if (arg !== undefined) {
        error(`Unexpected type of ${name}! Expected "string" or "Element", got ${typeof arg}`);
      }
    });
  }
  return params;
};