import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise} from 'angular';
import {NgClientService} from '@legacy/app/client/client';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {firstValueFrom} from 'rxjs';

export default function getManagersEtudeStatistics(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('EtudeStatisticsManager', Service);

        /**
         * Manager template
         *
         * @param ClientService
         * @param Ng2EtudeService
         * @constructor
         */
        Service.$inject = ['ClientService', 'Ng2EtudeService'];
        function Service(this: any, clientService: NgClientService, ng2EtudeService: EtudeService) {
            const self = this;

            self.getEtudeActivity = getEtudeActivity;
            self.getEtudeNegociationRevenus = getEtudeNegociationRevenus;
            self.getVentesNaturesRepartition = getVentesNaturesRepartition;
            self.getVentesNegociees = getVentesNegociees;
            self.getVentesNegocieesNatures = getVentesNegocieesNatures;
            self.getFilledStatistics = getFilledStatistics;

            /**
             * Routing
             */
            clientService.addRoute('etude-statistics.etude-wallet', {
                path: '/etudes/{etudeId}/statistics/{statisticName}',
                method: 'GET'
            });

            /**
             * Get activity of etude
             *
             * @returns {promise}
             */
            function getEtudeActivity(): IPromise<any> {
                return self.getFilledStatistics('etude-wallet', {statisticName: 'activity'});
            }

            /**
             * Get negociation revenus of etude
             *
             * @param params
             * @returns {promise}
             */
            function getEtudeNegociationRevenus(params: any): IPromise<any> {
                return self.getFilledStatistics('etude-wallet', angular.extend({statisticName: 'chiffre_affaires'}, params));
            }

            /**
             * Get Ventes by natures repartition
             *
             * @returns {promise}
             */
            function getVentesNaturesRepartition(): IPromise<any> {
                return self.getFilledStatistics('etude-wallet', {statisticName: 'ventes_natures_distribution'});
            }

            /**
             * Get Ventes negociees
             *
             * @returns {promise}
             */
            function getVentesNegociees(): IPromise<any> {
                return self.getFilledStatistics('etude-wallet', {statisticName: 'ventes_negociees'});
            }

            /**
             * Get Ventes negociees by natures
             *
             * @param params
             * @returns {promise}
             */
            function getVentesNegocieesNatures(params: any): IPromise<any> {
                return self.getFilledStatistics('etude-wallet', angular.extend({statisticName: 'ventes_negociees_nature'}, params));
            }

            /**
             * Get filled statistics
             *
             * @param suffix
             * @param params
             * @returns {promise}
             */
            function getFilledStatistics(suffix: string, params: any): IPromise<any> {
                return firstValueFrom(ng2EtudeService.last$).then(currentEtude => {
                    params.etudeId = currentEtude.id;

                    return clientService.execRoute('etude-statistics.' + suffix, params);
                });
            }
        }
    })(angularJS);
}
