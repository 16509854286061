export default `
<ui-select on-select="$ctrl.onSelect($ctrl.options.fullObject ? $select.selected : $select.selected.person)"
           ng-model="$ctrl.ngModel" spinner-enabled="true">
  <ui-select-match placeholder="{{ $ctrl.options.placeholder }}">
    {{$select.selected.person.convivialName}}
  </ui-select-match>

  <ui-select-choices repeat="participant in $ctrl.participants | filter: $select.search"
                     ui-disable-choice="$ctrl.disabled(participant)"
                     refresh="$ctrl.getResearchedParticipants($select.search)" refresh-delay="500"
                     minimum-input-length="2">
    <p>
      {{ participant.person.convivialName }}
    </p>
  </ui-select-choices>

  <ui-select-no-choice ng-if="!$ctrl.options.otherwise">
    <p class="text-muted">Modifier votre recherche</p>
  </ui-select-no-choice>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
