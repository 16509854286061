import {NgModule} from '@angular/core';
import {AppMediaConvivialNameComponent} from '@features/medias/media/convivial-name/media.convivial-name.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {DCMediaRenameComponent} from '@features/medias/media/rename/media.rename.component';
import {CommunesModule} from '@features/communes/communes.module';

@NgModule({
    declarations: [AppMediaConvivialNameComponent, DCMediaRenameComponent],
    exports: [AppMediaConvivialNameComponent],
    imports: [CommonModule, SharedModule, CommunesModule],
})
export class MediasModule {
}
