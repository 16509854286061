export default `
<soq-panel box="$ctrl.box">
  <tools-slot>
    <a class="btn btn-transparent" target="_blank" href="https://openweathermap.org/">
      <fa-icon [icon]="'external-link-alt'"></fa-icon> OpenWeatherMap
    </a>
  </tools-slot>

  <content-slot><esk-meteo-widget></esk-meteo-widget></content-slot>
</soq-panel>
`;
