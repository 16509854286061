@if (cVentePrixHistories$ | appLoadObs | async; as cVentePrixHistories) {
  @if (cVentePrixHistories.value && cVentePrixHistories.value.isRaising) {
    <fa-icon [icon]="'arrow-right'" class="tw-ml-1 tw-text-orange-500"
             [title]="vente.labelPrice[0].toUpperCase() + vente.labelPrice.slice(1) + ' a augmenté'"
             style="transform: rotate(-45deg); display: inline-block;"/>
  }
  @if (cVentePrixHistories.value && cVentePrixHistories.value.isDecreasing) {
    <fa-icon [icon]="'arrow-right'" class="tw-ml-1 tw-text-green-500"
             [title]="vente.labelPrice[0].toUpperCase() + vente.labelPrice.slice(1) + ' a baissé'"
             style="transform: rotate(45deg); display: inline-block;"/>
  }
} @else {
  <app-loader class="tw-block tw-w-4"/>
}
