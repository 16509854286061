import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgContactManager, NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgContactsGroupMember} from '@legacy/app/managers/ressources';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';

export default function getManagersContactsGroupMember(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ContactsGroupMemberManager', manager);

        /**
         * Manager contact
         *
         * @param BaseManager
         * @param UtilsManager
         * @param ContactManager
         * @param $q
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'ContactManager', '$q'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         contactManager: NgContactManager,
                         $q: IQService) {
            let classResource = baseManager.getClass<NgContactsGroupMember>();

            /**
             * Routing
             */
            baseManager.addRoute('contacts-group-members.insert', {
                path: '/contacts-groups/{contactsGroupId}/members',
                method: 'POST'
            });
            baseManager.addRoute('contacts-group-members.update-rank', {
                path: '/contacts-groups/{contactsGroupId}/members/{id}/rank',
                method: 'PATCH'
            });

            /**
             * ContactsGroupMemberManager object
             *
             * @constructor
             */
            angular.extend(ContactsGroupMemberManager.prototype, baseManager.__proto__);
            ContactsGroupMemberManager.prototype.eskManager = {
                prefixRoute: 'contacts-group-members',
                collectionName: 'items'
            };

            function ContactsGroupMemberManager() {
            }

            /**
             * Create a ContactsGroupMember object
             *
             * @param ng2ContactsGroupMember
             * @returns manager.ContactsGroupMember
             */
            ContactsGroupMemberManager.prototype.createFromNg2 = function (ng2ContactsGroupMember: ContactsGroupMember) {
                if (!ng2ContactsGroupMember) {
                    return $q.resolve(undefined!);
                }

                return contactManager.createFromNg2(ng2ContactsGroupMember.contact).then(contact =>
                    this.create({contact, sortableRank: ng2ContactsGroupMember.sortableRank})
                );
            };

            /**
             * Create a ContactsGroupMember object
             *
             * @param data
             * @param contactsGroupId
             * @returns manager.ContactsGroupMember object || Array of manager.ContactsGroupMember objects
             */
            ContactsGroupMemberManager.prototype.create = function (data: unknown, contactsGroupId: number) {
                if (angular.isArray(data)) {
                    var members = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        members.push(new ContactsGroupMember(contactsGroupId, data[i]));
                    }

                    return members;
                }

                // @ts-ignore
                return new ContactsGroupMember(contactsGroupId, data);
            };

            /**
             * ContactsGroupMember object
             *
             * @param contactsGroupId
             * @param data
             * @constructor
             */
            angular.extend(ContactsGroupMember.prototype, classResource.prototype);
            ContactsGroupMember.prototype.eskManager = {prefixRoute: 'contacts-group-members'};
            function ContactsGroupMember(this: NgContactsGroupMember, contactsGroupId: number, data: unknown) {
                this.extend(data);
                this.id = angular.isNumber(this.contact.id) as unknown as number;
                this._esk.defaultRouteParams = {id: this.contact.id, contactsGroupId};
                this._esk.persist = this.id as unknown as boolean;
                this._esk.flush = this._esk.persist;
            }

            /**
             * Extend the existing ContactsGroupMember with new data
             *
             * @param data
             * @returns {ContactsGroupMember}
             */
            ContactsGroupMember.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.contact = contactManager.create(this.contact);

                return this;
            };

            /**
             * Sanitize object ContactsGroupMember before send to API
             *
             * @returns {Object}
             */
            ContactsGroupMember.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet ContactsGroupMember pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'contact');

                return sanitizeObject;
            };

            // @ts-ignore
            return new ContactsGroupMemberManager();
        }
    })(angularJS);
}
