import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {DeviceDetectorService} from 'ngx-device-detector';

export default function getEskBrowserTools(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('EskBrowserTools', Service);

        /**
         * EskBrowserTools service
         *
         * @param Ng2DeviceDetectorService
         */
        Service.$inject = ['Ng2DeviceDetectorService'];
        function Service(this: any, ng2DeviceDetectorService: DeviceDetectorService) {
            this.browserIsOudated = browserIsOudated;
            this.getConvivialName = getConvivialName;

            /**
             * Return if browser is Outated
             *
             * @return boolean
             */
            function browserIsOudated() {
                // *** browser version listing *** : https://caniuse.com/#comparison
                switch (ng2DeviceDetectorService.browser) {
                    case 'IE' :
                        return true;
                    case 'ms-edge' :
                    case 'chrome' :
                    case 'firefox' :
                        return +ng2DeviceDetectorService.browser_version.split('.')[0] < 75;
                    default :
                        return false;
                }
            }

            /**
             * Get Convivial Name Of current Browser
             *
             * @return string
             */
            function getConvivialName() {
                switch (ng2DeviceDetectorService.browser) {
                    case 'IE' :
                        return 'Internet Explorer';
                    case 'MS-Edge' :
                        return 'Edge';
                    default :
                        return ng2DeviceDetectorService.browser;
                }
            }
        }
    })(angularJS);
}
