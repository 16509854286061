export default `
<div class="text-justify">
  <p class="text-bold">
    Addition des champs suivants :
  </p>

  <p>
    - Loyer chargé avec le complément
    <br>
    - Montant total dû
    <span ng-if="$ctrl.dossier.bien.nature.code === $ctrl.NATURE_LOCAUX_COMMERCIAUX">
      <br>
      - Droit au bail
    </span>
  </p>
</div>
`;
