import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {
    NgBien, NgBienDossier, NgContactsGroup, NgEmail, NgEmailRecipient, NgTemplate
} from '@legacy/app/managers/ressources';
import {NgEmailDossierManager, NgEmailRecipientManager, NgMediaManager} from '@legacy/app/managers/managers';
import {DocumentsService} from '@models/documents/documents.service';
import {firstValueFrom} from 'rxjs';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {DossierBienType, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {map, switchMap, take} from 'rxjs/operators';
import Bien from '@models/bien/bien.model';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';

export default function getBiensUtils(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BiensUtils', Factory);

        /**
         * @param $q
         * @param $translate
         * @param SoqSweetAlert
         * @param EmailRecipientManager
         * @param EmailDossierManager
         * @param SoqModalesManager
         * @param Ng2ToasterService
         * @param Ng2DocumentsService
         * @param MediaManager
         * @param Ng2DossierBienFactory
         * @param Ng2CTemplatesService
         */
        Factory.$inject = ['$q', '$translate', 'SoqSweetAlert', 'EmailRecipientManager', 'EmailDossierManager', 'SoqModalesManager', 'Ng2ToasterService', 'Ng2DocumentsService', 'MediaManager', 'Ng2DossierBienFactory', 'Ng2CTemplatesService'];
        function Factory($q: IQService,
                         $translate: angular.translate.ITranslateService,
                         soqSweetAlert: NgSoqSweetAlert,
                         emailRecipientManager: NgEmailRecipientManager,
                         emailDossierManager: NgEmailDossierManager,
                         soqModalesManager: NgSoqModalesManager,
                         ng2ToasterService: ToasterService,
                         ng2DocumentsService: DocumentsService,
                         mediaManager: NgMediaManager,
                         ng2DossierBienFactory: DossierBienFactory,
                         cTemplatesService: CTemplatesService) {
            return {
                getUISortableOptions: getUISortableOptions,
                showFieldForBien: showFieldForBien,
                showPanelForBien: showPanelForBien,
                sendGroupedEmail: sendGroupedEmail,
                getMediasFiche: getMediasFiche
            };

            /**
             * Returns UI Sortable options
             *
             * @returns {object}
             */
            function getUISortableOptions(): ng.ui.UISortableOptions<any> {
                return {
                    handle: '.drag-handler',
                    placeholder: 'placeholder',
                    update: function (e, ui: ng.ui.UISortableUIParams<any>) {
                        if (!angular.isObject(ui) || !angular.isObject(ui.item) || !angular.isObject(ui.item.sortable) || !angular.isObject(ui.item.sortable.model)) {
                            return;
                        }

                        if (ui.item.sortable.dropindex + 1 <= ui.item.sortable.sourceModel.length) {
                            ui.item.sortable.model.updateRank(ui.item.sortable.dropindex + 1);
                        } else {
                            ui.item.sortable.cancel();
                        }
                    }
                };
            }

            /**
             * Returns if a field has to be shown
             *
             * @param bien
             * @param field
             * @returns {boolean}
             */
            function showFieldForBien(bien: NgBien, field: string): boolean {
                const excludeFields: Record<string, string[]> = {
                    accesHandicape: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    alarmeLocation: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    alarmePleinePropriete: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    anneeConstruction: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    annexe: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    ascenseur: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    aucunChauffage: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    balcon: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chambreRdc: [Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chargesLocatives: [Bien.natures.IMMEUBLE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, DossierBienTypesConst.LOCATION],
                    charpentes: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chauffageCollectif: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chauffages: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cave: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cheminee: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chemineeFonctionnelle: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    chemineeOrnement: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    citerneGazLocation: [Bien.natures.APPARTEMENT],
                    citerneGazPleinePropriete: [Bien.natures.APPARTEMENT],
                    clotures: [Bien.natures.APPARTEMENT],
                    comblesAmenageables: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    commentsBatiment: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    connexionsInternet: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cour: [Bien.natures.APPARTEMENT, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cuisineEquipee: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    cuveFuelAerienne: [Bien.natures.APPARTEMENT],
                    cuveFuelEnterree: [Bien.natures.APPARTEMENT],
                    cuveInactiveDegazee: [Bien.natures.APPARTEMENT],
                    cuveInactiveNeutralizee: [Bien.natures.APPARTEMENT],
                    dateDisponibilite: [Bien.natures.IMMEUBLE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, DossierBienTypesConst.LOCATION],
                    declivite: [Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE],
                    degreDeclivite: [Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE],
                    dernierEtage: [Bien.natures.MAISON, Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.AUTRE],
                    designation: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    detecteurFumee: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    exceptionnel: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    epoqueConstruction: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    etat: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    garage: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    grenier: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    hauteurSousPlafond: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    huisseries: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    isolations: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    logements: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.AUTRE],
                    longueurFacade: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    loyerActuel: [Bien.natures.IMMEUBLE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, DossierBienTypesConst.LOCATION],
                    materiauxConstruction: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    materiauxCouverture: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    mitoyennete: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreBains: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreChambres: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreCheminees: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreDouches: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreEtages: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreEtagesBatiment: [Bien.natures.MAISON, Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreLogements: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.AUTRE],
                    nombrePieces: [Bien.natures.IMMEUBLE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreSallesBains: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreSallesEau: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    nombreWc: [Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    numeroEtage: [Bien.natures.MAISON, Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    occupation: [Bien.natures.IMMEUBLE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, DossierBienTypesConst.LOCATION],
                    performanceEnergetique: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    pieceEauRdc: [Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    pieces: [Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    piscine: [Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    plainPied: [Bien.natures.IMMEUBLE, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    residence: [Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    residenceService: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    sousSol: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    statutDiagnostic: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    statutDpe: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceAnnexe: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceBalcon: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceBatie: [Bien.natures.APPARTEMENT, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.BUREAUX],
                    surfaceCarrez: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceCave: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceCour: [Bien.natures.APPARTEMENT, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceGarage: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceNonBatie: [Bien.natures.APPARTEMENT, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.BUREAUX],
                    surfacePlancher: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.AUTRE],
                    surfaceSejour: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    surfaceTerrasse: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    terrasse: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    usage: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    veranda: [Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    viabilisable: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    viabilisationEau: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    viabilisationElectricite: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    viabilisationFibre: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    viabilisationGaz: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    viabilisationTelecom: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    viabilisationVoirie: [Bien.natures.MAISON, Bien.natures.APPARTEMENT, Bien.natures.IMMEUBLE, Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.AUTRE],
                    volets: [Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    wcBroyeur: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                };

                if (!angular.isObject(bien) || !angular.isObject(bien.nature) || !bien.nature.code || !field || !excludeFields[field]) {
                    return true;
                }

                return !excludeFields[field].includes(bien.nature.code) && !excludeFields[field].includes(bien._esk.type!);
            }

            /**
             * Returns if a panel has to be shown
             *
             * @param bien
             * @param panel
             * @returns {boolean}
             */
            function showPanelForBien(bien: NgBien, panel: string): boolean {
                const excludePanels: Record<string, string[]> = {
                    agencementsInterieurs: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    batiment: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    habitation: [Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN],
                    loiAlur: [Bien.natures.BUREAUX, Bien.natures.LOCAUX_COMMERCIAUX, Bien.natures.GARAGE, Bien.natures.TERRAIN_A_BATIR, Bien.natures.AUTRE_TERRAIN, Bien.natures.AUTRE],
                };

                if (!bien || !bien.nature || !bien.nature.code || !panel || !excludePanels[panel]) {
                    return true;
                }

                return !excludePanels[panel].includes(bien.nature.code);
            }

            /**
             * Send email to Bien contactGroups
             *
             * @param dossiers
             * @param email
             */
            function sendGroupedEmail(dossiers: NgBienDossier[], email: NgEmail): IPromise<void> {
                const noRecipientsContacts: NgContactsGroup[] = [];
                let promise = $q.resolve();

                if (dossiers.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('bien.grouped_action.mailing.TITLE'), $translate.instant('bien.grouped_action.mailing.no_selected.MESSAGE'));

                    return $q.reject();
                }

                if (!angular.isArray(email.dossiers)) {
                    email.dossiers = [];
                }

                email.recipients = [];
                angular.forEach(dossiers, function (dossier) {
                    var recipients;

                    if (dossier._esk.select) {
                        recipients = dossier.bien.contactsGroup.getRecipients();

                        if (recipients.length > 0) {
                            email.recipients = email.recipients.concat(emailRecipientManager.create(recipients, email.id) as NgEmailRecipient);
                            email.dossiers.push(emailDossierManager.create({
                                typeId: dossier.id,
                                type: dossier._esk.typeDossier,
                                dossier,
                            }, email.id));
                        } else {
                            noRecipientsContacts.push(dossier.bien.contactsGroup);
                        }
                    }
                });

                if (email.recipients.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('bien.grouped_action.mailing.TITLE'), $translate.instant('bien.grouped_action.mailing.invalid.MESSAGE'));

                    return $q.reject();
                }

                if (noRecipientsContacts.length > 0) {
                    let contactsNames = "";

                    angular.forEach(noRecipientsContacts, function (contactsGroup) {
                        contactsNames += " - " + contactsGroup.nom + "<br/>";
                    });

                    promise = soqSweetAlert.warningMessage($translate.instant('bien.grouped_action.mailing.TITLE'),
                        $translate.instant('bien.grouped_action.mailing.no_email.MESSAGE', {
                            nbContacts: noRecipientsContacts.length,
                            contactsNames,
                        }));
                }

                return promise.then(function () {
                    angular.forEach(email.recipients, function (recipient) {
                        recipient.type = EmailRecipient.types.BCC;
                    });

                    return soqModalesManager.open<void>('EskModalEmail', {
                        resolve: {email: email, currentDossier: {}},
                    });
                });
            }

            /**
             * Get medias for printed fiche
             *
             * @param dossierBienType
             * @param dossiers
             */
            function getMediasFiche(dossierBienType: DossierBienType, dossiers: NgBienDossier[]) {
                if (dossiers.length <= 0) {
                    ng2ToasterService.warning("Envoi des fiches", "Sélectionnez au moins une fiche à envoyer.");

                    return $q.reject('NO_PRINT_FICHE');
                }

                return firstValueFrom(cTemplatesService.getWithDefaultFirst$(['fiche-' + dossierBienType]).pipe(
                    map(templatesCollection => templatesCollection.results[0]),
                    switchMap(template => ng2DocumentsService.getMediaFromEachDossierBiens$(
                        dossiers.map(ngDossierBien => ng2DossierBienFactory.ngCreate(ngDossierBien)),
                        template,
                    )),
                    map(medias => medias.map(media => mediaManager.createFromNg2(media))),
                    map(ngMedias => ngMedias.map(ngMedia => ({media: ngMedia}))),
                    take(1),
                ));
            }
        }
    })(angularJS);
}
