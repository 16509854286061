import {inject, Injectable} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {BienFactory} from '@models/bien/bien.factory';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NgVente} from '@legacy/app/managers/ressources';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import {VentesApiService} from '@models/ventes/ventes.api.service';
import {IVenteApi, IVenteDiffusionEtatApi} from '@models/ventes/vente/vente.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DateFormat from '@shared/date/date.format';
import {VenteCreditRentierFactory} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.factory';
import {VenteCreditRentiersFactory} from '@models/ventes/vente/credit-rentier/vente-credit-rentiers.factory';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';

@Injectable({providedIn: 'root'})
export class VenteFactory {
    private _bienFactory = inject(BienFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _venteCompromisFactory = inject(VenteCompromisFactory);
    private _venteCreditRentierFactory = inject(VenteCreditRentierFactory);
    private _venteCreditRentiersFactory = inject(VenteCreditRentiersFactory);
    private _ventePriceFactory = inject(VentePriceFactory);
    private _ventesApiService = inject(VentesApiService);

    clone$(vente: Vente, keepProprietaires: boolean): Observable<Vente> {
        return this._ventesApiService.clone$(vente.uuid, keepProprietaires).pipe(map(venteApi => this.create(venteApi)));
    }

    create(venteApi: IVenteApi): Vente {
        const reference = venteApi._embedded?.bien?.reference;
        const vente = this.createVirgin(venteApi.uuid, venteApi.id, reference);

        vente.archiveAcquereur = venteApi.archiveAcquereur;
        vente.archiveComments = venteApi.archiveComments;
        vente.archiveDateVente = venteApi.archiveDateVente;
        vente.archiveDemandeurId = venteApi.archiveDemandeurId;
        vente.archiveEtudeAcquereurId = venteApi.archiveEtudeAcquereurId;
        vente.archiveHonorairesNego = venteApi.archiveHonorairesNego;
        vente.archiveHonorairesNegoPercus = venteApi.archiveHonorairesNegoPercus;
        vente.archivePrixCession = venteApi.archivePrixCession;
        vente.dateCreation = venteApi.dateCreation;
        vente.estimationMax = venteApi.estimationMax;
        vente.estimationMin = venteApi.estimationMin;
        vente.fraisActe = venteApi.fraisActe;
        vente.honorairesNego = venteApi.honorairesNego;
        vente.interactiveDateFin = venteApi.interactiveDateFin;
        vente.interactiveFraisPub = venteApi.interactiveFraisPub;
        vente.interactivePasOffre = venteApi.interactivePasOffre;
        vente.interactivePremiereOffre = venteApi.interactivePremiereOffre;
        vente.interactivePrixReserve = venteApi.interactivePrixReserve;
        vente.interactiveSystemeEncheres = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_INTERACTIVE_ENCHERES_SYSTEMES,
            venteApi.interactiveSystemeEncheres || Vente.CODES_DEFAULT.interactiveSystemeEncheres);
        vente.mandatDate = venteApi.mandatDate;
        vente.mandatDateFin = venteApi.mandatDateFin;
        vente.mandatType = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_MANDAT_TYPES, venteApi.mandatType || Vente.CODES_DEFAULT.mandatType);
        vente.prixAffiche = venteApi.prixAffiche;
        vente.prixVente = venteApi.prixVente;
        vente.referencePublique = venteApi.referencePublique;
        vente.statut = venteApi.statut;
        vente.type = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_TYPES, venteApi.type || Vente.CODES_DEFAULT.type);
        vente.typeCharges = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, venteApi.typeCharges || Vente.CODES_DEFAULT.typeCharges);
        vente.viagerBouquet = venteApi.viagerBouquet;
        vente.viagerIndexation = venteApi.viagerIndexation;
        vente.viagerNombreTetes = venteApi.viagerNombreTetes;
        vente.viagerRente = venteApi.viagerRente;
        // @todo Faire le tour des models pour s'assurer qu'il n'y a pas de "*.push()" mais une assignation avec "="
        vente.viagerTetes = (venteApi.viagerTetes || [])
            .filter(viagerTete => viagerTete)
            .map(viagerTete => this._venteCreditRentierFactory.create(viagerTete));
        vente.viagerValeurBien = venteApi.viagerValeurBien;
        vente.setInterne(venteApi.interne);
        if (venteApi._embedded) {
            vente.viagerPeriodiciteRente = this._dictionaryItemService.getById(Dictionary.names.PERIODICITES,
                venteApi._embedded.viagerPeriodiciteRente?.id || Vente.IDS_DEFAULT.viagerPeriodiciteRente);

            if (venteApi._embedded.bien) {
                vente.bien = this._bienFactory.create(venteApi._embedded.bien);
            }

            if (venteApi._embedded.compromis) {
                vente.compromisList = venteApi._embedded.compromis
                    .filter(compromis => compromis)
                    .map(compromis => this._venteCompromisFactory.create(compromis));
            }
        }

        if (venteApi._links) {
            if (venteApi._links.archiveDemandeur) {
                vente.linkArchiveDemandeur = venteApi._links.archiveDemandeur.href;
            }

            if (venteApi._links.archiveEtudeAcquereur) {
                vente.linkArchiveEtudeAcquereur = venteApi._links.archiveEtudeAcquereur.href;
            }

            if (venteApi._links.compromisActif) {
                vente.linkCompromisActif = venteApi._links.compromisActif.href;
            }

            if (venteApi._links.contactsGroup) {
                vente.linkContactsGroup = venteApi._links.contactsGroup.href;
            }

            if (venteApi._links.etude) {
                vente.linkEtude = venteApi._links.etude.href;
            }

            if (venteApi._links.self) {
                vente.linkSelf = venteApi._links.self.href;
            }
        }

        if (venteApi.archiveRaisonVenteNegociee) {
            vente.archiveRaisonVenteNegociee = this._dictionaryItemService
                .getByCode(Dictionary.names.VENTE_ARCHIVE_NEGOCIEE_RAISONS, venteApi.archiveRaisonVenteNegociee);
        }

        if (venteApi.archiveRaisonVenteNonNegociee) {
            vente.archiveRaisonVenteNonNegociee = this._dictionaryItemService
                .getByCode(Dictionary.names.VENTE_ARCHIVE_NON_NEGOCIEE_RAISONS, venteApi.archiveRaisonVenteNonNegociee);
        }

        if (venteApi.archiveType) {
            vente.archiveType = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, venteApi.archiveType);
        }

        return vente;
    }

    createFromVenteDiffusionEtat(venteDiffusionEtatApi: IVenteDiffusionEtatApi): Vente {
        const vente = this.createVirgin(null!, venteDiffusionEtatApi.id, venteDiffusionEtatApi.reference);

        vente.bien = this._bienFactory.createFromDossierBienDiffusionEtat(venteDiffusionEtatApi);
        vente.type = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_TYPES, venteDiffusionEtatApi.type);
        vente.typeCharges = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, venteDiffusionEtatApi.codeTypeCharges);

        return vente;
    }

    createVirgin(uuid = Vente.statuts.NEW, id?: number, reference?: string): Vente {
        return new Vente(uuid, id, reference);
    }

    forApi(vente: Vente): IVenteApi {
        const venteApi = {
            archiveAcquereur: vente.archiveAcquereur,
            archiveComments: vente.archiveComments,
            archiveDateVente: DateFormat.toAPI(vente.archiveDateVente),
            archiveDemandeurId: vente.archiveDemandeurId,
            archiveEtudeAcquereurId: vente.archiveEtudeAcquereurId,
            archiveHonorairesNego: vente.archiveHonorairesNego,
            archiveHonorairesNegoPercus: vente.archiveHonorairesNegoPercus,
            archivePrixCession: vente.archivePrixCession,
            archiveRaisonVenteNegociee: vente.archiveRaisonVenteNegociee?.code,
            archiveRaisonVenteNonNegociee: vente.archiveRaisonVenteNonNegociee?.code,
            archiveType: vente.archiveType?.code,
            bien: this._bienFactory.forApi(vente.bien),
            estimationMax: vente.estimationMax,
            estimationMin: vente.estimationMin,
            fraisActe: vente.fraisActe,
            honorairesNego: vente.honorairesNego,
            mandatDate: DateFormat.toAPI(vente.mandatDate),
            mandatDateFin: DateFormat.toAPI(vente.mandatDateFin),
            mandatType: vente.mandatType?.code,
            referencePublique: vente.referencePublique,
            type: vente.type?.code,
            typeCharges: vente.typeCharges?.code,
        } as IVenteApi;

        if (vente.isInteractive()) {
            venteApi.interactiveDateFin = DateFormat.toAPI(vente.interactiveDateFin);
            venteApi.interactiveFraisPub = vente.interactiveFraisPub;
            venteApi.interactivePasOffre = vente.interactivePasOffre;
            venteApi.interactivePremiereOffre = vente.interactivePremiereOffre;
            venteApi.interactivePrixReserve = vente.interactivePrixReserve;
            venteApi.interactiveSystemeEncheres = vente.interactiveSystemeEncheres?.code;
            venteApi.mandatType = Vente.mandatTypes.EXCLUSIF;
        } else if (vente.isSimple()) {
            venteApi.prixVente = vente.prixVente;
        } else if (vente.isViager()) {
            venteApi.viagerBouquet = vente.viagerBouquet;
            venteApi.viagerIndexation = vente.viagerIndexation;
            venteApi.viagerNombreTetes = vente.viagerNombreTetes;
            venteApi.viagerRente = vente.viagerRente;
            venteApi.viagerValeurBien = vente.viagerValeurBien;

            if (vente.viagerNombreTetes > 0 && vente.viagerTetes.length > 0) {
                venteApi.viagerTetes = this._venteCreditRentiersFactory.forApi(vente.viagerTetes);
            }

            if (vente.viagerRente > 0) {
                venteApi.viagerPeriodiciteRenteId = vente.viagerPeriodiciteRente?.id;
            }
        }

        return venteApi;
    }

    get$(uuid: string): Observable<Vente> {
        return this._ventesApiService.get$(uuid).pipe(map(venteApi => this.create(venteApi)));
    }

    getByLink$(link: string): Observable<Vente> {
        return this._ventesApiService.getByLink$(link).pipe(map(venteApi => this.create(venteApi)));
    }

    getPriceM2(vente: Vente, useArchivePrixCession = false): number {
        if (vente.bien.superficie <= 0) {
            return null!;
        }

        if (useArchivePrixCession) {
            return (vente.archivePrixCession ?? 0) / vente.bien.superficie;
        }

        const {priceHNI} = this._ventePriceFactory.createFromVente(vente);

        return priceHNI / vente.bien.superficie;
    }

    networkDiffuse$(vente: Vente): Observable<void> {
        return this._ventesApiService.networkDiffuse$(vente.id.toString());
    }

    openDetailsInNewTab$(vente: Vente): Observable<Window> {
        return this._ventesApiService.openDetailsInNewTab$(vente.id.toString());
    }

    openOverviewInNewTab$(vente: Vente): Observable<Window> {
        return this._ventesApiService.openOverviewInNewTab$(vente.id.toString());
    }

    save$(vente: Vente): Observable<Vente> {
        return this._ventesApiService.save$(vente.id?.toString() || Vente.statuts.NEW, this.forApi(vente)).pipe(map(venteApi => this.create(venteApi)));
    }

    ngCreate(ngVente: NgVente): Vente {
        const reference = ngVente.bien ? ngVente.bien.reference : undefined;
        const sanitizedNgVente = ngVente.sanitize() as NgVente;
        const vente = this.createVirgin(ngVente.uuid, ngVente.id, reference);

        vente.archiveAcquereur = ngVente.archiveAcquereur;
        vente.archiveComments = ngVente.archiveComments;
        vente.archiveDateVente = DateFormat.toJSON(ngVente.archiveDateVente);
        vente.archiveDemandeurId = sanitizedNgVente.archiveDemandeurId;
        vente.archiveEtudeAcquereurId = sanitizedNgVente.archiveEtudeAcquereurId;
        vente.archiveHonorairesNego = sanitizedNgVente.archiveHonorairesNego;
        vente.archiveHonorairesNegoPercus = sanitizedNgVente.archiveHonorairesNegoPercus;
        vente.archivePrixCession = sanitizedNgVente.archivePrixCession;
        vente.dateCreation = DateFormat.toJSON(sanitizedNgVente.dateCreation);
        vente.estimationMax = sanitizedNgVente.estimationMax;
        vente.estimationMin = sanitizedNgVente.estimationMin;
        vente.fraisActe = sanitizedNgVente.fraisActe;
        vente.honorairesNego = sanitizedNgVente.honorairesNego;
        vente.interactiveDateFin = DateFormat.toJSON(ngVente.interactiveDateFin);
        vente.interactiveFraisPub = sanitizedNgVente.interactiveFraisPub;
        vente.interactivePasOffre = sanitizedNgVente.interactivePasOffre;
        vente.interactivePremiereOffre = sanitizedNgVente.interactivePremiereOffre;
        vente.interactivePrixReserve = sanitizedNgVente.interactivePrixReserve;
        vente.mandatDate = DateFormat.toJSON(sanitizedNgVente.mandatDate);
        vente.mandatDateFin = DateFormat.toJSON(sanitizedNgVente.mandatDateFin);
        vente.prixAffiche = sanitizedNgVente.prixAffiche;
        vente.prixVente = sanitizedNgVente.prixVente;
        vente.referencePublique = ngVente.referencePublique;
        vente.statut = ngVente.statut;
        vente.viagerBouquet = sanitizedNgVente.viagerBouquet;
        vente.viagerIndexation = ngVente.viagerIndexation;
        vente.viagerNombreTetes = sanitizedNgVente.viagerNombreTetes;
        vente.viagerRente = sanitizedNgVente.viagerRente;
        // Ce sont déjà des instances de VenteCreditRentier
        vente.viagerTetes = ngVente.viagerTetes;
        vente.viagerValeurBien = sanitizedNgVente.viagerValeurBien;
        vente.setInterne(sanitizedNgVente.interne);
        if (ngVente._links) {
            if (ngVente._links.archiveDemandeur) {
                vente.linkArchiveDemandeur = ngVente._links.archiveDemandeur.href;
            }

            if (ngVente._links.archiveEtudeAcquereur) {
                vente.linkArchiveEtudeAcquereur = ngVente._links.archiveEtudeAcquereur.href;
            }

            if (ngVente._links.compromisActif) {
                vente.linkCompromisActif = ngVente._links.compromisActif.href;
            }

            if (ngVente._links.contactsGroup) {
                vente.linkContactsGroup = ngVente._links.contactsGroup.href;
            }

            if (ngVente._links.etude) {
                vente.linkEtude = ngVente._links.etude.href;
            }

            if (ngVente._links.self) {
                vente.linkSelf = ngVente._links.self.href;
            }
        }

        if (ngVente.archiveRaisonVenteNegociee) {
            vente.archiveRaisonVenteNegociee = this._dictionaryItemFactory.ngCreate(ngVente.archiveRaisonVenteNegociee);
        }

        if (ngVente.archiveRaisonVenteNonNegociee) {
            vente.archiveRaisonVenteNonNegociee = this._dictionaryItemFactory.ngCreate(ngVente.archiveRaisonVenteNonNegociee);
        }

        if (ngVente.archiveType) {
            vente.archiveType = this._dictionaryItemFactory.ngCreate(ngVente.archiveType);
        }

        if (ngVente.bien) {
            vente.bien = this._bienFactory.ngCreate(ngVente.bien);
        }

        if (ngVente.compromis) {
            vente.compromisList = ngVente.compromis
                .filter(ngVenteCompromis => ngVenteCompromis)
                .map(ngVenteCompromis => this._venteCompromisFactory.ngCreate(ngVenteCompromis));
        }

        if (ngVente.interactiveSystemeEncheres) {
            vente.interactiveSystemeEncheres = this._dictionaryItemFactory.ngCreate(ngVente.interactiveSystemeEncheres);
        }

        if (ngVente.mandatType) {
            vente.mandatType = this._dictionaryItemFactory.ngCreate(ngVente.mandatType);
        }

        if (ngVente.type) {
            vente.type = this._dictionaryItemFactory.ngCreate(ngVente.type);
        }

        if (ngVente.typeCharges) {
            vente.typeCharges = this._dictionaryItemFactory.ngCreate(ngVente.typeCharges);
        }

        if (ngVente.viagerPeriodiciteRente) {
            vente.viagerPeriodiciteRente = this._dictionaryItemFactory.ngCreate(ngVente.viagerPeriodiciteRente);
        }

        return vente;
    }
}
