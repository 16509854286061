import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgUtilsManager} from '@legacy/app/managers/managers';
import {NgBienDiagnostic} from '@legacy/app/managers/ressources';
import DateFormat from '@shared/date/date.format';

export default function getManagersBienDiagnostic(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienDiagnosticManager', manager);

        /**
         * Manager diagnostic
         *
         * @param UtilsManager
         * @param BaseManager
         * @returns {BienDiagnosticManager}
         */
        manager.$inject = ['UtilsManager', 'BaseManager'];
        function manager(utilsManager: NgUtilsManager, baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('diagnostics.cget', {path: '/biens/{bienId}/diagnostics', method: 'GET'});
            baseManager.addRoute('diagnostics.insert', {path: '/biens/{bienId}/diagnostics', method: 'POST'});
            baseManager.addRoute('diagnostics.edit', {path: '/biens/{bienId}/diagnostics/{id}', method: 'PUT'});
            baseManager.addRoute('diagnostics.update-rank', {
                path: '/biens/{bienId}/diagnostics/{id}/rank',
                method: 'PATCH'
            });
            baseManager.addRoute('diagnostics.remove', {path: '/biens/{bienId}/diagnostics/{id}', method: 'DELETE'});

            /**
             * BienDiagnosticManager object
             *
             * @constructor
             */
            angular.extend(BienDiagnosticManager.prototype, baseManager.__proto__);
            BienDiagnosticManager.prototype.eskManager = {prefixRoute: 'diagnostics', collectionName: 'items'};
            function BienDiagnosticManager() {
            }

            /**
             * Create a BienDiagnostic object
             *
             * @param data
             * @param bienId
             * @returns manager.BienDiagnostic object || Array of manager.BienDiagnostic objects
             */
            BienDiagnosticManager.prototype.create = function (data: unknown, bienId: number) {
                if (angular.isArray(data)) {
                    var diagnostics = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        diagnostics.push(new BienDiagnostic(bienId, data[i]));
                    }

                    return diagnostics;
                }

                // @ts-ignore
                return new BienDiagnostic(bienId, data);
            };

            /**
             * BienDiagnostic object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(BienDiagnostic.prototype, classResource.prototype);
            BienDiagnostic.prototype.eskManager = {prefixRoute: 'diagnostics'};
            function BienDiagnostic(this: NgBienDiagnostic, bienId: number, data: unknown) {
                this.extend(data);
                this.uuid = this.id?.toString();
                this._esk.defaultRouteParams = {id: this.id, bienId: bienId};
            }

            /**
             * Extend the existing BienDiagnostic with new data
             *
             * @param data
             * @returns {BienDiagnostic}
             */
            BienDiagnostic.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isString(this.date)) {
                    this.date = DateFormat.toDate(this.date);
                }

                return this;
            };

            /**
             * Sanitize object BienDiagnostic before send to API
             *
             * @returns {Object}
             */
            BienDiagnostic.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                sanitizeObject.date = DateFormat.toAPI(this.date);

                // Transformation des propriétés de l'objet BienDiagnostic pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'typeDiagnostic');

                return sanitizeObject;
            };

            /**
             * Test validity and fill _esk.errors
             *
             * @returns {boolean}
             */
            BienDiagnostic.prototype.isValid = function () {
                const sanitizeObject = this.sanitize();

                this._esk.errors = {typeDiagnostic: !sanitizeObject.typeDiagnosticId};

                return !this._esk.errors.typeDiagnostic;
            };

            /**
             * Test if BienDiagnostic is empty
             *
             * @returns {boolean}
             */
            BienDiagnostic.prototype.isEmpty = function () {
                return !angular.isObject(this.typeDiagnostic)
                    && (!angular.isDefined(this.date) || this.date === '')
                    && (!angular.isString(this.comments) || this.comments === '');
            };

            // @ts-ignore
            return new BienDiagnosticManager();
        }
    })(angularJS);
}
