export default `
<div ng-repeat="dictionaryItem in $ctrl.dictionaryGroups.items">
  <div ng-if="($ctrl.templates | eskTemplateByTags:dictionaryItem.tags).length > 0" class="row tw-mb-8">
    <div ng-if="dictionaryItem" class="col-xs-12 tw-text-center"><h3>{{dictionaryItem.label | translate}}</h3></div>

    <div ng-repeat="template in $ctrl.templates | eskTemplateByTags:dictionaryItem.tags" ng-click="$ctrl.ngModel = template;"
         class="{{$ctrl.colClass ? $ctrl.colClass : 'col-xs-6 col-sm-3'}}">
      <div class="template-item" ng-class="$ctrl.ngModel.id === template.id ? 'selected' : ''">
        <img ng-src="{{template.thumbnailUrl}}" class="img-responsive" loading="lazy">
      </div>

      <p class="tw-text-center text-muted">{{template.size + " " + template.orientation + " - " + template.name}}</p>
    </div>
  </div>
</div>
`;
