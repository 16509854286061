<div class="tw-form-2col">
  @if (etudeSettingsNetwork$ | async; as etudeSettingsNetwork) {
    @if (hasRoleVente$ | appLoadObs | async; as hasRoleVente) {
      @if (hasRoleVente.value) {
        <div class="tw-form-2col-part">
          <div class="tw-form-left-col">
            <h3 class="tw-form-left-col-title">Partage</h3>

            <p class="tw-form-left-col-explain">
              Configurez les options de partage de vos données avec les autres études.
            </p>
          </div>

          <div class="tw-form-right-col tw-space-y-8">
            <div class="tw-space-y-2">
              <div class="tw-flex tw-gap-4">
                <app-form-switch id="matchingDemandeurs" [model]="etudeSettingsNetwork.matchingDemandeurs"
                                 (clicked)="updateMatchingDemandeurs(etudeSettingsNetwork, $event)"
                                 [loading]="matchingDemandeursLoading"/>

                <label for="matchingDemandeurs" class="tw-text-sm tw-text-gray-700">
                  Visualisez le nombre de demandeurs correspondants sur le réseau.
                </label>
              </div>

              <p>
                Pour chacun de vos biens en vente, vous pouvez voir quelles études ainsi que le nombre de demandeurs
                correspondants de celles-ci. Vos confrères, ayant activé cette option, verront à leur tour le nombre de
                vos demandeurs correspondants à chacun de leurs biens en vente.
              </p>
            </div>

            <div class="tw-space-y-2">
              <div class="tw-flex tw-gap-4">
                <app-form-switch id="compromisActif" [model]="etudeSettingsNetwork.compromisActif"
                                 (clicked)="updateCompromisActif(etudeSettingsNetwork, $event)"
                                 [loading]="compromisActifLoading"/>

                <label for="compromisActif" class="tw-text-sm tw-text-gray-700">
                  Visualisez pour un bien confrère les informations de mise sous compromis.
                </label>
              </div>

              <p>
                Pour les biens en vente des confrères, qui auront également activé cette option, vous aurez le détail
                des informations de mise en compromis.
              </p>
            </div>
          </div>
        </div>
      }
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
    }
  } @else {
    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
  }
</div>
