import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {HttpErrorResponse, HttpEventType, HttpResponse} from '@angular/common/http';
import {HttpEventProgress} from '@core/api/api.interfaces';

export default class Transfer<T, U = T> {
    static readonly ABORT = 'Annulation manuelle';
    private _isAbort = false;
    private _isError = false;
    private _isInProgress = false;
    private _isSuccess = false;
    private _progressSource = new BehaviorSubject<number>(0);
    private _progress$ = this._progressSource.asObservable();
    private _raceSource = new ReplaySubject<void>(1);
    private _response!: U;

    constructor(request$: Observable<HttpEventProgress | HttpResponse<T>>, creation: (responseBrute: T) => U) {
        let uploadSubscription: Subscription;

        this.race$.subscribe({
            next: () => {
                uploadSubscription = request$.subscribe({
                    next: (event: HttpEventProgress | HttpResponse<T>) => {
                        if (event instanceof HttpResponse) {
                            this.onSuccess(creation, event.body!);
                        } else if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
                            this.onProgress(event.loaded!, event.total!);
                        }
                    },
                    error: (httpErrorResponse: HttpErrorResponse) => this.onError(httpErrorResponse)
                });
            },
            error: () => uploadSubscription?.unsubscribe(),
        });
    }

    get isAbort(): boolean {
        return this._isAbort;
    }

    get isError(): boolean {
        return this._isError;
    }

    get isInProgress(): boolean {
        return this._isInProgress;
    }

    get isPending(): boolean {
        return !this._isAbort && !this._isError && !this._isInProgress && !this._isSuccess;
    }

    get isSuccess(): boolean {
        return this._isSuccess;
    }

    get progress$(): Observable<number> {
        return this._progress$;
    }

    get race$(): Observable<void> {
        return this._raceSource.asObservable();
    }

    get response(): U {
        return this._response;
    }

    abort(): void {
        this._isAbort = true;
        this.onError(Transfer.ABORT);
    }

    onError(error: unknown): void {
        this._isInProgress = false;
        this._progressSource.complete();
        this._isError = true;
        this._raceSource.error(error);
    }

    onProgress(bytesTrandfered: number, bytesTotal: number): void {
        this._progressSource.next(Math.round(bytesTrandfered / bytesTotal * 100));
    }

    onSuccess(creation: (data: T) => U, responseBrute: T): void {
        if (responseBrute) {
            this._response = creation(responseBrute);
        }

        this.onProgress(1, 1);
        this._isInProgress = false;
        this._progressSource.complete();
        this._isSuccess = true;
        this._raceSource.complete();
    }

    start(): void {
        if (!this._isError) {
            this._isInProgress = true;
            this._raceSource.next();
        }
    }
}
