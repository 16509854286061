import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEskPagination(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-pagination></esk-pagination>
         */
        module.component('eskPagination', {
            bindings: {
                paginator: '=',
                infiniteScroll: '=',
                infiniteScrollBtnText: '@',
                loader: '=',
                onChange: '=?'
            },
            templateUrl: 'src/app/legacy/templates/eskimmo/components/pagination.html',
            controller: Controller
        });

        /**
         * Controller of eskPagination
         *
         * @constructor
         */
        Controller.$inject = [] as string[];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.nextPage = nextPage;
            $ctrl.changeCurrentPage = changeCurrentPage;

            /**
             * Get next page
             *
             * @param reload
             * @returns {Promise}
             */
            function nextPage(reload: boolean) {
                $ctrl.loader.updating = true;

                return $ctrl.paginator.nextPage(reload).then(successCallback).finally(finallyCallback);
            }

            /**
             * Get defined page
             *
             * @returns {Promise}
             */
            function changeCurrentPage() {
                $ctrl.loader.updating = true;

                return $ctrl.paginator.getPageFromNumber($ctrl.paginator.current).then(successCallback).finally(finallyCallback);
            }

            /**
             * Final action
             */
            function successCallback(data: unknown) {
                if (angular.isFunction($ctrl.onChange)) {
                    return $ctrl.onChange(data);
                }

                return data;
            }

            /**
             * Final action
             */
            function finallyCallback() {
                $ctrl.loader.updating = false;
            }
        }
    })(angularJS);
}
