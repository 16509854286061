import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-form-error',
    template: '@if (error) {<div class="error text-small tw-block">{{error}}</div>}'
})
export class AppFormErrorComponent {
    private _error!: string;

    get error(): string {
        return this._error;
    }

    @Input()
    set error(value: string) {
        this._error = value;
    }
}
