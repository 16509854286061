import {inject, Injectable} from '@angular/core';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {NgContactsGroup} from '@legacy/app/managers/ressources';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {ContactsGroupMembersService} from '@models/contacts-group/members/contacts-group-members.service';
import {map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContactsGroupService {
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _contactsGroupMembersService = inject(ContactsGroupMembersService);
    private _ngCreatedSource = new Subject<NgContactsGroup>();
    private _ngCreated$ = this._ngCreatedSource.asObservable();

    get ngCreated$(): Observable<NgContactsGroup> {
        return this._ngCreated$;
    }

    getOneByLink$(link?: string): Observable<ContactsGroup> {
        if (!link) {
            return of(this._contactsGroupFactory.createVirgin());
        }

        return this._contactsGroupFactory.getByLink$(link);
    }

    save$(contactsGroupToSave: ContactsGroup): Observable<ContactsGroup> {
        return this._contactsGroupFactory.save$(contactsGroupToSave).pipe(switchMap(contactsGroup =>
            this._contactsGroupMembersService.save$(contactsGroup, contactsGroupToSave.members).pipe(
                tap(contactsGroupMembers => contactsGroup.members = contactsGroupMembers),
                map(_ => contactsGroup),
            )
        ));
    }

    ngOneCreated(ngContactsGroup: NgContactsGroup): void {
        this._ngCreatedSource.next(ngContactsGroup);
    }
}
