@if (cContactsGroupMembers$ | async; as cContactsGroupMembers) {
  <div class="tw-overflow-hidden">
    <table id="dossierMembers" class="tw-table-head-separate-body"
           [appCollectionSelection]="{collection: cContactsGroupMembers, listName: options.nameSelection!, options: {initSelectAll: options.initSelectAll}}">
      <thead>
      <tr>
        <th><div>Contact</div></th>

        <th class="tw-hidden lg:tw-table-cell"><div>Téléphone</div></th>

        <th class="tw-hidden md:tw-table-cell"><div>Email</div></th>
      </tr>
      </thead>

      <tbody>
        @for (contactsGroupMember of cContactsGroupMembers.results; track contactsGroupMember) {
          <tr>
            <td>
              <app-contact-convivial-name [contact]="contactsGroupMember.contact"
                                          class="tw-block tw-truncate tw-w-full"/>
            </td>

            <td class="tw-hidden lg:tw-table-cell">
              <app-text-phones [phones]="[contactsGroupMember.contact.telephone, contactsGroupMember.contact.mobile]"/>
            </td>

            <td class="tw-hidden md:tw-table-cell ">
              @if (contactsGroupMember.contact.emailAddress) {
                <a href="mailto:{{contactsGroupMember.contact.emailAddress}}" target="_blank">
                  {{ contactsGroupMember.contact.emailAddress }}
                </a>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}
