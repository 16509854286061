import {inject, Injectable} from '@angular/core';
import Filter from '@models/filters/filter/filter.model';
import {IFilterApi, IFilterQueryParametersApi} from '@models/filters/filter/filter.interfaces';
import {Observable} from 'rxjs';
import {FiltersApiService} from '@models/filters/filters.api.service';
import {map} from 'rxjs/operators';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import {CSitesFactory} from '@models/sites/collection/sites.collection.factory';
import {IQueryParameters} from '@shared/texts/texts.interfaces';
import {
    CDemandeurRechercheResultatsFactory
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.factory';
import {NgFilter} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class FilterFactory {
    private _cDemandeurRechercheResultatsFactory = inject(CDemandeurRechercheResultatsFactory);
    private _cSitesFactory = inject(CSitesFactory);
    private _cUsersFactory = inject(CUsersFactory);
    private _filtersApiService = inject(FiltersApiService);

    create<T extends IQueryParameters>(filterApi: IFilterApi): Filter<T> {
        const filter = this.createVirgin<T>(filterApi.id.toString(), filterApi.id);

        filter.code = filterApi.code;
        filter.route = filterApi.route;
        filter.queryParameters = this.createQueryParameters(filter.route, filter.code, filterApi.queryParameters);

        return filter;
    }

    createDefault<T extends IQueryParameters>(route: string, code: string): Filter<T> {
        const filter = this.createVirgin<T>();

        filter.code = code;
        filter.queryParameters = this.getDefaultQueryParameters(route, code);
        filter.route = route;

        return filter;
    }

    createQueryParameters<T extends IQueryParameters>(route: string, code: string, filterQueryParametersApi: IFilterQueryParametersApi): T {
        let queryParameters = filterQueryParametersApi as T;

        switch (route) {
            case Filter.routes.GET_RECHERCHE_RESULTATS :
                queryParameters = this._cDemandeurRechercheResultatsFactory.createQueryParameters(filterQueryParametersApi, code) as T;
                break;
            case Filter.routes.GET_ANNUAIRES :
                switch (code) {
                    case Filter.codes.USERS :
                        queryParameters = this._cUsersFactory.createQueryParameters(filterQueryParametersApi) as T;
                        break;
                    case Filter.codes.SITES :
                        queryParameters = this._cSitesFactory.createQueryParameters(filterQueryParametersApi) as T;
                        break;
                }
        }

        if (filterQueryParametersApi.sorts) {
            queryParameters.tris = {};
            filterQueryParametersApi.sorts.forEach(sort => queryParameters.tris![sort.code] = sort.direction);
        }

        return queryParameters;
    }

    createVirgin<T extends IQueryParameters>(uuid?: string, id?: number): Filter<T> {
        return new Filter(uuid ?? Filter.statuts.NEW, id);
    }

    delete$(filter: Filter): Observable<void> {
        return this._filtersApiService.delete$(filter.id.toString());
    }

    getDefaultQueryParameters<T extends IQueryParameters>(route: string, code: string): T {
        switch (route) {
            case Filter.routes.GET_RECHERCHE_RESULTATS :
                return this._cDemandeurRechercheResultatsFactory.createVirginQueryParameters(code) as T;
            case Filter.routes.GET_ANNUAIRES :
                switch (code) {
                    case Filter.codes.USERS :
                        return this._cUsersFactory.createVirginQueryParameters() as T;
                    case Filter.codes.SITES :
                        return this._cSitesFactory.createVirginQueryParameters() as T;
                }
        }

        return {} as T;
    }

    getOneFromStorage$<T extends IQueryParameters>(route: string, code: string): Observable<Filter<T>> {
        return this._filtersApiService.getFromSessionStorage$<T>(route + '_'+ code).pipe(
            map(queryParameters => {
                const filter = this.createVirgin<T>();

                filter.code = code;
                filter.queryParameters = queryParameters || this.getDefaultQueryParameters(route, code);
                filter.route = route;

                return filter;
            })
        );
    }

    saveToStorage$<T extends IQueryParameters>(filter: Filter<T>): Observable<void> {
        return this._filtersApiService.saveToSessionStorage$(filter.route + '_'+ filter.code, filter.queryParameters);
    }

    ngCreate<T extends IQueryParameters>(ngFilter: NgFilter): Filter<T> {
        const filter = this.createVirgin<T>(ngFilter.id.toString(), ngFilter.id);

        filter.code = ngFilter.code;
        filter.route = ngFilter.route;
        filter.queryParameters = this.createQueryParameters(filter.route, filter.code, ngFilter.queryParameters);

        return filter;
    }
}
