import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-demandeur-results-historical', template: '<div appNgDemandeurResultsHistorical></div>'})
export class NgDemandeurResultsHistoricalComponent {
}

@Directive({selector: '[appNgDemandeurResultsHistorical]'})
export class NgDemandeurResultsHistoricalDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurResultsHistoricalCtrl', elementRef, injector);
    }
}
