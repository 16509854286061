import angularJS from '@shared/angularJS/global.ng';
import {firstValueFrom, ReplaySubject} from 'rxjs';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgContactsGroupManager, NgDemandeurManager, NgDemandeurRechercheManager, NgDictionariesManager, NgManager,
    NgUserManager, NgUtilsManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgDemandeur, NgDemandeurMetadata, NgFilter} from '@legacy/app/managers/ressources';
import {NgDemandeursCollection} from '@legacy/app/managers/collections';
import IInjectorService = angular.auto.IInjectorService;
import {EtudeService} from '@models/etudes/etude/etude.service';
import {map} from 'rxjs/operators';
import Ng2Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import User from '@core/models/users/user/user.model';
import {UserService} from '@models/users/user/user.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import Vente from '@models/ventes/vente/vente.model';
import {ILinksApi} from '@models/links/links.interfaces';

export default function getManagersDemandeur(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('DemandeurManager', manager);

        /**
         * Manager demandeur
         *
         * @param BaseManager
         * @param UserManager
         * @param UtilsManager
         * @param ContactsGroupManager
         * @param DictionariesManager
         * @param DemandeurRechercheManager
         * @param $injector
         * @param Ng2EtudeService
         * @param $q
         * @param Ng2UserService
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'UserManager', 'UtilsManager', 'ContactsGroupManager', 'DictionariesManager', 'DemandeurRechercheManager', '$injector', 'Ng2EtudeService', '$q', 'Ng2UserService', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         userManager: NgUserManager,
                         utilsManager: NgUtilsManager,
                         contactsGroupManager: NgContactsGroupManager,
                         dictionariesManager: NgDictionariesManager,
                         demandeurRechercheManager: NgDemandeurRechercheManager,
                         $injector: IInjectorService,
                         ng2EtudeService: EtudeService,
                         $q: IQService,
                         ng2UserService: UserService,
                         ng2DictionaryItemService: DictionaryItemService) {
            let classResource = baseManager.getClass<NgDemandeur>();
            let currentSource = new ReplaySubject<NgDemandeur>(1);

            /**
             * Routing
             */
            baseManager.addRoute('demandeurs.cget-etude', {path: '/etude/demandeurs', method: 'GET'});
            baseManager.addRoute('demandeurs.get', {path: '/demandeurs/{id}', method: 'GET'});
            baseManager.addRoute('demandeurs.insert', {path: '/user/demandeurs', method: 'POST'});
            baseManager.addRoute('demandeurs.edit', {path: '/demandeurs/{id}', method: 'PUT'});
            baseManager.addRoute('demandeurs.archive', {path: '/demandeurs/{id}/archiver', method: 'PATCH'});

            /**
             * DemandeurManager object
             *
             * @constructor
             */
            angular.extend(DemandeurManager.prototype, baseManager.__proto__);
            DemandeurManager.prototype.eskManager = {prefixRoute: 'demandeurs', collectionName: 'items'};
            function DemandeurManager(this: NgDemandeurManager) {
                this.current$ = currentSource.asObservable();
                this.current$.subscribe(current => this.current = current);
                currentSource.next(undefined!);
            }

            /**
             * Create a Demandeur object
             *
             * @param ng2Demandeur
             * @returns manager.Demandeur
             */
            DemandeurManager.prototype.createFromNg2 = function (ng2Demandeur: Ng2Demandeur) {
                if (!ng2Demandeur) {
                    return $q.resolve(undefined!);
                }

                return contactsGroupManager.createFromNg2(ng2Demandeur.contactsGroup).then(contactsGroup => this.create({
                    _links: {
                        responsableDossier: {href: ng2Demandeur.responsableDossier?.linkSelf},
                        self: {href: ng2Demandeur.linkSelf},
                    },
                    contactsGroup,
                    // @todo Utilisation de DateFormat ?
                    dateCreation: new Date(ng2Demandeur.dateCreation),
                    id: ng2Demandeur.id,
                    reference: ng2Demandeur.reference,
                    responsableDossier: userManager.createFromNg2(ng2Demandeur.responsableDossier),
                    uuid: ng2Demandeur.uuid,
                }));
            };

            DemandeurManager.prototype.emitCurrent = function(ngDemandeur: NgDemandeur) {
                currentSource.next(ngDemandeur);
            }

            /**
             * Init current and return promise
             *
             * @param demandeurIdBrut
             * @param currentUser
             * @returns {Promise}
             */
            DemandeurManager.prototype.initCurrent = function (demandeurIdBrut: number, currentUser: User) {
                const that = this;
                const demandeurId = +demandeurIdBrut;
                let promise: IPromise<NgDemandeur>;

                if (!demandeurId || !angular.isNumber(demandeurId)) {
                    promise = $q.resolve(that.create({
                        responsableDossier: userManager.createFromNg2(currentUser),
                        notaire: userManager.createFromNg2(currentUser.defaultNotaire),
                    }));
                } else {
                    promise = that.getOneById(demandeurId).then(demandeur => demandeurRechercheManager.getSearchForDemandeur(demandeur).then(() => demandeur));
                }

                return promise.then((demandeur: NgDemandeur) => currentSource.next(demandeur));
            };

            /**
             * Returns the promise to fetch collection of ventes from etude
             *
             * @param params
             * @returns {Promise}
             */
            DemandeurManager.prototype.getAllEtude = function (params: any): IPromise<NgDemandeursCollection> {
                return this.get('cget-etude', params);
            };

            /**
             * Returns the promise to fetch collection of filtered demandeurs from etude
             *
             * @param filter
             * @param params
             * @returns {Promise}
             */
            DemandeurManager.prototype.getAllEtudeFiltered = function (filter: NgFilter, params: any): IPromise<NgDemandeursCollection> {
                const paramsRequest = angular.extend(filter.getParamsRequest(), params)

                if (paramsRequest.tris?.createdAtDemandeur) {
                    paramsRequest.tris.createdAt = paramsRequest.tris.createdAtDemandeur;
                    delete paramsRequest.tris?.createdAtDemandeur;
                }

                if (paramsRequest.tris?.updatedAtDemandeur) {
                    paramsRequest.tris.updatedAt = paramsRequest.tris.updatedAtDemandeur;
                    delete paramsRequest.tris?.updatedAtDemandeur;
                }

                return this.getAllEtude(paramsRequest);
            };

            /**
             * Create a Demandeur object
             *
             * @param data
             * @returns manager.Demandeur object || Array of manager.Demandeur objects
             */
            DemandeurManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    const demandeurs = [];

                    for (let i = 0; i < data.length; i++) {
                        // @ts-ignore
                        demandeurs.push(new Demandeur(data[i]));
                    }

                    return demandeurs;
                }

                // @ts-ignore
                return new Demandeur(data);
            };

            /**
             * Demandeur object
             *
             * @param data
             * @constructor
             */
            angular.extend(Demandeur.prototype, classResource.prototype);
            Demandeur.prototype.eskManager = {prefixRoute: 'demandeurs'};
            function Demandeur(this: NgDemandeur, data: unknown) {
                this._links = {} as ILinksApi;
                this.statut = Ng2Demandeur.statuts.BROUILLON;
                this.financementProvenanceFonds = null!;
                this.financementStatut = null!;
                this.recherches = [];
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
                if (!angular.isObject(this.metadata)) {
                    this.metadata = {} as NgDemandeurMetadata;
                }
                if (!angular.isObject(this.metadata.confidentielles)) {
                    this.metadata.confidentielles = {comments: undefined!};
                }
                this.metadata.confidentielles.comments = this.renseignementsConfidentiels;
            }

            /**
             * Extend the existing demandeur with new data
             *
             * @param data
             * @returns {Demandeur}
             */
            Demandeur.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this._esk.typeDossier = DossierTypesConst.DEMANDEUR;
                this.recherches = demandeurRechercheManager.create(this.recherches, this.id);
                this.modifier = userManager.create(this.modifier);
                this.responsableDossier = userManager.create(this.responsableDossier);
                this.createdAt = DateFormat.toDate(this.createdAt);
                this.updatedAt = DateFormat.toDate(this.updatedAt);

                if (angular.isObject(this.notaire)) {
                    this.notaire = userManager.create(this.notaire);
                }

                if (angular.isNumber(this.typeId)) {
                    this.type = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.DEMANDEUR_TYPES, this.typeId));
                }

                this.contactsGroup = contactsGroupManager.create(this.contactsGroup);

                if (this.financementProvenanceFonds === null || angular.isString(this.financementProvenanceFonds)) {
                    this.financementProvenanceFonds = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.FINANCEMENT_FONDS_PROVENANCES, this.financementProvenanceFonds));
                }

                if (this.financementStatut === null || angular.isString(this.financementStatut)) {
                    this.financementStatut = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.FINANCEMENT_STATUTS, this.financementStatut));
                }

                if (angular.isString(this.archiveType)) {
                    this.archiveType = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_TYPES, this.archiveType));
                }

                if (angular.isString(this.archiveRaisonVenteTrouvee)) {
                    this.archiveRaisonVenteTrouvee = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS, this.archiveRaisonVenteTrouvee));
                }

                if (angular.isString(this.archiveDateAchat)) {
                    this.archiveDateAchat = DateFormat.toDate(this.archiveDateAchat);
                }

                return this;
            };

            /**
             * Sanitize object Demandeur before send to API
             *
             * @returns sanitizeObject
             */
            Demandeur.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                sanitizeObject.archiveDateAchat = DateFormat.toAPI(this.archiveDateAchat);
                sanitizeObject.renseignementsConfidentiels = sanitizeObject.metadata.confidentielles.comments;

                // Attributs inutiles pour l'API
                delete sanitizeObject.modifier;
                delete sanitizeObject.updatedAt;
                delete sanitizeObject.recherches;

                // Objets simples => id
                utilsManager.flattenIdForAttribute(sanitizeObject, 'responsableDossier');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'notaire');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'contactsGroup');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'type');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'financementProvenanceFonds');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'archiveType');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'archiveRaisonVenteTrouvee');
                utilsManager.flattenIdForAttribute(sanitizeObject, 'archiveVente');
                if (angular.isObject(this.financementProvenanceFonds) && this.financementProvenanceFonds.code === 'financement') {
                    utilsManager.flattenAttributeWithCode(sanitizeObject, 'financementStatut');
                } else {
                    delete sanitizeObject.financementStatut;
                    delete sanitizeObject.financementPartenaire;
                }

                return sanitizeObject;
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Demandeur.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self)
                    .then(() => firstValueFrom(ng2UserService.last$)).then(currentUser => {
                        self.updatedAt = DateFormat.toDate();
                        self.modifier = userManager.createFromNg2(currentUser);

                        return self;
                    })
                    .then(_ => firstValueFrom(currentSource.asObservable()))
                    .then(currentDemandeur => {
                        if (self.id === currentDemandeur?.id) {
                            currentSource.next(self);
                        }

                        return self;
                    });
            };

            /**
             * Archive Demandeur
             *
             * @param archive
             * @returns {Promise}
             */
            Demandeur.prototype.archive = function (archive: unknown) {
                const self = this;

                return self.saveArchiveInfos(archive).then(() => self.patch('archive'));
            };

            /**
             * Get information for archive
             *
             * @returns {Observable}
             */
            Demandeur.prototype.getArchiveInfos = function () {
                const self = this;

                return ng2EtudeService.last$.pipe(
                    map(currentEtude => {
                        const archive = {};

                        // @ts-ignore
                        archive.type = self.archiveType;
                        // @ts-ignore
                        if (!angular.isObject(archive.type)) {
                            // @ts-ignore
                            archive.type = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_TYPES, Ng2Demandeur.archiveTypes.VENTE_TROUVEE));
                        }

                        // @ts-ignore
                        archive.raisonVenteTrouvee = self.archiveRaisonVenteTrouvee;
                        // @ts-ignore
                        if (!angular.isObject(archive.raisonVenteTrouvee)) {
                            // @ts-ignore
                            archive.raisonVenteTrouvee = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_ARCHIVE_VENTE_NEGOCIEE_RAISONS, Vente.archiveNegocieeRaisons.PORTEFEUILLE));
                        }

                        // @ts-ignore
                        archive.dateAchat = self.archiveDateAchat;
                        // @ts-ignore
                        if (!angular.isDefined(archive.dateAchat) || archive.dateAchat === null) {
                            // @ts-ignore
                            archive.dateAchat = DateFormat.toDate(DateFormat.dateFromNow());
                        }

                        // @ts-ignore
                        archive.rechercheId = self.archiveRechercheId;
                        // @ts-ignore
                        if ((!angular.isDefined(archive.rechercheId) || archive.rechercheId === null) && self.recherches.length > 0) {
                            // @ts-ignore
                            archive.rechercheId = self.recherches[0].id;
                        }

                        // @ts-ignore
                        archive.vente = self.archiveVente;
                        if (angular.isObject(self._links)) {
                            // @ts-ignore
                            if (!angular.isObject(archive.vente) && angular.isObject(self._links.archiveVente) && angular.isDefined(self._links.archiveVente.href)) {
                                $injector.get<NgVenteManager>('VenteManager').execLink(self._links.archiveVente.href).then(function (vente) {
                                    self.archiveVente = vente;
                                    // @ts-ignore
                                    archive.vente = vente;
                                });
                            }
                        }

                        // @ts-ignore
                        archive.reseau = currentEtude.mainNetwork;
                        // @ts-ignore
                        archive.referenceAutres = self.archiveReferenceAutres;
                        // @ts-ignore
                        archive.prixAchat = self.archivePrixAchat;
                        // @ts-ignore
                        archive.honorairesNegoPercus = self.archiveHonorairesNegoPercus;
                        // @ts-ignore
                        archive.comments = self.archiveComments;

                        return archive;
                    }),
                );
            };

            /**
             * Save archive information
             *
             * @param archive
             * @returns {Promise}
             */
            Demandeur.prototype.saveArchiveInfos = function (archive: any) {
                const dateAchat = DateFormat.toDate(archive.dateAchat);

                DateFormat.razTime(dateAchat);
                this.archiveType = archive.type;
                this.archiveRaisonVenteTrouvee = archive.raisonVenteTrouvee;
                this.archiveRechercheId = archive.rechercheId;
                this.archiveVente = archive.vente;
                this.archiveReferenceAutres = archive.referenceAutres;
                this.archiveDateAchat = DateFormat.toJSON(dateAchat);
                this.archivePrixAchat = archive.prixAchat;
                this.archiveHonorairesNegoPercus = archive.honorairesNegoPercus;
                this.archiveComments = archive.comments;

                return this.save();
            };

            Demandeur.prototype.updateSearchStatusAndEmitCurrent = function () {
                if (this.recherches?.length > 0) {
                    return this.recherches[0].updateStatusValue().then(() => currentSource.next(this));
                }

                return $q.resolve(undefined!);
            };

            // @ts-ignore
            return new DemandeurManager();
        }
    })(angularJS);
}
