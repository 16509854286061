import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IDemandeurApi} from '@models/demandeurs/demandeur/demandeur.interfaces';
import {
    ICDemandeursApi, ICDemandeursQueryParameters
} from '@models/demandeurs/collection/demandeurs.collection.interfaces';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';

@Injectable({providedIn: 'root'})
export class DemandeursApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IDemandeurApi> {
        return this.getByLink$('/demandeurs/' + uuid);
    }

    getByLink$(link: string): Observable<IDemandeurApi> {
        return this._modelApiService.get$(link);
    }

    getCollectionByLink$(link: string): Observable<ICDemandeursApi> {
        return this._modelApiService.getCollection$(link);
    }

    getEtudeCollection$(queryParameters?: ICDemandeursQueryParameters): Observable<ICDemandeursApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/demandeurs', queryParameters));
    }

    save$(uuid: string, demandeurApi: IDemandeurApi): Observable<IDemandeurApi> {
        if (uuid === Demandeur.statuts.NEW) {
            return this._modelApiService.post$('/user/demandeurs', demandeurApi);
        }

        return throwError(() => 'Non implémenté');
    }
}
