import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgManager} from '@legacy/app/managers/managers';
import {NgClientPaginate} from '@legacy/app/client/client';
import Departement from '@core/models/departements/departement/departement.model';
import {LocalStorageService} from '@core/storage/local-storage.service';
import {ConditionConst} from '@shared/constants';
import Region from '@models/regions/region/region.model';
import {NgArea} from '@legacy/app/managers/ressources';

export default function getManagersArea(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('AreaManager', manager);

        /**
         * Manager area
         *
         * @param BaseManager
         * @param $q
         * @param ClientPaginate
         * @param Ng2LocalStorageService
         * @returns {AreaManager}
         */
        manager.$inject = ['BaseManager', '$q', 'ClientPaginate', 'Ng2LocalStorageService'];
        function manager(baseManager: NgManager,
                         $q: IQService,
                         clientPaginate: NgClientPaginate,
                         ng2LocalStorageService: LocalStorageService) {
            const areasLoaded: Record<string, { elements: unknown }> = {};

            /**
             * Routing
             */
            baseManager.addRoute('areas.cget', {path: '/areas', method: 'GET'});

            /**
             * AreaManager object
             *
             * @constructor
             */
            angular.extend(AreaManager.prototype, baseManager.__proto__);
            AreaManager.prototype.eskManager = {prefixRoute: 'areas', collectionName: 'items'};
            function AreaManager() {
            }

            /**
             * Load all authorized areas
             *
             * @returns {Promise}
             */
            AreaManager.prototype.load = function () {
                const areasPromises: IPromise<unknown>[] = [];
                const authorizedAreas = [
                    {nom: "regions", filtre: "region"},
                    {nom: "departements", filtre: "departement"}
                ];
                const self = this;
                let areasStore: Record<string, { elements: unknown; etag: unknown }> = {};
                var areasPromise;
                var promise;
                const areasStorage = ng2LocalStorageService.get<string>('areas');

                if (areasStorage) {
                    areasStore = JSON.parse(areasStorage) as Record<string, { elements: unknown; etag: unknown }>;
                }

                angular.forEach(authorizedAreas, function (authorizedArea) {
                    if (!areasStore[authorizedArea.nom]) {
                        areasStore[authorizedArea.nom] = {elements: [], etag: ''};
                    }

                    promise = self.get('cget',
                        {type: {condition: ConditionConst.IN, values: [authorizedArea.filtre]}},
                        {'If-None-Match': areasStore[authorizedArea.nom].etag}).then((response: Record<string, unknown>) => {
                        if (response && response.etag && response.items) {
                            areasStore[authorizedArea.nom] = {elements: response.items, etag: response.etag};
                        }
                    }, function (error: {status: number}) {
                        if (error.status === 304) {
                            return;
                        }

                        return $q.reject(error);
                    });

                    areasPromises.push(promise);
                });

                areasPromise = $q.all(areasPromises).then(function () {
                    ng2LocalStorageService.set('areas', JSON.stringify(areasStore));

                    return true;
                });

                return areasPromise;
            };

            /**
             * Create a Departement object
             *
             * @param ng2Departement
             * @returns manager.Departement
             */
            AreaManager.prototype.createFromNg2Departement = function (ng2Departement: Departement) {
                if (!ng2Departement) {
                    return undefined!;
                }

                return this.create({
                    code: ng2Departement.code,
                    fullname: ng2Departement.fullname,
                    id: ng2Departement.id,
                    uuid: ng2Departement.uuid,
                });
            };

            AreaManager.prototype.createFromNg2Region = function (ng2Region: Region): NgArea {
                if (!ng2Region) {
                    return undefined!;
                }

                return this.create({
                    code: ng2Region.code,
                    fullname: ng2Region.fullname,
                    id: ng2Region.id,
                    uuid: ng2Region.uuid,
                });
            }

            /**
             * Return objects collection or object
             *
             * @param data
             * @returns {*}
             */
            AreaManager.prototype.createObject = function (data: unknown) {
                return data;
            };

            /**
             * Get datas for area
             *
             * @param area
             * @returns [datas area]
             */
            AreaManager.prototype.getArea = function (area: string) {
                var areas;

                if (!angular.isString(area)) {
                    throw new Error("Pas de localisations à charger.");
                }

                if (!angular.isObject(areasLoaded[area])) {
                    areas = JSON.parse(ng2LocalStorageService.get<string>('areas'));
                    if (!angular.isObject(areas[area]) || !angular.isArray(areas[area].elements)) {
                        throw new Error("La localisation '" + area + "' n'existe pas.");
                    }

                    areasLoaded[area] = areas[area];
                }

                return areasLoaded[area].elements;
            };

            /**
             * Returns the promise to fetch collection of objects
             *
             * @param params
             * @returns {Promise}
             */
            AreaManager.prototype.getAll = function (params: unknown) {
                const self = this;

                return self.get('cget', params).then((data: unknown) => clientPaginate.create(self, data));
            };

            /**
             * Returns data
             *
             * @param data
             * @returns data
             */
            AreaManager.prototype.create = function (data: unknown) {
                return data;
            };

            // @ts-ignore
            return new AreaManager();
        }
    })(angularJS);
}
