import {inject, Injectable} from '@angular/core';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {ContactsApiService} from '@models/contacts/contacts.api.service';
import CContacts from '@models/contacts/collection/contacts.collection.model';
import {Observable} from 'rxjs';
import {ICContactsApi, ICContactsQueryParameters} from '@models/contacts/collection/contacts.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CContactsFactory {
    private _contactFactory = inject(ContactFactory);
    private _contactsApiService = inject(ContactsApiService);

    create(cContactsApi: ICContactsApi): CContacts {
        const cContacts = this.createVirgin();

        cContacts.links = new Links(cContactsApi._links);
        cContacts.page = cContactsApi.page;
        cContacts.pages = cContactsApi.pages;
        cContacts.perPage = cContactsApi.limit;
        cContacts.total = cContactsApi.total;
        if (cContactsApi._embedded?.items) {
            cContactsApi._embedded.items.filter(contactApi => contactApi).map(contactApi => cContacts.results.push(this._contactFactory.create(contactApi)));
        }

        return cContacts;
    }

    createVirgin(): CContacts {
        const cContacts = new CContacts();

        cContacts.links = new Links();

        return cContacts;
    }

    get$(queryParameters?: ICContactsQueryParameters): Observable<CContacts> {
        return this._contactsApiService.getEtudeCollection$(queryParameters).pipe(map(cContactsApi => this.create(cContactsApi)));
    }

    getByLink$(link: string): Observable<CContacts> {
        return this._contactsApiService.getCollectionByLink$(link).pipe(map(cContactsApi => this.create(cContactsApi)));
    }

    getNext$(cContacts: CContacts): Observable<CContacts> {
        return this._contactsApiService.getCollectionByLink$(cContacts.links.next).pipe(map(cContactsApi => this.create(cContactsApi)));
    }
}
