import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgPhoto} from '@legacy/app/managers/ressources';

export default function getEskModalEditPhotoCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EskModalEditPhotoCtrl', Controller);

        /**
         * Controller of modal to edit Photo
         *
         * @param $uibModalInstance
         * @param photo
         */
        Controller.$inject = ['$uibModalInstance', 'photo'];
        function Controller(this: any, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, photo: NgPhoto) {
            const $ctrl = this;

            $ctrl.save = save;
            $ctrl.onCrop = onCrop;
            $ctrl.saving = false;
            $ctrl.loading = true;
            $ctrl.photo = photo;

            /**
             * Save photo
             */
            function save() {
                $ctrl.saving = true;
                $ctrl.photo?.save().then(() => $uibModalInstance.close()).finally(() => $ctrl.saving = false);
            }

            /**
             * Update crop data
             *
             * @param details
             */
            function onCrop(details: Record<string, number>) {
                $ctrl.photo.topLeftCoordinates = [details.x, details.y];
                $ctrl.photo.bottomRightCoordinates = [details.x + details.width, details.y + details.height];
            }
        }
    })(angularJS);
}
