import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';

export default function getEskConfigurationDashboardCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-configuration-dashboard-ctrl></esk-configuration-dashboard-ctrl>
         */
        module.component('eskConfigurationDashboardCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/configuration/dashboard.html',
        });

        /**
         * Configuration dashboard controller
         *
         * @param EskBox
         * @constructor
         */
        Controller.$inject = ['EskBox'];
        function Controller(this: any, eskBox: NgEskBox) {
            const $ctrl = this;

            $ctrl.boxToggleVisibility = eskBox.toggleVisibility;
            $ctrl.saveConfig = eskBox.saveConfig;
            $ctrl.boxes = eskBox.getAll();
            angular.forEach($ctrl.boxes.collection, box => box.displayDetails = false);
        }
    })(angularJS);
}
