<div class="tw-mb-5">
  @if (reference.isNew()) {
    <h3><fa-icon [icon]="'plus'"/> Ajout d'une référence</h3>
  }

  @if (!reference.isNew()) {
    <h3><fa-icon [icon]="'edit'"/> Édition d'une référence</h3>
  }
</div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm" class="tw-w-[80vw] md:tw-w-[40rem]">
  <div class="tw-pt-2 tw-pb-6 tw-space-y-8">
    <fieldset>
      <legend>Localisation</legend>

      <div class="tw-flex tw-gap-8">
        <div class="tw-w-1/2" [appFormError]="commune.formInput">
          <label>Commune *</label>

          <app-commune-select [link]="reference.linkCommune" (selected)="onSelectCommune($event)"
                              #commune="communeSelect" [options]="{required: true}"/>
        </div>

        <div class="tw-w-1/2" [appFormError]="adresse">
          <label for="adresse">Adresse *</label>

          <input class="tw-input-text tw-block" type="text" id="adresse" [(ngModel)]="reference.adresse" name="adresse"
                 required #adresse="ngModel">
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Description</legend>

      <div class="tw-space-y-4">
        <div class="tw-flex tw-gap-8">
          <div class="tw-w-1/2" [appFormError]="nature.formInput">
            <label>Nature *</label>

            <app-dictionary-select #nature="dictionarySelect" [model]="reference.nature"
                                   [name]="DICTIONARY_NAME_NATURES" (selected)="onSelectNature($event)"
                                   [options]="{name: 'nature', required: true}"/>
          </div>

          <div class="tw-w-1/2" [appFormError]="loyer">
            <label for="loyer">Loyer</label>

            <div class="tw-input-currency">
              <input class="tw-input-number" id="loyer" type="number" step="0.01" min="0" name="loyer"
                     [(ngModel)]="reference.loyer" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" #loyer="ngModel">

              <span>€</span>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-gap-8">
          <div class="tw-w-1/2" [appFormError]="superficie">
            <label for="superficie">{{superficieLabel}}</label>

            <div class="tw-input-currency">
              <input class="tw-input-number" id="superficie" type="number" min="0" name="superficie"
                     #superficie="ngModel" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="reference.superficie">

              <span>m²</span>
            </div>
          </div>

          <div class="tw-w-1/2" [appFormError]="surfaceCadastrale">
            <label for="surface-cadastrale">Surface cadastrale</label>

            <div class="tw-input-currency">
              <input class="tw-input-number" id="surface-cadastrale" type="number" min="0" #surfaceCadastrale="ngModel"
                     name="surfaceCadastrale" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$"
                     [(ngModel)]="reference.surfaceCadastrale">

              <span>m²</span>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Construction</legend>

      <div class="tw-space-y-4">
        <div class="tw-flex tw-gap-8">
          <div class="tw-w-1/2" [appFormError]="nombrePieces">
            <label for="nombre-pieces">Nombre de pièces</label>

            <input class="tw-input-number tw-block" type="number" pattern="[0-9]+" min="0" id="nombre-pieces"
                   [(ngModel)]="reference.nombrePieces" name="nombrePieces" #nombrePieces="ngModel">
          </div>

          <div class="tw-w-1/2" [appFormError]="nombreChambres">
            <label for="nombre-chambres">Nombre de chambres</label>

            <input class="tw-input-number tw-block" type="number" pattern="[0-9]+" min="0" id="nombre-chambres"
                   [(ngModel)]="reference.nombreChambres" name="nombreChambres" #nombreChambres="ngModel">
          </div>
        </div>

        <div class="tw-flex tw-gap-8">
          <div class="tw-w-1/2" [appFormError]="epoque.formInput">
            <label>Époque de construction</label>

            <app-dictionary-select #epoque="dictionarySelect" [model]="reference.epoque" [options]="{name: 'epoque'}"
                                   [name]="DICTIONARY_NAME_CONSTRUCTION_EPOQUES" (selected)="onSelectEpoque($event)"/>
          </div>

          <div class="tw-w-1/2" [appFormError]="nombreEtages">
            <label for="nombre-etages">Nombre de niveaux</label>

            <input class="tw-input-number tw-block" type="number" pattern="[0-9]+" min="0" id="nombre-etages"
                   [(ngModel)]="reference.nombreEtages" name="nombreEtages" #nombreEtages="ngModel">
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Cession</legend>

      <div class="tw-flex tw-gap-8">
        <div class="tw-w-1/2" [appFormError]="dateVente.formInput">
          <label>Date de vente *</label>

          <app-form-select-date #dateVente="formSelectDate" [model]="reference.dateVente" class="tw-block tw-w-40"
                                (selected)="reference.dateVente = $event" [options]="dateVenteOptions"/>
        </div>

        <div class="tw-w-1/2" [appFormError]="prixVente">
          <label for="prix-vente">Prix de vente *</label>

          <div class="tw-input-currency">
            <input class="tw-input-number" id="prix-vente" type="number" step="0.01" min="0" name="prixVente" required
                   [(ngModel)]="reference.prixVente" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" #prixVente="ngModel">

            <span>€</span>
          </div>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="tw-float-right">
    <button type="submit" class="tw-btn-primary-info">
      @if (reference.isNew()) {
        <fa-icon [icon]="'plus'"/> <span>Ajouter</span>
      }

      @if (!reference.isNew()) {
        <fa-icon [icon]="'edit'"/> <span>Enregistrer</span>
      }
    </button>
  </div>
</form>
