import {inject, Injectable} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {UserService} from '@models/users/user/user.service';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BienFactory} from '@models/bien/bien.factory';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {BienCadastresService} from '@models/bien/cadastres/bien-cadastres.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {BienChargesService} from '@models/bien/charges/bien-charges.service';
import {BienCommoditesService} from '@models/bien/commodites/bien-commodites.service';
import {BienDiagnosticsService} from '@models/bien/diagnostics/bien-diagnostics.service';
import {BienLogementsService} from '@models/bien/logements/bien-logements.service';
import {BienSurfacesService} from '@models/bien/surfaces/bien-surfaces.service';

@Injectable({providedIn: 'root'})
export class BienService {
    private _bienCadastresService = inject(BienCadastresService);
    private _bienChargesService = inject(BienChargesService);
    private _bienCommoditesService = inject(BienCommoditesService);
    private _bienDiagnosticsService = inject(BienDiagnosticsService);
    private _bienFactory = inject(BienFactory);
    private _bienLogementsService = inject(BienLogementsService);
    private _bienSurfacesService = inject(BienSurfacesService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _userService = inject(UserService);

    createVirgin$(): Observable<Bien> {
        const bien = this._bienFactory.createVirgin();

        bien.contactsGroup = this._contactsGroupFactory.createVirgin();
        bien.zoneRisques = true;

        return this._userService.last$.pipe(
            tap(user => bien.linkNotaire = user.defaultNotaire?.linkSelf),
            tap(user => bien.linkResponsableDossier = user.linkSelf),
            tap(user => bien.responsableDossierId = user.id),
            map(_ => bien),
        );
    }

    saveCollection$(dossierBien: ADossierBien): Observable<void> {
        const savedCollection$: Observable<unknown>[] = [];

        savedCollection$.push(this._bienCadastresService.save$(dossierBien));
        savedCollection$.push(this._bienChargesService.save$(dossierBien));
        savedCollection$.push(this._bienCommoditesService.save$(dossierBien));
        if (!dossierBien.bien.isAutreTerrain() && !dossierBien.bien.isTerrainABatir()) {
            savedCollection$.push(this._bienDiagnosticsService.save$(dossierBien));
        }

        if (dossierBien.bien.isImmeuble()) {
            savedCollection$.push(this._bienLogementsService.save$(dossierBien));
        }

        if (!dossierBien.bien.isAutreTerrain() && !dossierBien.bien.isImmeuble() && !dossierBien.bien.isTerrainABatir()) {
            savedCollection$.push(this._bienSurfacesService.save$(dossierBien));
        }

        return combineLatest(savedCollection$).pipe(map(() => undefined));
    }

    saveCollectionForNew$(dossierBien: ADossierBien, newDossierBien: ADossierBien): Observable<void> {
        newDossierBien.bien.cadastres = dossierBien.bien.cadastres;
        newDossierBien.bien.charges = dossierBien.bien.charges;
        newDossierBien.bien.commodites = dossierBien.bien.commodites;
        newDossierBien.bien.diagnosticsRealises = dossierBien.bien.diagnosticsRealises;
        newDossierBien.bien.logements = dossierBien.bien.logements;
        newDossierBien.bien.surfaces = dossierBien.bien.surfaces;

        return this.saveCollection$(newDossierBien);
    }

    updateTitreDescriptif$(dossierBien: ADossierBien): Observable<void> {
        return combineLatest([
            this._bienFactory.getTitre$(dossierBien.id).pipe(tap(titre => dossierBien.bien.titre = titre)),
            this._bienFactory.getDescriptif$(dossierBien.id).pipe(tap(descriptif => dossierBien.bien.descriptif = descriptif)),
        ]).pipe(map(() => undefined));
    }
}
