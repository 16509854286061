import {inject, Injectable} from '@angular/core';
import CLocations from '@models/locations/collection/locations.collection.model';
import {Observable} from 'rxjs';
import {CLocationsFactory} from '@models/locations/collection/locations.collection.factory';
import {ICLocationsQueryParameters} from '@models/locations/collection/locations.collection.interfaces';
import {DossierBienSource, DossierBiensSourcesConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class CLocationsService {
    private _cLocationsFactory = inject(CLocationsFactory);

    getBySource$(source: DossierBienSource, queryParameters: ICLocationsQueryParameters = {}): Observable<CLocations> {
        if (source === DossierBiensSourcesConst.WALLET) {
            return this._cLocationsFactory.getEtude$(queryParameters);
        }

        return this._cLocationsFactory.get$(queryParameters);
    }
}
