import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise} from 'angular';
import {ICollectionApi} from '@models/collection/collection.interfaces';
import {NgClientHttp, NgPaginate} from '@legacy/app/client/client';
import {NgManager} from '@legacy/app/managers/managers';
import {ModelApiService} from '@models/model.api.service';

export default function getClientPaginate(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ClientPaginate', Factory);

        /**
         * Manage Paginate object
         *
         * @param ClientHttp
         * @param Ng2ModelApiService
         */
        Factory.$inject = ['ClientHttp', 'Ng2ModelApiService'];
        function Factory(clientHttp: NgClientHttp, ng2ModelApiService: ModelApiService) {
            /**
             * Paginate object
             *
             * @param object
             * @param collectionName
             * @constructor
             */
            Paginate.prototype.eskClient = {name: 'Paginate'};
            function Paginate<T>(this: NgPaginate, resourceManager: NgManager, data: ICollectionApi<unknown>) {
                this.resourceManager = resourceManager;
                this.collection = [];
                this.current = 0;
                this.pages = 0;
                this.pageSize = 0;
                this.totalItems = 0;
                this.collectionName = resourceManager.getCollectionName();
                this.firstPageLink = undefined!;
                this.previousPageLink = undefined!;
                this.currentPageLink = undefined!;
                this.nextPageLink = undefined!;
                this.lastPageLink = undefined!;

                /**
                 * Load the next page and returns the associated promise
                 *
                 * @param reset the current collection
                 * @returns {promise}
                 */
                this.nextPage = function (reset: boolean): IPromise<NgPaginate> {
                    return this.getPageFromLink(this.nextPageLink, reset);
                };

                this.load(data, true);
            }

            /**
             * Load the first page and returns the associated promise
             *
             * @returns {promise}
             */
            Paginate.prototype.firstPage = function () {
                return this.getPageFromLink(this.firstPageLink, true);
            };

            /**
             * Load the previous page and returns the associated promise
             *
             * @returns {promise}
             */
            Paginate.prototype.previousPage = function () {
                return this.getPageFromLink(this.previousPageLink, true);
            };

            /**
             * Reload the current page and returns the associated promise
             *
             * @returns {promise}
             */
            Paginate.prototype.currentPage = function () {
                return this.getPageFromLink(this.currentPageLink, true);
            };

            /**
             * Load the last page and returns the associated promise
             *
             * @returns {promise}
             */
            Paginate.prototype.lastPage = function () {
                return this.getPageFromLink(this.lastPageLink, true);
            };

            /**
             * Save data from object
             *
             * @param data
             * @param reset
             * @returns {Paginate}
             */
            Paginate.prototype.load = function (data: ICollectionApi<unknown>, reset?: boolean) {
                var resourcesArray;

                this.previousPageLink = undefined;
                this.nextPageLink = undefined;

                // @ts-ignore
                if (!angular.isObject(data) || !angular.isArray(data[this.collectionName])) {
                    return null;
                }

                // @ts-ignore
                resourcesArray = this.resourceManager.create(data[this.collectionName]);

                this.collection = reset ? resourcesArray : this.collection.concat(resourcesArray);
                this.current = data.page;
                this.pages = data.pages;
                this.pageSize = data.limit;
                this.totalItems = data.total;
                this.firstPageLink = data._links.first.href;
                this.currentPageLink = data._links.self.href;
                this.lastPageLink = data._links.last.href;
                if (angular.isObject(data._links.previous)) {
                    this.previousPageLink = data._links.previous.href;
                }
                if (angular.isObject(data._links.next)) {
                    this.nextPageLink = data._links.next.href;
                }

                return this;
            };

            /**
             * Get data from link
             *
             * @param path
             * @param reset
             * @returns {promise|Error}
             */
            Paginate.prototype.getPageFromLink = function (path: string, reset = true) {
                const self = this;

                if (!angular.isString(path) || path === '') {
                    throw new Error('Pas de page définie.');
                }

                return clientHttp.get(path).then((data: unknown) => self.load(data, angular.isDefined(reset) ? Boolean(reset) : true));
            };

            /**
             * Load the requested page and returns the associated promise
             *
             * @param pageNumber
             * @returns {promise}
             */
            Paginate.prototype.getPageFromNumber = function (pageNumber: number) {
                if (!angular.isNumber(pageNumber) || pageNumber <= 0 || this.pages < pageNumber) {
                    throw new Error('Numéro de page incorrect.');
                }

                return this.getPageFromLink(ng2ModelApiService.updateUrlPath(this.firstPageLink, 'page', pageNumber));
            };

            return {create: create};

            /**
             * Create a Paginate object
             *
             * @param resourceManager
             * @param data
             * @returns Paginate
             */
            function create<T>(resourceManager: NgManager, data: unknown) {
                // @ts-ignore
                return new Paginate(resourceManager, data);
            }
        }
    })(angularJS);
}
