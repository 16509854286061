import {inject, Injectable} from '@angular/core';
import CBonvisites from '@models/bonvisites/collection/bonvisites.collection.model';
import {BehaviorSubject, Observable, Subject, switchMap} from 'rxjs';
import {CBonvisitesFactory} from '@models/bonvisites/collection/bonvisites.collection.factory';
import {map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {ICBonvisitesQueryParameters} from '@models/bonvisites/collection/bonvisites.collection.interfaces';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';

@Injectable({providedIn: 'root'})
export class CBonvisitesService {
    private _bonvisiteService = inject(BonvisiteService);
    private _cBonvisitesFactory = inject(CBonvisitesFactory);
    private _currentSource = new BehaviorSubject<CBonvisites>(undefined!);
    private readonly _endCurrentSubject = new Subject<void>();

    get current$(): Observable<CBonvisites> {
        return this._currentSource.asObservable();
    }

    addNextToCurrent$(): Observable<CBonvisites> {
        return this.current$.pipe(
            take(1),
            switchMap(cBonvisites => this._cBonvisitesFactory.getNext$(cBonvisites).pipe(
                tap(newCBonvisites => cBonvisites.updateNext(newCBonvisites)),
                map(_ => cBonvisites),
            )),
        );
    }

    initCurrent(queryParameters?: ICBonvisitesQueryParameters): void {
        this._endCurrentSubject.next();
        this._bonvisiteService.lastEdited$.pipe(
            startWith(1),
            tap(_ => this._currentSource.next(undefined!)),
            switchMap(_ => this._cBonvisitesFactory.get$(queryParameters)),
            takeUntil(this._endCurrentSubject),
        ).subscribe(currentCBonvisites => this._currentSource.next(currentCBonvisites));
    }
}
