import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {AppDemandeursSelectComponent} from '@features/demandeurs/demandeur/select/demandeurs.select.component';
import {AppDemandeurViewComponent} from '@features/demandeurs/demandeur/view/demandeur.view.component';
import {
    DCDemandeurSelectComponent
} from '@features/demandeurs/demandeur/select/dynamic-components/demandeur-select.component';
import {AppDemandeurCardComponent} from '@features/demandeurs/demandeur/card/demandeur.card.component';
import {AppDemandeurStatutComponent} from '@features/demandeurs/demandeur/statut/demandeur.statut.component';
import {ContactsGroupModule} from '@features/contacts-group/contactsGroup.module';

@NgModule({
    declarations: [
        AppDemandeursSelectComponent,
        AppDemandeurCardComponent,
        AppDemandeurStatutComponent,
        AppDemandeurViewComponent,
        DCDemandeurSelectComponent,
    ],
    exports: [AppDemandeursSelectComponent, AppDemandeurCardComponent, AppDemandeurViewComponent],
    imports: [CommonModule, ContactsGroupModule, SharedModule],
})
export class DemandeursModule {
}
