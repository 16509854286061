export default `
<div class="container-fluid container-fluid-title">
  <h1>Critères de recherche des références en vente</h1>

  <a uib-popover-template="'src/app/legacy/templates/estimations/controllers/item/evaluation/onsale-references/criteria/help.html'"
     popover-trigger="'outsideClick'" popover-placement="bottom-right" class="btn btn-o btn-null -tw-top-1">
    <span class="badge badge-info">?</span>
  </a>
</div>

<div class="container-fluid container-fullw tw-bg-white">
  <esk-search-criteria search="$ctrl.search" update-search-localisations="$ctrl.updateSearchLocalisations"
                       options="{disabledFields: {fieldsetCession: true, fieldsetLocalisationCityscan: true, fieldsetSource: true, nombreChambres: true, surfaceCadastraleMin: true, uniquementBiensNotaire: true}}">
  </esk-search-criteria>

  <button type="button" ng-click="$ctrl.launch()" class="btn btn-primary pull-right btn-save">
    <fa-icon [icon]="'check'"></fa-icon> Lancer la recherche
  </button>
</div>
`;
