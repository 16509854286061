@if (!!ngVente) {
  <div appProvideParentForm>
    <!-- Groupe de contacts -->
    <fieldset>
      <legend>Propriétaires</legend>

      <app-dossier-contacts-group-edit [link$]="contactsGroupLink$" (changed)="onChangeContactsGroup($event)"/>
    </fieldset>

    <!-- Localisation -->
    <fieldset><legend>Localisation</legend> <app-bien-edit-localisation [ngBienDossier]="ngVente"/></fieldset>

    <!-- Description -->
    <fieldset><legend>Description</legend> <app-bien-edit-description [ngBienDossier]="ngVente"/></fieldset>

    <!-- Prix -->
    <fieldset class="prix-edit">
      <legend>
        Prix
        <span class="prix-information">
          <soq-popover-text [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"
                            [text]="'Tous les montants sont exprimés en T.T.C.'"/>
        </span>
      </legend>

      <!-- Prix -->
      <div class="row">
        <!-- Saisie -->
        <div class="col-xs-12 col-sm-6">
          <div class="row">
            <!-- Type de charges -->
            <div class="col-xs-12 col-sm-6 form-group">
              <label>Type de charges *</label>

              <div>
                <app-dictionary-radio [model]="typeCharges" [name]="VENTE_PRIX_CHARGES_TYPES"
                                      (selected)="onSelectTypeCharges($event)"/>
              </div>
            </div>

            <!-- Interactive : Prix de réserve -->
            @if (ngVente.type.code === VENTE_TYPE_INTERACTIVE) {
              <div class="col-xs-12 col-sm-6">
                @if (ngVente.typeCharges.code === DICTIONARY_CODE_NET_VENDEUR) {
                  <div class="form-group" [appFormError]="interactivePrixReserve">
                    <label for="interactive-prix-reserve-nv">Prix de réserve net vendeur *</label>

                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" pattern="^(?:\d*.)?\d+$" id="interactive-prix-reserve-nv"
                             class="form-control text-right" [(ngModel)]="ngVente.interactivePrixReserve"
                             #interactivePrixReserve="ngModel" [required]="ngVente.statut !== VENTE_BROUILLON"
                             name="interactivePrixReserve" (input)="ngVente.operatePricing(true, true)">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>
                }

                @if (ngVente.typeCharges.code === DICTIONARY_CODE_HONO_NEGO_INCLUS) {
                  <div class="form-group" [appFormError]="interactivePrixReserveHni">
                    <label for="interactive-prix-reserve-hni">Prix de réserve honoraires inclus *</label>

                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" pattern="^(?:\d*.)?\d+$" id="interactive-prix-reserve-hni"
                             class="form-control text-right" [(ngModel)]="ngVente._esk.prixReserveHNI"
                             #interactivePrixReserveHni="ngModel" [required]="ngVente.statut !== VENTE_BROUILLON"
                             name="interactivePrixReserveHni" (input)="ngVente.operatePricing(true, true)">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>
                }
              </div>
            }

            <!-- Traditionnelle : Prix de vente -->
            @if (ngVente.type.code === VENTE_TYPE_SIMPLE) {
              <div class="col-xs-12 col-sm-6">
                @if (ngVente.typeCharges.code === DICTIONARY_CODE_NET_VENDEUR) {
                  <div class="form-group" [appFormError]="prixVente">
                    <label for="prix-net-vendeur">Prix net vendeur *</label>

                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" pattern="^(?:\d*.)?\d+$" id="prix-net-vendeur" class="form-control text-right"
                             name="prixVente" [(ngModel)]="ngVente.prixVente" #prixVente="ngModel"
                             [required]="ngVente.statut !== VENTE_BROUILLON"
                             (input)="ngVente.operatePricing(true, true)">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>
                }

                @if (ngVente.typeCharges.code === DICTIONARY_CODE_HONO_NEGO_INCLUS) {
                  <div class="form-group" [appFormError]="prixHNI">
                    <label for="prix-hni">Prix honoraires inclus *</label>

                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" pattern="^(?:\d*.)?\d+$" id="prix-hni" class="form-control text-right"
                             name="prixHNI" [(ngModel)]="ngVente._esk.prixHNI" #prixHNI="ngModel"
                             [required]="ngVente.statut !== VENTE_BROUILLON"
                             (input)="ngVente.operatePricing(true, true)">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>
                }
              </div>
            }

            <!-- Viager : Valeur du bien -->
            @if (ngVente.type.code === VENTE_TYPE_VIAGER) {
              <div class="col-xs-12 col-sm-6 form-group" [appFormError]="viagerValeurBien">
                <label for="viager-valeur-bien">Valeur du bien *</label>

                <div class="input-group input-group-number input-group-currency">
                  <input type="text" pattern="^(?:\d*.)?\d+$" id="viager-valeur-bien" class="form-control text-right"
                         name="viagerValeurBien" [(ngModel)]="ngVente.viagerValeurBien" #viagerValeurBien="ngModel"
                         [required]="ngVente.statut !== VENTE_BROUILLON"
                         (input)="ngVente.operatePricing(true, true)">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            }
          </div>

          <div class="row">
            <!-- Honoraires de négociation -->
            <div class=" col-xs-12 form-group" [appFormError]="honorairesNego"
                 [class]="ngVente.type.code === VENTE_TYPE_INTERACTIVE ? 'col-sm-4' : 'col-sm-6'">
              <label for="vente-honoraires-nego">Honoraires de négociation *</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" pattern="^(?:\d*.)?\d+$" id="vente-honoraires-nego" class="form-control text-right"
                       name="honorairesNego" [(ngModel)]="ngVente.honorairesNego" #honorairesNego="ngModel"
                       (input)="ngVente.operatePricing(true)">

                <span class="input-group-addon">€</span>
              </div>
            </div>

            <!-- Frais d'acte -->
            <div class=" col-xs-12 form-group" [appFormError]="fraisActe"
                 [class]="ngVente.type.code === VENTE_TYPE_INTERACTIVE ? 'col-sm-4' : 'col-sm-6'">
              <label for="vente-frais-acte">Frais d’acte</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" pattern="^(?:\d*.)?\d+$" id="vente-frais-acte" class="form-control text-right"
                       name="fraisActe" [(ngModel)]="ngVente.fraisActe" #fraisActe="ngModel"
                       (input)="ngVente.operatePricing(true)">

                <span class="input-group-addon">€</span>
              </div>
            </div>

            <!-- Frais de publicité -->
            @if (ngVente.type.code === VENTE_TYPE_INTERACTIVE) {
              <div class=" col-xs-12 col-sm-4 form-group" [appFormError]="interactiveFraisPub">
                <label for="vente-frais-publicite">Frais de publicité</label>

                <div class="input-group input-group-number input-group-currency">
                  <input name="interactiveFraisPub" [(ngModel)]="ngVente.interactiveFraisPub"
                         type="text" pattern="^(?:\d*.)?\d+$" id="vente-frais-publicite"
                         #interactiveFraisPub="ngModel" class="form-control text-right">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            }
          </div>
        </div>

        <!-- Affichage du prix interactive -->
        @if (ngVente.type.code === VENTE_TYPE_INTERACTIVE) {
          <div class="col-xs-12 col-sm-6">
            <div class="form-control-static">
              Prix de réserve net vendeur :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.prixReserveNV || 0) | textCurrency }}
              </span>
            </div>

            <div class="form-control-static" title="Prix de réserve net vendeur + Honoraires de négociation">
              Prix de réserve honoraires de négociation inclus :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.prixReserveHNI || 0) | textCurrency }}
              </span>
            </div>

            <div class="form-control-static" title="Honoraires de négociation / Prix de réserve net vendeur">
              Pourcentage des honoraires :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.pourcentageHonoraires || 0) | textCurrency:'%' }}
              </span>
            </div>

            <div class="form-control-static" title="Prix de réserve honoraires de négociation inclus + Frais d'acte">
              Prix de réserve tous frais compris :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.prixReserveTFC || 0) | textCurrency }}
              </span>
            </div>
          </div>
        }

        <!-- Affichage du prix traditionnelle -->
        @if (ngVente.type.code === VENTE_TYPE_SIMPLE) {
          <div class="col-xs-12 col-sm-6">
            <div class="form-control-static">
              Prix net vendeur :
              <span class="text-bold pull-right">{{ (ngVente._esk.infosPricing?.prixNV || 0) | textCurrency }}</span>
            </div>

            <div class="form-control-static" title="Prix net vendeur + Honoraires de négociation">
              Prix honoraires de négociation inclus :
              <span class="text-bold pull-right">{{ (ngVente._esk.infosPricing?.prixHNI || 0) | textCurrency }}</span>
            </div>

            <div class="form-control-static" title="Honoraires de négociation / Prix net vendeur">
              Pourcentage des honoraires :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.pourcentageHonoraires || 0) | textCurrency:'%' }}
              </span>
            </div>

            <div class="form-control-static" title="Prix honoraires de négociation inclus + Frais d'acte">
              Prix tous frais compris :
              <span class="text-bold pull-right">{{ (ngVente._esk.infosPricing?.prixTFC || 0) | textCurrency }}</span>
            </div>
          </div>
        }

        <!-- Affichage du prix viager -->
        @if (ngVente.type.code === VENTE_TYPE_VIAGER) {
          <div class="col-xs-12 col-sm-6">
            <div class="form-control-static">
              Bouquet net vendeur :
              <span class="text-bold pull-right">{{ (ngVente._esk.infosPricing?.bouquetNV || 0) | textCurrency }}</span>
            </div>

            <div class="form-control-static" title="Bouquet net vendeur + Honoraires de négociation">
              Bouquet honoraires de négociation inclus :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.bouquetHNI || 0) | textCurrency }}
              </span>
            </div>

            <div class="form-control-static" title="Honoraires de négociation / Valeur du bien">
              Pourcentage des honoraires :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.pourcentageHonoraires || 0) | textCurrency:'%' }}
              </span>
            </div>

            <div class="form-control-static" title="Bouquet honoraires de négociation inclus + Frais d'acte">
              Bouquet tous frais compris :
              <span class="text-bold pull-right">
                {{ (ngVente._esk.infosPricing?.bouquetTFC || 0) | textCurrency }}
              </span>
            </div>
          </div>
        }
      </div>

      <!-- Autres champs interactive -->
      @if (ngVente.type.code === VENTE_TYPE_INTERACTIVE) {
        <div class="row">
          <!-- Première offre -->
          <div class="colx-xs-12 col-sm-6 col-md-3 form-group" [appFormError]="interactivePremiereOffre">
            <label for="interactive-prix-minimum">Première offre TTC *</label>

            <soq-popover-text [text]="'Dont ' + ((ngVente.honorairesNego || 0) | textCurrency) + ' honoraires de négociation inclus'"
                              [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"/>

            <div class="input-group input-group-number input-group-currency">
              <input class="form-control text-right" [(ngModel)]="ngVente.interactivePremiereOffre"
                     #interactivePremiereOffre="ngModel" [required]="!!ngVente.mandatPremiereDate"
                     type="text" pattern="^(?:\d*.)?\d+$" id="interactive-prix-minimum" name="interactivePremiereOffre">

              <span class="input-group-addon">€</span>
            </div>
          </div>

          <!-- Système enchères -->
          <div class="col-xs-12 col-sm-6 col-md-3 form-group" [appFormError]="interactiveSystemeEncheres.formInput">
            <label>Système d'enchères *</label>

            <app-dictionary-select #interactiveSystemeEncheres="dictionarySelect"
                                   [ngItem]="ngVente.interactiveSystemeEncheres"
                                   [name]="VENTE_INTERACTIVE_ENCHERES_SYSTEMES"
                                   [options]="{required: !!ngVente.mandatPremiereDate}"
                                   (selected)="onSelectInteractiveSystemeEncheres($event)"/>
          </div>

          <!-- Pas offre -->
          <div class="colx-xs-12 col-sm-6 col-md-3 form-group interactive-pas-offre"
               [appFormError]="interactivePasOffre">
            <label for="interactive-pas-offre">Pas des offres TTC *</label>

            <soq-popover-text [text]="'Le pas de l\'offre doit être un multiple de 500 €.'"
                              [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"/>

            <div class="input-group input-group-number input-group-currency">
              <input type="text" pattern="^(0|500|\d+000|\d+500)$" id="interactive-pas-offre"
                     class="form-control text-right" [(ngModel)]="ngVente.interactivePasOffre"
                     #interactivePasOffre="ngModel" [required]="!!ngVente.mandatPremiereDate"
                     name="interactivePasOffre">

              <span class="input-group-addon">€</span>
            </div>
          </div>

          <!-- Date et heure de fin -->
          <div class="col-xs-12 col-sm-6 col-md-3 interactive-date-fin" [appFormError]="interactiveDateFin.formInput">
            <label>Date et heure de fin *</label>

            <soq-popover-template [template]="'src/app/legacy/templates/ventes/controllers/item/edit/general/interactive-date-fin.help.html'"
                                  [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"/>

            <app-form-select-datetime (selected)="onChangeInteractiveDateFin($event)"
                                      [options]="{name: 'bonvisiteDatetime', required: !!ngVente.mandatPremiereDate}"
                                      [model]="ngVente.interactiveDateFin" #interactiveDateFin="formSelectDatetime"/>
          </div>
        </div>
      }

      <!-- Autres champs traditionnelle -->
      @if (ngVente.type.code === VENTE_TYPE_SIMPLE) {
        <div>
          <label for="bien-estimation-min">Estimation</label>

          <div>
            <!-- Estimation minimum -->
            <div class="pull-left form-group" [appFormError]="estimationMin">
              <div class="input-group input-group-number input-group-currency">
                <input type="text" id="bien-estimation-min" pattern="^(?:\d*.)?\d+$" placeholder="Minimum"
                       class="form-control text-right" name="estimationMin" [(ngModel)]="ngVente.estimationMin"
                       #estimationMin="ngModel">

                <span class="input-group-addon">€</span>
              </div>
            </div>

            <!-- Estimation maximum -->
            <div class="pull-left form-group" [appFormError]="estimationMax">
              <div class="input-group input-group-number input-group-currency">
                <input type="text" id="bien-estimation-max" pattern="^(?:\d*.)?\d+$" placeholder="Maximum"
                       class="form-control text-right" name="estimationMax" [(ngModel)]="ngVente.estimationMax"
                       #estimationMax="ngModel">

                <span class="input-group-addon">€</span>
              </div>
            </div>
          </div>
        </div>
      }

      <!-- Autres champs viager -->
      @if (ngVente.type.code === VENTE_TYPE_VIAGER) {
        <div class="row">
          <!-- Bouquet net vendeur -->
          @if (ngVente.typeCharges.code === DICTIONARY_CODE_NET_VENDEUR) {
            <div [appFormError]="viagerBouquet" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group">
              <label for="bouquet-net-vendeur">Bouquet net vendeur {{ hasViagerRente() ? '' : ' *' }}</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" pattern="^(?:\d*.)?\d+$" id="bouquet-net-vendeur" class="form-control text-right"
                       name="viagerBouquet" [(ngModel)]="ngVente.viagerBouquet" #viagerBouquet="ngModel"
                       [required]="!!ngVente.mandatPremiereDate && !hasViagerRente()"
                       (input)="ngVente.operatePricing(true)">

                <span class="input-group-addon">€</span>
              </div>
            </div>
          }

          <!-- Bouquet HNI -->
          @if (ngVente.typeCharges.code === DICTIONARY_CODE_HONO_NEGO_INCLUS) {
            <div [appFormError]="viagerBouquetHni" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group">
              <label for="bouquet-hni">Bouquet honoraires inclus {{ hasViagerRente() ? '' : ' *' }}</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" pattern="^(?:\d*.)?\d+$" id="bouquet-hni" class="form-control text-right"
                       name="viagerBouquetHni" [(ngModel)]="ngVente._esk.bouquetHNI" #viagerBouquetHni="ngModel"
                       [required]="!!ngVente.mandatPremiereDate && !hasViagerRente()"
                       (input)="ngVente.operatePricing(true)">

                <span class="input-group-addon">€</span>
              </div>
            </div>
          }

          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div class="row">
              <!-- Rente -->
              <div class="col-xs-8 form-group" [appFormError]="viagerRente">
                <label for="viager-rente">Rente {{ hasViagerBouquet() ? '' : ' *' }}</label>

                <div class="input-group input-group-number input-group-currency">
                  <input type="text" pattern="^(?:\d*.)?\d+$" id="viager-rente" class="form-control text-right"
                         name="viagerRente" [(ngModel)]="ngVente.viagerRente" #viagerRente="ngModel"
                         [required]="!!ngVente.mandatPremiereDate && !hasViagerBouquet()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>

              <!-- Indexation -->
              @if (hasViagerRente()) {
                <div class="col-xs-4 form-group padding-top-20">
                  <div class="checkbox clip-check check-primary checkbox-inline">
                    <input type="checkbox" id="viager-indexation" name="viagerIndexation"
                           [(ngModel)]="ngVente.viagerIndexation">

                    <label for="viager-indexation">Indexation</label>
                  </div>
                </div>
              }
            </div>
          </div>

          <!-- Périodicité-->
          @if (hasViagerRente()) {
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group"
                 [appFormError]="viagerPeriodiciteRente.formInput">
              <label>Périodicité {{ hasViagerRente() ? ' *' : '' }}</label>

              <app-dictionary-select [ngItem]="ngVente.viagerPeriodiciteRente" [name]="DICTIONARY_NAME_PERIODICITES"
                                     (selected)="onSelectViagerPeriodiciteRente($event)"
                                     [options]="{name: 'viagerPeriodiciteRente', required: hasViagerRente()}"
                                     #viagerPeriodiciteRente="dictionarySelect"/>
            </div>
          }

          <!-- Nombre de têtes -->
          <div class="col-xs-12">
            <ul>
              <li class="row margin-top-5" [appFormError]="viagerNombreTetes">
                <div class="col-xs-4 col-sm-3 col-md-2 col-lg-1 tw-pt-2">
                  <label for="viager-nombre-tetes">Nombre de têtes</label>
                </div>

                <div class="col-xs-4 col-sm-3 col-md-2">
                  <div class="input-group input-group-number">
                    <input type="number" id="viager-nombre-tetes" name="viagerNombreTetes"
                           class="form-control text-right" [(ngModel)]="ngVente.viagerNombreTetes" max="2" min="0"
                           #viagerNombreTetes="ngModel" (input)="ngVente.onChangeViagerNombreTetes()">
                  </div>
                </div>
              </li>

              @for (viagerTete of ngVente.viagerTetes; track viagerTete; let idx = $index) {
                <li class="row margin-top-5">
                  <div class="col-xs-12 col-sm-3 col-md-2 col-lg-1 tw-pt-2">Crédirentier {{ idx + 1 }}</div>

                  <div class="col-xs-6 col-sm-3 col-md-2" [appFormError]="viagerTeteAge">
                    <div class="input-group input-group-number input-group-currency">
                      <input type="number" [name]="'viagerTeteAge' + idx" pattern="[0-9]+" required placeholder="Âge"
                             class="form-control text-right" [(ngModel)]="viagerTete.age" min="0"
                             #viagerTeteAge="ngModel">

                      <span class="input-group-addon">ans</span>
                    </div>
                  </div>

                  <div class="col-xs-6 col-sm-2" [appFormError]="viagerTeteSexe.formInput">
                    <app-dictionary-select #viagerTeteSexe="dictionarySelect" [name]="DICTIONARY_NAME_SEXES"
                                           [options]="{name: 'viagerTeteSexe' + idx, placeholder: 'Sexe', required: true}"
                                           (selected)="onSelectViagerTeteSexe(viagerTete, $event)"
                                           [model]="viagerTete.sexe"/>
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>
      }
    </fieldset>

    <!-- Dossier -->
    <fieldset><legend>Dossier</legend> <app-bien-edit-dossier [ngBienDossier]="ngVente"/></fieldset>
  </div>
}
