import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import VenteCreditRentier from '@models/ventes/vente/credit-rentier/vente-credit-rentier.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default class Vente extends ADossierBien {
    static readonly archiveNegocieeRaisons = {
        AUTRES: 'autres',
        PORTEFEUILLE: 'portefeuille',
        RESEAU: 'reseau_notaires',
    };
    static readonly archiveNonNegocieeRaisons = {AGENCE: 'agence', AUTRES: 'autres', PAP: 'pap'};
    static readonly archiveTypes = {NEGOCIEE: 'negociee', NON_NEGOCIEE: 'non_negociee', RETIREE: 'retiree'};
    static readonly chargesType = {HONO_NEGO_INCLUS: 'honoraires_nego_inclus', NET_VENDEUR: 'net_vendeur'};
    static readonly compromisTypes = {AGENCE: 'agence', DUO: 'duo', PAP: 'pap', SOLO: 'solo'};
    static readonly interactiveSystemeEncheres = {ASCENDANTES: 'ascendantes', DESCENDANTES: 'descendantes'};
    static readonly mandatTypes = {EXCLUSIF: 'exclusif', NON_EXCLUSIF: 'non_exclusif'};
    static readonly statuts = {
        ARCHIVE: ADossierBien.abstractBienStatuts.ARCHIVE,
        BROUILLON: ADossierBien.abstractBienStatuts.BROUILLON,
        COMPROMIS: ADossierBien.abstractBienStatuts.COMPROMIS,
        DIFFUSE: ADossierBien.abstractBienStatuts.DIFFUSE,
        MANDAT: ADossierBien.abstractBienStatuts.MANDAT,
        SUSPENDU: ADossierBien.abstractBienStatuts.SUSPENDU,
        NEW: ADossierBien.abstractBienStatuts.NEW,
    };
    static readonly templates = {
        AFFICHETTE: 'affichette-vente',
        COURRIER_FICHE: 'fiche-vente-courrier-introductif',
        FICHE: 'fiche-vente',
        FICHE_CONFIDENTIELLE: 'fiche-vente-confidentielle',
        FICHE_CONFRERE: 'fiche-vente-confrere',
    };
    static readonly types = {INTERACTIVE: 'interactive', SIMPLE: 'simple', VIAGER: 'viager'};
    static readonly CODES_DEFAULT = {
        interactiveSystemeEncheres: Vente.interactiveSystemeEncheres.ASCENDANTES,
        mandatType: Vente.mandatTypes.NON_EXCLUSIF,
        type: Vente.types.SIMPLE,
        typeCharges: Vente.chargesType.NET_VENDEUR,
    };
    static readonly IDS_DEFAULT = {viagerPeriodiciteRente: 1};
    readonly dossierType = DossierBienTypesConst.VENTE;
    private _archiveAcquereur!: string;
    private _archiveDateVente!: string;
    private _archiveDemandeurId!: number;
    private _archiveEtudeAcquereurId!: number;
    private _archiveHonorairesNego!: number;
    private _archiveHonorairesNegoPercus!: number;
    private _archivePrixCession!: number;
    private _archiveRaisonVenteNegociee!: DictionaryItem;
    private _archiveRaisonVenteNonNegociee!: DictionaryItem;
    private _archiveType!: DictionaryItem;
    private _compromisList: VenteCompromis[] = [];
    private _enabledCompromis!: VenteCompromis;
    private _estimationMax!: number;
    private _estimationMin!: number;
    private _fraisActe!: number;
    private _honorairesNego!: number;
    private _interactiveDateFin!: string;
    private _interactiveFraisPub!: number;
    private _interactivePasOffre!: number;
    private _interactivePremiereOffre!: number;
    private _interactivePrixReserve!: number;
    private _interactiveSystemeEncheres!: DictionaryItem;
    private _labelPrice!: string;
    private _labelPriceNC!: string;
    private _linkArchiveDemandeur!: string;
    private _linkArchiveEtudeAcquereur!: string;
    private _linkCompromisActif!: string;
    private _linkEtude!: string;
    private _mandatDate!: string;
    private _mandatDateFin!: string;
    private _mandatType!: DictionaryItem;
    private _prixAffiche!: number;
    private _prixVente!: number;
    private _referencePublique!: string;
    private _type!: DictionaryItem;
    private _typeCharges!: DictionaryItem;
    private _viagerBouquet!: number;
    private _viagerIndexation!: boolean;
    private _viagerNombreTetes!: number;
    private _viagerPeriodiciteRente!: DictionaryItem;
    private _viagerRente!: number;
    private _viagerTetes: VenteCreditRentier[] = [];
    private _viagerValeurBien!: number;

    constructor(uuid: string, id?: number, reference?: string) {
        super(uuid, id, reference);
        this._printAffichetteCategoryCodes = [Vente.templates.AFFICHETTE];
        this._printCourrierFicheCategoryCodes = [Vente.templates.COURRIER_FICHE];
        this._printFicheCategoryCodes = [Vente.templates.FICHE];
        this._sendFicheCategoryCode = Vente.templates.FICHE;
    }

    get archiveAcquereur(): string {
        return this._archiveAcquereur;
    }

    set archiveAcquereur(value: string) {
        this._archiveAcquereur = value;
    }

    get archiveDateVente(): string {
        return this._archiveDateVente;
    }

    set archiveDateVente(value: string) {
        this._archiveDateVente = value;
    }

    get archiveDemandeurId(): number {
        return this._archiveDemandeurId;
    }

    set archiveDemandeurId(value: number) {
        this._archiveDemandeurId = value;
    }

    get archiveEtudeAcquereurId(): number {
        return this._archiveEtudeAcquereurId;
    }

    set archiveEtudeAcquereurId(value: number) {
        this._archiveEtudeAcquereurId = value;
    }

    get archiveHonorairesNego(): number {
        return this._archiveHonorairesNego;
    }

    set archiveHonorairesNego(value: number) {
        this._archiveHonorairesNego = value;
    }

    get archiveHonorairesNegoPercus(): number {
        return this._archiveHonorairesNegoPercus;
    }

    set archiveHonorairesNegoPercus(value: number) {
        this._archiveHonorairesNegoPercus = value;
    }

    get archivePrixCession(): number {
        return this._archivePrixCession;
    }

    set archivePrixCession(value: number) {
        this._archivePrixCession = value;
    }

    get archiveRaisonVenteNegociee(): DictionaryItem {
        return this._archiveRaisonVenteNegociee;
    }

    set archiveRaisonVenteNegociee(value: DictionaryItem) {
        this._archiveRaisonVenteNegociee = value;
    }

    get archiveRaisonVenteNonNegociee(): DictionaryItem {
        return this._archiveRaisonVenteNonNegociee;
    }

    set archiveRaisonVenteNonNegociee(value: DictionaryItem) {
        this._archiveRaisonVenteNonNegociee = value;
    }

    get archiveType(): DictionaryItem {
        return this._archiveType;
    }

    set archiveType(value: DictionaryItem) {
        this._archiveType = value;
    }

    get compromisList(): VenteCompromis[] {
        return this._compromisList;
    }

    set compromisList(value: VenteCompromis[]) {
        this._compromisList = value;
        this._enabledCompromis = this.compromisList.find(compromis => compromis.enabled)!;
    }

    get enabledCompromis(): VenteCompromis {
        return this._enabledCompromis;
    }

    get estimationMax(): number {
        return this._estimationMax;
    }

    set estimationMax(value: number) {
        this._estimationMax = value;
    }

    get estimationMin(): number {
        return this._estimationMin;
    }

    set estimationMin(value: number) {
        this._estimationMin = value;
    }

    get fraisActe(): number {
        return this._fraisActe;
    }

    set fraisActe(value: number) {
        this._fraisActe = value;
    }

    get honorairesNego(): number {
        return this._honorairesNego;
    }

    set honorairesNego(value: number) {
        this._honorairesNego = value;
    }

    get interactiveDateFin(): string {
        return this._interactiveDateFin;
    }

    set interactiveDateFin(value: string) {
        this._interactiveDateFin = value;
    }

    get interactiveFraisPub(): number {
        return this._interactiveFraisPub;
    }

    set interactiveFraisPub(value: number) {
        this._interactiveFraisPub = value;
    }

    get interactivePasOffre(): number {
        return this._interactivePasOffre;
    }

    set interactivePasOffre(value: number) {
        this._interactivePasOffre = value;
    }

    get interactivePremiereOffre(): number {
        return this._interactivePremiereOffre;
    }

    set interactivePremiereOffre(value: number) {
        this._interactivePremiereOffre = value;
    }

    get interactivePrixReserve(): number {
        return this._interactivePrixReserve;
    }

    set interactivePrixReserve(value: number) {
        this._interactivePrixReserve = value;
    }

    get interactiveSystemeEncheres(): DictionaryItem {
        return this._interactiveSystemeEncheres;
    }

    set interactiveSystemeEncheres(value: DictionaryItem) {
        this._interactiveSystemeEncheres = value;
    }

    get labelPrice(): string {
        return this._labelPrice;
    }

    get labelPriceNC(): string {
        return this._labelPriceNC;
    }

    get linkArchiveDemandeur(): string {
        return this._linkArchiveDemandeur;
    }

    set linkArchiveDemandeur(value: string) {
        this._linkArchiveDemandeur = value;
    }

    get linkArchiveEtudeAcquereur(): string {
        return this._linkArchiveEtudeAcquereur;
    }

    set linkArchiveEtudeAcquereur(value: string) {
        this._linkArchiveEtudeAcquereur = value;
    }

    get linkCompromisActif(): string {
        return this._linkCompromisActif;
    }

    set linkCompromisActif(value: string) {
        this._linkCompromisActif = value;
    }

    get linkEtude(): string {
        return this._linkEtude;
    }

    set linkEtude(value: string) {
        this._linkEtude = value;
    }

    get mandatDate(): string {
        return this._mandatDate;
    }

    set mandatDate(value: string) {
        this._mandatDate = value;
    }

    get mandatDateFin(): string {
        return this._mandatDateFin;
    }

    set mandatDateFin(value: string) {
        this._mandatDateFin = value;
    }

    get mandatType(): DictionaryItem {
        return this._mandatType;
    }

    set mandatType(value: DictionaryItem) {
        this._mandatType = value;
    }

    get prixAffiche(): number {
        return this._prixAffiche;
    }

    set prixAffiche(value: number) {
        this._prixAffiche = value;
    }

    get prixVente(): number {
        return this._prixVente;
    }

    set prixVente(value: number) {
        this._prixVente = value;
    }

    get referencePublique(): string {
        return this._referencePublique;
    }

    set referencePublique(value: string) {
        this._referencePublique = value;
    }

    get type(): DictionaryItem {
        return this._type;
    }

    set type(value: DictionaryItem) {
        this._type = value;
        if (this.isInteractive()) {
            this._labelPrice = 'la première offre';
            this._labelPriceNC = 'première offre non communiquée';
        } else if (this.isSimple()) {
            this._labelPrice = 'le prix de vente';
            this._labelPriceNC = 'prix de vente non communiqué';
        } else if (this.isViager()) {
            this._labelPrice = 'le bouquet';
            this._labelPriceNC = 'bouquet non communiqué';
        } else {
            this._labelPrice = 'le prix';
            this._labelPriceNC = 'prix non communiqué';
        }
    }

    get typeCharges(): DictionaryItem {
        return this._typeCharges;
    }

    set typeCharges(value: DictionaryItem) {
        this._typeCharges = value;
    }

    get viagerBouquet(): number {
        return this._viagerBouquet;
    }

    set viagerBouquet(value: number) {
        this._viagerBouquet = value;
    }

    get viagerIndexation(): boolean {
        return this._viagerIndexation;
    }

    set viagerIndexation(value: boolean) {
        this._viagerIndexation = value;
    }

    get viagerNombreTetes(): number {
        return this._viagerNombreTetes;
    }

    set viagerNombreTetes(value: number) {
        this._viagerNombreTetes = value;
    }

    get viagerPeriodiciteRente(): DictionaryItem {
        return this._viagerPeriodiciteRente;
    }

    set viagerPeriodiciteRente(value: DictionaryItem) {
        this._viagerPeriodiciteRente = value;
    }

    get viagerRente(): number {
        return this._viagerRente;
    }

    set viagerRente(value: number) {
        this._viagerRente = value;
    }

    get viagerTetes(): VenteCreditRentier[] {
        return this._viagerTetes;
    }

    set viagerTetes(value: VenteCreditRentier[]) {
        this._viagerTetes = value;
    }

    get viagerValeurBien(): number {
        return this._viagerValeurBien;
    }

    set viagerValeurBien(value: number) {
        this._viagerValeurBien = value;
    }

    isInteractive(): boolean {
        return this.type.code === Vente.types.INTERACTIVE;
    }

    isSimple(): boolean {
        return this.type.code === Vente.types.SIMPLE;
    }

    isVenteNegociee(): boolean {
        return this.archiveType?.code === Vente.archiveTypes.NEGOCIEE;
    }

    isVenteNegocieePortefeuille(): boolean {
        return this.archiveRaisonVenteNegociee?.code === Vente.archiveNegocieeRaisons.PORTEFEUILLE;
    }

    isVenteNegocieeReseau(): boolean {
        return this.archiveRaisonVenteNegociee?.code === Vente.archiveNegocieeRaisons.RESEAU;
    }

    isVenteNonNegociee(): boolean {
        return this.archiveType?.code === Vente.archiveTypes.NON_NEGOCIEE;
    }

    isViager(): boolean {
        return this.type.code === Vente.types.VIAGER;
    }

    setInterne(value: boolean): void {
        this._interne = value;
        this._printFicheCategoryCodes = [
            Vente.templates.FICHE,
            this.interne ? Vente.templates.FICHE_CONFIDENTIELLE : Vente.templates.FICHE_CONFRERE,
        ];
    }
}
