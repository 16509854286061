@if (cContacts$ | async; as cContacts) {
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-items-center">
      <div class="tw-flex tw-font-bold tw-text-base">
        <app-noteo-icon-text [label]="infoSources[source].LABEL" [srcImage]="infoSources[source].LOGO"/>
      </div>

      <span [ngPlural]="cContacts.total" class="tw-flex-1 tw-text-right">
        <ng-template ngPluralCase="=0">Aucun résultat</ng-template>
        <ng-template ngPluralCase="=1">1 résultat trouvé</ng-template>
        <ng-template ngPluralCase="other">{{ cContacts.total }} résultats trouvés</ng-template>
      </span>
    </div>

    @if (cContacts.results.length) {
      <div class="tw-mt-4">
        <ul>
          @for (contact of cContacts.results; track contact) {
            <li class="tw-bg-gray-50 tw-rounded-lg tw-p-2 tw-my-1 tw-flex tw-w-full tw-space-x-8">
              <app-contact-card class="tw-block tw-flex-1 sm:tw-flex-none sm:tw-min-w-[15rem] md:tw-min-w-[20rem] tw-w-px"
                                [options]="{enabledClick: false, highlight: keyword, withExternalSource: infoSources[source].DISPLAY_SOURCE}"
                                [contact]="contact"/>
              <div class="tw-self-end tw-flex-1 tw-hidden sm:tw-block tw-w-px">
                <div class="tw-truncate">
                  @if (contact.linkCommune) {
                    <span class="tw-font-semibold">
                      <app-commune-convivial-name [linkCommune]="contact.linkCommune"/>
                    </span>
                  }
                  {{ contact.codePostal }}
                </div>

                <div class="tw-line-clamp-2" [innerHTML]="contact.adresse | textNl2br" [highlight]="keyword"></div>
              </div>

              <div class="tw-w-20 tw-self-center tw-text-center">
                @if (isAlreadySelected(contact)) {
                  <span class="tw-font-semibold tw-text-green-500">
                    <fa-icon [icon]="'check'"/><span class="tw-hidden sm:tw-inline-block tw-ml-1">Ajouté</span>
                  </span>
                } @else {
                  <button type="button" (click)="add(contact)" [disabled]="!enableAdding"
                          class="tw-btn-secondary-info disabled:tw-waiting">
                    <fa-icon [icon]="'plus'" class="sm:tw-hidden"/>

                    <span class="tw-hidden sm:tw-inline-block">Ajouter</span>
                  </button>
                }
              </div>
            </li>
          }
        </ul>
      </div>
    }

    <app-load-more [collection$]="cContacts$" (loadMore)="getMore(cContacts)" [loading]="pendingGetMore"
                   [disabled]="!enableAdding"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-20'" class="tw-block"/>
}
