import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IReferenceApi} from '@models/references/reference/reference.interfaces';
import {ICReferencesApi} from '@models/references/collection/references.collection.interfaces';
import {HttpEventProgress, HttpOptions} from '@core/api/api.interfaces';
import {HttpResponse} from '@angular/common/http';
import {IQueryParameters} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class ReferencesApiService {
    private _modelApiService = inject(ModelApiService);

    getByLink$(link: string): Observable<IReferenceApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: IQueryParameters, httpOptions?: HttpOptions): Observable<ICReferencesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/references', queryParameters), httpOptions);
    }

    getCollectionByLink$(link: string, httpOptions?: HttpOptions): Observable<ICReferencesApi> {
        return this._modelApiService.getCollection$(link, httpOptions);
    }

    import$(formData: FormData): Observable<HttpEventProgress | HttpResponse<ICReferencesApi>> {
        return this._modelApiService.upload$('/references/import', formData);
    }

    save$(referenceApi: IReferenceApi): Observable<IReferenceApi> {
        return this._modelApiService.post$('/user/references', referenceApi);
    }
}
