import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Location from '@models/locations/location/location.model';

export default function getLocationLabelStatut(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-location-label-statut></esk-location-label-statut>
         */
        module.component('eskLocationLabelStatut', {
            bindings: {location: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/locations/components/item/label/statut.html'
        });

        /**
         * Controller for location statut label
         *
         * @param Ng2EtudeService
         */
        Controller.$inject = ['Ng2EtudeService'];
        function Controller(this: any, ng2EtudeService: EtudeService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.STATUTS = Location.statuts;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.hasNetwork = false;
                ng2EtudeService.last$.subscribe(currentEtude => $ctrl.hasNetwork = currentEtude.hasNetwork());
            }
        }
    })(angularJS);
}
