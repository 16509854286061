export default `
<soq-panel box="$ctrl.box">
  <content-slot>
    <esk-ventes-table-negociees-natures></esk-ventes-table-negociees-natures>
  </content-slot>

  <footer-slot>
    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/negociees/natures')">
      <fa-icon [icon]="'arrow-circle-right'"></fa-icon> Détails des ventes
    </a>
  </footer-slot>
</soq-panel>
`;
