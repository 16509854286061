import TemplateFooter from '@models/templates/footers/footer/template-footer.model';

export default class EtudeSettingsDocumentsFooter {
    static readonly CODE_CUSTOM = 'custom';
    private _code!: string;
    private _customHeight!: number;
    private _customTemplate!: string;
    private _enabled!: boolean;

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get customHeight(): number {
        return this._customHeight;
    }

    set customHeight(value: number) {
        this._customHeight = value;
    }

    get customTemplate(): string {
        return this._customTemplate;
    }

    set customTemplate(value: string) {
        this._customTemplate = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }

    isCustom(): boolean {
        return this.enabled && this.code === TemplateFooter.codes.CUSTOM;
    }

    mustUseCustomData(): boolean {
        return !this.enabled || this.isCustom();
    }
}
