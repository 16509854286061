import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager} from '@legacy/app/managers/managers';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Dictionary from '@core/models/dictionaries/dictionary/dictionary.model';

export default function getManagersDictionaries(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('DictionariesManager', manager);

        /**
         * Manager dictionaries
         *
         * @param BaseManager
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            /**
             * DictionariesManager object
             *
             * @constructor
             */
            angular.extend(DictionariesManager.prototype, baseManager.__proto__);
            DictionariesManager.prototype.eskManager = {prefixRoute: 'dictionaries'};
            function DictionariesManager() {
            }

            // @todo Vérifier tous les cas d'utilisation et vérifier leur utilité
            DictionariesManager.prototype.createDictionaryFromNg2 = function (ng2Dictionary: Dictionary) {
                if (!ng2Dictionary || !ng2Dictionary.items || ng2Dictionary.items.length <= 0) {
                    return [];
                }

                return ng2Dictionary.items.map(ng2DictionaryItem => this.createFromNg2(ng2DictionaryItem));
            };

            // @todo Vérifier tous les cas d'utilisation et vérifier leur utilité
            DictionariesManager.prototype.createFromNg2 = function (ng2DictionaryItem: DictionaryItem) {
                if (!ng2DictionaryItem) {
                    return undefined!;
                }

                return this.create({
                    abreviation: ng2DictionaryItem.abreviation,
                    code: ng2DictionaryItem.code,
                    codePasserelle: ng2DictionaryItem.codePasserelle,
                    codes: ng2DictionaryItem.codes,
                    defaultComments: ng2DictionaryItem.defaultComments,
                    deprecated: ng2DictionaryItem.deprecated,
                    duree: ng2DictionaryItem.duree,
                    id: ng2DictionaryItem.id,
                    label: ng2DictionaryItem.label,
                    shortLabel: ng2DictionaryItem.shortLabel,
                    tags: ng2DictionaryItem.tags,
                    uuid: ng2DictionaryItem.uuid,
                });
            };

            // @ts-ignore
            return new DictionariesManager();
        }
    })(angularJS);
}
