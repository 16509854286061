import AModel from '@models/model.abstract';

export default class EstimationOnsaleReference extends AModel {
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private _adresse!: string;
    private _communeId!: number;
    private _description!: string;
    private _imageUrl!: string;
    private _linkCommune!: string;
    private _natureId!: number;
    private _nombrePieces!: number;
    private _prixM2 = 0;
    private _prixVente!: number;
    private _publishedAt!: string;
    private _sortableRank!: number;
    private _superficie!: number;
    private _surfaceCadastrale!: number;
    private _url!: string;

    get adresse(): string {
        return this._adresse;
    }

    set adresse(value: string) {
        this._adresse = value;
    }

    get communeId(): number {
        return this._communeId;
    }

    set communeId(value: number) {
        this._communeId = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get imageUrl(): string {
        return this._imageUrl;
    }

    set imageUrl(value: string) {
        this._imageUrl = value;
    }

    get linkCommune(): string {
        return this._linkCommune;
    }

    set linkCommune(value: string) {
        this._linkCommune = value;
    }

    get natureId(): number {
        return this._natureId;
    }

    set natureId(value: number) {
        this._natureId = value;
    }

    get nombrePieces(): number {
        return this._nombrePieces;
    }

    set nombrePieces(value: number) {
        this._nombrePieces = value;
    }

    get prixM2(): number {
        return this._prixM2;
    }

    get prixVente(): number {
        return this._prixVente;
    }

    set prixVente(value: number) {
        this._prixVente = value;
        this.setPrixM2();
    }

    get publishedAt(): string {
        return this._publishedAt;
    }

    set publishedAt(value: string) {
        this._publishedAt = value;
    }

    get sortableRank(): number {
        return this._sortableRank;
    }

    set sortableRank(value: number) {
        this._sortableRank = value;
    }

    get superficie(): number {
        return this._superficie;
    }

    set superficie(value: number) {
        this._superficie = value;
        this.setPrixM2();
    }

    get surfaceCadastrale(): number {
        return this._surfaceCadastrale;
    }

    set surfaceCadastrale(value: number) {
        this._surfaceCadastrale = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    setPrixM2(): void {
        this._prixM2 = this.prixVente && this.superficie && this.superficie > 0 ? this.prixVente / this.superficie : 0;
    }
}
