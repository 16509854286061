import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IScope} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgLocation} from '@legacy/app/managers/ressources';
import Location from '@models/locations/location/location.model';

export default function getLocationsList(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-locations-list></esk-locations-list>
         * @param options : {deleteResultRemoved(function), displayMode(string), loader: {updating(bool)}, nameDossiers(string), onChangeFilter(function), typeFilter(string), visibleFields(object)}
         * @param options.visibleFields : {angleRight(bool), bullet(bool), bullet_help(bool), localisation(bool), loyer(bool), nature(bool), nombreChambres(bool), nombrePieces(bool), nomContactsGroup(bool), photo(bool), preciseLocalisation(bool), select(bool), statut(bool)}
         */
        module.component('eskLocationsList', {
            bindings: {
                filter: '=',
                locations: '=',
                click: '=onClick',
                select: '=onSelect',
                unselectAll: '=?',
                options: '=',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/locations/components/list.html'
        });

        /**
         * Controller for locations lists
         *
         * @param $scope
         * @param Ng2RouterStateForNgService
         */
        Controller.$inject = ['$scope', 'Ng2RouterStateForNgService'];
        function Controller(this: any, $scope: IScope, ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectAll = onSelectAll;
            $ctrl.onSelect = onSelect;
            $ctrl.onClick = onClick;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.STATUTS = Location.statuts;

            /**
             * Watch on locations total items change
             */
            $scope.$watch('$ctrl.locations', function () {
                if (angular.isObject($ctrl.locations) && angular.isArray($ctrl.locations.collection)) processSelectAll();
            });

            /**
             * Initialization method
             */
            function $onInit() {
                var listFields = ['angleRight', 'bullet', 'bullet_help', 'localisation', 'loyer', 'nature', 'nomContactsGroup', 'nombreChambres', 'nombrePieces', 'photo', 'preciseLocalisation', 'select', 'statut'];

                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
                if (!angular.isString($ctrl.options.displayMode)) $ctrl.options.displayMode = 'table';
                if (!angular.isObject($ctrl.options.visibleFields)) $ctrl.options.visibleFields = {};

                angular.forEach(listFields, function (field) {
                    if ($ctrl.options.visibleFields[field] !== false) {
                        $ctrl.options.visibleFields[field] = true;
                    }
                });
                $ctrl.unselectAll = unselectAll;

                if (angular.isFunction($ctrl.options.onChangeFilter)) {
                    $ctrl.onChangeSort = onChangeSort;
                }
            }

            /**
             * Select all locations
             */
            function onSelectAll() {
                angular.forEach($ctrl.locations.collection, function (location) {
                    location._esk.select = $ctrl.selectAll;
                    $ctrl.onSelect(location, true);
                });
            }

            /**
             * Select location
             *
             * @param location
             * @param ignoreSelectAll
             */
            function onSelect(location: NgLocation, ignoreSelectAll: boolean) {
                if (!ignoreSelectAll) {
                    if (location._esk.select) {
                        processSelectAll();
                    } else {
                        $ctrl.selectAll = false;
                    }
                }

                if (angular.isFunction($ctrl.select)) {
                    $ctrl.select(location);
                }
            }

            /**
             * Click on item
             *
             * @param location
             */
            function onClick(location: NgLocation) {
                if (angular.isFunction($ctrl.click)) {
                    $ctrl.click(location);
                } else if (angular.isString(location._esk.routing)) {
                    $ctrl.ng2RouterStateForNgService.navigateByUrl(location._esk.routing);
                } else {
                    location._esk.select = !location._esk.select;
                    $ctrl.onSelect(location);
                }
            }

            /**
             * Define selectAll
             */
            function processSelectAll() {
                $ctrl.selectAll = filter($ctrl.locations.collection, {_esk: {select: true}}).length === $ctrl.locations.collection.length;
            }

            /**
             * Unselect all selected locations
             */
            function unselectAll() {
                angular.forEach($ctrl.locations.collection, function (location) {
                    location._esk.select = false;
                });
                processSelectAll();
            }

            /**
             * On change sort on column
             *
             * @param sort
             */
            function onChangeSort(sort: unknown) {
                $ctrl.filter.queryParameters.sort = sort;
                $ctrl.filter.save().then($ctrl.options.onChangeFilter);
            }
        }
    })(angularJS);
}
