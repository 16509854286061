@if (cDossierFileContacts$ | async; as cDossierFileContacts) {
  @if (cDossierFileContacts.total > 0) {
    {{options.labelPrefix}}

    @if (cDossierFileContacts.total > 1) {
      <span class="tw-font-bold">{{cDossierFileContacts.total}}</span>
      contact{{cDossierFileContacts.total > 1 ? 's' : ''}}
    } @else {
      <span class="tw-font-bold">
        <app-contact-convivial-name [linkContact]="cDossierFileContacts.results[0].linkContact"/>
      </span>
    }
  } @else {
    {{options.labelNoOne}}
  }
} @else {
  <app-loader [classHeight]="'tw-h-5'"/>
}
