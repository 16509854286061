import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import AModel from '@models/model.abstract';

export default class VenteCreditRentier extends AModel {
    private _age!: number;
    private _sexe!: DictionaryItem;

    get age(): number {
        return this._age;
    }

    set age(value: number) {
        this._age = value;
    }

    get sexe(): DictionaryItem {
        return this._sexe;
    }

    set sexe(value: DictionaryItem) {
        this._sexe = value;
    }

    isAgeValid(): boolean {
        return !!this._age && this._age > 0;
    }

    isSexeValid(): boolean {
        return !!this._sexe;
    }

    isValid(): boolean {
        return this.isAgeValid() && this.isSexeValid();
    }
}
