import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import rentTemplate from '@legacy/templates/locations/components/item/label/rent';
import statutTemplate from '@legacy/templates/locations/components/item/label/statut';
import listTemplate from '@legacy/templates/locations/components/list';
import calculMontantPremierMoisHelpTemplate from '@legacy/templates/locations/controllers/item/edit/general/calcul-montant-premier-mois.help';
import calculMontantTotalDuHelpTemplate from '@legacy/templates/locations/controllers/item/edit/general/calcul-montant-total-du.help';
import conditionsLoyerBaseHelpTemplate from '@legacy/templates/locations/controllers/item/edit/general/conditions-loyer-base.help';
import btnsActionsTemplate from '@legacy/templates/locations/controllers/item/mandats/btns.actions';
import gestionTemplate from '@legacy/templates/locations/controllers/item/gestion';
import lifetimeTemplate from '@legacy/templates/locations/controllers/item/lifetime';
import mandatTemplate from '@legacy/templates/locations/controllers/item/mandats';
import archiveTemplate from '@legacy/templates/locations/controllers/modals/item/archive';
import editEtatLieuxTemplate from '@legacy/templates/locations/controllers/modals/item/edit.etats-lieux';
import depreactedLoyerTemplate from '@legacy/templates/locations/deprecated_loyer';
import disponibiliteMissingInformationTemplate from '@legacy/templates/locations/disponibilite.missing-information';
import keywordsHelpTemplate from '@legacy/templates/locations/keywords.help';

export default function getLocationsTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/locations/components/item/label/rent.html', rentTemplate);
            $templateCache.put('src/app/legacy/templates/locations/components/item/label/statut.html', statutTemplate);
            $templateCache.put('src/app/legacy/templates/locations/components/list.html', listTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/edit/general/calcul-montant-premier-mois.help.html', calculMontantPremierMoisHelpTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/edit/general/calcul-montant-total-du.help.html', calculMontantTotalDuHelpTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/edit/general/conditions-loyer-base.help.html', conditionsLoyerBaseHelpTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/mandats/btns.actions.html', btnsActionsTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/gestion.html', gestionTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/lifetime.html', lifetimeTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/item/mandats.html', mandatTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/modals/item/archive.html', archiveTemplate);
            $templateCache.put('src/app/legacy/templates/locations/controllers/modals/item/edit.etats-lieux.html', editEtatLieuxTemplate);
            $templateCache.put('src/app/legacy/templates/locations/deprecated_loyer.html', depreactedLoyerTemplate);
            $templateCache.put('src/app/legacy/templates/locations/disponibilite.missing-information.html', disponibiliteMissingInformationTemplate);
            $templateCache.put('src/app/legacy/templates/locations/keywords.help.html', keywordsHelpTemplate);
        }
    })(angularJS);
}
