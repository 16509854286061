import Contact from '@models/contacts/contact/contact.model';

export default class Entreprise extends Contact {
    private _capital!: number;
    private _formeJuridique!: string;
    private _raisonSociale!: string;
    private _siret!: string;

    get capital(): number {
        return this._capital;
    }

    set capital(value: number) {
        this._capital = value;
    }

    get formeJuridique(): string {
        return this._formeJuridique;
    }

    set formeJuridique(value: string) {
        this._formeJuridique = value;
    }

    get raisonSociale(): string {
        return this._raisonSociale;
    }

    set raisonSociale(value: string) {
        this._raisonSociale = value;
        this.setConvivialName();
    }

    get siret(): string {
        return this._siret;
    }

    set siret(value: string) {
        this._siret = value;
    }

    get type(): string {
        return Contact.type.ENTREPRISE;
    }

    isNew(): boolean {
        return super.isNew() || this.uuid === Entreprise.statuts.NEW;
    }

    setConvivialName(): void {
        this.convivialName = this.raisonSociale || Contact.NO_NAME;
    }
}
