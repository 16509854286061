import {inject, Injectable} from '@angular/core';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {Observable, of} from 'rxjs';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProcedureSignataireService {
    private _phoneNumberService = inject(PhoneNumberService);
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);

    delete$(procedureUuid: string, procedureSignataire: ProcedureSignataire): Observable<void> {
        if (procedureSignataire.isNew()) {
            return of(undefined);
        }

        return this._procedureSignataireFactory.delete$(procedureUuid, procedureSignataire);
    }

    sign$(procedureUuid: string, procedureSignataire: ProcedureSignataire, signature: string): Observable<void> {
        return this._procedureSignataireFactory.sign$(procedureUuid, procedureSignataire, signature).pipe(
            tap(_ => procedureSignataire.setFinished()),
        );
    }
}
