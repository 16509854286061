import AModel from '@models/model.abstract';

export default class DossierFileContact extends AModel {
    private readonly _id: number;
    private _linkContact!: string;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get id(): number {
        return this._id;
    }

    get linkContact(): string {
        return this._linkContact;
    }

    set linkContact(value: string) {
        this._linkContact = value;
    }
}
