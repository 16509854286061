@if (user$ | appLoadObs | async; as user) {
  @if (user.value) {
    <button title="{{user.value.convivialName}}" type="button"
            class="tw-btn-tertiary-info tw-flex tw-items-center tw-h-full tw-w-full tw-rounded-full tw-p-0 tw-space-x-2 tw-text-inherit tw-font-inherit tw-text-size-inherit"
            [disabled]="options.disableSlideOver" (click)="openSlideOver(user.value)">
      @if (options.showPhoto) {
        <div class="tw-relative tw-h-full tw-aspect-square">
          <img class="tw-rounded-full" [ngSrc]="user.value.linkPhotoThumbnail" fill>
        </div>
      }

      @if (options.showConvivialName || options.showInitiales) {
        <div class="tw-pr-1 tw-truncate" [ngClass]="{'tw-pl-1': !options.showPhoto}">
          @if (options.showConvivialName) {
            {{ user.value.convivialName }}
          }

          @if (options.showInitiales) {
            {{ user.value.initiales }}
          }
        </div>
      }
    </button>
  }
} @else {
  <app-loader class="tw-block tw-w-full"/>
}
