import DateFormat from '@shared/date/date.format';

export default class Meteo {
    private _cloudCover!: number;
    private _date!: string;
    private _description!: string;
    private _icon!: string;
    private _temperature!: number;
    private _windSpeed!: number;

    get cloudCover(): number {
        return this._cloudCover;
    }

    set cloudCover(value: number) {
        this._cloudCover = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get icon(): string {
        return this._icon;
    }

    set icon(value: string) {
        this._icon = value;
    }

    get temperature(): number {
        return this._temperature;
    }

    set temperature(value: number) {
        this._temperature = value;
    }

    get windSpeed(): number {
        return this._windSpeed;
    }

    set windSpeed(value: number) {
        this._windSpeed = value;
    }

    needReload(): boolean {
        if (!this.date) {
            return true;
        }

        return Math.abs(DateFormat.diff(this.date, undefined, {returnUnit: DateFormat.HOURS})) > 1;
    }
}
