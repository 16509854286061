import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgSoqTransformation, NgSoqValidation} from '@legacy/app/soqrate/soqrate';
import {UserFactory} from '@models/users/user/user.factory';
import {UserService} from '@models/users/user/user.service';
import {switchMap, take} from 'rxjs/operators';

export default function getEskModalChangePasswordCtrl(module: IModule): void {
    (angular => {
        'use strict';

        module.controller('EskModalChangePasswordCtrl', Controller);

        /**
         * Controller of modale to change password
         *
         * @param Ng2UserService
         * @param $uibModalInstance
         * @param $translate
         * @param SoqValidation
         * @param SoqTransformation
         * @param Ng2ToasterService
         * @param Ng2UserFactory
         */
        Controller.$inject = ['Ng2UserService', '$uibModalInstance', '$translate', 'SoqValidation', 'SoqTransformation', 'Ng2ToasterService', 'Ng2UserFactory'];
        function Controller(this: any,
                            ng2UserService: UserService,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $translate: angular.translate.ITranslateService,
                            soqValidation: NgSoqValidation,
                            soqTransformation: NgSoqTransformation,
                            ng2ToasterService: ToasterService,
                            ng2UserFactory: UserFactory) {
            const $ctrl = this;

            $ctrl.changePassword = changePassword;
            $ctrl.getRegex = soqTransformation.getRegex;
            $ctrl.newPassword = undefined;
            $ctrl.confirmationNewPassword = undefined;

            /**
             * Change passowrd to current user
             */
            function changePassword() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    return;
                }

                if (!soqValidation.password($ctrl.newPassword)) {
                    $ctrl.editForm.newPassword.$error = {parse: true};
                    $ctrl.editForm.newPassword.$invalid = true;

                    return;
                }

                $ctrl.sending = true;
                $ctrl.editForm.newPassword.$error = {};
                $ctrl.editForm.newPassword.$invalid = false;

                ng2UserService.last$.pipe(
                    switchMap(currentUser => ng2UserFactory.changePassword$(currentUser, $ctrl.newPassword)),
                    take(1),
                ).subscribe({
                    next: () => {
                        ng2ToasterService.success($translate.instant('authentication.modif_password.MESSAGE'));
                        $uibModalInstance.close();
                    },
                    complete: () => $ctrl.sending = false,
                });
            }
        }
    })(angularJS);
}
