import AModel from '@models/model.abstract';

export default class VenteContrat extends AModel {
    private _type!: string;

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}
