import {inject, Injectable} from '@angular/core';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {catchError} from 'rxjs/operators';
import {IModelValidationError} from '@models/model.interfaces';
import {
    EstimationOnsaleReferenceService
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.service';

@Injectable({providedIn: 'root'})
export class EstimationOnsaleReferencesService {
    private _estimationOnsaleReferenceService = inject(EstimationOnsaleReferenceService);

    saveFromCityscanOnsaleProperties$(estimation: Estimation, cityscanOnsaleProperties: CityscanOnsaleProperty[] = []): Observable<EstimationOnsaleReference[]> {
        if (cityscanOnsaleProperties.length <= 0) {
            return of(undefined! as EstimationOnsaleReference[]);
        }

        return combineLatest(cityscanOnsaleProperties.map((cityscanOnsaleProperty, index) =>
            this._estimationOnsaleReferenceService.saveFromCityscanOnsaleProperty$(estimation, cityscanOnsaleProperty).pipe(
                catchError((error: IModelValidationError) => {
                    error.index = index;

                    return throwError(() => error);
                }),
            )
        ));
    }
}
