export default `
<ng-switch on="$ctrl.etudePasserelle.statut" class="text-justify">
  <div ng-switch-when="configured">
    <div class="text-bold">
      Vous pouvez envoyer vos biens en vente et en location sur votre page Facebook depuis
      <app-noteo-icon-text-noty></app-noteo-icon-text-noty>.
    </div>

    <div class="text-muted margin-top-15">
      Avant de cliquer sur
      <app-ng2-img [src]="'assets/images/facebook/logout.png'" [width]="'auto'"></app-ng2-img>, ouvrez un nouvel onglet
      pour vous déconnecter de Facebook et ensuite cliquez pour déconnecter
      <app-noteo-icon-text-noty></app-noteo-icon-text-noty>.<br>Vous pourrez ensuite vous connectez avec un autre
      compte Facebook.
    </div>
  </div>

  <div ng-switch-default class="passerelle-connect">
    <div class="text-bold">
      Pour diffuser vos biens en vente et en location sur votre page Facebook, il vous est nécessaire de lier le compte
      gérant votre page avec <app-noteo-icon-text-noty></app-noteo-icon-text-noty>.
    </div>

    <hr>

    <ul class="list-unstyled margin-top-15">
      <li ng-repeat="i in [1, 2, 3, 4]" class="badge" ng-class="$ctrl.currentStepHelp !== i ? 'badge-inverse' : ''"
          ng-click="$ctrl.currentStepHelp = i">{{i}}</li>
    </ul>

    <ng-switch on="$ctrl.currentStepHelp" class="connect-step">
      <div ng-switch-default class="step">
        <div class="step-img">
          <app-ng2-img [src]="'assets/images/facebook/continue_with.png'" [width]="'auto'"></app-ng2-img>
        </div>

        <div class="step-txt">Connectez vous à Facebook avec le compte gérant la page en cliquant sur le bouton.</div>
      </div>

      <div ng-switch-when="2" class="step">
        <div class="step-img">
          <app-ng2-img [src]="'assets/images/facebook/wrong_account.png'" [width]="'auto'"></app-ng2-img>
        </div>

        <div class="step-txt">
          Le compte avec lequel vous vous êtes connecté n'est pas celui gérant la page Facebook.<br>Ouvrez un nouvel
          onglet pour vous déconnecter de Facebook et connectez vous avec le bon compte.
        </div>
      </div>

      <div ng-switch-when="3" class="step">
        <div class="step-img">
          <app-ng2-img [src]="'assets/images/facebook/pages_select.png'" [width]="'auto'"></app-ng2-img>
        </div>

        <div class="step-txt">
          Votre compte Facebook gère plusieurs pages.<br>Choisissez la page sur laquelle sera diffusée les biens en
          vente et en location.
        </div>
      </div>

      <div ng-switch-when="4" class="step">
        <div class="step-img">
          <app-ng2-img [src]="'assets/images/facebook/f_login.png'" [width]="'auto'"></app-ng2-img>
        </div>

        <div class="step-txt">
          Votre page Facebook n'est pas liée à <app-noteo-icon-text-noty></app-noteo-icon-text-noty>.<br>Connectez
          vous afin de pouvoir envoyer vos biens en vente et en location sur votre page Facebook.
        </div>
      </div>
    </ng-switch>
  </div>
</ng-switch>
`;
