<div class="tw-flex tw-w-full tw-gap-2">
  <div class="tw-relative tw-flex-1 tw-w-full" [ngClass]="options.aspectRatio">
    <img class="tw-object-cover tw-shadow-lg tw-transition-shadow hover:tw-shadow tw-cursor-ns-resize"
         [ngStyle]="{objectPosition: cropCoordinates.x.toString() + '% ' + cropCoordinates.y.toString() + '%'}"
         [ngSrc]="link" fill>

    <img #ghostImg src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
  </div>

  <div class="tw-flex tw-flex-col tw-justify-center tw-gap-2">
    <button type="button" class="tw-btn-secondary-default" (click)="up()"><fa-icon [icon]="['fal', 'up']"/></button>

    <button type="button" class="tw-btn-secondary-default" (click)="down()"><fa-icon [icon]="['fal', 'down']"/></button>
  </div>
</div>
