import {Injectable} from '@angular/core';
import {IDemandeurRechercheResultatApi} from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.interfaces';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

@Injectable({providedIn: 'root'})
export class DemandeurRechercheResultatFactory {
    create(demandeurRechercheResultatApi: IDemandeurRechercheResultatApi): DemandeurRechercheResultat {
        const demandeurRechercheResultat: DemandeurRechercheResultat = new DemandeurRechercheResultat(demandeurRechercheResultatApi.id.toString(), demandeurRechercheResultatApi.id);

        demandeurRechercheResultat.interest = demandeurRechercheResultatApi.interest;
        demandeurRechercheResultat.reason = demandeurRechercheResultatApi.reason;
        demandeurRechercheResultat.status = demandeurRechercheResultatApi.status;
        if (demandeurRechercheResultatApi._links) {
            if (demandeurRechercheResultatApi._links.demandeur) {
                demandeurRechercheResultat.linkDemandeur = demandeurRechercheResultatApi._links.demandeur.href;
            }

            if (demandeurRechercheResultatApi._links.location) {
                demandeurRechercheResultat.linkLocation = demandeurRechercheResultatApi._links.location.href;
            }

            if (demandeurRechercheResultatApi._links.recherche) {
                demandeurRechercheResultat.linkDemandeurRecherche = demandeurRechercheResultatApi._links.recherche.href;
            }

            if (demandeurRechercheResultatApi._links.vente) {
                demandeurRechercheResultat.linkVente = demandeurRechercheResultatApi._links.vente.href;
            }
        }

        return demandeurRechercheResultat;
    }
}
