import AModel from '@models/model.abstract';

export default class Departement extends AModel {
    private _code!: string;
    private _fullname!: string;
    private readonly _id: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get fullname(): string {
        return this._fullname;
    }

    set fullname(value: string) {
        this._fullname = value;
    }

    get id(): number {
        return this._id;
    }

    isDOMTOM(): boolean {
        if (['2A', '2B'].includes(this.code)) {
            return false;
        }

        return +this.code >= 97;
    }
}
