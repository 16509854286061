import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MediaService} from '@models/medias/media/media.service';
import Media from '@models/medias/media/media.model';
import Report from '@models/reports/report/report.model';

@Injectable({providedIn: 'root'})
export class ReportsService {
    private _mediaService = inject(MediaService);

    getMedia$(reports: Report[], titreMedia = Media.NO_TITLE): Observable<Media> {
        return this._mediaService.getFromReports$(reports, titreMedia);
    }

    read$(reports: Report[], titreMedia = Media.NO_TITLE): Observable<Window> {
        return this._mediaService.readFromReports$(reports, titreMedia);
    }
}
