import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-ventes-negociees-etudes', template: '<div appNgVentesNegocieesEtudes></div>'})
export class NgVentesNegocieesEtudesComponent {
}

@Directive({selector: '[appNgVentesNegocieesEtudes]'})
export class NgVentesNegocieesEtudesDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVentesNegocieesEtudeCtrl', elementRef, injector);
    }
}
