import {Component, Input, output} from '@angular/core';
import {Observable} from 'rxjs';
import Collection from '@models/collection/collection.model';

@Component({selector: 'app-load-more', templateUrl: 'load-more.component.html'})
export class AppLoadMoreComponent {
    readonly loadMore = output<void>();
    private _collection$!: Observable<Collection>;
    private _loading = false;
    private _disabled = false;

    get collection$(): Observable<Collection> {
        return this._collection$;
    }

    @Input()
    set collection$(value: Observable<Collection>) {
        this._collection$ = value;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    @Input()
    set loading(value: boolean) {
        this._loading = value;
    }

    executeLoadMore(): void {
        this.loadMore.emit();
    }
}
