import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewLoiAlurService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isAdministrationSyndicLoiAlurVisible!: boolean;
    private _isCommentsLoiAlurVisible!: boolean;
    private _isCoordonneesSyndicLoiAlurVisible!: boolean;
    private _isMontantQuotePartLoiAlurVisible!: boolean;
    private _isMontantTravauxLoiAlurVisible!: boolean;
    private _isNombreCoproprietairesLoiAlurVisible!: boolean;
    private _isNombreLotsLoiAlurVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isPlanSauvegardeLoiAlurVisible!: boolean;

    get isAdministrationSyndicLoiAlurVisible(): boolean {
        return this._isAdministrationSyndicLoiAlurVisible;
    }

    get isCommentsLoiAlurVisible(): boolean {
        return this._isCommentsLoiAlurVisible;
    }

    get isCoordonneesSyndicLoiAlurVisible(): boolean {
        return this._isCoordonneesSyndicLoiAlurVisible;
    }

    get isMontantQuotePartLoiAlurVisible(): boolean {
        return this._isMontantQuotePartLoiAlurVisible;
    }

    get isMontantTravauxLoiAlurVisible(): boolean {
        return this._isMontantTravauxLoiAlurVisible;
    }

    get isNombreCoproprietairesLoiAlurVisible(): boolean {
        return this._isNombreCoproprietairesLoiAlurVisible;
    }

    get isNombreLotsLoiAlurVisible(): boolean {
        return this._isNombreLotsLoiAlurVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isPlanSauvegardeLoiAlurVisible(): boolean {
        return this._isPlanSauvegardeLoiAlurVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isAdministrationSyndicLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.administrationSyndicLoiAlur);
        this._isCommentsLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsLoiAlur);
        this._isCoordonneesSyndicLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.coordonneesSyndicLoiAlur);
        this._isMontantQuotePartLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.montantQuotePartLoiAlur);
        this._isMontantTravauxLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.montantTravauxLoiAlur);
        this._isNombreCoproprietairesLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreCoproprietairesLoiAlur);
        this._isNombreLotsLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreLotsLoiAlur);
        this._isPlanSauvegardeLoiAlurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.planSauvegardeLoiAlur);

        this._isPartVisible = dossierBien.bien.hasLoiAlur
            && (this.isAdministrationSyndicLoiAlurVisible || this.isCommentsLoiAlurVisible
                || this.isCoordonneesSyndicLoiAlurVisible || this.isMontantQuotePartLoiAlurVisible
                || this.isMontantTravauxLoiAlurVisible || this.isNombreCoproprietairesLoiAlurVisible
                || this.isNombreLotsLoiAlurVisible || this.isPlanSauvegardeLoiAlurVisible);
    }
}
