import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';

export default function getSoqPopoverComponent(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-popover-template></soq-popover-template>
         */
        module.component('soqPopoverTemplate', {
            bindings: {
                cadastreEditable: '=',
                demandeur: '=',
                dossier: '=',
                display: '=',
                placement: '=',
                template: '=',
                trigger: '='
            },
            controller: Controller,
            template: '<a uib-popover-template="\'{{$ctrl.template}}\'" popover-placement="{{$ctrl.placement}}" ' +
                'popover-trigger="\'{{$ctrl.trigger}}\'" class="btn btn-o btn-null">' +
                '<span ng-if="$ctrl.display === \'default\'" class="badge badge-info">?</span>' +
                '<span ng-if="$ctrl.display === \'warning\'" class="text-warning"><fa-icon [icon]="\'exclamation-triangle\'"></fa-icon></span>' +
                '</a>',
        });

        /**
         * @example <soq-popover-text></soq-popover-text>
         */
        module.component('soqPopoverText', {
            bindings: {display: '=', placement: '=', text: '=', trigger: '='},
            controller: Controller,
            template: '<a uib-popover="{{$ctrl.text}}" popover-placement="{{$ctrl.placement}}" ' +
                'popover-trigger="\'{{$ctrl.trigger}}\'" class="btn btn-o btn-null">' +
                '<span ng-if="$ctrl.display === \'default\'" class="badge badge-info">?</span>' +
                '<span ng-if="$ctrl.display === \'warning\'" class="text-warning"><fa-icon [icon]="\'exclamation-triangle\'"></fa-icon></span>' +
                '</a>',
        });

        /**
         * Manage collection
         *
         * @constructor
         */
        Controller.$inject = ['Ng2EtudeService'];
        function Controller(this: any, ng2EtudeService: EtudeService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
            }
        }
    })(angularJS);
}
