import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CEtudeDossiersFactory} from '@models/etude-dossiers/collection/etude-dossiers.collection.factory';
import CEtudeDossiers from '@models/etude-dossiers/collection/etude-dossiers.collection.model';


@Injectable({providedIn: 'root'})
export class CEtudeDossiersService {
    private _cEtudeDossiersFactory = inject(CEtudeDossiersFactory);

    addNext$(cEtudeDossiers: CEtudeDossiers): Observable<CEtudeDossiers> {
        return this._cEtudeDossiersFactory.getNext$(cEtudeDossiers).pipe(
            tap(newCEtudeDossiers => cEtudeDossiers.updateNext(newCEtudeDossiers)),
            map(_ => cEtudeDossiers),
        );
    }
}
