import {Component, Input} from '@angular/core';

@Component({selector: 'app-noteo-icon-text-noty', templateUrl: 'noteo-icon-text.noty.component.html'})
export class AppNoteoIconTextNotyComponent {
    static readonly label = 'Noty';
    static readonly srcImage = '/assets/images/septeo/logo.svg';
    private _label = AppNoteoIconTextNotyComponent.label;
    private _srcImage = AppNoteoIconTextNotyComponent.srcImage;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
