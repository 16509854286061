<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">
    Diagnostic{{ (dossierBien.bien.diagnosticsRealises.length + (dossierBien.bien.performanceEnergetique.consommation > 0 ? 1 : 0) + (dossierBien.bien.performanceEnergetique.emissions > 0 ? 1 : 0)) > 0 ? 's' : '' }}
  </h4>

  <div class="tw-bg-gray-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    @if (isDiagnosticsRealisesVisible) {
      <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
        @for (diagnosticRealise of dossierBien.bien.diagnosticsRealises; track diagnosticRealise) {
          <li>
            {{ diagnosticRealise.type.label }}

            @if (diagnosticRealise.date) {
              <span class="tw-text-gray-500 tw-text-xs tw-font-normal">réalisé le</span>
              {{ diagnosticRealise.date | date:'longDate' }}
            }

            @if (diagnosticRealise.comments) {
              <div class="tw-text-gray-500 tw-font-normal">{{ diagnosticRealise.comments }}</div>
            }
          </li>
        }
      </ul>
    }

    @if (isPerformanceEnergetiqueCommentsVisible) {
      <div class="tw-text-sm tw-text-gray-700">{{ dossierBien.bien.performanceEnergetique.comments }}</div>
    }
  </div>
</div>
