import {inject, Injectable} from '@angular/core';
import {ICommuneVoieApi} from '@models/communes/commune/voies/voie/commune-voie.interfaces';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';
import {NgCommuneVoie} from '@legacy/app/managers/ressources';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';

@Injectable({providedIn: 'root'})
export class CommuneVoieFactory {
    private _quartierFactory = inject(QuartierFactory);

    create(communeVoieApi: ICommuneVoieApi): CommuneVoie {
        // En attente de l'API pour avoir un vrai uuid
        const communeVoie = new CommuneVoie(communeVoieApi.id.toString(), communeVoieApi.id);

        communeVoie.code = communeVoieApi.code;
        communeVoie.nom = communeVoieApi.nom;
        if (communeVoieApi._embedded?.quartier) {
            communeVoie.quartier = this._quartierFactory.create(communeVoieApi._embedded.quartier);
        }

        return communeVoie;
    }

    ngCreate(ngCommuneVoie: NgCommuneVoie): CommuneVoie {
        const communeVoie = new CommuneVoie(ngCommuneVoie.id.toString(), ngCommuneVoie.id);

        communeVoie.code = ngCommuneVoie.code;
        communeVoie.nom = ngCommuneVoie.nom;
        if (ngCommuneVoie.quartier) {
            communeVoie.quartier = this._quartierFactory.ngCreate(ngCommuneVoie.quartier);
        }

        return communeVoie;
    }
}
