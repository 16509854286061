import angularJS from '@shared/angularJS/global.ng';
import {IModule, IScope} from 'angular';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default function getSoqInputRadioYnn(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-input-radio-ynn></soq-input-radio-ynn>
         * @param options : {labels(object), name(string), typeDossier(string), values(object)}
         * @param options.labels : {choiceFalse(string), choiceNull(string), choiceTrue(string)}
         * @param options.values : {choiceFalse(any), choiceNull(any), choiceTrue(any)}
         */
        module.component('soqInputRadioYnn', {
            bindings: {
                title: '@',
                radioId: '@',
                options: '=?',
                ngModel: '=',
                isDisabled: '=?',
                onChange: '&',
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/soqrate/components/input-radio-ynn.html',
        });

        /**
         * Manage collection
         *
         * @param $scope
         * @constructor
         */
        Controller.$inject = ['$scope'];
        function Controller(this: any, $scope: IScope) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Watch on ngModel changes
             */
            $scope.$watch('$ctrl.ngModel', function () {
                switch ($ctrl.ngModel) {
                    case $ctrl.displayedOptions.values.choiceTrue :
                        $ctrl.value = '1';
                        break;
                    case $ctrl.displayedOptions.values.choiceFalse :
                        $ctrl.value = '2';
                        break;
                    default :
                        $ctrl.value = '3';
                        break;
                }
                $ctrl.onChange();
            });

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) {
                    $ctrl.options = {};
                }

                $ctrl.displayedOptions = angular.merge({
                    labels: {choiceTrue: 'Oui', choiceFalse: 'Non', choiceNull: 'Non renseigné'},
                    values: {choiceTrue: true, choiceFalse: false, choiceNull: null}
                }, $ctrl.options);

                if ($ctrl.displayedOptions.typeDossier === DossierTypesConst.DEMANDEUR) {
                    $ctrl.classColor = "radio-purple";
                } else {
                    $ctrl.classColor = "radio-primary";
                }
            }
        }
    })(angularJS);
}
