import {inject, Injectable} from '@angular/core';
import CActualites from '@models/actualites/collection/actualites.collection.model';
import {Observable} from 'rxjs';
import {CActualitesFactory} from '@models/actualites/collection/actualites.collection.factory';
import {map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CActualitesService {
    private _cActualitesFactory = inject(CActualitesFactory);

    addNext$(cActualites: CActualites): Observable<CActualites> {
        return this._cActualitesFactory.getNext$(cActualites).pipe(
            tap(newCActualites => cActualites.updateNext(newCActualites)),
            map(_ => cActualites),
        );
    }
}
