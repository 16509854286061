import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-estimations-references', template: '<div appNgEskEstimationsReferences></div>'})
export class NgEstimationsReferencesComponent {
}

@Directive({selector: '[appNgEskEstimationsReferences]'})
export class NgEstimationsReferencesDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationsReferences', elementRef, injector);
    }
}
