<div class="app-content tw-h-[100vh] tw-overflow-auto print:tw-overflow-initial">
  @if (isImpersonate$() | appLoadObs | async; as isImpersonate) {
    <div class="tw-w-full" [ngClass]="isImpersonate.value ? 'tw-bg-red-900' : 'tw-bg-septeo-blue-900'">
      <div class="tw-hidden md:tw-flex tw-justify-between tw-h-full tw-w-full">
        @if (leftMenus$ | async; as leftMenus) {
          <div class="tw-flex-1 tw-flex">
            <div class="tw-w-36"><ng-container [ngTemplateOutlet]="impersonationView"/></div>

            <div class="tw-flex">
              @for (menu of leftMenus; track menu) {
                <ng-container *ngTemplateOutlet="sideMenuView;context:{menu, subMenusPositionClass: 'tw-left-0'}"/>
              }
            </div>
          </div>
        }

        @if (rightMenus$ | async; as rightMenus) {
          <div class="tw-hidden xl:tw-flex">
            @for (menu of rightMenus; track menu) {
              <ng-container *ngTemplateOutlet="sideMenuView;context:{menu, subMenusPositionClass: 'tw-right-0'}"/>
            }
          </div>

          <div class="xl:tw-hidden">
            <ng-template *ngTemplateOutlet="concatMenuView;context:{mainMenu: profilMenu, subMenus: rightMenus}"/>
          </div>
        }
      </div>

      <div class="tw-flex md:tw-hidden tw-justify-between tw-h-full tw-w-full">
        <div [ngClass]="isImpersonate.value ? 'tw-w-32' : 'tw-w-16 tw-py-2'">
          <ng-container [ngTemplateOutlet]="impersonationView"/>
        </div>

        @if (completeMenus$ | async; as completeMenus) {
          <ng-template *ngTemplateOutlet="concatMenuView;context:{mainMenu: globalMenu, subMenus: completeMenus}"/>
        }
      </div>

      <ng-template #impersonationView>
        @if (isImpersonate.value) {
          <button type="button" (click)="stopImpersonate()"
                  class="tw-btn-secondary-danger tw-flex tw-flex-wrap tw-h-full tw-w-full">
            <fa-icon [icon]="'times'"/>

            <span class="tw-font-bold">STOPPER</span>

            <span class="tw-text-2xs">IMPERSONNALISATION</span>
          </button>
        } @else {
          <a routerLink="/app" class="tw-relative tw-block tw-h-full tw-w-full">
            <img class="tw-hidden md:tw-block" ngSrc="assets/images/noty/logo.svg" fill priority>

            <img class="tw-block md:tw-hidden" ngSrc="assets/images/septeo/logo.svg" fill priority>
          </a>
        }
      </ng-template>

      <ng-template #concatMenuView let-mainMenu="mainMenu" let-subMenus="subMenus">
        <div class="tw-group tw-relative">
          <ng-template *ngTemplateOutlet="menuView;context:{menu: mainMenu}"/>

          @if (subMenuShow) {
            <div class="tw-hidden group-hover:tw-block tw-absolute tw-top-full tw-w-max tw-bg-septeo-blue-800 tw-z-50 tw-shadow-xl tw-rounded-b-lg tw-border-x-2 tw-border-b-2 tw-border-septeo-blue-900 tw-divide-y-2 tw-divide-septeo-blue-900"
                 [ngClass]="'tw-right-0'">
              @for (subMenu of subMenus; track subMenu; let first = $first) {
                @if (subMenu.isTaskRunner) {
                  <!-- Pas d'affichage pour le moment, ça nécessiterait de développer une vue spécifique -->
                  <!-- Vu que c'est le premier objet de la liste en taille moyenne, ça prend la place du "first" -->
                } @else if (subMenu.subMenus) {
                  <div class="tw-text-sm tw-text-gray-500">
                    @if (!first) {
                      <div class="tw-h-1 tw-w-full tw-bg-septeo-blue-900"></div>
                    }

                    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-7">{{ subMenu.label }}</div>
                  </div>

                  @for (subSubMenu of subMenu.subMenus; track subSubMenu) {
                    <ng-container *ngTemplateOutlet="subMenuView;context:{subMenu: subSubMenu}"/>
                  }
                } @else {
                  <ng-container *ngTemplateOutlet="subMenuView;context:{subMenu}"/>
                }
              }
            </div>
          }

          <div class="tw-absolute tw-top-1 tw-right-1"><ng-template [ngTemplateOutlet]="badgeContactView"/></div>
        </div>
      </ng-template>

      <ng-template #sideMenuView let-menu="menu" let-subMenusPositionClass="subMenusPositionClass">
        <div class="tw-group tw-relative">
          <ng-template *ngTemplateOutlet="menuView;context:{menu}"/>

          @if (subMenuShow && !menu.isTaskRunner && menu.subMenus && menu.subMenus.length > 0) {
            <div class="tw-hidden group-hover:tw-block tw-absolute tw-top-full tw-w-max tw-bg-septeo-blue-800 tw-z-[500] tw-shadow-xl tw-rounded-b-lg tw-border-x-2 tw-border-b-2 tw-border-septeo-blue-900 tw-divide-y-2 tw-divide-septeo-blue-900"
                 [ngClass]="subMenusPositionClass">
              @for (subMenu of menu.subMenus; track subMenu) {
                <ng-container *ngTemplateOutlet="subMenuView;context:{subMenu}"/>
              }
            </div>
          }
        </div>
      </ng-template>

      <ng-template #menuView let-menu="menu">
        <div (mouseenter)="subMenuShow = true">
          @if (menu.isTaskRunner) {
            <div class="tw-relative tw-flex tw-flex-col tw-justify-between tw-items-center tw-gap-3 tw-h-full hover:tw-bg-septeo-blue-700 group-hover:tw-bg-septeo-blue-700 tw-border-b-2 tw-border-septeo-blue-900 tw-py-2 tw-px-3 tw-text-base tw-text-center">
              <ng-template *ngTemplateOutlet="menuContentView;context:{menu}"/>

              <div class="tw-absolute tw-inset-0"><esk-task-runner class="tw-h-full tw-w-full"/></div>
            </div>
          } @else {
            <!-- !tw-border-noty-green obligatoire car les class ne sont pas prises dans le bon ordre sinon -->
            <a [routerLink]="menu.link" class="tw-peer tw-relative tw-flex-col tw-justify-between tw-items-center tw-gap-3 tw-h-full hover:tw-bg-septeo-blue-700 group-hover:tw-bg-septeo-blue-700 tw-border-b-2 tw-border-septeo-blue-900 tw-py-2 tw-px-3 tw-text-base tw-text-center"
               routerLinkActive="tw-bg-septeo-blue-700 !tw-border-noty-green"
               [ngClass]="isDesktop ? 'tw-flex' : 'tw-hidden'">
              <ng-template *ngTemplateOutlet="menuContentView;context:{menu}"/>
            </a>

            <!-- !tw-border-noty-green obligatoire car les class ne sont pas prises dans le bon ordre sinon -->
            <div class="tw-relative tw-flex-col tw-justify-between tw-items-center tw-gap-3 tw-h-full hover:tw-bg-septeo-blue-700 group-hover:tw-bg-septeo-blue-700 tw-border-b-2 tw-border-septeo-blue-900 tw-py-2 tw-px-3 tw-text-base tw-text-center peer-[.tw-bg-septeo-blue-700]:tw-bg-septeo-blue-700 peer-[.\!tw-border-noty-green]:!tw-border-noty-green"
                 [ngClass]="isDesktop ? 'tw-hidden' : 'tw-flex'">
              <ng-template *ngTemplateOutlet="menuContentView;context:{menu}"/>
            </div>
          }
        </div>
      </ng-template>

      <ng-template #menuContentView let-menu="menu">
        <ng-template *ngTemplateOutlet="iconOrImgView;context:{menu}"/>

        <span class="tw-text-slate-100 tw-text-2xs tw-font-semibold tw-uppercase">{{ menu.label }}</span>

        @if (menu.hasBadgeContact) {
          <div class="tw-absolute tw-top-1 tw-right-4"><ng-template [ngTemplateOutlet]="badgeContactView"/></div>
        }
      </ng-template>

      <ng-template #subMenuView let-subMenu="subMenu">
        @if (subMenu.link) {
          <a [routerLink]="subMenu.link" class="tw-flex tw-items-center tw-gap-1 tw-h-10 hover:tw-bg-septeo-blue-700 tw-py-2 tw-pr-4 tw-pl-3"
             routerLinkActive="tw-bg-septeo-blue-700" [routerLinkActiveOptions]="{exact: true}">
            <ng-template *ngTemplateOutlet="subMenuContentView;context:{subMenu}"/>
          </a>
        } @else if (subMenu.externalLink) {
          <a class="tw-flex tw-items-center tw-gap-1 tw-h-10 hover:tw-bg-septeo-blue-700 tw-py-2 tw-pr-4 tw-pl-3"
             [href]="subMenu.externalLink" target="_blank">
            <ng-template *ngTemplateOutlet="subMenuContentView;context:{subMenu}"/>
          </a>
        } @else if (subMenu.phoneLink) {
          <a class="tw-flex tw-items-center tw-gap-1 tw-h-10 hover:tw-bg-septeo-blue-700 tw-py-2 tw-pr-4 tw-pl-3"
             [href]="subMenu.phoneLink">
            <ng-template *ngTemplateOutlet="subMenuContentView;context:{subMenu}"/>
          </a>
        } @else if (subMenu.execCode) {
          <button class="tw-flex tw-items-center tw-gap-1 tw-h-10 tw-w-full hover:tw-bg-septeo-blue-700 tw-py-2 tw-pr-4 tw-pl-3 tw-text-left"
                  type="button" (click)="execCode(subMenu.execCode)">
            <ng-template *ngTemplateOutlet="subMenuContentView;context:{subMenu}"/>
          </button>
        }
      </ng-template>

      <ng-template #subMenuContentView let-subMenu="subMenu">
        <ng-template *ngTemplateOutlet="iconOrImgView;context:{menu: subMenu}"/>

        <span class="tw-text-slate-100 tw-text-sm">{{ subMenu.label }}</span>

        @if (subMenu.hasBadgeContact) {
          <div class="tw-pb-1 tw-pl-1"><ng-template [ngTemplateOutlet]="badgeContactView"/></div>
        }
      </ng-template>

      <ng-template #iconOrImgView let-menu="menu">
        @if (menu.icon) {
          <div class="tw-relative tw-h-5 tw-w-5"><fa-icon [icon]="menu.icon" class="tw-text-noty-green"/></div>
        }

        @if (menu.imgPath) {
          <div class="tw-relative tw-h-5 tw-w-5"><img [ngSrc]="menu.imgPath" fill priority></div>
        }
      </ng-template>

      <ng-template #badgeContactView>
        @if (nbInvalidContacts$ | async; as nbInvalidContacts) {
          <div class="tw-h-4 tw-w-6 tw-flex tw-items-center tw-justify-center tw-bg-red-700 tw-rounded-full tw-py-1 tw-px-1 tw-text-2xs tw-font-semibold tw-text-slate-100"
               [title]="(nbInvalidContacts ? '1 contact a' : (nbInvalidContacts + 'contacts ont')) + 'une adresse email qui n\'existe pas.'">
            {{ nbInvalidContacts > 9 ? '+9' : nbInvalidContacts }}
          </div>
        }
      </ng-template>
    </div>

    <!-- @todo Ne pas faire le calcul mais utiliser flex-col ? -->
    <div class="main-content tw-h-[calc(100%_-_62px)]">
      <!-- ! pour contrer "container" -->
      <div class="container tw-h-full print:!tw-p-0"><router-outlet/></div>
    </div>
  }
</div>

<app-modal/>

<app-slide-over/>
