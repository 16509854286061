import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {CommunesModule} from '@features/communes/communes.module';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {
    AppCityscanOnsalePropertiesListComponent
} from '@features/cityscan/onsaleProperties/list/cityscan-onsale-properties-list.component';
import {
    CityscanOnsalePropertyDropdownComponent
} from '@features/cityscan/onsaleProperties/onsaleProperty/dropdown/cityscan-onsale-property.dropdown.component';
import {
    DCCityscanOnsalePropertySlideOverComponent
} from '@features/cityscan/onsaleProperties/onsaleProperty/slide-over/cityscan-onsale-property.slide-over.component';
import {
    CityscanOnsalePropertyPhotoComponent
} from '@features/cityscan/onsaleProperties/onsaleProperty/photo/cityscan-onsale-property.photo.component';

@NgModule({
    declarations: [
        AppCityscanOnsalePropertiesListComponent,
        CityscanOnsalePropertyDropdownComponent,
        CityscanOnsalePropertyPhotoComponent,
        DCCityscanOnsalePropertySlideOverComponent,
    ],
    exports: [AppCityscanOnsalePropertiesListComponent],
    imports: [CommonModule, CommunesModule, DictionariesModule, SharedModule],
})
export class CityscanModule {
}
