import angularJS from '@shared/angularJS/global.ng';
import {map} from 'lodash';
import {IModule, IQService} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgEstimation} from '@legacy/app/managers/ressources';

export default function getEstimationModalArchiveCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EstimationModalArchiveCtrl', Controller);

        /**
         * Controller for archive modal
         *
         * @param estimation
         * @param $uibModalInstance
         * @param $translate
         * @param SoqSweetAlert
         * @param $q
         * @constructor
         */
        Controller.$inject = ['estimation', '$uibModalInstance', '$translate', 'SoqSweetAlert', '$q'];
        function Controller(
            this: any,
            estimation: NgEstimation,
            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
            $translate: angular.translate.ITranslateService,
            soqSweetAlert: NgSoqSweetAlert,
            $q: IQService,
        ) {
            const $ctrl = this;

            $ctrl.saveEstimation = saveEstimation;
            $ctrl.archiveEstimation = archiveEstimation;
            $ctrl.sending = false;
            $ctrl.estimation = estimation;
            $ctrl.estimation.getArchiveInfos().subscribe((archiveInfos: unknown) => $ctrl.archive = archiveInfos);

            /**
             * Save estimation information
             */
            function saveEstimation() {
                $ctrl.sending = true;

                saveArchiveInfosEstimation().then(function () {
                    $uibModalInstance.dismiss();
                }).finally(function () {
                    $ctrl.sending = false;
                });
            }

            /**
             * Archive estimation
             */
            function archiveEstimation() {
                $ctrl.sending = true;
                $ctrl.editForm.$submitted = true;

                saveArchiveInfosEstimation().then(function () {
                    soqSweetAlert.dangerConfirmation(
                        $translate.instant('estimation.archive.confirm.TITLE'),
                        $translate.instant('estimation.archive.confirm.MESSAGE', {reference: $ctrl.estimation.bien.reference}),
                        {confirmButtonText: $translate.instant('estimation.archive.confirm.CONFIRMATION')}
                    ).then(function () {
                        return $ctrl.estimation.archive($ctrl.archive).then(() => $uibModalInstance.close());
                    }).catch(function () {
                        $uibModalInstance.dismiss();
                    });
                }, function (rejection: {data: {errors: string[]}; status: number}) {
                    if (rejection.status === 422 && angular.isObject(rejection.data) && angular.isArray(rejection.data.errors) && rejection.data.errors.length > 0) {
                        soqSweetAlert.warningMessage($translate.instant('estimation.save.TITLE', {nbMissingData: rejection.data.errors.length}), $translate.instant('estimation.save.MESSAGE', {
                            nbMissingData: rejection.data.errors.length,
                            missingData: map(rejection.data.errors, 'message').join('<br>- ')
                        }));
                    }
                }).finally(() => $ctrl.sending = false);
            }

            /**
             * Save estimation
             *
             * @returns {Promise}
             */
            function saveArchiveInfosEstimation() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));

                    return $q.reject();
                }

                return $ctrl.estimation.saveArchiveInfos($ctrl.archive);
            }
        }
    })(angularJS);
}
