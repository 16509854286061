import angularJS from '@shared/angularJS/global.ng';
import {IAttributes, ICompileService, IDirectiveFactory, IModule, Injectable, IScope} from 'angular';
import {NgElement} from '@legacy/app/soqrate/soqrate';

export default function getSoqMultiple(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example soq-multiple=""
         */
        module.directive('soqMultiple', directive as Injectable<IDirectiveFactory>);

        /**
         * Add multiple attribute
         *
         * @param $compile
         */
        directive.$inject = ['$compile'];
        function directive($compile: ICompileService) {
            return {restrict: 'A', priority: 1000, terminal: true, link: link};

            /**
             * Link for directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes) {
                if ($scope.$eval($attrs.soqMultiple)) {
                    $element.attr('multiple', true as unknown as string);
                } else {
                    $element.removeAttr('multiple');
                }

                $element.removeAttr('soq-multiple');
                $compile($element)($scope);
            }
        }
    })(angularJS);
}
