import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewArchiveService {
    private _isPartVisible!: boolean;

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        let isVenteArchiveVisible = false;

        if (dossierBien.isVente()) {
            const vente = dossierBien as Vente;

            isVenteArchiveVisible = !!vente.archiveDateVente || !!vente.archiveType;
        }

        this._isPartVisible = !!dossierBien.archiveComments || !!dossierBien.bien.dateArchive || isVenteArchiveVisible;
    }
}
