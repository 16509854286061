import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-vente-mandats', template: '<div appNgVenteMandats></div>'})
export class NgVenteMandatsComponent {
}

@Directive({selector: '[appNgVenteMandats]'})
export class NgVenteMandatsDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVenteMandatsCtrl', elementRef, injector);
    }
}
