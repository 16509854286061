import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgBienCadastre, NgBienDossier, NgEstimation, NgVente} from '@legacy/app/managers/ressources';
import Commune from '@models/communes/commune/commune.model';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgBienCadastreManager, NgCommuneManager, NgQuartierManager} from '@legacy/app/managers/managers';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';
import {CommuneVoieFactory} from '@models/communes/commune/voies/voie/commune-voie.factory';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';
import Quartier from '@models/quartiers/quartier/quartier.model';
import {BehaviorSubject, from, Observable, of, ReplaySubject, Subject} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCBienEditCadastreComponent
} from '@features/bien-edit/dynamic-components/cadastre/bien.edit-cadastre.component';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {ContactsGroupService} from '@models/contacts-group/contacts-group.service';
import {DossierBienFieldsConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Estimation from '@models/estimations/estimation/estimation.model';
import {BienCadastreService} from '@models/bien/cadastres/cadastre/bien-cadastre.service';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import Vente from '@models/ventes/vente/vente.model';

@Component({selector: 'app-bien-edit-localisation', templateUrl: 'bien.edit-localisation.component.html'})
export class AppBienEditLocalisationComponent implements OnDestroy, OnInit {
    readonly AppBienEditLocalisationCadastresName = 'AppBienEditLocalisationCadastres';
    private _bienCadastreService = inject(BienCadastreService);
    private _collectionSortableService = inject(CollectionSortableService);
    private _communeFactory = inject(CommuneFactory);
    private _communeVoieFactory = inject(CommuneVoieFactory);
    private _contactsGroupService = inject(ContactsGroupService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _quartierFactory = inject(QuartierFactory);
    private _cadastreEditable = false;
    private _cadastresReadySource = new BehaviorSubject(true);
    private _cadastresReady$ = this._cadastresReadySource.asObservable();
    private _commune!: Commune;
    private _communeVoie!: CommuneVoie;
    private _dossierBien!: ADossierBien;
    private _localisationEditable = false;
    private _ngBienDossier!: NgBienDossier;
    private _ngBienDossierSource = new ReplaySubject<NgBienDossier>(1);
    private readonly _onDestroy$ = new Subject<void>();
    private _quartier!: Quartier;

    // Supprimer les injections des anciens manager
    private get ngBienCadastreManager(): NgBienCadastreManager {
        return this._ngInjectorService.getService('BienCadastreManager');
    }

    // Supprimer les injections des anciens manager
    private get ngCommuneManager(): NgCommuneManager {
        return this._ngInjectorService.getService('CommuneManager');
    }

    // Supprimer les injections des anciens manager
    private get ngEskUtils(): NgEskUtils {
        return this._ngInjectorService.getService('EskUtils');
    }

    // Supprimer les injections des anciens manager
    private get ngQuartierManager(): NgQuartierManager {
        return this._ngInjectorService.getService('QuartierManager');
    }

    get cadastreEditable(): boolean {
        return this._cadastreEditable;
    }

    get cadastresReady$(): Observable<boolean> {
        return this._cadastresReady$;
    }

    get commune(): Commune {
        return this._commune;
    }

    get communeVoie(): CommuneVoie {
        return this._communeVoie;
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get DOSSIER_BIEN_BROUILLON(): string {
        return ADossierBien.abstractBienStatuts.BROUILLON;
    }

    get DOSSIER_BIEN_TYPES(): typeof DossierBienTypesConst {
        return DossierBienTypesConst;
    }

    get ESTIMATION_TYPE_EVALUATION(): string {
        return Estimation.types.EVALUATION;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienFieldsConst;
    }

    get ngBienDossier(): NgBienDossier {
        return this._ngBienDossier;
    }

    @Input()
    set ngBienDossier(value: NgBienDossier) {
        this._ngBienDossier = value;
        if (this._ngBienDossier.bien.option.commune) {
            this._commune = this._communeFactory.ngCreate(this._ngBienDossier.bien.option.commune);
        } else {
            this._commune = undefined!;
        }

        if (this._ngBienDossier.bien.option.voie) {
            this._communeVoie = this._communeVoieFactory.ngCreate(this._ngBienDossier.bien.option.voie);
        } else {
            this._communeVoie = undefined!;
        }

        if (this._ngBienDossier.bien.option.quartier) {
            this._quartier = this._quartierFactory.ngCreate(this._ngBienDossier.bien.option.quartier);
        } else {
            this._quartier = undefined!;
        }

        this._dossierBien = this._dossierBienFactory.ngCreate(this._ngBienDossier);
        this._ngBienDossierSource.next(this._ngBienDossier);
    }

    get ngEstimation(): NgEstimation {
        return this._ngBienDossier as NgEstimation;
    }

    get localisationEditable(): boolean {
        return this._localisationEditable;
    }

    get quartier(): Quartier {
        return this._quartier;
    }

    ngOnInit(): void {
        this.cadastresReady$.pipe(
            filter(isReady => isReady),
            switchMap(() => this._collectionSortableService.isInit$(this.AppBienEditLocalisationCadastresName)),
            filter(isInit => isInit),
            switchMap(_ => this._collectionSortableService.getMovedItem$(this.AppBienEditLocalisationCadastresName)),
            map(({item, idx}) => ({ngBienCadastre: item, idx} as { ngBienCadastre: NgBienCadastre, idx: number })),
            takeUntil(this._onDestroy$),
        ).subscribe(({ngBienCadastre, idx}) => ngBienCadastre.updateRank(idx + 1));
        this._ngBienDossierSource.asObservable().pipe(
            switchMap(_ => this.currentEtude$),
            switchMap(currentEtude => {
                if (this.ngBienDossier._esk.typeDossier !== DossierBienTypesConst.VENTE) {
                    return of({cadastreEditable: true, localisationEditable: true});
                }

                if (this.ngBienDossier.statut === Vente.statuts.BROUILLON) {
                    return of({cadastreEditable: true, localisationEditable: true});
                }

                if (!currentEtude.hasNetwork()) {
                    return of({cadastreEditable: true, localisationEditable: true});
                }

                return from(this.ngBienCadastreManager.checkDoubleMandat(this.ngBienDossier.bien.cadastres, this.ngBienDossier.bien.id)).pipe(
                    map(ngDoubleMandat => ({
                        cadastreEditable: !ngDoubleMandat,
                        localisationEditable: !(this.ngBienDossier as NgVente).mandatPremiereDate,
                    })),
                );
            }),
        ).subscribe(({cadastreEditable, localisationEditable}) => {
            this._cadastreEditable = cadastreEditable;
            this._localisationEditable = localisationEditable;
        });
    }

    ngOnDestroy(): void {
        this._collectionSortableService.delete(this.AppBienEditLocalisationCadastresName);
        this._onDestroy$.next();
    }

    addNewCadastre(): void {
        const virginCadastre = this.ngBienCadastreManager.create({
            prefixe: '000',
            commune: this.ngBienDossier.bien.option.commune,
        }, this.ngBienDossier.bien.id);

        this.editCadastre(virginCadastre);
    }

    communeSelected(commune: Commune): void {
        const prevCommune = this._ngBienDossier.bien.option.commune;

        this._commune = commune;
        this.communeQuartiersSelected(undefined!);
        if (this._commune) {
            this._ngBienDossier.bien.option.commune = this.ngCommuneManager.createFromNg2(this._commune);
            this._ngBienDossier.bien.cadastres
                .filter(bienCadastre => !bienCadastre.commune)
                .forEach(bienCadastre => bienCadastre.commune = this._ngBienDossier.bien.option.commune);
        } else {
            this._ngBienDossier.bien.option.commune = undefined!;
        }
        this.ngEskUtils.setCodePostalFromWatchingCommune(this.ngBienDossier.bien.option, this.ngBienDossier.bien.option.commune, prevCommune);
    }

    communeQuartiersSelected(quartier: Quartier): void {
        this._quartier = quartier;
        if (quartier) {
            this._ngBienDossier.bien.option.quartier = this.ngQuartierManager.createFromNg2(this.ngBienDossier.bien.option.commune.id, quartier);
        } else {
            this._ngBienDossier.bien.option.quartier = undefined!;
        }
    }

    communeVoieSelected(communeVoie: CommuneVoie): void {
        const prevVoie = this._ngBienDossier.bien.option.voie;

        if (communeVoie) {
            this._ngBienDossier.bien.option.voie = this.ngCommuneManager.createVoieFromNg2(communeVoie);
        } else {
            this._ngBienDossier.bien.option.voie = undefined!;
        }

        if (prevVoie?.id === this._ngBienDossier.bien.option.voie?.id) {
            return;
        }

        if (!this._ngBienDossier.bien.option.voie) {
            return;
        }

        this._ngBienDossier.bien.option.nomVoie = this._ngBienDossier.bien.option.voie.nom;
        this.communeQuartiersSelected(communeVoie.quartier);
        this._ngBienDossier.bien.updateNetworkAddress();
    }

    editCadastre(ngBienCadastreToSave: NgBienCadastre): void {
        this._modalService.open$(DCBienEditCadastreComponent, {
            dossierBien: this._dossierBien,
            ngBien: this.ngBienDossier.bien,
            ngBienCadastre: ngBienCadastreToSave.copy()
        }).pipe(
            map(ngBienCadastreSaved => ngBienCadastreSaved as NgBienCadastre),
            tap(() => this._cadastresReadySource.next(false)),
            tap(ngBienCadastreSaved => {
                if (!ngBienCadastreSaved) {
                    return;
                }

                const idx = this.ngBienDossier.bien.cadastres.findIndex(ngBienCadastreFinding => ngBienCadastreFinding.uuid === ngBienCadastreSaved.uuid);

                if (idx >= 0) {
                    this.ngBienDossier.bien.cadastres[idx] = ngBienCadastreSaved;
                } else {
                    this.ngBienDossier.bien.cadastres.push(ngBienCadastreSaved);
                }
            }),
            take(1),
        ).subscribe({
            next: _ => this.ngBienDossier.bien.operateSurfaceCadastraleFromRefs(),
            complete: () => this._cadastresReadySource.next(true),
        });
    }

    filterBienCadastre(ngBienCadastre: NgBienCadastre): boolean {
        return !ngBienCadastre.isEmpty();
    }

    getReferentLocalisation(): void {
        this._contactsGroupService.getOneByLink$(this._ngBienDossier._links.contactsGroup.href).pipe(
            map(contactsGroup => contactsGroup.getReferent()),
            switchMap(contactReferent => this._communeFactory.getByLink$(contactReferent.linkCommune).pipe(
                tap(commune => this.communeSelected(commune)),
                map(_ => contactReferent),
            )),
            tap(contactReferent => this._ngBienDossier.bien.option.codePostal = contactReferent.codePostal),
            tap(contactReferent => this._ngBienDossier.bien.option.nomVoie = contactReferent.adresse),
            take(1),
        ).subscribe(() => this._ngBienDossier.bien.updateNetworkAddress());
    }

    removeCadastre(ngBienCadastre: NgBienCadastre): void {
        from(this.ngBienDossier.bien.removeItem('cadastres', ngBienCadastre))
            .pipe(take(1))
            .subscribe(_ => this.ngBienDossier.bien.operateSurfaceCadastraleFromRefs());
    }

    saveCadastre(ngBienCadastre: NgBienCadastre): void {
        this._bienCadastreService.ngSave$(this._dossierBien, this.ngBienDossier.bien, ngBienCadastre).pipe(take(1)).subscribe();
    }
}
