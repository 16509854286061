import {inject, Injectable} from '@angular/core';
import VenteArchiveInfos from '@models/ventes/vente/archive-infos/vente-archive-infos.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {Observable, of} from 'rxjs';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import Etude from '@models/etudes/etude/etude.model';
import {DemandeurService} from '@models/demandeurs/demandeur/demandeur.service';

@Injectable({providedIn: 'root'})
export class VenteArchiveInfosService {
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurService = inject(DemandeurService);
    private _etudeFactory = inject(EtudeFactory);

    getDemandeur$(venteArchiveInfos: VenteArchiveInfos): Observable<Demandeur> {
        if (!venteArchiveInfos.linkDemandeur) {
            if (venteArchiveInfos.acquereur) {
                return this._demandeurService.createFromVenteArchiveInfos$(venteArchiveInfos);
            } else {
                return of(undefined as unknown as Demandeur);
            }
        }

        return this._demandeurFactory.getByLink$(venteArchiveInfos.linkDemandeur);
    }

    getEtude$(venteArchiveInfos: VenteArchiveInfos): Observable<Etude> {
        if (!venteArchiveInfos.linkEtudeAcquereur) {
            return of(undefined as unknown as Etude);
        }

        return this._etudeFactory.getByLink$(venteArchiveInfos.linkEtudeAcquereur);
    }
}
