import DateFormat from '@shared/date/date.format';
import AModel from '@models/model.abstract';

export default class LocationPasserelle extends AModel{
    private _afficherCommune = true;
    private _afficherDpe = true;
    private _communeId!: number;
    private _createdAt!: string;
    private _dateDiffusion!: string;
    private _dateRetrait!: string;
    private _descriptif!: string;
    private _diffused = false;
    private _linkPasserelle!: string;
    private _linkLocation!: string;
    private _locationId: number;
    private _natureExternalType!: string;
    private _natureExternalTypeId!: number;
    private _passerelleId: number;
    private _updatedAt!: string;

    constructor(uuid: string, passerelleId: number, locationId: number) {
        super(uuid);

        this._passerelleId = passerelleId;
        this._locationId = locationId;
        this._linkPasserelle = '/etude/passerelles/' + this.passerelleId.toString();
        this._linkLocation = '/locations/' + this.locationId.toString();
    }

    get afficherCommune(): boolean {
        return this._afficherCommune;
    }

    set afficherCommune(value: boolean) {
        this._afficherCommune = value;
    }

    get afficherDpe(): boolean {
        return this._afficherDpe;
    }

    set afficherDpe(value: boolean) {
        this._afficherDpe = value;
    }

    get communeId(): number {
        return this._communeId;
    }

    set communeId(value: number) {
        this._communeId = value;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    set createdAt(value: string) {
        this._createdAt = value;
    }

    get dateDiffusion(): string {
        return this._dateDiffusion;
    }

    set dateDiffusion(value: string) {
        this._dateDiffusion = value;
    }

    get dateRetrait(): string {
        return this._dateRetrait;
    }

    set dateRetrait(value: string) {
        this._dateRetrait = value;
    }

    get descriptif(): string {
        return this._descriptif;
    }

    set descriptif(value: string) {
        this._descriptif = value;
    }

    get diffused(): boolean {
        return this._diffused;
    }

    set diffused(value: boolean) {
        this._diffused = value;
    }

    get linkPasserelle(): string {
        return this._linkPasserelle;
    }

    set linkPasserelle(value: string) {
        this._linkPasserelle = value;
    }

    get linkLocation(): string {
        return this._linkLocation;
    }

    set linkLocation(value: string) {
        this._linkLocation = value;
    }

    get locationId(): number {
        return this._locationId;
    }

    get natureExternalType(): string {
        return this._natureExternalType;
    }

    set natureExternalType(value: string) {
        this._natureExternalType = value;
    }

    get natureExternalTypeId(): number {
        return this._natureExternalTypeId;
    }

    set natureExternalTypeId(value: number) {
        this._natureExternalTypeId = value;
    }

    get passerelleId(): number {
        return this._passerelleId;
    }

    get updatedAt(): string {
        return this._updatedAt;
    }

    set updatedAt(value: string) {
        this._updatedAt = value;
    }

    setDiffuser(): void {
        this.dateDiffusion = DateFormat.datetimeFromDate();
        this.diffused = true;
    }

    setRetirer(): void {
        this.dateDiffusion = null!;
        this.dateRetrait = DateFormat.datetimeFromDate();
        this.diffused = false;
    }
}
