import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({
    selector: 'app-ng-eskimmo-configuration-passerelles',
    template: '<div appNgEskimmoConfigurationPasserelles></div>',
})
export class NgEskimmoConfigurationPasserellesComponent {
}

@Directive({selector: '[appNgEskimmoConfigurationPasserelles]'})
export class NgEskimmoConfigurationPasserellesDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskConfigurationPasserellesCtrl', elementRef, injector);
    }
}
