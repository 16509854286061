<div class="ventes-negociees">
  <div class="container-fluid container-fluid-title"><h1>Comparatif des ventes</h1></div>

  <div class="container-fluid container-fullw tw-bg-white">
    <div class="ventes-negociees-container">
      <div class="text-right hidden-print">
        <button type="button" class="btn btn-primary btn-o" (click)="print()">
          <fa-icon [icon]="'print'"/><span>Imprimer</span>
        </button>
      </div>

      <router-outlet/>
    </div>
  </div>
</div>
