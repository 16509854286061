import {Component, Directive, ElementRef, inject, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import Location from '@models/locations/location/location.model';
import {NgLocation} from '@legacy/app/managers/ressources';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgLocationManager} from '@legacy/app/managers/managers';

@Component({
    selector: 'esk-location-label-price',
    template: '@if (ngLocation) {<div appNgEskLocationLabelPrice [location]="ngLocation"></div>}',
})
export class NgLocationLabelPriceComponent {
    private _ngInjectorService = inject(NgInjectorService);
    private _ngLocation!: NgLocation;

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService<NgLocationManager>('LocationManager');
    }

    get ngLocation(): NgLocation {
        return this._ngLocation;
    }

    @Input()
    set location(value: Location) {
        this.ngLocationManager.createFromNg2(value).then(ngLocation => this._ngLocation = ngLocation);
    }
}

@Directive({selector: '[appNgEskLocationLabelPrice]'})
export class NgLocationLabelPriceDirective extends UpgradeComponent {
    @Input() location!: NgLocation;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskLocationLabelRent', elementRef, injector);
    }
}
