import angularJS from '@shared/angularJS/global.ng';
import {camelCase, filter, find} from 'lodash';
import {IModule, IPromise, IQService, IWindowService} from 'angular';
import {
    NgBienCadastreManager, NgDictionariesManager, NgEmailManager, NgMediaManager, NgTemplateManager,
    NgVenteContratManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgTemplate, NgVenteContractor, NgVenteContrat} from '@legacy/app/managers/ressources';
import {NgVenteContratsCollection} from '@legacy/app/managers/collections';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {ToasterService} from '@shared/toaster/toaster.service';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import {map, take, tap} from 'rxjs/operators';
import Procedure from '@models/procedures/procedure/procedure.model';
import {CProceduresFactory} from '@models/procedures/collection/procedures.collection.factory';
import {combineLatest, firstValueFrom, from, Observable, of, switchMap} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import {EmailService} from '@models/emails/email/email.service';
import {DocumentsService} from '@models/documents/documents.service';
import Document from '@models/documents/document/document.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {SortConst} from '@shared/constants';
import DateFormat from '@shared/date/date.format';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Vente from '@models/ventes/vente/vente.model';
import {DocumentService} from '@models/documents/document/document.service';
import {IDocumentApi} from '@models/documents/document/document.interfaces';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {ModalService} from '@shared/modal/modal.service';
import {VenteContratFactory} from '@models/ventes/vente/contrats/contrat/vente-contrat.factory';

export default function getVenteMandatsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskVenteMandatsCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/controllers/item/mandats.html',
        });

        /**
         * Controller for edit mandat
         *
         * @param VenteManager
         * @param DictionariesManager
         * @param VenteContratManager
         * @param $translate
         * @param SoqSweetAlert
         * @param SoqModalesManager
         * @param BienCadastreManager
         * @param $q
         * @param Ng2EtudeService
         * @param EmailManager
         * @param Ng2TinymceService
         * @param Ng2RouterStateForNgService
         * @param Ng2UserService
         * @param Ng2ProcedureService
         * @param Ng2DocumentFactory
         * @param Ng2VenteFactory
         * @param Ng2ToasterService
         * @param Ng2CProceduresFactory
         * @param Ng2EmailService
         * @param Ng2DocumentsService
         * @param MediaManager
         * @param Ng2DictionaryFactory
         * @param $window
         * @param Ng2DocumentService
         * @param Ng2TemplateFactory
         * @param TemplateManager
         * @param Ng2ModalService
         * @param Ng2VenteContratFactory
         */
        Controller.$inject = ['VenteManager', 'DictionariesManager', 'VenteContratManager', '$translate', 'SoqSweetAlert', 'SoqModalesManager', 'BienCadastreManager', '$q', 'Ng2EtudeService', 'EmailManager', 'Ng2TinymceService', 'Ng2RouterStateForNgService', 'Ng2UserService', 'Ng2ProcedureService', 'Ng2DocumentFactory', 'Ng2VenteFactory', 'Ng2ToasterService', 'Ng2CProceduresFactory', 'Ng2EmailService', 'Ng2DocumentsService', 'MediaManager', 'Ng2DictionaryFactory', '$window', 'Ng2DocumentService', 'Ng2TemplateFactory', 'TemplateManager', 'Ng2ModalService', 'Ng2VenteContratFactory'];
        function Controller(this: any,
                            venteManager: NgVenteManager,
                            dictionariesManager: NgDictionariesManager,
                            venteContratManager: NgVenteContratManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            soqModalesManager: NgSoqModalesManager,
                            bienCadastreManager: NgBienCadastreManager,
                            $q: IQService,
                            ng2EtudeService: EtudeService,
                            emailManager: NgEmailManager,
                            ng2TinymceService: TinymceService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2UserService: UserService,
                            ng2ProcedureService: ProcedureService,
                            ng2DocumentFactory: DocumentFactory,
                            ng2VenteFactory: VenteFactory,
                            ng2ToasterService: ToasterService,
                            ng2CProceduresFactory: CProceduresFactory,
                            ng2EmailService: EmailService,
                            ng2DocumentsService: DocumentsService,
                            mediaManager: NgMediaManager,
                            ng2DictionaryFactory: DictionaryFactory,
                            $window: IWindowService,
                            ng2DocumentService: DocumentService,
                            ng2TemplateFactory: TemplateFactory,
                            templateManager: NgTemplateManager,
                            ng2ModalService: ModalService,
                            ng2VenteContratFactory: VenteContratFactory) {
            const $ctrl = this;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.onChangeMandatDate = onChangeMandatDate;
            $ctrl.onChangeMandatDateFin = onChangeMandatDateFin;
            $ctrl.saveVente = saveVente;
            $ctrl.generateContrats = generateContrats;
            $ctrl.loadContrats = loadContrats;
            $ctrl.printContrats = printContrats;
            $ctrl.selectContrat = selectContrat;
            $ctrl.saveCurrentContrat = saveCurrentContrat;
            $ctrl.recreateCurrentContratDocuments = recreateCurrentContratDocuments;
            $ctrl.removeCurrentContrat = removeCurrentContrat;
            $ctrl.sendCurrentContrat = sendCurrentContrat;
            $ctrl.signCurrentContrat = signCurrentContrat;
            $ctrl.editProcedure = editProcedure;
            $ctrl.couldSignCurrentContrat = couldSignCurrentContrat;
            $ctrl.printCurrentContrat = printCurrentContrat;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.VIAGER = Vente.types.VIAGER;
            $ctrl.vente = venteManager.current;
            $ctrl.options = {
                mandatDate: {maxDate: DateFormat.toDate()},
                mandatDateFin: {minDate: $ctrl.vente.mandatDate},
                dateSignature: {maxDate: DateFormat.toDate()},
                loader: {updating: true}
            };
            $ctrl.editForm = {typesMandatVente: {}, mandatDate: {}, mandatDateFin: {}};
            $ctrl.typesMandatVente = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.VENTE_MANDAT_TYPES));
            $ctrl.vente.mandatType = $ctrl.typesMandatVente.find(item => item.uuid === $ctrl.vente.mandatType.uuid);
            ng2EtudeService.last$.subscribe(currentEtude => {
                $ctrl.hasNetwork = currentEtude.hasNetwork();
                if ($ctrl.hasNetwork) {
                    bienCadastreManager.checkDoubleMandat($ctrl.vente.bien.cadastres, $ctrl.vente.bien.id)
                        .then(doubleMandat => $ctrl.doubleMandat = doubleMandat);
                }
            });
            ng2UserService.last$.subscribe(currentUser => $ctrl.currentUser = currentUser);
            getLatestContrats($window.history.state.contratUuid).finally(() => $ctrl.options.loader.updating = false);

            /**
             * On change mandat date
             *
             * @returns {*}
             */
            function onChangeMandatDate() {
                if ($ctrl.vente.mandatDate) {
                    let mandatDateFin: Date;

                    if ($ctrl.vente.mandatType.code === Vente.mandatTypes.EXCLUSIF) {
                        mandatDateFin = DateFormat.toDate(DateFormat.addDaysToDateString(DateFormat.addMonthsToDateString($ctrl.vente.mandatDate.toString(), 3), -1));
                    } else {
                        mandatDateFin = DateFormat.toDate(DateFormat.addDaysToDateString(DateFormat.addYearsToDateString($ctrl.vente.mandatDate.toString(), 1), -1));
                    }

                    const toSave = DateFormat.diff(mandatDateFin?.toString(), $ctrl.vente.mandatDateFin?.toString()) === 0;

                    $ctrl.vente.mandatDateFin = mandatDateFin;
                    $ctrl.options.mandatDateFin.minDate = $ctrl.vente.mandatDate;

                    if (toSave) {
                        if (angular.isDefined($ctrl.vente.mandatPremiereDate)) {
                            if (angular.isDefined($ctrl.vente.mandatDate)) {
                                $ctrl.editForm.mandatDate.error = $ctrl.options.mandatDate.maxDate < $ctrl.vente.mandatDate ? {maxdate: true} : undefined;
                            } else {
                                $ctrl.editForm.mandatDate.error = {required: true};
                            }
                        } else {
                            $ctrl.editForm.mandatDate.error = undefined;
                        }

                        return $ctrl.saveVente();
                    }
                } else {
                    $ctrl.vente.mandatDateFin = null;
                }
            }

            /**
             * On change mandat date fin
             *
             * @returns {*}
             */
            function onChangeMandatDateFin() {
                if ($ctrl.vente.mandatDateFin !== null) {
                    if (angular.isObject($ctrl.vente)) {
                        if (angular.isDefined($ctrl.vente.mandatPremiereDate)) {
                            if (angular.isDefined($ctrl.vente.mandatDateFin)) {
                                $ctrl.editForm.mandatDateFin.error = $ctrl.options.mandatDateFin.minDate > $ctrl.vente.mandatDateFin ? {mindate: true} : undefined;
                            } else {
                                $ctrl.editForm.mandatDateFin.error = {required: true};
                            }
                        } else {
                            $ctrl.editForm.mandatDateFin.error = undefined;
                        }

                        return $ctrl.saveVente();
                    }
                }
            }

            /**
             * Save Vente
             *
             * @returns {*}
             */
            function saveVente() {
                var promise;

                $ctrl.editForm.typesMandatVente = {error: angular.isObject($ctrl.vente.mandatType) ? undefined : {required: true}};
                if (angular.isObject($ctrl.editForm.typesMandatVente.error) || angular.isObject($ctrl.editForm.mandatDate.error) || angular.isObject($ctrl.editForm.mandatDateFin.error)) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return $q.reject();
                }

                if (angular.isObject($ctrl.doubleMandat) && $ctrl.doubleMandat.type === 'doubleMandat' && !angular.isObject($ctrl.vente.mandatPremiereDate) && angular.isObject($ctrl.vente.mandatDate)) {
                    promise = soqSweetAlert.warningConfirmation($translate.instant("vente.mandat.double.TITLE"), $translate.instant("vente.mandat.double.MESSAGE", {
                        reference: $ctrl.doubleMandat.bienReference,
                        raison_sociale: $ctrl.doubleMandat.etudeRaisonSociale,
                        responsable: $ctrl.doubleMandat.responsable,
                    }));
                } else {
                    promise = $q.resolve();
                }

                return promise.then(function () {
                    var hasMandatPremiereDate = angular.isObject($ctrl.vente.mandatPremiereDate);

                    $ctrl.saving = true;

                    return $ctrl.vente.save().then(function () {
                        if (!$ctrl.hasNetwork || $ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork === null || hasMandatPremiereDate || angular.isObject($ctrl.doubleMandat)) {
                            return;
                        }

                        soqSweetAlert.successMessage($translate.instant('vente.mandat.success.TITLE'), $translate.instant('vente.mandat.success.MESSAGE', {nbDays: ($ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork < 0 ? 0 : $ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork)}));
                    }, function () {
                        if (angular.isObject($ctrl.vente._esk.errorsSaving) && $ctrl.vente._esk.errorsSaving.nbErrors > 0) {
                            soqSweetAlert.warningMessage($translate.instant('vente.save.TITLE', $ctrl.vente._esk.errorsSaving), $translate.instant('vente.save.MESSAGE', $ctrl.vente._esk.errorsSaving));
                            if ($ctrl.vente.statut === Vente.statuts.BROUILLON) {
                                $ctrl.vente.mandatDate = null;
                                $ctrl.vente.mandatDateFin = null;
                            }
                        }
                    }).finally(() => $ctrl.saving = false);
                });
            }

            /**
             * Generate contrats
             */
            function generateContrats() {
                soqModalesManager.open('VenteModalContrats').then(function (contratsOptions: any) {
                    const promises = [] as IPromise<unknown>[];
                    let contratToSelect: NgVenteContrat;

                    $ctrl.options.loader.updating = true;
                    if (contratsOptions.contratMandant && contratsOptions.mandants.length > 0) {
                        angular.forEach(contratsOptions.mandants, function (mandant) {
                            promises.push(venteContratManager.create({
                                type: contratsOptions.type,
                                contactId: mandant.id,
                            }, $ctrl.vente.uuid).save().then(venteContrat => venteContrat as NgVenteContrat).then(createDocuments));
                        });
                    } else {
                        promises.push(venteContratManager.create({
                            type: contratsOptions.type,
                            contactsGroupId: $ctrl.vente.bien.contactsGroup.id,
                        }, $ctrl.vente.uuid).save().then(venteContrat => venteContrat as NgVenteContrat).then(createDocuments));
                    }

                    return $q.all(promises).then(
                        contrats => contrats as NgVenteContrat[]
                    ).then(contrats => {
                        if (contrats.length === 1) contratToSelect = contrats[0];
                    }).finally(function () {
                        return getLatestContrats().then(function () {
                            const params: Record<string, unknown> = {};

                            if (!angular.isObject(contratToSelect)) {
                                $ctrl.currentContractor = undefined;
                                $ctrl.currentContrat = undefined;

                                return;
                            }

                            if (angular.isNumber(contratToSelect.contactsGroupId)) {
                                params['contacts-group'] = {id: contratToSelect.contactsGroupId};
                            } else {
                                params.contact = {id: contratToSelect.contactId};
                            }

                            $ctrl.selectContrat(find($ctrl.contractors.collection, params), contratToSelect);
                        });
                    });

                    /**
                     * Create documents
                     *
                     * @param contrat
                     * @return {Promise}
                     */
                    function createDocuments(contrat: NgVenteContrat) {
                        return attachDocumentsToContrat(contrat, contratsOptions[contratsOptions.type].template.id, angular.isObject(contratsOptions[contratsOptions.type].courrier) ? contratsOptions[contratsOptions.type].courrier.id : undefined);
                    }
                }).finally(() => $ctrl.options.loader.updating = false);
            }

            /**
             * Load contrats
             *
             * @param contractor
             */
            function loadContrats(contractor: NgVenteContractor) {
                var params = {};
                var promise;

                if (angular.isString(contractor.moreContrats)) {
                    displayContrats();

                    return;
                }

                contractor.moreContrats = 'in_progress';
                if (contractor.contrats.length < 2) {
                    // @ts-ignore
                    params[camelCase(contractor.type)] = contractor[contractor.type].id;
                    promise = venteContratManager.getAll($ctrl.vente.uuid, params).then(function (contrats: NgVenteContratsCollection) {
                        contractor.contrats = contrats.collection;
                    });
                } else {
                    promise = $q.resolve();
                }

                promise.finally(displayContrats);

                /**
                 * Display contrats
                 */
                function displayContrats() {
                    contractor.displayedMoreContrats = contractor.displayedMoreContrats !== true;
                    contractor.moreContrats = contractor.displayedMoreContrats ? 'display' : '';
                }
            }

            /**
             * Print contrats
             */
            function printContrats() {
                const types: string[] = [];
                let documents$: Observable<Document[]>[] = [];

                $ctrl.options.loader.printing = true;
                angular.forEach($ctrl.contractors.collection, function (contractor) {
                    if (!angular.isArray(contractor.contrats) || contractor.contrats.length <= 0) return;

                    if (!types.includes(contractor.contrats[0].type)) types.push(contractor.contrats[0].type);

                    documents$ = documents$.concat(getContrats$(contractor.contrats[0]));
                });
                combineLatest(documents$).pipe(
                    map(documentss => documentss.reduce((previous, current) => previous.concat(current))),
                    switchMap(documents => ng2DocumentsService.read$(documents, $translate.instant('vente.contrats.list.print.LABEL', {
                        type: types.length === 2 ? 'both' : types[0],
                        nbDocuments: $ctrl.contractors.totalItems,
                        reference: $ctrl.vente.bien.reference,
                    }))),
                    take(1),
                ).subscribe({complete: () => $ctrl.options.loader.printing = false});
            }

            /**
             * Select current contrat
             *
             * @param contractor
             * @param contrat
             */
            function selectContrat(contractor: NgVenteContractor, contrat: NgVenteContrat) {
                const promises = [] as IPromise<unknown>[];

                if (angular.isDefined(contrat) && $ctrl.currentContrat === contrat) return;

                $ctrl.currentContractor = contractor;
                $ctrl.currentContrat = angular.isObject(contrat) ? find($ctrl.currentContractor.contrats, {contratUuid: contrat.contratUuid}) : $ctrl.currentContractor.contrats[0];
                if (!angular.isObject($ctrl.currentContrat) || !$ctrl.currentContrat.contrat.documentId) return;

                $ctrl.currentContrat._esk.loading = true;
                $ctrl.currentContrat._esk.documents = {};
                $ctrl.currentContrat._esk.tinymceOptions = {};
                promises.push(firstValueFrom(ng2DocumentFactory.get$($ctrl.currentContrat.contrat.documentId).pipe(
                    tap(document => $ctrl.currentContrat._esk.documents.main = document),
                    switchMap(document => ng2DocumentService.getStylesheetLinks$(document).pipe(tap(content_css => {
                        const options = ng2TinymceService.completeOptions;

                        options.content_css = options.content_css.concat(content_css);
                        $ctrl.currentContrat._esk.tinymceOptions.main = options;
                    }))),
                )));

                if ($ctrl.currentUser.hasRoleSignatureElectronique()) {
                    promises.push(firstValueFrom(ng2ProcedureService.getLastCreated$({document: $ctrl.currentContrat.contrat.documentId}).pipe(
                        tap(procedure => $ctrl.currentContrat._esk.documents.procedure = procedure),
                        take(1),
                    )));
                }

                if ($ctrl.currentContrat.contrat.documentCourrierIntroductifId > 0) {
                    promises.push(firstValueFrom(ng2DocumentFactory.get$($ctrl.currentContrat.contrat.documentCourrierIntroductifId).pipe(
                        tap(document => $ctrl.currentContrat._esk.documents.courrier = document),
                        switchMap(document => ng2DocumentService.getStylesheetLinks$(document).pipe(tap(content_css => {
                            const options = ng2TinymceService.completeOptions;

                            options.content_css = options.content_css.concat(content_css);
                            $ctrl.currentContrat._esk.tinymceOptions.courrier = options;
                        }))),
                    )));
                }

                $q.all(promises).finally(function () {
                    $ctrl.editDoc = 'MAIN';
                    $ctrl.currentContrat._esk.loading = false;
                });
            }

            /**
             * Save current contrat
             */
            function saveCurrentContrat() {
                if (!$ctrl.currentContrat._esk.documents?.main) {
                    return;
                }

                const promises = [] as IPromise<unknown>[];

                $ctrl.currentContrat._esk.inProgress = 'SAVE';
                promises.push(firstValueFrom(ng2DocumentService.save$($ctrl.currentContrat._esk.documents.main)));
                if ($ctrl.currentContrat.contrat.documentCourrierIntroductifId > 0) {
                    promises.push(firstValueFrom(ng2DocumentService.save$($ctrl.currentContrat._esk.documents.courrier)));
                }

                promises.push($ctrl.currentContrat.save());

                return $q.all(promises).finally(() => $ctrl.currentContrat._esk.inProgress = undefined);
            }

            /**
             * Recreate documents of current contrat
             */
            function recreateCurrentContratDocuments() {
                $ctrl.currentContrat._esk.inProgress = 'LOAD';
                attachDocumentsToContrat($ctrl.currentContrat, $ctrl.currentContrat._esk.documents.main.templateId, $ctrl.currentContrat._esk.documents.courrier?.templateId).finally(() => {
                    $ctrl.currentContrat._esk.inProgress = undefined!;

                    const currentContrat = angular.copy($ctrl.currentContrat);

                    $ctrl.currentContrat = undefined!;
                    $ctrl.selectContrat($ctrl.currentContractor, currentContrat);
                });
            }

            /**
             * Remove current contrat
             */
            function removeCurrentContrat() {
                $ctrl.currentContrat._esk.inProgress = 'REMOVE';
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: $translate.instant('vente.contrats.item.remove.CONFIRMATION'),
                    question: $translate.instant('vente.contrats.item.remove.MESSAGE', {type: $ctrl.currentContrat.type}),
                    title: $translate.instant('vente.contrats.item.remove.TITLE', {type: $ctrl.currentContrat.type}),
                    status: ModalService.status.DANGER,
                }).pipe(
                    switchMap(response => {
                        if (!response) {
                            return of(null);
                        }

                        return ng2VenteContratFactory.delete$(ng2VenteFactory.ngCreate($ctrl.vente), ng2VenteContratFactory.ngCreate($ctrl.currentContrat)).pipe(
                            switchMap(() => {
                                $ctrl.options.loader.updating = true;
                                $ctrl.currentContractor = undefined;
                                $ctrl.currentContrat = undefined;

                                return from(getLatestContrats());
                            }),
                        );
                    }),
                    take(1),
                ).subscribe({complete: () => $ctrl.options.loader.updating = false});
            }

            /**
             * Send current contrat
             */
            function sendCurrentContrat() {
                $ctrl.saveCurrentContrat().then(() => {
                    $ctrl.currentContrat._esk.inProgress = 'PRINT';

                    return firstValueFrom(getContrats$($ctrl.currentContrat).pipe(
                        switchMap(documents => ng2DocumentsService.getMedia$(documents, $ctrl.currentContrat._esk.documents.main.titre)),
                        map(media => mediaManager.createFromNg2(media)),
                    )).then(ngMedia => {
                        $ctrl.currentContrat._esk.inProgress = 'SEND';
                        if ($ctrl.currentContractor && $ctrl.currentContractor[$ctrl.currentContractor.type]) {
                            const labelTypeDocument = $ctrl.currentContrat.type === 'avenant' ? "l'avenant au mandat" : "le mandat";

                            return soqModalesManager.open('EskModalEmail', {
                                resolve: {
                                    email: emailManager.create({
                                        recipients: $ctrl.currentContractor.recipients,
                                        attachments: [{media: ngMedia}],
                                        dossiers: [{
                                            typeId: $ctrl.vente.id,
                                            type: DossierBienTypesConst.VENTE,
                                            dossier: $ctrl.vente
                                        }],
                                        subject: ngMedia.title,
                                        message: $ctrl.currentContractor[$ctrl.currentContractor.type].formuleIntroduction + ",<br><br>" +
                                            "Nous vous prions de trouver ci-joint, " + labelTypeDocument + " de vente concernant votre bien.<br><br>" +
                                            "Nous vous remercions de bien vouloir le compléter aux endroits indiqués et nous le retourner signé et daté avec vos initiales à chaque bas de page, afin que nous puissions effectuer les visites nécessaires pour parvenir à la vente de celui-ci.<br><br>" +
                                            "Nous vous rappelons que le notaire négociateur de votre bien sera le notaire rédacteur de l'acte de vente.<br><br>" +
                                            "Nous restons à votre entière disposition pour tous renseignements complémentaires.<br>" +
                                            "Nous vous prions d'agréer, " + $ctrl.currentContractor[$ctrl.currentContractor.type].formulePolitesse + ", l'expression de nos salutations les plus distinguées.<br><br>" +
                                            emailManager.getCurrentUserSignature()
                                    }),
                                    currentDossier: $ctrl.vente,
                                },
                            });
                        }

                        return undefined;
                    }).finally(() => $ctrl.currentContrat._esk.inProgress = undefined);
                });
            }

            /**
             * Could sign current contrat
             */
            function couldSignCurrentContrat() {
                return $ctrl.currentContrat.contrat.document?.template?.signatureElectronique
                    && (!$ctrl.currentContrat._esk.documents.procedure || $ctrl.currentContrat._esk.documents.procedure.isClosed());
            }

            /**
             * Sign current contrat
             */
            function signCurrentContrat() {
                if ($ctrl.currentUser.hasRoleSignatureElectronique()) {
                    $ctrl.saveCurrentContrat().then(function () {
                        $ctrl.currentContrat._esk.documents.procedure = ng2ProcedureService.createFromDossierDocument(
                            ng2VenteFactory.ngCreate($ctrl.vente),
                            ng2DocumentFactory.create($ctrl.currentContrat.contrat.document),
                            $ctrl.currentContrat.contrat.contactId,
                        );
                        $ctrl.editProcedure();
                    });
                } else {
                    ng2EmailService.informationRequest$('SIGNATURE').pipe(take(1)).subscribe();
                }
            }

            /**
             * Edit procedure
             */
            function editProcedure() {
                const document = ng2DocumentFactory.create($ctrl.currentContrat.contrat.document);
                const paramsForProviderSoqrate = {
                    procedureUuid: $ctrl.currentContrat._esk.documents.procedure.uuid,
                    routeBack: '/app/ventes/' + $ctrl.vente.id.toString() + '/documents',
                };

                if ($ctrl.currentContrat._esk.documents.procedure.isPresentielEditable()) {
                    ng2RouterStateForNgService.navigateByUrl('/app/procedures/' + $ctrl.currentContrat._esk.documents.procedure.uuid, paramsForProviderSoqrate);
                } else {
                    soqModalesManager.open('EskModalProcedure', {
                        resolve: {document, procedure: $ctrl.currentContrat._esk.documents.procedure},
                    }).then(
                        procedure => procedure as Procedure
                    ).then(procedureFromModale => {
                        if (!procedureFromModale) {
                            return;
                        }

                        ng2ProcedureService.getLastCreated$({document: $ctrl.currentContrat.contrat.documentId}).pipe(
                            tap(procedure => $ctrl.currentContrat._esk.documents.procedure = procedure),
                            take(1),
                        ).subscribe(procedure => {
                            if (procedure?.isPresentielEditable()) {
                                paramsForProviderSoqrate.procedureUuid = procedure.uuid;

                                return ng2RouterStateForNgService.navigateByUrl('/app/procedures/' + procedure.uuid, paramsForProviderSoqrate);
                            }

                            if (procedureFromModale.uuid !== procedure?.uuid) {
                                return;
                            }

                            if (procedure.isDraft()) {
                                ng2ToasterService.info('La procédure de signature ' + procedure.providerLabel + ' est créée',
                                    'Pensez à la lancer pour les signataires');
                            } else if (procedure.isLaunched()) {
                                ng2ToasterService.info('La procédure de signature ' + procedure.providerLabel + ' est lancée',
                                    'Retrouvez toutes les procédures dans "Boîte à outils" > "Procédures"');
                            } else if (procedure.isCanceled()) {
                                ng2ToasterService.info('La procédure de signature ' + procedure.providerLabel + ' est annulée');
                            }

                            return;
                        });
                    });
                }
            }

            /**
             * Print current contrat
             */
            function printCurrentContrat() {
                $ctrl.saveCurrentContrat()
                    .then(() => $ctrl.currentContrat._esk.inProgress = 'PRINT')
                    .then(() => firstValueFrom(getContrats$($ctrl.currentContrat).pipe(
                        switchMap(documents => ng2DocumentsService.read$(documents, $ctrl.currentContrat._esk.documents.main.titre)),
                    )))
                    .finally(() => $ctrl.currentContrat._esk.inProgress = undefined);
            }

            /**
             * Get latest contrats
             *
             * @returns {Promise}
             */
            function getLatestContrats(wantedVenteContratUuid = undefined) {
                return venteContratManager.getAll($ctrl.vente.uuid, {latests: true}).then((venteContrats: NgVenteContratsCollection) => {
                    const documentIds = venteContrats.collection.map(venteContrat => venteContrat.contrat.documentId);

                    if (!$ctrl.currentUser.hasRoleSignatureElectronique()) {
                        return $q.resolve(venteContrats);
                    }

                    return firstValueFrom(ng2CProceduresFactory.get$({
                        document: documentIds,
                        tris: {createdAt: SortConst.ASCENDING}
                    })).then(cProcedures => {
                        cProcedures.results.forEach(procedure => {
                            const venteContrat = venteContrats.collection.find(venteContrat => venteContrat.contrat.documentId === procedure.documentId);

                            if (!venteContrat) {
                                return;
                            }

                            venteContrat._esk.documents = {procedure};
                        })
                    }).then(_ => venteContrats);
                }).then(function (venteContrats: NgVenteContratsCollection) {
                    let wantedVenteContrat: NgVenteContrat = undefined!;
                    let selectedContractor: any;

                    if (wantedVenteContratUuid) {
                        wantedVenteContrat = filter(venteContrats.collection, {contratUuid: wantedVenteContratUuid})?.[0];
                    }

                    $ctrl.contractors = {collection: []};
                    if (angular.isObject($ctrl.vente.bien.contactsGroup) && $ctrl.vente.bien.contactsGroup.id > 0) {
                        $ctrl.contractors.collection.push({
                            type: 'contacts-group',
                            'contacts-group': $ctrl.vente.bien.contactsGroup,
                            convivialName: $ctrl.vente.bien.contactsGroup.nom,
                            recipients: $ctrl.vente.bien.contactsGroup.getRecipients(),
                            contrats: filter(venteContrats.collection, {contrat: {contactsGroupId: $ctrl.vente.bien.contactsGroup.id}}),
                        });
                        if (wantedVenteContrat?.contrat?.contactsGroupId && !wantedVenteContrat?.contrat?.contactId) {
                            selectedContractor = $ctrl.contractors.collection[$ctrl.contractors.collection.length - 1];
                        }

                        angular.forEach($ctrl.vente.bien.contactsGroup.members, function (member) {
                            $ctrl.contractors.collection.push({
                                type: 'contact',
                                contact: member.contact,
                                convivialName: member.contact[member.contact.type].convivialName,
                                recipients: member.contact.getRecipients(),
                                contrats: filter(venteContrats.collection, {contrat: {contactId: member.contact.id}})
                            });
                            if (!wantedVenteContrat?.contrat?.contactsGroupId && wantedVenteContrat?.contrat?.contactId && wantedVenteContrat?.contrat?.contactId === member.contact.id) {
                                selectedContractor = $ctrl.contractors.collection[$ctrl.contractors.collection.length - 1];
                            }
                        });
                    }

                    $ctrl.contractors.totalItems = $ctrl.contractors.collection.length;
                    $ctrl.contractors.hasContrats = venteContrats.totalItems > 0;
                    if (selectedContractor) {
                        $ctrl.selectContrat(selectedContractor);
                    } else if (wantedVenteContratUuid) {
                        // @todo $ctrl.loadContrats(<AVEC LE BON CONTRACTOR>) suivi de $ctrl.selectContrat(<AVEC LE BON CONTRACTOR>, <AVEC LE BON CONTRAT>)
                    }
                });
            }

            /**
             * Get ids from venteContrat
             *
             * @param venteContrat
             * @returns []
             */
            function getContrats$(venteContrat: NgVenteContrat) {
                const documents$ = [ng2DocumentFactory.get$(venteContrat.contrat.documentId)];

                if (venteContrat.contrat.documentCourrierIntroductifId) {
                    documents$.unshift(ng2DocumentFactory.get$(venteContrat.contrat.documentCourrierIntroductifId));
                }

                return combineLatest(documents$);
            }

            /**
             * Attach new documents for venteContrat
             *
             * @param venteContrat
             * @param contratTemplateId
             * @param courrierTemplateId
             * @return {Promise}
             */
            function attachDocumentsToContrat(venteContrat: NgVenteContrat, contratTemplateId: number, courrierTemplateId: number) {
                const documentsPromises = [] as IPromise<unknown>[];

                documentsPromises.push(firstValueFrom(ng2DocumentFactory.save$(ng2DocumentFactory.create({
                    templateId: contratTemplateId,
                    variables: {venteUuid: $ctrl.vente.uuid, contratUuid: venteContrat.contratUuid},
                    titre: $translate.instant('vente.contrats.item.CONTRAT_TITLE', {
                        type: venteContrat.type,
                        reference: $ctrl.vente.bien.reference,
                    }),
                } as IDocumentApi)).pipe(
                    tap(document => venteContrat.contrat.document = document),
                    tap(document => venteContrat.contrat.documentId = document.id),
                    switchMap(document => ng2TemplateFactory.getByLink$(document.linkTemplate)),
                    switchMap(template => from(templateManager.createFromNg2(template))),
                    tap(ngTemplate => (venteContrat.contrat.document as unknown as {template: NgTemplate}).template = ngTemplate),
                )));
                if (courrierTemplateId > 0) {
                    documentsPromises.push(firstValueFrom(ng2DocumentFactory.save$(ng2DocumentFactory.create({
                        templateId: courrierTemplateId,
                        variables: {venteUuid: $ctrl.vente.uuid, contratUuid: venteContrat.contratUuid},
                        titre: $translate.instant('vente.contrats.item.COURRIER_TITLE', {
                            type: venteContrat.type,
                            reference: $ctrl.vente.bien.reference,
                        }),
                    } as IDocumentApi)).pipe(
                        tap(document => venteContrat.contrat.documentCourrierIntroductif = document),
                        tap(document => venteContrat.contrat.documentCourrierIntroductifId = document.id),
                        switchMap(document => ng2TemplateFactory.getByLink$(document.linkTemplate)),
                        switchMap(template => from(templateManager.createFromNg2(template))),
                        tap(ngTemplate => (venteContrat.contrat.documentCourrierIntroductif as unknown as {template: NgTemplate}).template = ngTemplate),
                    )));
                }

                return $q.all(documentsPromises).then(() => venteContrat.save());
            }
        }
    })(angularJS);
}
