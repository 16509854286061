import Media from '@models/medias/media/media.model';

export default class DossierExportMedia {
    static readonly MEDIA_ALLOWED_MIME_TYPES = [
        Media.ACCEPTED_MIME_TYPES.applicationPdf,
        Media.ACCEPTED_MIME_TYPES.imageJpeg,
        Media.ACCEPTED_MIME_TYPES.imageJpg,
        Media.ACCEPTED_MIME_TYPES.imagePng,
    ];
    static readonly MEDIA_ALLOWED_CONVIVIAL_MIME_TYPES = DossierExportMedia.MEDIA_ALLOWED_MIME_TYPES
        .map(dossierFileMediaAllowedMimeType => dossierFileMediaAllowedMimeType.convivial)
        .filter((mimeType, idx, mediaAllowedConvivialMimeTypes) => mediaAllowedConvivialMimeTypes.indexOf(mimeType) === idx)
        .join(' ou ');
    static readonly INOT_MAX_SIZE = 25000000;
    private _linkDossierMedia!: string;

    get linkDossierMedia(): string {
        return this._linkDossierMedia;
    }

    set linkDossierMedia(value: string) {
        this._linkDossierMedia = value;
    }
}
