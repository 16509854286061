import {inject, Injectable} from '@angular/core';
import {EtudePasserelleService} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.service';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {Observable, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import Vente from '@models/ventes/vente/vente.model';
import {
    CEtudePasserellesFactory
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.factory';

@Injectable({providedIn: 'root'})
export class EtudePasserellesService {
    private _cEtudePasserellesFactory = inject(CEtudePasserellesFactory);
    private _etudePasserelleService = inject(EtudePasserelleService);

    getFromVente$(vente: Vente): Observable<EtudePasserelle[]> {
        return this._cEtudePasserellesFactory.get$().pipe(
            map(cEtudePasserelles => vente.interne ? cEtudePasserelles.results : cEtudePasserelles.getNetworkAllowed()),
        );
    }

    updateBiensEnvoyees$(etudePasserelles: EtudePasserelle[]): Observable<void> {
        return combineLatest(etudePasserelles.map(etudePasserelle => this._etudePasserelleService.updateBiensEnvoyees$(etudePasserelle))).pipe(map(_ => undefined));
    }
}
