import {inject, Injectable} from '@angular/core';
import Document from '@models/documents/document/document.model';
import {Observable} from 'rxjs';
import {IDocumentApi, IDocumentVariables} from '@models/documents/document/document.interfaces';
import {DocumentsApiService} from '@models/documents/documents.api.service';
import {map} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';
import Media from '@models/medias/media/media.model';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Template from '@models/templates/template/template.model';

@Injectable({providedIn: 'root'})
export class DocumentFactory {
    private _documentsApiService = inject(DocumentsApiService);

    create(documentApi: IDocumentApi): Document {
        const document = this.createVirgin(documentApi.id?.toString(), documentApi.id);

        document.contenu = documentApi.contenu;
        document.templateId = documentApi.templateId;
        document.titre = documentApi.titre;
        document.variables = documentApi.variables;
        if (documentApi._links) {
            if (documentApi._links.self) {
                document.linkSelf = documentApi._links.self.href;
            }

            if (documentApi._links.template) {
                document.linkTemplate = documentApi._links.template.href;
            }
        }

        return document;
    }

    createFromDossierBien(dossierBien: ADossierBien, template: Template, addedVariables?: Record<string, unknown>): Document {
        const document = this.createVirgin();

        document.templateId = template.id;
        document.titre = template.name + ' ' + dossierBien.reference;
        document.variables = {[dossierBien.dossierType + 'Id']: dossierBien.id};

        return this.setAddedVariables(document, addedVariables);
    }

    createFromDossierBiens(dossierBiens: ADossierBien[], template: Template, addedVariables?: Record<string, unknown>): Document {
        const document = this.createVirgin();

        document.templateId = template.id;
        document.titre = 'Bien' + (dossierBiens.length === 1 ? '' : 's') + ' en ' + dossierBiens[0].dossierType;
        document.variables = {ids: dossierBiens.map(dossierBien => dossierBien.id)};

        return this.setAddedVariables(document, addedVariables);
    }

    createFromFraisAcquisition(template: Template, variables: IDocumentVariables): Document {
        const document = this.createVirgin();

        document.templateId = template.id;
        document.titre = template.name;
        document.variables = variables;

        return document;
    }

    createVirgin(uuid?: string, id?: number): Document {
        const document = new Document(uuid ?? Document.statuts.NEW, id);

        document.variables = {};

        return document;
    }

    forApi(document: Document): IDocumentApi {
        const documentApi = {} as IDocumentApi;

        documentApi.contenu = document.contenu;
        documentApi.templateId = document.templateId;
        documentApi.titre = document.titre;
        documentApi.variables = document.variables;

        return documentApi;
    }

    get$(id: number): Observable<Document> {
        return this._documentsApiService.get$(id.toString()).pipe(map(documentApi => this.create(documentApi)));
    }

    getByLink$(link: string): Observable<Document> {
        return this._documentsApiService.getByLink$(link).pipe(map(documentApi => this.create(documentApi)));
    }

    readByName$(name: string, title = Media.NO_TITLE): Observable<Window> {
        return this._documentsApiService.readByName$(name, title);
    }

    save$(document: Document, httpOptions?: HttpOptions): Observable<Document> {
        return this._documentsApiService.save$(document.uuid, this.forApi(document), httpOptions).pipe(map(documentApi => this.create(documentApi)));
    }

    setAddedVariables(document: Document, addedVariables?: Record<string, unknown>): Document {
        if (addedVariables) {
            Object.entries(addedVariables).map(([key, value]) => {
                document.variables[key] = value;
            });
        }

        return document;
    }
}
