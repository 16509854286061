import {Directive, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {delay, merge, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgBienCollectionItem} from '@legacy/app/managers/ressources';
import {AppFormErrorDirective} from '@shared/form/error/form-error.directive';
import FormService from '@shared/form/form.service';
import {ICollectionItemErrorNgModel} from '@shared/collection/collection.interfaces';

@Directive({selector: '[appCollectionItemError]'})
export class AppCollectionItemErrorDirective extends AppFormErrorDirective implements OnInit {
    private _ngBienCollectionItem!: NgBienCollectionItem;
    private _ngModelSource = new ReplaySubject<NgModel>();
    private _ngModel$ = this._ngModelSource.asObservable();
    private _valueChangesSource = new Subject<void>();
    private _createdModel = {
        errors: {},
        formDirective: {ngSubmit: of(undefined!), submitted: true},
        valueChanges: this._valueChangesSource.asObservable(),
    } as unknown as ICollectionItemErrorNgModel;

    @Input()
    set appCollectionItemError([ngBienCollectionItem, ngModel]: [NgBienCollectionItem, NgModel]) {
        this._ngBienCollectionItem = ngBienCollectionItem;
        this._ngModelSource.next(ngModel);
    }

    ngOnInit(): void {
        this.appFormError = this._createdModel;
        super.ngOnInit();
        this._ngModel$.pipe(
            filter(ngModel => !!ngModel),
            switchMap(ngModel => merge(
                ngModel.valueChanges!,
                this._ngBienCollectionItem._esk.savedAsk$!,
            ).pipe(map(_ => ngModel))),
            filter(_ => !this._ngBienCollectionItem.isEmpty()),
            map(ngModel => ngModel.name.replace(this._ngBienCollectionItem.uuid, '')),
            delay(1),
            tap(_ => this._ngBienCollectionItem.isValid()),
            tap(name => this._createdModel.errors[FormService.PARSE] = this._ngBienCollectionItem._esk.errors![name]),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this._valueChangesSource.next());
    }
}
