import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {BonvisiteLocationFactory} from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.factory';
import {BonvisiteLocationsApiService} from '@models/bonvisites/bonvisite/locations/bonvisite-locations.api.service';
import {
    ICBonvisiteLocationsApi
} from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.interfaces';
import CBonvisiteLocations from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CBonvisiteLocationsFactory {
    private _bonvisiteLocationFactory = inject(BonvisiteLocationFactory);
    private _bonvisiteLocationsApiService = inject(BonvisiteLocationsApiService);

    create(cBonvisiteLocationsApi: ICBonvisiteLocationsApi): CBonvisiteLocations {
        const cBonvisiteLocations = new CBonvisiteLocations();

        cBonvisiteLocations.links = new Links(cBonvisiteLocationsApi._links);
        cBonvisiteLocations.page = cBonvisiteLocationsApi.page;
        cBonvisiteLocations.pages = cBonvisiteLocationsApi.pages;
        cBonvisiteLocations.perPage = cBonvisiteLocationsApi.limit;
        cBonvisiteLocations.total = cBonvisiteLocationsApi.total;

        if (cBonvisiteLocationsApi._embedded?.items) {
            cBonvisiteLocationsApi._embedded.items.filter(bonvisiteLocationApi => bonvisiteLocationApi)
                .map(bonvisiteLocationApi => cBonvisiteLocations.results.push(this._bonvisiteLocationFactory.create(bonvisiteLocationApi)));
        }

        return cBonvisiteLocations;
    }

    getByLink$(link: string): Observable<CBonvisiteLocations> {
        return this._bonvisiteLocationsApiService.getCollectionByLink$(link).pipe(map(cBonvisiteLocationsApi => this.create(cBonvisiteLocationsApi)));
    }
}
