import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from '@shared/dropdown/dropdown.component';
import {DropdownContentComponent} from '@shared/dropdown/content/dropdown-content.component';

@NgModule({
    declarations: [DropdownComponent, DropdownContentComponent],
    exports: [DropdownComponent],
    imports: [CommonModule],
})
export class DropdownModule {
}
