export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.saveDemandeur()" novalidate class="demandeur-archive">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'archive'"></fa-icon> Archiver le demandeur</h3>
  </div>

  <div class="modal-body">
    <div class="row">
      <!-- Raison -->
      <div class="col-sm-6 form-group">
        <label>Raison de l'archivage</label>

        <div class="radio clip-radio radio-purple"
             ng-repeat="typeArchiveDemandeurVente in $ctrl.typesArchiveDemandeurVente">
          <input type="radio" id="type-archive{{typeArchiveDemandeurVente.id}}" ng-model="$ctrl.archive.type"
                 name="typeArchiveDemandeurVente" ng-value="typeArchiveDemandeurVente">

          <label for="type-archive{{typeArchiveDemandeurVente.id}}">
            {{typeArchiveDemandeurVente.label|translate}}
          </label>
        </div>
      </div>

      <!-- Provenance -->
      <div class="col-sm-6 form-group" ng-if="$ctrl.archive.type.code === $ctrl.CODE_ARCHIVE_TYPES_VENTE_TROUVEE">
        <label>{{"Provenance de la vente"|translate}}</label>

        <div class="radio clip-radio radio-purple"
             ng-repeat="raisonArchiveDemandeurVenteNegociee in $ctrl.raisonsArchiveDemandeurVenteNegociee">
          <input type="radio" name="raisonArchiveDemandeurVenteNegociee"
                 ng-value="raisonArchiveDemandeurVenteNegociee" ng-model="$ctrl.archive.raisonVenteTrouvee"
                 id="raison-archive-demandeur-vente-negociee{{raisonArchiveDemandeurVenteNegociee.id}}">

          <label for="raison-archive-demandeur-vente-negociee{{raisonArchiveDemandeurVenteNegociee.id}}">
            {{raisonArchiveDemandeurVenteNegociee.label|translate}}
          </label>
        </div>
      </div>
    </div>

    <div class="row" ng-if="$ctrl.archive.type.code === $ctrl.CODE_ARCHIVE_TYPES_VENTE_TROUVEE">
      <!-- Référence de la vente -->
      <div class="col-sm-8 reference-vente form-group"
           ng-class="{'has-error': $ctrl.editForm.$submitted && ($ctrl.editForm.archiveVenteId.$invalid || $ctrl.editForm.archiveVenteAllId.$invalid || $ctrl.editForm.archiveVenteAutre.$invalid)}">
        <label>Référence du bien en vente</label>

        <div class="ventes-archivees"
             ng-if="$ctrl.archive.raisonVenteTrouvee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE || $ctrl.archive.raisonVenteTrouvee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU">
          <div class="checkbox clip-check check-purple"
               title="La recherche sera effectuée également sur les biens en vente archivés.">
            <input type="checkbox" id="ventes-archivees" ng-model="$ctrl.archivedVentes"
                   ng-change="$ctrl.getArchivedVentes()">

            <label for="ventes-archivees">Archivés</label>
          </div>
        </div>

        <div>
          <!-- Référence interne de la vente -->
          <div ng-if="$ctrl.archive.raisonVenteTrouvee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE">
            <esk-select-dossier types-dossier-enabled="['ventes']" ng-model="$ctrl.archive.vente"
                                options="$ctrl.options.ventes.interne"></esk-select-dossier>

            <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveVenteId.$error"
                 ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archiveVenteId.$invalid">
              <ng-messages-include src="messages.html"></ng-messages-include>
            </div>
          </div>

          <!-- Toutes références de ventes -->
          <div ng-if="$ctrl.archive.raisonVenteTrouvee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU">
            <esk-select-dossier types-dossier-enabled="['ventes_reseau']" ng-model="$ctrl.archive.vente"
                                options="$ctrl.options.ventes.all"></esk-select-dossier>

            <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveVenteAllId.$error"
                 ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archiveVenteAllId.$invalid">
              <ng-messages-include src="messages.html"></ng-messages-include>
            </div>
          </div>

          <!-- Autre référence de la vente -->
          <div ng-if="$ctrl.archive.raisonVenteTrouvee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES">
            <input type="text" name="archiveVenteAutre" class="form-control" ng-model="$ctrl.archive.referenceAutres"
                   ng-required="true">

            <div class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveVenteAutre.$error"
                 ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.archiveVenteAutre.$invalid">
              <ng-messages-include src="messages.html"></ng-messages-include>
            </div>
          </div>
        </div>
      </div>

      <!-- Date d'achat -->
      <div class="col-sm-4">
        <div class="form-group"
             ng-class="{'has-error': $ctrl.editForm.$submitted && ($ctrl.editForm.archiveDateAchat.$error | json) !== '{}'}">
          <label>{{"Date d'achat"|translate}}</label>

          <esk-select-date form="$ctrl.editForm" form-field-name="archiveDateAchat" is-required="true"
                           model="$ctrl.archive.dateAchat" options="$ctrl.options.date"></esk-select-date>

          <span class="error text-small tw-block" ng-messages="$ctrl.editForm.archiveDateAchat.$error"
                ng-if="$ctrl.editForm.$submitted">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </span>
        </div>
      </div>
    </div>

    <div class="row" ng-if="$ctrl.archive.type.code === $ctrl.CODE_ARCHIVE_TYPES_VENTE_TROUVEE">
      <!-- Prix de la vente sélectionnée -->
      <div class="col-sm-4 form-group" ng-if="$ctrl.archive.raisonVenteTrouvee.code !== $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES">
        <label ng-show="!!$ctrl.archive.vente.prixVente">Prix de vente</label>

        <p
          class="form-control-static">{{$ctrl.archive.vente.prixVente | currency:'€':2 * ($ctrl.archive.vente.prixVente % 1 !== 0)}}</p>
      </div>

      <!-- Prix d'achat -->
      <div class="col-sm-4 form-group"
           ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.prixAchat.$invalid}">
        <label for="archive-prix-achat">{{"Prix d'achat"|translate}}</label>

        <div class="input-group">
          <input id="archive-prix-achat" type="text" name="prixAchat" class="form-control"
                 ng-model="$ctrl.archive.prixAchat" soq-only-digits="{nonAccepted0: true}" required>

          <span class="input-group-addon">&euro;</span>
        </div>

        <div class="error text-small tw-block" ng-messages="$ctrl.editForm.prixAchat.$error"
             ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.prixAchat.$invalid">
          <ng-messages-include src="messages.html"></ng-messages-include>
        </div>
      </div>

      <!-- Honoraires de négociation perçues -->
      <div class="col-sm-4 form-group" ng-if="$ctrl.archive.raisonVenteTrouvee.code !== $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE">
        <label for="archive-honoraires-nego-percus">Négociation perçue</label>

        <div class="input-group">
          <input type="text" id="archive-honoraires-nego-percus" class="form-control"
                 ng-model="$ctrl.archive.honorairesNegoPercus" soq-only-digits>

          <span class="input-group-addon">&euro;</span>
        </div>
      </div>
    </div>

    <!-- Commentaires -->
    <div class="row">
      <div class="col-sm-12">
        <label>Commentaires</label>

        <app-ng2-textarea [text]="$ctrl.archive.comments" (changed)="$ctrl.archive.comments = $event"
                          [placeholder]="'Commentaires complémentaires concernant l\\'archivage'"></app-ng2-textarea>
      </div>
    </div>
  </div>

  <div class="modal-footer" ng-switch="$ctrl.sending">
    <p ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
    </p>

    <div ng-switch-default>
      <button class="btn btn-primary btn-o" type="submit"><fa-icon [icon]="'check'"></fa-icon> Enregistrer</button>

      <button class="btn btn-primary" type="button" ng-click="$ctrl.archiveDemandeur()">
        <fa-icon [icon]="'archive'"></fa-icon> Confirmer l'archivage
      </button>
    </div>
  </div>
</form>
`;
