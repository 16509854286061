import {NgModule, Type} from '@angular/core';
import {
    NgLocationLabelPriceComponent, NgLocationLabelPriceDirective
} from '@shared/angularJS/up-ng/locations/location-label-price.component';
import {
    NgLocationDiffusionComponent, NgLocationDiffusionDirective
} from '@shared/angularJS/up-ng/locations/locations-item-diffusion.component';
import {
    NgLocationGestionComponent, NgLocationGestionDirective
} from '@shared/angularJS/up-ng/locations/locations-item-gestion.component';
import {
    NgLocationLifetimeComponent, NgLocationLifetimeDirective
} from '@shared/angularJS/up-ng/locations/locations-item-lifetime.component';
import {
    NgLocationMandatsComponent, NgLocationMandatsDirective
} from '@shared/angularJS/up-ng/locations/locations-item-mandats.component';
import {CommonModule} from '@angular/common';

const components: Type<unknown>[] = [
    NgLocationDiffusionComponent,
    NgLocationGestionComponent,
    NgLocationLabelPriceComponent,
    NgLocationLifetimeComponent,
    NgLocationMandatsComponent,
];
const directives: Type<unknown>[] = [
    NgLocationDiffusionDirective,
    NgLocationGestionDirective,
    NgLocationLabelPriceDirective,
    NgLocationLifetimeDirective,
    NgLocationMandatsDirective,
];

@NgModule({declarations: [...components, ...directives], exports: components, imports: [CommonModule]})
export class LocationsComponentsModule {
}
