<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Loi ALUR</h4>

  <div class="tw-bg-gray-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (isNombreLotsLoiAlurVisible) {
        <li>{{ dossierBien.bien.nombreLotsLoiAlur }} Lot{{ dossierBien.bien.nombreLotsLoiAlur > 1 ? 's' : '' }}</li>
      }

      @if (isNombreCoproprietairesLoiAlurVisible) {
        <li>
          {{ dossierBien.bien.nombreCoproprietairesLoiAlur }}
          Copropriétaire{{ dossierBien.bien.nombreCoproprietairesLoiAlur > 1 ? 's' : '' }}
        </li>
      }

      @if (isCoordonneesSyndicLoiAlurVisible) {
        <li>
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">Coordonnées du Syndic</span>

          <div class="tw-text-gray-700 tw-font-normal">{{ dossierBien.bien.coordonneesSyndicLoiAlur }}</div>
        </li>
      }

      @if (isMontantQuotePartLoiAlurVisible) {
        <li>
          Quote-part moyenne annuelle <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.montantQuotePartLoiAlur | textCurrency:'€' }}
        </li>
      }

      @if (isMontantTravauxLoiAlurVisible) {
        <li>
          Travaux <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span>
          {{ dossierBien.bien.montantTravauxLoiAlur | textCurrency:'€' }}
        </li>
      }

      @if (isPlanSauvegardeLoiAlurVisible) {
        <li>Plan de sauvegarde</li>
      }

      @if (isAdministrationSyndicLoiAlurVisible) {
        <li>Administration provisoire du Syndic</li>
      }
    </ul>

    @if (isCommentsLoiAlurVisible) {
      <div class="tw-text-sm tw-text-gray-700">{{ dossierBien.bien.commentsLoiAlur }}</div>
    }
  </div>
</div>
