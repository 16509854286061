import {inject, Injectable} from '@angular/core';
import CDemandeurs from '@models/demandeurs/collection/demandeurs.collection.model';
import {Observable} from 'rxjs';
import {CDemandeursFactory} from '@models/demandeurs/collection/demandeurs.collection.factory';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {map, tap} from 'rxjs/operators';
import {ConditionConst} from '@shared/constants';

@Injectable({providedIn: 'root'})
export class CDemandeursService {
    private _cDemandeursFactory = inject(CDemandeursFactory);

    addNext$(cDemandeurs: CDemandeurs): Observable<CDemandeurs> {
        return this._cDemandeursFactory.getNext$(cDemandeurs).pipe(
            tap(newCDemandeurs => cDemandeurs.updateNext(newCDemandeurs)),
            map(_ => cDemandeurs),
        );
    }

    searchActive$(keyword?: string): Observable<CDemandeurs> {
        return this._cDemandeursFactory.get$({
            keywords: keyword,
            statut: {condition: ConditionConst.NOT_IN, values: [Demandeur.statuts.ARCHIVE]},
        });
    }
}
