import {Component, inject, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {IBienPhotoOptions} from '@features/biens/biens.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';

@Component({selector: 'app-bien-photo', templateUrl: 'bien.photo.component.html'})
export class AppBienPhotoComponent {
    static readonly initBienPhotoOptions: IBienPhotoOptions = {linkPhotoAttribute: 'linkPremierePhotoOptimized'};
    private _dossierBienFactory = inject(DossierBienFactory);
    private _etudeService = inject(EtudeService);
    private _dossierBien$!: Observable<ADossierBien>;
    private _options: IBienPhotoOptions = {...AppBienPhotoComponent.initBienPhotoOptions};

    get dossierBien$(): Observable<ADossierBien> {
        return this._dossierBien$;
    }

    @Input()
    set dossierBien(value: ADossierBien) {
        this._dossierBien$ = of(value);
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    @Input()
    set linkDossierBien(value: string) {
        this._dossierBien$ = this._dossierBienFactory.getByLink$(value);
    }

    get options(): IBienPhotoOptions {
        return this._options;
    }

    @Input()
    set options(value: IBienPhotoOptions) {
        this._options = {...AppBienPhotoComponent.initBienPhotoOptions, ...value};
    }
}
