import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserSettingsApiService} from '@models/users/user/settings/user-settings.api.service';
import {
    IUserSettingsDiffusionsApi, IUserSettingsDossiers, IUserSettingsDossiersApi
} from '@models/users/user/settings/user-settings.interfaces';
import UserSettings from '@models/users/user/settings/user-settings.model';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import User from '@models/users/user/user.model';
import {HttpOptions} from '@core/api/api.interfaces';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Injectable({providedIn: 'root'})
export class UserSettingsFactory {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _phoneNumberService = inject(PhoneNumberService);
    private _userSettingsApiService = inject(UserSettingsApiService);

    createVirgin(): UserSettings {
        return new UserSettings();
    }

    get$(user: User, httpOptions?: HttpOptions): Observable<UserSettings> {
        const get$s = UserSettings.DOSSIER_TYPES.map(dossierType =>
            this._userSettingsApiService.getDossiers$(user.uuid, dossierType, httpOptions)
                .pipe(map(userSettingsDossiersApi => this.updateDossiers(dossierType, userSettings, userSettingsDossiersApi)))
        );
        const userSettings = this.createVirgin();

        get$s.push(this._userSettingsApiService.getDiffusions$(user.uuid, httpOptions).pipe(map(userSettingsDiffusionsApi => this.updateDiffusions(userSettings, userSettingsDiffusionsApi))));

        return combineLatest(get$s).pipe(map(_ => userSettings));
    }

    forApiDiffusions(userSettings: UserSettings): IUserSettingsDiffusionsApi {
        if (!userSettings.diffusions?.telephoneSource) {
            return {} as IUserSettingsDiffusionsApi;
        }

        return {
            telephoneAutre: userSettings.diffusions.telephoneSource.code === UserSettings.phoneSources.AUTRE ? this._phoneNumberService.getE164(userSettings.diffusions.telephoneAutre) : undefined!,
            telephoneSource: userSettings.diffusions.telephoneSource.code,
        };
    }

    forApiDossiers(dossierType: string, userSettings: UserSettings): IUserSettingsDossiersApi {
        if (!userSettings[dossierType]) {
            return {} as IUserSettingsDossiersApi;
        }

        return {defaultNature: (userSettings[dossierType] as IUserSettingsDossiers).defaultNature.code};
    }

    save$(user: User, userSettings: UserSettings): Observable<UserSettings> {
        const save$s = UserSettings.DOSSIER_TYPES.map(dossierType =>
            this._userSettingsApiService.saveDossiers$(user.uuid, dossierType, this.forApiDossiers(dossierType, userSettings))
                .pipe(map(userSettingsDossiersApi => this.updateDossiers(dossierType, userSettings, userSettingsDossiersApi)))
        );

        save$s.push(this._userSettingsApiService.saveDiffusions$(user.uuid, this.forApiDiffusions(userSettings)).pipe(
            map(userSettingsDiffusionsApi => this.updateDiffusions(userSettings, userSettingsDiffusionsApi))
        ));

        return combineLatest(save$s).pipe(map(_ => userSettings));
    }

    updateDiffusions(userSettings: UserSettings, userSettingsDiffusionsApi: IUserSettingsDiffusionsApi): UserSettings {
        userSettings.diffusions.telephoneAutre = this._phoneNumberService.getUsable(userSettingsDiffusionsApi.telephoneAutre);
        userSettings.diffusions.telephoneSource =
            this._dictionaryItemService.getByCode(Dictionary.names.DIFFUSION_PHONE_SOURCES, userSettingsDiffusionsApi.telephoneSource);

        return userSettings;
    }

    updateDossiers(dossierType: string, userSettings: UserSettings, userSettingsDossiersApi: IUserSettingsDossiersApi): UserSettings {
        userSettings[dossierType] = {defaultNature: this._dictionaryItemService.getByCode(Dictionary.names.NATURES, userSettingsDossiersApi.defaultNature)};

        return userSettings;
    }
}
