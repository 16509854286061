export default `
<form name="$ctrl.editForm" class="ventes-modal-box-mandats" ng-submit="$ctrl.save()" novalidate>
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans modifier la configuration"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'cogs'"></fa-icon> {{$ctrl.box.name}}</h3>
  </div>

  <div class="modal-body">
    <div class="form-group"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.nbDisplayed.$invalid}">
      <label>{{"Nombre de biens à afficher" | translate}}</label>

      <esk-select ng-model="$ctrl.box.config.nbDisplayed" list="$ctrl.listNbDisplayed"
                  options="$ctrl.options.nbDisplayed"></esk-select>

      <div class="help-block" ng-messages="$ctrl.editForm.nbDisplayed.$error"
           ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>

    <div ng-if="$ctrl.box.code === 'ventes-mandats-expiring'" class="form-group"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.mandatsEcheance.$invalid}">
      <label>{{"Échéance du mandat"|translate}}</label>

      <esk-select ng-model="$ctrl.box.config.mandatsEcheance" list="$ctrl.listMandatsEcheance"
                  options="$ctrl.options.mandatsEcheance"></esk-select>

      <div class="help-block" ng-messages="$ctrl.editForm.mandatsEcheance.$error"
           ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>

    <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.sort.$invalid}">
      <label>{{"Tri de la liste" | translate}}</label>

      <esk-select-sort type="{{'BOX_' + $ctrl.box.code}}" ng-model="$ctrl.box.config.sort"
                       options="$ctrl.options.sort"></esk-select-sort>

      <div class="help-block" ng-messages="$ctrl.editForm.sort.$error"
           ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-switch on="$ctrl.loader">
      <p class="text-muted" ng-switch-when="true">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
      </p>

      <button type="submit" ng-switch-default class="btn btn-primary">
        <fa-icon [icon]="'check'"></fa-icon> Enregistrer
      </button>
    </ng-switch>
  </div>
</form>
`;
