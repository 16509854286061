import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {combineLatest, Observable, of} from 'rxjs';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';

@Injectable({providedIn: 'root'})
export class DossierBiensService {
    private _dossierBienFactory = inject(DossierBienFactory);

    getByLinks$(links: string[] = []): Observable<ADossierBien[]> {
        return links.length > 0 ? combineLatest(links.map(link => this._dossierBienFactory.getByLink$(link))) : of([] as ADossierBien[]);
    }
}
