import {inject, Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
    CBonvisiteVentesFactory
} from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.factory';
import Vente from '@models/ventes/vente/vente.model';
import {VentesService} from '@models/ventes/ventes.service';

@Injectable({providedIn: 'root'})
export class CBonvisiteVentesService {
    private _cBonvisiteVentesFactory = inject(CBonvisiteVentesFactory);
    private _ventesService = inject(VentesService);

    getVentesByLink$(link: string): Observable<Vente[]> {
        return this._cBonvisiteVentesFactory.getByLink$(link).pipe(
            map(cBonvisiteVentes => cBonvisiteVentes.results.map(bonvisiteVente => bonvisiteVente.linkVente)),
            switchMap(venteLinks => this._ventesService.getByLinks$(venteLinks)),
        );
    }
}
