import {inject, Injectable} from '@angular/core';
import {IUserPhotoApi} from '@models/users/user/photo/user-photo.interfaces';
import UserPhoto from '@models/users/user/photo/user-photo.model';
import {Observable} from 'rxjs';
import {UserPhotoApiService} from '@models/users/user/photo/user-photo.api.service';
import {NgUserPhoto} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class UserPhotoFactory {
    private _userPhotoApiService = inject(UserPhotoApiService);

    create(userPhotoApi: IUserPhotoApi): UserPhoto {
        const userPhoto = new UserPhoto();

        userPhoto.bottomRightCoordinates = userPhotoApi.bottomRightCoordinates;
        userPhoto.photoId = userPhotoApi._embedded.photo.id;
        userPhoto.topLeftCoordinates = userPhotoApi.topLeftCoordinates;

        return userPhoto;
    }

    removeCurrentPhoto$(): Observable<void> {
        return this._userPhotoApiService.removeCurrentPhoto$();
    }

    ngCreate(ngUserPhoto: NgUserPhoto): UserPhoto {
        const userPhoto = new UserPhoto();

        userPhoto.bottomRightCoordinates = ngUserPhoto.bottomRightCoordinates;
        userPhoto.photoId = ngUserPhoto.photo.id;
        userPhoto.topLeftCoordinates = ngUserPhoto.topLeftCoordinates;

        return userPhoto;
    }
}
