import {Injectable} from '@angular/core';
import Departement from '@models/departements/departement/departement.model';
import {NgArea} from '@legacy/app/managers/ressources';
import {IDepartementApi} from '@models/departements/departement/departement.interfaces';

@Injectable({providedIn: 'root'})
export class DepartementFactory {
    create(departementApi: IDepartementApi): Departement {
        const departement = new Departement(departementApi.uuid, departementApi.id);

        departement.code = departementApi.code;
        departement.fullname = departementApi.fullname;

        return departement;
    }

    ngCreate(ngDepartement: NgArea): Departement {
        const departement = new Departement(ngDepartement.uuid, ngDepartement.id);

        departement.code = ngDepartement.code;
        departement.fullname = ngDepartement.fullname;

        return departement;
    }
}
