import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Vente from '@models/ventes/vente/vente.model';
import BonvisiteVente from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.model';
import {BonvisiteVenteFactory} from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.factory';
import {
    CBonvisiteVentesFactory
} from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.factory';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BonvisiteVentesService {
    private _bonvisiteVenteFactory = inject(BonvisiteVenteFactory);
    private _cBonvisiteVentesFactory = inject(CBonvisiteVentesFactory);

    delete$(bonvisite: Bonvisite, bonvisiteVentes: BonvisiteVente[] = []): Observable<void> {
        if (bonvisiteVentes.length <= 0) {
            return of(undefined);
        }

        return combineLatest(bonvisiteVentes.map(bonvisiteVente => this._bonvisiteVenteFactory.delete$(bonvisite, bonvisiteVente))).pipe(map(_ => undefined));
    }

    persist$(bonvisite: Bonvisite, ventes: Vente[] = []): Observable<BonvisiteVente[]> {
        let ventesToSave$ = of(undefined) as Observable<unknown>;

        if (bonvisite.linkBVVentes) {
            ventesToSave$ = this._cBonvisiteVentesFactory.getByLink$(bonvisite.linkBVVentes).pipe(
                switchMap(cBonvisiteVentes => this.delete$(bonvisite, cBonvisiteVentes.results)),
            );
        }

        return ventesToSave$.pipe(switchMap(_ => this.save$(bonvisite, ventes)));
    }

    save$(bonvisite: Bonvisite, ventes: Vente[] = []): Observable<BonvisiteVente[]> {
        return ventes.length > 0 ? combineLatest(ventes.map(vente => this._bonvisiteVenteFactory.save$(bonvisite, this._bonvisiteVenteFactory.createFromVente(vente)))) : of([]);
    }
}
