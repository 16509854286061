import Collection from '@models/collection/collection.model';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';

export default class CProcedureSignataires extends Collection<ProcedureSignataire> {
    getFinished(): ProcedureSignataire[] {
        return this.results.filter(signataire => signataire.isFinished());
    }

    isValid(procedureProvider: string): boolean {
        if (this.total <= 0) {
            return false;
        }

        return !this.results.some(procedureSignataire => !procedureSignataire.isValid(procedureProvider));
    }
}
