import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {TemplateFooterFactory} from '@models/templates/footers/footer/template-footer.factory';
import {ICTemplateFootersApi} from '@models/templates/footers/collection/template-footers.collection.interfaces';
import CTemplateFooters from '@models/templates/footers/collection/template-footers.collection.model';
import {TemplateFootersApiService} from '@models/templates/footers/template-footers.api.service';

@Injectable({providedIn: 'root'})
export class CTemplateFootersFactory {
    private _templateFooterFactory = inject(TemplateFooterFactory);
    private _templateFootersApiService = inject(TemplateFootersApiService);

    create(cTemplateFootersApi: ICTemplateFootersApi): CTemplateFooters {
        const cTemplateFooters = new CTemplateFooters();

        cTemplateFooters.links = new Links(cTemplateFootersApi._links);
        cTemplateFooters.page = cTemplateFootersApi.page;
        cTemplateFooters.pages = cTemplateFootersApi.pages;
        cTemplateFooters.perPage = cTemplateFootersApi.limit;
        cTemplateFooters.total = cTemplateFootersApi.total;

        if (cTemplateFootersApi._embedded && cTemplateFootersApi._embedded.items) {
            cTemplateFootersApi._embedded.items
                .filter(templateFooterApi => templateFooterApi)
                .map(templateFooterApi => cTemplateFooters.results.push(this._templateFooterFactory.create(templateFooterApi)));
        }

        return cTemplateFooters;
    }

    get$(): Observable<CTemplateFooters> {
        return this._templateFootersApiService.getFooters$().pipe(map(cTemplateFootersApi => this.create(cTemplateFootersApi)));
    }
}
