import {inject, Injectable} from '@angular/core';
import EtudeSettingsDocumentsFooter
    from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.model';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {
    EtudeSettingsDocumentsFooterFactory
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EtudeSettingsDocumentsFooterService {
    private _etudeService = inject(EtudeService);
    private _etudeSettingsDocumentsFooterFactory = inject(EtudeSettingsDocumentsFooterFactory);
    private _currentSource = new BehaviorSubject<EtudeSettingsDocumentsFooter>(undefined!);
    private _current$ = this._currentSource.asObservable();

    get current$(): Observable<EtudeSettingsDocumentsFooter> {
        return this._current$;
    }

    get$(): Observable<EtudeSettingsDocumentsFooter> {
        return this._etudeService.last$.pipe(switchMap(currentEtude => this._etudeSettingsDocumentsFooterFactory.get$(currentEtude)));
    }

    initCurrent(): void {
        this._currentSource.next(undefined!);
        this.get$().pipe(take(1)).subscribe(etudeSettingsDocumentsFooter => this._currentSource.next(etudeSettingsDocumentsFooter));
    }

    save$(etudeSettingsDocumentsFooter: EtudeSettingsDocumentsFooter): Observable<EtudeSettingsDocumentsFooter> {
        return this._etudeService.last$.pipe(switchMap(currentEtude => this._etudeSettingsDocumentsFooterFactory.save$(currentEtude, etudeSettingsDocumentsFooter)));
    }
}
