import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICVenteMatchingsApi} from '@models/ventes/vente/matchings/collection/vente-matching.collection.interfaces';

@Injectable({providedIn: 'root'})
export class VenteMatchingsApiService {
    private _modelApiService = inject(ModelApiService);

    getCollection$(venteUuid: string): Observable<ICVenteMatchingsApi> {
        return this.getCollectionByLink$('/ventes/' + venteUuid + '/demandeurs');
    }

    getCollectionByLink$(link: string): Observable<ICVenteMatchingsApi> {
        return this._modelApiService.getCollection$(link);
    }
}
