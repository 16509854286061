import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IEstimationIndicator} from '@models/estimations/estimation/indicators/estimation-indicators.interfaces';
import {
    EstimationIndicatorsSectionFactory
} from '@models/estimations/estimation/indicators/section/estimation-indicators-section.factory';
import EstimationIndicatorsSection
    from '@models/estimations/estimation/indicators/section/estimation-indicators-section.model';

@Injectable({providedIn: 'root'})
export class EstimationIndicatorsService {
    static readonly CITYSCAN_INDICATORS_SECTIONS = [
        {
            indicators: [
                {
                    code: 'avalider_avant_utilisation_cadastre',
                    convivialName: 'Cadastre',
                    disabled: true,
                    uuid: 'mission_carte_avalider_avant_utilisation_cadastre',
                },
                {
                    code: 'avalider_avant_utilisation_aerienne',
                    convivialName: 'Aérienne',
                    disabled: true,
                    uuid: 'mission_carte_avalider_avant_utilisation_aerienne',
                },
            ],
            title: 'Mission / Cartes',
        },
        {
            indicators: [
                {
                    code: 'avalider_avant_utilisation_acces_transports',
                    convivialName: 'Accès aux transports',
                    disabled: true,
                    uuid: 'description_carte_avalider_avant_utilisation_acces_transports',
                },
                {
                    code: 'avalider_avant_utilisation_acces_education',
                    convivialName: 'Accès à l\'éducation',
                    disabled: true,
                    uuid: 'description_carte_avalider_avant_utilisation_acces_education',
                },
                {
                    code: 'avalider_avant_utilisation_acces_commerces',
                    convivialName: 'Accès aux commerces',
                    disabled: true,
                    uuid: 'description_carte_avalider_avant_utilisation_acces_commerces',
                },
                {
                    code: 'avalider_avant_utilisation_acces_sante',
                    convivialName: 'Accès à la santé',
                    disabled: true,
                    uuid: 'description_carte_avalider_avant_utilisation_acces_sante',
                },
                {
                    code: 'NUISANCE_SONORE',
                    convivialName: 'Nuisance sonore',
                    disabled: false,
                    uuid: 'description_carte_NUISANCE_SONORE',
                },
                {code: 'RADON_CARTE', convivialName: 'Radon', disabled: false, uuid: 'description_carte_RADON'},
                {
                    code: 'POLLUTION_AIR_CARTE',
                    convivialName: 'Pollution de l\'air',
                    disabled: false,
                    uuid: 'description_carte_POLLUTION_AIR',
                },
            ],
            title: 'Description / Cartes',
        },
        {
            indicators: [
                {
                    code: 'RISQUE_MINIER',
                    convivialName: 'Minier',
                    disabled: false,
                    uuid: 'description_risques_RISQUE_MINIER',
                },
                {
                    code: 'SISMICITE',
                    convivialName: 'Sismicité',
                    disabled: false,
                    uuid: 'description_risques_SISMICITE',
                },
                {
                    code: 'FEU_FORET',
                    convivialName: 'Feu de forêt',
                    disabled: false,
                    uuid: 'description_risques_FEU_FORET',
                },
                {
                    code: 'RISQUE_INDUSTRIEL',
                    convivialName: 'Industriel',
                    disabled: false,
                    uuid: 'description_risques_RISQUE_INDUSTRIEL',
                },
                {
                    code: 'RISQUE_NUCLEAIRE',
                    convivialName: 'Nucléaire',
                    disabled: false,
                    uuid: 'description_risques_RISQUE_NUCLEAIRE',
                },
                {
                    code: 'MOUVEMENT_TERRAIN',
                    convivialName: 'Mouvement de terrain',
                    disabled: false,
                    uuid: 'description_risques_MOUVEMENT_TERRAIN',
                },
                {
                    code: 'INONDATION',
                    convivialName: 'Inondation',
                    disabled: false,
                    uuid: 'description_risques_INONDATION',
                },
                {
                    code: 'AVALANCHE',
                    convivialName: 'Avalanche',
                    disabled: false,
                    uuid: 'description_risques_AVALANCHE',
                },
                {
                    code: 'RISQUE_SOLS_POLLUES',
                    convivialName: 'Sol pollué',
                    disabled: false,
                    uuid: 'description_risques_RISQUE_SOLS_POLLUES',
                },
                {
                    code: 'TRANSPORT_DANGEREUX',
                    convivialName: 'Transport dangereux',
                    disabled: false,
                    uuid: 'description_risques_TRANSPORT_DANGEREUX',
                },
                {
                    code: 'POLLUTION_AIR',
                    convivialName: 'Pollution de l\'air',
                    disabled: false,
                    uuid: 'description_risques_POLLUTION_AIR',
                },
                {
                    code: 'POLLUTION_ELECTROMAGNETIQUE',
                    convivialName: 'Pollution électromagnétique',
                    disabled: false,
                    uuid: 'description_risques_POLLUTION_ELECTROMAGNETIQUE',
                },
                {code: 'RADON', convivialName: 'Radon', disabled: false, uuid: 'description_risques_RADON'},
            ],
            title: 'Description / Risques',
        },
        {
            indicators: [
                {
                    code: 'PRIX_ANCIEN',
                    convivialName: 'Prix du m² dans l\'ancien',
                    disabled: false,
                    uuid: 'analyse_cartes_PRIX_ANCIEN',
                },
                {
                    code: 'PRIX_NEUF',
                    convivialName: 'Prix du m² dans le neuf',
                    disabled: false,
                    uuid: 'analyse_cartes_PRIX_NEUF',
                },
                {
                    code: 'PRIX_LOYERS',
                    convivialName: 'Loyer du m²',
                    disabled: false,
                    uuid: 'analyse_cartes_PRIX_LOYERS',
                },
            ],
            title: 'Analyse du marché / Cartes',
        },
    ];

    private _estimationIndicatorsSectionFactory = inject(EstimationIndicatorsSectionFactory);

    getCityscanCodes(estimationIndicators: IEstimationIndicator[]): string[] {
        const estimationCodeIndicators: string[] = [];

        estimationIndicators
            .map(estimationIndicator => estimationIndicator.code)
            .forEach(estimationIndicatorCode => {
                if (['PRIX_ANCIEN', 'PRIX_NEUF', 'PRIX_LOYERS'].includes(estimationIndicatorCode)) {
                    estimationCodeIndicators.push(estimationIndicatorCode + '_MAISON');
                    estimationCodeIndicators.push(estimationIndicatorCode + '_APPARTEMENT');
                    estimationCodeIndicators.push(estimationIndicatorCode + '_GLOBAL');
                } else {
                    estimationCodeIndicators.push(estimationIndicatorCode);
                }
            });

        return estimationCodeIndicators;
    }

    getCityscanIndicatorsSections$(): Observable<EstimationIndicatorsSection[]> {
        return of(EstimationIndicatorsService.CITYSCAN_INDICATORS_SECTIONS.map(
            indicatorsSection => this._estimationIndicatorsSectionFactory.create(indicatorsSection.indicators, indicatorsSection.title)
        ));
    }
}
