import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, from, Observable, Subject} from 'rxjs';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgDemandeurManager} from '@legacy/app/managers/managers';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '@models/users/user/user.service';
import {map, takeUntil, switchMap, tap} from 'rxjs/operators';
import {NgRechercheResultStatus} from '@legacy/app/managers/ressources';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';

// @todo À ranger plus proprement
interface NavItem {
    code: string;
    route: string;
    label: string;
    notif: { class?: string; results: number };
}

@Component({selector: 'layout-demandeur', templateUrl: 'layout-demandeur.component.html'})
export class AppLayoutDemandeurComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _userService = inject(UserService);
    private _demandeurSource = new Subject<Demandeur>();
    private _demandeur$ = this._demandeurSource.asObservable();
    private _navItems!: NavItem[];
    private _nbDossierFilesSource = new BehaviorSubject(0);
    private _nbDossierFiles$ = this._nbDossierFilesSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _toggleMore = false;

    // Supprimer les injections des anciens manager
    private get ngDemandeurManager(): NgDemandeurManager {
        return this._ngInjectorService.getService<NgDemandeurManager>('DemandeurManager');
    }

    get demandeur$(): Observable<Demandeur> {
        return this._demandeur$;
    }

    get navItems(): NavItem[] {
        return this._navItems;
    }

    get nbDossierFiles$(): Observable<number> {
        return this._nbDossierFiles$;
    }

    get toggleMore(): boolean {
        return this._toggleMore;
    }

    set toggleMore(value: boolean) {
        this._toggleMore = value;
    }

    ngOnInit(): void {
        combineLatest([this._activatedRoute.params, this._userService.last$]).pipe(
            tap(_ => this._demandeurSource.next(undefined!)),
            switchMap(([params, currentUser]): Observable<void> => from(this.ngDemandeurManager.initCurrent((params as {demandeurId: number}).demandeurId, currentUser))),
            switchMap(_ => this.ngDemandeurManager.current$),
            tap(ngDemandeur => {
                if (ngDemandeur.id) {
                    const status = ngDemandeur.recherches[0]?._esk.status as NgRechercheResultStatus;
                    const value = status?.main.value;

                    this._navItems = [
                        {
                            code: 'DASHBOARD',
                            label: 'Dossier',
                            notif: {results: -1},
                            route: '/app/demandeurs/' + ngDemandeur.id.toString() + '/lifetime',
                        },
                        {
                            code: 'FICHE',
                            label: 'Fiche',
                            notif: {results: -1},
                            route: '/app/demandeurs/' + ngDemandeur.id.toString() + '/edit',
                        },
                        {
                            code: 'RESULTS',
                            label: 'Résultats',
                            notif: {
                                class: status?.main.class as string,
                                results: (status as unknown as Record<string, number>)?.[value!],
                            },
                            route: '/app/demandeurs/' + ngDemandeur.id.toString() + '/resultats',
                        },
                        {
                            code: 'DOCUMENTS',
                            label: 'Document',
                            notif: {results: -1},
                            route: '/app/demandeurs/' + ngDemandeur.id.toString() + '/documents',
                        },
                        {
                            code: 'SUIVI',
                            label: 'Suivi',
                            notif: {results: -1},
                            route: '/app/demandeurs/' + ngDemandeur.id.toString() + '/suivi',
                        },
                    ];
                } else {
                    this._navItems = [];
                }
            }),
            map(ngDemandeur => this._demandeurFactory.ngCreate(ngDemandeur)),
            tap(demandeur => this._cDossierFilesService.initCurrentWithMedia(demandeur)),
            switchMap(demandeur => this._cDossierFilesService.getCurrentTotal$().pipe(
                tap(cDossierFilesTotal => this._nbDossierFilesSource.next(cDossierFilesTotal)),
                map(_ => demandeur),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(demandeur => this._demandeurSource.next(demandeur));
        this._activatedRoute.url.pipe(takeUntil(this._onDestroy$)).subscribe(_ => this._toggleMore = false);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
