import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {combineLatest, firstValueFrom, from} from 'rxjs';
import {CTemplatesFactory} from '@models/templates/collection/templates.collection.factory';
import {NgTemplateManager} from '@legacy/app/managers/managers';
import Template from '@models/templates/template/template.model';

export default function getEskSelectTemplate(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-template></esk-select-template>
         * @param options : {loading(boolean), name(string), placeholder(string), required(boolean)}
         */
        module.component('eskSelectTemplate', {
            bindings: {ngModel: '=', codes: '=', options: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/template.html'
        });

        /**
         * Controller for templates list
         *
         * @param Ng2EtudeService
         * @param Ng2CTemplatesFactory
         * @param TemplateManager
         */
        Controller.$inject = ['Ng2EtudeService', 'Ng2CTemplatesFactory', 'TemplateManager'];
        function Controller(this: any,
                            ng2EtudeService: EtudeService,
                            ng2CTemplatesFactory: CTemplatesFactory,
                            templateManager: NgTemplateManager) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.getTemplates = getTemplates;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.getTemplates();
            }

            /**
             * Get templates
             */
            function getTemplates() {
                $ctrl.options.loading = true;
                $ctrl.templates = [];

                return firstValueFrom(ng2CTemplatesFactory.get$({categoryCodes: $ctrl.codes}).pipe(
                    map(templatesCollection => templatesCollection.results),
                    switchMap(templates => combineLatest(templates.map(template => from(templateManager.createFromNg2(template))))),
                    switchMap(ngTemplates => ng2EtudeService.last$.pipe(
                        tap(currentEtude => {
                            let defaultTemplate: Template = undefined!;

                            $ctrl.codes.forEach(code => {
                                if (!angular.isObject(defaultTemplate)) {
                                    defaultTemplate = currentEtude.defaultTemplates.find(template => template.category.code === code)!;
                                }
                            });
                            if (($ctrl.options.required || (!$ctrl.options.required && defaultTemplate)) && !$ctrl.ngModel) {
                                $ctrl.ngModel = ngTemplates.find(template => template.id === defaultTemplate?.id) ?? ngTemplates[0];
                            }
                        }),
                        map(_ => ngTemplates),
                    )),
                    tap(ngTemplates => $ctrl.templates = ngTemplates),
                )).finally(() => $ctrl.options.loading = false);
            }
        }
    })(angularJS);
}
