import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskBox, NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgFilterManager} from '@legacy/app/managers/managers';
import {NgFilter} from '@legacy/app/managers/ressources';
import Filter from '@models/filters/filter/filter.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getVentesNetworkUpdatedCriteriaCtrl(module: IModule): void {
    ((angular) => {
        'use strict';

        module.component('eskVentesNetworkUpdatedCriteriaCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/controllers/network-updated.criteria.html',
        });

        /**
         * Controller of criteria for last ventes
         *
         * @param FilterManager
         * @param EskUtils
         * @param Ng2RouterStateForNgService
         * @param EskBox
         */
        Controller.$inject = ['FilterManager', 'EskUtils', 'Ng2RouterStateForNgService', 'EskBox'];
        function Controller(this: any,
                            filterManager: NgFilterManager,
                            eskUtils: NgEskUtils,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            eskBox: NgEskBox) {
            const $ctrl = this;
            let filter: NgFilter;

            $ctrl.$onInit = $onInit;
            $ctrl.launch = launch;
            $ctrl.box = eskBox.getOneByCode('ventes-network-updated');
            $ctrl.loading = true;

            /**
             * Initialization method
             */
            function $onInit() {
                filterManager.getActiveByRouteCode(Filter.routes.GET_VENTES, 'box_updated_ventes').then(
                    filterResponse => filterResponse as NgFilter
                ).then(
                    filterResponse => eskUtils.getSearchFromFilter(DossierBienTypesConst.VENTE, filterResponse.queryParameters as NgFilter).then(search => {
                        filter = filterResponse;
                        $ctrl.search = search;
                    })
                ).finally(() => $ctrl.loading = false);
            }

            /**
             * Launch request
             */
            function launch() {
                if (!angular.isFunction($ctrl.updateSearchLocalisations)) {
                    return;
                }

                $ctrl.saving = true;
                eskBox.saveConfig().then(() => {
                    $ctrl.updateSearchLocalisations($ctrl.search).then(() => {
                        filter.queryParameters = eskUtils.getFilterFromSearch($ctrl.search);
                        filter.save().then(() => ng2RouterStateForNgService.navigateByUrl('/app/dashboard')).finally(() => $ctrl.saving = false);
                    });
                });
            }
        }
    })(angularJS);
}
