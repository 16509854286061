import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-demandeur-results-details', template: '<div appNgDemandeurResultsDetails></div>'})
export class NgDemandeurResultsDetailsComponent {
}

@Directive({selector: '[appNgDemandeurResultsDetails]'})
export class NgDemandeurResultsDetailsDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurResultsDetailsCtrl', elementRef, injector);
    }
}
