export default `
<span>
  {{$ctrl.NOTY_LABEL}} {{$ctrl.etudePasserelle.statut === 'configured' ? "est" : "n'est pas"}} connectée.

  <a uib-popover-template="'src/app/legacy/templates/eskimmo/components/configuration/facebook/help.html'"
     popover-trigger="'outsideClick'" popover-placement="bottom" class="margin-left-10">
    <span class="badge badge-info">?</span>
  </a>
</span>

<ng-switch on="$ctrl.etudePasserelle.statut" class="pull-right">
  <div ng-switch-default>
    <soq-facebook fb-scopes="{{$ctrl.fbScopes}}" on-change-status="$ctrl.onChangeStatus"></soq-facebook>
  </div>

  <div ng-switch-when="authenticated">
    <button type="button" class="btn btn-primary" ng-click="$ctrl.reset()">Se connecter</button>
  </div>

  <div ng-switch-when="configured">
    <button type="button" class="btn btn-primary btn-o" ng-click="$ctrl.reset()">Se déconnecter</button>
  </div>
</ng-switch>
`;
