<div class="tw-py-1">
  @if (options.enabledActions.link) {
    <button type="button" class="tw-dropdown-link" (click)="link()"><fa-icon [icon]="'link'"/><span>Lier</span></button>
  }

  @if (options.enabledActions.restore) {
    <button type="button" class="tw-dropdown-link" (click)="restore()">
      <fa-icon [icon]="['fal', 'rotate']"/><span>Restaurer</span>
    </button>
  }

  @if (options.enabledActions.sendFiche) {
    <button type="button" class="tw-dropdown-link" (click)="sendFiche()">
      <fa-icon [icon]="['fal', 'envelope']"/><span>Envoyer</span>
    </button>
  }

  @if (options.enabledActions.sendToConfrere) {
    <button type="button" class="tw-dropdown-link" (click)="sendToConfrere()">
      <span class="tw-fa-custom-stack">
        <fa-icon [icon]="['fal', 'envelope']" class="tw-fa-custom-stack-main"/>
        <fa-icon [icon]="['fal', 'user']" size="xs" class="tw-fa-custom-stack-secondary"/>
      </span>

      <span>Contacter le confrère</span>
    </button>
  }

  @if (options.enabledActions.createBonvisite) {
    <button type="button" class="tw-dropdown-link" (click)="createBonvisite()">
      <fa-icon [icon]="['fal', 'file-alt']"/><span>Créer un bon de visite</span>
    </button>
  }

  @if (options.enabledActions.createOffreachat) {
    <button type="button" class="tw-dropdown-link" (click)="createOffreachat()">
      <fa-icon [icon]="['fal', 'file-invoice-dollar']"/><span>Créer une offre d'achat</span>
    </button>
  }

  @if (options.enabledActions.see) {
    <button type="button" class="tw-dropdown-link" (click)="see()"><fa-icon [icon]="'eye'"/><span>Voir</span></button>
  }

  @if (options.enabledActions.seeMatchedDemandeurs) {
    <button type="button" class="tw-dropdown-link" (click)="seeMatchedDemandeurs()">
      <fa-icon [icon]="['fal', 'user']"/><span>Voir les demandeurs correspondants</span>
    </button>
  }

  @if (options.enabledActions.manageDiffusions) {
    <button type="button" class="tw-dropdown-link" (click)="manageDiffusions()">
      <fa-icon [icon]="['fal', 'rss']"/><span>Gérer les diffusions</span>
    </button>
  }

  @if (options.enabledActions.print) {
    <button type="button" class="tw-dropdown-link" (click)="print()">
      <fa-icon [icon]="['fal', 'print']"/><span>Imprimer</span>
    </button>
  }

  @if (options.enabledActions.printAffichettes) {
    <button type="button" class="tw-dropdown-link" (click)="printAffichettes()">
      <fa-icon [icon]="['fal', 'print']"/><span>Imprimer des affichettes</span>
    </button>
  }

  @if (options.enabledActions.advancedPrint) {
    <button type="button" class="tw-dropdown-link" (click)="advancedPrint()">
      <fa-icon [icon]="['fal', 'print-magnifying-glass']"/>
      <span>Imprimer <span class="tw-text-xs tw-opacity-75">(mode avancé)</span></span>
    </button>
  }

  @if (options.enabledActions.update) {
    <button type="button" class="tw-dropdown-link" (click)="update()">
      <fa-icon [icon]="['fal', 'edit']"/><span>Modifier</span>
    </button>
  }

  @if (options.enabledActions.unlock) {
    <button type="button" class="tw-dropdown-link" (click)="unlock()">
      <fa-icon [icon]="['fal', 'lock']"/><span>Voir les informations confidentielles</span>
    </button>
  }
</div>
