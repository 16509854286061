import {Injectable} from '@angular/core';
import {IDynamicComponentLoading} from '@shared/shared.interfaces';
import {Subject, Observable} from 'rxjs';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';

@Injectable({providedIn: 'root'})
export class DropdownService {
    private _openDynamicComponentLoadingSource = new Subject<[HTMLElement, IDynamicComponentLoading]>();
    private _clickedSource = new Subject<IDropdownClicked>();
    private _clicked$ = this._clickedSource.asObservable();

    get clicked$(): Observable<IDropdownClicked> {
        return this._clicked$;
    }

    get openElementDynamicComponentLoading$(): Observable<[HTMLElement, IDynamicComponentLoading]> {
        return this._openDynamicComponentLoadingSource.asObservable();
    }

    clicked(action: string, value?: unknown): void {
        this._clickedSource.next({action, value});
    }

    open(htmlSourceElement: HTMLElement, dynamicComponentLoading: IDynamicComponentLoading): void {
        this._openDynamicComponentLoadingSource.next([htmlSourceElement, dynamicComponentLoading]);
    }
}
