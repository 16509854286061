import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewViabilisationService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _isAssainissementVisible!: boolean;
    private _isCommentsViabilisationVisible!: boolean;
    private _isRaccordementsVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isViabilisableVisible!: boolean;
    private _raccordements!: DictionaryItem[];

    get isAssainissementVisible(): boolean {
        return this._isAssainissementVisible;
    }

    get isCommentsViabilisationVisible(): boolean {
        return this._isCommentsViabilisationVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isRaccordementsVisible(): boolean {
        return this._isRaccordementsVisible;
    }

    get isViabilisableVisible(): boolean {
        return this._isViabilisableVisible;
    }

    get raccordements(): DictionaryItem[] {
        return this._raccordements;
    }

    init(dossierBien: ADossierBien): void {
        this.initRaccordements(dossierBien);
        this._isAssainissementVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.aucunAssainissement)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.assainissementNonConforme)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.viabilisationAssainissement)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.assainissements);
        this._isCommentsViabilisationVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.viabilisationComments);
        this._isRaccordementsVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.raccordements)
            && this._raccordements.length > 0;
        this._isViabilisableVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.viabilisable);

        this._isPartVisible = this.isAssainissementVisible || this.isCommentsViabilisationVisible
            || this.isRaccordementsVisible || this.isViabilisableVisible;
    }

    initRaccordements(dossierBien: ADossierBien): void {
        this._raccordements = dossierBien.bien.raccordements.filter(raccordement => !this._dossierBienOverviewMainInfosService.getByCode(raccordement.code));
    }
}
