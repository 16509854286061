import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
    CEstimationOnsaleReferencesFactory
} from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.factory';
import CEstimationOnsaleReferences
    from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.model';

@Injectable({providedIn: 'root'})
export class CEstimationOnsaleReferencesService {
    private _cEstimationOnsaleReferencesFactory = inject(CEstimationOnsaleReferencesFactory);

    addNext$(cEstimationOnsaleReferences: CEstimationOnsaleReferences): Observable<CEstimationOnsaleReferences> {
        return this._cEstimationOnsaleReferencesFactory.getNext$(cEstimationOnsaleReferences).pipe(
            tap(newCEstimationOnsaleReferences => cEstimationOnsaleReferences.updateNext(newCEstimationOnsaleReferences)),
            map(_ => cEstimationOnsaleReferences),
        );
    }
}
