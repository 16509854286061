import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    IEtudeDiffusionsQueryParameters, IEtudeDiffusionsWalletApi
} from '@models/etudes/etude/diffusions/etude-diffusions.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeDiffusionsApiService {
    private _modelApiService = inject(ModelApiService);

    getWalletEtat$(queryParameters?: IEtudeDiffusionsQueryParameters): Observable<IEtudeDiffusionsWalletApi> {
        return this._modelApiService.get$(this._modelApiService.pathWithQueryParameters('/toolbox/diffusions-passerelles/etude-wallet-etat', queryParameters));
    }
}
