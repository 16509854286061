import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {UserService} from '@models/users/user/user.service';
import {switchMap, take, tap} from 'rxjs/operators';
import {MeteoService} from '@core/meteo/meteo.service';

export default function getEskMeteoWidget(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-meteo-widget></esk-meteo-widget>
         */
        module.component('eskMeteoWidget', {
            templateUrl: 'src/app/legacy/templates/eskimmo/components/meteo-widget.html',
            controller: Controller
        });

        /**
         * Controller for eskMeteoWidget
         *
         * @param Ng2UserService
         * @param Ng2MeteoService
         */
        Controller.$inject = ['Ng2UserService', 'Ng2MeteoService'];
        function Controller(this: any, ng2UserService: UserService, ng2MeteoService: MeteoService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.loading = true;
                ng2UserService.getCurrentCommune$().pipe(
                    tap(commune => $ctrl.commune = commune),
                    switchMap(commune => ng2MeteoService.get$(commune)),
                    tap(meteo => $ctrl.meteo = meteo),
                    take(1),
                ).subscribe({complete: () => $ctrl.loading = false});
            }
        }
    })(angularJS);
}
