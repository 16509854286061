export default `
<div class="row">
  <div class="col-xs-12 col-sm-7">{{"Prix honoraires de négociation inclus" | translate}}</div>

  <div class="col-xs-12 col-sm-5 text-right">
    {{$ctrl.dossier._esk.infosPricing.prixHNI | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixHNI % 1 !== 0)}}
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-7">{{"Frais d’acte" | translate}}</div>

  <div class="col-xs-12 col-sm-5 text-right">
    <div class="border-bottom">
      {{$ctrl.dossier._esk.infosPricing.fraisActe |
      currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-7">{{"Prix tous frais compris" | translate}}</div>

  <div class="col-xs-12 col-sm-5 text-right">
    {{$ctrl.dossier._esk.infosPricing.prixTFC | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixTFC % 1 !== 0)}}
  </div>
</div>
`;
