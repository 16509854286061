import angularJS from '@shared/angularJS/global.ng';
import {IIntervalService, IModule, IPromise} from 'angular';
import {NgContactManager} from '@legacy/app/managers/managers';

export default function getContactsEmailAddress(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('ContactsEmailAddress', Service);

        /**
         * ContactsEmailAddress
         *
         * @param ContactManager
         * @param $interval
         * @returns {ContactsEmailAddress}
         */
        Service.$inject = ['ContactManager', '$interval'];
        function Service(this: any, contactManager: NgContactManager, $interval: IIntervalService) {
            const self = this;
            let nbInvalid = 0;
            let refreshInvalidInterval: IPromise<unknown>;

            self.getNbInvalid = getNbInvalid;
            self.refreshInvalid = refreshInvalid;
            self.launchRefreshInvalid = launchRefreshInvalid;
            self.stopRefreshInvalid = stopRefreshInvalid;

            /**
             * Refresh contacts with invalid email address
             */
            function refreshInvalid() {
                return contactManager.getAllForEtude({limit: 1, invalidEmailAddress: true}).then(function (contacts) {
                    nbInvalid = contacts.totalItems;
                });
            }

            /**
             * Get contacts with invalid email address
             */
            function getNbInvalid() {
                return nbInvalid;
            }

            /**
             * Launch refreshInvalid
             */
            function launchRefreshInvalid() {
                if (angular.isDefined(refreshInvalidInterval)) self.stopRefreshInvalid();

                self.refreshInvalid();
                refreshInvalidInterval = $interval(self.refreshInvalid, 300000);
            }

            /**
             * Stop refreshInvalid
             */
            function stopRefreshInvalid() {
                $interval.cancel(refreshInvalidInterval);
                refreshInvalidInterval = undefined!;
            }
        }
    })(angularJS);
}
