import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Router} from '@angular/router';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {NgForm} from '@angular/forms';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {IProcedureSignatairesEditOptions} from '@features/procedures/procedures.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {
    ProcedureSignatairesEditComponent
} from '@features/procedures/procedure/signataires/edit/procedure-signataires.edit.component';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {
    OffreachatSignActionsMainComponent
} from '@features/offreachats/item/actions/sign/offreachat-sign.actions-main.component';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {ToasterService} from '@shared/toaster/toaster.service';
import {UserService} from '@models/users/user/user.service';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {
    AppLayoutOffreachatSignatureComponent
} from '@core/routing/layout/offreachats/offreachat/signature/layout.offreachat-signature.component';
import {
    AppOffreachatSignatureEditComponent
} from '@features/offreachats/item/signature/edit/offreachat.signature-edit.component';
import {MediaResumeService} from '@models/medias/media/media-resume.service';

@Component({selector: 'layout-offreachat-sign', templateUrl: 'layout.offreachat-sign.component.html'})
/**
 * @deprecated, Utiliser AppLayoutOffreachatSignatureComponent
 */
export class AppLayoutOffreachatSignComponent implements OnDestroy, OnInit {
    @ViewChild('signatairesForm') signatairesForm!: NgForm;
    @ViewChild('signatairesButtonSubmit') signatairesButtonSubmit!: ElementRef<HTMLButtonElement>;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: AppOffreachatSignatureEditComponent.errorMessages.OTHER,
        SIGN_DISABLED: AppLayoutOffreachatSignatureComponent.messages.SIGN_DISABLED,
        TITLE: AppOffreachatSignatureEditComponent.errorMessages.TITLE,
    };
    static readonly messages = {
        signataires: {
            NO_ONE: ProcedureSignatairesEditComponent.messages.NO_SIGNATAIRES,
            TITLE: 'Saisie des signataires',
        },
    };
    private _callToActionService = inject(CallToActionService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _dossierService = inject(DossierService);
    private _mediaResumeService = inject(MediaResumeService);
    private _modalService = inject(ModalService);
    private _offreachatService = inject(OffreachatService);
    private _procedureService = inject(ProcedureService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _userService = inject(UserService);
    private _venteFactory = inject(VenteFactory);
    private _offreachatsRoot!: string;
    private _cProcedureSignatairesSource = new BehaviorSubject<CProcedureSignataires>(undefined!);
    private _cProcedureSignataires$ = this._cProcedureSignatairesSource.asObservable();
    private _currentDossier!: ADossier;
    private _deletedSignataires!: ProcedureSignataire[];
    private _editedSignataires!: ProcedureSignataire[];
    private _fromSaveCurrent = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _procedureSource = new BehaviorSubject<Procedure>(undefined!);
    private _procedure$ = this._procedureSource.asObservable();
    private _procedureSignatairesEditOptions!: IProcedureSignatairesEditOptions;
    private _signatairesFormSource = new ReplaySubject<void>(1);

    get cProcedureSignataires$(): Observable<CProcedureSignataires> {
        return this._cProcedureSignataires$;
    }

    get currentDossier(): ADossier {
        return this._currentDossier;
    }

    set deletedSignataires(value: ProcedureSignataire[]) {
        this._deletedSignataires = value;
    }

    set editedSignataires(value: ProcedureSignataire[]) {
        this._editedSignataires = value;
    }

    get procedure$(): Observable<Procedure> {
        return this._procedure$;
    }

    get procedureSignatairesEditOptions(): IProcedureSignatairesEditOptions {
        return this._procedureSignatairesEditOptions;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        this._offreachatsRoot = '/app/offres-achat';
        if (!this._router.url.startsWith('/app/offres-achat')) {
            this._offreachatsRoot = this._router.url.split('/offres-achat')[0] + '/offres-achat';
            fallbackPath = this._offreachatsRoot + '/portefeuille';
        }

        this._offreachatService.current$.pipe(
            filter(offreachat => !!offreachat),
            switchMap(offreachat => combineLatest([this._userService.last$, of(offreachat)])),
            take(1),
        ).subscribe(([currentUser, offreachat]) => {
            if (!currentUser.hasRoleSignatureElectronique() || !offreachat.isSign()) {
                this._toasterService.warning(AppLayoutOffreachatSignComponent.errorMessages.SIGN_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this._offreachatService.current$.pipe(
            filter(offreachat => !!offreachat),
            switchMap(offreachat => this._offreachatService.getProcedure$(offreachat).pipe(
                tap(procedure => this._procedureService.initCurrent(procedure.linkSelf)),
                switchMap(_ => this._procedureService.current$),
                filter(procedure => !!procedure),
                tap(procedure => this._procedureSignatairesEditOptions = {
                    enabledActions: {
                        copyLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                        remove: procedure.isNewOrDraft(),
                        sendLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                    },
                    enabledEmail: procedure.isDistanciel(),
                    enabledPhone: procedure.isDistanciel(),
                    enabledSignatairesAdding: procedure.isNewOrDraft(),
                    enabledStatut: !procedure.isNewOrDraft(),
                }),
                tap(procedure => {
                    const fromDossier = this._router.url.includes(DossierTypesConst.DEMANDEUR) || this._router.url.includes(DossierTypesConst.VENTE);

                    this._callToActionService.setDynamicComponentLoading({
                        component: OffreachatSignActionsMainComponent,
                        data: {
                            offreachat,
                            options: {
                                archive: fromDossier,
                                cancelSign: true,
                                print: true,
                                save: fromDossier && procedure.isNewOrDraft(),
                                sign: procedure.isNewOrDraft(),
                            },
                        },
                    });
                }),
                tap(procedure => this._procedureSource.next(procedure)),
                tap(_ => this._currentDossier = this._dossierService.getCurrentFromNg(this._router.url)),
                switchMap(procedure => combineLatest([
                    this._demandeurFactory.getByLink$(offreachat.linkDemandeur),
                    this._venteFactory.getByLink$(offreachat.linkVente),
                ]).pipe(
                    switchMap(dossiers => this._procedureService.getCProcedureSignataires$(procedure, dossiers, {withResponsableDossier: false})),
                )),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(cProcedureSignataires => this._cProcedureSignatairesSource.next(cProcedureSignataires));
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.ARCHIVE) {
                    return this._callToActionService.actionExec$(this.archiveCurrent$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.CANCEL_SIGN) {
                    return this._callToActionService.actionExec$(this.cancelSign$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.SAVE) {
                    return this._callToActionService.actionExec$(this.save$());
                } else if (callToActionClicked.action === OffreachatSignActionsMainComponent.actions.SIGN) {
                    return this._callToActionService.actionExec$(this.sign$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    archiveCurrent$(): Observable<string> {
        return this._offreachatService.archiveCurrent$().pipe(
            switchMap(isArchived => this._offreachatService.current$.pipe(
                take(1),
                map(offreachat => isArchived ? this._offreachatsRoot + '/' + offreachat.uuid + '/resume' : undefined!))
            ),
        );
    }

    cancelSign$(): Observable<string> {
        return this.procedure$.pipe(
            take(1),
            switchMap(procedure => this._procedureService.cancel$(procedure)),
            switchMap(isCanceled => isCanceled ? this._offreachatService.updateCurrent$() : of(undefined)),
            map(offreachat => offreachat?.isArchive() ? this._offreachatsRoot + '/' + offreachat.uuid + '/resume' : undefined!)
        );
    }

    print$(): Observable<string> {
        return this._offreachatService.current$.pipe(
            switchMap(offreachat => this._mediaResumeService.readFromOffreachat$(offreachat)),
            map(_ => undefined!),
        );
    }

    save(saveHtmlButtonElement: HTMLButtonElement): void {
        saveHtmlButtonElement.focus({preventScroll: true});
        this._signatairesFormSource.next();
        if (!this._fromSaveCurrent) {
            this._callToActionService.clicked(OffreachatSignActionsMainComponent.actions.SAVE);
        }
    }

    save$(): Observable<string> {
        return this.saveCurrent$().pipe(map(_ => undefined!));
    }

    saveCurrent$(): Observable<void> {
        if (!this.signatairesForm.submitted) {
            this._fromSaveCurrent = true;
            setTimeout(() => this.signatairesButtonSubmit.nativeElement.click(), 1);
        }

        return this._signatairesFormSource.asObservable().pipe(
            switchMap(_ => {
                if (!this.signatairesForm.valid) {
                    return this._modalService.openInformation$({
                        comments: AppLayoutOffreachatSignComponent.errorMessages.OTHER,
                        title: AppLayoutOffreachatSignComponent.errorMessages.TITLE,
                        status: ModalService.status.WARNING,
                    });
                }

                return this._procedureService.saveCurrentSignataires$(this._deletedSignataires, this._editedSignataires);
            }),
            map(_ => undefined),
        );
    }

    sign$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(_ => this.procedure$),
            take(1),
            switchMap(procedure => this._procedureService.getCProcedureSignataires$(procedure)),
            switchMap(cProcedureSignataires => {
                if (cProcedureSignataires.total > 0) {
                    return this._procedureService.launchCurrent$();
                }

                return this._modalService.openInformation$({
                    comments: AppLayoutOffreachatSignComponent.messages.signataires.NO_ONE,
                    title: AppLayoutOffreachatSignComponent.messages.signataires.TITLE,
                    status: ModalService.status.WARNING,
                });
            }),
            map(_ => undefined!),
        );
    }

    signed(): void {
        this.procedure$.pipe(
            switchMap(procedure => procedure.isClosed() ? this._offreachatService.updateCurrent$() : of(undefined)),
            take(1),
            map(offreachat => offreachat ? this._offreachatsRoot + '/' + offreachat.uuid + '/resume' : undefined!),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }
}
