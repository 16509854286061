import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from "lodash";
import {IModule, IQService} from 'angular';
import {
    NgContactsGroupManager, NgContactsGroupMemberManager, NgDictionariesManager, NgManager, NgUtilsManager
} from '@legacy/app/managers/managers';
import {NgContact, NgContactsGroup, NgContactsGroupMember} from '@legacy/app/managers/ressources';
import ContactsGroupNg2 from '@models/contacts-group/contacts-group.model';
import {ReplaySubject} from 'rxjs';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';

export default function getManagersContactsGroup(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ContactsGroupManager', manager);

        /**
         * Manager contactsGroups
         *
         * @param BaseManager
         * @param UtilsManager
         * @param ContactsGroupMembersManager
         * @param DictionariesManager
         * @param $q
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'UtilsManager', 'ContactsGroupMemberManager', 'DictionariesManager', '$q', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         utilsManager: NgUtilsManager,
                         contactsGroupMemberManager: NgContactsGroupMemberManager,
                         dictionariesManager: NgDictionariesManager,
                         $q: IQService,
                         ng2DictionaryItemService: DictionaryItemService) {
            const classResource = baseManager.getClass<NgContactsGroup>();
            let contactsGroupManager: NgContactsGroupManager;

            /**
             * Routing
             */
            baseManager.addRoute('contacts-groups.cget', {path: '/etude/contacts-groups', method: 'GET'});
            baseManager.addRoute('contacts-groups.get', {path: '/contacts-groups/{id}', method: 'GET'});
            baseManager.addRoute('contacts-groups.insert', {path: '/user/contacts-groups', method: 'POST'});
            baseManager.addRoute('contacts-groups.edit', {path: '/contacts-groups/{id}', method: 'PUT'});

            /**
             * ContactsGroupManager object
             *
             * @constructor
             */
            angular.extend(ContactsGroupManager.prototype, baseManager.__proto__);
            ContactsGroupManager.prototype.eskManager = {prefixRoute: 'contacts-groups', collectionName: 'items'};
            function ContactsGroupManager(this: NgContactsGroupManager) {
                this.currentSource = new ReplaySubject<ContactsGroup>(1);
            }

            /**
             * Create a ContactsGroup object
             *
             * @param data
             * @returns manager.ContactsGroup object || Array of manager.ContactsGroup objects
             */
            ContactsGroupManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var contactsGroups = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        contactsGroups.push(new ContactsGroup(data[i]));
                    }

                    return contactsGroups;
                }

                // @ts-ignore
                return new ContactsGroup(data);
            };

            /**
             * Create a ContactsGroup object
             *
             * @param ng2ContactsGroup
             * @returns manager.ContactsGroup
             */
            ContactsGroupManager.prototype.createFromNg2 = function (ng2ContactsGroup: ContactsGroupNg2) {
                if (!ng2ContactsGroup) {
                    return $q.resolve(undefined!);
                }

                return $q.all((ng2ContactsGroup.members || []).map(member => contactsGroupMemberManager.createFromNg2(member))).then(members =>
                    this.create({
                        _links: {self: {href: ng2ContactsGroup.linkSelf}},
                        formuleAdressePostale: ng2ContactsGroup.formuleAdressePostale,
                        formuleIntroduction: ng2ContactsGroup.formuleIntroduction,
                        formulePolitesse: ng2ContactsGroup.formulePolitesse,
                        id: ng2ContactsGroup.id,
                        members,
                        nom: ng2ContactsGroup.nom,
                        typeGroup: dictionariesManager.createFromNg2(ng2ContactsGroup.typeGroup),
                        uuid: ng2ContactsGroup.uuid,
                    })
                );
            };

            /**
             * ContactsGroup object
             *
             * @param bienId
             * @param data
             * @constructor
             */
            angular.extend(ContactsGroup.prototype, classResource.prototype);
            ContactsGroup.prototype.eskManager = {prefixRoute: 'contacts-groups'};
            function ContactsGroup(this: NgContactsGroup, data: unknown) {
                this.typeGroup = dictionariesManager.createFromNg2(ng2DictionaryItemService.getById(Dictionary.names.CONTACTS_GROUP_TYPES, 2));
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Extend the existing ContactsGroup with new data
             *
             * @param data
             * @returns {ContactsGroup}
             */
            ContactsGroup.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isArray(this.members)) {
                    this.members = contactsGroupMemberManager.create(this.members, this.id);
                } else {
                    this.members = [];
                }

                if (!angular.isString(this.nom)) {
                    this.nom = ContactsGroupNg2.NO_NAME;
                }

                return this;
            };

            /**
             * Returns the promise of save
             *
             * @returns {Promise}
             */
            ContactsGroup.prototype.save = function () {
                const self = this;

                return classResource.prototype.save.call(self).then(function () {
                    return contactsGroupManager.getOneById(self.id).then(selfUpdated => {
                        angular.merge(self, selfUpdated);

                        return self;
                    });
                });
            };

            /**
             * Sanitize object ContactsGroup before send to API
             *
             * @returns {Object}
             */
            ContactsGroup.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                // Transformation des propriétés de l'objet ContactsGroup pour l'envoi à l'API
                utilsManager.flattenIdForAttribute(sanitizeObject, 'typeGroup');
                delete sanitizeObject.members;

                return sanitizeObject;
            };

            /**
             * Adding a member for the group (+ calling server)
             *
             * @param member
             * @returns {boolean}
             */
            ContactsGroup.prototype.addMember = function (member: NgContactsGroupMember) {
                const self = this;
                var promise = $q.resolve();

                if (!angular.isDefined(self.id)) {
                    if (self.nom === ContactsGroupNg2.NO_NAME) {
                        if (member.contact.type === 'person') {
                            self.nom = member.contact.person!.nom + ' ' + member.contact.person!.prenom;
                        } else if (member.contact.type === 'entreprise') {
                            self.nom = member.contact.entreprise!.convivialName;
                        }

                        self.nom = self.nom.substring(0, 140);
                    }

                    promise = self.save();
                }

                return promise.then(function () {
                    var memberCreated = contactsGroupMemberManager.create(member, self.id);

                    memberCreated.id = undefined!;
                    memberCreated._esk.persist = false;
                    memberCreated._esk.flush = false;

                    return memberCreated.save().then(function (memberCreated) {
                        self.members.push(memberCreated);

                        return memberCreated;
                    });
                });
            };

            /**
             * Returns index of the contact member if exists. If not, returns -1;
             *
             * @param contact
             * @returns {int}
             */
            ContactsGroup.prototype.hasContact = function (contact: NgContact) {
                return findIndex(this.members, (member: NgContactsGroupMember) => member.contact.id === contact.id);
            };

            /**
             * Return recipients
             *
             * @returns {Array}
             */
            ContactsGroup.prototype.getRecipients = function () {
                const recipients: { emailAddress: string, name: string, type: string }[] = [];

                angular.forEach(this.members, function (member) {
                    // @todo Utiliser Contact.getRecipients()
                    var representant = member.contact.representant;

                    if (angular.isString(member.contact[member.contact.type].emailAddress) && member.contact[member.contact.type].emailAddress !== '') {
                        recipients.push({
                            emailAddress: member.contact[member.contact.type].emailAddress,
                            type: recipients.length > 0 ? EmailRecipient.types.CC : EmailRecipient.types.TO,
                            name: member.contact[member.contact.type].convivialName
                        });
                    }

                    if (angular.isObject(representant) && angular.isString(representant[representant.type].emailAddress) && representant[representant.type].emailAddress !== '') {
                        recipients.push({
                            emailAddress: representant[representant.type].emailAddress,
                            type: recipients.length > 0 ? EmailRecipient.types.CC : EmailRecipient.types.TO,
                            name: representant[representant.type].convivialName
                        });
                    }
                });

                return recipients;
            };

            // @ts-ignore
            contactsGroupManager = new ContactsGroupManager();

            return contactsGroupManager;
        }
    })(angularJS);
}
