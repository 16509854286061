import {Component, Inject, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {IVenteDropdownData, IVentesListOptions} from '@features/ventes/ventes.interfaces';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {catchError, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {EstimationActionsMainComponent} from '@features/estimations/estimation/actions/estimation.actions-main.component';
import {NavigationBehaviorOptions, Router} from '@angular/router';
import {
    EstimationActionsSelectionComponent
} from '@features/estimations/estimation/actions/estimation.actions-selection.component';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {SearchCriteriaService} from '@models/search/criteria/search-criteria.service';
import {CVentesService} from '@models/ventes/collection/ventes.collection.service';
import {ConditionConst, SortConst, SortDefinition} from '@shared/constants';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {IWindowHistoryState} from '@shared/routes/routes.interfaces';
import {VenteDropdownComponent} from '@features/ventes/vente/dropdown/vente.dropdown.component';
import Vente from '@models/ventes/vente/vente.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    EstimationReferenceService
} from '@models/estimations/estimation/references/reference/estimation-reference.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import {IModel, IModelValidationError} from '@models/model.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {CReferencesService} from '@models/references/collection/references.collection.service';
import CReferences from '@models/references/collection/references.collection.model';
import {IDCReferenceData, IReferencesListOptions} from '@features/references/references.interfaces';
import {ReferenceDropdownComponent} from '@features/references/reference/dropdown/reference.dropdown.component';
import Reference from '@models/references/reference/reference.model';
import {HttpErrorResponse} from '@angular/common/http';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import {DCReferenceSlideOverComponent} from '@features/references/reference/slide-over/reference.slide-over.component';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {ReferencesService} from '@models/references/references.service';
import {DCVenteSlideOverComponent} from '@features/ventes/vente/slide-over/vente.slide-over.component';
import SearchCriteria from '@models/search/criteria/search-criteria.model';
import {SearchCriteriaSourceConst} from '@models/search/criteria/search-criteria.constants';
import {EstimationReferencesService} from '@models/estimations/estimation/references/estimation-references.service';

@Component({
    selector: 'layout-estimation-evaluation-references-list',
    templateUrl: 'layout-estimation-evaluation-references-list.component.html',
})
export class AppLayoutEstimationEvaluationReferencesListComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {httpResponseError: {TITLE: 'Recherche de références'}};
    static readonly INITIAL_TRIS = {
        DATE_VENTE: {archiveDateVente: SortConst.DESCENDING},
        DISTANCE: {distance: SortConst.ASCENDING},
    };
    static readonly REFERENCES_LIST_OPTIONS: IReferencesListOptions = {
        nameSelection: 'layout-estimation-evaluation-references-references-list-selection',
    };
    static readonly VENTES_LIST_OPTIONS: IVentesListOptions = {
        nameSelection: 'layout-estimation-evaluation-references-ventes-list-selection',
    };

    private _callToActionService = inject(CallToActionService);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cReferencesService = inject(CReferencesService);
    private _cVentesService = inject(CVentesService);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dropdownService = inject(DropdownService);
    private _estimationReferenceService = inject(EstimationReferenceService);
    private _estimationReferencesService = inject(EstimationReferencesService);
    private _estimationService = inject(EstimationService);
    private _modalService = inject(ModalService);
    private _referencesService = inject(ReferencesService);
    private _router = inject(Router);
    private _searchCriteriaService = inject(SearchCriteriaService);
    private _slideOverService = inject(SlideOverService);
    private _toasterService = inject(ToasterService);
    private _window: Window;
    private _searchCriteria!: SearchCriteria;
    private _cReferencesSource = new ReplaySubject<CReferences>();
    private _cReferences$ = this._cReferencesSource.asObservable();
    private _cVentesSource = new ReplaySubject<CVentes>();
    private _cVentes$ = this._cVentesSource.asObservable();
    private _errorCityscanLocalizable = false;
    private _estimation$!: Observable<Estimation>;
    private readonly _onDestroy$ = new Subject<void>();
    private _redirectionRoute!: string;
    private _referencesListOptions: IReferencesListOptions = {...AppLayoutEstimationEvaluationReferencesListComponent.REFERENCES_LIST_OPTIONS};
    private _ventesListOptions: IVentesListOptions = {...AppLayoutEstimationEvaluationReferencesListComponent.VENTES_LIST_OPTIONS};

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    get CALL_TO_ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get cReferences$(): Observable<CReferences> {
        return this._cReferences$;
    }

    get searchCriteria(): SearchCriteria {
        return this._searchCriteria;
    }

    get cVentes$(): Observable<CVentes> {
        return this._cVentes$;
    }

    get errorCityscanLocalizable(): boolean {
        return this._errorCityscanLocalizable;
    }

    get estimation$(): Observable<Estimation> {
        return this._estimation$;
    }

    get hasSelectedItems(): boolean {
        return this._collectionSelectionService.hasValues(this.referencesListOptions.nameSelection!) ||
            this._collectionSelectionService.hasValues(this.ventesListOptions.nameSelection!);
    }

    get redirectionRoute(): string {
        return this._redirectionRoute;
    }

    get referencesListOptions(): IReferencesListOptions {
        return this._referencesListOptions;
    }

    get ventesListOptions(): IVentesListOptions {
        return this._ventesListOptions;
    }

    ngOnInit(): void {
        this._estimation$ = of(this._estimationService.getCurrentFromNg());
        this._estimation$.pipe(
            tap(estimation => this._redirectionRoute = '/app/estimations/' + estimation.id.toString() + '/evaluation'),
            map(estimation => this._searchCriteriaService.getOneOrFromEstimation(this._redirectionRoute + '/references', estimation)),
            tap(criteria => this._searchCriteria = criteria),
            take(1),
        ).subscribe(_ => this.getCItems());
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === EstimationActionsMainComponent.actions.REFERENCES_CRITERIA) {
                    const criteriaRoute = this._redirectionRoute + '/references/criteres';

                    this._searchCriteriaService.save(criteriaRoute, this.searchCriteria);

                    return of({url: criteriaRoute});
                } else if (callToActionClicked.action === EstimationActionsSelectionComponent.actions.LINK) {
                    const listName = this.searchCriteria.isSourceCReferences() ?
                        AppLayoutEstimationEvaluationReferencesListComponent.REFERENCES_LIST_OPTIONS.nameSelection! :
                        AppLayoutEstimationEvaluationReferencesListComponent.VENTES_LIST_OPTIONS.nameSelection!;
                    const action$ = (items: Set<IModel>) => this.linkItems$.call(this, items);
                    const actionExec$ = this._collectionSelectionService.operateListSelected$(listName, action$);

                    return this._callToActionService.actionExec$(actionExec$).pipe(
                        map(_ => ({
                            params: {state: {extraParams: {addedReferences: true}}},
                            url: this.redirectionRoute
                        })),
                        catchError(_ => of(undefined)),
                    );
                }

                return of(undefined);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(data => {
            const {params, url} = (data ?? {}) as { params: NavigationBehaviorOptions, url: string };

            if (url) {
                this._router.navigateByUrl(url, params);
            }
        });
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => {
            const reference = (dropdownClicked.value as IDCReferenceData).reference!;
            const vente = (dropdownClicked.value as IVenteDropdownData).vente!;

            if (dropdownClicked.action === ReferenceDropdownComponent.actions.LINK) {
                this.linkReference(reference);
            } else if (dropdownClicked.action === ReferenceDropdownComponent.actions.SEE) {
                this._slideOverService.open$(DCReferenceSlideOverComponent, {reference}).pipe(take(1)).subscribe();
            } else if (dropdownClicked.action === VenteDropdownComponent.actions.LINK) {
                this.linkVente(vente);
            } else if (dropdownClicked.action === VenteDropdownComponent.actions.SEE) {
                this._slideOverService.open$(DCVenteSlideOverComponent, {link: vente.linkSelf}).pipe(take(1)).subscribe();
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    changedTri([name, sort]: [string, SortDefinition]): void {
        this.searchCriteria.setTri(name, sort, false);
        this.getCItems();
    }

    getCItems(): void {
        const linkReferences = (this._window.history.state as IWindowHistoryState)?.extraParams?.linkReferences as string[];
        let getCItems$: Observable<unknown> = of(undefined);

        if (linkReferences) {
            this._cReferencesSource.next(undefined!);
            this.searchCriteria.source = SearchCriteriaSourceConst.REFERENCE;
            getCItems$ = this._referencesService.getCollectionFromLinks$(linkReferences).pipe(
                map(cReferences => this._cReferencesSource.next(cReferences)),
            );
        } else if (this.searchCriteria.isSourceCReferences()) {
            let getCReferences$: Observable<CReferences>;

            this._cReferencesSource.next(undefined!);
            this.searchCriteria.tris = this.searchCriteria.tris ?? (this.searchCriteria.isSourceCityscan() ?
                    AppLayoutEstimationEvaluationReferencesListComponent.INITIAL_TRIS.DISTANCE :
                    AppLayoutEstimationEvaluationReferencesListComponent.INITIAL_TRIS.DATE_VENTE
            );
            this._referencesListOptions.enabledColumnDistance = this.searchCriteria.isSourceCityscan();
            if (this.searchCriteria.isSourceCityscan()) {
                getCReferences$ = this._estimation$.pipe(
                    switchMap(estimation => this._cReferencesService.getByEstimationAndSearchCriteria$(estimation, this.searchCriteria)),
                );
            } else {
                getCReferences$ = this._cReferencesService.getBySearchCriteria$(this.searchCriteria);
            }

            getCItems$ = getCReferences$.pipe(
                tap(cReferences => this._cReferencesSource.next(cReferences)),
                catchError((httpErrorResponse: HttpErrorResponse): Observable<void> => {
                    const error = httpErrorResponse?.error as { type?: string };

                    if (httpErrorResponse.status !== 400 && error?.type) {
                        return throwError(() => httpErrorResponse);
                    }

                    const errorType = error.type!;

                    if (!errorType.includes(EtudeSettingsCityscan.REQUEST_ERRORS.cityscan_not_localizable)) {
                        return throwError(() => httpErrorResponse);
                    }

                    this._errorCityscanLocalizable = true;

                    return of(undefined);
                }),
            );
        } else if (this.searchCriteria.isSourceCVentes()) {
            this._cVentesSource.next(undefined!);
            this.searchCriteria.archiveTypes = [this._dictionaryItemService.getByCode(Dictionary.names.VENTE_ARCHIVE_TYPES, Vente.archiveTypes.RETIREE)];
            this.searchCriteria.statuts = [this._dictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, Vente.statuts.ARCHIVE)];
            this.searchCriteria.tris = this.searchCriteria.tris ?? AppLayoutEstimationEvaluationReferencesListComponent.INITIAL_TRIS.DATE_VENTE;
            getCItems$ = this._cVentesService.getBySearchCriteria$(this.searchCriteria, {venteArchiveTypeCondition: ConditionConst.NOT_IN}).pipe(
                tap(cVentes => this._cVentesSource.next(cVentes)),
            );
        }

        getCItems$.pipe(
            tap(_ => {
                this._referencesListOptions.tris = this.searchCriteria.tris;
                this._ventesListOptions.tris = this.searchCriteria.tris;
                if (this.searchCriteria.natures.length === 1) {
                    this._referencesListOptions.mainNature = this.searchCriteria.natures[0];
                    this._ventesListOptions.mainNature = this.searchCriteria.natures[0];
                }
            }),
            take(1),
        ).subscribe({
            error: (httpErrorResponse: HttpErrorResponse) =>
                this._toasterService.error(AppLayoutEstimationEvaluationReferencesListComponent.messages.httpResponseError.TITLE, httpErrorResponse.message),
        });
    }

    linkItems$(items: Set<IModel>): Observable<void> {
        return this._estimation$.pipe(
            switchMap(estimation => {
                if (this.searchCriteria.isSourceCReferences()) {
                    const references = Array.from(items as Set<Reference>);

                    return this._estimationReferencesService.linkReferences$(estimation, references, this.searchCriteria.getEstimationReferenceSource()).pipe(
                        tap(_ => this._toasterService.success(EstimationReferencesService.TITLE_ADDED_ESTIMATION_REFERENCES, EstimationReferencesService.getMessageLinkReferencesAdded(references))),
                        catchError((error: IModelValidationError) => this._modalService.openInformation$({
                            comments: EstimationReferenceService.getMessageLinkReferenceAddedError(error),
                            title: EstimationReferenceService.TITLE_ADDED_ESTIMATION_REFERENCE,
                            status: ModalService.status.DANGER,
                        }).pipe(switchMap(_ => throwError(() => error)))),
                    );
                }

                if (this.searchCriteria.isSourceCVentes()) {
                    const ventes = Array.from(items as Set<Vente>);

                    return this._estimationReferencesService.linkVentes$(estimation, ventes).pipe(
                        tap(_ => this._toasterService.success(EstimationReferencesService.TITLE_ADDED_ESTIMATION_REFERENCES, EstimationReferencesService.getMessageLinkVentesAdded(ventes))),
                        catchError((error: IModelValidationError) => this._modalService.openInformation$({
                            comments: EstimationReferencesService.getMessageLinkVentesAddedError(ventes, error),
                            title: EstimationReferenceService.TITLE_ADDED_ESTIMATION_REFERENCE,
                            status: ModalService.status.DANGER,
                        }).pipe(switchMap(_ => throwError(() => error)))),
                    );
                }

                return throwError(() => '"' + this.searchCriteria.source + '" non implémenté');
            }),
            map(_ => undefined),
        );
    }

    linkReference(reference: Reference): void {
        this._estimation$.pipe(
            switchMap(estimation => this._estimationReferenceService.linkReference$(estimation, reference, this.searchCriteria.getEstimationReferenceSource())),
            take(1),
        ).subscribe({
            next: _ => this._toasterService.success(EstimationReferenceService.TITLE_ADDED_ESTIMATION_REFERENCE, EstimationReferenceService.getMessageLinkReferenceAdded()),
            error: (error: IModelValidationError) => this._modalService.openInformation$({
                comments: EstimationReferenceService.getMessageLinkReferenceAddedError(error),
                title: EstimationReferenceService.TITLE_ADDED_ESTIMATION_REFERENCE,
                status: ModalService.status.DANGER,
            }).pipe(take(1)).subscribe(),
        });
    }

    linkVente(vente: Vente): void {
        this._estimation$.pipe(
            switchMap(estimation => this._estimationReferenceService.linkVente$(estimation, vente)),
            take(1),
        ).subscribe({
            next: _ => this._toasterService.success(EstimationReferenceService.TITLE_ADDED_ESTIMATION_REFERENCE, EstimationReferenceService.getMessageLinkVenteAdded(vente)),
            error: (error: IModelValidationError) => this._modalService.openInformation$({
                comments: EstimationReferenceService.getMessageLinkVenteAddedError(vente, error),
                title: EstimationReferenceService.TITLE_ADDED_ESTIMATION_REFERENCE,
                status: ModalService.status.DANGER,
            }).pipe(take(1)).subscribe(),
        });
    }
}
