import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICTemplatesApi, ICTemplatesQueryParameters} from '@models/templates/collection/templates.collection.interfaces';
import {ITemplateApi} from '@models/templates/template/template.interfaces';

@Injectable({providedIn: 'root'})
export class TemplatesApiService {
    private _modelApiService = inject(ModelApiService);

    getByLink$(link: string): Observable<ITemplateApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICTemplatesQueryParameters): Observable<ICTemplatesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/templates', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICTemplatesApi> {
        return this._modelApiService.getCollection$(link);
    }
}
