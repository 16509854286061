import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {takeUntil} from 'rxjs/operators';
import {BonvisiteDropdownComponent} from '@features/bonvisites/item/dropdown/bonvisite.dropdown.component';
import {
    IBonvisiteActionsMainData, IBonvisiteActionsMainDataOptions, IBonvisiteDropdownDataOptions
} from '@features/bonvisites/bonvisites.interfaces';

@Component({selector: 'bonvisite-resume-actions-main', templateUrl: 'bonvisite-resume.actions-main.component.html'})
export class BonvisiteResumeActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = BonvisiteDropdownComponent.actions;
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private _bonvisite!: Bonvisite;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IBonvisiteActionsMainDataOptions = {};

    set data(value: IBonvisiteActionsMainData) {
        this._bonvisite = value.bonvisite;
        this._options = value.options!;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        const options: IBonvisiteDropdownDataOptions = {enabledActions: {print: true}};

        this._dropdownService.open(htmlButtonElement, {
            component: BonvisiteDropdownComponent,
            data: {bonvisite: this._bonvisite, options},
        });
    }

    send(): void {
        this._callToActionService.clicked(BonvisiteResumeActionsMainComponent.actions.SEND, this._bonvisite);
    }
}
