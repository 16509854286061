import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({
    selector: 'app-ng-ventes-network-updated-criteria',
    template: '<div appNgVentesNetworkUpdatedCriteria></div>',
})
export class NgVentesNetworkUpdatedCriteriaComponent {
}

@Directive({selector: '[appNgVentesNetworkUpdatedCriteria]'})
export class NgVentesNetworkUpdatedCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVentesNetworkUpdatedCriteriaCtrl', elementRef, injector);
    }
}
