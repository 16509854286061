import {inject, Injectable} from '@angular/core';
import {VenteService} from '@models/ventes/vente/vente.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {DemandeurService} from '@models/demandeurs/demandeur/demandeur.service';
import {LocationService} from '@models/locations/location/location.service';
import {NgBienDossier, NgDemandeur} from '@legacy/app/managers/ressources';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import {
    NgDemandeurManager, NgEstimationManager, NgLocationManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {from, Observable, of} from 'rxjs';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import { EstimationFactory } from '@core/models/estimations/estimation/estimation.factory';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';

@Injectable({providedIn: 'root'})
export class DossierService {
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurService = inject(DemandeurService);
    private _estimationFactory = inject(EstimationFactory);
    private _estimationService = inject(EstimationService);
    private _locationFactory = inject(LocationFactory);
    private _locationService = inject(LocationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _venteFactory = inject(VenteFactory);
    private _venteService = inject(VenteService);

    // Supprimer les injections des anciens manager
    private get ngDemandeurManager(): NgDemandeurManager {
        return this._ngInjectorService.getService('DemandeurManager');
    }

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService<NgEstimationManager>('EstimationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    getByLink$(link?: string): Observable<ADossier> {
        if (link?.includes(DossierTypesConst.DEMANDEUR)) {
            return this._demandeurFactory.getByLink$(link);
        } else if (link?.includes(DossierTypesConst.ESTIMATION)) {
            return this._estimationFactory.getByLink$(link);
        } else if (link?.includes(DossierTypesConst.LOCATION)) {
            return this._locationFactory.getByLink$(link);
        } else if (link?.includes(DossierTypesConst.VENTE)) {
            return this._venteFactory.getByLink$(link);
        }

        return of(undefined as unknown as ADossier);
    }

    getCurrentFromNg(currentUrl = ''): ADossier {
        if (currentUrl.includes(DossierTypesConst.DEMANDEUR)) {
            return this._demandeurService.getCurrentFromNg();
        } else if (currentUrl.includes(DossierTypesConst.ESTIMATION)) {
            return this._estimationService.getCurrentFromNg();
        } else if (currentUrl.includes(DossierTypesConst.LOCATION)) {
            return this._locationService.getCurrentFromNg();
        } else if (currentUrl.includes(DossierTypesConst.VENTE)) {
            return this._venteService.getCurrentFromNg();
        }

        return undefined!;
    }

    getNgDossier$(dossier: ADossier): Observable<NgDemandeur | NgBienDossier> {
        if (dossier.isDemandeur()) {
            return from(this.ngDemandeurManager.createFromNg2(dossier as Demandeur));
        } else if (dossier.isEstimation()) {
            return from(this.ngEstimationManager.createFromNg2(dossier as Estimation));
        } else if (dossier.isLocation()) {
            return from(this.ngLocationManager.createFromNg2(dossier as Location));
        } else if (dossier.isVente()) {
            return from(this.ngVenteManager.createFromNg2(dossier as Vente));
        }

        return of(undefined as unknown as NgDemandeur);
    }
}
