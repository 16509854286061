export default `
<div>
  Cette référence est envoyée aux sites Internet qui le proposent.
  <br>
  "{{$ctrl.currentEtude.crpcen}}-" est obligatoire au début de la référence.
  <br>
  Caractères autorisés :
  <ul>
    <li>Chiffres de 0 à 9</li>
    <li>Lettres minuscules et majuscules</li>
    <li>_ / . -</li>
  </ul>
</div>
`;
