import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import {delay, Subject} from 'rxjs';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEstimationLabelValeur(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimation-label-valeur></esk-estimation-label-valeur>
         */
        module.component('eskEstimationLabelValeur', {
            bindings: {estimation: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/item/label/valeur.html'
        });

        /**
         * Controller
         */
        Controller.$inject = ['EstimationManager'];
        function Controller(this: any, estimationManager: NgEstimationManager) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();

            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.$onInit = $onInit;
            $ctrl.ESTIMATION_TYPES = Estimation.types;
            $ctrl.ESTIMATION_VALEUR_VENALE_TYPES = Estimation.valeurVenaleTypes;

            /**
             * Initialization method
             */
            function $onInit() {
                // Écoute du current$ pour mettre à jour en fonction de la saisie du prix dans le current
                estimationManager.current$.pipe(startWith(undefined), tap(_ => $ctrl.loader = true), delay(1), takeUntil(_onDestroy$)).subscribe(() => {
                    $ctrl.pourcentageValeurVenale = +($ctrl.estimation.pourcentageValeurVenale?.toString().replace(',', '.'));
                    $ctrl.valeurVenale = +($ctrl.estimation.valeurVenale?.toString().replace(',', '.'));
                    $ctrl.valeurVenaleMax = +($ctrl.estimation.valeurVenaleMax?.toString().replace(',', '.'));
                    $ctrl.valeurVenaleMin = +($ctrl.estimation.valeurVenaleMin?.toString().replace(',', '.'));
                    $ctrl.loader = false;
                });
            }
        }
    })(angularJS);
}
