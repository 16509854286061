export default `
<div class="container-fluid container-fullw tw-bg-white">
  <div class="row">
    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.duplicate()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'copy', action_icon_transform: 'shrink-36 up-26 right-29', main_icon_name: 'home'}">
              </app-fa-shortcut>

              <h4>Dupliquer</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2"
         title="{{$ctrl.hasRoleLocation ? '' : 'Vous n\\'avez pas le module Location.'}}">
      <div class="shortcut disabled:tw-disabled" ng-disabled="!$ctrl.hasRoleLocation">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createLocation()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'arrow-right-arrow-left', main_icon_name: 'key'}">
              </app-fa-shortcut>

              <h4>Transformer en location</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createEstimation()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'arrow-right-arrow-left', main_icon_name: 'chart-line'}">
              </app-fa-shortcut>

              <h4>Transformer en estimation</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ng-if="$ctrl.vente.type.code !== $ctrl.INTERACTIVE" class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center tw-relative">
          <div class="panel-body">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/bons-visite/' + $ctrl.BonvisiteStatutNEW + '/edit')">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'file-alt', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Créer un bon de visite</h4>
            </a>
          </div>

          <div class="tw-absolute tw-right-0 tw-bottom-0 tw-text-xs">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/bons-visite/portefeuille')"
               class="tw-block tw-p-2">Voir la liste</a>
          </div>
        </div>
      </div>
    </div>

    <div ng-if="$ctrl.vente.type.code !== $ctrl.INTERACTIVE" class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/offres-achat/' + $ctrl.OffreachatStatutNEW + '/edit')">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'file-invoice-dollar'}">
              </app-fa-shortcut>

              <h4>Créer une offre d'achat</h4>
            </a>
          </div>

          <div class="tw-absolute tw-right-0 tw-bottom-0 tw-text-xs">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/offres-achat/portefeuille')"
               class="tw-block tw-p-2">Voir la liste</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw-text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createEmail()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'envelope', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Envoyer un E-mail</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row lifetime">
    <div class="col-xs-12 col-sm-6 col-md-8">
      <div class="row">
        <!-- Demandeurs correspondants/potentiels -->
        <div class="col-xs-12 col-md-6">
          <div ng-if="$ctrl.vente.statut !== $ctrl.STATUTS.ARCHIVE" class="panel panel-white no-radius lifetime-matching-demandeurs">
            <div class="panel-heading border-bottom">
              <h4 class="panel-title text-bold">
                <fa-icon [icon]="'crosshairs'"></fa-icon>

                Demandeurs {{$ctrl.vente.statut === $ctrl.STATUTS.BROUILLON ? "potentiels" : "correspondants"}}
              </h4>
            </div>

            <div ng-switch="$ctrl.loader.demandeursCorrespondants" class="list-group">
              <p ng-switch-when="true" class="matching-demandeurs-loading">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
              </p>

              <div ng-switch-default>
                <div ng-if="$ctrl.showMatchingDemandeurs">
                  <div ng-repeat="venteMatching in $ctrl.cVenteMatchings.results" class="list-group-item">
                    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/correspondants')"
                       ng-if="$first">
                      <div class="row tw-text-gray-600 tw-my-1">
                        <div class="col-xs-11">
                          <span ng-if="!venteMatching.numberDemandeurs">Aucun demandeur dans votre portefeuille</span>

                          <span ng-if="venteMatching.numberDemandeurs > 0">
                            <span class="text-bold">
                              {{venteMatching.numberDemandeurs}} demandeur{{venteMatching.numberDemandeurs > 1 ? 's' : ''}}
                            </span>

                            dans votre portefeuille
                          </span>
                        </div>

                        <div class="col-xs-1"><fa-icon [icon]="'angle-right'"></fa-icon></div>
                      </div>
                    </a>

                    <div ng-if="!$first" class="row tw-text-gray-600 tw-my-1">
                      <div class="col-xs-12">
                        <span ng-if="!venteMatching.numberDemandeurs">Aucun demandeur dans les études du réseau</span>

                        <span ng-if="venteMatching.numberDemandeurs > 0" class="tw-flex">
                          <span class="text-bold">
                            {{venteMatching.numberDemandeurs}} demandeur{{venteMatching.numberDemandeurs > 1 ? 's' : ''}}
                          </span>

                           chez 

                          <app-etude-raison-sociale [link]="venteMatching.linkEtude" class="tw-flex-1">
                          </app-etude-raison-sociale>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div ng-if="!$ctrl.showMatchingDemandeurs">
                  <a ng-repeat="demandeur in $ctrl.demandeursCorrespondants.collection" class="list-group-item"
                     ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + demandeur.id.toString() + '/resultats')">
                    <div class="row">
                      <div class="col-xs-11">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="text-bold">
                              <fa-icon [icon]="'user'"></fa-icon> {{demandeur.contactsGroup.nom}} /
                            </span>

                            <span class="text-bold">{{demandeur.referenceInterne}}</span>

                            <span>{{demandeur.reference}}</span>
                          </div>

                          <div class="col-xs-12 pre-wrap" ng-bind-html="demandeur.recherches[0]._esk.resume"></div>
                        </div>
                      </div>

                      <div class="col-xs-1 margin-top-20 "><fa-icon [icon]="'angle-right'"></fa-icon></div>
                    </div>
                  </a>

                  <div ng-if="$ctrl.demandeursCorrespondants.totalItems <= 0" class="matching-demandeurs-null">
                    <p class="text-muted">
                      Aucun demandeur {{$ctrl.vente.statut === $ctrl.STATUTS.BROUILLON ? "potentiel" : "correspondant"}} à afficher
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div ng-if="$ctrl.demandeursCorrespondants.totalItems > 0" class="panel-footer">
              <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/correspondants')">
                <fa-icon [icon]="'arrow-circle-right'"></fa-icon>

                Tous les demandeurs {{$ctrl.vente.statut === $ctrl.STATUTS.BROUILLON ? "potentiels" : "correspondants"}}
              </a>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-md-6">
          <app-dossier-inot-exports-box [dossier]="$ctrl.ng2Vente"></app-dossier-inot-exports-box>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4">
      <!-- Compromis -->
      <div ng-if="$ctrl.vente.statut !== $ctrl.STATUTS.ARCHIVE" class="tw-w-full">
        <div class="panel panel-white no-radius">
          <div class="panel-heading border-bottom">
            <h4 class="panel-title text-bold"><fa-icon [icon]="['far', 'bookmark']"></fa-icon> Compromis</h4>
          </div>

          <ul class="list-group">
            <li class="list-group-item">
              <span ng-hide="$ctrl.vente.enabledCompromis">Le bien est actuellement en vente.</span>

              <span ng-show="$ctrl.vente.enabledCompromis">
                <fa-icon [icon]="'check'"></fa-icon> Compromis {{$ctrl.vente.enabledCompromis.type.tinyLabel}} signé le
                <app-ng2-date [date]="$ctrl.vente.enabledCompromis.date" format="shortDate"></app-ng2-date>
              </span>
            </li>
          </ul>

          <div class="panel-footer">
            <button type="button" ng-hide="$ctrl.vente.enabledCompromis" class="text-bold text-success" ng-click="$ctrl.compromis()">
              <fa-icon [icon]="'check'"></fa-icon> Mettre en compromis
            </button>

            <button type="button" ng-show="$ctrl.vente.enabledCompromis" class="text-bold text-warning" ng-click="$ctrl.compromis()">
              <fa-icon [icon]="'reply'" flip="vertical"></fa-icon> Remettre en négociation
            </button>
          </div>
        </div>
      </div>

      <!-- Archivage -->
      <div ng-if="$ctrl.vente.statut !== $ctrl.STATUTS.ARCHIVE" class="tw-w-full">
        <div class="panel panel-white no-radius">
          <div class="panel-heading border-bottom">
            <h4 class="panel-title text-bold"><fa-icon [icon]="'archive'"></fa-icon> Archivage</h4>
          </div>

          <div class="panel-body">
            <span>Ce bien en vente est actif. Il n'est pas encore archivé.</span>

            <p class="text-muted">
              Vous pouvez procéder à son archivage en cliquant sur le lien ci-dessous.<br>Il sera retiré de votre
              portefeuille de biens en vente.
            </p>
          </div>

          <div class="panel-footer">
            <button type="button" class="text-bold text-danger" ng-click="$ctrl.archive()">
              Archiver le bien en vente ...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-text-right">
    Bien créé le <app-ng2-date [date]="$ctrl.vente.bien.createdAt" format="shortDate"></app-ng2-date> |
    Dernière modification le <app-ng2-date [date]="$ctrl.vente.bien.updatedAt" format="shortDate"></app-ng2-date>
  </div>
</div>
`;
