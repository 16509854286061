import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getContactsEmailAddress from "@legacy/app/contacts/services/email-address";
import getContactResume from '@legacy/app/contacts/components/item/resume';
import getContactInitiales from '@legacy/app/contacts/components/item/initiales';
import getContactConvivialName from '@legacy/app/contacts/components/item/convivial-name';
import getContactsTemplates from '@legacy/app/contacts/templates';
import getContactsSearch from '@legacy/app/contacts/components/search';
import getContactEdit from '@legacy/app/contacts/components/item/edit';
import getContactEditEntreprise from '@legacy/app/contacts/components/item/edit/entreprise';
import getContactEditPerson from '@legacy/app/contacts/components/item/edit/person';
import getContactsList from '@legacy/app/contacts/components/list';
import getContactsWalletCurrentCtrl from '@legacy/app/contacts/controllers_ponents/wallet.current';
import getContactModalEditCtrl from '@legacy/app/contacts/controllers/modals/item.edit';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';

export default function getContacts(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.contacts', ['esk.managers', 'dndLists', 'soqrate', 'ui.select', 'ui.bootstrap'])
            .config(['SoqModalesManagerProvider', modales]);

        // Config / Run
        getContactsTemplates(module);

        // Services
        getContactsEmailAddress(module);

        // Components
        getContactConvivialName(module);
        getContactEdit(module);
        getContactEditEntreprise(module);
        getContactEditPerson(module);
        getContactInitiales(module);
        getContactsList(module);
        getContactResume(module);
        getContactsSearch(module);

        // Controllers
        getContactModalEditCtrl(module);
        getContactsWalletCurrentCtrl(module);

        return module;

        /**
         * Declare modales
         *
         * @param SoqModalesManagerProvider
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('ContactModalEdit', {
                templateUrl: 'src/app/legacy/templates/contacts/controllers/modals/item.edit.html',
                controller: 'ContactModalEditCtrl',
                size: 'lg',
            });
        }
    })(angularJS);
}
