import {Injectable} from '@angular/core';
import Participant from '@models/participants/participant/participant.model';
import User from '@models/users/user/user.model';
import Person from '@models/contacts/person/person.model';

@Injectable({providedIn: 'root'})
export class ParticipantFactory {
    create(data?: Person | User): Participant {
        if (data instanceof Person) {
            return this.createFromPerson(data);
        } else if (data instanceof User) {
            return this.createFromUser(data);
        }

        return this.createVirgin();
    }

    createFromPerson(person: Person): Participant {
        const participant = this.createVirgin();

        participant.data = person;
        participant.emailAddress = person.emailAddress;
        participant.nom = person.nom;
        participant.prenom = person.prenom;
        participant.telephone = person.telephone;

        return participant;
    }

    createFromUser(user: User): Participant {
        const participant = this.createVirgin();

        participant.data = user;
        participant.emailAddress = user.emailAddress;
        participant.nom = user.nom;
        participant.prenom = user.prenom;
        participant.telephone = user.getPhoneMobileFirst();

        return participant;
    }

    createVirgin(): Participant {
        return new Participant();
    }
}
