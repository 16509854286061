import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {RoutesService} from '@shared/routes/routes.service';

@Injectable({providedIn: 'root'})
export class GuardsService {
    // @todo Rechercher "/app/dashboard" et utiliser ce static
    static readonly DEFAULT_ROUTE = '/app/dashboard';
    static readonly REDIRECT_TO_PARENT = '..';
    static readonly REDIRECTION_ROUTE_DATA = 'redirection';
    private _router = inject(Router);
    private _routesService = inject(RoutesService);

    getRedirection(activatedRouteSnapshot: ActivatedRouteSnapshot, key?: string): string {
        if (!activatedRouteSnapshot.data) {
            return GuardsService.DEFAULT_ROUTE;
        }

        if (key && activatedRouteSnapshot.data[key]) {
            return activatedRouteSnapshot.data[key] as string;
        }

        if (activatedRouteSnapshot.data[GuardsService.REDIRECTION_ROUTE_DATA]) {
            return activatedRouteSnapshot.data[GuardsService.REDIRECTION_ROUTE_DATA] as string;
        }

        return GuardsService.DEFAULT_ROUTE;
    }

    redirect(activatedRouteSnapshot: ActivatedRouteSnapshot, key?: string): UrlTree {
        const redirectionPath = this.getRedirection(activatedRouteSnapshot, key);

        if (redirectionPath === GuardsService.REDIRECT_TO_PARENT) {
            const pathFromRootSplitted = this._routesService.pathFromRoot(activatedRouteSnapshot).split('/');

            pathFromRootSplitted.pop();

            return this._router.parseUrl(pathFromRootSplitted.join('/'));
        }

        return this._router.parseUrl(redirectionPath);
    }
}
