import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
    CVentePasserellesFactory
} from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.factory';
import CVentePasserelles from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.model';

@Injectable({providedIn: 'root'})
export class CVentePasserellesService {
    private _cVentePasserellesFactory = inject(CVentePasserellesFactory);

    addNext$(cVentePasserelles: CVentePasserelles): Observable<CVentePasserelles> {
        return this._cVentePasserellesFactory.getNext$(cVentePasserelles).pipe(
            tap(newCVentePasserelles => cVentePasserelles.updateNext(newCVentePasserelles)),
            map(_ => cVentePasserelles),
        );
    }
}
