export default `
<div class="details-item">
  <div class="row">
    <div class="col-sm-6 text-right">
      <h4 class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.bouquetHNI |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.bouquetHNI % 1 !== 0)}}
      </h4>

      <p>{{"Bouquet honoraires de négociation inclus" | translate}}</p>
    </div>

    <div class="col-sm-6">
      <h5 class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.fraisActe |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
      </h5>

      <p>{{"Frais d’acte" | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 text-right">
      <h4 class="text-bold">
        {{$ctrl.dossier._esk.infosPricing.bouquetTFC |
        currency:'€':2 * ($ctrl.dossier._esk.infosPricing.bouquetTFC % 1 !== 0)}}
      </h4>

      <p>{{"Bouquet tous frais compris" | translate}}</p>
    </div>

    <div class="col-sm-6">
      <h4 class="text-bold">
        {{$ctrl.dossier.viagerRente | currency:'€':2 * ($ctrl.dossier.viagerRente % 1 !== 0)}}
      </h4>

      <p>
        {{"Rente" | translate}}

        {{$ctrl.dossier.viagerPeriodiciteRente.label | lowercase}}

        <span ng-if="$ctrl.dossier.viagerIndexation">{{"indexée" | translate}}</span>
      </p>
    </div>
  </div>

  <div
    ng-if="!!$ctrl.dossier.viagerValeurBien || $ctrl.dossier.viagerValeurBien === 0 || !!$ctrl.dossier.viagerNombreTetes"
    class="row">
    <div ng-if="!!$ctrl.dossier.viagerValeurBien || $ctrl.dossier.viagerValeurBien === 0" class="col-sm-6 text-right">
      <h4 class="text-bold">
        {{$ctrl.dossier.viagerValeurBien | currency:'€':2 * ($ctrl.dossier.viagerValeurBien % 1 !== 0)}}
      </h4>

      <p>{{"Valeur du bien" | translate}}</p>
    </div>

    <div ng-if="!!$ctrl.dossier.viagerNombreTetes" class="col-sm-6">
      <div ng-repeat="credirentier in $ctrl.dossier.viagerTetes">
        <h5>
          <span class="text-bold">{{credirentier.sexe.label}}</span> de

          <span class="text-bold">{{credirentier.age}} ans</span>
        </h5>
      </div>
    </div>
  </div>
</div>
`;
