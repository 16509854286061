import {inject, Injectable} from '@angular/core';
import {IDossierFileApi} from '@models/dossiers/dossier/files/file/dossier-file.interfaces';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {Observable} from 'rxjs';
import {DossierFilesApiService} from '@models/dossiers/dossier/files/dossier-files.api.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import Contact from '@models/contacts/contact/contact.model';
import {map} from 'rxjs/operators';
import Media from '@models/medias/media/media.model';

@Injectable({providedIn: 'root'})
export class DossierFileFactory {
    private _dossierFilesApiService = inject(DossierFilesApiService);

    create(dossierFileApi: IDossierFileApi): DossierFile {
        const dossierFile = this.createVirgin(dossierFileApi.id.toString(), dossierFileApi.id);

        if (dossierFileApi._links) {
            if (dossierFileApi._links.contacts) {
                dossierFile.linkContacts = dossierFileApi._links.contacts.href;
            }

            if (dossierFileApi._links.media) {
                dossierFile.linkMedia = dossierFileApi._links.media.href;
            }
        }

        return dossierFile;
    }

    createFromMedia(media: Media): DossierFile {
        const dossierFile = this.createVirgin();

        dossierFile.media = media;

        return dossierFile;
    }

    createVirgin(uuid?: string, id?: number): DossierFile {
        return new DossierFile(uuid ?? DossierFile.statuts.NEW, id);
    }

    delete$(dossier: ADossier, dossierFile: DossierFile): Observable<void> {
        return this._dossierFilesApiService.delete$(dossier.dossierType, dossier.id.toString(), dossierFile.uuid);
    }

    forApi(dossierFile: DossierFile): IDossierFileApi {
        const dossierFileApi = {} as IDossierFileApi;

        if (dossierFile.media) {
            dossierFileApi.mediaId = dossierFile.media.id;
        }

        return dossierFileApi;
    }

    save$(dossier: ADossier, dossierFile: DossierFile): Observable<DossierFile> {
        return this._dossierFilesApiService.save$(dossier.dossierType, dossier.id.toString(), this.forApi(dossierFile)).pipe(map(dossierFileApi => this.create(dossierFileApi)));
    }

    share$(dossier: ADossier, dossierFile: DossierFile, contact: Contact): Observable<void> {
        return this._dossierFilesApiService.share$(dossier.dossierType, dossier.id.toString(), dossierFile.uuid, contact.id);
    }

    unshare$(dossier: ADossier, dossierFile: DossierFile, contact: Contact): Observable<void> {
        return this._dossierFilesApiService.unshare$(dossier.dossierType, dossier.id.toString(), dossierFile.uuid, contact.id);
    }
}
