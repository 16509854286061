import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {AppSignatureComponent} from '@shared/signature/signature.component';

@Component({selector: 'dc-procedure-signataire-sign', templateUrl: 'procedure-signataire.sign.component.html'})
export class DCProcedureSignataireSignComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _canValidate = false;
    private _isSignatureValidate = false;
    private _procedureSignataire!: ProcedureSignataire;
    private _signature!: string;

    get canValidate(): boolean {
        return this._canValidate;
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._procedureSignataire = dynamicComponentData.procedureSignataire as ProcedureSignataire;
    }

    get isSignatureValidate(): boolean {
        return this._isSignatureValidate;
    }

    set isSignatureValidate(value: boolean) {
        this._isSignatureValidate = value;
    }

    get procedureSignataire(): ProcedureSignataire {
        return this._procedureSignataire;
    }

    getSignature(signature: string): void {
        if (![AppSignatureComponent.DRAWING, AppSignatureComponent.NONE].includes(signature)) {
            this._canValidate = true;
            this._signature = signature;
        } else {
            this._canValidate = false;
            this._isSignatureValidate = false;
        }
    }

    sign(): void {
        this._modalService.setResponse(this._signature.split(',')[1]);
    }
}
