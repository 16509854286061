import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {
    ICActualitesApi, ICActualitesQueryParameters
} from '@models/actualites/collection/actualites.collection.interfaces';
import {IActualiteApi} from '@models/actualites/actualite/actualite.interfaces';
import {LocalStorageService} from '@core/storage/local-storage.service';

@Injectable({providedIn: 'root'})
export class ActualitesApiService {
    static readonly LOCAL_STORAGE_KEY = 'actualites';
    private _localStorageService = inject(LocalStorageService);
    private _modelApiService = inject(ModelApiService);

    getByLink$(link: string): Observable<IActualiteApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICActualitesQueryParameters): Observable<ICActualitesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/actualites', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICActualitesApi> {
        return this._modelApiService.getCollection$(link);
    }

    getPopupDatetime(): string {
        try {
            return this._localStorageService.get<{ popup: string }>(ActualitesApiService.LOCAL_STORAGE_KEY).popup;
        } catch (e) {
            return null!;
        }
    }

    read$(actualiteUuid: string): Observable<void> {
        return this._modelApiService.post$(`/actualites/${actualiteUuid}/read`);
    }

    savePopupDatetime(popupDatetime: string): void {
        this._localStorageService.set(ActualitesApiService.LOCAL_STORAGE_KEY, {popup: popupDatetime});
    }
}
