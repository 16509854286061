import AModel from '@models/model.abstract';
import Media from '@models/medias/media/media.model';

export default class DossierFile extends AModel {
    static readonly CONTENT_ALLOWED_TYPES = [
        Media.ACCEPTED_MIME_TYPES.applicationCdfv2Unknown,
        Media.ACCEPTED_MIME_TYPES.applicationMsoutlook,
        Media.ACCEPTED_MIME_TYPES.applicationMsword,
        Media.ACCEPTED_MIME_TYPES.applicationPdf,
        Media.ACCEPTED_MIME_TYPES.applicationVndMsExcel,
        Media.ACCEPTED_MIME_TYPES.applicationVndMsOutlook,
        Media.ACCEPTED_MIME_TYPES.applicationVndMsPowerpoint,
        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentGraphics,
        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentPresentation,
        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentSpreadsheet,
        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentText,
        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation,
        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet,
        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument,
        Media.ACCEPTED_MIME_TYPES.applicationXMsg,
        Media.ACCEPTED_MIME_TYPES.applicationXZipCompressed,
        Media.ACCEPTED_MIME_TYPES.applicationZip,
        Media.ACCEPTED_MIME_TYPES.imageBmp,
        Media.ACCEPTED_MIME_TYPES.imageGif,
        Media.ACCEPTED_MIME_TYPES.imageJpeg,
        Media.ACCEPTED_MIME_TYPES.imageJpg,
        Media.ACCEPTED_MIME_TYPES.imagePng,
        Media.ACCEPTED_MIME_TYPES.imageTiff,
        Media.ACCEPTED_MIME_TYPES.imageXBmp,
        Media.ACCEPTED_MIME_TYPES.imageXMsBmp,
        Media.ACCEPTED_MIME_TYPES.messageRfc822,
        Media.ACCEPTED_MIME_TYPES.textCsv,
        Media.ACCEPTED_MIME_TYPES.textHtml,
        Media.ACCEPTED_MIME_TYPES.textPlain,
        Media.ACCEPTED_MIME_TYPES.textRtf,
        Media.ACCEPTED_MIME_TYPES.videoMp4,
        Media.ACCEPTED_MIME_TYPES.videoMpeg,
        Media.ACCEPTED_MIME_TYPES.videoQuicktime,
        Media.ACCEPTED_MIME_TYPES.videoXFlv,
        Media.ACCEPTED_MIME_TYPES.videoXMsWmv,
        Media.ACCEPTED_MIME_TYPES.videoXMsVideo,
        Media.ACCEPTED_MIME_TYPES.videoWebm,
    ].map(mimeType => mimeType.mime_type).join(',');
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private readonly _id: number;
    private _linkContacts!: string;
    private _linkMedia!: string;
    private _media!: Media;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get id(): number {
        return this._id;
    }

    get linkContacts(): string {
        return this._linkContacts;
    }

    set linkContacts(value: string) {
        this._linkContacts = value;
    }

    get linkMedia(): string {
        return this._linkMedia;
    }

    set linkMedia(value: string) {
        this._linkMedia = value;
    }

    get media(): Media {
        return this._media;
    }

    set media(value: Media) {
        this._media = value;
    }
}
