export default `
<ui-select ng-required="{{$ctrl.ngRequired}}" backspace-reset="!$ctrl.ngRequired" ng-model="$ctrl.ngModel"
           on-select="$ctrl.options.onSelect($item, $model)" name="$ctrl.name">
  <ui-select-match placeholder="{{$ctrl.options.placeholder}}" allow-clear="{{!$ctrl.ngRequired}}">
    {{$select.selected.convivialName}}
  </ui-select-match>

  <ui-select-choices repeat="user in $ctrl.users | eskSelectUserFilter:$select.search:$ctrl.ngModel"
                     refresh="$ctrl.refresh($select.search)" refresh-delay="500" minimum-input-length="1">
    <span ng-bind-html="user.convivialName|highlight: $select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
