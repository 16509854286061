import {inject, Injectable} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgLocationManager} from '@legacy/app/managers/managers';
import {LocationFactory} from '@models/locations/location/location.factory';
import Location from '@models/locations/location/location.model';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {NgLocation} from '@legacy/app/managers/ressources';
import {BienService} from '@models/bien/bien.service';

@Injectable({providedIn: 'root'})
export class LocationService {
    private _bienService = inject(BienService);
    private _locationFactory = inject(LocationFactory);
    private _ngInjectorService = inject(NgInjectorService);
    private _needToBeSavedSource = new Subject<string>();
    private _needToBeSaved$ = this._needToBeSavedSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    get needToBeSaved$(): Observable<string> {
        return this._needToBeSaved$;
    }

    askToBeSaved(reason?: string): void {
        this._needToBeSavedSource.next(reason!);
    }

    getCurrentFromNg(): Location {
        if (!this.ngLocationManager.current) {
            return undefined!;
        }

        return this._locationFactory.ngCreate(this.ngLocationManager.current);
    }

    save$(location: Location): Observable<Location> {
        const isNew = location.isNew();

        return this._locationFactory.save$(location).pipe(switchMap(locationSaved => {
            if (!isNew) {
                return of(locationSaved);
            }

            return this._bienService.saveCollectionForNew$(location, locationSaved).pipe(switchMap(() => this._locationFactory.getByLink$(locationSaved.linkSelf)));
        }));
    }

    ngSave$(ngLocation: NgLocation): Observable<Location> {
        return this.save$(this._locationFactory.ngCreate(ngLocation));
    }
}
