import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {EtudeService} from '@models/etudes/etude/etude.service';

export default function getBiensWalletDisplayPhoto(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-biens-wallet-display-photo></esk-biens-wallet-display-photo>
         */
        module.component('eskBiensWalletDisplayPhoto', {
            bindings: {photoLink: '=', routing: '='},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/wallet.display.photo.html'
        });

        /**
         * Controller
         */
        Controller.$inject = ['Ng2EtudeService'];
        function Controller(this: any, ng2EtudeService: EtudeService) {
            const $ctrl = this;

            ng2EtudeService.last$.subscribe(etude => $ctrl.linkMarianne = etude.linkMarianne)
        }
    })(angularJS);
}
