import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AppBonvisiteEditComponent} from '@features/bonvisites/item/edit/bonvisite.edit.component';
import {SharedModule} from '@shared/shared.module';
import {DemandeursModule} from '@features/demandeurs/demandeurs.module';
import {ContactsModule} from '@features/contacts/contacts.module';
import {AppBonvisitesListComponent} from '@features/bonvisites/list/bonvisites.list.component';
import {AppBonvisiteStatutComponent} from '@features/bonvisites/item/statut/bonvisite.statut.component';
import {
    AppBonvisiteDossierbiensNumberComponent
} from '@features/bonvisites/item/dossier-biens-number/bonvisite.dossierbiens-number.component';
import {VentesModule} from '@features/ventes/ventes.module';
import {LocationsModule} from '@features/locations/locations.module';
import {
    BonvisiteEditActionsMainComponent
} from '@features/bonvisites/item/actions/edit/bonvisite-edit.actions-main.component';
import {BonvisitesActionsMainComponent} from '@features/bonvisites/list/actions/bonvisites.actions-main.component';
import {
    BonvisitesActionsMainDropdownComponent
} from '@features/bonvisites/list/actions/dropdown/bonvisites.actions-main-dropdown.component';
import {BonvisiteDropdownComponent} from '@features/bonvisites/item/dropdown/bonvisite.dropdown.component';
import {
    BonvisiteWriteActionsMainComponent
} from '@features/bonvisites/item/actions/write/bonvisite-write.actions-main.component';
import {
    BonvisiteSignActionsMainComponent
} from '@features/bonvisites/item/actions/sign/bonvisite-sign.actions-main.component';
import {
    BonvisiteResumeActionsMainComponent
} from '@features/bonvisites/item/actions/resume/bonvisite-resume.actions-main.component';
import {DossiersModule} from '@features/dossiers/dossiers.module';

@NgModule({
    declarations: [
        AppBonvisiteDossierbiensNumberComponent,
        AppBonvisiteEditComponent,
        AppBonvisitesListComponent,
        AppBonvisiteStatutComponent,
        BonvisiteDropdownComponent,
        BonvisiteEditActionsMainComponent,
        BonvisiteResumeActionsMainComponent,
        BonvisiteSignActionsMainComponent,
        BonvisiteWriteActionsMainComponent,
        BonvisitesActionsMainComponent,
        BonvisitesActionsMainDropdownComponent,
    ],
    exports: [AppBonvisiteEditComponent, AppBonvisitesListComponent, AppBonvisiteStatutComponent],
    imports: [
        CommonModule,
        ContactsModule,
        DemandeursModule,
        DossiersModule,
        LocationsModule,
        SharedModule,
        VentesModule,
    ],
})
export class BonvisitesModule {
}
