import angularJS from '@shared/angularJS/global.ng';
import {NgLocationManager} from '@legacy/app/managers/managers';
import {IModule} from 'angular';

export default function getLocationDiffusionCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-location-diffusion-ctrl></esk-location-diffusion-ctrl>
         */
        module.component('eskLocationDiffusionCtrl', {
            controller: Controller,
            template: '<esk-bien-diffusion dossier="$ctrl.location"></esk-bien-diffusion>',
        });

        /**
         * Diffusion of location
         *
         * @param LocationManager
         * @constructor
         */
        Controller.$inject = ['LocationManager'];
        function Controller(this: any, locationManager: NgLocationManager) {
            const $ctrl = this;

            $ctrl.location = locationManager.current;
        }
    })(angularJS);
}
