import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgCommune} from '@legacy/app/managers/ressources';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Person from '@models/contacts/person/person.model';

export default function getContactEditPerson(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact-edit-person></esk-contact-edit-person>
         */
        module.component('eskContactEditPerson', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/contacts/components/item/edit/person.html',
            bindings: {contact: '=', editForm: '=', editRepresentant: '='}
        });

        /**
         * Controller to edit person
         *
         * @param EskUtils
         */
        Controller.$inject = ['EskUtils'];
        function Controller(this: any, eskUtils: NgEskUtils) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectCommune = onSelectCommune;
            $ctrl.PERSON_TITRE_MONSIEUR = Person.titres.MONSIEUR;
            $ctrl.TITRES = Dictionary.names.TITRES;

            /**
             * Initialization method
             */
            function $onInit() {
            }

            /**
             * On commune selection
             *
             * @param commune
             */
            function onSelectCommune(commune: NgCommune) {
                eskUtils.setCodePostalFromWatchingCommune($ctrl.contact.person, commune, undefined!);
            }
        }
    })(angularJS);
}
