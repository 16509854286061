<div class="tw-shadow-sm tw-rounded-md">
  @for (item of list; track item; let first = $first; let last = $last) {
    <button type="button" (click)="onSelect(item)" [disabled]="item.disabled"
            [ngClass]="{'tw-rounded-l-md': first, '-tw-ml-px ': !first, 'tw-rounded-r-md': last,
            'tw-text-blue-700 tw-bg-blue-100 hover:tw-bg-blue-50': item.code === model,
            'tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50': item.code !== model}"
            class="tw-inline-flex tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-font-medium disabled:tw-disabled">
      {{ item.label }}
    </button>
  }
</div>
