import {inject, Injectable} from '@angular/core';
import {
    EtudeDossierQualifiedContactFactory
} from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.factory';
import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';

@Injectable({providedIn: 'root'})
export class EtudeDossierQualifiedContactsService {
    private _etudeDossierQualifiedContactFactory = inject(EtudeDossierQualifiedContactFactory);

    createFromContactsGroupMembers(contactsGroupMembers: ContactsGroupMember[], qualification: string): EtudeDossierQualifiedContact[] {
        return contactsGroupMembers.map(contactsGroupMember => this._etudeDossierQualifiedContactFactory.createFromContactsGroupMember(contactsGroupMember, qualification));
    }
}
