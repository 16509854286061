import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IEtudeSettingsCityscanApi} from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeSettingsCityscanApiService {
    private _modelApiService = inject(ModelApiService);

    get$(etudeUuid: string): Observable<IEtudeSettingsCityscanApi> {
        return this._modelApiService.get$('/etudes/' + etudeUuid + '/settings/cityscan');
    }
}
