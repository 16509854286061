import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import User from '@models/users/user/user.model';

export default function getEskModalImpersonationCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EskModalImpersonationCtrl', Controller);

        /**
         * Controller for archive modal
         *
         * @param $uibModalInstance
         * @param $translate
         * @param Ng2ToasterService
         * @constructor
         */
        Controller.$inject = ['$uibModalInstance', '$translate', 'Ng2ToasterService'];
        function Controller(this: any,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $translate: angular.translate.ITranslateService,
                            ng2ToasterService: ToasterService) {
            const $ctrl = this;

            $ctrl.impersonate = impersonate;
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];

            /**
             * Operate pricing
             */
            function impersonate() {
                if (!angular.isObject($ctrl.userImpersonate)) {
                    ng2ToasterService.warning($translate.instant('impersonate.selection.TITLE'), $translate.instant('impersonate.selection.MESSAGE'));

                    return;
                }

                $uibModalInstance.close($ctrl.userImpersonate.username);
            }
        }
    })(angularJS);
}
