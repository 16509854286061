import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import DateFormat from '@shared/date/date.format';

export default function getEskBoxNegociationRevenus(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-box-negociation-revenus></esk-box-negociation-revenus>
         */
        module.run(template).component('eskBoxNegociationRevenus', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/box/negociation-revenus.html'
        });

        /**
         * Display negociation revenus
         *
         * @param EskBox
         * @param Ng2RouterStateForNgService
         * @constructor
         */
        Controller.$inject = ['EskBox', 'Ng2RouterStateForNgService'];
        function Controller(this: any, eskBox: NgEskBox, ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.dateDebut = DateFormat.addDaysToDateString(DateFormat.addMonthsToDateString(DateFormat.dateFromNow(), -11), 1 - DateFormat.getDay(DateFormat.dateFromNow()));
            $ctrl.dateFin = DateFormat.toDate();
            $ctrl.box = eskBox.getOneByCode('etude-negociation-revenus');
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/eskimmo/components/box/negociation-revenus/container.html', '<esk-box-negociation-revenus></esk-box-negociation-revenus>');
        }
    })(angularJS);
}
