import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IReportApi} from '@models/reports/report/report.interfaces';
import {ModelApiService} from '@models/model.api.service';

@Injectable({providedIn: 'root'})
export class ReportsApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IReportApi> {
        return this._modelApiService.get$('/reports/' + uuid);
    }
}
