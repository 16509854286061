import {NgModule} from '@angular/core';
import {NoteoComponentsModule} from '@shared/angularJS/up-ng/soqrate/soqrate-components.module';
import {DemandeursComponentsModule} from '@shared/angularJS/up-ng/demandeurs/demandeurs-components.module';
import {BiensComponentsModule} from '@shared/angularJS/up-ng/biens/biens-components.module';
import {VentesComponentsModule} from '@shared/angularJS/up-ng/ventes/ventes-components.module';
import {LocationsComponentsModule} from '@shared/angularJS/up-ng/locations/locations-components.module';
import {EstimationsComponentsModule} from '@shared/angularJS/up-ng/estimations/estimations-components.module';
import {AuthComponentsModule} from '@shared/angularJS/up-ng/auth/auth-components.module';
import {EskimmoAppComponentsModule} from '@shared/angularJS/up-ng/eskimmo/eskimmo-app-components.module';
import {ContactsComponentsModule} from '@shared/angularJS/up-ng/contacts/contacts-components.module';

const modules = [
    AuthComponentsModule,
    BiensComponentsModule,
    ContactsComponentsModule,
    DemandeursComponentsModule,
    EskimmoAppComponentsModule,
    EstimationsComponentsModule,
    LocationsComponentsModule,
    NoteoComponentsModule,
    VentesComponentsModule,
];

@NgModule({exports: modules, imports: modules})
export class EskimmoComponentsModule {
}
