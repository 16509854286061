import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from 'lodash';
import {IModule} from 'angular';
import {NgUserManager} from '@legacy/app/managers/managers';
import {NgUser} from '@legacy/app/managers/ressources';
import {UserService} from '@models/users/user/user.service';
import {firstValueFrom} from 'rxjs';

export default function getEskSelectUser(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-select-user></esk-select-user>
         * @param options : {onSelect(function), placeholder(string)}
         */
        module.component('eskSelectUser', {
            bindings: {
                ngModel: '=',
                name: '@',
                roles: '=',
                etudes: '=',
                isRequired: '=',
                options: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/select/user.html'
        }).filter('eskSelectUserFilter', filter);

        /**
         * Select users
         *
         * @param userManager
         * @param Ng2UserService
         * @constructor
         */
        Controller.$inject = ['UserManager', 'Ng2UserService'];
        function Controller(this: any, userManager: NgUserManager, ng2UserService: UserService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            /**
             * Initialization method
             */
            function $onInit() {
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
                if (!angular.isString($ctrl.options.placeholder) || $ctrl.options.placeholder === '') {
                    $ctrl.options.placeholder = "Rechercher un utilisateur";
                }

                $ctrl.ngRequired = $ctrl.isRequired === true ? true : null;
                $ctrl.refresh = refresh;
                $ctrl.refresh();
            }

            /**
             * Refresh users
             *
             * @param search
             */
            function refresh(search: string) {
                $ctrl.users = [];

                return firstValueFrom(ng2UserService.last$).then(currentUser => {
                    return userManager.getAllForEtudesAndRoles($ctrl.etudes, $ctrl.roles, search).then(function (userPaginator) {
                        let hasRole = false;

                        $ctrl.users = userPaginator.collection;
                        if (!angular.isObject($ctrl.ngModel) && $ctrl.ngRequired) {
                            angular.forEach($ctrl.roles, function (role) {
                                if (currentUser.hasRole(role)) {
                                    hasRole = true;
                                }
                            });

                            $ctrl.ngModel = hasRole ? userManager.createFromNg2(currentUser) : ((angular.isArray($ctrl.users) && $ctrl.users.length > 0) ? $ctrl.users[0] : null);
                        }
                    });
                });
            }
        }

        /**
         * Filter list of users
         *
         * @returns {Function}
         */
        filter.$inject = [] as string[];
        function filter() {
            return function (users: NgUser[], search: string, ngModel: NgUser[]) {
                const formatedSearch = search.toLowerCase();
                const filterUsers: NgUser[] = [];

                angular.forEach(users, function (user) {
                    if (findIndex(ngModel, {id: user.id}) >= 0) {
                        return;
                    }

                    if (user.convivialName.toLowerCase().indexOf(formatedSearch) > -1) {
                        filterUsers.push(user);
                    }
                });

                return filterUsers;
            };
        }
    })(angularJS);
}
