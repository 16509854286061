import {Directive} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

// Cette directive n'est pas testée car impossible, surtout sur "useFactory".
// Penser à supprimer l'entrée dans "codeCoverageExclude" de "angular.json" si une solution est mise en place.
@Directive({
    selector: '[appProvideParentForm]',
    providers: [{provide: ControlContainer, useFactory: (form: NgForm): NgForm => form, deps: [NgForm]}]
})
export class ProvideParentFormDirective {
}
