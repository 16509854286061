import {inject, Injectable} from '@angular/core';
import {ProcedureFactory} from '@models/procedures/procedure/procedure.factory';
import {ProceduresApiService} from '@models/procedures/procedures.api.service';
import CProcedures from '@models/procedures/collection/procedures.collection.model';
import {Observable} from 'rxjs';
import {
    ICProceduresQueryParameters, ICProceduresApi
} from '@models/procedures/collection/procedures.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CProceduresFactory {
    private _procedureFactory = inject(ProcedureFactory);
    private _proceduresApiService = inject(ProceduresApiService);

    create(cProceduresApi: ICProceduresApi): CProcedures {
        const cProcedures = new CProcedures();

        cProcedures.links = new Links(cProceduresApi._links);
        cProcedures.page = cProceduresApi.page;
        cProcedures.pages = cProceduresApi.pages;
        cProcedures.perPage = cProceduresApi.limit;
        cProcedures.total = cProceduresApi.total;
        if (cProceduresApi._embedded?.items) {
            cProceduresApi._embedded.items.filter(procedureApi => procedureApi).map(procedureApi => cProcedures.results.push(this._procedureFactory.create(procedureApi)));
        }

        return cProcedures;
    }

    get$(queryParameters?: ICProceduresQueryParameters): Observable<CProcedures> {
        return this._proceduresApiService.getCollection$(queryParameters).pipe(map(cProceduresApi => this.create(cProceduresApi)));
    }

    getByLink$(link: string): Observable<CProcedures> {
        return this._proceduresApiService.getCollectionByLink$(link).pipe(map(cProceduresApi => this.create(cProceduresApi)));
    }

    getNext$(cProcedures: CProcedures): Observable<CProcedures> {
        return this.getByLink$(cProcedures.links.next);
    }
}
