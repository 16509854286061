import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {IProcedureApi} from '@models/procedures/procedure/procedure.interfaces';
import {Observable} from 'rxjs';
import Procedure from '@models/procedures/procedure/procedure.model';
import {
    ICProceduresApi, ICProceduresQueryParameters
} from '@models/procedures/collection/procedures.collection.interfaces';

@Injectable({providedIn: 'root'})
export class ProceduresApiService {
    private _modelApiService = inject(ModelApiService);

    cancel$(uuid: string): Observable<void> {
        return this._modelApiService.patch$('/procedures/' + uuid + '/cancel');
    }

    get$(uuid: string): Observable<IProcedureApi> {
        return this.getByLink$('/procedures/' + uuid);
    }

    getByLink$(link: string): Observable<IProcedureApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICProceduresQueryParameters): Observable<ICProceduresApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/procedures', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICProceduresApi> {
        return this._modelApiService.getCollection$(link);
    }

    launch$(uuid: string): Observable<void> {
        return this._modelApiService.patch$('/procedures/' + uuid + '/launch');
    }

    save$(uuid: string, procedureApi: IProcedureApi): Observable<IProcedureApi> {
        if (uuid === Procedure.statuts.NEW) {
            return this._modelApiService.post$('/procedures', procedureApi);
        }

        // Le PUT n'existe pas mais pour conserver une cohérence avec les autres ressources
        return this.get$(uuid);
    }
}
