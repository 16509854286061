import {NgModule} from '@angular/core';
import {NgAuthLoginComponent} from '@shared/angularJS/up-ng/auth/auth.login.component';
import {NgAuthLogoutComponent} from '@shared/angularJS/up-ng/auth/auth.logout.component';
import {NgAuthResetComponent} from '@shared/angularJS/up-ng/auth/auth.reset.component';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutAuthComponent} from '@core/routing/auth/layout-auth.component';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';

const routes: Routes = [
    {
        component: AppLayoutAuthComponent,
        path: '',
        children: [
            {component: NgAuthLoginComponent, path: 'login'},
            {component: NgAuthLogoutComponent, path: 'logout'},
            {component: NgAuthResetComponent, path: 'reset/:token'},
            // Wildcard pour /auth/*
            {path: '**', pathMatch: 'full', redirectTo: 'login'},
        ],
    },
];

@NgModule({
    declarations: [AppLayoutAuthComponent],
    imports: [EskimmoModule, RouterModule.forChild(routes), SharedModule],
})
export class AuthRoutingModule {
}
