import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {IBonvisiteLocationApi} from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.interfaces';
import {
    ICBonvisiteLocationsApi
} from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.interfaces';

@Injectable({providedIn: 'root'})
export class BonvisiteLocationsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(bonvisiteUuid: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$('/etude/bons-visite/' + bonvisiteUuid + '/locations/' + uuid);
    }

    getCollectionByLink$(link: string): Observable<ICBonvisiteLocationsApi> {
        return this._modelApiService.getCollection$(link);
    }

    save$(bonvisiteUuid: string, bonvisiteLocationApi: IBonvisiteLocationApi): Observable<IBonvisiteLocationApi> {
        return this._modelApiService.post$('/etude/bons-visite/' + bonvisiteUuid + '/locations', bonvisiteLocationApi);
    }
}
