import {Component, inject, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';

@Component({selector: 'app-estimation-onsale-reference-photo', templateUrl: 'estimation.onsale-reference.photo.component.html'})
export class EstimationOnsaleReferencePhotoComponent {
    private _etudeService = inject(EtudeService);
    private _estimationOnsaleReference!: EstimationOnsaleReference;

    @Input({required: true})
    set estimationOnsaleReference(value: EstimationOnsaleReference) {
        this._estimationOnsaleReference = value;
    }

    get estimationOnsaleReference(): EstimationOnsaleReference {
        return this._estimationOnsaleReference;
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

}
