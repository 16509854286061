import {IValidationError} from '@models/model.interfaces';

class ValidationErrors {
    static readonly DATE_VENTE: IValidationError = {
        code: 'validation_error_date_vente',
        message: 'Date de vente obligatoire',
    };
    static readonly COMMUNE: IValidationError = {code: 'validation_error_commune', message: 'Commune obligatoire'};
    static readonly NATURE: IValidationError = {code: 'validation_error_nature', message: 'Nature obligatoire'};
    static readonly PRIX_VENTE: IValidationError = {
        code: 'validation_error_prix_vente',
        message: 'Prix de vente obligatoire',
    };
    static readonly SOURCE_TYPE: IValidationError = {
        code: 'validation_error_source_type',
        message: 'Type de la source obligatoire',
    };
    static readonly SOURCE_UUID: IValidationError = {
        code: 'validation_error_source_uuid',
        message: 'Identifiant unique obligatoire',
    };
}

export const ValidationErrorsConst = ValidationErrors;

export const VALIDATION_ERRORS_TYPE = 'VALIDATION_ERRORS_TYPE';
