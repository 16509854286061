import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import {NgItemDictionary} from '@legacy/app/managers/ressources';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {CReferencesFactory} from '@models/references/collection/references.collection.factory';
import Transfer from '@models/transfers/transfer/transfer.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

export default function getEstimationModalReferencesImportCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EstimationModalReferencesImportCtrl', Controller);

        /**
         * Controller for estimation references import modal
         *
         * @param nature
         * @param Ng2RouterStateForNgService
         * @param $uibModalInstance
         * @param EstimationManager
         * @param Ng2CReferencesFactory
         * @param Ng2DictionaryItemFactory
         * @constructor
         */
        Controller.$inject = ['nature', 'Ng2RouterStateForNgService', '$uibModalInstance', 'EstimationManager', 'Ng2CReferencesFactory', 'Ng2DictionaryItemFactory'];
        function Controller(this: any,
                            nature: NgItemDictionary,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            estimationManager: NgEstimationManager,
                            ng2CReferencesFactory: CReferencesFactory,
                            ng2DictionaryItemFactory: DictionaryItemFactory) {
            const $ctrl = this;

            $ctrl.handleFileInput = handleFileInput;
            $ctrl.selectReferences = selectReferences;
            $ctrl.linkReferences = linkReferences;
            $ctrl.nature = nature;
            $ctrl.NATURES = Dictionary.names.NATURES;

            /**
             * Get file
             *
             * @param files
             */
            function handleFileInput(files: File[]) {
                $ctrl.file = files[0];

                const transfer = ng2CReferencesFactory.import($ctrl.file, ng2DictionaryItemFactory.ngCreate($ctrl.nature));

                $ctrl.file._esk = {status: 'WAITING'};
                transfer.progress$.subscribe(progress => {
                    $ctrl.file._esk.status = 'IN_PROGRESS';
                    $ctrl.file._esk.progress = progress;
                });
                transfer.race$.subscribe({
                    error: (error: string) => {
                        if (error === Transfer.ABORT) {
                            error = "Annulation manuelle du téléchargement.";
                        }

                        $ctrl.file._esk.status = 'ERROR';
                        $ctrl.file._esk.error = (error as unknown as {error: unknown})?.error || error;
                    },
                    complete: () => {
                        $ctrl.references = transfer.response;
                        $ctrl.file._esk.status = 'UPLOADED'
                        $ctrl.file = undefined
                    },
                });
            }

            /**
             * Select references
             */
            function selectReferences() {
                ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + estimationManager.current.id.toString() + '/evaluation/references', {
                    estimationId: estimationManager.current.id,
                    linkReferences: $ctrl.references.results.map(reference => reference.linkSelf),
                });
                $uibModalInstance.close();
            }

            /**
             * Link references
             */
            function linkReferences() {
                $uibModalInstance.close($ctrl.references);
            }
        }
    })(angularJS);
}
