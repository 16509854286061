import {Component, inject, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {
    AppDiagGrapheV2EmissionsAComponent, AppDiagGrapheV2EmissionsBComponent, AppDiagGrapheV2EmissionsCComponent,
    AppDiagGrapheV2EmissionsDComponent, AppDiagGrapheV2EmissionsEComponent, AppDiagGrapheV2EmissionsFComponent,
    AppDiagGrapheV2EmissionsGComponent, AppDiagGrapheV2EmissionsStatutComponent
} from '@shared/diagnostic/graphes/v2/emissions/diagnostic-graphes-v2-emissions.component';
import {DiagnosticLettersConst, DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';

// @todo Voir DropdownComponent pour les tests
// Ce component n'est pas testé car impossible de mocker ViewContainerRef.
// Penser à supprimer l'entrée dans "codeCoverageExclude" de "angular.json" si une solution est mise en place.
@Component({selector: 'app-diag-graphe-v2-emissions', template: ''})
export class AppDiagGrapheV2EmissionsComponent implements OnDestroy, OnInit {
    private _renderer2 = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);
    private _codeStatutDpe!: string;
    private _grapheEmissionsSource = new BehaviorSubject<void>(undefined);
    private _emissions!: number;
    private _emissionsClasse!: string;
    private _emissionsSource = new ReplaySubject<void>();
    private readonly _onDestroy$ = new Subject<void>();

    @Input()
    set codeStatutDpe(value: string) {
        this._codeStatutDpe = value;
        this._grapheEmissionsSource.next();
    }

    @Input()
    set emissions(value: number) {
        this._emissions = value;
        this._emissionsSource.next();
    }

    @Input()
    set emissionsClasse(value: string) {
        this._emissionsClasse = value;
        this._grapheEmissionsSource.next();
    }

    ngOnInit(): void {
        const nativeElement = this._viewContainerRef.element.nativeElement as AppDiagGrapheV2EmissionsComponent;

        this._grapheEmissionsSource.asObservable().pipe(
            map(_ => {
                if (this._codeStatutDpe === DiagnosticStatutsConst.DISPONIBLE) {
                    if (this._emissionsClasse === DiagnosticLettersConst.A) {
                        return AppDiagGrapheV2EmissionsAComponent;
                    } else if (this._emissionsClasse === DiagnosticLettersConst.B) {
                        return AppDiagGrapheV2EmissionsBComponent;
                    } else if (this._emissionsClasse === DiagnosticLettersConst.C) {
                        return AppDiagGrapheV2EmissionsCComponent;
                    } else if (this._emissionsClasse === DiagnosticLettersConst.D) {
                        return AppDiagGrapheV2EmissionsDComponent;
                    } else if (this._emissionsClasse === DiagnosticLettersConst.E) {
                        return AppDiagGrapheV2EmissionsEComponent;
                    } else if (this._emissionsClasse === DiagnosticLettersConst.F) {
                        return AppDiagGrapheV2EmissionsFComponent;
                    } else {
                        return AppDiagGrapheV2EmissionsGComponent;
                    }
                } else {
                    return AppDiagGrapheV2EmissionsStatutComponent;
                }
            }),
            tap(_ => this._viewContainerRef.clear()),
            map(componentFactory => this._viewContainerRef.createComponent(componentFactory)),
            tap(componentRef => this._renderer2.appendChild(nativeElement, componentRef.location.nativeElement)),
            switchMap(componentRef => this._emissionsSource.asObservable().pipe(map(_ => componentRef))),
            takeUntil(this._onDestroy$),
        ).subscribe(componentRef => componentRef.instance.emissions = this._emissions);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
