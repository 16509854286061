import angularJS from '@shared/angularJS/global.ng';
import {round, uniq} from 'lodash';
import {
    IAngularEvent, IAttributes, IController, IDirectiveFactory, IDocumentService, IModule, Injectable, IScope
} from 'angular';
import {NgElement, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {TextsService} from '@shared/texts/texts.service';
import Media from '@models/medias/media/media.model';

export default function getSoqInputFile(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <soq-input-file></soq-input-file>
         */
        module.directive('soqInputFile', directive as Injectable<IDirectiveFactory>);

        /**
         * Get selected files
         *
         * @param SoqSweetAlert
         * @param $translate
         * @param $document
         * @param Ng2TextsService
         */
        directive.$inject = ['SoqSweetAlert', '$translate', '$document', 'Ng2TextsService'];
        function directive(soqSweetAlert: NgSoqSweetAlert,
                           $translate: angular.translate.ITranslateService,
                           $document: IDocumentService,
                           ng2TextsService: TextsService) {
            let listType: string[] = [];

            return {
                restrict: 'E',
                scope: {
                    idDndField: '@',
                    fileType: '@',
                    filesMaxSize: '=',
                    onChange: '=',
                    options: '=',
                },
                templateUrl: 'src/app/legacy/templates/soqrate/directives/input-file.html',
                link: link,
                controller: ['$scope', '$element', controller]
            };

            /**
             * Link of directive
             *
             * @param $scope
             * @param $element
             * @param $attrs
             * @param $ctrl
             */
            function link($scope: IScope, $element: NgElement, $attrs: IAttributes, $ctrl: IController) {
                const $input = $element.find('input'); // Récupération de l'élément <input type=file>
                let $dnd: {
                    0: {
                        ondragover: (evt: IAngularEvent) => void;
                        ondragleave: (evt: IAngularEvent) => void;
                        ondrop: (evt: IAngularEvent) => void;
                    };
                    addClass: (acssCass: string) => void;
                    removeClass: (cssClass: string) => void;
                };

                // @ts-ignore
                if (['image', 'photo'].includes($scope.fileType)) {
                    listType = [Media.ACCEPTED_MIME_TYPES.imageJpeg.mime_type, '.jpeg'];
                    // @ts-ignore
                } else if ($scope.fileType === 'classeur') {
                    listType = [
                        Media.ACCEPTED_MIME_TYPES.applicationVndMsExcel.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet.mime_type,
                    ];
                } else {
                    listType = [
                        Media.ACCEPTED_MIME_TYPES.applicationCdfv2Unknown.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationMsoutlook.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationMsword.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationPdf.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndMsExcel.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndMsOutlook.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndMsPowerpoint.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentGraphics.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentPresentation.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentSpreadsheet.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOasisOpendocumentText.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationXMsg.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationXZipCompressed.mime_type,
                        Media.ACCEPTED_MIME_TYPES.applicationZip.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageBmp.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageGif.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageJpeg.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageJpg.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imagePng.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageTiff.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageXBmp.mime_type,
                        Media.ACCEPTED_MIME_TYPES.imageXMsBmp.mime_type,
                        Media.ACCEPTED_MIME_TYPES.messageRfc822.mime_type,
                        Media.ACCEPTED_MIME_TYPES.textCsv.mime_type,
                        Media.ACCEPTED_MIME_TYPES.textHtml.mime_type,
                        Media.ACCEPTED_MIME_TYPES.textPlain.mime_type,
                        Media.ACCEPTED_MIME_TYPES.textRtf.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoMp4.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoMpeg.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoQuicktime.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoXFlv.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoXMsWmv.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoXMsVideo.mime_type,
                        Media.ACCEPTED_MIME_TYPES.videoWebm.mime_type,
                    ];
                }

                // @ts-ignore
                $scope.allowedTypes = listType.join(',');

                // @ts-ignore
                if (!$scope['options'] || $scope['options'].multiple !== false) {
                    $input.attr('multiple', true as unknown as string); // Ajout de la sélection multiple
                }
                $input[0].onchange = $ctrl.onInputChange; // Sélection en mode bouton des fichiers

                // Sélection en mode DnD des fichiers
                // @ts-ignore
                if (angular.isString($scope.idDndField)) {
                    // @ts-ignore
                    $dnd = angular.element($document[0].querySelector('#' + $scope.idDndField));
                    if (angular.isDefined($dnd[0])) {
                        $dnd[0].ondragover = ondragover;
                        $dnd[0].ondragleave = ondragleave;
                        $dnd[0].ondrop = ondrop;
                    }
                }

                /**
                 * On drag over
                 *
                 * @param evt
                 */
                function ondragover(evt: IAngularEvent) {
                    $dnd.addClass('dnd-active');
                    evt.preventDefault();
                }

                /**
                 * On drag leave
                 *
                 * @param evt
                 */
                function ondragleave(evt: IAngularEvent) {
                    $dnd.removeClass('dnd-active');
                    evt.preventDefault();
                }

                /**
                 * On drop
                 *
                 * @param evt
                 */
                function ondrop(evt: IAngularEvent) {
                    $dnd.removeClass('dnd-active');
                    evt.preventDefault();
                    evt.stopPropagation!();
                    $ctrl.onDrop(evt);
                }
            }

            /**
             * Controller of directive
             *
             * @param $scope
             * @param $element
             */
            function controller(this: any, $scope: IScope, $element: NgElement) {
                const $ctrl = this;
                const $input = $element.find('div .btn input');
                // @ts-ignore
                const filesMaxSize = $scope.filesMaxSize > 0 ? $scope.filesMaxSize : 31457280;

                $ctrl.onInputChange = onInputChange;
                $ctrl.onDrop = onDrop;

                /**
                 * Get files when selection is made
                 *
                 * @param evt
                 */
                function onInputChange(evt: { currentTarget: { files: File[] } }) {
                    onChange(evt.currentTarget.files);
                }

                /**
                 * Get files when drop is made
                 *
                 * @param evt
                 */
                function onDrop(evt: { dataTransfer: { files: File[] } }) {
                    onChange(evt.dataTransfer.files);
                }

                /**
                 * Get files
                 *
                 * @param files
                 */
                function onChange(files: File[]) {
                    const fileList: unknown[] = [];
                    const fileTypeErrors: unknown[] = [];
                    let totalSize = 0;
                    let labelFilesMaxSize;

                    angular.forEach(files, function (file) {
                        const fileNameSplit = file.name.split('.');
                        const fileExtension = fileNameSplit[fileNameSplit.length - 1];

                        // @todo, Pour s'assurer réellement du type d'un fichier, voir https://gist.github.com/topalex/ad13f76150e0b36de3c4a3d5ba8dc63a
                        if (listType.length > 0 && (!listType.includes(file.type) && !listType.includes('.' + fileExtension))) {
                            if (file.type === '') {
                                fileTypeErrors.push(fileExtension);
                            } else {
                                fileTypeErrors.push(file.type);
                            }
                            return;
                        }

                        totalSize += file.size;
                        (file as unknown as { uuid: string }).uuid = ng2TextsService.uuid();
                        fileList.push(file);
                    });

                    if (fileTypeErrors.length > 0) {
                        soqSweetAlert.warningMessage($translate.instant("formulaire.upload.TITLE"), $translate.instant("formulaire.upload.MESSAGE_TYPE", {
                            nbFiles: fileTypeErrors.length,
                            nbTypes: uniq(fileTypeErrors).length,
                            types: uniq(fileTypeErrors).join(', ')
                        }));
                    }

                    if (totalSize > filesMaxSize) {
                        if (filesMaxSize >= 1073741824) {
                            labelFilesMaxSize = round(filesMaxSize / 1024 / 1024 / 1024) + "Go";
                        } else if (filesMaxSize >= 1048576) {
                            labelFilesMaxSize = round(filesMaxSize / 1024 / 1024) + "Mo";
                        } else if (filesMaxSize >= 1024) {
                            labelFilesMaxSize = round(filesMaxSize / 1024) + "ko";
                        } else {
                            labelFilesMaxSize = filesMaxSize + "o";
                        }
                        soqSweetAlert.warningMessage($translate.instant("formulaire.upload.TITLE"), $translate.instant("formulaire.upload.MESSAGE_SIZE", {
                            // @ts-ignore
                            items: ($scope.fileType === "photo" ? "photos" : $scope.fileType === "image" ? "images" : $scope.fileType === "classeur" ? "classeurs" : "fichiers"),
                            maxSize: labelFilesMaxSize
                        }));

                        return;
                    }

                    // @ts-ignore
                    if (fileList.length > 0) $scope.$apply($scope.onChange(fileList));

                    $input.val(null!); // Utile pour faire de nouveau la même sélection
                }
            }
        }
    })(angularJS);
}
