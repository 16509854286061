import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import Person from '@models/contacts/person/person.model';

export default function getContactConvivialName(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contact-convivial-name></esk-contact-convivial-name>
         */
        module.component('eskContactConvivialName', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/contacts/components/item/convivial-name.html',
            bindings: {contact: '='}
        });

        Controller.$inject = ['EskUtils'];
        function Controller(this: any) {
            const $ctrl = this;

            $ctrl.PERSON_TITRE_MONSIEUR = Person.titres.MONSIEUR;
        }
    })(angularJS);
}
