import {Component, Input} from '@angular/core';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import {ConsommationV1ColorsConst, ConsommationV2ColorsConst} from '@shared/diagnostic/diagnostic.constants';

@Component({
    selector: 'app-diagnostic-letter-consommation',
    templateUrl: 'diagnostic-letter-consommation.component.html',
})
export class AppDiagnosticLetterConsommationComponent {
    private _backgroundColor!: string;
    private _letter!: string;

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get letter(): string {
        return this._letter;
    }

    @Input()
    set performanceEnergetique(value: BienPerformanceEnergetique) {
        const consommationClasse = value.consommationClasse!;

        this._letter = consommationClasse.code;
        this._backgroundColor = value.isV1ByDate() ? ConsommationV1ColorsConst[this._letter] : ConsommationV2ColorsConst[this._letter];
    }
}
