import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'app-ng-location-lifetime', template: '<div appNgLocationLifetime></div>'})
export class NgLocationLifetimeComponent {
}

@Directive({selector: '[appNgLocationLifetime]'})
export class NgLocationLifetimeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskLocationLifetimeCtrl', elementRef, injector);
    }
}
