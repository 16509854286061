import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ILocationApi} from '@models/locations/location/location.interfaces';
import {ICLocationsApi, ICLocationsQueryParameters} from '@models/locations/collection/locations.collection.interfaces';
import Location from '@models/locations/location/location.model';

@Injectable({providedIn: 'root'})
export class LocationsApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<ILocationApi> {
        return this._modelApiService.get$('/locations/' + uuid);
    }

    getByLink$(link: string): Observable<ILocationApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICLocationsQueryParameters): Observable<ICLocationsApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/locations', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICLocationsApi> {
        return this._modelApiService.getCollection$(link);
    }

    getEtudeCollection$(queryParameters?: ICLocationsQueryParameters): Observable<ICLocationsApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/locations', queryParameters));
    }

    networkDiffuse$(id: string): Observable<void> {
        return this._modelApiService.patch$(`/locations/${id}/internal/networks/diffuser`);
    }

    openDetailsInNewTab$(id: string): Observable<Window> {
        return this._modelApiService.openWindow$('/app/locations/details/' + id);
    }

    save$(id: string, locationApi: ILocationApi): Observable<ILocationApi> {
        if (id === Location.statuts.NEW) {
            return this._modelApiService.post$('/user/locations', locationApi);
        }

        return this._modelApiService.put$(`/locations/${id}`, locationApi).pipe(switchMap(_ => this.get$(id)));
    }
}
