import {inject, Injectable} from '@angular/core';
import {LocationFactory} from '@models/locations/location/location.factory';
import CLocations from '@models/locations/collection/locations.collection.model';
import {ICLocationsApi, ICLocationsQueryParameters} from '@models/locations/collection/locations.collection.interfaces';
import Links from '@models/links/links.model';
import {LocationsApiService} from '@models/locations/locations.api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CLocationsFactory {
    private _locationFactory = inject(LocationFactory);
    private _locationsApiService = inject(LocationsApiService);

    create(cLocationsApi: ICLocationsApi): CLocations {
        const cLocations = this.createVirgin();

        cLocations.links = new Links(cLocationsApi._links);
        cLocations.page = cLocationsApi.page;
        cLocations.pages = cLocationsApi.pages;
        cLocations.perPage = cLocationsApi.limit;
        cLocations.total = cLocationsApi.total;
        if (cLocationsApi._embedded?.items) {
            cLocationsApi._embedded.items.filter(locationApi => locationApi).map(locationApi => cLocations.results.push(this._locationFactory.create(locationApi)));
        }

        return cLocations;
    }

    createVirgin(): CLocations {
        const cLocations = new CLocations();

        cLocations.links = new Links();

        return cLocations;
    }

    get$(queryParameters?: ICLocationsQueryParameters): Observable<CLocations> {
        return this._locationsApiService.getCollection$(queryParameters).pipe(map(cLocationsApi => this.create(cLocationsApi)));
    }

    getByLink$(link: string): Observable<CLocations> {
        return this._locationsApiService.getCollectionByLink$(link).pipe(map(cLocationsApi => this.create(cLocationsApi)));
    }

    getEtude$(queryParameters?: ICLocationsQueryParameters): Observable<CLocations> {
        return this._locationsApiService.getEtudeCollection$(queryParameters).pipe(map(cLocationsApi => this.create(cLocationsApi)));
    }
}
