export default `
<soq-panel box="$ctrl.box">
  <content-slot>
    <esk-chart-negociation-revenus date-debut="$ctrl.dateDebut" date-fin="$ctrl.dateFin">
    </esk-chart-negociation-revenus>
  </content-slot>

  <footer-slot>
    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/negociation/revenus', {dateDebutBrute: $ctrl.dateDebut, dateFinBrute: $ctrl.dateFin})">
      <fa-icon [icon]="'arrow-circle-right'"></fa-icon> Détails du chiffre d'affaires
    </a>
  </footer-slot>
</soq-panel>
`;
