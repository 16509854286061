export default `
<ui-select ng-required="{{$ctrl.ngRequired}}" backspace-reset="!$ctrl.ngRequired" ng-model="$ctrl.ngModel"
           on-select="$ctrl.onSelect($item, $model)" name="{{$ctrl.name}}">
  <ui-select-match placeholder="Rechercher une étude" allow-clear="{{!$ctrl.ngRequired}}">
    {{$select.selected.raisonSociale}}
  </ui-select-match>

  <ui-select-choices repeat="etude in $ctrl.etudes | filter: $select.search">
    <span ng-bind-html="etude.raisonSociale | highlight: $select.search"></span>
  </ui-select-choices>

  <ui-select-no-choice>
    <span class="content" ng-hide="$select.refreshing || $select.search.length === 0">
      Aucun résultat pour "{{$select.search}}"
    </span>
  </ui-select-no-choice>
</ui-select>
`;
