import {inject, Injectable} from '@angular/core';
import Meteo from '@core/meteo/meteo.model';
import Commune from '@models/communes/commune/commune.model';
import {Observable, of} from 'rxjs';
import {MeteoFactory} from '@core/meteo/meteo.factory';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MeteoService {
    private _meteoFactory = inject(MeteoFactory);

    get$(commune: Commune): Observable<Meteo> {
        const meteoStorage = this._meteoFactory.getFromStorage();

        if (!meteoStorage.needReload()) {
            return of(meteoStorage);
        }

        return this._meteoFactory.get$(commune).pipe(tap(meteo => this._meteoFactory.saveInStorage(meteo)));
    }
}
