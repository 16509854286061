export default `
<div class="container-fluid container-fluid-title"><h1>Critères de recherche des biens modifiés du réseau</h1></div>

<div class="container-fluid container-fullw tw-bg-white">
  <div class="padding-bottom-10 padding-left-20">
    <div class="checkbox clip-check check-primary padding-bottom-10">
      <input type="checkbox" id="modification-prix" ng-model="$ctrl.search.dateModification.prix">

      <label for="modification-prix">{{"Voir les dernières modifications de prix de vente" | translate}}</label>
    </div>

    <div class="checkbox clip-check check-primary padding-bottom-10">
      <input type="checkbox" id="modification-compromis" ng-model="$ctrl.search.dateModification.compromis">

      <label for="modification-compromis">Voir les derniers compromis créés et annulés</label>
    </div>

    <div class="checkbox clip-check check-primary padding-bottom-10">
      <input type="checkbox" id="modification-archive" ng-model="$ctrl.search.dateModification.archive">

      <label for="modification-archive">Voir les derniers archivages</label>
    </div>
  </div>

  <esk-search-criteria ng-if="!$ctrl.loading" options="{disableSecondaryCriteria: true, disabledFields: {
                            fieldsetSource: true,
                            fieldsetTypeTransaction: true,
                            fieldsetNatures: true,
                            fieldsetCession: true,
                            fieldsetBudget: true,
                            fieldsetConstruction: true,
                            searchByAdresse: true
                         }}" search="$ctrl.search" update-search-localisations="$ctrl.updateSearchLocalisations">
  </esk-search-criteria>

  <button type="button" ng-click="$ctrl.launch()" class="btn btn-primary pull-right btn-save"
          ng-disabled="$ctrl.saving" ng-switch="$ctrl.saving">
    <span ng-switch-when="true"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Enregistrement des critères</span>

    <span ng-switch-default><fa-icon [icon]="'check'"></fa-icon> Appliquer les critères</span>
  </button>
</div>
`;
