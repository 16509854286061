export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()" title="Fermer">
    <fa-icon [icon]="'times'"></fa-icon>
  </button>

  <h3 class="modal-title"><fa-icon [icon]="'rss'"></fa-icon> {{$ctrl.title | translate}}</h3>
</div>

<div class="modal-body">
  <ul class="list-group list-unstyled margin-left-30 padding-left-30">
    <li ng-repeat="passerelle in $ctrl.passerelles">
      <div class="checkbox clip-check check-primary">
        <input type="checkbox" id="passerelle{{passerelle.passerelle.id}}" ng-model="passerelle._esk.select" ng-change="$ctrl.onSelect(passerelle)"
               ng-disabled="passerelle.configurator !== null && passerelle.configurator !== '' && passerelle.statut !== 'configured'">

        <label for="passerelle{{passerelle.passerelle.id}}">{{passerelle.passerelle.name}}</label>

        <a ng-if="passerelle.configurator !== null && passerelle.configurator !== '' && passerelle.statut !== 'configured'"
          ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/configuration/passerelles')">Configuration</a>
      </div>
    </li>
  </ul>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" type="button" ng-click="$ctrl.validateSelection()">
    <fa-icon [icon]="'check'"></fa-icon> Ok
  </button>
</div>
`;
