import {inject, Injectable} from '@angular/core';
import {IProcedureSignataireApi} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.interfaces';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import Contact from '@models/contacts/contact/contact.model';
import Person from '@models/contacts/person/person.model';
import User from '@models/users/user/user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProcedureSignatairesApiService} from '@models/procedures/procedure/signataires/procedure-signataires.api.service';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import Participant from '@models/participants/participant/participant.model';

@Injectable({providedIn: 'root'})
export class ProcedureSignataireFactory {
    private _phoneNumberService = inject(PhoneNumberService);
    private _procedureSignatairesApiService = inject(ProcedureSignatairesApiService);

    create(procedureSignataireApi: IProcedureSignataireApi): ProcedureSignataire {
        const procedureSignataire = this.createVirgin(procedureSignataireApi.uuid);

        procedureSignataire.email = procedureSignataireApi.email;
        procedureSignataire.finishedAt = procedureSignataireApi.finishedAt;
        procedureSignataire.nom = procedureSignataireApi.nom;
        procedureSignataire.positionLlx = procedureSignataireApi.positionLlx;
        procedureSignataire.positionLly = procedureSignataireApi.positionLly;
        procedureSignataire.positionUrx = procedureSignataireApi.positionUrx;
        procedureSignataire.positionUry = procedureSignataireApi.positionUry;
        procedureSignataire.prenom = procedureSignataireApi.prenom;
        procedureSignataire.refusedAt = procedureSignataireApi.refusedAt;
        procedureSignataire.statut = procedureSignataireApi.status;
        procedureSignataire.telephone = this._phoneNumberService.getUsable(procedureSignataireApi.telephone);
        procedureSignataire.type = procedureSignataireApi.type;
        procedureSignataire.typeId = procedureSignataireApi.typeId;
        if (procedureSignataireApi._links?.interfaceSignature) {
            procedureSignataire.linkInterfaceSignature = procedureSignataireApi._links.interfaceSignature.href;
        }

        return procedureSignataire;
    }

    createFromContact(contact: Contact): ProcedureSignataire {
        const procedureSignataire = this.createVirgin();

        procedureSignataire.email = contact.emailAddress;
        procedureSignataire.telephone = this._phoneNumberService.getUsable(contact.mobile ? contact.mobile : contact.telephone);
        procedureSignataire.type = ProcedureSignataire.types.CONTACT;
        procedureSignataire.typeId = contact.id;
        if (contact instanceof Person) {
            procedureSignataire.nom = contact.nom;
            procedureSignataire.prenom = contact.prenom;
        }

        return procedureSignataire;
    }

    createFromParticipant(participant: Participant): ProcedureSignataire {
        if (participant.isPerson()) {
            return this.createFromContact(participant.person);
        } else if (participant.isUser()) {
            return this.createFromUser(participant.user);
        }

        return this.createVirgin();
    }

    createFromUnknown(futurProcedureSignataire?: Contact | User): ProcedureSignataire {
        if (futurProcedureSignataire instanceof Contact) {
            return this.createFromContact(futurProcedureSignataire);
        } else if (futurProcedureSignataire instanceof User) {
            return this.createFromUser(futurProcedureSignataire);
        }

        return this.createVirgin();
    }

    createFromUser(user: User): ProcedureSignataire {
        const procedureSignataire = this.createVirgin();

        procedureSignataire.email = user.emailAddress;
        procedureSignataire.nom = user.nom;
        procedureSignataire.prenom = user.prenom;
        procedureSignataire.telephone = user.getPhoneMobileFirst();
        procedureSignataire.type = ProcedureSignataire.types.UTILISATEUR;
        procedureSignataire.typeId = user.id;

        return procedureSignataire;
    }

    createVirgin(uuid?: string): ProcedureSignataire {
        return new ProcedureSignataire(uuid ?? ProcedureSignataire.statuts.NEW);
    }

    delete$(procedureUuid: string, procedureSignataire: ProcedureSignataire): Observable<void> {
        return this._procedureSignatairesApiService.delete$(procedureUuid, procedureSignataire.uuid);
    }

    forApi(procedureSignataire: ProcedureSignataire, procedureProvider: string): IProcedureSignataireApi {
        const procedureSignataireApi = {
            nom: procedureSignataire.nom,
            prenom: procedureSignataire.prenom,
            type: procedureSignataire.type,
            typeId: procedureSignataire.typeId,
        } as IProcedureSignataireApi;

        if (procedureProvider === Procedure.providers.yousign.code) {
            procedureSignataireApi.email = procedureSignataire.email;
            procedureSignataireApi.telephone = this._phoneNumberService.getE164(procedureSignataire.telephone);
        }

        return procedureSignataireApi;
    }

    save$(procedure: Procedure, procedureSignataire: ProcedureSignataire): Observable<ProcedureSignataire> {
        return this._procedureSignatairesApiService.save$(procedure.uuid, procedureSignataire.uuid, this.forApi(procedureSignataire, procedure.provider))
            .pipe(map(procedureSignataireApi => this.create(procedureSignataireApi)));
    }

    sign$(procedureUuid: string, procedureSignataire: ProcedureSignataire, signature: string): Observable<void> {
        return this._procedureSignatairesApiService.sign$(procedureUuid, procedureSignataire.uuid, signature);
    }
}
