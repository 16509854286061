export default class EtudeSettingsCityscan {
    static readonly REQUEST_ERRORS = {cityscan_not_localizable: 'cityscan_not_localizable'};
    private _enabled!: boolean;

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }
}
