import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {DCReferenceEditComponent} from '@features/references/reference/edit/reference.edit.component';
import {CommunesModule} from '@features/communes/communes.module';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {AppReferencesListComponent} from '@features/references/list/references-list.component';
import {ReferenceDropdownComponent} from '@features/references/reference/dropdown/reference.dropdown.component';
import {DCReferenceSlideOverComponent} from '@features/references/reference/slide-over/reference.slide-over.component';

@NgModule({
    declarations: [
        AppReferencesListComponent,
        DCReferenceEditComponent,
        DCReferenceSlideOverComponent,
        ReferenceDropdownComponent,
    ],
    exports: [AppReferencesListComponent],
    imports: [CommonModule, CommunesModule, DictionariesModule, SharedModule],
})
export class ReferencesModule {
}
