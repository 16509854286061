import {Component, inject, Input} from '@angular/core';
import {IIframeOptions} from '@shared/iframe/iframe.interfaces';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import Media from '@models/medias/media/media.model';

@Component({selector: 'app-iframe', templateUrl: 'iframe.component.html'})
export class AppIframeComponent {
    static readonly initIframeOptions: IIframeOptions = {height: '100%', sandbox: undefined!, width: '100%'};
    private _domSanitizer = inject(DomSanitizer);
    private _options: IIframeOptions = {...AppIframeComponent.initIframeOptions};
    private _safeResourceUrl!: SafeResourceUrl;

    get options(): IIframeOptions {
        return this._options;
    }

    @Input()
    set options(value: IIframeOptions) {
        this._options = {...AppIframeComponent.initIframeOptions, ...value};
    }

    get safeResourceUrl(): SafeResourceUrl {
        return this._safeResourceUrl;
    }

    @Input()
    set src(value: string) {
        this._safeResourceUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(value);
    }

    @Input()
    set srcHTML(value: string) {
        const blob = new Blob([value], {type: Media.ACCEPTED_MIME_TYPES.textHtml.mime_type});

        this._safeResourceUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    }
}
