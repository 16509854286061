<div class="tw-second-nav">
  <div class="tw-second-nav-menus">
    <h1 class="tw-second-nav-menus-title">Signature en présentiel</h1> <app-call-to-action [type]="ACTION_MAIN"/>
  </div>

  <div class="tw-second-nav-content">
    @if (procedure$ | async; as procedure) {
      <app-procedure-presentiel-sign [cSignataires$]="cProcedureSignataires$" [procedure]="procedure"
                                     (signed)="hasToRedirect(procedure)"/>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-p-4"/>
    }
  </div>
</div>
