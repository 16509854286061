import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';

@Component({
    selector: 'dossier-files-actions-selection-dropdown',
    templateUrl: 'dossier-files.actions-selection-dropdown.component.html',
})
export class DossierFilesActionsSelectionDropdownComponent implements IDynamicComponent {
    static readonly actions = {DELETE: 'dossier_files_delete', SHARE: 'dossier_files_share'};
    private _dropdownService = inject(DropdownService);
    private _value!: IDynamicComponentData;

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    delete(): void {
        this._dropdownService.clicked(DossierFilesActionsSelectionDropdownComponent.actions.DELETE);
    }
}
