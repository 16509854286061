import {inject, Injectable} from '@angular/core';
import {
    IEtudeSettingsDocumentsFooterApi
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.interfaces';
import EtudeSettingsDocumentsFooter
    from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.model';
import {Observable} from 'rxjs';
import {
    EtudeSettingsDocumentsFooterApiService
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.api.service';
import {map} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';

@Injectable({providedIn: 'root'})
export class EtudeSettingsDocumentsFooterFactory {
    private _etudeSettingsDocumentsFooterApiService = inject(EtudeSettingsDocumentsFooterApiService);

    create(etudeSettingsDocumentsFooterApi: IEtudeSettingsDocumentsFooterApi): EtudeSettingsDocumentsFooter {
        const etudeSettingsDocumentsFooter = new EtudeSettingsDocumentsFooter();

        etudeSettingsDocumentsFooter.code = etudeSettingsDocumentsFooterApi.code;
        etudeSettingsDocumentsFooter.customHeight = etudeSettingsDocumentsFooterApi.customHeight;
        etudeSettingsDocumentsFooter.customTemplate = etudeSettingsDocumentsFooterApi.customTemplate;
        etudeSettingsDocumentsFooter.enabled = etudeSettingsDocumentsFooterApi.enabled;

        return etudeSettingsDocumentsFooter;
    }

    forApi(etudeSettingsDocumentsFooter: EtudeSettingsDocumentsFooter): IEtudeSettingsDocumentsFooterApi {
        const etudeSettingsDocumentsFooterApi = {} as IEtudeSettingsDocumentsFooterApi;

        if (etudeSettingsDocumentsFooter.enabled) {
            etudeSettingsDocumentsFooterApi.code = etudeSettingsDocumentsFooter.code;

            if (etudeSettingsDocumentsFooter.code === EtudeSettingsDocumentsFooter.CODE_CUSTOM) {
                etudeSettingsDocumentsFooterApi.customHeight = etudeSettingsDocumentsFooter.customHeight;
                etudeSettingsDocumentsFooterApi.customTemplate = etudeSettingsDocumentsFooter.customTemplate;
            }
        }

        return etudeSettingsDocumentsFooterApi;
    }

    get$(etude: Etude): Observable<EtudeSettingsDocumentsFooter> {
        return this._etudeSettingsDocumentsFooterApiService.get$(etude.id.toString()).pipe(map(etudeSettingsDocumentsFooterApi => this.create(etudeSettingsDocumentsFooterApi)));
    }

    save$(etude: Etude, etudeSettingsDocumentsFooter: EtudeSettingsDocumentsFooter): Observable<EtudeSettingsDocumentsFooter> {
        return this._etudeSettingsDocumentsFooterApiService.save$(etude.id.toString(), this.forApi(etudeSettingsDocumentsFooter)).pipe(
            map(etudeSettingsDocumentsFooterApi => this.create(etudeSettingsDocumentsFooterApi)),
        );
    }
}
