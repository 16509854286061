import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import AModel from '@models/model.abstract';
import DateFormat from '@shared/date/date.format';
import Vente from '@models/ventes/vente/vente.model';

export default class VenteCompromis extends AModel {
    static NEW = AModel.STATUT_NEW;
    static COMPROMIS_DURATION = 3;
    private _acquereur!: string;
    private _comments!: string;
    private _date!: string;
    private _dateFin!: string;
    private _dateVente!: string;
    private _demandeurId!: number;
    private _enabled!: boolean;
    private _etudeAcquereurId!: number;
    private _honorairesNego!: number;
    private _honorairesNegoPercus!: number;
    private _linkDemandeur!: string;
    private _linkEtudeAcquereur!: string;
    private _negocie!: boolean;
    private _prixCession!: number;
    private _raisonNegocie!: DictionaryItem;
    private _raisonNonNegocie!: DictionaryItem;

    get acquereur(): string {
        return this._acquereur;
    }

    set acquereur(value: string) {
        this._acquereur = value;
    }

    get comments(): string {
        return this._comments;
    }

    set comments(value: string) {
        this._comments = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
        this.dateFin = DateFormat.addMonthsToDateString(this.date, VenteCompromis.COMPROMIS_DURATION);
    }

    get dateFin(): string {
        return this._dateFin;
    }

    set dateFin(value: string) {
        this._dateFin = value;
        this.dateVente = this.dateFin;
    }

    get dateVente(): string {
        return this._dateVente;
    }

    set dateVente(value: string) {
        if (value === null) {
            this._dateVente = undefined!;
        } else {
            this._dateVente = DateFormat.toJSON(DateFormat.dateFromDate(DateFormat.sanitize(value)));
        }
    }

    get demandeurId(): number {
        return this._demandeurId;
    }

    set demandeurId(value: number) {
        this._demandeurId = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }

    get etudeAcquereurId(): number {
        return this._etudeAcquereurId;
    }

    set etudeAcquereurId(value: number) {
        this._etudeAcquereurId = value;
    }

    get honorairesNego(): number {
        return this._honorairesNego;
    }

    set honorairesNego(value: number) {
        this._honorairesNego = value;
    }

    get honorairesNegoPercus(): number {
        return this._honorairesNegoPercus;
    }

    set honorairesNegoPercus(value: number) {
        this._honorairesNegoPercus = value;
    }

    get linkDemandeur(): string {
        return this._linkDemandeur;
    }

    set linkDemandeur(value: string) {
        this._linkDemandeur = value;
    }

    get linkEtudeAcquereur(): string {
        return this._linkEtudeAcquereur;
    }

    set linkEtudeAcquereur(value: string) {
        this._linkEtudeAcquereur = value;
    }

    get prixCession(): number {
        return this._prixCession;
    }

    set prixCession(value: number) {
        this._prixCession = value;
    }

    get negocie(): boolean {
        return this._negocie;
    }

    set negocie(value: boolean) {
        this._negocie = value;
    }

    get raisonNegocie(): DictionaryItem {
        return this._raisonNegocie;
    }

    set raisonNegocie(value: DictionaryItem) {
        this._raisonNegocie = value;
    }

    get raisonNonNegocie(): DictionaryItem {
        return this._raisonNonNegocie;
    }

    set raisonNonNegocie(value: DictionaryItem) {
        this._raisonNonNegocie = value;
    }

    isNegocieReseau(): boolean {
        return this.negocie && this.raisonNegocie && this.raisonNegocie.code === Vente.archiveNegocieeRaisons.RESEAU;
    }

    isNonNegociePAP(): boolean {
        return !this.negocie && this.raisonNegocie && this.raisonNegocie.code === Vente.archiveNonNegocieeRaisons.PAP;
    }
}
