import angularJS from '@shared/angularJS/global.ng';
import {IAngularEvent, IHttpService, IModule, IQService, IServiceProvider} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgLocalStorageServiceAuth} from '@legacy/app/soqrate/soqrate';
import {NgAuthenticationServiceProvider} from '@legacy/app/auth/auth';
import {AuthService} from '@core/auth/core/auth.service';
import {LocalStorageService} from '@core/storage/local-storage.service';
import {SessionStorageService} from '@core/storage/session-storage.service';
import DateFormat from '@shared/date/date.format';

export default function getAuthAuthenticationService(module: IModule): void {
    (function (angular) {
        'use strict';

        module.provider('AuthenticationService', Provider as unknown as IServiceProvider);

        /**
         * AuthenticationService provider
         */
        Provider.$inject = [] as string[];
        function Provider(this: NgAuthenticationServiceProvider) {
            let baseUrl: string;
            let clientId: string;
            let clientSecret: string;

            this.setBaseUrl = setBaseUrl;
            this.setClientId = setClientId;
            this.setClientSecret = setClientSecret;
            this.$get = Service;

            /**
             * Set API baseUrl
             *
             * @param _baseUrl_
             */
            function setBaseUrl(_baseUrl_: string): void {
                baseUrl = _baseUrl_;
            }

            /**
             * Set API Client ID
             *
             * @param _clientId_
             */
            function setClientId(_clientId_: string): void {
                clientId = _clientId_;
            }

            /**
             * Set API Client ID
             *
             * @param _clientSecret_
             */
            function setClientSecret(_clientSecret_: string): void {
                clientSecret = _clientSecret_;
            }

            /**
             * AuthenticationService
             *
             * @param $http
             * @param $q
             * @param Ng2RouterStateForNgService
             * @param Ng2LocalStorageService
             * @param Ng2SessionStorageService
             * @constructor
             */
            Service.$inject = ['$http', '$q', 'Ng2RouterStateForNgService', 'Ng2LocalStorageService', 'Ng2SessionStorageService'];
            function Service($http: IHttpService,
                             $q: IQService,
                             ng2RouterStateForNgService: RouterStateForNgService,
                             ng2LocalStorageService: LocalStorageService,
                             ng2SessionStorageService: SessionStorageService) {
                return {
                    authenticate: authenticate,
                    authenticateWithRefreshToken: authenticateWithRefreshToken,
                    eraseCredentials: eraseCredentials,
                    getAccessToken: getAccessToken,
                    goToSavedState: goToSavedState,
                    hasRefreshToken: hasRefreshToken,
                    isAuthenticated: isAuthenticated,
                    logout: logout,
                    redirect: redirect,
                };

                /**
                 * Returns if the current access token is still valid
                 *
                 * @returns {boolean}
                 */
                function isAuthenticated() {
                    const auth = ng2LocalStorageService.get<NgLocalStorageServiceAuth>('auth');

                    if (!angular.isObject(auth) || !angular.isString(auth.accessToken)) {
                        return false;
                    }

                    return !DateFormat.isNowOlder(auth.expiredAt);
                }

                /**
                 * Returns if client has a refresh token
                 *
                 * @returns {boolean}
                 */
                function hasRefreshToken() {
                    const refreshToken = getRefreshToken();

                    return angular.isString(refreshToken) && !!refreshToken;
                }

                /**
                 * Returns the current access token
                 *
                 * @returns {*}
                 */
                function getAccessToken() {
                    const auth = ng2LocalStorageService.get<NgLocalStorageServiceAuth>('auth');

                    return auth?.accessToken;
                }

                /**
                 * Save the desired state and redirect to another state (start, login, ...)
                 *
                 * @param state
                 * @param event
                 * @param savedState
                 * @param savedStateParams
                 * @return {*}
                 */
                function redirect(state: string, event: IAngularEvent | string, savedState: string, savedStateParams: {}) {
                    if (savedState !== 'start') {
                        ng2SessionStorageService.set('redirect', {state: savedState, params: savedStateParams});
                    }

                    return ng2RouterStateForNgService.navigateByUrl(state);
                }

                /**
                 * Go to the saved state or default
                 *
                 * @returns {*}
                 */
                function goToSavedState() {
                    const redirectUrl = ng2SessionStorageService.get<string>('redirectedUrl');

                    ng2SessionStorageService.remove('redirectedUrl');

                    return ng2RouterStateForNgService.navigateByUrl(redirectUrl && redirectUrl !== '/auth/logout' && redirectUrl !== 'start' ? redirectUrl : '');
                }

                /**
                 * Authenticate with user and password
                 *
                 * @param username
                 * @param password
                 * @param remember
                 * @returns {*}
                 */
                function authenticate(username: string, password: string, remember: boolean) {
                    return authenticateOAuth({
                        grant_type: 'password',
                        username: username,
                        password: password
                    }, remember);
                }

                /**
                 * Authenticate with refreshToken
                 * @returns {*}
                 */
                function authenticateWithRefreshToken() {
                    if (!hasRefreshToken()) {
                        return $q.reject(AuthService.NO_REFRESH_TOKEN);
                    }

                    return authenticateOAuth({grant_type: 'refresh_token', refresh_token: getRefreshToken()}, true);
                }

                /**
                 * Logout with eraseCredentials method
                 */
                function logout() {
                    eraseCredentials();
                    ng2RouterStateForNgService.navigateByUrl('/auth/login');
                }

                /**
                 * Erase credentials informations
                 */
                function eraseCredentials() {
                    ng2LocalStorageService.remove('auth');
                }

                /**
                 * Save tokens from HTTP Response
                 *
                 * @param response
                 * @param remember
                 */
                function saveCredentialsFromResponse(response: { data: Record<string, string>; headers(type: string): string }, remember: boolean) {
                    ng2LocalStorageService.set('auth', {
                        accessToken: response.data.access_token,
                        refreshToken: remember ? response.data.refresh_token : undefined!,
                        expiredAt: DateFormat.addSecondsToDateString(response.headers('Date'), +response.data.expires_in),
                    });
                }

                /**
                 * Returns current refresh token
                 *
                 * @returns {*}
                 */
                function getRefreshToken() {
                    const auth = ng2LocalStorageService.get<NgLocalStorageServiceAuth>('auth');

                    if (angular.isObject(auth) && angular.isString(auth.refreshToken) && auth.refreshToken !== '') {
                        return auth.refreshToken;
                    }

                    return '';
                }

                /**
                 * OAuth authenticate request
                 *
                 * @param params
                 * @param remember
                 */
                function authenticateOAuth(params: unknown, remember: boolean) {
                    const config = {headers: {Authorization: "Basic " + btoa(clientId + ':' + clientSecret)}};

                    remember = angular.isDefined(remember) ? remember : false;

                    return $http.post(baseUrl + '/oauth/v2/token', params, config).then(
                        data => data as { data: Record<string, string>; headers(type: string): string }
                    ).then(response => {
                        saveCredentialsFromResponse(response, remember);

                        return response;
                    }, response => {
                        eraseCredentials();

                        return $q.reject(response);
                    });
                }
            }
        }
    })(angularJS);
}
