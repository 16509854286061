import {inject, Injectable} from '@angular/core';
import Site from '@models/sites/site/site.model';
import Etude from '@models/etudes/etude/etude.model';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SiteService {
    private _communeFactory = inject(CommuneFactory);

    getConvivialName$(etude: Etude, site: Site): Observable<string> {
        if (site.isPrincipal()) {
            return of(etude.raisonSociale);
        }

        return this._communeFactory.getByLink$(site.linkCommune).pipe(
            map(commune => commune.fullname),
            map(communeName => etude.raisonSociale + ' (' + communeName + ')'),
        );
    }
}
