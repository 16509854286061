import {inject, Injectable} from '@angular/core';
import {CReferencesFactory} from '@models/references/collection/references.collection.factory';
import {Observable} from 'rxjs';
import CReferences from '@models/references/collection/references.collection.model';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import {map, tap} from 'rxjs/operators';
import Estimation from '@models/estimations/estimation/estimation.model';
import {ICReferencesQueryParameters} from '@models/references/collection/references.collection.interfaces';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

@Injectable({providedIn: 'root'})
export class CReferencesService {
    private _cReferencesFactory = inject(CReferencesFactory);

    addNext$(cReferences: CReferences): Observable<CReferences> {
        return this._cReferencesFactory.getNext$(cReferences).pipe(
            tap(newCCReferences => cReferences.updateNext(newCCReferences)),
            map(_ => cReferences),
        );
    }

    get$(queryParameters?: ICReferencesQueryParameters): Observable<CReferences> {
        return this._cReferencesFactory.get$(queryParameters, {headers: {handledTypeErrors: [EtudeSettingsCityscan.REQUEST_ERRORS.cityscan_not_localizable]}});
    }

    getByEstimationAndSearchCriteria$(estimation: Estimation, searchCriteria: SearchCriteria): Observable<CReferences> {
        return this.get$({
            ...this._cReferencesFactory.getQueryParametersFromSearchCriteria(searchCriteria),
            estimationUuid: estimation.uuid,
        });
    }

    getBySearchCriteria$(searchCriteria: SearchCriteria): Observable<CReferences> {
        return this.get$(this._cReferencesFactory.getQueryParametersFromSearchCriteria(searchCriteria));
    }
}
