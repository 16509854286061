export default `
<form ng-submit="$ctrl.onChangeFilter($ctrl.keywords)" class="search-keywords">
  <div class="input-group">
    <input type="search" class="form-control" ng-model="$ctrl.keywords" placeholder="Rechercher ...">

    <span class="input-group-btn">
      <button ng-if="!!$ctrl.keywords" type="button" class="btn btn-o btn-null search-keywords-remove"
              ng-click="$ctrl.removeKeywords()"><fa-icon [icon]="'times'"></fa-icon></button>

      <button ng-if="!!$ctrl.help" type="button" popover-trigger="'outsideClick'" uib-popover-template="$ctrl.help"
              popover-placement="bottom-right" class="btn btn-o btn-null search-keywords-help">
        <span class="badge badge-info">?</span>
      </button>

      <button type="submit" class="btn btn-default"><fa-icon [icon]="'search'"></fa-icon></button>
    </span>
  </div>
</form>
`;
