import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {
    DossierFileContactFactory
} from '@models/dossiers/dossier/files/file/contacts/contact/dossier-file-contact.factory';
import {
    DossierFileContactsApiService
} from '@models/dossiers/dossier/files/file/contacts/dossier-file-contacts.api.service';
import {
    ICDossierFileContactsApi, ICDossierFileContactsQueryParameters
} from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.interfaces';
import CDossierFileContacts
    from '@models/dossiers/dossier/files/file/contacts/collection/dossier-file-contacts.collection.model';

@Injectable({providedIn: 'root'})
export class CDossierFileContactsFactory {
    private _dossierFileContactFactory = inject(DossierFileContactFactory);
    private _dossierFileContactsApiService = inject(DossierFileContactsApiService);

    create(cDossierFileContactsApi: ICDossierFileContactsApi): CDossierFileContacts {
        const cDossierFileContacts = new CDossierFileContacts();

        cDossierFileContacts.links = new Links(cDossierFileContactsApi._links);
        cDossierFileContacts.page = cDossierFileContactsApi.page;
        cDossierFileContacts.pages = cDossierFileContactsApi.pages;
        cDossierFileContacts.perPage = cDossierFileContactsApi.limit;
        cDossierFileContacts.total = cDossierFileContactsApi.total;
        if (cDossierFileContactsApi._embedded?.items) {
            cDossierFileContactsApi._embedded.items.filter(dossierDossierFileContactApi => dossierDossierFileContactApi)
                .map(dossierDossierFileContactApi => cDossierFileContacts.results.push(this._dossierFileContactFactory.create(dossierDossierFileContactApi)));
        }

        return cDossierFileContacts;
    }

    getByLink$(link: string, queryParameters?: ICDossierFileContactsQueryParameters): Observable<CDossierFileContacts> {
        return this._dossierFileContactsApiService.getCollectionByLink$(link, queryParameters).pipe(map(cDossierFileContactsApi => this.create(cDossierFileContactsApi)));
    }

    getNext$(cDossierFileContacts: CDossierFileContacts): Observable<CDossierFileContacts> {
        return this._dossierFileContactsApiService.getCollectionByLink$(cDossierFileContacts.links.next).pipe(map(cDossierFileContactsApi => this.create(cDossierFileContactsApi)));
    }
}
