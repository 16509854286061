import {IModel} from '@models/model.interfaces';

export default abstract class AModel implements IModel {
    static readonly STATUT_NEW = 'new';
    private _linkSelf!: string;
    private _uuid: string;

    constructor(uuid?: string) {
        this._uuid = uuid!;
    }

    get linkSelf(): string {
        return this._linkSelf;
    }

    set linkSelf(value: string) {
        this._linkSelf = value;
    }

    get uuid(): string {
        return this._uuid;
    }
}
