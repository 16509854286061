<div class="bg-modules-fond tw-bg-cover">
  <div class="tw-min-h-screen tw-h-full tw-w-full tw-px-6">
    <div class="tw-pt-24 tw-pb-32 tw-space-y-4 tw-text-center">
      <h3 class="text-noty-blue tw-font-bold">Utilisez <app-noteo-icon-text-noty/></h3>

      @if (publicite) {
        <h4 class="text-noty-blue tw-font-bold">et</h4>

        <h1 class="text-noty-blue tw-font-bold">{{ publicite.titre }}</h1>
      }
    </div>

    @if (publicite) {
      <ul class="tw-max-w-3.5xl lg:tw-max-w-10xl tw-m-auto tw-mt-8 tw-px-4 tw-pb-32 tw-grid tw-grid-cols-1 tw-gap-12 lg:tw-gap-6 tw-gap-y-24 sm:tw-grid-cols-2 lg:tw-grid-cols-4">
        @for (argument of publicite.arguments; track $index) {
          <li class="tw-relative tw-text-center tw-bg-white tw-rounded-lg tw-shadow tw-border tw-border-solid">
            <div class="tw-absolute tw-inset-x-0 -tw-top-10 tw-flex tw-justify-center">
              <div class="tw-flex tw-items-center tw-justify-center tw-h-20 tw-w-20 bg-noty-green tw-rounded-full tw-text-white">
                <fa-icon [icon]="argument.icon" size="2x"/>
              </div>
            </div>

            <div class="tw-mt-10">
              <h3 class="tw-flex tw-items-center tw-justify-center tw-m-0 tw-h-56 tw-py-12 tw-px-8 tw-text-gray-500 tw-font-bold">
                {{ argument.titre }}
              </h3>

              <div class="tw-bg-gray-200 tw-h-44 sm:tw-h-60 lg:tw-h-[22rem] xl:tw-h-64 tw-p-6 tw-text-xl 2xl:tw-text-2xl tw-text-left"
                   [innerHTML]="argument.description"></div>
            </div>
          </li>
        }
      </ul>
    }

    @if (publicite) {
      <div class="tw-hidden md:tw-block xl:tw-mt-12 tw-pt-24 tw-pb-32 tw-px-32">
        <div class="tw-relative tw-w-full tw-aspect-double">
          <img [ngSrc]="publicite.imgList" class="tw-max-w-full tw-rounded-3xl tw-shadow-2xl" fill>
        </div>
      </div>
    }

    <div class="tw-mt-12 tw-pb-40 sm:tw-pb-28 md:tw-pb-40 lg:tw-pb-24 xl:tw-pb-32 2xl:tw-pb-36 lg:tw-ml-36 xl:tw-ml-[29rem] 2xl:tw-ml-[39rem] tw-text-xl sm:tw-text-2xl md:tw-text-3xl lg:tw-text-4xl">
      <div class="tw-mb-4 tw-font-bold">Besoin d'information ?</div>

      <esk-contact type="commerce" class="tw-block sm:tw-pr-40 md:tw-pr-0"/>
    </div>
  </div>
</div>
