import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {DossierFileFactory} from '@models/dossiers/dossier/files/file/dossier-file.factory';
import {DossierFilesApiService} from '@models/dossiers/dossier/files/dossier-files.api.service';
import CDossierFiles from '@models/dossiers/dossier/files/collection/dossier-files.collection.model';
import {ICDossierFilesApi} from '@models/dossiers/dossier/files/collection/dossier-files.collection.interfaces';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';

@Injectable({providedIn: 'root'})
export class CDossierFilesFactory {
    private _dossierFileFactory = inject(DossierFileFactory);
    private _dossierFilesApiService = inject(DossierFilesApiService);

    create(cDossierFilesApi: ICDossierFilesApi): CDossierFiles {
        const cDossierFiles = this.createVirgin();

        cDossierFiles.links = new Links(cDossierFilesApi._links);
        cDossierFiles.page = cDossierFilesApi.page;
        cDossierFiles.pages = cDossierFilesApi.pages;
        cDossierFiles.perPage = cDossierFilesApi.limit;
        cDossierFiles.total = cDossierFilesApi.total;
        if (cDossierFilesApi._embedded?.items) {
            cDossierFilesApi._embedded.items.filter(dossierDossierFileApi => dossierDossierFileApi)
                .map(dossierDossierFileApi => cDossierFiles.results.push(this._dossierFileFactory.create(dossierDossierFileApi)));
        }

        return cDossierFiles;
    }

    createVirgin(): CDossierFiles {
        const cDossierFiles = new CDossierFiles();

        cDossierFiles.links = new Links();

        return cDossierFiles;
    }

    get$(dossier: ADossier): Observable<CDossierFiles> {
        return this._dossierFilesApiService.getCollection$(dossier.dossierType, dossier.id.toString()).pipe(map(cDossierFilesApi => this.create(cDossierFilesApi)));
    }

    getByLink$(link: string): Observable<CDossierFiles> {
        return this._dossierFilesApiService.getCollectionByLink$(link).pipe(map(cDossierFilesApi => this.create(cDossierFilesApi)));
    }

    getNext$(cDossierFiles: CDossierFiles): Observable<CDossierFiles> {
        return this.getByLink$(cDossierFiles.links.next);
    }
}
