import {inject, Injectable} from '@angular/core';
import {UserFactory} from '@models/users/user/user.factory';
import {BehaviorSubject, combineLatest, Observable, of, switchMap} from 'rxjs';
import User from '@models/users/user/user.model';
import {filter, map, take, tap} from 'rxjs/operators';
import {UserClientMetadataService} from '@models/users/user/client-metadata/user-client-metadata.service';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {UserPhotoFactory} from '@models/users/user/photo/user-photo.factory';
import {UserSettingsFactory} from '@models/users/user/settings/user-settings.factory';
import {AuthService} from '@core/auth/core/auth.service';
import {ConditionConst} from '@shared/constants';
import Commune from '@models/communes/commune/commune.model';
import {CommuneFactory} from '@models/communes/commune/commune.factory';

@Injectable({providedIn: 'root'})
export class UserService {
    private _authService = inject(AuthService);
    private _communeFactory = inject(CommuneFactory);
    private _cUsersFactory = inject(CUsersFactory);
    private _errorConfigurationService = inject(ErrorConfigurationService);
    private _userClientMetadataService = inject(UserClientMetadataService);
    private _userFactory = inject(UserFactory);
    private _userPhotoFactory = inject(UserPhotoFactory);
    private _userSettingsFactory = inject(UserSettingsFactory);
    private _currentSource = new BehaviorSubject<User>(undefined!);

    get current$(): Observable<User> {
        return this._currentSource.asObservable();
    }

    get last$(): Observable<User> {
        return this.current$.pipe(filter(currentUser => !!currentUser), take(1));
    }

    currentHasRole$(role: string): Observable<boolean> {
        return this.current$.pipe(map(current => current?.hasRole(role)), map(hasRole => !!hasRole));
    }

    getCurrentCommune$(): Observable<Commune> {
        return this.last$.pipe(switchMap(currentUser => this._communeFactory.getByLink$(currentUser.site.linkCommune)));
    }

    initCurrent(): void {
        this._currentSource.next(undefined!);
        this._userFactory.getCurrent$().pipe(
            tap(currentUser => this._errorConfigurationService.setUser(currentUser)),
            switchMap(currentUser => this.setAddedInformation$(currentUser)),
            take(1),
        ).subscribe({
            next: currentUser => this._currentSource.next(currentUser),
            error: () => this._authService.logout(),
        });
    }

    logout(): void {
        this._currentSource.next(undefined!);
    }

    removeCurrentPhoto$(): Observable<void> {
        return this.last$.pipe(switchMap(currentUser => this._userPhotoFactory.removeCurrentPhoto$().pipe(
            tap(_ => currentUser.photo = undefined!),
            tap(_ => currentUser.setLinkPhoto()),
        )));
    }

    saveCurrent$(): Observable<User> {
        return this.last$.pipe(
            switchMap(currentUser => this._userFactory.save$(currentUser)),
            switchMap(currentUser => this.setAddedInformation$(currentUser)),
            tap(currentUser => this._currentSource.next(currentUser)),
        );
    }

    setAddedInformation$(user: User): Observable<User> {
        let defaultNotaire$ = of(user);

        if (!user.hasRoleNotaire()) {
            defaultNotaire$ = this._cUsersFactory.get$({
                etude: {condition: ConditionConst.IN, values: [user.etudeId]},
                role: {condition: ConditionConst.CONTAINS_SOME, values: [User.roles.NOTAIRE]},
            }).pipe(map(cUserNotaires => cUserNotaires.results[0]));
        }

        return combineLatest([
            defaultNotaire$.pipe(tap(defaultNotaire => user.defaultNotaire = defaultNotaire)),
            this._userClientMetadataService.getOneFromUser$(user).pipe(tap(userClientMetadata => user.clientMetadata = userClientMetadata)),
            this._userSettingsFactory.get$(user).pipe(tap(userSettings => user.settings = userSettings)),
        ]).pipe(map(_ => user));
    }
}
