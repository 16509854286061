import AModel from '@models/model.abstract';

export default class EtudeConstraint extends AModel {
    private _expression!: string;
    private _message!: string;
    private _name!: string;
    private _natures!: string[];
    private _path!: string;

    get expression(): string {
        return this._expression;
    }

    set expression(value: string) {
        this._expression = value;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get natures(): string[] {
        return this._natures;
    }

    set natures(value: string[]) {
        this._natures = value;
    }

    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
    }
}
