import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {BienCadastreFactory} from '@models/bien/cadastres/cadastre/bien-cadastre.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BienCadastresService {
    private _bienCadastreFactory = inject(BienCadastreFactory);

    save$(dossierBien: ADossierBien): Observable<void> {
        if (dossierBien.bien.cadastres.length <= 0) {
            return of(undefined);
        }

        return combineLatest(dossierBien.bien.cadastres.map(bienCadastre => this._bienCadastreFactory.save$(dossierBien, bienCadastre)))
            .pipe(map(() => undefined));
    }
}
