import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from 'lodash';
import {IModule, IWindowService} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgBienDossier, NgTemplate} from '@legacy/app/managers/ressources';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {
    NgDictionariesManager, NgEmailManager, NgEstimationManager, NgLocationManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {BienFactory} from '@models/bien/bien.factory';
import {
    BienPerformanceEnergetiqueService
} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {firstValueFrom, Observable, of} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import DateFormat from '@shared/date/date.format';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Vente from '@models/ventes/vente/vente.model';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DiagnosticStatutsConst} from '@shared/diagnostic/diagnostic.constants';
import Estimation from '@models/estimations/estimation/estimation.model';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {DocumentService} from '@models/documents/document/document.service';
import {
    CEtudePasserellesFactory
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.factory';
import {VentePasserellesService} from '@models/ventes/vente/passerelles/vente-passerelles.service';
import Location from '@models/locations/location/location.model';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import {LocationPasserellesService} from '@models/locations/location/passerelles/location-passerelles.service';
import {LocationPasserelleFactory} from '@models/locations/location/passerelles/passerelle/location-passerelle.factory';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

type NgSelectedTemplates = {
    documentTemplate: NgTemplate
    courrierTemplate: NgTemplate
}

export default function getBienDetails(module: IModule): void {
    (angular => {
        'use strict';

        /**
         * @example <esk-bien-details></esk-bien-details>
         */
        module.component('eskBienDetails', {
            bindings: {
                dossiers: '=',
                currentId: '=currentDossierId',
                loader: '=',
                replaceLocation: '=',
                backToList: '=',
                setInterest: '=',
                workingDossier: '=?'
            },
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/components/item/details.html'
        });

        /**
         * Controller for Dossiers view
         *
         * @param BiensUtils
         * @param SoqSweetAlert
         * @param Ng2RouterStateForNgService
         * @param SoqModalesManager
         * @param $translate
         * @param $window
         * @param VenteManager
         * @param LocationManager
         * @param EstimationManager
         * @param Ng2BienFactory
         * @param Ng2BienPerformanceEnergetiqueService
         * @param DictionariesManager
         * @param Ng2DictionaryItemService
         * @param Ng2DossierBienFactory
         * @param Ng2TemplateFactory
         * @param Ng2DocumentService
         * @param Ng2CEtudePasserellesFactory
         * @param Ng2LocationPasserellesService
         * @param Ng2LocationPasserelleFactory
         * @param Ng2VentePasserellesService
         * @param Ng2VentePasserelleFactory
         */
        Controller.$inject = ['BiensUtils', 'SoqSweetAlert', 'Ng2RouterStateForNgService', 'SoqModalesManager', 'EmailManager', '$translate', '$window', 'VenteManager', 'LocationManager', 'EstimationManager', 'Ng2BienFactory', 'Ng2BienPerformanceEnergetiqueService', 'DictionariesManager', 'Ng2DictionaryItemService', 'Ng2DossierBienFactory', 'Ng2TemplateFactory', 'Ng2DocumentService', 'Ng2CEtudePasserellesFactory', 'Ng2LocationPasserellesService', 'Ng2LocationPasserelleFactory', 'Ng2VentePasserellesService', 'Ng2VentePasserelleFactory'];
        function Controller(this: any,
                            biensUtils: NgBienUtils,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            $window: IWindowService,
                            venteManager: NgVenteManager,
                            locationManager: NgLocationManager,
                            estimationManager: NgEstimationManager,
                            ng2BienFactory: BienFactory,
                            ng2BienPerformanceEnergetiqueService: BienPerformanceEnergetiqueService,
                            dictionariesManager: NgDictionariesManager,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2DossierBienFactory: DossierBienFactory,
                            ng2TemplateFactory: TemplateFactory,
                            ng2DocumentService: DocumentService,
                            ng2CEtudePasserellesFactory: CEtudePasserellesFactory,
                            ng2LocationPasserellesService: LocationPasserellesService,
                            ng2LocationPasserelleFactory: LocationPasserelleFactory,
                            ng2VentePasserellesService: VentePasserellesService,
                            ng2VentePasserelleFactory: VentePasserelleFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.showField = showField;
            $ctrl.showPanel = showPanel;
            $ctrl.getDossier = getDossier;
            $ctrl.restoration = restoration;
            $ctrl.print = print;
            $ctrl.advancedPrint = advancedPrint;
            $ctrl.send = send;
            $ctrl.updateInterest = updateInterest;
            $ctrl.contactConfrere = contactConfrere;
            $ctrl.openVirtualVisit = openVirtualVisit;
            $ctrl.editBonVisite = editBonVisite;
            $ctrl.editOffreAchat = editOffreAchat;
            $ctrl.editDossierBien = editDossierBien;
            $ctrl.getNumber = getNumber;
            $ctrl.getEtudePasserelle = getEtudePasserelle;
            $ctrl.diffuser = diffuser;
            $ctrl.retirer = retirer;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES = Vente.archiveNegocieeRaisons.AUTRES;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE = Vente.archiveNegocieeRaisons.PORTEFEUILLE;
            $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU = Vente.archiveNegocieeRaisons.RESEAU;
            $ctrl.CODE_VENTE_BROUILLON = Vente.statuts.BROUILLON;
            $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS = Vente.chargesType.HONO_NEGO_INCLUS;
            $ctrl.DISINTERESTED = DemandeurRechercheResultat.interests.DISINTERESTED;
            $ctrl.DISINTERESTED_EXCEPT_PRICE = DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.ESTIMATION_VALEUR_VENALE_TYPES = Estimation.valeurVenaleTypes;
            $ctrl.STATUT_ARCHIVE = ADossierBien.abstractBienStatuts.ARCHIVE;
            $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE = Vente.archiveTypes.NEGOCIEE;
            $ctrl.VENTE_ARCHIVE_TYPES_NON_NEGOCIEE = Vente.archiveTypes.NON_NEGOCIEE;
            $ctrl.INTERACTIVE = Vente.types.INTERACTIVE;
            $ctrl.VIAGER = Vente.types.VIAGER;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.inReferencesArchivees = ng2RouterStateForNgService.current.url.includes('/app/estimations/references/details/');
                $ctrl.visualTab = 0;
                $ctrl.getDossier(findIndex($ctrl.dossiers.collection, {id: +($ctrl.currentId?.toString().replace(',', '.'))}));
            }

            /**
             * Display field
             *
             * @param field
             * @param value
             * @param options
             * @returns {boolean}
             */
            function showField(field: string, value: unknown, options = {accepted0: false}) {
                if (!angular.isObject($ctrl.dossier) || !angular.isObject($ctrl.dossier.bien) || !angular.isObject($ctrl.dossier.bien.option)) {
                    return false;
                }

                if (!biensUtils.showFieldForBien($ctrl.dossier.bien, field)) {
                    return false;
                }

                switch (field) {
                    case 'assainissement' :
                        return !(!$ctrl.dossier.bien.option.aucunAssainissement && !$ctrl.dossier.bien.option.assainissementNonConforme && !(angular.isArray($ctrl.dossier.bien.assainissements) && $ctrl.dossier.bien.assainissements.length));

                    case 'chauffage' :
                        return !(!$ctrl.dossier.bien.option.chauffageCollectif && !(angular.isArray($ctrl.dossier.bien.chauffages) && $ctrl.dossier.bien.chauffages.length));

                    case 'performanceEnergetique' :
                        return true;
                }

                if (!value && options.accepted0 && (value === 0 || value === '0')) {
                    return true;
                }

                if (value === true || value === false) {
                    return value;
                }

                try {
                    const valueNumber = parseFloat((value as string).toString().replace(',', '.'));

                    return isNaN(valueNumber) || !!valueNumber;
                } catch (e) {
                    return !!value;
                }
            }

            /**
             * Display panel
             *
             * @param panel
             * @returns {boolean}
             */
            function showPanel(panel: string) {
                let verifSupp;

                if (!angular.isObject($ctrl.dossier) || !angular.isObject($ctrl.dossier.bien) || !angular.isObject($ctrl.dossier.bien.option)) {
                    return false;
                }

                if (!biensUtils.showPanelForBien($ctrl.dossier.bien, panel)) {
                    return false;
                }

                switch (panel) {
                    case 'accessibilite' :
                        return !(!(angular.isArray($ctrl.dossier.bien.commodites) && ($ctrl.dossier.bien.commodites.length)) && !$ctrl.dossier.bien.option.plainPied && !$ctrl.dossier.bien.option.pieceEauRdc && !$ctrl.dossier.bien.option.chambreRdc && !$ctrl.dossier.bien.option.accesHandicape && !$ctrl.dossier.bien.option.commentsAccessibiliteEtEnvironnement);

                    case 'agencements' :
                        return !(!$ctrl.dossier.bien.option.cuisineEquipee && !$ctrl.dossier.bien.option.cheminee && !$ctrl.dossier.bien.option.ascenseur && !$ctrl.dossier.bien.option.dernierEtage && !$ctrl.dossier.bien.option.balcon && !$ctrl.dossier.bien.option.grenier && !$ctrl.dossier.bien.option.comblesAmenageables && !$ctrl.dossier.bien.option.cave && !$ctrl.dossier.bien.option.sousSol && !$ctrl.dossier.bien.option.veranda && !$ctrl.dossier.bien.option.terrasse && !$ctrl.dossier.bien.option.cour && !$ctrl.dossier.bien.option.piscine && !$ctrl.dossier.bien.option.annexe && !$ctrl.dossier.bien.option.garage && !$ctrl.dossier.bien.option.nombrePlacesParking && !$ctrl.dossier.bien.option.citerneGazLocation && !$ctrl.dossier.bien.option.citerneGazPleinePropriete && !$ctrl.dossier.bien.option.cuveFuelAerienne && !$ctrl.dossier.bien.option.cuveFuelEnterree && !$ctrl.dossier.bien.option.cuveInactiveDegazee && !$ctrl.dossier.bien.option.cuveInactiveNeutralizee && !$ctrl.dossier.bien.option.commentsAgencementExterieur);

                    case 'agencementsInterieurs' :
                        verifSupp = biensUtils.showFieldForBien($ctrl.dossier.bien, 'nombrePieces') ? $ctrl.dossier.bien.option.nombrePieces : $ctrl.dossier.bien.option.nombreLogements;

                        return !(!verifSupp && !$ctrl.dossier.bien.option.nombreEtages && !$ctrl.dossier.bien.option.numeroEtage && !$ctrl.dossier.bien.option.nombreChambres && !$ctrl.dossier.bien.option.surfaceSejour && !$ctrl.dossier.bien.option.hauteurSousPlafond && !$ctrl.dossier.bien.option.nombreDouches && !$ctrl.dossier.bien.option.nombreSallesBains && !$ctrl.dossier.bien.option.nombreSallesEau && !$ctrl.dossier.bien.option.nombreBains && !$ctrl.dossier.bien.option.nombreWc && !$ctrl.dossier.bien.option.wcBroyeur && !$ctrl.dossier.bien.option.alarmeLocation && !$ctrl.dossier.bien.option.alarmePleinePropriete && !$ctrl.dossier.bien.option.detecteurFumee && !(angular.isArray($ctrl.dossier.bien.connexionsInternet) && $ctrl.dossier.bien.connexionsInternet.length) && !$ctrl.dossier.bien.option.commentsAgencementInterieur);

                    case 'autres' :
                        return !(!$ctrl.dossier.bien.option.exceptionnel && !$ctrl.dossier.bien.option.commentsConstruction && !$ctrl.dossier.bien.vues?.length);

                    case 'batiment' :
                        return !(!$ctrl.dossier.bien.option.anneeConstruction && !$ctrl.dossier.bien.option.epoqueConstruction && !$ctrl.dossier.bien.option.etat && !$ctrl.dossier.bien.option.nombreEtagesBatiment && !$ctrl.dossier.bien.option.surfaceCarrez && !$ctrl.dossier.bien.option.exposition && !$ctrl.dossier.bien.option.mitoyennete && !$ctrl.dossier.bien.option.aucunChauffage && !(angular.isArray($ctrl.dossier.bien.materiauxConstruction) && $ctrl.dossier.bien.materiauxConstruction.length) && !(angular.isArray($ctrl.dossier.bien.charpentes) && $ctrl.dossier.bien.charpentes.length) && !(angular.isArray($ctrl.dossier.bien.materiauxCouverture) && $ctrl.dossier.bien.materiauxCouverture.length) && !(angular.isArray($ctrl.dossier.bien.isolations) && $ctrl.dossier.bien.isolations.length) && !(angular.isArray($ctrl.dossier.bien.huisseries) && $ctrl.dossier.bien.huisseries.length) && !(angular.isArray($ctrl.dossier.bien.volets) && $ctrl.dossier.bien.volets.length) && !$ctrl.dossier.bien.option.commentsBatiment);

                    case 'couts' :
                        verifSupp = angular.isObject($ctrl.dossier.bien.metadata) && angular.isObject($ctrl.dossier.bien.metadata.couts)
                            && !!$ctrl.dossier.bien.metadata.couts.comments;

                        return !($ctrl.dossier.bien.charges.map(charge => !charge.isEmpty()).length <= 0 && !verifSupp);

                    case 'cadastres' :
                        return !(!(angular.isArray($ctrl.dossier.bien.cadastres) && ($ctrl.dossier.bien.cadastres.length - $ctrl.dossier.bien._esk.interne)));

                    case 'diagnostics' :
                        verifSupp = $ctrl.dossier.bien.performanceEnergetique.statutDpe && ($ctrl.dossier.bien.performanceEnergetique.statutDpe !== DiagnosticStatutsConst.DISPONIBLE || ($ctrl.dossier.bien.performanceEnergetique.statutDpe === DiagnosticStatutsConst.DISPONIBLE && ($ctrl.dossier.bien.performanceEnergetique.consommation >= 0 || $ctrl.dossier.bien.performanceEnergetique.emissions >= 0)));

                        return !(!(angular.isArray($ctrl.dossier.bien.diagnostics) && ($ctrl.dossier.bien.diagnostics.length - 1)) && !verifSupp);

                    case 'habitation' :
                        verifSupp = $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE && angular.isObject($ctrl.dossier.bien.metadata) && angular.isObject($ctrl.dossier.bien.metadata.occupation)
                            && !!$ctrl.dossier.bien.metadata.occupation.dateDisponibilite && !!$ctrl.dossier.bien.metadata.occupation.chargesActuelles && !!$ctrl.dossier.bien.metadata.occupation.loyerActuel;

                        return !(!$ctrl.dossier.bien.option.usage && !$ctrl.dossier.bien.option.residence && !$ctrl.dossier.bien.option.residenceService && !($ctrl.dossier.bien.option.occupation && $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE) && !verifSupp);

                    case 'loiAlur' :
                        verifSupp = angular.isObject($ctrl.dossier.bien.metadata) && angular.isObject($ctrl.dossier.bien.metadata.loiAlur)
                            && !!$ctrl.dossier.bien.metadata.loiAlur.loiAlur;

                        return !(!verifSupp);

                    case 'proprietaires' :
                        return !(!$ctrl.dossier.bien.contactsGroup.id);

                    case 'terrain' :
                        return !(!$ctrl.dossier.bien.option.surfaceCadastrale && !$ctrl.dossier.bien.option.surfaceBatie && !$ctrl.dossier.bien.option.surfaceNonBatie && !$ctrl.dossier.bien.option.declivite && !$ctrl.dossier.bien.option.surfacePlancher && !$ctrl.dossier.bien.option.longueurFacade && !$ctrl.dossier.bien.option.zoneInondable && !$ctrl.dossier.bien.option.pointEauVille && !$ctrl.dossier.bien.option.pointEauCampagne && !$ctrl.dossier.bien.option.panneauxPhotovoltaiques && !(angular.isArray($ctrl.dossier.bien.clotures) && $ctrl.dossier.bien.clotures.length) && !$ctrl.dossier.bien.option.servitude && !$ctrl.dossier.bien.option.commentsTerrain);

                    case 'urbanisme' :
                        return !(!$ctrl.dossier.bien.option.urbanismeZonePLU && !$ctrl.dossier.bien.option.urbanismeComments);

                    case 'viabilisation' :
                        return !(!$ctrl.dossier.bien.option.viabilisable && !$ctrl.dossier.bien.option.viabilisationEau && !$ctrl.dossier.bien.option.viabilisationElectricite && !$ctrl.dossier.bien.option.viabilisationTelecom && !$ctrl.dossier.bien.option.viabilisationFibre && !$ctrl.dossier.bien.option.viabilisationVoirie && !$ctrl.dossier.bien.option.viabilisationGaz && !$ctrl.dossier.bien.option.aucunAssainissement && !$ctrl.dossier.bien.option.assainissementNonConforme && !$ctrl.dossier.bien.option.viabilisationAssainissement && !$ctrl.dossier.bien.option.viabilisationAssainissementCollectifRaccorde && !(angular.isArray($ctrl.dossier.bien.assainissements) && $ctrl.dossier.bien.assainissements.length > 0) && !$ctrl.dossier.bien.option.viabilisationComments);
                }

                return true;
            }

            /**
             * Get current dossier
             *
             * @param index
             */
            function getDossier(index: number) {
                let manager;

                $ctrl.loader.updating = true;

                if (index < 0 || index >= $ctrl.dossiers.collection.length) {
                    index = 0;
                }
                $ctrl.currentIndex = index;

                if (angular.isFunction($ctrl.replaceLocation) && $ctrl.dossiers.collection[$ctrl.currentIndex]) {
                    $ctrl.replaceLocation($ctrl.dossiers.collection[$ctrl.currentIndex]);
                }

                $ctrl.unlockData = false;
                if ($ctrl.dossiers.collection[$ctrl.currentIndex]?._esk?.detail) {
                    setDossier();
                    // Permet à tous les composants de se mettre correctement à jour
                    setTimeout(() => $ctrl.loader.updating = false);

                    return;
                }

                switch ($ctrl.dossiers.collection[$ctrl.currentIndex]?._esk?.typeDossier) {
                    case DossierBienTypesConst.VENTE :
                        manager = venteManager;
                        break;
                    case DossierBienTypesConst.LOCATION :
                        manager = locationManager;
                        break;
                    case DossierBienTypesConst.ESTIMATION :
                        manager = estimationManager;
                        break;
                    default:
                        if (!$ctrl.dossiers.collection[$ctrl.currentIndex]) {
                            throw new Error("'" + $ctrl.currentIndex + "' dans une liste de " + $ctrl.dossiers.collection.length + ".");
                        } else if (!$ctrl.dossiers.collection[$ctrl.currentIndex]._esk) {
                            throw new Error("Dossier '" + $ctrl.dossiers.collection[$ctrl.currentIndex].reference + "' mal initialisé.");
                        } else {
                            throw new Error("'" + $ctrl.dossiers.collection[$ctrl.currentIndex]._esk.typeDossier + "' inconnu.");
                        }
                }

                manager.getOneById($ctrl.dossiers.collection[$ctrl.currentIndex].id).then(dossier => dossier as NgBienDossier).then(dossier => {
                    dossier._esk.detail = true;
                    dossier._esk.result = $ctrl.dossiers.collection[$ctrl.currentIndex]._esk.result;
                    dossier._esk.routing = $ctrl.dossiers.collection[$ctrl.currentIndex]._esk.routing;
                    $ctrl.dossiers.collection[$ctrl.currentIndex] = dossier;
                    setDossier();
                }).finally(() => $ctrl.loader.updating = false);

                /**
                 * Set dossier and related options
                 */
                function setDossier() {
                    $ctrl.dossier = $ctrl.dossiers.collection[$ctrl.currentIndex];
                    $ctrl.bien = ng2BienFactory.ngCreate($ctrl.dossier.bien);
                    $ctrl.isDiagV2 = ng2BienPerformanceEnergetiqueService.isDiagV2($ctrl.bien);
                    $ctrl.performanceEnergetiqueStatutDpe = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.DIAGNOSTIC_STATUTS, $ctrl.dossier.bien.performanceEnergetique.statutDpe));

                    const bienDossier = ng2DossierBienFactory.ngCreate($ctrl.dossier);

                    if ($ctrl.dossier.statut === ADossierBien.abstractBienStatuts.ARCHIVE) {
                        if (bienDossier.isLocation()) {
                            $ctrl.archive = {comments: $ctrl.dossier.archiveComments};
                        } else {
                            ($ctrl.dossier.getArchiveInfos() as Observable<unknown>).pipe(
                                map(archiveInfos => archiveInfos as { dateVente: string }),
                                tap(archiveInfos => $ctrl.archiveDateVente = DateFormat.toDate(archiveInfos.dateVente)),
                            ).subscribe(archiveInfos => $ctrl.archive = archiveInfos);
                        }
                    }

                    $ctrl.bienDossier = bienDossier;
                    $ctrl.options = {};
                    if (bienDossier.isLocation() || bienDossier.isVente()) {
                        if (bienDossier.isLocation()) {
                            $ctrl.options.sendingCategoryCode = 'fiche-location';
                            $ctrl.options.categoriesCode = [$ctrl.options.sendingCategoryCode];
                            if ($ctrl.dossier.bien._esk.interne) {
                                $ctrl.options.categoriesCode.push('fiche-location-confidentielle');
                            } else {
                                $ctrl.options.categoriesCode.push('fiche-location-confrere');
                            }
                        }

                        if (bienDossier.isVente()) {
                            $ctrl.options.sendingCategoryCode = Vente.templates.FICHE;
                            $ctrl.options.categoriesCode = [$ctrl.options.sendingCategoryCode];
                            if ($ctrl.dossier.bien._esk.interne) {
                                $ctrl.options.categoriesCode.push(Vente.templates.FICHE_CONFIDENTIELLE);
                            } else {
                                $ctrl.options.categoriesCode.push(Vente.templates.FICHE_CONFRERE);
                            }
                        }

                        if (!bienDossier.interne && !bienDossier.isArchived()) {
                            ng2CEtudePasserellesFactory.get$().pipe(
                                map(cEtudePasserelles => cEtudePasserelles.getNetworkAllowed()),
                                tap(networkAllowedEtudePasserelles => $ctrl.networkAllowedEtudePasserelles = networkAllowedEtudePasserelles),
                                switchMap(networkAllowedEtudePasserelles => {
                                    if (bienDossier.isLocation()) {
                                        return ng2LocationPasserellesService.getFromEtudePasserelles$(bienDossier as Location, networkAllowedEtudePasserelles);
                                    }

                                    if (bienDossier.isVente()) {
                                        return ng2VentePasserellesService.getFromEtudePasserelles$(bienDossier as Vente, networkAllowedEtudePasserelles);
                                    }

                                    return of([]);
                                }),
                                take(1),
                            ).subscribe(bienDossierPasserelles => $ctrl.bienDossierPasserelles = bienDossierPasserelles);
                        }
                    } else if (bienDossier.isEstimation()) {
                        $ctrl.options.sendingCategoryCode = 'fiche-estimation';
                        $ctrl.options.categoriesCode = [
                            $ctrl.options.sendingCategoryCode,
                            'fiche-estimation-confidentielle',
                        ];
                        $ctrl.advancedPrint = undefined!;
                    }
                }
            }

            /**
             * Restore current Dossier
             */
            function restoration() {
                $ctrl.loader.action = 'DEFAULT';
                soqSweetAlert.warningConfirmation($translate.instant($ctrl.dossier._esk.typeDossier + ".restoration.confirm.TITLE", {reference: $ctrl.dossier.bien.reference}), $translate.instant($ctrl.dossier._esk.typeDossier + ".restoration.confirm.MESSAGE"), {
                    input: 'checkbox',
                    customClass: {input: 'checkbox check-green text-bold'},
                    inputPlaceholder: $translate.instant($ctrl.dossier._esk.typeDossier + ".restoration.confirm.INPUT"),
                    confirmButtonText: $translate.instant($ctrl.dossier._esk.typeDossier + ".restoration.confirm.CONFIRMATION")
                }).then(keepOwners => $ctrl.dossier.clone(keepOwners).then((clonedDossier: NgBienDossier) => {
                    ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.dossier._esk.typeDossier + 's/' + clonedDossier.id + '/edit/general');
                })).finally(() => $ctrl.loader.action = undefined!);
            }

            /**
             * Print fiche
             *
             * @param ngTemplate
             */
            function print(ngTemplate: NgTemplate) {
                if (!ngTemplate) {
                    return;
                }

                $ctrl.loader.action = 'PRINT';
                ng2DocumentService.readFromDossierBien$($ctrl.bienDossier, ng2TemplateFactory.ngCreate(ngTemplate))
                    .pipe(take(1))
                    .subscribe({complete: () => $ctrl.loader.action = undefined!});
            }

            /**
             * Advanced print fiche
             */
            function advancedPrint() {
                $ctrl.loader.action = 'PRINT';
                soqModalesManager.open<NgSelectedTemplates>('BienModalAdvancedPrint', {
                    resolve: {dossier: $ctrl.dossier}
                }).then((selectedTemplates: NgSelectedTemplates) => firstValueFrom(
                    ng2DocumentService.readFromDossierBien$($ctrl.bienDossier,
                        ng2TemplateFactory.ngCreate(selectedTemplates.documentTemplate),
                        selectedTemplates.courrierTemplate ? ng2TemplateFactory.ngCreate(selectedTemplates.courrierTemplate) : null!,
                        $ctrl.bienDossier.contactsGroup?.getReferent()?.id)
                )).finally(() => $ctrl.loader.action = undefined!);
            }

            /**
             * Send fiche
             */
            function send() {
                $ctrl.loader.action = 'SEND';
                biensUtils.getMediasFiche($ctrl.dossier._esk.typeDossier, [$ctrl.dossier]).then(attachments => {
                    const dossiers = [] as unknown[];
                    let currentDossier = $ctrl.workingDossier;
                    let addDemandeurMessage = '';
                    let contactsGroup = {
                        formuleIntroduction: ContactsGroup.default.FORMULE_INTRODUCTION,
                        formulePolitesse: ContactsGroup.default.FORMULE_POLITESSE,
                        getRecipients: () => []
                    };

                    if (angular.isObject(currentDossier)) {
                        if (currentDossier._esk.typeDossier === DossierTypesConst.DEMANDEUR) {
                            addDemandeurMessage = '<br>Si toutefois, vous n\'étiez plus en recherche d\'un bien ou si vos critères avaient évolué, merci de nous en informer.<br><br>';
                            contactsGroup = currentDossier.contactsGroup;
                        } else {
                            contactsGroup = currentDossier.bien.contactsGroup;
                        }
                        if (angular.isNumber(currentDossier.id)) {
                            dossiers.push({
                                typeId: currentDossier.id,
                                type: currentDossier._esk.typeDossier,
                                dossier: currentDossier
                            });
                        }
                    } else {
                        currentDossier = $ctrl.dossier;
                    }

                    if ($ctrl.dossier.bien._esk.interne) {
                        dossiers.push({
                            typeId: $ctrl.dossier.id,
                            type: $ctrl.dossier._esk.typeDossier,
                            dossier: $ctrl.dossier
                        });
                    }

                    let message: string;
                    let subject: string;
                    if ($ctrl.inReferencesArchivees) {
                        message = contactsGroup.formuleIntroduction + ",<br><br>" +
                            "Veuillez trouver ci-jointe la fiche d'un bien.<br>" +
                            "Nous vous en souhaitons bonne réception et restons à votre entière disposition pour tous renseignements complémentaires.<br><br>" +
                            "Nous vous prions d'agréer, " + contactsGroup.formulePolitesse + ", l'expression de nos salutations les plus distinguées.<br><br>" +
                            emailManager.getCurrentUserSignature()
                        subject = 'Fiche du bien ' + $ctrl.dossier.bien.reference;
                    } else {
                        message = contactsGroup.formuleIntroduction + ",<br><br>" +
                            "Veuillez trouver ci-jointe la fiche d'un bien qui pourrait vous intéresser.<br>" +
                            addDemandeurMessage +
                            "Nous vous en souhaitons bonne réception et restons à votre entière disposition pour tous renseignements complémentaires.<br><br>" +
                            "Nous vous prions d'agréer, " + contactsGroup.formulePolitesse + ", l'expression de nos salutations les plus distinguées.<br><br>" +
                            emailManager.getCurrentUserSignature()
                        subject = 'Fiche du bien en ' + $ctrl.dossier._esk.typeDossier + ' ' + $ctrl.dossier.bien.reference;
                    }

                    return soqModalesManager.open('EskModalEmail', {
                        resolve: {
                            email: emailManager.create({
                                recipients: contactsGroup.getRecipients(),
                                attachments: attachments,
                                dossiers: dossiers,
                                subject,
                                message,
                            }),
                            currentDossier: currentDossier
                        }
                    }).then(() => $ctrl.updateInterest('interested'));
                }).finally(() => $ctrl.loader.action = undefined);
            }

            /**
             * Update interest for result
             *
             * @param interest
             * @return Promise
             */
            function updateInterest(interest: string) {
                $ctrl.interestUpdating = true;

                return $ctrl.setInterest($ctrl.dossier._esk.result, interest).finally(() => $ctrl.interestUpdating = false);
            }

            /**
             * Contact confrere
             */
            function contactConfrere() {
                const config = {
                    raisonSociale: $ctrl.dossier.bien.responsableDossier?.etude.raisonSociale,
                    sexe: $ctrl.dossier.bien.responsableDossier?.sexe,
                    referenceBien: $ctrl.dossier.bien.reference,
                    typeBien: $ctrl.dossier._esk.typeDossier,
                };
                const dossiers = [] as unknown[];
                let confrereFrom = 'mail.confrere.from_default';
                let currentDossier = $ctrl.workingDossier;

                $ctrl.loader.action = 'SEND';
                if (angular.isObject(currentDossier)) {
                    if (angular.isNumber(currentDossier.id)) {
                        dossiers.push({
                            typeId: currentDossier.id,
                            type: currentDossier._esk.typeDossier,
                            dossier: currentDossier,
                        });
                    }
                } else {
                    currentDossier = $ctrl.dossier;
                }

                if (currentDossier._esk.typeDossier === DossierTypesConst.DEMANDEUR) {
                    // @ts-ignore
                    config.referenceDemandeur = currentDossier.reference;
                    // @ts-ignore
                    config.nomDemandeur = currentDossier.contactsGroup.nom;
                    confrereFrom = 'mail.confrere.from_demandeur';
                }

                if ($ctrl.dossier.bien._esk.interne) {
                    dossiers.push({
                        typeId: $ctrl.dossier.id,
                        type: $ctrl.dossier._esk.typeDossier,
                        dossier: $ctrl.dossier,
                    });
                }

                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: [{
                                emailAddress: $ctrl.dossier.bien.responsableDossier?.emailAddress,
                                type: EmailRecipient.types.TO,
                                name: $ctrl.dossier.bien.responsableDossier?.convivialName
                            }],
                            dossiers: dossiers,
                            subject: $translate.instant(confrereFrom + '.TITLE', config),
                            message: $translate.instant(confrereFrom + '.MESSAGE', config) +
                                "<br><br>" + emailManager.getCurrentUserSignature()
                        }),
                        currentDossier: currentDossier,
                    }
                }).finally(() => $ctrl.loader.action = undefined!);
            }

            /**
             * Open virtual visit
             */
            function openVirtualVisit() {
                $ctrl.visualTab = 0;
                $window.open((($ctrl.dossier.bien.option.urlVisiteVirtuelle.startsWith('https://') || $ctrl.dossier.bien.option.urlVisiteVirtuelle.startsWith('http://')) ? '' : 'http://') + $ctrl.dossier.bien.option.urlVisiteVirtuelle);
            }

            /**
             * Edit bon visite
             */
            function editBonVisite() {
                if (angular.isObject($ctrl.workingDossier) && $ctrl.workingDossier._esk.typeDossier === DossierTypesConst.DEMANDEUR) {
                    ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.workingDossier.id.toString() + '/bons-visite/' + Bonvisite.statuts.NEW + '/edit', {
                        demandeurId: $ctrl.workingDossier.id,
                        linkLocations: $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.LOCATION ? [$ctrl.dossier._links.self.href] : undefined,
                        linkVentes: $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE ? [$ctrl.dossier._links.self.href] : undefined,
                    });
                } else {
                    ng2RouterStateForNgService.navigateByUrl('/app/bons-visite/' + Bonvisite.statuts.NEW + '/edit', {
                        linkLocations: $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.LOCATION ? [$ctrl.dossier._links.self.href] : undefined,
                        linkVentes: $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE ? [$ctrl.dossier._links.self.href] : undefined,
                    });
                }
            }

            /**
             * Create offre achat
             */
            function editOffreAchat() {
                if (angular.isObject($ctrl.workingDossier) && $ctrl.workingDossier._esk.typeDossier === DossierTypesConst.DEMANDEUR) {
                    ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.workingDossier.id.toString() + '/offres-achat/' + Offreachat.statuts.NEW + '/edit', {
                        demandeurId: $ctrl.workingDossier.id,
                        linkVente: $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE ? $ctrl.dossier._links.self.href : undefined,
                    });
                } else {
                    ng2RouterStateForNgService.navigateByUrl('/app/offres-achat/' + Offreachat.statuts.NEW + '/edit', {
                        linkVente: $ctrl.dossier._esk.typeDossier === DossierBienTypesConst.VENTE ? $ctrl.dossier._links.self.href : undefined,
                    });
                }
            }

            /**
             * Edit dossier bien
             */
            function editDossierBien() {
                $ctrl.ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id + '/edit/general');
            }

            function getNumber(data: string | number) {
                if (!data) {
                    return undefined!;
                }

                if (typeof data === 'string') {
                    return +(data.toString().replace(',', '.'));
                }

                return data;
            }

            function getEtudePasserelle(bienDossierPasserelle: LocationPasserelle | VentePasserelle): EtudePasserelle {
                return $ctrl.networkAllowedEtudePasserelles.find(networkAllowedEtudePasserelle => networkAllowedEtudePasserelle.passerelle.id === bienDossierPasserelle.passerelleId);
            }

            function diffuser(bienDossierPasserelle: LocationPasserelle | VentePasserelle) {
                if ($ctrl.bienDossier.isLocation()) {
                    ng2LocationPasserelleFactory.diffuser$(bienDossierPasserelle as LocationPasserelle).pipe(take(1)).subscribe();
                } else if ($ctrl.bienDossier.isVente()) {
                    ng2VentePasserelleFactory.diffuser$(bienDossierPasserelle as VentePasserelle).pipe(take(1)).subscribe();
                }
            }

            function retirer(bienDossierPasserelle: LocationPasserelle | VentePasserelle) {
                if ($ctrl.bienDossier.isLocation()) {
                    ng2LocationPasserelleFactory.retirer$(bienDossierPasserelle as LocationPasserelle).pipe(take(1)).subscribe();
                } else if ($ctrl.bienDossier.isVente()) {
                    ng2VentePasserelleFactory.retirer$(bienDossierPasserelle as VentePasserelle).pipe(take(1)).subscribe();
                }
            }
        }
    })(angularJS);
}
