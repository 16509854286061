import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewRisquesService
} from '@features/dossiers/dossier/bien/overview/risques/dossier-bien-overview.risques.service';

@Component({selector: 'app-dossier-bien-overview-risques', templateUrl: 'dossier-bien-overview.risques.component.html'})
export class AppDossierBienOverviewRisquesComponent {
    private _dossierBienOverviewRisquesService = inject(DossierBienOverviewRisquesService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isCommentsRisquesVisible(): boolean {
        return this._dossierBienOverviewRisquesService.isCommentsRisquesVisible;
    }

    get isZoneRisquesVisible(): boolean {
        return this._dossierBienOverviewRisquesService.isZoneRisquesVisible;
    }
}
