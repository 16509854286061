import {Component, inject, Input} from '@angular/core';
import {NgBienDossier, NgEstimation, NgVente} from '@legacy/app/managers/ressources';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {Observable} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import User from '@models/users/user/user.model';
import {UserFactory} from '@models/users/user/user.factory';
import {NgUserManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {map, take, tap} from 'rxjs/operators';
import {UserService} from '@models/users/user/user.service';

@Component({selector: 'app-bien-edit-dossier', templateUrl: 'bien.edit-dossier.component.html'})
export class AppBienEditDossierComponent {
    private _dictionnatyItemService = inject(DictionaryItemService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _etudeService = inject(EtudeService);
    private _ngInjectorService = inject(NgInjectorService);
    private _userFactory = inject(UserFactory);
    private _userService = inject(UserService);
    private _bienDossier!: ADossierBien;
    private _ngBienDossier!: NgBienDossier;
    private _notaire!: User;
    private _responsableDossier!: User;
    private _motif!: DictionaryItem;

    // Supprimer les injections des anciens manager
    private get ngUserManager(): NgUserManager {
        return this._ngInjectorService.getService('UserManager');
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get ESTIMATION_MOTIFS(): string {
        return Dictionary.names.ESTIMATION_MOTIFS;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienFieldsConst;
    }

    get motif(): DictionaryItem {
        return this._motif;
    }

    get ngBienDossier(): NgBienDossier {
        return this._ngBienDossier;
    }

    @Input()
    set ngBienDossier(value: NgBienDossier) {
        this._ngBienDossier = value;
        this._bienDossier = this._dossierBienFactory.ngCreate(this._ngBienDossier);
        if (this._bienDossier.bien.linkNotaire) {
            this._userFactory.getByLink$(this._bienDossier.bien.linkNotaire).pipe(take(1)).subscribe(notaire => this._notaire = notaire);
        } else {
            this._userService.last$.pipe(
                map(user => user.defaultNotaire),
                tap(user => {
                    if (user) {
                        this._ngBienDossier.bien._links.notaire = {href: user.linkSelf};
                        this._ngBienDossier.bien.notaire = this.ngUserManager.createFromNg2(user);
                        this._ngBienDossier.bien.notaireId = user?.id;
                    }
                }),
            ).subscribe(user => this._notaire = user);
        }

        if (this._ngBienDossier.bien.responsableDossier) {
            this._responsableDossier = this._userFactory.ngCreate(this._ngBienDossier.bien.responsableDossier);
        } else {
            this._responsableDossier = undefined!;
        }

        if (this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION && this.ngEstimation.motif) {
            this._motif = this._dictionnatyItemService.getByCode(Dictionary.names.ESTIMATION_MOTIFS, this.ngEstimation.motif);
        } else {
            this._motif = undefined!;
        }
    }

    get ngEstimation(): NgEstimation {
        return this._ngBienDossier as NgEstimation;
    }

    get ngVente(): NgVente {
        return this._ngBienDossier as NgVente;
    }

    get notaire(): User {
        return this._notaire;
    }

    get responsableDossier(): User {
        return this._responsableDossier;
    }

    get roleNotaire(): string {
        return User.roles.NOTAIRE;
    }

    onMotifSelected(motif: DictionarySelected): void {
        this._motif = motif as DictionaryItem;
        if (this._motif && !Array.isArray(this._motif)) {
            this.ngEstimation.motif = this._motif.code;
        } else {
            this.ngEstimation.motif = undefined!;
        }
    }

    onNotaireSelected(notaire: User): void {
        this._notaire = notaire;
        if (this._notaire) {
            this._ngBienDossier.bien._links.notaire = {href: this._notaire.linkSelf};
            this._ngBienDossier.bien.notaire = this.ngUserManager.createFromNg2(this._notaire);
            this._ngBienDossier.bien.notaireId = this._notaire?.id;
        } else {
            this._ngBienDossier.bien._links.notaire = undefined!;
            this._ngBienDossier.bien.notaire = undefined!;
            this._ngBienDossier.bien.notaireId = undefined!;
        }
    }

    onResponsableDossierSelected(responsableDossier: User): void {
        this._responsableDossier = responsableDossier;
        if (this._responsableDossier) {
            this._ngBienDossier.bien.responsableDossier = this.ngUserManager.createFromNg2(this._responsableDossier);
        } else {
            this._ngBienDossier.bien.responsableDossier = undefined!;
        }
    }

    showFieldForBien(field: string): boolean {
        return this._dossierBienFieldsService.isEditionVisible(this._bienDossier, field);
    }
}
