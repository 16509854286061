import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {IContactsGroupApi} from '@models/contacts-group/contacts-group.interfaces';
import {Observable, switchMap} from 'rxjs';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

@Injectable({providedIn: 'root'})
export class ContactsGroupApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IContactsGroupApi> {
        return this.getByLink$('/contacts-groups/' + uuid);
    }

    getByLink$(link: string): Observable<IContactsGroupApi> {
        return this._modelApiService.get$(link);
    }

    save$(uuid: string, contactsGroupApi: IContactsGroupApi): Observable<IContactsGroupApi> {
        if (uuid === ContactsGroup.statuts.NEW) {
            return this._modelApiService.post$('/user/contacts-groups', contactsGroupApi);
        }

        return this._modelApiService.put$('/contacts-groups/' + uuid, contactsGroupApi).pipe(switchMap(_ => this.get$(uuid)));
    }
}
