import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapComponent} from '@shared/map/map.component';
import {LeafletModule} from '@bluehalo/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@bluehalo/ngx-leaflet-markercluster';

@NgModule({
    declarations: [MapComponent],
    imports: [CommonModule, LeafletModule, LeafletMarkerClusterModule],
    exports: [MapComponent],
})
export class MapModule {
}
