import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager} from '@legacy/app/managers/managers';
import {NgEmailRecipientsCollection} from '@legacy/app/managers/collections';
import {NgEmailRecipient} from '@legacy/app/managers/ressources';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';

export default function getManagersEmailRecipient(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EmailRecipientManager', manager);

        /**
         * Manager recipient
         *
         * @param BaseManager
         * @returns {EmailRecipientManager}
         */
        manager.$inject = ['BaseManager'];
        function manager(baseManager: NgManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('email-recipients.cget', {path: '/emails/{emailId}/recipients', method: 'GET'});
            baseManager.addRoute('email-recipients.insert', {path: '/emails/{emailId}/recipients', method: 'POST'});
            baseManager.addRoute('email-recipients.edit', {path: '/emails/{emailId}/recipients/{id}', method: 'PUT'});
            baseManager.addRoute('email-recipients.remove', {
                path: '/emails/{emailId}/recipients/{id}',
                method: 'DELETE'
            });

            /**
             * EmailRecipientManager object
             *
             * @constructor
             */
            angular.extend(EmailRecipientManager.prototype, baseManager.__proto__);
            EmailRecipientManager.prototype.eskManager = {
                prefixRoute: 'email-recipients',
                collectionName: 'items'
            };

            function EmailRecipientManager() {
            }

            /**
             * Create a EmailRecipient object
             *
             * @param ng2EmailRecipient
             * @returns manager.EmailRecipient
             */
            EmailRecipientManager.prototype.createFromNg2 = function (ng2EmailRecipient: EmailRecipient) {
                if (!ng2EmailRecipient) {
                    return undefined!;
                }

                return this.create({
                    emailAddress: ng2EmailRecipient.emailAddress,
                    id: ng2EmailRecipient.id,
                    name: ng2EmailRecipient.name,
                    type: ng2EmailRecipient.type,
                    uuid: ng2EmailRecipient.uuid,
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param emailId
             * @returns {Promise}
             */
            EmailRecipientManager.prototype.getAll = function (emailId: number) {
                return baseManager.getAll.call(this, {emailId: emailId}).then(emailRecipients => {
                    const emailRecipientsCollection: NgEmailRecipientsCollection = emailRecipients as NgEmailRecipientsCollection;

                    angular.forEach(emailRecipientsCollection.collection, recipient => recipient._esk.defaultRouteParams!.emailId = emailId);

                    return emailRecipientsCollection;
                });
            };

            /**
             * Create a EmailRecipient object
             *
             * @param data
             * @param emailId
             * @returns manager.EmailRecipient object
             */
            EmailRecipientManager.prototype.create = function (data: unknown, emailId: number) {
                if (angular.isArray(data)) {
                    var recipients = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        recipients.push(new EmailRecipient(emailId, data[i]));
                    }

                    return recipients;
                }

                // @ts-ignore
                return new EmailRecipient(emailId, data);
            };

            /**
             * EmailRecipient object
             *
             * @param emailId
             * @param data
             * @constructor
             */
            angular.extend(EmailRecipient.prototype, classResource.prototype);
            EmailRecipient.prototype.eskManager = {prefixRoute: 'email-recipients'};
            function EmailRecipient(this: NgEmailRecipient, emailId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, emailId: emailId};
            }

            // @ts-ignore
            return new EmailRecipientManager();
        }
    })(angularJS);
}
