@if (vente$ | async; as vente) {
  <div class="vente tw-h-full">
    @if (ngVente) {
      @if (!vente.isArchived()) {
        <esk-bien-header [dossier]="ngVente" class="print:tw-hidden"/>

        <div class="tw-h-[calc(100%_-_70px)]"><router-outlet/></div>
      }

      @if (vente.isArchived()) {
        <div class="tw-second-nav">
          <div class="tw-second-nav-menus-header">
            <div class="tw-second-nav-menus-title tw-overflow-hidden">
              <app-navigation-toggle/>

              <app-dossier-bien-header [dossierBien]="vente" class="tw-flex-1 tw-overflow-hidden"/>
            </div>

            <app-call-to-action [type]="ACTION_MAIN" class="tw-block tw-mr-2"/>
          </div>

          <div class="tw-second-nav-content">
            @if (sections$ | async; as sections) {
              <div class="tw-overflow-hidden tw-h-full tw-flex tw-divide-x tw-divide-solid">
                <app-navigation-lateral [sections]="sections"/>

                <div class="tw-overflow-y-auto tw-p-4 tw-flex-1"><router-outlet/></div>
              </div>
            } @else {
              <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-p-4"/>
            }
          </div>
        </div>
      }
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
