export default `
<div ng-if="!!$ctrl.currentEtude" class="negociation">
  <div class="container-fluid container-fluid-title"><h1>Chiffre d'affaires du service négociation</h1></div>

  <!-- ! pour contrer "container-fluid" -->
  <div class="container-fluid container-fullw tw-bg-white print:!tw-p-0">
    <!-- ! pour contrer "negociation-container" -->
    <div class="negociation-container print:!tw-p-0">
      <div class="text-right hidden-print">
        <button ng-hide="$ctrl.loading" type="button" class="btn btn-primary btn-o" onClick="window.print()">
          <fa-icon [icon]="'print'"></fa-icon> {{'global.label.PRINT' | translate}}
        </button>
      </div>

      <div class="negociation-revenus">
        <div class="row negociation-revenus-criteria">
          <div class="col-xs-12 col-sm-3">
            <label>{{'Utilisateur' | translate}}</label>

            <esk-select-user roles="$ctrl.usersRoles" etudes="[$ctrl.currentEtude.id]" ng-model="$ctrl.user"
                             options="{onSelect: $ctrl.refresh, placeholder: 'Sélectionner un utilisateur'}">
            </esk-select-user>
          </div>

          <div class="col-xs-6 col-sm-3">
            <div class="pull-right">
              <label>{{"Date de début"|translate}}</label>

              <esk-select-date is-required="true" model="$ctrl.dateDebut" on-select="$ctrl.refresh()"
                               options="$ctrl.options.dateDebut"></esk-select-date>
            </div>
          </div>

          <div class="col-xs-6 col-sm-3">
            <label>{{"Date de fin"|translate}}</label>

            <esk-select-date is-required="true" model="$ctrl.dateFin" on-select="$ctrl.refresh()"
                             options="$ctrl.options.dateFin"></esk-select-date>
          </div>

          <div class="col-xs-12 col-sm-3 margin-top-15">
            <div class="checkbox clip-check check-primary">
              <input type="checkbox" id="honorairesHorsTaxe" value="1" ng-model="$ctrl.honorairesHorsTaxe"
                     ng-change="$ctrl.refresh()">

              <label for="honorairesHorsTaxe" title="{{'Voir les prix en Hors Taxes'|translate}}.">
                {{"Voir en H.T."|translate}}
              </label>
            </div>
          </div>
        </div>

        <esk-chart-negociation-revenus date-debut="$ctrl.dateDebut" date-fin="$ctrl.dateFin" user="$ctrl.user"
                                       get-statistics="$ctrl.getStatistics" honoraires-hors-taxe="$ctrl.honorairesHorsTaxe"
                                       refresh="$ctrl.refreshChart"></esk-chart-negociation-revenus>

        <!-- Table -->
        <div ng-if="!$ctrl.loading" class="negociation-revenus-table">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th ng-repeat="month in $ctrl.months" class="tw-text-center text-capitalize">
                  <app-ng2-date [date]="month" format="MMM yy"></app-ng2-date>
                </th>
              </tr>
              </thead>

              <tbody>
              <tr>
                <td colspan="{{$ctrl.months.length}}" class="tw-text-center text-bold text-large">Solo</td>
              </tr>

              <tr>
                <td ng-repeat="month in $ctrl.months" class="text-right">
                  <ul ng-if="$ctrl.statistics[$ctrl.getStatisticsKey(month)].solo.honoraires.length > 0"
                      class="border-bottom">
                    <li
                      ng-repeat="honoraires in $ctrl.statistics[$ctrl.getStatisticsKey(month)].solo.honoraires track by $index">
                      <div ng-if="$index > 0" class="pull-left">+</div>

                      {{honoraires | currency:'€':2 * (honoraires % 1 !== 0)}}
                    </li>
                  </ul>

                  <div class="text-bold">
                    {{$ctrl.statistics[$ctrl.getStatisticsKey(month)].solo.honorairesTotal
                    | currency:'€':2 * ($ctrl.statistics[$ctrl.getStatisticsKey(month)].solo.honorairesTotal % 1 !== 0)}}
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="{{$ctrl.months.length}}" class="tw-text-center text-bold text-large">Duo</td>
              </tr>

              <tr>
                <td ng-repeat="month in $ctrl.months" class="text-right">
                  <ul
                    ng-if="$ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honoraires.length > 0 || $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo_network.honoraires.length > 0"
                    class="border-bottom">
                    <li
                      ng-repeat="honoraires in $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honoraires track by $index">
                      <div ng-if="$index > 0" class="pull-left">+</div>

                      {{honoraires | currency:'€':2 * (honoraires % 1 !== 0)}}
                    </li>

                    <li
                      ng-repeat="honoraires in $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo_network.honoraires track by $index">
                      <div ng-if="$index > 0 || $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honoraires.length > 0"
                           class="pull-left">+
                      </div>

                      {{honoraires | currency:'€':2 * (honoraires % 1 !== 0)}}
                    </li>
                  </ul>

                  <div class="text-bold">
                    {{($ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honorairesTotal
                    + $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo_network.honorairesTotal)
                    | currency:'€':2 * (($ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honorairesTotal
                    + $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo_network.honorairesTotal) % 1 !== 0)}}
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="{{$ctrl.months.length}}" class="tw-text-center text-bold text-large">Total</td>
              </tr>

              <tr>
                <td ng-repeat="month in $ctrl.months" class="tw-text-center text-bold">
                  {{($ctrl.statistics[$ctrl.getStatisticsKey(month)].solo.honorairesTotal
                  + $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honorairesTotal
                  + $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo_network.honorairesTotal)
                  | currency:'€':2 * (($ctrl.statistics[$ctrl.getStatisticsKey(month)].solo.honorairesTotal
                  + $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo.honorairesTotal
                  + $ctrl.statistics[$ctrl.getStatisticsKey(month)].duo_network.honorairesTotal) % 1 !== 0)}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <p class="text-right text-muted text-small">
            Mise à jour des données : <app-ng2-date [date]="$ctrl.statistics.date" format="shortDate"></app-ng2-date>
            à <app-ng2-date [date]="$ctrl.statistics.date" format="shortTime"></app-ng2-date>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
`;
