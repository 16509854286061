export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()" title="Fermer">
    <fa-icon [icon]="'times'"></fa-icon>
  </button>

  <h3 class="modal-title"><fa-icon [icon]="'pencil-alt'"></fa-icon> Format des affichettes</h3>
</div>

<div class="modal-body modal-affichette">
  <div class="row">
    <div class="col-xs-12">
      <esk-select-template-thumbnails codes="$ctrl.affichette.codesTemplate" ng-model="$ctrl.affichette.template"
                                      groups="$ctrl.affichette.groups"></esk-select-template-thumbnails>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" type="button" ng-click="$close($ctrl.affichette.template)">
    <fa-icon [icon]="'check'"></fa-icon> Ok
  </button>
</div>
`;
