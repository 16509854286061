import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {VenteService} from '@models/ventes/vente/vente.service';
import Vente from '@models/ventes/vente/vente.model';
import {map} from 'rxjs/operators';
import Template from '@models/templates/template/template.model';
import {DocumentService} from '@models/documents/document/document.service';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';
import {ModalService} from '@shared/modal/modal.service';
import {DCTemplatesSelectComponent} from '@features/templates/select/dynamic-components/templates-select.component';
import {IDCTemplatesSelectResponse} from '@features/templates/templates.interfaces';
import {NgTemplate} from '@legacy/app/managers/ressources';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {IDossierBienRestorationResponse} from '@features/dossiers/dossier/dossier.interfaces';
import {
    DCDossierBienRestorationComponent
} from '@features/dossiers/dossier/bien/restoration/dossier-bien.restoration.component';
import {IVenteActions} from '@features/ventes/ventes.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {NavigationBehaviorOptions} from '@angular/router';
import {IRoutingNavigateByUrl} from '@shared/routes/routes.interfaces';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {UserFactory} from '@models/users/user/user.factory';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {
    DCVentePasserellesComponent
} from '@features/dynamic-components/vente-passerelles/dc-vente-passerelles.component';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {EtudePasserellesService} from '@models/etudes/etude/passerelles/etude-passerelles.service';

@Injectable({providedIn: 'root'})
export class VenteOverviewService {
    private _cTemplatesService = inject(CTemplatesService);
    private _documentService = inject(DocumentService);
    private _emailEditService = inject(EmailEditService);
    private _etudePasserellesService = inject(EtudePasserellesService);
    private _modalService = inject(ModalService);
    private _slideOverService = inject(SlideOverService);
    private _templateFactory = inject(TemplateFactory);
    private _userFactory = inject(UserFactory);
    private _venteService = inject(VenteService);

    advancedPrint$(vente: Vente): Observable<void> {
        return this._modalService.open$<IDCTemplatesSelectResponse>(DCTemplatesSelectComponent, {
            courrierCategories: vente.printCourrierFicheCategoryCodes,
            documentCategories: vente.printFicheCategoryCodes,
            options: {
                buttonCreateLabel: 'Créer la fiche',
                inputDocumentLabel: 'Fiche',
                titre: 'Création d\'une fiche',
            },
        }).pipe(
            switchMap(templates => {
                if (templates?.documentTemplate) {
                    return this._documentService.readFromDossierBien$(vente, templates.documentTemplate, templates.courrierTemplate).pipe(map(_ => undefined));
                }

                return of(undefined);
            }),
        );
    }

    createBonvisite$(vente: Vente): Observable<IRoutingNavigateByUrl> {
        let extras = undefined as unknown as NavigationBehaviorOptions;
        let url = '/app/ventes/' + vente.id.toString() + '/bons-visite/' + Bonvisite.statuts.NEW + '/edit';

        if (!vente.interne) {
            extras = {state: {extraParams: {linkVentes: [vente.linkSelf]}}};
            url = '/app/bons-visite/' + Bonvisite.statuts.NEW + '/edit';
        }

        return of({extras, url});
    }

    createOffreachat$(vente: Vente): Observable<IRoutingNavigateByUrl> {
        let extras = undefined as unknown as NavigationBehaviorOptions;
        let url = '/app/ventes/' + vente.id.toString() + '/offres-achat/' + Bonvisite.statuts.NEW + '/edit';

        if (!vente.interne) {
            extras = {state: {extraParams: {linkVente: vente.linkSelf}}};
            url = '/app/offres-achat/' + Bonvisite.statuts.NEW + '/edit';
        }

        return of({extras, url});
    }

    getActions$(vente: Vente): Observable<IVenteActions> {
        return combineLatest([
            this._etudePasserellesService.getFromVente$(vente).pipe(map(etudePasserelles => etudePasserelles.length > 0)),
            this._venteService.isCurrentPrintAffichettes$(),
        ]).pipe(map(([manageDiffusions, printAffichettes]) => ({
            advancedPrint: true,
            createBonvisite: !vente.isArchived() && !vente.isInteractive(),
            createOffreachat: !vente.isArchived() && !vente.isInteractive(),
            manageDiffusions: !vente.isNewOrBrouillon() && !vente.isArchived() && manageDiffusions,
            printAffichettes,
            restore: vente.interne && vente.isArchived(),
            seeMatchedDemandeurs: !vente.isNewOrBrouillon() && !vente.isArchived(),
            sendFiche: !vente.isArchived(),
            sendToConfrere: !vente.interne,
            unlock: true,
            update: vente.interne && !vente.isArchived(),
        })));
    }

    getDefaultPrintFicheTemplate$(vente: Vente): Observable<Template> {
        return this._cTemplatesService.getWithDefaultFirst$(vente.printFicheCategoryCodes).pipe(
            map(cTemplates => cTemplates.results[0]),
        );
    }

    manageDiffusions$(vente: Vente): Observable<void> {
        return this._slideOverService.open$(DCVentePasserellesComponent, {vente}).pipe(map(_ => undefined));
    }

    print$(vente: Vente): Observable<void> {
        return this.getDefaultPrintFicheTemplate$(vente).pipe(
            switchMap(template => this._documentService.readFromDossierBien$(vente, template)),
            map(_ => undefined),
        );
    }

    printAffichettes$(vente: Vente): Observable<void> {
        return this._modalService.ngOpen$<NgTemplate>('BiensModalFormatAffichettes', {
            resolve: {codesTemplate: () => vente.printAffichetteCategoryCodes},
        }).pipe(
            switchMap(ngTemplate => {
                if (ngTemplate) {
                    return this._documentService.readFromDossierBien$(vente, this._templateFactory.ngCreate(ngTemplate)).pipe(map(_ => undefined));
                }

                return of(undefined);
            }),
        );
    }

    restore$(vente: Vente): Observable<Vente> {
        return this._modalService.open$<IDossierBienRestorationResponse>(DCDossierBienRestorationComponent, {dossierBien: vente}).pipe(
            switchMap(response => {
                if (response?.isAccepted) {
                    return this._venteService.cloneCurrent$(response.keepProprietaires);
                }

                return of(undefined as unknown as Vente);
            }),
        );
    }

    seeMatchedDemandeurs$(vente: Vente): Observable<string> {
        return of('/app/ventes/' + vente.id.toString() + '/correspondants');
    }

    sendFiche$(vente: Vente): Observable<void> {
        const dossiers: ADossier[] = [];
        let currentDossier = undefined as unknown as ADossier;

        if (vente.interne) {
            currentDossier = vente;
            dossiers.push(vente);
        }

        return this.getDefaultPrintFicheTemplate$(vente).pipe(
            switchMap(template => this._documentService.getMediaDossierBien$(vente, template)),
            switchMap(media => this._emailEditService.forVenteFiche$(vente, [media], dossiers, currentDossier)),
            map(_ => undefined),
        );
    }

    sendToConfrere$(vente: Vente): Observable<void> {
        const dossiers: ADossier[] = [];
        let currentDossier = undefined as unknown as ADossier;

        if (vente.interne) {
            currentDossier = vente;
            dossiers.push(vente);
        }

        return combineLatest([this._venteService.getEtude$(vente), this._userFactory.getByLink$(vente.bien.linkResponsableDossier)]).pipe(
            switchMap(([etude, user]) => this._emailEditService.forConfrereFromVente$(etude, user, vente, dossiers, currentDossier)),
            map(_ => undefined),
        );
    }

    update$(vente: Vente): Observable<string> {
        return of('/app/ventes/' + vente.id.toString() + '/edit');
    }
}
