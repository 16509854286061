import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService, ITemplateCacheService} from 'angular';
import {NgEskBox} from '@legacy/app/eskimmo/eskimmo';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {NgEstimationManager, NgFilterManager} from '@legacy/app/managers/managers';
import {NgFilter} from '@legacy/app/managers/ressources';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import Filter from '@models/filters/filter/filter.model';
import {ConditionConst} from '@shared/constants';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEstimationsBoxRemis(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimations-box-remis></esk-estimations-box-remis>
         */
        module.run(template).component('eskEstimationsBoxRemis', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/box.remis.html'
        });

        /**
         * Display estimations avis_valeur remis
         *
         * @param EskBox
         * @param $q
         * @param EstimationManager
         * @param Ng2RouterStateForNgService
         * @param SoqModalesManager
         * @param FilterManager
         * @constructor
         */
        Controller.$inject = ['EskBox', '$q', 'EstimationManager', 'Ng2RouterStateForNgService', 'SoqModalesManager', 'FilterManager'];
        function Controller(this: any,
                            eskBox: NgEskBox,
                            $q: IQService,
                            estimationManager: NgEstimationManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            soqModalesManager: NgSoqModalesManager,
                            filterManager: NgFilterManager) {
            const $ctrl = this;
            let filter = filterManager.create({
                route: Filter.routes.GET_ETUDE_ESTIMATIONS,
                queryParameters: {typeEstimation: {condition: ConditionConst.IN, values: []}}
            }) as NgFilter;

            $ctrl.$onInit = $onInit;
            $ctrl.updateEstimations = updateEstimations;
            $ctrl.openConfig = openConfig;
            $ctrl.goToEstimations = goToEstimations;
            $ctrl.options = {
                displayMode: 'box',
                loader: {updating: false},
                visibleFields: {nomContactsGroup: false, preciseLocalisation: false, select: false, statut: false},
            };

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.box = eskBox.getOneByCode('estimations-remis');
                $ctrl.updateEstimations();
            }

            /**
             * Refresh list of estimations
             *
             * @returns {Promise}
             */
            function updateEstimations() {
                $ctrl.options.loader.updating = true;
                filter.queryParameters.sort = $ctrl.box.config.sort;
                filter.queryParameters.statut = {condition: ConditionConst.IN, values: [Estimation.statuts.REMIS]};

                return estimationManager.getAllEtudeFiltered(filter, {limit: $ctrl.box.config.nbDisplayed.code}).then(function (estimations) {
                    angular.forEach(estimations.collection, function (estimation) {
                        estimation._esk.routing = '/app/estimations/' + estimation.id.toString() + '/rapport';
                    });
                    $ctrl.estimations = estimations;
                }).finally(function () {
                    $ctrl.box.badge.value = $ctrl.estimations.totalItems;
                    $ctrl.options.loader.updating = false;
                });
            }

            /**
             * Open configuration
             */
            function openConfig() {
                soqModalesManager.open('EstimationsModalBoxRemis', {resolve: {box: $ctrl.box}}).then($ctrl.updateEstimations);
            }

            /**
             * Go to estimations
             */
            function goToEstimations() {
                filterManager.getActiveByRouteCode(Filter.routes.GET_ETUDE_ESTIMATIONS, Filter.codes.WALLET_CURRENT).then(
                    filterUser => filterUser as NgFilter
                ).then(filterUser => {
                    filterUser.queryParameters = filter.queryParameters;
                    filterUser.fromFilterId = filterUser.id;
                    filterUser.save().then(() => ng2RouterStateForNgService.navigateByUrl('/app/estimations/portefeuille'));
                });
            }
        }

        /**
         * Template initialization
         *
         * @param $templateCache
         */
        template.$inject = ['$templateCache'];
        function template($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/estimations/components/box.remis/container.html', '<esk-estimations-box-remis></esk-estimations-box-remis>');
        }
    })(angularJS);
}
