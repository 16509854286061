import angularJS from '@shared/angularJS/global.ng';
import {filter, find, findIndex, map} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgBienUtils} from '@legacy/app/biens/biens';
import {
    NgDemandeurManager, NgDemandeurRechercheResultatManager, NgEmailManager, NgFilterManager
} from '@legacy/app/managers/managers';
import {NgBienDossier, NgFilter} from '@legacy/app/managers/ressources';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {DisplayMode} from '@shared/constants';
import {NgBienDossiersCollection, NgRechercheResultsCollection} from '@legacy/app/managers/collections';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {UserService} from '@models/users/user/user.service';
import Filter from '@models/filters/filter/filter.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

export default function getDemandeurResults(module: IModule): void {
    (function (angular) {
        'use strict';

        // Utiliser comme composant de routing
        module.component('eskDemandeurResults', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/demandeurs/components/item/results.html',
        });

        /**
         * Controller for edit Search
         *
         * @param SoqModalesManager
         * @param Ng2RouterStateForNgService
         * @param DemandeurManager
         * @param FilterManager
         * @param $q
         * @param DemandeurRechercheResultatManager
         * @param EmailManager
         * @param $translate
         * @param BiensUtils
         * @param Ng2ToasterService
         * @param Ng2UserService
         * @constructor
         */
        Controller.$inject = ['SoqModalesManager', 'Ng2RouterStateForNgService', 'DemandeurManager', 'FilterManager', '$q', 'DemandeurRechercheResultatManager', 'EmailManager', '$translate', 'BiensUtils', 'Ng2ToasterService', 'Ng2UserService'];
        function Controller(this: any,
                            soqModalesManager: NgSoqModalesManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            demandeurManager: NgDemandeurManager,
                            filterManager: NgFilterManager,
                            $q: IQService,
                            demandeurRechercheResultatManager: NgDemandeurRechercheResultatManager,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            biensUtils: NgBienUtils,
                            ng2ToasterService: ToasterService,
                            ng2UserService: UserService) {
            const $ctrl = this;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.sortResults = sortResults;
            $ctrl.changeDisplayMode = changeDisplayMode;
            $ctrl.onChange = onChange;
            $ctrl.setInterest = setInterest;
            $ctrl.send = send;
            $ctrl.createBonVisite = createBonVisite;
            $ctrl.getSelectedDossiers = getSelectedDossiers;
            $ctrl.demandeur = demandeurManager.current;
            $ctrl.DossierBienTypesConst = DossierBienTypesConst;
            $ctrl.DEMANDEUR_RECHERCHE_BROUILLON = DemandeurRecherche.statuts.BROUILLON;
            $ctrl.DISINTERESTED = DemandeurRechercheResultat.interests.DISINTERESTED;
            $ctrl.DISINTERESTED_EXCEPT_PRICE = DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE;
            $ctrl.filterCode = Filter.codes.WALLET_CURRENT;
            $ctrl.filterRoute = Filter.routes.GET_RECHERCHE_RESULTATS;
            ng2UserService.last$.subscribe(currentUser => {
                $ctrl.currentUser = currentUser;
                $q.resolve(demandeurManager.current.recherches[0]).then(search => {
                    const typeList = search.type.toUpperCase() + 'S_LIST_CORRESPONDANTS';

                    $ctrl.search = search;
                    $ctrl.options = {
                        nameDossiers: typeList,
                        typeFilter: typeList,
                        typeSort: typeList,
                        displayMode: $ctrl.currentUser.clientMetadata.getWallet($ctrl.filterRoute, $ctrl.filterCode).view,
                        loader: {filter: true, updating: true},
                        visibleFields: {
                            archiveDateVente: false,
                            mandatDateFin: false,
                            nomContactsGroup: false,
                            priceCession: false,
                            preciseLocalisation: false,
                            statut: false,
                        },
                        deleteResultRemoved: function (dossier: NgBienDossier) {
                            const idx = findIndex($ctrl.search._esk.dossiersCorrespondants.collection, {id: dossier.id});

                            dossier._esk.result!.interest = DemandeurRechercheResultat.interests.DISINTERESTED;
                            dossier._esk.result!.save().then(function () {
                                ng2ToasterService.success($translate.instant('demandeur.results.TITLE', {nbResults: 1}), $translate.instant('demandeur.results.suppression.MESSAGE'));
                                $ctrl.search._esk.dossiersCorrespondants.collection.splice(idx, 1);
                                $ctrl.search._esk.status.red--;
                                $ctrl.search._esk.status.none++;
                            });
                        },
                        onChangeFilter: function (filter: NgFilter) {
                            $ctrl.options.loader.updating = true;
                            $ctrl.filter = filter;

                            return getFilteredResults().finally(function () {
                                $ctrl.options.loader.updating = false;
                            });
                        }
                    };
                    filterManager.getActiveByRouteCode($ctrl.filterRoute, $ctrl.filterCode).then(filter => filter as NgFilter).then(filter => {
                        filter.nom = undefined!;
                        $ctrl.filter = filter;
                        $ctrl.options.loader.filter = false;

                        return getFilteredResults();
                    }).finally(() => $ctrl.options.loader.updating = false);
                    updateResumeLocalisations();
                });
            });

            /**
             * Sort results
             */
            function sortResults() {
                $ctrl.options.loader.updating = true;
                $ctrl.filter.save().then(getFilteredResults).finally(() => $ctrl.options.loader.updating = false);
            }

            /**
             * Change view mode
             *
             * @param displayMode
             */
            function changeDisplayMode(displayMode: DisplayMode) {
                $ctrl.options.displayMode = displayMode;
            }

            /**
             * On change list
             *
             * @param resultsPaginator
             */
            function onChange(resultsPaginator: NgRechercheResultsCollection) {
                resultsPaginator.collection.forEach(result => result._esk.defaultRouteParams!.rechercheId = $ctrl.search.id);
                $ctrl.search.setDossiersCorrespondants(resultsPaginator);

                return setRouting($ctrl.search._esk.dossiersCorrespondants);
            }

            /**
             * Set interest on each selected dossiers
             *
             * @param interest
             */
            function setInterest(interest: string) {
                const promises: IPromise<unknown>[] = [];

                angular.forEach($ctrl.getSelectedDossiers(), function (dossier) {
                    const predicate = {[dossier._esk.typeDossier]: {id: dossier.id}};
                    const result = find($ctrl.search.results.collection, predicate);

                    result.interest = interest;
                    promises.push(result.save());
                });

                $q.all(promises).then(function () {
                    $ctrl.unselectAll();
                    ng2ToasterService.success($translate.instant('demandeur.results.TITLE', {nbResults: promises.length}), $translate.instant('demandeur.results.action.MESSAGE', {
                        nbResults: promises.length,
                        state: demandeurRechercheResultatManager.stringifyInterest(interest)
                    }));
                    $ctrl.options.loader.updating = true;

                    return getFilteredResults().then(() => $ctrl.demandeur.updateSearchStatusAndEmitCurrent());
                }).finally(() => {
                    $ctrl.options.loader.updating = false;
                });
            }

            /**
             * Send selected dossiers
             */
            function send() {
                var selectedDossiers = $ctrl.getSelectedDossiers();

                $ctrl.barreFaireLoader = 'global.en_cours.SEND';
                biensUtils.getMediasFiche($ctrl.search.type, selectedDossiers).then(function (attachments) {
                    return soqModalesManager.open('EskModalEmail', {
                        resolve: {
                            email: emailManager.create({
                                recipients: demandeurManager.current.contactsGroup.getRecipients(),
                                attachments: attachments,
                                dossiers: [{
                                    typeId: demandeurManager.current.id,
                                    type: DossierTypesConst.DEMANDEUR,
                                    dossier: demandeurManager.current,
                                }],
                                subject: $translate.instant('mail.bien_correspondants.TITLE', {
                                    typeDossier: $ctrl.search.type,
                                    nbDossiers: selectedDossiers.length,
                                }),
                                message: $translate.instant('mail.bien_correspondants.MESSAGE', {
                                    ...{
                                        typeDossier: $ctrl.search.type,
                                        nbDossiers: selectedDossiers.length,
                                        references: map(selectedDossiers, 'bien.reference').join(', '),
                                        formuleIntroduction: demandeurManager.current.contactsGroup.formuleIntroduction,
                                        formulePolitesse: demandeurManager.current.contactsGroup.formulePolitesse,
                                    }, ...demandeurManager.current.contactsGroup
                                }) + "<br><br>" + emailManager.getCurrentUserSignature()
                            }),
                            currentDossier: demandeurManager.current
                        }
                    }).then(function () {
                        return $ctrl.setInterest('interested');
                    });
                }).finally(function () {
                    $ctrl.unselectAll();
                    $ctrl.barreFaireLoader = undefined;
                });
            }

            /**
             * Create bon visite for a dossier
             */
            function createBonVisite() {
                const selectedDossiers = $ctrl.getSelectedDossiers();

                ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + demandeurManager.current.id.toString() + '/bons-visite/' + Bonvisite.statuts.NEW + '/edit', {
                    demandeurId: demandeurManager.current.id,
                    linkLocations: selectedDossiers.filter(selectedDossier => selectedDossier._esk.typeDossier === DossierBienTypesConst.LOCATION)
                        .map(selectdDossier => selectdDossier._links.self.href),
                    linkVentes: selectedDossiers.filter(selectedDossier => selectedDossier._esk.typeDossier === DossierBienTypesConst.VENTE)
                        .map(selectdDossier => selectdDossier._links.self.href),
                });
            }

            /**
             * Get selected dossiers
             *
             * @returns {Array}
             */
            function getSelectedDossiers() {
                let selectedDossiers: NgBienDossier[] = [];

                if ($ctrl.search._esk.dossiersCorrespondants) {
                    angular.forEach(filter($ctrl.search._esk.dossiersCorrespondants.collection, {_esk: {select: true}}), function (selectedDossier) {
                        const predicate = {[selectedDossier._esk.typeDossier]: {id: selectedDossier.id}};

                        if (find($ctrl.search.results.collection, predicate)._esk.colorStatus.value !== 'red') {
                            selectedDossiers.push(selectedDossier);
                        }
                    });
                }

                return selectedDossiers;
            }

            /**
             * Get filtered results from search
             */
            function getFilteredResults() {
                return $ctrl.search.getFilteredResults($ctrl.filter).then(function () {
                    setRouting($ctrl.search._esk.dossiersCorrespondants);
                    demandeurManager.current.recherches[0] = angular.copy($ctrl.search);
                });
            }

            /**
             * Update resume of localisations
             */
            function updateResumeLocalisations() {
                $ctrl.resumeAreas = map($ctrl.search.areas, 'name').join(', ');
                $ctrl.resumeCommunes = map($ctrl.search.communes, 'name').join(', ');
                $ctrl.resumeQuartiers = map($ctrl.search.quartiers, 'name').join(', ');
            }

            /**
             * Update Dossiers correspondants list
             *
             * @param dossiersCorrespondants
             * @returns {Array}
             */
            function setRouting(dossiersCorrespondants: NgBienDossiersCollection) {
                angular.forEach(dossiersCorrespondants.collection, function (dossier) {
                    const predicate = {[dossier._esk.typeDossier!]: {id: dossier.id}};

                    dossier._esk.routing = '/app/demandeurs/' + demandeurManager.current.id.toString() + '/resultats/' + find($ctrl.search.results.collection, predicate).id.toString();
                });

                return dossiersCorrespondants;
            }
        }
    })(angularJS);
}
