import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IOffreachatApi} from '@models/offreachats/offreachat/offreachat.interfaces';
import {
    ICOffreachatsApi, ICOffreachatsQueryParameters
} from '@models/offreachats/collection/offreachats.collection.interfaces';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';

@Injectable({providedIn: 'root'})
export class OffreachatsApiService {
    private _modelApiService = inject(ModelApiService);

    archive$(uuid: string): Observable<void> {
        return this._modelApiService.patch$('/etude/offres-achat/' + uuid + '/archiver');
    }

    get$(uuid: string): Observable<IOffreachatApi> {
        return this.getByLink$('/offres-achat/' + uuid);
    }

    getByLink$(link: string): Observable<IOffreachatApi> {
        return this._modelApiService.get$(link);
    }

    getCollectionByLink$(link: string): Observable<ICOffreachatsApi> {
        return this._modelApiService.getCollection$(link);
    }

    getEtudeCollection$(queryParameters?: ICOffreachatsQueryParameters): Observable<ICOffreachatsApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/offres-achat', queryParameters));
    }

    save$(uuid: string, offreachatApi: IOffreachatApi): Observable<IOffreachatApi> {
        if (uuid === Offreachat.statuts.NEW) {
            return this._modelApiService.post$('/user/offres-achat', offreachatApi);
        }

        return this._modelApiService.put$('/offres-achat/' + uuid, offreachatApi).pipe(switchMap(_ => this.get$(uuid)));
    }

    sign$(uuid: string, data: unknown): Observable<void> {
        return this._modelApiService.patch$('/etude/offres-achat/' + uuid + '/signer', data);
    }

    write$(uuid: string, data: unknown): Observable<void> {
        return this._modelApiService.patch$('/etude/offres-achat/' + uuid + '/rediger', data);
    }
}
