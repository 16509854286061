import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import CContactsGroupMembers from '@models/contacts-group/members/collection/contacts-group-members.collection.model';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {ICollectionSelectionListOptions} from '@shared/collection/selection/collection-selection.interfaces';

@Component({selector: 'app-dossier-members-list', templateUrl: 'dossier-members-list.component.html'})
export class AppDossierMembersListComponent implements OnDestroy, OnInit {
    static readonly initDossierMembersListOptions: ICollectionSelectionListOptions = {
        initSelectAll: false,
        nameSelection: 'dossier-files-list-selection',
    };
    readonly dossierMembersSelected = output<ContactsGroupMember[]>();
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cContactsGroupMembers$!: Observable<CContactsGroupMembers>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: ICollectionSelectionListOptions = {...AppDossierMembersListComponent.initDossierMembersListOptions};

    get cContactsGroupMembers$(): Observable<CContactsGroupMembers> {
        return this._cContactsGroupMembers$;
    }

    @Input()
    set cContactsGroupMembers$(value$: Observable<CContactsGroupMembers>) {
        this._cContactsGroupMembers$ = value$;
    }

    get options(): ICollectionSelectionListOptions {
        return this._options;
    }

    @Input()
    set options(value: ICollectionSelectionListOptions) {
        this._options = {...AppDossierMembersListComponent.initDossierMembersListOptions, ...value};
    }

    ngOnInit(): void {
        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<ContactsGroupMember>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.dossierMembersSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }
}
