<fa-stack>
  <fa-icon [class]="faStack.main_background_color!" transform="{{faStack.main_background_transform}}" class="fa-2x"
  [icon]="[faStack.main_background_name_prefix!, faStack.main_background_name!]" stackItemSize="2x"/>

  <fa-icon [icon]="[faStack.main_icon_name_prefix!, faStack.main_icon_name!]" stackItemSize="1x" class="fa-2x"
  [class]="faStack.main_icon_color!" transform="{{faStack.main_icon_transform}}"/>

  @if (faStack.action_icon_name) {
    <fa-icon [icon]="[faStack.action_background_name_prefix!, faStack.action_background_name!]" stackItemSize="1x"
      [class]="faStack.action_background_color!" transform="{{faStack.action_background_transform}}"
    class="action-background fa-2x"/>
    <fa-icon [icon]="[faStack.action_icon_name_prefix!, faStack.action_icon_name!]" stackItemSize="1x" [rotate]="180"
    [class]="faStack.action_icon_color!" transform="{{faStack.action_icon_transform}}"/>
  }
</fa-stack>
