import AModel from '@models/model.abstract';
import Photo from '@models/photos/photo/photo.model';

export default class BienPhoto extends AModel {
    private _bottomRightCoordinates!: number[];
    private _linkFile!: string;
    private _photo!: Photo;
    private _topLeftCoordinates!: number[];
    private _visibilite!: string;

    get bottomRightCoordinates(): number[] {
        return this._bottomRightCoordinates;
    }

    set bottomRightCoordinates(value: number[]) {
        this._bottomRightCoordinates = value;
    }

    get linkFile(): string {
        return this._linkFile;
    }

    set linkFile(value: string) {
        this._linkFile = value;
    }

    get photo(): Photo {
        return this._photo;
    }

    set photo(value: Photo) {
        this._photo = value;
    }

    get topLeftCoordinates(): number[] {
        return this._topLeftCoordinates;
    }

    set topLeftCoordinates(value: number[]) {
        this._topLeftCoordinates = value;
    }

    get visibilite(): string {
        return this._visibilite;
    }

    set visibilite(value: string) {
        this._visibilite = value;
    }
}
