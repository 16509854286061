import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewAgencementExterieurService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _isAnnexeVisible!: boolean;
    private _isBalconVisible!: boolean;
    private _isCiterneGazLocationVisible!: boolean;
    private _isCiterneGazPleineProprieteVisible!: boolean;
    private _isCommentsAgencementExterieurVisible!: boolean;
    private _isCourVisible!: boolean;
    private _isCuveFuelAerienneVisible!: boolean;
    private _isCuveFuelEnterreeVisible!: boolean;
    private _isCuveInactiveDegazeeVisible!: boolean;
    private _isCuveInactiveNeutralizeeVisible!: boolean;
    private _isGarageVisible!: boolean;
    private _isNombrePlacesParkingVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isPiscineVisible!: boolean;
    private _isTerrasseVisible!: boolean;
    private _isVerandaVisible!: boolean;

    get isAnnexeVisible(): boolean {
        return this._isAnnexeVisible;
    }

    get isBalconVisible(): boolean {
        return this._isBalconVisible;
    }

    get isCiterneGazLocationVisible(): boolean {
        return this._isCiterneGazLocationVisible;
    }

    get isCiterneGazPleineProprieteVisible(): boolean {
        return this._isCiterneGazPleineProprieteVisible;
    }

    get isCommentsAgencementExterieurVisible(): boolean {
        return this._isCommentsAgencementExterieurVisible;
    }

    get isCourVisible(): boolean {
        return this._isCourVisible;
    }

    get isCuveFuelAerienneVisible(): boolean {
        return this._isCuveFuelAerienneVisible;
    }

    get isCuveFuelEnterreeVisible(): boolean {
        return this._isCuveFuelEnterreeVisible;
    }

    get isCuveInactiveDegazeeVisible(): boolean {
        return this._isCuveInactiveDegazeeVisible;
    }

    get isCuveInactiveNeutralizeeVisible(): boolean {
        return this._isCuveInactiveNeutralizeeVisible;
    }

    get isGarageVisible(): boolean {
        return this._isGarageVisible;
    }

    get isNombrePlacesParkingVisible(): boolean {
        return this._isNombrePlacesParkingVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isPiscineVisible(): boolean {
        return this._isPiscineVisible;
    }

    get isTerrasseVisible(): boolean {
        return this._isTerrasseVisible;
    }

    get isVerandaVisible(): boolean {
        return this._isVerandaVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isAnnexeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.annexe);
        this._isBalconVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.balcon)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.balcon);
        this._isCiterneGazLocationVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.citerneGazLocation);
        this._isCiterneGazPleineProprieteVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.citerneGazPleinePropriete);
        this._isCommentsAgencementExterieurVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsAgencementExterieur);
        this._isCourVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cour);
        this._isCuveFuelAerienneVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cuveFuelAerienne);
        this._isCuveFuelEnterreeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cuveFuelEnterree);
        this._isCuveInactiveDegazeeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cuveInactiveDegazee);
        this._isCuveInactiveNeutralizeeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.cuveInactiveNeutralizee);
        this._isGarageVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.garage)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.garage);
        this._isNombrePlacesParkingVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.nombrePlacesParking)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombrePlacesParking);
        this._isPiscineVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.piscine);
        this._isTerrasseVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.terrasse)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.terrasse);
        this._isVerandaVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.veranda);

        this._isPartVisible = this.isAnnexeVisible || this.isBalconVisible || this.isCiterneGazLocationVisible
            || this.isCiterneGazPleineProprieteVisible || this.isCommentsAgencementExterieurVisible || this.isCuveFuelAerienneVisible
            || this.isCiterneGazPleineProprieteVisible || this.isCuveInactiveDegazeeVisible || this.isCuveInactiveNeutralizeeVisible
            || this.isGarageVisible || this.isPiscineVisible || this.isTerrasseVisible || this.isVerandaVisible;
    }
}
