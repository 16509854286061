import angularJS from '@shared/angularJS/global.ng';
import {IModule, IScope} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {NgVentesNegocieesEtudeStatistics, NgVentesNegocieesEtudeStatisticsApi} from '@legacy/app/ventes/ventes';
import {EtudeService} from '@models/etudes/etude/etude.service';
import DateFormat from '@shared/date/date.format';

export default function getVentesNegocieesEtudeCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskVentesNegocieesEtudeCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/ventes/controllers/negociees/etude.html',
        });

        /**
         * Controller of ventes negociees
         *
         * @param Ng2EtudeService
         * @param $scope
         * @param EskUtils
         */
        Controller.$inject = ['Ng2EtudeService', '$scope', 'EskUtils'];
        function Controller(this: any, ng2EtudeService: EtudeService, $scope: IScope, eskUtils: NgEskUtils) {
            const $ctrl = this;

            $ctrl.loading = true;
            $ctrl.years = [DateFormat.getYear(DateFormat.dateFromNow()) - 1, DateFormat.getYear(DateFormat.dateFromNow())];
            $ctrl.months = DateFormat.monthsShort();
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.network = currentEtude.mainNetwork);

            /**
             * Get statistics
             */
            $scope.$watch('$ctrl.getStatistics', function () {
                if (!angular.isFunction($ctrl.getStatistics)) return;

                $ctrl.getStatistics().then(function (statistics: NgVentesNegocieesEtudeStatisticsApi) {
                    $ctrl.statistics = {date: statistics.date} as NgVentesNegocieesEtudeStatistics;
                    $ctrl.statistics[$ctrl.years[0]] = {
                        solo: remove0(eskUtils.getArraySum(statistics.data[$ctrl.years[0]].autres, statistics.data[$ctrl.years[0]].portefeuille)),
                        duo: remove0(statistics.data[$ctrl.years[0]].reseau_notaires)
                    };
                    $ctrl.statistics[$ctrl.years[1]] = {
                        solo: remove0(eskUtils.getArraySum(statistics.data[$ctrl.years[1]].autres, statistics.data[$ctrl.years[1]].portefeuille)),
                        duo: remove0(statistics.data[$ctrl.years[1]].reseau_notaires),
                        network: remove0(statistics.data[$ctrl.years[1]].network)
                    };

                    /**
                     * Remove 0 from array
                     *
                     * @param dirtyArray
                     * @return {Array}
                     */
                    function remove0(dirtyArray: number[] | undefined): number[] {
                        const cleanArray: number[] = [];

                        angular.forEach(dirtyArray, (item: number) => {
                            cleanArray.push(item ? item : undefined as unknown as number);
                        });

                        return cleanArray;
                    }
                }).finally(function () {
                    $ctrl.loading = false;
                });
            });
        }
    })(angularJS);
}
