import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {AppPersonEditSimpleComponent} from '@features/contacts/person-edit-simple/person.edit-simple.component';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {
    AppContactConvivialNameComponent
} from '@features/contacts/contact/convivial-name/contact.convivial-name.component';
import {AppContactCardComponent} from '@features/contacts/contact/card/contact.card.component';
import {AppContactInitialesComponent} from '@features/contacts/contact/initiales/contact.initiales.component';
import {AppContactsSourceComponent} from '@features/contacts/source/contacts-source.component';
import {CommunesModule} from '@features/communes/communes.module';

@NgModule({
    declarations: [
        AppContactCardComponent,
        AppContactConvivialNameComponent,
        AppContactInitialesComponent,
        AppContactsSourceComponent,
        AppPersonEditSimpleComponent,
    ],
    exports: [
        AppContactCardComponent,
        AppContactConvivialNameComponent,
        AppContactsSourceComponent,
        AppPersonEditSimpleComponent,
    ],
    imports: [CommunesModule, CommonModule, DictionariesModule, SharedModule],
})
export class ContactsModule {
}
