export default `
<div class="files-errors">
  <p class="tw-text-center text-bold">{{'files.process.TITLE' | translate:'{type: "' + $ctrl.typeFile + '"}'}}</p>

  <div ng-repeat="taskError in $ctrl.taskErrors" class="file-process">
    {{taskError.infoClient.media.file.name}}

    <div class="file-process-status text-danger">
      <fa-icon [icon]="'times'"></fa-icon>

      <ng-switch on="taskError.error.status">
        <div ng-switch-default>Status : {{taskError.error.status}}</div>

        <div ng-switch-when="ERROR">{{taskError.error.data.title}}</div>

        <ng-switch on="taskError.error.data.title" ng-switch-when="403">
          <div ng-switch-default>Title : {{taskError.error.data.title}}</div>

          <a ng-switch-when="Quota exceeded"
             ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/documents/advertisement', {statutDocuments: {quota: taskError.error.data.quota, size: taskError.error.data.size, used: taskError.error.data.used}})">
            <span class="text-danger">Capacité maximale de stockage atteinte,</span>

            <span>cliquez pour en savoir plus</span>
          </a>
        </ng-switch>

        <div ng-switch-when="422">{{taskError.error.data.errors[0].message}}</div>
      </ng-switch>
    </div>
  </div>
</div>
`;
