import {Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewDescriptifsService {
    private _isPartVisible!: boolean;

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isPartVisible = !!dossierBien.bien.descriptifAffichette || !!dossierBien.bien.descriptifJournalPapier
            || !!dossierBien.bien.descriptifMandat || !!dossierBien.bien.descriptifSiteInternet;
    }
}
