import {inject, Injectable} from '@angular/core';
import {CReferencesFactory} from '@models/references/collection/references.collection.factory';
import {combineLatest, Observable, of} from 'rxjs';
import CReferences from '@models/references/collection/references.collection.model';
import {map, tap} from 'rxjs/operators';
import {ReferenceFactory} from '@models/references/reference/reference.factory';
import Reference from '@models/references/reference/reference.model';

@Injectable({providedIn: 'root'})
export class ReferencesService {
    private _cReferencesFactory = inject(CReferencesFactory);
    private _referenceFactory = inject(ReferenceFactory);

    getCollectionFromLinks$(links: string[] = []): Observable<CReferences> {
        const cReferences = this._cReferencesFactory.createVirgin();
        let references$ = of([] as Reference[]);

        if (links?.length > 0) {
            references$ = combineLatest(links.map(link => this._referenceFactory.getByLink$(link)));
        }

        return references$.pipe(
            tap(references => references.map(reference => cReferences.addResult(reference))),
            map(_ => cReferences),
        );
    }
}
