@if (!!formSelectImage) {
  @if (list$ | async; as list) {
    <ul role="list" class="tw-mt-2 tw-grid tw-grid-cols-4 tw-gap-4">
      @for (item of list; track item) {
        <li class="tw-relative">
          <div [ngClass]="(item.code === formSelectImage.code ? 'tw-ring-2 ' : '') + options.aspectRatio"
               class="tw-group tw-relative tw-block tw-w-full tw-rounded-lg tw-bg-gray-100 focus-within:tw-ring-2 tw-ring-offset-4 tw-ring-offset-gray-100 tw-ring-blue-500 tw-overflow-hidden">
            <img [ngSrc]="item.thumbnailUrl" class="tw-object-cover tw-pointer-events-none group-hover:tw-opacity-75"
                 fill>

            <div class="tw-absolute tw-inset-0">
              <button type="button" class="tw-h-full tw-w-full focus:tw-outline-none"
                      (click)="onSelect(item, focusButton)" #focusButton></button>
            </div>
          </div>

          <div [ngClass]="item.code === formSelectImage.code ? 'tw-text-blue-500' : 'tw-text-gray-900'"
               class="tw-mt-2 tw-block tw-text-sm tw-text-center tw-font-medium tw-truncate tw-pointer-events-none">
            {{ item.label }}
          </div>
        </li>
      }
    </ul>
  }
} @else {
  <app-loader [classHeight]="'tw-h-20'"/>
}
