import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IVenteApi} from '@models/ventes/vente/vente.interfaces';
import {ICVentesApi, ICVentesQueryParameters} from '@models/ventes/collection/ventes.collection.interfaces';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class VentesApiService {
    private _modelApiService = inject(ModelApiService);

    clone$(uuid: string, keepProprietaires: boolean): Observable<IVenteApi> {
        return this._modelApiService.patch$('/ventes/' + uuid + '/clone', {keepProprietaires});
    }

    get$(uuid: string): Observable<IVenteApi> {
        return this._modelApiService.get$('/ventes/' + uuid);
    }

    getByLink$(link: string): Observable<IVenteApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(queryParameters?: ICVentesQueryParameters): Observable<ICVentesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/ventes', queryParameters));
    }

    getCollectionByLink$(link: string): Observable<ICVentesApi> {
        return this._modelApiService.getCollection$(link);
    }

    getEtudeCollection$(queryParameters?: ICVentesQueryParameters): Observable<ICVentesApi> {
        return this.getCollectionByLink$(this._modelApiService.pathWithQueryParameters('/etude/ventes', queryParameters));
    }

    networkDiffuse$(id: string): Observable<void> {
        return this._modelApiService.patch$(`/ventes/${id}/internal/networks/diffuser`);
    }

    openDetailsInNewTab$(id: string): Observable<Window> {
        return this._modelApiService.openWindow$('/app/ventes/details/' + id);
    }

    openOverviewInNewTab$(id: string): Observable<Window> {
        return this._modelApiService.openWindow$('/app/ventes/' + id + '/overview');
    }

    save$(id: string, venteApi: IVenteApi): Observable<IVenteApi> {
        if (id === Vente.statuts.NEW) {
            return this._modelApiService.post$('/user/ventes', venteApi);
        }

        return this._modelApiService.put$(`/ventes/${id}`, venteApi).pipe(switchMap(_ => this.get$(id)));
    }
}
