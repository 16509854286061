import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MediaService} from '@models/medias/media/media.service';
import Media from '@models/medias/media/media.model';
import LocationMandat from '@models/locations/location/mandats/mandat/location-mandat.model';
import Location from '@models/locations/location/location.model';

@Injectable({providedIn: 'root'})
export class LocationMandatsService {
    private _mediaService = inject(MediaService);

    getMedia$(location: Location, locationMandat: LocationMandat): Observable<Media> {
        return this._mediaService.getFromLocationMandats$(location, locationMandat);
    }

    read$(location: Location, locationMandat: LocationMandat): Observable<Window> {
        return this._mediaService.readFromLocationMandats$(location, locationMandat);
    }
}
