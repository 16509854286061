import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';

export default function getEskDocumentsAdvertisementCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDocumentsAdvertisementCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/controllers/documents/advertisement.html',
        });

        /**
         * General controller
         *
         * @param Ng2RouterStateForNgService
         * @constructor
         */
        Controller.$inject = ['Ng2RouterStateForNgService'];
        function Controller(this: any, ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            $ctrl.statutDocuments = ng2RouterStateForNgService.params.statutDocuments;
        }
    })(angularJS);
}
