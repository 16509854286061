import angularJS from '@shared/angularJS/global.ng';
import {firstValueFrom, of, ReplaySubject} from 'rxjs';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgBienManager, NgDictionariesManager, NgEstimationManager, NgManager, NgTemplateManager, NgUserManager,
    NgUtilsManager
} from '@legacy/app/managers/managers';
import {NgEstimation, NgFilter, NgItemDictionary} from '@legacy/app/managers/ressources';
import User from '@models/users/user/user.model';
import Ng2Estimation from '@models/estimations/estimation/estimation.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DateFormat from '@shared/date/date.format';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {ILinksApi} from '@models/links/links.interfaces';

export default function getManagersEstimation(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EstimationManager', manager);

        /**
         * Manager Estimation
         *
         * @param BaseManager
         * @param DictionariesManager
         * @param BienManager
         * @param UtilsManager
         * @param UserManager
         * @param $q
         * @param TemplateManager
         * @param Ng2DictionaryItemService
         */
        manager.$inject = ['BaseManager', 'DictionariesManager', 'BienManager', 'UtilsManager', 'UserManager', '$q', 'TemplateManager', 'Ng2DictionaryItemService'];
        function manager(baseManager: NgManager,
                         dictionariesManager: NgDictionariesManager,
                         bienManager: NgBienManager,
                         utilsManager: NgUtilsManager,
                         userManager: NgUserManager,
                         $q: IQService,
                         templateManager: NgTemplateManager,
                         ng2DictionaryItemService: DictionaryItemService) {
            const classResource = baseManager.getClass();
            let currentSource = new ReplaySubject<NgEstimation>(1);

            /**
             * Routing
             */
            baseManager.addRoute('estimations.cget-etude', {path: '/etude/estimations', method: 'GET'});
            baseManager.addRoute('estimations.get', {path: '/estimations/{id}', method: 'GET'});
            baseManager.addRoute('estimations.insert', {path: '/user/estimations', method: 'POST'});
            baseManager.addRoute('estimations.edit', {path: '/estimations/{id}', method: 'PUT'});
            baseManager.addRoute('estimations.remettre', {path: '/estimations/{id}/remettre', method: 'PATCH'});
            baseManager.addRoute('estimations.create-vente', {path: '/estimations/{id}/vente', method: 'PATCH'});
            baseManager.addRoute('estimations.clone', {path: '/estimations/{uuid}/clone', method: 'PATCH'});
            baseManager.addRoute('estimations.archive', {path: '/estimations/{id}/archiver', method: 'PATCH'});

            /**
             * EstimationManager object
             *
             * @constructor
             */
            angular.extend(EstimationManager.prototype, baseManager.__proto__);
            EstimationManager.prototype.eskManager = {prefixRoute: 'estimations', collectionName: 'items'};
            function EstimationManager(this: NgEstimationManager) {
                this.current$ = currentSource.asObservable();
                this.current$.subscribe(current => this.current = current);
                currentSource.next(undefined!);
            }

            /**
             * Create a Estimation object
             *
             * @param ng2Estimation
             * @returns manager.Estimation
             */
            EstimationManager.prototype.createFromNg2 = function (ng2Estimation: Ng2Estimation) {
                if (!ng2Estimation) {
                    return $q.resolve(undefined!);
                }

                return bienManager.createFromNg2(DossierBienTypesConst.ESTIMATION, ng2Estimation.bien, ng2Estimation.reference).then(bien => this.create({
                    _links: {self: {href: ng2Estimation.linkSelf}},
                    bien,
                    courrierIntroductifRapportId: ng2Estimation.courrierIntroductifRapportId,
                    id: ng2Estimation.id,
                    rapportId: ng2Estimation.rapportId,
                    statut: ng2Estimation.statut,
                    uuid: ng2Estimation.uuid,
                }));
            };

            EstimationManager.prototype.emitCurrent = function(ngEstimation: NgEstimation) {
                currentSource.next(ngEstimation);
            }

            /**
             * Init current and return promise
             *
             * @param estimationId
             * @param currentUser
             * @returns {Promise}
             */
            EstimationManager.prototype.initCurrent = function (estimationId: number, currentUser: User) {
                const that = this;
                let promise: IPromise<NgEstimation>;

                if (estimationId) {
                    promise = that.getOneById(estimationId);
                } else {
                    promise = $q.resolve(that.create({
                        _links: {},
                        bien: {
                            notaire: userManager.createFromNg2(currentUser.defaultNotaire),
                            option: {risquesZone: true},
                            responsableDossier: userManager.createFromNg2(currentUser),
                        },
                        interne: true,
                    }));
                }

                return promise.then(estimation => {
                    bienManager.initCurrent(estimation.bien);
                    currentSource.next(estimation);
                });
            };

            /**
             * Returns the promise to fetch object
             *
             * @param estimationId
             * @returns {Promise}
             */
            EstimationManager.prototype.getOneById = function (estimationId: number) {
                return this.get('get', {id: estimationId}).then(function (estimation: NgEstimation) {
                    const promises = [] as IPromise<unknown>[];

                    if (estimation.bien._links?.modifier?.href) {
                        promises.push(userManager.execLink(estimation.bien._links.modifier.href).then(modifier => {
                            estimation.bien.modifier = modifier;
                        }));
                    }

                    if (estimation.bien.interne && estimation.bien._links?.responsableDossier?.href) {
                        promises.push(userManager.execLink(estimation.bien._links.responsableDossier.href).then(responsableDossier => {
                            estimation.bien.responsableDossier = responsableDossier;
                        }));
                    }

                    return $q.all(promises).then(() => estimation);
                });
            };

            /**
             * Returns the promise to fetch collection of estimations from etude
             *
             * @param params
             * @returns {Promise}
             */
            EstimationManager.prototype.getAllEtude = function (params: unknown) {
                return this.get('cget-etude', params);
            };

            /**
             * Returns the promise to fetch collection of filtered estimations from etude
             *
             * @param filter
             * @param params
             * @returns {Promise}
             */
            EstimationManager.prototype.getAllEtudeFiltered = function (filter: NgFilter, params: unknown) {
                return this.getAllEtude(angular.extend(filter.getParamsRequest(), params));
            };

            /**
             * Create a Estimation object
             *
             * @param data
             * @returns Estimation object || Array of manager.Estimation objects
             */
            EstimationManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var estimations = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        estimations.push(new Estimation(data[i]));
                    }

                    return estimations;
                }

                // @ts-ignore
                return new Estimation(data);
            };

            /**
             * Estimation object
             *
             * @param data
             * @constructor
             */
            angular.extend(Estimation.prototype, classResource.prototype);
            Estimation.prototype.eskManager = {prefixRoute: 'estimations'};
            function Estimation(this: NgEstimation, data: unknown) {
                this._links = {} as ILinksApi;
                this.statut = Ng2Estimation.statuts.BROUILLON;
                this.type = Ng2Estimation.types.AVIS_VALEUR as unknown as NgItemDictionary;
                this.typeValeurVenale = Ng2Estimation.valeurVenaleTypes.SIMPLE as unknown as NgItemDictionary;
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Extend the existing Estimation with new data
             *
             * @param data
             * @returns {Estimation}
             */
            Estimation.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                this.bien.interne = this.interne;
                this._esk.typeDossier = DossierBienTypesConst.ESTIMATION;
                if (this.bien) {
                    this.bien.interne = true;
                }

                this.bien = bienManager.create(this._esk.typeDossier, this.bien);
                if (!angular.isObject(this.bien.metadata)) {
                    this.bien.metadata = {};
                }

                if (angular.isString(this.type)) {
                    this.type = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_TYPES, this.type));
                }

                if (angular.isString(this.typeValeurVenale)) {
                    this.typeValeurVenale = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_VALEUR_VENALE_TYPES, this.typeValeurVenale));
                }

                if (angular.isString(this.dateVisite)) {
                    this.dateVisite = DateFormat.toDate(this.dateVisite);
                }

                this.valeurVenale = this.valeurVenale?.toString().replace('.', ',');
                this.valeurVenaleMax = this.valeurVenaleMax?.toString().replace('.', ',');
                this.valeurVenaleMin = this.valeurVenaleMin?.toString().replace('.', ',');

                return this;
            };

            /**
             * Sanitize on change type
             *
             * @returns {Estimation}
             */
            Estimation.prototype.sanitizeOnChangeType = function () {
                if (this.type.code === Ng2Estimation.types.AVIS_VALEUR) {
                    this.correctifValeurVenale = undefined!;
                    this.montantTravauxM2 = undefined!;
                    this.reservesEnvironnementContamination = undefined!;
                    this.valeurVenaleBrute = undefined!;
                    this.valeurVenaleComments = undefined!;
                    this.valeurVenaleComparaison = undefined!;
                    this.valeurVenaleM2 = undefined!;
                } else {
                    this.typeValeurVenale = dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_VALEUR_VENALE_TYPES, Ng2Estimation.valeurVenaleTypes.SIMPLE));
                }
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Estimation.prototype.save = function () {
                const self = this;
                var selfBien = angular.copy(self.bien);

                return classResource.prototype.save.call(self).then(function () {
                    return self.bien.save(selfBien).then(function () {
                        if (self._esk.setTitreDescriptif.need) {
                            return templateManager.setBienTitreDescriptif(self).then(() => self);
                        }

                        return self;
                    });
                }).then(_ => firstValueFrom(currentSource.asObservable())).then(currentEstimation => {
                    if (self.id === currentEstimation.id) {
                        currentSource.next(self);
                    }

                    return self;
                });
            };

            /**
             * Sanitize object Estimation before send to API
             *
             * @returns {Object}
             */
            Estimation.prototype.sanitize = function () {
                let sanitizeObject;

                this.pourcentageValeurVenale = this.pourcentageValeurVenale > 100 ? 0 : this.pourcentageValeurVenale;
                sanitizeObject = classResource.prototype.sanitize.call(this);
                sanitizeObject.bien = this.bien.sanitize();
                sanitizeObject.dateVisite = DateFormat.toAPI(this.dateVisite);
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'type');
                utilsManager.flattenAttributeWithCode(sanitizeObject, 'typeValeurVenale');
                if (!!this.valeurVenale) {
                    sanitizeObject.valeurVenale = +(this.valeurVenale.toString().replace(',', '.'));
                } else {
                    sanitizeObject.valeurVenale = null!;
                }

                if (!!this.valeurVenaleMax) {
                    sanitizeObject.valeurVenaleMax = +(this.valeurVenaleMax.toString().replace(',', '.'));
                } else {
                    sanitizeObject.valeurVenaleMax = null!;
                }

                if (!!this.valeurVenaleMin) {
                    sanitizeObject.valeurVenaleMin = +(this.valeurVenaleMin.toString().replace(',', '.'));
                } else {
                    sanitizeObject.valeurVenaleMin = null!;
                }

                if (this.typeValeurVenale.code === Ng2Estimation.valeurVenaleTypes.FOURCHETTE) {
                    delete sanitizeObject.valeurVenale;
                    delete sanitizeObject.pourcentageValeurVenale;
                } else {
                    delete sanitizeObject.valeurVenaleMin;
                    delete sanitizeObject.valeurVenaleMax;
                }

                return sanitizeObject;
            };

            /**
             * Say if Rapport is editable or not
             *
             * @return boolean
             */
            Estimation.prototype.canEditRapport = function () {
                const valeurVenale = +((this.valeurVenale || '0').toString().replace(',', '.'));
                const valeurVenaleMax = +((this.valeurVenaleMax || '0').toString().replace(',', '.'));
                const valeurVenaleMin = +((this.valeurVenaleMin || '0').toString().replace(',', '.'));

                return angular.isObject(this.dateRemise) ||
                    (angular.isString(this.dateRemise) && this.bien.dateRemise !== '') ||
                    (
                        this.bien.hasSavedCadastres() &&
                        angular.isString(this.bien.descriptif) && this.bien.descriptif !== '' &&
                        (
                            (this.typeValeurVenale.code === Ng2Estimation.valeurVenaleTypes.SIMPLE && valeurVenale > 0)
                            || (this.typeValeurVenale.code === Ng2Estimation.valeurVenaleTypes.FOURCHETTE && valeurVenaleMin > 0 && valeurVenaleMax > 0)
                        ) && (
                            this.type.code === Ng2Estimation.types.AVIS_VALEUR || (
                                this.type.code === Ng2Estimation.types.EVALUATION &&
                                angular.isObject(this.bien.option.commune) && this.bien.option.codePostal && (
                                    (this.bien.option.commune.hasVoies && this.bien.option.voie) ||
                                    (!this.bien.option.commune.hasVoies && this.bien.option.nomVoie)
                                )
                            )
                        )
                    );
            };

            /**
             * Remettre Estimation
             *
             * @returns {Promise}
             */
            Estimation.prototype.remettre = function () {
                const self = this;

                return self.patch('remettre').then(function () {
                    self.dateRemise = DateFormat.toDate();
                    self.statut = Ng2Estimation.statuts.REMIS;
                });
            };

            /**
             * Create Vente from Estimation
             *
             * @returns {Promise}
             */
            Estimation.prototype.createVente = function () {
                return this.patch('create-vente');
            };

            /**
             * Archive Estimation
             *
             * @param archive
             * @returns {Promise}
             */
            Estimation.prototype.archive = function (archive: unknown) {
                const self = this;

                return self.saveArchiveInfos(archive).then(function () {
                    return self.patch('archive');
                });
            };

            /**
             * Get information for archive
             *
             * @returns {Observable}
             */
            Estimation.prototype.getArchiveInfos = function () {
                return of({comments: this.archiveComments});
            };

            /**
             * Save archive information
             *
             * @param archive
             * @returns {Promise}
             */
            Estimation.prototype.saveArchiveInfos = function (archive: { comments: string }) {
                this.archiveComments = archive.comments;

                return this.save();
            };

            /**
             * Clone estimation
             *
             * @param keepOwners
             * @returns {Promise}
             */
            Estimation.prototype.clone = function (keepOwners: boolean): IPromise<NgEstimation> {
                return this.patch('clone', {keepProprietaires: Boolean(keepOwners) === true}, {uuid: this.uuid});
            };

            // @ts-ignore
            return new EstimationManager();
        }
    })(angularJS);
}
