import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {
    OffreachatResumeActionsMainComponent
} from '@features/offreachats/item/actions/resume/offreachat-resume.actions-main.component';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import Document from '@models/documents/document/document.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Vente from '@models/ventes/vente/vente.model';
import {Router} from '@angular/router';
import {ToasterService} from '@shared/toaster/toaster.service';
import {MediaResumeService} from '@models/medias/media/media-resume.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {DocumentService} from '@models/documents/document/document.service';

@Component({selector: 'layout-offreachat-resume', templateUrl: 'layout.offreachat-resume.component.html'})
export class AppLayoutOffreachatResumeComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        RESUME_DISABLED: 'Le résumé de votre offre d\'achat n\'est pas disponible.',
    };
    private _callToActionService = inject(CallToActionService);
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _emailEditService = inject(EmailEditService);
    private _mediaResumeService = inject(MediaResumeService);
    private _offreachatService = inject(OffreachatService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _venteFactory = inject(VenteFactory);
    private _documentPathSource = new BehaviorSubject<string>(undefined!);
    private _documentPath$ = this._documentPathSource.asObservable();
    private _documentSource = new ReplaySubject<Document>(1);
    private _document$ = this._documentSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _procedureSource = new ReplaySubject<Procedure>(1);
    private _procedure$ = this._procedureSource.asObservable();
    private _offreachatSource = new BehaviorSubject<Offreachat>(undefined!);
    private _offreachat$ = this._offreachatSource.asObservable();
    private _venteSource = new BehaviorSubject<Vente>(undefined!);
    private _vente$ = this._venteSource.asObservable();

    get offreachat$(): Observable<Offreachat> {
        return this._offreachat$;
    }

    get documentPath$(): Observable<string> {
        return this._documentPath$;
    }

    get procedure$(): Observable<Procedure> {
        return this._procedure$;
    }

    get vente$(): Observable<Vente> {
        return this._vente$;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        if (!this._router.url.startsWith('/app/offres-achat')) {
            fallbackPath = this._router.url.split('/offres-achat')[0] + '/offres-achat/portefeuille';
        }

        this._offreachatService.current$.pipe(filter(offreachat => !!offreachat), take(1)).subscribe(offreachat => {
            if (!offreachat.isArchive()) {
                this._toasterService.warning(AppLayoutOffreachatResumeComponent.errorMessages.RESUME_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this._offreachatService.current$.pipe(
            filter(offreachat => !!offreachat),
            takeUntil(this._onDestroy$),
        ).subscribe(offreachat => this._offreachatSource.next(offreachat));
        this.offreachat$.pipe(
            filter(offreachat => !!offreachat),
            tap(offreachat => {
                if (offreachat.documentId) {
                    this._callToActionService.setDynamicComponentLoading({
                        component: OffreachatResumeActionsMainComponent,
                        data: {offreachat},
                    });
                }
            }),
            switchMap(offreachat => {
                const data$: Observable<unknown>[] = [this._venteFactory.getByLink$(offreachat.linkVente).pipe(tap(vente => this._venteSource.next(vente)))];

                if (offreachat.linkDocument) {
                    data$.push(this._documentFactory.getByLink$(offreachat.linkDocument)
                        .pipe(tap(document => this._documentSource.next(document))));
                    data$.push(this._offreachatService.getProcedure$(offreachat)
                        .pipe(tap(procedure => this._procedureSource.next(procedure))));
                }

                if (offreachat.linkMedia) {
                    data$.push(this._mediaResumeService.getFromOffreachat$(offreachat).pipe(
                        map(media => media.linkDownload),
                        tap(documentPath => this._documentPathSource.next(documentPath)),
                    ));
                } else if (offreachat.linkDocument) {
                    data$.push(this._document$.pipe(
                        filter(document => !!document),
                        switchMap(document => this._documentService.getMedia$(document)),
                        map(media => media.linkDownload),
                        tap(documentPath => this._documentPathSource.next(documentPath)),
                    ));
                }

                return combineLatest(data$);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe();
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === OffreachatResumeActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === OffreachatResumeActionsMainComponent.actions.SEND) {
                    return this._callToActionService.actionExec$(this.send$());
                }

                return of(undefined);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    print$(): Observable<string> {
        return this.offreachat$.pipe(
            take(1),
            switchMap(offreachat => this._mediaResumeService.readFromOffreachat$(offreachat)),
            map(_ => undefined!),
        );
    }

    send$(): Observable<string> {
        return this.offreachat$.pipe(
            take(1),
            switchMap(offreachat => combineLatest([
                this._document$,
                this._mediaResumeService.getFromOffreachat$(offreachat).pipe(map(media => [media])),
                this._offreachatService.getDossiersCurrent$(),
            ])),
            switchMap(([document, attachments, dossiers]) => this.procedure$.pipe(
                switchMap(procedure => this._emailEditService.fromOffreachatDocument$(document, attachments, dossiers, procedure))
            )),
            map(_ => undefined!),
        );
    }
}
