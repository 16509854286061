import {Component, Input} from '@angular/core';
import {IEmailRecipientStatutOptions} from '@features/emails/emails.interfaces';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';

@Component({selector: 'app-email-recipient-statut', templateUrl: 'email-recipient.statut.component.html'})
export class AppEmailRecipientStatutComponent {
    static readonly initEmailRecipientStatutOptions: IEmailRecipientStatutOptions = {useShortLabel: false};
    private _emailRecipient!: EmailRecipient;
    private _options: IEmailRecipientStatutOptions = {...AppEmailRecipientStatutComponent.initEmailRecipientStatutOptions};

    get emailRecipient(): EmailRecipient {
        return this._emailRecipient;
    }

    @Input()
    set emailRecipient(value: EmailRecipient) {
        this._emailRecipient = value;
    }

    get options(): IEmailRecipientStatutOptions {
        return this._options;
    }

    @Input()
    set options(value: IEmailRecipientStatutOptions) {
        this._options = {...AppEmailRecipientStatutComponent.initEmailRecipientStatutOptions, ...value};
    }
}
